<div class="pa-1">
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="slackChannel && !slackChannel.is_authenticated">
    <button mat-stroked-button color="primary" (click)="addChannel()">
      <mat-icon>vpn_key</mat-icon>
      {{ 'slack_channel.message.click_add_your_channel' | translate }}
    </button>
  </div>

  <form [formGroup]="form">
    <ng-container *ngIf="slackChannel && slackChannel.is_authenticated">
      <mat-form-field class="full-width">
        <input matInput [readonly]="true" placeholder="{{ 'slack_channel.content.public_id' | translate }}"
          formControlName="publicId">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput [readonly]="true" placeholder="{{ 'slack_channel.content.team_id' | translate }}"
          formControlName="teamId">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput [readonly]="true" placeholder="{{ 'slack_channel.content.team_name' | translate }}"
          formControlName="teamName">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput [readonly]="true" placeholder="{{ 'slack_channel.content.channel' | translate }}"
          formControlName="channel">
      </mat-form-field>

      <mat-checkbox formControlName="isActive">{{ 'slack_channel.content.is_active' | translate }}</mat-checkbox>
    </ng-container>
  </form>
  <ng-container *ngIf="slackChannel && slackChannel.is_authenticated">
    <mat-form-field class="full-width">
      <textarea type="text" matInput [formControl]="messageCtrl" placeholder="{{ 'slack_channel.content.message' | translate }}"></textarea>
      <mat-error *ngIf="messageCtrl.invalid && messageCtrl.dirty" class="mat-text-warn">
        {{ 'slack_channel.validators.message_cannot_blank' | translate }}
      </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button type="button" mat-raised-button color="primary" [disabled]="messageCtrl.invalid" (click)="sendMessage()">
        <mat-icon>send</mat-icon> 
        {{ 'public.send' | translate }}
      </button>
    </div>
  </ng-container>
</div>
