
  <mat-tab-group mat-stretch-tabs dynamicHeight>
    <mat-tab label="{{ 'call_queue.dialog_queue_list.inbound' | translate }}">
      <div fxLayout="column">
        <div #descriptionTop class="description text-center"
          [innerHTML]="(isAdmin ? 'call_queue.dialog_queue_list.description_top_link_v2' : 'call_queue.dialog_queue_list.description_top_v2')|translate:{ agent_name: agent.name }">
        </div>

        <div *ngIf="selected_list.inbound?.length">
          <ng-container
            *ngTemplateOutlet="tplSelectedItems; context: {selectedList: selected_list.inbound || [], isInbound: true}">
          </ng-container>
        </div>
        
        <flex-tree-input #inboundTree [formControl]="inboundControl" [nodes]="tree_group_queue_list"
          [showFilter]="true" [multiple]="true" [showCheckAll]="true" [expandAll]="!hasFeatureGroup"></flex-tree-input>
        
        <div class="description text-center" *ngIf="hasFeatureGroup">
          {{ 'call_queue.dialog_queue_list.description_footer_v2'|translate:{agent_name: agent.name} }}
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'call_queue.dialog_queue_list.outbound' | translate }}">
      <div fxLayout="column">
        <div #outboundDescriptionTop class="description text-center"
          [innerHTML]="(isAdmin ? 'call_queue.dialog_queue_list.outbound_description_top_link_v2' : 'call_queue.dialog_queue_list.outbound_description_top_v2')|translate:{ agent_name: agent.name }">
        </div>

        <div *ngIf="selected_list.outbound?.length">
          <ng-container
            *ngTemplateOutlet="tplSelectedItems; context: {selectedList: selected_list.outbound || [], isInbound: false}">
          </ng-container>
        </div>

        <flex-tree-input #outboundTree [formControl]="outboundControl" [nodes]="tree_group_campaign_list"
          [showFilter]="true" [multiple]="true" [showCheckAll]="true" [expandAll]="!hasFeatureGroup"></flex-tree-input>

        <div class="description text-center" *ngIf="hasFeatureGroup">
          {{ 'call_queue.dialog_queue_list.outbound_description_footer_v2'|translate:{agent_name: agent.name} }}
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>


<ng-template #tplSelectedItems let-selectedList="selectedList" let-isInbound="isInbound">
  <div fxLayout="row wrap" fxLayoutCap="5px">
    <ng-container *ngFor="let item of selectedList">
      <span class="btn-tag" *ngIf="item.id">
        <div class="tag-inner">
          <button mat-icon-button color="warn" (click)="removeSelectedItem(item, isInbound)">
            <mat-icon>clear</mat-icon>
          </button>
          <span class="tag-label" [innerHTML]="item.name" [matTooltip]="item.name"></span>
        </div>
      </span>
    </ng-container>
  </div>
</ng-template>
