import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { AuthenticationService } from '@wephone-core/service/authentication';
import * as _ from 'lodash';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { IAgentListSort } from '../agent-list/agent-list.i';
import { FlexBaseComponent } from '@wephone-core-ui';
import { EavesdropService } from 'src/app/wephone-common/service/eavesdrop.service';
import { AgentService } from '@wephone/services/agent.service';
import { PauseAgentDialog } from '@wephone-common/modals';
import { DialogService, regexSearch } from '@wephone-utils';
import { MatDialogRef } from '@angular/material/dialog';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { IAgentItemActionMenu } from '@wephone-common/user/agent-item/agent-item.i';
import { AgentStatusType } from '../agent-status-type';
import { AgentChangeQueueListDialog } from '@wephone/modals/agent/agentChangeQueueList/agentChangeQueueList';
import { _ti } from '@wephone-translation';
import { EnterpriseParam, UserType } from '@wephone-core/system';
import { EavesdropRequestEntity } from '@wephone-core/model/entity/eavesdrop_request';
import { EavesdropRequestRepository } from '@wephone-core/model/repository/eavesdrop_request';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CallQueueItemRepository } from '@wephone-core/model/repository/callqueueitem';
import { AgentChangeInoutStateComponent } from '@wephone/modals/agent/agent-change-inout-state/agent-change-inout-state.component';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';

@Component({
  selector: 'available-agents',
  templateUrl: 'available-agents.html',
  styleUrls: ['available-agents.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableAgentsComponent extends FlexBaseComponent implements OnInit, OnDestroy {
  PERIODICAL_UPDATE: true;
  @Input() title: string;
  @Input() agentSort: IAgentListSort;
  @Input() filterQueue: CallQueueEntity;
  @Input() displayType: string;
  @Input() searchText: string;
  @Input() dropEnable: boolean;
  @Input() callDirection: string; // Filter by call-direction: both, incall, outcall
  @Input() filterGroupIds: number[];
  @Input() filterUngroupedAgents: boolean; // Check whether ungrouped agent was listed or not
  @Input() filterEavesdrop: boolean;
  @Input() filterAgentStatus: AgentStatusType[];
  @Output() readonly onDataUpdated: EventEmitter<any> = new EventEmitter();
  directionSelectable: number;

  currentUser: IUserEntity;
  agent_list: AgentEntity[] = [];
  agentActions: IAgentItemActionMenu[];
  eavesdrop_list: EavesdropRequestEntity[] = [];

  constructor(
    protected readonly em: EntityManager,
    protected readonly authService: AuthenticationService,
    protected readonly configManager: ConfigManager,
    protected readonly agentService: AgentService,
    protected readonly eavesdropService: EavesdropService,
    protected readonly dialogService: DialogService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.callDirection = this.callDirection || 'both';
    this.displayType = this.displayType || 'compact';
    this.agentSort = this.agentSort || {
      sort_column: 'name',
      sort_dir: 1
    };

    this.currentUser = this.authService.getUser();
  }

  ngOnInit(): any {
    super.ngOnInit();

    this.directionSelectable = this.configManager.getEnterpriseParamValue(EnterpriseParam.direction_selection);

    this.setActionMenu();
  }

  async resolveData(): Promise<void> {
    let filteredUserIds: number[];
    if (!this.authService.isAdmin()) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      filteredUserIds = myProfile.managed_user_ids;

      if (myProfile.managed_queue_ids && myProfile.managed_queue_ids.length) {
        const queues = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectList()
          .filter(q => myProfile.managed_queue_ids.includes(q.id) ? true : false);
        for (const q of queues) {
          filteredUserIds = filteredUserIds.concat(q.getAgents().map(a => a.user_id));
        }
      }

      if (myProfile.managed_group_ids && myProfile.managed_group_ids.length) {
        const groups = this.em.getRepository<UserGroupRepository>('UserGroupRepository').getObjectList()
          .filter(g => myProfile.managed_group_ids.includes(g.id) ? true : false);
        let uids = [];
        for (const g of groups) {
          filteredUserIds = filteredUserIds.concat(g.users.map(u => u.id));
          uids = uids.concat(g.users.map(u => u.id));
        }
      }

      filteredUserIds = _.uniq(filteredUserIds);
    }

    this.agent_list = this.em.getRepository<AgentRepository>('AgentRepository').getObjectList();
    if (filteredUserIds) {
      this.agent_list = this.agent_list.filter(x => _.includes(filteredUserIds, x.user_id));
    }

    const eavesdropRepo = this.em.getRepository<EavesdropRequestRepository>('EavesdropRequestRepository');

    this.setEavesDropList();
    const eavesdropSource = eavesdropRepo.dataSource<EavesdropRequestEntity>();
    this.addSubscription(eavesdropSource.subscribe(val => {
      this.setEavesDropList();
    }));

    const agentSource = this.em.getRepository<AgentRepository>('AgentRepository').dataSource<AgentEntity>();
    this.addSubscription(agentSource.subscribe(val => {
      this.setActionMenu();
    }));
  }

  private setEavesDropList(): void {
    const eavesdropRepo = this.em.getRepository<EavesdropRequestRepository>('EavesdropRequestRepository');
    this.eavesdrop_list = eavesdropRepo.getObjectList();
    this.setActionMenu();
  }

  detectChanges(): void {
    if (this.onDataUpdated) {
      this.onDataUpdated.emit({ updated: true });
    }
    this.setActionMenu();
    super.detectChanges();
  }

  get canChangeNumber(): boolean {
    return this.is_admin || this.is_supervisor;
  }

  get count_agents(): number {
    return this.agent_available_list.length;
  }

  get agent_available_list(): AgentEntity[] {
    return this.agent_list.filter((i: AgentEntity) => {
      return this.filter_agent_bound(i) &&
        this.filter_agent(i) &&
        // this.filter_agent_available(i) &&
        this.filter_agent_queue(i) &&
        this.search_agent(i);
    });
  }

  get agent_available_list_ordered(): any[] {
    return _.orderBy(
      this.agent_available_list,
      [(o) => {
        if (_.includes(['pause_reason', 'pause_reason_id'], this.agentSort.sort_column)) {
          return o.getPauseReasonLabel();
        }
        return o[this.agentSort.sort_column];
      }],
      [this.agentSort.sort_dir ? 'asc' : 'desc']
    );
  }

  private filter_agent_queue(agent: AgentEntity): boolean {
    if (!this.filterQueue) {
      return true;
    }
    return this.filterQueue.contain_agent_id(agent.id);
  }

  private filter_agent(agent: AgentEntity): boolean {
    if (!this.currentUser) {
      console.warn('AgentAvailable: Current user not found');
      return false;
    }
    if (!agent.enabled) {
      return false;
    }

    if (agent.user && agent.user.user_type === UserType.ACCOUNTANT) {
      return false;
    }

    const current_user_group_ids: number[] = this.currentUser.group_ids || [];
    if (
      this.authService.isWatcher() &&
      // (!agent.group_ids ||
      (this.configManager.hasFeature('SERVICE_GROUP') &&
        agent.group_ids && current_user_group_ids.length && !agent.group_ids.filter((n: number) => {
          return current_user_group_ids.indexOf(n) !== -1;
        }).length)
      // )
    ) {
      return false;
    }

    // Not show agent in case of calling from sipphone to sipphone
    if (!agent.is_online_cc && !agent.reserved) {
      return false;
    }

    if (!_.isEmpty(this.filterAgentStatus) && !this.filterAgentStatus.includes(agent.agent_status)) {
      return false;
    }

    const found = this.eavesdropService.getEavesdropAgent(this.currentUser, agent);
    if ((this.filterEavesdrop && !found) || (!this.filterEavesdrop && found)) {
      return false;
    }

    // Filter by groups
    if (this.configManager.hasFeature('SERVICE_GROUP')) {
      if (!(agent.group_ids || []).length) {
        // If there is some group filtered, show agent without group if option show un-grouped agent was checked
        return this.filterUngroupedAgents;
      }

      if (!this.filterGroupIds || this.filterGroupIds.length === 0) {
        // if no group was selected, show all
        return true;
      }

      let ret = false;
      for (let i = 0; i < agent.group_ids.length; i++) {
        const group_id = agent.group_ids[i];
        if (this.filterGroupIds.indexOf(group_id) !== -1) {
          ret = true;
          break;
        }
      }
      return ret;
    }

    return true;
  }

  filter_agent_bound(agent: AgentEntity): boolean {
    return (
      this.callDirection === 'both' ||
      (this.callDirection === 'inbound' && (agent.inout_state === 0 || agent.inout_state === 1)) ||
      (this.callDirection === 'outbound' && (agent.inout_state === 0 || agent.inout_state === 2))
    );
  }

  private search_agent(agent: AgentEntity): boolean {
    if (!this.searchText) {
      return true;
    }

    // const re = new RegExp(this.searchText, 'i');
    const searchTextList = [agent.name];
    for (const queue of agent.inbound_queue_list) {
      searchTextList.push(queue.queue_name);
    }

    for (const searchText of searchTextList) {
      // if (search_text.search(re) !== -1) {
      if (regexSearch(searchText, this.searchText)) {
        return true;
      }
    }

    return false;
  }

  agentDisconnect = (agent: AgentEntity): any => {
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.content.confirm_disconnect_agent'),
      async () => {
        try {
          const rs = await this.agentService.disconnect_callcenter(agent.id);
          this.detectChanges();
          this.successToast(_ti('agent_panel.action_result.disconnect_success'));

          return rs;
        } catch (error) {
          console.error('Agent disconnect: ', error);
          this.showExceptionMessage(error);
        }
      }
    );
  }

  agentUnpause = async (agent: AgentEntity): Promise<void> => {
    try {
      await this.agentService.unpause(agent.id);
      this.successToast(_ti('agent_panel.message.unpause_success'));
    } catch (response) {
      console.error('Unpause agent error', response);
      this.showExceptionMessage(response, _ti('agent_panel.message.unpause_failure'));
    }
  }

  agentPause = (agent: AgentEntity): MatDialogRef<any> => {
    return this.dialogService.openDialog2(PauseAgentDialog, {}, async (ret: { pause_reason_id: number }) => {
      if (!ret || !ret.pause_reason_id) {
        return;
      }

      try {
        await this.agentService.pause(agent.id, ret.pause_reason_id);
        this.detectChanges();
        this.successToast(_ti('agent_panel.message.pause_success'));
      } catch (response) {
        console.error('Pause agent error', response);
        this.showExceptionMessage(response, _ti('agent_panel.message.pause_failure'));
      }
    });
  }

  private showExceptionMessage(errorException: any, defaultMessage = _ti('public.message.error_occurred')): void {
    let message: string;
    if (errorException) {
      message = errorException.error && errorException.error.message || errorException.message;
    }
    this.toastService.showError(message || defaultMessage);
  }

  agentChangeQueueList = (agent: AgentEntity): MatDialogRef<any> => {
    const agent_queues: CallQueueEntity[] = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getQueuesByAgentId(agent.id);

    return this.dialogService.openDialog2(
      AgentChangeQueueListDialog,
      {
        data: {
          agent,
          queues: agent_queues
        },
        size: 's',
      },
      () => {
        this.detectChanges();
      }
    );
  }

  agentChangeInOut = (agent: AgentEntity): MatDialogRef<any> => {
    return this.dialogService.openDialog2(
      AgentChangeInoutStateComponent,
      {
        data: {
          agent
        },
        size: 's',
      },
      () => {
        this.detectChanges();
      }
    );
  }

  async handleEavesdropAgent(agent: AgentEntity): Promise<void> {
    await this.eavesdropService.call_eavesdrop_agent(agent.user.id);
    this.detectChanges();
  }

  async handleRemoveEavesdropAgent(agent: AgentEntity): Promise<void> {
    const eavesdropRequest: EavesdropRequestEntity = this.eavesdropService.getEavesdropAgent(this.currentUser, agent);
    if (!eavesdropRequest) {
      this.toastService.showError('Eavesdrop not found');
      return;
    }

    await this.eavesdropService.removeEavesdropRequest(eavesdropRequest.id);
    this.detectChanges();
  }

  get agent_id(): number {
    return this.authService.getAgentId();
  }

  get is_admin(): boolean {
    return this.authService.isAdmin();
  }

  get is_supervisor(): boolean {
    return this.authService.isSupervisor();
  }

  async dropAgent(event: CdkDragDrop<any>): Promise<any> {
    if (!this.dropEnable) {
      console.warn('Drop event was disabled');
      return undefined;
    }

    console.log('dropAgent event', event);
    const agent = event.container.data[0]; // data contain only one item
    const callId = +event.item.data;
    if (!_.isEmpty(agent) && callId) {
      await this.em.getRepository<CallQueueItemRepository>('CallQueueItemRepository').forceAgentForCall(callId, agent, undefined);
      this.detectChanges();
    }
  }

  private setActionMenu(): void {
    this.agentActions = [
      {
        id: 'change_agent_queue',
        icon: 'list',
        label: 'livecall.actions.change_agent_queue',
        callback: (agent: AgentEntity) => {
          return this.agentChangeQueueList(agent);
        },
        visible: (agent: AgentEntity) => {
          return this.canChangeNumber;
        }
      },
      {
        id: 'change_agent_in_out',
        icon: 'compare_arrows',
        label: 'livecall.actions.change_inout_state',
        callback: (agent: AgentEntity) => {
          return this.agentChangeInOut(agent);
        },
        visible: (agent: AgentEntity) => {
          return this.directionSelectable &&
            (this.is_admin || this.is_supervisor || this.agent_id === agent.id);
        }
      },
      {
        id: 'eavsedrop-agent',
        icon: 'headphones',
        label: 'livecall.actions.eavesdrop',
        callback: (agent: AgentEntity) => {
          return this.handleEavesdropAgent(agent);
        },
        visible: (agent: AgentEntity) => {
          return this.eavesdropService.canEavesdropAgent(this.currentUser, agent);
        }
      },
      {
        id: 'remove-eavesdrop-agent',
        icon: 'headset_off',
        color: 'warn',
        label: 'livecall.eavesdrop.remove',
        callback: (agent: AgentEntity) => {
          return this.handleRemoveEavesdropAgent(agent);
        },
        visible: (agent: AgentEntity) => {
          return this.eavesdropService.canRemoveEavesdrop(this.currentUser, agent);
        }
      },
      {
        id: 'pause_agent',
        icon: 'pause',
        label: 'livecall.actions.pause',
        callback: (agent: AgentEntity) => {
          return this.agentPause(agent);
        },
        visible: (agent: AgentEntity) => {
          return (this.is_admin || this.is_supervisor || this.agent_id === agent.id) && !agent.is_paused && !agent.reserved;
        }
      },
      {
        id: 'unpause_agent',
        icon: 'play_arrow',
        label: 'livecall.actions.unpause',
        callback: (agent: AgentEntity) => {
          return this.agentUnpause(agent);
        },
        visible: (agent: AgentEntity) => {
          return (this.is_admin || this.is_supervisor || this.agent_id === agent.id) && agent.is_paused;
        }
      },
      {
        id: 'disconnect_agent',
        icon: 'exit_to_app',
        label: 'livecall.actions.disconnect',
        callback: (agent: AgentEntity) => {
          return this.agentDisconnect(agent);
        },
        visible: (agent: AgentEntity) => {
          return (this.is_admin || this.is_supervisor || this.agent_id === agent.id) && agent.is_connected;
        }
      },
    ];
  }

  isAgentAvailable(agent: AgentEntity): boolean {
    return agent.agent_status === AgentStatusType.AVAILABLE;
  }
}
