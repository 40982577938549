import { Entity, datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { HotelWakeupCallEntity } from '@wephone-core/model/entity/hotel_wakeup_call';
import { parseDateTime } from '@wephone-utils';
import { SipPhoneRepository } from '../repository/sipphone';
import { HotelWakeupCallRepository } from '../repository/hotel_wakeup_call';
import * as _ from 'lodash';
import { _ti } from '@wephone-translation';
import { HotelRookStateType, HotelRoomPhoneStateType, HotelWakeupCallStateType } from './hotelroom.i';
import { DateTime } from 'luxon';

export class HotelRoomEntity extends Entity {
  static object_type_id = 'hotelroom';

  id: number;
  @datafield room_number: string;
  // @datafield room_state: HotelRookStateType; // (0: unoccupied, 1: checked in)
  // @datafield room_state_dt: any;
  @datafield client_name: string;
  @datafield client_id: string;
  @datafield crm_hotel_reservation_id: string;
  @datafield crm_hotel_room_id: string;
  @datafield not_found_in_pms: number;
  @datafield last_checkin_dt: DateTime;

  private _sip_phone: SipPhoneEntity;
  private _wakeup_call: HotelWakeupCallEntity;
  private _last_wakeup_call: HotelWakeupCallEntity;

  get room_state(): HotelRookStateType {
    return this.has_reservation ? HotelRookStateType.CHECKEDIN : HotelRookStateType.UNOCCUPIED;
  }

  get has_reservation(): boolean {
    return !!this.client_name && !!this.client_id
    && !this.not_found_in_pms
    && !!this.crm_hotel_room_id;
  }

  set room_state(state: HotelRookStateType) {
  }

  get is_occupied(): boolean {
    return this.room_state === HotelRookStateType.CHECKEDIN; // this.client_name && this.crm_hotel_reservation_id && 
  }

  get display_room_number(): string {
    return _ti('hotelroom.content.room') + ` ${this.room_number}`;
  }

  get sipphone_is_online(): HotelRoomPhoneStateType {
    if (this._sip_phone) {
      return this._sip_phone.is_online && HotelRoomPhoneStateType.CONNECTED || HotelRoomPhoneStateType.DISCONECTED;
    }
    return HotelRoomPhoneStateType.NO_PHONE; // not having sip phone
  }

  get sip_phone(): SipPhoneEntity {
    return this._sip_phone;
  }

  set sip_phone(sp: SipPhoneEntity) {
    if (this._sip_phone && this._sip_phone.hotel_room_id === this.id) {
      this._sip_phone.hotel_room_id = null;
    }
    this._sip_phone = sp;
    if (sp) {
      sp.hotel_room_id = this.id;
    }
  }

  get wakeup_call(): HotelWakeupCallEntity {
    return this._wakeup_call;
  }

  set wakeup_call(wakeup_call: HotelWakeupCallEntity) {
    if (this._wakeup_call && this._wakeup_call.room_id === this.id) {
      this._wakeup_call.room_id = null;
    }
    this._wakeup_call = wakeup_call;
    if (wakeup_call) {
      wakeup_call.room_id = this.id;
    }
  }

  get last_wakeup_call(): HotelWakeupCallEntity {
    return this._last_wakeup_call;
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    // this.room_state_dt = this.room_state_dt && parseDateTime(this.room_state_dt);
    this.last_checkin_dt = this.last_checkin_dt && parseDateTime(this.last_checkin_dt);
  }

  fetchRelatedData(): void {
    this._sip_phone = EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository').getFirstObjectByHotelRoomId(this.id);
    this._wakeup_call = EntityManager.getRepository<HotelWakeupCallRepository>('HotelWakeupCallRepository').getFirstObjectByHotelRoomId(this.id);
    this._last_wakeup_call = EntityManager.getRepository<HotelWakeupCallRepository>('HotelWakeupCallRepository').getLastWakeupCallHotelRoomId(this.id);
  }
}
