<mat-progress-spinner *ngIf="isLoading"></mat-progress-spinner>
<flex-select *ngIf="!isLoading" [placeholder]="placeholder" 
              [formControl]="flexSelect"
              [itemList] = "hotelRoomList"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              [multiple]="false">
  <ng-template #itemTemplate let-room="item" let-isTruncated="truncate">
    <app-text-truncate *ngIf="isTruncated;else NotTruncate" [text]="room.display_room_number"></app-text-truncate>
    <ng-template #NotTruncate>{{ room.display_room_number }}</ng-template>
  </ng-template>
</flex-select> 
