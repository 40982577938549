import {
  Component,
  OnInit,
  ElementRef,
  Optional,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import { DialogService, FlexMatInputBase, IFlexDialogConfig, regexSearch } from '@wephone-utils';
import { FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { UserRepository } from '@wephone-core/model/repository/user';
import { GroupAddUserComponent } from '@wephone/modals/group-add-user/group-add-user.component';
import * as _ from 'lodash';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { PermissionService } from '@wephone/services/permission.service';

@Component({
  selector: 'group-user-list',
  templateUrl: './group-user-list.component.html',
  styleUrls: ['./group-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupUserListComponent extends FlexMatInputBase implements OnInit, OnChanges, MatFormFieldControl<number[]> {
  @Input() title?: string;
  @Input() directUsers?: UserEntity[];
  @Input() excludedUsers?: UserEntity[];
  userList: UserEntity[] = [];
  users: UserEntity[] = [];
  filterString: string;
  newAddedUsers: UserEntity[] = [];
  form: FormGroup;
  private initialized = false;

  constructor(
    elRef: ElementRef,
    @Optional() ngControl: NgControl,
    private readonly dialogService: DialogService,
    cdr: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly permissionService: PermissionService,
  ) {
    super(ngControl, elRef, cdr);
  }

  ngOnChanges(changes: any): void {
    console.log('changes', changes);
    this.setUserList();
    if (('directUsers' in changes || 'excludedUsers' in changes) &&
      this.initialized) {
      this.updateLayout();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initialized = true;

    this.form = this.fb.group({
      filterString: []
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(this.onFormValueChange)
    );

    this.userList = this.em.getRepository<UserRepository>('UserRepository').getObjectList();
    this.setUserList();
  }

  get controlType(): string {
    return 'flex-group-user-list-form-input';
  }

  canChangeUser(user: UserEntity): boolean {
    return this.permissionService.canChangeUserFromGroup(user);
  }

  private onFormValueChange = (formValues): void => {
    this.filterString = formValues.filterString && formValues.filterString.trim() || '';
    this.setUserList();
  }

  setUserList(): void {
    if (!this.value) {
      this.users = [];
      return;
    }

    const filterValue = this.filterString;
    this.users = this.userList && this.userList.filter(u =>
      this.value.includes(u.id) &&
      (!filterValue || 
        filterValue && regexSearch(u.name.toLowerCase(), filterValue)
      )
    ) || [];
  }

  // get users(): UserEntity[] {
  //   if (!this.value) {
  //     return [];
  //   }

  //   const filterValue = _.trim(this.searchText);
  //   return this.userList.filter(u =>
  //     this.value.includes(u.id) &&
  //     (!filterValue || 
  //       filterValue && regexSearch(u.name.toLowerCase(), filterValue)
  //     )
  //   );
  // }

  isDirectUser(user: UserEntity): boolean {
    if (this.newAddedUsers.findIndex(i => i.id === user.id) > -1) {
      return true;
    }

    return !this.directUsers || this.directUsers && this.directUsers.find(u => u.id === user.id) ? true : false;
  }

  addUserToGroup = (users: UserEntity[]): void => {
    const userIds = [];
    for (const u of users) {
      const added = this.newAddedUsers.find(au => au.id === u.id);
      if (!added) {
        this.newAddedUsers.push(u);
      }
      if (!this.value.includes(u.id)) {
        userIds.push(u.id);
      }
    }

    if (!_.isEmpty(userIds)) {
      this.value = this.value.concat(userIds);
      this.onChange();
    }

    this.setUserList();
    this.updateLayout();
  }

  openDialogAddUser(): void {
    const modalConfig: IFlexDialogConfig = {
      data: {
        userIds: _.cloneDeep(this.value || []),
        excludedUserIds: _.isEmpty(this.excludedUsers) ? [] : this.excludedUsers.map(x => x.id),
        onAddToGroup: this.addUserToGroup
      }
    };

    this.dialogService.openDialog2(GroupAddUserComponent, modalConfig);
  }

  removeUser(user: UserEntity): void {
    const i: number = this.value.indexOf(user.id);
    if (i > -1) {
      const newAddedIndex = this.newAddedUsers.findIndex((u: UserEntity) => u.id === user.id);
      if (newAddedIndex > -1) {
        this.newAddedUsers.splice(newAddedIndex, 1);
      }

      this.value.splice(i, 1);
      this.onChange();
      this.setUserList();
      this.updateLayout();
    }
  }

  private updateLayout(): void {
    // this.cdr.detectChanges();
    this.detectChanges();
  }
}
