import * as _ from 'lodash';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';

import { OutcallCampaignItemEntity } from '@wephone-core/model/entity/outcallcampaignitem';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Colors, DialogActionButton, DynamicFilterSource, FlexTableComponent, IFlexTableConfig, regexSearch } from '@wephone-utils';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { ParticularAgent } from '@wephone-core/model/repository/outcallcampaign.i';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { AgentRepository } from '@wephone-core/model/repository/agent';

@Component({
  selector: 'app-outcallcampaign-view-agents',
  templateUrl: './outcallcampaign-view-agents.component.html',
  styleUrls: ['./outcallcampaign-view-agents.component.scss']
})
export class OutcallcampaignViewAgentsComponent extends DialogComponentBase implements OnInit {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;
  dialogLeftActions: DialogActionButton[] = [];
  dialogRightActions: DialogActionButton[] = [];

  dialogTitle = _tk('call_queue_edit.view_particular_agents');
  campaign: OutcallCampaignEntity;
  dataSource: DynamicFilterSource;
  dataNotifierSource: Subject<ParticularAgent[]>;
  tableConfig: IFlexTableConfig;
  filterString: string;
  form: FormGroup;
  campaignRepo: OutcallCampaignRepository;
  agents: { [key: string]: AgentEntity } = {};

  constructor(
    dialogRef: MatDialogRef<OutcallcampaignViewAgentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      campaign: OutcallCampaignEntity
    },
    protected em: EntityManager,
    private fb: FormBuilder,
  ) {
    super(dialogRef);
    this.campaign = data.campaign;
    this.campaignRepo = this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository');

    this.dataNotifierSource = new Subject<ParticularAgent[]>();
    this.dataSource = new DynamicFilterSource(this.dataNotifierSource, this.filterFunc);

    this.tableConfig = {
      enableFilter: true,
      columns: [
        {
          name: 'agent_id',
          label: _tk('campaign.particular_agents.agent'),
          sortable: true,
          customSortFn: (item: ParticularAgent) => {
            return this.agents[item.agent_id].name;
          }
        },
        {
          name: 'remaining',
          label: _tk('campaign.particular_agents.remaining_calls'),
          sortable: true
        },
        {
          name: 'total',
          label: _tk('campaign.particular_agents.total_calls'),
          sortable: true
        },
      ],
      listActions: {
        defaultActions: [
          {
            id: 'resfesh',
            icon: 'cached',
            callback: () => {
              return this.refreshList();
            }
          },
        ]
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });
    this.form.valueChanges.subscribe(this.onFormValueChage);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    const agents = this.em.getRepository<AgentRepository>('AgentRepository').getObjectList();
    for (const agent of agents) {
      this.agents[agent.id] = agent;
    }

    await this.loadDS();
  }

  onFormValueChage = (formValues): void => {
    this.filterString = (formValues.filterString || '').trim();
    this.applyFilter();
  }

  applyFilter = () => {
    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  filterPredicate(item: ParticularAgent, filterString: string): boolean {
    if (!filterString) {
      return true;
    }

    return this.agents[item.agent_id] && regexSearch(this.agents[item.agent_id].name, filterString);
  }

  async refreshList(): Promise<void> {
    this.dataNotifierSource.next([]);
    await this.loadDS();
  }

  filterFunc = (item: ParticularAgent): boolean => {
    return this.filterPredicate(item, this.filterString);
  }

  async loadDS(): Promise<void> {
    let data: ParticularAgent[] = await this.campaignRepo.getParticularAgents(this.campaign.id);

    const defaultData = this.getDefaultData();
    data = defaultData.map(i => {
      const d = _.find(data, o => o.agent_id === i.agent_id);
      return d || i;
    });

    this.dataNotifierSource.next(data);
    this.applyFilter();
  }

  private getDefaultData(): ParticularAgent[] {
    const data = [];
    const queue = this.campaign.queue;
    if (queue) {
      let agents = queue.getAgents();
      agents = _.sortBy(agents, a => a.name);

      for (const agent of agents) {
        data.push({
          agent_id: agent.id,
          processed: 0,
          success_today: 0,
          success: 0,
          failure: 0,
          remaining: 0,
          total: 0
        });
      }
    }

    return data;
  }

  close(result?): void {
    this.dismiss(result);
  }
}
