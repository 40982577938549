import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { IEntity, IRepository } from '@wephone-core/model/model.interface';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
// import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import * as _ from 'lodash';

export enum EntityEventType {
  NEW = 'new',
  UPDATE = 'update',
  REMOVE = 'remove'
}

export class EntityEvent {
  constructor(public type: EntityEventType, public entity: IEntity) { }
}

export class BaseRepository implements IRepository {
  private static instance: IRepository;
  ENTITY_CLASS: any;
  protected RELATED_REPOSITORIES: string[] = [];
  protected URL_PREFIX = '';
  protected METHOD_PATH_UPDATE = 'update';
  protected METHOD_PATH_UPDATE_ATTRS = 'update_attrs';
  protected METHOD_PATH_UPDATE_ATTRS_BULK = 'update_attrs_bulk';
  protected METHOD_PATH_NEW = 'add';
  protected METHOD_PATH_NEW_ATTRS = 'add_attrs';
  protected METHOD_PATH_DELETE = 'delete';
  protected USE_PUSH_API = false;

  protected object_list: any[] = new Array<any>();
  protected _data_loaded = false;
  private d_wait_push_api: Deferred<any>;
  private waiting_object_ids: any;
  private on_update_data_callback;
  event$: Observable<EntityEvent>;
  private eventObserver: Observer<EntityEvent>;
  private _dataSource: BehaviorSubject<IEntity[]>;

  static getInstance<T extends IRepository = BaseRepository>(): T {
    return this.instance as T;
  }

  constructor() {
    this.d_wait_push_api = new Deferred();
    const oldInstance = this.constructor['instance'];
    if (oldInstance && oldInstance === this) {
      console.error('Repository get created more than once', this.ENTITY_CLASS.object_type_id);
      throw new Error('Repository get created more than once ' + this.ENTITY_CLASS.object_type_id);
    }

    this.constructor['instance'] = this;

    this.event$ = Observable.create(observer => {
      this.eventObserver = observer;
    })
      .pipe(share());
    this._dataSource = new BehaviorSubject<IEntity[]>([]);
  }

  dataSource<T extends IEntity = any>(): Observable<T[]> {
    return this._dataSource.asObservable() as Observable<T[]>;
  }

  get object_type_id(): string {
    return this.ENTITY_CLASS.object_type_id;
  }

  usePushAPI = () => {
    return EntityManager.getInstance().hasPushAPI() && this.USE_PUSH_API;
  }

  sendEvent(event: EntityEventType, entity: IEntity): void {
    if (this.eventObserver) {
      this.eventObserver.next(new EntityEvent(event, entity));
    }
  }

  protected get data_loaded(): boolean {
    return this._data_loaded;
  }

  setObjectList(obj_list: any[]): void {
    this.object_list = obj_list;
    this._dataSource.next(this.object_list);
  }

  wait_for_object_id(object_id: number): Promise<any> {
    if (!this.waiting_object_ids) {
      this.waiting_object_ids = {};
    }
    if (!this.USE_PUSH_API) {
      return this.findById(object_id, false);
    }
    if (object_id in this.waiting_object_ids) {
      return this.waiting_object_ids[object_id].promise;
    }
    for (const obj of this.getObjectList()) {
      if (obj.id === object_id) {
        return Promise.resolve(obj);
      }
    }
    this.waiting_object_ids[object_id] = new Deferred();
    return this.waiting_object_ids[object_id].promise;
  }

  /**
   * @return: a promise, whose result contains the list of item belonging to the current enterprise.
   */
  findAll(use_cache: boolean = true): Promise<any[]> {
    return this.findAllItems(use_cache);
  }

  findById(object_id: number, use_cache: boolean = true): Promise<any> {
    return this.findAllItems(use_cache).then(object_list => {
      return this.getObjectById(object_id);
    });
  }

  findAllItems(use_cache: boolean = true, filters?: any): Promise<any[]> {
    if (this.data_loaded && use_cache && filters === undefined) {
      // Only use cache if the is no filter
      return Promise.resolve(this.object_list);
    }

    return this._getRemoteData(use_cache, filters);
  }

  private _getRemoteData(use_cache: boolean, filters?: any): Promise<any[]> {
    return this.getRemoteData(use_cache, filters).then(object_list => {
      this.reloadRelatedData();
      return object_list;
    });
  }

  // API for push api
  loadObjectList(): Promise<any[]> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }

    if (this.usePushAPI()) {
      return EntityManager.getInstance().waitForPushedConfig().then(data => {
        EntityManager.getInstance().watchRepository(this.object_type_id);
        return this.d_wait_push_api.promise.then(resp_data => {
          return this.object_list;
        });
      });
    }

    return this.findAll();
  }

  getObjectList(): any[] {
    if (!this.data_loaded && EntityManager.getInstance().isDataReady() && this.usePushAPI()) {
      console.log('getObjectList got called while data  is not ready yet', this.object_type_id);
    }
    return this.object_list.filter(x => this.filterEntity(x));
  }

  getObjectById<T>(id: number | string): any {
    if (!id) {
      return;
    }
    // Use function getObjectList() instead of using directly object_list so that subsclass can overwrite this function to get object list from other repositories
    const obj_list = this.getObjectList();
    if (obj_list) {
      // DO not use '===' here because sometime we get id as string
      return obj_list.find(x => x.getId() == id);
    }
    if (EntityManager.getInstance().isDataReady()) {
      // console.warn("getObjectById: cannot find object or not have necessary permission ", this.object_type_id, id);
    }
    return;
  }

  getObjectListByIds(id_list: number[]): any[] {
    if (_.isEmpty(id_list)) {
      return [];
    }
    const result = this.getObjectList().filter(obj => id_list.includes(obj.getId()));
    if (EntityManager.getInstance().isDataReady() && id_list.length > result.length) {
      console.error('getObjectListByIds: not all objects are found', this.object_type_id, id_list);
    }
    return result;
  }

  setDataAsReady(): void {
    // This method need to be public because it is called inside ObjectGroupRepository to mark SkillGroupRepository and UserGroupRepository as ready!
    this._data_loaded = true;
  }

  invalidateCache(): void {
    this._data_loaded = false;
    if (this.object_list) {
      this.object_list.length = 0;
    }
  }

  protected getBaseUrl(): string {
    return ConfigManager.getInstance().getBaseURL();
  }

  protected getUrl(suffix): string {
    return FlexIvrSettings.getInstance().getAbsoluteUrl(joinURL(this.getBaseUrl(), this.URL_PREFIX, suffix));
  }

  protected getUrlV2(suffix): string {
    return FlexIvrSettings.getInstance().getAbsoluteWSv2URL(joinURL(this.getBaseUrl(), this.URL_PREFIX, suffix));
  }

  protected getURLAction(action: string): string {
    switch (action) {
      case 'UPDATE':
        return this.getUrl(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrl(this.METHOD_PATH_UPDATE_ATTRS);
      case 'UPDATE_ATTRS_BULK':
        return this.getUrl(this.METHOD_PATH_UPDATE_ATTRS_BULK);
      case 'ADD':
        return this.getUrl(this.METHOD_PATH_NEW);
      case 'ADD_ATTRS':
        return this.getUrl(this.METHOD_PATH_NEW_ATTRS);
      case 'DELETE':
        return this.getUrl(this.METHOD_PATH_DELETE);
      default:
        throw new Error('Cannot get URL for action ' + action);
    }
  }

  protected getRemoteData(use_cache = true, filters?: any): Promise<any[]> {
    if (this.usePushAPI()) {
      if (EntityManager.getInstance().isDataReady()) {
        return Promise.resolve(this.object_list);
      }

      return EntityManager.getInstance().waitForPushedConfig().then(data => {
        return this.object_list;
      });
    }

    // let d: any;
    // let eid = ConfigManager.getInstance().getCurrentEnterpriseId();
    const d = ConfigManager.getInstance().get_config(this.object_type_id, true, use_cache);
    return d.then(object_list_data => {
      this.setDataAsReady();

      // this.setObjectList(object_list_data || new Array<any>()); // Not update here because responsed data is empty due to v2 changed
      return this.object_list;
    });
  }

  protected filterEntity(entity: IEntity): boolean {
    return true;
  }

  removeObjectById(object_id): void {
    const removed_obj = this.removeItemFromListById(object_id, this.object_list);
    if (removed_obj) {
      this.onObjectRemoved(removed_obj[0]);
      this._dataSource.next(this.object_list);
    }
  }

  mergeUpdatedObjectList(updated_objects, fetch_related_data = false): void {
    if (updated_objects) {
      let found;
      for (const new_obj_data of updated_objects) {
        if (this.ENTITY_CLASS.object_type_id !== 'enterprise' && new_obj_data.id === undefined && this.object_list.length > 1) {
          console.error('Object id is undefined', new_obj_data);
        }
        found = false;
        for (const obj of this.object_list) {
          // Check domain instead of ID because in case of logged's role is Admin because no enterprise's ID responsed
          if (this.ENTITY_CLASS.object_type_id !== 'enterprise' && obj.getId() === new_obj_data['id']
            || this.ENTITY_CLASS.object_type_id === 'enterprise' &&
            (!new_obj_data['id'] && obj.domain === new_obj_data['domain'] || obj.getId() === new_obj_data['id'])
          ) {
            obj.setObjectData(new_obj_data, fetch_related_data);
            this.onObjectUpdated(obj);
            found = true;
            break;
          }
        }
        if (!found) {
          const new_obj = this.create(new_obj_data, fetch_related_data);
          this.object_list.push(new_obj);
          if (this.waiting_object_ids && new_obj.getId() in this.waiting_object_ids) {
            const p = this.waiting_object_ids[new_obj.getId()];
            delete this.waiting_object_ids[new_obj.getId()];
            p.resolve(new_obj);
          }
          this.onObjectAdded(new_obj);
        }
      }
      this._dataSource.next(this.object_list);
    }
  }

  updateWithRemoteData(push_data, fetch_related_data = false): void {
    const id_list = push_data['id_list'];
    const updated_list = push_data['object_list'];
    if (id_list) {
      for (let i = this.object_list.length - 1; i >= 0; i--) {
        if (id_list.indexOf(this.object_list[i].id) < 0) {
          const removed_obj = this.object_list.splice(i, 1)[0];
          this.onObjectRemoved(removed_obj);
        }
      }
    }

    this.mergeUpdatedObjectList(updated_list, fetch_related_data);

    if (!this.data_loaded) {
      this.setDataAsReady();
      this.d_wait_push_api.resolve(this.object_list);
    }

    // Note: It is important to keep the order of the next 3 functions calls
    // this.reloadRelatedData(): update data of this object from related repository
    // this.onRemoteDataUpdated(): Callback that notify that this object is updated
    // this.updateRelatedRepositories(): Update related repository with data from this object
    if (fetch_related_data) {
      this.reloadRelatedData();
      this.onRemoteDataUpdated();
      this.updateRelatedRepositories();
      if (this.on_update_data_callback) {
        this.on_update_data_callback(this.object_list);
      }
    }
  }

  protected setRemoteDataUpdatedCallback(fnCallback): void {
    this.on_update_data_callback = fnCallback;
  }

  onRemoteDataUpdated(): void { }

  protected onObjectRemoved(obj: IEntity): void {
    this.sendEvent(EntityEventType.REMOVE, obj);
  }

  protected onObjectUpdated(obj: IEntity): void {
    this.sendEvent(EntityEventType.UPDATE, obj);
  }

  protected onObjectAdded(obj: IEntity): void {
    this.sendEvent(EntityEventType.NEW, obj);
  }

  updateRelatedRepositories(): void {
    for (const related_repo of this.RELATED_REPOSITORIES) {
      const repo = EntityManager.getInstance().getRepositoryById(related_repo);
      if (repo) {
        repo.reloadRelatedData();
      } else {
        console.warn('Not support repository', related_repo);
      }
    }
  }

  reloadRelatedData(): void {
    if (!this._data_loaded) {
      return;
    }
    if (!this.object_list) {
      return;
    }
    for (const obj of this.object_list) {
      obj.fetchRelatedData();
    }
  }

  reload(filters?: any): Promise<any[]> {
    return this._getRemoteData(false, filters);
  }

  create(object_data?: any, fetch_related_data = false): IEntity {
    const ret = new this.ENTITY_CLASS();
    if (object_data) {
      ret.setObjectData(object_data, fetch_related_data);
    }

    return ret;
  }

  createAndSave(object_data?: any, extra_data?: any): Promise<any> {
    const obj = this.create(object_data);
    return this.save(obj, extra_data);
  }

  clone(source: IEntity): IEntity {
    const ret: IEntity = new this.ENTITY_CLASS();
    ret.setObjectData(source);
    return ret;
  }

  cloneDeep(source: IEntity): IEntity {
    const ret: IEntity = new this.ENTITY_CLASS();
    ret.setObjectData(_.cloneDeep(source));
    return ret;
  }

  /**
   * Merge 2 entity object, usually use in case of reverting from error updating entity object
   */
  merge(destination: IEntity, source: IEntity): IEntity {
    destination.setObjectData(source);
    return destination;
  }

  cloneList(source_list: IEntity[]): IEntity[] {
    const ret: IEntity[] = new Array<IEntity>();
    for (const e of source_list) {
      ret.push(this.clone(e));
    }
    return ret;
  }

  save(item: IEntity, extra_data?: { [id: string]: any }, attr_group?: string): Promise<any> {
    const action: string = item.getId() ? 'UPDATE' : 'ADD';
    const url = this.getURLAction(action);
    const post_data = item.dumpObjectData(undefined, true);
    if (extra_data) {
      _.extend(post_data, extra_data);
    }
    const field_list = [];
    for (const k of Object.keys(post_data)) {
      if (k !== 'eid') {
        field_list.push(k);
      }
    }
    post_data['__field_list__'] = field_list;
    return HttpEngine.getInstance().postJson(url, post_data);
  }

  saveAttrsBulk(ids: number[], item: IEntity, attr_list: string[], extra_data?: { [id: string]: any }): Promise<any> {
    if (!(attr_list instanceof Array)) {
      throw new Error(`Attribute 'attr_list' must be an array`);
    }
    const action = 'UPDATE_ATTRS_BULK';
    const url = this.getURLAction(action);
    let item_data = item.dumpObjectData(attr_list, true);
    if (extra_data) {
      item_data = _.extend(item_data, extra_data);
    }

    const post_data = {};
    for (const attr of attr_list) {
      post_data[attr] = item_data[attr];
    }
    post_data['ids'] = ids;
    post_data['__field_list__'] = attr_list;

    return HttpEngine.getInstance().postJson(url, post_data);
  }

  saveAttrs(item: IEntity, attr_list: string[], extra_data?: { [id: string]: any }): Promise<any> {
    if (!(attr_list instanceof Array)) {
      throw new Error('Attribute \'attr_list\' must be an array');
    }
    const action: string = item.getId() ? 'UPDATE_ATTRS' : 'ADD_ATTRS';
    const url = this.getURLAction(action);
    let item_data = item.dumpObjectData(attr_list, true);
    if (extra_data) {
      item_data = _.extend(item_data, extra_data);
    }

    const post_data = {};
    for (const attr of attr_list) {
      post_data[attr] = item_data[attr];
    }
    if (item_data.id) {
      post_data['id'] = parseInt(item_data.id);
    }
    post_data['__field_list__'] = attr_list;

    return HttpEngine.getInstance().postJson(url, post_data);
  }

  update(item: IEntity, extra_data?: { [id: string]: any }): Promise<any> {
    if (!item.getId()) {
      throw new Error('Cannot update item without key');
    }
    return this.save(item, extra_data);
  }

  protected removeItemFromListById(item_id, item_list): any {
    for (let i = 0; i < item_list.length; i++) {
      if (item_list[i].getId() === item_id) {
        const item = item_list.splice(i, 1);
        return item;
      }
    }
  }

  delete(item: IEntity, manual_remove = true): Promise<any> {
    const url = this.getURLAction('DELETE');
    const id: number = item.getId();
    return HttpEngine.getInstance().postJson(url, { ids: [id] }).then(ret => {
      this.removeObjectById(id);
      // update related repository
      this.updateRelatedRepositories();
      return ret;
    });
  }

  /**
   * @params items: deleted items
   * @params manual_remove: Manual remove in list
   */
  bulkDelete(items: IEntity[], manual_remove = true): Promise<any> {
    const ids: number[] = items.map(x => x.getId());
    const url = this.getURLAction('DELETE');
    return HttpEngine.getInstance().postJson(url, { ids }).then(ret => {
      if (manual_remove) {
        for (const i of ids) {
          this.removeObjectById(i);
        }
      }
      return ret;
    });
  }

  postUpdateRequest(post_url: string, params: any): Promise<any> {
    return HttpEngine.getInstance().postFormData(post_url, params);
  }
}
import { EntityManager } from '@wephone-core/service/entity_manager';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { Deferred } from 'ts-deferred';
import { joinURL } from '@wephone-utils/utils/url-util';
