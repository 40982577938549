import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { DialogService, EditingComponent, IFlexSelectOptions, regexSearch, ToastService } from '@wephone-utils';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { _tk, _ti } from '@wephone-translation';
import { EnterpriseSipGatewayEntity } from '@wephone-core/model/entity/enterprise_sip_gateway';
import { EnterpriseSipGatewayRepository } from '@wephone-core/model/repository/enterprise_sip_gateway';
import { EnterpriseSipGatewayService } from '@wephone-common/service';
import { IEnterpriseGatewayConfig, IEnterpriseGatewayFirmwareObject, IEnterpriseGatewayOption } from '@wephone-core/model/entity/enterprise_sip_gateway.i';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ShowGatewayProvisioninkModalComponent } from '@wephone/modals/show-gateway-provisioning-link-modal/show-gateway-provisioning-link-modal.component';

export interface IPortConfigData {
  port: number;
  data?: SipPhoneEntity;
}

@Component({
  selector: 'app-edit-enterprise-sip-gateway',
  templateUrl: './edit-enterprise-sip-gateway.component.html',
  styleUrls: ['./edit-enterprise-sip-gateway.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditEnterpriseSipGatewayComponent extends EditingComponent implements OnInit, AfterViewInit {

  @Input() editingItem: EnterpriseSipGatewayEntity;

  tabSelectedIndex: number;
  sipGateway: EnterpriseSipGatewayEntity;
  form: FormGroup;
  modelList: IEnterpriseGatewayOption[] = [];
  firmwareObject: IEnterpriseGatewayFirmwareObject = {};
  sipPhoneList: SipPhoneEntity[];
  portConfigGroupList: IPortConfigData[][] = [];
  filterSipPhone = new FormControl('');

  private readonly sipGatewayRepo: EnterpriseSipGatewayRepository = EnterpriseSipGatewayRepository.getInstance<EnterpriseSipGatewayRepository>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly enterpriseSipGatewayService: EnterpriseSipGatewayService,
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.sipGateway = _.cloneDeep(this.editingItem);
    await this.resovelData();
    this.buildPortConfigData();

    this.initFormGroup();

    if (!this.tabSelectedIndex) {
      this.tabSelectedIndex = 0;
    }
  }

  ngAfterViewInit(): void {
    this.detechChange();
  }

  private async resovelData(): Promise<any> {
    this.sipPhoneList = EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository').getObjectList();

    const sipGatwayConfig: IEnterpriseGatewayConfig = await this.enterpriseSipGatewayService.getEnterpriseGatewayConfig();
    if (sipGatwayConfig) {
      for (const model of Object.keys(sipGatwayConfig)) {
        this.modelList.push({ value: model, label: sipGatwayConfig[model].name });

        const fwVersions = sipGatwayConfig[model].fw_versions;
        const fwList: any = [];
        for (const fwVersion of Object.keys(fwVersions)) {
          fwList.push({ value: fwVersion, label: fwVersions[fwVersion] });
        }
        this.firmwareObject[model] = fwList;
      }
    }
  }

  private initFormGroup(): void {
    const portMap = this.sipGateway.port_map && _.cloneDeep(this.sipGateway.port_map) || {};
    const macAddressPattern = '^([0-9A-Fa-f:\s-])+$';
    this.form = this.fb.group({
      name: [this.sipGateway.name, [Validators.required, Validators.maxLength(100)]],
      model: [this.sipGateway.model, [Validators.required]],
      firmware: [this.sipGateway.firmware, [Validators.required]],
      mac: [this.sipGateway.mac, [Validators.required, Validators.maxLength(32), Validators.pattern(macAddressPattern)]],
      port_count: [this.sipGateway.port_count, [Validators.required, Validators.min(1), Validators.max(99)]],
      port_map: [portMap]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
        this.detechChange();
      })
    );

    this.addSubscription(
      this.filterSipPhone.valueChanges.subscribe(value => {
        this.detechChange();
      })
    );
  }

  private buildPortConfigData(): void {
    this.portConfigGroupList = [];

    const portCount = this.sipGateway.port_count;

    if (!portCount) {
      return;
    }

    const portConfigs: IPortConfigData[] = [];
    for (let i = 1; i <= portCount; i++) {
      let sipphone: SipPhoneEntity;
      if (!_.isEmpty(this.sipGateway.port_map) && this.sipGateway.port_map[i]) {
        sipphone = this.sipPhoneList.find(sp => sp.id === this.sipGateway.port_map[i]);
      }

      portConfigs.push({ port: i, data: sipphone });
    }

    let limitGroup = 16;
    if (portCount > 32) {
      limitGroup = Math.floor(portCount / 2);
      if (portCount % 2 !== 0) {
        limitGroup += limitGroup % 2 === 0 ? 2 : 1;
      }
    }

    let groupLength = Math.floor(portCount / limitGroup);
    if (portCount % limitGroup !== 0) {
      groupLength += 1;
    }

    for (let index = 0; index < groupLength; index++) {
      const startIndex = index * limitGroup;
      const groupData = portConfigs.filter((value: IPortConfigData, i: number) => {
        return (i >= startIndex && i < startIndex + limitGroup) ? true : false;
      });
      this.portConfigGroupList.push(groupData);
    }
  }

  private getFormResetData(): Object {
    const portMap = this.sipGateway.port_map && _.cloneDeep(this.sipGateway.port_map) || {};
    return {
      name: this.sipGateway.name,
      model: this.sipGateway.model,
      firmware: this.sipGateway.firmware,
      mac: this.sipGateway.mac,
      port_count: this.sipGateway.port_count,
      port_map: portMap,
    };
  }

  getChangeSet(): any {
    const data: any = super.getChangeSet();
    if ('port_map' in data) {
      for (const key of Object.keys(data.port_map)) {
        if (!data.port_map[key]) {
          delete data.port_map[key];
        }
      }
    }

    return data;
  }

  async submitForm(): Promise<any> {
    this.form.markAllAsTouched();

    try {
      const updateData = this.getChangeSet();
      const attrList = Object.keys(updateData);

      if (!this.formIsValid() || _.isEmpty(updateData)) {
        throw Error(_ti('public.message.data_invalid'));
      }

      await this.sipGatewayRepo.saveAttrs(this.sipGateway, attrList, updateData);
      this.toast.showSuccess(_ti('public.message.update_success'));

      this.sipGateway = this.sipGatewayRepo.getObjectById(this.sipGateway.id);
      this.resetForm();

    } catch (e) {
      console.error('Update Enterprise Gateway error', e);
      this.toast.showErrorMessage(e, _ti('public.message.update_failure'));
    }
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    this.form.reset(this.getFormResetData(), options);
    this.form.markAsPristine();
    this.onFormValueChange();

    this.buildPortConfigData();
    this.detechChange();
  }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.form.get(controlName).markAsDirty();
    this.form.get(controlName).setValue(null);
  }

  updateTabSelectedIndex(index: number): void {
    this.tabSelectedIndex = index;
  }

  dropSipPhoneToPort(event: CdkDragDrop<any>): void {
    if (_.isEmpty(event.container.data)) {
      console.warn('Container data not found', event.container);
      return;
    }

    if (event.previousContainer !== event.container) {
      // const portConfig = event.container.data[event.currentIndex];
      const portConfig = !_.isEmpty(event.container.data) && _.isArray(event.container.data) && event.container.data[0];
      const sipphone: SipPhoneEntity = event.item.data;

      if (portConfig.data) {
        this.dialogService.confirmDialog(
          _ti('dialogs.confirmation'),
          _ti('enterprise_sip_gateway.content.confirm_change_port_config'),
          () => {
            this.setConfigPort(portConfig, sipphone);
          }
        );
        return;
      }

      this.setConfigPort(portConfig, sipphone);
    }
  }

  clearPortConfig(portConfig: any): void {
    this.setConfigPort(portConfig, undefined);
  }

  private setConfigPort(portConfig, sipphone: SipPhoneEntity): void {
    portConfig.data = sipphone;
    const portMap: any = this.form.get('port_map').value || {};
    portMap[`${portConfig.port}`] = sipphone && sipphone.id || undefined;
    this.setPortMapControlValue(portMap);
  }

  private setPortMapControlValue(portMap: any): void {
    this.form.get('port_map').markAsDirty();
    this.form.get('port_map').setValue(portMap);
    this.detechChange();
  }

  private detechChange(): void {
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  private sipPhoneInUse(sipphone: SipPhoneEntity): boolean {
    for (const portConfigGroup of this.portConfigGroupList) {
      if (portConfigGroup.find(o => o.data && o.data.id === sipphone.id)) {
        return true;
      }
    }
    return false;
  }

  sipPhoneVisible(sipphone: SipPhoneEntity): boolean {
    const searchText = _.trim(this.filterSipPhone.value || '').toLocaleLowerCase();
    if (searchText && !regexSearch(sipphone.extension_with_owner.toLocaleLowerCase(), searchText)) {
      return false;
    }
    return !this.sipPhoneInUse(sipphone);
  }

  showProvisionLink(): void {
    this.dialogService.openDialog2(ShowGatewayProvisioninkModalComponent, { data: { sip_gateway: this.sipGateway }, size: 's' });
  }

  hiddenSipPhoneList(): boolean {
    return !this.sipPhoneList.filter(sp => {
      return this.sipPhoneVisible(sp);
    }).length;
  }
}
