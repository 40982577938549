import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, DialogService } from '@wephone-utils';
import { PaymentAttemptEntity } from '@wephone-core/model/entity/payment_attempt';
import { PaymentSourceEntity } from '@wephone-core/model/entity/payment_source';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PaymentSourceRepository } from '@wephone-core/model/repository/payment_source';
import { PaymentIntent } from '@stripe/stripe-js';
import { _ti } from '@wephone-translation';

@Injectable()
export class PaymentService {
  private readonly PAYMENT_PATH = 'payment';
  private URL_PROCESS_PAYMENT = 'payment/process_payment_attempt/';
  // private URL_PROCESS_PAYMENT_DEFAULT = '/api/payment/process_payment_attempt_with_default_card';
  private URL_PROCESS_PAYMENT_PREPAID_CREDIT = 'payment/process_payment_attempt_with_prepaid_credit';
  private URL_REQUEST_ENTERPRISE_PAYMENT = 'payment/request_enterprise_payment';
  private URL_CHANGE_LICENSE = 'payment/enterprise_change_license';
  private URL_PREPARE_BUY_DID = 'payment/request_buy_did';
  // private URL_PREPARE_PAY_INVOICE = 'payment/request_invoice_payment/:invoice_id';
  private URL_PAY_INVOICE = 'payment/make_invoice_payment/:invoice_id';
  // private URL_PAY_SUBSCRIBE_PACK = 'payment/make_payment_subscribe_pack/:subscription_pack_id';
  private URL_PREPARE_CREDIT_RECHARGE = 'payment/request_credit_recharge';
  private UPDATE_SOURCE_URL = 'payment/customer_update_source';
  private DELETE_SOURCE_URL = 'payment/customer_delete_source';

  constructor(
    private trans: TranslateService,
    private toast: ToastService,
    private readonly em: EntityManager,
    private httpEngine: HttpEngine) {
  }

  prepare_payment_attempt_update_subscription(params: PaymentAttemptEntity): Promise<any> {
    return this.httpEngine.apiPost(this.URL_CHANGE_LICENSE, params);
  }

  prepare_payment_attempt_premium(params: any): Promise<PaymentAttemptEntity> {
    return this.httpEngine.apiPost(this.URL_REQUEST_ENTERPRISE_PAYMENT, params);
  }

  // Not used anymore
  // prepare_payment_invoice_by_id(invoice_id: number): Promise<PaymentAttemptEntity> {
  //   return this.httpEngine.apiPostV2(this.URL_PREPARE_PAY_INVOICE.replace(':invoice_id', invoice_id.toString()));
  // }

  payPaymentInvoice(invoiceId: number): Promise<PaymentIntent> {
    return this.httpEngine.apiPostV2(`${this.PAYMENT_PATH}/${invoiceId}/pay`);
  }

  cancelPaymentInvoice(invoiceId: number): Promise<PaymentIntent> {
    return this.httpEngine.apiPostV2(`${this.PAYMENT_PATH}/${invoiceId}/cancel`);
  }

  // Not in-used
  // makePaymentUpdateSubscription(packId: string): Promise<PaymentAttemptEntity> {
  //   const url: string = this.URL_PAY_SUBSCRIBE_PACK.replace(':subscription_pack_id', packId);
  //   return this.httpEngine.apiPostV2(url, undefined, undefined, true);
  // }

  prepare_payment_credit_recharge(params: any): Promise<PaymentAttemptEntity> {
    return this.httpEngine.apiPost(this.URL_PREPARE_CREDIT_RECHARGE, params);
  }

  prepare_payment_attempt_buy_did(params: any): Promise<PaymentAttemptEntity> {
    return this.httpEngine.apiPost(this.URL_PREPARE_BUY_DID, params);
  }

  /******************************************************************/
  /*                           PAYMENT PROCESSCING                  */
  /******************************************************************/
  onServerReply = (ret: any): void => {
    if (ret['action'] === 'redirect') {
      window.open(ret['redirect_url'], '_self');
    } else if (ret['action'] === 'confirm') {
      this.toast.showSuccess(_ti('payment_wizard.message.payment_attempt_success'));
    } else {
      this.toast.showError(_ti('payment_wizard.message.payment_attempt_failure'));
    }
  }

  processPaymentAttempt(payment_attempt: PaymentAttemptEntity, card_info: any, pspId: string, save_card: boolean = false): Promise<any> {
    const post_url = this.URL_PROCESS_PAYMENT + pspId;

    return this.httpEngine.apiPost(post_url,
      {
        payment_attempt_id: payment_attempt.id,
        card_info,
        save_card_info: save_card ? 1 : 0,
        return_url: window.location.href
      }
    ).then(this.onServerReply);
  }

  // processPaymentAttemptDefault(payment_attempt: PaymentAttemptEntity): Promise<any> {
  //   const post_url = this.URL_PROCESS_PAYMENT_DEFAULT;

  //   return this.httpEngine.apiPost(post_url, {
  //       payment_attempt_id: payment_attempt.id
  //     }
  //   ).then(this.onServerReply);
  // }

  processPaymentAttemptPrepaidCredit(payment_attempt: PaymentAttemptEntity): Promise<any> {
    const post_url = this.URL_PROCESS_PAYMENT_PREPAID_CREDIT;

    return this.httpEngine.apiPost(post_url,
      {
        payment_attempt_id: payment_attempt.id
      }
    ).then(this.onServerReply);
  }

  updateSource(sourceId: string, type: string): Promise<any> {
    return this.httpEngine.apiPostV2(this.UPDATE_SOURCE_URL, { source_id: sourceId, type }, undefined, true);
  }

  deleteSource(): Promise<any> {
    return this.httpEngine.apiPostV2(this.DELETE_SOURCE_URL);
  }

  async mySource(): Promise<PaymentSourceEntity> {
    const repo: PaymentSourceRepository = this.em.getRepository('PaymentSourceRepository') as PaymentSourceRepository;
    const ps: PaymentSourceEntity = await repo.getMyPaymentSource() as PaymentSourceEntity;
    return ps;
  }

  getLocaleForStripe(): string {
    const availLangs = ['auto', 'da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'ja', 'no', 'nl', 'sv', 'zh'];
    const currentLang: string = this.trans.currentLang;
    return _.includes(availLangs, currentLang) ? currentLang : 'auto';
  }
}
