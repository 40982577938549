<form [formGroup]="form">
  <mat-card class="shadow-none">
    <mat-card-header>
      <div *ngIf="openingCalendarControl?.value" mat-card-avatar>
        <i class="timeline-icon material-icons">access_time</i>
      </div>
      <div class="timeline-actions">
        <button *ngIf="openingCalendarControl?.value" mat-icon-button matSuffix type="button" (click)="removeCalendar()"
          title="{{ 'public.remove'|translate }}" data-ci="btn-remove-calendar" class="mat-text-warn">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <mat-card-title fxLayoutAlign="start center" [attr.data-ci]="'selected-calendar-value'">
        <span *ngIf="openingCalendarControl?.value">{{'call_queue_edit.section.check_time'|translate}}&nbsp;</span>

        <ng-container *ngIf="openingCalendarControl?.value?.name; else selectCalendar">
          <a class="flex-link" data-ci="link-change-calendar" (click)="openDialogSelectCalendar()">
            {{ openingCalendarControl.value.name }}
          </a>
        </ng-container>
        <ng-template #selectCalendar>
          <button mat-stroked-button matSuffix type="button" color="accent" (click)="openDialogSelectCalendar()"
            data-ci="open-select-calendar">
            <mat-icon class="text-align">add</mat-icon>
            <span class="text-align">{{ 'update_queue_calendar.select_calendar'|translate }}</span>
          </button>
        </ng-template>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content *ngIf="!hideClosedConfig">
      <div class="activity-stream" [hidden]="!openingCalendarControl?.value">
        <mat-card class="shadow-none">
          <mat-card-header>
            <div mat-card-avatar><i class="timeline-icon material-icons">close</i></div>
            <mat-card-title>{{'call_queue_edit.section.if_closed'|translate}}
            </mat-card-title>
          </mat-card-header>
          <!-- <mat-card-content>
            <mat-button-toggle-group *ngIf="did" formControlName="calendar_reroute"
              (change)="calendarRerouteChange($event)" class="button-multiple-choice" fxLayout="row">
              <mat-button-toggle [title]="'call_queue.content.play_close_message'|translate" fxFlex="50" [value]="false"
                class="button-multiple-choice">
                {{'call_queue.content.play_close_message'|translate}}
              </mat-button-toggle>
              <mat-button-toggle [title]="'call_queue.content.reroute_call'|translate" fxFlex="50" [value]="true"
                class="button-multiple-choice" data-ci="calendar-reroute-call">
                {{'call_queue.content.reroute_call'|translate}}
              </mat-button-toggle>
            </mat-button-toggle-group>

            <div fxLayout="column" *ngIf="did && form.get('calendar_reroute').value">
              <call-destination fxFlex="auto" #callDestination [destinations]="destinationList" [isSubMenu]="false"
                [value]="actionNotOfficeHour" (valueChanged)="actionNotOfficeHourOnChange($event)">
              </call-destination>
              <mat-error *ngIf="!form.get('action_not_office_hour').valid">
                {{ 'public.message.data_invalid'|translate }}
              </mat-error>
            </div>
          </mat-card-content> -->
        </mat-card>
        <ng-container *ngIf="did || queue">
          <ng-container *ngTemplateOutlet="tplServiceCloseSound"></ng-container>

          <mat-card class="shadow-none">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon class="timeline-icon">directions</mat-icon>
              </div>
              <mat-card-title>
                {{ 'call_queue.content.agenda_closed_config'|translate }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="column">
                <call-destination *ngIf="did; else tplAgendaCallDestination" class="full-width -mt-2"
                  data-id="agenda_closed_config" #callDestination [destinations]="destinationList" [isSubMenu]="false"
                  [excludeDidId]="did.id" [excludeQueueId]="did.routed_queue && did.routed_queue.id"
                  [excludeIvrMenuId]="did.routed_menu && did.routed_menu.id"
                  [excludeSipPhoneId]="did.routed_sipphone && did.routed_sipphone.id"
                  [routeAppLabelObject]="{voicemail: 'call_queue_edit.content.voicemail'|translate}"
                  [voicemail]="{ voicemail_enabled: false }" [value]="actionNotOfficeHour"
                  [required]="true" (valueChanged)="actionNotOfficeHourOnChange($event)">
                </call-destination>
                <ng-template #tplAgendaCallDestination>
                  <call-destination *ngIf="queue" class="full-width -mt-2" data-id="agenda_closed_config"
                    #agendaClosedConfigDestination [destinations]="agendaClosedConfigDestinationList"
                    [excludeQueueId]="queue.id" [isSubMenu]="false"
                    [routeAppLabelObject]="{voicemail: 'call_queue_edit.content.voicemail'|translate}"
                    [voicemail]="{ voicemail_enabled: true, mailbox_id: queue.voicemail_id }"
                    [value]="queue.agenda_closed_config" [required]="true"
                    (valueChanged)="updateAgendaClosedConfig($event)">
                  </call-destination>
                </ng-template>

                <div [hidden]="did || !isVoicemailEnabled()">
                  <a [attr.data-ci]="'open-voicemail-email-setting'" class="flex-link"
                    (click)="openVoicemailByEmailSettings()">{{ 'voicemail.email_notification'|translate }}</a>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>

        <!-- <ng-container *ngIf="did && !form.get('calendar_reroute').value">
          <ng-container *ngTemplateOutlet="tplServiceCloseSound"></ng-container> -->

        <!-- <mat-card class="shadow-none">
            <mat-card-header>
              <div mat-card-avatar><i class="timeline-icon material-icons">library_music</i></div>
              <mat-card-title>{{'call_queue_edit.section.close_message'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="column">
                <sound-upload class="wrapper-sound-upload" [defaultSound]="serviceCloseSound"
                  [attr.data-ci]="'sound-upload-out-of-work'" [fileEntry]="openingHourFileCtrl.value"
                  title="{{'call_queue.content.out_of_hours_message'|translate}}" [hideRemove]="true"
                  (onChange)="updateOpeningHourFile($event)">
                </sound-upload>
              </div>
            </mat-card-content>
          </mat-card> -->

        <!-- <mat-card class="shadow-none" *ngIf="!did">
            <mat-card-header>
              <div mat-card-avatar><i class="timeline-icon material-icons">voicemail</i></div>
              <mat-card-title>{{'call_queue_edit.section.voicemail'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="column">
                <mat-slide-toggle formControlName="vm_enabled" data-ci="voicemail">
                  {{ 'call_queue_edit.content.active_voicemail' | translate }}
                </mat-slide-toggle>
                <div class="py-1" [hidden]="!vmEnabledCtrl.value">
                  <a [attr.data-ci]="'open-voicemail-email-setting'" class="flex-link"
                    (click)="openVoicemailByEmailSettings()">{{ 'voicemail.email_notification'|translate }}</a>
                </div>
              </div>
            </mat-card-content>
          </mat-card> -->

        <!-- </ng-container> -->
      </div>
    </mat-card-content>
  </mat-card>
</form>

<ng-template #tplServiceCloseSound>
  <mat-card class="shadow-none" *ngIf="isVoicemailEnabled()">
    <mat-card-header>
      <div mat-card-avatar><i class="timeline-icon material-icons">library_music</i></div>
      <mat-card-title>{{'call_queue_edit.section.close_message'|translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <sound-upload class="wrapper-sound-upload" [defaultSound]="serviceCloseSound"
          [attr.data-ci]="'sound-upload-out-of-work'" [fileEntry]="openingHourFileCtrl.value"
          title="{{'call_queue.content.out_of_hours_message'|translate}}" [hideRemove]="true"
          (onChange)="updateOpeningHourFile($event)">
        </sound-upload>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
