<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="shortcutForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
      fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterValue"
          [hintText]="'shortcut.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud [filterTemplate]="listFilterTpl" [tableConfig]="tableConfig" [dataSource]="dataSource"
    pageTitle="{{ 'menu.manage_shortcuts'| translate }}" [sidePannelOptions]="sidePannelOptions" fxLayout="row"
    fxFlex="grow"></flex-crud-page>
</div>
