import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import {
  DialogService,
  ToastService,
  EditingComponent,
  DialogActionButton,
  Colors,
  NoWhitespaceValidator,
} from '@wephone-utils';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { TreeComponent } from '@circlon/angular-tree-component';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import {
  QualificationFormDialogComponent,
  IQualFormDialogValues,
} from '@wephone/pages/qualification/dialogs/qualification-form-dialog/qualification-form-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import * as _ from 'lodash';
import { CallCenterQualificationDialog, QualificationService, ObjectUsingQualification } from '@wephone-common';
import { _tk, _ti } from '@wephone-translation';
import { DidEntity } from '@wephone-core/model/entity/did';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import { Router } from '@angular/router';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';

@Component({
  selector: 'app-qualification-edit',
  templateUrl: './qualification-edit.component.html',
  styleUrls: ['./qualification-edit.component.scss']
})
export class QualificationEditComponent extends EditingComponent implements OnInit, AfterViewInit {
  @Input() editingItem: QualificationEntity;
  formValueChanges;
  options: any;

  @ViewChild('tree') tree: TreeComponent;
  qualification: QualificationEntity;
  groups: UserGroupEntity[] = [];
  qualForm: FormGroup;
  nodes;
  treeOptions = {
    allowDrag: true,
    allowDrop: true
  };

  usedObjectList: ObjectUsingQualification[] = [];

  usedDidList: DidEntity[] = [];
  usedDidStr: string;

  usedQueueList: CallQueueEntity[] = [];
  usedQueueStr: string;

  usedCampaignList: OutcallCampaignEntity[] = [];
  usedCampaignStr: string;

  actions: DialogActionButton[];

  readonly steps = {
    form: 'opening_hour_calendar.content.information',
    inused: 'opening_hour_calendar.content.usage',
  };

  private readonly userGroupRepo: UserGroupRepository;
  private readonly qualRepo: QualificationRepository;

  constructor(
    private readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly toast: ToastService,
    private readonly em: EntityManager,
    private readonly router: Router,
    private readonly qualifService: QualificationService,
  ) {
    super();
    this.userGroupRepo = this.em.getRepository<UserGroupRepository>('UserGroupRepository');
    this.qualRepo = this.em.getRepository<QualificationRepository>('QualificationRepository');
    this.groups = this.userGroupRepo.getObjectList();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.qualification = _.cloneDeep(this.editingItem);

    this.qualForm = this.fb.group({
      name: [this.qualification.value, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]],
      group: [this.qualification.group],
    });

    this.qualForm.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });

    this.nodes = this.makeQualNodes(this.qualification.children);

    this.usedObjectList = this.qualifService.getObjectsUsingQualifications([this.qualification.id]);

    this.usedDidList = this.usedObjectList.filter(x => x instanceof DidEntity) as DidEntity[];
    this.usedDidStr = this.usedDidList.map(x => x.number).join(', ');

    this.usedQueueList = this.usedObjectList.filter(x => x instanceof CallQueueEntity) as CallQueueEntity[];
    this.usedQueueStr = this.usedQueueList.map(x => x.queue_name).join(', ');

    this.usedCampaignList = this.usedObjectList.filter(x => x instanceof OutcallCampaignEntity) as OutcallCampaignEntity[];
    this.usedCampaignStr = this.usedCampaignList.map(x => x.name).join(', ');

    this.actions = [
      {
        label: 'public.delete',
        action: () => {
          this.deleteQualif();
        },
        color: Colors.PRIMARY,
        visible: (): boolean => {
          return !!this.qualification.id;
        }
      }
    ];
  }

  get stepKeys(): string[] {
    return Object.keys(this.steps);
  }

  get nameControl(): FormControl {
    return this.qualForm.get('name') as FormControl;
  }

  ngAfterViewInit(): void {
    this.tree.treeModel.expandAll();
  }

  formHasChanged(): boolean {
    return this.qualForm.dirty;
  }

  resetForm(): void {
    this.qualForm.reset({
      name: this.qualification.value,
      group: this.qualification.group,
    });
    this.qualForm.markAsPristine();
    this.onFormValueChange();
  }

  async addFirstNode(): Promise<any> {
    try {
      const newQual = this.qualRepo.create(
        {
          value: _ti('call_qualification.content.new_qualification'),
          parent_id: this.qualification.id
        }
      ) as QualificationEntity;

      const formValues: IQualFormDialogValues = {
        qual: newQual
      };

      const result = await this.presentQualFormDialog(formValues);

      if (!result) {
        return undefined;
      }

      this.updateQualNodes();
    } catch (error) {
      console.error(error);
    }
  }

  async submitForm(): Promise<any> {
    try {
      // Check duplicate name
      if ((this.em.getRepository<QualificationRepository>('QualificationRepository'))
        .getExistQualificationName(this.nameControl.value, this.qualification.parent_id, this.qualification.id)
      ) {
        this.nameControl.setErrors({ duplicated: true });
      }

      if (this.qualForm.invalid) {
        this.qualForm.markAllAsTouched();
        this.toast.showError(_ti('form.validator.data_invalid'));
        return undefined;
      }

      const formData = this.qualForm.value;
      const extraData = {
        value: _.trim(formData.name),
        group_id: formData.group ? formData.group.id : null,
      };

      await this.qualRepo.update(this.qualification, extraData);
      this.qualification = this.qualRepo.getObjectById(this.qualification.id);
      this.resetForm();
      this.toast.showSuccess(_ti('call_qualification.message.update_success'));
    } catch (error) {
      console.error(error);
      const msg = error && error.error && error.error.message ? error.error.message : '';
      this.toast.showError(_ti('public.message.update_failure') + ` ${msg}`);
    }
  }

  protected makeQualNodes(nodes, tree = []): any[] {
    for (const node of nodes) {
      const newNode = {
        id: node.id,
        name: node.value,
        parent_id: node.parent_id,
        is_goal: node.is_goal,
        position: node.position,
        children: []
      };

      if (node.children) {
        this.makeQualNodes(node.children, newNode.children);
      }

      tree.push(newNode);
    }

    return tree.sort((a, b) => a.position - b.position);
  }

  protected nodesToList(nodes, list = [], parrentId): any[] {
    let newPosition = 0;
    for (const node of nodes) {
      newPosition += QualificationEntity.positionStep;
      const newNode = { id: node.id, parent_id: parrentId, position: newPosition };
      list.push(newNode);

      if (node.children) {
        this.nodesToList(node.children, list, node.id);
      }
    }

    return list;
  }

  updateQualNodes(): void {
    const qual = this.qualRepo.getObjectById(this.qualification.id) as QualificationEntity;
    if (qual) {
      this.nodes = this.makeQualNodes(qual.children);
    }
  }

  private async updatePositions(): Promise<any> {
    try {
      const nodeList = this.nodesToList(this.nodes, [], this.qualification.id);
      await this.qualRepo.savePositionsV3(nodeList);
      this.updateQualNodes();
    } catch (error) {
      console.error(error);
    }
  }

  private async presentQualFormDialog(formValues = {}): Promise<any> {
    const dialogConfig = {
      data: {
        formConfig: {
          editName: true,
          editGroup: false,
          editGoal: true
        },
        formValues,
      }
    };

    return this.dialogService.openDialog2(QualificationFormDialogComponent, { width: '100px', data: dialogConfig.data })
      .afterClosed().toPromise();
  }

  onUpdateData($event): void {
    $event.treeModel.expandAll();
  }

  onMoveNode($event): void {
    this.updatePositions();
  }

  async editNode($event, node): Promise<any> {
    try {
      $event.stopPropagation();
      const editQual = this.qualRepo.getObjectById(node.id) as QualificationEntity;

      const formValues: IQualFormDialogValues = { qual: editQual };
      const result = await this.presentQualFormDialog(formValues);

      if (result) {
        this.updateQualNodes();
        this.toast.showSuccess(_ti('call_qualification.message.update_success'));
      }

    } catch (error) {
      console.error(error);
    }
  }

  async toggleGoal($event, node): Promise<any> {
    try {
      $event.stopPropagation();
      const qual = this.qualRepo.getObjectById(node.id) as QualificationEntity;

      if (!qual) {
        return undefined;
      }

      qual.is_goal = 1 - qual.is_goal;
      const result = await this.qualRepo.saveAttrs(qual, ['is_goal']);

      if (!result) {
        return undefined;
      }

      this.updateQualNodes();
    } catch (error) {
      console.error(error);
    }
  }

  async appendSiblingNode($event, node): Promise<any> {
    try {
      $event.stopPropagation();
      const parentNode = node.parent.data;
      const newQual = this.qualRepo.create({
        value: _ti('call_qualification.content.new_qualification'),
        parent_id: node.data.parent_id
      }) as QualificationEntity;

      const formValues: IQualFormDialogValues = { qual: newQual };
      const result = await this.presentQualFormDialog(formValues);

      if (!result) {
        return undefined;
      }

      const newNode = {
        id: result.id,
        name: result.value,
        parent_id: result.parent_id,
        is_goal: result.is_goal,
        position: 0,
        children: []
      };

      parentNode.children.splice(node.index + 1, 0, newNode);
      this.tree.treeModel.update();
      this.updatePositions();
      this.toast.showSuccess(_ti('call_qualification.message.create_success'));
    } catch (error) {
      console.error(error);
    }
  }

  async appendChildNode($event, node): Promise<any> {
    try {
      $event.stopPropagation();
      console.log('appendChildNode: ', node);
      const currentNode = node.data;
      const newQual = this.qualRepo.create({
        value: _ti('call_qualification.content.new_qualification'),
        parent_id: currentNode.parent_id
      }) as QualificationEntity;

      const formValues: IQualFormDialogValues = {
        qual: newQual
      };

      const result = await this.presentQualFormDialog(formValues);

      if (!result) {
        return undefined;
      }

      const newNode = {
        id: result.id,
        name: result.value,
        parent_id: result.parent_id,
        is_goal: result.is_goal,
        position: 0,
        children: []
      };
      currentNode.children.push(newNode);
      this.tree.treeModel.update();
      this.updatePositions();
      this.toast.showSuccess(_ti('call_qualification.message.create_success'));
    } catch (error) {
      console.error(error);
    }
  }

  deleteNode($event, node): void {
    $event.stopPropagation();
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      () => {
        const qualification = this.qualRepo.getObjectById(node.id);
        this.qualRepo.delete(qualification).then(() => {
          this.updateQualNodes();
          this.toast.show(_ti('public.message.delete_success'));
        });
      }
    );
  }

  public testQualification(): void {
    const dialogData = {
      hasAnsweringMachine: false,
      pathQualIds: '',
      finished: false,
      root_id: this.qualification.id,
      comment: '',
      canQualify: true
    };
    this.dialogService.openDialog2(CallCenterQualificationDialog, { width: '60%', data: dialogData });
  }

  gotoEditDid(did: DidEntity): void {
    this.router.navigate(['number-routing', did.id], { skipLocationChange: false });
  }

  hasFeature(feature: string): boolean {
    return ConfigManager.getInstance().hasFeature(feature);
  }

  async deleteQualif(): Promise<void> {
    const msgs: string[] = [];
    const qualIds = [this.qualification].map(q => q.id);
    const objectInuseds = this.qualifService.getObjectsUsingQualifications(qualIds);
    const usedQueueList = objectInuseds.filter(x => x instanceof CallQueueEntity) as CallQueueEntity[];
    const usedCampaignList = objectInuseds.filter(x => x instanceof OutcallCampaignEntity) as OutcallCampaignEntity[];

    if (usedQueueList.length) {
      const usedQueues = [this.qualification].filter(q => {
        return !!usedQueueList.find(queue => queue.has_qualification && (queue.in_qualification_id === q.id || queue.out_qualification_id === q.id));
      });
      msgs.push(_ti('qualification.message.confirm_delete_with_dependent_queues', {
        dependent_queues: usedQueueList.map(x => x.queue_name).join(', '),
        objects: usedQueues.map(i => i.value),
      }));
    }

    if (usedCampaignList.length) {
      const usedCampaigns = [this.qualification].filter(q => {
        return !!usedCampaignList.find(c => {
          return c.queue && c.queue.has_qualification && (c.queue.in_qualification_id === q.id || c.queue.out_qualification_id === q.id);
        });
      });
      msgs.push(_ti('qualification.message.confirm_delete_with_dependent_campaigns', {
        dependent_campaigns: usedCampaignList.map(x => x.name).join(', '),
        objects: usedCampaigns.map(i => i.value),
      }));
    }

    if (objectInuseds.length) {
      msgs.unshift(_ti('public.message.cannot_delete_inused'));
      const msg = msgs.join('\n');
      return this.dialogService.showAlert(_ti('dialogs.warning'), msg);
    }

    return this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('user.title.delete'), () => {
      this.em.getRepository<QualificationRepository>('QualificationRepository').bulkDelete([this.qualification]).then(
        res => {
          this.close();
          if (res && res.error && res.error.message) {
            this.toast.showError(res.error.message);
            return undefined;
          }

          this.toast.showSuccess(_ti('public.message.delete_success'));
        }, e => {
          const msg = (e.error && e.error.message ? e.error.message : e.message) || '';
          this.toast.showError(_ti('public.message.delete_failure') + ` ${msg}`);
        }
      );
    });
  }

  clearValue(controlName: string): void {
    const control = this.qualForm.get(controlName);
    if (control) {
      control.markAsDirty();
      control.setValue(null);
    }
  }
}
