import { Injectable } from '@angular/core';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import * as _ from 'lodash';
import { ValidatorService } from '@wephone-utils/services/validator.service';

@Injectable()
export class CallDestinationService {
  private static _instance: CallDestinationService;

  constructor(private readonly validatorService: ValidatorService) {
    if (CallDestinationService._instance) {
      throw new Error('Error: Instantiation failed: Use CallDestinationService.getInstance() instead of new.');
    }
    CallDestinationService._instance = this;
  }

  validCallDestination(callDestination: any): any {
    const errors: any = {}; // reset errors

    if (_.isEmpty(callDestination) || !callDestination.application) {
      errors['call_application'] = 'form.validator.field_required';
      return errors;
    }

    switch (callDestination.application) {
      case RoutingAppName.call_user:
        if (!callDestination.params || !callDestination.params.id) {
          console.warn('No call_user');
          errors['call_user'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.callqueue:
        if (!callDestination.params || !callDestination.params.queue) {
          console.warn('No callqueue');
          errors['callqueue'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.remote_application:
        if (!callDestination.params || !callDestination.params.id) {
          console.warn('No remote_application');
          errors['remote_application'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.runvxmlscript:
        if (!callDestination.params || !callDestination.params.ivrscript_id) {
          console.warn('No runvxmlscript');
          errors['runvxmlscript'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.call_phone:
        if (!callDestination.params || !callDestination.params.sipphone_id) {
          console.warn('No call_phone');
          errors['call_phone'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.conference:
        if (!callDestination.params || !callDestination.params.conference) {
          console.warn('No conference');
          errors['conference'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.ivr_custom_menu:
        if (!callDestination.params || (!callDestination.params.id && !callDestination.params.ivr_custom_menu)) {
          console.warn('No ivr_custom_menu', callDestination.params);
          errors['ivr_custom_menu'] = 'form.validator.field_required';
          return errors;
        }
        // if (this.canAddSubMenu && !callDestination.params.ivr_custom_menu) {
        //     return errors;
        // }
        break;
      case RoutingAppName.ivr_custom_main_menu:
        if (!callDestination.params || !callDestination.params.id) {
          console.warn('No ivr_custom_main_menu', callDestination.params);
          errors['ivr_custom_main_menu'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.call_phone_number:
        if (!callDestination.params || !callDestination.params.number) {
          console.warn('No call_phone_number');
          errors['call_phone_number'] = 'form.validator.field_required';
          return errors;
        }

        if (!this.validatorService.isPhoneNumber(callDestination.params.number)) {
          console.warn('Phone number invalid');
          errors['call_phone_number'] = 'did.validator.phone_number_invalid';
          return errors;
        }
        break;
      case RoutingAppName.play_then_hangup:
        // Allow null as default value
        // if (!callDestination.params || !callDestination.params.sound_id) {
        //   console.warn('No play_then_hangup');
        //   errors['play_then_hangup'] = 'form.validator.field_required';
        //   return errors;
        // }
        break;
      case RoutingAppName.digitalsecretary:
        if (!callDestination.params || !callDestination.params.default_ext) {
          console.warn('No digitalsecretary');
          errors['digitalsecretary'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.to_did:
        if (!callDestination.params || !callDestination.params.did_id) {
          console.warn('No to_did');
          errors['to_did'] = 'form.validator.field_required';
          return errors;
        }
        break;
      case RoutingAppName.send_sms:
        if (!callDestination.params || !callDestination.params.sms_id) {
          console.warn('No SMS Template');
          errors['send_sms'] = 'form.validator.field_required';
          return errors;
        }
        if (!callDestination.params || !callDestination.params.sound_id) {
          console.warn('No play after send sms');
          errors['play_after_send_sms'] = 'form.validator.field_required';
          return errors;
        }
        break;
      default: break;
    }

    return errors;
  }
}
