import { Routes, Router } from '@angular/router';
import { AdminLayoutComponent, AuthLayoutComponent } from './core';
import { AuthGuardService } from '@wephone-core/service/auth_guard.service';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { MessageService } from '@wephone-utils';
import { WephoneRoutes } from '@wephone/wephone.routing';
import { AppConfigService } from 'src/app/wephone-common/service';
import { ConfigManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { EnterpriseParam } from '@wephone-core/system';
import { WsRpcService } from '@wephone-core/service/wsrpc_service';
import { VMReaderDataMsg } from '@wephone-core/model/repository/voicemail.i';

export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        resolve: {
          config: AppConfigService
        },
        component: AdminLayoutComponent,
        children: WephoneRoutes
      },
      {
        path: '**',
        redirectTo: 's/404'
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 's',
        loadChildren: () => import('../../../../src/app/wephone-session/wephone-session.module').then(m => m.WephoneSessionModule)
      }
    ]
  }
];

@Injectable()
export class RoutingService {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private messageService: MessageService,
    private configService: ConfigManager,
    private wsrpc: WsRpcService,
    private settings: FlexIvrSettings
  ) {
    this.messageService.subscribe('application:login:success', returnUrl => {
      console.log('app.routing returnUrl', returnUrl);
      const currentUrl: string = this.router.url;
      console.log('app.routing currentUrl', currentUrl);
      const subscriptionExpired: number = this.configService.getEnterpriseParamValue(EnterpriseParam.subscription_expired);
      const onBoardingDone: number = this.configService.getEnterpriseParamValue(EnterpriseParam.onboarding_done);
      // Redirect to onboarding page to show guided tour if logged role is Admin & not seen boarding guide yet
      if (this.settings.isProjectAdmin() &&
        this.authService.isAdmin() &&
        !(currentUrl && currentUrl.includes('create-from-marketplace'))
      ) {
        if (subscriptionExpired === 1) {
          this.router.navigate(['/s/subscription']);
          return;
        }

        if (onBoardingDone === 0) {
          this.router.navigate(['/s/onboarding']);
          return;
        }
      }

      this.subscribeWsMissedCall();
      this.subscribeWsVoicemail();

      this.router.navigateByUrl(returnUrl || this.authService.getDefaultReturnUrl());
    });
  }

  canActivate(): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/s/signin']);

      return false;
    }

    return true;
  }

  private subscribeWsMissedCall(): void {
    console.log('Subscribe WS MISSED_CALL_UPDATE');
    this.wsrpc.subscribe('MISSED_CALL_UPDATE', undefined,
      (data: {
        message: 'MISSED_CALL_UPDATE',
        enterprise_id?: number,
        data: {
          call_id: number,
          owner_id: number,
          recall_state: number
        }
      }) => {
        console.log('Update missed call', data);
        this.messageService.broadcastMissedCallStatus(data);
      }
    );
  }

  private subscribeWsVoicemail(): void {
    console.log('Subscribe WS VM_READER_UPDATE');
    this.wsrpc.subscribe('VM_READER_UPDATE', undefined,
      (data: VMReaderDataMsg) => {
        console.log('Update voicemail reader', data);
        this.messageService.broadcastVMReaderUpdate(data);
      }
    );
  }
}
