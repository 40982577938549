<ng-container *ngIf="border;else NoBorder">
  <button [hidden]="is_playing" mat-icon-button color="{{ !disabled ? color : '' }}"
    [ngClass]="{'border-visible': border}" (click)="play($event)" [disabled]="disabled" data-ci="play">
    <mat-icon>play_arrow</mat-icon>
  </button>
  <button [hidden]="!is_playing" data-ci="stop" type="button" mat-icon-button color="warn" (click)="stop($event)">
    <mat-icon>stop</mat-icon>
  </button>
</ng-container>

<ng-template #NoBorder>
  <button [hidden]="is_playing" data-ci="play" type="button" mat-icon-button mat-icon-button
    color="{{ !disabled ? color : '' }}" (click)="play($event)" [disabled]="disabled">
    <mat-icon>play_arrow</mat-icon>
  </button>
  <button [hidden]="!is_playing" data-ci="stop" type="button" mat-icon-button color="warn" (click)="stop($event)">
    <mat-icon>stop</mat-icon>
  </button>
</ng-template>
