// import * as _ from 'lodash';
import {
  Component,
  OnInit,
  OnDestroy,
  Self,
  Optional,
  ElementRef,
  Input
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';

@Component({
  selector: 'flex-select-enterprise-crm-input',
  templateUrl: './flex-select-enterprise-crm-input.component.html',
  styleUrls: ['./flex-select-enterprise-crm-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectEnterpriseCrmFormInput }]
})
export class FlexSelectEnterpriseCrmFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<EnterpriseCrmEntity> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  enterpriseCrmList: EnterpriseCrmEntity[];
  flexSelectOptions: IFlexSelectOptions;

  @Input() isConfigured: boolean;
  @Input() isAuthenticated: boolean;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private readonly em: EntityManager,
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: EnterpriseCrmEntity, filterString) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: EnterpriseCrmEntity, b: EnterpriseCrmEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setEnterpriseCrmList();
  }

  /**
   * Hard reload list after create new item
   */
  setEnterpriseCrmList(): void {
    this.enterpriseCrmList = this.em.getRepository('EnterpriseCrmRepository').getObjectList().filter(crm => crm.isConfigured);
    if (this.isAuthenticated) {
      this.enterpriseCrmList = this.enterpriseCrmList.filter(crm => crm.is_authenticated);
    }
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  get controlType(): string {
    return 'enterprise-crm-select-form-input';
  }
}
