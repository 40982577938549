<div fxLayout="column" class="pa-1">
    <div *ngIf="item.analysis_state === 2">
        <strong>{{ 'call_queue.content.analysis.title'|translate }}</strong>
        <table class="flex-table-info full-width" *ngIf="analysisResult">
            <tr>
                <th>{{ 'public.key_label'|translate }}</th>
                <th>{{ 'public.value'|translate }}</th>
            </tr>

            <tr *ngFor="let key of getKeyAnalysis()">
                <td>{{ key }}</td>
                <td>{{ analysisResult.json_result[key] }}</td>
            </tr>

            <tr *ngFor="let key of getKeyAnalysisUuid()">
                <td>{{ key }}</td>
                <td>{{ analysisResult[key] }}</td>
            </tr>
        </table>
    </div>

    <div class="mt-1" *ngIf="item.transcription_state === 2">
        <strong>{{ 'call_queue.content.transcription'|translate }}</strong>
        <p class="mt-0 transcription-text" [innerHTML]="transcriptionText"></p>
    </div>
</div>
