import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '@wephone-utils/services/cookie.service';
import { ITranslationService, TranslationLocale } from './translation-types';
import { setTranslationServiceInstance } from './static-methods';

@Injectable()
export class TranslationService implements ITranslationService {
  static availableLangs: TranslationLocale[] = [
    {code: 'en', label: 'English', country: 'gb'},
    {code: 'fr', label: 'Français', country: 'fr'},
    {code: 'pt_BR', label: 'Portugues do Brasil', country: 'br'},
    {code: 'vi', label: 'Tiếng Việt', country: 'vn'},
  ];
  private static instance: TranslationService;
  static cookieName = 'flex_locale';
  static defaultLang = 'en';

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService
  ) {
    if (TranslationService.instance) {
      throw new Error(`Cannot initialize ${this.constructor.name} twice`);
    }
    TranslationService.instance = this;
    setTranslationServiceInstance(this);
  }

  static getInstance(): TranslationService {
    return TranslationService.instance;
  }

  get availableLangs(): TranslationLocale[] {
    return TranslationService.availableLangs;
  }

  /**
   * Prior set according to cookie, if cookie not exist, use browser language
   * Otherwise, use default language
   * Was set in app.component when not login yet
   */
  async setDefaultLang(): Promise<void> {
    let lang: string = await this.cookieService.get(TranslationService.cookieName);
    if (!lang) {
      lang = this.translate.getBrowserLang();
    }

    if (!this._supportLang(lang)) {
      lang = TranslationService.defaultLang;
    }

    this.translate.setDefaultLang(lang);
    this.setAppLanguage(lang);
  }

  private _supportLang(language: string): boolean {
    return this.availableLangs.find(x => x.code === language) ? true : false;
  }

  setAppLanguage(language: string): void {
    if (!this._supportLang(language)) {
      throw new Error(`Language '${language}' is not supported`);
    }
    // store in cookie
    this.cookieService.set(TranslationService.cookieName, language);
    this.translate.use(language);
    this.translate.setDefaultLang(language);
  }

  getCurrLanguage(): TranslationLocale {
    const language = this.translate.currentLang || TranslationService.defaultLang;
    return this.availableLangs.find(x => x.code === language);
  }

  getCurrentLocale(): string {
    const transService = this.translate;
    const language = transService && transService.currentLang || 'fr';
    return this.getLocaleByLanguage(language);
  }

  getLocaleByLanguage(language: string): string {
    return language === 'pt_BR' ? 'pt' : language;
  }

  get(key): Promise<string> {
    return this.translate.get(key).toPromise();
  }

  instant(key: string, params?: any): string {
    return this.translate.instant(key, params);
  }
}
