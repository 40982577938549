import { Component, OnInit } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IFlexDialogConfig, DialogActionButton, DialogService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { UserEntity } from '@wephone-core/model/entity/user';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangeUserPasswordModal } from '@wephone/modals/change-user-password/change-user-password';

@Component({
  selector: 'user-profile',
  templateUrl: 'user-profile.html',
  styleUrls: ['user-profile.scss']
})
export class UserProfileDialog extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    hasBackdrop: true,
    size: 'xs',
  };

  dialogTitle = _tk('menu.manage_profile');
  dialogRightActions: DialogActionButton[] = [];

  loggedUser: UserEntity;

  constructor(
    dialogRef: MatDialogRef<UserProfileDialog>,
    private em: EntityManager,
    private authService: AuthenticationService,
    private dialogService: DialogService,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loggedUser = this.authService.getUser() as any;
  }

  openChangeUserPasswordDialog($event: Event): MatDialogRef<any> {
    $event.preventDefault();
    $event.stopPropagation();
    return this.dialogService.openDialog2(ChangeUserPasswordModal, {
      data: { id: this.loggedUser.id }
    });
  }
}
