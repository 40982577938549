import { Injectable } from '@angular/core';
import { IOrderDidsData } from './order-did-data.i';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { UserRole } from '@wephone-core/system';
import { HttpEngine } from '@wephone-core/service/http_engine';

@Injectable()
export class DidOrderService {
  private static URL_ORDER_DID_SA = '/sa/did/buy_number';
  private static URL_ORDER_DID = '/did/buy_number';

  constructor(
    private readonly http: HttpEngine,
    private readonly authService: AuthenticationService
  ) {}

  // Call when button Ok clicked on the dialog order-did
  submitOrder(
    params: IOrderDidsData
  ): Promise<any> {
    let url: string = DidOrderService.URL_ORDER_DID;
    if (this.authService.isRole(UserRole.SUPER_ADMIN)) {
      url = DidOrderService.URL_ORDER_DID_SA;
    }
    return this.http.apiPostV2(url, params, undefined, true);
  }
}
