import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SingletonBase } from '../utils/singleton';
import { ToastService } from './toast.service';
import { _tk, _ti } from '@wephone-translation';
// import { TranslateService } from '@ngx-translate/core';
import {
  IRoutingAppDestinationData,
  RoutingAppName,
  RoutingParamsConference,
  RoutingParamsDigitalSecretary,
  RoutingParamsIvrMenu,
  RoutingParamsPhoneNumber,
  RoutingParamsPlayFileAndHangup,
  RoutingParamsQueue,
  RoutingParamsRemoteApp,
  RoutingParamsRunVxml,
  RoutingParamsSipphone,
  RoutingParamsToDid,
  RoutingParamsUser
} from '@wephone-core/routing-app/routing-app.interface';
import { CallDestinationService } from '@wephone/services/call-destination.service';
import * as Joi from 'joi-browser';

export interface CalendarInputParams {
  calendar_id?: number;
  opening_hour_file_id?: number;
  vm_enabled?: number;
  action_not_office_hour?: any;
}
@Injectable()
export class FormService extends SingletonBase {
  constructor(
    private readonly toast: ToastService,
    // private readonly translate: TranslateService,
    private readonly callDestinationService: CallDestinationService
  ) {
    super();
  }

  normalizeData(data: { [key: string]: any }): any {
    if (!_.isEmpty(data)) {
      for (const k of Object.keys(data)) {
        if (data[k] && typeof data[k] === 'string') {
          data[k] = _.trim(data[k]);
        }
      }
    }

    return data;
  }

  markGroupDirty(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      switch (formGroup.get(key).constructor.name) {
        case 'FormGroup':
          this.markGroupDirty(formGroup.get(key) as FormGroup);
          break;
        case 'FormArray':
          this.markArrayDirty(formGroup.get(key) as FormArray);
          break;
        case 'FormControl':
          this.markControlDirty(formGroup.get(key) as FormControl);
          break;
        default:
          break;
      }
    });
  }

  markArrayDirty(formArray: FormArray): void {
    formArray.controls.forEach(control => {
      switch (control.constructor.name) {
        case 'FormGroup':
          this.markGroupDirty(control as FormGroup);
          break;
        case 'FormArray':
          this.markArrayDirty(control as FormArray);
          break;
        case 'FormControl':
          this.markControlDirty(control as FormControl);
          break;
        default:
          break;
      }
    });
  }

  markControlDirty(formControl: FormControl): void {
    formControl.markAsDirty();
  }

  getChangeSet(form: FormGroup): any {
    const ret = {};
    for (const k of Object.keys(form.controls)) {
      if (form.get(k).dirty) {
        ret[k] = form.get(k).value;
      }
    }

    return ret;
  }

  validateForm(formGroup: FormGroup, schema: { [key: string]: any }): void {
    const value = formGroup.value;
    const validate = Joi.validate(value, schema);
    if (validate.error) {
      validate.error.details.reduce((obj, val, key) => {
        // obj[val.type] = val.message;
        const keyName: string = val.context && val.context.key;
        if (keyName) {
          const formControl = formGroup.get(keyName);
          if (formControl) {
            const error = {};
            error[val.type] = val.message;
            formControl.setErrors(error);
          }
        }
        return obj;
      }, {});
    }
  }

  // getChangeSet(form: FormGroup): any {
  //   const ret = {};
  //   for (const k in form.controls) {
  //     const formitem = form.get(k);
  //     if (formitem.dirty) {
  //       if (formitem instanceof FormArray) {
  //         ret[k] = [];
  //         for (const j in formitem.controls) {
  //           ret[k].push();
  //         }
  //       } else {
  //         ret[k] = formitem.value;
  //       }
  //     }
  //   }

  //   return ret;
  // }

  checkParamsCallDestination(callDestination: IRoutingAppDestinationData): boolean {
    if (!(callDestination && callDestination.application && callDestination.params)) {
      return false;
    }

    switch (callDestination.application) {
      case RoutingAppName.callqueue:
        return !!(callDestination.params as RoutingParamsQueue).queue;
      case RoutingAppName.remote_application:
        return !!(callDestination.params as RoutingParamsRemoteApp).id;
      case RoutingAppName.runvxmlscript:
        return !!(callDestination.params as RoutingParamsRunVxml).ivrscript_id;
      case RoutingAppName.call_phone:
        return !!(callDestination.params as RoutingParamsSipphone).sipphone_id;
      case RoutingAppName.call_user:
        return !!(callDestination.params as RoutingParamsUser).id;
      case RoutingAppName.conference:
        return !!(callDestination.params as RoutingParamsConference).conference;
      case RoutingAppName.ivr_custom_main_menu:
      case RoutingAppName.ivr_custom_menu:
        if (
          callDestination.application === RoutingAppName.ivr_custom_main_menu ||
          (callDestination.params && callDestination.params.sub_menu === 0)
        ) {
          return !!(callDestination.params as RoutingParamsIvrMenu).id;
        }

        return !!(callDestination.params as RoutingParamsIvrMenu).ivrscript_data;
      case RoutingAppName.call_phone_number:
        return !!(callDestination.params as RoutingParamsPhoneNumber).number;
      // case 'play_then_hangup':
      //   return !!(callDestination.params as RoutingParamsPlayFileAndHangup).sound_id;
      case RoutingAppName.digitalsecretary:
        return !!(callDestination.params as RoutingParamsDigitalSecretary).default_ext;
      case RoutingAppName.to_did:
        return !!(callDestination.params as RoutingParamsToDid).did_id;
      default:
        return true;
    }
  }

  validateMenuData(ivrscript_data: any): boolean {
    if (!ivrscript_data || _.isEmpty(ivrscript_data)) {
      console.error('No ivr script data');
      this.toast.showError(_ti('ivrmenu.validator.data_required'));

      return false;
    }

    if (!ivrscript_data.sound_id) {
      console.error('No ivr script sound id');
      this.toast.showError(_ti('ivrmenu.validator.welcome_message_required'));

      return false;
    }

    if (!ivrscript_data.keymap || _.isEmpty(ivrscript_data.keymap)) {
      console.error('No ivr script keymap');
      this.toast.showError(_ti('ivrmenu.validator.keymap_required'));

      return false;
    }

    if (ivrscript_data.keymap.default && (!ivrscript_data.timeout || ivrscript_data.timeout < 0)) {
      this.toast.showError(_ti('ivrmenu.validator.timeout_invalid'));

      return false;
    }

    const keymaps: any = ivrscript_data.keymap;
    const inused_keys: string[] = [];

    for (const keys of Object.keys(keymaps)) {
      const keymap = keymaps[keys];

      if (!keys) {
        this.toast.showError(_ti('ivrmenu.validator.keymap_data_required'));
        return false;
      }

      if (!this.checkParamsCallDestination(keymap)) {
        console.error('Keymap is invalid');
        this.toast.showError(_ti('ivrmenu.validator.data_required'));
        return false;
      }

      for (const key of keys.split(',')) {
        if (inused_keys.indexOf(key) !== -1) {
          const error_message = _ti('ivrmenu.message.duplicated_keys', { key });
          this.toast.showError(error_message);

          return false;
        }

        inused_keys.push(key);
      }

      if (keymap.application === 'ivr_custom_menu' && !keymap.params.id && !keymap.params.ivrscript_data) {
        this.toast.showError(_ti('ivrmenu.validator.keymap_data_required'));

        return false;
      }

      // validate data sub menu
      if (
        keymap.application === 'ivr_custom_menu' &&
        keymap.params.ivrscript_data &&
        !this.validateMenuData(keymap.params.ivrscript_data)
      ) {
        return false;
      }
    }

    return true;
  }

  makeNumberOpeningCalendarValue(value: any): CalendarInputParams {
    if (value.hasOwnProperty('opening_calendar')) {
      value.calendar_id = value.opening_calendar ? value.opening_calendar.id : null;
      delete value.opening_calendar;
    }

    if (value.hasOwnProperty('opening_hour_file')) {
      value.opening_hour_file_id = value.opening_hour_file ? value.opening_hour_file.id : null;
      delete value.opening_hour_file;
    }

    if (value.hasOwnProperty('vm_enabled')) {
      value.vm_enabled = value.vm_enabled ? 1 : 0;
    }

    if (value.hasOwnProperty('calendar_reroute')) {
      if (!value.calendar_reroute) {
        value.action_not_office_hour = null;
      }

      delete value.calendar_reroute;
    }

    return value;
  }

  isValidCallDestination(actionNotOfficeHour: IRoutingAppDestinationData): boolean {
    if (_.isEmpty(actionNotOfficeHour)) {
      this.toast.showError(_ti('did.validator.action_not_office_hours_required'));
      return false;
    }

    const errors = this.callDestinationService.validCallDestination(actionNotOfficeHour);
    if (!_.isEqual({}, errors)) {
      this.toast.showError(_ti('did.validator.action_not_office_hours_invalid'));
      return false;
    }

    return true;
  }
}
