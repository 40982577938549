import { Entity, datafield } from '@wephone-core/model/model';
import { PhoneNumberService, parseDateTime } from '@wephone-utils';
import { UserRepository } from '../repository/user';
import { UserEntity } from './user';

export enum DURATION_TYPES {
  forever = 'forever',
  one_year = '1year',
  six_months = '6months',
  three_months = '3months',
  one_week = '1week',
  one_day = '1day'
}

export enum PhoneNumberBlockActionType {
  BLOCK = 0,
  LOW_PRIORITY = 1
}

export class BlackListEntity extends Entity {
  static object_type_id = 'blacklist';

  id: number;
  @datafield number: string;
  @datafield country_code: string;
  @datafield blocker_id: number;
  @datafield block_reason: string;
  @datafield block_dt: any;
  @datafield block_until: any;
  @datafield block_action: number;

  display_number: string;
  private blocker: UserEntity;

  get blocker_name(): string {
    return this.blocker && this.blocker.name || '';
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    this.block_until = this.block_until ? parseDateTime(this.block_until) : undefined;
    this.display_number = PhoneNumberService.getInstance().getDisplayNumber(this.number);
    this.blocker = this.blocker_id && UserRepository.getInstance().getObjectById(this.blocker_id);
    this.block_dt = this.block_dt ? parseDateTime(this.block_dt) : undefined;
  }
}
