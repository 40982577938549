import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';

export class IvrEvaluationRepository extends BaseRepository {
  ENTITY_CLASS = IvrEvaluationEntity;
  protected URL_PREFIX = 'ivr-evaluation';
  protected METHOD_PATH_LIST = 'list';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'LIST':
        return this.getUrlV2(this.METHOD_PATH_LIST);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    const url = this.getURLAction('LIST');
    if (use_cache && (this.object_list || []).length) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance().get(url, {}).then((updated_list: any) => {
      this.setDataAsReady();
      return this.object_list;
    });
  }

  /**
   * @params items: deleted items
   * @params manual_remove: Manual remove in list
   */
  bulkDelete(items: IvrEvaluationEntity[], manual_remove = true): Promise<any> {
    const ids: number[] = [];
    for (let i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    const url = this.getURLAction('DELETE');
    return HttpEngine.getInstance().postJson(url, { ids }).then((ret: { items_in_use?: number[] }) => {
      if (manual_remove) {
        const inUseIds: number[] = ret && ret.items_in_use || [];
        const deletedIds: number[] = ids.filter(id => !inUseIds.includes(id));
        for (const i of deletedIds) {
          this.removeObjectById(i);
        }
      }
      return ret;
    });
  }
}
