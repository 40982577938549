import { Component } from '@angular/core';

// create your cellRenderer as a Angular component
@Component({
  selector: 'pipe-cell-renderer',
  template: `
    	<div [ngSwitch]="pipe">
			<span *ngSwitchCase="'seconds_as_duration'">{{value | seconds_as_duration}}</span>
			<span *ngSwitchDefault>{{value}}</span>
    	</div>`
})
export class PipeCellRenderer {
  params: any;
  value;
  pipe;

  agInit(params: any): void {
    this.params = params;
    //        console.error("PipeCellRenderer params", params);
    this.value = params.value;
    this.pipe = params.renderPipe;
  }
}
