import { IEntity } from '@wephone-core/model/model.interface';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { UserRepository } from '@wephone-core/model/repository/user';
import { AgentRepository } from '@wephone-core/model/repository/agent';
// import { GroupRepository } from '../repository/group';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { IGroupRepository } from '../repository/group.i';
import { CallQueueRepository } from '../repository/callqueue';
import { DidRepository } from '../repository/did';

export interface IUserGroupEntity extends IEntity {
  user_ids: number[];
  users: IUserEntity[];
}

export class UserGroupEntity extends GroupEntity implements IUserGroupEntity {
  static object_type_id = 'servicegroup';
  user_ids: number[] = [];
  users: IUserEntity[] = [];

  constructor() {
    super();
    this.group_type = 'service';
  }

  fetchRelatedData(): void {
    super.fetchRelatedData();
    this.updateList(this.users, UserRepository.getInstance<UserRepository>().findByGroupId(this.id));
  }

  hasUserId(user_id: number): boolean {
    if (!this.users) {
      return false;
    }
    for (const user of this.users) {
      if (user.getId() === user_id) {
        return true;
      }
    }
    return false;
  }

  hasAgentId(agent_id: number): boolean {
    const agent = AgentRepository.getInstance().getObjectById(agent_id);
    return this.user_ids.indexOf(agent.user_id) >= 0;
  }

  hasAgent(): boolean {
    const agents = AgentRepository.getInstance<AgentRepository>().getObjectList();
    const groupsIds = [this.id, ...(EntityManager.getInstance().getRepository('GroupRepository') as IGroupRepository).getAllChildrenGroup(this).map(i => i.id)];
    const found = agents.find(a => Array.isArray(a.group_ids) && a.group_ids.find((gId: number) => groupsIds.includes(gId)));
    return !!found;
  }

  hasQueue(): boolean {
    const queues = CallQueueRepository.getInstance<CallQueueRepository>().getObjectList();
    const groupsIds = [this.id, ...(EntityManager.getInstance().getRepository('GroupRepository') as IGroupRepository).getAllChildrenGroup(this).map(i => i.id)];
    const found = queues.find(q => q.group_id && groupsIds.includes(q.group_id));
    return !!found;
  }

  hasDid(): boolean {
    const dids = DidRepository.getInstance<DidRepository>().getObjectList();
    const groupsIds = [this.id, ...(EntityManager.getInstance().getRepository('GroupRepository') as IGroupRepository).getAllChildrenGroup(this).map(i => i.id)];
    const found = dids.find(d => groupsIds.includes(d.group_id));
    return !!found;
  }
}
