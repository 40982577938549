<form [formGroup]="form">
  <mat-form-field class="full-width">
    <mat-label>{{ 'team_group.content.name'|translate }}</mat-label>
    <input data-ci="name" matInput formControlName="name">

    <mat-error [flexFormControlError]="form.get('name')"
      [customMessages]="{
        'duplicated': 'public.message.name_exist'|translate
      }"></mat-error>
  </mat-form-field>
  <mat-form-field class="full-width" *ngIf="teams.length > 0">
    <mat-label>{{'team_group.content.parent'|translate}}</mat-label>
    <flex-select-group-input [groups]="teams" groupType="team" placeholder="{{'team_group.content.parent'|translate}}"
      groupType="team" formControlName="parentId">
    </flex-select-group-input>
    <button mat-icon-button matSuffix type="button" (click)="removeOption()"
      [disabled]="!form.controls['parentId'].value">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>
