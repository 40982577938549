import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DynamicFilterSource, IFlexTableConfig, DialogService,
  IFlexTableSidePannelOptions, FlexCRUDPageComponent,
  regexSearch,
  localNow,
  parseDateTime,
} from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
// import { TranslateService } from '@ngx-translate/core';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { CreateSiptrunkModalComponent } from '@wephone/modals/create-siptrunk-modal/create-siptrunk-modal.component';
import { EditSiptrunkComponent } from '@wephone/components/edit-siptrunk/edit-siptrunk.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { SipTrunkService } from '@wephone/services/siptrunk.service';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { FAIcons } from '@wephone-custom-icon';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-sip-trunk',
  templateUrl: './sip-trunk.component.html',
  styleUrls: ['./sip-trunk.component.scss']
})
export class SipTrunkComponent extends FlexBasePage implements OnInit {
  FAIcons = FAIcons;

  filterValue: string;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sipTrunkForm: FormGroup;
  sipTrunkRepo = SipTrunkRepository.getInstance<SipTrunkRepository>();
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditSiptrunkComponent,
  };

  uiSiptrunk: boolean = FlexIvrSettings.getInstance().uiSipTrunk();

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly sipTrunkService: SipTrunkService
  ) {
    super();
    const sipTrunkSource = this.sipTrunkRepo.dataSource<SipTrunkEntity>();
    this.dataSource = new DynamicFilterSource(sipTrunkSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('siptrunk.form.name'),
          enableTooltip: true,
          lineClamp: 2,
          searchable: true,
          sortable: true,
          sort: 'asc'
        },
        {
          name: 'username',
          label: _tk('siptrunk.form.username'),
          searchable: true,
          sortable: true,
        },
        {
          name: 'outgoing_enabled',
          label: _tk('siptrunk.form.outgoing_enabled'),
          searchable: false,
          sortable: true,
        },
        // {
        //   name: 'alias',
        //   label: _tk('siptrunk.form.alias')
        // },
        {
          name: 'is_default',
          label: _tk('siptrunk.form.is_default'),
          visible: this.uiSiptrunk,
          searchable: false,
          sortable: false,
        },
        {
          name: 'last_online_dt',
          label: _tk('sipphone.content.last_online'),
          searchable: false,
          sortable: true,
          customSortFn: (siptrunk: SipTrunkEntity) => {
            let lastOnline: DateTime = !_.isEmpty(siptrunk.last_online_dt) ? parseDateTime(siptrunk.last_online_dt) : undefined;
            if (siptrunk.is_online) {
              lastOnline = localNow();
            }
            return lastOnline && lastOnline.toFormat('yyyyMMdd-HHmm') || '';
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.sipTrunkForm = this.fb.group({
      filterValue: [''],
    });

    this.sipTrunkForm.valueChanges.subscribe(formValue => {
      if (formValue) {
        this.onFormValueChange(formValue.filterValue);
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected async resolveData(): Promise<void> {
    await this.sipTrunkRepo.findAll();
  }

  private readonly filterFunc = (item: SipTrunkEntity): boolean => {
    return !this.filterValue || regexSearch(item.name.toLowerCase(), this.filterValue);
  }

  private onFormValueChange(searchText): void {
    this.filterValue = searchText && searchText.trim().toLowerCase() || null;
    this.dataSource.onFilterChange();
  }

  createNew(): any {
    this.dialogService.openDialog2(CreateSiptrunkModalComponent);
  }

  async bulkDelete(items: SipTrunkEntity[]): Promise<any> {
    const defaultTrunk = items.find(i => !!i.is_default);
    if (this.uiSiptrunk && defaultTrunk) {
      this.showWarning(_ti('siptrunk.validator.cannot_delete_default_siptrunk'));
      return;
    }

    const confirmed: boolean = await this.sipTrunkService.getConfirmDeleteSipTrunks(items);
    if (confirmed) {
      try {
        await this.sipTrunkRepo.bulkDelete(items);
        this.flexCrud.closeSidePanel();
        this.successToast(_ti('public.message.delete_success'));
      } catch (error) {
        console.error('Delete SIP trunk error', error);
        this.showErrorMessage(error, _ti('public.message.delete_failure'));
      }
    }
  }
}
