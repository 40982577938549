import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';
import { parseDateTime } from '@wephone-utils';
// import { TranslateService } from '@ngx-translate/core';
import { SmsConversationItem, SmsDialog, SmsSendStatus } from '@wephone-sms/sms.types';
import { DidEntity } from '@wephone-core/model/entity/did';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { UserEntity } from '@wephone-core/model/entity/user';
import { CallingNumber, UserProfileService } from '@wephone/services/user-profile.service';
import { DateTime } from 'luxon';
import { count } from 'sms-length';
import { _ti } from '@wephone-translation';

export enum SmsDirection {
  INBOUND = 0,
  OUTBOUND = 1
}

export enum SendStatus {
  SEND_STATUS_WAITING = 0,
  SEND_STATUS_TRYING = 1,
  SEND_STATUS_SENT = 2,
  SEND_STATUS_DELIVERED = 3,
  SEND_STATUS_FAILURE = 4,
}
export enum FailureReason {
  INVALID_PHONE_NUMBER = 1,
  PHONE_NOT_REACHED = 2,
}

export interface SmsSendResp {
  id: number;
  dialog_id: number;
  direction: SmsDirection;
  remote_number: string;
  message: string;
  creation_time: string;
  last_state_time: string;
  delivery_time: string;
  send_time: string;
  notification_time: string;
  notification_enabled: number;
  lang: string;
  send_status: SendStatus;
  is_manual: number;
  provider_error_code: string;
  failure_reason_id: FailureReason;
  failure_reason: string;
  failure_count: number;
  client_name: string;
  sender_name: string;
}

export interface SentSmsResult {
  sms: SmsSendResp;
  dialog: SmsDialog;
}

export interface SmsMessageCount {
  max: number;
  length: number;
  frag: number;
}

@Injectable()
export class SmsService {
  // Constants
  static URL_GET_DIALOG_MESSAGES = 'sms/dialog_messages';
  static URL_SEND_SMS = 'sms/send';
  static URL_SEND_SMS_SCHEDULED = 'sms/schedule';
  static URL_SEND_SMS_UNSCHEDULED = 'sms/unschedule';
  static URL_MARK_CONVERSATION_AS_HANDLED = 'sms/mark_as_handled';
  static URL_GET_SMS_CONVERSATION_INFO = 'sms/conversation_info';
  static URL_GET_SMS_MESSAGE_INFO = 'sms/message_info';
  static URL_GET_SMS_TOTAL_CONVERSATION_UNREAD = 'sms/total_conversation_unread';

  private totalUnreadSms;

  // Constructor
  constructor(
    private httpEngine: HttpEngine,
    private em: EntityManager,
    private userProfile: UserProfileService,
    // private readonly trans: TranslateService,
  ) {
  }

  async getSmsDialogMessages(dialogId: number): Promise<SmsConversationItem[]> {
    const conversations: SmsConversationItem[] = await this.httpEngine.apiGetV2(SmsService.URL_GET_DIALOG_MESSAGES,
      { dialog_id: dialogId }
    );
    return conversations.map(x => _.extend(x, {
      message_time: parseDateTime(x.message_time)
    }));
  }

  markSmsAsHandled(dialogId: number, value: number): Promise<any> {
    const params: any = {
      dialog_id: dialogId,
      value
    };

    return this.httpEngine.apiPostV2(SmsService.URL_MARK_CONVERSATION_AS_HANDLED, params);
  }

  sendSmsFromDialog(smdDialogId: number, message: string, scheduledDate?: DateTime): Promise<SentSmsResult[]> {
    const params: any = {
      dialog_id: smdDialogId,
      message,
    };

    if (scheduledDate) {
      params.scheduled_date = scheduledDate;

      return this.httpEngine.apiPostV2(SmsService.URL_SEND_SMS_SCHEDULED, params);
    }

    return this.httpEngine.apiPostV2(SmsService.URL_SEND_SMS, params);
  }

  unscheduleSms(smsId: number): Promise<any> {
    return this.httpEngine.apiPostV2(SmsService.URL_SEND_SMS_UNSCHEDULED, {
      id: smsId
    });
  }

  sendSms(from: string, to: string, message: string, senderName?: string, scheduledDate?: DateTime): Promise<SentSmsResult[]> {
    const params: any = {
      from,
      to: [to],
      message,
    };

    if (senderName) {
      params.sender_name = senderName;
    }

    if (scheduledDate) {
      params.scheduled_date = scheduledDate;

      return this.httpEngine.apiPostV2(SmsService.URL_SEND_SMS_SCHEDULED, params);
    }

    return this.httpEngine.apiPostV2(SmsService.URL_SEND_SMS, params);
  }

  displayStatus(status: SmsSendStatus): string {
    let displayStatus: string;
    switch (status) {
      case SmsSendStatus.SEND_STATUS_WAITING:
        displayStatus = _ti('sms.status.waiting');
        break;

      case SmsSendStatus.SEND_STATUS_TRYING:
        displayStatus = _ti('sms.status.trying');
        break;

      case SmsSendStatus.SEND_STATUS_SENT:
        displayStatus = _ti('sms.status.sent');
        break;

      case SmsSendStatus.SEND_STATUS_DELIVERED:
        displayStatus = _ti('sms.status.delivered');
        break;

      case SmsSendStatus.SEND_STATUS_FAILURE:
        displayStatus = _ti('sms.status.failure');
        break;

      default:
        displayStatus = 'Undefined';
        break;
    }
    return displayStatus;
  }

  getSmsConversationInfo(id: number): Promise<SmsDialog> {
    return this.httpEngine.apiGetV2(SmsService.URL_GET_SMS_CONVERSATION_INFO, { id });
  }

  getSmsMessageInfo(id: number): any {
    return this.httpEngine.apiGetV2(SmsService.URL_GET_SMS_MESSAGE_INFO, { id });
  }

  getSmsMessageCount(message: string): SmsMessageCount {
    const smsInfo = count(message || '');
    const max = smsInfo.characterPerMessage;
    const length = smsInfo.length;
    return { max, length, frag: smsInfo.messages || 1 };
  }

  getTotalConversationUnread(): number {
    return this.totalUnreadSms || 0;
  }

  async updateTotalConversationUnreadSms(): Promise<void> {
    this.totalUnreadSms = await this.httpEngine.apiGetV2(SmsService.URL_GET_SMS_TOTAL_CONVERSATION_UNREAD);
  }

  async getDidForReplySMS(user: UserEntity): Promise<DidEntity> {
    const dfCallingNumber: DidEntity = user.getDefaultCallingNumber();
    if (dfCallingNumber && dfCallingNumber.can_send_sms) {
      return dfCallingNumber;
    }

    const numbers: CallingNumber[] = await this.userProfile.getCallingNumbers();
    if (_.isEmpty(numbers)) {
      return undefined;
    }

    const numberIds: number[] = numbers.map(x => x.id);
    const dids: DidEntity[] = this.em.getRepository('DidRepository').getObjectList().filter(x => _.includes(numberIds, x.id) && x.can_send_sms);
    return !_.isEmpty(dids) ? dids[0] : undefined;
  }
}
