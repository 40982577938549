<div fxLayout="column" fxFill>
  <form [formGroup]="filterForm">
    <div align="center" class="mb-1">
      <period-select [displayPeriodList]="periodList" [defaultPeriod]="defaultPeriod"
        [startDate]="startDateControl.value" [endDate]="endDateControl.value"
        (changedPeriod)="changeFilterDate($event)"></period-select>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start none"
      fxLayoutGap="10px" fxLayoutGap.xs="0px">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0">
        <div fxFlex="calc(33.3% - 20px)">
          <mat-form-field class="full-width">
            <mat-label>{{ 'campaign.startDt' | translate }}</mat-label>
            <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
              placeholder="{{ 'campaign.startDt' | translate }}">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error *ngIf="startDateControl.hasError('flexDatepickerMin') || startDateControl.hasError('required')">
              {{ 'form.validator.required'|translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(33.3% - 20px)">
          <mat-form-field class="full-width">
            <mat-label>{{ 'campaign.endDt' | translate }}</mat-label>
            <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
              placeholder="{{ 'campaign.endDt' | translate }}">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="endDateControl.hasError('required')">
              {{ 'form.validator.required'|translate }}
            </mat-error>
            <mat-error
              *ngIf="endDateControl.hasError('flexDatepickerMin') || endDateControl.hasError('matDatepickerFilter')">
              {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
              startDateControl.value | timeFormatDateShort} }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <button type="button" mat-raised-button color="accent" (click)="reloadFilter()">
        {{ 'public.apply' | translate }}</button>
    </div>
  </form>
  <div class="pa-1 text-center"
    [innerHTML]="'components.ivr_menu_statistics.total_calls'|translate: {total: totalCalls}">Total calls</div>
  <flex-table #flexTable fxFill fxFlex [dataSource]="dataSource" [tableConfig]="tableConfig"></flex-table>
</div>
