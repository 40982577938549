import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IAudioTrack } from '@wephone-audio';
import { FlexBaseComponent } from '@wephone-core-ui';

@Component({
  selector: 'audio-track-progess-bar',
  templateUrl: './audio-track-progess-bar.html',
  styleUrls: [ './audio-track-progess-bar.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioTrackProgressBarComponent extends FlexBaseComponent {
  PERIODICAL_UPDATE = true;

  @Input() audioTrack: IAudioTrack;

  constructor(private cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  get seek(): number {
    // return this.audioTrack ? Math.round(this.audioTrack.seek() || 0) : 0;
    return this.audioTrack && this.audioTrack.seek() || 0;
  }

  get duration(): number {
    // return this.audioTrack ? Math.round(this.audioTrack.duration()) : 0;
    return this.audioTrack && this.audioTrack.duration() || 0;
  }

  sliderChange(event: any): void {
    if (this.audioTrack && event.value) {
      this.audioTrack.setSeek(event.value);
    }
  }
}
