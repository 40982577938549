import { Component, Inject, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { DialogFlowService } from '../service/dialog-flow.service';
import { FlexAnchorDirective, deferToNextTick } from '@wephone-utils';
import { IChatbotReplyComponent } from './chatbot-reply-component';

@Component({
  selector: 'chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent extends FlexBaseComponent {
  hasAnswer = false;
  textRequest: string;

  @ViewChild(FlexAnchorDirective) anchor: FlexAnchorDirective;

  constructor(
    private dialogFlow: DialogFlowService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    super();
  }

  async sendRequest(): Promise<void> {
    const res = await this.dialogFlow.sendRequest(this.textRequest);
    const componentClass = this.dialogFlow.getComponentForIntent(res.intentName);
    if (componentClass) {
      this.hasAnswer = true;
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
      const viewContainerRef = this.anchor.viewContainerRef;
      viewContainerRef.clear();
      deferToNextTick(
        async () => {
          const componentRef = viewContainerRef.createComponent(componentFactory);
          const replyComponent: IChatbotReplyComponent = componentRef.instance as IChatbotReplyComponent;
          replyComponent.ngOnInit(); // Manually call ngOnInit
          await replyComponent.checkReadiness();
          //replyComponent.setReplyData({number: 2, 'number-type': 'national', 'geo-city': 'Paris' });
          replyComponent.setReplyData(res.params);
        }
      );
    }
  }
}
