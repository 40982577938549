import { Component, OnInit, OnDestroy, Self, Optional, ElementRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IvrEvaluationRepository } from '@wephone-core/model/repository/ivr_evaluation';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';

@Component({
  selector: 'ivr-evaluation-select-form-input',
  templateUrl: './ivr-evaluation-select.component.html',
  styleUrls: ['./ivr-evaluation-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: IvrEvaluationSelectFormInput }]
})
export class IvrEvaluationSelectFormInput extends FlexMatInputWrapper implements OnInit, OnDestroy, MatFormFieldControl<IvrEvaluationEntity> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  list: IvrEvaluationEntity[];
  flexSelectOptions: IFlexSelectOptions;

  private ivrEvaluationRepo: IvrEvaluationRepository;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    readonly em: EntityManager,
  ) {
    super(ngControl, elRef);

    this.ivrEvaluationRepo = this.em.getRepository<IvrEvaluationRepository>('IvrEvaluationRepository');

    this.flexSelectOptions = {
      filterFunc: (item: IvrEvaluationEntity, filterString: string) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: IvrEvaluationEntity, b: IvrEvaluationEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();

    // load data before init component
    await this.ivrEvaluationRepo.findAll();
    this.list = this.ivrEvaluationRepo.getObjectList();
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  get controlType(): string {
    return 'ivr-evaluation-select-form-input';
  }
}
