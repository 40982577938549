<mat-menu #locale="matMenu" x-position="before">
  <button mat-menu-item *ngFor="let lang of langs" (click)="setLocale(lang.code)">
    <span country-flag [countryCode2]="lang.country"></span>
    {{ lang.label }}
  </button>
</mat-menu>

<button *ngIf="currentLang" [matTooltip]="currentLang.label" [matMenuTriggerFor]="locale" mat-icon-button class="ml-xs">
  <span country-flag [countryCode2]="currentLang.country">
  </span>
</button>
