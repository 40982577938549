import { FormControl } from '@angular/forms';
import { ValidatorService } from '../services/validator.service';
import * as _ from 'lodash';

export const MACValidator = (control: FormControl) => {
  const value = control.value && _.trim(control.value) || undefined;
  if (value && !ValidatorService.VALID_MAC_ADDRESS_PATTERN.test(value)) {
    return { invalid_mac: true };
  }
  return null;
};
