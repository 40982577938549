import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';

export class RoutingAppRunVxmlScript extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.runvxmlscript;
  private ivrscript: IvrScriptEntity;

  setIvrScript(ivrscript: IvrScriptEntity): void {
    this.setAppParams({
      ivrscript_id: ivrscript.id
    });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.ivrscript_id) {
      this.ivrscript = EntityManager.getRepository('IvrScriptRepository').getObjectById(this.appParams.ivrscript_id);
    }
  }

  getRelatedObject(): IvrScriptEntity {
    return this.ivrscript;
  }

  getDescription(): string {
    const ivrscript_name: string = this.ivrscript ? this.ivrscript.name : '<invalid>';

    return `To IVR script ${ivrscript_name}`;
  }
}
