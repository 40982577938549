import { datafield, Entity } from '@wephone-core/model/model';

export class TagEntity extends Entity {
  static object_type_id = 'tag';

  public id: number;
  @datafield
  public enterprise_id: number;
  @datafield
  public tag_name: string;
}
