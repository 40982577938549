import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class CrmRoutingRuleRepository extends BaseRepository implements ICrmRoutingRuleRepository {
  ENTITY_CLASS = CrmRoutingRuleEntity;
  protected URL_PREFIX = 'crm_routing_rule';
  private URL_GET_LIST = 'list';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'LIST':
        return this.getUrlV2(this.URL_GET_LIST);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  isNameExist(name: string, id: number): boolean {
    const item = _.find(this.getObjectList(), o => {
      return o.name === name && (!id || id && o.id !== id);
    });
    return !!item;
  }

  // createAndSave(object_data: any = null, extra_data: any = null): Promise<any> {
  //   const obj = this.create(object_data);
  //   return this.save(obj, extra_data).then(ret => {
  //     const obj_id = ret.item.id;
  //     return this.reload().then(resp => {
  //       return this.wait_for_object_id(obj_id);
  //     });
  //   });
  // }

  save(item: IEntity, extra_data: { [id: string]: any } = null, attr_group: string = null): Promise<any> {
    return super.save(item, extra_data, attr_group).then(ret => {
      return this.reload().then(() => {
        return ret;
      });
    });
  }

  saveAttrs(item: IEntity, attr_list: string[], extra_data: { [id: string]: any } = null): Promise<any> {
    return super.saveAttrs(item, attr_list, extra_data).then(ret => {
      return this.reload().then(() => {
        return ret;
      });
    });
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    const url = this.getUrlV2(this.URL_GET_LIST);
    if (use_cache && (this.object_list || []).length) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance().get(url, {}).then((updated_list: any) => {
      const object_list_data = [];
      for (const new_obj_data of updated_list) {
        const item = _.find(object_list_data, { id: new_obj_data.id }) || null;
        if (item) {
          item.setObjectData(new_obj_data);
          continue;
        }
        // create new object
        const new_blacklist_obj = this.create();
        new_blacklist_obj.setObjectData(new_obj_data, false);
        object_list_data.push(new_blacklist_obj);
      }
      this.setDataAsReady();
      this.setObjectList(object_list_data || new Array<any>());
      return this.object_list;
    });
  }
}

import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { IEntity } from '@wephone-core/model/model.interface';
import { ICrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule.i'; import { HttpEngine } from '@wephone-core/service/http_engine';
