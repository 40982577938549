import { Component, OnInit, ViewChild } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CustomReportConfigRepository } from '@wephone-core/model/repository/custom_report_config';
import { CustomReportConfigEntity } from '@wephone-core/model/entity/custom_report_config';
import { CustomReportWidgetComponent } from '../custom-report-widget/custom-report-widget.component';

@Component({
  selector: 'wallboards',
  templateUrl: './wallboard.component.html',
  styleUrls: ['./wallboard.component.scss']
})
export class WallboardComponent extends FlexBaseComponent implements OnInit {
  @ViewChild('customReportWidget', { static: false }) customReportWidget: CustomReportWidgetComponent;

  customReportList: CustomReportConfigEntity[];
  customReport: CustomReportConfigEntity;

  constructor(
    private readonly em: EntityManager,
  ) {
    super();
  }

  ngOnInit(): void {
    this.em.getRepository<CustomReportConfigRepository>('CustomReportConfigRepository').findAll()
      .then((data: CustomReportConfigEntity[]) => {
        this.customReportList = data.filter(i => !!i.source_name);
        this.customReport = this.customReportList[0];
      });

    super.ngOnInit();
  }

  changeCustomReport(): void {
    if (this.customReportWidget) {
      this.customReportWidget.loadData();
    }
  }

}
