<div fxFill fxLayout="column">
  <p class="mat-error" *ngIf="error">{{ error }}</p>
  <p *ngIf="data && data.prompt">{{ data.prompt }}</p>
  <div>
    <mat-form-field class="full-width">
      <mat-label>{{'dialogs.did_setup_wizard.step3.selected_sipphone'|translate}}</mat-label>
      <sipphone-select-form-input [(ngModel)]="sipphone"></sipphone-select-form-input>
    </mat-form-field>
  </div>
</div>
