import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'direction-renderer',
  template: `<span [ngClass]="params.value==1?'into-direction':'out-direction'">{{ (params.value==1?'cdr.incoming':'cdr.outgoing') | translate }}</span>`,
  styleUrls: ['./direction-renderer.scss']
})
export class DirectionRenderer implements ICellRendererAngularComp {
  params: any;

  constructor(
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
