import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'date-renderer',
  template: `<span>{{ params.value | timeFormatDateShort }}</span>`
  // template: `<span>{{ params.value | timeFormatCustom: format }}</span>`
})
export class DateRenderer implements ICellRendererAngularComp {
  params: any;
  // format = 'dd/MM/yyyy HH:mm:ss';

  agInit(params: any): void {
    this.params = params;

    // if (this.params.colDef.format) {
    //   this.format = this.params.colDef.format;
    // }
  }

  refresh(): boolean {
    return false;
  }
}

@Component({
  selector: 'datetime-renderer',
  template: `<span>{{ params.value | timeFormatDateTimeShort }}</span>`
  // template: `<span>{{ params.value | timeFormatCustom: format }}</span>`
})
export class DateTimeRenderer implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
