import { Inject, Injectable } from '@angular/core';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { HttpEngine } from '@wephone-core/service/http_engine';
// import { TranslateService } from '@ngx-translate/core';
import { _ti } from '@wephone-translation';

enum SsmlVoiceGender {
  SSML_VOICE_GENDER_UNSPECIFIED = 'SSML_VOICE_GENDER_UNSPECIFIED',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NEUTRAL = 'NEUTRAL',
}

export interface GoogleCloudVoiceItem {
  'languageCodes': string[];
  'name': string;
  'ssmlGender': SsmlVoiceGender;
  'naturalSampleRateHertz': number;
}

export interface Text2SpeechLangItem {
  label: string;
  value: string;
}

export interface GoogleCloudVoiceParam {
  languageCode: string;
  ssmlGender: SsmlVoiceGender;
}

export interface GoogleCloudFileOption {
  name: string;
  create_temp_file: number;
  file_entry_id?: number;
}

export interface Text2SpeechVoiceItem {
  label: string;
  gender: string;
  position: number;
  value: GoogleCloudVoiceParam;
}

export interface Text2SpeechLangVoice {
  [key: string]: Text2SpeechVoiceItem[];
}

@Injectable()
export class Text2SpeechDataService {
  static NumberGenderLimited = 1; // Undefined means no limit
  private _text2speech_lang_list: Text2SpeechLangItem[];
  private _text2speech_lang_voices: Text2SpeechLangVoice;

  // readonly text2speech_lang_list = [
  //   { label: 'France', value: 'fr' },
  //   { label: 'English', value: 'en' },
  //   { label: 'Saudi Arabia', value: 'sa' },
  //   { label: 'Spain', value: 'es' },
  //   { label: 'China', value: 'cn' },
  //   { label: 'Czech Republic', value: 'cz' },
  //   { label: 'Denmark', value: 'dk' },
  //   { label: 'Belgium', value: 'be' },
  //   { label: 'Netherlands', value: 'nl' },
  //   { label: 'Australia', value: 'au' },
  //   { label: 'India', value: 'in' },
  //   { label: 'German', value: 'de' },
  //   { label: 'Greek', value: 'gr' },
  //   { label: 'Italian', value: 'it' },
  //   { label: 'Japanese', value: 'jp' },
  //   { label: 'Korean', value: 'kr' },
  //   { label: 'Norway', value: 'no' },
  //   { label: 'Poland', value: 'pl' },
  //   { label: 'Portugal', value: 'pt' },
  //   { label: 'Russian ', value: 'ru' },
  //   { label: 'Sweden', value: 'se' },
  //   { label: 'Turkey', value: 'tr' }
  // ];

  // readonly text2speech_lang_voices = {
  //   fr: [
  //     { label: 'Alice', value: 'alice8kmu' },
  //     { label: 'Antoine', value: 'antoine8kmu' },
  //     { label: 'Antoine From Afar', value: 'antoinefromafar8kmu' },
  //     { label: 'Antoine Happy', value: 'antoinehappy8kmu' },
  //     { label: 'Antoine Up Close', value: 'antoineupclose8kmu' },
  //     { label: 'Bruno', value: 'bruno8kmu' },
  //     { label: 'Claire', value: 'claire8kmu' },
  //     { label: 'Julie', value: 'julie8kmu' },
  //     { label: 'Manon', value: 'manon8kmu' },
  //     { label: 'Margaux', value: 'margaux8kmu' },
  //     { label: 'Margaux Happy', value: 'margauxhappy8kmu' },
  //     { label: 'Margaux Sad', value: 'margauxsad8kmu' },
  //     { label: 'Robot', value: 'robot8kmu' }
  //   ],
  //   en: [
  //     { label: 'Graham', value: 'graham8kmu' },
  //     { label: 'Lucy', value: 'lucy8kmu' },
  //     { label: 'Nizareng', value: 'nizareng8kmu' },
  //     { label: 'Peter', value: 'peter8kmu' },
  //     { label: 'Peter Sad', value: 'petersad8kmu' },
  //     { label: 'Queen Elizabeth', value: 'queenelizabeth8kmu' },
  //     { label: 'Rachel', value: 'rachel8kmu' },
  //     { label: 'Karen', value: 'karen8kmu' },
  //     { label: 'Kenny', value: 'kenny8kmu' },
  //     { label: 'Laura', value: 'laura8kmu' },
  //     { label: 'Micah', value: 'micah8kmu' },
  //     { label: 'Nelly', value: 'nelly8kmu' },
  //     { label: 'Rod', value: 'rod8kmu' },
  //     { label: 'Ryan', value: 'ryan8kmu' },
  //     { label: 'Saul', value: 'saul8kmu' },
  //     { label: 'Sharon', value: 'sharon8kmu' },
  //     { label: 'Tracy', value: 'tracy8kmu' },
  //     { label: 'Will Happy', value: 'willhappy8kmu' },
  //     { label: 'Will Little Creature', value: 'willlittlecreature8kmu' },
  //     { label: 'Will Old Man', value: 'willoldman8kmu' },
  //     { label: 'Will Sad', value: 'willsad8kmu' },
  //     { label: 'Will Up Close', value: 'willupclose8kmu' }
  //   ],
  //   sa: [
  //     { label: 'Leila', value: 'leila8kmu' },
  //     { label: 'Mehdi', value: 'mehdi8kmu' },
  //     { label: 'Nizar', value: 'nizar8kmu' },
  //     { label: 'Salma', value: 'salma8kmu' }
  //   ],
  //   es: [
  //     { label: 'Laia', value: 'laia8kmu' },
  //     { label: 'Antonio', value: 'antonio8kmu' },
  //     { label: 'Ines', value: 'ines8kmu' },
  //     { label: 'Maria', value: 'maria8kmu' }
  //   ],
  //   cn: [ { label: 'Lulu', value: 'lulu8kmu' } ],
  //   cz: [ { label: 'Eliska', value: 'eliska8kmu' } ],
  //   dk: [ { label: 'Mette', value: 'mette8kmu' }, { label: 'Rasmus', value: 'rasmus8kmu' } ],
  //   be: [
  //     { label: 'Jeroen', value: 'jeroen8kmu' },
  //     { label: 'Jeroen Happy', value: 'jeroenhappy8kmu' },
  //     { label: 'Jeroen Sad', value: 'jeroensad8kmu' },
  //     { label: 'Sofie', value: 'sofie8kmu' },
  //     { label: 'Zoe', value: 'zoe8kmu' }
  //   ],
  //   nl: [
  //     { label: 'Daan', value: 'daan8kmu' },
  //     { label: 'Femke', value: 'femke8kmu' },
  //     { label: 'Jasmijn', value: 'jasmijn8kmu' },
  //     { label: 'Max', value: 'max8kmu' }
  //   ],
  //   au: [ { label: 'Lisa', value: 'lisa8kmu' }, { label: 'Tyler', value: 'tyler8kmu' } ],
  //   in: [ { label: 'Deepa', value: 'deepa8kmu' } ],
  //   de: [
  //     { label: 'Andreas', value: 'andreas8kmu' },
  //     { label: 'Claudia', value: 'claudia8kmu' },
  //     { label: 'Julia', value: 'julia8kmu' },
  //     { label: 'Klaus', value: 'klaus8kmu' },
  //     { label: 'Sarah', value: 'sarah8kmu' }
  //   ],
  //   gr: [
  //     { label: 'Dimitris', value: 'dimitris8kmu' },
  //     { label: 'Dimitris Happy', value: 'dimitrishappy8kmu' },
  //     { label: 'Dimitris Sad', value: 'dimitrissad8kmu' }
  //   ],
  //   it: [
  //     { label: 'Chiara', value: 'chiara8kmu' },
  //     { label: 'Fabiana', value: 'fabiana8kmu' },
  //     { label: 'Vittorio', value: 'vittorio8kmu' }
  //   ],
  //   jp: [ { label: 'Sakura', value: 'sakura8kmu' } ],
  //   kr: [ { label: 'Minji', value: 'minji8kmu' } ],
  //   no: [
  //     { label: 'Bente', value: 'bente8kmu' },
  //     { label: 'Kari', value: 'kari8kmu' },
  //     { label: 'Olav', value: 'olav8kmu' }
  //   ],
  //   pl: [ { label: 'Ania', value: 'ania8kmu' }, { label: 'Monika', value: 'monika8kmu' } ],
  //   pt: [ { label: 'Celia', value: 'celia8kmu' }, { label: 'Marcia', value: 'marcia8kmu' } ],
  //   ru: [ { label: 'Alyona', value: 'alyona8kmu' } ],
  //   se: [
  //     { label: 'Mia', value: 'mia8kmu' },
  //     { label: 'Elin', value: 'elin8kmu' },
  //     { label: 'Emil', value: 'emil8kmu' },
  //     { label: 'Emma', value: 'emma8kmu' },
  //     { label: 'Erik', value: 'erik8kmu' },
  //     { label: 'Samuel', value: 'samuel8kmu' },
  //     { label: 'Kal', value: 'kal8kmu' }
  //   ],
  //   tr: [ { label: 'Ipek', value: 'ipek8ka' } ]
  // };

  private URL_FILE_ENTRY_GGCLOUD_VOICES = '/api/fileentry/google_cloud_voices';
  constructor(
    private httpEngine: HttpEngine,
    // private trans: TranslateService,
    private settings: FlexIvrSettings) {
  }

  async listGGCloudVoices(): Promise<any> {

    const voices: GoogleCloudVoiceItem[] =
      await this.httpEngine.get(this.settings.getAbsoluteWSv2URL(this.URL_FILE_ENTRY_GGCLOUD_VOICES), {});
    const langConfig: any = {};
    const genderVoicesNumber = {};

    for (const voice of voices) {
      const codes = voice.languageCodes;
      for (const code of codes) {
        // Limit number of voice and set position
        if (!genderVoicesNumber[code]) {
          genderVoicesNumber[code] = {};
        }

        // Increase position
        if (!genderVoicesNumber[code][voice.ssmlGender]) {
          genderVoicesNumber[code][voice.ssmlGender] = 0;
        }
        genderVoicesNumber[code][voice.ssmlGender] += 1;

        // Limit number of voice
        if (Text2SpeechDataService.NumberGenderLimited &&
          genderVoicesNumber[code][voice.ssmlGender] > Text2SpeechDataService.NumberGenderLimited) {
          continue;
        }

        // Set position
        if (!langConfig[code]) {
          langConfig[code] = [];
        }
        langConfig[code].push({
          name: voice.name,
          gender: voice.ssmlGender,
          position: !Text2SpeechDataService.NumberGenderLimited || Text2SpeechDataService.NumberGenderLimited > 1 ?
            genderVoicesNumber[code][voice.ssmlGender] : ''
        });
      }
    }

    // Set text2speech_lang_list
    const text2speechLangList = [];
    for (const lang of Object.keys(langConfig)) {
      const langSpliter = lang.split('-');
      if (langSpliter.length !== 2) {
        console.error('Incorrect lang format', lang);
        continue;
      }
      const langTrans = _ti(`language_codes.${langSpliter[0].toLowerCase()}`);
      const countryTrans = _ti(`country_codes.${langSpliter[1].toLowerCase()}`);

      text2speechLangList.push({
        value: lang,
        label: `${langTrans} - ${countryTrans}`
      });
    }
    this.text2speech_lang_list = text2speechLangList;

    // Set text2speech_lang_voices
    const text2speechLangVoices = {};
    for (const lang of Object.keys(langConfig)) {
      if (!text2speechLangVoices[lang]) {
        text2speechLangVoices[lang] = [];
      }
      for (const config of langConfig[lang]) {
        text2speechLangVoices[lang].push({
          position: config.position,
          gender: config.gender,
          label: `${_ti(`genders.${config.gender.toLowerCase()}`)} ${config.position}`,
          value: {
            languageCode: lang,
            ssmlGender: config.gender
          },
        });
      }
    }

    // Sort
    // for (const voice of Object.keys(text2speechLangVoices)) {
    //   text2speechLangVoices[voice].sort((a, b) => {
    //     if (a.gender === b.gender) {
    //       return a.position > b.position;
    //     }
    //     return a.gender > b.gender;
    //   });
    // }
    this.text2speech_lang_voices = text2speechLangVoices;

    return voices;
  }

  get text2speech_lang_list(): { label: string; value: string }[] {
    return this._text2speech_lang_list;
  }

  set text2speech_lang_list(list) {
    this._text2speech_lang_list = list;
  }

  get text2speech_lang_voices(): Text2SpeechLangVoice {
    return this._text2speech_lang_voices;
  }

  set text2speech_lang_voices(voices: Text2SpeechLangVoice) {
    this._text2speech_lang_voices = voices;
  }

  getLangByValue(lang: string): Text2SpeechLangItem {
    return this.text2speech_lang_list.find(x => x.value === lang);
  }

  getVoiceByValue(lang: string, voice: string): Text2SpeechVoiceItem {
    const voices = this.text2speech_lang_voices;
    if (!voices[lang]) {
      console.error(`${lang} might not be used`);
      return;
    }
    return voices[lang].find(x => x.gender === voice);
  }

}
