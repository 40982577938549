<form [formGroup]="form">
<div class="wrapper">
  <mat-button-toggle-group formControlName="availability">
    <ng-container *ngIf="forUser; else notForUser">
        <mat-button-toggle data-ci="always_open" [value]="1">
            {{ 'update_queue_calendar.always_open'|translate }}
        </mat-button-toggle>
        <mat-button-toggle data-ci="connected" [value]="0">
            {{ 'user_page.availability.connected'|translate }}
        </mat-button-toggle>
        <mat-button-toggle data-ci="define_calendar" [value]="2">
            {{ 'update_queue_calendar.define_calendar'|translate }}
        </mat-button-toggle>
    </ng-container>
    <ng-template #notForUser>
      <mat-button-toggle data-ci="always_open" [value]="1">
          {{ 'update_queue_calendar.always_open'|translate }}
      </mat-button-toggle>
      <mat-button-toggle data-ci="define_calendar" [value]="0">
          {{ 'update_queue_calendar.define_calendar'|translate }}
      </mat-button-toggle>
      <mat-button-toggle data-ci="always_close" [value]="2">
          {{ 'update_queue_calendar.always_close'|translate }}
      </mat-button-toggle>
    </ng-template>
  </mat-button-toggle-group>

  <ng-container *ngIf="form.get('availability').value == 2">
    <div class="calendar-name">
      <span *ngIf="form.get('calendar').value">{{ 'update_queue_calendar.calendar_in_use'|translate:{calendar_name: form.get('calendar').value.name} }}</span>
      <a data-ci="modify" class="change-calendar flex-link" (click)="openCalendarListDialog()">
        {{ 'public.change'|translate }}
      </a>
      <!-- <button type="button" mat-icon-button (click)="removeCalendar()" *ngIf="form.get('calendar').value">
        <mat-icon>delete</mat-icon>
      </button> -->
    </div>
    <div class="calendar-information" *ngIf="form.get('calendar').value" [ngClass]="{'has-change': hasUpdateCalendar}">
      <calendar-small [id]="form.get('calendar').value.id" [allowEdit]="true"></calendar-small>
      
      <div class="actions" *ngIf="hasUpdateCalendar">
        <div class="mt-2 mb-1" *ngIf="usedQueueList?.length">
            <span>{{ 'update_queue_calendar.update_warning_first'|translate }}: </span>
            <ng-container *ngFor="let q of usedQueueList; let last=last">
                <a class="flex-link">{{ q.queue_name }}</a>{{ !last ? ',':  '. ' }}
            </ng-container>
            {{ 'update_queue_calendar.update_warning_last'|translate }}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('calendar').invalid && form.get('calendar').touched">
    <span class="mat-error" *ngIf="form.get('calendar').hasError('required')">
      {{ 'opening_hour_calendar.message.calendar_required'|translate }}
    </span>
  </ng-container>
</div>
</form>
