import { Injectable } from '@angular/core';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as util from 'util';
import { EnterpriseBillingState } from './invoice.service.i';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { InvoicePaymentMethod } from '@wephone-core/model/entity/invoice';
import { localNow } from '@wephone-utils';
@Injectable()
export class InvoiceService {
  static DueDurationDelay = 30;
  static DueDateDelay = 7;

  private static URL_INVOICE_SEND_EMAIL = '/api/invoice/send_invoice_notification_email';
  private static URL_INVOICE_CREATE_MONTHLY = '/api/invoice/create_invoice_monthly';
  private static URL_INVOICE_RECREATE = '/api/invoice/recreate_invoice';
  private static URL_INVOICE_UPDATE_STATUS = '/api/invoice/update_status';
  private static URL_INVOICE_UPDATE_PAYMENT_INFO = '/api/invoice/update_payment_info';
  private static URL_INVOICE_UPDATE_PAYMENT_FAILURE_REASON = 'invoice/update_payment_failure_reason';
  private static URL_INVOICE_GET_EMAIL_CONTENT = '/api/invoice/get_email_content';
  private static URL_INVOICE_VIEW_PDF = '/invoice/view_invoice_pdf/%s?download=%s';
  private static URL_INVOICE_VIEW_CSV = '/invoice/view_invoice_csv/%s';
  private static URL_BILLING_GET_STATE = '/api/sa/get_billing_state';
  private static URL_SEND_ULTIMATUM = '/api/sa/send_ultimatum';
  private readonly CREATE_MANUAL_INVOICE_URL = '/api/invoice/create-manual-invoice';

  constructor(public httpEngine: HttpEngine, private settings: FlexIvrSettings, private config_manager: ConfigManager) { }

  async canSendUltimatum(enterprise_id: number): Promise<boolean> {
    const billingState: EnterpriseBillingState = await this.getBillingState(enterprise_id);
    // console.log('billingState', billingState);
    let ultimatumDateValid: boolean = !billingState.ultimatum_date;
    if (!ultimatumDateValid) {
      const now = DateTime.fromJSDate(new Date());
      ultimatumDateValid = now > billingState.ultimatum_date && now.diff(billingState.ultimatum_date, 'days').get('days') > InvoiceService.DueDateDelay;
    }

    return ultimatumDateValid &&
      billingState.unpaid_delay > InvoiceService.DueDurationDelay;
  }

  private async getBillingState(enterprise_id: number): Promise<EnterpriseBillingState> {
    const url: string = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(InvoiceService.URL_BILLING_GET_STATE);
    const resp: {
      unpaid_invoice: number,
      unpaid_delay: number,
      ultimatum_date: string
    } = await this.httpEngine.get(url, { eid: enterprise_id });
    console.log('getBillingState', resp);
    const ret: EnterpriseBillingState = _.extend(resp, {
      ultimatum_date: resp.ultimatum_date ? DateTime.fromJSDate(new Date(resp.ultimatum_date)) : null
    });
    return ret;
  }

  sendUltimatum(enterpriseId: number): Promise<any> {
    const url = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(InvoiceService.URL_SEND_ULTIMATUM);
    return this.httpEngine.post(url, { eid: enterpriseId });
  }

  sendInvoiceEmail(
    invoiceId: number,
    enterpriseId: number,
    emails: string[],
    emailSubject?: string,
    emailContent?: string,
  ): Promise<any> {
    const url = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(InvoiceService.URL_INVOICE_SEND_EMAIL);
    return this.httpEngine.post(url, {
      emails,
      invoice_id: invoiceId,
      enterprise_id: enterpriseId,
      email_content: emailContent,
      email_subject: emailSubject
    });
  }

  getInvoiceViewPdfUrl(invoice_id: number, download: boolean = false): string {
    const url = FlexIvrSettings.getInstance().getEnterpriseUrlV2(InvoiceService.URL_INVOICE_VIEW_PDF);
    return util.format(url, invoice_id, download ? '1' : '0');
  }

  getInvoiceDownloadCsvUrl(invoice_id: number): string {
    const url = FlexIvrSettings.getInstance().getEnterpriseUrlV2(InvoiceService.URL_INVOICE_VIEW_CSV);
    return util.format(url, invoice_id);
  }

  createMonthInvoices(month: string, enterprise_ids?: Array<number> | undefined): Promise<any> {
    const url = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(InvoiceService.URL_INVOICE_CREATE_MONTHLY);
    return this.httpEngine.post(url, { month, enterprise_ids });
  }

  createManualInvoice(enterprise_id: number): Promise<any> {
    const url = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(this.CREATE_MANUAL_INVOICE_URL);
    return this.httpEngine.post(url, { enterprise_id });
  }

  recreateInvoice(invoice_id: number): Promise<{ id: number }> {
    const url = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(InvoiceService.URL_INVOICE_RECREATE);
    return this.httpEngine.post(url, { invoice_id });
  }

  updateInvoiceStatus(invoice_id: number, paid: boolean, paymentMethod?: InvoicePaymentMethod, paymentDate?: Date): Promise<{ id: number }> {
    const url = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(InvoiceService.URL_INVOICE_UPDATE_STATUS);
    let params: any = { invoice_id, paid: paid ? 1 : 0 };
    if (paid) {
      params = _.extend(params, { payment_date: paymentDate, payment_method: paymentMethod });
    }
    return this.httpEngine.post(url, params);
  }

  getEmailContentInvoice(invoice_id: number): Promise<{ content: string, subject: string }> {
    const url = FlexIvrSettings.getInstance().getAbsoluteWSv2URL(InvoiceService.URL_INVOICE_GET_EMAIL_CONTENT);
    return this.httpEngine.get(url, { invoice_id });
  }

  updateInvoicePaymentFailureReason(invoice_id: number, paymentFailureReason: string): Promise<{ id: number }> {
    return this.httpEngine.apiPostV2(InvoiceService.URL_INVOICE_UPDATE_PAYMENT_FAILURE_REASON, { invoice_id, payment_failure_reason: paymentFailureReason });
  }
}
