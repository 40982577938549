<div class="pa-1">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div>
      <mat-form-field class="full-width">
        <input matInput [placeholder]="'ivrremoteapp.content.name'| translate" formControlName="name">

        <mat-error [flexFormControlError]="form.get('name')"
          [customMessages]="{
            'name_exist': 'public.message.name_exist' | translate
          }"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
