import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { SingletonBase } from '@wephone-utils';

@Injectable()
export class WebPushService extends SingletonBase {
  static REGISTER_PATH = '/ws-v2/api/webpush/register';

  register(data: any): Promise<any> {
    return HttpEngine.getInstance().post(`${WebPushService.REGISTER_PATH}`, data);
  }
}
