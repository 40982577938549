import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OutcallcampaignBaseComponent } from '@wephone/components/outcallcampaign/outcallcampaign-base/outcallcampaign-base.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ToastService, DynamicFilterSource, IFlexTableConfig, regexSearch } from '@wephone-utils';
import { UserService } from '@wephone/services/user.service';
import { Router } from '@angular/router';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { OutcallCampaignItemEntity } from '@wephone-core/model/entity/outcallcampaignitem';
import { OutcallCampaignItemRepository } from '@wephone-core/model/repository/outcallcampaignitem';
import { Subject } from 'rxjs';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OutcallcampaignCreateContactComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-create-contact/outcallcampaign-create-contact.component';
import { OutcallcampaignImportContactsComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-import-contacts/outcallcampaign-import-contacts.component';
import { _tk, _ti } from '@wephone-translation';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-outcallcampaign-contact',
  templateUrl: './outcallcampaign-contact.component.html',
  styleUrls: ['./outcallcampaign-contact.component.scss']
})
export class OutcallcampaignContactComponent extends OutcallcampaignBaseComponent implements OnInit {
  @Input() campaign: OutcallCampaignEntity;

  dataNotifierSource: Subject<OutcallCampaignItemEntity[]>;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  filterString: string;
  form: FormGroup;
  campaignItemRepo: OutcallCampaignItemRepository;
  @ViewChild('flexCrud') flexCrud;
  constructor(
    translate: TranslateService,
    protected dialogService: DialogService,
    protected userService: UserService,
    protected toast: ToastService,
    protected em: EntityManager,
    protected router: Router,
    private fb: FormBuilder,
  ) {
    super(translate, dialogService, userService, toast, router);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.campaignItemRepo = this.em.getRepository<OutcallCampaignItemRepository>('OutcallCampaignItemRepository');
    this.dataNotifierSource = new Subject<OutcallCampaignItemEntity[]>();
    this.dataSource = new DynamicFilterSource(this.dataNotifierSource, this.filterFunc);

    this.tableConfig = {
      enableFilter: false,
      rowHeight: 60,
      columns: [
        {
          name: 'called_number',
          label: _tk('call_queue.content.called_number'),
          width: '100px',
          sortable: true
        },
        {
          name: 'contact_name',
          label: _tk('campaign.full_name'),
          sortable: true
        },
        {
          name: 'status',
          label: _tk('campaign.status'),
          width: '80px',
          sortable: true
        },
        {
          name: 'scheduled_dt',
          label: _tk('campaign.scheduled_date'),
          width: '80px',
          sortable: true
        },
        {
          name: 'action',
          label: _tk('public.action'),
          width: '80px',
          sortable: false
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'resfesh',
            icon: 'cached',
            callback: () => {
              return this.refreshOutcallCampaignItems();
            }
          },
          {
            id: 'add',
            icon: 'person_add',
            callback: () => {
              return this.createNewOutcallCampaignItem();
            }
          },
          {
            id: 'import',
            icon: 'group_add',
            callback: () => {
              return this.importOutcallCampaignItem();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                console.log('Delete button clicked');

                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });
    this.form.valueChanges.subscribe(this.onFormValueChage);

    await this.loadDS();
  }

  onFormValueChage = (formValues): void => {
    this.filterString = (formValues.filterString || '').trim();
    this.applyFilter();
  }

  filterPredicate(item: OutcallCampaignItemEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }

    return regexSearch(item.called_number, filterString) ||
      (item.first_name && regexSearch(item.first_name, filterString)) ||
      (item.last_name && regexSearch(item.last_name, filterString));
  }

  bulkDelete(items: OutcallCampaignItemEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        await this.campaignItemRepo.bulkDelete(items);
        await this.loadDS();
        // this.refreshOutcallCampaignItems();
        this.close();
        this.toast.show(_ti('public.message.delete_success'));
      }
    );
  }

  async refreshOutcallCampaignItems(): Promise<void> {
    this.dataNotifierSource.next([]);
    this.applyFilter();
    await this.loadDS();
  }

  createNewOutcallCampaignItem(): Promise<any> {
    return this.dialogService
      .openDialog2(OutcallcampaignCreateContactComponent, {
        data: {
          outcallcampaign_item: this.campaignItemRepo.create(),
          outcallcampaign_id: this.campaign.id
        },
        size: 's'
      }, async rs => {
        console.log('createNewOutcallCampaignItem', rs);
        await this.loadDS();
      })
      .afterClosed()
      .toPromise();
  }

  editOutcallCampaignItem(campaignItem: OutcallCampaignItemEntity): Promise<any> {
    return this.dialogService
      .openDialog2(OutcallcampaignCreateContactComponent, {
        data: {
          outcallcampaign_item: campaignItem,
          outcallcampaign_id: this.campaign.id
        },
        size: 's'
      }, async rs => {
        console.log('editOutcallCampaignItem', rs);
        await this.loadDS();
      })
      .afterClosed()
      .toPromise();
  }

  importOutcallCampaignItem(): Promise<any> {
    return this.dialogService
      .openDialog2(OutcallcampaignImportContactsComponent, {
        data: {
          outcallcampaign_id: this.campaign.id
        },
        size: 'l'
      }, async rs => {
        console.log('editOutcallCampaignItem', rs);
        await this.loadDS();
      })
      .afterClosed()
      .toPromise();
  }

  applyFilter = () => {
    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  filterFunc = (item: OutcallCampaignItemEntity): boolean => {
    return item.campaign_id === this.campaign.id && this.filterPredicate(item, this.filterString);
  }

  async loadDS(): Promise<void> {
    const data = await this.campaignItemRepo.findByCampaignId(this.campaign.id, true);
    this.dataNotifierSource.next(data);
    this.applyFilter();
  }

  async submitForm(): Promise<any> { }

  resetForm(): void { }
}
