import { Component, OnInit, Directive } from '@angular/core';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
// import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { ToastService, DialogService, EditingComponent } from '@wephone-utils';
import { TranslateService } from '@ngx-translate/core';
// import { GroupRepository } from '@wephone-core/model/repository/group';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
// import { ICallQueueEntity } from '@wephone-core/model/entity/callqueue.i';
// import { CommonAPI } from '@wephone-core/service/common_api';
import { UserService } from '@wephone/services/user.service';
// import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { Router } from '@angular/router';
import { _ti } from '@wephone-translation';

@Directive()
export abstract class OutcallcampaignBaseComponent extends EditingComponent implements OnInit {
  // private _URL_EXPORT_OUTCALL_ITEM = '/callcenter/data_export_csv_outcalls/{campaign_id}';

  campaign: OutcallCampaignEntity;
  queue: CallQueueEntity;

  constructor(
    protected translate: TranslateService,
    protected dialogService: DialogService,
    protected userService: UserService,
    protected toast: ToastService,
    protected router: Router
  ) {
    super();
  }

  validateRequiredField(field: string, field_list: string[]): boolean {
    if (this.isSubmitField(field, field_list) && !this.form.controls[field].value) {
      this.form.controls[field].setErrors({ required: _ti('form.validator.field_required') });

      return false;
    }

    return true;
  }

  isSubmitField(field: string, field_list: string[]): boolean {
    return field_list.indexOf(field) >= 0;
  }

  async updateQueueAttrs(queue: CallQueueEntity, field_list: string[]): Promise<CallQueueEntity> {
    if (!queue.id) {
      console.warn('Queue not found');
      this.toast.showError(_ti('public.message.update_failure'));

      return Promise.resolve(undefined);
    }
    if (!(field_list instanceof Array)) {
      throw new Error('Parameter invalid !');
    }

    const repo = CallQueueRepository.getInstance() as CallQueueRepository;
    const resp = await repo.saveAttrs(queue, field_list);
    return repo.getObjectById(resp.object_id);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.campaign) {
      return;
    }

    if (!this.queue) {
      this.queue = this.campaign.queue || (CallQueueRepository.getInstance().create() as CallQueueEntity);
    }

    if (!this.queue.after_call_time) {
      this.queue.after_call_time = 0;
    }
  }
}
