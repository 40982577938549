import { Injectable } from '@angular/core';
import { ConfigManager } from '@wephone-core/wephone-core.module';

@Injectable()
export class SipAccountService {

    constructor(private configManager: ConfigManager) {
    }

    getSipAccountList(): Array<any> {
        return this.configManager.getSipAccountList();
    }
}