<div>
  <h2 [ngClass]="{'text-green': !hasError, 'mat-error': hasError}" fxLayout="row" fxLayoutGap="10px">
    <mat-icon>
      {{ hasError ? 'error_outline' : 'check_circle' }}
    </mat-icon>
    <span>
      {{ (hasError ? 'crm.customcrm.test_failure' : 'crm.customcrm.test_success')|translate }}
    </span>
  </h2>
  <ng-container *ngIf="hasError">
    <div *ngFor="let msg of errors">
      <div class="mat-error">{{ msg }}</div>
    </div>
  </ng-container>
  <mat-card>
    <mat-card-content>
      <pre>{{ responseData|json }}</pre>
    </mat-card-content>
  </mat-card>
</div>
