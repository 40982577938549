<div fxFill>
  <h2 class="px-1">{{ 'public.multipleedit'|translate:{number:editingList.length} }}</h2>

  <mat-tab-group fxFill [selectedIndex]="tabSelectedIndex" (selectedIndexChange)="updateTabSelectedIndex($event)">
    <mat-tab>
      <ng-template matTabLabel>{{ 'campaign.tab_campaign_attributes'|translate }}</ng-template>
      <ng-template matTabContent>
        <div class="full-height">
          <app-outcallcampaign-general #generalEditor 
            [hidden]="!dummyCampaign" 
            [campaign]="dummyCampaign" 
            [queue]="dummyQueue"
            [multipleEdit]="true"
            (formValueChanges)="onFormValueChange()">
          </app-outcallcampaign-general>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  

</div>
