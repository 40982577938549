import * as _ from 'lodash';

import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class RoutingServiceRepository extends BaseRepository {
  ENTITY_CLASS = RoutingServiceEntity;
  protected URL_PREFIX = 'routing_service';

  getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }
}

import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { RoutingServiceEntity } from '../entity/routing_service';
