import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';

export class RoutingAppExternalNumber extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.call_phone_number;
  // private phone_number: string;

  setPhoneNumber(phone_number: string): void {
    this.setAppParams({ number: phone_number });
  }

  setRelatedObject(): void {
  }

  getRelatedObject(): string {
    return this.appParams.number;
  }

  getDescription(): string {
    const web_service = this.appParams ? this.appParams.number : '<invalid>';

    return `Call an external phone number ${web_service}`;
  }
}
