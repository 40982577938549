import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { UserEntity } from '@wephone-core/model/entity/user';
import { Colors, DialogActionButton, DialogService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DidRepository } from '@wephone-core/model/repository/did';
import { DidEntity } from '@wephone-core/model/entity/did';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { FormControl } from '@angular/forms';
import { UserRepository } from '@wephone-core/model/repository/user';
import { DidService } from '@wephone/services/did.service';

@Component({
  selector: 'user-dedicated-number-modal',
  templateUrl: './user-dedicated-number-modal.component.html',
  styleUrls: ['./user-dedicated-number-modal.component.scss']
})
export class UserDedicatedNumberModalComponent extends DialogComponentBase implements OnInit {

  dialogTitle = _tk('user_dedicated_number_modal.title');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.applyDedicatedNumber();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  user: UserEntity;
  didList: DidEntity[];
  treeData: FlexSelectTreeNode[];
  dedicatedControl: FormControl;

  constructor(
    dialogRef: MatDialogRef<UserDedicatedNumberModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private em: EntityManager,
    private didService: DidService,
    private dialogService: DialogService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.user = this.em.getRepository<UserRepository>('UserRepository').getObjectById(this.data.user_id);
    this.didList = this.em.getRepository<DidRepository>('DidRepository').getObjectList();
    this.dedicatedControl = new FormControl();
    this.buildTreeData();
  }

  private buildTreeData(): void {
    this.treeData = this.didList.filter(did => this.showDid(did)).map(did => {
      const countryCode = did.country_code && did.country_code.toLowerCase();
      const flag = countryCode && `<span class="flag-icon flag-icon-${countryCode}"></span><span>&nbsp;</span>` || '';
      return {
        id: did.id,
        name: flag + did.number_with_name,
        value: did,
        checkbox: true,
        children: [],
      };
    });
  }

  private showDid(did: DidEntity): boolean {
    return !did.hasLinkedData();
  }

  async applyDedicatedNumber(): Promise<any> {

    const selectedDidId = this.dedicatedControl.value;

    if (!selectedDidId) {
      return;
    }

    const selectedDid = this.didList.find(d => d.id === selectedDidId);

    if (selectedDid.hasRoutingData()) {
      return this.dialogService.confirmDialog(
        _ti('dialogs.confirmation'),
        _ti('user_dedicated_number_modal.confirm_message'),
        async () => {
          await this.saveDedicatedNumber(selectedDidId);
        }
      );
    }

    return this.saveDedicatedNumber(selectedDidId);
  }

  saveDedicatedNumber(selectedDidId: number): Promise<any> {
    return this.didService.setDedicatedForUser(selectedDidId, this.user.id).then(
      resp => {
        this.successToast(_ti('public.message.update_success'));
        this.dismiss(selectedDidId);
        return resp;
      }, resp => {
        this.showError(_ti('public.message.update_failure') + ` ${resp.message}`);
        return resp;
      }
    );
  }

}
