<ng-template #listFilterTpl>
  <form [formGroup]="phoneBookForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
    fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
    <flex-search-input formControlName="filterValue" placeholder="{{ 'public.search' | translate }}"
      [hintText]="'phone_book.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
  </form>
</ng-template>

<flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_phone_book'|translate }}" [filterTemplate]="listFilterTpl"
  [tableConfig]="tableConfig" [dataSource]="dataSource"
  [columnTemplates]="{phone_office: tplPhoneOffice, phone_mobile: tplPhoneMobile}"
  [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">
  <ng-template #tplPhoneOffice let-item="row">
    {{ item.phone_office | displayPhoneNumber }}
  </ng-template>
  <ng-template #tplPhoneMobile let-item="row">
    {{ item.phone_mobile | displayPhoneNumber }}
  </ng-template>
</flex-crud-page>
