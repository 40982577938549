<div fxFill fxLayout="column" class="pa-1">
  <form [formGroup]="form" *ngIf="form">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'shortdialcode.content.dial_code'| translate}}</mat-label>
        <input matInput placeholder="{{'shortdialcode.content.dial_code' | translate}}" formControlName="dial_code"
          data-ci="dial-code">
          
        <mat-error [flexFormControlError]="form.get('dial_code')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox formControlName="match_prefix" data-ci="match-prefix">
        {{'shortdialcode.content.match_prefix'| translate}}
      </mat-checkbox>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="20px"
      fxLayoutGap.xs="0" class="mat-chip">
      <div fxFlex="33">
        <span>{{'shortdialcode.content.routing_data' | translate}}</span>
      </div>
      <div fxFlex="33">
        <span>{{'did.content.default_destination' | translate}}</span>
      </div>
      <div fxFlex="34">
        <mat-chip-list>
          <mat-chip color="primary" selected (click)="changeCallDestination()" data-ci="change-call-destination">
            {{ (shortDialCode.routing_data ? 'did.form.change' : 'did.form.define_call_destination')|translate }}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="20px"
      fxLayoutGap.xs="0">
      <div fxFlex="33">
      </div>
      <div fxFlex="33">
        <div class="application" *ngIf="form.get('routing_data').value">
          {{ applicationName }}
          <b>{{ callDestinationInfo }}</b>
        </div>
      </div>
      <div fxFlex="34">
      </div>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'shortdialcode.content.comment'|translate}}</mat-label>
        <textarea matInput rows="3" placeholder="{{'shortdialcode.content.comment'|translate}}"
          formControlName="comment"></textarea>
          
        <mat-error [flexFormControlError]="form.get('comment')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
