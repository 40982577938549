import { Component, OnInit } from '@angular/core';
import { FlexBasePage } from '@wephone-core-ui';
import { StorageService } from '@wephone-core/service/storage.service';
import * as _ from 'lodash';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DashboardService } from '../dashbord.service';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { IAgentListSort } from '@wephone/components/agents/agent-list/agent-list.i';
import { FAIcons } from '@wephone-custom-icon';
import { DisplayType, SortCallByDuration } from './LivePageType';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss'],
  providers: [DashboardService]
})
export class LivePage extends FlexBasePage implements OnInit {
  FAIcons = FAIcons;

  callDirectionList: string[] = ['both', 'inbound', 'outbound'];
  filters: {
    call_direction: string;
    filter_group_selected_ids: number[];
    filter_group_show_not_grouped_agents: boolean;
  };
  // selected_group_ids;
  // show_not_grouped_agents;
  // checked_all_group;
  sortCallByDuration: number;
  readonly sortByCallDirection = {
    recent_call_first: SortCallByDuration.RECENT_CALL_FIRST,
    recent_call_last: SortCallByDuration.RECENT_CALL_LAST,
  };

  agent_filters: any = {
    search_text: undefined,
    agent_show_option: DisplayType.compact
  };

  agent_sort: IAgentListSort = {
    sort_column: 'name',
    sort_dir: 1
  };

  pause_agent_sort: IAgentListSort = {
    sort_column: 'pause_reason',
    sort_dir: 1
  };

  readonly agent_display_types: DisplayType[] = [DisplayType.compact, DisplayType.queue, DisplayType.group];

  private readonly storageKey = 'flexivr_live_page';
  private readonly agentStorageKey = 'flexivr_livepage_agent_list';
  private readonly callStorageKey = 'flexivr_livepage_active_call_list';

  searchTextControl = new FormControl();

  constructor(
    private readonly storage: StorageService,
    private readonly configManager: ConfigManager,
    private readonly authService: AuthenticationService,
    private readonly dashboardService: DashboardService
  ) {
    super();

    this.filters = {
      call_direction: this.callDirectionList[0],
      filter_group_selected_ids: [],
      filter_group_show_not_grouped_agents: true
    };
    this.sortCallByDuration = this.sortByCallDirection.recent_call_first;

    this.searchTextControl.valueChanges.subscribe(value => {
      this.agent_filters.search_text = value && value.trim() || undefined;
    });
  }

  resolveData(): void {
    this.storage.getDomainConfig(this.storageKey).then(data => {
      if (data) {
        this.filters.call_direction = data.call_direction ? data.call_direction : this.callDirectionList[0];
        this.filters.filter_group_selected_ids = data.filter_group_selected_ids || [];
        this.filters.filter_group_show_not_grouped_agents = data.filter_group_show_not_grouped_agents || true;
      }
    });

    this.storage.getAppConfig(this.agentStorageKey).then(data => {
      if (data) {
        if (data.agent_filters) {
          this.agent_filters = data.agent_filters || {
            search_text: undefined,
            display_type: 'compact'
          };
        }
        this.agent_filters.display_type = this.agent_filters.display_type || this.agent_display_types[0];

        if (data.agent_sort) {
          this.agent_sort = data.agent_sort || {
            sort_column: 'name',
            sort_dir: 1
          };
        }
      }
    });

    this.storage.getDomainConfig(this.callStorageKey).then(data => {
      if (data) {
        this.sortCallByDuration = +data;
      }
    });
  }

  changeSortByDurationDirection(direction: number): void {
    this.storage.setDomainConfig(this.callStorageKey, direction);
  }

  changeCallDirection(callDirection: string): void {
    this.storage.setDomainConfig(this.storageKey, callDirection);
  }

  showGroupFilter(): void {
    const data = {
      group_ids: this.filters.filter_group_selected_ids,
      checked_all: (this.filters.filter_group_selected_ids || []).length > 0 ? false : true,
      show_ungrouped_agents: this.filters.filter_group_show_not_grouped_agents
    };

    this.dashboardService.showGroupFilterDialog(data, respData => {
      if (respData) {
        const checked_all = (respData.selected_group_ids || []).length > 0 ? false : true;
        this.filters.filter_group_selected_ids = respData.selected_group_ids;
        this.filters.filter_group_show_not_grouped_agents = checked_all
          ? true
          : respData.show_not_grouped_agents;
        this.storage.setDomainConfig(this.storageKey, this.filters);
      }
    });
  }

  showActionLogs(): void {
    this.dashboardService.showActionLogsDialog();
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature) ? true : false;
  }

  get is_admin(): boolean {
    return this.authService.isAdmin();
  }

  get agent_display_type(): DisplayType {
    return this.agent_filters.display_type;
  }

  set agent_display_type(display_type: DisplayType) {
    this.agent_filters.display_type = display_type;
    this.storage.setAppConfig(this.agentStorageKey, { display_type });
  }

  // get search_text(): string {
  //   return this.agent_filters.search_text;
  // }

  // set search_text(value: string) {
  //   this.agent_filters.search_text = value;
  // }

  changeSortAgent(field: string, direction: number): void {
    this.agent_sort.sort_column = field || 'name';
    this.agent_sort.sort_dir = direction ? 1 : 0;
  }

  clearSearch(): void {
    this.searchTextControl.setValue(null);
    // this.agent_filters.search_text = undefined;
  }
}
