<div class="pa-1">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'hotelroom.content.room_number'| translate}}</mat-label>
        <input matInput type="text" formControlName="room_number"
          placeholder="{{ 'hotelroom.content.room_number'|translate }}">
        <mat-error *ngIf="form.get('room_number').invalid">
          <ng-container *ngIf="form.get('room_number').hasError('exists'); else tolRoomNumberRequired">
            {{'hotelroom.message.room_number_exist'|translate}}
          </ng-container>
          <ng-template #tolRoomNumberRequired>
            <ng-container
              *ngIf="form.get('room_number').hasError('any.empty') || form.get('room_number').hasError('any.required'); else tplRoomNumberMin">
              {{'form.validator.field_required'|translate}}
            </ng-container>
          </ng-template>
          <ng-template #tplRoomNumberMin>
            <ng-container *ngIf="form.get('room_number').hasError('string.min'); else tplRoomNumberMax">
              {{ 'form.validator.min_value'|translate:{min: 1} }}
            </ng-container>
          </ng-template>
          <ng-template #tplRoomNumberMax>
            <ng-container *ngIf="form.get('room_number').hasError('string.max'); else tplRoomNumberWhitespace">
              {{ 'form.validator.max_value'|translate:{max: 10} }}
            </ng-container>
          </ng-template>
          <ng-template #tplRoomNumberWhitespace>
            <ng-container *ngIf="form.get('room_number').hasError('whitespace'); else tplDataInvalid">
              {{'form.validator.whitespace'|translate}}
            </ng-container>
          </ng-template>
          <ng-template #tplDataInvalid>
            {{ 'form.validator.data_invalid'|translate }}
          </ng-template>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="mt-1">
      <mat-slide-toggle formControlName="has_sipphone" color="primary">
        {{'hotelroom.content.also_create_sipphone'|translate}}
        <span [hidden]="!form.get('has_sipphone').value" class="mat-text-primary">
          {{ 'sipphone.content.enabled'|translate }}</span>
        <span [hidden]="form.get('has_sipphone').value" class="mat-text-muted">
          {{ 'sipphone.content.disabled'|translate }}</span>
      </mat-slide-toggle>
    </div>
  </form>
</div>
