import { DialogActionButton, IFlexDialogConfig, IDialogComponent, DialogWrapper, DialogService } from '@wephone-utils';
import { FlexBaseComponent } from './flex-base-component';
import { HostBinding, Directive, OnInit, ChangeDetectorRef } from '@angular/core';
import { _tk, _ti } from '@wephone-translation';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Directive()
export abstract class DialogComponentBase extends FlexBaseComponent implements IDialogComponent, OnInit {
  SCROLLABLE_CONTENT = false;
  @HostBinding('class.flex-dialog-no-scroll') get _baseDialogCssClassNoScroll(): boolean {
    return !this.SCROLLABLE_CONTENT;
  }

  @HostBinding('class.flex-dialog-scrollable') get _baseDialogCssClassScrollable(): boolean {
    return this.SCROLLABLE_CONTENT;
  }

  dialogTitle?: string;
  dialogLeftActions?: DialogActionButton[];
  dialogRightActions?: DialogActionButton[];
  modalRef: DialogWrapper;
  _cancelButton: DialogActionButton = {label: _tk('public.button.close')};
  form: FormGroup;
  backButton: DialogActionButton = {label: _tk('public.button.back'), visible: () => false };

  get cancelButton(): DialogActionButton {
    return this._cancelButton;
  }

  set cancelButton(button: DialogActionButton) {
    this._cancelButton = button;
  }

  constructor(protected dialogRef: MatDialogRef<any>, cdr?: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.addSubscription(
      this.dialogRef.keydownEvents().subscribe(event => {
        if (event.key === 'Escape') {
          this.dismiss();
        }
      })
    );
  }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  setModalComponent(dlg: DialogWrapper): void {
    this.modalRef = dlg;
  }

  updateDialogLayout(): void {
    this.modalRef.detectChange();
  }

  getDialogRightActions(): DialogActionButton[] {
    return this.dialogRightActions;
  }

  getDialogLeftActions(): DialogActionButton[] {
    return this.dialogLeftActions;
  }

  protected dismiss(data = null, warnIfFormChanged = true): void {
    if (this.form && this.form.dirty && warnIfFormChanged) {
      DialogService.getInstance().confirmDialog(
        _ti('dialogs.confirmation'),
        _ti('public.message.form_dirty_close_warning'),
        () => {
          this.dialogRef.close(data);
        }
      );

      return;
    }

    this.dialogRef.close(data);
  }
}
