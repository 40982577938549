<div class="pa-1" fxFlex="grow">
  <form [formGroup]="form">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'call_queue.content.alias'|translate}}</mat-label>
        <input matInput formControlName="alias" [placeholder]="'call_queue.content.alias'|translate">
        <mat-error [flexFormControlError]="form.get('alias')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'call_queue.content.result_notification_url'|translate}}</mat-label>
        <input type="url" matInput formControlName="result_notification_url"
          [placeholder]="'call_queue.content.result_notification_url'|translate">
        
        <mat-error [flexFormControlError]="form.get('result_notification_url')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
