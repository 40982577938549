import * as _ from 'lodash';

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors, IFlexDialogConfig, ToastService, NoWhitespaceValidator } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TagEntity } from '@wephone-core/model/entity/tag';
import { TagRepository } from '@wephone-core/model/repository/tag';


@Component({
  selector: 'app-create-tag-modal',
  templateUrl: './create-tag-modal.component.html',
  styleUrls: ['./create-tag-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTagModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's',
  };

  dialogTitle = _tk('tag.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  // public form: FormGroup;
  newTag: TagEntity;

  constructor(
    private readonly fb: FormBuilder,
    dialogRef: MatDialogRef<CreateTagModalComponent>,
    public toast: ToastService,
    cdr?: ChangeDetectorRef,
  ) {
    super(dialogRef, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      tag_name: ['', [Validators.required, NoWhitespaceValidator]]
    });
  }

  async submit(): Promise<any> {
    this.form.markAllAsTouched();
    this.detectChanges();

    if (this.form.invalid) {
      return;
    }

    const updateData = { tag_name: _.trim(this.form.get('tag_name').value) };

    try {
      const tagRepo: TagRepository = TagRepository.getInstance<TagRepository>();
      const resp = await tagRepo.createAndSave(updateData);
      let newTag: TagEntity;
      if (resp && resp.object_id) {
        await tagRepo.wait_for_object_id(resp.object_id);
        newTag = tagRepo.getObjectById(resp.object_id);
      }

      this.successToast(_ti('public.message.create_success'));
      return this.dismiss(newTag, false);
    } catch (e) {
      console.error('Create Enterprise CRM erorr: ' + e);
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }
}
