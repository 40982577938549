import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { FormControl } from '@angular/forms';
import { regexSearch } from '@wephone-utils';

@Component({
  selector: 'agent-selector-one-click',
  templateUrl: 'agent-selector-one-click.html',
  styleUrls: ['./agent-selector-one-click.scss']
})
export class AgentSelectorOneClick implements OnInit {
  @Input() excludedList: AgentEntity[];
  @Output() readonly selectAgent = new EventEmitter<any>();

  private readonly agentRepo: AgentRepository;

  agents: AgentEntity[] = [];
  filterCtrl = new FormControl();
  filterValue: string;
  excludedIds: number[] = [];

  constructor(
    private readonly em: EntityManager,
  ) {
    this.agentRepo = this.em.getRepository('AgentRepository');
  }

  ngOnInit(): void {
    this.agents = this.agentRepo.getObjectList();

    this.filterCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
      .subscribe(value => {
        this.filterValue = value;
      });

    if (this.excludedList) {
      for (const item of this.excludedList) {
        this.excludedIds.push(item.getId());
      }
    }
  }

  onAgentSelect(agent: AgentEntity): void {
    if (this.selectAgent) {
      this.selectAgent.emit(agent);
    }
  }

  get filteredAgents(): AgentEntity[] {
    return _.sortBy(this.agents.filter(agent => {
      if (this.filterValue && this.filterValue.trim() !== '') {
        return (agent.is_online_cc
          && !agent.reserved
          && !agent.is_paused
          && !this.excludedIds.includes(agent.getId())
          && regexSearch(agent.name, this.filterValue));
      }

      return (agent.is_online_cc && !agent.reserved && !agent.is_paused && !this.excludedIds.includes(agent.getId()));
    }), [a => a.name]);
  }

  get filteredBusyAgents(): AgentEntity[] {
    return _.sortBy(this.agents.filter(agent => {
      if (this.filterValue && this.filterValue.trim() !== '') {
        return (agent.is_online_cc
          && agent.reserved
          && agent.is_paused
          && !this.excludedIds.includes(agent.getId())
          && regexSearch(agent.name, this.filterValue));
      }

      return (agent.is_online_cc && (agent.reserved || agent.is_paused) && !this.excludedIds.includes(agent.getId()));
    }), [a => a.name]);
  }
}
