import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import { Deferred } from 'ts-deferred';
import { Injectable } from '@angular/core';

import { WSJsonRPCEngine, ConnectionStateEvent, ConnectionState } from '@wephone-core/rpc/wsjsonrpcengine';
import { CommonAPI } from '@wephone-core/service/common_api';
import { RpcDataCallback } from '@wephone-core/rpc/wsjsonrpcengine';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { SingletonBase } from '@wephone-utils/utils/singleton';
import { get_browser } from '@wephone-utils/utils/util';

@Injectable()
export class WsRpcService extends SingletonBase {

  private static DEFAULT_WEBSOCKET_URL = 'liveapi/push_api';

  private subscription_dict: any = {};

  private rpc_engine: WSJsonRPCEngine;
  private d_connected: Deferred<boolean>;

  private connection_cb_list: Array<any> = [];
  private websocket_url: string;
  public connectionState$: Observable<ConnectionStateEvent>;
  public connectionStateObserver: Observer<ConnectionStateEvent>;
  public dataUpdated$: Observable<any>;
  public dataUpdatedObserver: Observer<any>;

  constructor(
    private settings: FlexIvrSettings
  ) // private authService:AuthenticationService
  {
    super();
    this.d_connected = undefined;

    this.rpc_engine = new WSJsonRPCEngine(this.on_data_handled);

    this.connectionState$ = Observable.create(observer => {
      this.connectionStateObserver = observer;
    })
    .pipe(share());
    this.dataUpdated$ = Observable.create(observer => {
      this.dataUpdatedObserver = observer;
    })
    .pipe(share());
  }

  private sendConnectionStateEvent(e: ConnectionStateEvent) {
    if (this.connectionStateObserver) {
      this.connectionStateObserver.next(e);
    }
  }

  private sendDataUpdatedEvent(data?) {
    if (this.dataUpdatedObserver) {
      this.dataUpdatedObserver.next(data);
    }
  }

  public connect() {
    if (this.isConnected()) {
      return Promise.resolve(true);
    }
    if (this.d_connected) {
      return this.d_connected;
    }
    this.d_connected = new Deferred<boolean>();

    // check domain
    const domain = AuthenticationService.getInstance().getEnterpriseDomain();
    if (!domain) {
      return Promise.reject('Domain not found');
    }
    if (!this.websocket_url) {
      this.websocket_url = this.getWebsocketUrl();
    }
    this.rpc_engine.setRemoteUrl(this.websocket_url);
    return this.rpc_engine.connect(this.on_connection_state);
  }

  private getWebsocketUrl = () => {
    const app_version = window['APPLICATION_VERSION'];
    let ua = '';
    if (window['ELECTRON_APP']) {
      const platform = window['ELECTRON_PLATFORM'];
      ua = `electron-${platform}`;
    } else {
      ua = get_browser()['name'] + get_browser()['version'];
    }

    let ws_url = `${WsRpcService.DEFAULT_WEBSOCKET_URL}?domain=${AuthenticationService.getInstance().getEnterpriseDomain()}&app=${this.settings.app_type}&app_version=${app_version}&ua=${ua}`;
    if (ws_url.indexOf('ws://') === -1 && ws_url.indexOf('wss://') === -1) {
      ws_url = this.settings.getAbsoluteUrl(ws_url);
      let ws_secured = false;
      if (ws_url.indexOf('https://') >= 0) {
        ws_url = ws_url.substr(8);
        ws_secured = true;
      } else if (ws_url.indexOf('http://') >= 0) {
        ws_url = ws_url.substr(7);
      }
      ws_url = (ws_secured ? 'wss://' : 'ws://') + ws_url;
    }
    return ws_url;
  }

  public disconnect() {
    return this.rpc_engine.disconnect();
  }

  public getReconnectionTime = () => {
    return this.rpc_engine.getReconnectionTime();
  }

  public getConnectionState = () => {
    return this.rpc_engine.getConnectionState();
  }

  public isConnected = () => {
    return this.rpc_engine.isConnected();
  }

  private on_data_handled = data => {
    this.sendDataUpdatedEvent();
  }

  private on_connection_state = (e: ConnectionStateEvent) => {
    console.debug('Received connection state from remote ', e.name);
    this.sendConnectionStateEvent(e);
    if (e.name === ConnectionState.Connected) {
      for (const event_name in this.subscription_dict) {
        if (this.subscription_dict.hasOwnProperty(event_name)) {
          let event_params: any;
          let on_data_cb: RpcDataCallback;
          [event_params, on_data_cb] = this.subscription_dict[event_name];
          this.rpc_engine.subscribe_remote_event(event_name, undefined, on_data_cb, this.on_remote_failure);
        }
      }
      if (this.d_connected) {
        this.d_connected.resolve(true);
        this.d_connected = undefined;
      }
    } else if (e.name === ConnectionState.Disconnected) {
      if (this.d_connected) {
        this.d_connected.reject();
        this.d_connected = undefined;
      }
    }
  };

  public subscribeConnectionEvent(connection_cb) {
    this.connection_cb_list.push(connection_cb);
  }

  public subscribe(event_name: string, event_params?: any, on_data_cb?: RpcDataCallback): void {
    this.subscription_dict[event_name] = [event_params, on_data_cb];
    if (this.rpc_engine.isConnected()) {
      this.rpc_engine.subscribe_remote_event(event_name, event_params, on_data_cb, this.on_remote_failure);
    }
  }

  public unsubscribe(event_name: string): void {
    if (this.rpc_engine.isConnected()) {
      this.rpc_engine.unsubscribe_remote_event(event_name);
    }
  }

  public call_remote(method: string, params?: any): Promise<any> {
    return this.rpc_engine.call_remote_v2(method, params, false);
  }

  private on_remote_failure = (failure: any) => {
    console.error('Error getting data from remote', failure);
  }
}
