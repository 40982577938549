import * as _ from 'lodash';

import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, NoWhitespaceValidator, ToastService } from '@wephone-utils';
import { FormBuilder, Validators } from '@angular/forms';
import { _tk, _ti } from '@wephone-translation';
import { TagEntity } from '@wephone-core/model/entity/tag';
import { TagRepository } from '@wephone-core/model/repository/tag';

@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.scss']
})

export class TagEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: TagEntity;

  private tagRepo = TagRepository.getInstance() as TagRepository;

  tag: TagEntity;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.tag = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      tag_name: [this.tag.tag_name, [Validators.required, NoWhitespaceValidator]],
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
  }

  private getFormResetData(): any {
    return {
      tag_name: this.tag.tag_name,
    };
  }

  async submitForm(): Promise<any> {
    const updatedTag = this.tagRepo.create();
    updatedTag.setObjectData({
      id: this.tag.getId(),
      tag_name: this.form.get('tag_name').value,
    });

    try {
      if (!this.form.valid) {
        return;
      }

      const attrList = [
        'tag_name'
      ];
      const resp = await this.tagRepo.saveAttrs(updatedTag, attrList);
      this.toast.showSuccess(_ti('public.message.update_success'));
      // this.tag.setObjectData(returnedTag);
      // this.tagRepo.reload();
      if (resp && resp.object_id) {
        this.tag = this.tagRepo.getObjectById(resp.object_id);
      }
      this.resetForm();
    } catch (e) {
      console.log('Upate tag error: ', e);
      const msg = e && e.error && e.error.message || e && e.message || _ti('public.message.update_failure');
      this.toast.showError(msg);
    }
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }
}
