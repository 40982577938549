import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class LiveUserCallRepository extends BaseRepository {
  ENTITY_CLASS = LiveUserCallEntity;
  // protected URL_PREFIX = 'callcenter/queue';
  protected USE_PUSH_API = true;

  getUserActiveCallInQueue(userId: number, queueId: number): LiveUserCallEntity {
    return (this.object_list || []).find((i: LiveUserCallEntity) => {
      return i.user_id === userId && i.call_queue_id === queueId;
    });
  }
}

import { LiveUserCallEntity } from '../entity/liveusercall';
// import { HttpEngine } from '@wephone-core/service/http_engine';
