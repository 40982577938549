import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class ArchivableRepository extends BaseRepository {
  protected METHOD_PATH_ARCHIVE = 'archive';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'ARCHIVE':
        return this.getUrlV2(this.METHOD_PATH_ARCHIVE);
      default:
        return super.getURLAction(action);
    }
  }

  bulkArchive(items: IEntity[], archive: number): Promise<any> {
    const ids = [];
    for (let i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    const url = this.getURLAction('ARCHIVE');
    return HttpEngine.getInstance().post(url, { ids, archive });
  }
}

import { IEntity } from '@wephone-core/model/model.interface';
import { HttpEngine } from '@wephone-core/service/http_engine';
