<form [formGroup]="openingHourForm">
  <ng-container *ngIf="type !== 'hour'">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxFlex="25" fxFlex.xs="50" class="pr-1">
        <mat-form-field class="full-width">
          <mat-label>{{ 'opening_hour_calendar.content.open_from' | translate }}</mat-label>
          <mat-select data-ci="date_from" formControlName="dayFrom">
            <mat-option *ngFor="let dayOfWeek of weekDays" [value]="dayOfWeek.value">
              {{ dayOfWeek.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="25" fxFlex.xs="50" class="pr-1">
        <mat-form-field class="full-width">
          <mat-label>{{ 'opening_hour_calendar.content.open_to' | translate }}</mat-label>
          <mat-select data-ci="date_to" formControlName="dayTo">
            <mat-option *ngFor="let dayOfWeek of weekDays" [value]="dayOfWeek.value">
              {{ dayOfWeek.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="25" fxFlex.xs="50" class="pr-1">
        <flex-time-picker formControlName="startTime" placeholder="{{ 'public.from' | translate }}"></flex-time-picker>
      </div>
      <div fxFlex="25" fxFlex.xs="50" class="pr-1">
        <flex-time-picker formControlName="endTime" placeholder="{{ 'public.to' | translate }}"></flex-time-picker>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'hour'">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="50" class="pr-1">
        <flex-time-picker formControlName="startTime" placeholder="{{ 'public.from' | translate }}"></flex-time-picker>
      </div>
      <div fxFlex="50" class="pr-1">
        <flex-time-picker formControlName="endTime" placeholder="{{ 'public.to' | translate }}"></flex-time-picker>
      </div>
    </div>
  </ng-container>
</form>
