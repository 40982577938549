import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService, regexSearch } from '@wephone-utils';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { CampaignActiveInfo, OutcallService } from '@wephone-core/service/outcall.service';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
// import { TranslateService } from '@ngx-translate/core';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import * as _ from 'lodash';
import { _ti } from '@wephone-translation';
import { FAIcons } from '@wephone-custom-icon';

interface CampaignActiveInfoDisplayed extends CampaignActiveInfo {
  campaign: OutcallCampaignEntity;
  queue: CallQueueEntity;
  processed_percent: number;
}
@Component({
  selector: 'page-active-outbound-campaigns',
  templateUrl: './active-outbound-campaigns.component.html',
  styleUrls: ['./active-outbound-campaigns.component.scss']
})
export class ActiveOutboundCampaignsComponent implements OnInit, OnDestroy {
  FAIcons = FAIcons;

  orderDir = false;
  orderBy: string;
  activecampaigns: CampaignActiveInfoDisplayed[] = []; // All campaigns
  displayed_campaigns: CampaignActiveInfoDisplayed[] = []; // Displayed campaign
  updateActiveCamapignInterval: any;
  campaigns: {[keys: number]: OutcallCampaignEntity} = {};
  search: string;
  agentSort = {
    sort_column: 'name',
    sort_dir: 1
  };

  private outcallRepo: OutcallCampaignRepository;
  constructor(
    private em: EntityManager,
    private outcallService: OutcallService,
    // private translate: TranslateService,
    protected toast: ToastService) {
    this.outcallRepo = this.em.getRepository('OutcallCampaignRepository') as OutcallCampaignRepository;
  }

  ngOnInit(): void {
    this.orderBy = 'name';
    const campaigns: OutcallCampaignEntity[] = this.outcallRepo.getObjectList();
    for (const campaign of campaigns) {
      this.campaigns[campaign.getId()] = campaign;
    }

    this.refreshList();
  }

  ngOnDestroy(): void {
    this.clearIntervalGetActivecampaign();
  }

  get sortedDisplayedCampaigns(): any[] {
    const ret = _.orderBy(this.displayed_campaigns, [this.orderBy], [this.orderDir ? 'desc' : 'asc']);
    return ret;
  }

  private setIntervalGetActivecampaign(): void {
    this.updateActiveCamapignInterval = setInterval(() => {
      this.getActiveCampaignInfo();
    }, 120 * 1000);
  }

  private clearIntervalGetActivecampaign(): void {
    if (this.updateActiveCamapignInterval) {
      clearInterval(this.updateActiveCamapignInterval);
      this.updateActiveCamapignInterval = undefined;
    }
  }

  filterCampaigns(): void {
    this.displayed_campaigns = this.activecampaigns.filter(x => {
      return this.filterCampaign(x.campaign);
    }).sort(this.sortList);
  }

  async refreshList(): Promise<void> {
    this.activecampaigns = [];
    await this.getActiveCampaignInfo();

    this.clearIntervalGetActivecampaign();
    this.setIntervalGetActivecampaign();
  }

  async getActiveCampaignInfo(): Promise<any> {
    if (_.isEmpty(this.activecampaigns)) {
      this.activecampaigns = [];
    }
    this.activecampaigns.length = 0;
    try {
      const activeCampaigns: CampaignActiveInfo[] = await this.outcallService.getActiveInfo();
      this.activecampaigns = [];

      // Update queue for activecampaigns list
      for (const active_campaign of activeCampaigns) {
        const campaign = this.campaigns[active_campaign.id];
        const c: CampaignActiveInfoDisplayed = _.extend(_.cloneDeep(active_campaign), {
          campaign,
          queue: campaign.queue,
          processed_percent: active_campaign.total_calls ?
          Math.ceil(active_campaign.processed_calls * 100 / active_campaign.total_calls) :
          0
        });

        this.activecampaigns.push(c);
      }

      // Applying filter
      this.filterCampaigns();

    } catch (e) {
      console.error('getActiveCampaignInfo', e);
    }

  }

  updateQueueAttrs(queue: CallQueueEntity, field_list: string[]): void {
    try {
      const repo = this.em.getRepository('CallQueueRepository') as CallQueueRepository;
      repo.saveAttrs(queue, field_list);
      this.toast.showSuccess(_ti('public.message.update_success'));
    } catch (e) {
      console.error('updateQueueAttrs', e);
      if (e.error && e.error.message) {
        this.toast.showError(e.error.message);
      }
    }
  }

  private filterCampaign(item: OutcallCampaignEntity): boolean {
    const filterValue: string = (this.search || '').trim();
    if (!filterValue) {
      return true;
    }

    // const re = new RegExp(this.search, 'i');
    const searchTextList = [item.name, item.alias];

    for (const searchText of searchTextList) {
      // if (search_text && search_text.search(re) !== -1) {
      if (searchText && regexSearch(searchText, filterValue)) {
        return true;
      }
    }

    return false;
  }

  sortList = (item1, item2): number => {
    const n: number = this.orderDir ? 1 : -1;
    const nn: number = n * -1;
    if (this.orderBy === 'name') {
      return item1.name > item2.name ? n : nn;
    }
    if (this.orderBy === 'connected_agents') {
      return item1.connected_agents > item2.connected_agents ? n : nn;
    }
    return 0;
  }

  changeSort($event): void {
    this.filterCampaigns();
  }

  changeDirection($event): void {
    this.orderDir = !this.orderDir;
    this.filterCampaigns();
  }
}
