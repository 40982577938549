import * as _ from 'lodash';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { StorageService } from '@wephone-core/service/storage.service';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { LiveQueueData, LiveStatsService, IChartFilter, ILiveQueueFilter } from '@wephone-core/service/live-stats.service';
import { UserEntity } from '@wephone-core/model/entity/user';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { GroupEntity } from '@wephone-core/model/entity/group';
// import { IGroupEntity } from '@wephone-core/model/entity/group.interface';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { _ti } from '@wephone-translation';
import { DialogService, localNow, parseDateTime } from '@wephone-utils';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { FlexBasePage } from '@wephone-core-ui';
import { DateAdapter } from '@angular/material/core';
import { AccessRight } from '@wephone-core/system';
import { ChartGroupData, ChartPeriod, QosDataType } from './graph-chart-type';
import { DasboardFilterKey } from '../dashboard/dashboard';
import { QueuesFilterPage } from '@wephone/modals/queues-filter/queues-filter';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { DateTime } from 'luxon';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';

@Component({
  selector: 'page-graph-chart',
  templateUrl: './graph-chart.component.html',
  styleUrls: ['./graph-chart.component.scss'],
})
export class GraphChartComponent extends FlexBasePage implements OnInit, OnDestroy {
  static cookie_ui_pref_group_hidden = 'livepage_overviews_ui_pref_group_hidden';
  static defaultStartHour = '09:00';
  static defaultEndHour = '20:00';

  groupIds: number[];
  groupNodes: FlexSelectTreeNode[];
  grouped_queues: ChartGroupData[] = [];
  period_list: { period: ChartPeriod, label: string }[];
  view_mode: 'midnight' | '24h';

  // Filters
  period_start_date: Date;
  period_end_date: Date;
  include_outbound = true;
  ui_pref_group_hidden: number[] = [];
  current_user: UserEntity;
  period: ChartPeriod = ChartPeriod.today;
  showEavesdrop: boolean;
  filteredQueues: CallQueueEntity[] = [];

  myProfile: MyUserProfile;

  call_filters: IChartFilter = {
    time_start: GraphChartComponent.defaultStartHour,
    time_end: GraphChartComponent.defaultEndHour,
  };

  liveQueueInfo: LiveQueueData[] = []; // Cached data

  filteredQueueIds: number[];
  filteredGroupIds: number[];

  // private usergroups_ids: number[];
  // private queueRepo: CallQueueRepository;
  private groupRepo: GroupRepository;
  private readonly hasServiceGroup: boolean;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly liveStatsService: LiveStatsService,
    private readonly storage: StorageService,
    private readonly em: EntityManager,
    private readonly dialogService: DialogService,
    dateAdapter: DateAdapter<Date>,
    private readonly configManager: ConfigManager,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;
    // this.view_mode_list = [
    //   { name: _ti('stats.callcenter.liveinfo.last_24h'), value: '24h' },
    //   { name: _ti('stats.callcenter.liveinfo.from_midnight'), value: 'midnight' }
    // ];
    this.period_list = [
      { period: ChartPeriod.today, label: 'stats.callcenter.liveinfo.today' },
      { period: ChartPeriod.yesterday, label: 'stats.callcenter.liveinfo.yesterday' },
      { period: ChartPeriod.per_week, label: 'stats.callcenter.liveinfo.per_week' },
      { period: ChartPeriod.this_week, label: 'stats.callcenter.liveinfo.this_week' },
      { period: ChartPeriod.this_month, label: 'stats.callcenter.liveinfo.this_month' },
      { period: ChartPeriod.per_month, label: 'stats.callcenter.liveinfo.per_month' }
    ];

    this.view_mode = 'midnight';
    // this.error = undefined;

    this.hasServiceGroup = configManager.hasFeature('SERVICE_GROUP');
    // this.queueRepo = em.getRepository<CallQueueRepository>('CallQueueRepository');
    this.groupRepo = em.getRepository<GroupRepository>('GroupRepository');
  }

  async resolveData(): Promise<void> {
    if (!this.authService.isAdmin()) {
      this.myProfile = await this.configManager.getMyprofile();
      this.filteredQueueIds = this.myProfile.managed_queue_ids;
      this.filteredGroupIds = this.myProfile.managed_group_ids;
    }

    await this.initCallFilters();

    if (!this.ui_pref_group_hidden) {
      this.ui_pref_group_hidden = [];
    }

    this.period_start_date = localNow().startOf('year').toJSDate();
    this.period_end_date = new Date();

    this.current_user = this.authService.getUser();
    // this.usergroups_ids = this.getUserGroupIds();
    this.showEavesdrop = this.authService.hasAccessRight(AccessRight.Eavesdrop) && this.uiShowEavesdrop();

    // this.inboundQeueIds = this.queueRepo.getObjectList().filter(x => !x.is_outcall_campaign).map(q => q.id);

    this.ui_pref_group_hidden = await this.storage.getDomainConfig(GraphChartComponent.cookie_ui_pref_group_hidden) || [];

    await this.refresh();
    this.setGroupedQueues();
  }

  private async initCallFilters(): Promise<void> {
    const chartFilterTime = await this.storage.getDomainConfig(DasboardFilterKey.STORAGE_GRAPH_CALL_FILTERS);

    this.call_filters = {
      queues: chartFilterTime && !_.isEmpty(chartFilterTime.queue_ids) ? chartFilterTime.queue_ids : [],
      time_start: chartFilterTime && chartFilterTime.time_start ? chartFilterTime.time_start : GraphChartComponent.defaultStartHour,
      time_end: chartFilterTime && chartFilterTime.time_end ? chartFilterTime.time_end : GraphChartComponent.defaultEndHour
    };

    this.setFilteredQueues();
  }

  setFilteredQueues(): void {
    this.filteredQueues = [];
    if (this.call_filters && this.call_filters.queues) {
      this.filteredQueues = this.em.getRepository('CallQueueRepository').getObjectListByIds(this.call_filters.queues);
    }

    if (this.filteredQueueIds) {
      this.filteredQueues = this.filteredQueues.filter(x => _.includes(this.filteredQueueIds, x.id));
    }
  }

  async refresh(): Promise<void> {
    await this.storage.setDomainConfig(DasboardFilterKey.STORAGE_GRAPH_CALL_FILTERS, {
      time_start: this.call_filters.time_start,
      time_end: this.call_filters.time_end,
      queue_ids: this.call_filters.queues
    });

    this.grouped_queues = []; // Refresh current result
    await this.set_ui_pref_group_domain_cfg();
    await this.reload_data();
    this.setGroupedQueues();
    this.detectChanges();
  }

  private setGroupedQueues(): void {
    const grouped_queues = this.get_grouped_queues();
    this.grouped_queues = grouped_queues.filter(group => this.ui_pref_group_hidden.indexOf(group.id) === -1);

    // if (this.filteredGroupIds) {
    //   this.grouped_queues = this.grouped_queues.filter(x => _.includes(this.filteredGroupIds, x.id));
    // }
  }

  private getChartGroupData(g: GroupEntity): ChartGroupData {
    return {
      id: g.id,
      name: g.name,
      children: g.children,
      all_children_ids: g.all_children.map(c => c.id),
      queues: [],
      qos_data: new QosDataType(),
    };
  }

  private isInboundQueue(callQueueId: number): boolean {
    const queue = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(callQueueId);
    if (!queue) {
      return false;
    }

    return !queue.is_outbound_campaign || queue.is_outbound_campaign !== 1;
  }

  filterChartData(): void {
    // const queuesInfo: LiveQueueData[] = await this.liveStatsService.liveStatsGetQueueInfo(false, this.call_filters) || [];
    const queuesInfo: LiveQueueData[] = this.liveQueueInfo;
    const ccQueues: LiveQueueData[] = queuesInfo.filter(q => {
      return this.isInboundQueue(q.queue_id);
    });

    this.dialogService.openDialog2(
      QueuesFilterPage,
      {
        data: {
          show_date: false,
          show_queue: true,
          queues: ccQueues || [],
          time_start: this.call_filters.time_start,
          time_end: this.call_filters.time_end,
          filter_queue_ids: this.call_filters.queues,
          has_routed: false,
        },
      },
      ret => {
        if (!ret) {
          return;
        }

        this.call_filters.queues = ret.filter_queue_ids;
        this.call_filters.time_start = ret.time_start;
        this.call_filters.time_end = ret.time_end;
        this.setFilteredQueues();

        this.refresh();
      }
    );
  }

  /**
   * Callback when click on button hide
   */
  toogleGroup($event: any): void {
    const group_id: number = $event.group_id;
    const hidden: boolean = $event.hidden;
    this.set_ui_pref_group_hidden(group_id, hidden);
  }

  /**
   * Set for 1 node
   */
  set_ui_pref_group_hidden(group_id: number, value: boolean): void {
    // Remove from hidden list
    if (this.ui_pref_group_hidden.indexOf(group_id) !== -1) {
      this.ui_pref_group_hidden.splice(this.ui_pref_group_hidden.indexOf(group_id), 1);
    }
    // Add to hidden list if value is True
    if (value) {
      this.ui_pref_group_hidden.push(group_id);
    }

    this.grouped_queues = []; // Refresh list

    this.set_ui_pref_group_domain_cfg();
    this.setGroupedQueues();
  }

  private set_ui_pref_group_domain_cfg(): Promise<any> {
    return this.storage.setDomainConfig(GraphChartComponent.cookie_ui_pref_group_hidden, this.ui_pref_group_hidden);
  }

  private isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  private isSupervisor(): boolean {
    return this.authService.isSupervisor();
  }

  private uiShowEavesdrop(): boolean {
    return this.isAdmin() || this.isSupervisor();
  }

  private update_group_qos_data(group: ChartGroupData): void {
    const qos_data: QosDataType = {
      answered: 0,
      total_inbound: 0,
      total_outbound: 0,
      handled: 0,
      total: 0,
      waiting_call_count: 0,
      total_handling_time: 0,
      total_after_call_waiting_time: 0,
      total_before_answer_waiting_time: 0,
      qos: 0,
      dmc: 0,
      acw: 0,
      dmt: 0,
      dmr: 0
    };

    if (group.children && group.children.length) {
      for (const g of group.children) {
        this.update_group_qos_data(g);
        qos_data.answered += g.qos_data['answered'];
        qos_data.total_inbound += g.qos_data['total_inbound'];
        qos_data.total_outbound += g.qos_data['total_outbound'];
        qos_data.handled += g.qos_data['handled'];
        qos_data.total += g.qos_data['total'];
        qos_data.total_handling_time += g.qos_data['total_handling_time'];
        qos_data.total_after_call_waiting_time += g.qos_data['total_after_call_waiting_time'];
        qos_data.total_before_answer_waiting_time += g.qos_data['total_before_answer_waiting_time'];
        if (g.qos_data['waiting_call_count']) {
          qos_data.waiting_call_count += g.qos_data['waiting_call_count'];
        }
      }
    }

    if (group.queues && group.queues.length) {
      const queues: LiveQueueData[] = group.queues.filter((q: LiveQueueData) => this.isInboundQueue(q.queue_id));
      for (const q of queues) {
        qos_data.answered += q.qos_data['answered'];
        qos_data.total_inbound += q.qos_data['total_inbound'];
        qos_data.total_outbound += q.qos_data['total_outbound'];
        qos_data.handled += q.qos_data['handled'];
        qos_data.total += q.qos_data['total'];
        qos_data.total_handling_time += q.qos_data['dmc'] * q.qos_data['handled'];
        qos_data.total_after_call_waiting_time += q.qos_data['acw'] * q.qos_data['handled'];
        qos_data.total_before_answer_waiting_time += q.qos_data['dmr'] * q.qos_data['handled'];
        qos_data.waiting_call_count += q.waiting_call_count;
      }
    }

    // Calculate qos, dmc, acw, dmt
    if (qos_data.total_inbound > 0) {
      qos_data.qos = Math.round(qos_data.answered * 100 / qos_data.total_inbound);
    }
    if (qos_data.handled > 0) {
      qos_data.dmc = Math.round(qos_data.total_handling_time / qos_data.handled);
      qos_data.acw = Math.round(qos_data.total_after_call_waiting_time / qos_data.handled);
      qos_data.dmr = Math.round(qos_data.total_before_answer_waiting_time / qos_data.handled);
      qos_data.dmt = qos_data.dmc + qos_data.acw;
    }
    group.qos_data = qos_data;
  }

  changePeriod(period: ChartPeriod): void {
    this.period = period;
    this.refresh();
  }

  private get_grouped_queues(): ChartGroupData[] {
    const ret: ChartGroupData[] = [];
    const groups: GroupEntity[] = this.hasServiceGroup ? this.groupRepo.getUserGroupTree() : [];

    const empty_group: ChartGroupData = this.getChartGroupData(new GroupEntity());
    if (!this.hasServiceGroup) {
      empty_group.name = ' ';
    }
    let grouped_queues: ChartGroupData[] = [];

    // If has groups: Update all data from queue belongs to group (include empty group - means queues from no-group)
    if (groups.length) {
      grouped_queues = groups.map(i => this.getChartGroupData(i));
      grouped_queues.push(empty_group);

      // Set queues belong group include no-group
      for (const group of grouped_queues) {
        this.set_queues_stats_data(group, false);
      }
    } else {
      grouped_queues.push(empty_group);

      // If no group or not support group: Update all data from all queues
      this.set_queues_stats_data(empty_group, true);
    }

    // Update live queues data for group
    for (const group of grouped_queues) {
      // if (!this.hasPermissionGroup(group.id)) {
      //   continue;
      // }

      this.update_group_qos_data(group);
      ret.push(group);
    }

    return _.sortBy(ret, [n => n.name]);
  }

  // private _set_all_queues_stats_data(group: any): void {
  //   group.queues = this.liveStatsService.queue_live_info.filter(i => this.isCallCenterQueue(i.queue_id));
  // }

  private matchFilteredQueue(qid: number): boolean {
    return !this.call_filters || _.isEmpty(this.call_filters.queues) || _.includes(this.call_filters.queues, qid);
  }

  private set_queues_stats_data(group: ChartGroupData, includeAllQueues: boolean = false): void {
    // Get live queue info from server
    let queueLiveInfo: LiveQueueData[] = this.liveQueueInfo.filter(item => // this.liveStatsService.queue_live_info.filter(item =>
      (includeAllQueues || !includeAllQueues && item.group_id === (group.id || 0))
    );

    const queueIdsLiveInfo = queueLiveInfo.map(x => x.queue_id);

    // Include empty info from queues belongs to group
    const queues: CallQueueEntity[] = (this.em.getRepository('CallQueueRepository') as CallQueueRepository)
      .get_queues_by_group_id(group.id, false, 'inbound', group.id ? false : true)
      .filter(x => !_.includes(queueIdsLiveInfo, x.id));

    if (!_.isEmpty(queues)) {
      const emptyLiveInfo = this.liveStatsService.getEmptyData(queues);
      queueLiveInfo = _.concat(queueLiveInfo, emptyLiveInfo);
    }

    group.queues = queueLiveInfo.filter(item => this.isInboundQueue(item.queue_id) && this.matchFilteredQueue(item.queue_id) &&
      (_.isUndefined(this.filteredQueueIds) || _.includes(this.filteredQueueIds, item.queue_id))
    );

    if (!includeAllQueues) {
      if (group.children && group.children.length) {
        for (const child_group of group.children) {
          this.set_queues_stats_data(child_group);
        }
      }
    }
  }

  get has_hidden_group(): boolean {
    return this.ui_pref_group_hidden && this.ui_pref_group_hidden.length > 0;
  }

  get_group_name_by_id(group_id: number): string {
    const group = this.groupRepo.getObjectById(group_id);

    return group ? group.name : '';
  }

  private async reload_data(): Promise<any> {
    // this.liveStatsService.setIncludeOutbound(this.include_outbound);
    // this.liveStatsService.setPeriod(this.period);

    let periodStartDate: string;
    let periodEndDate: string;

    const filteredStats: ILiveQueueFilter = {
      period: this.period,
      include_outbound: this.include_outbound ? 1 : 0,
    };

    if (this.period === ChartPeriod.per_week) {
      periodStartDate = DateTime.fromJSDate(this.period_start_date).toFormat('yyyy-MM-dd');
      periodEndDate = DateTime.fromJSDate(this.period_end_date).toFormat('yyyy-MM-dd');
      _.extend(filteredStats, {
        period_start_date: periodStartDate,
        period_end_date: periodEndDate,
      });
    }

    this.liveQueueInfo = await this.liveStatsService.getLiveQueueInfo(filteredStats, this.call_filters);
    return this.liveQueueInfo;
  }

  // private hasPermissionGroup(groupId: number): boolean {
  //   if (!groupId || this.isAdmin()) {
  //     return true;
  //   }

  //   return this.usergroups_ids.includes(groupId);
  // }

  // private isCallCenterQueue(queueId: number): boolean {
  //   return this.inboundQeueIds.includes(queueId);
  // }

  // private getUserGroupIds(): number[] {
  //   if (!(this.current_user.group_ids || []).length) {
  //     return [];
  //   }

  //   const group_ids: number[] = [];
  //   const group_list: GroupEntity[] = this.groupRepo.getObjectListByIds(this.current_user.group_ids);

  //   for (const g of group_list) {
  //     const parent_ids = this.getGroupParentIds(g);
  //     group_ids.push(g.getId());
  //     for (const i of parent_ids) {
  //       if (group_ids.indexOf(i) === -1) {
  //         group_ids.push(i);
  //       }
  //     }
  //   }

  //   return group_ids;
  // }

  // private getGroupParentIds(group: IGroupEntity): number[] {
  //   const parent_ids: number[] = group.all_parent ? group.all_parent.map(p => p.id) : [];
  //   return parent_ids;
  // }
}
