<mat-accordion *ngIf="has_show_group">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header class="bgpanel-group mat-blue">
      <mat-panel-title>
        <span class="text-white">{{ group.name || ('livecall.content.queue_no_group'|translate) }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!--content-->
    <!-- {{group.id}}-
    <span *ngIf="filteredGroupIds">{{filteredGroupIds.join(',')}}</span> -->
    <div fxLayout="column">
      
      <mat-chip-list class="my-1" *ngIf="groupChildrenHidden.length">
        <mat-chip 
          *ngFor="let g_hidden of groupChildrenHidden"
          color="primary" 
          (removed)="set_ui_pref_group_hidden(g_hidden.getId(), false)">
          {{ g_hidden.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
  
      <div fxLayout="row" class="my-1">
        <mat-chip-list fxFlex="auto" 
          fxLayout="row wrap" 
          fxLayoutAlign="start center" 
          fxLayoutGap="20px" 
          fxLayoutGap.xs="0">
          <div fxLayout="row" fxLayoutAlign="start center">
            <strong>QoS :</strong>
            <mat-chip color="primary">{{group.qos_data.qos}}%</mat-chip>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <strong>{{ 'livecall.overview.received_calls' | translate }} :</strong>
            <mat-chip color="primary">{{group.qos_data.total_inbound}}</mat-chip>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <strong>{{ 'livecall.overview.answered_calls' | translate }} :</strong>
            <mat-chip color="primary">{{group.qos_data.answered}}</mat-chip>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <strong>{{ 'livecall.overview.outbound_calls' | translate }} :</strong>
            <mat-chip color="primary">{{group.qos_data.total_outbound}}</mat-chip>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <strong>DMR: </strong>
            <mat-chip color="primary">{{group.qos_data.dmr | seconds_as_duration}}</mat-chip>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <strong>DMT :</strong>
            <mat-chip color="primary">{{group.qos_data.dmt | seconds_as_duration}}</mat-chip>
          </div>
        </mat-chip-list>  
        <div *ngIf="group.id"
          fxFlex="100px" fxLayout="row" fxLayout.xs="column" 
          fxLayoutAlign="end center" fxLayoutAlign.xs="end" 
          fxLayoutGap="20px" fxLayoutGap.xs="0">
          
          <button mat-stroked-button color="primary" 
            (click)="set_ui_pref_group_hidden(group.id, true)" class="border-button">
              {{ 'public.hide' | translate }}
          </button>    
          <button *ngIf="showEavesdrop" 
            mat-stroked-button color="primary" 
            (click)="call_eavesdrop_group(group.id)"  class="border-button">{{ 'livecall.actions.eavesdrop' | translate }}
          </button>
        </div>
      </div>

      <div fxLayout="row" *ngFor="let g_displayed of groupChildrenDisplayed">
        <app-graph-chart-group 
          (onHidePanel)="hideGroup($event)" 
          [filteredGroupIds]="filteredGroupIds"
          [hiddenGroups]="hiddenGroups"
          [group]="g_displayed" 
          [period]="period" 
          [callFilters]="callFilters"
          [showEavesdrop]="showEavesdrop"
          fxFlex="100" ></app-graph-chart-group>
      </div>

      <div fxLayout="row"  *ngFor="let q of getQueuesFromGroup(group)">
        <app-graph-chart-queue fxFlex="100"  *ngIf="hasPermissionView(group.id)"
          [period]="period" 
          [callFilters]="callFilters"
          [queue]="q" 
          [showEavesdrop]="showEavesdrop"></app-graph-chart-queue>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>
