import { Injectable } from '@angular/core';
import { LocationEntity } from '@wephone-core/model/entity/location';
import { HttpEngine } from './http_engine';
import { EntityManager } from './entity_manager';

@Injectable()
export class LocationService {
  private static instance: LocationService = null;

  private static URL_LIST_LOCATION_BY_SIP_NUMBER = 'location/list_by_sip_number';
  private static URL_RESTART_SIP_PHONE = 'callcenter/location/restart_sip_phone';

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly em: EntityManager,
  ) { }

  async getLocationsBySipNumber(sip_number: string): Promise<LocationEntity[]> {
    const params = {
      sip_number
    };
    const locations = await this.httpEngine.apiGetV2(LocationService.URL_LIST_LOCATION_BY_SIP_NUMBER, params) || [];
    return locations.map(o => {
      // const location = new LocationEntity();
      // location.setObjectData(o, true);
      const location = this.em.getRepository('LocationRepository').create(o, true);
      return location;
    });
  }

  restartSipPhone(locationId: number): Promise<any> {
    const params = {
      location_id: locationId
    };
    return this.httpEngine.apiPostV2(LocationService.URL_RESTART_SIP_PHONE, params);
  }
}
