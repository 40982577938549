import { Entity, datafield } from '@wephone-core/model/model';
import { parseDateTime } from '@wephone-utils';
import * as _ from 'lodash';
import { _ti } from '@wephone-translation';
import { DateTime } from 'luxon';

export class LocationEntity extends Entity {
  static object_type_id = 'location';

  id: number;
  @datafield contact: string;
  @datafield expires: DateTime;
  @datafield last_modified: DateTime;
  @datafield user_agent: string;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    this.expires = object_data.expires && parseDateTime(object_data.expires) || null;
    this.last_modified = object_data.last_modified && parseDateTime(object_data.last_modified) || null;
  }
}
