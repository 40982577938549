import { Input, ElementRef, OnInit, Directive, ChangeDetectorRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FlexMatInputBase } from './flex-mat-input-base';

@Directive()
export abstract class FlexMatInputWrapper extends FlexMatInputBase implements OnInit {
  constructor(ngControl: NgControl, elRef: ElementRef, protected cdr?: ChangeDetectorRef) {
    super(ngControl, elRef, cdr);
  }

  abstract get wrappedControl(): FormControl;

  ngOnInit(): void {
    super.ngOnInit();
    this.wrappedControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
      this.onChange();
    });
  }

  get empty(): boolean {
    return !this.wrappedControl.value;
  }

  @Input()
  get value(): any {
    return this.wrappedControl.value;
  }
  set value(val: any) {
    this._value = val;
    this.wrappedControl.setValue(val);
    this.stateChanges.next();
  }

  setValueNoEmit(val: any): void {
    this.wrappedControl.setValue(val, {onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true});
  }

  writeValue(val): void {
    if (val != this.value) {
      this.wrappedControl.setValue(val);
    }
  }

/*
  ngDoCheck(): void {
    this.errorState = !!this.ngControl.errors;
  }
*/

  get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }
}
