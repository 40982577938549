import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IOpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar.i';
import { OpeningHourCalendarEntity, LinkedObjectType } from '@wephone-core/model/entity/openinghour_calendar';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
// import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { HttpEngine } from '@wephone-core/service/http_engine';

export class OpeningHourCalendarRepository extends BaseRepository implements IOpeningHourCalendarRepository {
  ENTITY_CLASS = OpeningHourCalendarEntity;
  protected URL_PREFIX = 'callcenter/openinghour';
  protected USE_PUSH_API = false;
  protected RELATED_REPOSITORIES = ['did'];

  protected METHOD_PATH_UPDATE_LINK_OBJECT_ORDERS = 'update_link_object_orders';
  private METHOD_GET_OBJECTS_USING_CALENDARS = 'objects-used';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      case 'UPDATE_LINK_OBJECT_ORDERS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_LINK_OBJECT_ORDERS);
      case 'GET_OBJECTS_USING_CALENDARS':
        return this.getUrlV2(this.METHOD_GET_OBJECTS_USING_CALENDARS);
      default:
        return super.getURLAction(action);
    }
  }

  public createDefaultCalendar(name: string): OpeningHourCalendarEntity {
    // Check name exist & find appropriate name
    let calendar_exist = false;
    let exist_name = name;
    let count = 1;
    while (true) {
      calendar_exist = false;
      for (const calendar of this.getObjectList()) {
        if (calendar.name === exist_name) {
          exist_name = name + ' ' + count;
          calendar_exist = true;
        }
      }
      if (!calendar_exist) {
        break;
      }
      count++;
    }

    const openinghour_calendar: OpeningHourCalendarEntity = EntityManager.getRepository<OpeningHourCalendarRepository>(
      'OpeningHourCalendarRepository'
    ).create() as OpeningHourCalendarEntity;
    openinghour_calendar.name = exist_name;

    const openinghours = [{ start_time: '09:00', week_day: [1, 2, 3, 4, 5], end_time: '18:00' }];
    for (const openinghour of openinghours) {
      const opening_hour: OpeningHourEntity = EntityManager.getRepository(
        'OpeningHourRepository'
      ).create() as OpeningHourEntity;
      opening_hour.setObjectData(openinghour);
      openinghour_calendar.addOpeningHour(opening_hour);
    }

    return openinghour_calendar;
  }

  public getCalendarsLinkToDidByDidId(did_id: number): OpeningHourCalendarEntity[] {
    const dependent_list = [];
    for (const item of this.getObjectList()) {
      if (!item.link_object_type || !item.link_object_id) {
        continue;
      }
      if (item.link_object_type === LinkedObjectType.LINK_OBJECT_DID && item.link_object_id === did_id) {
        dependent_list.push(item);
      }
    }
    return dependent_list;
  }

  getObjectsUsingCalendars(ids: number[]): Promise<any> {
    const url = this.getURLAction('GET_OBJECTS_USING_CALENDARS');
    return HttpEngine.getInstance().get(url, { 'ids[]': ids });
  }

  updateLinkObjectOrders(calendars: OpeningHourCalendarEntity[]): Promise<any> {
    const postData = calendars.map((i: OpeningHourCalendarEntity) => {
      const c = i.dumpObjectData();
      return { id: c.id, link_object_order: c.link_object_order };
    });

    const url = this.getURLAction('UPDATE_LINK_OBJECT_ORDERS');

    return HttpEngine.getInstance().post(url, { data: postData });
  }
}
