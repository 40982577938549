<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="pa-1">
    <div formArrayName="itemRows">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
        *ngFor="let itemrow of itemRowsCtrl.controls; let i=index" [formGroupName]="i">
        <div fxFlex="45">
          <mat-form-field class="full-width">
            <mat-label>{{ 'sipphone.content.from_extension'|translate }}</mat-label>
            <input matInput placeholder="{{ 'sipphone.content.from_extension'|translate }}" formControlName="from"
              type="number" min="100">
            <mat-error *ngIf="itemRowsCtrl.controls[i].get('from').hasError('required')">
              {{'form.validator.field_required'|translate}}
            </mat-error>
            <mat-error *ngIf="itemRowsCtrl.controls[i].get('from').hasError('min')">
              {{'form.validator.field_min_value'|translate: {min:100} }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="45">
          <mat-form-field class="full-width">
            <mat-label>{{ 'sipphone.content.to_extension'|translate }}</mat-label>
            <input matInput placeholder="{{ 'sipphone.content.to_extension'|translate }}" type="number" min="100"
              formControlName="to">
            <mat-error *ngIf="itemRowsCtrl.controls[i].get('to').hasError('required')">
              {{'form.validator.field_required'|translate}}
            </mat-error>
            <mat-error *ngIf="itemRowsCtrl.controls[i].get('to').hasError('min')">
              {{'form.validator.field_min_value'|translate: {min:100} }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="5" align="end">
          <button mat-icon-button (click)="deleteRow(i)" [disabled]="form.get('itemRows').value.length <= 1">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
