import { Entity, datafield } from '@wephone-core/model/model';
import * as _ from 'lodash';

export class EnterpriseSipGatewayEntity extends Entity {
  static object_type_id = 'enterprise_sip_gateway';

  id: number;
  @datafield name: string;
  @datafield model: string;
  @datafield firmware: string;
  @datafield mac: string;
  @datafield port_count: number;
  @datafield port_map: any;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    if (typeof object_data.port_map === 'string') {
      this.port_map = JSON.parse(object_data.port_map || '{}');
    }
  }
}
