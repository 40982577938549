import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { DidEntity } from '@wephone-core/model/entity/did';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { HotelRoomEntity } from '@wephone-core/model/entity/hotelroom';
import { IHotelRoomEntity } from '@wephone-core/model/entity/hotelroom.i';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { UserEntity } from '@wephone-core/model/entity/user';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { EntityManager } from '@wephone-core/wephone-core.module';

import { _tk, _ti } from '@wephone-translation';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';

@Component({
  selector: 'number-routing-dest',
  templateUrl: './number-routing-dest.html',
  styleUrls: ['./number-routing-dest.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberRoutingDestComponent implements OnInit, OnChanges {
  @Input() did: DidEntity;
  @Input() notOfficeHour: boolean;
  @Input() updateLayout: boolean;
  @Input() enableTooltip: boolean;

  routingParams: {
    app: RoutingAppName | string;
    label: string;
    display_name: string;
    id: number;
    url_edit: string;
    object?: IUserEntity | IHotelRoomEntity;
  } = {
      app: null,
      label: null,
      display_name: null,
      id: null,
      url_edit: null,
    };

  constructor(
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.getCallDestination();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCallDestination();
  }

  gotoEditDidPage(destinationDid: DidEntity): void {
    this.router.navigateByUrl(`number-routing/${destinationDid.id}?id=${destinationDid.id}`);
  }

  getCallDestination(): any {
    const did: DidEntity = this.did;
    this.routingParams = null;

    if (did.hasLinkedUser()) {
      // const userName = did.routed_user && did.routed_user.name || '';
      // return _ti('did.content.user_dedicated_number') + ` ${userName}`;
      return;
    }

    if (did.master_did) {
      // return _ti('did.content.tracking_number_of', { did_number: did.master_did.display_number });
      this.routingParams = {
        app: 'alias',
        label: _ti('did.content.tracking_number_of', { did_number: '' }),
        display_name: did.master_did.number_with_name,
        id: this.did.master_did_id,
        url_edit: `/number-routing/${this.did.master_did_id}`
      };
      return;
    }

    const routingAction = this.notOfficeHour ? did.action_not_office_hour : did.action_office_hour;
    if (!routingAction) {
      // console.warn('No routing action params');
      return;
    }

    this.routingParams = {
      label: _ti('routing_application.' + routingAction.application + '.app_name'),
      app: routingAction.application,
      display_name: null,
      id: null,
      url_edit: null
    };

    try {
      switch (routingAction.application) {
        case RoutingAppName.remote_application:
          const app: IvrRemoteAppEntity = EntityManager.getRepository('IvrRemoteAppRepository').getObjectById(routingAction.params.id);
          if (app) {
            this.routingParams.display_name = app.name;
            this.routingParams.id = app.id;
            this.routingParams.url_edit = `developpers/ivr-remote-apps/${app.id}`;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }
          break;
        case RoutingAppName.runvxmlscript:
          const script: IvrScriptEntity = EntityManager.getRepository('IvrScriptRepository').getObjectById(routingAction.params.ivrscript_id);
          if (script) {
            this.routingParams.display_name = script.name;
            this.routingParams.id = script.id;
            this.routingParams.url_edit = `/developpers/ivr-application/${script.id}`;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }
          break;
        case RoutingAppName.callqueue:
          const queue: CallQueueEntity = EntityManager.getRepository('CallQueueRepository').getObjectById(routingAction.params.queue);
          if (queue) {
            this.routingParams.display_name = queue.queue_name;
            this.routingParams.id = queue.id;
            this.routingParams.url_edit = `/queues/${queue.id}`;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }
          break;
        case RoutingAppName.conference:
          const conference: ConferenceEntity = EntityManager.getRepository('ConferenceRepository').getObjectById(routingAction.params.conference);
          if (conference) {
            this.routingParams.display_name = conference.name;
            this.routingParams.id = conference.id;
            this.routingParams.url_edit = `/telephony/conferences/${conference.id}`;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }

          break;
        case RoutingAppName.ivr_custom_menu:
          const ivrMenu: IvrCustomMenuEntity = EntityManager.getRepository('IvrCustomMenuRepository')
            .getObjectById(routingAction.params.id);
          if (ivrMenu) {
            this.routingParams.display_name = ivrMenu.name;
            this.routingParams.id = ivrMenu.id;
            this.routingParams.url_edit = `/ivr/ivr-menus/${ivrMenu.id}`;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }

          break;
        case RoutingAppName.call_phone:
          const sipphone: SipPhoneEntity = EntityManager.getRepository('SipPhoneRepository')
            .getObjectById(routingAction.params.sipphone_id);
          if (sipphone) {
            this.routingParams.display_name = sipphone.extension_with_owner;
            this.routingParams.id = sipphone.id;
            this.routingParams.url_edit = `/telephony/sip-phones/${sipphone.id}`;
            this.routingParams.object = sipphone.user || sipphone.hotelroom;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }
          break;
        case RoutingAppName.sip_trunk:
          const sipTrunk: SipTrunkEntity = EntityManager.getRepository('SipTrunkRepository')
            .getObjectById(routingAction.params.siptrunk_id);
          if (sipTrunk) {
            this.routingParams.display_name = sipTrunk.name;
            this.routingParams.id = sipTrunk.id;
            this.routingParams.url_edit = `/telephony/sip-trunk/${sipTrunk.id}`;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }
          break;
        case RoutingAppName.crm_route:
          const crmRR: CrmRoutingRuleEntity = EntityManager.getRepository('CrmRoutingRuleRepository')
            .getObjectById(routingAction.params.id);
          if (crmRR) {
            this.routingParams.display_name = crmRR.name;
            this.routingParams.id = crmRR.id;
            this.routingParams.url_edit = `/crm/crm-routing/${crmRR.id}`;
          } else {
            this.routingParams.display_name = 'Invalid destination';
          }
          break;
        case RoutingAppName.call_phone_number:
          this.routingParams.display_name = routingAction.params.number;

          break;

        case RoutingAppName.play_then_hangup:
          let sound: FileEntryEntity;
          const isDefaultSound: boolean = routingAction.params && !routingAction.params.sound_id;

          if (!isDefaultSound) {
            sound = EntityManager.getRepository('FileEntryRepository')
              .getObjectById(routingAction.params.sound_id);
          } else {
            sound = (EntityManager.getRepository('FileEntryRepository') as FileEntryRepository)
              .getDefaultFileEntry('service_closed');
          }

          if (sound) {
            if (!isDefaultSound) {
              this.routingParams.display_name = sound.name;
              this.routingParams.id = sound.id;
              this.routingParams.url_edit = `/media/reusable-sound/${sound.id}`;
            } else {
              this.routingParams.display_name = _ti('default_sounds.service_closed');
            }
          } else {
            this.routingParams.display_name = _ti('did.routing_invalid.invalid_destination.hangupsound');
          }
          break;

        case RoutingAppName.webservice:
          this.routingParams.display_name = routingAction.params.url;

          break;
        default:
          break;
      }

      return this.routingParams;
    } catch (error) {
      console.error('Get routing params error', error);
      this.routingParams = null;
    }
  }

  gotoEditPage(url: string): void {
    this.router.navigateByUrl(url);
  }

  gotoEditUserOrHotelRoomPage(object: IUserEntity | HotelRoomEntity): void {
    const url = object instanceof HotelRoomEntity ? `hotelroom/${object.id}` : `manage-users/users/${object.id}`;
    this.router.navigateByUrl(url);
  }
}
