import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class RoutingAppCrmRoute extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.crm_route;
  private crmRouting: CrmRoutingRuleEntity;

  setCrmRoutingApp(crm_routing_app: CrmRoutingRuleEntity): void {
    this.setAppParams({ id: crm_routing_app.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.id) {
      this.crmRouting = EntityManager.getRepository('CrmRoutingRuleRepository').getObjectById(this.appParams.id);
    }
  }

  getRelatedObject(): CrmRoutingRuleEntity {
    return this.crmRouting;
  }

  getDescription(): string {
    const crm_route_name: string = this.crmRouting ? this.crmRouting.name : '<invalid>';

    return `To routing CRM  ${crm_route_name}`;
  }
}
