import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { HttpEngine } from '@wephone-core/service/http_engine';
// import * as adyenEncrypt from 'adyen-cse-web';
import { SystemParam } from '@wephone-core/system';
// declare var adyen: any;

@Injectable()
export class PaymentAdyenService {
  private cseInstance: any;

  private URL_SAVE_CREDIT_CARD = '/api/payment/save_credit_card';
  private URL_CAPTURE_PAYMENT = '/api/payment/adyen/capture';
  private URL_GENERATION_TIME = '/api/payment/adyen/generationtime';

  constructor(
    private config_manager: ConfigManager,
    private httpEngine: HttpEngine) {
    const public_key = this.config_manager.getSystemParam(SystemParam.adyen_public_key);
    if (public_key) {
      // this.cseInstance = adyen.encrypt.createEncryption(public_key, {});
      // this.cseInstance = adyenEncrypt.createEncryption(public_key, {});
    }
  }

  async addTimeStampInfo(creditcard_info: any): Promise<any> {
    const response = await this.httpEngine.get(this.URL_GENERATION_TIME, {});
    const ret = {};
    _.extend(ret, creditcard_info, response);
    return ret;
  }

  async saveCreditCard(creditcard_info: any): Promise<any> {
    const encrypted_data = await this.encryptCardData(creditcard_info);
    if (!encrypted_data) {
      console.error('Encrypted data error');
      return Promise.reject();
    }
    return this.httpEngine.post(this.URL_SAVE_CREDIT_CARD, { card_info: encrypted_data })
  }

  async checkCardData(creditcard_info: any): Promise<boolean> {
    const resp = await this.encryptCardData(creditcard_info);
    return resp ? true : false;
  }

  encryptCardData(creditcard_info: any): Promise<any> {
    return this.addTimeStampInfo(creditcard_info).then(
      (extended_card_info: any) => {
        return this.cseInstance.encrypt(extended_card_info);
      }
    );
  }
}
