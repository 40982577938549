<ng-container *ngIf="dependentCalendar">
  <calendar-view [calendar]="dependentCalendar" [clickToEdit]="!!dependentCalendar.id" [flatLayout]="false">
  </calendar-view>
</ng-container>
<mat-tab-group [selectedIndex]="stepActive" (selectedIndexChange)="gotoStep($event + 1)" class="ma-0 pa-0">
  <mat-tab>
    <ng-template mat-tab-label>
      <h3>1. {{ 'dialogs.did_setup_wizard.step1.title'|translate }}</h3>
    </ng-template>
    <mat-radio-group [(ngModel)]="routeTo.destination" fxLayout="row warp" fxLayout.xs="column"
      class="mx-0 mb-0 pa-0 mt-1">

      <h3 fxFlex="100" class="mt-0 mb-1">{{ 'dialogs.did_setup_wizard.step2.common_dest'|translate }}</h3>
      <mat-radio-button *ngIf="data.full_option" value="callqueue" fxFlex="33.33" fxFlex.xs="100" class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_queue'|translate}}
      </mat-radio-button>

      <mat-radio-button *ngIf="data.full_option" value="ivr_custom_menu" fxFlex="33.33" fxFlex.xs="100"
        [disabled]="!hasIvrMenu()" class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_ivrmenu'|translate}}
      </mat-radio-button>

      <mat-radio-button value="call_phone_number" fxFlex="33.33" fxFlex.xs="100" class="mb-1" id="call_phone_number">
        {{'dialogs.did_setup_wizard.step2.route_to_phone_number'|translate}}
      </mat-radio-button>

      <mat-radio-button *ngIf="data.full_option" value="call_phone" fxFlex="33.33" fxFlex.xs="100"
        [disabled]="!hasSipPhone()" class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_sipphone'|translate}}
      </mat-radio-button>

      <mat-radio-button value="play_then_hangup" fxFlex="33.33" fxFlex.xs="100" class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_sound'|translate}}
      </mat-radio-button>

      <mat-radio-button *ngIf="isEnabledSipTrunk" value="sip_trunk" fxFlex="33.33" fxFlex.xs="100" class="mb-1" id="sip_trunk">
        {{'dialogs.did_setup_wizard.step2.route_to_sip_trunk'|translate}}
      </mat-radio-button>

      <!-- <mat-radio-button value="call_user" fxFlex="33.33" fxFlex.xs="100" class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_user'|translate}}
      </mat-radio-button> -->

      <div fxFlex="100" *ngIf="!routingForCrm && data.full_option">
        <h3 class="mt-0 mb-1">{{ 'dialogs.did_setup_wizard.step2.tracking_number'|translate }}</h3>
        <mat-radio-button [disabled]="!dedicatedDidIds.length" value="master_did" class="mb-1" id="master_did">
          {{'dialogs.did_setup_wizard.step2.tracking_number_hint'|translate}}
        </mat-radio-button>
      </div>

      <h3 fxFlex="100" class="mt-0 mb-1">{{ 'dialogs.did_setup_wizard.step2.send_to_application'|translate }}</h3>

      <ng-container *ngIf="isCallCenter">
        <mat-radio-button value="webservice" fxFlex="33.33" fxFlex.xs="100" class="mb-1">
          {{'dialogs.did_setup_wizard.step2.route_to_webservice'|translate}}
        </mat-radio-button>
        <mat-radio-button value="crm_route" fxFlex="33.33" fxFlex.xs="100" *ngIf="!routingForCrm"
          [disabled]="!hasCrmRouting()" class="mb-1">
          {{'dialogs.did_setup_wizard.step2.route_to_crm_route'|translate}}
        </mat-radio-button>
      </ng-container>
      <mat-radio-button value="runvxmlscript" fxFlex="33.33" fxFlex.xs="100" [disabled]="!hasIvrApplication()"
        class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_ivrapplication'|translate}}
      </mat-radio-button>
      <mat-radio-button value="remote_application" fxFlex="33.33" fxFlex.xs="100" *ngIf="isCallCenter"
        [disabled]="!hasRemmoteApplication()" class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_remoteapplication'|translate}}
      </mat-radio-button>

      <mat-radio-button value="conference" fxFlex="33.33" fxFlex.xs="100" class="mb-1">
        {{'dialogs.did_setup_wizard.step2.route_to_conference'|translate}}
      </mat-radio-button>

    </mat-radio-group>

  </mat-tab>
  <mat-tab [disabled]="!routeTo.destination">
    <ng-template mat-tab-label>
      <h3>2. {{ 'dialogs.did_setup_wizard.step3.title'|translate }}</h3>
    </ng-template>

    <ng-container [ngSwitch]="routeTo.destination">

      <ng-container *ngSwitchCase="'master_did'">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.did_setup_wizard.step3.select_master_did'|translate}}</mat-label>
          <did-select-form-input [attr.data-ci]="'master_dids'" [(ngModel)]="routeTo.master_did"
            [includeIds]="dedicatedDidIds"></did-select-form-input>
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'call_phone_number'">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.did_setup_wizard.step3.phone_number'|translate}}</mat-label>
          <input matInput type="tel" [(ngModel)]="routeTo.phone_number" pattern="^[0-9]{4,20}$" [required]="true"
            id="phone_number" />
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'call_phone'">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.did_setup_wizard.step3.selected_sipphone'|translate}}</mat-label>
          <sipphone-select-form-input [(ngModel)]="routeTo.sipphone" [isConfigured]="true"></sipphone-select-form-input>
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'callqueue'">
        <h4>{{'dialogs.did_setup_wizard.step3.routing_option'|translate}}</h4>
        <mat-radio-group [(ngModel)]="routeTo.create_new" fxLayout="column" class="mb-1">
          <mat-radio-button [value]="0">
            {{ 'dialogs.did_setup_wizard.step3.selected_queue'|translate }}
          </mat-radio-button>
          <mat-radio-button [value]="1">
            {{ 'dialogs.did_setup_wizard.step3.create_queue'|translate }}
          </mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf="routeTo.create_new == 0; else createNewQueue">
          <mat-form-field class="full-width">
            <mat-label>{{ 'dialogs.did_setup_wizard.step3.selected_queue'|translate }}</mat-label>
            <queue-select-form-input [(ngModel)]="routeTo.queue"></queue-select-form-input>
          </mat-form-field>
          <span class="mat-error" *ngIf="message">{{ message }}</span>
        </ng-container>
        <ng-template #createNewQueue>
          <mat-form-field class="full-width">
            <mat-label>{{ 'dialogs.did_setup_wizard.step3.enter_queue_name'|translate }}</mat-label>
            <input matInput type="text" [(ngModel)]="routeTo.queue_name" [required]="true">
          </mat-form-field>
          <span class="mat-error" *ngIf="message">{{ message }}</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'conference'">
        <h4>{{'dialogs.did_setup_wizard.step3.routing_option'|translate}}</h4>
        <mat-radio-group [(ngModel)]="routeTo.create_new" fxLayout="column" class="mb-1">
          <mat-radio-button [value]="0">
            {{ 'dialogs.did_setup_wizard.step3.selected_conference'|translate }}
          </mat-radio-button>
          <mat-radio-button [value]="1">
            {{ 'dialogs.did_setup_wizard.step3.create_conference'|translate }}
          </mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf="routeTo.create_new == 0; else createNewConference">
          <mat-form-field class="full-width">
            <mat-label>{{'dialogs.did_setup_wizard.step3.selected_conference'|translate}}</mat-label>
            <conference-select-form-input [(ngModel)]="routeTo.conference"></conference-select-form-input>
          </mat-form-field>
          <span class="mat-error" *ngIf="message">{{ message }}</span>
        </ng-container>
        <ng-template #createNewConference>
          <mat-form-field class="full-width">
            <mat-label>{{'dialogs.did_setup_wizard.step3.enter_conference_name'|translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="routeTo.conference_name" [required]="true">
          </mat-form-field>
          <span class="mat-error" *ngIf="message">{{ message }}</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'ivr_custom_menu'">
        <h4>{{'dialogs.did_setup_wizard.step3.routing_option'|translate}}</h4>
        <mat-radio-group [(ngModel)]="routeTo.create_new" fxLayout="column" class="mb-1">
          <mat-radio-button [value]="0">
            {{ 'dialogs.did_setup_wizard.step3.selected_ivrmenu'|translate }}
          </mat-radio-button>
          <mat-radio-button [value]="1">
            {{ 'did_setup_wizard.create_new_ivrmenu'|translate }}
          </mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf="routeTo.create_new == 0; else tplCreateNewIvrMenu">
          <mat-form-field class="full-width">
            <mat-label>{{'dialogs.did_setup_wizard.step3.selected_ivrmenu'|translate}}</mat-label>
            <ivr-menu-select-form-input [(ngModel)]="routeTo.ivrmenu" [menuType]="0" [isConfigured]="true"
              [required]="true"></ivr-menu-select-form-input>
          </mat-form-field>
          <span class="mat-error" *ngIf="message">{{message}}</span>
        </ng-container>
        <ng-template #tplCreateNewIvrMenu>
          <mat-form-field class="full-width">
            <mat-label>{{'dialogs.did_setup_wizard.step3.enter_ivrmenu_name'|translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="routeTo.ivrmenu_name" [required]="true">
          </mat-form-field>
          <span class="mat-error" *ngIf="message">{{ message }}</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'runvxmlscript'">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'dialogs.did_setup_wizard.step3.selected_ivrapplication'|translate}}</mat-label>
          <ivr-script-select-form-input [(ngModel)]="routeTo.ivrscript">
          </ivr-script-select-form-input>
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'remote_application'">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.did_setup_wizard.step3.selected_remote_application'|translate}}</mat-label>
          <remote-application-select-form-input [(ngModel)]="routeTo.remote_application">
          </remote-application-select-form-input>
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'play_then_hangup'">
        <sound-upload [fileEntry]="routeTo.file_entry"
          defaultSound="service_closed"
          title="{{ 'dialogs.did_setup_wizard.step3.routing_option'|translate }}" (onChange)="routeToFileEntry($event)">
        </sound-upload>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'sip_trunk'">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.did_setup_wizard.step3.selected_sip_trunk'|translate}}</mat-label>
          <sip-trunk-select-form-input [(ngModel)]="routeTo.siptrunk">
          </sip-trunk-select-form-input>
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'webservice'">
        <h4>{{'dialogs.did_setup_wizard.step3.routing_option'|translate}}</h4>
        <mat-form-field class="full-width">
          <label>{{ 'routing_application.params.url'|translate }}</label>
          <input matInput type="url" [(ngModel)]="routeTo.url" [required]="false">
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'crm_route'">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.did_setup_wizard.step3.routing_option'|translate}}</mat-label>
          <crm-routing-rule-select-form-input [(ngModel)]="routeTo.crm_routing_rule">
          </crm-routing-rule-select-form-input>
        </mat-form-field>
        <span class="mat-error" *ngIf="message">{{ message }}</span>
      </ng-container>

      <!-- <ng-container *ngSwitchCase="'call_user'">
        <mat-form-field class="full-width">
          <mat-label>{{'dialogs.did_setup_wizard.step3.selected_user'|translate}}</mat-label>
          <user-select-form-input [(ngModel)]="routeTo.user"></user-select-form-input>
          <span class="mat-error" *ngIf="message">{{ message }}</span>
        </mat-form-field>
      </ng-container> -->

      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>

  </mat-tab>
</mat-tab-group>
