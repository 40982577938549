import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { CustomStatsFilterEntity } from '@wephone-core/model/entity/customstatsfilter';

export class CustomStatsFilterRepository extends BaseRepository {
  ENTITY_CLASS = CustomStatsFilterEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'custom_stats_filter';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      // case 'UPDATE_ATTRS':
      //   return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      // case 'UPDATE_ATTRS_BULK':
      //   return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS_BULK);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'ADD_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_NEW_ATTRS);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

}
