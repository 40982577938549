<div fxFlexFill fxLayout="column">
  <div fxFlex="initial" *ngIf="showFilter">
    <flex-search-input [attr.data-ci]="'filter-node-name'" [formControl]="filterValue" class="full-width"></flex-search-input>
  </div>

  <div fxFlex="grow" fxLayout="column">
    <div *ngIf="showCheckAll && multiple">
      <mat-checkbox [attr.data-ci]="'chk-all'" class="tree-node-checkbox" color="primary" [checked]="checkall"
        (change)="toogleSelectedAll()" [disabled]="disabled">
        {{ 'public.check_uncheck_all'|translate }}
      </mat-checkbox>
    </div>
    <tree-root #tree [nodes]="treeNodes" class="tree-content" fxFlex="auto">
      <ng-template #treeNodeTemplate let-node="node" let-index="index">
        <ng-template #nodeName>
          <span [innerHTML]="node.data.name" [style]="node.data.style"></span>
        </ng-template>
        <mat-checkbox *ngIf="node.data.checkbox;else nodeName" class="tree-node-checkbox" color="primary"
          [indeterminate]="node.data.indeterminate" [ngModel]="node.data.checked"
          (change)="onChangeCheckboxNode(node, !node.data.checked)" [disabled]="node.data.disabled || disabled">
          <ng-container *ngTemplateOutlet="nodeName; context: {node: node}"></ng-container>
        </mat-checkbox>
      </ng-template>
    </tree-root>
  </div>
</div>
