import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService, NoWhitespaceValidator, DialogService } from '@wephone-utils';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutingServiceRepository } from '@wephone-core/model/repository/routing_service';
import { _tk, _ti } from '@wephone-translation';
import { RoutingServiceEntity } from '@wephone-core/model/entity/routing_service';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';
import { RoutingServiceService } from '@wephone-core/service/routing-service.service';
import { TestUrlServiceResultDataType, TestUrlServiceResultModalComponent } from '@wephone/modals/test-url-service-result-modal/test-url-service-result-modal.component';

@Component({
  selector: 'app-routing-service-edit',
  templateUrl: './routing-service-edit.component.html',
  styleUrls: ['./routing-service-edit.component.scss']
})
export class RoutingServiceEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: RoutingServiceEntity;

  private readonly routingServiceRepo = RoutingServiceRepository.getInstance<RoutingServiceRepository>();

  form: FormGroup;
  routingService: RoutingServiceEntity;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly routingServiceService: RoutingServiceService,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.routingService = this.editingItem;
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.routingService.name, [Validators.required, Validators.maxLength(64), NoWhitespaceValidator]],
      url: [this.routingService.url, [Validators.required, UrlValidator.isValidUrl]],
    });

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });
  }

  async verifyUrl(): Promise<void> {
    try {
      if (this.form.get('url').invalid) {
        this.form.get('url').markAsTouched();
        this.toast.showError(_ti('public.message.data_invalid'));
        return;
      }

      const data = {
        url: this.form.get('url').value,
      };
      const ret = await this.routingServiceService.verify(data);
      // this.toast.showSuccess(_ti('crm.customcrm.test_success'));

      const respData: TestUrlServiceResultDataType = {
        errors: ret && ret.error || [],
        responseData: ret && ret.responseData
      };
      this.showVerifyUrlResult(respData);

    } catch (error) {
      let errorMSG = _ti('public.message.test_failure');
      if (error) {
        if (error.message) {
          errorMSG = error.message;
        } else if (error.error && error.error.message) {
          errorMSG = error.error.message;
        }
      }
      const respData: TestUrlServiceResultDataType = {
        errors: [errorMSG],
        responseData: error
      };
      this.showVerifyUrlResult(respData);
      // this.toast.showError(errorMSG);
    }
  }

  private showVerifyUrlResult(data: TestUrlServiceResultDataType): void {
    this.dialogService.openDialog2(TestUrlServiceResultModalComponent, {
      data,
      size: 's'
    });
  }

  async submitForm(): Promise<void> {
    const updateRoutingService = this.routingServiceRepo.create();
    updateRoutingService.setObjectData({
      id: this.routingService.getId(),
      name: _.trim(this.form.get('name').value),
      url: this.form.get('url').value,
    });

    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.toast.showError(_ti('public.message.data_invalid'));
        return;
      }

      const attrList = ['name', 'url'];
      const updatedRoutingService = await this.routingServiceRepo.saveAttrs(updateRoutingService, attrList, null);
      this.routingService.setObjectData(updatedRoutingService);
      this.toast.show(_ti('public.message.update_success'));
      this.resetForm();
    } catch (error) {
      let errorMSG = _ti('public.message.update_failure');
      if (error) {
        if (error.message) {
          errorMSG = error.message;
        } else if (error.error && error.error.message) {
          errorMSG = error.error.message;
        }
      }
      this.toast.showError(errorMSG);
    }
  }

  private getFormResetData(): any {
    return {
      name: this.routingService.name,
      url: this.routingService.url,
    };
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }
}
