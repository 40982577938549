import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import {
  IFlexDialogConfig,
  DialogActionButton,
  Colors,
} from '@wephone-utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { _tk, _ti, TranslationService } from '@wephone-translation';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { LangChangeEvent } from '@ngx-translate/core';

interface ScheduleTime {
  label: string;
  value: Date;
}

@Component({
  selector: 'app-sms-set-schedule',
  templateUrl: './sms-set-schedule.component.html',
  styleUrls: ['./sms-set-schedule.component.scss'],
  // providers: [
  //   // use french locale
  //   {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
  // ],
})
export class SmsSetScheduleComponent extends DialogComponentBase implements OnDestroy {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('sms.set_schedule');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('sms.set_schedule'),
      action: () => {
        this.setSchedule();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY,
    },
  ];

  times: ScheduleTime[] = [];
  selectedTime: Date;

  constructor(
    dateTimeAdapter: DateTimeAdapter<any>,
    dialogRef: MatDialogRef<SmsSetScheduleComponent>,
  ) {
    super(dialogRef);

    dateTimeAdapter.setLocale(TranslationService.getInstance().getCurrentLocale());
    // this.addSubscription(
    //   TranslationService.getInstance().translate.onLangChange.subscribe(
    //     (langEvent: LangChangeEvent) => {
    //       dateTimeAdapter.setLocale(TranslationService.getInstance().getLocaleByLanguage(langEvent.lang));
    //     }
    //   )
    // );

    this.times = [
      {
        label: 'sms.schedule_time.tomorrow_morning',
        value: DateTime.local().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).plus({ day: 1 }).toJSDate()
      },
      {
        label: 'sms.schedule_time.tomorrow_afternoon',
        value: DateTime.local().set({ hour: 13, minute: 0, second: 0, millisecond: 0 }).plus({ day: 1 }).toJSDate()
      },
      {
        label: 'sms.schedule_time.monday_morning',
        value: DateTime.local().startOf('week').plus({ week: 1 }).set({ hour: 8 }).toJSDate()
      }
    ];
  }

  setSchedule(): void {
    if (!this.selectedTime) {
      this.showError(_ti('sms.alert.must_select_scheduled_date'));
      return;
    }

    const now = new Date();
    if (this.selectedTime < now) {
      this.showError(_ti('sms.alert.scheduled_date_must_in_future'));
      return;
    }

    this.close(this.selectedTime);
  }

  setCustomTime(customTime: Date): void {
    this.selectedTime = customTime;
    this.setSchedule();
  }

  selectTime(time: ScheduleTime): void {
    this.selectedTime = time.value;
    this.setSchedule();
  }

  private close(result?): void {
    this.dismiss(result);
  }

}
