<div class="page-root">
  <div class="page-header">
    <h2>{{ 'enterprise_info.tabs.api_integration'|translate }}</h2>
  </div>

  <div class="table-container">
    <form [formGroup]="form" fxFlex="grow">
      <mat-card class="item-detail shadow-none" fxFlex="grow">
        <div fxLayout="column" fxFlex="grow">
          <mat-toolbar class="mat-primary" fxFlex="62px" fxLayout="row" fxLayoutAlign="start center">
            <div class="button-container" *ngIf="formHasChanged()">
              <button type="button" mat-button class="border-white-button" (click)="updateMyEnterprise()">
                {{ 'public.save'|translate }}
              </button>
              <button type="button" mat-button class="border-white-button ml-1" (click)="resetForm()">
                {{ 'public.cancel'|translate }}
              </button>
            </div>
          </mat-toolbar>

          <div fxLayout="column" fxFlex="grow" class="side-pannel-content pa-1">

            <mat-card class="shadow-none header-text-no-margin">
              <mat-card-header>
                <div mat-card-avatar>
                  <mat-icon class="title-icon">api</mat-icon>
                </div>
                <mat-card-title>
                  <h2>{{ 'manage_integration.api'|translate }}</h2>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="form-group">
                <div>
                  <span class="mat-text-muted" [ngClass]="{'mat-text-red font-weight-italic': !myEnterprise.apikey}">
                    {{ apikeyControl.value || ('manage_integration.no_api_key' | translate) }}
                  </span>
                  <button type="button" mat-icon-button 
                    [disabled]="!myEnterprise.apikey" (click)="copyToClipboard(myEnterprise.apikey)"
                    title="{{ 'clipboard.copy_title'|translate }}">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  <button type="button" mat-stroked-button *ngIf="authService.isAdmin()" (click)="renewApiKey()">
                    {{ 'manage_integration.generate_api_key' | translate }}
                  </button>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none header-text-no-margin mt-1">
              <mat-card-header>
                <div mat-card-avatar>
                  <mat-icon class="title-icon">phone_forwarded</mat-icon>
                </div>
                <mat-card-title>
                  <h2>{{ 'manage_integration.web_callback'|translate }}</h2>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="form-group">
                <div fxLayout="column">
                  <mat-form-field>
                    <mat-label>{{ 'manage_integration.web_callback_callstart'|translate }}</mat-label>
                    <input type="text" matInput formControlName="call_start_url"
                      placeholder="{{ 'manage_integration.web_callback_callstart'|translate }}" />
                    
                    <mat-error [flexFormControlError]="form.get('call_start_url')"></mat-error>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{ 'manage_integration.web_callback_callend'|translate }}</mat-label>
                    <input type="text" matInput formControlName="call_end_url"
                      placeholder="{{ 'manage_integration.web_callback_callend'|translate }}" />
                      
                    <mat-error [flexFormControlError]="callEndUrlControl"></mat-error>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>

          </div>
        </div>


        <!-- <mat-toolbar>
      <h1>{{ 'enterprise_info.tabs.api_integration'|translate }}</h1>
    </mat-toolbar> -->
      </mat-card>
    </form>

  </div>
</div>
