import { DateTime } from 'luxon';
import { datafield, Entity } from '@wephone-core/model/model';
import { parseDateTime } from '@wephone-utils';

export class CallQueueSessionEntity extends Entity {
  public static object_type_id = 'callqueue_session';

  public static AUDIO_STATUS: any = {
    available: 0,
    not_available: 1
  };

  public id: number;
  @datafield public enterprise_id: number;
  @datafield public operator_id: number;
  @datafield public local_number: string;
  @datafield public remote_number: string;
  @datafield public start_dt: any;
  @datafield public stop_dt: any;
  @datafield public bridged_dt: any;
  @datafield public keepalive_dt: any;
  @datafield public client_call_id: string;
  @datafield public client_call_keyid: number;
  @datafield public operator_call_id: string;
  @datafield public handling_server: string;
  @datafield public terminated: number;
  @datafield public call_queue_keyid: number;
  @datafield public call_queue_item_keyid: number;
  @datafield public outcall_campaign_id: number;
  @datafield public outcall_job_id: number;
  @datafield public fail_reason: string;
  @datafield public recorded_call_id: number;
  @datafield public transfer_out: number;
  @datafield public transfer_out_data: any;
  @datafield public transfer_in: number;
  @datafield public transfer_in_session_id: number; // The session before the call is transferred to this session
  @datafield public qualification: string[];
  @datafield public qualification_comment: string;
  @datafield public is_outgoing: number;
  @datafield public total_onhold_time: number;
  @datafield public is_onhold: number;
  @datafield public last_onhold_dt;
  @datafield public eavesdropper_ids: number[];
  @datafield public transfer_in_reason: string;
  @datafield public transfer_in_agent_id: number;
  @datafield public transfer_in_type: number;
  @datafield public audio_status: number;

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.start_dt = this.start_dt && parseDateTime(this.start_dt);
    this.stop_dt = this.stop_dt && parseDateTime(this.stop_dt);
    this.bridged_dt = this.bridged_dt && parseDateTime(this.bridged_dt);
    this.keepalive_dt = this.keepalive_dt && parseDateTime(this.keepalive_dt);
    this.last_onhold_dt = this.last_onhold_dt && parseDateTime(this.last_onhold_dt);
  }
}
