import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IUserRepository } from '@wephone-core/model/repository/user.i';
import { UserEntity } from '@wephone-core/model/entity/user';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { IUserEntity } from '../entity/user.i';
import * as _ from 'lodash';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { joinURL } from '@wephone-utils';

export class UserRepository extends BaseRepository implements IUserRepository {
  ENTITY_CLASS = UserEntity;
  protected USE_PUSH_API = true;
  // protected LIST_CONFIG_NAME = 'user';
  protected RELATED_REPOSITORIES = ['callqueue', 'sipphone', 'did', 'servicegroup', 'teamgroup', 'voicemail'];
  protected URL_PREFIX = 'user';
  protected URL_PREFIX_SA = 'sa/user';
  protected METHOD_PATH_REGISTER = 'register';
  protected METHOD_PATH_ENABLE = 'enable';
  protected METHOD_PATH_DISABLE = 'disable';
  protected METHOD_PATH_VOICEMAIL_ENABLE = 'voicemail_enable';
  protected METHOD_PATH_VOICEMAIL_DISABLE = 'voicemail_disable';
  protected METHOD_PATH_RECORDING_ENABLE = 'recording_enable';
  protected METHOD_PATH_RECORDING_DISABLE = 'recording_disable';
  protected METHOD_PATH_MY_PROFILE = 'my_profile';
  protected METHOD_PATH_LIST_SUPERUSERS = 'superusers';
  protected METHOD_PATH_UPDATE_SUPERUSER = 'update_superuser';
  protected METHOD_PATH_DELETE_SUPERUSERS = 'delete_superusers';
  protected METHOD_PATH_CHANGE_PASSWORD = 'change_password';
  protected METHOD_PATH_UPDATE_PROFILE = 'update_profile';
  protected METHOD_PATH_IMPORT_USER_CRM = 'import_crm_user';
  protected baseUrl: string;

  protected getBaseUrl(): string {
    return this.baseUrl || super.getBaseUrl();
  }
  setBaseUrl(baseUrl: string): void {
    this.baseUrl = baseUrl;
  }

  protected getSAUrl(suffix: string): string {
    return FlexIvrSettings.getInstance().getAbsoluteWSv2URL(joinURL(this.getBaseUrl(), this.URL_PREFIX_SA, suffix));
  }

  protected getURLAction(action: string): string {
    switch (action) {
      // case 'REGISTER':
      //   return this.getUrl(this.METHOD_PATH_REGISTER);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      case 'ENABLE':
        return this.getUrlV2(this.METHOD_PATH_ENABLE);
      case 'DISABLE':
        return this.getUrlV2(this.METHOD_PATH_DISABLE);
      // case 'MY_PROFILE':
      //   return this.getUrl(this.METHOD_PATH_MY_PROFILE);
      // case 'VOICEMAIL_ENABLE':
      //   return this.getUrl(this.METHOD_PATH_VOICEMAIL_ENABLE);
      // case 'VOICEMAIL_DISABLE':
      //   return this.getUrl(this.METHOD_PATH_VOICEMAIL_DISABLE);
      // case 'RECORDING_ENABLE':
      //   return this.getUrl(this.METHOD_PATH_RECORDING_ENABLE);
      // case 'RECORDING_DISABLE':
      //   return this.getUrl(this.METHOD_PATH_RECORDING_DISABLE);
      case 'ADD_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_NEW_ATTRS);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'CHANGE_PASSWORD':
        return this.getUrlV2(this.METHOD_PATH_CHANGE_PASSWORD);
      case 'UPDATE_PROFILE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_PROFILE);
      case 'IMPORT_USER_CRM':
        return this.getUrlV2(this.METHOD_PATH_IMPORT_USER_CRM);
      default:
        return super.getURLAction(action);
    }
  }

  protected getURLSAAction(action: string): string {
    switch (action) {
      case 'ADD_ATTRS':
        return this.getSAUrl(this.METHOD_PATH_NEW_ATTRS);
      case 'UPDATE_ATTRS':
        return this.getSAUrl(this.METHOD_PATH_UPDATE_ATTRS);
      case 'CHANGE_PASSWORD':
        return this.getSAUrl(this.METHOD_PATH_CHANGE_PASSWORD);
      default:
        return super.getURLAction(action);
    }
  }

  protected changeState(item: UserEntity, state: number): Promise<any> {
    const action: string = state === 1 ? 'ENABLE' : 'DISABLE';
    const url = this.getURLAction(action);
    return HttpEngine.getInstance()
      .post(url, { ids: [item.getId()] })
      .then(ret => {
        item.enabled = state ? true : false;
      });
  }

  protected filterEntity(entity: UserEntity): boolean {
    return !entity.is_system_user;
  }

  bulkDisable(items: UserEntity[]): Promise<any> {
    return this.bulkChangeState(items, 0);
  }

  bulkEnable(items: UserEntity[]): Promise<any> {
    return this.bulkChangeState(items, 1);
  }

  protected bulkChangeState(items: UserEntity[], state: number): Promise<any> {
    const action: string = state === 1 ? 'ENABLE' : 'DISABLE';
    const url = this.getURLAction(action);
    const ids = [];
    let i: number;
    for (i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    return HttpEngine.getInstance()
      .post(url, { ids })
      .then(res => {
        for (i = 0; i < items.length; i++) {
          items[i].enabled = state ? true : false;
        }
        return res;
      }, error => {
        throw error;
      });
  }

  // protected updateMyProfile(data: any): Promise<any> {
  //   const url = this.getURLAction('MY_PROFILE');
  //   return HttpEngine.getInstance().post(url, data);
  // }

  importCrmUser(usersList: {
    user_type: string;
    email: string;
    name: string;
  }[]): Promise<any> {
    const url = this.getURLAction('IMPORT_USER_CRM');

    return HttpEngine.getInstance()
      .post(url, { users: usersList });
  }

  // protected changeVoiceMailState(item: UserEntity, state: number): Promise<any> {
  //   const action: string = state === 1 ? 'VOICEMAIL_ENABLE' : 'VOICEMAIL_DISABLE';
  //   const url = this.getURLAction(action);
  //   return HttpEngine.getInstance()
  //     .post(url, { ids: [item.getId()] })
  //     .then(ret => {
  //       item.voicemail_enabled = state;
  //     });
  // }

  // protected bulkChangeVoiceMailState(items: UserEntity[], state: number): Promise<any> {
  //   const action: string = state === 1 ? 'VOICEMAIL_ENABLE' : 'VOICEMAIL_DISABLE';
  //   const url = this.getURLAction(action);
  //   const ids = [];
  //   let i: number;
  //   for (i = 0; i < items.length; i++) {
  //     ids.push(items[i].getId());
  //   }
  //   return HttpEngine.getInstance()
  //     .post(url, { ids })
  //     .then(ret => {
  //       for (i = 0; i < items.length; i++) {
  //         items[i].voicemail_enabled = state;
  //       }
  //     });
  // }

  // protected changeRecordingState(item: UserEntity, state: number): Promise<any> {
  //   const action: string = state === 1 ? 'RECORDING_ENABLE' : 'RECORDING_DISABLE';
  //   const url = this.getURLAction(action);
  //   // item.enabled = state;
  //   return HttpEngine.getInstance()
  //     .post(url, { ids: [item.getId()] })
  //     .then(ret => {
  //       item.recording_mode = state;
  //     });
  // }

  // protected bulkChangeRecordingState(items: UserEntity[], state: number): Promise<any> {
  //   const action: string = state === 1 ? 'RECORDING_ENABLE' : 'RECORDING_DISABLE';
  //   const url = this.getURLAction(action);
  //   const ids = [];
  //   let i: number;
  //   for (i = 0; i < items.length; i++) {
  //     ids.push(items[i].getId());
  //   }
  //   return HttpEngine.getInstance()
  //     .post(url, { ids })
  //     .then(ret => {
  //       for (i = 0; i < items.length; i++) {
  //         items[i].recording_mode = state;
  //       }
  //     });
  // }

  /**
   * @param id Get user but not through filterEntity
   * @returns UserEntity
   */
  getUserById(id: number): UserEntity {
    return this.object_list.find(x => x.getId() === id);
  }

  findByGroupId(group_id: number): UserEntity[] {
    const users: UserEntity[] = this.getObjectList().filter(user => {
      return user.group_ids && _.includes(user.group_ids, group_id);
    });

    return users;
  }

  findByTeamId(team_id: number | string): UserEntity[] {
    const users = [];
    for (const user of this.getObjectList()) {
      // DO not use '===' here because sometime we find id as string
      if (user.team_id && user.team_id == team_id) {
        users.push(user);
      }
    }
    return users;
  }

  //        Moved to frontend_app/register.ts
  //        register(form) {
  //        	console.log("register submit",form);
  //            let url = this.getURLAction("REGISTER");
  //            return HttpEngine.getInstance().post(url, form);
  //        }

  createUserAndSave(obj, attr_list, extra_data = null): Promise<any> {
    return this.saveAttrs(obj, attr_list, extra_data);
  }

  createUserAndSaveBySA(obj, attr_list, extra_data = null): Promise<any> {
    if (!(attr_list instanceof Array)) {
      throw new Error('Attribute \'attr_list\' must be an array');
    }
    const action: string = obj.getId() ? 'UPDATE_ATTRS' : 'ADD_ATTRS';
    const url = this.getURLSAAction(action);
    let item_data = obj.dumpObjectData(attr_list, true);
    if (extra_data) {
      item_data = _.extend(item_data, extra_data);
    }

    const post_data = {};
    for (const attr of attr_list) {
      post_data[attr] = item_data[attr];
    }
    if (item_data.id) {
      post_data['id'] = parseInt(item_data.id);
    }
    post_data['__field_list__'] = attr_list;

    return HttpEngine.getInstance().postJson(url, post_data);
  }

  findSuperUsers(): Promise<UserEntity[]> {
    const url = this.getUrlV2(this.METHOD_PATH_LIST_SUPERUSERS);
    return HttpEngine.getInstance().get(url);
  }

  bulkDeleteSuperUsers(items: UserEntity[], manual_remove = true): Promise<any> {
    const ids = items.map(x => x.getId());
    const url = this.getUrlV2(this.METHOD_PATH_DELETE_SUPERUSERS);
    return HttpEngine.getInstance().postJson(url, { ids }).then(ret => {
      if (manual_remove) {
        for (const i of ids) {
          this.removeObjectById(i);
        }
      }
      return ret;
    });
  }

  saveSuperUser(item: UserEntity, extra_data?: { [id: string]: any }): Promise<any> {
    const url = this.getUrlV2(this.METHOD_PATH_UPDATE_SUPERUSER);
    const superUserFieldList = ['id', 'firstname', 'lastname', 'user_type', 'email', 'phonenum'];
    const post_data = item.dumpObjectData(superUserFieldList, true);
    if (extra_data) {
      _.extend(post_data, extra_data);
    }

    post_data['__field_list__'] = superUserFieldList;
    return HttpEngine.getInstance().postJson(url, post_data);
  }

  changePassword(userId: number, extra_data: { password: string; confirm_password: string }): Promise<any> {
    const url = this.getURLAction('CHANGE_PASSWORD');
    const post_data = {
      id: userId,
      password: extra_data.password,
      confirm_password: extra_data.confirm_password
    };
    post_data['__field_list__'] = ['id', 'password', 'confirm_password'];
    return HttpEngine.getInstance().postJson(url, post_data);
  }

  updateProfile(item: IUserEntity, extra_data: { avatar_id: number; replace_avatar: number }): Promise<any> {
    const url = this.getURLAction('UPDATE_PROFILE');
    const post_data = {
      id: item.id,
      avatar_id: extra_data.avatar_id,
      replace_avatar: extra_data.replace_avatar
    };
    post_data['__field_list__'] = ['id', 'avatar_id', 'replace_avatar'];
    return HttpEngine.getInstance().postJson(url, post_data);
  }

  getAllEnabled(): UserEntity[] {
    return this.getObjectList().filter((o: UserEntity) => o.enabled);
  }

  getUserByEmail(email: string, excludedId: number = null): UserEntity {
    return this.getObjectList().find(x => x.email === email && (!excludedId || excludedId && x.id !== excludedId));
  }
}
