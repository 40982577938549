import { Injectable } from '@angular/core';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { SyncTaskEntity } from '@wephone-core/model/entity/sync_task';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OHCashier, OHTransaction } from './enterprise_crm.service.i';

@Injectable()
export class EnterpriseCrmService {
  static VERIFY_CUSTOMCRM_CONFIG_PATH = 'enterprise_crm/customcrm/verify-config';
  // static SYNCHRONIZE_HOTEL_ROOM_PATH = 'enterprise_crm/synchronization-hotel-room';
  static GET_OH_CASHIERS = 'enterprise_crm/oraclehospitality/:crm_id/cashiers';
  static GET_OH_TRANSACTION_CODES = 'enterprise_crm/oraclehospitality/:crm_id/transaction_codes';
  static GET_SYNCHRONIZATION_HOTEL_ROOM_PATH = 'enterprise_crm/synchronization-hotel-room/:crm_id';
  static RELOAD_MAILBOXES_PATH = 'enterprise_crm';

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly em: EntityManager,
  ) { }

  verifyCustomCrmConfig(url: string, remote_number: string, local_number: string, apikey?: string): Promise<{ error?: string[], responseData: any }> {
    const params: any = { url, remote_number, local_number };
    if (apikey) {
      params.crm_apikey = apikey;
    }

    return this.httpEngine.apiPostV2(EnterpriseCrmService.VERIFY_CUSTOMCRM_CONFIG_PATH, params);
  }

  async getSynchronizationHotelRoom(crm: EnterpriseCrmEntity): Promise<SyncTaskEntity> {
    const resp = await this.httpEngine.apiGetV2(EnterpriseCrmService.GET_SYNCHRONIZATION_HOTEL_ROOM_PATH.replace(':crm_id', crm.id.toString()));
    if (resp && resp.id) {
      return this.em.getRepository('SyncTaskRepository').getObjectById(resp.id);
    }
  }

  async getOHTransactions(crm: EnterpriseCrmEntity): Promise<OHTransaction[]> {
    const ret = await this.httpEngine.apiGetV2(EnterpriseCrmService.GET_OH_TRANSACTION_CODES.replace(':crm_id', crm.id.toString()));
    return ret;
  }

  async getOHCashiers(crm: EnterpriseCrmEntity): Promise<OHCashier[]> {
    const ret = await this.httpEngine.apiGetV2(EnterpriseCrmService.GET_OH_CASHIERS.replace(':crm_id', crm.id.toString()));
    return ret;
  }

  // async synchronizeHotelRoom(crm: EnterpriseCrmEntity): Promise<SyncTaskEntity> {
  //   const params: any = {
  //     crm_id: crm.id
  //   };

  //   const resp = await this.httpEngine.apiPostV2(EnterpriseCrmService.SYNCHRONIZE_HOTEL_ROOM_PATH, params);
  //   if (resp && resp.id) {
  //     return this.em.getRepository('SyncTaskRepository').getObjectById(resp.id);
  //   }
  // }

  reloadMailboxes(crmId: number): Promise<any> {
    return this.httpEngine.apiGetV2(EnterpriseCrmService.RELOAD_MAILBOXES_PATH + `/${crmId}/mailboxes`);
  }
}
