import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { PhoneNumberService } from '@wephone-utils';

@Component({
  selector: 'callednumber-renderer',
  template:  `<span>{{ params.value }}</span>`
})
export class CalledNumberrRenderer implements ICellRendererAngularComp {
  params: any;
  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.params.value = PhoneNumberService.getInstance().getDisplayNumber(this.params.value);
  }

  refresh(): boolean {
    return false;
  }
}
