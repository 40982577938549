<div fxLayout="column" fxFlex="grow">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="start end" class="filter-wraper">
    <div fxFlex>
      <a class="flex-link" (click)="selectAll()">
        <mat-icon>{{ selectedAll ? 'clear' : 'done_all' }}</mat-icon>&nbsp;
        {{ (selectedAll ? 'group.add_agent_dialog.clear_selection' : 'group.add_agent_dialog.select_all')|translate }}
      </a>
    </div>
    <div fxFlex="50">
      <flex-search-input class="full-width" [attr.data-ci]="'filter-agent-name'" [(ngModel)]="filters.search_text"
        [placeholder]="'form.text_search_placeholder'|translate"></flex-search-input>
    </div>
  </div>
  <div perfectScrollbar class="container">
    <div fxLayout="row wrap" fxLayout.xs="column" class="agent-list">
      <div *ngFor="let user of users" fxFlex.xs="auto" fxFlex.sm="33.33%" fxFlex.gt-sm="25%" class="agent-wrapper">
        <div fxFlexFill fxLayout="column" (click)="canChangeUser(user) && selectUser(user)" class="bg-gray"
          [ngClass]="{'selected': hasSelected(user), 'disabled': !canChangeUser(user)}">
          <agent-item [avatar]="true" [agent]="user.agent" [displayCallInfo]="false"></agent-item>
        </div>

        <button type="button" color="warn" mat-icon-button (click)="unselectUser(user)" class="btn-unselect"
          *ngIf="hasSelected(user)" [disabled]="!canChangeUser(user)">
          <mat-icon class="text-sm">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
