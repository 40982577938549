import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { EndorsementEntity } from '@wephone-core/model/entity/endorsement';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { HttpEngine } from '@wephone-core/service/http_engine';

export class EndorsementRepository extends BaseRepository {
  ENTITY_CLASS = EndorsementEntity;
  protected URL_PREFIX = 'endorsement';

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/endorsement'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get endorsement list error', response);

          return [];
        }
      );
  }
}
