import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, joiValidator, ToastService } from '@wephone-utils';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ConferenceRepository } from '@wephone-core/model/repository/conference';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-edit-conference',
  templateUrl: './edit-conference.component.html',
  styleUrls: ['./edit-conference.component.scss']
})
export class EditConferenceComponent extends EditingComponent implements OnInit {
  @Input() editingItem: ConferenceEntity;

  public conference: ConferenceEntity;
  public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    public readonly toast: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.conference = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.conference.name, [Validators.required, Validators.maxLength(255)]],
      password: [this.conference.password, joiValidator(Joi.string().max(64).required())],
    });

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });
  }

  private getFormResetData(): Object {
    return {
      name: this.conference.name,
      password: this.conference.password,
    };
  }

  async submitForm(): Promise<any> {
    if (this.form.invalid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return undefined;
    }

    try {
      const updatedConference = EntityManager.getRepository('ConferenceRepository').create() as ConferenceEntity;
      updatedConference.setObjectData({
        id: this.conference.id,
        name: this.form.get('name').value,
        password: this.form.get('password').value
      });

      const attrList = ['name', 'password'];
      const returnedConference = await EntityManager.getRepository<ConferenceRepository>('ConferenceRepository')
        .saveAttrs(updatedConference, attrList);
      this.toast.show(_ti('public.message.update_success'));
      this.conference.setObjectData(returnedConference, true);
      this.resetForm();
    } catch (error) {
      this.toast.showError(error.message || _ti('public.message.update_failure'));
      let msg = _ti('public.message.update_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toast.showError(msg);
    }
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    this.form.reset(this.getFormResetData(), options);
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  pristineForm(): void {
    this.form.markAsPristine();
    this.formValueChanges.next(false);
  }
}
