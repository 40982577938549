import * as _ from 'lodash';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import {
  ToastService,
  EditingComponent,
  DialogService,
  IFlexDialogConfig,
  NoWhitespaceValidator,
} from '@wephone-utils';
import { CallingProfileRepository } from '@wephone-core/model/repository/callingprofile';
import { CallingProfileEntity } from '@wephone-core/model/entity/callingprofile';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { _tk, _ti } from '@wephone-translation';
import { SipPhoneLogModal } from '@wephone/modals/sip-phone-log-modal/sip-phone-log-modal.component';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { HotelRoomEntity } from '@wephone-core/model/entity/hotelroom';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { UserEntity } from '@wephone-core/model/entity/user';
import { SipphoneKeyConfigComponent } from '../sipphone-key-config/sipphone-key-config.component';
import { PhoneModelType, SipphoneKeyConfigType, ISipPhoneKeyConfig, ISipPhoneCustomSettings } from '@wephone-core/model/entity/sipphone.i';
import { SipphoneCustomConfigComponent } from '../sipphone-custom-config/sipphone-custom-config.component';
import { SipPhoneService } from '@wephone-common/service/sipphone.service';
import { MACValidator } from '@wephone-utils/validator/mac-validator';
import { SipPhoneExtensionValidated } from '@wephone/services/form-validator';

const usedValidator = (sipPhoneId: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const usedSiphone = SipPhoneRepository.getInstance().getObjectList().find(s => {
      return s.extension === control.value && s.id !== sipPhoneId;
    });

    return usedSiphone ? { used: true } : undefined;
  };
};

@Component({
  selector: 'sipphone-edit',
  templateUrl: './sipphone-edit.component.html',
  styleUrls: ['./sipphone-edit.component.scss']
})
export class SipPhoneEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: SipPhoneEntity;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SipphoneKeyConfigComponent) sipphoneKeyConfig: SipphoneKeyConfigComponent;
  @ViewChild(SipphoneCustomConfigComponent) sipphoneCustomConfig: SipphoneCustomConfigComponent;

  // Private member
  private readonly sipphoneRepo = EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository');

  sipphone: SipPhoneEntity;
  originSipPhone: SipPhoneEntity;
  callingProfile: CallingProfileEntity;
  loadedCallingProfile = false;
  hidePassword = true;

  steps = {
    _1general: 'sipphone.content.tab_general',
    _2sip_info: 'sipphone.content.sip_connection_info',
    _3key_config: 'sipphone.content.key_config',
    _4custom_config: 'sipphone.content.custom_config',
    _5location: 'sipphone.content.tab_location',
    _6logs: 'sipphone.content.tab_logs',
  };

  filterCtrl = new FormControl();
  currentUser: UserEntity;

  phoneModels: { value: string, label: string }[];
  phoneCustomSettings: ISipPhoneCustomSettings;

  readonly is_hotel: boolean = FlexIvrSettings.getInstance().uiHotel();

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly toast: ToastService,
    private readonly sipPhoneService: SipPhoneService,
    readonly authServive: AuthenticationService,
  ) {
    super();
    this.currentUser = authServive.getUser();

    this.phoneModels = [
      { value: PhoneModelType.grandstream_wp820, label: 'sipphone.phone_model_type.grandstream_wp820' },
      { value: PhoneModelType.grandstream_gxp17xx, label: 'sipphone.phone_model_type.grandstream_gxp17xx' },
      { value: PhoneModelType.grandstream_grp26xx, label: 'sipphone.phone_model_type.grandstream_grp26xx' },
      { value: PhoneModelType.yealink_t33g, label: 'sipphone.phone_model_type.yealink_t33g' },
      { value: PhoneModelType.yealink_t53w, label: 'sipphone.phone_model_type.yealink_t53w' },
      { value: PhoneModelType.yealink_t54w, label: 'sipphone.phone_model_type.yealink_t54w' },
      { value: PhoneModelType.yealink_t42x, label: 'sipphone.phone_model_type.yealink_t42x' },
      { value: PhoneModelType.xenios_icd012, label: 'sipphone.phone_model_type.xenios_icd012' },
      { value: PhoneModelType.xenios_itl010, label: 'sipphone.phone_model_type.xenios_itl010' },
      { value: PhoneModelType.fanvil_h2u, label: 'sipphone.phone_model_type.fanvil_h2u' },
      { value: PhoneModelType.fanvil_h3w, label: 'sipphone.phone_model_type.fanvil_h3w' },
      { value: PhoneModelType.fanvil_h5w, label: 'sipphone.phone_model_type.fanvil_h5w' }
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.sipphone = _.cloneDeep(this.editingItem);
    this.setOriginSipPhone();
    this.initFormGroup();
    this.setCallProfileControlValue();
  }

  setOriginSipPhone(): void {
    this.originSipPhone = _.cloneDeep(this.sipphone);
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      extension: [
        this.sipphone.extension,
        [
          Validators.required,
          SipPhoneExtensionValidated(),
          Validators.maxLength(20),
          usedValidator(this.sipphone.id),
        ],
      ],
      password: [
        this.sipphone.password,
        [
          Validators.required,
          Validators.minLength(6),
        ],
      ],
      calling_profile: [this.callingProfile],
      calling_number: [this.sipphone.did_calling_number],
      is_hotelroom: [!!this.sipphone.hotel_room_id],
      hotelroom: [this.sipphone.hotelroom],
      user: [this.sipphone.user],
      description: [this.sipphone.description],
      enabled: [this.sipphone.enabled],
      key_config: [this.sipphone.key_config],
      custom_config: [this.sipphone.custom_config],
      phone_model: [this.sipphone.phone_model],
      mac_addr: [this.sipphone.mac_addr, [Validators.maxLength(20), NoWhitespaceValidator, MACValidator]],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
        console.log('value changes', changes);
      })
    );

    this.addSubscription(
      this.form.get('phone_model').valueChanges.subscribe(changes => {
        this.setPhoneCustomSettings(changes);
      })
    );

    this.setPhoneCustomSettings(this.form.get('phone_model').value);
  }

  private setPhoneCustomSettings(phoneModel: PhoneModelType): void {
    this.phoneCustomSettings = null;

    this.sipPhoneService.getPhoneCustomSettings(phoneModel)
      .then(resp => {
        console.log('getPhoneCustomSettings', resp);
        this.phoneCustomSettings = resp;
      })
      .catch(e => {
        console.error('getPhoneCustomSettings failed', e);
      });
  }

  get extensionCtrl(): AbstractControl {
    return this.form.get('extension');
  }

  onChangeKeyConfig($event): void {
    const keyConfig = $event;
    console.log('onChangeKeyConfig', keyConfig);
    this.form.get('key_config').setValue(keyConfig);
    this.form.get('key_config').markAsDirty();
    this.form.updateValueAndValidity();
  }

  onChangeCustomConfig($event): void {
    const customConfig = $event;
    console.log('onChangeCustomConfig', customConfig);
    this.form.get('custom_config').setValue(customConfig);
    this.form.get('custom_config').markAsDirty();
    this.form.updateValueAndValidity();
  }

  getExtensionErrorMessage(): string {
    if (this.extensionCtrl.hasError('required')) {
      return _ti('form.validator.field_required');
    }

    if (this.extensionCtrl.hasError('invalidSipPhoneExtension')) {
      return _ti('sipphone.validator.extension_pattern_x', { x: 3 });
    }

    if (this.extensionCtrl.hasError('maxlength')) {
      return _ti('form.validator.max_length', { max: 20 });
    }

    if (this.extensionCtrl.hasError('used')) {
      return _ti('sipphone.validator.sipphone_extension_in_used');
    }
  }

  private setCallProfileControlValue(): void {
    const profileList = CallingProfileRepository.getInstance().getObjectList();
    this.callingProfile = profileList.find(i => i.id === this.sipphone.calling_profile_id);
    if (this.callingProfile) {
      this.form.get('calling_profile').setValue(this.callingProfile);
    }
  }

  // isExistExtension = extension => {
  //   if (this.editingItem.extension === extension) {
  //     return false;
  //   }

  //   const sipphoneList = this.sipphoneRepo.getObjectList();
  //   const sipphone = _.find(sipphoneList, (obj: any) => {
  //     return obj.extension === extension && obj.id !== this.sipphone.id;
  //   });

  //   return !!sipphone;
  // }

  hasControlValue(controlName: string): any {
    return controlName && this.form.controls[controlName].value;
  }

  // Form actions
  removeControlValue(controlName: string, e?: MouseEvent): void {
    if (e) {
      e.stopPropagation();
    }
    this.form.controls[controlName].markAsDirty();
    this.form.controls[controlName].setValue(null);
  }

  private getFormResetData(): object {
    return {
      extension: this.sipphone.extension,
      password: this.sipphone.password,
      calling_profile: this.callingProfile,
      calling_number: this.sipphone.did_calling_number,
      is_hotelroom: !!this.sipphone.hotel_room_id,
      hotelroom: this.sipphone.hotelroom,
      user: this.sipphone.user,
      description: this.sipphone.description,
      enabled: this.sipphone.enabled,
      key_config: this.sipphone.key_config,
      custom_config: this.sipphone.custom_config,
      phone_model: this.sipphone.phone_model,
      mac_addr: this.sipphone.mac_addr,
    };
  }

  getChangeSet(): any {
    const data = super.getChangeSet();

    if ('user' in data) {
      data.user_id = data.user && data.user.id || null;
      delete data.user;
    }

    if ('hotelroom' in data) {
      // if change hotel_room_id, must update sip_phone data of hotel room
      // if (data.hotelroom && !data.hotelroom.id) {
      //   data.hotelroom.sip_phone = undefined;
      // }

      data.hotel_room_id = data.hotelroom && data.hotelroom.id || null;
      delete data.hotelroom;
    }

    if ('is_hotelroom' in data) {
      if (data.is_hotelroom) {
        data.user_id = null;
      } else {
        data.hotel_room_id = null;
      }
      delete data.is_hotelroom;
    }

    if ('calling_number' in data) {
      data.calling_number_id = data.calling_number = data.calling_number && data.calling_number.id || null;
      delete data.calling_number;
    }

    if ('calling_profile' in data) {
      data.calling_profile_id = data.calling_profile && data.calling_profile.id || null;
      delete data.calling_profile;
    }

    if ('enabled' in data) {
      data.enabled = data.enabled ? 1 : 0;
    }

    if ('mac_addr' in data) {
      data.mac_addr = data.mac_addr && _.trim(data.mac_addr);
    }

    return data;
  }

  async submitForm(): Promise<void> {
    this.form.get('key_config').setErrors(null);
    if (!this.sipphoneKeyConfig.isValid()) {
      this.form.get('key_config').setErrors({ invalid: true });
    }

    this.form.get('custom_config').setErrors(null);
    if (!this.sipphoneCustomConfig.isValid()) {
      this.form.get('custom_config').setErrors({ invalid: true });
    }

    if (this.form.invalid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return;
    }

    const keyConfigs: ISipPhoneKeyConfig[] = this.form.get('key_config').value;
    if (keyConfigs) {
      for (const keyConfig of keyConfigs) {
        if (keyConfig.type === SipphoneKeyConfigType.Undefined) {
          keyConfig.data = null;
          keyConfig.label = null;
        }
      }
    }

    const updatedPhone = this.sipphoneRepo.create();
    const callingProfile = this.form.get('calling_profile').value;
    const macAddr: string = this.form.get('mac_addr').value && _.trim(this.form.get('mac_addr').value);

    updatedPhone.setObjectData({
      id: this.sipphone.id,
      extension: this.form.get('extension').value,
      password: this.form.get('password').value,
      calling_profile_id: callingProfile && callingProfile.getId(),
      calling_number: this.form.get('calling_number').value ? this.form.get('calling_number').value.id : undefined,
      user: !this.form.get('is_hotelroom').value && this.form.get('user').value || null,
      description: this.form.get('description').value,
      enabled: this.form.get('enabled').value ? 1 : 0,
      key_config: keyConfigs,
      custom_config: this.form.get('custom_config').value,
      hotelroom: this.form.get('is_hotelroom').value && this.form.get('hotelroom').value || null,
      phone_model: this.form.get('phone_model').value || null,
      mac_addr: macAddr && macAddr.toLowerCase() || null
    });

    const updateData = this.getChangeSet();
    const fieldList = Object.keys(updateData);

    try {
      // const returnedPhone: any = 
      await this.sipphoneRepo.saveAttrs(updatedPhone, fieldList, updateData);
      this.toast.show(_ti('sipphone.message.update_success'));

      const em = EntityManager.getInstance();
      // await em.reloadRepositories([]);
      // this.sipphone.setObjectData(returnedPhone, true);
      this.sipphone = this.sipphoneRepo.getObjectById(this.sipphone.id);
      this.setOriginSipPhone();
      this.callingProfile = callingProfile;

      if (this.is_hotel) {
        // If clear hotel_room_id, must update related data for hotel room object
        em.getRepository<HotelRoomRepository>('HotelRoomRepository').reloadRelatedData();
      }

      // Call resetForm to put the form in a clean state (pristine state)
      this.resetForm();
    } catch (e) {
      const errMsg = e.error && e.error.message || e.message || _ti('public.message.update_failure');
      this.toast.showError(errMsg);
    }
  }

  resetForm(): void {
    this.sipphone = _.cloneDeep(this.originSipPhone);
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  openSipPhoneLogs(): void {
    const modalConfig: IFlexDialogConfig = {
      data: { sipPhoneId: this.sipphone.id }
    };
    this.dialogService.openDialog2(SipPhoneLogModal, modalConfig);
  }

  // openCustomConfig(): void {
  //   this.dialogService.openDialog2(SipphoneCustomConfigComponent, {
  //     data: {sipphone_id: this.sipphone.id}
  //   });
  // }

  disabledHotelRoomOptionFunc = (hotelRoom: HotelRoomEntity): boolean => {
    return hotelRoom.sip_phone || hotelRoom.sip_phone && hotelRoom.sip_phone.id === this.sipphone.id ? true : false;
  }
}
