
import { Injectable, PipeTransform } from '@angular/core';
import { SecondsAsDurationPipe } from '../pipes';
import { ColumnTypes } from '../datasource/datasource-types';

@Injectable()
export class DataRenderService {
    private rendererMap: {[key: string]: PipeTransform};

    constructor(secondAsTimePipe: SecondsAsDurationPipe) {
        this.rendererMap = {
            seconds: secondAsTimePipe
        };
    }

    renderData(value: string, type: ColumnTypes): string {
        const renderer = this.rendererMap[type];
        if (renderer) {
            return renderer.transform(value);
        }

        return value;
    }
}
