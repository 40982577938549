<div fxLayout="column" fxFlex="grow" class="flex-container">
  <h2 class="mb-0">{{ 'recording_call.title'| translate }}</h2>
  <div class="filter-container" *ngIf="!filteredPublicFileId">
    <form [formGroup]="filterForm">
      <period-select [startDate]="startDateControl.value" [endDate]="endDateControl.value"
        (changedPeriod)="changePeriod($event)"></period-select>
      <div *ngIf="filtersReadonly" align="center">
        <div fxLayout="column">
          <div>
            <span *ngIf="startDateControl.value && endDateControl.value">{{startDateControl.value
              | timeFormatDateShort }} --&gt; {{endDateControl.value | timeFormatDateShort}}</span>&nbsp;
            <!-- filterForm.get('filter_time').value &&  -->
            <span *ngIf="filterForm.get('start_time').value != filterForm.get('end_time').value">
              {{ 'stats.callcenter.search_in_time'|translate: {start_value: filterForm.get('start_time').value,
              end_value: filterForm.get('end_time').value} }}
            </span>
          </div>
          <span *ngIf="groupsControl.value.length">
            <strong>{{ 'stats.filter.group'|translate }}</strong>: {{ rendererGroup(groupsControl.value)|truncateArray
            }}
          </span>
          <span *ngIf="filterForm.get('queues').value.length">
            <strong>{{ 'stats.filter.queue'|translate }}</strong>: {{
            rendererQueue(filterForm.get('queues').value)|truncateArray }}
          </span>
          <span *ngIf="filterForm.get('agents').value.length">
            <strong>{{ 'stats.filter.agent'|translate }}</strong>: {{
            rendererUser(filterForm.get('agents').value)|truncateArray }}
          </span>
          <span *ngIf="filterForm.get('qualifications').value.length">
            <strong>{{ 'stats.handled_call.columns.qualification'|translate }}</strong>:
            {{ rendererQualifi(filterForm.get('qualifications').value)|truncateArray }}
          </span>
          <span *ngIf="filterForm.get('numbers').value.length">
            <strong>{{ 'stats.filter.phone_number'|translate }}</strong>:
            {{ rendererNumber(filterForm.get('numbers').value)|truncateArray }}
          </span>
          <span *ngIf="filterForm.get('remote_number').value">
            <strong>{{ 'recording_call.remote_number'|translate }}</strong>:
            {{filterForm.get('remote_number').value}}
          </span>
          <span *ngIf="filterForm.get('campaigns').value.length">
            <strong>{{ 'stats.filter.campaign'|translate }}</strong>: {{
            rendererCampaign(filterForm.get('campaigns').value)|truncateArray }}
          </span>
          <span *ngIf="filterForm.get('filter_duration').value">
            <strong>{{ 'recording_call.table_filter.number'|translate }}</strong>: {{ filterForm.get('start_min').value
            }} --&gt; {{ filterForm.get('end_min').value }}
            <strong>{{'recording_call.table_filter.minutes'|translate}}</strong>
          </span>

          <a class="link" (click)="changeFilter()">{{'public.change' | translate}}</a>
        </div>
      </div>
      <div *ngIf="!filtersReadonly">
        <div fxLayout="row wrap" fxLayoutAlign="start end" fxFlexFill fxLayoutGap="20px" fxLayoutGap.xs="0">
          <mat-form-field fxFlex="calc(20%-20px)" fxFlex.xs="60">
            <mat-label>{{ 'public.from'|translate }}</mat-label>
            <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
              placeholder="{{ 'public.from'|translate }}">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error
              *ngIf="startDateControl.hasError('flexDatepickerMin') || startDateControl.hasError('required') && startDateControl.touched">
              {{ 'form.validator.required'|translate }}
            </mat-error>
          </mat-form-field>
          <div fxFlex="calc(10%-20px)" fxFlex.xs="40">
            <flex-time-picker formControlName="start_time" [placeholder]="'campaign.hour_start' | translate">
            </flex-time-picker>
          </div>
          <mat-form-field fxFlex="calc(20%-20px)" fxFlex.xs="60">
            <mat-label>{{ 'public.to'|translate }}</mat-label>
            <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
              [matDatepickerFilter]="minEndDateFilter" placeholder="{{ 'public.to'|translate}}">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="endDateControl.hasError('required') && endDateControl.touched">
              {{ 'form.validator.required'|translate }}
            </mat-error>
            <mat-error
              *ngIf="endDateControl.hasError('flexDatepickerMin') || endDateControl.hasError('matDatepickerFilter')">
              {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
              startDateControl.value | timeFormatDateShort} }}
            </mat-error>
          </mat-form-field>
          <div fxFlex="calc(10%-20px)" fxFlex.xs="40">
            <flex-time-picker formControlName="end_time" [placeholder]="'campaign.hour_end' | translate">
            </flex-time-picker>
          </div>
          <mat-form-field fxFlex="calc(40%-20px)" fxFlex.xs="100" *ngIf="!isInDevMode">
            <mat-label>{{ 'recording_call.table_filter.audio_state'|translate }}</mat-label>
            <mat-select placeholder="{{'recording_call.table_filter.audio_state'| translate}}"
              formControlName="audio_state">
              <mat-option value="">{{'public.all' | translate}}</mat-option>
              <mat-option value="0">{{'recording_call.audio_state.0' | translate}}</mat-option>
              <mat-option value="1">{{'recording_call.audio_state.1' | translate}}</mat-option>
              <mat-option value="2">{{'recording_call.audio_state.2' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex="auto" fxFlex.xs="100" *ngIf="hasFeature('SERVICE_GROUP')">
            <mat-form-field class="full-width">
              <mat-label>{{ 'stats.filter.select_group' | translate }}</mat-label>
              <flex-select-group-input [limitedByRole]="true" [showCheckAll]="true" [multiple]="true"
                [filteredIds]="filteredGroupIds" [hasAgentOrQueueOrDid]="true"
                [placeholder]="'stats.filter.select_group' | translate" groupType="service"
                formControlName="groups"></flex-select-group-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'groups', true)"
                [disabled]="!filterForm.get('groups').value || !filterForm.get('groups').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="auto" fxFlex.xs="100">
            <mat-form-field class="full-width flex-select">
              <mat-label>{{ 'stats.filter.select_queue' | translate }}</mat-label>
              <flex-select-queue-input [limitedByRole]="true" [groupIds]="groupsControl.value" [showCheckAll]="true"
                [filteredIds]="filteredQueueIds" [multiple]="true" queueType="inbound"
                placeholder="{{ 'stats.filter.select_queue' | translate }}"
                formControlName="queues"></flex-select-queue-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'queues', true)"
                [disabled]="!filterForm.get('queues').value || !filterForm.get('queues').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="auto" fxFlex.xs="100">
            <mat-form-field class="full-width">
              <mat-label>{{ 'stats.filter.select_agent' | translate }}</mat-label>
              <flex-select-user-input [showCheckAll]="true" [multiple]="true" [hasUser]="true"
                [filteredIds]="filteredUserIds" placeholder="{{ 'stats.filter.select_agent' | translate }}"
                formControlName="agents">
              </flex-select-user-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'agents', true)"
                [disabled]="!filterForm.get('agents').value || !filterForm.get('agents').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <mat-form-field fxFlex="auto" fxFlex.xs="100">
            <mat-label>{{ 'stats.filter.select_qualification' | translate }}</mat-label>
            <flex-select-qualification-input [groupIds]="groupsControl.value" [showCheckAll]="true" [multiple]="true"
              formControlName="qualifications" placeholder="{{ 'stats.filter.select_qualification' | translate }}">
            </flex-select-qualification-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'qualifications', true)"
              [disabled]="!filterForm.get('qualifications').value || !filterForm.get('qualifications').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field fxFlex="auto" fxFlex.xs="100">
            <mat-label>{{ 'stats.filter.select_did' | translate }}</mat-label>
            <flex-select-did-input [multiple]="true" [showCheckAll]="true" [hasAnonymous]="hasAnonymous"
              [didList]="didConfiguredList" placeholder="{{ 'stats.filter.select_did' | translate }}"
              formControlName="numbers">
            </flex-select-did-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'numbers', true)"
              [disabled]="!filterForm.get('numbers').value || !filterForm.get('numbers').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div fxFlex="auto" fxFlex.xs="100">
            <mat-form-field class="full-width flex-select">
              <mat-label>{{ 'stats.filter.select_campaign' | translate }}</mat-label>
              <flex-select-campaign-input [groupId]="groupsControl.value" [showCheckAll]="true" [multiple]="true"
                placeholder="{{ 'stats.filter.select_campaign' | translate }}"
                formControlName="campaigns"></flex-select-campaign-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'campaigns', true)"
                [disabled]="!filterForm.get('campaigns').value || !filterForm.get('campaigns').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <mat-form-field fxFlex="auto" fxFlex.xs="100">
            <mat-label>{{'recording_call.remote_number'|translate}}</mat-label>
            <input matInput placeholder="{{'recording_call.remote_number'|translate}}" formControlName="remote_number">
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'remote_number', false)"
              [disabled]="!filterForm.get('remote_number').value || !filterForm.get('remote_number').value">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint>{{'recorded_calls.filter.remote_number_hint'|translate}}</mat-hint>
            <mat-error
              *ngIf="filterForm.get('remote_number').hasError('pattern') && filterForm.get('remote_number').touched">
              {{ 'form.validator.data_invalid'|translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex="auto" fxFlex.xs="100">
            <mat-checkbox formControlName="filter_duration">{{'recording_call.table_filter.number'|translate}}
            </mat-checkbox>
          </div>
          <mat-form-field fxFlex="auto" fxFlex.xs="100" *ngIf="filterForm.get('filter_duration').value">
            <mat-label>{{'public.from'|translate}}</mat-label>
            <input matInput placeholder="{{'public.from'|translate}}" type="number" min="0" formControlName="start_min">
            <mat-hint>
              <strong>{{'recording_call.table_filter.minutes'|translate}}</strong>
            </mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex="auto" fxFlex.xs="100" *ngIf="filterForm.get('filter_duration').value">
            <mat-label>{{'public.to'|translate}}</mat-label>
            <input matInput placeholder="{{'public.to'|translate}}" type="number" min="0" formControlName="end_min">
            <mat-hint>
              <strong>{{'recording_call.table_filter.minutes'|translate}}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="text-center">
          <button mat-raised-button color="accent" (click)="reloadClick()">{{ 'public.apply' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
  <div fxFlex="grow" *ngIf="is_ready">
    <flex-table #flexTable fxFill fxFlex [dataSource]="dataSource" [tableConfig]="tableConfig" [columnTemplates]="{
        duration: tplDuration, 
        direction: tplDirection, 
        recorded_dt: tplRecordedDate,
        local_number: tplLocalNumber,
        action: tplAction
      }">

      <ng-template #tplDuration let-item="row">
        <span>{{ (item.duration || 0)|seconds_as_duration}}</span>
      </ng-template>

      <ng-template #tplDirection let-item="row">
        <span *ngIf="item.direction === 1; else callOut" class="mat-text-primary">
          <mat-icon color="accent" matTooltip="{{ 'cdr.incoming'|translate }}">arrow_downward</mat-icon>
        </span>
        <ng-template #callOut>
          <span class="mat-text-warn">
            <mat-icon color="warn" matTooltip="{{ 'cdr.outgoing'|translate }}">arrow_upward</mat-icon>
          </span>
        </ng-template>
      </ng-template>

      <ng-template #tplRecordedDate let-item="row">
        {{ item.recorded_dt|timeFormatDateTimeShort }}
      </ng-template>

      <ng-template #tplLocalNumber let-item="row">
        {{ item.local_number | displayPhoneNumber }}
      </ng-template>

      <!-- <ng-template #tplRemoteNumber let-item="row">
        <div class="client-info"
          [matTooltip]="(item.remote_number|displayPhoneNumber) + (item.client_name ? '&nbsp;(' + item.client_name + ')' : '')">
          {{ item.remote_number | displayPhoneNumber }}
          <ng-container *ngIf="item.client_name">&nbsp;({{item.client_name}})</ng-container>
        </div>
      </ng-template> -->

      <ng-template #tplAction let-item="row">
        <div class="cell-actions">
          <fileentry-player-button [disabled]="!item.file_entry_ready" [filePublicId]="item.file_public_id">
          </fileentry-player-button>

          <button mat-icon-button [disabled]="!item.file_entry_ready" (click)="downloadRecoredCall(item)"
            matTooltip="{{ 'public.button.download'|translate }}">
            <mat-icon>cloud_download</mat-icon>
          </button>

          <!--Copy-detail-->
          <button mat-icon-button [disabled]="!item.file_entry_ready" (click)="copyRecoredCallUrl(item)"
            matTooltip="{{ 'public.share'|translate }}">
            <mat-icon>share</mat-icon>
          </button>

          <!--Qualify-->
          <ng-container *ngIf="canQualify(item.call_log); else NoQualification">
            <ng-container *ngIf="(item.call_log.qualification_comment || (item.call_log.qualification_ids && item.call_log.qualification_ids.length)); else AddQualificationBlock">
              <button mat-icon-button (click)="reQualify(item)" color="warn"
                matTooltip="{{ 'call.actions.change_qualification' | translate }}">
                <mat-icon>edit_note</mat-icon>
              </button>
            </ng-container>
            <ng-template #AddQualificationBlock>
              <button mat-icon-button color="primary" (click)="reQualify(item)"
                matTooltip="{{ 'call.actions.add_qualification' | translate }}">
                <mat-icon>note_add</mat-icon>
              </button>
            </ng-template>
          </ng-container>
          <ng-template #NoQualification>
            <button mat-icon-button color="primary" disabled
              matTooltip="{{ 'call.actions.add_qualification' | translate }}">
              <mat-icon>note_add</mat-icon>
            </button>
          </ng-template>

          <!--Call-detail-->
          <button mat-icon-button color="primary" (click)="detailHandledCallInfo(item.call_log)">
            <mat-icon [matTooltip]="'call.actions.view_detail'|translate">info</mat-icon>
          </button>

          <!--Transcription-detail-->
          <button [disabled]="!(item.transcription_state === 2 || item.analysis_state === 2)"
            mat-icon-button (click)="showTranscriptionResult(item)"
            [color]="item.analysis_state === 2 ? 'primary' : 'accent'"
            [matTooltip]="'recording_call.content.transcription_detail'|translate">
            <mat-icon>insights</mat-icon>
          </button>

          <!--Transcription-->
          <ng-container *ngIf="hasTranscription">
            <button mat-icon-button *ngIf="item.transcription_status === 0" (click)="downloadTranscription(item)"
              matTooltip="{{'public.button.speech2text'|translate}}">
              <mat-icon>sync</mat-icon>
            </button>
            <button mat-icon-button *ngIf="item.transcription_status === 1" (click)="downloadTranscription(item)"
              matTooltip="{{'public.button.speech2text'|translate}}">
              <mat-icon>downloading</mat-icon>
            </button>
            <button mat-icon-button *ngIf="item.transcription_status === 2" (click)="downloadTranscription(item)"
              matTooltip="{{'public.button.download'|translate}}">
              <mat-icon>text_snippet</mat-icon>
            </button>
          </ng-container>

          <!--Crm-contact-->
          <div *ngIf="activeCRM" class="crm-logo" (click)="openContactProfile(item.call_log)" matTooltip="{{activeCRM.name}}">
            <img *ngIf="crmConfig" src="assets/images/{{crmConfig.logo}}" alt="{{activeCRM.type}}">
          </div>

        </div>
      </ng-template>

    </flex-table>
  </div>
</div>
