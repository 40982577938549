import { Pipe, PipeTransform } from '@angular/core';
import { durationFromSeconds } from '../utils';

@Pipe({
  name: 'seconds_as_duration'
})
export class SecondsAsDurationPipe implements PipeTransform {
  // Transform is the new "return function(value, args)" in Angular 1.x
  transform(seconds, format: string = 'hh:mm:ss'): string {
    let ret = seconds;
    if (!isNaN(parseInt(seconds))) {
      let fmt = format;
      const secs = parseInt(seconds);
      const duration = durationFromSeconds(secs);
      if (fmt && fmt === 'mm:ss' && duration.as('hour') >= 1) {
        fmt = 'hh:mm:ss';
      }
      ret = duration.toFormat(fmt || 'hh:mm:ss');
    }

    return ret;
  }
}
