import { Component, OnInit, ViewChild } from '@angular/core';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { _tk, _ti } from '@wephone-translation';
import { InvoiceRepository } from '@wephone-core/model/repository/invoice';
import { InvoiceEntity } from '@wephone-core/model/entity/invoice';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceService } from '@wephone-core/service/invoice.service';
import { SubscriptionEntity } from '@wephone-core/model/entity/subscription';
import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommaSepEmailValidator, EditingComponent, NoWhitespaceValidator, ToastService } from '@wephone-utils';
import * as _ from 'lodash';

@Component({
  selector: 'app-enterprise-invoices-pament',
  templateUrl: './enterprise-invoices-pament.component.html',
  styleUrls: ['./enterprise-invoices-pament.component.scss']
})
export class EnterpriseInvoicesPamentComponent extends EditingComponent implements OnInit {
  private readonly myEnterpriseRepo = EnterpriseRepository.getInstance<EnterpriseRepository>();
  private readonly invoiceRepo = InvoiceRepository.getInstance<InvoiceRepository>();

  myEnterprise: EnterpriseEntity;
  subscription: SubscriptionEntity;
  subscriptionPack: SubscriptionPackEntity;

  steps = [
    { id: 'enterprise_config', label: 'enterprise_info.step.enterprise_configuration' },
    { id: 'payment_info', label: 'enterprise_info.step.payment_info' },
    { id: 'billing_plan', label: 'enterprise_info.step.billing_plan' },
    { id: 'subscription', label: 'client.content.subscription' },
    { id: 'invoice_history', label: 'enterprise_info.step.invoice_history' }
  ];

  form: FormGroup;

  invoiceDataSource: MatTableDataSource<any>;
  displayedColumns = ['invoice_number', 'invoice_dt', 'total', 'download'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly invoiceService: InvoiceService,
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    await this.resolveData();

    this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
  }

  protected async resolveData(): Promise<any> {
    this.setMyEnterprise();
    this.subscription = await this.myEnterprise.getCurrentSubscription();
    this.subscriptionPack = await this.myEnterprise.getCurrentSubscriptionPack();

    this.form = this.fb.group({
      vat_number: [this.myEnterprise.vat_number, [NoWhitespaceValidator]],
      billing_contact_email: [this.myEnterprise.billing_contact_email, [CommaSepEmailValidator]],
    });

    const invoices = await this.invoiceRepo.findAll();
    const paidInvoices = invoices.filter(invoice => invoice.is_paid);
    this.invoiceDataSource = new MatTableDataSource(paidInvoices);
    this.invoiceDataSource.sort = this.sort;
  }

  setMyEnterprise(): void {
    this.myEnterprise = this.myEnterpriseRepo.getMyEnterprise();
  }

  resetForm(): void {
    this.form.reset({
      vat_number: this.myEnterprise.vat_number,
      billing_contact_email: this.myEnterprise.billing_contact_email,
    });

    this.form.markAsPristine();
  }

  downloadCsv(invoice: InvoiceEntity): void {
    const url = this.invoiceService.getInvoiceDownloadCsvUrl(invoice.id);
    window.open(url, '_blank');
  }

  async formDataTransform(formData: { [key: string]: any }): Promise<{ [key: string]: any }> {
    const attrList = Object.keys(formData);
    for (const attr of attrList) {
      switch (attr) {
        case 'vat_number':
          formData[attr] = _.trim(formData[attr]);
          break;
        default:
          break;
      }
    }
    return formData;
  }

  async submit(): Promise<any> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        throw new Error(_ti('public.message.data_invalid'));
      }

      const formData = this.formService.getChangeSet(this.form);
      const formDataUpdated = await this.formDataTransform(formData);
      const updatedData = await this.myEnterpriseRepo.updateEnterprise(formDataUpdated);
      delete updatedData.id;
      this.myEnterprise.setObjectData(updatedData, true);
      this.setMyEnterprise();
      this.resetForm();
      this.toast.showSuccess(_ti('public.message.update_success'));
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }
}
