import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService, joiValidator } from '@wephone-utils';
import { PhoneBookEntry } from '@wephone-core/model/entity/phonebook';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PhoneBookRepository } from '@wephone-core/model/repository/phonebook';
import { _tk, _ti } from '@wephone-translation';
import { PhoneNumberValidated, OneRequired } from '@wephone/services/form-validator';

@Component({
  selector: 'app-edit-phone-book',
  templateUrl: './edit-phone-book.component.html',
  styleUrls: ['./edit-phone-book.component.scss']
})
export class EditPhoneBookComponent extends EditingComponent implements OnInit {
  @Input() editingItem: PhoneBookEntry;

  public phoneBook: PhoneBookEntry;
  public form: FormGroup;

  private phoneBookRepo = EntityManager.getRepository<PhoneBookRepository>('PhoneBookRepository');

  constructor(
    private fb: FormBuilder,
    public toast: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.phoneBook = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      firstName: [this.phoneBook.first_name, joiValidator(Joi.string().trim().max(128).required())],
      lastName: [this.phoneBook.last_name, joiValidator(Joi.string().trim().max(128).optional().allow(null).allow(''))],
      email: [this.phoneBook.email, joiValidator(Joi.string().trim().max(128).optional().allow(null).allow(''))],
      phoneOffice: [this.phoneBook.phone_office, [PhoneNumberValidated(), Validators.maxLength(20)]],
      phoneMobile: [this.phoneBook.phone_mobile, [PhoneNumberValidated(), Validators.maxLength(20)]]
    }, {
      validators: OneRequired(['phoneOffice', 'phoneMobile'])
    });

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });
  }

  private getFormResetData(): any {
    return {
      firstName: this.phoneBook.first_name,
      lastName: this.phoneBook.last_name,
      email: this.phoneBook.email,
      phoneOffice: this.phoneBook.phone_office,
      phoneMobile: this.phoneBook.phone_mobile
    };
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  private validForm(): boolean {
    for (const key of Object.keys(this.form.controls)) {
      this.form.get(key).markAsTouched();
    }

    if (!this.form.valid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return false;
    }

    return true;
  }

  async submitForm(): Promise<any> {
    const updatedPhoneBook = this.phoneBookRepo.create() as PhoneBookEntry;
    updatedPhoneBook.setObjectData({
      id: this.phoneBook.id,
      first_name: this.form.get('firstName').value,
      last_name: this.form.get('lastName').value,
      email: this.form.get('email').value,
      phone_office: this.form.get('phoneOffice').value,
      phone_mobile: this.form.get('phoneMobile').value
    });

    try {
      if (!this.validForm()) {
        console.warn('Form data invalid');
        return;
      }

      const attrList = ['first_name', 'last_name', 'email', 'phone_office', 'phone_mobile'];
      const result: { object_id: number } = await this.phoneBookRepo.saveAttrs(updatedPhoneBook, attrList);
      this.toast.show(_ti('public.message.update_success'));
      this.phoneBook = _.cloneDeep(this.phoneBookRepo.getObjectById(result.object_id));
      this.resetForm();
    } catch (error) {
      console.error('Error: ', error);
      this.toast.showError(_ti('public.message.update_failure'));
    }
  }

  onTouched(controlNames: string[]): void {
    for (const name of controlNames) {
      this.form.get(name).markAsTouched();
    }
  }
}
