import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicFilterSource, FlexTableComponent, IFlexTableConfig, regexSearch, ToastService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { SipPhoneProvisioningTokenEntity } from '@wephone-core/model/entity/sip_phone_provisioning_token';
import { SipPhoneProvisioningTokenRepository } from '@wephone-core/model/repository/sip_phone_provisioning_token';
import { FlexBasePage } from '@wephone-core-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ConfiguredPhone,
  PendingConfigRequest,
  SipPhoneService,
} from '@wephone-common/service';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import {
  UpdateSipPhoneProvisioningTokenModalComponent,
} from '@wephone/modals/pending-config-requests/update-sip-phone-provisioning-token.component';
import {
  ShowProvisioningTokenModalComponent,
} from '@wephone/modals/show-provisioning-token/show-provisioning-token.component';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-sip-phone-provisioning-token-list',
  host: { class: 'flex-page-component' },
  templateUrl: './sip-phone-provisioning-token-list.component.html',
  styleUrls: ['./sip-phone-provisioning-token-list.component.scss'],
})
export class SipPhoneProvisioningTokenListComponent extends FlexBasePage implements OnInit {
  @ViewChild('FlexTableProvisionToken', { static: false }) FlexTableProvisionToken: FlexTableComponent;
  @ViewChild('FlexTablePendingConfigRequests', { static: false }) FlexTablePendingConfigRequests: FlexTableComponent;
  @ViewChild('FlexTableConfiguredPhones', { static: false }) FlexTableConfiguredPhones: FlexTableComponent;

  sipPhoneRepo = SipPhoneRepository.getInstance();

  dataSourceProvisionToken: DynamicFilterSource;
  dataSourcePendingConfigRequests: DynamicFilterSource;
  dataSourceConfiguredPhones: DynamicFilterSource;

  tableConfigProvisionToken: IFlexTableConfig;
  tableConfigPendingConfigRequests: IFlexTableConfig;
  tableConfigConfiguredPhones: IFlexTableConfig;

  private _dataSource: BehaviorSubject<SipPhoneProvisioningTokenEntity[]>;
  private _dataSourcePendingConfigRequests: BehaviorSubject<PendingConfigRequest[]>;
  private _dataSourceConfiguredPhones: BehaviorSubject<ConfiguredPhone[]>;

  constructor(
    public readonly toast: ToastService,
    private readonly dialogService: DialogService,
    private readonly sipPhoneService: SipPhoneService,
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;

    this._dataSource = new BehaviorSubject<SipPhoneProvisioningTokenEntity[]>([]);
    this._dataSourcePendingConfigRequests = new BehaviorSubject<PendingConfigRequest[]>([]);
    this._dataSourceConfiguredPhones = new BehaviorSubject<ConfiguredPhone[]>([]);

    this.tableConfigProvisionToken = {
      scrollable: false,
      multiSelect: false,
      enableFilter: true,
      enableExportCsv: false,
      columns: [
        {
          name: 'name',
          label: 'public.name',
          sortable: true,
          searchable: true
        },
        {
          name: 'creation_dt',
          label: 'public.creation_date',
          sortable: true,
          sort: 'desc',
          searchable: false
        },
        {
          name: 'valid_until',
          label: 'public.expiration_date',
          sortable: true,
          searchable: false
        },
        {
          name: 'action',
          label: 'public.action',
          searchable: false
        }
      ]
    };

    this.tableConfigPendingConfigRequests = {
      scrollable: false,
      multiSelect: false,
      enableFilter: true,
      enableExportCsv: false,
      columns: [
        {
          name: 'mac_addr',
          label: 'phone_config_request.content.mac_addr',
          sortable: true,
          searchable: true
        },
        {
          name: 'name',
          label: 'public.name',
          sortable: true,
          searchable: true
        },
        {
          name: 'last_request_time',
          label: 'phone_config_request.content.last_request_time',
          sortable: true,
          sort: 'desc',
          searchable: false
        },
        {
          name: 'action',
          label: 'public.action',
          searchable: false
        }
      ]
    };

    this.tableConfigConfiguredPhones = {
      scrollable: false,
      multiSelect: false,
      enableFilter: true,
      enableExportCsv: false,
      columns: [
        {
          name: 'mac_addr',
          label: 'phone_config_request.content.mac_addr',
          sortable: true,
          searchable: true
        },
        {
          name: 'provisioning_token_name',
          label: 'public.name',
          sortable: true,
          sort: 'desc',
          searchable: true
        },
        {
          name: 'extension_with_owner',
          label: 'phone_config_request.content.sipphone',
          sortable: true,
          searchable: true
        },
        {
          name: 'action',
          label: 'public.action',
          searchable: false
        }
      ]
    };
  }

  private readonly sipPhoneProvisioningTokenRepo = SipPhoneProvisioningTokenRepository.getInstance<SipPhoneProvisioningTokenRepository>();
  sipPhoneProvisioningTokens: SipPhoneProvisioningTokenEntity[] = [];
  pendingConfigRequests: PendingConfigRequest[] = [];
  configuredPhones: ConfiguredPhone[] = [];

  ngOnInit(): void {
    super.ngOnInit();

    const ds = this.getDataSource<SipPhoneProvisioningTokenEntity>();
    const dsPendingConfigRequests = this.getDataSourcePendingConfigRequests<PendingConfigRequest>();
    const dsPendingConfiguredPhones = this.getDataSourceConfiguredPhones<ConfiguredPhone>();

    this.dataSourceProvisionToken = new DynamicFilterSource(ds, this.filterFuncProvisionToken);
    this.dataSourcePendingConfigRequests = new DynamicFilterSource(dsPendingConfigRequests, this.filterFuncPendingConfigRequests);
    this.dataSourceConfiguredPhones = new DynamicFilterSource(dsPendingConfiguredPhones, this.filterFuncConfiguredPhones);
  }

  private readonly filterFuncProvisionToken = (item: SipPhoneProvisioningTokenEntity): boolean => {
    const filterString: string = this.FlexTableProvisionToken && this.FlexTableProvisionToken.filterString;
    return !filterString || regexSearch(item.name, filterString);
  }

  private readonly filterFuncPendingConfigRequests = (item: PendingConfigRequest): boolean => {
    const filterString: string = this.FlexTablePendingConfigRequests && this.FlexTablePendingConfigRequests.filterString;
    return !filterString ||
      regexSearch(item.name, filterString) ||
      regexSearch(item.mac_addr, filterString)
      ;
  }

  private readonly filterFuncConfiguredPhones = (item: ConfiguredPhone): boolean => {
    const filterString: string = this.FlexTableConfiguredPhones && this.FlexTableConfiguredPhones.filterString;
    return !filterString ||
      regexSearch(item.provisioning_token_name, filterString) ||
      regexSearch(item.mac_addr, filterString) ||
      (item.sipphone && regexSearch(item.sipphone.extension_with_owner, filterString));
  }

  getDataSource<T extends SipPhoneProvisioningTokenEntity = any>(): Observable<T[]> {
    return this._dataSource.asObservable() as Observable<T[]>;
  }

  getDataSourcePendingConfigRequests<T extends PendingConfigRequest = any>(): Observable<T[]> {
    return this._dataSourcePendingConfigRequests.asObservable() as Observable<T[]>;
  }

  getDataSourceConfiguredPhones<T extends ConfiguredPhone = any>(): Observable<T[]> {
    return this._dataSourceConfiguredPhones.asObservable() as Observable<T[]>;
  }

  async resolveData(): Promise<void> {
    await this.sipPhoneProvisioningTokenRepo.loadObjectList();
    this.refreshSipPhoneProvisioningTokens();
    await this.fetchData();
  }

  refreshSipPhoneProvisioningTokens(): void {
    this.sipPhoneProvisioningTokens = this.sipPhoneProvisioningTokenRepo.getObjectList();
    this._dataSource.next(this.sipPhoneProvisioningTokens);
  }

  async fetchData(): Promise<void> {
    this.pendingConfigRequests = await this.sipPhoneService.getPendingConfigRequest();
    this._dataSourcePendingConfigRequests.next(this.pendingConfigRequests);

    await this.refreshConfiguredPhones();
    // this.configuredPhones = await this.sipPhoneService.getConfiguredPhones();
    // this.configuredPhones.forEach(configuredPhone => {
    //   configuredPhone.sipphone = this.sipPhoneRepo.getObjectById(configuredPhone.sipphone_id);
    // });
    this.detectChanges();
  }

  private async refreshConfiguredPhones(): Promise<void> {
    this.configuredPhones = await this.sipPhoneService.getConfiguredPhones();
    this.configuredPhones.forEach(configuredPhone => {
      configuredPhone.sipphone = this.sipPhoneRepo.getObjectById(configuredPhone.sipphone_id);
    });

    this._dataSourceConfiguredPhones.next(this.configuredPhones);
  }

  onCreate(): void {
    const dialogRef = this.dialogService.openDialog2(UpdateSipPhoneProvisioningTokenModalComponent, { size: 's' });
    dialogRef.afterClosed().toPromise().then(() => {
      this.refreshSipPhoneProvisioningTokens();
      this.detectChanges();
    });
  }

  onEdit(item: SipPhoneProvisioningTokenEntity): void {
    this.editItem(item, false);
  }

  onEditCustomConfig(item: SipPhoneProvisioningTokenEntity): void {
    this.editItem(item, true);
  }

  private editItem(item: SipPhoneProvisioningTokenEntity, customConfig?: boolean): void {
    const dialogRef = this.dialogService.openDialog2(UpdateSipPhoneProvisioningTokenModalComponent, {
      data: { item, customize_config: customConfig },
      size: 's'
    });

    dialogRef.afterClosed().toPromise().then(async (ret: any) => {
      if (ret) {
        this.refreshSipPhoneProvisioningTokens();
        await this.refreshConfiguredPhones();
        this.detectChanges();
      }
    });
  }

  onClearCustomConfig(item: SipPhoneProvisioningTokenEntity): void {
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('sip_token.content.confirm_clear_customize_config'),
      async () => {
        try {
          await this.sipPhoneProvisioningTokenRepo.clearTemplateConfig(item.id);
          this.refreshSipPhoneProvisioningTokens();
          this.fetchData();
          this.toast.showSuccess(_ti('sip_token.message.clear_customize_config_success'));
        } catch (error) {
          this.toast.showErrorMessage(error, _ti('sip_token.message.clear_customize_config_failure'));
        }
      });
  }

  async onDelete(provisioningToken: SipPhoneProvisioningTokenEntity): Promise<void> {
    const accessTokens = await this.sipPhoneProvisioningTokenRepo.getPhoneConfigAccessTokensById(provisioningToken.id) || [];
    const confirmMessage = accessTokens.length > 0 ? _ti('sip_token.content.confirm_delete', { n: accessTokens.length }) : _ti('user.title.delete');
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      confirmMessage,
      async () => {
        try {
          await this.sipPhoneProvisioningTokenRepo.bulkDelete([provisioningToken]);
          this.refreshSipPhoneProvisioningTokens();
          this.fetchData();
          this.detectChanges();
          this.toast.showSuccess(_ti('public.message.delete_success'));
        } catch (error) {
          this.toast.showErrorMessage(error, _ti('public.message.delete_failure'));
        }
      });
  }

  getLink(item: SipPhoneProvisioningTokenEntity): void {
    this.dialogService.openDialog2(ShowProvisioningTokenModalComponent, { data: { item }, size: 's' });
  }

  async setConfigPhonePendingRequest(pendingConfigRequest: PendingConfigRequest): Promise<void> {
    const prompt = '';
    const dialogTitle = _tk('sipphone_provisioning_token.set_configuration');
    const sipphone: SipPhoneEntity = await this.sipPhoneService.selectSipPhone(prompt, dialogTitle);

    if (!sipphone) {
      return;
    }

    try {
      await this.sipPhoneService.validatePhoneConfigRequest(sipphone, pendingConfigRequest.mac_addr);
      this.fetchData();
      this.showInfo(_ti('public.message.update_success'));
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  deletePendingRequest(pendingConfigRequest: PendingConfigRequest): void {
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        try {
          await this.sipPhoneService.deletePhoneConfigRequest(pendingConfigRequest.mac_addr);
          this.fetchData();
          this.toast.showSuccess(_ti('public.message.delete_success'));
        } catch (error) {
          this.toast.showErrorMessage(error, _ti('public.message.delete_failure'));
        }
      });
  }

  async changeConfigPhone(configuredPhone: ConfiguredPhone): Promise<void> {
    const prompt = '';
    const dialogTitle = _tk('sipphone_provisioning_token.change_configuration');
    const sipphone: SipPhoneEntity = await this.sipPhoneService.selectSipPhone(prompt, dialogTitle, configuredPhone.sipphone);

    if (!sipphone) {
      return;
    }

    try {
      await this.sipPhoneService.changeConfiguredPhone(configuredPhone, sipphone);
      await this.refreshConfiguredPhones();
      this.showInfo(_ti('public.message.update_success'));
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  async removeSipPhone(configuredPhone: ConfiguredPhone): Promise<void> {
    if (!configuredPhone) {
      return;
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        try {
          await this.sipPhoneService.removeConfiguredPhone(configuredPhone);
          this.fetchData();
          this.showInfo(_ti('public.message.delete_success'));
        } catch (error) {
          this.toastService.showErrorMessage(error, _ti('public.message.delete_failure'));
        }
      }
    );
  }

  downloadConfigSipphone(configuredPhone: ConfiguredPhone): void {
    if (!configuredPhone.mac_addr || !configuredPhone.sipphone_id) {
      console.error('Phone is not configured', configuredPhone);
      return;
    }

    this.sipPhoneService.downloadConfigSipPhone(configuredPhone);
  }
}
