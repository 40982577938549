<div class="pa-1" fxFill fxFlex>
  <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'components.update_conference.name'|translate }}</mat-label>
        <input matInput placeholder="{{ 'components.update_conference.name'|translate }}" formControlName="name">
        
        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'components.update_conference.password'|translate }}</mat-label>
        <input matInput placeholder="{{ 'components.update_conference.password'|translate }}"
          formControlName="password">
          
        <mat-error [flexFormControlError]="form.get('password')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
