import { Component } from '@angular/core';
import { MenuService, MainMenuItem } from '../menu.service';
import { _tk, _ti } from '@wephone-translation';
import { BusinessType } from '@wephone-core/system';

const SUPERVISOR_MENU_ITEMS: Array<MainMenuItem> = [
  {
    state: 'dashboard',
    name: _tk('site.dashboard'),
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'manage-users',
    name: _tk('menu.manage_user'),
    type: 'sub',
    icon: 'people',
    children: [
      { state: 'users', name: _tk('menu.manage_user') },
      { state: 'teams', name: _tk('menu.manage_team') },
    ]
  },
  {
    state: 'groups',
    name: _tk('menu.manage_group'),
    type: 'link',
    icon: 'group',
    features: ['SERVICE_GROUP']
  },
  {
    state: 'queues',
    name: _tk('menu.manage_queue'),
    type: 'link',
    icon: 'notes'
  },
  {
    state: 'media',
    name: _tk('menu.manage_media'),
    type: 'sub',
    icon: 'movie',
    children: [
      { state: 'reusable-sound', name: _tk('menu.manage_reusablesound') }
//      { state: 'image', name: _tk('menu.manage_image') },
    ]
  },
  {
    state: 'out-call-campaigns',
    name: _tk('menu.manage_outcall_campaign'),
    type: 'link',
    icon: 'calendar_today'
  },
  // {
  //   state: 'campaigns-overview',
  //   name: _tk('livecall.tab.active_campaign'),
  //   type: 'link',
  //   icon: 'calendar_today'
  // },
  {
    state: 'vocal_messages',
    name: _tk('menu.vocal_messages'),
    type: 'sub',
    icon: 'voicemail',
    children: [
      { state: 'voicemail-queue', name: _tk('menu.voicemail') },
      { state: 'recorded-call', name: _tk('menu.recorded_call') },
    ]
  },
  {
    state: 'statistics',
    name: _tk('menu.statistics'),
    type: 'sub',
    icon: 'equalizer',
    children: [
      { state: 'incoming', name: _tk('stats.callcenter.incoming') },
      { state: 'outgoing', name: _tk('stats.callcenter.outgoing') },
      { state: 'missedcall', name: _tk('stats.callcenter.missedcall') },
      { state: 'agent-performance', name: _tk('agent_performance.heading'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'agent-status', name: _tk('stats.agent_status.title'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'user-stats', name: _tk('stats.user_stats.title'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'connection-log', name: _tk('stats.agent_connection_log.title'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'callcenter-export-data', name: _tk('menu.callcenter_export_data'), businessTypes: [BusinessType.CALLCENTER] },
    ]
  },
  {
    state: 'callcenter-params',
    name: _tk('menu.callcenter_params'),
    type: 'sub',
    icon: 'settings_phone',
    children: [
      { state: 'opening-hour', name: _tk('opening_hour_calendar.menu.title') },
      { state: 'qualification', name: _tk('menu.manage_call_qualification'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'black-list', name: _tk('menu.manage_blacklist') }
    ]
  }
];

@Component({
  selector: 'supervisor-menu',
  templateUrl: './supervisor-menu.component.html',
  styleUrls: ['./supervisor-menu.component.scss'],
  providers: [MenuService]
})
export class SupervisorMenuComponent {
  constructor(public menuService: MenuService) { }

  get supervisorMenus(): Array<MainMenuItem> {
    return SUPERVISOR_MENU_ITEMS;
  }
}
