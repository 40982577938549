<div fxFill class="flex-grid" fxLayout="column" fxLayoutGap="7px">
  <div *ngIf="hasLoadedColumns" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
    fxLayoutAlign.xs="start" fxLayoutGap="10px">
    <div fxFlex="initial">
      <button mat-raised-button color="primary" (click)="export()">
        <mat-icon>assignment_returned</mat-icon>
        {{ 'public.export' | translate }}
      </button>
    </div>
    <span fxFlex="auto"></span>
    <flex-search-input *ngIf="searchable" [formControl]="searchCtrl" [hintText]="searchHintText"
      ngClass.xs="search-full-width"></flex-search-input>
  </div>

  <div fxFlex="grow" *blockUI="'flex-grid-block'">
    <ag-grid-angular #flexGrid class="ag-theme-material grid-content" [gridOptions]="gridOptions"
      [rowBuffer]="rowBuffer" [debug]="false" [rowSelection]="rowSelection" [rowModelType]="rowModelType"
      [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize"
      [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
      [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache"
      [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
