export enum CallDirection {
  INBOUND = 1,
  OUTBOUND = 2,
}

export interface ICallLog {
  id: number;
  called_number: string;
  calling_number: string;
  connected_time?: Date;
  start_dt?: string;
  start_time: Date;
  duration?: number;
  user_direction?: CallDirection;
  wait_duration?: number;
  is_read?: number;
  readers?: number[];
  voicemail_public_id?: string;
  recorded_call_public_id?: string;
  is_missed?: number; // 0 | 1;
  is_voicemail?: number; // 0 | 1;
  missed_call_id?: number;
  message_id?: number;
  is_outgoing?: number; // 0 | 1;
  queue_ids?: number[];
  has_qualification?: number; // 0 | 1;
  qualification_ids?: number[];
  qualification_comment?: string;
  agent_name?: string;
  qualification?: string;
  recall_request_status?: number;
  recall_time?: string;
  handled_user_ids?: number[];
  out_qualification_id?: number;
  in_qualification_id?: number;
}

export interface ICallDial {
  id: number;
  called_number: string;
  calling_number: string;
  connected_time?: Date;
  start_time: Date;
  duration?: number;
  user_direction?: CallDirection;
  queue_ids?: number[];
}

export interface ICallInfoDataChanged {
  qualification_displayed?: string;
  qualification_comment?: string;
  qualification?: number[];
  vm_message_is_read?: number;
  vm_message_readers?: number[];
  voicemail_has_deleted?: boolean;
}
