import { Howl } from 'howler';
import { IAudioTrack } from './types';
import * as _ from 'lodash';

export class FlexAudioTrack implements IAudioTrack {
  private howler: Howl;

  constructor(source_uri: string) {
    this.howler = new Howl({
      src: [ source_uri ],
      html5: true,
      onend: () => {
        this.howler.stop();
        this.howler.seek(0);
      }
    });
  }

  play(): void {
    this.howler.play();
  }

  pause(): void {
    this.howler.pause();
  }

  stop(): void {
    this.howler.stop();
  }

  mute(muted: boolean): void {
    this.howler.mute(muted);
  }

  duration(): number {
    return this.howler.duration();
  }

  seek(): number {
    return +this.howler.seek() || 0;
  }

  setSeek(value: number): void {
    if (_.isNumber(value)) {
      this.howler.seek(value);
    }
  }

  isLoading(): boolean {
    return this.howler.state() === 'loading';
  }

  isLoaded(): boolean {
    return this.howler.state() === 'loaded';
  }

  isPlaying(): boolean {
    return this.howler.playing();
  }

  onend(callback: (soundId: number) => void, id?: number): Howl {
    return this.howler.on('end', callback, id);
  }
}
