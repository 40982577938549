import { Entity, datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { parseDateTime } from '@wephone-utils';
import { HotelRoomRepository } from '../repository/hotelroom';
import { HotelRoomEntity } from './hotelroom';

export class HotelRoomLogEntity extends Entity {
  static object_type_id = 'hotel_room_log';

  id: number;
  @datafield room_id: number;
  @datafield checkin_dt: any;
  @datafield checkout_dt: any;
  @datafield client_name: string;
  @datafield client_id: number;

  private _room: HotelRoomEntity;

  get room(): HotelRoomEntity {
    if (!this._room && this.room_id) {
      this._room = this.room_id && EntityManager.getRepository<HotelRoomRepository>('HotelRoomRepository').getObjectById(this.room_id) || undefined;
    }
    return this._room;
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    if (this.room_id !== object_data.room_id) {
      this._room = undefined;
    }

    super.setObjectData(object_data, fetch_related_data);

    this.checkin_dt = this.checkin_dt && parseDateTime(this.checkin_dt);
    this.checkout_dt = this.checkout_dt && parseDateTime(this.checkout_dt);
  }

  fetchRelatedData(): void {
    // required fetch room data before loading this object
    this._room = this.room_id && EntityManager.getRepository<HotelRoomRepository>('HotelRoomRepository').getObjectById(this.room_id) || undefined;
  }
}
