<div fxLayout="column" fxFill>
  <ng-template #tplListFilter>
    <form [formGroup]="form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start"
      fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input formControlName="filterString" [hintText]="'enterprise_sip_gateway.search.hint'|translate"
          ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud pageTitle="{{ 'enterprise_sip_gateway.title.manage'|translate }}"
    [filterTemplate]="tplListFilter" [tableConfig]="tableConfig" [dataSource]="dataSource"
    [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">
  </flex-crud-page>
</div>
