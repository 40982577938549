import * as _ from 'lodash';
import { _ti } from '@wephone-translation';
import { IEntity } from '../model.interface';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { HotelWakeupCallEntity } from '@wephone-core/model/entity/hotel_wakeup_call';

export enum HotelRookStateType {
  UNOCCUPIED = 0,
  CHECKEDIN = 1
}

export enum HotelRoomPhoneStateType {
  CONNECTED = 1,
  DISCONECTED = 0,
  NO_PHONE = -1
}

export interface IHotelRoomEntity extends IEntity {
  id: number;
  room_number: string;
  room_state: number; // (0: unoccupied, 1: checked in)
  // room_state_dt: any;
  client_name: string;
  display_room_number: string;
  sipphone_is_online: HotelRoomPhoneStateType;
  sip_phone: SipPhoneEntity;
  wakeup_call: HotelWakeupCallEntity;
}

export enum HotelWakeupCallStateType {
  ACTIVE = 1,
  RUNNING = 2,
  FINISHED = 3,
  CANCELLED = 4, // Cancelled/Deleted
  FAILED = 5 // Cancelled/Deleted
}

export enum HotelWakeupCallObjectType {
  ROOM = 0,
  USER = 1,
}

export interface IHotelRoomRRequestRangesNumber {
  from: number;
  to: number;
}
export interface OracleHospitalityReservationInfo {
  reservationIdList: {
      id: string;
      type: 'Reservation' | 'Confirmation';
  }[];
  roomStay: {
      arrivalDate: string; // 2022-03-17,
      departureDate: string; // 2023-03-17,
      originalTimeSpan: {
          startDate: string; // 2022-03-17,
          endDate: string; // 2023-03-17
      };
      expectedTimes: {
          reservationExpectedArrivalTime: string; // 2022-03-17 05:16:55.0,
          reservationExpectedDepartureTime: string; // 2023-03-17
      };
      adultCount: number; // 0,
      childCount: number; // 0,
      roomClass: string; // ALL,
      roomType: string; // PM,
      numberOfRooms: number; // 1,
      roomId: string; // 9002,
      ratePlanCode: string; // COMP,
      rateAmount: {
          amount: number; // 0,
          currencyCode: string; // USD
      };
      rateSuppressed: boolean; // false,
      bookingChannelCode: string; // WEB,
      fixedRate: boolean; // false,
      guarantee: {
          guaranteeCode: string; // CHECKED IN,
          shortDescription: string; // Checked In
      };
      marketCode: string; // INTERNAL,
      sourceCode: string; // INTERNAL,
      sourceCodeDescription: string; // Internal reservation,
      balance: {
          amount: string; // 94743055.19,
          currencyCode: string; //  USD
      };
      roomTypeCharged: string; // PM,
      roomNumberLocked: boolean; // false,
      pseudoRoom: boolean; // true
  };
  reservationGuest: {
      givenName?: string; // IFC account,
      surname: string; // POS AMEX,
      language: string; // E,
      vip: any;
      address: {
          country: {
              code: string; // US
          };
      };
      anonymization: any;
      accompanyGuests: any[];
      guestRestricted: string; // false,
      nameType: string; // Guest,
      id: number; // 113169,
      type: string; // Profile
  };
  sharedGuests: any[];
  attachedProfiles: any[];
  reservationPaymentMethod: any;
  reservationFolioWindows: any[];
  reservationIndicators: any[];
  roomStatus: string; // Clean,
  sourceOfSale: {
      sourceType: string; // PMS,
      sourceCode: string; // SAND01
  };
  waitlist: {};
  advanceCheckIn: {
      advanceCheckedIn: boolean; // false
  };
  hotelId: string; // SAND01,
  hotelName: string; // OHIP Sandbox 1,
  roomStayReservation: boolean; // true,
  createDateTime: string; // 2022-03-17 09:16:33.0,
  lastModifyDateTime: string; // 2022-05-20 11:52:32.0,
  reservationStatus: string; // InHouse,
  computedReservationStatus: string; // InHouse,
  walkInIndicator: boolean; // false,
  commissionPayoutTo: string; // None,
  paymentMethod: string; // CA,
  preRegistered: boolean; // false,
  openFolio: boolean; // false,
  allowMobileCheckout: boolean; // false,
  optedForCommunication: boolean; // false
}
