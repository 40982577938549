import { Injectable } from '@angular/core';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { UserRepository } from '@wephone-core/model/repository/user';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { StorageService } from '@wephone-core/service/storage.service';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { UserEntity } from '@wephone-core/model/entity/user';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import * as _ from 'lodash';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { PhoneNumberService } from '@wephone-utils';
import { DidEntity } from '@wephone-core/model/entity/did';

export enum PeriodPerformance {
  Today = 'today',
  ThisWeek = 'this_week',
  ThisMonth = 'this_month'
}

export interface SingleUserStatsQoSData {
  pause_time: number;
  non_working_pause_time: number;
  connection_time: number;
  avg_ring_time_total: number;
  avg_ring_time_inbound: number;
  avg_ring_time_outbound: number;
  acw: number;
  communication_inbound: number;
  communication_outbound: number;
  aht_inbound: number;
  aht_outbound: number;
  outbound_call_count: number;
  outbound_call_answered: number;
  outbound_call_effective: number;
  inbound_call_count: number;
  inbound_call_answered: number;
  inbound_call_effective: number;
  avg_hold_time_total: number;
  avg_hold_time_inbound: number;
  avg_hold_time_outbound: number;
  call_with_onhold_inbound: number;
  call_with_onhold_outbound: number;
  avg_talk_time_inbound: number;
  avg_talk_time_outbound: number;
  avg_talk_time_total: number;
  qos?: number;
}

@Injectable()
export class UserService {
  static URL_GROUP_GET_DIRECT_USERS = 'servicegroup/get_direct_users';
  static URL_REQUEST_RESET_PW = '/ws-v2/signin/reset/request';
  static URL_RESET_PW = '/noauth/api/reset_pw';
  static URL_USER_MARK_ONBOARDING_DONE = 'user/mark_onboarding_done';
  static URL_USER_UPDATE_AGENT_ATTRS = 'user/update_agent_attrs';
  static URL_USER_CREATE_AGENT_CALENDAR = '/api/user/create_agent_calendar';
  static USER_CREATE_VOIP_URL = 'user/create-voip';
  static URL_USER_IMPORT_CSV = 'user/import_csv';
  static SA_USER_LIST_URL = 'sa/user/list';
  static URL_TODAY_PERFORMANCE = 'user/today_performance';
  static UPDATE_USER_PARAMETERS_URL = 'user_parameters';
  static RESELLER_USER_LIST_PATH = 'reseller/user/list';

  constructor(
    private readonly settings: FlexIvrSettings,
    private readonly storage: StorageService,
    private readonly httpEngine: HttpEngine,
    private readonly em: EntityManager,
    private readonly configManager: ConfigManager,
    private readonly phoneNumberService: PhoneNumberService,
  ) {
  }

  /**
   * Phone number must not be a did-number
   * @param phoneNumber
   */
  isValidSecondaryPhoneNumber(phoneNumber: string): boolean {
    const standardPhoneNumber: string = this.phoneNumberService.normalizeNumber(phoneNumber);
    const existDid: DidEntity = this.em.getRepository('DidRepository').getObjectList()
      .find(x => x.number === standardPhoneNumber);

    if (existDid) {
      return false;
    }

    return true;
  }

  setUsername(user): void {
    this.storage
      .setAppConfig('username', user)
      .then(value => {
        console.log(value);
      })
      .catch(err => {
        console.log(err);
      });
  }

  setPassword(pass): void {
    this.storage
      .setAppConfig('password', pass)
      .then(value => {
        console.log(value);
      })
      .catch(err => {
        console.log(err);
      });
  }

  async getDirectUsersFromGroup(groupId: number): Promise<UserEntity[]> {
    const url = UserService.URL_GROUP_GET_DIRECT_USERS;
    const userObjects = await this.httpEngine.apiGetV2(url, { group_id: groupId });
    const ret: UserEntity[] = [];
    for (const userObject of userObjects) {
      const user = UserRepository.getInstance().create() as UserEntity;
      user.setObjectData(userObject, true);
      ret.push(user);
    }
    return ret;
  }

  requestResetPw(domain: string, email: string): Promise<any> {
    const url = UserService.URL_REQUEST_RESET_PW;
    return this.httpEngine.post(url, { domain, email });
  }

  resetPw(domain: string, token: string, password: string): Promise<any> {
    const url = UserService.URL_RESET_PW;
    return this.httpEngine.post(url, { domain, token, password });
  }

  markOnboardingDone(): Promise<any> {
    return this.httpEngine.apiPostV2(UserService.URL_USER_MARK_ONBOARDING_DONE);
  }

  getUsername(): Promise<string> {
    return this.storage.getAppConfig('username');
  }

  getPassword(): Promise<string> {
    return this.storage.getAppConfig('password');
  }

  user_avatar_url(user: IUserEntity): string {
    if (!user || !user.avatar_id) {
      return 'assets/images/avatar.png';
    }

    const url = `/user/${user.id}/avatar?avatar_id=${user.avatar_id}`;
    return this.settings.getAbsoluteApiUrlv2(url);
  }

  getEnterpriseFileEntryUrl(enterpriseFileId: number): string {
    const url = `/resource/enterprise_file_entry/get?id=${enterpriseFileId}`;

    return this.settings.getEnterpriseUrl(url);
  }

  queue_avatar_url(queue: CallQueueEntity): string {
    return queue.logo_id ?
      this.getEnterpriseFileEntryUrl(queue.logo_id) :
      FlexIvrSettings.getInstance().getAbsoluteUrl('/bundles/flexivrangularui/assets/img/queue.png');
  }

  user_full_name(user_id: number): string {
    const user = UserRepository.getInstance<UserRepository>().getUserById(user_id);

    return user ? user.name : '';
  }

  updateAgentAvailability(user_id: number, availability: number | undefined, working_calendar_id: number | undefined): Promise<UserEntity> {
    const params: any = {
      id: user_id,
      availability,
      __field_list__: ['availability']
    };

    if (working_calendar_id !== undefined) {
      params['working_calendar_id'] = working_calendar_id;
      params['__field_list__'].push('working_calendar_id');
    }

    return this.httpEngine.apiPostV2(UserService.URL_USER_UPDATE_AGENT_ATTRS, params);
  }

  createVoip(user_id: number): Promise<UserEntity> {
    return this.httpEngine.apiPostV2(UserService.USER_CREATE_VOIP_URL, { user_id });
  }

  // Not in-used
  // createAgentWorkingCalendar(user_id: number, working_calendar_data: object): Promise<AgentEntity> {
  //   const calendar_data = Object.assign({ id: user_id }, working_calendar_data);

  //   return this.httpEngine.post(UserService.URL_USER_CREATE_AGENT_CALENDAR, calendar_data);
  // }

  /**
   * Removing group from user will cause removing queue from user
   * @param user 
   * @param oldGroupIds 
   * @param newGroupIds 
   * @returns removed queue list
   */
  getRemovedQueues(user: UserEntity, oldGroupIds: number[], newGroupIds: number[]): CallQueueEntity[] {
    // if (!newGroupIds.length) {
    //   console.log('Updated group is empty');
    //   return [];
    // }

    const removedGroupIds = oldGroupIds.filter(x => !_.includes(newGroupIds || [], x));
    if (!removedGroupIds.length) {
      console.log('No group to be removed');
      return [];
    }

    // user.agent && user.agent.id;
    const agent: AgentEntity = (EntityManager.getInstance().getRepository('AgentRepository') as AgentRepository).getObjectByUserId(user.id);
    if (!agent) {
      console.log('No agent from user');
      return [];
    }

    const agentId: number = agent.id;

    const removedQueues: CallQueueEntity[] = EntityManager.getInstance().getRepository('CallQueueRepository').getObjectList()
      .filter(x => _.includes(removedGroupIds, x.group_id) && x.contain_agent_id(agentId));

    return removedQueues;
  }

  importCsv(file: any, send_email: boolean): Promise<any> {
    console.log('Upload file', file);
    if (!file) {
      console.error('No file uploaded');

      return Promise.resolve(undefined);
    }

    const eid: number = this.configManager.getCurrentEnterpriseId();
    const params: any = {
      send_email: send_email ? 1 : 0,
      eid
    };

    const options: any = {
      data: params,
      file,
      method: 'POST',
      headers: { 'Content-Type': file.type }
    };

    const body = new FormData();
    body.append('file', file);

    for (const key of Object.keys(params)) {
      body.append(key, params[key]);
    }

    return this.httpEngine.apiPostV2(UserService.URL_USER_IMPORT_CSV, body, options);
  }

  get_enterprise_url(url: string): string {
    if (this.settings.enterprise_domain) {
      return `/a/${this.settings.enterprise_domain}${url}`;
    }

    return url;
  }

  getUsersByEnterpriseId(eid: number): Promise<any> {
    return this.httpEngine.apiGetV2(UserService.SA_USER_LIST_URL, { eid });
  }

  resellerGetUsersByEnterpriseId(eid: number): Promise<any> {
    return this.httpEngine.apiGetV2(UserService.RESELLER_USER_LIST_PATH, { eid });
  }

  async getUserPerformance(period: PeriodPerformance = PeriodPerformance.Today): Promise<SingleUserStatsQoSData> {
    const ret: SingleUserStatsQoSData = await this.httpEngine.apiGetV2(UserService.URL_TODAY_PERFORMANCE, {
      period
    });

    _.extend(ret, {
      qos: ret.inbound_call_count ? Math.round((ret.inbound_call_answered * 100 / ret.inbound_call_count) * 100) / 100 : 0
    });

    return ret;
  }

  /**
   * Get user's group and their sub groups
   * See UserHelper.userGetManagedGroups (PHP) for reference
   * @param user: UserEntity
   */
  getManagedGroups(user: UserEntity): UserGroupEntity[] {
    let groups = UserGroupRepository.getInstance<UserGroupRepository>().getObjectList();

    if (!this.configManager.hasFeature('SERVICE_GROUP') || _.isEmpty(groups)) {
      return [];
    }

    if (!user.isAdmin() && !_.isEmpty(user.group_ids)) {
      groups = groups.filter(x =>
        _.includes(user.group_ids, x.id) ||
        _.intersection(user.group_ids, x.path).length
      );
    }

    return groups;
  }

  updateUserParameters(data: any): Promise<any> {
    return this.httpEngine.apiPostV2(UserService.UPDATE_USER_PARAMETERS_URL, data);
  }
}
