import { Component, Input, OnInit } from '@angular/core';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';

@Component({
  selector: 'app-sipphone-mini-block',
  templateUrl: './sipphone-mini-block.component.html',
  styleUrls: ['./sipphone-mini-block.component.scss']
})
export class SipphoneMiniBlockComponent implements OnInit {
  @Input() sipphone: SipPhoneEntity;
  // constructor() { }

  ngOnInit(): void {
  }

}
