import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { UIBlockerService } from './services/uiblocker.service';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogWrapper } from './components/flex-dialog/dialog-wrapper.component';
import { FlexTableComponent } from './components/flex-table/flex-table.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexTableSidePannelComponent } from './components/flex-table-side-pannel/flex-table-side-pannel.component';
import { FlexAnchorDirective } from './directives/flex-anchor/flex-anchor.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexCRUDPageComponent } from './components/flex-crud-page/flex-crud-page.component';
import { FlexSelect } from './form-input/flex-select/flex-select.component';
import { MaterialModule } from './material.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { ClickOutsideModule } from 'ng-click-outside';
import { AccordionDirective, AccordionAnchorDirective, AccordionLinkDirective } from './menu/menu-accordion';
import { RoutableTabNavigationMenu } from './components/routing/routable-tab-navigation-menu/routable-tab-navigation-menu';

import { DateInboxPipe } from './pipes/date-inbox/date-inbox';
import { SecondsAsDurationPipe } from './pipes/secondsAsDuration';
import { FilterByPipe } from './pipes/filterBy';
import { OrderByPipe } from './pipes/orderBy';
import { TimeSinceAsTextPipe } from './pipes/timeSinceAsText';
import { SecondsAsDurationTextPipe } from './pipes/secondsAsDurationText';
import { TimerSinceDirective, FlexAnchorAreaDirective } from './directives';
import { BlockUIModule } from 'ng-block-ui';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { FlexInfiniteScrollerDirective } from './components/scroll-container/flex-infinite-scroller.directive';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';

import {
  // CookieService,
  MessageService,
  CryptoService,
  ToastService,
  TreeHelper,
  FormService,
  DialogService,
  UIStateService,
  Platform,
  LoggerService,
  WindowService,
  TimerService,
  DynamicScriptLoader,
  DataRenderService
} from './services';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { IfLoadingDirective } from './directives/if-loading/if-loading.directive';
import { IfLoadingComponent } from './directives/if-loading/if-loading.component';
import { FlexProgressBarService } from './services/flex-progress-bar.service';
import { FlexProgressBarComponent } from './components/flex-progress-bar/flex-progress-bar.component';
import { TranslateSelectorPipe } from './pipes/translateSelector';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FlexSearchInput } from './components/flex-search-input/flex-search-input.component';
import { FlexProgressLineComponent } from './components/flex-progress-line/flex-progress-line.component';
import { DateFormatPipe, DateShortPipe, DateMedPipe, TimeSimplePipe, TimeWithSecondsPipe, DateFullPipe, DateTimeMedPipe, DateTimeShortPipe, DateTimeShortWithSecondsPipe } from './pipes/date-format/date-format.pipe';
import { NumberFormatPipe } from './pipes/number-format/number-format.pipe';
import { FreezeClickDirective } from './directives/freeze-click/freeze-click.directive';
import { FlexSnackBarComponent } from './components/flex-snack-bar/flex-snack-bar.component';
import { WephoneCustomIconModule } from '@wephone-custom-icon';
import { DisableControlDirective } from './directives/disable-control/disable-control.directive';
import { MapPipe } from './pipes/array/map.pipe';
import { JoinPipe } from './pipes/array/join.pipe';
import { CurrencySymbolPipe } from './pipes/currencySymbol';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { TruncateArrayPipe } from './pipes/truncate/truncate-array.pipe';
import { TextTruncateComponent } from './components/text-truncate/text-truncate.component';
import { FlexPaginatorDirective } from './components/flex-table/flex-paginator.directive';
import { ValidatorService } from './services/validator.service';
import { ExtensionAcceptPipe } from './pipes';
import { FlexFormControlErrorComponent } from './components/flex-form-control-error/flex-form-control-error.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  imports: [
    // NoopAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    TreeModule,
    WephoneCustomIconModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    BlockUIModule.forRoot(),
    DeviceDetectorModule,
  ],
  exports: [
    BlockUIModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LoggerModule,
    TreeModule,
    FlexLayoutModule,
    TranslateModule,
    ClickOutsideModule,
    RoutableTabNavigationMenu,
    // Custom component
    DialogWrapper,
    FlexTableComponent,
    TextTruncateComponent,
    ConfirmationDialogComponent,
    FlexTableSidePannelComponent,
    FlexCRUDPageComponent,
    FlexInfiniteScrollerDirective,
    // Form inputs
    FlexSelect,
    // Form inputs
    FlexSearchInput,
    FlexProgressLineComponent,
    // Directives
    FlexAnchorDirective,
    FlexAnchorAreaDirective,
    AccordionLinkDirective,
    AccordionAnchorDirective,
    AccordionDirective,
    TimerSinceDirective,
    // Pipes
    NumberFormatPipe,
    DateInboxPipe,
    SecondsAsDurationPipe,
    CurrencySymbolPipe,
    DateFormatPipe,
    TruncatePipe,
    TruncateArrayPipe,
    DateShortPipe,
    DateMedPipe,
    TimeSimplePipe,
    TimeWithSecondsPipe,
    DateFullPipe,
    DateTimeShortPipe,
    DateTimeShortWithSecondsPipe,
    DateTimeMedPipe,
    TranslateSelectorPipe,
    DateInboxPipe,
    SecondsAsDurationTextPipe,
    FilterByPipe,
    OrderByPipe,
    ExtensionAcceptPipe,
    TimeSinceAsTextPipe,
    MapPipe,
    JoinPipe,
    PerfectScrollbarModule,
    IfLoadingDirective,
    IfLoadingComponent,
    FileUploadComponent,
    FlexProgressBarComponent,
    FreezeClickDirective,
    FlexSnackBarComponent,
    DisableControlDirective,
    FlexPaginatorDirective,
    FlexFormControlErrorComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    DialogWrapper,
    IfLoadingComponent,
    FlexSnackBarComponent,
  ],
  declarations: [
    DialogWrapper,
    FlexTableComponent,
    TextTruncateComponent,
    ConfirmationDialogComponent,
    FlexTableSidePannelComponent,
    FlexAnchorDirective,
    FlexAnchorAreaDirective,
    FlexCRUDPageComponent,
    FlexSelect,
    FlexSearchInput,
    AccordionLinkDirective,
    AccordionAnchorDirective,
    AccordionDirective,
    RoutableTabNavigationMenu,
    // Directives
    TimerSinceDirective,
    // Pipes
    NumberFormatPipe,
    SecondsAsDurationPipe,
    CurrencySymbolPipe,
    DateFormatPipe,
    TruncatePipe,
    TruncateArrayPipe,
    DateShortPipe,
    DateMedPipe,
    TimeSimplePipe,
    TimeWithSecondsPipe,
    DateFullPipe,
    DateTimeShortPipe,
    DateTimeShortWithSecondsPipe,
    DateTimeMedPipe,
    TranslateSelectorPipe,
    DateInboxPipe,
    SecondsAsDurationTextPipe,
    FilterByPipe,
    OrderByPipe,
    ExtensionAcceptPipe,
    TimeSinceAsTextPipe,
    MapPipe,
    JoinPipe,
    FlexInfiniteScrollerDirective,
    IfLoadingComponent,
    IfLoadingDirective,
    FileUploadComponent,
    FlexProgressBarComponent,
    FileUploadComponent,
    FlexProgressLineComponent,
    FreezeClickDirective,
    FlexSnackBarComponent,
    DisableControlDirective,
    TextTruncateComponent,
    FlexPaginatorDirective,
    FlexFormControlErrorComponent,
  ]
})
export class WephoneUtilsModule {
  static forDashboardSA(): ModuleWithProviders {
    return {
      ngModule: WephoneUtilsModule,
      providers: [
        DateShortPipe
      ]
    };
  }
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WephoneUtilsModule,
      providers: [
        MessageService,
        TimerService,
        {
          provide: PERFECT_SCROLLBAR_CONFIG,
          useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        // CookieService,
        LoggerService,
        CryptoService,
        ToastService,
        TreeHelper,
        UIBlockerService,
        FormService,
        DialogService,
        UIStateService,
        Platform,
        FlexProgressBarService,
        WindowService,
        DynamicScriptLoader,
        SecondsAsDurationPipe,
        CurrencySymbolPipe,
        DataRenderService,
        ValidatorService,
        NumberFormatPipe,
      ],
    };
  }
}
