<form [formGroup]="objectConditionFrom">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-form-field fxFlex>
      <mat-select formControlName="field">
        <mat-option value="contact_type">Caller type</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-select formControlName="op">
        <mat-option value="equals_to">Equals to</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-select formControlName="operand">
        <mat-option value="prospect">Prospect</mat-option>
        <mat-option value="lead">Lead</mat-option>
        <mat-option value="client">Client</mat-option>
        <mat-option value="partner">Partner</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
