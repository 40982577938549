import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { durationFromSeconds } from '../utils';

@Pipe({
  name: 'seconds_as_duration_text'
})
export class SecondsAsDurationTextPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }
  transform(seconds): string {
    let ret = seconds;
    if (!isNaN(parseInt(seconds))) {
      const secs = parseInt(seconds);
      const duration = durationFromSeconds(secs);
      const secSep = `'` + this.trans('public.symbol.second') + `'`;
      const minSep = `'` + this.trans('public.symbol.minute') + `'`;
      const hourSep = `'` + this.trans('public.symbol.hour') + `'`;
      let format = 'h ' + hourSep + ' m ' + minSep + ' s ' + secSep;

      if (secs < 3600) {
        format = 'm ' + minSep + ' s ' + secSep;

        if (secs % 60 === 0) {
          format = 'm ' + minSep;
        }
      }

      if (secs < 60) {
        format = 's ' + secSep;
      }

      if (secs >= 3600) {
        const temSecs = secs % 3600;

        if (temSecs === 0) {
          format = 'h ' + hourSep;
        }

        if (temSecs > 0 && temSecs % 60 === 0) {
          format = 'h ' + hourSep + ' m ' + minSep;
        }
      }

      ret = duration.toFormat(format);
    }

    return ret;
  }

  private trans(key: string): string {
    return this.translateService.instant(key);
  }
}
