import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { SkillEntity } from '@wephone-core/model/entity/skill';

export class SkillRepository extends BaseRepository {
  ENTITY_CLASS = SkillEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX: string = 'skill';

  public getParentGroups(group_id) {
    let entity_manager = EntityManager.getInstance();
    let group_repo = SkillGroupRepository.getInstance<SkillGroupRepository>();
    let group = group_repo.getObjectById(group_id);

    if (!group) {
      return [];
    }
    let parents = [group];

    let grands = group_repo.getParentGroups(group_id);
    if (grands.length) {
      parents = parents.concat(grands);
    }
    return parents;
  }

  public findByGroupId(group_id: number) {
    let skills = [];
    for (let skill of this.object_list) {
      if (skill.group_id && skill.group_id == group_id) {
        skills.push(skill);
      }
    }
    return skills;
  }
}

import { EntityManager } from '@wephone-core/service/entity_manager';
import { SkillGroupRepository } from '@wephone-core/model/repository/skillgroup';
