import { FormControl, ValidationErrors } from '@angular/forms';
import { count, Encoding } from 'sms-length';
export const SmsMaxLength = 1024;
export const SmsMaxValidator = (control: FormControl) => {
  if (control.value) {
    const smsInfo: {
      encoding: Encoding;
      length: number;
      characterPerMessage: number;
      inCurrentMessage: number;
      remaining: number;
      messages: number;
    } = count(control.value);
    if (smsInfo && smsInfo.messages > 1) {
      const ret: ValidationErrors = {
        smsMax: {
          length: smsInfo.length,
          requiredLength: smsInfo.characterPerMessage
        }
      };
      return ret;
    }
  }

  return null;
};
