import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';
import { FileEntryEntity } from './fileentry';
import { EntityManager } from '@wephone-core/service/entity_manager';

export enum MailboxType {
  USER = 'user',
  QUEUE = 'queue',
}
export class VoiceMailBoxEntity extends Entity {
  static object_type_id = 'voicemail';

  id: number;
  @datafield
  mailbox_type: MailboxType;
  @datafield
  greeting_file_id: number;
  @datafield
  voicemail_by_mail_enabled: number;
  @datafield
  voicemail_in_attachement: number;
  @datafield
  voicemail_by_mail_addrs: string[];

  get greeting_file(): FileEntryEntity {
    return EntityManager.getRepository('FileEntryRepository').getObjectById(this.greeting_file_id);
  }

  set greeting_file(greeting_file: FileEntryEntity) {
    this.greeting_file_id = greeting_file ? greeting_file.id : undefined;
  }

  isMailboxTypeQueue(): boolean {
    return this.mailbox_type === MailboxType.QUEUE;
  }

  isMailboxTypeUser(): boolean {
    return this.mailbox_type === MailboxType.USER;
  }
}
