<agent-selector-one-click *ngIf="state === 0" (selectAgent)="onAgentSelected($event)"></agent-selector-one-click>
<ng-container *ngIf="state === 1">
  <div class="wrapper" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.xs="column"
    fxLayoutGap.xs="10px" *ngIf="selectedAgent">
    <div class="item" fxFlex fxFlexFill fxLayout="column" (click)="selectedType = 0"
      [ngClass]="{'selected': selectedType == 0}">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <user-avatar [user]="selectedAgent.user" width="50px" height="50px"></user-avatar>
        <div fxFlex>
          <h3>{{ selectedAgent.name }}</h3>
          <div *ngIf="selectedAgent.user.is_online_cc" class="status-bg {{ selectedAgent.agent_status }}">
            {{ selectedAgent.agent_status }}
          </div>
        </div>
      </div>
    </div>
    <div class="item" fxFlex fxFlexFill fxLayout="column" (click)="selectSecondaryNumber($event)"
      [ngClass]="{'selected': selectedType == 1}" *ngIf="secondary_number">
      <div>{{ 'routing_app_selector.user_secondary_number'|translate }}</div>
      <h3 class="mb-0">{{ secondary_number }}</h3>
    </div>
  </div>
  <div class="item other-number mt-2" fxLayout="column" (click)="selectOtherNumber($event)"
    [ngClass]="{'selected': selectedType == 2}">
    <div>{{ 'force_call_to_agent.other_number'|translate }}</div>
    <mat-form-field class="full-width">
      <mat-label>{{ 'livecall.message.input_phone_number' | translate }}</mat-label>
      <input type="text" matInput [(ngModel)]="selectedNumber"
        placeholder="{{ 'livecall.content.phone_number' | translate }}">
      <button matSuffix type="button" mat-icon-button (click)="selectedNumber = undefined" [disabled]="!selectedNumber">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    <div class="mat-error" *ngIf="selectedType == 2 && isSubmited && !selectedNumber">
      {{ 'form.validator.field_required'|translate}}
    </div>
  </div>
</ng-container>
