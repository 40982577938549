import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { DialogService, ToastService } from '@wephone-utils';
import { OpeningCalendarEditDialogComponent } from '../calendar';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _ti } from '@wephone-translation';
import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';

@Component({
  selector: 'calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent implements OnInit, OnChanges {
  @Input() calendar: OpeningHourCalendarEntity;
  @Input() clickToEdit: boolean;
  @Input() flatLayout: boolean;
  @Output() readonly changeValue: EventEmitter<any> = new EventEmitter<any>();

  openinghours: OpeningHourEntity[];
  weekdayLabels = [
    'opening_hour_calendar.content.week_day.0',
    'opening_hour_calendar.content.week_day.1',
    'opening_hour_calendar.content.week_day.2',
    'opening_hour_calendar.content.week_day.3',
    'opening_hour_calendar.content.week_day.4',
    'opening_hour_calendar.content.week_day.5',
    'opening_hour_calendar.content.week_day.6'
  ];

  private initilized = false;

  constructor(
    private readonly dialogService: DialogService,
    private readonly em: EntityManager,
    private readonly toast: ToastService,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.handlerData();
    this.initilized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initilized && changes['calendar']) {
      this.handlerData();
    }
  }

  handlerData(): void {
    if (!this.calendar.openinghours) {
      return;
    }

    this.openinghours = this.calendar.openinghours.sort((a: OpeningHourEntity, b: OpeningHourEntity) => {
      const openFromA = `${a.week_day[0]} ${a.start_time}`;
      const openFromB = `${b.week_day[0]} ${b.start_time}`;
      if (openFromA < openFromB) {
        return -1;
      }

      if (openFromA > openFromB) {
        return 1;
      }

      return 0;
    });
  }

  edit(): void {
    this.dialogService.openDialog2(OpeningCalendarEditDialogComponent, {
      data: {
        calendar: this.calendar
      }
    }, async (updateCalendar: OpeningHourCalendarEntity) => {
      if (!updateCalendar) {
        return;
      }
      try {
        const extraData = {
          openinghours: updateCalendar.openinghours.map((o: OpeningHourEntity) => o.dumpObjectData()),
          openinghours_special_date: updateCalendar.openinghours_special_date.map((s: OpeningHourSpecialDateEntity) => s.dumpObjectData())
        };
        await this.em.getRepository('OpeningHourCalendarRepository').save(updateCalendar, extraData);

        this.cdr.markForCheck();
        this.onChanged();

        this.toast.showSuccess(_ti('public.message.update_success'));
      } catch (e) {
        const msg = e && e.message || _ti('public.message.update_failure');
        this.toast.showError(msg);
      }
    });
  }

  onChanged(): void {
    if (this.changeValue) {
      this.changeValue.emit({ change: true });
    }
  }
}
