import { Component, ViewChild, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import {
  DialogService,
  IFlexTableSidePannelOptions,
  DynamicFilterSource,
  IFlexTableConfig,
  regexSearch,
} from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import * as _ from 'lodash';
import { SmsTemplateRepository } from '@wephone-core/model/repository/sms_template';
import { SmsTemplateEditComponent } from '../sms-template-edit/sms-template-edit';
import { SmsTemplateEntity } from '@wephone-core/model/entity/sms_template';
import { Router } from '@angular/router';
import { CreateSmsTemplateModalComponent } from '@wephone/modals/create-sms-template-modal/create-sms-template-modal.component';
import { SmsTemplateService } from '@wephone-core/service/sms-template.service';

@Component({
  selector: 'app-sms-template-list',
  templateUrl: './sms-template-list.html',
  styleUrls: ['./sms-template-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsTemplateListComponent extends FlexBasePage implements OnInit, OnDestroy {
  // Private member var with default value
  private readonly smsTemplateRepo = SmsTemplateRepository.getInstance<SmsTemplateRepository>();

  // Public member var
  enterprise: EnterpriseEntity;
  filterForm: FormGroup;
  filterValue: string;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;

  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: SmsTemplateEditComponent,
  };

  @ViewChild('flexCrud', { static: false }) flexCrud;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly smsTemplateService: SmsTemplateService,
  ) {
    super();
    const source = EntityManager.getRepository('SmsTemplateRepository').dataSource<SmsTemplateEntity>();
    this.dataSource = new DynamicFilterSource(source, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('sms_template.content.name'),
          sortable: true,
          sort: 'asc',
          customSortFn: (item: SmsTemplateEntity) => {
            return item.name.toLowerCase();
          },
          enableTooltip: true,
          lineClamp: 2
        },
        {
          name: 'sender_name',
          label: _tk('sms_template.content.sender_name'),
          sortable: true,
          sort: 'asc',
          customSortFn: (item: SmsTemplateEntity) => {
            return item.sender_name.toLowerCase();
          },
          enableTooltip: true,
          lineClamp: 2
        },
        {
          name: 'content',
          label: _tk('sms_template.content.content'),
          sortable: false,
          enableTooltip: true,
          lineClamp: 2
        },
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filterForm = this.fb.group({
      filterValue: [this.filterValue]
    });
    this.addSubscription(this.filterForm.valueChanges.subscribe(this.onFormValueChange));
  }

  protected async resolveData(): Promise<any> {
    return EntityManager.getRepository<SmsTemplateRepository>('SmsTemplateRepository').loadObjectList();
  }

  private readonly onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  private readonly filterFunc = (item: SmsTemplateEntity) => {
    return this.filterPredicate(item, this.filterValue);
  }

  private filterPredicate(item: SmsTemplateEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(item.name, filterString);
  }

  async bulkDelete(items: SmsTemplateEntity[]): Promise<void> {
    if (!items || !items.length) {
      return undefined;
    }

    const confirmed = await this.smsTemplateService.getConfirmDelete(items);
    if (confirmed) {
      const returnedData = await this.smsTemplateRepo.bulkDelete(items);
      if (returnedData) {
        this.flexCrud.closeSidePanel();
        this.successToast(_ti('public.message.delete_success'));
      }
    }
  }

  async createNew(): Promise<void> {
    try {
      const dialogRef = this.dialogService.openDialog2(CreateSmsTemplateModalComponent, { size: 's' });
      const newEntity = await dialogRef.afterClosed().toPromise();

      if (!newEntity) {
        return undefined;
      }

      this.gotoEditTagPage(newEntity.id);
    } catch (error) {
      let msg = _ti('public.message.create_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.showError(msg);
    }
  }

  private gotoEditTagPage(id: number): void {
    this.router.navigateByUrl(`sms-template/${id}`);
  }
}
