import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InvoiceEntity } from '@wephone-core/model/entity/invoice';
import {
  ToastService,
  EditingComponent,
  DialogService,
} from '@wephone-utils';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CurrencyPipe } from '@angular/common';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { MatSort } from '@angular/material/sort';
import { InvoiceItemEntity } from '@wephone-core/model/entity/invoice_item';
import { InvoiceService } from '@wephone-core/service/invoice.service';
import { _tk, _ti } from '@wephone-translation';
// import { AuthenticationService } from '@wephone-core/service/authentication';
import { InvoiceRepository } from '@wephone-core/model/repository/invoice';
import { PaymentService } from '@wephone/services/payment.service';
import { PaymentSourceEntity } from '@wephone-core/model/entity/payment_source';
import { PaymentSourceRepository } from '@wephone-core/model/repository/payment_source';
import { StripeService } from 'ngx-stripe';
import { SystemParam } from '@wephone-core/system';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
  providers: [CurrencyPipe]
})
export class InvoiceDetailComponent extends EditingComponent implements OnInit {
  @Input() editingItem: InvoiceEntity;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private readonly invoiceRepo = InvoiceRepository.getInstance<InvoiceRepository>();

  paymentSource: PaymentSourceEntity;
  invoice: InvoiceEntity;
  invoiceHeaders = [];
  invoiceItems = [];
  invoiceFooters = [];
  title: string;

  private paying: boolean;

  constructor(
    private readonly translate: TranslateService,
    private readonly toast: ToastService,
    private readonly invoiceService: InvoiceService,
    private readonly em: EntityManager,
    private readonly dialogService: DialogService,
    private readonly paymentService: PaymentService,
    private readonly stripeService: StripeService,
    private readonly configManager: ConfigManager,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    this.invoice = this.editingItem;
    const paymentSourceRepo = this.em.getRepository<PaymentSourceRepository>('PaymentSourceRepository');
    this.paymentSource = await paymentSourceRepo.getMyPaymentSource();
    this.title = _ti('invoice.content.invoice_name', { id: this.invoice.invoice_number });
    this.invoiceHeaders = [
      'invoice.content.description',
      'invoice.content.quantity',
      'invoice.content.unit_price',
      'invoice.content.subtotal',
    ];

    this.invoiceItems = this.invoice.invoice_items.sort((a: InvoiceItemEntity, b: InvoiceItemEntity) => {
      return a.order - b.order;
    });

    this.invoiceFooters = [
      {
        label: _tk('invoice.content.subtotal'),
        value: this.invoice.items_total,
      },
      {
        label: _tk('invoice.content.vat_label'),
        value: this.invoice.vat * this.invoice.items_total,
        vat: this.invoice.vat * 100 || 0,
      },
      {
        label: _tk('invoice.content.total'),
        value: this.invoice.total
      }
    ];

    const stripePublishableKey: string = this.configManager.getSystemParam(SystemParam.stripe_publishable_key);
    this.stripeService.setKey(stripePublishableKey);
  }

  // get dataSourceItems(): any {
  //   return this.makeDSInvoiceBilling();
  // }

  // private makeDSInvoiceBilling(): MatTableDataSource<any> {
  //   const rows: Array<any> = this.invoice.invoice_items.sort((a: InvoiceItemEntity, b: InvoiceItemEntity) => {
  //     return a.order - b.order;
  //   });
  //   // Subtotal
  //   rows.push({
  //     item_description: _tk('invoice.content.subtotal'),
  //     item_count: undefined,
  //     price: undefined,
  //     item_total: this.invoice.items_total,
  //   });

  //   // VAT
  //   rows.push({
  //     item_description: _tk('invoice.content.vat_label'),
  //     item_count: undefined,
  //     price: undefined,
  //     item_total: this.invoice.vat * this.invoice.items_total,
  //     vat: this.invoice.vat * 100 || 0
  //   });

  //   // Total
  //   rows.push({
  //     item_description: _tk('invoice.content.total'),
  //     item_count: undefined,
  //     price: undefined,
  //     item_total: this.invoice.total
  //   });

  //   return new MatTableDataSource(rows);
  // }

  downloadPdf(): void {
    const invoice_id = this.invoice.id;
    const url = this.invoiceService.getInvoiceViewPdfUrl(invoice_id);
    window.open(url, '_blank');
  }

  downloadCsv(): void {
    const invoice_id = this.invoice.id;
    const url = this.invoiceService.getInvoiceDownloadCsvUrl(invoice_id);
    window.open(url, '_blank');
  }

  isValid(): boolean {
    if (this.invoice.is_paid) {
      console.error('Invoice has already been paid');
      return false;
    }
    if (!this.paymentSource || !this.paymentSource.can_make_payment) {
      console.error('Enterprise doesnt have any credit card or card expired');
      return false;
    }
    if (this.invoice.total <= 0) {
      this.toast.showError(_ti('invoice.message.incorrect_total_amount'));
      return false;
    }
    return true;
  }

  async pay(): Promise<void> {
    if (!this.isValid()) {
      return Promise.reject();
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('payment_wizard.message.confirm_pay'),
      async () => {
        try {
          this.paying = true;

          const intent = await this.paymentService.payPaymentInvoice(this.invoice.id);
          if (intent.status === 'requires_action' || intent.status === 'requires_confirmation') {
            this.stripeService.confirmCardPayment(intent.client_secret)
              .subscribe(async (result: { paymentIntent?: PaymentIntent; error?: StripeError }) => {
                if (result.error) {
                  this.toast.showError(result.error.message);
                  setTimeout(() => {
                    this.invoiceRepo.findAll(false);
                  }, 2000);

                  try {
                    const errorCode = result.error.code || '';
                    await this.invoiceService.updateInvoicePaymentFailureReason(this.invoice.id, errorCode);
                  } catch (e) {
                    console.error('Cannot update invoice payment failure reason', e);
                  }

                  return;
                }

                if (result.paymentIntent.status === 'succeeded') {
                  this.invoice.is_paid = 1;
                  this.invoice.payment_status = 'succeeded';
                  this.toast.show(_ti('invoice.message.payment_success'));
                }
              });
          }
        } catch (error) {
          console.error('Error pay payment invoice: ', error);
          this.toast.showErrorMessage(error, _ti('invoice.message.payment_failure'));
          const payment_failure_reason = error && error.message || _ti('invoice.message.payment_failure');
          await this.invoiceService.updateInvoicePaymentFailureReason(this.invoice.id, payment_failure_reason);
        } finally {
          this.paying = false;
        }
      }
    );
  }

  async cancel(): Promise<void> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('payment.title.cancel'),
      async () => {
        try {
          await this.paymentService.cancelPaymentInvoice(this.invoice.id);
          this.toast.show(_ti('invoice.message.payment_canceled'));
        } catch (error) {
          this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
        }
      }
    );
  }

  payAvailable(): boolean {
    if (this.paying) {
      return false;
    }

    return !this.invoice.payment_status || _.includes([
      'canceled',
      'requires_action',
      'requires_capture',
      'requires_confirmation',
      'requires_payment_method'
    ], this.invoice.payment_status);
  }
}
