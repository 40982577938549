import * as _ from 'lodash';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { EditingComponent, ToastService } from '@wephone-utils';
import { SlackChannelEntity } from '@wephone-core/model/entity/slack_channel';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { SlackChannelRepository } from '@wephone-core/model/repository/slack_channel';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'slack-channel-edit',
  templateUrl: './slack-channel-edit.html',
  styleUrls: ['./slack-channel-edit.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlackChannelEditComponent extends EditingComponent implements OnInit {

  SEND_MESSAGE_URL = 'slack/send-message';

  private slackChannelRepo = SlackChannelRepository.getInstance() as SlackChannelRepository;

  @Input() editingItem: SlackChannelEntity;

  slackChannel: SlackChannelEntity;
  messageCtrl = new FormControl('', Validators.required);

  constructor(
    private readonly toastService: ToastService,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.slackChannel = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      publicId: [this.slackChannel.public_id],
      teamId: [this.slackChannel.team_id],
      teamName: [this.slackChannel.team_name],
      channel: [this.slackChannel.incoming_webhook_channel],
      isActive: [this.slackChannel.is_active],
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
  }

  resetForm(): void {
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  // Actions form
  addChannel(): void {
    const url = `${FlexIvrSettings.getInstance().getAbsoluteUrl('/ws-v2/api/slack/oauth')}/${this.slackChannel.public_id}`;
    window.location.href = url;
  }

  async sendMessage(): Promise<void> {
    if (this.messageCtrl.invalid) {
      return;
    }

    const message = this.messageCtrl.value;
    const data = {
      id: this.slackChannel.id,
      message
    };

    try {
      const res = await HttpEngine.getInstance().apiPostV2(this.SEND_MESSAGE_URL, data);
      this.toastService.showSuccess(res);
    } catch (e) {
      this.toastService.showError(e.error.message);
    }
  }

  async submitForm(): Promise<any> {
    const is_active = this.form.value.isActive ? 1 : 0;

    try {
      const res = await this.slackChannelRepo.saveAttrs(this.slackChannel, ['is_active'], { is_active });
      this.toastService.showSuccess(res.status);
      this.resetForm();
    } catch (e) {
      this.toastService.showError(e.error.message);
    }
  }
}
