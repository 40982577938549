<!-- <ng-template #tplFilterDefault>
  <flex-search-input placeholder="{{ 'public.search' | translate }}" [(ngModel)]="filterString"
    ngClass.xs="search-full-width"></flex-search-input>
</ng-template> -->

<div class="page-root">
  <div class="page-header" fxLayout="row" fxLayout.xs="column">
    <h2 *ngIf="pageTitle">{{ pageTitle }}</h2>
    <div fxFlex fxLayout="row" fxLayoutAlign="end center" class="action-container">
      <div fxFlex="auto" class="filter-container"
        *ngIf="filterTemplate && (!sidePannel.hasEditor() || (sidePannel.hasEditor() && !sidePannel.maximize()))">
        <ng-container *ngTemplateOutlet="filterTemplate;context:{filter:filterString}"></ng-container>
      </div>
      <ng-container *ngIf="actionsMenu.length">
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #appMenu="matMenu" yPosition="below">
          <div (click)="action.action()" mat-menu-item *ngFor="let action of actionsMenu">
            {{ action.label | translate }}
          </div>
        </mat-menu>
      </ng-container>
    </div>
  </div>

  <div class="table-container" [fxLayout]="sidePannel.hasEditor() ? 'row': 'column'">
    <mat-card #listWrapper class="item-list shadow-none" [ngClass]="{'list-with-detail': sidePannel.hasEditor()}"
      [fxFlex]="listFlexValue" fxLayout="column"
      [hidden]="(!screenSize.isScreenSize('gt-md') && sidePannel.hasEditor()) || (sidePannel.hasEditor() && sidePannel.maximize())">

      <flex-table #objectListTable [dataSource]="dataSource" [tableConfig]="tableConfig"
        [sidePannelOptions]="sidePannelOptions"
        [compactMode]="sidePanelHasEditor()"
        (onSelectionChange)="selectionChange($event)" [columnTemplates]="columnTemplates" fxLayout="column"
        fxFlex="grow">
      </flex-table>
    </mat-card>
    <div class="separator" *ngIf="!sidePannel.maximize() && sidePannel.hasEditor() && screenSize.isScreenSize('gt-md')">
      <div class="connector" [style.height.px]="selectedRowOffsetY">
        <div class="connector-top">
          <div class="buble"></div>
        </div>
        <div class="connector-middle"></div>
        <div class="connector-bottom">
          <div class="buble"></div>
        </div>
      </div>
    </div>
    <mat-card class="item-detail shadow-none" [hidden]="!sidePannel.hasEditor()">
      <flex-table-side-pannel #sidePannel [dataSource]="dataSource" [flexTable]="objectListTable"
        [options]="sidePannelOptions" fxFill fxFlex>
      </flex-table-side-pannel>
    </mat-card>
  </div>
</div>
