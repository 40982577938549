import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class HotelWakeupCallRepository extends BaseRepository {
  ENTITY_CLASS = HotelWakeupCallEntity;
  // protected USE_PUSH_API = true;
  protected URL_PREFIX = 'hotel_wakeup_call';
  protected RELATED_REPOSITORIES = ['hotelroom'];

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  getObjectListByHotelRoomId(room_id: number): HotelWakeupCallEntity[] {
    return this.getObjectList().filter(sp => sp.room_id === room_id);
  }

  getFirstObjectByHotelRoomId(room_id: number): HotelWakeupCallEntity {
    let obj_list: HotelWakeupCallEntity[] = this.getObjectListByHotelRoomId(room_id);
    obj_list = _.orderBy(obj_list, ['id'], ['desc']);
    return obj_list.length ? obj_list[0] : null;
  }

  getLastWakeupCallHotelRoomId(room_id: number): HotelWakeupCallEntity {
    let obj_list: HotelWakeupCallEntity[] = this.getObjectListByHotelRoomId(room_id);
    obj_list = _.orderBy(obj_list, ['id'], ['desc']);
    return obj_list.find(o => o.state !== HotelWakeupCallStateType.ACTIVE);
  }
}

import { HotelWakeupCallEntity } from '../entity/hotel_wakeup_call';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { HotelWakeupCallStateType } from '../entity/hotelroom.i';
