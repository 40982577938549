<div class="mini-block">
    <div class="flag">
        <span class="flag-icon maticon"><mat-icon>alt_route</mat-icon></span>
    </div>
    <div class="number-call">
        <span class="name font-weight-bold" [matTooltip]="crmRoutingRule.name" matTooltipPosition="above">{{ crmRoutingRule.name | truncate:18 }}&nbsp;</span>
        <span class="number" *ngIf="crmRoutingRule.crm_id && crmRoutingRule.crm">
            <span>{{ crmRoutingRule.crm.name | truncate:18 }}</span>
        </span>
    </div>
</div>
