<div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div *ngFor="let type of types" (click)="onCrmSelect(type)">
    <mat-card [attr.data-ci]="type.name" class="round crm-type" mat-stroked-button>
      <div fxLayout="column" fxLayoutAlign="center center" fxFill>
        <img src="assets/images/{{type.logo}}" width="60%">
        {{ type.name }}
      </div>
    </mat-card>
  </div>
</div>
