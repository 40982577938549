<ng-container *ngIf="is_ready">
  <div fxLayout="row wrap" class="available-agent-list">
    <ng-container *ngFor="let agent of agent_available_list_ordered; let i = index">
      <div fxLayoutGap="10px" class="item-wrapper {{ displayType == 'compact' ? 'compact-item' : 'full-width' }}"
        ngClass.xs="xs" ngClass.sm="sm" ngClass.md="md" ngClass.lg="lg">
        <ng-container *ngIf="dropEnable && isAgentAvailable(agent);else NotAvailBlock">
          <div fxFlex="grow" fxLayout="column" class="item-body" id="{{ 'agent' + agent.id }}"
            [attr.agent-id]="agent.id" cdkDropList [cdkDropListData]="[agent]" (cdkDropListDropped)="dropAgent($event)">
            <div fxFlexFill fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="none" cdkDrag
              [cdkDragDisabled]="true" [cdkDragData]="agent.id">
              <div class="flex-drag-placeholder" *cdkDragPlaceholder></div>
              <ng-container
                *ngTemplateOutlet="tlpAgentItem; context: {agent: agent, idx: i, displayType: displayType, agentActions: agentActions}">
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #NotAvailBlock>
          <div fxFlex="grow" fxLayout="column" class="item-body">
            <ng-container
              *ngTemplateOutlet="tlpAgentItem; context: {agent: agent, idx: i, displayType: displayType, agentActions: agentActions}">
            </ng-container>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <ng-template #tlpAgentItem let-agent="agent" let-i="idx" let-displayType="displayType"
    let-agentActions="agentActions">
    <div fxFlexFill fxLayout="column" class="bg-gray">
      <div fxFlex>
        <agent-item [avatar]="true" [agent]="agent" [actions]="agentActions" [displayCallInfo]="true">
        </agent-item>
      </div>

      <!-- queues & groups -->
      <div fxFlex *ngIf="displayType == 'queue'">
        <agent-queues [agent]="agent" [add_queue]="false"></agent-queues>
      </div>

      <div fxFlex *ngIf="displayType == 'group'">
        <agent-groups [agent]="agent" [removeGroup]="true"></agent-groups>
      </div>
    </div>
  </ng-template>
</ng-container>
