import { Component, OnInit, Input } from '@angular/core';
import { DidEntity } from '@wephone-core/model/entity/did';

@Component({
  selector: 'app-did-mini-block',
  templateUrl: './did-mini-block.component.html',
  styleUrls: ['./did-mini-block.component.scss']
})
export class DidMiniBlockComponent implements OnInit {
  @Input() did: DidEntity;
  // constructor() { }

  ngOnInit(): void {
  }

}
