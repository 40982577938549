import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IEntity } from '@wephone-core/model/model.interface';
import { ISipPhoneRepository } from '@wephone-core/model/repository/sipphone.i';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';

export class SipPhoneRepository extends BaseRepository implements ISipPhoneRepository {
  ENTITY_CLASS = SipPhoneEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'sipphone';

  protected METHOD_PATH_ENABLE = 'enable';
  protected METHOD_PATH_DISABLE = 'disable';
  private URL_ADD_MULTIPLE_SIPPHONE = 'add_multiple';

  getObjectListByUserId(user_id: number): SipPhoneEntity[] {
    return this.object_list.filter(sp => sp.user_id == user_id);
    // const obj_list = [];
    // for (const a of this.object_list) {
    //   if (a.user_id == user_id) {
    //     obj_list.push(a);
    //   }
    // }
    // return obj_list;
  }

  getFirstObjectByUserId(user_id: number): SipPhoneEntity {
    const obj_list: SipPhoneEntity[] = this.getObjectListByUserId(user_id);
    return obj_list && obj_list.length ? obj_list[0] : null;
  }

  setPhoneMode(sipphone: SipPhoneEntity, phone_mode): Promise<any> {
    const attr_list = ['phone_mode'];
    return this.saveAttrs(sipphone, attr_list, {});
  }

  getObjectListByHotelRoomId(hotel_room_id: number): SipPhoneEntity[] {
    return this.object_list.filter(sp => sp.hotel_room_id == hotel_room_id);
  }

  getFirstObjectByHotelRoomId(hotel_room_id: number): SipPhoneEntity {
    let obj_list: SipPhoneEntity[] = this.getObjectListByHotelRoomId(hotel_room_id);
    obj_list = _.orderBy(obj_list, ['id'], ['desc']);
    return obj_list && obj_list.length ? obj_list[0] : null;
  }

  protected getURLAction(action: string): string {
    let url: string;
    switch (action) {
      case 'ENABLE':
        url = this.getUrlV2(this.METHOD_PATH_ENABLE);
        break;
      case 'DISABLE':
        url = this.getUrlV2(this.METHOD_PATH_DISABLE);
        break;
      case 'UPDATE':
        url = this.getUrlV2(this.METHOD_PATH_UPDATE);
        break;
      case 'UPDATE_ATTRS':
        url = this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
        break;
      case 'UPDATE_ATTRS_BULK':
        url = this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS_BULK);
        break;
      case 'ADD':
        url = this.getUrlV2(this.METHOD_PATH_NEW);
        break;
      case 'ADD_MULTIPLE':
        url = this.getUrlV2(this.URL_ADD_MULTIPLE_SIPPHONE);
        break;
      case 'ADD_ATTRS':
        url = this.getUrlV2(this.METHOD_PATH_NEW_ATTRS);
        break;
      case 'DELETE':
        url = this.getUrlV2(this.METHOD_PATH_DELETE);
        break;
      default:
        url = super.getURLAction(action);
        break;
    }
    return url;
  }

  bulkEnable(items: IEntity[]): Promise<any> {
    const ids: number[] = [];
    let i: number;
    for (i = 0; i < items.length; i++) {
      ids.push(+items[i].getId());
    }
    const url = this.getURLAction('ENABLE');
    return HttpEngine.getInstance().post(url, { ids });
  }

  bulkDisable(items: IEntity[]): Promise<any> {
    const ids: number[] = [];
    let i: number;
    for (i = 0; i < items.length; i++) {
      ids.push(+items[i].getId());
    }
    const url = this.getURLAction('DISABLE');
    return HttpEngine.getInstance().post(url, { ids });
  }

  getObjectByHotelRoomId(hotelRoomId: number): SipPhoneEntity {
    let sipphone: SipPhoneEntity;
    for (const i of this.getObjectList()) {
      if (i.hotel_room_id && i.hotel_room_id == hotelRoomId) {
        sipphone = i;
        break;
      }
    }
    return sipphone;
  }

  // override from base_repository
  createAndSave(object_data: any = null, extra_data: any = null): Promise<any> {
    const obj = this.create(object_data);
    return this.save(obj, extra_data);
  }

  addMultipleSipPhone(range_extensions: any[]): Promise<any> {
    const url = this.getURLAction('ADD_MULTIPLE');
    return HttpEngine.getInstance().post(url, { range_extensions });
  }

  getObjectByDidNumber(number: string): SipPhoneEntity {
    return this.object_list.find(o => {
      return o.did_calling_number && o.did_calling_number.number === number;
    });
  }

  getObjectByExtension(extension: string): SipPhoneEntity {
    return this.object_list.find(o => {
      return o.extension === extension;
    });
  }

  getAllEnabled(): SipPhoneEntity[] {
    return this.object_list.filter(o => o.enabled);
  }
}
