import { Component, OnInit, Input, ViewChild, ElementRef, Optional, Self } from '@angular/core';
import { FlexMatInputWrapper, TreeHelper } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import { NgControl, FormControl } from '@angular/forms';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import * as _ from 'lodash';

@Component({
  selector: 'flex-select-pause-input',
  templateUrl: './flex-select-pause-input.html',
  styleUrls: ['./flex-select-pause-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectPauseInput }]
})
export class FlexSelectPauseInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() pauses: PauseReasonEntity[];
  @Input() multiple: boolean;
  @Input() showCheckAll: boolean;
  @ViewChild('flexSelectTreeInput', { static: false }) flexSelectTreeInput: FlexSelectTreeInput;

  private pauseRepo = PauseReasonRepository.getInstance() as PauseReasonRepository;

  myControl = new FormControl();
  pauseNodes: FlexSelectTreeNode[];

  constructor(
    elRef: ElementRef,
    @Optional()
    @Self()
    ngControl: NgControl
  ) {
    super(ngControl, elRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.pauses) {
      this.pauses = this.pauseRepo.getObjectList() as PauseReasonEntity[];
    }

    const nodeList = this.pauses.map(pause => {
      return {
        id: pause.id,
        name: pause.label,
        parent_id: undefined
      };
    });

    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.pauseNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-pause-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }
}
