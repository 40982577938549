import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'time-renderer',
  template: `<span>{{ params.value | timeFormatTimeSimple }}</span>`
  // EN: 1:30 PM  FR: 13:30
})
export class TimeRenderer implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}

@Component({
  selector: 'timeWithSecond-renderer',
  template: `<span>{{ params.value | timeFormatTimeWithSeconds }}</span>`
  // EN: 1:30:23 PM	FR: 13:30:23
})
export class TimeWithSecondRenderer implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
