import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { _ti } from '@wephone-translation';
import { ImageEntity } from '../entity/image';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';

export class ImageRepository extends BaseRepository {
  ENTITY_CLASS = ImageEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'image';

  private URL_IMAGE_GET = 'image/{public_id}';
  private URL_IMAGE_DEL = 'image/delete';
  private URL_IMAGE_UPLOAD = 'image/upload';
  private URL_IMAGE_UPDATE = 'image/update';
  private URL_IMAGE_USED = 'image/objects-used';

  getFileUrl(publicId: string, download: boolean = false): string {
    // const fileEntry: ImageEntity = this.getObjectByPublicId(publicId);
    let url: string;

    url = this.URL_IMAGE_GET
      .replace('{public_id}', publicId);

    if (download) {
      url += '?download=1';
    }

    return FlexIvrSettings.getInstance().getEnterpriseUrlV2(url);
  }

  async getObjectsUsed(ids: number[]): Promise<any> {
    const ret = await HttpEngine.getInstance().apiGetV2(this.URL_IMAGE_USED, { 'ids[]': ids });
    return ret;
  }

  getObjectByName(name: string, excludedId?: number): ImageEntity {
    return this.getObjectList().find(x => x.name.toLowerCase() === _.trim(name).toLowerCase() 
      && (!excludedId || excludedId && x.id !== excludedId));
  }

  getObjectList(): ImageEntity[] {
    const list = super.getObjectList();
    return list.filter(x => !x.is_temp);
  }

  getObjectByPublicId(publicId: string): ImageEntity {
    return this.getObjectList().find(x => x.public_id === publicId);
  }

  bulkDelete(images: ImageEntity[]): Promise<any> {
    const publicIds: string[] = images.map(x => x.public_id);
    return HttpEngine.getInstance().apiPostV2(this.URL_IMAGE_DEL, { public_ids: publicIds });
  }

  updateImage(params: {
    public_id: string;
    name: string;
    // replaced_public_id?: string;
  }): Promise<any> {
    return HttpEngine.getInstance().apiPostV2(this.URL_IMAGE_UPDATE, params);
  }

  upload(soundFile: File, params: any = null): Promise<any> {
    const options: any = {
      data: params,
      method: 'POST',
      headers: { 'Content-Type': soundFile.type }
    };
    const body = new FormData();
    body.append('file', soundFile);

    if (params) {
      for (const key of Object.keys(params)) {
        body.append(key, params[key]);
      }
    }

    return HttpEngine.getInstance().apiPostV2(this.URL_IMAGE_UPLOAD, body, options, true);
  }
}
