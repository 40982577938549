<div fxLayout="column" class="blk-queue pa-1">
  <div fxLayout="row" fxLayoutAlign="start center">
    <call-queue-logo 
      [queue]="queues[queue.queue_id]"
      height="30px"
      width="auto"
      [uploadAvatar]="false">
    </call-queue-logo>
    <div class="ml-1 font-weight-bold">{{queue.queue_name}}</div>
  </div>
  <div fxLayout="row">
    <mat-chip-list fxFlex="auto" 
      fxLayout="row wrap" 
      fxLayoutAlign="start center" 
      fxLayoutGap="20px" 
      fxLayoutGap.xs="0" *ngIf="queue.qos_data">
      <div fxLayout="row" fxLayoutAlign="start center">
        <strong>QoS :</strong>
        <mat-chip color="primary">{{queue.qos_data.qos}} %</mat-chip>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <strong>{{ 'livecall.overview.received_calls' | translate }} :</strong>
        <mat-chip color="primary">{{queue.qos_data.total_inbound}}</mat-chip>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <strong>{{ 'livecall.overview.answered_calls' | translate }} :</strong>
        <mat-chip color="primary">{{queue.qos_data.answered}}</mat-chip>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <strong>{{ 'livecall.overview.outbound_calls' | translate }} :</strong>
        <mat-chip color="primary">{{queue.qos_data.total_outbound}}</mat-chip>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <strong>DMR: </strong>
        <mat-chip color="primary">{{queue.qos_data.dmr | seconds_as_duration}}</mat-chip>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <strong>DMT :</strong>
        <mat-chip color="primary">{{queue.qos_data.dmt | seconds_as_duration}}</mat-chip>
      </div>
    </mat-chip-list>

    <div fxFlex="100px" fxLayoutAlign="end center" >
      <button *ngIf="showEavesdrop" 
        mat-stroked-button color="primary"
        (click)="call_eavesdrop_queue(queue.queue_id)" class="border-button">{{ 'livecall.actions.eavesdrop' | translate }}
      </button>
    </div>

  </div>

  <div>
    <div [chart]="lineChart"></div>
  </div>
</div>
