<div class="ma-1" fxLayout="column" fxLayoutGap="10px" *ngIf="invoice">

  <div fxLayout="row" fxLayoutAlign="start center">
    <h2 fxFlex class="heading ma-0">{{ 'invoice.content.invoice_info'|translate }}</h2>
    <div fxFlex="200px" fxLayoutAlign="end center">
      <ng-container *ngIf="!invoice.is_paid">
        <button *ngIf="paymentSource?.can_make_payment" (click)="pay()" mat-raised-button color="accent"
          [disabled]="!payAvailable()">
          <mat-icon>money</mat-icon>
          {{'invoice.content.pay'|translate}}
        </button>
      </ng-container>
      <!-- <ng-template #cancelBlock>
        <button
          *ngIf="['requires_payment_method', 'requires_capture', 'requires_confirmation', 'requires_action'].includes(invoice.payment_status)"
          (click)="cancel()" mat-raised-button color="warn">
          <mat-icon>cancel</mat-icon>
          {{'invoice.content.cancel_payment'|translate}}
        </button>
      </ng-template> -->
    </div>
  </div>

  <div>
    <table class="table-info">
      <tbody>
        <tr>
          <td class="label-header"><strong>{{ 'invoice.content.invoice_period' | translate }}:</strong></td>
          <td class="right-align">{{ invoice.start_dt | date:'yyyy-MM-dd' }} ~ {{ invoice.end_dt | date:'yyyy-MM-dd' }}
          </td>
        </tr>
        <tr>
          <td class="label-header"><strong>{{ 'invoice.content.invoice_id' | translate }}:</strong></td>
          <td class="right-align">{{ invoice.invoice_number }}</td>
        </tr>
        <tr>
          <td class="label-header"><strong>{{ 'invoice.content.invoice_date' | translate }}:</strong></td>
          <td class="right-align">{{ invoice.invoice_dt | date:'yyyy-MM-dd' }}</td>
        </tr>
        <tr>
          <td class="label-header"><strong>{{ 'invoice.content.status' | translate }}:</strong></td>
          <td class="right-align">
            <ng-container *ngIf="invoice.is_paid; else statusBlock">
              <span class="text-success" translate="invoice.content.paid"></span>
            </ng-container>
            <ng-template #statusBlock>
              <span class="text-muted" *ngIf="invoice.payment_status !== 'processing'"
                translate="invoice.content.to_be_paid">
              </span>
              <span class="mat-text-warn" *ngIf="invoice.payment_status === 'processing'"
                translate="invoice.content.processing">
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="hr"></div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <h2 fxFlex class="heading ma-0">{{ 'invoice.content.invoice_detail'|translate }}</h2>
    <div fxLayoutAlign="end center">
      <button class="mr-1" mat-stroked-button color="accent" [disabled]="!invoice.isPdfReady" (click)="downloadPdf()"
        title="{{ 'invoice.content.view_pdf'|translate }}">
        <mat-icon>file_copy</mat-icon>
        {{ 'invoice.content.view_pdf'|translate }}
      </button>
      <button *ngIf="invoice.invoice_type === 0" mat-stroked-button color="accent" [disabled]="!invoice.isPdfReady"
        (click)="downloadCsv()" title="{{ 'invoice.content.export_call.title'| translate }}">
        <mat-icon>vertical_align_bottom</mat-icon>
        {{ 'invoice.content.export_call.title'|translate }}
      </button>
    </div>
  </div>

  <div>
    <table class="flex-table-info">
      <thead>
        <tr>
          <th *ngFor="let header of invoiceHeaders" [translate]="header"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of invoiceItems">
          <td>{{ item.item_description | translate }}</td>
          <td>{{ item.item_count | number:'0.2-2' }}</td>
          <td>{{ item.price | currency: invoice.currency : 'symbol' : '0.2-2' }}</td>
          <td>{{ item.item_total | currency:invoice.currency: 'symbol':'0.2-2' }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr *ngFor="let footer of invoiceFooters">
          <td colspan="3">{{ footer.label | translate:{vat:footer.vat} }}</td>
          <td>{{ footer.value | currency: invoice.currency : 'symbol' : '0.2-2' }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
