import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';

export interface VoicemailUser {
  queue_id: number;
  queue_name: string;
  readers: number[];
  sound_id: string;
  duration: number;
  receive_dt: string;
  id: number;
  is_read: number;
  is_archived: number;
  calling_number: string;
  called_number: string;
  file_entry_id: number;
  file_entry_ready: number;
}

export interface VoicemailUserFilterParams {
  called_numbers?: string[];
  calling_number?: string;
  groups?: number[];
  queues?: number[];
  start_dt?: string;
  end_dt?: string;
  listen_state?: string;
  archived?: 1 | 0;
}

@Injectable()
export class VoicemailService {
  private static URL_GET_MY_VOICEMAIL = 'voicemail/get_user_voicemail/{userId}';
  private static URL_GET_UNREAD_MY_VOICEMAIL_NUMBER = 'voicemail/get_unlistened_number/{userId}';
  constructor(private httpEngine: HttpEngine) { }

  private getVoicemailUserParams(filter: VoicemailUserFilterParams): any {
    return {
      start_dt: filter.start_dt,
      end_dt: filter.end_dt,
      calling_number: filter.calling_number,
      listen_state: filter.listen_state,
      archived: filter.archived && 1 || 0,
      'called_numbers[]': filter.called_numbers.filter(n => !!n),
      'groups[]': filter.groups.filter(g => !!g),
      'queues[]': filter.queues.filter(q => !!q),
    };
  }

  getUnlistenedNumber(userId: number, filter: VoicemailUserFilterParams): Promise<number> {
    const params = this.getVoicemailUserParams(filter);
    return this.httpEngine.apiGetV2(VoicemailService.URL_GET_UNREAD_MY_VOICEMAIL_NUMBER.replace('{userId}', userId.toString()), params);
  }

  getUserVoicemails(userId: number, filter: VoicemailUserFilterParams): Promise<VoicemailUser[]> {
    const params = this.getVoicemailUserParams(filter);
    return this.httpEngine.apiGetV2(VoicemailService.URL_GET_MY_VOICEMAIL.replace('{userId}', userId.toString()), params);
  }
}
