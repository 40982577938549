<ng-template #listFilterTpl>
  <form [formGroup]="tagForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
    fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
    <div>
      <flex-search-input formControlName="filterValue" placeholder="{{ 'public.search' | translate }}"
        ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>
<ng-template #activeTpl let-active="row">
  <mat-icon *ngIf="active.is_active">done</mat-icon>
  <mat-icon *ngIf="!active.is_active"></mat-icon>
</ng-template>
<ng-template #agentIdTpl let-agentId="row">
  <mat-icon *ngIf="agentId.use_agent_id">done</mat-icon>
  <mat-icon *ngIf="!agentId.use_agent_id"></mat-icon>
</ng-template>
<flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_tags' | translate }}" [filterTemplate]="listFilterTpl"
  [tableConfig]="tableConfig" [dataSource]="dataSource" [sidePannelOptions]="sidePannelOptions" fxLayout="row"
  fxFlex="grow">
</flex-crud-page>
