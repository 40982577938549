import { Injectable } from '@angular/core';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import * as _ from 'lodash';
import { EntityManager } from './entity_manager';

@Injectable()
export class IvrEvaluationService {
  constructor(
    private readonly em: EntityManager,
  ) { }

  getUsedQueuesOfIvrEvaluations(ivrEvaluationIds: number[]): CallQueueEntity[] {
    return this.em.getRepository('CallQueueRepository').getObjectList().filter(q => ivrEvaluationIds.includes(q.eoc_survey_id));
  }
}
