import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class TaskRepository extends BaseRepository {
  ENTITY_CLASS = TaskEntity;
  protected URL_PREFIX: string = 'task';
  protected USE_PUSH_API = true;

  public getMissedCallTasks(user_id: number): Array<TaskEntity> {
    let tasks = this.getObjectList();
    let ret = [];
    for (let task of tasks) {
      if (
        task.task_type == TaskEntity.task_type_missedcall &&
        task.owner_type == TaskEntity.ower_type_user &&
        task.owner_id == user_id
      ) {
        ret.push(task);
      }
    }
    return ret;
  }

  public getUserQueueTasks(call_queue_ids: Array<number>): Array<TaskEntity> {
    let tasks = this.getObjectList();
    let ret = [];
    for (let task of tasks) {
      if (task.owner_type == TaskEntity.ower_type_queue && call_queue_ids.indexOf(task.owner_id) != -1) {
        ret.push(task);
      }
    }
    return ret;
  }
}

import { TaskEntity } from '@wephone-core/model/entity/task';
