<flex-select [placeholder]="placeholder" 
              [formControl]="flexSelect" 
              [itemList]="sipTrunkList"
              [itemTemplate]="itemTemplate"
              [options]="flexSelectOptions"
              [multiple]="false">
  <ng-template #itemTemplate let-item="item">
    {{ item.name }}
  </ng-template>
</flex-select>
