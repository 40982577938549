import { NgModule, Injector, ModuleWithProviders } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from '@wephone-core/service/http_interceptor';

import { WephoneUtilsModule, PhoneNumberService } from '@wephone-utils';

import { ConfigManager } from '@wephone-core/service/config_manager';
export { ConfigManager } from '@wephone-core/service/config_manager';

import { EventManager } from '@wephone-core/event/event_manager';

import { AuthenticationService } from '@wephone-core/service/authentication';

import { EntityManager } from '@wephone-core/service/entity_manager';
export { EntityManager } from '@wephone-core/service/entity_manager';

import { DataSourceService } from '@wephone-core/service/datasource.service';
import { CallLogService } from '@wephone-core/service/call_log_service';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { WsRpcService } from '@wephone-core/service/wsrpc_service';

import { FlexIvrSettings, FlexIVRAppType } from '@wephone-core/service/flexivr_settings';
export { FlexIvrSettings, FlexIVRAppType as FlexIVRProject } from '@wephone-core/service/flexivr_settings';

import { CommonAPI } from '@wephone-core/service/common_api';
import { LiveStatsService } from '@wephone-core/service/live-stats.service';
import { LiveQoSWatchService } from '@wephone-core/service/live-qoswatch.service';
import { OutcallService } from '@wephone-core/service/outcall.service';
import { HighChartsHelperService } from '@wephone-core/service/high_charts_helper.service';
import { LocalManager } from '@wephone-core/service/local_manager';
import { StorageService } from '@wephone-core/service/storage.service';
import { AuthGuardService } from '@wephone-core/service/auth_guard.service';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { SystemParamService } from '@wephone-core/service/system-param-service';
import { RoleGuardService } from '@wephone-core/service/role_guard.service';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { EnterpriseFileEntryService } from '@wephone-core/service/enterprise_file_entry_service';
import { InvoiceService } from '@wephone-core/service/invoice.service';
import { LocationService } from '@wephone-core/service/locaction.service';
import { CdrService } from '@wephone-core/service/cdr_service';
import { PushNotificationManager } from '@wephone-core/service/push_notification';
import { RecordingService } from '@wephone-core/service/recording_service';
import { UserStatsService } from '@wephone-core/service/user-stats.service';
import { TaskService } from '@wephone-core/service/task.service';
import { NetStatus } from './service/net-status.service';
import { environment } from '../../../src/environments/environment';
import { IvrService } from './service/ivr.service';
import { SmsTemplateService } from './service/sms-template.service';

import localeFr from '@angular/common/locales/fr';
import localeVi from '@angular/common/locales/vi';
import localePt from '@angular/common/locales/pt';
import { CrmContactService } from './service/crm-contact.service';
import { VoicemailService } from './service/voicemail.service';
import { WebPushService } from './service/webpush.service';
import { RoutingAppService } from './routing-app/routing-app-service';
import { IvrEvaluationService } from './service/ivr_evaluation.service';
import { RoutingServiceService } from './service/routing-service.service';
import { NotificationService } from './service/notification.service';
import { ImageService } from '@wephone/services/image.service';

for (const locale of [localeFr, localeVi, localePt]) {
  registerLocaleData(locale);
}

let root_url = '/';
if (window['ELECTRON_APP'] || window['cordova'] || window['_cordovaNative']) {
  root_url = window['WEPHONE_URL'] || environment.apiUrl || '/';
}

if (root_url === '/' && window && window.location) {
  const location = window.location;
  root_url = location.protocol + '//' + location.host;
}

FlexIvrSettings.getInstance().update({
  php_session_name: 'PHPSESSID',
  root_url
});

export function getFlexIvrSetting(): FlexIvrSettings {
  return FlexIvrSettings.getInstance();
}

@NgModule({
  imports: [WephoneUtilsModule],
  declarations: [],
  exports: [],
})
export class WephoneCoreModule {

  constructor(injector: Injector, em: EntityManager, routingAppService: RoutingAppService) {
    StaticServiceLocator.injector = injector;
    /*
    StaticServiceLocator.injector = Injector.create(
      Object.keys(staticServices).map(key => staticServices[key])
    );
    */
  }

  static forRoot(): ModuleWithProviders<WephoneCoreModule> {
    return {
      ngModule: WephoneCoreModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        { provide: FlexIvrSettings, useFactory: getFlexIvrSetting },
        HttpEngine,
        StorageService,
        CommonAPI,
        EventManager,
        ConfigManager,
        DataSourceService,
        CallLogService,
        WsRpcService,
        EntityManager,
        AuthenticationService,
        PhoneNumberService,
        LiveStatsService,
        UserStatsService,
        LiveQoSWatchService,
        OutcallService,
        HighChartsHelperService,
        LocalManager,
        AuthGuardService,
        RoleGuardService,
        SystemParamService,
        FileEntryService,
        ImageService,
        EnterpriseFileEntryService,
        InvoiceService,
        VoicemailService,
        LocationService,
        CdrService,
        PushNotificationManager,
        RecordingService,
        TaskService,
        NetStatus,
        IvrService,
        IvrEvaluationService,
        CrmContactService,
        WebPushService,
        RoutingAppService,
        RoutingServiceService,
        NotificationService,
        SmsTemplateService,
      ]
    };
  }
}
