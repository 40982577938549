import * as _ from 'lodash';

import { Component, ViewChild } from '@angular/core';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IFlexTableSidePannelOptions, DialogService, regexSearch, IFlexTableConfig, DynamicFilterSource } from '@wephone-utils';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrMenuEditComponent } from '@wephone/components/ivr-menu/ivr-menu-edit/ivr-menu-edit.component';
import { IvrMenuCreateModal } from '@wephone/modals/ivr-menu/ivr-menu-create/ivr-menu-create-modal.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { Router } from '@angular/router';
import { IvrService } from '@wephone-core/service/ivr.service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IvrMenuType } from '@wephone-core/model/repository/ivr_custom_menu.i';

@Component({
  selector: 'ivrmenu-page',
  templateUrl: './ivrmenu-page.component.html',
  styleUrls: ['./ivrmenu-page.component.scss']
})
export class IvrMenuPage extends FlexBasePage {
  ivrmenuRepo = IvrCustomMenuRepository.getInstance<IvrCustomMenuRepository>();
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: IvrMenuEditComponent
  };
  filterString: string;
  form: FormGroup;

  @ViewChild('flexCrud') flexCrud;

  constructor(
    public dialogService: DialogService,
    private fb: FormBuilder,
    private router: Router,
    private readonly ivrService: IvrService,
  ) {
    super();
    const ivrmenuSource = this.ivrmenuRepo.dataSource<IvrCustomMenuEntity>();
    this.dataSource = new DynamicFilterSource(ivrmenuSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('ivrmenu.content.name'),
          enableTooltip: true,
          lineClamp: 2,
          sortable: true,
          sort: 'asc',
          customSortFn: (ivrmenu: IvrCustomMenuEntity) => {
            return ivrmenu.name.toLowerCase();
          }
        },
        {
          name: 'sound_id',
          label: 'ivrmenu.content.play_message',
          sortable: false,
          // customSortFn: (ivrmenu: IvrCustomMenuEntity) => {
          //   return ivrmenu.isConfigured ? 1 : 0;
          // }
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.create();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });
    this.form.valueChanges.subscribe(this.onFormValueChange);
  }

  protected async resolveData(): Promise<any> {
    await EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
  }

  private filterFunc = (ivrmenu: IvrCustomMenuEntity): boolean => {
    if (!ivrmenu.parent_id && ivrmenu.ivrscript_type === IvrMenuType.IvrMenu && this.filterPredicate(ivrmenu, this.filterString)) {
      return true;
    }
  }

  private filterPredicate(item: IvrCustomMenuEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(item.name, filterString);
  }

  private onFormValueChange = (formValues: { filterString: string }) => {
    this.filterString = (formValues.filterString || '').trim();
    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  private async bulkDelete(items: IvrCustomMenuEntity[]): Promise<any> {
    if (_.isEmpty(items)) {
      return;
    }

    const confirmed: boolean = await this.ivrService.getConfirmDeleteIvrMenus(items);
    if (confirmed) {
      try {
        await this.ivrmenuRepo.bulkDelete(items);
        this.flexCrud.closeSidePanel();
        this.successToast(_ti('public.message.delete_success'));
      } catch (e) {
        console.error('Delete IVR-Menu error', e);
        this.showErrorMessage(e, _ti('public.message.delete_failed'));
      }
    }
  }

  notOpenEditor(event: any): void {
    event.stopPropagation();
  }

  create(): void {
    this.dialogService.openDialog2(IvrMenuCreateModal, { size: 'fs' }, ivrmenu => {
      if (ivrmenu && ivrmenu.id) {
        this.router.navigate(['ivr', 'ivr-menus', ivrmenu.id], { skipLocationChange: false });
      }
    });
  }
}
