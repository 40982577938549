import { Component, Input } from '@angular/core';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';

@Component({
  selector: 'app-calendar-mini-block',
  templateUrl: './calendar-mini-block.component.html',
  styleUrls: ['./calendar-mini-block.component.scss']
})
export class CalendarMiniBlockComponent {
  @Input() calendar: OpeningHourCalendarEntity;
}
