import { DataSource } from '@angular/cdk/table';
import {
  IDataSourceService, IDataSourceFilter, IDataSourceSort, ColumnTypes,
} from '@wephone-utils/datasource/datasource-types';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { SortDirection, MatSort } from '@angular/material/sort';
import { Deferred } from '../utils/defer';
import * as _ from 'lodash';

export interface IFlexDataSource<T> {
  data: any[];
  sort: MatSort;
  columnLoaded: boolean;
  isLoading: boolean;
  paginator: MatPaginator;
  total: number;

  dataChanges: Observable<any>;

  connect(collectionViewer: CollectionViewer): Observable<T[]>;
  disconnect(collectionViewer: CollectionViewer): void;
  setFilterString(str: string): void;
  load_columns(filters?: IDataSourceFilter): Promise<void>;
  delete_data(filters?: IDataSourceFilter): Promise<any>;
  export_csv(filters?: IDataSourceFilter): void;
  load(filters?: IDataSourceFilter): Promise<any>;
  setOrder(name: string, direction: SortDirection): void;
  reload(): Promise<any>;
  load_more(): Promise<any>;
  load_page(pageIndex: number): Promise<any>; // Only support with paginator
  setColumnConfig(columnCfg: any[]): void;
  onLoadItem(func:(raw: any) => void);
}

export interface IFlexDataSourceColumn {
  name: string;
  type?: ColumnTypes;
  uiWidget?: string;
  displayName: string;
  visible: boolean;
  searchable: boolean;
  enableSorting: boolean;
  sort?: {
    direction: 'asc' | 'desc'
  };
  position?: number;
  customSortFn?(o: any): string;
}

export type IFlexDataSourceColumnList = IFlexDataSourceColumn[];

export class FlexDataSource extends DataSource<any> implements IFlexDataSource<any> {
  private columns: IFlexDataSourceColumnList = [];
  private sortColumns: IFlexDataSourceColumnList = [];
  private _columnLoaded = false;
  private lineLoaded = 0;
  private totalLineCount: number;
  private filters: IDataSourceFilter;
  private readonly pageSize = 100;
  private dLoading: Deferred<boolean>;
  private _onLoadItem;

  data: any[];
  private readonly newItemSubject: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private readonly dataSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private readonly loadingStateSubject: Subject<boolean> = new Subject<boolean>();
  private readonly _columnChanges: BehaviorSubject<IFlexDataSourceColumnList> = new BehaviorSubject(undefined);

  constructor(
    private readonly dsService: IDataSourceService,
    public datasource_name: string,
  ) {
    super();
  }

  private _paginator: MatPaginator;
  get paginator(): MatPaginator {
    return this._paginator;
  }

  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
  }

  get dataChanges(): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  get newItemAware(): Observable<any> {
    return this.newItemSubject.asObservable();
  }

  get columnChanges(): Observable<IFlexDataSourceColumnList> {
    return this._columnChanges.asObservable();
  }

  get loadingStateChanges(): Observable<boolean> {
    return this.loadingStateSubject.asObservable();
  }

  get total(): number {
    return this.totalLineCount;
  }

  setData(data: any[]): any[] {
    this.data = this.customSortData(data);
    this.refreshData();

    return this.data;
  }

  onLoadItem(func): void {
    this._onLoadItem = func;
  }

  private customSortData(data: any[] = []): any[] {
    if (this.sort && this.sort.active) {
      const sortCustomCol = this.sortColumns.find(c => c.name === this.sort.active && !!c.customSortFn);
      if (sortCustomCol && this.sort.active === sortCustomCol.name) {
        data.sort((a: any, b: any) => {
          const v1 = sortCustomCol.customSortFn(a);
          const v2 = sortCustomCol.customSortFn(b);
          return (v1 < v2 ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
        });
      }
    }

    if (this._onLoadItem) {
      for (const a of data) {
        this._onLoadItem(a);
      }
    }

    return data;
  }

  notifyNewItem(data: any): void {
    this.newItemSubject.next(data);
  }

  refreshData(): void {
    this.dataSubject.next(this.data);
  }

  getSelectedEntity(id: number): any {
    return this.data ? this.data.find(x => x.id === id) : undefined;
  }

  private getIndexRow(row: any): number {
    return this.data ? this.data.indexOf(row) : undefined;
  }

  deleteRow(row: any): void {
    const idx: number = this.getIndexRow(row);
    if (idx >= 0) {
      this.data.splice(idx, 1);
      this.refreshData();
    }
  }

  private notifyLoadingState(isLoading: boolean): void {
    this.loadingStateSubject.next(isLoading);
  }

  setColumnConfig(columnCfg: any[]): void {
    if (!this.filters) {
      this.filters = {};
    }
    this.filters.columns = columnCfg;
    this._updateSortColumns(columnCfg);
  }

  private _updateSortColumns(columnCfg: any[]): void {
    for (const col of columnCfg) {
      if (col.searchable !== false) {
        const sortColumn = this.sortColumns.find(c => c.name === col.name);
        if (!sortColumn) {
          this.sortColumns.push(_.cloneDeep(col));
        } else {
          _.extend(sortColumn, col);
        }
      }
    }
  }

  setOrder(name: string, direction: SortDirection): void {
    const order: IDataSourceSort = {
      column: name,
      dir: direction,
    };
    if (!this.filters) {
      this.filters = {};
    }
    this.filters.order = [order];
  }

  get sort(): MatSort {
    const order: IDataSourceSort = this.filters && this.filters.order ? this.filters.order[0] : undefined;
    if (order) {
      return {
        active: order.column,
        direction: order.dir,
        start: order.dir,
      } as any as MatSort;
    }
  }

  setFilterString(str: string): void {
    if (!this.filters) {
      this.filters = {};
    }
    this.filters.search = { value: str };
  }

  connect(viewer: CollectionViewer): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(viewer: CollectionViewer): void {
  }

  async load_columns(filters?: IDataSourceFilter): Promise<void> {
    this.setFilter(filters);

    this._columnLoaded = false;
    const columns = await this.dsService.load_columns(this.datasource_name, this.filters);
    this.columns = _.sortBy(columns, 'position', 'asc');
    this.sortColumns = this.columns.filter(c => c.searchable !== false).map(c => c);
    this._columnChanges.next(this.columns);
    this._columnLoaded = true;
  }

  // private dataArrayToObject(dataArray: Array<any>): any {
  //   const ret: any = {};
  //   for (let i = 0; i < this.columns.length; i++) {
  //     ret[this.columns[i].name] = dataArray[i];
  //   }

  //   return ret;
  // }

  delete_data(filters?: IDataSourceFilter): Promise<any> {
    this.setFilter(filters || this.filters);
    return this.dsService.delete_data(this.datasource_name, this.filters);
  }

  export_csv(filters?: IDataSourceFilter): void {
    this.setFilter(filters || this.filters);
    this.dsService.export_csv(this.datasource_name, this.filters);
  }

  async reload(filters?: IDataSourceFilter): Promise<any> {
    this.notifyLoadingState(true);
    if (!_.isEmpty(filters)) {
      this.setFilter(filters);
    }
    this.setData(undefined);

    try {
      await this.reloadData();
    } catch (error) {
      console.error('Cannot reload', error);
      throw error;
    } finally {
      this.notifyLoadingState(false);
    }
  }

  async reloadData(): Promise<any> {
    this.lineLoaded = 0;
    const pageSize = this.paginator && this.paginator.pageSize || this.pageSize;
    const pageIndex = this.paginator && this.paginator.pageIndex || 0;
    const startLine = this.paginator && (pageSize * pageIndex) || this.lineLoaded;
    const res = await this.dsService.load_data(this.datasource_name, this.filters, this.columns, {
      start: startLine,
      length: pageSize
    });
    const newData = res.data;
    this.lineLoaded = this.paginator ? newData.length : this.lineLoaded + newData.length;
    this.totalLineCount = res.total ? res.total : this.lineLoaded;

    this.setData(newData);
    return this.data;
  }

  setFilter(filters?: IDataSourceFilter): void {
    if (!this.filters) {
      this.filters = {};
    }
    console.log('Datasource filters first', this.filters);
    const extractFilters = _.pick(this.filters, ['order', 'columns']);
    this.filters = _.merge(filters, extractFilters);
    console.log('Datasource filters last', this.filters);
  }

  getFilter(): any {
    return this.filters;
  }

  async load(filters?: IDataSourceFilter): Promise<any> {
    this.notifyLoadingState(true);
    this.setData(undefined);
    this.setFilter(filters);

    try {
      await this.load_columns(this.filters);
      await this.reloadData();
    } catch (e) {
      console.error('Cannot load', e);
      throw e;
    } finally {
      this.notifyLoadingState(false);
    }
  }

  get columnLoaded(): boolean {
    return this._columnLoaded;
  }

  get isLoading(): boolean {
    return !!this.dLoading;
  }

  async load_more(): Promise<boolean> {
    if (this.dLoading) {
      return this.dLoading.promise;
    }

    if (!this._columnLoaded) {
      return false;
    }
    if (this.totalLineCount !== undefined && this.lineLoaded >= this.totalLineCount) {
      // Nothing more to load
      return false;
    }

    this.dLoading = new Deferred<boolean>();
    this.notifyLoadingState(true);
    try {
      const res = await this.dsService.load_data(this.datasource_name, this.filters, this.columns, {
        start: this.lineLoaded,
        length: this.pageSize
      });
      const newData = res.data;
      this.lineLoaded += newData.length;
      this.setData(this.data ? this.data.concat(newData) : newData);
      this.dLoading.resolve(true);
      this.dLoading = undefined;
      return true;
    } catch (e) {
      console.error('Error while loading more data from data source', this.datasource_name);
      this.dLoading.reject(e);
      this.dLoading = undefined;
      return false;
    } finally {
      this.notifyLoadingState(false);
    }
  }

  // support for paginator
  async load_page(pageIndex: number): Promise<boolean> {
    if (!this.paginator) {
      console.warn('Only support for paginator');
      return false;
    }

    if (!this._columnLoaded) {
      return false;
    }

    this.notifyLoadingState(true);
    try {
      const pageSize = this.paginator.pageSize || this.pageSize;
      const startLine = this.paginator.pageSize * pageIndex || 0;
      if (startLine >= this.total) {
        // Nothing more to load
        return false;
      }

      const res = await this.dsService.load_data(this.datasource_name, this.filters, this.columns, {
        start: startLine,
        length: pageSize
      });
      const newData = res.data;
      if (!this.totalLineCount) {
        this.totalLineCount = res.total ? res.total : this.lineLoaded;
      }
      this.setData(newData);
      return true;
    } catch (e) {
      console.error(`Error while loading data for page ${pageIndex} from data source`, this.datasource_name);
      return false;
    } finally {
      this.notifyLoadingState(false);
    }
  }
}
