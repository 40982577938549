<ng-template #tplDataInvalid>
  {{'form.validator.data_invalid'|translate}}
</ng-template>

<div fxFill fxLayout="column" class="pa-1">
  <form [formGroup]="form">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'sms_template.content.name'| translate}}</mat-label>
        <input matInput placeholder="{{ 'sms_template.content.name' | translate }}" formControlName="name"
          data-ci="name">

        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'sms_template.content.sender_name'| translate}}</mat-label>
        <input matInput placeholder="{{ 'sms_template.content.sender_name' | translate }}" formControlName="sender_name"
          data-ci="sender-name">
          
        <mat-error [flexFormControlError]="form.get('sender_name')"></mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'sms_template.content.content'| translate}}</mat-label>
        <textarea matInput [maxlength]="hasLimitedSms() && smsContentInfo && (smsContentInfo.max+1) || smsMaxLength"
          cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"
          placeholder="{{ 'sms_template.content.content' | translate }}" formControlName="content" data-ci="content"></textarea>

        <mat-hint *ngIf="smsContentInfo" align="end">
          <span *ngIf="hasLimitedSms();else CountSMS">{{ smsContentInfo.length || 0 }}/{{ smsContentInfo.max }}</span>
          <ng-template #CountSMS>{{ 'sms.content.frag'|translate }} {{ smsContentInfo.frag }}</ng-template>
        </mat-hint>

        <mat-error [flexFormControlError]="form.get('content')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
