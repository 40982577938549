<div class="main-container">
    <div class="top-container">
        <ng-container *ngIf="!hasAnswer">
            <div class="monkey-container">
                <img src="./assets/images/chatbot/monkey-1.png" alt="monkey" title="monkey" class="monkey">
                <div class="response-container">
                    <p class="monkey-text"><span class="fontfamily-bold-italic">Hey ! <br> Je suis là pour t'aider.</span><br> <span class="fontfamily-italic">Pose-moi une question !</span></p>
                </div>
                <img src="./assets/images/chatbot/queue-monkey.png" alt="monkey" title="monkey" class="monkey-queue">
            </div>
            <div class="ml-2">
                <p class="mt-3 fontfamily-bold">By the way, here are examples of questions that I can answer ;)</p>
                <ul>
                    <li>Lorem ipsum dolor sit amet ?</li>
                    <li>Consectetur adipiscing elit ?</li>
                    <li>Aliquam fringilla enim at purus tristique molestie ?</li>
                    <li>Nunc blandit luctus nibh, quis dictum lacus ?</li>
                    <li>Phasellus condimentum interdum sodales ?</li>
                </ul>
            </div>
        </ng-container>

        <div class="monkey-container" [hidden]="!hasAnswer">
            <img src="./assets/images/chatbot/monkey-2.png" alt="monkey" title="monkey" class="monkey-1">
        </div>
        <div class="response-container" [hidden]="!hasAnswer">
            <ng-template flex-anchor></ng-template>
        </div>
    </div>
    
    <div class="input-container">
        <div class="textinput-container">
            <mat-form-field class="question-input round-border-input mat-input-sm grey-border" appearance="outline">
                <input matInput [(ngModel)]="textRequest" type="text"
                    placeholder="Type your question here or press the button above and let's talk">
                
                <button matSuffix mat-icon-button (click)="sendRequest()">
                    <mat-icon class="bt-send">
                        send
                    </mat-icon>
                </button>
            </mat-form-field>
            <speech-recognition-button class="speech-button"></speech-recognition-button>

        </div>
    </div>
</div>