import { Component, Input, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { IAgentListSort } from './agent-list.i';
import { FlexBaseComponent } from '@wephone-core-ui';
import { AgentStatusType } from '../agent-status-type';
import { EavesdropRequestRepository } from '@wephone-core/model/repository/eavesdrop_request';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { EavesdropRequestEntity } from '@wephone-core/model/entity/eavesdrop_request';
import { AuthenticationService } from '@wephone-core/service/authentication';
import * as _ from 'lodash';
import { EnterpriseParam } from '@wephone-core/system';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'agent-list',
  templateUrl: './agent-list.html',
  styleUrls: ['./agent-list.scss']
})
export class AgentListComponent extends FlexBaseComponent implements OnInit, OnDestroy {
  @Input() callDirection: string;
  @Input() filterGroupIds: number[];
  @Input() filterUngroupedAgents: boolean;
  @Input() agentFilters: any;
  @Input() agentSort: IAgentListSort;
  @Input() pauseAgentSort: IAgentListSort;

  agentStatusType = AgentStatusType;
  eavesdrop_list: EavesdropRequestEntity[] = [];
  message: string;
  ccLicense: number;

  constructor(
    private readonly em: EntityManager,
    private readonly authService: AuthenticationService,
    private readonly configManager: ConfigManager,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): any {
    super.ngOnInit();

    const defaultAgentFilters = {
      search_text: undefined,
      display_type: 'compact'
    };
    const defaultAgentSort = {
      sort_column: 'name',
      sort_dir: 1
    };

    if (!this.agentFilters) {
      this.agentFilters = defaultAgentFilters;
    }
    this.agentFilters.display_type = this.agentFilters.display_type || defaultAgentFilters.display_type;

    this.agentSort = _.extend({}, defaultAgentSort, this.agentSort);
    this.pauseAgentSort = _.extend({}, this.agentSort, this.pauseAgentSort);

    // if (!this.agentSort) {
    //   this.agentSort = defaultAgentSort;
    // }
    // if (!this.pauseAgentSort) {
    //   this.pauseAgentSort = this.agentSort;
    // }
    // this.agentSort.sort_column = this.agentSort.sort_column || defaultAgentSort.sort_column;
    // this.agentSort.sort_dir = this.agentSort.sort_dir || defaultAgentSort.sort_dir;

    this.callDirection = this.callDirection || 'both';

    const eavesdropRepo = this.em.getRepository<EavesdropRequestRepository>('EavesdropRequestRepository');
    this.eavesdrop_list = eavesdropRepo.getObjectList();
    const eavesdropSource = eavesdropRepo.dataSource<EavesdropRequestEntity>();
    this.addSubscription(eavesdropSource.subscribe(val => {
      this.eavesdrop_list = eavesdropRepo.getObjectList();
    }));

    this.ccLicense = this.configManager.getEnterpriseParamValue(EnterpriseParam.cc_license);
  }

  get shouldDisplayEavesdrop(): boolean {
    // if (!(this.authService.isAdmin() || this.authService.isSupervisor() || this.authService.isWatcher())) {
    //   return false;
    // }
    const found = this.eavesdrop_list.find(e => e.is_active && e.eavesdropper_user_id === this.authService.getUserId() && e.target_user?.agent?.is_online_cc);
    return !!found;
  }

  get agent_display_type(): 'compact' | 'queue' | 'group' {
    return this.agentFilters.display_type || 'compact';
  }

  get search_text(): string {
    return this.agentFilters.search_text;
  }

  onTotalAvailableAgentUpdated($event: {
    total: number
  }): void {
    const total: number = $event && $event.total || 0;

    this.message = null;
    if (this.ccLicense && total >= this.ccLicense) {
      this.message = _ti('livecall.message.cc_license_reached');
    }
  }
}
