import { Injectable } from '@angular/core';
import { SingletonBase } from '../utils/singleton';

export class TreeNodeItem {
  id: number;
  parent_id?: number;
  checkbox: boolean;
  checked?: boolean;
  children?: TreeNodeItem[];
}
@Injectable()
export class TreeHelper extends SingletonBase {

  static listToTree(list: any[]): TreeNodeItem[] {
    const map: {[key: number]: any} = {};
    let node;
    const roots = [];

    for (const item of list) {
      map[item.id] = item;
      item.children = [];
    }

    for (const item of list) {
      node = item;
      node.checkbox = true;
      if (node.parent_id) {
        if (!map[node.parent_id]) {
          console.error('No node with id ' + node.parent_id);
          throw new Error(`No node with id ${node.parent_id}`);
        }
        map[node.parent_id].children.push(node);
      } else {
        roots.push(node);
      }
    }

    return roots;
  }
}
