import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { CalendarInputParams, DialogActionButton } from '@wephone-utils';
import { DidEntity, DidType } from '@wephone-core/model/entity/did';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import * as _ from 'lodash';
import { NumberRoutingEditBaseComponent } from '../number-routing-edit-base';
import { _tk, _ti } from '@wephone-translation';
import { UpdateNumberDescriptionModalComponent } from '@wephone/modals/did/update-number-description-modal/update-number-description-modal.component';
import { RoutingAppName, IRoutingApp, IRoutingAppDestinationData } from '@wephone-core/routing-app/routing-app.interface';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UserActionLogComponent } from '@wephone/modals/user-action-log/user-action-log.component';
import { IvrMenuStatisticsComponent } from '@wephone/modals/ivr-menu/ivr-menu-statistics/ivr-menu-statistics.component';
import { DidSetupWizardComponent } from '@wephone/components/did/did-setup-wizard/did-setup-wizard.component';
import { DidRepository } from '@wephone-core/model/repository/did';
// import { TimeDependentRoutingComponent } from '@wephone/components/did/time-dependent-routing/time-dependent-routing.component';
import { DidConfigWizardModalComponent, IDidConfigWizardReturnData } from '@wephone/modals/did/did-config-wizard-modal/did-config-wizard-modal.component';
import { CallDestinationComponent } from '@wephone/components/call-destination/call-destination.component';
import { NumberOpeningCalendar } from '@wephone/components/number-opening-calendar/number-opening-calendar.component';
import { UserEntity } from '@wephone-core/model/entity/user';
import { Validators } from '@angular/forms';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { RoutingServiceEntity } from '@wephone-core/model/entity/routing_service';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';

@Component({
  selector: 'app-number-routing-edit',
  templateUrl: './number-routing-edit.html',
  styleUrls: ['./number-routing-edit.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberRoutingEditComponent extends NumberRoutingEditBaseComponent implements OnInit, OnDestroy {
  @Input() editingItem: DidEntity;
  @ViewChild('callDestination') callDestination: CallDestinationComponent;
  @ViewChild('numberOpeningCalendar') numberOpeningCalendar: NumberOpeningCalendar;
  @ViewChild('didSetupWizard') didSetupWizard: DidSetupWizardComponent;
  // @ViewChild('timeDependentRouting') timeDependentRouting: TimeDependentRoutingComponent;

  didPreview: DidEntity;
  actions: DialogActionButton[] = [
    {
      id: 'clear_did',
      label: _tk('did.content.clear_did'),
      action: () => {
        console.log('action clicked');
        this.clearDid();
      }
    },
    {
      id: 'update_destination',
      label: _tk('did.content.update_number_description'),
      action: () => {
        console.log('action rename did clicked');
        this.updateNumberDestination();
      }
    },
    {
      id: 'view_stats',
      label: _tk('ivr_menu.content.view_statistics'),
      action: () => {
        console.log('action ivrmenu view statistics clicked');
        this.showIvrStatistics();
      },
      visible: () => {
        return this.did && this.did.linked_object_type === DidEntity.LINK_TYPE_IVR;
      }
    },
    {
      id: 'view_history',
      label: _tk('call_queue.content.tab_history'),
      action: () => {
        console.log('action history did clicked');
        this.showNumberUserActionLog();
      }
    }
  ];
  steps: any = {};

  updateLayout = false;
  showCalendar = false;
  hasCrmAuthenticated = false;
  hasRoutingService = false;

  async ngOnInit(): Promise<void> {
    this.setDid(this.editingItem);
    // this.initForm();
    await super.ngOnInit();
    // this.cdr.markForCheck(); // Fix bug don't show number information data
    this.hasCrmAuthenticated = !!this.em.getRepository('EnterpriseCrmRepository').getObjectList().find(crm => crm.is_authenticated);
    this.hasRoutingService = !!this.em.getRepository('RoutingServiceRepository').getObjectList().length;
  }

  get opening_calendar(): OpeningHourCalendarEntity {
    return this.did.opening_calendar;
  }

  protected initForm(): void {
    this.form = this.fb.group({
      action_office_hour: [this.getActionOfficeHour()],
      master_did: [this.did.master_did],
      inbound_sms_emails: [this.did.inbound_sms_emails],
      is_hidden: [!!this.did.is_hidden],
      name: [this.did.name, Validators.compose([Validators.required, Validators.maxLength(100)])],
      welcome_message: [this.did.welcome_message, [Validators.maxLength(512)]],
      crm: [this.did.enterprise_crm],
      routing_service: [this.did.routing_service],
      group: [this.did.group],
    });

    this.addSubscription(this.form.valueChanges.subscribe(val => {
      this.onFormValueChange();
    }));
  }

  getActionOfficeHour(): IRoutingAppDestinationData {
    return this.did && !_.isEmpty(this.did.action_office_hour)
      ? _.cloneDeep(this.did.action_office_hour) : new IRoutingAppDestinationData();
  }

  actionOfficeHourOnChange(actionOfficeHour: IRoutingAppDestinationData): void {
    this.setFormControlValue('action_office_hour', actionOfficeHour);
  }

  showSmsEmails(): boolean {
    return this.did && this.did.did_type === DidType.mobile;
  }

  validateForm(): boolean {
    // if (!this.callDestination.validParam()
    // ) {
    //   this.form.get('action_office_hour').setErrors({invalid: true});
    //   this.form.get('action_office_hour').markAsTouched();
    //   return false;
    // }

    if (
      this.showSmsEmails() &&
      !_.isEmpty(this.form.get('inbound_sms_emails').value) &&
      !this.validatorService.isValidEmail(this.form.get('inbound_sms_emails').value, ',')
    ) {
      this.form.get('inbound_sms_emails').setErrors({ incorrect: true });
    }

    if (this.numberOpeningCalendar && !this.numberOpeningCalendar.validateForm()) {
      return false;
    }

    if (!this.MULTI_EDITOR) {
      const existName: DidEntity = this.didRepo.getDidByName(this.form.get('name').value, this.did.id);
      if (existName) {
        this.form.get('name').setErrors({ name_exist: true });
        return false;
      }
    }

    return this.formIsValid();
  }

  formHasChanged(): boolean {
    return super.formHasChanged() ||
      this.numberOpeningCalendar && this.numberOpeningCalendar.formHasChanged()
      // || this.timeDependentRouting.formHasChanged()
      ;
  }

  numberOpeningCalendarChange(): any {
    // const value = this.numberOpeningCalendar.getChangeSet();
    // this.hasCalendar = this.numberOpeningCalendar.openingCalendarControl && !!this.numberOpeningCalendar.openingCalendarControl.value;
    this.onFormValueChange();
  }

  get hasCalendar(): boolean {
    return this.numberOpeningCalendar && this.numberOpeningCalendar.openingCalendarControl && !!this.numberOpeningCalendar.openingCalendarControl.value;
  }

  protected setFormControlValue(formControlName: string, value: any): void {
    this.form.get(formControlName).markAsDirty();
    this.form.get(formControlName).setValue(value);
  }

  protected getNumberOpeningCalendarValue(): CalendarInputParams {
    const numberOpeningCalendarValue = this.numberOpeningCalendar && this.numberOpeningCalendar.getChangeSet();

    if (_.isEmpty(numberOpeningCalendarValue)) {
      return;
    }

    if (numberOpeningCalendarValue.calendar_reroute && !this.formService.isValidCallDestination(numberOpeningCalendarValue.action_not_office_hour)) {
      throw new Error(_ti('form.validator.data_invalid'));
    }

    return this.formService.makeNumberOpeningCalendarValue(numberOpeningCalendarValue);
  }

  setDid(item?: DidEntity): void {
    super.setDid(item);
    // this.setDependentCalendars(this.did.dependent_calendars);
    this.setDidPreview();
  }

  protected setDisplayedCalendar(): void {
    const did: DidEntity = this.didPreview;
    this.showCalendar = !did.hasLinkedUser() && !did.master_did_id;
    // this.hasCalendar = !!this.did.opening_calendar;
    if (!this.showCalendar) {
      this.steps = {
        _2default_routing: 'crm_routing.content.dest_default',
        _3number_information: 'did.content.did_info',
      };
    } else {
      this.steps = {
        _1calendar: 'call_queue_edit.nav.calendar',
        _2default_routing: 'crm_routing.content.dest_default',
        _3number_information: 'did.content.did_info',
      };

      if (this.MULTI_EDITOR) {
        this.steps._3general_info = 'did.content.did_info';
      }
    }

    if (this.showSmsEmails()) {
      this.steps._4inbound_sms = 'did.content.inbound_sms';
    }

  }

  protected setDidPreview(): void {
    this.didPreview = _.cloneDeep(this.did);
    this.setDisplayedCalendar();
    this.updateDisplayedRouting();
  }

  // protected setDependentCalendars(calendars: OpeningHourCalendarEntity[]): void {
  //   this.dependentCalendars = _.cloneDeep(calendars);
  // }

  get stepKeys(): string[] {
    return Object.keys(this.steps).sort();
  }

  protected getFormData(did: DidEntity): Object {
    return {
      // useEnterpriseCalendar: did.use_enterprise_calendar,
      // actionOfficeHour: did.action_office_hour,
      // actionNotOfficeHour: did.action_not_office_hour,
      // sameRouting: did.same_routing,
      // calendars: did.dependent_calendars,
      // language: did.language,
      action_office_hour: did.action_office_hour,
      name: did.name,
      welcome_message: did.welcome_message,
      is_hidden: !!did.is_hidden,
      master_did: did.master_did,
      crm: did.enterprise_crm,
      routing_service: did.routing_service,
      inbound_sms_emails: did.inbound_sms_emails,
      group: did.group,
    };
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    if (this.numberOpeningCalendar) {
      this.numberOpeningCalendar.resetForm(options);
    }

    // this.setDependentCalendars(this.did.dependent_calendars);
    this.setDidPreview();
    this.form.reset(this.getFormData(this.did), options);
    // this.form.markAsPristine();
    // this.formValueChanges.next(false);
    this.pristineForm();
  }

  protected pristineForm(): void {
    // if (this.numberOpeningCalendar) {
    // this.numberOpeningCalendar.pristineForm();
    // }
    this.form.markAsPristine();
    this.formValueChanges.next(false);
    this.cdr.markForCheck();
  }

  // Actions form
  clearDid(): void {
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('dialogs.routing.reset_confirm_msg'),
      async () => {
        try {
          await this.didRepo.clear([this.did]);
          this.did.name = undefined;
          this.did.clearRoutingData();
          if (this.didSetupWizard && this.didSetupWizard.did) {
            this.didSetupWizard.did.name = undefined;
            this.didSetupWizard.stepActive = 1;
            this.didSetupWizard.didNameControl.setValue(undefined);
          }
          this.resetForm();
          this.cdr.markForCheck();
          this.cdr.detectChanges();
          this.toast.showSuccess(_ti('did.message.reset_success'));
        } catch (error) {
          const msg = error && error.message ? error.message : _ti('public.message.update_failure');
          this.toast.showError(msg);
        }
      }
    );
  }

  updateNumberDestination(): void {
    this.dialogService.openSideDialog(UpdateNumberDescriptionModalComponent, { data: { id: this.did.id }, size: 's' }, ret => {
      console.log('updateNumberDestination ret', ret);
      this.refreshDid();
    });
  }

  private refreshDid(): void {
    const did: DidEntity = this.em.getRepository('DidRepository').getObjectById(this.did.id);
    this.setDid(did);
    this.resetForm();
  }

  showNumberUserActionLog(): void {
    this.dialogService.openDialog2(UserActionLogComponent, { data: { did: this.did } }, ret => {
      console.log('UserActionLogComponent ret', ret);
    });
  }

  validate(): void {
    // super.validate();

    if (!this.validateForm()) {
      throw new Error(_ti('form.validator.data_invalid'));
    }
  }

  getChangeSet(): {
    action_office_hour?: any;
    master_did_id?: number;
    name?: string,
    welcome_message?: string,
    crm_id?: number;
    is_hidden?: number;
    routing_service_id?: number;
    inbound_sms_emails?: string;
    group_id?: number;
    linked_object_id?: number;
    linked_object_type?: number;
  } {
    const changeset: {
      action_office_hour: any;
      master_did: DidEntity;
      name: string,
      welcome_message: string,
      crm: EnterpriseCrmEntity;
      routing_service: RoutingServiceEntity;
      is_hidden: boolean;
      inbound_sms_emails: string;
      group?: GroupEntity | UserGroupEntity;
      routed_siptrunk?: SipTrunkEntity;
    } = super.getChangeSet() || {};

    const data: {
      action_office_hour?: any;
      master_did_id?: number;
      name?: string,
      welcome_message?: string,
      crm_id?: number;
      routing_service_id?: number;
      is_hidden?: number;
      inbound_sms_emails?: string;
      group_id?: number;
      linked_object_id?: number;
      linked_object_type?: number;
    } = {};

    if (changeset.hasOwnProperty('name')) {
      data.name = changeset.name || null;
    }

    if (changeset.hasOwnProperty('welcome_message')) {
      data.welcome_message = changeset.welcome_message || null;
    }

    if (changeset.hasOwnProperty('action_office_hour')) {
      data.action_office_hour = changeset.action_office_hour || null;
    }

    if (changeset.hasOwnProperty('master_did')) {
      data.master_did_id = changeset.master_did && changeset.master_did.id || null;
    }

    if (changeset.hasOwnProperty('crm')) {
      data.crm_id = changeset.crm && changeset.crm.id || null;
    }

    if (changeset.hasOwnProperty('routing_service')) {
      data.routing_service_id = changeset.routing_service && changeset.routing_service.id || null;
    }

    if (changeset.hasOwnProperty('is_hidden')) {
      data.is_hidden = changeset.is_hidden ? 1 : 0;
    }

    if (changeset.hasOwnProperty('inbound_sms_emails')) {
      data.inbound_sms_emails = changeset.inbound_sms_emails;
    }

    if (changeset.hasOwnProperty('group')) {
      data.group_id = changeset.group && changeset.group.id || null;
    }

    if (changeset.hasOwnProperty('routed_siptrunk')) {
      data.linked_object_id = changeset.routed_siptrunk && changeset.routed_siptrunk.id || null;
      data.linked_object_type = changeset.routed_siptrunk && DidEntity.LINK_TYPE_SIP_TRUNK || null;
    }

    return data;
  }

  async submitForm(): Promise<DidEntity> {
    try {
      this.validate();

      const updatedData: any = this.getChangeSet();

      // Update other fields
      const newNumberOpeningCalendarValue = await this.getNumberOpeningCalendarValue();

      // Update default did
      if (newNumberOpeningCalendarValue) {
        _.extend(updatedData, newNumberOpeningCalendarValue);
      }

      if (!Object.keys(updatedData).length) {
        console.error('No updated data');
        return;
      }

      await this.em.getRepository<DidRepository>('DidRepository')
        .saveAttrs(this.did, Object.keys(updatedData), updatedData);

      this.toast.showSuccess(_ti('public.message.update_success'));

      this.refreshDid();

      this.pristineForm();
      this.updateDisplayedRouting();
      return this.did;
    } catch (error) {
      console.error('Update error', error);

      this.form.markAllAsTouched();
      this.cdr.markForCheck();

      const msg = error && error.message || _ti('public.message.update_failure');
      this.toast.showError(msg);
    }
  }

  protected updateDisplayedRouting(): void {
    this.updateLayout = !this.updateLayout;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  updateConfigDid(value): void {
    if (value && value.name) {
      this.did.name = value.name;
    }

    if (value && value.id) {
      const did = this.em.getRepository<DidRepository>('DidRepository').getObjectById(value.id);
      this.setDid(did);
      this.resetForm();
    }
  }

  onFormValueChange(): void {
    super.onFormValueChange();
    this.cdr.markForCheck();
  }

  gotoEditDidPage(destinationDid: DidEntity): void {
    this.router.navigateByUrl(`number-routing/${destinationDid.id}?id=${destinationDid.id}`);
  }

  gotoEditUserPage(user: UserEntity): void {
    this.router.navigateByUrl(`manage-users/users/${user.id}`);
  }

  isDestType(dest_type: string): boolean {
    if (dest_type === 'alias') {
      return true;
    }
    if (!RoutingAppName[dest_type]) {
      console.error('Unknown destination type', dest_type);
      return;
    }
    return this.did.isDestType(dest_type as RoutingAppName);
  }

  getDestinationQueue(): CallQueueEntity {
    // if (this.did.hasLinkedUser()) {
    //   return this.getUserDedicatedQueue();
    // }

    return this.did.getDestinationObject();
  }

  // protected getUserDedicatedQueue(): CallQueueEntity {
  //   if (!this.did.hasLinkedUser()) {
  //     return;
  //   }

  //   const queueDedicatedList: CallQueueEntity[] = this.em.getRepository('CallQueueRepository').getObjectList() || [];

  //   return queueDedicatedList.find((q: CallQueueEntity) => q.dedicated_user_id === this.did.linked_object_id);

  // }

  showIvrStatistics(): void {
    const ivrmenu = this.did.getDestinationObject();
    this.dialogService.openDialog2(IvrMenuStatisticsComponent, { data: { ivrmenu } }, ret => {
      console.log('IvrMenuStatisticsComponent ret', ret);
    });
  }

  canUseCallCenter(): boolean {
    return !!(this.did.hasLinkedUser() && this.did.routed_user && this.did.routed_user.can_use_callcenter);
  }

  // Currently not in used
  // addDependentRouting(): void {
  //   const calendarName = `Time Based Routing for did ${this.did.display_number}`;

  //   let calendarExist = false;
  //   let calendarNameNew = calendarName;
  //   let count = 1;
  //   while (true) {
  //     calendarExist = false;
  //     for (const calendar of this.calendarRepo.getObjectList()) {
  //       if (calendar.name === calendarNameNew) {
  //         calendarNameNew = `${calendarName} ${count}`;
  //         calendarExist = true;
  //       }
  //     }
  //     if (!calendarExist) {
  //       break;
  //     }
  //     count++;
  //   }

  //   const calendarData = {
  //     name: calendarNameNew,
  //     link_object_type: LinkedObjectType.LINK_OBJECT_DID,
  //     link_object_id: this.did.id,
  //     is_protected: 1
  //   };
  //   const openingHourData = { week_day: [1, 2, 3, 4, 5, 6, 0], start_time: '09:00', end_time: '17:00' };

  //   const newOpeningHour = this.em.getRepository<OpeningHourRepository>('OpeningHourRepository').create(openingHourData) as OpeningHourEntity;
  //   const newCalendar = this.calendarRepo.create(calendarData) as OpeningHourCalendarEntity;
  //   newCalendar.addOpeningHour(newOpeningHour);

  //   this.dialogConfigDestination(newCalendar);
  // }

  // protected dialogConfigDestination(calendar: OpeningHourCalendarEntity): void {
  //   const data = {
  //     call_destination: calendar.getRoutingApp() && calendar.getRoutingApp().getAsJsonObject() || {},
  //     dependent_calendar: calendar
  //   };
  //   this.dialogService.openSideDialog(DidConfigWizardModalComponent, { data }, async (res: {app?: IRoutingApp, masterDid?: DidEntity}) => {
  //     const app: IRoutingApp = res && res.app;

  //     if (!app) {
  //       console.warn('Routing data not found');
  //       return;
  //     }

  //     const calendarData = app.getAsJsonObject();
  //     calendar.setRoutingApp(calendarData);

  //     const extraData = {
  //       openinghours: calendar.openinghours.map(i => i.dumpObjectData()),
  //       data: calendar.data
  //     };

  //     try {
  //       const newCalendar = await this.calendarRepo.createAndSave(calendar.dumpObjectData(), extraData);
  //       if (newCalendar) {
  //         this.toast.showSuccess(_ti('public.message.create_success'));
  //         this.setDependentCalendars(this.did.dependent_calendars);
  //         this.cdr.markForCheck();
  //       }
  //     } catch (e) {
  //       const msg = e && e.message || _ti('public.message.create_failure');
  //       this.toast.showError(msg);
  //     }
  //   });
  // }

  setOtherDestination(): void {
    const data = {
      // call_destination: this.getActionOfficeHour(),
      did: this.didPreview,
      full_option: true
    };
    this.dialogService.openSideDialog(DidConfigWizardModalComponent, { data }, (res: IDidConfigWizardReturnData) => {
      if (!res) {
        console.warn('No response');
        return;
      }
      const app: IRoutingApp = res && res.app;

      if (res.masterDid) {
        this.setFormControlValue('master_did', res.masterDid);
        this.didPreview.master_did = res.masterDid;
        this.didPreview.action_office_hour = null;
        this.didPreview.action_not_office_hour = null;
      } else if (app) {
        const routeData = app.getAsJsonObject();
        this.setFormControlValue('action_office_hour', routeData);
        this.didPreview.action_office_hour = routeData;
        this.didPreview.action_not_office_hour = routeData;
        this.didPreview.master_did = null;
      } else {
        console.warn('Application not found');
      }

      this.setDisplayedCalendar();

      // if (!app) {
      //   console.warn('Application not found');
      //   return;
      // }
      this.updateDisplayedRouting();
    });
  }

  hasFeature(feature: string): boolean {
    return ConfigManager.getInstance().hasFeature(feature);
  }

  hasControlValue(controlName: string): boolean {
    const controlValue = this.form.get(controlName).value;
    return controlName && controlValue ? true : false;
  }

  removeControlValue(controlName: string): void {
    this.setFormControlValue(controlName, null);
  }
}
