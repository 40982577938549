import { FormControl } from '@angular/forms';
import { ValidatorService } from '@wephone-utils/services/validator.service';
import * as _ from 'lodash';

export const UrlValidator = (control: FormControl) => {
  if (control.value && !ValidatorService.VALID_URL_PATTERN.test(control.value)) {
    return { invalid_ip: true };
  }
  return null;
};
