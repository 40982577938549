import * as _ from 'lodash';
import * as localforage from 'localforage';
import { Injectable, Inject } from '@angular/core';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { ElectronService } from 'ngx-electron';
import { SingletonBase } from '@wephone-utils/utils/singleton';

@Injectable()
export class StorageService extends SingletonBase {

  private electronSettings: any;
  private keytar: any;
  private domainInstances = {};
  private defaultLFInstance;

  constructor(electron: ElectronService) {
    super();
    if (electron.isElectronApp) {
      const remoteRequest = window['EREMOTE'].require;
      this.electronSettings = remoteRequest('electron-settings');
      this.keytar = remoteRequest('keytar');
    }
  }

  getDomainConfig<T>(key: string, domain?: string): any {
    const d = domain || FlexIvrSettings.getInstance().enterprise_domain;
    return this.getData<T>(key, d, true);
  }

  setDomainConfig<T>(key: string, val: any, domain?: string): Promise<T> {
    const d = domain || FlexIvrSettings.getInstance().enterprise_domain;
    return this.setData<T>(key, val, d, true);
  }

  getDomainTempData<T>(key: string, domain?: string): any {
    const d = domain || FlexIvrSettings.getInstance().enterprise_domain;
    return this.getData<T>(key, d, false);
  }

  setDomainTempData<T>(key: string, val: any, domain?: string): Promise<T> {
    const d = domain || FlexIvrSettings.getInstance().enterprise_domain;
    return this.setData<T>(key, val, d, false);
  }

  private getLocalForageInstance(domain?: string): LocalForage {
    if (!domain) {
      if (!this.defaultLFInstance) {
        this.defaultLFInstance = localforage.createInstance({ name: '.global' });
      }
      return this.defaultLFInstance;
    } else {
      if (domain in this.domainInstances) {
        return this.domainInstances[domain];
      } else {
        this.domainInstances[domain] = localforage.createInstance({ name: domain });
        return this.domainInstances[domain];
      }
    }
  }

  getAppConfig<T>(key: string): Promise<any> {
    return this.getData<T>(key);
  }

  setAppConfig<T>(key: string, val: any): Promise<any> {
    return this.setData<T>(key, val);
  }

  private getData<T = any>(key: string, domain?: string, isConfig: boolean = true): Promise<T> {
    if (this.electronSettings && isConfig) {
      if (domain) {
        return Promise.resolve(this.electronSettings.get('domains.' + domain + '.' + key));
      } else {
        return Promise.resolve(this.electronSettings.get(key));
      }
    } else {
      const s = this.getLocalForageInstance(domain);
      return s.getItem<T>(key);
    }
  }

  private setData<T = void>(key: string, val: any, domain?: string, isConfig: boolean = true): Promise<T> {
    if (this.electronSettings && isConfig) {
      // val parameter could be a remote object from main process
      // For that case, we use _.extend to make a copy of it into a local object so that Electron Settings can save new attributes correctly
      // Otherwise only existing attributes of the remote object will be updated
      let newVal = val;
      if (typeof newVal === 'object' && newVal !== null) {
        newVal = _.extend({}, val);
      }
      if (domain) {
        return Promise.resolve(this.electronSettings.set('domains.' + domain + '.' + key, newVal));
      } else {
        return Promise.resolve(this.electronSettings.set(key, newVal));
      }
    } else {
      const s = this.getLocalForageInstance(domain);
      return s.setItem<T>(key, val);
    }
  }

  getPassword(account: string, domain: string): string {
    let key = account;
    if (domain) {
      key = key + `@${domain}`;
    }
    if (this.keytar) {
      return this.keytar.getPassword('Graam', key);
    }
  }

  savePassword(account: string, domain: string, password: string): string {
    let key = account;
    if (domain) {
      key = key + `@${domain}`;
    }
    if (this.keytar) {
      return this.keytar.setPassword('Graam', key, password);
    }
  }

  deletePassword(account: string, domain: string, password: string): string {
    let key = account;
    if (domain) {
      key = key + `@${domain}`;
    }
    if (this.keytar) {
      return this.keytar.deletePassword('Graam', key, password);
    }
  }
}
