<form [formGroup]="form" fxLayout="column">
  <!-- <div class="mb-1">
    <mat-form-field class="full-width">
      <mat-label>{{ 'call_queue.content.default_calling_number'|translate }}</mat-label>
      <did-select-form-input formControlName="defaultDid" [allowAnonymous]="true" [allowEmpty]="true">
      </did-select-form-input>
      <mat-hint>{{ 'did.title.calling_number_hint'|translate }}</mat-hint>
    </mat-form-field>
  </div> -->

  <!-- <h3 class="text-xl py-1 mb-0 font-weight-bold">{{ 'call_queue.content.number_routed_to_queue'|translate }}</h3> -->
  <queue-did-list-form-input formControlName="did_list" [notInUsed]="true" [queue]="queue">
  </queue-did-list-form-input>
</form>
