import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { LocalManager } from '@wephone-core/service/local_manager';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { SoundService } from '../../service/sound.service';

/**
 * Generated class for the FileentryPlayerButtonComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'fileentry-player-button',
  templateUrl: './fileentry-player-button.html',
  styleUrls: ['./fileentry-player-button.scss']
})
export class FileentryPlayerButtonComponent implements OnInit {
  @Input() filePublicId: string;
  @Input() playerId: string;
  @Input() lang: string;
  @Input() topPlayerVisible: boolean;
  @Input() disabled: boolean;
  @Input() color = 'primary';
  @Input() border = false;

  @Output() readonly onPlay = new EventEmitter();

  is_playing = false;
  language: string;

  constructor(
    private readonly soundService: SoundService,
    private readonly localeService: LocalManager,
  ) { }

  ngOnInit(): void {
    this.language = this.lang;
    const fileEntry = EntityManager.getRepository<FileEntryRepository>('FileEntryRepository').getObjectByPublicId(this.filePublicId);
    if (fileEntry && fileEntry.is_default) {
      this.language = this.localeService.getLangForDefaultFileEntry();
    }
    const playing_file_entry = this.soundService.getPlayingFileEntry();

    if (
      playing_file_entry &&
      playing_file_entry.file_public_id &&
      playing_file_entry.file_public_id === this.filePublicId
    ) {
      this.markAsPlay();
    } else {
      this.markAsStop();
    }
  }

  play($event): void {
    $event.stopPropagation();

    this.soundService.hideTopPlayer();
    if (this.topPlayerVisible !== false) {
      this.soundService.showTopPlayer();
    }

    this.soundService.playFileEntry(this.filePublicId, this.language, (e: any) => {
      this.markAsStop();
    });
    this.markAsPlay();
  }

  private markAsPlay(): void {
    this.is_playing = true;
    this.onPlay.emit();
  }

  private markAsStop(): void {
    this.is_playing = false;
  }

  stop($event): void {
    $event.stopPropagation();
    this.soundService.stopPlayingFileEntry(this.filePublicId, this.lang);
    this.markAsStop();
  }
}
