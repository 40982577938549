<div class="flex-snack-bar-container full-width">
  <div class="title" *ngIf="data.title" (click)="hasAction && snackBarRef.dismissWithAction()">{{ data.title }}</div>
  <div class="message" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
    <div fxFlex="calc(100%-50px)" (click)="hasAction && snackBarRef.dismissWithAction()">{{ data.message }}</div>
    <div fxFlex="40px" class="mat-simple-snackbar-action">
      <!-- <button mat-button (click)="snackBarRef.dismissWithAction()" *ngIf="hasAction">{{ data.action }}</button> -->
      <button mat-icon-button class="btn-close" (click)="snackBarRef.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
