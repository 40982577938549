import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ViewChild, Input, ElementRef, Optional, ChangeDetectorRef, OnChanges } from '@angular/core';
import { TreeComponent } from '@circlon/angular-tree-component';
import { FormControl, NgControl } from '@angular/forms';
import { FlexMatInputWrapper } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';

@Component({
  selector: 'flex-tree-input',
  templateUrl: './flex-tree-input.html',
  styleUrls: ['./flex-tree-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexTreeInput }]
})
export class FlexTreeInput extends FlexMatInputWrapper implements OnInit, AfterViewInit, MatFormFieldControl<any> {
  @Input() nodes: FlexSelectTreeNode[];
  @Input() showFilter: boolean;
  @Input() multiple: boolean;
  @Input() independent: boolean;
  @Input() showCheckAll: boolean;
  @Input() expandAll: boolean;
  @ViewChild('tree') tree: TreeComponent;

  treeNodes: FlexSelectTreeNode[] = [];
  treeNodeList: FlexSelectTreeNode[] = [];
  filterValue = new FormControl();
  myControl = new FormControl();

  checkall = false;

  constructor(
    elRef: ElementRef,
    @Optional() ngControl: NgControl,
    protected cdr?: ChangeDetectorRef
  ) {
    super(ngControl, elRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.addSubscription(
      this.filterValue
        .valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged()
        )
        .subscribe(value => {
          const filterValue = (value || '').trim();
          this.tree.treeModel.filterNodes(filterValue);
        })
    );

    this.initialize();
  }

  ngAfterViewInit(): void {
    if (this.expandAll) {
      this.tree.treeModel.expandAll();
    }
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get controlType(): string {
    return 'flex-tree-input';
  }

  get empty(): boolean {
    if (this.multiple && Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  writeValue(val): void {
    this.wrappedControl.setValue(val);

    setTimeout(() => {
      this.updateCheckAllStatus();
      this.updateSelectedNode(val);
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
  }

  private initialize(): void {
    if (this.nodes) {
      this.treeNodes = _.cloneDeep(this.nodes);
    }

    this.treeNodeList = this.getNestedNode(this.treeNodes);
    this.updateCheckAllStatus();
  }

  private updateSelectedNode(value): void {
    let selectedIds = [];
    if (value || value === 0) {
      selectedIds = this.multiple ? value : [value];
    }

    this.treeNodeList.map((node: any) => {
      if (_.includes(selectedIds, node.id)) {
        node.checked = true;
      }
    });
  }

  private updateCheckAllStatus(): void {
    if (this.multiple && this.showCheckAll) {
      const nodeIds = this.treeNodeList.map((node: any) => node.id);
      const selectedIds = this.wrappedControl.value || [];

      this.checkall = selectedIds.length === nodeIds.length;
    }
  }

  getNestedNode(nodes, list = []): FlexSelectTreeNode[] {
    for (const node of nodes) {
      list.push(node);

      if (node.children) {
        this.getNestedNode(node.children, list);
      }
    }

    return list;
  }

  onChangeCheckboxNode(node, checked): void {
    this.check(node, checked);

    this.updateCheckAllStatus();
  }

  check(node, checked): void {
    if (this.multiple) {
      this.multipleSelect(node, checked);
    } else {
      this.singleSelect(node, checked);
    }

    const selectedNodes = this.getSelectedNodes();
    const selected = this.multiple ? selectedNodes.map(n => n.id) : (selectedNodes[0] ? selectedNodes[0].id : undefined);
    this.wrappedControl.setValue(selected);
  }

  private singleSelect(node, checked): void {
    this.treeNodeList.forEach((n: any) => n.checked = false);

    if (this.check) {
      node.data.checked = checked;
    }
  }

  private multipleSelect(node, checked): void {
    node.data.indeterminate = false;

    this.updateChildNodeCheckbox(node, checked);
    this.updateParentNodeCheckbox(node.realParent);
  }

  private updateChildNodeCheckbox(node, checked): void {
    node.data.checked = checked;
    node.data.indeterminate = false;

    if (node.children && !this.independent) {
      node.children.forEach(child => this.updateChildNodeCheckbox(child, checked));
    }
  }

  private updateParentNodeCheckbox(node): void {
    if (!node) {
      return;
    }

    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (!child.data.checked || child.data.indeterminate) {
        allChildrenChecked = false;
      }
      if (child.data.checked) {
        noChildChecked = false;
      }
    }

    if (allChildrenChecked) {
      node.data.checked = true;
      node.data.indeterminate = false;
    } else if (noChildChecked) {
      node.data.checked = false;
      node.data.indeterminate = false;
    } else {
      node.data.checked = true;
      node.data.indeterminate = true;
    }

    if (!this.independent) {
      this.updateParentNodeCheckbox(node.parent);
    }
  }

  private getSelectedNodes(): FlexSelectTreeNode[] {
    return this.treeNodeList.filter((node: any) => node.checked && !node.indeterminate && !node.disabled);
  }

  getSelectedText(): string {
    return this.getSelectedNodes().map(node => node.name).join(', ');
  }

  selectedAll(checked: boolean): void {
    if (!this.multiple) {
      console.warn('Check all items only support with multiple select');
      return;
    }

    this.checkall = checked;
    this.tree.treeModel.doForAll(node => {
      this.check(node, checked);
    });
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  toogleSelectedAll(): void {
    this.checkall = !this.checkall;
    this.selectedAll(this.checkall);
  }
}
