import * as _ from 'lodash';

import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class AgentRepository extends BaseRepository {
  ENTITY_CLASS = AgentEntity;
  protected URL_PREFIX = 'callcenter/queue';
  protected USE_PUSH_API = true;
  protected RELATED_REPOSITORIES: string[] = ['user'];

  getObjectByUserId(user_id: number): AgentEntity {
    return this.getObjectList().find(x => x.user_id === user_id);
  }

  private agentBelongGroups(agent: AgentEntity, group_ids: number[]): boolean {
    if (agent.group_ids && group_ids && group_ids.length) {
      const intersection = agent.group_ids.filter((n: any) => {
        return group_ids.indexOf(n) > -1;
      });

      if (intersection.length === 0) {
        return false;
      }
    }
    return true;
  }

  getAvailableAgents(group_ids?: number[]): AgentEntity[] {
    const ret: AgentEntity[] = [];
    for (const agent of this.getObjectList()) {
      if (group_ids && group_ids.length && !this.agentBelongGroups(agent, group_ids)) {
        continue;
      }
      if (agent.enabled && agent.is_available_cc) {
        ret.push(agent);
      }
    }
    return ret;
  }

  getCCStaffList(): AgentEntity[] {
    return this.getObjectList().filter(x => x.can_use_callcenter);
    // return list.filter(agent => {
    //   const user: UserEntity = agent.user as UserEntity;
    //   if (!user) {
    //     console.error('No user associated', agent);
    //     return false;
    //   }
    //   return user.can_use_callcenter;
    // });
  }

  protected filterEntity(entity: AgentEntity): boolean {
    const user: UserEntity = entity.user as UserEntity;
    return !user || user && user.is_system_user && !AuthenticationService.getInstance().isSystemUser() ? false : true;
  }

  getConnectedAgents(group_ids?: number[]): AgentEntity[] {
    const ret: AgentEntity[] = [];
    for (const agent of this.getObjectList()) {
      if (group_ids && group_ids.length && !this.agentBelongGroups(agent, group_ids)) {
        continue;
      }
      if (agent.is_online_cc) {
        ret.push(agent);
      }
    }
    return ret;
  }

  getInProgressAgents(group_ids?: number[]): AgentEntity[] {
    const ret: AgentEntity[] = [];
    for (const agent of this.getObjectList()) {
      if (group_ids && group_ids.length && !this.agentBelongGroups(agent, group_ids)) {
        continue;
      }
      if (agent.reserved) {
        ret.push(agent);
      }
    }
    return ret;
  }

  getPausedAgents(group_ids?: number[]): AgentEntity[] {
    const ret: AgentEntity[] = [];
    for (const agent of this.getObjectList()) {
      if (group_ids && group_ids.length && !this.agentBelongGroups(agent, group_ids)) {
        continue;
      }
      if (agent.pause_event_id) {
        ret.push(agent);
      }
    }
    return ret;
  }
}

import { AgentEntity } from '@wephone-core/model/entity/agent';
import { UserEntity } from '../entity/user';
import { AuthenticationService } from '@wephone-core/service/authentication';
