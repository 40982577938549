import { datafield, Entity } from '@wephone-core/model/model';

export class CustomReportConfigEntity extends Entity {
  public static object_type_id = 'custom_report_config';

  public id: number;
  @datafield
  public name: string;
  @datafield
  public source_name: string;
  @datafield
  public column_config: any; // jsonb
  @datafield
  public shared: number;
  @datafield
  public filters: any; // jsonb
}
