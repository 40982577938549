import { datafield, mapped_datafield, Entity } from '@wephone-core/model/model';
import { TeamGroupRepository } from '@wephone-core/model/repository/teamgroup';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { WorkingPauseType } from './pause_reason.i';

export class PauseReasonEntity extends Entity {
  static object_type_id = 'pause_reason';

  id: number;

  @datafield
  label: string;

  @datafield
  is_archived: number;

  @datafield
  working_pause: WorkingPauseType;

  @datafield
  after_call: number;

  @mapped_datafield('group_ids')
  team_ids: number[];

  get teams(): TeamGroupEntity[] {
    const teams: TeamGroupEntity[] = [];
    if ((this.team_ids || []).length > 0) {
      for (const id of this.team_ids) {
        const group = TeamGroupRepository.getInstance().getObjectById(id);
        teams.push(group);
      }
    }
    return teams;
  }
}
