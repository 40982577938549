import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extensionAccept'
})
export class ExtensionAcceptPipe implements PipeTransform {
  transform(value: string[]): string {
    return value.join(',');
  }
}
