import { FormControl } from '@angular/forms';
import { ValidatorService } from '@wephone-utils/services/validator.service';

export const PhoneNumberValidator = (control: FormControl) => {
  const validatorService = new ValidatorService();
  const val = control.value && control.value.trim();
  if (val && !validatorService.isPhoneNumber(val)) {
    return { wrongPhoneNumber: true };
  }
  return null;
};
