<p *ngIf="user">{{ 'live_tracking.actions.eavesdropping_call_description'|translate:{agent: user.name} }}</p>
<form [formGroup]="form" *ngIf="form">
<ng-container *ngIf="eavesdrop_duration_enabled">
  <span>{{ 'live_tracking.content.you_want'|translate }}</span>
  <mat-list class="pa-0 mb-1">
    <mat-radio-group class="radio-group" formControlName="eavesdrop_duration" (change)="setEavesdropDuration()"
      ngDefaultControl>
      <mat-radio-button class="radio-button" [value]="0">{{'livecall.overview.eavesdrop_once'|translate}}</mat-radio-button>
      <mat-radio-button class="radio-button" [value]="1">{{'livecall.overview.eavesdrop_until'|translate}}</mat-radio-button>
    </mat-radio-group>
  </mat-list>
  <div *ngIf="form.get('eavesdrop_duration').value==1" fxLayout="column">
    <div fxLayout="row wrap">
      <button class="duration-period" mat-raised-button [color]="nextHour === selectedHour?'primary':'secondary'" *ngFor="let nextHour of nextHours" (click)="setNextHourCalling(nextHour)">
        {{ nextHour }} {{ 'stats.js.duration_hours'|translate }}
      </button>
      <button class="duration-period" mat-raised-button [color]="!selectedHour?'primary':'secondary'" (click)="setCustomNextHourCalling()">
        {{ 'stats.missed_call.period.custom'|translate }}
      </button>
    </div>
    <flex-time-picker (timeSet)="setCustomNextHourCalling()" formControlName="eavesdrop_until_hour" placeholder="{{ (isToday ? 'period_select.today' : 'period_select.tomorrow')|translate }}"></flex-time-picker>
  </div>
</ng-container>

<span>{{ 'live_tracking.content.listen_from'|translate }}</span>
<mat-list class="pa-0 mb-1">
  <mat-radio-group class="radio-group" formControlName="eavesdrop_listen_from" (change)="setEavesdropListenFrom()"
    ngDefaultControl>
    <mat-radio-button [disabled]="!sipRegistered" class="radio-button" [value]="0">{{'live_tracking.content.listen_application'|translate}}</mat-radio-button>
    <mat-radio-button class="radio-button" [value]="1">{{'live_tracking.content.listen_phone_number'|translate}}</mat-radio-button>
  </mat-radio-group>
</mat-list>

<mat-form-field class="full-width" [hidden]="!form.get('eavesdrop_listen_from').value">
  <mat-label>{{ 'did.content.number'|translate }}</mat-label>
  <input matInput type="tel" formControlName="phone_number" [placeholder]="'livecall.overview.enter_phone_number' | translate">
  <mat-error [flexFormControlError]="form.controls['phone_number']"></mat-error>
</mat-form-field>
</form>
