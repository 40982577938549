import { Entity, datafield } from '@wephone-core/model/model';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { OpeningHourRepository } from '@wephone-core/model/repository/openinghour';
import { parseDateTime } from '@wephone-utils';
import { DateTime } from 'luxon';
import { FileEntryEntity } from './fileentry';
import { EntityManager } from '@wephone-core/service/entity_manager';

export enum OpeningMode {
  CloseAllday = 0,
  OpenAllday = 1,
  CloseDuringPeriod = 2,
  OpenDuringPeriod = 3
}
export class OpeningHourSpecialDateEntity extends Entity {
  static object_type_id = 'opening_hour_special_date';

  id: number;
  @datafield
  calendar_id: number;
  @datafield
  name: string;
  @datafield
  opening_mode: OpeningMode = OpeningMode.CloseAllday;
  @datafield
  start_dt: any;
  @datafield
  end_dt: any;
  @datafield
  announcement_id: number;

  openinghour: OpeningHourEntity = null;
  announcement: FileEntryEntity = null;

  setObjectData(object_data: any, fetch_related_data = true): void {
    super.setObjectData(object_data, fetch_related_data);

    if (this.getId() && (this.opening_mode === OpeningMode.CloseDuringPeriod || this.opening_mode === OpeningMode.OpenDuringPeriod)) {
      this.openinghour = OpeningHourRepository.getInstance<OpeningHourRepository>().getObjectBySpecialDateId(
        this.getId()
      );
    }

    this.start_dt = this.start_dt && parseDateTime(this.start_dt).toISO();
    this.end_dt = this.end_dt && parseDateTime(this.end_dt).toISO();

    if (this.announcement_id) {
      this.announcement = EntityManager.getRepository('FileEntryRepository').getObjectById(this.announcement_id);
    } else {
      this.announcement = null;
    }
  }

  setDefaultValue(name: string): void {
    this.name = name;
    const today = new Date();
    this.start_dt = parseDateTime(today).toISO();
    this.end_dt = parseDateTime(today).toISO();
    this.opening_mode = OpeningMode.CloseDuringPeriod;
  }

  dumpObjectData(field_list: string[] = null, use_mapped_name: boolean = false): any {
    const data = super.dumpObjectData(field_list, use_mapped_name);

    data['announcement_id'] = this.announcement && this.announcement.id || null;

    if (this.openinghour) {
      data['openinghour'] = {
        id: this.openinghour.id,
        start_time: this.openinghour.start_time,
        end_time: this.openinghour.end_time
      };
    }
    return data;
  }
}
