import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
// import { CommonAPI } from '@wephone-core/service/common_api';
// import { StorageService } from '@wephone-core/service/storage.service';
import { EntityManager } from './entity_manager';
import { FlexIvrSettings } from './flexivr_settings';
// import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { EnterpriseFileEntryEntity } from '@wephone-core/model/entity/enterprise_fileentry';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { EnterpriseFileEntryRepository } from '@wephone-core/model/repository/enterprise_fileentry';

@Injectable()
export class EnterpriseFileEntryService {

  private readonly UPLOAD_URL = 'enterprise-file-entry/upload';

  constructor(
    private readonly entityManager: EntityManager,
    private readonly configManager: ConfigManager,
    private readonly httpEngine: HttpEngine,
    private readonly settings: FlexIvrSettings
  ) { }

  upload(file: any, title: string, category: string, file_type: string): Promise<EnterpriseFileEntryEntity> {
    const eid = this.configManager.getCurrentEnterpriseId();
    const params = {
      title,
      category,
      eid,
      fileType: file_type
    };

    const options: any = {
      data: params,
      method: 'POST',
      headers: { 'Content-Type': file.type }
    };

    const body = new FormData();
    body.append('file', file);

    for (const key of Object.keys(params)) {
      body.append(key, params[key]);
    }

    return this.httpEngine.apiPostV2(this.UPLOAD_URL, body, options);
  }

}
