import { Entity, datafield } from '@wephone-core/model/model';
import { parseDateTime } from '@wephone-utils';
import { DateTime } from 'luxon';
import { HotelWakeupCallObjectType, HotelWakeupCallStateType } from './hotelroom.i';

export class HotelWakeupCallEntity extends Entity {
  static object_type_id = 'hotel_wakeup_call';

  id: number;
  @datafield room_id: number;
  @datafield wakeup_time: DateTime;
  @datafield state: HotelWakeupCallStateType;
  @datafield updater_object_type: HotelWakeupCallObjectType;
  @datafield updater_object_id: number;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.wakeup_time = this.wakeup_time && parseDateTime(this.wakeup_time);
  }

  // dumpObjectData(field_list: string[] = null, use_mapped_name: boolean = false): any {
  //   super.dumpObjectData(field_list, use_mapped_name);

  //   if (this.wakeup_time) {
  //     this.wakeup_time = parseDateTime(this.wakeup_time);
  //   }
  // }
}
