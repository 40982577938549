import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DynamicFilterSource, DialogService, ToastService, IFlexTableSidePannelOptions, FlexCRUDPageComponent, IFlexTableConfig } from '@wephone-utils';
import { TranslateService } from '@ngx-translate/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ShortcutEntity } from '@wephone-core/model/entity/shortcut';
import { ShortcutRepository } from '@wephone-core/model/repository/shortcut';
import { CreateShortcutModalComponent } from '@wephone/modals/create-shortcut-modal/create-shortcut-modal.component';
import { EditShortcutComponent } from '@wephone/components/edit-shortcut/edit-shortcut.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./shortcut.component.scss']
})
export class ShortcutComponent extends FlexBasePage implements OnInit {

  public shortcutForm: FormGroup;
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditShortcutComponent
  };
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;
  constructor(
    public dialogService: DialogService,
    public toast: ToastService,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
  ) {
    super();
    const shortcutSource = EntityManager.getRepository('ShortcutRepository').dataSource<ShortcutEntity>();
    this.dataSource = new DynamicFilterSource(shortcutSource, undefined);

    this.tableConfig = {
      columns: [
        {
          name: 'display_text',
          label: _tk('shortcut.content.display_text'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (shortcut: ShortcutEntity) => {
            return shortcut.display_text.toLowerCase();
          }
        },
        {
          name: 'destination',
          label: _tk('shortcut.content.destination'),
          searchable: false
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.shortcutForm = this.fb.group({
      filterValue: ['']
    });

    this.shortcutForm.valueChanges.subscribe(this.onFormValueChange);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;

    this.dataSource.onFilterChange();
  }

  createNew(): void {
    this.dialogService.openDialog2(CreateShortcutModalComponent, {});
  }

  public async bulkDelete(items: ShortcutEntity[]): Promise<any> {
    const values = await this.translate
      .get(['dialogs.confirmation', 'user.title.delete', 'dialogs.no', 'dialogs.yes'])
      .toPromise();

    return this.dialogService.confirmDialog(values['dialogs.confirmation'], values['user.title.delete'], () => {
      if (items) {
        EntityManager.getRepository<ShortcutRepository>('ShortcutRepository').bulkDelete(items).then(
          response => {
            this.toast.show(_ti('public.message.delete_success'));
            this.flexCrud.closeSidePanel();
          },
          response => {
            this.toast.showError(_ti('public.message.delete_failure'));
          }
        );
      }
    });
  }
}
