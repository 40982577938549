<form [formGroup]="form" (ngSubmit)="submit()">
  <div fxLayout="column">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'shortdialcode.content.dial_code'| translate}}</mat-label>
        <input matInput placeholder="{{'shortdialcode.content.dial_code'| translate}}" formControlName="dial_code"
          data-ci="dial-code">
          
        <mat-error [flexFormControlError]="form.get('dial_code')"></mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
