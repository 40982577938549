
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogActionButton, Colors, IFlexDialogConfig, joiValidator } from '@wephone-utils';
import * as Joi from 'joi-browser';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';
import { IHotelRoomRRequestRangesNumber } from '@wephone-core/model/entity/hotelroom.i';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'app-create-multiple-hotelroom-modal',
  templateUrl: './create-multiple-hotelroom-modal.component.html',
  styleUrls: ['./create-multiple-hotelroom-modal.component.scss']
})
export class CreateMultipleHotelroomModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'xs'
  };

  dialogTitle = _tk('hotelroom.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    dialogRef: MatDialogRef<CreateMultipleHotelroomModalComponent>,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.form = this.fb.group({
      ranges_number: this.fb.array([this.getNewRangeNumber()]),
      has_sipphone: [true]
    });
  }

  private getNewRangeNumber(): FormGroup {
    return this.fb.group({
      from: [undefined, joiValidator(Joi.number().min(1).max(9999999999).required())],
      to: [undefined, joiValidator(Joi.number().min(1).max(9999999999).required())],
    });
  }

  addRangeNumber(): void {
    this.rangesNumberControl.push(this.getNewRangeNumber());
  }

  removeRangeNumber(index: number): void {
    if (this.rangesNumberControl.controls.length === 0) {
      console.warn('Cannot remove range number item');
      return;
    }

    this.rangesNumberControl.markAsDirty();
    this.rangesNumberControl.removeAt(index);
  }

  get rangesNumberControl(): FormArray {
    return this.form && this.form.get('ranges_number') as FormArray;
  }

  async submitForm(): Promise<void> {
    this.form.get('ranges_number').setErrors(null);
    const rangesNumber: IHotelRoomRRequestRangesNumber[] = this.form.get('ranges_number').value;

    if (rangesNumber.length > 100) {
      this.form.get('ranges_number').setErrors({max_length: true});
    }

    const roomNumbers = this.em.getRepository('HotelRoomRepository').getObjectList();

    for (const rangeNumberGroup of this.rangesNumberControl.controls) {
      rangeNumberGroup.get('from').setErrors(null);
      rangeNumberGroup.get('to').setErrors(null);

      // Check required
      if (!rangeNumberGroup.get('from').value) {
        rangeNumberGroup.get('from').setErrors({'any.required': true});
      }

      if (!rangeNumberGroup.get('to').value) {
        rangeNumberGroup.get('to').setErrors({'any.required': true});
      }

      if (rangeNumberGroup.get('to').value < rangeNumberGroup.get('from').value) {
        rangeNumberGroup.get('to').setErrors({lesser: true});
      }

      // Check exist room number
      for (let roomNumber = +rangeNumberGroup.get('from').value; roomNumber <= +rangeNumberGroup.get('to').value; roomNumber++) {
        if (roomNumbers.find(x => x.room_number === roomNumber.toString())) {
          rangeNumberGroup.get('from').setErrors({exists: true, message: _ti('hotelroom.message.room_number_already_exist', {room_number: roomNumber})});
          rangeNumberGroup.get('to').setErrors({exists: true, message: _ti('hotelroom.message.room_number_already_exist', {room_number: roomNumber})});
          break;
        }
      }
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      const hasSipphone: boolean = !!this.form.get('has_sipphone').value;
      const res = await HotelRoomRepository.getInstance<HotelRoomRepository>().createMultipleHotelRoom(rangesNumber, hasSipphone);
      this.successToast(_ti('public.message.create_success'));
      this.dismiss(res, false);
    } catch (e) {
      console.error('Ceate room numbers errors', e);
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  updateValidation(minValue: number, formControl: FormControl): void {
    formControl.markAsTouched();
    formControl.clearValidators();
    if (formControl.value && +formControl.value < +minValue) {
      formControl.setValidators(joiValidator(Joi.number().min(minValue).max(9999999999).required()));
    } else {
      formControl.setValidators(joiValidator(Joi.number().min(1).max(9999999999).required()));
    }
    formControl.updateValueAndValidity();
  }

}
