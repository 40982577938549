import { IChangeAwareComponent } from './changeaware-component.i';
import { EventEmitter, Output, Input, HostBinding, Directive, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../services/form.service';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { IItemEditor } from './editor.i';
import { Deferred } from 'ts-deferred';
import { DynamicFilterSource } from '../flex-table/datasource/dynamic-filter-source';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DateAdapter } from '@angular/material/core';
import { TranslationService, _ti } from '@wephone-translation';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Directive()
export abstract class EditingComponent implements IItemEditor, IChangeAwareComponent, OnDestroy, OnInit {
  ALLOW_SCROLL = true;
  MULTI_EDITOR = false;
  dateAdapter: DateAdapter<Date>;
  dataSource: DynamicFilterSource | FlexDataSource;

  editingItem: any;
  editingList: any[];

  @HostBinding('class.flex-editing-component-scroll') get _scrollEnabled(): boolean { return this.ALLOW_SCROLL; }
  @HostBinding('class.flex-editing-component-no-scroll') get _scrollNotEnabled(): boolean { return !this.ALLOW_SCROLL; }

  @Input() options: any;
  @Output() readonly formValueChanges: EventEmitter<boolean>;

  form: FormGroup;
  protected formService: FormService;
  protected dClosed: Deferred<any>;
  protected hasClosed = false;

  protected subscriptionList: Subscription[] = new Array<Subscription>();

  constructor() {
    this.formService = StaticServiceLocator.injector.get(FormService);
    this.formValueChanges = new EventEmitter<boolean>();
    this.dClosed = new Deferred<any>();

  }

  get editorClose(): Promise<any> {
    return this.dClosed.promise;
  }

  private setDatepickerLocale(): void {
    this.dateAdapter.setLocale(TranslationService.getInstance().getCurrentLocale());
  }

  protected addSubscription(sub: Subscription): Subscription {
    this.subscriptionList.push(sub);
    return sub;
  }

  close(): void {
    if (this.hasClosed) {
      return;
    }
    this.hasClosed = true;
    this.dClosed.resolve();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  getChangeSet(): any {
    return this.formService.getChangeSet(this.form);
  }

  clearEditState(): void {
    this.form.reset(this.form.value);
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  formHasChanged(): boolean {
    return this.form && this.form.dirty;
  }

  formIsValid(): boolean {
    return this.form && this.form.valid;
  }

  async submitForm(): Promise<any> {
    throw new Error('This method must be overriden by subclass');
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    throw new Error('This method must be overriden by subclass');
  }

  setEditingData(data: any): void {
    if (this.MULTI_EDITOR) {
      this.editingList = data;
    } else {
      this.editingItem = data;
    }
  }

  ngOnInit(): void {
    if (!_.isEmpty(this.dateAdapter)) {
      this.setDatepickerLocale();
      this.addSubscription(
        TranslationService.getInstance().translate.onLangChange.subscribe(
          () => {
            this.setDatepickerLocale();
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptionList) {
      sub.unsubscribe();
    }
    this.subscriptionList = [];
  }
}
