<div fxLayout="row" class="full-width" fxLayoutAlign="start center">
  <div fxFlex="{{ duration > 3600 ? '100px' : '90px' }}">
    {{ seek|seconds_as_duration:'mm:ss' }}/{{ duration|seconds_as_duration:'mm:ss' }}
  </div>
  <div fxFlex="calc(100%-90px)">
    <mat-slider [min]="0" [max]="duration" 
      [value]="seek" 
      (input)="sliderChange($event)"
      [disabled]="!audioTrack"
      class="full-width">
    </mat-slider>
  </div>
</div>
