import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { RoutingAppEntity } from '@wephone-core/model/entity/routing_app';
import { CALL_ROUTING_APPS } from '@wephone-core/constants/telephony-config';

export class RoutingAppRepository extends BaseRepository {
  ENTITY_CLASS = RoutingAppEntity;
  protected URL_PREFIX = 'routing_app';

  // must run findAll first
  getObjectByApp(application_name: string): RoutingAppEntity {
    return this.getObjectList().find(i => i.application === application_name);
  }

  getObjectList(): RoutingAppEntity[] {
    const routingApps = CALL_ROUTING_APPS;
    const ret: RoutingAppEntity[] = [];
    for (const app of routingApps) {
      const item: RoutingAppEntity = this.create(app) as RoutingAppEntity;
      ret.push(item);
    }
    return ret;
  }
}
