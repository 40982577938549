import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService, HttpClient, NoWhitespaceValidator } from '@wephone-utils';
import { ImageEntity } from '@wephone-core/model/entity/image';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { ImageRepository } from '@wephone-core/model/repository/image';
import { FormBuilder, Validators } from '@angular/forms';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { ImageService } from '@wephone/services/image.service';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { Router } from '@angular/router';


@Component({
  selector: 'app-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.scss']
})
export class ImageEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: ImageEntity;
  loading = true;
  image: ImageEntity;
  newImage: ImageEntity;
  inUsedObjects: any = {};
  
  private readonly imageRepo: ImageRepository;

  readonly steps = {
    characteristic: 'image.content.general',
    inused: 'image.content.objects_used',
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly imageService: ImageService,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    readonly em: EntityManager
  ) {
    super();
    this.imageRepo = em.getRepository<ImageRepository>('ImageRepository');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    this.setItem();

    this.form = this.fb.group({
      name: [this.image.name, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]],
      // replacedPublicId: [undefined]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe((changes: any) => {
        this.onFormValueChange();
      })
    );

    try {
      this.inUsedObjects = await this.imageService.getInUsedObjectsByIds([this.image.id]);

      this.loading = false;
    } catch (error) {
      console.error('get object error', error);
    } finally {
      this.loading = false;
    }
  }

  get noObjectUsing(): boolean {
    return _.isEqual(this.inUsedObjects, {});
  }

  get stepKeys(): string[] {
    return Object.keys(this.steps);
  }

  gotoPageSipPhone(sipphone: SipPhoneEntity): void {
    this.router.navigate(['telephony', 'sip-phones', sipphone.id], { skipLocationChange: false });
  }

  async downloadEntry(image: ImageEntity): Promise<any> {
    const url = this.imageRepo.getFileUrl(image.public_id, true);
    const fileName = `image-${image.name}-${image.public_id}.${image.file_ext}`;

    const data = await this.httpClient
      .get(FlexIvrSettings.getInstance().getAbsoluteUrl(url), { responseType: 'blob' })
      .toPromise();

    this.downloadFile(data, fileName);
  }

  private downloadFile(data, fileName: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    // remove the element
    a.remove();
  }

  setItem(): void {
    this.image = _.cloneDeep(this.editingItem);
  }

  private getFormResetData(): Object {
    this.setItem();
    return {
      name: this.image.name,
      // replacedPublicId: undefined,
    };
  }

  async submitForm(): Promise<any> {
    const name: string = this.form.get('name').value;
    if (this.form.get('name').valid) {
      this.form.get('name').setErrors(null);
      if (this.imageRepo.getObjectByName(name, this.image && this.image.id)) {
        this.form.get('name').setErrors({duplicated: true});
        this.form.get('name').markAsTouched();
      }
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toast.showError(_ti('form.validator.data_invalid'));
      return undefined;
    }

    try {
      const params: any = {
        public_id: this.image.public_id,
        name
      };

      // if (this.form.get('replacedPublicId').value) {
      //   params.replaced_public_id = this.form.get('replacedPublicId').value;
      // }

      const returnedObj = await this.imageRepo.updateImage(params);
      if (!returnedObj) {
        throw new Error('Cannot update');
      }
      this.image = this.imageRepo.getObjectById(+returnedObj.id);

      this.toast.show(_ti('public.message.update_success'));
      this.resetForm();
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }

  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
  }
}
