<div fxLayout="column" fxFill class="flex-container" *ngIf="is_ready">
  <form [formGroup]="filterForm">
    <period-select [startDate]="filterForm.get('start_dt').value" [endDate]="filterForm.get('end_dt').value"
      (changedPeriod)="changePeriodFilter($event)"></period-select>
    <div class="pb-1" *ngIf="filtersReadonly;else BlockFilterForm" fxLayout="column" fxLayoutAlign="start center">
      <span fxFlex *ngIf="filterForm.get('start_dt').value && filterForm.get('end_dt').value">
        {{ filterForm.get('start_dt').value|timeFormatDateShort }} -->
        {{ filterForm.get('end_dt').value|timeFormatDateShort }}
      </span>
      <span *ngIf="(filterForm.get('agents').value || []).length">
        <strong>{{ 'stats.filter.agent'|translate }}</strong>:
        {{ rendererUser(filterForm.get('agents').value)|truncateArray }}
      </span>
      <span *ngIf="(filterForm.get('queue_ids').value || []).length">
        <strong>{{ 'stats.filter.queue'|translate }}</strong>:
        {{ rendererQueue(filterForm.get('queue_ids').value)|truncateArray }}
      </span>
      <span *ngIf="filterForm.get('phone_number').value">
        <strong>{{ 'stats.handled_call.filter.phone_number'|translate }}</strong>: {{ filterForm.get('phone_number').value }}
      </span>
      <span *ngIf="hasServiceGroup && filterForm.get('groups').value.length">
        <strong>{{ 'stats.filter.group'|translate }}</strong>:
        {{ rendererGroup(filterForm.get('groups').value)|truncateArray }}
      </span>
      <span *ngIf="filterForm.get('public_call_id').value">
        <strong>{{ 'stats.handled_call.filter.public_call_id'|translate }}</strong>: {{ filterForm.get('public_call_id').value }}
      </span>
      <a class="link" (click)="changeFilter()">{{'public.change'|translate}}</a>
    </div>

    <ng-template #BlockFilterForm>
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center">
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'public.from'|translate }}</mat-label>
            <input matInput formControlName="start_dt" [matDatepicker]="startPicker"
              placeholder="{{ 'campaign.startDt' | translate }}">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex="20px"></div>

        <div fxFlex>
          <mat-form-field fxFlex>
            <mat-label>{{ 'public.to'|translate }}</mat-label>
            <input matInput formControlName="end_dt" [matDatepicker]="endPicker"
              placeholder="{{ 'campaign.endDt' | translate }}">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>

      </div>

      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center">
        <div fxFlex>
          <mat-form-field fxFlex>
            <mat-label>{{ 'stats.handled_call.filter.phone_number' | translate }}</mat-label>
            <input matInput="tel" formControlName="phone_number"
              placeholder="{{ 'stats.handled_call.filter.phone_number' | translate}}">
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'phone_number')"
                [disabled]="!filterForm.get('phone_number').value">
                <mat-icon>close</mat-icon>
              </button>
          </mat-form-field>
        </div>

        <div fxFlex="20px"></div>

        <ng-container *ngIf="showAgentList">
          <div fxFlex>
            <mat-form-field class="full-width flex-select">
              <mat-label>{{ 'stats.filter.select_agent' | translate }}</mat-label>
              <flex-select-user-input [multiple]="true" [showCheckAll]="true" [hasUser]="true"
                [filteredIds]="filteredUserIds"
                placeholder="{{ 'stats.filter.select_agent' | translate }}" formControlName="agents">
              </flex-select-user-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'agents', true)"
                [disabled]="!filterForm.get('agents').value || !filterForm.get('agents').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div fxFlex="20px"></div>
        </ng-container>

        <div fxFlex>
          <mat-form-field class="full-width flex-select">
            <mat-label>{{ 'stats.filter.select_queue' | translate }}</mat-label>
            <flex-select-queue-input [multiple]="true" [showCheckAll]="true" queueType="inbound"
              [filteredIds]="filteredQueueIds"
              placeholder="{{ 'stats.filter.select_queue' | translate }}" formControlName="queue_ids">
            </flex-select-queue-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'queue_ids', true)"
              [disabled]="!filterForm.get('queue_ids').value || !filterForm.get('queue_ids').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" class="text-center"
        *ngIf="hasServiceGroup">
        <div fxFlex>
          <mat-form-field class="full-width flex-select">
            <mat-label>{{ 'stats.filter.select_group' | translate }}</mat-label>
            <flex-select-group-input [limitedByRole]="true" [multiple]="true" [showCheckAll]="true" [hasAgentOrQueueOrDid]="true"
              [filteredIds]="filteredGroupIds"
              groupType="service" placeholder="{{ 'stats.filter.select_group' | translate }}" formControlName="groups">
            </flex-select-group-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'groups', true)"
              [disabled]="!filterForm.get('groups').value || !filterForm.get('groups').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div fxFlex="20px"></div>
        
        <div fxFlex>
          <mat-form-field fxFlex>
            <mat-label>{{ 'stats.handled_call.filter.public_call_id' | translate }}</mat-label>
            <input matInput formControlName="public_call_id"
              placeholder="{{ 'stats.handled_call.filter.public_call_id' | translate}}">
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'public_call_id')"
                [disabled]="!filterForm.get('public_call_id').value">
                <mat-icon>close</mat-icon>
              </button>
          </mat-form-field>
        </div>
        
      </div>
      <div align="center" class="my-1">
        <button type="submit" mat-raised-button color="accent" (click)="applyFilter()">
          {{ 'public.apply' | translate }}</button>
      </div>
    </ng-template>
  </form>
  <flex-table #flexTable fxLayout="row" fxFlex="grow" [dataSource]="dataSource" [tableConfig]="tableConfig" [columnTemplates]="{
      duration: tplDuration, is_outgoing: tplIsOutgoing, start_dt: tplStartDate, 
      handled_user_id: tplHandledUser, action: tplAction
    }">
    <!-- <flex-table fxLayout="row" fxFlex="grow" [dataSource]="dataSource" [tableConfig]="tableConfig"
    [columnTemplates]="{duration: tplDuration,  is_outgoing: tplIsOutgoing, start_dt: tplStartDate, action: tplAction}"> -->

    <ng-template #tplHandledUser let-item="row">
      <span class="agent-name">{{ item.handled_user_id }}</span>
    </ng-template>

    <!-- <ng-template #tplQueueName let-item="row">
      <span class="queue-name">{{ item.queue_name }}</span>
    </ng-template> -->

    <!-- <ng-template #tplQualification let-item="row">
      <div class="qualification">{{ item.qualification }}</div>
    </ng-template> -->

    <!-- <ng-template #tplQualificationComment let-item="row">
      <div class="qualification-comment" matTooltip="{{ item.qualification_comment }}">{{ item.qualification_comment }}
      </div>
    </ng-template> -->

    <ng-template #tplDuration let-item="row">
      {{ item.duration|seconds_as_duration }}
    </ng-template>

    <ng-template #tplIsOutgoing let-item="row">
      <span *ngIf="item.is_outgoing === 0; else callOut" class="mat-text-primary">
        <mat-icon color="accent" matTooltip="{{ 'livecall.call_incoming'|translate }}">arrow_downward</mat-icon>
      </span>
      <ng-template #callOut>
        <mat-icon color="warn" matTooltip="{{ 'livecall.call_outgoing'|translate }}">arrow_upward</mat-icon>
      </ng-template>
    </ng-template>

    <ng-template #tplStartDate let-item="row">
      {{ item.start_dt|timeFormatDateTimeShort }}
    </ng-template>

    <ng-template #tplAction let-item="row">
      <div fxLayout="row" fxLayoutAlign="start center">
        <!--Qualify-call-->
        <ng-container *ngIf="canQualify(item); else NoQualification">
          <ng-container *ngIf="(item.qualification_comment || item.qualification); else AddQualificationBlock">
            <button mat-icon-button (click)="reQualify(item)" color="warn"
              matTooltip="{{ 'call.actions.change_qualification' | translate }}">
              <mat-icon>edit_note</mat-icon>
            </button>
          </ng-container>
          <ng-template #AddQualificationBlock>
            <button mat-icon-button color="primary" (click)="reQualify(item)"
              matTooltip="{{ 'call.actions.add_qualification' | translate }}">
              <mat-icon>note_add</mat-icon>
            </button>
          </ng-template>
        </ng-container>
        <ng-template #NoQualification>
          <button mat-icon-button color="primary" disabled
            matTooltip="{{ 'call.actions.add_qualification' | translate }}">
            <mat-icon>note_add</mat-icon>
          </button>
        </ng-template>

        <!--View-detail-->
        <button mat-icon-button color="primary" (click)="detailHandledCallInfo(item)">
          <mat-icon [matTooltip]="'call.actions.view_detail'|translate">info</mat-icon>
        </button>

        <!--Open-config-->
        <div *ngIf="activeCRM" class="crm-logo" (click)="openContactProfile(item)" matTooltip="{{activeCRM.name}}">
          <img *ngIf="crmConfig" src="assets/images/{{crmConfig.logo}}" alt="{{activeCRM.type}}">
        </div>
      </div>
    </ng-template>
  </flex-table>
</div>
