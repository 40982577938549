import { Component, OnInit, Inject } from '@angular/core';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import {
  ToastService,
  DialogActionButton,
  Colors,
  IFlexDialogConfig,
  NoWhitespaceValidator,
} from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
// import { TranslateService } from '@ngx-translate/core';
import { WorkingPauseType } from '@wephone-core/model/entity/pause_reason.i';

@Component({
  selector: 'app-create-pause-reason-modal',
  templateUrl: './create-pause-reason-modal.component.html',
  styleUrls: ['./create-pause-reason-modal.component.scss']
})
export class CreatePauseReasonModalComponent extends DialogComponentBase {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('pause_reason.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  private readonly pauseReasonRepo = PauseReasonRepository.getInstance<PauseReasonRepository>();
  private readonly pauseReasonList: PauseReasonEntity[];

  pauseReason: PauseReasonEntity;
  form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    dialogRef: MatDialogRef<CreatePauseReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly toast: ToastService,
    // private readonly translate: TranslateService,
  ) {
    super(dialogRef);
    this.pauseReasonList = this.pauseReasonRepo.getObjectList();
    this.pauseReason = this.pauseReasonRepo.create() as PauseReasonEntity;
    this.pauseReason.label = this.getNewLabel();
    this.initFormGroup();
  }

  getNewLabel(): string {
    const defaultLabel = _ti('pause_reason.title.new_reason');
    let newLabel: string;
    let i = 1;

    do {
      newLabel = `${defaultLabel} ${i}`;
      i++;
    } while (this.checkLabelPauseReason(newLabel));

    return newLabel;
  }

  checkLabelPauseReason(label: string): boolean {
    for (const pause of this.pauseReasonList) {
      if (label === pause.label) {
        return true;
      }
    }

    return false;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      label: [this.pauseReason.label, [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      nonWorkingPause: [this.pauseReason.working_pause === WorkingPauseType.NON_WORKING_REASON ? true : false],
    });
  }

  async submit(): Promise<void> {
    const label: string = _.trim(this.form.get('label').value);
    const pauseReason: PauseReasonEntity = (EntityManager.getInstance().getRepository<PauseReasonRepository>('PauseReasonRepository'))
      .getObjectUsingLabel(label, null);

    if (pauseReason) {
      this.form.get('label').setErrors({ duplicated: true });
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toast.showError(_ti('form.validator.data_invalid'));
      return;
    }

    try {
      const formValue = this.form.value;
      this.pauseReason.label = _.trim(formValue.label);
      this.pauseReason.working_pause = formValue.nonWorkingPause ? WorkingPauseType.NON_WORKING_REASON : WorkingPauseType.WORKING_REASON;
      const createdPauseReason = await this.pauseReasonRepo.save(this.pauseReason);
      this.toastService.show(_ti('public.message.create_success'));
      this.dismiss(createdPauseReason, false);
    } catch (error) {
      console.error('Create pause reason error:', error);
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  cancel(result?: any): void {
    this.dismiss(result);
  }
}
