<ng-container *ngIf="is_ready">
<ng-template #listFilterTpl>
  <form [formGroup]="groupForm" class="filter-form">
    <div fxLayoutAlign="end center">
      <flex-search-input formControlName="filterValue" [hintText]="'group.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>
<flex-crud-page #flexCrud
              pageTitle="{{ 'menu.manage_group'|translate }}"
              [filterTemplate]="listFilterTpl"
              [tableConfig]="tableConfig"
              [columnTemplates]="{name: nameTpl, users: userTpl, queues: queueTpl}"
              [dataSource]="dataSource"
              [sidePannelOptions]="sidePannelOptions"
              fxLayout="row" fxFlex="grow">

  <ng-template #userTpl let-item="row">
    <div *ngIf="item.users" matTooltip="{{ item.users|map:'name'|join:', ' }}">
      <ng-container *ngFor="let user of item.users; let i = index; let last = last">
        <ng-container *ngIf="i < 5">
          <span>{{ user.name }}</span><span *ngIf="!last && i<4">, </span><span *ngIf="i==4 && (item.users).length > 5">... </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(item.users).length > 5">
        <span>({{ 'group.content.agent_more'|translate: {value: (item.users).length - 5} }})</span>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #queueTpl let-item="row">
    <div fxFlexFill>
    {{ groupQueueMap[item.id]|map:'queue_name'|join:', ' }}
  </div>
    <!-- <div *ngIf="groupQueueMap[item.id]" matTooltip="{{ groupQueueMap[item.id]|map:'queue_name'|join:', ' }}">
      <ng-container *ngFor="let q of groupQueueMap[item.id]; let i = index; let last = last">
        <ng-container *ngIf="i < 5">
          <span>{{ q.queue_name }}</span><span *ngIf="!last && i<4">, </span><span *ngIf="i==4 && (groupQueueMap[item.id]).length > 5">... </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(groupQueueMap[item.id]).length > 5">
        <span>({{ 'group.content.agent_more'|translate: {value: (groupQueueMap[item.id]).length - 5} }})</span>
      </ng-container>
    </div> -->
  </ng-template>

  <ng-template #nameTpl let-name="row">
    <div [innerHTML]="renderNameCol(name)"></div>
  </ng-template>

</flex-crud-page>
</ng-container>
