import { datafield, Entity } from '@wephone-core/model/model';
import { IvrCustomMenuEntity } from './ivr_custom_menu';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class SmsTemplateEntity extends Entity {
  static object_type_id = 'sms_template';

  id: number;
  @datafield
  enterprise_id: number;
  @datafield
  name: string;
  @datafield
  sender_name: string;
  @datafield
  content: string;

  get routedIvrCustomMenuList(): IvrCustomMenuEntity[] {
    const result: IvrCustomMenuEntity[] = [];
    const ivrCustomMenuList: IvrCustomMenuEntity[] = EntityManager.getRepository('IvrCustomMenuRepository').getObjectList();

    for (const ivrCustomMenu of ivrCustomMenuList) {
      const routedSmsTemplate = ivrCustomMenu.routed_sms_templates;
      if (routedSmsTemplate && routedSmsTemplate.length) {
        const found = routedSmsTemplate.find(i => i.id === this.id);
        if (found) {
          result.push(ivrCustomMenu);
        }
      }
    }

    return result;
  }
}
