import { Component, Input, OnInit } from '@angular/core';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';

@Component({
  selector: 'app-ivr-menu-mini-block',
  templateUrl: './ivr-menu-mini-block.component.html',
  styleUrls: ['./ivr-menu-mini-block.component.scss']
})
export class IvrMenuMiniBlockComponent implements OnInit {
  @Input() ivrMenu: IvrCustomMenuEntity;
  constructor() { }

  ngOnInit(): void {
  }

}
