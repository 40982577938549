import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class SlackChannelRepository extends BaseRepository {
  ENTITY_CLASS = SlackChannelEntity;

  protected URL_PREFIX = 'slack';
  private URL_GET_SLACK_CHANNEL_LIST = 'list';

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  protected getRemoteData(use_cache = true): Promise<any> {
    const url = this.getUrl(this.URL_GET_SLACK_CHANNEL_LIST);
    if (use_cache && (this.object_list || []).length) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance().get(url).then(() => {
      return this.getObjectList();
    });
  }
}

import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { SlackChannelEntity } from '../entity/slack_channel';
