import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class RoutingAppConference extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.conference;
  private conference: ConferenceEntity;

  setConference(conference: ConferenceEntity): void {
    this.setAppParams({ conference: conference.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.conference) {
      this.conference = EntityManager.getRepository('ConferenceRepository').getObjectById(this.appParams.conference);
    }
  }

  getRelatedObject(): ConferenceEntity {
    return this.conference;
  }

  getDescription(): string {
    const conference_name = this.conference ? this.conference.name : '<invalid>';

    return `To conference ${conference_name}`;
  }
}
