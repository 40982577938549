import { Injectable } from '@angular/core';
import { _tk, _ti } from '@wephone-translation';
import { MessageService } from '@wephone-utils';

@Injectable()
export class ServiceWorkerService {
    private clientId: string;

    constructor(private messageService: MessageService) {
    }

    initServiceWorker(): void {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('./phone-service-worker.js').then(
                (reg: ServiceWorkerRegistration) => {
                    console.log('Object received after register message is', reg);
                    navigator.serviceWorker.addEventListener(
                        'message',
                        event => {
                            this.onMessageFromServiceWorker(event);
                        }
                    );
                }
            );
            navigator.serviceWorker.ready.then(
                registration => {
                    registration.active.postMessage({message: 'hello'});
                }
            );
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                console.log('Service worker controller changed');
                navigator.serviceWorker.controller.postMessage({message: 'hello'});
            });
        }
    }

    onMessageFromServiceWorker(event: MessageEvent): void {
        console.log('event from service worker', event);
        if (!event.data) {
            return;
        }
        if (event.data.message === 'welcome') {
            this.clientId = event.data.clientId;
        } else if (event.data.message === 'makecall') {
            const phone_number = event.data.phone_number;
            this.messageService.broadcast('phone:open_dialer', phone_number);
        } else if (event.data.message === 'notification_clicked') {
            const action = event.data.action;
            if (action === 'answer') {
                this.messageService.broadcast('phone:call_action', {action: 'answer'});
            } else if (action === 'hangup') {
                this.messageService.broadcast('phone:call_action', {action: 'hangup'});
            }
        }
    }

    showNotification(title, options: NotificationOptions): void {
        if ('Notification' in window && Notification.permission === 'granted') {
            navigator.serviceWorker.getRegistration().then((reg: ServiceWorkerRegistration) => {
                options.data = options.data || {};
                options.data.clientId = this.clientId;

                if (!reg) {
                    console.error('No service worker resgistered');
                    return;
                }
                reg.showNotification(title, options);
            });
          }
    }
}
