<div class="pa-1">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div>
      <mat-form-field class="full-width" hideRequiredMarker="true">
        <mat-label>{{'group.content.name' | translate}}</mat-label>
        <input data-ci="name" matInput placeholder="{{'group.content.name' | translate}}" formControlName="name"
          [required]="true">

        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="groups.length">
      <mat-form-field class="full-width">
        <mat-label>{{'group.content.parent' | translate}}</mat-label>
        <flex-select-group-input [groups]="groups" groupType="service"
          placeholder="{{'group.content.parent'|translate}}" formControlName="group"></flex-select-group-input>
        <button mat-icon-button matSuffix type="button" (click)="form.controls['group'].setValue(null)"
          [disabled]="!form.controls['group'].value">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
</div>
