
  <div fxLayout="row">
    <div fxFlex>
      <flex-select
        class="full-width"
        [formControl]="myForm.get('telephone_code')"
        [itemList]="codes"
        [itemTemplate]="itemTemplate"
        [itemOptionTemplate]="itemOptionTemplate"
        [options]="flexSelectOptions"
        [multiple]="false"
      >
        <ng-template #itemTemplate let-item="item">
          <span [attr.data-ci]="'country-code-' + item.isoCode2" country-flag [countryCode2]="item.isoCode2"
            [matTooltip]="'country_codes.' + item.isoCode2.toLowerCase() | translate"></span>
          +{{ (item.countryCodes && item.countryCodes[0]) || '' }}
        </ng-template>
  
        <ng-template #itemOptionTemplate let-item="item">
          <span [attr.data-ci]="'country-code-' + item.isoCode2" country-flag [countryCode2]="item.isoCode2"></span>
          {{ 'country_codes.' + item.isoCode2.toLowerCase() | translate }}
          +{{ (item.countryCodes && item.countryCodes[0]) || '' }}
        </ng-template>
      </flex-select>

    </div>
    <div fxFlex="5px"></div>
    <input fxFlex="120px" matInput [placeholder]="placeholder" (click)="onClickInput($event)" [formControl]="myForm.get('telephone_number')" />
  </div>

