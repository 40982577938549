<flex-select [placeholder]="placeholder" 
              [formControl]="flexSelect" 
              [itemList] = "countryList"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              [multiple]="false">
  <ng-template #itemTemplate let-country="item" >
      <span [attr.data-ci]="'country-code-'+country?.code2" country-flag [countryCode2]="country?.code2"></span>
      {{ 'country_codes.' + country?.code2?.toLowerCase() | translate }}
  </ng-template>
</flex-select>
