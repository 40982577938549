export class ClassRegistry {
    static classMap = {};

    static register(cls: any, name?: string): void {
        ClassRegistry.classMap[name ? name : cls.name] = cls;
    }

    static getClassByName(className): any {
        return ClassRegistry.classMap[className];
    }
}
