import * as _ from 'lodash';
import { GroupRepository } from '@wephone-core/model/repository/group';

export class UserGroupRepository extends GroupRepository {
  ENTITY_CLASS = UserGroupEntity;
  protected URL_PREFIX = 'servicegroup';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  getObjectList(): UserGroupEntity[] {
    return GroupRepository.getInstance<GroupRepository>().getServiceGroupList() as UserGroupEntity[];
  }

  create(data: any = null): IEntity {
    let object_data: any = data;
    if (!object_data) {
      object_data = {};
    }
    object_data.group_type = 'service';
    return super.create(object_data);
  }

  // Do it because cannot call this from parent.
  reloadRelatedData(): void {
    for (const obj of this.getObjectList()) {
      obj.fetchRelatedData();
    }
  }

  clone(source: IUserGroupEntity): IUserGroupEntity {
    const ret: IUserGroupEntity = new UserGroupEntity();
    _.extend(ret, source);
    if (source.users) {
      ret.users = CommonAPI.cloneArray(source.users);
    }
    return ret;
  }
}

import { UserGroupEntity, IUserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { IEntity } from '@wephone-core/model/model.interface';
import { CommonAPI } from '@wephone-core/service/common_api';
