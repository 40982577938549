import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SingletonBase } from '@wephone-utils';
import { HttpEngine } from './http_engine';
import { TranslateService } from '@ngx-translate/core';

interface UserStatsDetail {
  total?: number;
  inbound?: number;
  outbound?: number;
}

export interface UserStatsData {
  number_calls: UserStatsDetail;
  answered_calls: UserStatsDetail;
  argumented_calls: UserStatsDetail;
  time_on_phone: UserStatsDetail;
  average_answer_delay: UserStatsDetail;
  calls_on_hold: UserStatsDetail;
  average_onhold_time: UserStatsDetail;
  average_talk_time: UserStatsDetail;
}

interface UserStatsResponse {
  pause_time: number;
  connection_time: number;
  avg_ring_time_total: number;
  avg_ring_time_inbound: number;
  avg_ring_time_outbound: number;
  acw: number;
  communication_inbound: number;
  communication_outbound: number;
  avg_talk_time_inbound: number;
  avg_talk_time_outbound: number;
  avg_talk_time_total: number;
  outbound_call_count: number;
  outbound_call_answered: number;
  outbound_call_effective: number;
  inbound_call_count: number;
  inbound_call_answered: number;
  inbound_call_effective: number;
  avg_hold_time_total: number;
  avg_hold_time_inbound: number;
  avg_hold_time_outbound: number;
  call_with_onhold_inbound: number;
  call_with_onhold_outbound: number;
}

@Injectable()
export class UserStatsService extends SingletonBase {
  static URL_USER_STATS = '/callcenter/agent/user_stats';

  constructor(private datePipe: DatePipe, private httpEngine: HttpEngine, private translate: TranslateService) {
    super();
  }

  /**
   * 
   * @param startDt: formated string YYYY-MM-DD
   * @param endDt: formated string YYYY-MM-DD
   */
  async getUserStatsInfo(userId: number, startDt: string, endDt: string): Promise<UserStatsData> {
    const params = {
      start_dt: startDt,
      end_dt: endDt,
      user_id: userId
    };

    try {
      const data: UserStatsResponse = await this.httpEngine.apiGetV2(UserStatsService.URL_USER_STATS, params);

      return {
        number_calls: {
          total: data.outbound_call_count + data.inbound_call_count,
          inbound: data.inbound_call_count,
          outbound: data.outbound_call_count,
        },
        answered_calls: {
          total: data.outbound_call_answered + data.inbound_call_answered,
          inbound: data.inbound_call_answered,
          outbound: data.outbound_call_answered,
        },
        argumented_calls: {
          total: data.outbound_call_effective + data.inbound_call_effective,
          inbound: data.inbound_call_effective,
          outbound: data.outbound_call_effective,
        },
        average_talk_time: {
          total: data.avg_talk_time_total,
          inbound: data.avg_talk_time_inbound,
          outbound: data.avg_talk_time_outbound
        },
        time_on_phone: {
          total: data.communication_inbound + data.communication_outbound,
          inbound: data.communication_inbound,
          outbound: data.communication_outbound,
        },
        average_answer_delay: {
          total: data.avg_ring_time_inbound,
          inbound: data.avg_ring_time_inbound,
          outbound: undefined,
        },
        average_onhold_time: {
          total: data.avg_hold_time_total,
          inbound: data.avg_hold_time_inbound,
          outbound: data.avg_hold_time_outbound,
        },
        calls_on_hold: {
          total: data.call_with_onhold_outbound + data.call_with_onhold_inbound,
          inbound: data.call_with_onhold_inbound,
          outbound: data.call_with_onhold_outbound,
        }
      };
    } catch (e) {
      console.error('GetUserStatsInfo Error', e);
    }
  }

}
