export enum PeriodSelectType {
  today = 'today',
  yesterday = 'yesterday',
  this_week = 'this_week',
  last_week = 'last_week',
  this_month = 'this_month',
  last_month = 'last_month',
  this_year = 'this_year',
  // custom = 'custom',
}
