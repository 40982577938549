<form [formGroup]="form" (ngSubmit)="submit()">
  <div>
    <mat-form-field class="full-width">
      <input matInput placeholder="{{'shortcut.content.display_text'|translate}}" formControlName="displayText">
      
      <mat-error [flexFormControlError]="form.get('displayText')"></mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <input matInput placeholder="{{'shortcut.content.destination'|translate}}" formControlName="destination">

      <mat-error [flexFormControlError]="form.get('destination')"></mat-error>
    </mat-form-field>
  </div>
</form>
