import { Entity, datafield } from '@wephone-core/model/model';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { UserRepository } from '@wephone-core/model/repository/user';
import { parseDateTime } from '@wephone-utils';
import { RecallState, RecallStateText } from './missed_call.i';
import { IUserEntity } from './user.i';
import { ICallQueueEntity } from './callqueue.i';

export class MissedCallEntity extends Entity {
  public static object_type_id: string = 'missed_call';

  @datafield
  public calling_number: string;
  @datafield
  public called_number: string;
  @datafield
  public queue_ids: number[];
  @datafield
  public recall_state: RecallState;
  @datafield
  public recall_count: number;
  @datafield
  public missed_call_count: number;
  @datafield
  public recall_call_keyid: number;
  @datafield
  public recall_dt;
  @datafield
  public recall_user_id;
  @datafield
  public start_dt;

  get queue(): ICallQueueEntity {
    if (this.queue_ids && this.queue_ids.length > 0) {
      return CallQueueRepository.getInstance().getObjectById(this.queue_ids[0]);
    }
  }

  get user(): IUserEntity {
    if (this.recall_user_id) {
      return UserRepository.getInstance().getObjectById(this.recall_user_id);
    }
  }

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.recall_dt = this.recall_dt && parseDateTime(this.recall_dt).toJSDate();
    this.start_dt = this.start_dt && parseDateTime(this.start_dt).toJSDate();
  }

  get status(): string {
    return RecallStateText[this.recall_state] || RecallStateText[RecallState.TO_TRY];
  }
}
