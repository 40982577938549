import {
  Component,
  OnInit,
  OnDestroy,
  Self,
  Optional,
  ElementRef,
  Input
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { RoutingServiceEntity } from '@wephone-core/model/entity/routing_service';

@Component({
  selector: 'flex-select-routing-service-input',
  templateUrl: './flex-select-routing-service-input.component.html',
  styleUrls: ['./flex-select-routing-service-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectRoutingServiceFormInput }]
})
export class FlexSelectRoutingServiceFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<RoutingServiceEntity> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  routingServiceList: RoutingServiceEntity[];
  flexSelectOptions: IFlexSelectOptions;

  @Input() isConfigured: boolean;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private readonly em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: RoutingServiceEntity, filterString) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: RoutingServiceEntity, b: RoutingServiceEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setRoutingServiceList();
  }

  /**
   * Hard reload list after create new item
   */
  setRoutingServiceList(): void {
    this.routingServiceList = this.em.getRepository('RoutingServiceRepository').getObjectList();
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  get controlType(): string {
    return 'routing-service-select-form-input';
  }
}
