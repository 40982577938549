import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { DynamicFilterSource, IFlexTableConfig, DialogService, ToastService, IFlexTableSidePannelOptions, regexSearch, FlexCRUDPageComponent } from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConferenceRepository } from '@wephone-core/model/repository/conference';
import { CreateConferencesModalComponent } from '@wephone/modals/create-conferences-modal/create-conferences-modal.component';
import { EditConferenceComponent } from '@wephone/components/edit-conference/edit-conference.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { ConferenceService } from '@wephone/services/conference.service';

@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.scss']
})
export class ConferencesComponent extends FlexBasePage implements OnInit {

  filterValue: string;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditConferenceComponent
  };
  public conferenceForm: FormGroup;
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private fb: FormBuilder,
    private conferenceService: ConferenceService,
    public dialogService: DialogService,
    public toast: ToastService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    const conferenceSource = EntityManager.getRepository('ConferenceRepository').dataSource<ConferenceEntity>();
    this.dataSource = new DynamicFilterSource(conferenceSource, this.filterFn);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('conference.content.name'),
          enableTooltip: true,
          lineClamp: 2,
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (conference: ConferenceEntity) => {
            return conference.name.toLowerCase();
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.conferenceForm = this.fb.group({
      filterValue: ['']
    });

    this.conferenceForm.valueChanges.subscribe(this.onFormValueChange);
  }

  protected async resolveData(): Promise<any> {
    await EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
  }

  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;

    this.dataSource.onFilterChange();
  }

  filterFn = (item: ConferenceEntity): boolean => {
    if (!this.filterValue) {
      return true;
    }

    return regexSearch(item.name, this.filterValue);
  }

  async createNew(): Promise<any> {
    try {
      const newConference = EntityManager.getRepository('ConferenceRepository').create() as ConferenceEntity;
      const dialogRef = this.dialogService.openDialog2(CreateConferencesModalComponent, {
        data: {
          conference: newConference
        }
      });

      const newConferenceObject = await dialogRef.afterClosed().toPromise();

      if (!newConferenceObject || !newConferenceObject.name || !newConferenceObject.password) {
        console.warn('Erorr create conference: Name and password cannot be blank', newConferenceObject);
        return;
      }

      const response = await EntityManager.getRepository<ConferenceRepository>('ConferenceRepository')
        .createAndSave(newConferenceObject);

      this.toast.show(_ti('public.message.create_success'));

      return true;
    } catch (e) {
      const errMsg = e && e.error && e.error.message || e && e.message || '';
      this.toast.showError(_ti('public.message.create_failure') + ` ${errMsg}`);
      return false;
    }
  }

  public async bulkDelete(items: ConferenceEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    const confirmed: boolean = await this.conferenceService.getConfirmDeleteRoutingItems(items);
    if (confirmed) {
      EntityManager.getRepository<ConferenceRepository>('ConferenceRepository').bulkDelete(items).then(
        response => {
          this.flexCrud.closeSidePanel();
          this.toast.show(_ti('public.message.delete_success'));
        },
        response => {
          this.toast.showError(_ti('public.message.delete_failure'));
        }
      );
    }
  }
}
