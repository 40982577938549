import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  IFlexSelectOptions,
  // DynamicFilterSource,
  FlexMatInputWrapper,
  ToastService,
  DialogService
  // FlexSelect
} from '@wephone-utils';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
// import { TranslateService } from '@ngx-translate/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OpeningCalendarEditDialogComponent } from '../opening-calendar-edit-dialog/opening-calendar-edit-dialog.component';
import { IOpeningCalendarEditDialogConfig } from '../calendar-types';
import { _tk, _ti } from '@wephone-translation';
import { CalendarSelectListDialogComponent } from './calendar-select-list-dialog/calendar-select-list-dialog.component';
import { CalendarUtils } from './calendar.utils';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';
import * as _ from 'lodash';

@Component({
  selector: 'calendar-select-form-input',
  templateUrl: './calendar-select.component.html',
  styleUrls: ['./calendar-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CalendarSelectFormInput }]
})
export class CalendarSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<OpeningHourCalendarEntity> {
  static nextId = 0;
  @Input() create: boolean;
  @Input() isTruncate: boolean;
  @Input() buttonType: boolean;
  @Input() displayMode: 'compact' | 'full' = 'full';

  flexSelect: FormControl = new FormControl();
  calendarList: OpeningHourCalendarEntity[];
  flexSelectOptions: IFlexSelectOptions;

  config: IOpeningCalendarEditDialogConfig = {
    editCustomData: false,
    editName: true,
    editGroup: true
  };

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    // private transService: TranslateService,
    public toast: ToastService,
    public dialogService: DialogService,
    // private readonly fb: FormBuilder,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, elRef, cdr);

  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  writeValue(val: OpeningHourCalendarEntity): void {
    const emptyObject = this.getNoCalendarObject();
    this.flexSelect.setValue(val ? val : emptyObject);
  }

  filterItemOnSearch = (item: OpeningHourCalendarEntity): boolean => {
    return !item.is_private ? true : false;
  }

  private getNoCalendarObject(): OpeningHourCalendarEntity {
    const nocalendarObject = EntityManager.getRepository('OpeningHourCalendarRepository').create({
      id: null,
      name: _ti('call_queue.content.no_calendar')
    }) as OpeningHourCalendarEntity;

    return nocalendarObject;
  }

  get controlType(): string {
    return 'calendar-select-form-input';
  }

  createCalendar(): void {
    try {
      const calendarUtils = new CalendarUtils();
      const newName = calendarUtils.getNewName();
      const newCalendar = EntityManager.getRepository<OpeningHourCalendarRepository>(
        'OpeningHourCalendarRepository'
      ).createDefaultCalendar(newName) as OpeningHourCalendarEntity;
      const config = _.cloneDeep(this.config);
      config.showSideSteps = false;
      config.showUsedObjects = false;

      this.dialogService.openDialog2(
        OpeningCalendarEditDialogComponent,
        {
          data: {
            calendar: newCalendar,
            config
          }
        },
        async (calendar: OpeningHourCalendarEntity) => {
          if (calendar) {
            await this.createUpdateCalendarCb(calendar, true);
            this.toast.show(_ti('public.message.create_success'));
          }
        }
      );
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  editCalendar(): void {
    try {
      this.dialogService.openDialog2(
        OpeningCalendarEditDialogComponent,
        {
          data: {
            calendar: this.wrappedControl.value,
            config: this.config
          }
        },
        (calendar: OpeningHourCalendarEntity) => {
          if (calendar) {
            this.createUpdateCalendarCb(calendar, false);
            this.toast.show(_ti('public.message.update_success'));
          }
        }
      );
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  async createUpdateCalendarCb(calendar: OpeningHourCalendarEntity, create = false): Promise<void> {
    const extra_param: any = {
      openinghours: (calendar.openinghours || []).map((o: OpeningHourEntity) => o.dumpObjectData()),
      openinghours_special_date: (calendar.openinghours_special_date || []).map((s: OpeningHourSpecialDateEntity) => s.dumpObjectData())
    };

    const updatedCalendar: OpeningHourCalendarEntity = await EntityManager.getRepository<OpeningHourCalendarRepository>(
      'OpeningHourCalendarRepository'
    ).save(calendar, extra_param);

    if (create) {
      const newCalendarList = [...this.calendarList, updatedCalendar];
      this.calendarList = newCalendarList;
    }

    this.flexSelect.setValue(updatedCalendar);

    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  openCalendarListDialog(): void {
    this.dialogService.openDialog2(
      CalendarSelectListDialogComponent,
      {
        data: {
          calendar: this.flexSelect.value,
          displayMode: this.displayMode,
          hasEmpty: true
        },
        size: 's'
      },
      (calendar: OpeningHourCalendarEntity) => {
        if (calendar) {
          this.flexSelect.setValue(calendar);
        }
      }
    );
  }
}
