import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DialogService,
  DynamicFilterSource,
  IFlexTableConfig,
  IFlexTableSidePannelOptions,
  FlexCRUDPageComponent,
  regexSearch,
} from '@wephone-utils';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ImageRepository } from '@wephone-core/model/repository/image';
import { ImageEntity } from '@wephone-core/model/entity/image';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { ImageEditComponent } from '@wephone/components/image/image-edit/image-edit.component';
import { ImageService } from '@wephone/services/image.service';
import { ImageCreateDialogComponent } from '@wephone/components/image/image-create-dialog/image-create-dialog.component';
import { ImageConfirmDeleteComponent } from '@wephone/components/image/image-confirm-delete/image-confirm-delete.component';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent extends FlexBasePage implements OnInit {
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: ImageEditComponent
  };

  filterString: string;
  form: FormGroup;

  private readonly _imageRepo: ImageRepository;

  constructor(
    private readonly dialogService: DialogService,
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly imageService: ImageService,
  ) {
    super();
    this._imageRepo = this.em.getRepository<ImageRepository>('ImageRepository');
    const imageSource = this._imageRepo.dataSource<ImageEntity>();
    this.dataSource = new DynamicFilterSource(imageSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'public_id',
          label: _tk('image.content.image'),
          sortable: false,
        },
        {
          name: 'name',
          label: _tk('image.content.name'),
          enableTooltip: true,
          lineClamp: 2,
          sortable: true,
          sort: 'asc',
          customSortFn: (sound: ImageEntity) => {
            return sound.name.toLowerCase();
          }
        },

      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: (): any => {
              this.createImage();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: (data: any): any => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });
    this.form.valueChanges.subscribe(this.onFormValueChage);
  }

  protected async resolveData(): Promise<any> {
    await this._imageRepo.loadObjectList();
  }

  private readonly filterFunc = (image: ImageEntity) => {
    return image.is_temp === 0 && this.filterPredicate(image, this.filterString);
  }

  private filterPredicate(image: ImageEntity, filterString: string): boolean {
    return !filterString ? true : regexSearch(image.name, filterString);
  }

  private readonly onFormValueChage = (formValues: any) => {
    this.filterString = (formValues.filterString || '').trim();

    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  async bulkDelete(items: ImageEntity[]): Promise<any> {
    const inUsedObjects = await this.imageService.getInUsedObjects(items);
    if (!_.isEmpty(inUsedObjects)) {
      return this.dialogService.openDialog2(ImageConfirmDeleteComponent, {
        data: {
          inUsedObjects
        },
        size: 's'
      });
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      () => {
        this._bulkDelete(items);
      }
    );
  }

  async _bulkDelete(items): Promise<void> {
    try {
      await this._imageRepo.bulkDelete(items);
      this.showInfo(_ti('public.message.delete_success'));
    } catch (e) {
      this.showErrorMessage(e, _ti('public.message.delete_failure'));
    }
  }

  createImage(): void {
    this.openDialogChangeImage();
  }

  async openDialogChangeImage(image?: ImageEntity): Promise<void> {
    const data = {
      image,
      selectExisting: false,
      showInputName: true
    };

    const dialogRef = this.dialogService.openDialog2(ImageCreateDialogComponent, { data, size: 's' });

    const data_response = await dialogRef.afterClosed().toPromise();
    if (!data_response) {
      return;
    }

    const newImage: any = data_response.file_entry;
    console.log('newImage', newImage);

    if (newImage && newImage.id && (!image || !image.id)) {
      this.successToast(_ti('public.message.create_success'));
      return;
    }

    if (!newImage || !image ||
      !newImage.id || !image.id ||
      newImage.id === image.id
    ) {
      console.log('No entry was replaced');
      return;
    }
  }

}
