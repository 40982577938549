import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { HttpEngine } from '@wephone-core/service/http_engine';

export class SubscriptionPackRepository extends BaseRepository {
  ENTITY_CLASS = SubscriptionPackEntity;
  protected URL_PREFIX = 'subscription_pack';

  getTrialPackage(): SubscriptionPackEntity {
    return this.getObjectList().find(x => x.isTrial());
  }

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  async findValidPacks(): Promise<SubscriptionPackEntity[]> {
    const packs: SubscriptionPackEntity[] = await this.findAll();
    return packs.filter(x => !!x.package_id);
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/subscription_pack'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get subscription pack list error', response);

          return [];
        }
      );
  }
}
