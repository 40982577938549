import { Injectable } from '@angular/core';
import { SingletonBase } from '../utils/singleton';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FlexProgressBarService extends SingletonBase {
  public pendingActionSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public pendingActionCount = 0;

  get pendingActionChange(): Observable<number> {
    return this.pendingActionSubject.asObservable();
  }

  startPendingAction(): void {
    this.pendingActionCount++;
    this.pendingActionSubject.next(this.pendingActionCount);
  }

  stopPendingAction(): void {
    if (this.pendingActionCount > 0) {
      this.pendingActionCount--;
    }
    this.pendingActionSubject.next(this.pendingActionCount);
  }

  reset(): void {
    this.pendingActionCount = 0;
  }
}
