export interface ParticularAgent {
  agent_id: number;
  processed: number;
  success_today: number;
  success: number;
  failure: number;
  remaining: number;
  total: number;
}

export enum CampaignActiveType {
  Active = 1,
  Deactive = 0
}

export enum ArchiveStateType {
  Archived = 1,
  Unarchived = 0
}
