import { AbstractControl } from '@angular/forms';
import { ValidatorService } from '../services/validator.service';

// const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const CommaSepEmailValidator = (control: AbstractControl): { [key: string]: any } | null => {
  const emails = control.value && control.value.split(',') || [];
  const isValid = emails.every((email: string) => !!email && ValidatorService.VALID_EMAIL_PATTERN.test(email));
  return isValid ? null : { wrongEmail: true };
};
