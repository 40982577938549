import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { EavesdropService } from '../../../../../wephone-common/service/eavesdrop.service';
import { HighChartsHelperService } from '@wephone-core/service/high_charts_helper.service';
import { Chart } from 'angular-highcharts';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ChartPeriod } from '../graph-chart-type';
import { LiveQueueData, IChartFilter } from '@wephone-core/service/live-stats.service';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FlexBaseComponent } from '@wephone-core-ui';

@Component({
  selector: 'app-graph-chart-queue',
  templateUrl: './graph-chart-queue.component.html',
  styleUrls: ['./graph-chart-queue.component.scss']
})
export class GraphChartQueueComponent extends FlexBaseComponent implements OnInit, OnDestroy {

  @Input() queue: LiveQueueData;
  @Input() period: ChartPeriod;
  @Input() showEavesdrop: boolean;
  @Input() callFilters: IChartFilter;
  lineChart: Chart;
  queues: { [key: number]: CallQueueEntity } = {};

  constructor(
    private eavesdropService: EavesdropService,
    private em: EntityManager,
    private highChartsHelperService: HighChartsHelperService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    for (const queue of this.em.getRepository('CallQueueRepository').getObjectList()) {
      this.queues[queue.id] = queue;
    }

    this.drawLineChart(this.queue);

    this.addSubscription(
      this.translate.onLangChange.subscribe(() => {
        this.drawLineChart(this.queue);
      })
    );
  }

  async call_eavesdrop_queue(queue_id: number): Promise<any> {
    return this.eavesdropService.call_eavesdrop_queue(queue_id);
  }

  private drawLineChart(queue: LiveQueueData): void {
    if (this.lineChart) {
      this.lineChart.destroy();
    }

    const _op: any = this.highChartsHelperService.get_charts_line_data(this.period, queue['call_stats']['history'], this.callFilters);

    this.lineChart = new Chart({
      chart: {
        height: 300,
        type: 'line'
      },
      credits: _op.credits,
      legend: _op.options.legend,
      plotOptions: _op.options.plotOptions,
      title: _op.options.title,
      tooltip: _op.options.tooltip,
      xAxis: _op.xAxis,
      yAxis: _op.options.yAxis,
      series: _op.series
    });

  }
}
