<div class="queue-agent-container" fxLayout="column" fxFlexFill>
  <div class="panel-heading">
    <div class="order-mode" *ngIf="queueAgentList?.length > 1 ">
      <mat-form-field class="full-width">
        <label mat-label>{{'call_queue.content.call_distribution' | translate}}</label>
        <mat-select [(ngModel)]="selectionMode" (selectionChange)="onSelectionModeChange()"
          placeholder="{{ 'call_queue.content.call_distribution'|translate }}">
          <mat-option [value]="1">{{ 'call_queue.content.in_following_order'|translate }}</mat-option>
          <mat-option [value]="0">{{ 'call_queue.content.load_balancing'|translate }}</mat-option>
          <mat-option [value]="2" *ngIf="allowRingAll">{{ 'call_queue.content.ring_all'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div align="end" *ngIf="queueAgentList.length > 10">
      <flex-search-input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"></flex-search-input>
    </div>
  </div>

  <div class="recording-call-information">
    <span *ngIf="selectionMode == 1">{{ 'queue_agent_list.in_following_order_description'|translate }}</span>
    <span *ngIf="selectionMode == 0">{{ 'queue_agent_list.load_balancing_description'|translate }}</span>
    <span *ngIf="selectionMode == 2">{{ 'queue_agent_list.ring_all_description'|translate }}</span>
  </div>

  <div class="agent-list" fxLayout="row wrap" cdkDropList #{{dragDropId}}="cdkDropList"
    [cdkDropListData]="queueAgentList" [cdkDropListConnectedTo]="[dragDropGroup]" id="{{ dragDropId }}"
    (cdkDropListDropped)="drop($event)">
    <div *ngFor="let queueAgent of queueAgentList; let i = index; last as last" fxFlex.xs="100"
      fxFlex.gt-xs="0 0 calc(50% - 10px)" fxFlex.gt-md="0 0 calc(33.33% - 10px)" fxLayout="column" cdkDrag
      [hidden]="!hasFiltered(queueAgent)">
      <div fxFlexFill fxLayout="column" class="bg-gray agent-wrapper">
        <div fxFlex>
          <agent-item [avatar]="true" [agent]="queueAgent.agent" [actions]="agentActions">
          </agent-item>
          <div class="agent-position" *ngIf="selectionMode == 1">{{ i+1 }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="actions">
    <button type="button" color="accent" mat-stroked-button (click)="openModalAddAgentToQueue()" class="bt-add"
      data-ci="queue_add_agents">
      {{ 'call_queue.content.operators.add'|translate }}
    </button>
  </div>

</div>
