<div fxLayout="column" fxFill>
  <ng-template #tplFilter>
    <form [formGroup]="filterForm">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start">
        <div fxFlex.xs="none">
          <mat-form-field class="full-width">
            <mat-label>{{ 'hotelroom.content.phone_state' | translate }}</mat-label>
            <mat-select placeholder="{{ 'hotelroom.content.select_phone_state' | translate }}"
              formControlName="phone_state">
              <mat-option>{{ 'public.all' | translate }}</mat-option>
              <mat-option *ngFor="let phoneState of phoneStateList" [value]="phoneState.value">
                {{ phoneState.text }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex.xs="none">
          <flex-search-input class="full-width" placeholder="{{ 'public.search' | translate }}"
            formControlName="filter_value" [hintText]="'hotelroom.search.hint'|translate"
            ngClass.xs="search-full-width">
          </flex-search-input>
        </div>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud pageTitle="{{ 'menu.hotelroom'|translate }}" [filterTemplate]="tplFilter"
    [tableConfig]="tableConfig" [dataSource]="dataSource" [exportable]="true" exportFileName="exported_hotel_rooms"
    [columnTemplates]="{room_number: tplRoomNumber, room_state: tplRoomState, sipphone_is_online: tplSipPhoneOnline}"
    [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">

    <ng-template #tplRoomState let-item="row">
      <ng-container [ngSwitch]="item.room_state">
        <span *ngSwitchCase="1" class="text-green">{{ 'hotelroom.content.room_state_occupied'|translate }}</span>
        <span *ngSwitchDefault>{{ 'hotelroom.content.room_state_free'|translate }}</span>
      </ng-container>
    </ng-template>

    <ng-template #tplRoomNumber let-item="row">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [title]="'hotelroom.content.not_found_in_pms'|translate" class="mat-error"
          *ngIf="activePms && item.not_found_in_pms">warning</mat-icon>
        <span>{{ item.room_number }}</span>
      </div>
    </ng-template>

    <ng-template #tplSipPhoneOnline let-item="row">
      <ng-container [ngSwitch]="item.sipphone_is_online">
        <span *ngSwitchCase="1">{{ 'hotelroom.content.phone_state_connected'|translate }}</span>
        <span *ngSwitchCase="0">{{ 'hotelroom.content.phone_state_disconnected'|translate }}</span>
        <span *ngSwitchDefault>{{ 'hotelroom.content.phone_state_nophone'|translate }}</span>
      </ng-container>
    </ng-template>
  </flex-crud-page>
</div>
