import * as _ from 'lodash';
import { Component, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { CallQueueItemEntity } from '@wephone-core/model/entity/callqueueitem';
import { CallQueueSessionEntity } from '@wephone-core/model/entity/callqueuesession';
import { AgentService } from '@wephone/services/agent.service';
import { EavesdropService } from '../../../wephone-common/service/eavesdrop.service';
import { DialogService, PhoneNumberService } from '@wephone-utils';
import { ChooseAgentOrNumberDialog } from '@wephone/modals/agent/dialog-choose-agent-or-number/dialog-choose-agent-or-number';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { CallQueueItemRepository } from '@wephone-core/model/repository/callqueueitem';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { FlexBaseComponent } from '@wephone-core-ui';
import { _ti } from '@wephone-translation';
import { AccessRight } from '@wephone-core/system';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IAgentItemActionMenu } from '@wephone-common/user/agent-item/agent-item.i';
import { SortCallByDuration } from '../dashboard/live/LivePageType';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';

export interface IActiveCallActionMenu {
  icon: string;
  classIcon?: string;
  label: string;
  callback: ((a?: any) => any);
  visible?: ((a?: any) => any);
}
@Component({
  selector: 'active-calls',
  templateUrl: './active-calls.html',
  styleUrls: ['./active-calls.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveCallsPage extends FlexBaseComponent implements OnDestroy {
  PERIODICAL_UPDATE = true;
  realtime_pushapi = true;
  @Input() dragDropEnable: boolean;
  @Input() callDirection: string;
  @Input() sortCallByDuration: SortCallByDuration;
  @Input() filterGroupIds: number[];
  @Input() filterUngroupedAgents: boolean;
  @Output() readonly droppedCall: EventEmitter<{ event: CdkDragDrop<string[]>; call: CallQueueItemEntity; itemData?: any }> = new EventEmitter();

  actions: IActiveCallActionMenu[] = [];
  showActionBtn: any = {};
  menuOpened: any = {};
  hasFeatureGroup: boolean;
  filteredQueueIds: number[];

  private currentUser: IUserEntity;
  private queueItemRepo: CallQueueItemRepository;

  constructor(
    private authService: AuthenticationService,
    private dialogService: DialogService,
    private agentService: AgentService,
    private eavesdropService: EavesdropService,
    private configManager: ConfigManager,
    private em: EntityManager,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
    this.callDirection = this.callDirection || 'bold';
    this.queueItemRepo = this.em.getRepository<CallQueueItemRepository>('CallQueueItemRepository');
    this.currentUser = this.authService.getUser();
    this.hasFeatureGroup = this.hasFeature('SERVICE_GROUP');
  }

  async resolveData(): Promise<any> {
    if (!this.authService.isAdmin()) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      this.filteredQueueIds = myProfile.managed_queue_ids;
    }

    if (!this.sortCallByDuration) {
      this.sortCallByDuration = SortCallByDuration.RECENT_CALL_FIRST;
    }

    this.actions = [
      {
        icon: 'headset',
        label: 'livecall.actions.eavesdrop',
        classIcon: 'text-success',
        callback: (item: CallQueueItemEntity) => {
          return this.callEavesdrop(item);
        },
        visible: (item: CallQueueItemEntity) => {
          return this.hasAccessRightEavesdrop() && this.isSessionBridged(item) && this.canEavesdrop(item) && !this.isEavesDroppedByMe(item.session);
        }
      },
      // {
      //   icon: 'headset',
      //   label: 'livecall.eavesdropping',
      //   callback: (item: CallQueueItemEntity) => {
      //     return this.callEavesdrop(item);
      //   },
      //   visible: (item: CallQueueItemEntity) => {
      //     return this.hasAccessRightEavesdrop() && this.isSessionBridged(item) && this.canEavesdrop(item) && this.isEavesDroppedByMe(item.session);
      //   }
      // },
      {
        icon: 'stop',
        classIcon: 'text-warn',
        label: 'livecall.actions.hangup',
        callback: (item: CallQueueItemEntity) => {
          return this.hangupCall(item.id);
        },
        visible: (item: CallQueueItemEntity) => {
          return this.canHangup(item);
        }
      },
      {
        icon: 'more',
        label: 'livecall.actions.send_to',
        callback: (item: CallQueueItemEntity) => {
          return this.sendUnansweredCall(item.id);
        },
        visible: (item: CallQueueItemEntity) => {
          return (this.is_admin || this.is_supervisor) && !this.isSessionBridged(item);
        }
      }
    ];
  }

  getActiveActions(item: CallQueueItemEntity): IActiveCallActionMenu[] {
    return this.actions && this.actions.filter(x => !x.visible || x.visible(item)) || [];
  }

  get active_calls(): CallQueueItemEntity[] {
    const callList: CallQueueItemEntity[] = this.queueItemRepo.getObjectList()
      .filter(
        item => {
          return this._fildCallDirection(item) && this._filterCall(item);
        }
      );

    return _.orderBy(
      callList,
      ['bridged_dt'],
      [this.sortCallByDuration === SortCallByDuration.RECENT_CALL_FIRST ? 'desc' : 'asc']
    );
  }

  get is_admin(): boolean {
    return this.authService.isAdmin();
  }

  get is_supervisor(): boolean {
    return this.authService.isSupervisor();
  }

  onMouseEnter(i: number): void {
    this.showActionBtn[i] = true;
    // if (!this.menuOpened.hasOwnProperty(i)) {
    //   this.menuOpened[i] = true;
    // }
  }

  onMouseLeave(i: number): void {
    if (!this.menuOpened[i]) {
      this.showActionBtn[i] = false;
    }
  }

  onMenuOpened(i: number): void {
    this.showActionBtn[i] = true;
    this.menuOpened[i] = true;
    console.log('onMenuOpened');
  }
  onMenuClosed(i: number): void {
    this.showActionBtn[i] = false;
    this.menuOpened[i] = false;
    console.log('onMenuClosed');
  }

  handingUser(item: CallQueueItemEntity): IUserEntity {
    const agent: AgentEntity = this.handlingAgent(item);
    if (agent && agent.user) {
      return agent.user;
    }
  }

  // showActionButton(index: number): void {
  //   for (const idx of Object.keys(this.showActionBtn)) {
  //     this.showActionBtn[idx] = false;
  //   }
  //   this.showActionBtn[index] = true;
  // }

  handingAgentName(item: CallQueueItemEntity): string {
    const agent = this.handlingAgent(item);
    if (agent) {
      return agent.name;
    }
  }

  private _fildCallDirection(item: CallQueueItemEntity): boolean {
    if (
      this.callDirection === 'both' ||
      (this.callDirection === 'inbound' && item.is_outgoing === 0) ||
      (this.callDirection === 'outbound' && item.is_outgoing === 1)
    ) {
      return true;
    }

    return false;
  }

  private _filterCall(call: CallQueueItemEntity): boolean {
    if (this.filteredQueueIds && !_.includes(this.filteredQueueIds, call.call_queue_keyid)) {
      return false;
    }

    if (this.authService.isWatcher() &&
      (!call.group_id || !_.isEmpty(this.currentUser.group_ids) && !_.includes(this.currentUser.group_ids, call.group_id))
    ) {
      return false;
    }

    // Filter by selected group
    if (this.hasFeatureGroup) {
      if ((this.filterGroupIds || []).length === 0 && call.group_id) {
        return true;
      }

      // Filter by ungrouped agent if there's no group
      if (!call.group_id) {
        return this.filterUngroupedAgents || false;
      }
      return _.includes(this.filterGroupIds, call.group_id);
    }
    return true;
  }

  hangupCall(call_id: number): void {
    if (!call_id) {
      console.error('hangup_call: Invalid call_id', call_id);

      return;
    }

    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('livecall.message.hangup_call_confirm'),
      async () => {
        try {
          await this.agentService.hangupCall(call_id);
          this.showInfo(_ti('public.success'));
        } catch (e) {
          this.showError(e.error && e.error.message || _ti('public.failure'));
        }
      }
    );
  }

  callEavesdrop(item: CallQueueItemEntity): Promise<any> {
    const sessionId: number = item.session && item.session.id;
    if (!sessionId) {
      console.error('No session id');
      return;
    }
    return this.eavesdropService.call_eavesdrop(sessionId);
  }

  private handlingAgent(item: CallQueueItemEntity): AgentEntity {
    if (!item.session || !item.session.operator_id) {
      return;
    }

    return this.getAgentById(item.session.operator_id);
  }

  getAgentById(agent_id: number): AgentEntity {
    if (!agent_id) {
      console.error('getAgentById: Invalid agent_id ', agent_id);

      return;
    }

    return this.em.getRepository<AgentRepository>('AgentRepository').getObjectById(agent_id);
  }

  async sendUnansweredCall(queue_item_id: number): Promise<any> {
    const dialog_params = {
      availalbe: true
    };

    await this.dialogService.openDialog2(ChooseAgentOrNumberDialog, { data: dialog_params }, (result: any) => {
      if (!result) {
        return;
      }
      this.queueItemRepo.forceAgentForCall(queue_item_id, result.agent, result.phone_number);
    });
  }

  // getGroupName(item: CallQueueItemEntity): boolean {
  //   if (!item) {
  //     console.error('show_group_name: Invalid item', item);

  //     return;
  //   }

  //   return item.group_id && item.group && this.hasFeatureGroup;
  // }

  canEavesdrop(item: CallQueueItemEntity): boolean {
    const agent: AgentEntity = this.handlingAgent(item);
    if (agent) {
      return this.currentUser.canEavesdrop(agent.user);
    }
  }

  hasAccessRightEavesdrop(): boolean {
    return this.currentUser &&
      (this.currentUser.isAdmin() ||
        this.currentUser.isSupervisor() ||
        !this.authService.isAdmin() && !this.authService.isSupervisor() && this.authService.hasAccessRight(AccessRight.Eavesdrop));
  }

  isEavesDroppedByMe(session: CallQueueSessionEntity): boolean {
    return session && session.eavesdropper_ids && session.eavesdropper_ids.indexOf(this.authService.getUserId()) !== -1;
  }

  /* Queue item state template helper*/
  isSessionOnhold(item: CallQueueItemEntity): boolean {
    return item.session && item.session.is_onhold ? true : false;
  }

  isSessionBridged(item: CallQueueItemEntity): boolean {
    return item.session && item.session.bridged_dt;
  }

  private hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  normalizeNumber(number: string): string {
    return PhoneNumberService.getInstance().getDisplayNumber(number);
  }

  canDrag(queueItem: CallQueueItemEntity): boolean {
    return (this.is_admin || this.is_supervisor) && !this.isSessionBridged(queueItem);
  }

  dropCall(event: CdkDragDrop<any>): void {
    if (!this.dragDropEnable) {
      return;
    }

    console.log('dropCall event', event);
    const itemData = event.item.data;
    const callId = +event.container.element.nativeElement.getAttribute('call-id');
    const call: CallQueueItemEntity = this.active_calls.find(c => c.id === callId);
    this.droppedCall.emit({ event, call, itemData });
  }

  canHangup(call: any): boolean {
    const session: CallQueueSessionEntity = call.session;
    if (!session) {
      return this.is_admin;
    }

    return this.is_admin || this.authService.getAgentId() === session.operator_id;
  }
}
