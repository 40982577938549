import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import * as _ from 'lodash';
import { FileEntryRepository } from '../repository/fileentry';
import { FileEntryEntity } from './fileentry';
import { IIvrEvaluationQuestionDataType } from './ivr_evaluation.i';

export class IvrEvaluationEntity extends Entity {
  static object_type_id: string = 'ivr_evaluation';

  id: number;
  @datafield
  name: string;
  @datafield
  welcome_msg_id: number;
  @datafield
  thankyou_msg_id: number;
  @datafield
  question_data: IIvrEvaluationQuestionDataType[];

  welcome_msg: FileEntryEntity;
  thankyou_msg: FileEntryEntity;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    if (typeof object_data.question_data === 'string') {
      object_data.question_data = JSON.parse(object_data.question_data) || [];
    }

    super.setObjectData(object_data, fetch_related_data);
  }

  fetchRelatedData(): void {
    super.fetchRelatedData();
    const fileEntryRepo = EntityManager.getRepository<FileEntryRepository>('FileEntryRepository');
    this.welcome_msg = this.welcome_msg_id ? fileEntryRepo.getObjectById(this.welcome_msg_id) : undefined;
    this.thankyou_msg = this.thankyou_msg_id ? fileEntryRepo.getObjectById(this.thankyou_msg_id) : undefined;
  }
}
