<div class="page-root">
  <div class="page-header">
    <h2>{{ 'enterprise_params.invoices_and_payment'|translate }}</h2>
  </div>

  <div class="table-container">
    <form [formGroup]="form" *ngIf="form" fxLayout="column" fxFlex="grow">
      <mat-card class="item-detail shadow-none" fxLayout="column" fxFlex="grow">
        <div fxLayout="column" fxFlex="grow">
          <mat-toolbar class="mat-primary" fxFlex="62px" fxLayout="row" fxLayoutAlign="start center">
            <div class="button-container" *ngIf="formHasChanged()">
              <button type="submit" mat-button class="border-white-button" (click)="submit()">
                {{ 'public.save'|translate }}
              </button>
              <button type="button" mat-button class="border-white-button ml-1" (click)="resetForm()">
                {{ 'public.cancel'|translate }}
              </button>
            </div>
          </mat-toolbar>

          <div fxLayout="column" fxFlex="grow" class="side-pannel-content">

            <div class="wrapper">
              <div class="col-left">
                <div class="inner">
                  <ul class="stepper stepper-vertical">
                    <li *ngFor="let step of steps; let i=index;">
                      <a (click)="mainArea.scrollToElement(step.id)" class="step {{ step.id }}"
                        [ngClass]="{'active': mainArea.activeAnchor === step.id}">
                        <span class="circle">{{ i+1 }}</span>
                        <span class="label">{{ step.label | translate }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
                <div class="inner">
                  <mat-card class="shadow-none" id="enterprise_config" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">payment</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.enterprise_configuration' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group" class="full-width">

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.vat_number' | translate }}</mat-label>
                          <input matInput placeholder="{{ 'enterprise_info.vat_number' | translate }}"
                            formControlName="vat_number">
                            
                          <mat-error [flexFormControlError]="form.get('vat_number')"></mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.billing_contact_email' | translate }}</mat-label>
                          <input matInput placeholder="{{ 'enterprise_info.billing_contact_email' | translate }}"
                            formControlName="billing_contact_email">
                          <mat-hint>{{ 'input.email.hint_multi_emails'|translate }}</mat-hint>
                          
                          <mat-error [flexFormControlError]="form.get('billing_contact_email')"></mat-error>
                        </mat-form-field>
                      </div>

                    </mat-card-content>
                  </mat-card>

                  <mat-divider class="section-divider"></mat-divider>

                  <mat-card class="shadow-none" id="payment_info" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">payment</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.payment_info' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <enterprise-payment-info></enterprise-payment-info>
                      <!-- <div fxLayout="row" fxLayoutAlign="start center" class="pt-1">
                        <div fxFlex class="pr-1">
                          <strong>{{ 'credit_recharge.content.remaining_credit' | translate }}: </strong>
                          {{ (myEnterprise.remaining_credit || '0') | currency:(myEnterprise.currency || 'EUR') }}
                        </div>
                        <div fxFlex="25" align="end">
                          <button mat-button color="blue" (click)="openModalPayCreditRecharge()">
                            {{'credit_recharge.content.recharge' | translate }}
                          </button>
                        </div>
                      </div> -->
                    </mat-card-content>
                  </mat-card>

                  <mat-divider class="section-divider"></mat-divider>

                  <mat-card class="shadow-none" id="billing_plan" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">payment</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.billing_plan' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <app-enterprise-billing-plan></app-enterprise-billing-plan>
                    </mat-card-content>

                  </mat-card>

                  <mat-divider class="section-divider"></mat-divider>

                  <mat-card class="shadow-none" id="subscription" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">payment</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'client.content.subscription'|translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group" *ngIf="subscription && subscriptionPack">
                      <div>
                        <p>
                          <label>{{ 'client.content.subscription_package'|translate }}</label>
                          <strong class="mx-1" [attr.data-ci]="'subscription-name'">{{ subscriptionPack?.name
                            }}</strong>
                          <!-- <button mat-stroked-button color="primary"
                            (click)="changeSubscription()">{{ 'did.form.change'|translate }}
                          </button> -->
                        </p>
                        <p *ngIf="subscription.expired_dt;else NoExpiredDtBlock">
                          <label>
                            {{ 'public.from'|translate }}
                            <strong [attr.data-ci]="'subscription-start_dt'">{{ subscription.start_dt |
                              timeFormatDateShort }}</strong>
                          </label>
                          <label class="ml-1">
                            {{ 'public.to'|translate }}
                            <strong [attr.data-ci]="'subscription-expired_dt'">{{ subscription.expired_dt |
                              timeFormatDateShort }}</strong>
                          </label>
                          <!-- <label>{{ 'client.content.subscription_expire_date'|translate }}</label> -->
                        </p>
                        <ng-template #NoExpiredDtBlock>
                          <p>
                            <label>
                              {{ 'public.since'|translate }}
                              <strong [attr.data-ci]="'subscription-start_dt'">{{ subscription.start_dt |
                                timeFormatDateShort }}</strong>
                            </label>
                          </p>
                        </ng-template>
                      </div>
                    </mat-card-content>
                  </mat-card>

                  <mat-divider class="section-divider"></mat-divider>

                  <mat-card class="shadow-none invoice-history" id="invoice_history" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">history</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.invoice_history' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <table mat-table [dataSource]="invoiceDataSource" class="flat-table" matSort>
                        <ng-container matColumnDef="invoice_number">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'invoice.content.invoice_id' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let invoice">
                            <!-- <div [innerHTML]="renderId(invoice_number)"></div> -->
                            {{ '#' + invoice.invoice_number }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="invoice_dt">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'invoice.content.invoice_date' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let invoice">
                            {{ invoice.invoice_dt | timeFormatDateTimeShort }}
                            <!-- <div [innerHTML]="renderDate(invoice_dt)"></div> -->
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="total">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'invoice.content.total' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <span>{{ element.total | currency:element.currency:'symbol-narrow': '0.2-2' }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="download">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'public.button.download' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="'public.button.download' | translate" color="primary"
                              [disabled]="!element.isPdfReady" (click)="downloadCsv(element)">
                              <mat-icon>cloud_download</mat-icon>
                            </button>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>

          </div>
        </div>
      </mat-card>
    </form>
  </div>
