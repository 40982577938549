import { Component } from '@angular/core';
import { VoiceMailRepository } from '@wephone-core/model/repository/voicemail';
import { IEntity } from '@wephone-core/model/model.interface';
import { VoiceMailEntity } from '@wephone-core/model/entity/voicemail';
import { DialogService } from '@wephone-utils';
import { TranslatePipe } from '@ngx-translate/core';
import { MessageService } from '@wephone-utils';

// create your cellRenderer as a Angular component
@Component({
  selector: 'voicemail-action-renderer',
  template: `
    <button mat-button icon-only (click)="deleteVoicemail()"><mat-icon>trash</mat-icon></button>
    `,
  providers: [TranslatePipe]
})
export class VoicemailActionCellRenderer {
  private params: any;
  private isFileReady;

  constructor(
    private dialogService: DialogService,
    private translatePipe: TranslatePipe,
    public messageService: MessageService
  ) {}

  agInit(params: any): void {
    this.params = params;
    //        console.error("paaaams", params);
    this.isFileReady = params && params.data ? params.data.file_entry_ready === 1 : false;
  }

  protected translate(key: string, params: any = {}) {
    return this.translatePipe.transform(key, params);
  }

  //    private downloadVoicemail () {
  //    	let entity:any = this.params.data;
  //  	  	console.log("download", entity);
  //  	  	let download_url = this.SoundService.getFileUrl(entity.sound_id, true);
  //  	  	this.$window.open(download_url, '_blank');
  //    }

  async deleteVoicemail() {
    let entity: any = this.params.data;
    console.log('delete', entity);

    this.dialogService.confirmDialog(
      this.translate('dialogs.confirmation'),
      this.translate('user.title.delete'),
      () => {
        this.bulkDelete([entity]);
      }
    );
  }

  public bulkDelete(entities: Array<any>) {
    let voicemail_repository: VoiceMailRepository = VoiceMailRepository.getInstance();

    let items: Array<IEntity> = [];
    for (let entity of entities) {
      let item = voicemail_repository.create();
      item.setObjectData(entity);
      items.push(item);
    }
    //        console.error("deleting",items);
    voicemail_repository.deleteItems(items).then(
      data => {
        //update unlisten
        //                this.unlistenInfo(this.message_type);
        //                this.reloadClick();
        //          	  this.showSuccessMessage(this.translate('voicemail.title'), this.translate('public.message.delete_success'));
        //            	console.error("deleteItems",data);
        this.messageService.broadcastDatasource('pbx_voicemail_user');
      },
      resp => {
        console.error('Delete Voicemail Error: ', resp);
        //                this.showErrorMessage(this.translate('voicemail.title'), this.translate('public.message.delete_failure') + ' ' + resp.data.error.message);
      }
    );
  }
}
