import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-screen',
  templateUrl: 'loading-screen.html',
  styleUrls: ['loading-screen.scss']
})
export class LoadingScreenComponent {
  @Input()
  public errorMessage: string;

  constructor() { }
}
