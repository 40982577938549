import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, IFlexDialogConfig, ToastService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { SipPhoneProvisioningTokenEntity } from '@wephone-core/model/entity/sip_phone_provisioning_token';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { SystemParam } from '@wephone-core/system';

interface DataType {
  item: SipPhoneProvisioningTokenEntity;
}

@Component({
  selector: 'app-show-provisioning-token',
  templateUrl: './show-provisioning-token.component.html',
  styleUrls: ['./show-provisioning-token.component.scss'],
})
export class ShowProvisioningTokenModalComponent extends DialogComponentBase implements OnInit {

  constructor(
    dialogRef: MatDialogRef<ShowProvisioningTokenModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DataType,
    private readonly clipboard: Clipboard,
    public configManager: ConfigManager,
    public toast: ToastService,
    cdr: ChangeDetectorRef,
  ) {
    super(dialogRef, cdr);
  }

  dialogTitle = _tk('public.show');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.cancel'),
      action: () => {
        this.cancel();
      },
      visible: () => {
        return true;
      },
    }
  ];

  provisioningToken = '';

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.item && this.data.item.token) {
      const provisioningBaseUrl: string = this.configManager.getSystemParam(SystemParam.provisioning_url) || environment.apiUrl;
      this.provisioningToken = `${provisioningBaseUrl}/ws-v2/noauth/sipphone-config/${this.data.item.token}`;
    }
  }

  copy(): void {
    if (!this.provisioningToken) {
      return;
    }
    this.clipboard.copy(this.provisioningToken);
    this.toast.showSuccess(_ti('clipboard.copied'));
  }

  cancel(): void {
    this.dismiss();
  }
}
