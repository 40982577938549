import { datafield, Entity } from '@wephone-core/model/model';
import * as _ from 'lodash';

export class EnterpriseContactEntity extends Entity {
  public static object_type_id = 'enterprise_contact';

  @datafield
  public first_name: string;
  @datafield
  public last_name: string;
  @datafield
  public address_home: string;
  @datafield
  public address_work: string;
  @datafield
  public company: string;
  @datafield
  public email: string;
  @datafield
  public tel_home: string;
  @datafield
  public tel_work: string;
  @datafield
  public title: string;
  @datafield
  public campaign_id: number;
  @datafield
  public custom_data: any;

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    // store json key as string instead of number
    if (this.custom_data) {
      const custom_data = _.cloneDeep(this.custom_data);
      for (const field_id in custom_data) {
        delete this.custom_data[field_id];
        this.custom_data[field_id.toString()] = custom_data[field_id];
      }
    }
  }
}
