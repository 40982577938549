<div class="page-root">
  <div class="page-header">
    <h2>{{'enterprise_params.content.oauth_settings_enabled'|translate}}</h2>
  </div>

  <div class="table-container" fxLayout="column">
    <form [formGroup]="form" fxLayout="column" *ngIf="form" fxFlex="grow">
      <mat-card class="item-detail shadow-none" fxLayout="column" fxFlex="grow">
        <div fxLayout="column" fxFlex="grow">
          <mat-toolbar class="mat-primary" fxFlex="62px" fxLayout="row" fxLayoutAlign="start center">
            <div class="button-container" *ngIf="formHasChanged()">
              <button data-ci="btn-save" type="button" mat-button class="border-white-button" (click)="save()">
                {{ 'public.save'|translate }}
              </button>
              <button type="button" mat-button class="border-white-button ml-1" (click)="resetForm()">
                {{ 'public.cancel'|translate }}
              </button>
            </div>
          </mat-toolbar>

          <div fxLayout="column" fxFlex="grow" class="side-pannel-content">
            <div class="ma-1" fxLayout="column" *ngFor="let key of objectKeys(form.controls)">

              <div class="full-width" fxLayout="row" *ngIf="oAuthSettingNames">
                <div fxFlex="100px">
                  <img src="assets/images/{{ oAuthSettingNames[key].type }}-logo.png" height="20px">
                </div>
                <label fxFlex="300px" fxFlex.xs="250px">
                  {{ oAuthSettingNames[key].label }}
                </label>
                <div fxFlex>
                  <mat-slide-toggle
                    [attr.data-ci]="'oauth-' + oAuthSettingNames[key].name + '-' + oAuthSettingNames[key].label"
                    [formControl]="form.controls[key]" color="primary">
                    <span [hidden]="form.controls[key].value" class="mat-text-muted">{{ 'public.button.no'
                      | translate }}</span>
                    <span [hidden]="!form.controls[key].value" class="mat-text-primary">{{ 'public.button.yes'
                      | translate }}</span>
                  </mat-slide-toggle>
                </div>

              </div>

            </div>
          </div>
        </div>
      </mat-card>
    </form>
  </div>
</div>
