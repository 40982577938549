import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AsciiValidator, EditingComponent, NoWhitespaceValidator, SmsMaxLength, SmsMaxValidator, ToastService } from '@wephone-utils';
import * as _ from 'lodash';
import { _tk, _ti } from '@wephone-translation';
import { FormBuilder, Validators } from '@angular/forms';
import { SmsTemplateEntity } from '@wephone-core/model/entity/sms_template';
import { SmsTemplateRepository } from '@wephone-core/model/repository/sms_template';
import { NotPhoneNumberValidated } from '@wephone/services/form-validator';
import { count } from 'sms-length';
import { SmsMessageCount, SmsService } from '@wephone-sms/service/sms.service';

@Component({
  selector: 'app-sms-template-edit',
  templateUrl: './sms-template-edit.html',
  styleUrls: ['./sms-template-edit.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsTemplateEditComponent extends EditingComponent implements OnInit, OnDestroy {
  @Input() editingItem: SmsTemplateEntity;
  private readonly smsTemplateRepo = SmsTemplateRepository.getInstance<SmsTemplateRepository>();

  smsTemplate: SmsTemplateEntity;
  smsContentInfo: SmsMessageCount;
  smsMaxLength: number = SmsMaxLength;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toastService: ToastService,
    private readonly smsService: SmsService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.smsTemplate = this.editingItem;
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.smsTemplate.name, [Validators.required, Validators.maxLength(50), NoWhitespaceValidator]],
      sender_name: [this.smsTemplate.sender_name, [
        Validators.maxLength(11), NoWhitespaceValidator, AsciiValidator, NotPhoneNumberValidated()
      ]],
      content: [this.smsTemplate.content, {
        validators: [Validators.required, NoWhitespaceValidator],
        updateOn: 'change'
      }],
      // content: [this.smsTemplate.content, [Validators.required, Validators.maxLength(160), NoWhitespaceValidator]],
    });

    this.setSmsContentInfo();
    this.setValidatorsSmsMessage();

    this.addSubscription(
      this.form.valueChanges.subscribe(() => {
        this.onFormValueChange();
      })
    );

    this.addSubscription(
      this.form.get('sender_name').valueChanges.subscribe(() => {
        this.setValidatorsSmsMessage();
      })
    );

    this.addSubscription(
      this.form.get('content').valueChanges.subscribe(() => {
        this.setSmsContentInfo();
        this.form.get('content').markAsTouched();
      })
    );
  }

  hasLimitedSms(): boolean {
    return !!this.form.get('sender_name').value;
  }

  private setSmsContentInfo(): void {
    this.smsContentInfo = this.smsService.getSmsMessageCount(this.form.get('content').value);
  }

  private setValidatorsSmsMessage(): void {
    if (this.form.get('sender_name').value) {
      this.form.get('content').setValidators([Validators.required, SmsMaxValidator, NoWhitespaceValidator]);
    } else {
      this.form.get('content').setValidators([Validators.required, NoWhitespaceValidator]);
    }
  }

  private getFormResetData(): any {
    return {
      name: this.smsTemplate.name,
      sender_name: this.smsTemplate.sender_name,
      content: this.smsTemplate.content,
    };
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  async submitForm(): Promise<void> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.toastService.showError(_ti('public.message.data_invalid'));
        return undefined;
      }
      const updateData = {
        name: this.form.get('name').value,
        sender_name: this.form.get('sender_name').value,
        content: this.form.get('content').value,
      };
      const updateFields = Object.keys(updateData);
      const res = await this.smsTemplateRepo.saveAttrs(this.smsTemplate, updateFields, updateData);
      this.toastService.showSuccess(_ti('public.message.update_success'));
      if (res && res.object_id) {
        this.smsTemplate = this.smsTemplateRepo.getObjectById(res.object_id);
      }
      this.resetForm();
    } catch (error) {
      let errorMSG = _ti('public.message.update_failure');
      if (error) {
        if (error.message) {
          errorMSG = error.message;
        } else if (error.error && error.error.message) {
          errorMSG = error.error.message;
        }
      }
      this.toastService.showError(errorMSG);
    }
  }
}
