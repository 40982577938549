import { Entity, mapped_datafield, datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { HotelRoomEntity } from '@wephone-core/model/entity/hotelroom';
import { ISipPhoneCustomConfig, ISipphoneEntity, ISipPhoneKeyConfig, PhoneModelType, SipphoneKeyConfigType, SipphonePhoneModeType } from './sipphone.i';
import { parseDateTime } from '@wephone-utils';
import { IDidEntity } from './did.i';
import { DidEntity } from './did';
import { CrmRoutingRuleEntity } from './crm_routing_rule';
import * as _ from 'lodash';
import { CallingProfileRepository } from '../repository/callingprofile';
import { CallingProfileEntity } from './callingprofile';
import { IvrCustomMenuEntity } from './ivr_custom_menu';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { UserRepository } from '../repository/user';
import { HotelRoomRepository } from '../repository/hotelroom';
import { IHotelRoomEntity } from './hotelroom.i';
import { ImageEntity } from './image';
import { ImageRepository } from '../repository/image';
export class SipPhoneEntity extends Entity implements ISipphoneEntity {
  static object_type_id = 'sipphone';
  static PHONE_MODE_OFFICE = 1;
  static PHONE_MODE_CALLCENTER = 2;

  id: number;
  @datafield extension: string;
  @datafield password: string;
  @datafield user_id: number;
  @datafield is_online: number;
  @datafield last_user_agent: string;
  @datafield enabled: number;
  @datafield phone_mode: SipphonePhoneModeType;
  @datafield calling_profile_id: number;
  @mapped_datafield('calling_number') calling_number_id: number;
  @datafield description: string;
  @datafield last_online_dt: any;
  @datafield hotel_room_id: number;
  @datafield key_config: ISipPhoneKeyConfig[];
  @datafield custom_config: ISipPhoneCustomConfig;
  @datafield phone_model: PhoneModelType;
  @datafield mac_addr: string;

  get backgroundImage(): ImageEntity {
    if (!this.custom_config || !this.custom_config.background_image) {
      return null;
    }

    return (EntityManager.getInstance().getRepository('ImageRepository') as ImageRepository).getObjectByPublicId(this.custom_config.background_image);
  }

  get extension_with_owner(): string {
    let ret = `${this.extension}`;
    const owner = this.user && this.user.name || this.hotelroom && this.hotelroom.display_room_number || '';

    if (owner) {
      ret += ` ${owner}`;
    }
    return ret;
  }

  get did_calling_number(): IDidEntity {
    if (this.calling_number_id) {
      return EntityManager.getRepository('DidRepository').getObjectById(this.calling_number_id);
    }
  }

  get calling_profile(): CallingProfileEntity {
    if (this.calling_profile_id) {
      return CallingProfileRepository.getInstance().getObjectById(this.calling_profile_id);
    }
  }

  set did_calling_number(did: IDidEntity) {
    this.calling_number_id = did ? did.id : null;
  }

  get user(): IUserEntity {
    if (this.user_id) {
      return EntityManager.getRepository<UserRepository>('UserRepository').getObjectById(this.user_id);
    }
  }

  set user(user: IUserEntity) {
    this.user_id = user ? user.id : null;
  }

  get hotelroom(): IHotelRoomEntity {
    if (this.hotel_room_id) {
      return EntityManager.getRepository<HotelRoomRepository>('HotelRoomRepository').getObjectById(this.hotel_room_id);
    }
  }

  set hotelroom(hotelRoom: IHotelRoomEntity) {
    this.hotel_room_id = hotelRoom ? hotelRoom.id : null;
  }

  dumpObjectData(field_list: string[] = null, use_mapped_name: boolean = false): any {
    const data = super.dumpObjectData(field_list, use_mapped_name);

    // user_id
    if (this.user) {
      data['user_id'] = this.user.id;
    } else {
      data['user_id'] = null;
    }
    // calling_number_id
    if (this.did_calling_number) {
      data['calling_number_id'] = this.did_calling_number.id;
    } else {
      data['calling_number_id'] = null;
    }

    // hotel_room_id
    if (this.hotelroom) {
      data['hotel_room_id'] = this.hotelroom.id;
    } else {
      data['hotel_room_id'] = null;
    }

    return data;
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.last_online_dt = this.last_online_dt && parseDateTime(this.last_online_dt);
  }

  setDefaultKeyConfig(keyLengh: number = 10): void {
    this.key_config = [];
    for (let i = 0; i < keyLengh; i++) {
      const newKeyConfig: ISipPhoneKeyConfig = {
        type: SipphoneKeyConfigType.Undefined,
        data: null,
        label: null
      };

      this.key_config.push(newKeyConfig);
    }
  }

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      if (did.routed_sipphone && did.routed_sipphone.id === this.id ||
        did.out_office_hours_routed_sipphone && did.out_office_hours_routed_sipphone.id === this.id ||
        (did.hasLinkedSipPhone() && did.linked_object_id === this.id)) {
        ret.push(did);
      }
    }
    return ret;
  }

  get routedCrmRoutings(): CrmRoutingRuleEntity[] {
    const ret: CrmRoutingRuleEntity[] = [];
    for (const item of EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList() as CrmRoutingRuleEntity[]) {
      if (item.routed_sipphone && item.routed_sipphone.id === this.id) {
        ret.push(item);
        continue;
      }
      if (!_.isEmpty(item.routed_sipphones_conditional) && _.includes(item.routed_sipphones_conditional.map(x => x.id), this.id)) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routedIvrMenus(): IvrCustomMenuEntity[] {
    return RoutingAppService.getInstance().getRoutedIvrMenusToEntity(this.id, RoutingAppName.call_phone);
  }
}
