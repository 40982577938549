<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="filterForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
      fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <calendar-select-form-input [formControl]="defaultCalendarControl" displayMode="compact" [create]="false"
          [buttonType]="true" [isTruncate]="true"
          [matTooltip]="'opening_hour_calendar.content.default_calendar'|translate">
        </calendar-select-form-input>
      </div>
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterValue"
          [hintText]="'work_calendar.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>
  <flex-crud-page #flexCrud [filterTemplate]="listFilterTpl" [tableConfig]="tableConfig" [dataSource]="dataSource"
    [columnTemplates]="{default: tplDefault}" [sidePannelOptions]="sidePannelOptions"
    pageTitle="{{ 'opening_hour_calendar.menu.title'| translate }}" fxLayout="row" fxFlex="grow">

    <ng-template #tplDefault let-item="row">
      <strong *ngIf="defaultCalendarControl.value && defaultCalendarControl.value.id == item.id"
        class="label">{{ 'public.default'|translate }}</strong>
    </ng-template>

  </flex-crud-page>
</div>
