export interface MissedCallDataMsg {
    data: MissedCallData;
    message: string;
}

export enum RecallState {
    TO_TRY = 0,
    CLIENT_RECALL = 1,
    AGENT_RECALL = 2,
    OUTBOUND_VOICEMAIL = 3,
}

export let RecallStateText = {
    [RecallState.TO_TRY]: 'to_try',
    [RecallState.CLIENT_RECALL]: 'client_recall',
    [RecallState.AGENT_RECALL]: 'agent_recall',
    [RecallState.OUTBOUND_VOICEMAIL]: 'outbound_voicemail',
};

export interface MissedCallData {
    id: number;
    recall_state: RecallState;
    owner_id: number;
    finished: number;
    missed_call_count: number;
    last_outbound_user_id: number;
    recall_request_status: number;
}
