import * as _ from 'lodash';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { DialogService } from '@wephone-utils';
import {
  IvrMenuCustomAttributesModal,
  IvrMenuCustomAttributeType,
} from '@wephone/modals/ivr-menu/ivr-menu-custom-attributes/ivr-menu-custom-attributes-modal.component';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FlexBaseComponent } from '@wephone-core-ui';
import { IRoutingAppDestinationData, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { IvrMenuKeymapType } from './ivr-sub-menu.i';
import { CallDestinationComponent } from '@wephone/components/call-destination/call-destination.component';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'ivr-sub-menu',
  host: { class: 'flex-page-component' },
  templateUrl: './ivr-sub-menu.component.html',
  styleUrls: ['./ivr-sub-menu.component.scss']
})
export class IvrSubMenuComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() ivrmenu: IvrCustomMenuEntity;
  @Input() excludeIvrMenuId: number;
  @Input() canAddSubMenu = false;
  @Input() shouldViewSubMenu = true;
  @Input() isSubMenu = false;
  @Output() readonly valueChange = new EventEmitter<IvrCustomMenuEntity>();
  @ViewChildren('callDestinations') callDestinations: QueryList<CallDestinationComponent>;

  readonly DATA_TIMEOUT_MIN = 5;
  private readonly DATA_TIMEOUT_DEFAULT = 30;
  private readonly KEY_SEPARATED = ',';
  private initialized = false;

  destinations: RoutingAppName[] = [
    RoutingAppName.to_did,
    RoutingAppName.callqueue,
    RoutingAppName.call_phone,
    RoutingAppName.call_phone_number,
    RoutingAppName.reprompt,
    // RoutingAppName.remote_application,
    RoutingAppName.runvxmlscript,
    RoutingAppName.play_then_hangup,
    RoutingAppName.ivr_custom_menu,
    RoutingAppName.ivr_custom_main_menu,
    RoutingAppName.send_sms,
  ];

  has_default_opt = false;
  keymap_list: IvrMenuKeymapType[]; // [{ key:[number,number..], call_destination:{application: "", params: {}} }]
  readonly key_list: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#'];
  duplicated_keys: object = {};

  key_length: number;
  readonly keyLengths = [1, 2, 3, 4, 0];

  routeAppLabelObject: any = {};
  sourceIvrmenu: IvrCustomMenuEntity;
  errors: any = {};

  constructor(
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }
    this.initData();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.isSubMenu) {
      this.destinations.push(RoutingAppName.go_up);
    }

    if (!this.isSubMenu) {
      this.routeAppLabelObject.ivr_custom_menu = _ti('routing_application.ivr_custom_menu.submenu');
    }
  }

  resolveData(): void {
    this.initData();
    this.initialized = true;
  }

  private initData(): void {
    this.errors = {};
    this.sourceIvrmenu = this.ivrmenu && _.cloneDeep(this.ivrmenu);

    if (!this.ivrmenu) {
      this.sourceIvrmenu = EntityManager.getRepository('IvrCustomMenuRepository').create() as IvrCustomMenuEntity;
      console.warn('IVR Menu not found');
    }

    if (_.isEmpty(this.sourceIvrmenu.ivrscript_data)) {
      this.sourceIvrmenu.ivrscript_data = {
        sound_id: undefined,
        key_length: undefined,
        keymap: undefined,
        timeout: undefined
      };
    }

    if (!this.sourceIvrmenu.ivrscript_data.timeout) {
      this.sourceIvrmenu.ivrscript_data.timeout = this.DATA_TIMEOUT_DEFAULT;
    }

    this.key_length = this.sourceIvrmenu.ivrscript_data.key_length ?? 1;

    this._buildKeymapList();

    this.has_default_opt = this.hasDefaultOpt();

    this.initTimeoutValue();
  }

  validateForm(): boolean {
    if (!this.sourceIvrmenu.sound_message) {
      this.errors['sound_message'] = true;
    }

    if (this.has_default_opt &&
      this.sourceIvrmenu && this.sourceIvrmenu.ivrscript_data && this.sourceIvrmenu.ivrscript_data.timeout < this.DATA_TIMEOUT_MIN) {
      this.errors['timeout'] = true;
    }

    let i = -1;
    for (const item of this.keymap_list) {
      i++;
      if (_.isEmpty(this.errors['keymap'])) {
        this.errors['keymap'] = [];
      }

      if (_.isEmpty(item.key)) {
        this.errors['keymap'][i] = _.extend(this.errors['keymap'][i] || {}, { empty_keys: true });
        continue;
      }

      if (this.duplicated_keys[i] && this.duplicated_keys[i].length > 0) {
        this.errors['keymap'][i] = _.extend(this.errors['keymap'][i] || {}, { duplicated_keys: true });
        continue;
      }

      if (this.key_length === 0 && !_.isEmpty(item.key) && item.key.filter(k => k.search('#') !== -1).length > 0) {
        this.errors['keymap'][i] = _.extend(this.errors['keymap'][i] || {}, { data_incorrect: true });
        continue;
      }

      if (this.key_length > 0 && item.key.filter(k => k.length < this.key_length).length) {
        this.errors['keymap'][i] = _.extend(this.errors['keymap'][i] || {}, { key_length_incorrect: true });
        continue;
      }

      if (!this.callDestinations.toArray()[i].validParam()) {
        this.errors['keymap'][i] = _.extend(this.errors['keymap'][i] || {}, { calldestination: true });
      }
    }
    if (this.errors['keymap'] && !this.errors['keymap'].length) {
      delete this.errors.keymap;
    }

    return _.isEqual(this.errors, {});
  }

  private updateKeymapData(): void {
    this.sourceIvrmenu.ivrscript_data.keymap = this.getKeymapData();
    this.changed();
  }

  initTimeoutValue(): void {
    const data_timeout = this.sourceIvrmenu.ivrscript_data.timeout || undefined;
    if (typeof data_timeout === 'string') {
      this.sourceIvrmenu.ivrscript_data.timeout = parseInt(data_timeout, 10);
    }
  }

  hasDefaultOpt(): boolean {
    // return !!this._getKeymapDefault();
    return !!this.keymap_list.filter(km => !_.isEmpty(km) && !_.isEmpty(km.key) && km.key.includes('default')).length;
  }

  setDefaultOpt(): void {
    if (!this.hasDefaultOpt()) {
      this.addKeymapItem(['default'], this._newCallDestination(), true, true);
    } else {
      this.removeKeymapDefault();
    }
  }

  private _newCallDestination(): IRoutingAppDestinationData {
    return new IRoutingAppDestinationData();
  }

  getDuplicatedKeys(item: IvrMenuKeymapType): string[] {
    let available_keys: string[] = [];
    for (const i of this.keymap_list) {
      if (i !== item) {
        available_keys = available_keys.concat(i.key);
      }
    }
    const unique_keys = [];
    for (const key of available_keys) {
      if (unique_keys.indexOf(key) === -1) {
        unique_keys.push(key);
      }
    }

    const intersection = unique_keys.filter(n => {
      return item.key.indexOf(n) !== -1;
    });

    return intersection;
  }

  // private _getKeymapDefault(): IRoutingAppDestinationData {
  //   if (_.isEmpty(this.sourceIvrmenu.ivrscript_data) || _.isEmpty(this.sourceIvrmenu.ivrscript_data.keymap)) {
  //     console.warn('Keymap data not found');
  //     return;
  //   }

  //   const defaultKey = 'default';
  //   const keymapKeys = Object.keys(this.sourceIvrmenu.ivrscript_data.keymap);
  //   if (keymapKeys.includes(defaultKey)) {
  //     return this.sourceIvrmenu.ivrscript_data.keymap[defaultKey];
  //   }
  // }

  private _buildKeymapList(): void {
    this.keymap_list = [];
    // const keymap_default: IRoutingAppDestinationData = this._getKeymapDefault();
    // if (keymap_default) {
    //   this.keymap_list.push({ key: ['default'], call_destination: keymap_default });
    // }

    if (!_.isEmpty(this.sourceIvrmenu.ivrscript_data) && !_.isEmpty(this.sourceIvrmenu.ivrscript_data.keymap)) {
      for (const k of Object.keys(this.sourceIvrmenu.ivrscript_data.keymap)) {
        const km = {
          key: k.split(this.KEY_SEPARATED),
          call_destination: this.sourceIvrmenu.ivrscript_data.keymap[k]
        };

        if (k === 'default') {
          this.keymap_list.unshift(km);
          continue;
        }

        this.keymap_list.push(km);
      }
    } else {
      this.addKeymapItem(undefined, this._newCallDestination(), false, false);
    }
  }

  addNewKeymapItem(): void {
    this.addKeymapItem(undefined, this._newCallDestination(), false, true);
  }

  popupDialogCustomAttrs(keymap: IvrMenuKeymapType): void {
    const dialog_params = {
      call_attributes:
        keymap.call_destination && keymap.call_destination.call_attributes
          ? keymap.call_destination.call_attributes
          : {}
    };

    this.dialogService.openDialog2(
      IvrMenuCustomAttributesModal,
      { data: dialog_params },
      (data: { custom_attrs: IvrMenuCustomAttributeType[] }) => {
        if (data && data.custom_attrs) {
          if (!keymap.call_destination) {
            keymap.call_destination = new IRoutingAppDestinationData();
          }

          keymap.call_destination.call_attributes = {};

          for (const custom_attr of data.custom_attrs) {
            keymap.call_destination.call_attributes[custom_attr.name] = custom_attr.value;
          }
        }
      }
    );
  }

  private getKeymapData(): { [key: string]: IRoutingAppDestinationData } {
    const result = {};

    for (const keymap of this.keymap_list) {
      let keys = '';

      if (!_.isEmpty(keymap.key)) {
        keys = keymap.key.sort().join(this.KEY_SEPARATED);
      }

      if (typeof result[keys] !== 'undefined') {
        continue;
      }

      result[keys] = keymap.call_destination; // keymap.call_destination = {application: "", params: {}};
    }

    return result;
  }

  addKeymapItem(
    key?: string[],
    call_destination?: IRoutingAppDestinationData,
    addToFirst = false,
    hasUpdateData: boolean = true
  ): void {
    const new_keymap = new IvrMenuKeymapType();
    new_keymap.key = key;
    new_keymap.call_destination = call_destination || this._newCallDestination();

    if (addToFirst) {
      this.keymap_list.unshift(new_keymap);
    } else {
      this.keymap_list.push(new_keymap);
    }

    if (hasUpdateData) {
      this.updateKeymapData();
    }
  }

  removeKeymapItem(item: IvrMenuKeymapType): void {
    const index: number = this.keymap_list.indexOf(item);
    if (index > -1) {
      this.keymap_list.splice(index, 1);
      if (index in this.duplicated_keys) {
        delete this.duplicated_keys[index];
      }
    }

    this.updateKeymapData();
  }

  private removeKeymapDefault(): void {
    const keymapDefault = this.keymap_list.find(keymap => {
      if (!_.isEmpty(keymap) && !_.isEmpty(keymap.key) && keymap.key.includes('default')) {
        return true;
      }
    });

    if (keymapDefault) {
      this.removeKeymapItem(keymapDefault);
      this.updateKeymapData();
    }
  }

  onChangeTimeout(): void {
    this.changed();
  }

  updateSoundMessageFile(data: { new_file_entry: FileEntryEntity }): void {
    const file_entry: FileEntryEntity = data.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivrmenu');
      this.sourceIvrmenu.sound_message = undefined;
      this.sourceIvrmenu.ivrscript_data.sound_id = undefined;
      return;
    }

    this.sourceIvrmenu.sound_message = file_entry;
    this.sourceIvrmenu.ivrscript_data.sound_id = file_entry.id;
    this.updateKeymapData();
  }

  phonePadPickerChanged(data: { keys: string[], error?: string }, item: IvrMenuKeymapType, index: number): void {
    if (data.error) {
      this.errors.keymap = [];
      if (data.error === 'LENGTH_INVALID') {
        this.errors.keymap[index] = { key_length_incorrect: true };
      } else {
        this.errors.keymap[index] = { data_incorrect: true };
      }
      // this.errors.keymap[index] = data.error === 'DUPLICATED' ? {data_duplicated: true} : { data_incorrect: true };
      return;
    }

    item.key = data.keys;
    this.duplicated_keys[index] = this.getDuplicatedKeys(item);
    this.updateKeymapData();
  }

  updateCallDestination(): void {
    this.updateKeymapData();
  }

  updateIvrMenuDataKeyLength(value: any): void {
    if (this.sourceIvrmenu.ivrscript_data && this.key_length === this.sourceIvrmenu.ivrscript_data.key_length) {
      this._buildKeymapList();
      return;
    }

    this.sourceIvrmenu.ivrscript_data.key_length = +this.key_length;
    this.keymap_list = this.keymap_list.map((km: IvrMenuKeymapType) => {
      if (!_.isEmpty(km) && !_.isEmpty(km.key) && !km.key.includes('default')) {
        delete km.key;
      }
      return km;
    });
    this.changed();
  }

  changed(): void {
    this.errors = {};
    if (this.valueChange) {
      setTimeout(() => {
        this.valueChange.emit(this.sourceIvrmenu);
      });
    }
  }

  resetData(): void {
    this.initData();
  }
}
