import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { _ti } from '@wephone-translation';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'queue-select-form-input',
  templateUrl: './queue-select.component.html',
  styleUrls: ['./queue-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: QueueSelectFormInput }]
})
export class QueueSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<CallQueueEntity> {
  static nextId = 0;

  @Input() allowEmpty: boolean;
  @Input() agent: AgentEntity;
  @Input() emptyLabel: string;
  @Input() excludeQueueIds: number[];
  @Output() readonly selectionChange = new EventEmitter<CallQueueEntity>();

  flexSelect: FormControl = new FormControl();
  queueList: CallQueueEntity[];
  flexSelectOptions: IFlexSelectOptions;
  private emptyItem: CallQueueEntity;
  private queueRepo: CallQueueRepository;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    readonly em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: CallQueueEntity, filterString) => {
        return regexSearch(item.queue_name, filterString);
      },
      compareWith: (a: CallQueueEntity, b: CallQueueEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
    this.queueRepo = em.getRepository<CallQueueRepository>('CallQueueRepository');
    this.emptyItem = this.queueRepo.getEmptyCallQueue(this.emptyLabel);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setQueueList();
  }

  onContainerClick(event: MouseEvent): void {
    this.setQueueList();
    super.onContainerClick(event);
  }

  setQueueList(): void {
    let queueList: CallQueueEntity[] = !this.agent ? this.queueRepo.getInboundQueues() : this.queueRepo.getInboundQueuesByAgentId(this.agent.id);
    if (!_.isEmpty(this.excludeQueueIds)) {
      queueList = queueList.filter(q => !_.includes(this.excludeQueueIds, q.id));
    }
    this.queueList = _.sortBy(queueList, [o => o.queue_name]);

    if (this.allowEmpty) {
      this.queueList.unshift(this.emptyItem);
    }
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  get controlType(): string {
    return 'queue-select-form-input';
  }

  onSelectionChange($event: MatSelectChange): void {
    console.log('queue changed', $event.value);
    this.selectionChange.emit($event.value);
  }

  // writeValue(val: CallQueueEntity): void {
  //   let _val = val;
  //   if (this.allowEmpty && !val) {
  //     _val = this.emptyItem;
  //   }
  //   this.wrappedControl.setValue(_val);
  // }
}
