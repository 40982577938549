<h2>{{ 'public.multipleedit'|translate:{number:editingList.length} }}</h2>
<mat-tab-group fxFill fxFlex [selectedIndex]="tabSelectedIndex" (selectedIndexChange)="updateTabSelectedIndex($event)">
  <mat-tab label="{{ 'call_queue.content.tab_general'|translate }}" >
      <queue-edit-general #generalQueueEditor 
        [hidden]="!dummyQueue" 
        [queue]="dummyQueue"
        [multipleEdit]="true"
        (formValueChanges)="onFormValueChange()">
      </queue-edit-general>
  </mat-tab>
  <mat-tab label="{{ 'call_queue.content.tab_advanced'|translate }}">
      <queue-edit-advance #advanceQueueEditor 
        [hidden]="!dummyQueue" 
        [queue]="dummyQueue"
        [multipleEdit]="true"
        (formValueChanges)="onFormValueChange()">
      </queue-edit-advance>
  </mat-tab>
</mat-tab-group>
