import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class QueueTransferNumberRepository extends BaseRepository {
  ENTITY_CLASS = QueueTransferNumberEntity;
  protected RELATED_REPOSITORIES = ['callqueue'];
  protected USE_PUSH_API = true;

  public getObjectListByQueueId(callqueue_id): QueueTransferNumberEntity[] {
    const ret = [];
    for (const s of this.object_list) {
      if (s.call_queue_keyid === callqueue_id) {
        ret.push(s);
      }
    }
    return ret;
  }
}

import { QueueTransferNumberEntity } from '@wephone-core/model/entity/queue_transfernumber';
