import * as _ from 'lodash';

import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  DialogService,
  IFlexTableSidePannelOptions,
  FlexCRUDPageComponent,
  DynamicFilterSource,
  IFlexTableConfig,
  regexSearch,
} from '@wephone-utils';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { TeamGroupRepository } from '@wephone-core/model/repository/teamgroup';
import { TeamCreateModalComponent } from '@wephone/modals/team-create-modal/team-create-modal.component';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { TeamEditComponent } from '@wephone/components/team-edit/team-edit.component';
import { Router } from '@angular/router';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { AuthenticationService } from '@wephone-core/service/authentication';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent extends FlexBasePage implements OnInit, OnDestroy {
  filters: { search_text: string; } = { search_text: '' };
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: TeamEditComponent
  };

  filterForm: FormGroup;
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly dialogService: DialogService,
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);

    this.tableConfig = {
      rowHeight: 48,
      columns: [
        {
          name: 'name',
          label: _tk('team_group.content.name'),
          enableTooltip: true,
          lineClamp: 2,
          sortable: false,
        },
        {
          name: 'agents',
          label: _tk('group.content.username'),
          sortable: false,
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'person_add',
            hint: _tk('team_group.content.create_new'),
            callback: () => {
              return this.createTeam();
            }
          },
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: (data: any) => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

  }

  ngOnInit(): void {
    super.ngOnInit();

    const repo = this.em.getRepository<GroupRepository>('GroupRepository');
    const entitySource = repo.dataSource<GroupEntity>();
    this.dataSource = new DynamicFilterSource(entitySource,
      this.filterFunc
    );

    this.dataSource.setOrder('path_name', 'asc');

    this.filterForm = this.fb.group({
      search_text: [this.filters.search_text]
    });

    this.addSubscription(
      this.filterForm.valueChanges.subscribe(this.applyFilter)
    );
  }

  private readonly filterFunc = (group: GroupEntity): boolean => {
    return group.group_type === 'team' && this.filterPredicate(group as TeamGroupEntity, this.searchText);
  }

  private filterPredicate(group: TeamGroupEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }

    return regexSearch(group.name, filterString);
  }

  protected resolveData(): void {
    this.applyFilter();
  }

  private async createTeam(): Promise<void> {
    const dialogRef = this.dialogService.openDialog2(TeamCreateModalComponent, { size: 's' });
    const createdTeam = await dialogRef.afterClosed().toPromise();

    if (!createdTeam) {
      return;
    }

    this.router.navigate(['manage-users', 'teams', createdTeam.id], { skipLocationChange: false });
  }

  bulkDelete(items: TeamGroupEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      () => {
        this.deleteTeams(items);
      }
    );
  }

  get searchText(): string {
    return this.filterForm && this.filterForm.get('search_text').value;
  }

  applyFilter = () => {
    this.filters.search_text = (this.searchText || '').trim();

    if (this.dataSource) {
      this.dataSource.filter = this.filters.search_text;
      this.dataSource.onFilterChange();
    }
  }

  async deleteTeams(items: TeamGroupEntity[]): Promise<void> {
    try {
      const repo = this.em.getRepository<TeamGroupRepository>('TeamGroupRepository');
      await repo.bulkDelete(items);

      this.applyFilter();
      this.showInfo(_ti('public.message.delete_success'));
      this.flexCrud.closeSidePanel();
    } catch (response) {
      const msg = response && response.message || response.error && response.error.message || _ti('public.message.delete_failure');
      this.showError(msg);
    }
  }

  renderNameCol(item: TeamGroupEntity): string {
    const number_slash = (item.path && item.path.length) || 0;
    const name = Array(number_slash + 1).join('&mdash;') + item.name;

    return name;
  }
}
