import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidRepository } from '@wephone-core/model/repository/did';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-add-did-to-queue-modal',
  templateUrl: './add-did-to-queue-modal.component.html',
  styleUrls: ['./add-did-to-queue-modal.component.scss']
})
export class AddDidToQueueModalComponent extends DialogComponentBase implements OnInit {

  dialogTitle = _tk('call_queue.content.didroute.dialog_title');
  dialogRightActions: Array<DialogActionButton> = [];

  didAvailableList: Array<DidEntity>;
  searchText: string;
  filterCtrl = new FormControl();
  didList;

  priorityList: Array<{
    label: string;
    value: number;
  }>;
  constructor(
    dialogRef: MatDialogRef<AddDidToQueueModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { queue_did_list?: Array<DidEntity>; not_in_used?: boolean }
  ) {
    super(dialogRef);
    this.priorityList = [
      {
        value: 0,
        label: _ti('call_queue.content.priority_level.very_high')
      },
      {
        value: 1,
        label: _ti('call_queue.content.priority_level.high')
      },
      {
        value: 2,
        label: _ti('call_queue.content.priority_level.normal')
      },
      {
        value: 3,
        label: _ti('call_queue.content.priority_level.low')
      },
      {
        value: 4,
        label: _ti('call_queue.content.priority_level.very_low')
      }
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.didList = EntityManager.getRepository('DidRepository').getObjectList();

    this.didAvailableList = [];

    for (const did of this.didList) {
      if (did.id > 0 && !did.linked_object_id && (!this.dialogData.not_in_used
        || (this.dialogData.not_in_used && !did.action_office_hour))) {
        this.didAvailableList.push(did);
      }
    }

    this.addSubscription(
      this.filterCtrl.valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged()
      )
      .subscribe(value => {
        this.searchText = value;
      })
    );

    console.log('ABCD: ', this.filteredDidAvailableList);
  }

  get filteredDidAvailableList(): Array<any> {
    return _.filter(this.didAvailableList, did => {
      if (this.searchText) {
        return did.number === this.searchText;
      }

      return did;
    });
  }

  addToQueue(did: DidEntity): void {
    this.dismiss({ did: _.cloneDeep(did) }, false);
  }

  close(): void {
    this.dismiss();
  }
}
