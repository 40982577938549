import { Entity, datafield } from '@wephone-core/model/model';
import { EnterpriseEntity } from './enterprise';
import { EnterpriseRepository } from '../repository/enterprise';

export class EndorsementEntity extends Entity {
  public static object_type_id = 'endorsement';
  public id: number;
  @datafield
  public enterprise_id: number;
  @datafield
  public endorsed_enterprise_id: number;
  @datafield
  public monthly_reduction: number;
  @datafield
  public until: Date;

  get enterprise(): EnterpriseEntity {
    return this.enterprise_id ? EnterpriseRepository.getInstance().getObjectById(this.enterprise_id) : undefined;
  }

  get endorsed_enterprise(): EnterpriseEntity {
    return this.endorsed_enterprise_id ? EnterpriseRepository.getInstance().getObjectById(this.endorsed_enterprise_id) : undefined;
  }
}
