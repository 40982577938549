import { DateTime } from 'luxon';
import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { CallQueueSessionEntity } from '@wephone-core/model/entity/callqueuesession';
import { CallQueueSessionRepository } from '@wephone-core/model/repository/callqueuesession';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { parseDateTime } from '@wephone-utils';
import { AgentEntity } from './agent';
import { AgentRepository } from '../repository/agent';

export class CallQueueItemEntity extends Entity {
  public static object_type_id = 'callqueue_item';

  public id: number;
  @datafield public enterprise_id: number;
  @datafield public call_queue_keyid: number;
  @datafield public priority: number;
  @datafield public calling_reason: string;
  @datafield public remote_number: string;
  @datafield public local_number: string;
  @datafield public arrive_dt: any;
  @datafield public hungup_dt: any;
  @datafield public is_handled: number;
  @datafield public call_park_id: string;
  @datafield public server_id: string;
  @datafield public hunt_history: string;
  @datafield public finished: number;
  @datafield public client_call_id: number;
  @datafield public client_call_keyid: number;
  @datafield public is_outgoing: number;
  @datafield public forced_operator_id: number;
  @datafield public forced_operator_number: string;
  @datafield public forcing_user_id: number;
  @datafield public group_id: number;

  // ------------- local attributes -------------------
  get queue(): CallQueueEntity {
    return EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(this.call_queue_keyid) as CallQueueEntity;
  }

  set queue(queue: CallQueueEntity) {
    this.call_queue_keyid = this.queue ? queue.id : undefined;
  }

  get session(): CallQueueSessionEntity {
    return EntityManager.getRepository<CallQueueSessionRepository>('CallQueueSessionRepository').getObjectByCallQueueItemId(this.id) as CallQueueSessionEntity;
  }

  get bridged_dt() {
    if (this.session && this.session.bridged_dt) {
      return this.session.bridged_dt;
    }
    return undefined;
  }

  get group() {
    if (this.group_id) {
      return UserGroupRepository.getInstance().getObjectById(this.group_id);
    }
    return undefined;
  }

  get waiting_minutes(): number {
    if (this.arrive_dt) {
      const now = new Date().getTime();
      return (now - this.arrive_dt.toJSDate().getTime()) / 60 / 1000;
    }
    return undefined;
  }

  get agent(): AgentEntity {
    if (this.session && this.session.operator_id) {
      return AgentRepository.getInstance().getObjectById(this.session.operator_id);
    }
  }

  // public fetchRelatedData() {
  // this.queue = CallQueueRepository.getInstance().getObjectById(this.call_queue_keyid);
  // }

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.arrive_dt = this.arrive_dt ? parseDateTime(this.arrive_dt) : undefined;
    this.hungup_dt = this.hungup_dt ? parseDateTime(this.hungup_dt) : undefined;
  }
}
