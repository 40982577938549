import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService, NoWhitespaceValidator } from '@wephone-utils';
import { ShortcutEntity } from '@wephone-core/model/entity/shortcut';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ShortcutRepository } from '@wephone-core/model/repository/shortcut';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-edit-shortcut',
  templateUrl: './edit-shortcut.component.html',
  styleUrls: ['./edit-shortcut.component.scss']
})
export class EditShortcutComponent extends EditingComponent implements OnInit {
  @Input() editingItem: ShortcutEntity;

  shortcut: ShortcutEntity;
  form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    public toast: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.shortcut = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      displayText: [this.shortcut.display_text, [Validators.required, NoWhitespaceValidator, Validators.maxLength(100)]],
      destination: [this.shortcut.destination, [Validators.required, NoWhitespaceValidator, Validators.maxLength(255)]],
      newWindow: [this.shortcut.in_new_window],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  private getFormResetData(): any {
    return {
      displayText: this.shortcut.display_text,
      destination: this.shortcut.destination,
      newWindow: this.shortcut.in_new_window,
    };
  }

  async submitForm(): Promise<void> {
    if (this.form.invalid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      this.shortcut.setObjectData({
        display_text: _.trim(this.form.get('displayText').value),
        destination: _.trim(this.form.get('destination').value),
        in_new_window: this.form.get('newWindow').value,
      });

      const attrList = ['display_text', 'destination', 'in_new_window'];
      await EntityManager.getRepository<ShortcutRepository>('ShortcutRepository')
        .saveAttrs(this.shortcut, attrList);
      this.shortcut = EntityManager.getRepository<ShortcutRepository>('ShortcutRepository').getObjectById(this.shortcut.id);
      this.toast.show(_ti('public.message.update_success'));
      this.resetForm();
    } catch (error) {
      let msg = _ti('public.message.create_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toast.showError(msg);
    }
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }
}
