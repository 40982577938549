import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { IFlexTableConfig, DialogService, MessageService, FlexTableSelectionChangeEvent, FlexTableComponent } from '@wephone-utils';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserRepository } from '@wephone-core/model/repository/user';
import { SentSmsModalComponent } from '../sent-sms-modal/sent-sms-modal';
import { SmsDialogService } from '@wephone-sms/service';
import { ActivatedRoute } from '@angular/router';
import { SmsDialog, SmsMessage } from '@wephone-sms/sms.types';

@Component({
  selector: 'app-smsin-handler',
  templateUrl: './smsin-handler.component.html',
  styleUrls: ['./smsin-handler.component.scss']
})
export class SmsinHandlerComponent extends FlexBasePage implements OnInit, OnDestroy {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  tableConfig: IFlexTableConfig;

  dataSource: FlexDataSource;
  currentUser: UserEntity;

  private initilized = false;

  constructor(
    private readonly dialogService: DialogService,
    // private readonly smsService: SmsService,
    private readonly smsDialogService: SmsDialogService,
    private readonly datasourceService: DataSourceService,
    private readonly messageService: MessageService,
    em: EntityManager,
    authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.currentUser = em.getRepository<UserRepository>('UserRepository').getObjectById(authService.getUserId());
    this.dataSource = this.datasourceService.createDataSource('pbx_sms_received_handler');
    this.dataSource.setOrder('message_time', 'desc');

    this.tableConfig = {
      rowHeight: 40,
      multiSelect: false,
      enableFilter: false,
      enableExportCsv: false,
      columns: [
        { name: 'client_name', label: 'name', width: '180px' },
        // { name: 'message_time', label: 'Message time', width: '150px' },
        { name: 'message', label: 'Message' }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'send-sms',
            icon: 'textsms',
            hint: 'sms.action.new_sms',
            callback: (): any => {
              return this.agentSendSms();
            }
          }
        ]
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.addSubscription(
      this.messageService.subscribeNewSms(() => {
        this.loadData();
      })
    );

    this.addSubscription(
      this.activatedRoute.queryParams.subscribe(params => {
        if (this.initilized && 'view' in params && params.view === 'sms-handler') {
          this.loadData();
        }
      })
    );

    this.initilized = true;
  }

  protected async resolveData(): Promise<void> {
    await this.loadData();
  }

  private loadData(): Promise<any> {
    const newFilters: any = {
      limit: 100
    };

    if (this.flexTable) {
      // this.dataSource.setFilter(newFilters);
      return this.flexTable.reloadDataSource(newFilters);
    }

    return this.dataSource.load(newFilters);
  }

  // getDisplayName = (row: any, colName?: string): string => {
  //   if (row.client_name) {
  //     return row.client_name;
  //   }

  //   return this.phoneNumberService.getDisplayNumber(row.remote_number);
  // }

  // displayStatus(status): string {
  //   return this.smsService.displayStatus(status);
  // }

  onSelectionChange(event: FlexTableSelectionChangeEvent): void {
    if (event.focusedItem && !event.selectedItems.length) {
      this.detailConversation(event.focusedItem);
    }
  }

  detailConversation(item: SmsDialog): void {
    console.log('Detail sms', item);
    this.smsDialogService.detailConversation(item, data => {
      console.log('Detail conversation closed', data);
      if (item.unread || !_.isEmpty(data)) {
        this.loadData();
      }
    });
  }

  agentSendSms(): void {
    this.dialogService.openDialog2(SentSmsModalComponent, {}, async ret => {
      if (ret) {
        await this.loadData();
        const smsMessage: SmsMessage = ret.length ? ret[0] : undefined;
        if (smsMessage && smsMessage.dialog_id) {
          const smsDialog: SmsDialog = this.dataSource.data.find(i => i.id = smsMessage.dialog_id);
          // selected row in the flex-table
          this.flexTable.focusedRow = smsDialog;
          this.detailConversation(smsDialog);
        }
      }
    });
  }
}
