<div fxLayout="column" *ngIf="isUpload()">
  <div fxLayout="row">
    <div fxFlex="100">
      <span>{{ 'public.message.download_sample_file'|translate }}</span>
      <button type="button" mat-icon-button (click)="downloadSampleFile()">
        <mat-icon attr.aria-label="{{ 'public.message.download_sample_file'|translate }}">save</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row">
    <mat-slide-toggle fxFlex="100" [(ngModel)]="sendEmail">
      {{'user.dialog.import.send_mail'|translate}}
    </mat-slide-toggle>
  </div>

  <div fxLayout="row" class="my-1">
    <div fxFlex="100">
      <button (click)="fileInput.click()" mat-raised-button color="primary">{{'public.upload'|translate}}</button>
      <span *ngIf="importFile">{{importFile.name}}</span>
      <input #fileInput type="file" [accept]="fileSettings.extensions|extensionAccept" style="display:none" (change)="importCsv($event)">
    </div>
  </div>
</div>

<div fxLayout="column" *ngIf="isUploadResult()">
  <ng-container fxFlex="100" *ngIf="errors.length">
    <h4>{{ 'public.message.error_occurred'|translate }}</h4>
    <mat-error *ngFor="let msg of errors">{{ msg }}</mat-error>
  </ng-container>
  <div fxLayoutAlign="start center" *ngIf="!errors.length" class="py-1">
    <mat-icon class="mat-text-green">done_all</mat-icon>
    <div class="pl-1">{{ 'user.dialog.import.import_success'|translate }}</div>
  </div>
</div>
