<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="filterForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
      fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">

      <div fxFlex.xs="none">
        <mat-form-field class="full-width">
          <mat-label>{{ 'did.content.country' | translate }}</mat-label>
          <mat-select [placeholder]="'did.content.filter_country'|translate" formControlName="countryCode">
            <mat-select-trigger>
              <span country-flag [countryCode2]="filterForm.get('countryCode').value"></span>
              {{ ('country_codes.' + filterForm.get('countryCode').value) | translate }}
            </mat-select-trigger>
            <mat-option>{{ 'public.all' | translate }}</mat-option>
            <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode">
              <span country-flag [countryCode2]="countryCode"></span>
              {{ ('country_codes.' + countryCode) | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex.xs="none">
        <mat-form-field class="full-width">
          <mat-label>{{ 'blacklist.content.blocker' | translate }}</mat-label>
          <mat-select [placeholder]="'blacklist.content.blocker'|translate" formControlName="blockerId">
            <mat-option>{{ 'public.all' | translate }}</mat-option>
            <mat-option *ngFor="let blocker of blockers" [value]="blocker.id">
              {{ blocker.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <flex-search-input formControlName="filterValue" [placeholder]="'public.search'|translate"
        [hintText]="'blacklist.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_blacklist'|translate }}" [filterTemplate]="listFilterTpl"
    [tableConfig]="tableConfig" [dataSource]="dataSource"
    [columnTemplates]="{number: tplNumberNormalize, blocker: tplBlocker, block_dt: tplBlockDt}"
    [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">

    <ng-template #tplNumberNormalize let-item="row">
      <div class="phone-number">
        <div class="relative">
          <span *ngIf="item.country_code" class="country-column" country-flag [countryCode2]="item.country_code">
          </span>
          <span class="number-normalize">{{ item.display_number }}</span>
        </div>
      </div>
    </ng-template>

    <ng-template #tplBlocker let-item="row">
      {{ item.blocker_name || '' }}
    </ng-template>

    <ng-template #tplBlockDt let-item="row">
      {{ item.block_dt | timeFormatDateTimeShort }}
    </ng-template>

  </flex-crud-page>
</div>
