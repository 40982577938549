import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { parseDateTime, localNow, IFlexTableConfig, FlexDataSource, IDataSourceFilter, FlexTableComponent, FlexPaginatorConfigDefault, FormService } from '@wephone-utils';
import * as _ from 'lodash';
import { FlexBasePage } from '@wephone-core-ui';
import { _ti } from '@wephone-translation';
import { _tk } from 'jsphonelib/translation';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DateAdapter } from '@angular/material/core';
import { FlexDatepickerMin, PhoneNumberValidated } from '@wephone/services/form-validator';

interface ICdrDataSourceFilter extends IDataSourceFilter {
  start_dt?: string;
  end_dt?: string;
  start_time?: string;
  end_time?: string;
  calling_number?: string;
  called_number?: string;
  display_type: string;
}

@Component({
  selector: 'page-cdr',
  templateUrl: './cdr.component.html',
  styleUrls: ['./cdr.component.scss'],
})
export class CdrComponent extends FlexBasePage {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  PERIODICAL_UPDATE = true; // Detecting the layout automatically for each 1 second

  tableConfig: IFlexTableConfig = {
    multiSelect: false,
    enableFilter: false,
    enableExportCsv: true,
    columns: [
      { name: 'calling_number', label: _tk('call_queue.content.calling_number') },
      { name: 'called_number', label: _tk('call_queue.content.called_number') },
      { name: 'start_time', label: _tk('cdr.start_date') },
      { name: 'end_time', label: _tk('cdr.end_date') },
      { name: 'duration', label: _tk('cdr.duration') },
      { name: 'in_out', label: _tk('cdr.in_out') },
      { name: 'handled_user_id', label: _tk('cdr.last_agent') }
    ]
  };

  callDirection = {
    OUT: 0,
    IN: 1,
    INTERNAL: 2
  };

  filterForm: FormGroup;
  filtersReadonly = true;

  dataSource: FlexDataSource;

  private _agentNames: any = {};

  constructor(
    private readonly fb: FormBuilder,
    private readonly datasourceService: DataSourceService,
    private readonly formService: FormService,
    readonly cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;

    this.dataSource = this.datasourceService.createDataSource('callcenter_call_history');
    this.dataSource.setOrder('start_time', 'desc');

    this.filterForm = this.fb.group({
      start_dt: [localNow().minus({ months: 1 }).toJSDate(), Validators.required],
      end_dt: [localNow().plus({ days: 1 }).toJSDate(), Validators.required],
      start_time: '00:00',
      end_time: '00:00',
      called_number: ['', [PhoneNumberValidated()]],
      calling_number: ['', [PhoneNumberValidated()]],
    }, { validator: FlexDatepickerMin('end_dt', 'start_dt') });

  }

  get startDateControl(): FormControl {
    return this.filterForm.get('start_dt') as FormControl;
  }

  get endDateControl(): FormControl {
    return this.filterForm.get('end_dt') as FormControl;
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilter = (d: Date): boolean => {
    const startDate = this.startDateControl.value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  async resolveData(): Promise<void> {
    return this.applyFilter();
  }

  async applyFilter(): Promise<void> {
    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    const filterData = this.formService.normalizeData(this.filterForm.value);
    const newFilters: ICdrDataSourceFilter = _.cloneDeep(filterData);
    newFilters.start_dt = parseDateTime(newFilters.start_dt).toISODate();
    newFilters.end_dt = parseDateTime(newFilters.end_dt).toISODate();
    newFilters.display_type = 'by_date';

    // this.dataSource.setFilter(newFilters);
    if (this.flexTable) {
      this.flexTable.reloadDataSource(newFilters);
    } else {
      const orginFilters: any = this.dataSource.getFilter();
      newFilters.length = orginFilters.length || FlexPaginatorConfigDefault.PAGINATOR_LENGTH;
      // await this.dataSource.load(newFilters);
      await this.dataSource.load_columns(newFilters);
    }
    this.filtersReadonly = true;
    this.detectChanges();
  }

  changeFilter(): Boolean {
    return this.filtersReadonly = false;
  }

  getAgentName(userId: number): string {
    if (!userId) {
      return;
    }

    const userKey = `u${userId}`;

    if (!this._agentNames[userKey]) {
      const lastUser = EntityManager.getInstance().getRepository('UserRepository').getObjectById(userId);
      this._agentNames[userKey] = lastUser && lastUser.name;
    }

    return this._agentNames[userKey];
  }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.filterForm.get(controlName).setValue(null);
  }
}
