<div class="mini-block">
  <div class="flag">
    <span class="flag-icon maticon">
      <mat-icon>sip</mat-icon>
    </span>
  </div>
  <div class="number-call">
    <span class="name font-weight-bold" [matTooltip]="'enterprise_info.step.sip_terminal_settings'|translate"
      matTooltipPosition="above">
      {{ 'enterprise_info.step.sip_terminal_settings'|translate|truncate:15 }}
    </span>
  </div>
</div>
