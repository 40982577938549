import { Component, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { RoutingAppRepository } from '@wephone-core/model/repository/routing_app';
import { ConfigManager, FlexIvrSettings, EntityManager } from '@wephone-core/wephone-core.module';
import { RoutingAppEntity } from '@wephone-core/model/entity/routing_app';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { UserRepository } from '@wephone-core/model/repository/user';
import { CallDestinationService } from '@wephone/services/call-destination.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { ConferenceRepository } from '@wephone-core/model/repository/conference';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { FlexBaseComponent } from '@wephone-core-ui';
import {
  IRoutingAppDestinationData, RoutingAppId, RoutingAppName, RoutingParamsConference, RoutingParamsCrmRoute, RoutingParamsDigitalSecretary, RoutingParamsIvrMenu,
  RoutingParamsPhoneNumber, RoutingParamsPlayFileAndHangup, RoutingParamsQueue, RoutingParamsRemoteApp, RoutingParamsRunVxml, RoutingParamsSendSms,
  RoutingParamsSipphone, RoutingParamsToDid,
} from '@wephone-core/routing-app/routing-app.interface';
import { DialogService } from '@wephone-utils';
import { IvrSubMenuEditDialog } from '@wephone/modals/ivr-sub-menu-edit-dialog/ivr-sub-menu-edit-dialog';
import { DidRepository } from '@wephone-core/model/repository/did';
import { RoutingAppParamType } from '../ivr-menu/ivr-sub-menu/ivr-sub-menu.i';
import { _ti } from '@wephone-translation';
import { SmsTemplateEntity } from '@wephone-core/model/entity/sms_template';
import { SmsTemplateRepository } from '@wephone-core/model/repository/sms_template';
import * as _ from 'lodash';
import { FeatureName } from '@wephone-core/system';

export interface RouteApplicationLabelType {
  callqueue?: string;
  call_phone?: string;
  call_phone_number?: string;
  call_user?: string;
  conference?: string;
  ivr_custom_menu?: string;
  ivr_custom_main_menu?: string;
  play_then_hangup?: string;
  digitalsecretary?: string;
  to_did?: string;
  go_up?: string;
  reprompt?: string;
  remote_application?: string;
  runvxmlscript?: string;
  outbound_agent?: string;
  send_sms?: string;
  voicemail?: string;
}

export interface ICallDestinationVoicemailData {
  voicemail_enabled: boolean;
  mailbox_id?: number;
}

enum ControlNameType {
  call_user = 'call_user',
  call_phone = 'call_phone',
  callqueue = 'callqueue',
  ivr_custom_menu = 'ivr_custom_menu',
  ivr_custom_main_menu = 'ivr_custom_main_menu',
  call_phone_number = 'call_phone_number',
  voicemail = 'voicemail',
  conference = 'conference',
  crm_route = 'crm_route',
  play_then_hangup = 'play_then_hangup',
  remote_application = 'remote_application',
  user_secondary_number = 'user_secondary_number',
  webservice = 'webservice',
  runvxmlscript = 'runvxmlscript',
  digitalsecretary = 'digitalsecretary',
  to_did = 'to_did',
  go_up = 'go_up',
  reprompt = 'reprompt',
  send_sms = 'send_sms',
  play_after_send_sms = 'play_after_send_sms',
  call_application = 'call_application'
}

@Component({
  selector: 'call-destination',
  templateUrl: './call-destination.component.html',
  styleUrls: ['./call-destination.component.scss']
})
export class CallDestinationComponent extends FlexBaseComponent implements OnInit, OnChanges, OnDestroy {
  private _callDestination: IRoutingAppDestinationData = { application: undefined, params: undefined };
  @Input() placeholder: string = 'components.calldestination.redirect_to';
  @Input() canAddSubMenu: boolean;
  @Input() shouldViewSubMenu: boolean;
  @Input() isSubMenu: boolean;
  @Input() excludeDidId: number;
  @Input() excludeQueueId: number;
  @Input() excludeSipPhoneId: number;
  @Input() excludeIvrMenuId: number;
  @Input() voicemail: ICallDestinationVoicemailData;
  @Input() required: boolean;
  // @Input() defaultSound: FileEntryEntity;
  @Input() destinations: string[];
  @Input() routeAppLabelObject: RouteApplicationLabelType;
  @Output() readonly valueChanged: EventEmitter<any> = new EventEmitter<IRoutingAppDestinationData>();

  invalidRoutingDidIds: number[];
  get value(): IRoutingAppDestinationData {
    return this._callDestination;
  }

  @Input()
  set value(val: IRoutingAppDestinationData) {
    if (val) {
      this._callDestination = val;
    } else {
      this._callDestination = { application: undefined, params: undefined };
    }
  }

  private readonly routingAppRepo = RoutingAppRepository.getInstance<RoutingAppRepository>();
  private initialized = false;

  form: FormGroup;
  routingAppList: RoutingAppEntity[] = [];
  smsTemplates: SmsTemplateEntity[] = [];
  errors: any = {};
  excludeQueueIds: number[];
  hasSMSFeature: boolean;

  constructor(
    private readonly configManager: ConfigManager,
    private readonly appSettings: FlexIvrSettings,
    private readonly callDestinationService: CallDestinationService,
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly dialogService: DialogService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.hasSMSFeature = this.configManager.hasFeature(FeatureName.SMS);

    this.form = this.fb.group({
      [ControlNameType.call_application]: [undefined, Validators.compose([Validators.required])],
      [ControlNameType.call_phone_number]: undefined,
      [ControlNameType.digitalsecretary]: undefined,
      [ControlNameType.callqueue]: undefined,
      [ControlNameType.conference]: undefined,
      [ControlNameType.runvxmlscript]: undefined,
      [ControlNameType.call_phone]: undefined,
      [ControlNameType.remote_application]: undefined,
      [ControlNameType.call_user]: undefined,
      [ControlNameType.ivr_custom_menu]: undefined,
      [ControlNameType.ivr_custom_main_menu]: undefined,
      [ControlNameType.to_did]: undefined,
      [ControlNameType.play_then_hangup]: undefined,
      [ControlNameType.send_sms]: undefined,
      [ControlNameType.play_after_send_sms]: undefined,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }
    console.log('callDestination change');
    this.resetForm();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.required === false) {
      this.form.get(ControlNameType.call_application).clearValidators();
    }

    // this.setDefaultHangupSound();

    this.addSubscription(
      this.form.valueChanges.subscribe(dataChanges => {
        if (this.initialized) {
          this.clearFormErrors();
          this.onValueChanged();
        }
      })
    );

    // this.addSubscription(
    //   this.routingAppControl.valueChanges.subscribe(dataChanges => {
    //     this.setDefaultHangupSound();
    //   })
    // );
  }

  // private setDefaultHangupSound(): void {
  //   if (this.defaultSound) {
  //     this.updateSoundB4HangupFile({
  //       new_file_entry: this.defaultSound
  //     });
  //   }
  // }

  async resolveData(): Promise<any> {
    this.excludeQueueIds = [];
    if (this.excludeQueueId) {
      this.excludeQueueIds.push(this.excludeQueueId);
    }

    this.invalidRoutingDidIds = this.em.getRepository<DidRepository>('DidRepository')
      .getInvalidRoutingDid()
      .map(x => x.id);

    if (this.excludeDidId) {
      this.invalidRoutingDidIds.push(this.excludeDidId);
    }

    // load remote application data
    const remoteAppRepo = this.em.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository');
    await remoteAppRepo.loadObjectList();

    const smsTemplateRepo = this.em.getRepository<SmsTemplateRepository>('SmsTemplateRepository');
    await smsTemplateRepo.loadObjectList();
    this.smsTemplates = smsTemplateRepo.getObjectList();

    // set form data
    this.resetForm();

    // if (this.required) {
    //   // check valid if required
    //   this.validParam();
    // }
    this.initialized = true;
  }

  normalizeCallDestination(): any {
    const callDestination = this._callDestination && _.cloneDeep(this._callDestination);
    if (
      callDestination && callDestination.application === RoutingAppName.ivr_custom_menu &&
      callDestination.params && callDestination.params.sub_menu === 0
    ) {
      callDestination.application = RoutingAppName.ivr_custom_main_menu;
    }

    console.log('normalizeCallDestination', callDestination);
    return callDestination;
  }

  private setRoutingAppList(): void {
    let routingApps = this.routingAppRepo.getObjectList();

    if (this.destinations) {
      routingApps = routingApps.filter(app => this.destinations.includes(app.application));
    }

    const routingAppList: RoutingAppEntity[] = [];
    for (const routeApp of routingApps) {
      // not add option ivr_custom_menu if not having sub-menu
      if (routeApp.application === RoutingAppName.ivr_custom_menu && this.isSubMenu) {
        console.error('Invalid params with ivr-custom-menu');
        continue;
      }
      // not add option ivr_custom_menu if having sub-menu
      if (routeApp.application === RoutingAppName.go_up && !this.isSubMenu) {
        console.error('Invalid params with go_up');
        continue;
      }
      // if (routeApp.application === RoutingAppName.reprompt && !this.isSubMenu) {
      //   console.error('Invalid params with reprompt');
      //   continue;
      // }
      if (routeApp.required_features) {
        const has_required_features = _.find(routeApp.required_features, f => !this.configManager.hasFeature(f)) ? false : true;

        if (!has_required_features) {
          console.error('Invalid params with required_features');
          continue;
        }
      }
      if (routeApp.ui_modes && routeApp.ui_modes.indexOf(this.appSettings.ui_mode) === -1) {
        console.error('Invalid params ui-modes');
        continue;
      }
      routingAppList.push(routeApp);
    }

    this.routingAppList = this.hasSMSFeature && routingAppList || routingAppList.filter(i => i.application !== RoutingAppName.send_sms);

    if (this.isEnabledVoicemail()) {
      this.routingAppList.push(this.getRoutingAppVoicemail());
    }

    if (this.destinations && this.destinations.includes(RoutingAppName.ivr_custom_main_menu)) {
      const subMenuIndex = this.routingAppList.findIndex(i => i.application === RoutingAppName.ivr_custom_menu);
      this.routingAppList.splice(subMenuIndex, 0, this.getRoutingAppIvrMainMenu());
    }
  }

  private isEnabledVoicemail(): boolean {
    return this.voicemail && this.voicemail.voicemail_enabled;
  }

  private getRoutingAppVoicemail(): RoutingAppEntity {
    const routeApp = new RoutingAppEntity();
    routeApp.application = RoutingAppName.voicemail;
    routeApp.app_name = 'routing_application.voicemail.app_name';
    return routeApp;
  }

  private getRoutingAppIvrMainMenu(): RoutingAppEntity {
    const routeApp = new RoutingAppEntity();
    routeApp.application = RoutingAppName.ivr_custom_main_menu;
    routeApp.app_name = 'routing_application.ivr_custom_main_menu.app_name';
    routeApp.params = [{ param_name: 'id' }];
    return routeApp;
  }

  private getRoutingAppParams(callDestination: IRoutingAppDestinationData): RoutingAppParamType {
    const appParams: RoutingAppParamType = {};
    if (callDestination && callDestination.params) {
      switch (callDestination.application) {
        case RoutingAppName.callqueue:
          appParams.callqueue = this.em.getRepository<CallQueueRepository>('CallQueueRepository')
            .getObjectById((callDestination.params as RoutingParamsQueue).queue);
          break;
        case RoutingAppName.remote_application:
          appParams.remote_application = this.em.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository')
            .getObjectById((callDestination.params as RoutingParamsRemoteApp).id);
          break;
        case RoutingAppName.runvxmlscript:
          appParams.runvxmlscript = this.em.getRepository<IvrScriptRepository>('IvrScriptRepository')
            .getObjectById((callDestination.params as RoutingParamsRunVxml).ivrscript_id);
          break;
        case RoutingAppName.call_phone:
          appParams.call_phone = this.em.getRepository<SipPhoneRepository>('SipPhoneRepository')
            .getObjectById((callDestination.params as RoutingParamsSipphone).sipphone_id);
          break;
        case RoutingAppName.call_user:
          appParams.call_user = this.em.getRepository<UserRepository>('UserRepository')
            .getObjectById((callDestination.params as RoutingParamsCrmRoute).id);
          break;
        case RoutingAppName.conference:
          appParams.conference = this.em.getRepository<ConferenceRepository>('ConferenceRepository')
            .getObjectById((callDestination.params as RoutingParamsConference).conference);
          break;
        case RoutingAppName.ivr_custom_menu:
          appParams.ivr_custom_menu = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository')
            .getObjectById((callDestination.params as RoutingParamsIvrMenu).id);
          break;
        case RoutingAppName.ivr_custom_main_menu:
          appParams.ivr_custom_main_menu = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository')
            .getObjectById((callDestination.params as RoutingParamsIvrMenu).id);
          break;
        case RoutingAppName.call_phone_number:
          appParams.call_phone_number = (callDestination.params as RoutingParamsPhoneNumber).number;
          break;
        case RoutingAppName.play_then_hangup:
          appParams.play_then_hangup = this.em.getRepository<FileEntryRepository>('FileEntryRepository')
            .getObjectById((callDestination.params as RoutingParamsPlayFileAndHangup).sound_id);
          break;
        case RoutingAppName.digitalsecretary:
          appParams.digitalsecretary = (callDestination.params as RoutingParamsDigitalSecretary).default_ext;
          break;
        case RoutingAppName.to_did:
          appParams.to_did = this.em.getRepository<DidRepository>('DidRepository')
            .getObjectById((callDestination.params as RoutingParamsToDid).did_id);
          break;
        case RoutingAppName.send_sms:
          if (this.hasSMSFeature) {
            appParams.send_sms = this.em.getRepository<SmsTemplateRepository>('SmsTemplateRepository')
              .getObjectById((callDestination.params as RoutingParamsSendSms).sms_id);
            appParams.play_after_send_sms = this.em.getRepository<FileEntryRepository>('FileEntryRepository')
              .getObjectById((callDestination.params as RoutingParamsSendSms).sound_id);
          }
          break;
        case RoutingAppName.voicemail:
          if (this.voicemail.mailbox_id) {
            appParams.mailbox_id = this.voicemail.mailbox_id;
          }
          break;
        default:
          break;
      }
    }

    return appParams;
  }

  private getFormResetData(): any {
    const formData = {
      [ControlNameType.call_application]: undefined,
      [ControlNameType.call_phone_number]: undefined,
      [ControlNameType.digitalsecretary]: undefined,
      [ControlNameType.callqueue]: undefined,
      [ControlNameType.conference]: undefined,
      [ControlNameType.runvxmlscript]: undefined,
      [ControlNameType.call_phone]: undefined,
      [ControlNameType.remote_application]: undefined,
      [ControlNameType.call_user]: undefined,
      [ControlNameType.ivr_custom_menu]: undefined,
      [ControlNameType.ivr_custom_main_menu]: undefined,
      [ControlNameType.to_did]: undefined,
      [ControlNameType.play_then_hangup]: undefined,
      [ControlNameType.send_sms]: undefined,
      [ControlNameType.play_after_send_sms]: undefined,
    };

    const callDestination = this.normalizeCallDestination();
    let routingApp: RoutingAppEntity;
    if (!_.isEmpty(callDestination) && !_.isEmpty(callDestination.application)) {
      switch (callDestination.application) {
        case RoutingAppName.voicemail:
          if (this.isEnabledVoicemail()) {
            routingApp = this.getRoutingAppVoicemail();
          }
          break;
        case RoutingAppName.ivr_custom_main_menu:
          routingApp = this.getRoutingAppIvrMainMenu();
          break;
        default:
          routingApp = this.routingAppRepo.getObjectByApp(callDestination.application);
      }

      // if (this.isEnabledVoicemail() && this._callDestination.application === RoutingAppName.voicemail) {
      //   routingApp = this.getRoutingAppVoicemail();
      // } else {
      //   routingApp = this.routingAppRepo.getObjectByApp(this._callDestination.application);
      // }
    }

    if (!_.isEmpty(routingApp) && !_.isEmpty(routingApp.application)) {
      formData.call_application = _.cloneDeep(routingApp);

      const appParams = this.getRoutingAppParams(callDestination);
      _.extend(formData, appParams);
    }

    console.log('formData: ', formData);
    return formData;
  }

  resetForm(): void {
    this.setRoutingAppList();
    this.form.reset(this.getFormResetData(), { onlySelf: true, emitEvent: false });
    this.form.markAsPristine();
    this.cdr.markForCheck();
  }

  getReturnCallDestinationValue(): IRoutingAppDestinationData {
    const application = this.routingAppControl.value?.application;
    const retCallDestination = {
      application,
      params: this.getReturnCallDestinationParams(application)
    };

    return retCallDestination;
  }

  private getReturnCallDestinationParams(application: string): any {
    const retParams: any = {};

    if (application) {
      switch (application) {
        case RoutingAppName.callqueue:
          retParams.queue = this.callqueueControl.value?.id;
          break;
        case RoutingAppName.remote_application:
          retParams.id = this.remoteApplicationControl.value?.id;
          break;
        case RoutingAppName.runvxmlscript:
          retParams.ivrscript_id = this.runvxmlscriptControl.value?.id;
          retParams.script_params = {};
          break;
        case RoutingAppName.call_phone:
          retParams.sipphone_id = this.callPhoneControl.value?.id;
          break;
        case RoutingAppName.call_user:
          retParams.id = this.callUserControl.value?.id;
          break;
        case RoutingAppName.conference:
          retParams.conference = this.conferenceControl.value?.id;
          break;
        case RoutingAppName.ivr_custom_menu:
          retParams.id = this.ivrCustomMenuControl.value?.id;
          retParams.sub_menu = 1;
          if (this.canAddSubMenu) {
            retParams.ivr_custom_menu = this.ivrCustomMenuControl.value;
          }
          break;
        case RoutingAppName.ivr_custom_main_menu:
          retParams.id = this.ivrCustomMainMenuControl.value?.id;
          retParams.sub_menu = 0;
          break;
        case RoutingAppName.call_phone_number:
          retParams.number = this.callPhoneNumberControl.value;
          break;
        case RoutingAppName.play_then_hangup:
          retParams.sound_id = this.playThenHangupControl.value?.id || null;
          break;
        case RoutingAppName.digitalsecretary:
          retParams.default_ext = this.digitalsecretaryControl.value;
          break;
        case RoutingAppName.to_did:
          retParams.did_id = this.toDidControl.value?.id;
          break;
        case RoutingAppName.send_sms:
          retParams.sms_id = this.sendSMSControl.value?.id;
          retParams.sound_id = this.playAfterSendSMSControl.value?.id;
          break;
        case RoutingAppName.voicemail:
          if (this.voicemail.mailbox_id) {
            retParams.mailbox_id = this.voicemail.mailbox_id;
          }
          break;
        default:
          break;
      }
    }

    return retParams;
  }

  clearFormErrors(): void {
    const application = this.routingAppControl.value && this.routingAppControl.value.application;
    const excludeNames = [ControlNameType[application], ControlNameType.call_application, ControlNameType.play_after_send_sms];
    if (application === RoutingAppName.send_sms) {
      excludeNames.push(ControlNameType.play_after_send_sms);
    }
    const controlNames: string[] = Object.keys(this.form.controls).filter(f => !_.includes(excludeNames, f));
    for (const key of controlNames) {
      this.form.get(key).setErrors(null);
      this.form.get(key).markAsPristine();
    }
  }

  clearValidations(): void {
    // const application = this.routingAppControl.value?.application;

    this.errors = {};

    const keysForm: string[] = Object.keys(this.form.controls); // .filter(x => !_.includes(['routing_app'], x))
    for (const key of keysForm) {
      this.form.controls[key].setErrors(null);
      this.form.controls[key].markAsUntouched();
    }

    this.form.setErrors(null);
    this.form.clearValidators();

    // if (application && this.form.get(application)) {
    //   let validations = [ Validators.required ];
    //   if (_.includes(['call_phone_number', 'digitalsecretary'], application)) {
    //     validations = [ Validators.required, Validators.pattern('[0-9]{1,20}') ];
    //   }

    //   this.form.get(application).setValidators(validations);
    // }
  }

  validParam(): boolean {
    const application = this.routingAppControl.value && this.routingAppControl.value.application;
    this.errors = {};

    if (this.required !== false || application) {
      this.errors = this.callDestinationService.validCallDestination(this.getReturnCallDestinationValue());
      for (const key of Object.keys(this.errors)) {
        const ctrl = this.form.get(key) as FormControl;
        if (ctrl) {
          const errObj: any = {};
          errObj[key] = true;
          ctrl.setErrors(errObj);
        }
      }

      if (this.form.invalid) {
        this.form.markAllAsTouched();

        this.cdr.markForCheck();
        return false;
      }

      this.cdr.markForCheck();
    }

    return _.isEqual(this.errors, {});
  }

  updateSubMenuParam(ivrMenu: IvrCustomMenuEntity): void {
    this.ivrCustomMenuControl.markAsDirty();
    this.ivrCustomMenuControl.setValue(ivrMenu);
  }

  updateSoundB4HangupFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivrmenu');
    }
    this.playThenHangupControl.markAsDirty();
    this.playThenHangupControl.setValue(file_entry);
  }

  updateSoundAfterSendSMSFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivrmenu');
    }
    this.playAfterSendSMSControl.markAsDirty();
    this.playAfterSendSMSControl.setValue(file_entry);
  }

  onValueChanged(): void {
    if (this.valueChanged) {
      setTimeout(() => {
        if (!this.form.dirty) {
          return;
        }
        const retValue = this.getReturnCallDestinationValue();
        console.log('call-destination onValueChanged', retValue);

        // re-binding into callDestination
        this._callDestination.application = retValue.application;
        this._callDestination.params = retValue.params;

        this.valueChanged.emit(retValue);
        this.cdr.markForCheck();
      });
    }
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  openEditIvrSubMenu(ivrCustomMenu: any): void {
    const dialogRef = this.dialogService.openSideDialog(IvrSubMenuEditDialog, {
      data: {
        ivrCustomMenu,
        excludeIvrMenuId: this.excludeIvrMenuId
      },
      size: 's'
    });

    dialogRef.afterClosed().subscribe(newIvrSubMenu => {
      console.log('newIvrSubMenu: ', newIvrSubMenu);
      if (newIvrSubMenu) {
        this.ivrCustomMenuControl.markAsDirty();
        this.ivrCustomMenuControl.setValue(newIvrSubMenu);
      }
    });
  }

  routingAppCompareWith = (a, b) => {
    return a && b && a.application === b.application;
  }

  smsTemplateCompareWith = (a: SmsTemplateEntity, b: SmsTemplateEntity) => {
    return a && b && a.id === b.id;
  }

  getRoutingAppName(routeApp: RoutingAppEntity): string {
    if (_.includes(Object.keys(this.routeAppLabelObject || {}), routeApp.application)) {
      return this.routeAppLabelObject[routeApp.application];
    }

    return _ti(routeApp.app_name);
  }

  get routingAppControl(): FormControl {
    return this.form.get(ControlNameType.call_application) as FormControl;
  }

  get callPhoneNumberControl(): FormControl {
    return this.form.get(ControlNameType.call_phone_number) as FormControl;
  }

  get digitalsecretaryControl(): FormControl {
    return this.form.get(ControlNameType.digitalsecretary) as FormControl;
  }

  get callqueueControl(): FormControl {
    return this.form.get(ControlNameType.callqueue) as FormControl;
  }

  get conferenceControl(): FormControl {
    return this.form.get(ControlNameType.conference) as FormControl;
  }

  get runvxmlscriptControl(): FormControl {
    return this.form.get(ControlNameType.runvxmlscript) as FormControl;
  }

  get callPhoneControl(): FormControl {
    return this.form.get(ControlNameType.call_phone) as FormControl;
  }

  get remoteApplicationControl(): FormControl {
    return this.form.get(ControlNameType.remote_application) as FormControl;
  }

  get callUserControl(): FormControl {
    return this.form.get(ControlNameType.call_user) as FormControl;
  }

  get toDidControl(): FormControl {
    return this.form.get(ControlNameType.to_did) as FormControl;
  }

  get ivrCustomMenuControl(): FormControl {
    return this.form.get(ControlNameType.ivr_custom_menu) as FormControl;
  }

  get ivrCustomMainMenuControl(): FormControl {
    return this.form.get(ControlNameType.ivr_custom_main_menu) as FormControl;
  }

  get playThenHangupControl(): FormControl {
    return this.form.get(ControlNameType.play_then_hangup) as FormControl;
  }

  get sendSMSControl(): FormControl {
    return this.form.get(ControlNameType.send_sms) as FormControl;
  }

  get playAfterSendSMSControl(): FormControl {
    return this.form.get(ControlNameType.play_after_send_sms) as FormControl;
  }
}
