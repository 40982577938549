import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild } from '@angular/core';
import { PaymentAdyenService } from '@wephone/services/payment-adyen.service';
import { DialogComponentBase } from '@wephone-core-ui';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActionButton, Colors, joiValidator } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { PaymentService } from '@wephone/services/payment.service';
import { StripeService } from 'ngx-stripe';
// Elements, Element as StripeElement, TokenResult, ElementsOptions
import {
  Token,
  StripeError,
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfigManager } from '@wephone-core/wephone-core.module';
// import { TranslateService } from '@ngx-translate/core';
import * as Joi from 'joi-browser';
import { SystemParam } from '@wephone-core/system';
import { SubscriptionService } from '@wephone/services/subscription.service';

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss']
})
export class AddCreditCardComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('enterprise_info.add_credit_card_dialog.title');
  dialogRightActions: Array<DialogActionButton> = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.onSubmit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  error: string;

  elements: StripeElements;
  card: StripeCardElement;
 
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  // optional parameters
  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  stripeForm: FormGroup;

  constructor(
    public readonly adyenService: PaymentAdyenService,
    dialogRef: MatDialogRef<AddCreditCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      extend_subscription_pack: string
    },
    private readonly fb: FormBuilder,
    cdr: ChangeDetectorRef,
    private readonly configManager: ConfigManager,
    private readonly stripeService: StripeService,
    private readonly subscriptionService: SubscriptionService,
    // private readonly translateService: TranslateService,
    private readonly paymentService: PaymentService,
  ) {
    super(dialogRef, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    const locale: any = this.paymentService.getLocaleForStripe();
    this.elementsOptions.locale = locale;
    const stripePublishableKey: string = this.configManager.getSystemParam(SystemParam.stripe_publishable_key);

    this.stripeService.setKey(stripePublishableKey);
    this.stripeForm = this.fb.group({
      name: ['', joiValidator(Joi.string().optional().allow(''))] // required()
    });
    this.setForm(this.stripeForm);

    this.addSubscription(
      this.stripeService.elements(this.elementsOptions)
        .subscribe(elements => {
          this.elements = elements;
          // Only mount the element the first time
          if (!this.card) {
            this.card = this.elements.create('card', this.cardOptions);
          }
  
          setTimeout(() => {
            this.card.mount('#stripeCard'); // make sure element stripeCard was loaded
          });
        })
    );
  }

  async onSubmit(): Promise<void> {
    this.error = undefined;
    const name = this.stripeForm.get('name').value;
    if (this.stripeForm.invalid) {
      this.stripeForm.get('name').markAllAsTouched();
      console.error('Invalid form');
      return;
    }

    this.stripeService
      .createToken(this.card, { name })
      .subscribe(async (result: {
          token?: Token;
          error?: StripeError;
      }) => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log('token', result.token);
          try {
            let ret: any;
            if (!this.data || !this.data.extend_subscription_pack) {
              const customer = await this.paymentService.updateSource(result.token.id, 'card');
              console.log('customer: ', customer);
              ret = customer;
            } else {
              ret = await this.subscriptionService.subscribeSubscriptionPack(this.data.extend_subscription_pack, result.token.id, 'card');
            }

            this.toastService.showInfo(_ti('public.message.update_success'));
            this.form.markAsPristine();
            this.dismiss(ret);
          } catch (error) {
            console.error('Update source failure', error);
            this.error = error.message;
            this.toastService.showError(_ti('public.message.update_failure'));
          }
        } else if (result.error) {
          // Error creating the token
          console.error('Error stripe: ', result.error);
          this.error = result.error.message;
          this.toastService.showError(_ti('public.message.update_failure'));
        }
        this.detectChanges();
      });
  }

  public cancel(): void {
    this.dismiss();
  }
}
