import { Component } from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors, IFlexDialogConfig } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { FormControl, Validators } from '@angular/forms';
import { PhoneNumberValidated } from '@wephone/services/form-validator';
import { MatDialogRef } from '@angular/material/dialog';

export enum ForceCallToObjectType {
  AGENT = 0,
  SECONDARY_NUMBER = 1,
  PHONE_NUMBER = 2
}

@Component({
  selector: 'dialog-choose-agent-or-number',
  templateUrl: 'dialog-choose-agent-or-number.html',
  styleUrls: ['./dialog-choose-agent-or-number.scss']
})
export class ChooseAgentOrNumberDialog extends DialogComponentBase {
  static modalConfig: IFlexDialogConfig = {
    size: 'l'
  };

  dialogTitle = _tk('livecall.actions.select_handled_call');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.navigation.back'),
      action: () => {
        this.goBack();
      },
      visible: () => {
        return this.state === 1;
      },
      color: Colors.WARN
    },
    {
      label: _tk('public.apply'),
      action: () => {
        this.onNumberSelected();
      },
      visible: () => {
        return this.state === 1;
      },
      color: Colors.PRIMARY
    },
  ];

  selectedAgent: AgentEntity;
  selectedNumber: string;
  selectedType: ForceCallToObjectType = ForceCallToObjectType.AGENT;
  state: number;
  isSubmited = false;

  constructor(dialogRef: MatDialogRef<ChooseAgentOrNumberDialog>) {
    super(dialogRef);
    this.state = 0;
  }

  get secondary_number(): string {
    return this.selectedAgent.user.phone;
  }

  onAgentSelected(agent: AgentEntity): void {
    this.selectedAgent = agent;
    this.state = 1;
  }

  selectSecondaryNumber(e: MouseEvent): void {
    e.stopPropagation();
    this.selectedType = 1;
  }

  selectOtherNumber(e: MouseEvent): void {
    e.stopPropagation();
    this.selectedType = 2;
    this.isSubmited = false;
  }

  onNumberSelected(): void {
    this.isSubmited = true;

    let phoneNumber: string;

    if (this.selectedType !== ForceCallToObjectType.AGENT) {
      phoneNumber = this.selectedNumber;

      if (this.selectedType === ForceCallToObjectType.SECONDARY_NUMBER) {
        phoneNumber = this.selectedAgent.user && this.selectedAgent.user.phone;
      }

      if (!this.validPhoneNumber(phoneNumber)) {
        this.showError(_ti('force_call_to_agent.validator.phone_number_invalid'));
        return;
      }
    }

    this.modalRef.returnValue({ agent: this.selectedAgent, phone_number: phoneNumber });
  }

  goBack(): void {
    this.state = 0;
  }

  private validPhoneNumber(phoneNumber: string): boolean {
    const phoneNumberControl: FormControl = new FormControl(phoneNumber, [Validators.required, PhoneNumberValidated()]);
    if (phoneNumberControl.invalid) {
      return false;
    }

    return true;
  }
}
