import { Injectable } from '@angular/core';
import { IPhoneCall } from '@wephone-app-phone/services';
import { DialogService, ToastService } from '@wephone-utils';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { CallCenterQualificationDialog, CallCenterQualificationDialogParams } from './call-center-qualification-dialog/call-center-qualification-dialog';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { _ti } from '@wephone-translation';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { DidRepository } from '@wephone-core/model/repository/did';
import * as _ from 'lodash';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { DidEntity } from '@wephone-core/model/entity/did';

export interface ICallQualify {
  id: number;
  qualification_comment: string;
  out_qualification_id: number;
  in_qualification_id: number;
  queue_ids: number[];
  is_outgoing: number;
  qualification_ids: number[];
  // qualification: string; // Displayed qualifications
}

export interface IQualifyResult {
  qualification_ids: number[];
  qualification_comment: string;
  qualification: string;
}

export type ObjectUsingQualification = DidEntity | CallQueueEntity | OutcallCampaignEntity;
@Injectable()
export class QualificationService {
  // Constants
  private URL_QUALIFY_CALL = 'callcenter/qualify_call';

  // Private member var with default value
  private dialogRef: MatDialogRef<any>;

  // Constructor
  constructor(
    protected dialogService: DialogService,
    protected toastServices: ToastService,
    protected http: HttpEngine
  ) { }

  openQualification(call: IPhoneCall): void {
    if (!call.calldata) {
      console.error('No call data');
      return;
    }

    const dialogData: CallCenterQualificationDialogParams = {
      callQueueSessionId: call.calldata.call_queue_session_id,
      hasAnsweringMachine: !call.isInbound(),
      required: call.queue && !!call.queue.qualification_required || false,
      pathQualIds: call.pathQualIds,
      finished: !!call.pathQualIds.length,
      root_id: call.qualificationId,
      comment: call.qualificationComment,
      canQualify: call.canQualify()
    };

    this.updateQualification(call.calldata.call_queue_session_id, dialogData);
  }

  updateIsOutboundAnsweringMachine(call: IPhoneCall): Promise<any> {
    const dialogData = {
      call
    };

    this.dialogRef = this.dialogService.openDialog2(
      CallCenterQualificationDialog,
      {
        width: '60%',
        data: dialogData,
        disableClose: false,
        padding: false
      }
    );

    return this.dialogRef.afterClosed().toPromise();
  }

  endCallOpenQualification(call: IPhoneCall): void {
    // console.log('endCallOpenQualification call', call);
    const dialogData: CallCenterQualificationDialogParams = {
      callQueueSessionId: call.calldata.call_queue_session_id,
      hasAnsweringMachine: !call.isInbound(),
      required: call.queue && !!call.queue.qualification_required,
      pathQualIds: call.pathQualIds,
      finished: !!call.pathQualIds.length,
      root_id: call.qualificationId,
      comment: call.qualificationComment,
      canQualify: call.canQualify()
    };

    this.updateQualification(call.calldata.call_queue_session_id, dialogData);
  }

  async updateQualification(sessionId: number, dialogData: CallCenterQualificationDialogParams): Promise<any> {
    if (this.dialogRef && this.dialogRef.getState() !== MatDialogState.CLOSED) {
      const callQueueSessionId = this.dialogRef.componentInstance?.data?.callQueueSessionId;
      if (sessionId === callQueueSessionId) {
        console.log('Does not re-open dialog the same');
        return;
      }

      this.dialogRef.close();
    }

    // To prevent error ExpressionChangedAfterItHasBeenCheckedError, wrap the following code into the setTimeout callback function
    setTimeout(async () => {
      this.dialogRef = this.dialogService.openDialog2(
        CallCenterQualificationDialog,
        {
          width: '60%',
          data: dialogData,
          disableClose: !!dialogData.required,
          padding: false
        }
      );
  
      const result = await this.dialogRef.afterClosed().toPromise();
      if (this.dialogRef && this.dialogRef.getState() === MatDialogState.CLOSED) {
        this.dialogRef = undefined;
      }
  
      if (!result) {
        return;
      }
  
      try {
        const ret = await this.saveQualifySession(sessionId, result.isAnsweringMachine, result.selectedQualificationIds, result.qualificationComment);
        this.toastServices.showSuccess(_ti('public.message.update_success'));
        return ret;
  
      } catch (e: any) {
        console.error('Do Qualification error', e);
        const msg = e && e.error && e.error.message || e && e.message || _ti('public.message.update_failure');
        this.toastServices.showError(msg);
      }

    });
  }

  private saveQualifySession(sessionId: number, answeringMachine, qualifyIds: number[], qualifyComment: string): Promise<any> {
    if (answeringMachine) {
      return this.qualifySession(sessionId, 0, null, null, 1);
    }
    return this.qualifySession(sessionId, 0, qualifyIds, qualifyComment, 0);
  }

  /**
   * Qualify a session
   * @param object_id: number, session-id on session-type, client-call-key-id on call-type
   * @param object_type: number, 0: session type, 1: call type
   * @param qualif_data: any
   * @param qualif_comment: string
   * @param answering_machine: number 0|1
   * @returns Promise<any>
   */
  qualifySession(object_id: number, object_type: number, qualif_data: any, qualif_comment: string, answering_machine: number = 0): Promise<any> {
    console.log('qualifySession input', object_id, object_type, qualif_data, qualif_comment, answering_machine);

    const params: any = {
      object_id,
      object_type,
      comment: qualif_comment,
      answering_machine
    };

    if (qualif_data) {
      params['data'] = qualif_data;
    }

    return this.http.apiPostV2(this.URL_QUALIFY_CALL, params);
  }

  async reQualify(call: ICallQualify): Promise<IQualifyResult> {
    const queueId = call.queue_ids && call.queue_ids[0];
    if (!queueId) {
      console.warn('No queue or queue has no ID');
    }

    const queue: CallQueueEntity = EntityManager.getRepository('CallQueueRepository').getObjectById(queueId);

    if (queue && !queue.has_qualification) {
      console.warn('Queue has no qualification');
    }

    let rootQualId: number;
    if (queue) {
      rootQualId = call.is_outgoing ? queue.out_qualification_id : queue.in_qualification_id;
    }

    if (!rootQualId) {
      console.warn('No root qualification id');
    }

    const pathQualIds: QualificationEntity[] = [];

    const dialogData: CallCenterQualificationDialogParams = {
      hasAnsweringMachine: !!call.is_outgoing,
      required: queue && !!queue.qualification_required || false,
      pathQualIds: call.qualification_ids,
      finished: pathQualIds && pathQualIds.length ? true : false,
      root_id: rootQualId,
      comment: call.qualification_comment,
      canQualify: queue && !!queue.has_qualification || false
    };

    const dialogRef = this.dialogService.openDialog2(
      CallCenterQualificationDialog,
      {
        width: '60%',
        data: dialogData,
        padding: false,
      }
    );

    const result: any = await dialogRef.afterClosed().toPromise();

    if (!result) {
      return;
    }

    if (result.isAnsweringMachine) {
      return this.updateQualificationFromCall(call, null, null, null, 1);
    }

    return this.updateQualificationFromCall(call, result.selectedQualificationIds, result.selectedQualificationDisplayed, result.qualificationComment, 0);
  }

  private async updateQualificationFromCall(call: ICallQualify, qualIds: number[], qualDisplayed: string, comment: string, answeringMachine: number): Promise<IQualifyResult> {
    let ret: IQualifyResult = null;
    try {
      const id: number = call.id;
  
      await this.qualifySession(id, 1, qualIds, comment, answeringMachine);
      this.toastServices.showSuccess(_ti('public.message.update_success'));

      if (answeringMachine) {
        ret = {
          qualification_ids: [],
          qualification_comment: '',
          qualification: _ti('qualification.dialog.answering_machine')
        };
      } else {
        ret = {
          qualification_ids: qualIds,
          qualification_comment: comment,
          qualification: qualDisplayed
        };
      }
    } catch (error) {
      console.error('Save qualification failure', error);
      const msgError = call.qualification_comment || call.qualification_ids && call.qualification_ids.length ? _ti('public.message.update_failure') : _ti('public.message.create_failure');
      this.toastServices.showError(msgError);
    }

    return ret;
  }

  // getDidsUsingQualifications(qualifIds: number[]): DidEntity[] {
  //   const queuesUsingQualif: CallQueueEntity[] = EntityManager.getInstance().getRepository<CallQueueRepository>('CallQueueRepository').getObjectList()
  //     .filter(q => {
  //       return (!!q.dedicated_user_id || !!q.default_did_id) &&
  //         q.has_qualification && (!!q.in_qualification_id || !!q.out_qualification_id) &&
  //         _.intersection(qualifIds, [+q.in_qualification_id, +q.out_qualification_id]).length > 0;
  //     });

  //   const didList: DidEntity[] = EntityManager.getInstance().getRepository<DidRepository>('DidRepository').getObjectList();

  //   return didList.filter(d => {
  //     return queuesUsingQualif.filter(q => (d.hasLinkedUser() && d.linked_object_id === q.dedicated_user_id) || (q.default_did_id === d.id)).length > 0;
  //   });
  // }

  getObjectsUsingQualifications(qualifIds: number[]): ObjectUsingQualification[] {
    let ret: ObjectUsingQualification[] = [];

    const queuesUsingQualif: CallQueueEntity[] = EntityManager.getInstance().getRepository<CallQueueRepository>('CallQueueRepository').getObjectList()
      .filter(q => q.has_qualification &&
        (!!q.in_qualification_id || !!q.out_qualification_id) &&
        _.intersection(qualifIds, [+q.in_qualification_id, +q.out_qualification_id]).length > 0
      );

    if (!queuesUsingQualif.length) {
      return ret;
    }

    // Add in-bound callQueue
    const inboundQueues = queuesUsingQualif.filter(q => !q.is_outbound_campaign);
    if (inboundQueues.length) {
      ret = ret.concat(inboundQueues);
    }

    // Add outcall campaign
    const campaignsUsingQueue: OutcallCampaignEntity[] =
      EntityManager.getInstance().getRepository<OutcallCampaignRepository>('OutcallCampaignRepository').getObjectList()
        .filter(c => !!queuesUsingQualif.find(q => q.is_outbound_campaign && q.id === c.queue_id));

    if (campaignsUsingQueue.length) {
      ret = ret.concat(campaignsUsingQueue);
    }

    const didsUsingQueue: DidEntity[] = EntityManager.getInstance().getRepository<DidRepository>('DidRepository').getObjectList()
      .filter(d => !!queuesUsingQualif.find(q =>
        // (!!q.dedicated_user_id && d.hasLinkedUser() && d.linked_object_id === q.dedicated_user_id)
        // ||
        (!!d.routed_queue && d.routed_queue.id === q.id)
      )); // || (!!q.default_did_id && q.default_did_id === d.id)

    if (didsUsingQueue.length) {
      ret = ret.concat(didsUsingQueue);
    }

    return ret;
  }
}
