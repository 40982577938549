<ng-template #listFilterTpl>
  <form [formGroup]="enterpriseCrmForm" class="filter-form" fxLayout="row" fxLayout.xs="column"
    fxLayoutAlign="end center" fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
    <div>
      <flex-search-input formControlName="filterValue" placeholder="{{ 'public.search' | translate }}"
        [hintText]="'enterprise_crm.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>

<ng-template #activeTpl let-item="row">
  <mat-icon *ngIf="item.is_active">done</mat-icon>
  <mat-icon *ngIf="!item.is_active"></mat-icon>
</ng-template>

<ng-template #agentIdTpl let-item="row">
  <mat-icon *ngIf="item.item">done</mat-icon>
</ng-template>

<ng-template #nameTpl let-item="row">
  <div fxLayout="row" fxLayoutAlign="start center">
    <span>{{ item.name }}&nbsp;</span>
    <img class="img-logo" [src]="getLogo(item)" alt="{{item.type}}">
  </div>
</ng-template>

<flex-crud-page #flexCrud [pageTitle]="(isBusinessHotel ? 'menu.manage_enterprise_pms' : 'menu.manage_enterprise_crm')|translate" [filterTemplate]="listFilterTpl"
  [tableConfig]="tableConfig" [dataSource]="dataSource"
  [columnTemplates]="{'name': nameTpl, 'is_active': activeTpl, 'use_agent_id': agentIdTpl}" [sidePannelOptions]="sidePannelOptions"
  fxLayout="row" fxFlex="grow">
</flex-crud-page>
