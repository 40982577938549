<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="sipTrunkForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
      fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterValue"
          [hintText]="'sip_trunk.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>
  <flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_siptrunk'| translate }}" [filterTemplate]="listFilterTpl"
    [tableConfig]="tableConfig" [dataSource]="dataSource"
    [columnTemplates]="{name: tplName, 'outgoing_enabled': outgoingTpl, 'is_default': tplIsDefault, 'last_online_dt': tplLastOnlineDt}"
    [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">
  </flex-crud-page>
</div>
<ng-template #tplName let-siptrunk="row">
  <fa-icon [icon]="FAIcons.faCircle" class="mr-1"
    [ngClass]="{'mat-text-muted': !siptrunk.is_online, 'is-online': siptrunk.is_online}">brightness_1</fa-icon>
  <span>{{siptrunk.name}}</span>
</ng-template>
<ng-template #outgoingTpl let-outgoing="row">
  <mat-icon>{{ outgoing.outgoing_enabled ? 'done' : 'close'}}</mat-icon>
</ng-template>
<ng-template #tplIsDefault let-item="row">
  <mat-icon *ngIf="item.is_default" class="text-red">check</mat-icon>
</ng-template>
<ng-template #tplLastOnlineDt let-siptrunk="row">
  {{ !siptrunk.is_online ? (siptrunk.last_online_dt|timeFormatDateTimeShort) : ('sipphone.content.online_now'|translate)
  }}
</ng-template>
