import { Pipe, PipeTransform } from '@angular/core';
import { DidRoutePriority } from '@wephone-core/model/entity/did';
import { _ti } from '@wephone-translation';

@Pipe({
    name: 'routePriority'
})
export class RoutePriorityPipe implements PipeTransform {
    static priorityList = {};

    constructor() {
        const list: {[keys: number]: string} = {};
        list[DidRoutePriority.VeryHigh] = 'call_queue.content.priority_level.very_high';
        list[DidRoutePriority.High] = 'call_queue.content.priority_level.high';
        list[DidRoutePriority.Normal] = 'call_queue.content.priority_level.normal';
        list[DidRoutePriority.Low] = 'call_queue.content.priority_level.low';
        list[DidRoutePriority.VeryLow] = 'call_queue.content.priority_level.very_low';

        RoutePriorityPipe.priorityList = list;
    }

    transform(value: number): string {
        if (!value && value !== 0) {
            return '';
        }

        return RoutePriorityPipe.priorityList[value];
    }
}
