import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { SubscriptionManagedComponent } from '../base/subscription-managed-component';
import { ActivatedRoute } from '@angular/router';
import { Directive, OnDestroy, OnInit } from '@angular/core';

@Directive()
export class ParamRoutableComponent extends SubscriptionManagedComponent implements OnInit, OnDestroy {
    protected activatedRoute: ActivatedRoute;

    constructor() {
        super();
        this.activatedRoute = StaticServiceLocator.injector.get(ActivatedRoute);
    }

    ngOnInit(): void {
        this.addSubscription(
            this.activatedRoute.queryParams.subscribe(
                params => {
                    return this.onRouteQueryParamChange(params);
                }
            )
        );
        this.addSubscription(
            this.activatedRoute.params.subscribe(
                params => {
                    return this.onRouteParamChange(params);
                }
            )
        );
    }

    protected onRouteQueryParamChange(params: any): any {
    }

    protected onRouteParamChange(params: any): any {
    }
}
