import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DidOrderService } from '../services/did-order.service';
import { IOrderDidsData } from '../services/order-did-data.i';
import { ToastService, Colors, DialogActionButton, IFlexDialogConfig, UIBlockerService, regexSearch } from '@wephone-utils';
// import { TranslateService } from '@ngx-translate/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { DidCountryEntity } from '@wephone-core/model/entity/did_country';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { DidService, DidNumberTypesResponse, DidGroupsByTypeResponse, DidCountryItem } from '@wephone/services/did.service';
import { DidCountryStateEntity } from '@wephone-core/model/entity/did_country_state';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { SystemParam } from '@wephone-core/system';
import { DidTelecomProvider } from '@wephone-core/model/entity/did';

enum StepDidOrder {
  SELECT_COUNTRY = 'select_country',
  SELECT_NUMBER_TYPE = 'select_number_type',
  SELECT_STATE = 'select_state',
  SELECT_REGION = 'select_region',
  CONFIRM = 'confirm',
}

interface DidNumberType {
  // country: DidCountryItem;
  area_codes: string[];
  area_codes_display: string;
  name: string;
  value: number;
}

interface DidNumberRegion {
  country?: DidCountryItem;
  type: DidNumberType;
  state?: DidCountryStateEntity;
  name: string;
  prefix: string;
  monthly_cost: number;
  setup_cost: number;
}

interface CartItem {
  country: DidCountryItem;
  region: DidNumberRegion;
  quantity: number;
}

@Component({
  selector: 'app-did-order-global',
  templateUrl: './did-order-global.component.html',
  styleUrls: ['./did-order-global.component.scss']
})
export class DidOrderGlobalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '650px',
    minHeight: '90%',
  };

  dialogTitle = _tk('call_queue.content.transfer_number.add');
  backButton: DialogActionButton = {
    label: _tk('public.navigation.prev'),
    action: () => {
      this.goPrevStep();
    },
    visible: () => {
      return this.steps.indexOf(this.step) > 0 && this.step !== StepDidOrder.CONFIRM;
    },
    color: Colors.ACCENT
  };
  dialogLeftActions: DialogActionButton[] = [
    {
      getLabel: () => {
        return this.cartLabel;
      },
      id: 'gotoCart',
      icon: 'shopping_cart',
      action: () => {
        this.goToCart();
      },
      visible: () => {
        return this.step !== StepDidOrder.CONFIRM;
      },
      color: Colors.ACCENT
    }
  ];

  dialogRightActions: DialogActionButton[] = [
    // {
    //   label: _tk('public.navigation.next'),
    //   action: () => {
    //     this.goNextStep();
    //   },
    //   visible: () => {
    //     return this.steps.indexOf(this.step) < 4;
    //   },
    //   color: Colors.PRIMARY
    // },
    {
      label: _tk('public.button.validate'),
      action: () => {
        this.goNextStep();
      },
      visible: () => {
        return this.step === StepDidOrder.CONFIRM;
      },
      color: Colors.PRIMARY
    }
  ];

  errors: any = {};
  cart: CartItem[] = [];
  step: StepDidOrder;
  // steps = [
  //   StepDidOrder.SELECT_COUNTRY,
  //   StepDidOrder.SELECT_NUMBER_TYPE,
  //   StepDidOrder.SELECT_STATE,
  //   StepDidOrder.SELECT_REGION,
  //   StepDidOrder.CONFIRM,
  // ];
  country: DidCountryItem;
  state: DidCountryStateEntity;
  filterCountry: string;
  filterRegion: string;
  type: DidNumberType;
  types: DidNumberType[] = [];
  region: DidNumberRegion;
  regions: DidNumberRegion[] = [];
  countries: DidCountryItem[] = [];
  globalCountries: DidCountryEntity[] = [];
  states: DidCountryStateEntity[] = [];
  monthlyCost: number = 0;
  numberProvider: DidTelecomProvider;

  constructor(
    dialogRef: MatDialogRef<DidOrderGlobalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private didOrderService: DidOrderService,
    private didService: DidService,
    private toast: ToastService,
    private settings: FlexIvrSettings,
    private em: EntityManager,
    // private translate: TranslateService,
    private readonly configManager: ConfigManager
  ) {
    super(dialogRef);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.globalCountries = this.em.getRepository('DidCountryRepository').getObjectList();

    this.states = await this.em.getRepository('DidCountryStateRepository').findAll();

    let enterprise: EnterpriseEntity;
    if (!this.settings.isProjectSuperAdmin()) {
      enterprise = (this.em.getRepository<EnterpriseRepository>('EnterpriseRepository') as EnterpriseRepository).getMyEnterprise();
    } else if (this.data.eid) {
      enterprise = this.em.getRepository('EnterpriseRepository').getObjectById(this.data.eid);
    }

    console.log('SystemParam.phone_number_provider', SystemParam.phone_number_provider);
    this.numberProvider = this.configManager.getSystemParam(SystemParam.phone_number_provider);
    this.countries = await this.didService.getDidCountries(this.numberProvider);

    if (enterprise) {
      const subscriptionPack: SubscriptionPackEntity = await enterprise.getCurrentSubscriptionPack();
      // console.log('subscriptionPack', subscriptionPack);
      if (subscriptionPack) {
        this.monthlyCost = subscriptionPack.per_number;
      }
    }

    // console.log('this.countries', this.countries);
    this.step = StepDidOrder.SELECT_COUNTRY;
    this.updateDialogLayout();
  }

  // Not in-used
  // get numberProviderLabel(): string {
  //   return this.numberProvider === DidTelecomProvider.twilio ? 'Twilio' : (this.numberProvider === DidTelecomProvider.voxbone ? 'Voxbone' : 'None');
  // }

  get steps(): StepDidOrder[] {
    switch (this.numberProvider) {
      case DidTelecomProvider.twilio:
      default:
        return [
          StepDidOrder.SELECT_COUNTRY,
          StepDidOrder.CONFIRM,
        ];
      case DidTelecomProvider.voxbone:
        return [
          StepDidOrder.SELECT_COUNTRY,
          StepDidOrder.SELECT_NUMBER_TYPE,
          StepDidOrder.SELECT_STATE,
          StepDidOrder.SELECT_REGION,
          StepDidOrder.CONFIRM,
        ];
    }
  }

  get cartLabel(): string {
    const len = this.cart ? this.cart.length : 0;
    return _ti('buy_did.button.goto_cart') + ` (${len})`;
  }

  getCountryStates(country: DidCountryItem): DidCountryStateEntity[] {
    const states: DidCountryStateEntity[] = this.em.getRepository('DidCountryStateRepository')
      .getObjectList();

    return states.filter(x => x.country && x.country.code2 === country.code2);
  }

  get getDidRegions(): DidNumberRegion[] {
    const filterValue: string = (this.filterRegion || '').trim();
    if (!filterValue) {
      return this.regions;
    }
    return this.regions.filter(x => {
      return regexSearch(x.name, filterValue) || regexSearch(`+${this.country.phone_code}${x.prefix}`, filterValue);
    });
  }

  get getCountries(): DidCountryItem[] {
    const filterValue: string = this.filterCountry && this.filterCountry.trim();
    if (!filterValue) {
      return this.countries;
    }
    return this.countries.filter(x => {
      return regexSearch(x.name, filterValue) || regexSearch(`+${x.phone_code}`, filterValue);
    });
  }

  protected async resolveData(): Promise<void> {
    console.log('resolveData');
    await this.em.getRepository('DidCountryRepository').findAll();
  }

  goNextStep(): void {
    if (this.step === StepDidOrder.CONFIRM) {
      this.submitOrder();
      return;
    }
    this.step = this.steps[this.steps.indexOf(this.step) + 1];

    if (this.step === StepDidOrder.CONFIRM) { // && this.region
      this.cart.push({
        country: this.country,
        region: this.region,
        quantity: 1
      });
    }

    if (this.numberProvider === DidTelecomProvider.voxbone) {
      this.checkStepSelectCity(true);
      this.checkStepSelectState(true);
    }
    this.updateDialogLayout();
  }

  goPrevStep(): void {
    if (this.step === StepDidOrder.SELECT_COUNTRY) {
      return;
    }
    this.step = this.steps[this.steps.indexOf(this.step) - 1];

    this.checkStepSelectCity(false);
    this.checkStepSelectState(false);
    this.updateDialogLayout();
  }

  goToCart(): void {
    this.step = StepDidOrder.CONFIRM;
    this.updateDialogLayout();
  }

  addMore(): void {
    this.step = StepDidOrder.SELECT_COUNTRY;
    // Clear all info
    this.region = undefined;
    this.type = undefined;
    this.country = undefined;
    this.updateDialogLayout();
  }

  async getNumberTypeList(country: DidCountryItem): Promise<DidNumberType[]> {
    const items: DidNumberTypesResponse[] = await this.didService.getDidNumberTypes(country.id);

    // Set did-number-type
    const types = [];
    this.type = undefined;
    const firstChars = 3;
    for (const item of items) {
      const count = +item.count;
      if (!count) {
        // Only display the number > 0
        continue;
      }
      const type: DidNumberType = {
        area_codes: item.area_codes,
        area_codes_display: item.area_codes && item.area_codes.length ?
          item.area_codes.slice(0, firstChars).join('/') + (item.area_codes.length > firstChars ? '..' : '') :
          '',
        name: _tk(`did.did_type.${item.did_type}`),
        value: item.did_type
      };
      types.push(type);
    }

    return types;
  }

  async selectCountry(country: DidCountryItem): Promise<void> {
    console.log('this.country', country);
    this.uiBlocker.block('selectCountry');

    // List Did-Group
    try {
      this.country = country;

      if (this.numberProvider === DidTelecomProvider.voxbone) {
        // List Did-Type
        this.type = null;
        this.types = await this.getNumberTypeList(country);
        console.log('this.types', this.types);

        // Set did-country-states
        this.states = this.getCountryStates(country);
      }
      this.goNextStep();

    } catch (error) {
      console.error('Get did-number-type', error);
    } finally {
      this.uiBlocker.unblock('selectCountry');
    }
  }

  checkStepSelectCity(next: boolean = true): void {
    if (this.step !== StepDidOrder.SELECT_REGION) {
      return;
    }
    if (this.regions.length === 1) {
      this.region = this.regions[0];
      if (next) {
        this.goNextStep();
      } else {
        this.goPrevStep();
      }
    }
  }

  checkStepSelectState(next: boolean = true): void {
    if (this.step !== StepDidOrder.SELECT_STATE) {
      return;
    }
    if (!this.getCountryStates(this.country).length || !this.states || !this.states.length) {
      if (next) {
        this.goNextStep();
      } else {
        this.goPrevStep();
      }
    }
  }

  async getRegions(type: DidNumberType, state?: DidCountryStateEntity): Promise<void> {
    this.uiBlocker.block('getRegions');

    // List Did-Group
    try {
      const didGroups: DidGroupsByTypeResponse[] = await this.didService.getDidGroupsByType(
        this.country.id,
        state ? state.state_id : undefined,
        type.value
      );

      console.log('did-groups', didGroups);
      // List regions
      this.regions = [];
      this.region = undefined;

      for (const didGroup of didGroups) {
        const region: DidNumberRegion = {
          type,
          state,
          name: didGroup.city_name,
          prefix: didGroup.area_code,
          monthly_cost: parseFloat(didGroup.monthly_cost),
          setup_cost: parseFloat(didGroup.setup_cost)
        };
        this.regions.push(region);
      }

    } catch (error) {
      console.error('Get regions', error);
    } finally {
      this.uiBlocker.unblock('getRegions');
    }

  }

  async selectType(type: DidNumberType): Promise<void> {
    this.type = type;

    if (!this.getCountryStates(this.country).length) {
      await this.getRegions(type);
    }

    this.goNextStep();

    this.updateDialogLayout();
  }

  selectRegion(region: DidNumberRegion): void {
    this.region = region;

    this.goNextStep();
  }

  async selectState(state: DidCountryStateEntity): Promise<void> {
    this.state = state;

    if (!this.country || !this.getCountryStates(this.country).length) {
      console.error('No country or country has no state');
      return;
    }

    await this.getRegions(this.type, state);

    this.goNextStep();
  }

  get totalQty(): number {
    let totalQty = 0;

    for (const item of this.cart) {
      totalQty += item.quantity;
    }

    return totalQty;
  }

  async submitOrder(): Promise<void> {
    this.errors = {};
    const params: IOrderDidsData = {
      numbers: []
    };
    if (this.data.eid) {
      params.eid = this.data.eid;
    }

    if (!this.totalQty) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return;
    }

    for (const item of this.cart) {
      if (item.quantity <= 0) {
        continue;
      }
      params.numbers.push({
        country_code: item.country.code2,
        area_code: item.region && item.region.prefix || null,
        quantity: item.quantity
      });
    }

    try {
      const ret: any = await this.didOrderService.submitOrder(params);
      console.log('submitOrder', ret);
      this.dismiss({ ret });
      this.toast.showSuccess(_ti('did_order.fr.message.success'));
      this.updateDialogLayout();
    } catch (error) {
      console.error('Cannot buy did', error);
      const errorStr: string = error.message || 'internal_server_error';
      if (error.data) {
        const regionCode = error.data.area_code || error.data.country_code;
        if (regionCode) {
          this.errors[regionCode] = `buy_did.error_code.${errorStr}`;
        }
        // Manually remove did from the bought did because in case of the quantity is enough, these bought dids will be removed.
        const removedids = error.data.removed_ids;
        if (removedids) {
          for (const id of removedids) {
            this.em.getRepository('DidRepository').removeObjectById(id);
          }
        }
      }
      this.toast.showError(_ti('public.message.create_failure'));
      this.updateDialogLayout();
    }
  }

  calculatePrice(item: CartItem): number {
    return item.quantity * this.monthlyCost;
  }

  cancel(result?: any): void {
    this.dismiss(result);
  }

  deleteItem(item: CartItem): void {
    const idx = this.cart.indexOf(item);
    if (idx >= 0) {
      this.cart.splice(idx, 1);
    }
    this.updateDialogLayout();
  }

}
