import { Entity, datafield } from '@wephone-core/model/model';
import * as _ from 'lodash';
import { parseDateTime } from '@wephone-utils';

export class PaymentAttemptEntity extends Entity {
  public static object_type_id = 'payment_attempt';

  public static PAYMENT_TYPE_UPGRADE_PREMIUM = 1;
  public static PAYMENT_TYPE_INVOICE = 2;
  public static PAYMENT_TYPE_BUY_DID = 3;
  public static PAYMENT_TYPE_CREDIT_RECHARGE = 4;
  public static PAYMENT_TYPE_SAVE_CC = 5;

  public id: number;
  @datafield
  public creation_dt: any;
  @datafield
  public payment_type: number;
  @datafield
  public payment_status: string;
  @datafield
  public payment_intent_id: string;
  @datafield
  public total_amount: number;
  @datafield
  public real_total_amount: number;
  @datafield
  public invoice_id: number;
  @datafield
  public currency_code: string;
  @datafield
  public success: number;
  @datafield
  public custom_data: any;
  @datafield
  public psp_id: number;
  @datafield
  public psp_reference: string;
  @datafield
  public psp_response_data: Array<any>;

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.creation_dt = this.creation_dt && parseDateTime(this.creation_dt);
  }

}
