<div fxLayout="column" fxFlex="fxFill" *ngIf="is_ready">
  <h2 class="page-title">{{ 'sms.sms_sent' | translate }}</h2>

  <nav mat-tab-nav-bar>
    <a mat-tab-link (click)="activeTab = 'sms_sent'" [active]="activeTab == 'sms_sent'">
      {{ 'sms.sms_sent'|translate }}</a>
    <a mat-tab-link (click)="activeTab = 'sms_received'; !loadSmsReceived && reloadSmsReceivedClick();"
      [active]="activeTab == 'sms_received'">{{ 'sms.sms_received' | translate }}</a>
    <a mat-tab-link (click)="activeTab = 'api_config'" [active]="activeTab == 'api_config'">
      {{ 'sms.api_config'|translate }}</a>
  </nav>

  <ng-container *ngIf="activeTab == 'sms_sent'">
    <form [formGroup]="filterSmsSentForm" class="display-filter">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0" class="pb-1">
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.from'|translate }}</mat-label>
              <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
                placeholder="{{ 'public.from'|translate }}">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
              <mat-error *ngIf="startDateControlSmsSent.hasError('flexDatepickerMin') || startDateControlSmsSent.hasError('required') && startDateControlSmsSent.touched">
                {{ 'form.validator.required'|translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.to'|translate }}</mat-label>
              <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
                [matDatepickerFilter]="minEndDateFilterSmsSent" placeholder="{{ 'public.to'|translate }}">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
              <mat-error *ngIf="endDateControlSmsSent.hasError('required') && endDateControlSmsSent.touched">
                {{ 'form.validator.required'|translate }}
              </mat-error>
              <mat-error
                *ngIf="endDateControlSmsSent.hasError('flexDatepickerMin') || endDateControlSmsSent.hasError('matDatepickerFilter')">
                {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
                startDateControlSmsSent.value | timeFormatDateShort} }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'sms.sender_name'|translate }}</mat-label>
              <input matInput formControlName="sender_name" placeholder="{{ 'sms.sender_name'|translate }}">
              <button mat-icon-button type="button" matSuffix (click)="filterSmsSentForm.get('sender_name').setValue(null)"
                [disabled]="!filterSmsSentForm.get('sender_name').value">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'sms.destination'|translate }}</mat-label>
              <input matInput formControlName="destination" placeholder="{{ 'sms.destination'|translate }}">
              <button mat-icon-button type="button" matSuffix (click)="filterSmsSentForm.get('destination').setValue(null)"
                [disabled]="!filterSmsSentForm.get('destination').value">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="accent" (click)="reloadSmsSentClick()">
          {{ 'public.apply' | translate }}
        </button>
      </div>
    </form>
    <div fxLayout="column" fxFlex="grow" class="tab-content">
      <flex-table #flexTableSent fxFill fxFlex [dataSource]="dataSentSource" [tableConfig]="tableConfigSmsSent"
        [columnTemplates]="{
          creation_time: tplCreationTime,
          sender_name: tplSenderName,
          destination: tplDestination,
          message: tplMessage,
          send_status: tplSendStatus,
          action: tplAction
        }">
        <ng-template #tplCreationTime let-item="row">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="item.scheduled_send_dt">schedule</mat-icon>
            <span>{{ item.creation_time | timeFormatDateTimeShort }}</span>
          </div>
        </ng-template>

        <ng-template #tplSenderName let-item="row">
          {{ item.sender_name }}
        </ng-template>

        <ng-template #tplDestination let-item="row">
          <span>{{ item.destination | displayPhoneNumber }}</span>
          <span *ngIf="item.client_name"><br/>({{item.client_name}})</span>
        </ng-template>

        <ng-template #tplMessage let-item="row">
          <div class="sms-message">
            <span *ngIf="!item.show_full;else ShowFullMsg">{{ item.message|truncate:limitedMessageLength:'' }}</span>
            <ng-template #ShowFullMsg>{{ item.message }}</ng-template>
            <a class="flex-link" 
              [title]="'public.show_more'|translate"
              *ngIf="item.message.length > limitedMessageLength && !item.show_full" 
              (click)="showMoreMessage(item)">...
            </a>
          </div>
        </ng-template>

        <ng-template #tplSendStatus let-item="row">
          <ng-container *ngIf="!item.send_status">
            {{ 'sms.status.waiting' | translate }}
          </ng-container>
          <ng-container *ngIf="item.send_status">
            {{ ('sms.status.' + item.send_status) | translate }}
          </ng-container>
        </ng-template>

        <ng-template #tplAction let-item="row">
          <button *ngIf="canMakeCall" [disabled]="!item.did_id" mat-icon-button type="button" color="primary" (click)="makeCall(item.did_id, item.destination)"
            title="{{ 'public.button.call_now'|translate }}">
            <mat-icon>phone</mat-icon>
          </button>
        </ng-template>
      </flex-table>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 'sms_received'">
    <form [formGroup]="filterSmsReceivedForm" class="display-filter">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0" class="pb-1">
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.from'|translate }}</mat-label>
              <input matInput [matDatepicker]="startTime" formControlName="start_dt"
                placeholder="{{ 'public.from'|translate }}">
              <mat-datepicker-toggle matSuffix [for]="startTime"></mat-datepicker-toggle>
              <mat-datepicker #startTime></mat-datepicker>
              <mat-error *ngIf="startDateControlSmsReceived.hasError('flexDatepickerMin') || startDateControlSmsReceived.hasError('required') && startDateControlSmsReceived.touched">
                {{ 'form.validator.required'|translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.to'|translate }}</mat-label>
              <input matInput [matDatepicker]="endTime" formControlName="end_dt"
                [matDatepickerFilter]="minEndDateFilterSmsReceived" placeholder="{{ 'public.to'|translate }}">
              <mat-datepicker-toggle matSuffix [for]="endTime"></mat-datepicker-toggle>
              <mat-datepicker #endTime></mat-datepicker>
              <mat-datepicker #endPicker></mat-datepicker>
              <mat-error *ngIf="endDateControlSmsReceived.hasError('required') && endDateControlSmsReceived.touched">
                {{ 'form.validator.required'|translate }}
              </mat-error>
              <mat-error
                *ngIf="endDateControlSmsReceived.hasError('flexDatepickerMin') || endDateControlSmsReceived.hasError('matDatepickerFilter')">
                {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
                startDateControlSmsReceived.value | timeFormatDateShort} }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'sms.from'|translate }}</mat-label>
              <input matInput formControlName="sender_name" placeholder="{{ 'sms.from'|translate }}">
              <button mat-icon-button type="button" matSuffix (click)="filterSmsReceivedForm.get('sender_name').setValue(null)"
                [disabled]="!filterSmsReceivedForm.get('sender_name').value">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'sms.destination'|translate }}</mat-label>
              <input matInput formControlName="destination" placeholder="{{ 'sms.destination'|translate }}">
              <button mat-icon-button type="button" matSuffix (click)="filterSmsReceivedForm.get('destination').setValue(null)"
                [disabled]="!filterSmsReceivedForm.get('destination').value">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
      
      <div align="center">
        <button mat-raised-button color="accent" (click)="reloadSmsReceivedClick()">
          {{ 'public.apply' | translate }}</button>
      </div>
    </form>
    <div fxLayout="column" fxFlex="grow" class="tab-content">
      <flex-table #flexTableReceived fxFill fxFlex [dataSource]="dataReceivedSource" [tableConfig]="tableConfigSmsReceived"
        [columnTemplates]="{
          received_time: tplReceivedTime, 
          did_number: tplDidNumber, 
          from: tplFrom, 
          message: tplMessage,
          action: tplAction
        }">
        <ng-template #tplReceivedTime let-item="row">
          {{ item.received_time | timeFormatDateTimeShort }}
        </ng-template>

        <ng-template #tplFrom let-item="row">
          <span>{{ item.from | displayPhoneNumber }}</span>
          <span *ngIf="item.client_name">&nbsp;({{item.client_name}})</span>
        </ng-template>

        <ng-template #tplDidNumber let-item="row">
          {{ item.did_number | displayPhoneNumber }}
        </ng-template>

        <ng-template #tplMessage let-item="row">
          <div class="sms-message">
            <span *ngIf="!item.show_full;else ShowFullMsg">{{ item.message|truncate:limitedMessageLength:'' }}</span>
            <ng-template #ShowFullMsg>{{ item.message }}</ng-template>
            <a class="flex-link" 
              [title]="'public.show_more'|translate"
              *ngIf="item.message.length > limitedMessageLength && !item.show_full" 
              (click)="showMoreMessage(item)">...
            </a>
          </div>
        </ng-template>

        <ng-template #tplAction let-item="row">
          <button *ngIf="canMakeCall" [disabled]="!item.did_id || !isRemotePhoneNumber(item)" mat-icon-button color="primary" (click)="makeCall(item.did_id, item.from)"
            title="{{ 'public.button.call_now'|translate }}">
            <mat-icon>phone</mat-icon>
          </button>
        </ng-template>
        
      </flex-table>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 'api_config'">
    <form [formGroup]="updateForm">
      <div class="mb-1">
        <mat-form-field class="full-width">
          <mat-label>{{ 'sms.sms_callback_url'|translate }}</mat-label>
          <input matInput placeholder="{{ 'sms.sms_callback_url'|translate }}" formControlName="sms_callback_url">
          <mat-error
            *ngIf="updateForm.controls['sms_callback_url'].hasError('required') && updateForm.controls['sms_callback_url'].touched; else tplErrorUrlInvalid">
            {{ 'sms.sms_callback_url_cannot_blank' | translate }}
          </mat-error>
          <ng-template #tplErrorUrlInvalid>
            <mat-error
              *ngIf="updateForm.controls['sms_callback_url'].hasError('isValidUrl') && updateForm.controls['sms_callback_url'].touched">
              {{'form.validator.data_invalid'|translate}}
            </mat-error>
          </ng-template>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <button mat-raised-button
          [disabled]="updateForm.get('sms_callback_url').value === myenterprise.sms_callback_url" color="accent"
          (click)="updateSmsCallbackUrl()">
          {{ 'public.save' | translate }}
        </button>
        <button mat-raised-button
          [disabled]="updateForm.get('sms_callback_url').value === myenterprise.sms_callback_url" color="warn"
          (click)="resetForm()">
          {{ 'public.reset' | translate }}
        </button>
      </div>
    </form>
  </ng-container>
</div>
