<div class="mini-block">
    <div class="flag">
        <span country-flag *ngIf="did.country_code ;else nocountry" [countryCode2]="did.country_code"></span>
        <ng-template #nocountry>
            <span class="flag-icon maticon"><mat-icon>public</mat-icon></span>
        </ng-template>
    </div>
    <div class="number-call">
        <span class="name font-weight-bold" matTooltip="{{ did.name }}" matTooltipPosition="above">{{ did.name | truncate:18 }}&nbsp;</span>
        <span class="number">{{ did.display_number }}</span>
        <span>{{ did.route_priority|routePriority|translate }}</span>
    </div>
</div>
