import { GroupRepository } from '@wephone-core/model/repository/group';

export class SkillGroupRepository extends GroupRepository {
  ENTITY_CLASS = SkillGroupEntity;
  protected URL_PREFIX: string = 'skill_group';

  public getObjectList() {
    return GroupRepository.getInstance<GroupRepository>().getSkillGroupList();
  }

  public create(object_data: any = null): IEntity {
    if (!object_data) {
      object_data = {};
    }
    object_data.group_type = 'skill';
    return super.create(object_data);
  }
}

import { SkillGroupEntity } from '@wephone-core/model/entity/skillgroup';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { IEntity } from '@wephone-core/model/model.interface';
