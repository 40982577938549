<div fxLayout="column">
    <div *ngFor="let time of times; let i = index;" class="calendar-item" [ngClass]="i==0?'':'mt-1'">
        <mat-card class="shadow-none card-calendar">
            <mat-card-title fxLayout="row" fxLayoutAlign="start center">
                <div class="name" fxFlex>{{ time.label|translate }}</div>

                <div fxFlex="100px">{{ time.value | timeFormatDateTimeShort }}&nbsp;</div>

                <div fxFlex="120px" fxLayoutAlign="end center">
                    <button class="full-width" (click)="selectTime(time)" type="button" mat-raised-button color="primary">
                        {{ 'public.select_item'|translate }}
                    </button>
                </div>
            </mat-card-title>
        </mat-card>
    </div>

    <div class="calendar-item mt-1">
        <mat-card class="shadow-none card-calendar">
            <mat-card-title fxLayout="row" fxLayoutAlign="start center">
                <div class="name" fxFlex>
                    <input #inputDateTime class="datetime-picker-dump" [(ngModel)]="selectedTime" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time">
                    <owl-date-time #dt1></owl-date-time>
                    {{ 'sms.schedule_time.custom'|translate }}
                </div>

                <div fxFlex="100px" *ngIf="selectedTime">{{ selectedTime | timeFormatDateTimeShort }}&nbsp;</div>

                <div fxFlex="120px" fxLayoutAlign="end center">
                    <button class="full-width btn-set-schedule" type="button" (click)="inputDateTime.click()" mat-raised-button color="primary">
                        {{ 'sms.set_custom_time'|translate }}
                    </button>
                </div>
            </mat-card-title>
        </mat-card>
    </div>

</div>
