
export function joinURL(...url_parts: Array<string>): string {
  let ret: string;
  for (let i = 0; i < url_parts.length; i++) {
    const s = url_parts[i];
    if (ret) {
      if (ret[ret.length - 1] === '/' || (s.length > 0 && s[0] === '/')) {
        if (ret[ret.length - 1] === '/' && (s.length > 0 && s[0] === '/')) {
          ret += s.substring(1);
        } else {
          ret += s;
        }
      } else {
        ret += '/' + s;
      }
    } else {
      ret = s;
    }
  }
  return ret;
}

export function isValidUrl(url: string) {
  if (!url) {
    return false;
  }

  try {
    const r = new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}
