import { Subscription } from 'rxjs';
import { OnDestroy, Injectable } from '@angular/core';
import { TimerService } from '../../services/timer.service';

@Injectable()
export abstract class SubscriptionManagedComponent implements OnDestroy {
    private _subscriptionList = new Subscription();
    private _timerList = [];

    protected addSubscription(sub: Subscription): Subscription {
        this._subscriptionList.add(sub);

        return sub;
    }

    protected addTimerSubscription(id: number): void {
        this._timerList.push(id);
    }

    ngOnDestroy(): void {
        this._subscriptionList.unsubscribe();
        for (const id of this._timerList) {
            TimerService.getInstance().unsubscribe(id);
        }
    }
}
