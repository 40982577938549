import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FlexTableComponent, IFlexTableConfig } from '@wephone-utils';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataSourceFilter } from '@wephone-utils/datasource/datasource-types';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FlexSelectQueueInput, FlexSelectUserInput } from '@wephone-common';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

export interface IDataSourceQueueHistoryFilter extends IDataSourceFilter {
  queues?: number[];
  agents?: number[];
}

@Component({
  selector: 'app-dialog-queue-history',
  templateUrl: './dialog-queue-history.component.html',
  styleUrls: ['./dialog-queue-history.component.scss']
})
export class DialogQueueHistoryComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('call_queue.content.tab_history');

  tableConfig: IFlexTableConfig = {
    multiSelect: false,
    enableExportCsv: true,
    rowHeight: 100,
    columns: [
      {
        name: 'action_dt',
        label: _tk('components.user_action.action_date'),
        sortable: false,
      },
      {
        name: 'author',
        label: _tk('components.user_action.author'),
        sortable: false,
      },
      {
        name: 'user_action',
        label: _tk('components.user_action.action'),
        sortable: false,
      },
      {
        name: 'object',
        label: _tk('components.user_action.object'),
        sortable: false,
      },
      {
        name: 'detail',
        label: _tk('components.user_action.detail'),
        sortable: false,
      }
    ]
  };

  showFilter = true;
  // filters: IDataSourceQueueHistoryFilter;

  filterQueueControl: FormControl = new FormControl([]);
  filterAgentControl: FormControl = new FormControl([]);

  dataSource: FlexDataSource;

  @ViewChild('flexSelectQueueInput') flexSelectQueueInput: FlexSelectQueueInput;
  @ViewChild('flexSelectUserInput') flexSelectUserInput: FlexSelectUserInput;
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  constructor(
    dialogRef: MatDialogRef<DialogQueueHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    datasourceService: DataSourceService,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(dialogRef);

    this.dateAdapter = dateAdapter;
    this.dataSource = datasourceService.createDataSource('user_actions');
  }

  ngOnInit(): void {
    super.ngOnInit();

    const queue_ids = this.data && this.data.queue_ids || [];
    const agent_ids = this.data && this.data.agent_ids || [];
    this.filterQueueControl.setValue(queue_ids);
    this.filterAgentControl.setValue(agent_ids);

    if (this.data.show_filter !== undefined) {
      this.showFilter = !!this.data.show_filter;
    }
    this.tableConfig.enableFilter = this.showFilter;

    this.reloadClick();
  }

  reloadClick(): void {
    const newFilter: IDataSourceQueueHistoryFilter = {
      agents: this.filterAgentControl.value.filter(x => Number.isInteger(x) && x),
      queues: this.filterQueueControl.value.filter(x => Number.isInteger(x) && x)
    };

    if (this.flexTable) {
      // this.dataSource.setFilter(newFilter);
      this.flexTable.reloadDataSource(newFilter);
      return;
    }

    this.dataSource.load(newFilter);
  }

  // get filter_agent(): number[] {
  //   return this.filters.agents ? this.filters.agents : undefined;
  // }

  // set filter_agent(agent_ids: number[]) {
  //   this.filters.agents = agent_ids || [];
  // }

  // get filter_queue(): number[] {
  //   return this.filters.queues ? this.filters.queues : undefined;
  // }

  // set filter_queue(queue_ids: number[]) {
  //   this.filters.queues = queue_ids || [];
  // }

  clearFilter(e: Event, formControl: FormControl): void {
    e.stopPropagation();
    setTimeout(() => {
      formControl.setValue([]);
    });
    // this.flexSelectQueueInput.stateChanges.next();
    // this.flexSelectUserInput.stateChanges.next();
  }
}
