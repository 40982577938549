import { Injectable } from '@angular/core';
import { _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { IQualificationEntity } from '@wephone-core/model/entity/qualification.i';
import { HttpEngine } from '@wephone-core/service/http_engine';

export class QualificationTree {
  private qualifications: IQualificationEntity[] = [];

  constructor(qualifications: IQualificationEntity[]) {
    this.qualifications = qualifications;

    this.attachChildren();
  }

  private attachChildren(): void {
    // Update children
    for (const i of this.qualifications) {
      const children = [];
      for (const j of this.qualifications) {
        if (j.parent_id === i.id) {
          children.push(j);
        }
      }
      i.children = children;
    }
  }

  // Adds a new qualification to the tree
  addQualification(qualification: IQualificationEntity): void {
    this.qualifications.push(qualification);
  }

  // Finds all children of a given qualification by its ID
  findChildren(parentId: number): IQualificationEntity[] {
    return this.qualifications.filter(q => q.parent_id === parentId).sort((a, b) => a.position - b.position);
  }

  // (Optional) Find a qualification by its ID
  findQualificationById(id: number): IQualificationEntity | undefined {
    return this.qualifications.find(q => q.id === id);
  }

  getQualificationsDisplayed(selectedIds: number[]): string {
    const qualifications = selectedIds.map((x) => {
      const qlf = this.qualifications.find(q => q.id === x);
      return qlf && qlf.value || '';
    });

    return qualifications.join(' > ');
  }
}

@Injectable()
export class QualificationServiceV2 {
  private URL_QUALIFY_LIST_ROOT = 'qualification/list';
  private URL_QUALIFY_GET_TREE = 'qualification/get/:qualification_id';

  constructor(
    private http: HttpEngine
) {

  }

  getRootQualifications(): Promise<IQualificationEntity[]> {
    return this.http.apiGetV2(this.URL_QUALIFY_LIST_ROOT);
  }

  async getQualificationTree(qualificationId: number): Promise<QualificationTree> {
    const resp: IQualificationEntity[] = await this.http.apiGetV2(
      this.URL_QUALIFY_GET_TREE.replace(':qualification_id', qualificationId.toString())
    );

    const ret = new QualificationTree(resp);

    return ret;
  }
}
