<div fxFill fxLayout="column">
  <div class="wrapper" *ngIf="showSideSteps">

    <div class="col-left" [fxHide.xs]="true">
      <ul class="stepper stepper-vertical">
        <li *ngFor="let step of stepKeys; let i=index;">
          <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
            [ngClass]="{'active': mainArea.activeAnchor === step}">
            <span class="circle">{{ i+1 }}</span>
            <span class="label">{{ steps[step] | translate }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner">
        <div class="block-wrapper" id="form" anchorPart>
          <ng-container *ngTemplateOutlet="formEdit"></ng-container>
        </div>

        <ng-container *ngIf="config.showUsedObjects">
          <div class="my-2">
            <mat-divider></mat-divider>
          </div>

          <div class="block-wrapper" id="inused" anchorPart>
            <ng-container *ngTemplateOutlet="inUsed"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>

  </div>

  <ng-container *ngIf="!showSideSteps">
    <ng-container *ngTemplateOutlet="formEdit"></ng-container>

    <ng-container *ngIf="config.showUsedObjects">
      <div class="my-2">
        <mat-divider></mat-divider>
      </div>
      <ng-container *ngTemplateOutlet="inUsed"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #formEdit>
  <form [formGroup]="calendarForm" class="form-change-highlight">
    <ng-container *ngIf="config.editCustomData">
      <h4 class="title fontfamily-bold ma-0">
        <span><i class="timeline-icon material-icons">settings_applications</i></span>
        <span class="title-text">{{ 'opening_hour_calendar.content.call_destination' | translate }}</span>
      </h4>

      <mat-card class="mat-card-flat shadow-none py-0">
        <mat-card-content>

          <!-- <h4 class="title fontfamily-bold">{{ 'opening_hour_calendar.content.call_destination' | translate }}</h4> -->

          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span class="mat-body-2" *ngIf="!value?.getRoutingApp()">{{ 'did.content.no_destination_defined' |
              translate
              }}</span>
            <span class="mat-body-2" *ngIf="value?.getRoutingApp()" class="mat-text-primary">{{
              value?.getRoutingApp()?.getDescription() }}</span>
            <button mat-button type="button" color="primary" (click)="changeDestination()">{{ 'public.change' |
              translate }}</button>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="config.editName || config.editGroup">
      <h4 class="title fontfamily-bold ma-0">
        <span><i class="timeline-icon material-icons">info</i></span>
        <span class="title-text">{{ 'opening_hour_calendar.content.information' | translate }}</span>
      </h4>
      <mat-card class="mat-card-flat shadow-none py-0">
        <mat-card-content>
          <!-- <h4 class="title fontfamily-bold mb-0">{{ 'opening_hour_calendar.content.information' | translate }}</h4> -->

          <div fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
            <ng-container *ngIf="config.editName">
              <div fxFlex>
                <mat-form-field class="full-width">
                  <input matInput type="text"
                    placeholder="{{ 'opening_hour_calendar.content.calendar_name' | translate }} *"
                    formControlName="name" [errorStateMatcher]="matcher" [attr.data-ci]="'calendar-name'">

                  <mat-error [flexFormControlError]="nameControl"
                    [customMessages]="{
                      'duplicateName': 'opening_hour_calendar.message.calendar_name_already_exists'|translate
                    }"></mat-error>

                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <mat-card class="mat-card-flat shadow-none py-0">
      <mat-card-content>
        <div class="opening-hours" formArrayName="openingHours">
          <div class="timeslot" *ngFor="let openingHour of openingHoursControl.controls; let i=index">
            <button class="close-button" mat-icon-button (click)="removeOpeningHour(i)">
              <mat-icon>clear</mat-icon>
            </button>
            <div class="calendar-opening-hour">
              <opening-hour-form-input [formControlName]="i"></opening-hour-form-input>

              <mat-error [flexFormControlError]="openingHoursControl.controls[i]"
                [customMessages]="{
                  'duplicateOpeingHour': 'opening_hour_calendar.message.weekly_schedule_duplicate_invalid'|translate
                }"></mat-error>
            </div>
          </div>
          <!-- <div data-ci="openinghours_error_not_blank" class="mat-text-warn"
            *ngIf="!openingHoursControl.value || openingHoursControl.value.length == 0">
            {{ 'opening_hour_calendar.message.not_blank_hour' | translate }}
          </div> -->
          <div align="start">
            <button data-ci="add_time" mat-stroked-button class="add mt-2 mb-2" color="green"
              (click)="addOpeningHour()">
              <mat-icon>add</mat-icon>
              <span class="ml-1">{{ 'opening_hour_calendar.content.add_time_block' | translate}}</span>
            </button>
          </div>
        </div>

        <ng-container *ngIf="showException">
          <h4 class="title fontfamily-bold ma-0">
            <span><i class="timeline-icon material-icons">extension</i></span>
            <span class="title-text">{{ 'opening_hour_calendar.content.exceptions' | translate }}</span>
          </h4>
          <!-- <h4 class="title fontfamily-bold">{{ 'opening_hour_calendar.content.exceptions' | translate }}</h4> -->
          <div class="special-opening-hours" formArrayName="openingHoursSpecialDate">
            <div class="timeslot"
              *ngFor="let openingHourSpecialDate of openingHoursSpecialDateControl.controls; let i=index">
              <button class="close-button" mat-icon-button (click)="removeOpeningHourSpecialDate(i)">
                <mat-icon>clear</mat-icon>
              </button>
              <div class="calendar-opening-hour">
                <opening-hour-special-date-form-input [formControlName]="i"></opening-hour-special-date-form-input>

                <!-- <mat-error [flexFormControlError]="openingHoursSpecialDateControl.controls[i]"
                  [customMessages]="{
                    'nameRequired': 'opening_hour_calendar.message.exception_name_empty'|translate,
                    'dateRange': 'opening_hour_calendar.message.exception_date_invalid'|translate,
                    'timeRange': 'opening_hour_calendar.message.exception_time_invalid'|translate
                  }"></mat-error> -->
              </div>
            </div>
            <div align="start">
              <button mat-stroked-button class="add" color="green" (click)="addOpeningHourSpecialDate()"
                data-ci="add_special_date">
                <mat-icon>add</mat-icon>
                <span class="ml-1">{{ 'opening_hour_calendar.content.add_time_block' | translate }}</span>
              </button>
            </div>
          </div>
        </ng-container>

        <div class="pb-1">
          <a data-ci="show_exceptions" class="flex-link" (click)="showException = !showException">
            {{ (!showException ? 'opening_hour_calendar.content.show_exception' :
            'opening_hour_calendar.content.hide_exception')|translate }}
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</ng-template>

<ng-template #inUsed>
  <h4 class="title fontfamily-bold mt-1">
    <span><i class="timeline-icon material-icons">miscellaneous_services</i></span>
    <span class="title-text">{{ 'opening_hour_calendar.content.usage' | translate }}</span>
  </h4>
  <!-- <h4 class="title fontfamily-bold">{{ 'opening_hour_calendar.content.usage' | translate }}</h4> -->

  <mat-card class="mat-card-flat shadow-none py-0">
    <mat-card-content>
      <ng-container *ngIf="hasUsedItem; else noItems">
        <div *ngIf="usedQueueList.length" class="pb-1">
          <p class="fontfamily-bold">
            <span [matTooltip]="usedQueueStr" matTooltipPosition="above">{{
              'opening_hour_calendar.content.queue_inused'|translate }}</span>
          </p>
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px grid" class="full-width">
            <div [attr.data-ci]="'calendar-in-used-queue'" *ngFor="let queue of usedQueueList">
              <app-queue-mini-block [callQueue]="queue"></app-queue-mini-block>
            </div>

          </div>
        </div>
        <div *ngIf="usedIvrList.length" class="pb-1">
          <p class="fontfamily-bold">
            <span [matTooltip]="usedIvrStr" matTooltipPosition="above">{{
              'opening_hour_calendar.content.ivr_inused'|translate }}</span>
          </p>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="full-width">
            <div [attr.data-ci]="'calendar-in-used-ivr'" *ngFor="let ivr of usedIvrList">
              <app-ivr-menu-mini-block [ivrMenu]="ivr"></app-ivr-menu-mini-block>
            </div>

          </div>
        </div>
        <div *ngIf="usedDidList.length" class="pb-1">
          <p class="fontfamily-bold">
            <span [matTooltip]="usedDidStr" matTooltipPosition="above">{{ 'did.content.inused'|translate }}</span>
          </p>
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px grid" class="full-width">
            <div [attr.data-ci]="'calendar-in-used-did'" *ngFor="let did of usedDidList">
              <app-did-mini-block [did]="did"></app-did-mini-block>
            </div>
          </div>
        </div>
        <div *ngIf="usedAgentList.length" class="pb-1">
          <p class="fontfamily-bold">
            <span [matTooltip]="usedAgentStr" matTooltipPosition="above">{{ 'user.title.inused'|translate }}</span>
          </p>
          <div fxLayout="row wrap" class="agent-list">
            <div *ngFor="let agent of usedAgentList" fxFlex.xs="100" fxFlex.gt-xs="0 0 calc(50% - 10px)"
              fxFlex.gt-md="0 0 calc(33.33% - 10px)">
              <div fxFlexFill fxLayout="column" class="agent-wrapper" [attr.data-ci]="'calendar-in-used-agent'">
                <div fxFlex>
                  <agent-item [avatar]="true" [agent]="agent">
                  </agent-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noItems>

      </ng-template>
    </mat-card-content>
  </mat-card>
</ng-template>
