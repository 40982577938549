<form [formGroup]="form" *ngIf="form" (ngSubmit)="submitForm()" class="px-1">
    <div class="mb-1">
      <mat-form-field class="full-width">
        <mat-label>{{'ivr_evaluation.content.name'| translate}}</mat-label>
        <input matInput placeholder="{{ 'ivr_evaluation.content.name'|translate }}" formControlName="name">
  
        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
  
    <div class="mb-1">
      <h4 class="font-weight-bold ma-0">{{ 'ivr_evaluation.content.welcome_message'|translate }}</h4>
      <sound-upload title="{{'ivr_evaluation.content.play_message'|translate}}" data-ci="welcome_msg"
        [fileEntry]="form.get('welcome_msg').value" [hideRemove]="false" (onChange)="updateSoundB4WelcomeMsgFile($event)">
      </sound-upload>
      <!-- <mat-error *ngIf="form.get('welcome_msg').hasError('required') && form.get('welcome_msg').touched">
        {{ 'form.validator.field_required'|translate }}
      </mat-error> -->
    </div>
  
    <div class="mb-1">
      <!-- <h4 class="font-weight-bold ma-0">{{ 'ivr_evaluation.content.questions'|translate }}</h4> -->
  
      <mat-card class="mat-card-flat shadow-none pa-0 ma-0">
        <mat-card-content>
          <div class="question-list" formArrayName="questions">
            <ng-container *ngFor="let questionControl of questionsControl.controls; let i=index">
              <div class="question-slot" [attr.data-ci]="'question_' + (i + 1)">
                <div fxLayoutAlign="space-between center">
                  <h4 class="font-weight-bold ma-0">{{ 'ivr_evaluation.content.question'|translate: {n: i+1} }}</h4>
                  <button mat-button type="button" (click)="removeQuestion(i)" *ngIf="i > 0">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
  
                <div class="question-msg">
                  <sound-upload title="{{'ivr_evaluation.content.play_message'|translate}}"
                    [fileEntry]="questionControl.value && questionControl.value.msg? questionControl.value.msg : undefined"
                    [hideRemove]="true" (onChange)="updateSoundB4QuestionMsgFile($event, i)"></sound-upload>
  
                  <mat-error *ngIf="questionControl.invalid && questionControl.touched && questionControl.errors?.msg_required">
                    {{ 'ivr_evaluation.validator.sound_not_found'|translate }}
                  </mat-error>
                </div>
  
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="question-keys">
                    <div>{{ 'ivr_evaluation.content.accepted_answers'|translate }}</div>
                    <div>
                      <phonepad-picker [multiple]="true"
                        [options]="{key_length: 1, display_grouped_number: true, position: 'right'}"
                        [keys]="questionControl.value && questionControl.value.accepted_answer ? questionControl.value.accepted_answer : undefined"
                        (valueChange)="phonePadPickerChanged($event, i)">
                      </phonepad-picker>
                    </div>
                  </div>
                  
                  <mat-error *ngIf="questionControl.invalid && questionControl.touched && questionControl.errors?.accepted_answer_required">
                    {{ 'ivr_evaluation.validator.accepted_answer_required'|translate }}
                  </mat-error>
                </div>
                
                <mat-error *ngIf="questionControl.invalid && questionControl.touched && !questionControl?.errors?.accepted_answer_required && !questionControl?.errors?.msg_required" 
                  [flexFormControlError]="questionControl"
                  [customMessages]="{
                    'duplicated': 'ivr_evaluation.validator.duplicated_keys'|translate:{keys:questionControl?.errors?.duplicated?.keys}
                  }"></mat-error>
              </div>
            </ng-container>
          </div>
          <div align="start">
            <button type="button" data-ci="add_question" mat-stroked-button class="add mt-2 mb-2" color="green" (click)="addQuestion()">
              <mat-icon>add</mat-icon>
              <span class="ml-1">{{ 'ivr_evaluation.content.add_question' | translate }}</span>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    
    </div>
  
    <div>
      <h4 class="font-weight-bold ma-0">{{ 'ivr_evaluation.content.thankyou_message'|translate }}</h4>
      <sound-upload title="{{'ivr_evaluation.content.play_message'|translate}}" data-ci="thankyou_msg"
        [fileEntry]="form.get('thankyou_msg').value" [hideRemove]="true"
        (onChange)="updateSoundB4ThankyouMsgFile($event)"></sound-upload>
  
      <mat-error [flexFormControlError]="form.get('thankyou_msg')"></mat-error>
    </div>
</form>
  