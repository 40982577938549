import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { ConferenceRepository } from '@wephone-core/model/repository/conference';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { UserRepository } from '@wephone-core/model/repository/user';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { DidEntity } from './did';
import { IRoutingRuleCondition } from './crm_routing_rule.i';
import { IRoutingAppDestinationData, RoutingAppName, RoutingParamsConference, RoutingParamsPhoneNumber, RoutingParamsPlayFileAndHangup, RoutingParamsRemoteApp, RoutingParamsRunVxml, RoutingParamsWebservice } from '@wephone-core/routing-app/routing-app.interface';
import { DidRepository } from '../repository/did';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';

export class CrmRoutingRuleEntity extends Entity {
  static object_type_id = 'crm_routing_rule';

  id: number;
  @datafield name: string;
  @datafield crm_id: number;
  @datafield routing_data: IRoutingRuleCondition[];
  @datafield dest_default: IRoutingAppDestinationData;

  private _crm: EnterpriseCrmEntity;

  setObjectData(object_data: any, fetch_related_data = true): void {
    super.setObjectData(object_data, fetch_related_data);
    if (typeof object_data.routing_data === 'string') {
      this.routing_data = JSON.parse(object_data.routing_data);
    }
    if (typeof object_data.dest_default === 'string') {
      this.dest_default = JSON.parse(object_data.dest_default);
    }
  }

  get crm(): EnterpriseCrmEntity {
    if (this.crm_id) {
      if (!this._crm) {
        this._crm = EnterpriseCrmRepository.getInstance().getObjectById(this.crm_id);
      }
      return this._crm;
    }
    return null;
  }

  set crm(enterprise_crm: EnterpriseCrmEntity) {
    if (enterprise_crm) {
      this.crm_id = enterprise_crm.getId();
    } else {
      this.crm_id = null;
    }
    this._crm = enterprise_crm;
  }

  get application_name(): string {
    return this.application ? 'routing_application.' + this.application + '.app_name' : '';
  }

  get application(): RoutingAppName {
    return this.dest_default && this.dest_default.application ? this.dest_default.application : null;
  }

  get routed_queue(): CallQueueEntity {
    const items = this.routedQueues(true);
    return items && items.length ? items[0] : null;
  }
  get routed_queues_conditional(): CallQueueEntity[] {
    return this.routedQueues(false);
  }

  private routedQueues(defaultDest: boolean = true): CallQueueEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: CallQueueEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.callqueue) {
      //   if (!action.params.queue) {
      //     continue;
      //   }
      //   const item = CallQueueRepository.getInstance().getObjectById(action.params.queue);
      //   if (!item) {
      //     console.error('No call queue', action.params.queue);
      //     continue;
      //   }
      //   ret.push(item);
      // }
      
      const item: CallQueueEntity = RoutingAppService.getInstance().getRoutedCallQueue(action);
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routingDestConditions(): IRoutingAppDestinationData[] {
    return this.routing_data ? this.routing_data.map(x => x.dest) : [];
  }

  get routed_menu(): IvrCustomMenuEntity {
    const menus = this.routedMenus(true);
    return menus.length ? menus[0] : null;
  }

  get routed_menus_conditional(): IvrCustomMenuEntity[] {
    return this.routedMenus(false);
  }
  private routedMenus(defaultDest: boolean = true): IvrCustomMenuEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: IvrCustomMenuEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.ivr_custom_menu) {
      //   if (!action.params.id) {
      //     continue;
      //   }
      //   const item = IvrCustomMenuRepository.getInstance().getObjectById(action.params.id);
      //   if (!item) {
      //     console.error('No ivr menu', action.params.id);
      //     continue;
      //   }
      //   ret.push(item);
      // }
      console.log('RoutingAppService.getInstance()', RoutingAppService.getInstance());
      const item: IvrCustomMenuEntity = RoutingAppService.getInstance().getRoutedIvrMenu(action);
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routed_application(): IvrScriptEntity {
    const apps = this.routedApplications(true);
    return apps && apps.length ? apps[0] : null;
  }

  get routed_applications_conditional(): IvrScriptEntity[] {
    return this.routedApplications(false);
  }
  private routedApplications(defaultDest): IvrScriptEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: IvrScriptEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.runvxmlscript) {
      //   const actionParams: RoutingParamsRunVxml = action.params as RoutingParamsRunVxml;
      //   if (!actionParams.ivrscript_id) {
      //     continue;
      //   }
      //   const item = IvrScriptRepository.getInstance().getObjectById(action.params.ivrscript_id);
      //   if (!item) {
      //     console.error('No ivr script', actionParams);
      //     continue;
      //   }
      //   ret.push(item);
      // }

      const item: IvrScriptEntity = RoutingAppService.getInstance().getRoutedRunVxml(action);
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routed_remote_application(): IvrRemoteAppEntity {
    const apps = this.routedRemoteApplications(true);
    return apps && apps.length ? apps[0] : null;
  }

  get routed_remote_application_conditional(): IvrRemoteAppEntity[] {
    return this.routedRemoteApplications(false);
  }
  private routedRemoteApplications(defaultDest: boolean = true): IvrRemoteAppEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: IvrRemoteAppEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.remote_application) {
      //   const actionParams: RoutingParamsRemoteApp = action.params as RoutingParamsRemoteApp;
      //   if (!actionParams.id) {
      //     continue;
      //   }
      //   const item = IvrRemoteAppRepository.getInstance().getObjectById(actionParams.id);
      //   if (!item) {
      //     console.error('No ivr application', actionParams.id);
      //     continue;
      //   }
      //   ret.push(item);
      // }

      const item: IvrRemoteAppEntity = RoutingAppService.getInstance().getRoutedRemoteApp(action);
      if (item) {
        ret.push(item);
      }

    }
    return ret;
  }

  get routed_conference(): ConferenceEntity {
    const items = this.routedConferences(true);
    return items && items.length ? items[0] : null;
  }

  get routed_conferences_conditional(): ConferenceEntity[] {
    return this.routedConferences(false);
  }
  private routedConferences(defaultDest: boolean = true): ConferenceEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: ConferenceEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.conference) {
      //   const actionParams: RoutingParamsConference = action.params as RoutingParamsConference;
      //   if (!actionParams.conference) {
      //     continue;
      //   }
      //   const item = ConferenceRepository.getInstance().getObjectById(actionParams.conference);
      //   if (!item) {
      //     console.error('No conference', actionParams.conference);
      //     continue;
      //   }
      //   ret.push(item);
      // }
      const item = RoutingAppService.getInstance().getRoutedConference(action);
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routed_phone_number(): string {
    const numbers = this.routedPhoneNumbers(true);
    return numbers && numbers.length ? numbers[0] : null;
  }

  get routed_phone_number_conditional(): string[] {
    return this.routedPhoneNumbers(false);
  }
  private routedPhoneNumbers(defaultDest: boolean = true): string[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: string[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.call_phone_number) {
      //   const actionParams: RoutingParamsPhoneNumber = action.params as RoutingParamsPhoneNumber;
      //   if (!actionParams.number) {
      //     continue;
      //   }
      //   ret.push(actionParams.number);
      // }
      const item: string = RoutingAppService.getInstance().getRoutedPhoneNumber(action);
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routed_fileentry(): FileEntryEntity {
    const items = this.routedFileEntries(true);
    return items && items.length ? items[0] : null;
  }

  get routed_fileentries_conditional(): FileEntryEntity[] {
    return this.routedFileEntries(false);
  }
  private routedFileEntries(defaultDest: boolean = true): FileEntryEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: FileEntryEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.play_then_hangup) {
      //   const actionParams: RoutingParamsPlayFileAndHangup = action.params as RoutingParamsPlayFileAndHangup;
      //   if (!actionParams.sound_id) {
      //     continue;
      //   }
      //   const item = FileEntryRepository.getInstance().getObjectById(actionParams.sound_id);
      //   if (!item) {
      //     console.error('No file entry', actionParams.sound_id);
      //     continue;
      //   }
      //   ret.push(item);
      // }
      const item: FileEntryEntity = RoutingAppService.getInstance().getRoutedFileEntryHangup(action);
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routed_webservice(): string {
    const items = this.routedWebServices(true);
    return items && items.length ? items[0] : null;
  }
  get routed_webservices_conditional(): string[] {
    return this.routedWebServices(false);
  }
  private routedWebServices(defaultDest: boolean = true): string[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: string[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.webservice) {
      //   const actionParams: RoutingParamsWebservice = action.params as RoutingParamsWebservice;
      //   if (!actionParams.url) {
      //     continue;
      //   }
      //   ret.push(actionParams.url);
      // }
      const item: string = RoutingAppService.getInstance().getRoutedWebservice(action);
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routed_user(): IUserEntity {
    const items = this.routedUsers(true);
    return items && items.length ? items[0] : null;
  }

  get routed_users_conditionals(): IUserEntity[] {
    return this.routedUsers(false);
  }
  private routedUsers(defaultDest: boolean = true): IUserEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: IUserEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      // if (action.application === RoutingAppName.call_user) {
      //   if (!action.params.id) {
      //     continue;
      //   }
      //   const item = UserRepository.getInstance().getObjectById(action.params.id);
      //   if (!item) {
      //     console.error('No file entry', action.params.id);
      //     continue;
      //   }
      //   ret.push(item);
      // }
      const item: IUserEntity = RoutingAppService.getInstance().getRoutedUser(action) as IUserEntity;
      if (item) {
        ret.push(item);
      }
    }
    return ret;
  }

  get tooltip(): any[] {
    return this.getTooltip();
  }
  private getTooltip(): any[] {
    const action_data = this.dest_default;

    if (!action_data || !action_data.params) {
      return null;
    }

    const tooltip = [];

    for (const key of Object.keys(action_data.params)) {
      const param = action_data.params[key];
      const _get_tooltip = (value: any, keyTooltip: string) => {
        return {
          param_name: 'routing_application.params.' + keyTooltip,
          param_value: value
        };
      };
      const _getObjectParam = (objParam: number) => {
        let obj = null;
        switch (action_data.application) {
          case RoutingAppName.remote_application:
            // required loaded data
            obj = IvrRemoteAppRepository.getInstance().getObjectById(objParam);
            break;
          case RoutingAppName.runvxmlscript:
            obj = IvrScriptRepository.getInstance().getObjectById(objParam);
            break;
          case RoutingAppName.callqueue:
            obj = CallQueueRepository.getInstance().getObjectById(objParam);
            break;
          case RoutingAppName.conference:
            obj = ConferenceRepository.getInstance().getObjectById(objParam);
            break;
          case RoutingAppName.ivr_custom_menu:
            obj = IvrCustomMenuRepository.getInstance().getObjectById(objParam);
            break;
          case RoutingAppName.call_phone:
            obj = SipPhoneRepository.getInstance().getObjectById(objParam);
            break;
          case RoutingAppName.call_user:
            obj = UserRepository.getInstance().getObjectById(objParam);
            break;
          case RoutingAppName.play_then_hangup:
            obj = FileEntryRepository.getInstance().getObjectById(objParam);
            break;
          default:
            break;
        }
        return obj;
      };

      const param_obj = _getObjectParam(param);
      let tooltip_item;
      switch (key) {
        case 'ivrscript_id':
        case 'conference':
        case 'sound_id':
        case 'id':
          tooltip_item = _get_tooltip(param_obj ? param_obj.name : '', key);
          break;
        case 'queue':
          tooltip_item = _get_tooltip(param_obj ? param_obj.queue_name : '', key);
          break;
        case 'sipphone_id':
          tooltip_item = _get_tooltip(param_obj ? param_obj.extension : '', key);
          break;
        case 'number':
        case 'extension':
        case 'default_ext':
        case 'url':
          tooltip_item = _get_tooltip(param, key);
          break;
        default:
          tooltip_item = null;
          break;
      }
      if (tooltip_item) {
        tooltip.push(tooltip_item);
      }
    }
    return tooltip;
  }

  get routed_sipphone(): SipPhoneEntity {
    const items = this.routedSipPhone(true);
    return items && items.length ? items[0] : null;
  }

  get routed_sipphones_conditional(): SipPhoneEntity[] {
    return this.routedSipPhone(false);
  }

  get routed_dids_conditional(): DidEntity[] {
    return this.routedDid(false);
  }

  private routedDid(defaultDest: boolean = true): DidEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: DidEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      if (action.application === RoutingAppName.to_did) {
        if (!action.params.did_id) {
          continue;
        }
        const item = DidRepository.getInstance().getObjectById(action.params.did_id);
        if (!item) {
          console.error('No did', action.params.did_id);
          continue;
        }
        ret.push(item);
      }
    }
    return ret;
  }

  private routedSipPhone(defaultDest: boolean = true): SipPhoneEntity[] {
    const actions: IRoutingAppDestinationData[] = defaultDest ? [this.dest_default] : this.routingDestConditions;
    const ret: SipPhoneEntity[] = [];
    for (const action of actions) {
      if (!action) {
        continue;
      }
      if (action.application === RoutingAppName.call_phone) {
        if (!action.params.sipphone_id) {
          continue;
        }
        const item = SipPhoneRepository.getInstance().getObjectById(action.params.sipphone_id);
        if (!item) {
          console.error('No sip phone', action.params.sipphone_id);
          continue;
        }
        ret.push(item);
      }
    }
    return ret;
  }

  routeToUser(user: IUserEntity): void {
    this.dest_default = { application: RoutingAppName.call_user, params: { id: user.id } };
  }

  routeToQueue(queue: CallQueueEntity): void {
    this.dest_default = { application: RoutingAppName.callqueue, params: { queue: queue.id } };
  }

  routeToConference(conference: ConferenceEntity): void {
    this.dest_default = { application: RoutingAppName.conference, params: { conference: conference.id } };
  }

  routeToSipPhone(sipphone: SipPhoneEntity): void {
    this.dest_default = { application: RoutingAppName.call_phone, params: { sipphone_id: sipphone.id } };
  }

  routeToApplication(ivrscript: IvrScriptEntity): void {
    this.dest_default = {
      application: RoutingAppName.runvxmlscript,
      params: {
        ivrscript_id: ivrscript.id
      }
    };
  }

  routeToRemoteApplication(remoteApplication: IvrRemoteAppEntity): void {
    this.dest_default = {
      application: RoutingAppName.remote_application,
      params: { id: remoteApplication.id }
    };
  }

  routeToMenu(ivrCustomMenu: IvrCustomMenuEntity): void {
    this.dest_default = { application: RoutingAppName.ivr_custom_menu, params: { id: ivrCustomMenu.id } };
  }

  routeToPhoneNumber(phoneNumber: string): void {
    this.dest_default = { application: RoutingAppName.call_phone_number, params: { number: phoneNumber } };
  }

  routeToFileEntry(fileEntry: FileEntryEntity): void {
    this.dest_default = { application: RoutingAppName.play_then_hangup, params: { sound_id: fileEntry.id } };
  }

  routeToWebService(url: string): void {
    this.dest_default = { application: RoutingAppName.webservice, params: { url } };
  }

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      if (did.routed_crm_routing_rule && did.routed_crm_routing_rule.id === this.id ||
        did.out_office_hours_routed_crm_routing_rule && did.out_office_hours_routed_crm_routing_rule.id === this.id) {
        ret.push(did);
      }
    }
    return ret;
  }
}
