import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { _ti } from '@wephone-translation';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'qualification-select-form-input',
  templateUrl: './qualification-select.component.html',
  styleUrls: ['./qualification-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: QualificationSelectFormInput }]
})
export class QualificationSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<QualificationEntity> {
  static nextId = 0;
  @Input() allowEmpty: boolean = true;

  flexSelect: FormControl = new FormControl();
  list: QualificationEntity[];
  flexSelectOptions: IFlexSelectOptions;
  emptyValue: QualificationEntity = new QualificationEntity();

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private em: EntityManager
  ) {
    super(ngControl, elRef);
    this.emptyValue.value = _ti('call_qualification.content.no_qualification');

    this.flexSelectOptions = {
      compareWith: (a: QualificationEntity, b: QualificationEntity) => {
        return (a || this.emptyValue).id === (b || this.emptyValue).id;
      },
      filterFunc: (item: QualificationEntity, filterString) => {
        return regexSearch(item.value, filterString);
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    const repo: QualificationRepository = this.em.getRepository('QualificationRepository');
    this.list = repo.getValidRootObjectList();

    if (this.allowEmpty) {
      this.list.unshift(this.emptyValue);
      if (!this.value) {
        this.setValueNoEmit(this.emptyValue);
      }
    }
  }

  writeValue(val: any): void {
    let _val = val;
    if (this.allowEmpty && !_val) {
      _val = this.emptyValue;
    }

    super.writeValue(_val);
  }

  get controlType(): string {
    return 'qualification-select-form-input';
  }
}
