import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
export class PaginatorIntl {
  constructor(private readonly translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = this.translate.instant('flex_table.pagination.items_per_page_label');
    paginatorIntl.nextPageLabel = this.translate.instant('flex_table.pagination.next_page_label');
    paginatorIntl.previousPageLabel = this.translate.instant('flex_table.pagination.previous_page_label');
    paginatorIntl.firstPageLabel = this.translate.instant('flex_table.pagination.first_page_label');
    paginatorIntl.lastPageLabel = this.translate.instant('flex_table.pagination.last_page_label');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('flex_table.pagination.range_page_label_1', { length });
    }
    const newLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < newLength ? Math.min(startIndex + pageSize, newLength) : startIndex + pageSize;

    return this.translate.instant('flex_table.pagination.range_page_label_2', {
      startIndex: startIndex + 1,
      endIndex,
      newLength,
      length
    });
  }
}
