import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { SingletonBase } from '@wephone-utils';
import { EnterpriseParam, SystemParam } from '@wephone-core/system';

@Injectable()
export class SystemParamService extends SingletonBase {
  constructor(
    private readonly configManager?: ConfigManager,
  ) {
    super();
  }

  getParam(keyName: SystemParam, defaultValue?: any): any {
    const ret = this.configManager.getSystemParam(keyName);
    if (ret === undefined) {
      return defaultValue;
    }

    return ret;
  }

  getEnterpriseParam(keyName: EnterpriseParam): any {
    return this.configManager.getEnterpriseParamValue(keyName);
  }
}
