<div fxLayout="column">

  <div fxLayout="row" fxFlexAlign="start start">
    <!--Avatar-->
    <div fxFlex.xs="50%" fxFlex.gt-xs="160px">
      <div fxLayoutAlign="center">
        <user-avatar [uploadAvatar]="true" [hidden]="!loggedUser" [user]="loggedUser" width="80px" height="80px">
        </user-avatar>
      </div>

      <div fxLayoutAlign="center" class="mt-1">
        <a class="link" (click)="openChangeUserPasswordDialog($event)">{{ 'resetpass.btmchange' | translate }}</a>
      </div>
    </div>

    <div fxFlex.xs="0px" fxFlex.gt-xs="30px"></div>
    <div fxFlex.xs="50%" fxFlex.gt-xs="calc(100%-190px)" fxLayout="column" fxLayoutAlign="start start">
      <h2>
        {{ loggedUser.name }}
      </h2>
      {{ ('user_type_names.'+ loggedUser.user_type) | translate }}
    </div>
  </div>
</div>
