import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { SkillRequirementEntity } from '@wephone-core/model/entity/skill_requirement';
import { ISkillAgentRequirementRepository } from '@wephone-core/model/repository/skill_requirement.i';

export class SkillAgentRequirementRepository extends BaseRepository implements ISkillAgentRequirementRepository {
  ENTITY_CLASS = SkillRequirementEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'skill_requirement';

  public getRequirementByDidId(did_id: number): Array<SkillRequirementEntity> {
    let ret = [];
    for (let r of this.object_list) {
      if (r.object_type == SkillRequirementEntity.OBJECT_TYPE_DID && r.object_id == did_id) {
        ret.push(r);
      }
    }
    return ret;
  }

  public getAgentSkillsByAgentId(agent_id: number): Array<SkillRequirementEntity> {
    let ret = [];
    for (let r of this.object_list) {
      if (r.object_type == SkillRequirementEntity.OBJECT_TYPE_AGENT && r.object_id == agent_id) {
        ret.push(r);
      }
    }
    return ret;
  }
}
