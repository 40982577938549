<div fxFill fxLayout="column">
  <form [formGroup]="filterForm" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{ 'public.from' | translate }}</mat-label>
        <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
          placeholder="{{ 'public.from' | translate }}">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
        <mat-error
          *ngIf="filterForm.get('start_dt').hasError('flexDatepickerMin') || filterForm.get('start_dt').hasError('required')">
          {{ 'form.validator.required'|translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{ 'public.to' | translate }}</mat-label>
        <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
          [matDatepickerFilter]="minEndDateFilter" placeholder="{{ 'public.to' | translate }}">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
        <mat-error *ngIf="filterForm.get('end_dt').hasError('required')">
          {{ 'form.validator.required'|translate }}
        </mat-error>
        <mat-error
          *ngIf="filterForm.get('end_dt').hasError('flexDatepickerMin') || filterForm.get('end_dt').hasError('matDatepickerFilter')">
          {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
          filterForm.get('start_dt').value | timeFormatDateShort} }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-select placeholder="{{'cdr.in_out'|translate}}" formControlName="in_out">
          <mat-option value="">{{'cdr.in_out' | translate}}</mat-option>
          <mat-option value="0">{{'cdr.incoming' | translate}}</mat-option>
          <mat-option value="1">{{'cdr.outgoing' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{ 'stats.filter.phone_number' | translate }}</mat-label>
        <input matInput="tel" formControlName="phone_number"
          placeholder="{{ 'stats.filter.phone_number' | translate}}">
        <button mat-icon-button matSuffix type="button" (click)="filterForm.get('phone_number').setValue(null)"
          [disabled]="!filterForm.get('phone_number').value">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxFlex>
      <button mat-raised-button (click)="reloadClick()" type="button" color="accent">
        {{ 'public.apply' | translate }}
      </button>
    </div>
  </form>

  <div fxFlex="auto" class="logs-data">
    <flex-grid gridName="pbx_cdr_sipphone" [config]="config"></flex-grid>
  </div>
</div>
