export { WephoneCustomIconModule } from './wephone-custom-icon.module';
import { faCircle,
        faLongArrowAltDown,
        faLongArrowAltUp,
        faMinusCircle,
        faSortAlphaDown,
        faSortAlphaUp,
        faSortAmountDown,
        faSortAmountUp,
        faClipboard
     } from '@fortawesome/free-solid-svg-icons';

export const FAIcons = {
    faCircle,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faMinusCircle,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortAmountDown,
    faSortAmountUp,
    faClipboard
};
