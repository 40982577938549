import { LogTo } from '@wephone-core/model/entity/enterprise_crm.i';

export const ENTERPRISE_CRM = {
  crm_list: {
    salesforce: {
      name: 'salesforce',
      default_url: '',
      auth_method: 'oauth',
      logo: 'salesforce-logo.png',
      color: 'primary',
      log_state: true,
    },
    hubspot: {
      name: 'hubspot',
      default_url: 'https://api.hubapi.com',
      auth_method: 'oauth',
      logo: 'hubspot-logo.png',
      color: '',
      log_state: true,
    },
    zendesk: {
      name: 'zendesk',
      default_url: '',
      auth_method: 'oauth',
      logo: 'zendesk-logo.jpg',
      color: '',
      log_state: true,
    },
    freshdesk: {
      name: 'freshdesk',
      default_url: '',
      auth_method: 'apikey',
      logo: 'freshdesk-logo.png',
      color: '',
      log_state: true,
    },
    zoho: {
      name: 'zoho',
      default_url: '',
      auth_method: 'oauth',
      logo: 'zoho-logo.png',
      color: '',
      log_state: true,
    },
    oraclehospitality: {
      name: 'oraclehospitality',
      default_url: '',
      auth_method: 'apikey',
      logo: 'oraclehospitality-logo.jpg',
      color: '',
      log_state: true,
    },
    vtiger: {
      name: 'vtiger',
      default_url: '',
      auth_method: 'apikey',
      logo: 'vtiger-logo.png',
      color: '',
      log_state: true,
    },
    pipedrive: {
      name: 'pipedrive',
      default_url: '',
      auth_method: 'oauth',
      logo: 'pipedrive-logo.png',
      color: '',
      log_to: [
        LogTo.CONTACT,
        LogTo.DEAL,
      ],
      log_state: true,
    },
    front: {
      name: 'front',
      default_url: '',
      auth_method: 'oauth',
      logo: 'front-logo.png',
      color: '',
      log_state: true,
    },
    shopify: {
      name: 'shopify',
      default_url: '',
      auth_method: 'oauth',
      logo: 'shopify-logo.png',
      color: '',
      log_state: true,
    },
    customcrm: {
      name: 'customcrm',
      default_url: '',
      auth_method: 'apikey',
      logo: 'customcrm-logo.png',
      color: '',
      log_state: true,
    },
    dynamics: {
      name: 'dynamics',
      default_url: '',
      auth_method: 'oauth',
      logo: 'dynamics-logo.png',
      color: '',
      log_state: false,
    },
    helpscout: {
      name: 'helpscout',
      default_url: '',
      auth_method: 'oauth',
      logo: 'helpscout-logo.png',
      color: '',
      log_state: true,
    },
  },
  crm_contact_filters: {
    contact_type: {
      type: 'string',
      type_enum: {
        'crm.contact.types.prospect': 'prospect',
        'crm.contact.types.lead': 'lead',
        'crm.contact.types.client': 'client',
        'crm.contact.types.partner': 'partner'
      },
      label: 'crm.contact.filter_names.type'
    }
  }
}
