import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastService, EditingComponent, DialogService, Colors, DialogActionButton, localNow, parseDateTime } from '@wephone-utils';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { OutcallcampaignAdvanceComponent } from '@wephone/components/outcallcampaign/outcallcampaign-advance/outcallcampaign-advance.component';
import { OutcallcampaignGeneralComponent } from '@wephone/components/outcallcampaign/outcallcampaign-general/outcallcampaign-general.component';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OutcallcampaignResetModalComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-reset-modal/outcallcampaign-reset-modal.component';
import { OutcallService } from '@wephone-core/service/outcall.service';
import { OutcallcampaignContactsComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-contacts/outcallcampaign-contacts.component';
import { FormBuilder } from '@angular/forms';
import { QueueAgentListControlValue } from '@wephone/components/call-queue/queue-agent.i';
import { _ti } from '@wephone-translation';
import { OutcallcampaignImportContactsComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-import-contacts/outcallcampaign-import-contacts.component';
import { OutcallCampaignItemRepository } from '@wephone-core/model/repository/outcallcampaignitem';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { OutcallCampaignItemEntity } from '@wephone-core/model/entity/outcallcampaignitem';
import { OutcallcampaignViewAgentsComponent } from '@wephone/modals/outcallcampaign/outcallcampaign-view-agents/outcallcampaign-view-agents.component';
import * as _ from 'lodash';
import { ArchiveStateType, CampaignActiveType } from '@wephone-core/model/repository/outcallcampaign.i';
import { OutcallCampaignaAMAction, OutcallCampaignState } from '@wephone-core/model/entity/outcallcampaign.i';

@Component({
  selector: 'app-outcallcampaign-edit',
  templateUrl: './outcallcampaign-edit.component.html',
  styleUrls: ['./outcallcampaign-edit.component.scss']
})
export class OutcallcampaignEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: OutcallCampaignEntity;
  @ViewChild('advancedEditor') advancedEditor: OutcallcampaignAdvanceComponent;
  @ViewChild('generalEditor') generalEditor: OutcallcampaignGeneralComponent;
  @ViewChild('mainArea') mainArea;

  actions: DialogActionButton[] = [
    {
      label: 'public.archive',
      action: () => {
        this.archive();
      },
      color: Colors.PRIMARY,
      visible: (): boolean => {
        return this.campaign && !this.campaign.is_archived;
      }
    },
    {
      label: 'public.unarchive',
      action: () => {
        this.unarchive();
      },
      color: Colors.PRIMARY,
      visible: (): boolean => {
        return this.campaign && !!this.campaign.is_archived;
      }
    },
    {
      label: 'public.reset',
      action: () => {
        this.reset();
      },
      color: Colors.PRIMARY,
      visible: (): boolean => {
        return true;
      }
    },
    {
      label: 'public.delete',
      action: () => {
        this.delete();
      },
      color: Colors.PRIMARY,
      visible: (): boolean => {
        return true;
      }
    }
  ];

  steps: any = {
    step1: 'campaign.tab_campaign_attributes',
    step2: 'call_queue_edit.section.main_agents',
    step3: 'campaign.tab_campaign_contacts',
    step4: 'call_queue.content.tab_advanced',
  };

  campaign: OutcallCampaignEntity;
  queue: CallQueueEntity;

  originCampaign: OutcallCampaignEntity;
  originQueue: CallQueueEntity;
  hasContactItems = false;
  contactItems: OutcallCampaignItemEntity[] = [];
  limitDisplayContacts = 3;

  private queueRepo: CallQueueRepository;
  private campaignRepo: OutcallCampaignRepository;

  constructor(
    private readonly dialogService: DialogService,
    private readonly outcallService: OutcallService,
    private readonly elementRef: ElementRef,
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setOriginCampaign(this.editingItem);

    this.queueRepo = this.em.getRepository('CallQueueRepository');
    this.campaignRepo = this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository');

    const queue = !this.campaign.queue_id
      ? this.queueRepo.create() as CallQueueEntity
      : this.campaign.queue;

    this.setOriginQueue(queue);

    this.initFormGroup();
    this.setHasContactItems();
  }

  private async setHasContactItems(): Promise<any> {
    this.contactItems = await this.em.getRepository<OutcallCampaignItemRepository>('OutcallCampaignItemRepository')
      .findByCampaignId(this.campaign.id);
    this.hasContactItems = !!this.contactItems.length;
  }

  get step_keys(): string[] {
    return Object.keys(this.steps);
  }

  setOriginCampaign(campaign: OutcallCampaignEntity): void {
    this.originCampaign = _.cloneDeep(campaign);
    this.campaign = _.cloneDeep(campaign);
  }

  private setOriginQueue(queue: CallQueueEntity): void {
    this.originQueue = _.cloneDeep(queue);
    this.queue = _.cloneDeep(queue);
  }

  initFormGroup(): void {
    const queue_agents_main: QueueAgentListControlValue = {
      agent_selection_mode: this.queue.agent_selection_mode_main || 0,
      queue_agents: _.cloneDeep(this.queue.main_agents)
    };
    this.form = this.fb.group({
      queue_agents_main: [queue_agents_main],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        console.log('form changed', changes);
        this.onFormValueChange();
      })
    );

    // this.form.get('queue_agents_main').valueChanges.subscribe(changes => {
    //   console.log('form queue_agents_main changed', changes);
    //   this.onFormValueChange();
    // });
  }

  private scrollToFirstInvalidControl(): void {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector('.mat-error');
      if (!firstInvalidControl) {
        console.error('No error element found');
        return;
      }
      this.mainArea.scrollToElement(firstInvalidControl);
    });
  }

  private resetFormCampaign(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
  }

  private getFormResetData(): object {
    const queue_agents_main: QueueAgentListControlValue = {
      agent_selection_mode: this.queue.agent_selection_mode_main || 0,
      queue_agents: _.cloneDeep(this.queue.main_agents)
    };

    return {
      queue_agents_main,
    };
  }

  get editors(): any[] {
    return [this.advancedEditor, this.generalEditor];
  }

  formHasChanged(): boolean {
    for (const editor of this.editors) {
      if (editor && editor.formHasChanged()) {
        return true;
      }
    }

    return super.formHasChanged();
  }

  resetForm(): void {
    this.campaign = _.cloneDeep(this.originCampaign);
    this.queue = _.cloneDeep(this.originQueue);
    for (const editor of this.editors) {
      editor.resetForm();
    }
    this.resetFormCampaign();
    this.onFormValueChange();
  }

  reset(): Promise<any> {
    return this.dialogService
      .openDialog2(OutcallcampaignResetModalComponent, {
        data: {
          outcall_campaign: this.campaign,
        },
        size: 'auto',
      })
      .afterClosed()
      .toPromise();
  }

  openDialogContactPhone(): void {
    const data = {
      campaign: this.campaign,
    };

    this.dialogService.openSideDialog(OutcallcampaignContactsComponent, { data, size: 's' }, async data_response => {
      // if (!data_response) {
      //   console.log('Do cancel dialog');
      //   return;
      // }
      await this.setHasContactItems();
    });
  }

  /**
   * Validate campaign before activating.
   * @throws Throw error if campaign is invalid.
   */
  private validateActivatingCampaign(): void {
    if (this.campaign.schedule_end_date) {
      const now = localNow();

      let startDateTime = this.campaign.schedule_start_date && this.campaign.schedule_start_date.startOf('day');
      let endDateTime = this.campaign.schedule_end_date.endOf('day');

      const startTime = this.campaign.hour_start && parseDateTime(this.campaign.hour_start);
      const endTime = this.campaign.hour_end && parseDateTime(this.campaign.hour_end);

      if (endTime) {
        endDateTime = endDateTime.set({
          hour: endTime.hour,
          minute: endTime.minute,
          second: 0,
          millisecond: 0,
        });
      }

      if (startDateTime && startTime) {
        startDateTime = startDateTime.set({
          hour: startTime.hour,
          minute: startTime.minute,
          second: 0,
          millisecond: 0,
        });
      }

      // Check if end date is in the past
      if (endDateTime < now) {
        let errorMsg = _ti('campaign.validator.cannot_active_campaign_end_date_in_past');
        if (endDateTime.startOf('day').equals(now.startOf('day'))) {
          errorMsg = _ti('campaign.validator.cannot_active_campaign_end_time_in_past');
        }

        throw new Error(errorMsg);
      }

      // Start Date > End Date or Start Date = End Date and Start Time > End Time
      if (startDateTime && startDateTime > endDateTime) {
        throw new Error(_ti('campaign.validator.scheduled_datetime_invalid'));
      }
    }

    if (!this.campaign.is_valid_queue_agent) {
      throw new Error(_ti('campaign.validator.no_agents'));
      // this.toast.showError(_ti('campaign.validator.no_agents'));
      // return;
    }

    if (!this.hasContactItems) {
      throw new Error(_ti('campaign.validator.no_contacts'));
      // this.toast.showError(_ti('campaign.validator.no_contacts'));
      // return;
    }
  }

  enable(): Promise<void> {
    // if (!this.campaign.is_valid_scheduled_date) {
    //   this.toast.showError(_ti('campaign.validator.schedule_end_date_cannot_lesser_than_current_date'));
    //   return;
    // }

    // if (!this.campaign.is_valid_scheduled_time) {
    //   this.toast.showError(_ti('campaign.validator.hour_end_time_must_be_greater_than_current_time'));
    //   return;
    // }
    // if (!this.campaign.is_valid_scheduled_datetime) {
    //   this.toast.showError(_ti('campaign.validator.invalid_scheduled_datetime'));
    //   return;
    // }

    // if (!this.campaign.is_valid_queue_agent) {
    //   this.toast.showError(_ti('campaign.validator.no_agents'));
    //   return;
    // }

    // if (!this.hasContactItems) {
    //   this.toast.showError(_ti('campaign.validator.no_contacts'));
    //   return;
    // }

    // return this.changeActiveState(this.campaign, CampaignActiveType.Active);

    try {
      this.validateActivatingCampaign();
      return this.changeActiveState(this.campaign, CampaignActiveType.Active);
    } catch (e) {
      this.toast.showErrorMessage(e, _ti('public.message.enable_failure'));
    }
  }

  disable(): Promise<any> {
    return this.changeActiveState(this.campaign, CampaignActiveType.Deactive);
  }

  private async changeActiveState(item: OutcallCampaignEntity, state: CampaignActiveType): Promise<void> {
    try {
      const response = await this.campaignRepo.changeActiveState([item], state);
      if (response.items) {
        const message_key = state ? 'public.message.enable_success' : 'public.message.disable_success';
        this.toast.showSuccess(_ti(message_key));
      }

      if (response.error_message) {
        this.toast.showWarning(response.error_message);
      }
    } catch (e) {
      console.error('Activate/Deactivate Campaign error', e);
      const defaultMsg = state ? _ti('public.message.enable_failure') : _ti('public.message.disable_failure');
      this.toast.showErrorMessage(e, defaultMsg);
    }
  }

  delete(): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        try {
          const response = await this.em.getRepository('OutcallCampaignRepository').bulkDelete([this.campaign]);
          if (response && response.error && response.error.message) {
            this.toast.showWarning(response.error.message);
          } else {
            this.toast.showInfo(_ti('public.message.delete_success'));
          }
          this.close();
        } catch (e) {
          console.error('Delete campaign error', e);
          this.toast.showErrorMessage(e, _ti('public.message.delete_failure'));
        }
      }
    );
  }

  export(): void {
    this.outcallService.exportItems(this.campaign.getId());
  }

  archive(): Promise<any> {
    if (this.campaign.campaign_status === OutcallCampaignState.IN_PROGRESS) {
      return this.dialogService.confirmDialog(
        _ti('dialogs.confirmation'),
        _ti('campaign.message.confirm_archive'),
        async () => {
          return this.updateArchives(this.campaign, ArchiveStateType.Archived);
        }
      );
    }
    return this.updateArchives(this.campaign, ArchiveStateType.Archived);
  }

  unarchive(): Promise<any> {
    return this.updateArchives(this.campaign, ArchiveStateType.Unarchived);
  }

  private async updateArchives(item: OutcallCampaignEntity, archive: ArchiveStateType): Promise<any> {
    try {
      if (archive === ArchiveStateType.Archived && item.campaign_status === OutcallCampaignState.IN_PROGRESS) {
        await this.campaignRepo.changeActiveState([item], CampaignActiveType.Deactive);
      }

      await this.campaignRepo.bulkArchive([item], archive);
      this.toast.showSuccess(_ti(archive ? 'public.message.archive_success' : 'public.message.unarchive_success'));
      item.is_archived = archive;
    } catch (error) {
      console.error('Update Archive Error', error);
      let msg = _ti('public.message.archive_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toast.showError(msg);
    }
  }

  private _getSubmitedValues(field: string, changesetData: any): any {
    let updatedValue: any;
    switch (field) {
      case 'after_call_time':
      case 'am_check_max_duration':
        updatedValue = changesetData[field] || 0;
        break;
      // Get values from Entity
      case 'out_qualification_id':
      case 'opening_hour_calendar_id':
      case 'group_id':
      case 'ivr_id':
      case 'calling_number_id':
        // Should be null instead of undefined to be valid submit key
        updatedValue = changesetData[field] && changesetData[field].getId() ? changesetData[field].getId() : null;
        break;
      // Get values from Boolean
      case 'recording_mode':
      case 'has_qualification':
      case 'aftercall_pause_mode':
      case 'am_check_enabled':
        updatedValue = changesetData[field] ? 1 : 0;
        break;
      case 'am_action':
        updatedValue = changesetData[field] ? OutcallCampaignaAMAction.ClientUnreachabled : OutcallCampaignaAMAction.CampaignOK;
        break;
      default:
        updatedValue = changesetData[field];
        break;
    }
    return updatedValue;
  }

  getChangeSet(): any {
    const formCampaignChangeset = super.getChangeSet(); // _.cloneDeep(
    let changeset: any = _.merge(this.generalEditor.getChangeSet(), this.advancedEditor.getChangeSet());
    if (!_.isEqual(formCampaignChangeset, {})) {
      const formData: any = {
        main_agents: formCampaignChangeset.queue_agents_main ? formCampaignChangeset.queue_agents_main.queue_agents : [],
        agent_selection_mode_main: formCampaignChangeset.queue_agents_main ?
          formCampaignChangeset.queue_agents_main.agent_selection_mode : [],
      };
      changeset = _.merge(changeset, formData);
    }
    return changeset;
  }

  private validateForm(update_fields: string[]): boolean {
    let isValid = true;
    if (!this.generalEditor.isValidForm(update_fields)) {
      console.error('Invalid general form');
      isValid = false;
    }

    if (!this.advancedEditor.isValidForm()) {
      console.error('Invalid advance form');
      isValid = false;
    }

    // const queueAgentsMainControl = this.form.get('queue_agents_main');
    // this.form.get('queue_agents_main').setErrors(null);
    // if (_.isEmpty(queueAgentsMainControl.value) || _.isEmpty(queueAgentsMainControl.value.queue_agents)) {
    //   this.form.get('queue_agents_main').setErrors({ required: true });
    //   queueAgentsMainControl.markAsTouched();
    //   isValid = false;
    // }

    // if (!isValid) {
    //   this.toast.showError(_ti('public.message.update_failure'));
    //   return;
    // }
    return isValid;
  }

  async submitForm(): Promise<void> {
    try {
      let changesetData: Record<string, any> = this.getChangeSet();

      // Mapped properties between Entity Object & Object returned from Server (WebService or WebSocket)
      const campaign_fields: any = {
        name: 'campaign_name',
        schedule_start_date: 'schedule_start_dt',
        schedule_end_date: 'schedule_end_dt',
        hour_start: undefined,
        hour_end: undefined,
        ivr_id: undefined,
        campaign_type: undefined,
        calling_number_id: undefined,
        max_try_count: undefined,
        am_action: undefined,
        am_check_enabled: undefined,
        am_check_max_duration: undefined,
      };

      const queue_fields: any = {
        agent_selection_mode_main: undefined,
        main_agents: undefined,
        recording_mode: undefined,
        queue_priority: undefined,
        out_qualification_id: undefined,
        has_qualification: undefined,
        bo_type: undefined,
        bo_url: undefined,
        group_id: undefined,
        opening_hour_calendar_id: 'calendar_id',
        alias: undefined,
        aftercall_pause_mode: undefined,
        after_call_time: undefined,
        result_notification_url: undefined
      };

      const obj_data_campaign: any = {};
      const obj_data_queue: any = {};
      const update_campaign_fields: string[] = [];
      const update_campaign_extras: any = {};
      const update_queue_fields: string[] = [];

      if (!Object.keys(changesetData).length) {
        console.error('No changeset data');
        throw new Error(_ti('form.validator.data_invalid'));
      }

      // Get excluded changeset data
      if (changesetData.hasOwnProperty('am_check_enabled') && !this._getSubmitedValues('am_check_enabled', changesetData)) {
        changesetData = _.omit(changesetData, ['am_check_max_duration', 'am_action']);
      }
      if (changesetData.hasOwnProperty('opening_hour_calendar_id') && this._getSubmitedValues('opening_hour_calendar_id', changesetData)) {
        changesetData = _.omit(changesetData, ['hour_start', 'hour_end']);
      }

      for (const field of Object.keys(changesetData)) {
        // Get values from changeset
        const updatedValue = this._getSubmitedValues(field, changesetData);

        if (field in campaign_fields) {
          // Update mapped fields
          const campaign_field = campaign_fields[field] ? campaign_fields[field] : field;
          obj_data_campaign[campaign_field] = updatedValue;
          update_campaign_fields.push(field);
        }

        if (field in queue_fields) {
          const queue_field = queue_fields[field] ? queue_fields[field] : field;
          obj_data_queue[queue_field] = updatedValue;
          update_queue_fields.push(field);
        }
      }

      const update_fields = update_campaign_fields.concat(update_queue_fields);

      if (!update_fields.length) {
        console.error('No updated fields');
        throw new Error(_ti('form.validator.data_invalid'));
      }

      if (!this.validateForm(update_fields)) {
        this.scrollToFirstInvalidControl();
        throw new Error(_ti('form.validator.data_invalid'));
      }

      // Set update queue properties
      if (update_queue_fields.length > 0) {
        await this._updateQueue(obj_data_queue, update_queue_fields);
      }

      // Set update campaign properties
      if (update_campaign_fields.length > 0) {
        const updatedCampaign = await this._updateCampaign(
          obj_data_campaign,
          update_campaign_fields,
          update_campaign_extras
        );

        if (!updatedCampaign) {
          this.toast.showError(_ti('form.validator.data_invalid'));
          return undefined;
        }
      }

      // Reload campaign to fix bug running campaign after the campaign was created successfully
      const campaign = OutcallCampaignRepository.getInstance().getObjectById(this.campaign.id);
      this.setOriginCampaign(campaign);

      this.toast.showSuccess(_ti('public.message.update_success'));
      this.resetForm();
    } catch (e) {
      console.error('Error campaign form submit', e);
      // const msg = e && e.message || e && e.error && e.error.message || _ti('public.message.update_failure');
      // this.toast.showError(msg);
      this.toast.showError(_ti('public.message.update_failure'));
    }
  }

  private async _updateQueue(obj_data_queue: any, update_queue_fields: string[]): Promise<any> {
    const queue: CallQueueEntity = this.queueRepo.create() as CallQueueEntity;

    obj_data_queue.id = this.queue.getId();

    if (obj_data_queue.has_qualification === 0) {
      delete obj_data_queue.out_qualification_id;
    }
    queue.setObjectData(obj_data_queue, false);

    const returnedQueue: CallQueueEntity = await this.advancedEditor.updateQueueAttrs(queue, update_queue_fields);
    this.setOriginQueue(returnedQueue);
    // reload general form to update some fields of queue
    this.generalEditor.initFormGroup();

    return returnedQueue;
  }

  private async _updateCampaign(obj_data_campaign: any, update_campaign_fields: string[], update_campaign_extras: any): Promise<any> {
    const campaignRepo = this.em.getRepository('OutcallCampaignRepository');
    const campaign: OutcallCampaignEntity = campaignRepo.create() as OutcallCampaignEntity;

    obj_data_campaign.id = this.campaign.getId();
    campaign.setObjectData(obj_data_campaign, false);

    const updatedResponse: any = await this.generalEditor.updateCampaignAttrs(
      campaign,
      update_campaign_fields,
      update_campaign_extras
    );

    if (updatedResponse && updatedResponse.id) {
      // Update object on editor
      const returnedCampaign: OutcallCampaignEntity = campaignRepo.getObjectById(updatedResponse.id);
      // this.setOriginCampaign(returnedCampaign);
      return returnedCampaign;
    }
  }

  openDialogViewParticularAgents(): void {
    this.dialogService.openSideDialog(OutcallcampaignViewAgentsComponent, {
      data: {
        campaign: this.campaign
      },
      size: 'l'
    });
  }

  openDialogImportContactPhone(): void {
    this.dialogService
      .openDialog2(OutcallcampaignImportContactsComponent, {
        data: {
          outcallcampaign_id: this.campaign.id
        },
        size: 'l'
      }, async rs => {
        if (!rs) {
          return;
        }

        if (rs.success > 0) {
          await this.setHasContactItems();
          this.openDialogContactPhone();
        }
      });
  }
}
