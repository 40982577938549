<p class="mt-0">{{ 'sound_manager.message.not_delete_in_used' | translate }}</p>
<ul *ngIf="inUsedObjects['user']" class="in-used-list">
  <strong>{{ 'reusable_sound.content.voicemail_of_user'|translate }}</strong>
  <li *ngFor="let user of inUsedObjects['user']">
    {{ user.name }}
  </li>
</ul>
<ul *ngIf="inUsedObjects['did']" class="in-used-list">
  <strong>{{ 'did.content.inused'|translate }}</strong>
  <li *ngFor="let did of inUsedObjects['did']">
    {{ did.display_number }}
  </li>
</ul>
<ul *ngIf="inUsedObjects['call_queue']" class="in-used-list">
  <strong>{{ 'call_queue.content.inused'|translate }}</strong>
  <li *ngFor="let queue of inUsedObjects['call_queue']">
    {{ queue.queue_name }}
  </li>
</ul>
<ul *ngIf="inUsedObjects['ivr_menu']" class="in-used-list">
  <strong>{{ 'reusable_sound.content.ivrmenus'|translate }}</strong>
  <li *ngFor="let ivrMenu of inUsedObjects['ivr_menu']">
    {{ ivrMenu.name }}
  </li>
</ul>
<ul *ngIf="inUsedObjects['ivr_evaluation']" class="in-used-list">
  <strong>{{ 'reusable_sound.content.ivr_evaluation'|translate }}</strong>
  <li *ngFor="let ivrMenu of inUsedObjects['ivr_evaluation']">
    {{ ivrMenu.name }}
  </li>
</ul>
<ul *ngIf="inUsedObjects['work_calendar']" class="in-used-list">
  <strong>{{ 'reusable_sound.content.work_calendars'|translate }}</strong>
  <li *ngFor="let calendar of inUsedObjects['work_calendar']">
    {{ calendar.name }}
  </li>
</ul>
<ul *ngIf="inUsedObjects['enterprise_telecom_params']" class="in-used-list">
  <strong>{{ 'enterprise_info.title'|translate }}</strong>
  <li>
    {{ 'enterprise_info.step.sip_terminal_settings'|translate }}
  </li>
</ul>
