import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { SingletonBase } from '@wephone-utils';
import { FlexIvrSettings } from './flexivr_settings';

export interface CampaignActiveInfo {
  name: string;
  id: number;
  total_calls: number;
  processed_calls: number;
  success_calls: number;
  failure_calls: number;
  success_today_calls: number;
  remaining_calls: number;
  connected_agents: number;
}
@Injectable()
export class OutcallService extends SingletonBase {
  public static OUTCALL_UPLOAD_CSV_URL = 'callcenter/outcallcampaign/import_csv';
  public static OUTCALL_SAVE_CONTACT_URL = 'callcenter/outcallcampaign/save_contact_format';
  public static OUTCALL_CAMPAIGN_RESET = '/api/callcenter/outcallcampaign/reset';
  public static OUTCALL_CAMPAIGN_ACTIVE_INFO = 'callcenter/outcallcampaign/active_info';
  public static OUTCALL_CAMPAIGN_EXPORT_ITEMS = '/callcenter/data_export_csv_outcalls/{campaign_id}';

  constructor(
    private fileEntryService: FileEntryService,
    private settings: FlexIvrSettings
  ) {
    super();
  }

  importCsv(file: any): Promise<any> {
    console.log('Upload file', file);
    if (!file) {
      console.error('No file uploaded');

      return Promise.resolve(undefined);
    }

    // const eid: number = this.configManager.getCurrentEnterpriseId();
    const params: any = {
      // eid
    };

    const options: any = {
      data: params,
      file,
      method: 'POST',
      headers: { 'Content-Type': file.type }
    };

    const body = new FormData();
    body.append('file', file);

    for (const key of Object.keys(params)) {
      body.append(key, params[key]);
    }

    return HttpEngine.getInstance().apiPostV2(OutcallService.OUTCALL_UPLOAD_CSV_URL, body, options);
  }

  exportItems(outcall_campaign_id: number): void {
    let url = this.settings.getEnterpriseUrl(OutcallService.OUTCALL_CAMPAIGN_EXPORT_ITEMS);
    url = url.replace('{campaign_id}', outcall_campaign_id.toString());
    this.fileEntryService.download(url, `campaing-items-${outcall_campaign_id}.csv`);
  }

  saveContactFormat(contact_format: any, outcall_campaign_id: number): Promise<any> {
    const params = {
      contact_format,
      outcall_campaign_id
    };

    return HttpEngine.getInstance().apiPostV2(OutcallService.OUTCALL_SAVE_CONTACT_URL, params);
  }

  resetAllCampaigns(outcall_campaign_id: number): Promise<any> {
    const params = {
      only_unsuccessful: 0,
      outcall_campaign_id
    };

    return HttpEngine.getInstance().post(OutcallService.OUTCALL_CAMPAIGN_RESET, params);
  }

  getActiveInfo(): Promise<CampaignActiveInfo[]> {
    return HttpEngine.getInstance().apiGetV2(OutcallService.OUTCALL_CAMPAIGN_ACTIVE_INFO);
  }

  resetOnlyUnsuccessfullCampaigns(outcall_campaign_id: number): Promise<any> {
    const params = {
      only_unsuccessful: 1,
      outcall_campaign_id
    };

    return HttpEngine.getInstance().post(OutcallService.OUTCALL_CAMPAIGN_RESET, params);
  }
}
