<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <div class="filter-container">
      <form [formGroup]="filterForm" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
        <div>
          <flex-search-input formControlName="filterValue" placeholder="{{ 'public.search' | translate }}" ngClass.xs="search-full-width"></flex-search-input>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #activeTpl let-active="row">
    <mat-icon *ngIf="active.is_active">done</mat-icon>
  </ng-template>
  <flex-crud-page #flexCrud 
    pageTitle="{{ 'menu.manage_slack_channel' | translate }}"
    [dataSource]="dataSource" [tableConfig]="tableConfig" [columnTemplates]="{'is_active': activeTpl}"
    [filterTemplate]="listFilterTpl" [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow"></flex-crud-page>
</div>
