import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { CrmCallLogState } from '@wephone-core/model/entity/enterprise_crm.i';
import {
  EditingComponent, ToastService, IFlexDialogConfig, DialogService, NoWhitespaceValidator, UrlValidator,
} from '@wephone-utils';
import {
  AbstractControl,
  FormBuilder, FormControl, FormGroupDirective, NgForm, RequiredValidator, Validators,
} from '@angular/forms';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { ConfigManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import { ErrorStateMatcher } from '@angular/material/core';
import { _tk, _ti } from '@wephone-translation';
import { AuthenticationService } from '@wephone-core/service/authentication';
import {
  ImportUsersFromCrmModal,
} from '@wephone/modals/import-users-from-crm-modal/import-users-from-crm-modal.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CrmType } from '@wephone-core/model/entity/enterprise';
import { NotContainSpace } from '@wephone/services/form-validator';
import { EnterpriseCrmService } from '@wephone/services/emterprise_crm.service';
import {
  TestUrlServiceResultDataType, TestUrlServiceResultModalComponent,
} from '@wephone/modals/test-url-service-result-modal/test-url-service-result-modal.component';
import * as _ from 'lodash';
import { SyncTaskEntity } from '@wephone-core/model/entity/sync_task';
import { OHCashier, OHTransaction } from '@wephone/services/enterprise_crm.service.i';

export class EnterpriseCRMErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;

    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export class OHStateMatcher implements ErrorStateMatcher {
  private errors: { [key: string]: boolean };
  constructor(errors) {
    this.errors = errors;
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(this.errors && this.errors[this.getControlName(control)] && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-enterprise-crm-edit',
  templateUrl: './enterprise-crm-edit.component.html',
  styleUrls: ['./enterprise-crm-edit.component.scss']
})

export class EnterpriseCrmEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: EnterpriseCrmEntity;
  @ViewChild('urlInput') urlInput: ElementRef;

  private readonly groupRepo = UserGroupRepository.getInstance();
  private readonly enterpriseCrmRepo = EnterpriseCrmRepository.getInstance();

  errors: { [key: string]: boolean } = {
    cashier_id: false,
    transaction_code: false,
  };

  matcher = new EnterpriseCRMErrorStateMatcher();
  ohMatcher = new OHStateMatcher(this.errors);
  enterpriseCrm: EnterpriseCrmEntity;
  authMethod: string;
  types = [];
  type: any;
  groups = [];
  hide = true;
  logToList = [
    // { label: 'enterprise_crm.content.log_call_to_contact', value: LogCallTo.CONTACT },
    // { label: 'enterprise_crm.content.log_call_to_lead', value: LogCallTo.LEAD },
    // { label: 'enterprise_crm.content.log_call_to_deal', value: LogCallTo.DEAL },
  ];

  callLogStateList = [
    // { label: 'enterprise_crm.content.log_custom', value: CrmCallLogState.LOG_CUSTOM },
    { label: 'enterprise_crm.content.log_completed', value: CrmCallLogState.LOG_COMPLETED },
    { label: 'enterprise_crm.content.log_inprogress', value: CrmCallLogState.LOG_INPROGRESS },
    { label: 'enterprise_crm.content.log_notstarted', value: CrmCallLogState.LOG_NOTSTARTED },
  ];
  mailboxes = [];

  syncMessage: string;
  syncState: string;
  isActive = false;
  crmSyncRoom: SyncTaskEntity;
  ohCashiers: OHCashier[] = [];
  ohTransactionCodes: OHTransaction[] = [];

  constructor(
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder,
    public readonly configManager: ConfigManager,
    public readonly toast: ToastService,
    private readonly authService: AuthenticationService,
    private readonly enterpriseCrmService: EnterpriseCrmService,
  ) {
    super();
    this.groups = this.groupRepo.getObjectList();
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    
    this.enterpriseCrm = _.cloneDeep(this.editingItem);

    if (this.enterpriseCrm.type === CrmType.SALESFORCE) {
      this.callLogStateList.unshift({ label: 'enterprise_crm.content.log_custom', value: CrmCallLogState.LOG_CUSTOM });
    }

    if (this.enterpriseCrm.type !== CrmType.VTIGER) {
      this.callLogStateList.unshift({ label: 'enterprise_crm.content.do_nothing', value: CrmCallLogState.DO_NOTHING });
    }

    const isBusinessHotel = this.authService.isBusinessHotel();
    if (isBusinessHotel) {
      try {
        this.crmSyncRoom = await this.enterpriseCrmService.getSynchronizationHotelRoom(this.enterpriseCrm);
      } catch (error) {
        console.error('Cannot get synchronization status', error);
      }
    }

    this.updateSynchronizationState();

    this.getOHList();

    this.initFormGroup();

    this.isActive = !!this.enterpriseCrm.is_active;

    this.types = await this.configManager.getCrmTypeList();
    this.type = this.types[this.enterpriseCrm.type];
    this.authMethod = this.type && this.type.auth_method;
    this.mailboxes = this.enterpriseCrm.mailboxes;
    if (this.type && this.type.log_to) {
      this.type.log_to.forEach((t: string) => {
        this.logToList.push({
          label: `enterprise_crm.content.log_to_${t}`,
          value: t,
        });
      });
    }
  }

  private getOHList(): void {
    if (!(
      this.enterpriseCrm.id && this.enterpriseCrm.is_authenticated && this.enterpriseCrm.isOracleHospitality &&
      this.enterpriseCrm.url && this.enterpriseCrm.hotel_id && this.enterpriseCrm.oh_client_id && this.enterpriseCrm.oh_client_secret
    )) {
      console.log('Lack of data', this.enterpriseCrm);
      return;
    }

    let getOHList: boolean = !this.form;
    if (this.form) {
      const data: any = this.formService.getChangeSet(this.form);
      getOHList = !!data.client_id || !!data.client_secret || !!data.hotel_id || !!data.url || !!data.username || !!data.password;
    }
    
    if (getOHList) {
      this.errors.cashier_id = false;
      this.ohCashiers = [];
  
      this.errors.transaction_code = false;
      this.ohTransactionCodes = [];
  
      this.enterpriseCrmService.getOHCashiers(this.enterpriseCrm)
        .then((resp) => {
          this.ohCashiers = resp;
        })
        .catch((error) => {
          console.error('Cannot get oh cashiers');
          this.errors.cashier_id = true;
          this.form.get('cashier_id').markAsTouched();
        });

      this.enterpriseCrmService.getOHTransactions(this.enterpriseCrm)
        .then((resp) => {
          this.ohTransactionCodes = resp;
        })
        .catch((error) => {
          console.error('Cannot get oh transactions');
          this.errors.transaction_code = true;
          this.form.get('transaction_code').markAsTouched();
        });
    }
  }

  isType(types: string[]): boolean {
    return this.enterpriseCrm && types.includes(this.enterpriseCrm.type);
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.enterpriseCrm.name, [Validators.maxLength(128), NoWhitespaceValidator]],
      url: [this.enterpriseCrm.url, [Validators.maxLength(512), UrlValidator, NoWhitespaceValidator]],
      callstart_url: [this.enterpriseCrm.callstart_url, [Validators.maxLength(512), NoWhitespaceValidator]],
      callend_url: [this.enterpriseCrm.callend_url, [Validators.maxLength(512), NoWhitespaceValidator]],
      username: [this.enterpriseCrm.username, [Validators.maxLength(64), NoWhitespaceValidator]],
      password: [this.enterpriseCrm.password, [Validators.maxLength(64), NoWhitespaceValidator]],
      apikey: [this.enterpriseCrm.apikey, [Validators.maxLength(256), NoWhitespaceValidator]],
      autoRunTask: [this.enterpriseCrm.auto_run_task],
      mailbox_id: [this.enterpriseCrm.mailbox_id],
      hotel_id: [this.enterpriseCrm.hotel_id, [Validators.maxLength(512), NoWhitespaceValidator]],
      client_id: [this.enterpriseCrm.oh_client_id, [Validators.maxLength(512), NoWhitespaceValidator]],
      client_secret: [this.enterpriseCrm.oh_client_secret, [Validators.maxLength(512), NoWhitespaceValidator]],
      cashier_id: [this.enterpriseCrm.cashier_id],
      transaction_code: [this.enterpriseCrm.transaction_code, [Validators.maxLength(512), NoWhitespaceValidator]],
      createContactInboundCall: [this.enterpriseCrm.create_contact_inbound_call],
      log_state_outbound: [this.enterpriseCrm.log_state_outbound],
      log_state_outbound_text: [this.enterpriseCrm.log_state_outbound_text],
      log_state_inbound: [this.enterpriseCrm.log_state_inbound],
      log_state_inbound_text: [this.enterpriseCrm.log_state_inbound_text, [Validators.maxLength(64), NoWhitespaceValidator]],
      log_state_missed: [this.enterpriseCrm.log_state_missed],
      log_state_missed_text: [this.enterpriseCrm.log_state_missed_text, [Validators.maxLength(64), NoWhitespaceValidator]],
      log_call_to: [this.enterpriseCrm.log_call_to],
    });

    this.form.valueChanges.subscribe(() => {
      this.updateSynchronizationState();
      this.onFormValueChange();
    });
  }

  // async synchronizeHotelRoom(): Promise<void> {
  //   try {
  //     this.crmSyncRoom = await this.enterpriseCrmService.synchronizeHotelRoom(this.enterpriseCrm);
  //     this.toast.showInfo(_ti('enterprise_crm.message.request_sync_success'));
  //   } catch (error) {
  //     this.crmSyncRoom = null;
  //     this.toast.showError(_ti('enterprise_crm.message.request_sync_failure'));
  //   }
  //   this.updateSynchronizationState();
  // }

  addOauthAccount(): void {
    if (this.enterpriseCrm.type === CrmType.ZENDESK && !this.enterpriseCrm.url) {
      this.toast.showError(_ti('enterprise_crm.validators.url_cannot_blank'));
      this.urlInput.nativeElement.focus();
      return;
    }
    window.location.href = FlexIvrSettings.getInstance().getAbsoluteUrl(
      `/ws-v2/d/${this.authService.getEnterpriseDomain()}/crm/oauth-redirect/${this.enterpriseCrm.public_id}`
    );
  }

  private updateSynchronizationState(): void {
    if (!this.enterpriseCrm.sync_state && !this.enterpriseCrm.is_syncing) {
      this.syncState = 'waiting';
      this.syncMessage = 'enterprise_crm.message.sync_sate.waiting';
      return;
    }

    if (!this.enterpriseCrm.sync_state && this.enterpriseCrm.is_syncing) {
      this.syncState = 'in_progress';
      this.syncMessage = 'enterprise_crm.message.sync_sate.in_progress';
      return;
    }

    if (this.enterpriseCrm) {
      this.syncState = 'synchronized';
      this.syncMessage = 'enterprise_crm.message.sync_sate.synchronized';
      return;
    }
  }

  private getFormResetData(): any {
    return {
      name: this.enterpriseCrm.name,
      url: this.enterpriseCrm.url,
      callstart_url: this.enterpriseCrm.callstart_url,
      callend_url: this.enterpriseCrm.callend_url,
      username: this.enterpriseCrm.username,
      password: this.enterpriseCrm.password,
      apikey: this.enterpriseCrm.apikey,
      autoRunTask: this.enterpriseCrm.auto_run_task,
      mailbox_id: this.enterpriseCrm.mailbox_id,
      hotel_id: this.enterpriseCrm.hotel_id,
      cashier_id: this.enterpriseCrm.cashier_id,
      client_id: this.enterpriseCrm.oh_client_id,
      client_secret: this.enterpriseCrm.oh_client_secret,
      transaction_code: this.enterpriseCrm.transaction_code,
      createContactInboundCall: this.enterpriseCrm.create_contact_inbound_call,
      log_state_outbound: this.enterpriseCrm.log_state_outbound,
      log_state_outbound_text: this.enterpriseCrm.log_state_outbound_text,
      log_state_inbound: this.enterpriseCrm.log_state_inbound,
      log_state_inbound_text: this.enterpriseCrm.log_state_inbound_text,
      log_state_missed: this.enterpriseCrm.log_state_missed,
      log_state_missed_text: this.enterpriseCrm.log_state_missed_text,
      log_call_to: this.enterpriseCrm.log_call_to,
    };
  }

  async changeActive(ob: MatSlideToggleChange): Promise<any> {
    let newIsActive = 0;

    if (ob.checked) {
      newIsActive = 1;
    }

    const updatedEnterpriseCrm = this.enterpriseCrmRepo.create();
    updatedEnterpriseCrm.setObjectData({ id: this.enterpriseCrm.getId(), is_active: newIsActive });

    try {
      await this.enterpriseCrmRepo.saveAttrs(updatedEnterpriseCrm, ['is_active']);
      this.enterpriseCrmRepo.reload();
      this.toast.show(_ti('public.message.update_success'));
    } catch (e) {
      console.log('Error: ', e);
      this.toast.showError(_ti('public.message.update_failure'));
    }
  }

  private validateForm(): boolean {
    const type: CrmType = this.enterpriseCrm.type as CrmType;

    const validation = {
      name: [Validators.required, Validators.maxLength(128), NoWhitespaceValidator],
      url: null, // [Validators.required, UrlValidator, NoWhitespaceValidator],
      callstart_url: null, // [UrlValidator, NoWhitespaceValidator],
      callend_url: null, // [UrlValidator, NoWhitespaceValidator],
      username: null, // [Validators.required, NoWhitespaceValidator],
      password: null, // [Validators.required],
      apikey: null, // [NotContainSpace()],
      autoRunTask: null,
      mailbox_id: null,
      hotel_id: null,
      cashier_id: null,
      transaction_code: null,
      client_id: null,
      client_secret: null,
      createContactInboundCall: null,
      log_state_outbound: null, // [Validators.required],
      log_state_outbound_text: null, // [Validators.required],
      log_state_inbound: null, // [Validators.required],
      log_state_inbound_text: null, // [Validators.required],
      log_state_missed: null, // [Validators.required],
      log_state_missed_text: null, // [Validators.required],
      log_call_to: null,
    };

    switch (type) {
      case CrmType.ORACLEHOSPITALITY:
        _.extend(validation, {
          hotel_id: [Validators.maxLength(512), Validators.required, NotContainSpace()],
          client_id: [Validators.maxLength(512), Validators.required, NotContainSpace()],
          client_secret: [Validators.maxLength(512), Validators.required, NotContainSpace()],
          cashier_id: [],
          transaction_code: [],
          url: [Validators.maxLength(512), Validators.required, UrlValidator, NoWhitespaceValidator],
          username: [Validators.maxLength(64), Validators.required, NoWhitespaceValidator],
          password: [Validators.maxLength(64), Validators.required],
        });
        break;

      case CrmType.CUSTOMCRM:
        _.extend(validation, {
          apikey: [Validators.maxLength(256), Validators.required, NotContainSpace()],
          url: [Validators.maxLength(512), Validators.required, UrlValidator, NoWhitespaceValidator],
          callstart_url: [Validators.maxLength(512), UrlValidator, NoWhitespaceValidator],
          callend_url: [Validators.maxLength(512), UrlValidator, NoWhitespaceValidator],
        });
        break;

      case CrmType.FRESHDESK:
      case CrmType.VTIGER:
        _.extend(validation, {
          apikey: [Validators.maxLength(256), Validators.required, NotContainSpace()],
          url: [Validators.maxLength(512), Validators.required, UrlValidator, NoWhitespaceValidator],
          username: [Validators.maxLength(64), Validators.required, NoWhitespaceValidator],
          log_state_outbound: [Validators.required],
          log_state_inbound: [Validators.required],
          log_state_missed: [Validators.required],
        });
        break;

      case CrmType.ZENDESK:
      case CrmType.SHOPIFY:
      case CrmType.DYNAMICS:
        _.extend(validation, {
          url: [Validators.required, UrlValidator, NoWhitespaceValidator],
        });
        break;

      default:
        break;
    }

    for (const key of Object.keys(this.form.controls)) {
      this.form.get(key).clearValidators();

      if (validation[key]) {
        // formGroup[key][1] = validation[key];
        this.form.get(key).setValidators(validation[key]);
        this.form.get(key).updateValueAndValidity();
      }
    }

    // Set custom text validation
    const customStates = [
      'log_state_outbound',
      'log_state_inbound',
      'log_state_missed'
    ];
    for (const state of customStates) {
      if (this.form.get(state).value === CrmCallLogState.LOG_CUSTOM) {
        this.form.get(`${state}_text`).setValidators([Validators.required, NoWhitespaceValidator]);
        this.form.get(`${state}_text`).updateValueAndValidity();
      }
    }

    return this.form.valid;
  }

  // private validateForm(): boolean {
  //   this.updateFormValidation(this.enterpriseCrm.type as CrmType);

  // if (this.enterpriseCrm.type === CrmType.CUSTOMCRM) {
  //   // Set field apikey is required with enterprise crm has type is not customcrm
  //   this.form.get('apikey').clearValidators();
  //   this.form.get('apikey').setValidators([NotContainSpace()]);

  //   if (
  //     this.form.get('name').invalid ||
  //     this.form.get('url').invalid ||
  //     this.form.get('callstart_url').invalid ||
  //     this.form.get('callend_url').invalid ||
  //     this.form.get('apikey').invalid
  //   ) {
  //     return false;
  //   }

  //   return true;
  // }

  // this.form.get('hotel_id').clearValidators();
  // if (this.enterpriseCrm.type === CrmType.ORACLEHOSPITALITY) {
  //   this.form.get('hotel_id').setValidators([Validators.required, NotContainSpace()]);
  //   this.form.get('hotel_id').updateValueAndValidity();

  //   // TODO: Optimize later
  //   if (this.form.invalid) {
  //     console.error('Form is invalid', this.form);
  //     return false;
  //   }
  // }

  // this.form.get('apikey').clearValidators();
  // if (!_.includes([
  //   CrmType.ORACLEHOSPITALITY,
  //   CrmType.CUSTOMCRM
  // ], this.enterpriseCrm.type)) {
  //   // Set field apikey is required with enterprise crm has type is not customcrm
  //   this.form.get('apikey').setValidators([Validators.required, NotContainSpace()]);
  //   this.form.get('apikey').updateValueAndValidity();
  // }

  // if (
  //   !this.enterpriseCrm.is_authenticated &&
  //   (this.enterpriseCrm.type === CrmType.ZENDESK || this.enterpriseCrm.type === CrmType.SHOPIFY) &&
  //   this.form.get('url').invalid
  // ) {
  //   return false;
  // }

  // if (this.enterpriseCrm.is_authenticated || this.enterpriseCrm.auth_method !== 'oauth') {
  //   if (this.form.get('name').invalid) {
  //     return false;
  //   }

  //   if (
  //     this.authMethod === 'apikey' &&
  //     (this.form.get('username').invalid || this.form.get('apikey').invalid)
  //   ) {
  //     return false;
  //   }

  //   if ((
  //     this.enterpriseCrm.type === CrmType.ZENDESK ||
  //     this.enterpriseCrm.type === CrmType.FRESHDESK ||
  //     this.enterpriseCrm.type === CrmType.VTIGER
  //   ) && this.form.get('url').invalid) {
  //     return false;
  //   }

  //   if (
  //     this.form.get('log_state_outbound').invalid ||
  //     this.form.get('log_state_inbound').invalid ||
  //     this.form.get('log_state_missed').invalid
  //   ) {
  //     return false;
  //   }
  // }

  // return true;
  // return this.form.valid;
  // }

  async submitForm(): Promise<void> {
    if (!this.validateForm()) {
      this.form.markAllAsTouched();
      this.toast.showError(_ti('public.message.data_invalid'));
      return;
    }

    const updatedEnterpriseCrm = this.enterpriseCrmRepo.create();
    updatedEnterpriseCrm.setObjectData({
      id: this.enterpriseCrm.getId(),
      name: this.form.get('name').value,
      url: this.form.get('url').value,
      callstart_url: this.form.get('callstart_url').value,
      callend_url: this.form.get('callend_url').value,
      username: this.form.get('username').value,
      password: this.form.get('password').value,
      apikey: this.form.get('apikey').value,
      hotel_id: this.form.get('hotel_id').value,
      client_id: this.form.get('client_id').value,
      client_secret: this.form.get('client_secret').value,
      cashier_id: this.form.get('cashier_id').value,
      transaction_code: this.form.get('transaction_code').value,
      auto_run_task: this.form.get('autoRunTask').value ? 1 : 0,
      mailbox_id: this.form.get('mailbox_id').value,
      create_contact_inbound_call: this.form.get('createContactInboundCall').value ? 1 : 0,
      log_state_outbound: this.form.get('log_state_outbound').value,
      log_state_outbound_text: this.form.get('log_state_outbound_text').value,
      log_state_inbound: this.form.get('log_state_inbound').value,
      log_state_inbound_text: this.form.get('log_state_inbound_text').value,
      log_state_missed: this.form.get('log_state_missed').value,
      log_state_missed_text: this.form.get('log_state_missed_text').value,
      log_call_to: this.form.get('log_call_to').value,
    });

    try {
      const attrList = [
        'name',
        'type',
        'url',
        'callstart_url',
        'callend_url',
        'username',
        'password',
        'apikey',
        'auto_run_task',
        'mailbox_id',
        'hotel_id',
        'client_id',
        'client_secret',
        'cashier_id',
        'transaction_code',
        'create_contact_inbound_call',
        'log_state_outbound',
        'log_state_outbound_text',
        'log_state_inbound',
        'log_state_inbound_text',
        'log_state_missed',
        'log_state_missed_text',
        'log_call_to',
      ];
      const returnedEnterpriseCrm = await this.enterpriseCrmRepo.saveAttrs(updatedEnterpriseCrm, attrList);
      this.toast.show(_ti('public.message.update_success'));
      // this.enterpriseCrm.setObjectData(returnedEnterpriseCrm);
      // this.enterpriseCrmRepo.reload();
      this.enterpriseCrm = this.enterpriseCrmRepo.getObjectById(returnedEnterpriseCrm.id);
      this.getOHList();
      this.resetForm();
    } catch (error) {
      console.log('Error: ', error);
      const msg: string = error.message || _ti('public.message.update_failure');
      this.toast.showError(msg);
    }
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  removeLogState($event, logStateElem: string): void {
    $event.stopPropagation();
    const emptyValue = this.callLogStateList.find(x => x.value === CrmCallLogState.DO_NOTHING) ? CrmCallLogState.DO_NOTHING : null;
    this.form.get(logStateElem).markAsDirty();
    this.form.get(logStateElem).setValue(emptyValue);
  }

  // removeLogStateOutbound(): void {
  //   this.form.get('log_state_outbound').markAsDirty();
  //   this.form.get('log_state_outbound').setValue(null);
  // }

  // removeLogStateInbound(): void {
  //   this.form.get('log_state_inbound').markAsDirty();
  //   this.form.get('log_state_inbound').setValue(null);
  // }

  // removeLogStateMissed(): void {
  //   this.form.get('log_state_missed').markAsDirty();
  //   this.form.get('log_state_missed').setValue(null);
  // }

  importUsers(): void {
    const modalConfig: IFlexDialogConfig = {
      size: 'fs',
      data: { crmId: this.enterpriseCrm.id, showIgnoreStep: false },
      padding: false
    };

    this.dialogService.openDialog2(ImportUsersFromCrmModal, modalConfig);
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  async verifyCustomCrmConfig(): Promise<void> {
    const apikey = this.form.get('apikey').value;
    const urlControl = this.form.get('url');
    const url = urlControl.value;

    urlControl.markAsTouched();
    if (urlControl.invalid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      const ret: { error?: string[], responseData: any } = await this.enterpriseCrmService.verifyCustomCrmConfig(url, '0601020304', '0186534900', apikey);

      const respData: TestUrlServiceResultDataType = {
        errors: ret && ret.error || [],
        responseData: ret && ret.responseData
      };
      this.showVerifyUrlResult(respData);

      // if (_.isEmpty(ret) || !_.isEmpty(errors)) {
      //   throw new Error(errors[0] || _ti('crm.customcrm.configuration_incorrect'));
      // }

      // console.log('Verify config custom CRM response data', ret.responseData);

      // this.toast.showSuccess(_ti('crm.customcrm.test_success'));
    } catch (error) {
      console.error('CRM configuration error', error);
      let errorMSG = _ti('crm.customcrm.test_failure');
      if (error) {
        if (error.message) {
          errorMSG = error.message;
        } else if (error.error && error.error.message) {
          errorMSG = error.error.message;
        }
      }

      const respData: TestUrlServiceResultDataType = {
        errors: [errorMSG],
        responseData: error
      };
      this.showVerifyUrlResult(respData);

      // const msg = e && e.message || _ti('crm.customcrm.test_failure');
      // this.toast.showError(msg);
    }
  }

  private showVerifyUrlResult(data: TestUrlServiceResultDataType): void {
    this.dialogService.openDialog2(TestUrlServiceResultModalComponent, {
      data,
      size: 's'
    });
  }

  async reloadMailboxes(): Promise<void> {
    try {
      const mailboxes = await this.enterpriseCrmService.reloadMailboxes(this.enterpriseCrm.id);
      this.mailboxes = mailboxes;
      this.toast.showSuccess(_ti('public.message.update_success'));
    } catch (error) {
      let errorMSG = _ti('public.message.error_occurred');
      if (error) {
        if (error.message) {
          errorMSG = error.message;
        } else if (error.error && error.error.message) {
          errorMSG = error.error.message;
        }
      }
      this.toast.showError(errorMSG);
    }
  }
}
