<div class="pa-1">
  <form [formGroup]="form">
    <div fxFlexLayout="column">
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'ivrremoteapp.content.name' | translate}}</mat-label>
          <input matInput placeholder="{{'ivrremoteapp.content.name' | translate}}" formControlName="name">

          <mat-error [flexFormControlError]="form.get('name')"
            [customMessages]="{
              'name_exist': 'public.message.name_exist' | translate
            }"></mat-error>
        </mat-form-field>
      </div>
      
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'ivrremoteapp.content.alias' | translate}}</mat-label>
          <input matInput placeholder="{{'ivrremoteapp.content.alias' | translate}}" formControlName="alias">
          
          <mat-error [flexFormControlError]="form.get('alias')"></mat-error>
        </mat-form-field>
      </div>
      
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'ivrremoteapp.content.url' | translate}}</mat-label>
          <input matInput placeholder="{{'ivrremoteapp.content.url' | translate}}" formControlName="url">
          
          <mat-error [flexFormControlError]="form.get('url')"></mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
