import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService, DialogService, HttpClient } from '@wephone-utils';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { UserEntity } from '@wephone-core/model/entity/user';
import { Router } from '@angular/router';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { FormBuilder, Validators } from '@angular/forms';
import { _tk, _ti } from '@wephone-translation';
import {
  ChangeSoundModal,
  Text2SpeechLangItem,
  Text2SpeechDataService,
  Text2SpeechVoiceItem,
} from '@wephone-audio';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { DidEntity } from '@wephone-core/model/entity/did';
import * as _ from 'lodash';
import { ReusableSoundService } from '@wephone/services/reusable-sound.service';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';

@Component({
  selector: 'reusable-sound-edit',
  templateUrl: './reusable-sound-edit.component.html',
  styleUrls: ['./reusable-sound-edit.component.scss']
})
export class ReusableSoundEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: FileEntryEntity;
  loading = true;
  fileEntry: FileEntryEntity;
  newFileEntry: FileEntryEntity;
  inUsedObjects: any = {};
  currentLang = {
    region: undefined,
    value: 'default',
    text: 'default',
  };
  ttsLang: string;
  ttsVoice: string;
  readonly steps = {
    characteristic: 'reusable_sound.content.characteristic',
    inused: 'reusable_sound.content.using',
  };

  private readonly fileEntryRepo: FileEntryRepository;
  isSubmit = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly toast: ToastService,
    private readonly fileEntryService: FileEntryService,
    private readonly ttsDataService: Text2SpeechDataService,
    private readonly httpClient: HttpClient,
    private readonly dialogService: DialogService,
    private readonly em: EntityManager,
    private readonly reusableSoundService: ReusableSoundService,
  ) {
    super();
    this.fileEntryRepo = em.getRepository<FileEntryRepository>('FileEntryRepository');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.setFileEntry();

    this.form = this.fb.group({
      name: [this.fileEntry.name, [Validators.required, Validators.maxLength(255)]],
      alias: [this.fileEntry.alias, [Validators.maxLength(128)]],
      replacedPublicId: [undefined]
    });

    this.form.valueChanges.subscribe((changes: any) => {
      this.onFormValueChange();
    });

    try {
      await this.em.getRepository('EnterpriseCrmRepository').findAll();
      this.inUsedObjects = await this.fileEntryService.getObjectsUsedFileEntryByIds([this.fileEntry.id]);

      this.loading = false;
    } catch (error) {
      console.error('get object error', error);
    } finally {
      this.loading = false;
    }
  }

  formHasChanged(): boolean {
    return !this.isSubmit && this.form && this.form.dirty;
  }

  get noObjectUsing(): boolean {
    return _.isEqual(this.inUsedObjects, {});
  }

  get stepKeys(): string[] {
    return Object.keys(this.steps);
  }

  gotoPageUser(user: UserEntity): void {
    this.router.navigate(['manage-users', 'users', user.id], { skipLocationChange: false });
  }

  gotoPageDid(did: DidEntity): void {
    this.router.navigate(['number-routing', did.id], { skipLocationChange: false });
  }

  gotoPageIvrMenu(ivrmenu: IvrCustomMenuEntity): void {
    this.router.navigate(['ivr', 'ivr-menus', ivrmenu.id], { skipLocationChange: false });
  }

  gotoPageCrmRoutingRule(crmRoutingRule: CrmRoutingRuleEntity): void {
    this.router.navigate(['crm', 'crm-routing', crmRoutingRule.id], { skipLocationChange: false });
  }

  gotoPageQueue(queue: CallQueueEntity): void {
    this.router.navigate(['queues', queue.id], { skipLocationChange: false });
  }

  gotoPageIvrEvaluation(ivrEvaluation: IvrEvaluationEntity): void {
    this.router.navigate(['ivr', 'ivr-evaluation', ivrEvaluation.id], { skipLocationChange: false });
  }

  gotoPageCalendar(calenar: OpeningHourCalendarEntity): void {
    this.router.navigate(['callcenter-params', 'opening-hour', calenar.id], { skipLocationChange: false });
  }

  gotoPageEnterpriseInfo(): void {
    this.router.navigate(['enterprise', 'enterprise-info'], { skipLocationChange: false });
  }

  // isUsedFileEntry(queue: CallQueueEntity): boolean {
  //   return (
  //     queue &&
  //     [
  //       queue.greeting_file_id,
  //       queue.waiting_music_file_id,
  //       queue.moh_file_id,
  //       queue.opening_hour_file_id,
  //       queue.no_agent_file_id
  //     ].indexOf(this.fileEntry.id) > -1
  //   ) ? true : false;
  // }

  editFileEntry(fileEntry: FileEntryEntity): void {
    this.openDialogChangeSound(fileEntry, this.currentLang.value);
  }

  openDialogChangeSound(fileEntry: FileEntryEntity, lang: string): void {
    const data = {
      fileEntry,
      lang,
      selectExisting: false,
      showInputName: false,
      markFileAsTemp: true,
    };

    this.dialogService.openSideDialog(ChangeSoundModal, { data, size: 's' }, (data_response: any) => {
      if (data_response) {
        const newFileEntry = data_response.file_entry;
        console.log('newFileEntry', newFileEntry);
        if (!newFileEntry || !fileEntry ||
          !newFileEntry.id || !fileEntry.id ||
          newFileEntry.id === fileEntry.id
        ) {
          console.log('No entry was replaced');
          return;
        }

        this.form.get('replacedPublicId').setValue(newFileEntry.public_id);
        this.form.get('replacedPublicId').markAsDirty();
        this.form.updateValueAndValidity();
      }
    });
  }

  async downloadEntry(fileEntry: FileEntryEntity): Promise<any> {
    const url = this.fileEntryService.getFileUrl(fileEntry.public_id, true);
    const fileName = `fileentry-${fileEntry.name}-${fileEntry.public_id}.mp3`;

    const data = await this.httpClient
      .get(FlexIvrSettings.getInstance().getAbsoluteUrl(url), { responseType: 'blob' })
      .toPromise();

    this.downloadAudio(data, fileName);
  }

  private downloadAudio(data, fileName: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    // remove the element
    a.remove();
  }

  setFileEntry(): void {
    this.fileEntry = _.cloneDeep(this.editingItem);
    if (this.fileEntry.tts_data) {
      this.ttsDataService.listGGCloudVoices().then(() => {
        const ttsLang: Text2SpeechLangItem = this.ttsDataService.getLangByValue(this.fileEntry.tts_data.lang);
        const ttsVoice: Text2SpeechVoiceItem = this.ttsDataService.getVoiceByValue(this.fileEntry.tts_data.lang, this.fileEntry.tts_data.voice);

        this.ttsLang = ttsLang.label;
        this.ttsVoice = ttsVoice.label;
      });
    }
  }

  private getFormResetData(): Object {
    this.setFileEntry();
    return {
      name: this.fileEntry.name,
      alias: this.fileEntry.alias,
      replacedPublicId: undefined,
    };
  }

  async submitForm(): Promise<any> {
    this.isSubmit = true;
    this.onFormValueChange();
    if (this.form.invalid) {
      this.toast.showError(_ti('form.validator.data_invalid'));
      return undefined;
    }

    try {
      const params: any = {
        name: this.form.get('name').value,
        alias: this.form.get('alias').value,
        is_temp: 0,
      };

      if (this.form.get('replacedPublicId').value) {
        params.replaced_file_entry = {
          public_id: this.form.get('replacedPublicId').value,
          lang: 'default'
        };
      }
      const returnedObj = await this.fileEntryService.updateFileEntry(this.fileEntry.public_id, params);
      if (!returnedObj) {
        throw new Error('Cannot update');
      }
      this.fileEntry = this.fileEntryRepo.getObjectById(+returnedObj.id);

      this.isSubmit = false;
      this.toast.show(_ti('public.message.update_success'));
      this.resetForm();
    } catch (error) {
      let msg = _ti('public.message.update_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.isSubmit = false;
      this.onFormValueChange();
      this.toast.showError(msg);
    }
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  async deleteEntry(): Promise<any> {
    return this.reusableSoundService.bulkDelete([this.fileEntry], () => {
      this.close();
    });
  }
}
