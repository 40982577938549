import { Component, Input, OnChanges, Output, ViewChild, EventEmitter } from '@angular/core';
import { AvailableAgentsComponent } from '../available-agents/available-agents';

@Component({
  selector: 'agent-wrapper',
  templateUrl: './agent-wrapper.html',
  styleUrls: ['./agent-wrapper.scss']
})
export class AgentWrapperComponent extends AvailableAgentsComponent implements OnChanges {
  @Input() header: string;
  @Input() headerStyle?: any;
  @Output() readonly onTotalUpdated: EventEmitter<any> = new EventEmitter();

  totalAgents: number;
  displayContent = true;
  @ViewChild('listAgents') readonly listAgents: AvailableAgentsComponent;

  private initilized = false;

  ngOnChanges(): void {
    if (this.initilized) {
      this.setTotalAgent();
    }
  }

  async resolveData(): Promise<void> {
    super.resolveData();

    if (!this.headerStyle) {
      this.headerStyle = {};
    }
    this.setTotalAgent();
    this.initilized = true;
  }

  private setTotalAgent(): void {
    setTimeout(() => {
      this.totalAgents = this.listAgents && this.listAgents.count_agents || 0;
      if (this.onTotalUpdated) {
        this.onTotalUpdated.emit({ total: this.totalAgents });
      }
    });
  }
  // get totalAgents(): number {
  //   return this.listAgents && this.listAgents.count_agents || 0;
  // }

  onChangeAgentList($event): void {
    this.setTotalAgent();
  }
}
