<flex-table #flexTable fxFill fxFlex [dataSource]="dataSource" [tableConfig]="tableConfig"
    [columnTemplates]="{action_dt: tplActionDate, detail: tplDetail, object: tplObject}">
    <ng-template #tplActionDate let-item="row">
        {{ item.action_dt|timeFormatDateTimeShort }}
    </ng-template>
    <ng-template #tplObject let-item="row">
        {{ item.object | displayPhoneNumber }}
    </ng-template>
    <ng-template #tplDetail let-item="row">
        <div class="action-detail">
            {{ item.detail }}
        </div>
    </ng-template>
</flex-table>
