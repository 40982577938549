<form [formGroup]="form">
    <div fxFill fxLayout="column">
        <label class="pb-1">{{ 'agent_panel.content.agent_available_for'|translate }}</label>

        <mat-radio-group formControlName="inout_state">
            <div *ngFor="let state of inOutStateList">
                <mat-radio-button [value]="state">
                    {{ ('agent_panel.content.inout_state_' + state) | translate }}
                </mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
</form>
