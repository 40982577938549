import { Injectable } from '@angular/core';

import { ConfigManager } from '@wephone-core/service/config_manager';
import { WsRpcService } from '@wephone-core/service/wsrpc_service';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { MessageService, UIBlockerService } from '@wephone-utils';
import { PushNotificationManager } from '@wephone-core/service/push_notification';
import { RepositoryService } from '@wephone-core/service/repository_service';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { Deferred } from 'ts-deferred';
import { Resolve } from '@angular/router';
import { IAuthSuccessInfo } from '@wephone-core/service/authentication.i';

@Injectable()
export class AppConfigService implements Resolve<any> {
  private dConfigReady: Deferred<boolean> = new Deferred();

  constructor(
    private wsRpcService: WsRpcService,
    private em: EntityManager,
    private messageService: MessageService,
    private repoService: RepositoryService,
    private uiBlock: UIBlockerService
  ) {
    this.em.setupRepositories(this.repoService.getRepositoryList());
    this.messageService.subscribe('auth:login:success',
      (authInfo: IAuthSuccessInfo) => {
        return this.initialize().then(config => {
          this.messageService.broadcast('application:login:success', authInfo.returnUrl);
      });
    });
  }

  async initialize(): Promise<void> {
    this.uiBlock.block('appconfig.service.initialize');
    try {
      await ConfigManager.getInstance().reloadConfig();
      await this.wsRpcService.connect();
      await this.em.waitForPushedConfig();
    } catch (error) {
      console.error('Initialize error', error);
    } finally {
      this.uiBlock.unblock('appconfig.service.initialize');
    }
    this.dConfigReady.resolve(true);
  }

  resolve(): Promise<boolean> {
    return this.dConfigReady.promise;
  }
}
