import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CallingProfileEntity } from '@wephone-core/model/entity/callingprofile';
import { BoType, CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { QueueTransferNumberEntity } from '@wephone-core/model/entity/queue_transfernumber';
import { OneRequired, PhoneNumberValidated } from '@wephone/services/form-validator';
import { ConfigManager, EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import {
  EditingComponent,
  FormService,
  IChangeAwareComponent,
  joiValidator,
  ToastService
} from '@wephone-utils';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { _ti } from '@wephone-translation';
import { IRoutingAppDestinationData, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CallDestinationComponent } from '@wephone/components/call-destination/call-destination.component';

@Component({
  selector: 'queue-edit-general',
  templateUrl: './queue-edit-general.component.html',
  styleUrls: ['./queue-edit-general.component.scss']
})
export class QueueEditGeneralComponent extends EditingComponent implements OnInit, IChangeAwareComponent {
  @Input() queue: CallQueueEntity;
  @Input() multipleEdit?: boolean;
  @Output() readonly formValueChanges: EventEmitter<boolean>;

  @ViewChild('agendaClosedConfigDestination') agendaClosedConfigDestination: CallDestinationComponent;

  switchAssign: boolean;
  callingProfile: CallingProfileEntity;
  loadedCallingProfile = false;
  // afterCallTimeList: {
  //   name: string;
  //   value: number;
  // }[];
  afterCallPauseModeList: {
    name: string;
    value: number;
  }[];
  queueSoundSilentList: {
    name: string;
    value: number;
  }[];
  priorityList: {
    label: string;
    value: number;
  }[];

  agendaClosedConfigDestinationList: RoutingAppName[] = [
    RoutingAppName.callqueue,
    RoutingAppName.call_phone,
    RoutingAppName.call_phone_number,
    RoutingAppName.ivr_custom_menu,
    RoutingAppName.play_then_hangup,
    RoutingAppName.send_sms
  ];

  readonly isCallCenter: boolean;
  // readonly isEnterprise: boolean;
  readonly backOfficeType = BoType;

  showLangForm: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly toast: ToastService,
    private readonly configManager: ConfigManager,
    public formService: FormService,
    flexIvrSetting: FlexIvrSettings,
  ) {
    super();
    this.isCallCenter = flexIvrSetting.uiCallCenter();

    this.afterCallPauseModeList = [
      {
        name: 'call_queue.pause_modes.no_pause',
        value: 0
      },
      {
        name: 'call_queue.pause_modes.pause_both',
        value: 1
      },
      {
        name: 'call_queue.pause_modes.pause_inbound',
        value: 2
      },
      {
        name: 'call_queue.pause_modes.pause_outbound',
        value: 3
      }
    ];

    this.queueSoundSilentList = [
      {
        name: 'call_queue.silent_modes.play_greeting_waiting_sounds',
        value: 0
      },
      {
        name: 'call_queue.silent_modes.not_play_greeting_waiting_sounds',
        value: 1
      },
    ];

    this.priorityList = [
      {
        value: 0,
        label: 'call_queue.content.priority_level.very_high'
      },
      {
        value: 1,
        label: 'call_queue.content.priority_level.high'
      },
      {
        value: 2,
        label: 'call_queue.content.priority_level.normal'
      },
      {
        value: 3,
        label: 'call_queue.content.priority_level.low'
      },
      {
        value: 4,
        label: 'call_queue.content.priority_level.very_low'
      }
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.queue.max_inqueue_time) {
      this.queue.max_inqueue_time = 0;
    }
    this.initFormGroup();
  }

  hasQueueId(): boolean {
    return !!this.queue.id;
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  updateAgendaClosedConfig(data: IRoutingAppDestinationData): void {
    const formControl = this.form.get('agenda_closed_config');
    formControl.setValue(data);
    formControl.markAsDirty();
    formControl.markAsTouched();
    formControl.updateValueAndValidity();
  }

  private _getVoicemailByMailAddrsFormControls(mail_addrs: string[]): FormGroup[] {
    const voicemail_by_mail_addrs: FormGroup[] = [];
    for (const mail_addr of mail_addrs) {
      voicemail_by_mail_addrs.push(this.createVoicemailByMailAddr(mail_addr));
    }

    return voicemail_by_mail_addrs;
  }

  private _getTransferNumbersFormControls(transfer_numbers: QueueTransferNumberEntity[]): FormGroup[] {
    const ret: FormGroup[] = [];
    for (const transfer_number of transfer_numbers) {
      ret.push(this.createTransferNumber(transfer_number));
    }

    return ret;
  }

  initFormGroup(): void {
    const transfer_numbers = [];
    for (const transfer_number of _.cloneDeep(this.queue.transfer_numbers) || []) {
      transfer_numbers.push(this.createTransferNumber(transfer_number as QueueTransferNumberEntity));
    }

    const formGroup: any = {
      group: [this.queue.group],
      opening_calendar: [this.queue.opening_calendar],
      agenda_closed_config: [this.queue.agenda_closed_config],
      max_inqueue: [this.queue.max_inqueue_time, joiValidator(Joi.number().min(0).max(7200).required())],
      voicemail_enabled: [this.queue.voicemail_enabled],
      voicemail_by_mail_enabled: [this.queue.voicemail_by_mail_enabled],
      voicemail_in_attachement: [this.queue.voicemail_in_attachement],
      voicemail_by_mail_addrs: this.fb.array(
        this._getVoicemailByMailAddrsFormControls(_.cloneDeep(this.queue.voicemail_by_mail_addrs) || [])
      ),
      recording_mode: [this.queue.recording_mode],
      queue_priority: [this.queue.queue_priority, joiValidator(Joi.required())],
      silent: [this.queue.silent],
      // language: [LangeuageObjectType],
      play_entire_welcome: [this.queue.play_entire_welcome],
      eoc_ivr_menu: [this.queue.eoc_ivr_menu],
      aftercall_pause_mode: [this.queue.aftercall_pause_mode],
      after_call_time: [this.queue.after_call_time, [Validators.min(0), Validators.max(3600)]],
      has_qualification: [!!this.queue.has_qualification],
      qualification_required: [this.queue.qualification_required],
      in_qualification: [this.queue.in_qualification],
      out_qualification: [this.queue.out_qualification],
      bo_type: [this.queue.bo_type, joiValidator(Joi.required())],
      bo_url: [this.queue.bo_url],
      transfer_numbers: this.fb.array(
        this._getTransferNumbersFormControls(_.cloneDeep(this.queue.transfer_numbers) || [])
      ),
      opening_hour_file: [this.queue.opening_hour_file],
      no_agent_file: [this.queue.no_agent_file],
      moh_file: [this.queue.moh_file],
      greeting_file: [this.queue.greeting_file],
      waiting_music_file: [this.queue.waiting_music_file]
    };

    if (!this.multipleEdit) {
      formGroup.queue_name = [this.queue.queue_name, [Validators.required, Validators.maxLength(100)]];
    }

    this.form = this.fb.group(formGroup);

    this.setFormValidators();

    this.addSubscription(
      this.form.valueChanges.subscribe((value: any) => {
        const changedSets = this.getChangeSet();
        const changedSetKeys: string[] = Object.keys(changedSets);
        if (changedSetKeys.length === 1 && changedSetKeys[0] === 'opening_calendar') {
          const openingCalendar: OpeningHourCalendarEntity = changedSets[changedSetKeys[0]];
          if (
            (!openingCalendar && !this.queue.opening_calendar) ||
            (openingCalendar && this.queue.opening_calendar && openingCalendar.id === this.queue.opening_calendar.id)
          ) {
            console.log('No calendar properties changed');
            return;
          }
        }

        if (!this.hasQualificationControl.value) {
          this.resetQualificationValue();
        }
        this.setFormValidators();
        this.onFormValueChange();
      })
    );
  }

  private resetQualificationValue(): void {
    // Reset data for qualification
    if (this.qualificationRequiredControl.value) {
      this.qualificationRequiredControl.setValue(false);
    }
    this.inQualificationControl.setValue(this.queue.in_qualification, {
      onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true
    });
    this.outQualificationControl.setValue(this.queue.out_qualification, {
      onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true
    });
  }

  private setFormValidators(): void {
    this.form.clearValidators();
    if (this.hasQualificationControl.value) {
      this.form.setValidators(OneRequired(['in_qualification', 'out_qualification']));
    }
    if (this.isValidQualification()) {
      this.form.setErrors(null);
    }

    this.updateFormControlValidators();
  }

  private isValidQualification(): boolean {
    if (
      this.hasQualificationControl.value &&
      (!this.inQualificationControl.value || !this.inQualificationControl.value.id) &&
      (!this.outQualificationControl.value || !this.outQualificationControl.value.id)
    ) {
      return false;
    }

    return true;
  }

  private updateFormControlValidators(): void {
    // Must manually update for the qualification object because the dropdown list contains an empty item
    this.inQualificationControl.setErrors(null);
    this.outQualificationControl.setErrors(null);
    if (!this.isValidQualification()) {
      this.inQualificationControl.setErrors({ oneRequired: true });
      this.outQualificationControl.setErrors({ oneRequired: true });
    }
    this.inQualificationControl.markAsTouched();
    this.outQualificationControl.markAsTouched();

    this.form.get('agenda_closed_config').setErrors(null);
    if (this.agendaClosedConfigDestination && !this.agendaClosedConfigDestination.validParam()) {
      this.form.get('agenda_closed_config').setErrors({ invalid: true });
      this.form.get('agenda_closed_config').markAsTouched();
    }

    this.form.get('bo_url').clearValidators();
    const boUrl = this.form.get('bo_url').value && this.form.get('bo_url').value.trim();
    if (this.boTypeControl.value === this.backOfficeType.URL) {
      if (!boUrl) {
        this.form.get('bo_url').setValidators(Validators.required);
        this.form.get('bo_url').markAsTouched();
      }
    }
    if (this.boTypeControl.value !== this.backOfficeType.URL || boUrl) {
      this.form.get('bo_url').setErrors(null);
    }

    this.voicemailByMailAddrsControl.clearValidators();
    const mailAddrs = this.voicemailByMailAddrsControl.value;
    if (this.queueVoiMaileEabledControl.value && mailAddrs.length === 0) {
      this.voicemailByMailAddrsControl.setValidators(Validators.required);
      this.voicemailByMailAddrsControl.markAsTouched();
    }
    if (!this.queueVoiMaileEabledControl.value || mailAddrs.length > 0) {
      this.voicemailByMailAddrsControl.setErrors(null);
    }
  }

  get queueVoiMaileEabledControl(): FormControl {
    return this.form.get('voicemail_by_mail_enabled') as FormControl;
  }

  get queueVoiAttachementControl(): FormControl {
    return this.form.get('voicemail_in_attachement') as FormControl;
  }

  get hasQualificationControl(): FormControl {
    return this.form.get('has_qualification') as FormControl;
  }

  get qualificationRequiredControl(): FormControl {
    return this.form.get('qualification_required') as FormControl;
  }

  get inQualificationControl(): FormControl {
    return this.form.get('in_qualification') as FormControl;
  }

  get outQualificationControl(): FormControl {
    return this.form.get('out_qualification') as FormControl;
  }

  get boTypeControl(): FormControl {
    return this.form.get('bo_type') as FormControl;
  }

  get voicemailByMailAddrsControl(): FormArray {
    return this.form.get('voicemail_by_mail_addrs') as FormArray;
  }

  get transferNumbersControl(): FormArray {
    return this.form.get('transfer_numbers') as FormArray;
  }

  hasControlValue(controlName: string): boolean {
    return controlName && this.form && this.form.get(controlName).value ? true : false;
  }

  // Form actions
  removeControlValue(controlName: string): void {
    this.setFormControlValue(controlName, null);
  }

  async submitForm(): Promise<any> { }

  private getVoicemailByMailAddrsData(mail_attrs: string[]): { mail_addr: string }[] {
    const ret = [];
    for (const mail_addr of mail_attrs || []) {
      ret.push(this.createVoicemailByMailAddr(mail_addr).value);
    }

    return ret;
  }

  private getTransferNumberData(transfer_numbers: QueueTransferNumberEntity[]): any[] {
    const ret = [];
    for (const transfer_number of transfer_numbers || []) {
      ret.push(this.createTransferNumber(transfer_number).value);
    }

    return ret;
  }

  private getFormResetData(): object {
    return {
      queue_name: this.queue.queue_name,
      group: this.queue.group,
      opening_calendar: this.queue.opening_calendar,
      agenda_closed_config: this.queue.agenda_closed_config,
      max_inqueue: this.queue.max_inqueue_time,
      voicemail_enabled: this.queue.voicemail_enabled,
      voicemail_by_mail_enabled: this.queue.voicemail_by_mail_enabled,
      voicemail_in_attachement: this.queue.voicemail_in_attachement,
      voicemail_by_mail_addrs: this.getVoicemailByMailAddrsData(this.queue.voicemail_by_mail_addrs),
      recording_mode: this.queue.recording_mode,
      queue_priority: this.queue.queue_priority,
      silent: this.queue.silent,
      play_entire_welcome: this.queue.play_entire_welcome,
      eoc_ivr_menu: this.queue.eoc_ivr_menu,
      aftercall_pause_mode: this.queue.aftercall_pause_mode,
      after_call_time: this.queue.after_call_time,
      has_qualification: !!this.queue.has_qualification,
      qualification_required: this.queue.qualification_required,
      in_qualification: this.queue.in_qualification,
      out_qualification: this.queue.out_qualification,
      bo_type: this.queue.bo_type,
      bo_url: this.queue.bo_url,
      transfer_numbers: this.getTransferNumberData(_.cloneDeep(this.queue.transfer_numbers)),
      opening_hour_file: this.queue.opening_hour_file,
      no_agent_file: this.queue.no_agent_file,
      moh_file: this.queue.moh_file,
      greeting_file: this.queue.greeting_file,
      waiting_music_file: this.queue.waiting_music_file,
    };
  }

  private _resetVoicemailByMailAddrsControls(): void {
    while (this.voicemailByMailAddrsControl.length > 0) {
      this.voicemailByMailAddrsControl.removeAt(0);
    }

    const mailAddrs: string[] = this.queue.voicemail_by_mail_addrs || [];

    for (const mail_addr of mailAddrs) {
      this.voicemailByMailAddrsControl.push(this.createVoicemailByMailAddr(mail_addr));
    }
  }

  private _resetTransferNumbersControls(): void {
    while (this.transferNumbersControl.length > 0) {
      this.transferNumbersControl.removeAt(0);
    }

    const transferNumbers: QueueTransferNumberEntity[] = this.queue.transfer_numbers || [];

    for (const transfer_number of transferNumbers) {
      this.transferNumbersControl.push(this.createTransferNumber(transfer_number));
    }
  }

  resetForm(): void {
    if (!this.form) {
      return;
    }
    this._resetVoicemailByMailAddrsControls();
    this._resetTransferNumbersControls();

    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  checkValidEmail(emailValue: string): boolean {
    if (!emailValue || !emailValue.trim()) {
      return false;
    }
    const emailControl = new FormControl(emailValue, Validators.email);

    return !emailControl.hasError('email');
  }

  createVoicemailByMailAddr(mail_addr: string): FormGroup {
    return this.fb.group({
      mail_addr: [mail_addr]
    });
  }

  addVoicemailByMailAddr(): void {
    this.voicemailByMailAddrsControl.markAsDirty();
    this.voicemailByMailAddrsControl.push(this.createVoicemailByMailAddr(undefined));
    this.voicemailByMailAddrsControl.markAsTouched();
  }

  removeVoicemailByMailAddr(index: number): void {
    if (this.voicemailByMailAddrsControl.length === 1) {
      this.queueVoiMaileEabledControl.setValue(false);
      this.queueVoiAttachementControl.setValue(0);
    }

    this.voicemailByMailAddrsControl.markAsDirty();
    this.voicemailByMailAddrsControl.removeAt(index);
  }

  private getMappedFields(): Record<string, string> {
    return {
      greeting_file: 'greeting_file_id',
      waiting_music_file: 'waiting_music_file_id',
      moh_file: 'moh_file_id',
      opening_hour_file: 'opening_hour_file_id',
      no_agent_file: 'no_agent_file_id'
    };
  }

  getChangeSet(): any {
    const data = {};
    const mappedFields = this.getMappedFields();
    const formData = super.getChangeSet();
    for (const field of Object.keys(formData)) {
      const f: string = mappedFields.hasOwnProperty(field) && mappedFields[field] || field;
      switch (field) {
        case 'has_qualification':
          data[f] = formData[field] ? 1 : 0;
          break;
        case 'in_qualification':
        case 'out_qualification':
          // skip update qualification if has_qualification is disabled
          const hasQualification: boolean = formData.hasOwnProperty('has_qualification') && formData.has_qualification || !!this.queue.has_qualification;
          if (hasQualification) {
            data[f] = formData[f];
          }
          break;
        case 'greeting_file':
        case 'waiting_music_file':
        case 'moh_file':
        case 'opening_hour_file':
        case 'no_agent_file':
          data[f] = formData[field] ? formData[field].id : null;
          break;
        case 'max_inqueue':
          data[f] = +formData[field];
          break;
        default:
          data[f] = formData[field];
      }
    }
    return data;
    // if (formData.hasOwnProperty('greeting_file')) {
    //   formData['greeting_file_id'] = formData.greeting_file ? formData.greeting_file.id : null;
    //   delete formData.greeting_file;
    // }
    // if (formData.hasOwnProperty('waiting_music_file')) {
    //   formData['waiting_music_file_id'] = formData.waiting_music_file ? formData.waiting_music_file.id : null;
    //   delete formData.waiting_music_file;
    // }
    // if (formData.hasOwnProperty('moh_file')) {
    //   formData['moh_file_id'] = formData.moh_file ? formData.moh_file.id : null;
    //   delete formData.moh_file;
    // }
    // if (formData.hasOwnProperty('opening_hour_file')) {
    //   formData['opening_hour_file_id'] = formData.opening_hour_file ? formData.opening_hour_file.id : null;
    //   delete formData.opening_hour_file;
    // }
    // if (formData.hasOwnProperty('no_agent_file')) {
    //   formData['no_agent_file_id'] = formData.no_agent_file ? formData.no_agent_file.id : null;
    //   delete formData.no_agent_file;
    // }
    // if (formData.hasOwnProperty('max_inqueue')) {
    //   formData['max_inqueue'] = +formData.max_inqueue;
    // }
    // return formData;
  }

  // dialogQueueHistory(): void {
  //   this.dialogService.openDialog2(DialogQueueHistoryComponent, {
  //     data: {
  //       queue_ids: [ this.queue.id ]
  //     }
  //   });
  // }

  updateSoundFile(formControlName, uploadResult: { new_file_entry: FileEntryEntity; remove?: boolean }): void {
    const oldFileEntryMapping = {
      opening_hour_file: this.form.get('opening_hour_file').value,
      no_agent_file: this.form.get('no_agent_file').value,
      moh_file: this.form.get('moh_file').value,
      greeting_file: this.form.get('greeting_file').value,
      waiting_music_file: this.form.get('waiting_music_file').value
    };

    if (!oldFileEntryMapping.hasOwnProperty(formControlName)) {
      console.error('Form control name not found', formControlName);

      return;
    }

    const fileEntry: FileEntryEntity = uploadResult.new_file_entry; // Empty new_file_entry and remove is true mean remove sound

    if (!uploadResult.remove && (!fileEntry || !fileEntry.getId())) {
      console.error('No file entry created/updated');

      return;
    }

    // this.queue[formControlName] = fileEntry; // update queue for updateing template
    this.setFormControlValue(formControlName, fileEntry);
  }

  createTransferNumber(transfer_number: QueueTransferNumberEntity): FormGroup {
    return this.fb.group({
      id: [transfer_number ? transfer_number.id : undefined],
      label: [transfer_number ? transfer_number.label : undefined, joiValidator(Joi.string().trim().max(128).required())],
      phone_number: [
        transfer_number ? transfer_number.phone_number : undefined,
        [Validators.required, Validators.maxLength(20), PhoneNumberValidated()]
      ]
    });
  }

  addTransferNumber(): void {
    const new_transfer_number = this.em.getRepository(
      'QueueTransferNumberRepository'
    ).create() as QueueTransferNumberEntity;
    this.transferNumbersControl.markAsDirty();
    this.transferNumbersControl.push(this.createTransferNumber(new_transfer_number));
  }

  removeTransferNumber(index: number): void {
    this.transferNumbersControl.markAsDirty();
    this.transferNumbersControl.removeAt(index);
  }

  private setFormControlValue(formControlName, value): void {
    this.form.get(formControlName).markAsDirty();
    this.form.get(formControlName).setValue(value);
  }

  private showFormFieldError(groupControlList: any[], field_list: string[]): void {
    for (const controls of groupControlList) {
      // const controls = item;

      for (const field of Object.keys(controls)) {
        if (!controls.hasOwnProperty(field) || (!_.isEmpty(field_list) && !_.includes(field_list, field))) {
          continue;
        }

        const control = controls[field];
        if (control.invalid) {
          control.markAsTouched();
        }

        if (control instanceof FormArray) {
          const subGroupControlList = [];
          for (const subControl of control.controls as FormGroup[]) {
            subGroupControlList.push(subControl.controls);
          }
          this.showFormFieldError(subGroupControlList, field_list);
        }
      }
    }
  }

  isValid(field_list: string[] = []): boolean {
    try {
      // this.form.get('agenda_closed_config').setErrors(null);
      // if (this.agendaClosedConfigDestination && !this.agendaClosedConfigDestination.validParam()) {
      //   this.form.get('agenda_closed_config').setErrors({ invalid: true });
      //   this.form.get('agenda_closed_config').markAsTouched();
      // }

      // this.form.get('bo_url').setErrors(null);
      // if (this.boTypeControl.value === this.backOfficeType.URL) {
      //   if (!this.form.get('bo_url').value || !this.form.get('bo_url').value.trim()) {
      //     this.form.get('bo_url').setErrors({ required: true });
      //   }
      // }

      // this.voicemailByMailAddrsControl.setErrors(null);
      // const mailAddrs = this.voicemailByMailAddrsControl.value;
      // if (this.form.get('voicemail_by_mail_enabled').value && mailAddrs.length === 0) {
      //   this.voicemailByMailAddrsControl.setErrors({ required: true });
      // }

      if (!this.formIsValid()) {
        this.form.markAllAsTouched();
        console.error('Form validate error', this.form.errors);

        let fieldValid = true;
        // Check valid only field_list
        if (field_list && field_list.length) {
          for (const field of Object.keys(this.form.controls)) {
            if (field_list.indexOf(field) > -1 && this.form.controls[field].invalid) {
              fieldValid = false;
              break;
            }
          }
        }
        if (!fieldValid) {
          this.showFormFieldError([this.form.controls], field_list);
        }
        throw new Error('Data invalid');
      }

      return true;
    } catch (error) {
      console.error('Edit queue validate error', error);
      this.toast.showError(_ti('form.validator.data_invalid'));
      return false;
    }
  }
}
