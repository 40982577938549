import * as _ from 'lodash';

import { Component, OnInit, ElementRef, Optional, Self, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
import { FlexMatInputWrapper, TreeHelper } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';

@Component({
  selector: 'flex-select-queue-input',
  templateUrl: './flex-select-queue-input.html',
  styleUrls: ['./flex-select-queue-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectQueueInput }]
})
export class FlexSelectQueueInput extends FlexMatInputWrapper implements OnInit, OnChanges, MatFormFieldControl<any> {
  @Input() groupIds: number[];
  @Input() filteredIds: number[];
  @Input() queues: CallQueueEntity[];
  @Input() multiple: boolean;
  @Input() limitedByRole: boolean;
  @Input() showCheckAll: boolean;
  @Input() queueType: 'inbound' | 'outbound' | undefined;
  @ViewChild('flexSelectTreeInput') flexSelectTreeInput: FlexSelectTreeInput;

  private readonly queueRepo: CallQueueRepository;
  private _queues: CallQueueEntity[];
  private limitedQueueIds: number[];

  queueNodes: FlexSelectTreeNode[] = [];
  hasInitilized = false;
  myControl = new FormControl();

  constructor(
    elRef: ElementRef,
    @Optional() @Self() ngControl: NgControl,
    em: EntityManager,
    private readonly configManager: ConfigManager,
    private readonly authService: AuthenticationService,
  ) {
    super(ngControl, elRef);
    this.queueRepo = em.getRepository<CallQueueRepository>('CallQueueRepository');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.limitedQueueIds = await this.getLimitedQueueIds();

    this.loadQueues();
    this.hasInitilized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitilized) {
      return;
    }

    if ('groupIds' in changes) {
      this.loadQueues();
      const validIds: number[] = [];
      const ids: number[] = this.wrappedControl.value;
      for (const id of ids) {
        if (!_.isEmpty(this._queues) && !!this._queues.find(x => x.id === id) && !_.includes(validIds, id)) {
          validIds.push(id);
        }
      }
      this.writeValue(validIds);
    }
  }

  private async getLimitedQueueIds(): Promise<number[]> {
    const currentUser = this.authService.getUser();

    if (this.limitedByRole && !currentUser.isAdmin()) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      return myProfile.managed_queue_ids;
    }
  }

  protected loadQueues(): void {
    const inboundQueues: CallQueueEntity[] = this.queueRepo.getInboundQueues();

    const limitedGroupIds: number[] = !_.isEmpty(this.groupIds) && Array.isArray(this.groupIds) && this.groupIds || [];

    this._queues = inboundQueues;

    if (this.queues && Array.isArray(this.queues)) {
      this._queues = this.queues;
    }

    if (!_.isUndefined(this.limitedQueueIds)) {
      this._queues = this._queues.filter(x => _.includes(this.limitedQueueIds, x.id));
    }

    if (!_.isUndefined(this.filteredIds)) {
      this._queues = this._queues.filter(x => _.includes(this.filteredIds, x.id));
    }

    if (!_.isEmpty(limitedGroupIds)) {
      const queuesByGroup = this.getQueuesByGroupIds(limitedGroupIds);
      this._queues = this._queues.filter(q => _.includes(queuesByGroup, q));
    }

    const nodeList = this._queues.map(queue => {
      // let noteName: string = queue.queue_name;
      // if (this.showDidNumber && queue.default_did) {
      //   noteName = `${noteName} (${queue.default_did.display_number})`;
      //   // if (queue.default_did.country_code) {
      //   //   noteName = `<span class="flag-icon flag-icon-${queue.default_did.country_code.toLowerCase()}"></span> ${noteName}`;
      //   // }
      // }

      return {
        id: queue.id,
        // name: noteName,
        name: queue.queue_name,
        parent_id: undefined
      };
    });
    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.queueNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-queue-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }

  protected getQueuesByGroupIds(groupIds: number[]): CallQueueEntity[] {
    // return groupIds.reduce((queuesInGroup: CallQueueEntity[], groupId: number) => {
    //   const queues = this.queueRepo.get_queues_by_group_id(groupId, true, this.queueType, true);

    //   // return queuesInGroup.concat(queues);
    //   return queuesInGroup.concat(queues.filter(x => !_.includes(queuesInGroup, x)));
    // }, []);
    const ret: CallQueueEntity[] = [];
    for (const groupId of groupIds) {
      const queues: CallQueueEntity[] = this.queueRepo.get_queues_by_group_id(groupId, true, this.queueType, true);
      for (const q of queues) {
        if (!ret.find(i => i.id === q.id)) {
          ret.push(q);
        }
      }
    }
    return ret;
  }

  stripHtml(html: string): string {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
