import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { DynamicFilterSource, DialogService, IFlexTableConfig, IFlexTableSidePannelOptions, regexSearch, FlexCRUDPageComponent } from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { CreateGroupModalComponent } from '@wephone/modals/create-group-modal/create-group-modal.component';
import { GroupEditComponent } from '@wephone/components/group-edit/group-edit.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { PermissionService } from '@wephone/services/permission.service';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { GroupEntity } from '@wephone-core/model/entity/group';

@Component({
  selector: 'app-groups',
  host: { class: 'flex-page-component' },
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent extends FlexBasePage implements OnInit {
  private groupRepo = GroupRepository.getInstance() as GroupRepository;
  private userGroupRepo = UserGroupRepository.getInstance() as UserGroupRepository;

  groupForm: FormGroup;
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: GroupEditComponent
  };

  groupQueueMap: { [key: string]: CallQueueEntity[] } = {};
  isAdmin: boolean;

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthenticationService,
    private permissionService: PermissionService,
    private configManager: ConfigManager,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('group.content.name'),
          enableTooltip: true,
          lineClamp: 2,
          sortable: false
        },
        {
          name: 'users',
          label: _tk('group.content.username'),
          sortable: false,
          lineClamp: 2
        },
        {
          name: 'queues',
          label: _tk('group.content.call_queue'),
          sortable: false,
          enableTooltip: true,
          lineClamp: 2,
          renderCell: this.renderQueues
        }
      ],
      listActions: {
        defaultActions: [
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: (params: any) => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    if (this.isAdmin) {
      this.tableConfig.listActions.defaultActions = [
        {
          id: 'add',
          icon: 'add',
          callback: () => {
            return this.createNew();
          }
        }
      ];
    }

    const groupSource = this.groupRepo.dataSource<UserGroupEntity>();
    this.dataSource = new DynamicFilterSource(
      groupSource,
      this.filterFunc
    );

    this.dataSource.setOrder('path_name', 'asc');

    if (!this.isAdmin) {
      this.dataSource.setReadOnlyItems(EntityManager.getInstance().getRepository('UserGroupRepository').getObjectList());
    }

    this.groupForm = this.fb.group({
      filterValue: ['']
    });

    this.groupForm.valueChanges.subscribe(this.onFormValueChange);
    this.setGroupQueueMap();
  }

  protected async resolveData(): Promise<void> {
    this.isAdmin = this.authService.isAdmin();

    // if (!this.isAdmin) {
    //   const myProfile: MyUserProfile = await this.configManager.getMyprofile();
  
    //   const managedGroupIds: number[] = myProfile.managed_group_ids || [];
    //   // console.log('managedGroupIds', managedGroupIds);
  
    //   const readonlyItems: UserGroupEntity[] = EntityManager.getInstance().getRepository('UserGroupRepository')
    //     .getObjectList()
    //     .filter(x => !_.includes(managedGroupIds, x.id));
  
    //   // console.log('readonlyItems', readonlyItems.map(x => x.id));
  
    //   this.dataSource.setReadOnlyItems(readonlyItems);
    // }
  }

  private filterPredicate(group: UserGroupEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }

    return regexSearch(group.name, filterString);
  }

  private onFormValueChange = (formValues: any) => {
    this.filterValue = (formValues.filterValue || '').trim();

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  renderQueues = (item: UserGroupEntity, colName: string) => {
    if (_.isEmpty(this.groupQueueMap[item.id])) {
      this.setGroupQueueMap();
    }
    return this.groupQueueMap[item.id].map(q => q.queue_name).join(', ');
  }

  setGroupQueueMap(): void {
    const queues = CallQueueRepository.getInstance<CallQueueRepository>().getObjectList();
    for (const g of this.groupRepo.getObjectList()) {
      this.groupQueueMap[`${g.id}`] = queues.filter(q => !q.is_outbound_campaign && q.group_id === g.id && !q.is_archived);
    }
  }

  async createNew(): Promise<any> {
    const newGroup = this.userGroupRepo.create() as UserGroupEntity;
    const dialogRef = this.dialogService.openDialog2(CreateGroupModalComponent, {
      data: {
        group: newGroup
      }
    });

    const updateGroup = await dialogRef.afterClosed().toPromise();
    if (updateGroup) {
      this.gotoEditPage(updateGroup);
    }
  }

  bulkDelete(groups: UserGroupEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        try {
          if (_.isEmpty(groups)) {
            throw new Error('No selected groups');
          }

          await this.userGroupRepo.bulkDelete(groups);
          this.flexCrud.closeSidePanel();
          this.successToast(_ti('public.message.delete_success'));

        } catch (response) {
          console.error('error', response);
          const msg = response && response.message || response.error && response.error.message || _ti('public.message.update_failure');
          this.showError(msg);
        }
      }
    );
  }

  private readonly filterFunc = (item: UserGroupEntity): boolean => {
    if (item.group_type !== 'service') {
      return false;
    }

    if (!this.isAdmin && !this.permissionService.canViewGroup(item, true)) {
      return false;
    }

    return this.filterPredicate(item, this.filterValue);
  }

  renderNameCol(item: UserGroupEntity): string {
    const number_slash = (item.path && item.path.length) || 0;
    const name = Array(number_slash + 1).join('&mdash;') + item.name;

    return name;
  }

  gotoEditPage(group: UserGroupEntity): void {
    this.router.navigate(['groups', group.id], { skipLocationChange: false });
  }
}
