<div class="pa-1">
  <form [formGroup]="form" *ngIf="form">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'team_group.content.name' | translate }}</mat-label>
        <input matInput placeholder="{{'group.content.name' | translate}}" formControlName="name"
          [errorStateMatcher]="matcher">
  
        <mat-error [flexFormControlError]="form.get('name')"
          [customMessages]="{
            'duplicated': 'public.message.name_exist'|translate
          }"></mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="userteamList.length > 0">
      <mat-form-field class="full-width flex-select">
        <mat-label>{{'team_group.content.parent'|translate}}</mat-label>
        <flex-select-group-input [groups]="userteamList" groupType="team"
          placeholder="{{'team_group.content.parent'|translate}}" groupType="team" formControlName="parentId">
        </flex-select-group-input>
        <button mat-icon-button matSuffix type="button" (click)="clearParentValue($event)"
          [disabled]="!form.controls['parentId'].value">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'group.content.description' | translate }}</mat-label>
        <textarea matInput rows="2" placeholder="{{ 'group.content.description' | translate }}"
          formControlName="description"></textarea>

        <mat-error [flexFormControlError]="form.get('description')"></mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <group-user-list formControlName="users" [excludedUsers]="excludedUsers"
        [title]="'call_queue.content.operator_associated'|translate"></group-user-list>
    </div>
  </form>
</div>
