import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService } from '@wephone-utils';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { _tk, _ti } from '@wephone-translation';
import { UpdateSipTrunkCommon } from './update-siptrunk-common';
import { PhoneNumberOnlyDigitValidated, RequiredByEnableStatusValidator } from '@wephone/services/form-validator';
import { Router } from '@angular/router';
import { DidEntity } from '@wephone-core/model/entity/did';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'app-edit-siptrunk',
  templateUrl: './edit-siptrunk.component.html',
  styleUrls: ['./edit-siptrunk.component.scss']
})
export class EditSiptrunkComponent extends EditingComponent implements OnInit {
  @Input() editingItem: SipTrunkEntity;

  private readonly sipTrunkRepo = SipTrunkRepository.getInstance<SipTrunkRepository>();
  private readonly updateSipTrunkCommon: UpdateSipTrunkCommon = new UpdateSipTrunkCommon();
  sipTrunk: SipTrunkEntity;
  form: FormGroup;

  readonly steps = {
    _1general: 'siptrunk.form.general',
    _2sip_info: 'sipphone.content.sip_connection_info',
    _3inused: 'siptrunk.form.using',
  };

  uiSipTrunk: boolean = FlexIvrSettings.getInstance().uiSipTrunk();
  hidePassword = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.sipTrunk = this.editingItem;
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.sipTrunk.name, [Validators.required, Validators.maxLength(255)]],
      username: [this.sipTrunk.username, [Validators.required, Validators.maxLength(64)]],
      password: [this.sipTrunk.password, [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      proxy_host: [this.sipTrunk.proxy_host, [Validators.maxLength(255)]],
      proxy_port: [this.sipTrunk.proxy_port, [Validators.min(0), Validators.max(65535)]],
      secondary_proxy_host: [this.sipTrunk.secondary_proxy_host, [Validators.maxLength(255)]],
      secondary_proxy_port: [this.sipTrunk.secondary_proxy_port, [Validators.min(0), Validators.max(65535)]],
      secondary_proxy_mode: [this.sipTrunk.secondary_proxy_mode],
      outgoing_enabled: [this.sipTrunk.outgoing_enabled, [Validators.maxLength(255)]],
      alias: [this.sipTrunk.alias, [Validators.maxLength(255)]],
      backup_number: [this.sipTrunk.backup_number, [Validators.maxLength(20), PhoneNumberOnlyDigitValidated()]],
      backup_number_enable: [this.sipTrunk.backup_number_enable],
      sip_ping_enable: [this.sipTrunk.sip_ping_enable],
      channel_max: [this.sipTrunk.channel_max, [Validators.min(1), Validators.max(1000)]],
      is_default: [this.sipTrunk.is_default],
    }, {
      validators: RequiredByEnableStatusValidator('backup_number', 'backup_number_enable')
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(() => {
        this.onFormValueChange();
      })
    );

    this.addSubscription(
      this.form.get('backup_number_enable').valueChanges.subscribe((isEnabled: boolean) => {
        if (!isEnabled) {
          this.form.get('backup_number').setValue(this.sipTrunk.backup_number);
          this.form.get('backup_number').updateValueAndValidity();
        }
      })
    );
  }

  generatePassword(): void {
    this.updateSipTrunkCommon.generatePassword(this.form.get('password'), 12);
  }

  private getFormResetData(): { [key: string]: any } {
    return {
      name: this.sipTrunk.name,
      username: this.sipTrunk.username,
      password: this.sipTrunk.password,
      proxy_host: this.sipTrunk.proxy_host,
      proxy_port: this.sipTrunk.proxy_port,
      secondary_proxy_host: this.sipTrunk.secondary_proxy_host,
      secondary_proxy_port: this.sipTrunk.secondary_proxy_port,
      secondary_proxy_mode: this.sipTrunk.secondary_proxy_mode,
      outgoing_enabled: !!this.sipTrunk.outgoing_enabled,
      alias: this.sipTrunk.alias,
      backup_number: this.sipTrunk.backup_number,
      backup_number_enable: !!this.sipTrunk.backup_number_enable,
      sip_ping_enable: !!this.sipTrunk.sip_ping_enable,
      channel_max: this.sipTrunk.channel_max,
      is_default: !!this.sipTrunk.is_default
    };
  }

  getChangeSet(): any {
    const data: any = super.getChangeSet();
    return this.updateSipTrunkCommon.formDataTransform(data);
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (!this.formIsValid()) {
      this.toast.showError(_ti('form.validator.data_invalid'));
      return;
    }

    try {
      const formDataUpdated: any = this.getChangeSet();
      await this.sipTrunkRepo.saveAttrs(this.sipTrunk, Object.keys(formDataUpdated), formDataUpdated);
      this.sipTrunk = this.sipTrunkRepo.getObjectById(this.sipTrunk.id);
      this.resetForm();
      this.toast.showSuccess(_ti('public.message.update_success'));
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.updateSipTrunkCommon.clearValue(this.form.get(controlName));
  }

  gotoEditDidPage(did: DidEntity): void {
    this.router.navigate(['number-routing', did.id]);
  }

  copyToClipboard(val: string): void {
    const selBox = document.createElement('input');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toast.showSuccess(_ti('clipboard.copied'));
  }
}
