import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@wephone/services/user.service';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { ToastService } from '@wephone-utils';
// import { TranslateService } from '@ngx-translate/core';
import { EnterpriseFileEntryService } from '@wephone-core/service/enterprise_file_entry_service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EnterpriseFileEntryEntity } from '@wephone-core/model/entity/enterprise_fileentry';
import { FileSettings, getFileValidation } from '@wephone-utils/utils/file-util';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'call-queue-logo',
  templateUrl: './call-queue-logo.component.html',
  styleUrls: ['./call-queue-logo.component.scss']
})
export class CallQueueLogoComponent implements OnInit {

  @Input() queue: CallQueueEntity;
  @Input() width: string;
  @Input() height: string;
  @Input() uploadAvatar?: boolean;

  queue_avatar_url: string;
  file_avatar: any;
  fileSettings: FileSettings;
  is_uploading: boolean;

  constructor(
    private userService: UserService,
    private settings: FlexIvrSettings,
    private toast: ToastService,
    private entityManager: EntityManager,
    private enterpriseFileentryService: EnterpriseFileEntryService,
    // private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.file_avatar = undefined;
    if (!this.width) {
      this.width = 'auto';
    }

    if (!this.height) {
      this.height = 'auto';
    }

    // File avatar
    this.fileSettings = {
      extensions: ['.png', '.jpg', '.jpeg', '.gif'],
      max_size: this.settings.max_upload_size * 1024 * 1024
    };

    if (this.queue) {
      setTimeout(() => {
        this.queue_avatar_url = this.userService.queue_avatar_url(this.queue);
      });
    }

  }

  async onAvatarSelected($file): Promise<EnterpriseFileEntryEntity> {
    this.file_avatar = undefined;
    if (!$file || !$file.srcElement || !$file.srcElement.files) {
      // not found solution to validate in local
      console.error('No file selected');

      return Promise.resolve(undefined);
    }

    this.file_avatar = $file.srcElement.files[0];
    if (this.file_avatar) {
      const fileValidation = getFileValidation(this.file_avatar, this.fileSettings);
      if (!fileValidation.valid) {
        this.toast.showError(fileValidation.message);
        this.file_avatar = undefined;
        return Promise.resolve(undefined);
      }
    }

    const avatar_title = `queue - ${(this.queue.queue_name)}`;
    this.is_uploading = true;

    try {
      const enterprise_file_entry: EnterpriseFileEntryEntity = await this.enterpriseFileentryService.upload(
        this.file_avatar,
        avatar_title,
        EnterpriseFileEntryEntity.CATEGORY_AVATAR_USER,
        EnterpriseFileEntryEntity.TYPE_IMAGE);

      if (enterprise_file_entry) {
        const queue: CallQueueEntity = await this.updateQueueAvatar(enterprise_file_entry.getId());
        if (queue) {
          this.queue = queue;
        }
        this.is_uploading = false;
        this.queue_avatar_url = undefined;
        setTimeout(() => {
          this.queue_avatar_url = this.userService.queue_avatar_url(this.queue);
        });
      }

      return enterprise_file_entry;
    } catch (e) {
      this.is_uploading = false;
      this.toast.showErrorMessage(e, _ti('campaign.contact.upload.validator.file_not_accepted'));
    }
  }

  async updateQueueAvatar(file_id): Promise<CallQueueEntity> {
    return this.entityManager.getRepository('CallQueueRepository').saveAttrs(
      this.queue, ['logo_id'], { logo_id: file_id });
  }

  removeEntry(): Promise<any> {
    this.queue_avatar_url = FlexIvrSettings.getInstance().getAbsoluteUrl('/bundles/flexivrangularui/assets/img/queue.png');
    this.queue.logo_id = undefined;

    return this.entityManager.getRepository('CallQueueRepository').saveAttrs(this.queue, ['logo_id']);
  }
}
