<div fxLayout="column" class="column-right" #mainArea>
  <h3><b>{{ 'ivrmenu.title.config_welcome_message' | translate }}</b></h3>
  <p>{{ 'ivrmenu.content.welcome_message_note' | translate }}</p>

  <div class="pl-1 mt-1">
    <sound-upload [(fileEntry)]="sourceIvrmenu.sound_message" [hideRemove]="true"
      [attr.data-ci]="'sound-upload-play-message'" title="{{ 'ivrmenu.content.play_message' | translate }}"
      (onChange)="updateSoundMessageFile($event)">
    </sound-upload>
    <span class="mat-error" *ngIf="errors.sound_message">
      {{ 'form.validator.field_required' | translate }}
    </span>
  </div>

  <hr class="horizontal_dotted_line" />

  <h3><b>{{ 'ivrmenu.title.program_actions_ivr' | translate }}</b></h3>
  <p>{{ 'ivrmenu.content.program_actions_ivr_note' | translate }}</p>

  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="7px" fxLayoutGap.xs="0px">
    <div class="pr-1">{{ 'ivrmenu.content.input_length'|translate }}</div>
    <div>
      <mat-form-field>
        <mat-select [(ngModel)]="key_length" (selectionChange)="updateIvrMenuDataKeyLength($event)">
          <mat-option [value]="key" *ngFor="let key of keyLengths">
            {{ key !== 0 ? key : 'ivrmenu.content.key_length_any'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="7px" fxLayoutGap.xs="0px">
    <!-- <mat-checkbox [(ngModel)]="has_default_opt" [value]="1" (change)="setDefaultOpt()">
        {{ 'ivrmenu.content.choose_default_opt' | translate }}
      </mat-checkbox> -->
    <section>
      <mat-slide-toggle [(ngModel)]="has_default_opt" data-ci="choose_default" (change)="setDefaultOpt()">
        {{ 'ivrmenu.content.choose_default_opt' | translate }}
      </mat-slide-toggle>
    </section>
    <div fxLayout="column">
      <mat-form-field>
        <input matInput type="number" id="ivr_number" [(ngModel)]="sourceIvrmenu.ivrscript_data.timeout"
          (ngModelChange)="onChangeTimeout()" min="{{ DATA_TIMEOUT_MIN }}" [ngClass]="{'ng-invalid': errors?.timeout}"
          [disabled]="!has_default_opt">
        <div matSuffix>{{ 'public.second'|translate }}</div>
      </mat-form-field>
      <span class="mat-text-warn" *ngIf="errors?.timeout">
        {{ 'ivrmenu.validator.value_cannot_less_than_x' | translate:{x: DATA_TIMEOUT_MIN} }}
      </span>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="7px">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="7px" *ngFor="let item of keymap_list; let i = index">
      <div fxFlex="100px" fxLayout fxLayoutAlign="center center" class="action-item">
        <div fxLayout="column">
          <div fxFlex>
            <strong *ngIf="item.key && item.key[0] === 'default'; else phonepadPicker">
              {{ item.key[0] }}
            </strong>
            <ng-template #phonepadPicker>
              <phonepad-picker [options]="{key_length: key_length}" [keys]="item.key"
                (valueChange)="phonePadPickerChanged($event, item, i)">
              </phonepad-picker>
            </ng-template>
          </div>
          <ng-container *ngIf="errors && errors.keymap && errors.keymap[i]">
            <div class="mat-text-warn" *ngIf="errors.keymap[i].empty_keys">
              {{ 'form.validator.field_required' | translate }}
            </div>
            <div class="mat-text-warn" *ngIf="errors.keymap[i].duplicated_keys">
              {{ 'ivrmenu.message.duplicated_keys'|translate:{ key: duplicated_keys[i].join(', ') } }}
            </div>
            <!-- <div class="mat-text-warn" *ngIf="errors.keymap[i].data_duplicated">
              {{ 'ivrmenu.message.keys_data_duplicated'|translate }}
            </div> -->
            <div class="mat-text-warn" *ngIf="errors.keymap[i].data_incorrect">
              {{ 'ivrmenu.message.keys_data_incorrect'|translate }}
            </div>
            <div class="mat-text-warn" *ngIf="errors.keymap[i].key_length_incorrect">
              {{ 'ivrmenu.message.key_length_incorrect'|translate }}
            </div>
          </ng-container>
        </div>
      </div>
      <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start center" class="action-item item-destination">
        <div class="full-width">
          <call-destination class="full-width" #callDestinations id="default_des" [isSubMenu]="isSubMenu"
            [canAddSubMenu]="canAddSubMenu" [shouldViewSubMenu]="shouldViewSubMenu" [destinations]="destinations"
            [routeAppLabelObject]="routeAppLabelObject" [value]="item.call_destination" [excludeIvrMenuId]="excludeIvrMenuId"
            (valueChanged)="updateCallDestination()">
          </call-destination>
        </div>
        <!-- <mat-error class="mat-text-warn" *ngIf="errors?.keymap && errors?.keymap[i]?.calldestination">
          {{ 'public.message.data_invalid' | translate }}
        </mat-error> -->
      </div>
      <div fxFlex="40px" fxLayout fxLayoutAlign="center center" class="action-item">
        <div *ngIf="item.key && item.key[0] === 'default'; else deleteBlock"></div>
        <ng-template #deleteBlock>
          <button mat-icon-button color="warn" (click)="removeKeymapItem(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="mt-1">
    <button type="button" mat-stroked-button color color="accent" data-ci="button-add-new-key"
      (click)="addNewKeymapItem()">
      <mat-icon class="mat-text-accent">add_circle</mat-icon>
      <span class="ml-1">{{ 'ivrmenu.content.add_new_key' | translate }}</span>
    </button>
  </div>
</div>
