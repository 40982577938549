import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { IIvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app.i';import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { IEntity } from '../model.interface';

export class IvrRemoteAppRepository extends BaseRepository implements IIvrRemoteAppRepository {
  ENTITY_CLASS = IvrRemoteAppEntity;
  protected URL_PREFIX = 'ivrremoteapp';

  getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }
}

