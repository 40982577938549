import { Injectable } from '@angular/core';

import { EntityManager } from '@wephone-core/service/entity_manager';
import { WsRpcService } from '@wephone-core/service/wsrpc_service';
import { ConnectionStateEvent } from '@wephone-core/rpc/wsjsonrpcengine';
import { MessageService } from '@wephone-utils/services/message.service';
import { WindowService } from '@wephone-utils/services/window.service';
import { _tk } from '@wephone-translation';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { IJsPhoneDriver, ISipAccount } from '@jsphonelib';
// import { SigninService } from '@wephone-session/service/signin.service';

@Injectable()
export class ConnectivityService {
  private jsPhone: IJsPhoneDriver;
  private callCenterDataRequestCount = 0;
  private loadingElement: any;
  private TOAST_PUSHAPI_CONNECTION_L0ST = 'pushapi-connection-lost';
  private connectionWasOK = true;
  private pingInterval = 30000;

  constructor(
    private em: EntityManager,
    private wsrpcService: WsRpcService,
    private messageService: MessageService,
    private authService: AuthenticationService,
    private windowService: WindowService
  ) {
    wsrpcService.connectionState$.subscribe((connectionEvent: ConnectionStateEvent) => {
      this.checkConnectivity();
      /*
        if (connectionEvent.name === ConnectionState.Connected) {
          this.checkConnectivity();
          if (connectionEvent.params && connectionEvent.params.reconnection) {
            this.toastService.show(_ti('connectivity.connected'), ToastStyle.INFO);
          }
        } else if (connectionEvent.name === ConnectionState.WaitToReconnect) {
          // this.toastService.showError(_ti('connectivity.connection_lost'));
        }
      */
    });

    messageService.subscribe('auth:logged_out', () => {
      this.wsrpcService.disconnect();
      this.authService.gotoLoginPage();
    });

    messageService.subscribe('sipaccount:state', (sipaccount: ISipAccount) => {
      this.checkConnectivity();
    });

    this.jsPhone = window['jsphone_api'];
  }

  connectivityOK(): boolean {
    return this.authService.isAuthenticated() && this.phoneConnectionOK() && this.wsrpcService.isConnected();
  }

  checkConnectivity(): void {
    const ok = this.connectivityOK();
    if (ok !== this.connectionWasOK) {
      this.connectionWasOK = ok;
      if (!ok) {
        this.windowService.bringToFront();
      }
    }
  }

  wsrpcConnectionOk(): boolean {
    return this.wsrpcService.isConnected();
  }

  phoneConnectionOK(): boolean {
    if (this.jsPhone  && this.jsPhone.getPhoneConfig().sipstack_enabled) {
      return this.jsPhone.accountManager.defaultAccountConnected();
    }
    return true;
  }

  requestCallCenterLiveData(): Promise<any> {
    return this.em.requestCallCenterLiveData();
  }

  releaseCallcenterLiveData(): void {
    if (this.callCenterDataRequestCount > 0) {
      this.callCenterDataRequestCount--;
    } else {
      console.error('Trying to release callCenterDataRequestCount but it is already 0');
    }
    if (this.callCenterDataRequestCount === 0) {
      this.hideLoading();
    }
  }

  showPushAPIDisconnectionWarning(message?: string): void {}

  showLoading(message?: string): void {
    /*
    if (!this.loadingElement) {
      this.translation.get('public.message.loading').subscribe(async (res: string) => {
        this.loadingElement = await this.loadingCtrl.create({
          content: res
        });
        this.loadingElement.present();
      });
    }
    */
  }

  hideLoading(): void {
    if (this.loadingElement) {
      this.loadingElement.dismiss();
      this.loadingElement = undefined;
    }
  }

  startPinging(): void {}
}
