import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EditingComponent } from '@wephone-utils';
import {
  FormBuilder, FormArray, FormGroup, ValidatorFn, Validators, ValidationErrors,
} from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import * as _ from 'lodash';

interface EstimatedSound {
  counter: number;
  sound: FileEntryEntity;
  is_default: boolean;
}

export const duplicateCounterValidator = (): ValidatorFn => {
  return (form: FormGroup): ValidationErrors | null => {
    const formArray = form.get('estimatedItems') as FormArray;
    formArray.controls.forEach(control => {
      const isDuplicate = formArray.value.filter(i => i.counter === control.value.counter).length > 1;
      if (isDuplicate) {
        if (!control.get('counter').hasError('duplicateCounter')) {
          control.get('counter').setErrors({ duplicateCounter: true });
        }
      } else if (control.get('counter').hasError('duplicateCounter')) {
        control.get('counter').setErrors({ duplicateCounter: null });
        control.get('counter').updateValueAndValidity();
      }
    });
    return null;
  };
};
@Component({
  selector: 'app-queue-estimated-wait-time',
  templateUrl: './queue-estimated-wait-time.component.html',
  styleUrls: ['./queue-estimated-wait-time.component.scss']
})
export class QueueEstimatedWaitTimeComponent extends EditingComponent implements OnInit, OnChanges {
  @Input() queue: CallQueueEntity;
  @Output() readonly formValueChanges: EventEmitter<boolean>;
  @Output() readonly onValueChange: EventEmitter<any> = new EventEmitter<any>();

  private readonly fileEntryRepo: FileEntryRepository;
  private valueChanged: any;
  private hasInitialized = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.fileEntryRepo = this.em.getRepository('FileEntryRepository');
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.hasInitialized = true;
    this.initFormData();
  }

  ngOnChanges(): void {
    if (!this.hasInitialized) {
      return;
    }
    this.initFormData();
  }

  setEstimatedItemsControls(): void {
    this.estimatedItemsControl.controls.splice(0);
    const estimatedItems: EstimatedSound[] = this.getEstimatedItems();

    for (const item of estimatedItems) {
      this.estimatedItemsControl.push(this.createEwtItem(item));
    }
  }

  initFormData(): void {
    this.form = this.fb.group({
      estimatedItems: this.fb.array([])
    }, {
      validators: duplicateCounterValidator(),
    });

    this.setEstimatedItemsControls();

    this.form.valueChanges.subscribe((value: any) => {
      console.log('form queue-estimated-wait-time changed', value);
      this.valueChanged = value;

      this.onValueChange.emit(value);
      this.onFormValueChange();
    });
  }

  get estimatedItemsCtrl(): FormArray {
    return this.form.get('estimatedItems') as FormArray;
  }

  formIsValid(): boolean {
    return super.formIsValid();
  }

  getEstimatedItems(): EstimatedSound[] {
    const estimatedItems: EstimatedSound[] = [];
    const requireDefault = !this.queue.ewt_data || !this.queue.ewt_data.filter(x => x.counter === 0).length;

    if (requireDefault) {
      estimatedItems.push({
        counter: 0,
        sound: undefined,
        is_default: true
      });
    }

    if (this.queue.ewt_data) {
      for (const ewt of this.queue.ewt_data) {
        estimatedItems.push({
          counter: ewt.counter,
          sound: this.fileEntryRepo.getObjectById(ewt.sound_id),
          is_default: !ewt.counter
        });
      }
    }

    return estimatedItems;
  }

  getChangeSet(): any {
    return this.valueChanged;
  }

  createEwtItem(item: any): FormGroup {
    return this.fb.group({
      counter: [item.counter, [
        Validators.required,
        Validators.min(item.is_default ? 0 : 1),
        Validators.max(9999),
      ]],
      sound: [item.sound, [Validators.required]],
      is_default: [item.is_default],
    });
  }

  addMorePeopleSound(): void {
    this.estimatedItemsControl.push(
      this.createEwtItem({
        counter: 1,
        sound: undefined,
        is_default: false,
      })
    );
  }

  get estimatedItemsControl(): FormArray {
    return this.form.get('estimatedItems') as FormArray;
  }

  set estimatedItemsControl(fa: FormArray) {
    this.form.controls['estimatedItems'] = fa;
  }

  removePeopleSound(index: number): void {
    this.estimatedItemsControl.markAsDirty();
    this.estimatedItemsControl.removeAt(index);
  }

  updateSoundFile(index: number, $event: { new_file_entry: FileEntryEntity; remove?: boolean }): void {
    if ($event.remove) {
      this.estimatedItemsControl.controls[index].get('sound').setValue(undefined);
    } else {
      this.estimatedItemsControl.controls[index].get('sound').setValue($event.new_file_entry);
    }
  }
}
