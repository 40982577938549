import * as _ from 'lodash';
import { IRoutingApp, IRoutingAppDestinationData } from '@wephone-core/routing-app/routing-app.interface';

export interface IStringAppMap {
  [key: string]: { new (app_params?: any): IRoutingApp };
}

export class RoutingAppManager {
  private static instance: RoutingAppManager;
  private appMap: IStringAppMap = {};
  private appParams: any;

  constructor() {
    if (RoutingAppManager.instance) {
      throw new Error('Use RoutingAppManager.getInstance() to get singleton object of this class');
    }
    this.registerAppList();
  }

  static getInstance(): RoutingAppManager {
    if (!RoutingAppManager.instance) {
      RoutingAppManager.instance = new RoutingAppManager();
    }

    return RoutingAppManager.instance;
  }

  registerRoutingAppType(AppClass: { new (): IRoutingApp }): void {
    const app = new AppClass();
    this.appMap[app.getAppName()] = AppClass;
  }

  private registerAppList(): void {
    this.registerRoutingAppType(RoutingAppCallUser);
    this.registerRoutingAppType(RoutingAppConference);
    this.registerRoutingAppType(RoutingAppCrmRoute);
    this.registerRoutingAppType(RoutingAppExternalNumber);
    this.registerRoutingAppType(RoutingAppIvrMenu);
    this.registerRoutingAppType(RoutingAppPlayThenHangup);
    this.registerRoutingAppType(RoutingAppQueue);
    this.registerRoutingAppType(RoutingAppRemoteApp);
    this.registerRoutingAppType(RoutingAppUserSecondaryNumber);
    this.registerRoutingAppType(RoutingAppVoicemail);
    this.registerRoutingAppType(RoutingAppCallPhone);
    this.registerRoutingAppType(RoutingAppWebService);
    this.registerRoutingAppType(RoutingAppRunVxmlScript);
    this.registerRoutingAppType(RoutingApSipTrunk);
  }

  createAppInstance(app_name: string, app_data?: any): IRoutingApp {
    if (app_name in this.appMap) {
      return new this.appMap[app_name](app_data || undefined);
    }
    throw new Error(`Cannot create app instance for unknown app name ${app_name}`);
  }

  createAppInstanceFromData(app_data: IRoutingAppDestinationData): IRoutingApp {
    return this.createAppInstance(app_data.application, app_data.params);
  }

}

import { RoutingAppCallUser } from '@wephone-core/routing-app/app/call-user';
import { RoutingAppConference } from '@wephone-core/routing-app/app/conference';
import { RoutingAppCrmRoute } from '@wephone-core/routing-app/app/crm-route';
import { RoutingAppExternalNumber } from '@wephone-core/routing-app/app/external-number';
import { RoutingAppIvrMenu } from '@wephone-core/routing-app/app/ivr-menu';
import { RoutingAppPlayThenHangup } from '@wephone-core/routing-app/app/play-then-hangup';
import { RoutingAppQueue } from '@wephone-core/routing-app/app/queue';
import { RoutingAppRemoteApp } from '@wephone-core/routing-app/app/remote-application';
import { RoutingAppUserSecondaryNumber } from '@wephone-core/routing-app/app/user-secondary-number';
import { RoutingAppVoicemail } from '@wephone-core/routing-app/app/voicemail';
import { RoutingAppCallPhone } from '@wephone-core/routing-app/app/call-phone';
import { RoutingAppWebService } from '@wephone-core/routing-app/app/web-service';
import { RoutingAppRunVxmlScript } from '@wephone-core/routing-app/app/run-vxml-script';
import { RoutingApSipTrunk } from '@wephone-core/routing-app/app/sip-trunk';
