import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';

export class SkillRequirementEntity extends Entity {
  public static object_type_id: string = 'skill_requirement';
  public static OBJECT_TYPE_DID = 2;
  public static OBJECT_TYPE_AGENT = 1;

  constructor(skill_id: number = null, level: number = 5, object_id: number = null, object_type: number = null) {
    super();
    this.skill_id = skill_id;
    this.level = level;
    this.object_id = object_id;
    this.object_type = object_type;
  }

  @datafield
  public object_id: number;

  @datafield
  public object_type: number;

  @datafield
  public skill_id: number;

  @datafield
  public level: number;

  get skill_name() {
    if (this.skill_id) {
      return SkillRepository.getInstance().getObjectById(this.skill_id).name;
    }
    return null;
  }
}

import { EntityManager } from '@wephone-core/service/entity_manager';
import { SkillRepository } from '@wephone-core/model/repository/skill';
