import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { FlexivrEvent } from '@wephone-core/event/flexivr_event';
import { SingletonBase } from '@wephone-utils';

export class EventManager extends SingletonBase {
  observable: Observable<any>;
  observer: Observer<any>;

  constructor() {
    super();
    this.observable = Observable.create((observer: Observer<any>) => {
      this.observer = observer;
    })
    .pipe(share());
  }

  /**
   * Method to broadcast the event to observer
   */
  broadcast(event_name: string, event_params: any) {
    let event: FlexivrEvent = new FlexivrEvent(event_name, event_params);
    if (this.observer != null) {
      this.observer.next(event);
    }
  }

  /**
   * Method to subscribe to an event with callback
   */
  subscribe(eventName: string) {
    return this.observable.pipe(
      filter(
        (event: FlexivrEvent) => {
          return event.name === eventName;
      })
    );
  }
}
