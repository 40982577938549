<div fxLayout="column" fxFlex="grow" class="flex-container">
  <div class="page-header">
    <h2>{{ 'menu.manage_cdr' | translate }}</h2>
  </div>
  <div class="filter-container">
    <form [formGroup]="filterForm">
      <ng-container *ngIf="filtersReadonly">
        <div fxLayout="column" fxLayoutAlign="center center">
          <span *ngIf="startDateControl.value && endDateControl.value">
            {{ startDateControl.value|timeFormatDateShort }} -->
            {{endDateControl.value|timeFormatDateShort}}
          </span>
          <span *ngIf="filterForm.get('start_time').value != filterForm.get('end_time').value">
            {{ filterForm.get('start_time').value }} to {{ filterForm.get('end_time').value }}
          </span>
          <span *ngIf="filterForm.get('called_number').value">
            {{ filterForm.get('called_number').value | displayPhoneNumber }}
          </span>
          <span *ngIf="filterForm.get('calling_number').value">
            {{ filterForm.get('calling_number').value | displayPhoneNumber }}
          </span>
          <a class="link" (click)="changeFilter()">{{ 'public.change'|translate }}</a>
        </div>
      </ng-container>

      <ng-container *ngIf="!filtersReadonly">
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxFlexFill
          fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{ 'cdr.start_date' | translate }}</mat-label>
              <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
                placeholder="{{ 'campaign.startDt' | translate }}">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
              <mat-error
                *ngIf="startDateControl.hasError('flexDatepickerMin') || startDateControl.hasError('required') && startDateControl.touched">
                {{ 'form.validator.required'|translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{ 'cdr.end_date' | translate }}</mat-label>
              <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
                [matDatepickerFilter]="minEndDateFilter" placeholder="{{ 'campaign.endDt' | translate }}">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
              <mat-error *ngIf="endDateControl.hasError('required') && endDateControl.touched">
                {{ 'form.validator.required'|translate }}
              </mat-error>
              <mat-error
                *ngIf="endDateControl.hasError('flexDatepickerMin') || endDateControl.hasError('matDatepickerFilter')">
                {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
                startDateControl.value | timeFormatDateShort} }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{ 'call_queue.content.called_number'|translate }}</mat-label>
              <input matInput="tel" placeholder="{{ 'call_queue.content.called_number'|translate}}"
                formControlName="called_number">
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'called_number')"
                [disabled]="!filterForm.get('called_number').value">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start"
          fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex>
            <flex-time-picker formControlName="start_time" placeholder="{{ 'campaign.hour_start' | translate }}">
            </flex-time-picker>
          </div>
          <div fxFlex>
            <flex-time-picker formControlName="end_time" placeholder="{{ 'campaign.hour_end' | translate }}">
            </flex-time-picker>
          </div>
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{ 'call_queue.content.calling_number'|translate }}</mat-label>
              <input matInput="tel" placeholder="{{ 'call_queue.content.calling_number'|translate}}"
                formControlName="calling_number">
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'calling_number')"
                [disabled]="!filterForm.get('calling_number').value">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div align="center">
          <button type="submit" mat-raised-button color="accent" (click)="applyFilter()">
            {{ 'public.apply' | translate }}
          </button>
        </div>
      </ng-container>
    </form>
  </div>

  <div fxFlex="grow" *ngIf="is_ready">
    <flex-table #flexTable fxFlex="grow" [dataSource]="dataSource" [tableConfig]="tableConfig" [enablePaginator]="true"
      [columnTemplates]="{
          calling_number: tplCallingNumber, called_number: tplCalledNumber, 
          start_time: tplStartDate, end_time: tplEndDate, 
          in_out: tplInOut, duration: tplDuration
        }">

      <ng-template #tplCallingNumber let-item="row">
        <span [ngClass]="{'mat-text-warn': !!item.is_missed}">{{ item.calling_number | displayPhoneNumber }}</span>
      </ng-template>

      <ng-template #tplCalledNumber let-item="row">
        <span [ngClass]="{'mat-text-warn': !!item.is_missed}">{{ item.called_number | displayPhoneNumber }}</span>
      </ng-template>

      <ng-template #tplStartDate let-item="row">
        <span [ngClass]="{'mat-text-warn': !!item.is_missed}">{{ item.start_time|timeFormatDateTimeShort }}</span>
      </ng-template>

      <ng-template #tplEndDate let-item="row">
        <span [ngClass]="{'mat-text-warn': !!item.is_missed}">{{ item.end_time|timeFormatDateTimeShort }}</span>
      </ng-template>

      <ng-template #tplInOut let-item="row">
        <ng-container [ngSwitch]="item.in_out">
          <span *ngSwitchCase="callDirection.IN" class="mat-text-primary">
            <mat-icon color="accent" matTooltip="{{ 'livecall.call_incoming'|translate }}">arrow_downward</mat-icon>
          </span>
          <span *ngSwitchCase="callDirection.OUT" class="mat-text-primary">
            <mat-icon color="warn" matTooltip="{{ 'livecall.call_outgoing'|translate }}">arrow_upward</mat-icon>
          </span>
          <span *ngSwitchDefault class="mat-text-primary">
            <mat-icon class="internal-call" matTooltip="{{ 'livecall.call_internal'|translate }}">repeat</mat-icon>
          </span>
        </ng-container>
        <!-- <span *ngIf="item.in_out === 1; else callOut" class="mat-text-primary">
          <mat-icon color="accent" matTooltip="{{ 'livecall.call_incoming'|translate }}">arrow_downward</mat-icon>
        </span>
        <ng-template #callOut>
          <mat-icon color="warn" matTooltip="{{ 'livecall.call_outgoing'|translate }}">arrow_upward</mat-icon>
        </ng-template> -->
      </ng-template>

      <ng-template #tplDuration let-item="row">
        <span *ngIf="!item.is_missed">{{item.duration | seconds_as_duration}}</span>
      </ng-template>

    </flex-table>
  </div>
</div>
