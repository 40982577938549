import { Component, Input, OnInit } from '@angular/core';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';

@Component({
  selector: 'app-ivr-evaluation-mini-block',
  templateUrl: './ivr-evaluation-mini-block.component.html',
  styleUrls: ['./ivr-evaluation-mini-block.component.scss']
})
export class IvrEvaluationMiniBlockComponent implements OnInit {
  @Input() ivrEvaluation: IvrEvaluationEntity;
  constructor() { }

  ngOnInit(): void {
  }

}
