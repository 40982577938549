<form [formGroup]="form">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
    
    <mat-form-field class="full-width">
      <mat-label>{{ 'fos.new_password' | translate }}</mat-label>
      <input matInput data-cy="password" type="password" [formControl]="form.controls['password']" placeholder="{{ 'fos.new_password' | translate }}"
        [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      
      <mat-error *ngIf="form.controls['password'].invalid && form.controls['password'].touched">
        <!-- <small *ngIf="form.controls['password'].hasError('required')">
          {{ 'form.validator.field_required'|translate }}
        </small>
        <small *ngIf="form.controls['password'].hasError('min_character')">
          {{ 'user.validators.password_min_character'|translate }}
        </small>
        <small *ngIf="form.controls['password'].hasError('password_constraint')">
          {{ 'user.validators.password_constraint'|translate }}
        </small> -->
        {{ 'user.validators.password_constraint'|translate }}
      </mat-error>
    </mat-form-field>
  
    <mat-form-field class="full-width">
      <mat-label>{{ 'fos.new_password_confirmation' | translate }}</mat-label>
      <input matInput data-cy="confirm_password" type="password" [formControl]="form.controls['confirm_password']" placeholder="{{ 'fos.new_password_confirmation' | translate }}">
      <mat-error *ngIf="form.controls['confirm_password'].invalid && form.controls['confirm_password'].touched">
        <!-- <small *ngIf="form.controls['confirm_password'].hasError('password_not_match')">
          {{ 'user.validators.confirm_password_not_match'|translate }}
        </small> -->
        {{ 'user.validators.confirm_password_not_match'|translate }}
      </mat-error>
    </mat-form-field>
    
  </div>
</form>
