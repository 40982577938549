import { Component, OnInit, ViewChild } from '@angular/core';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { ToastService, IFlexTableSidePannelOptions, DialogService, DynamicFilterSource, IFlexTableConfig, regexSearch } from '@wephone-utils';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { CreateIvrRemoteAppModalComponent } from '@wephone/modals/create-ivr-remote-app-modal/create-ivr-remote-app-modal.component';
import { IvrRemoteAppEditComponent } from '@wephone/components/ivr-remote-app-edit/ivr-remote-app-edit.component';
import { _tk, _ti } from '@wephone-translation';
import { IvrRemoteAppService } from '@wephone/services/ivr-remote-app.service';
import { MatDialogRef } from '@angular/material/dialog';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'app-ivr-remote-apps',
  templateUrl: './ivr-remote-apps.component.html',
  styleUrls: ['./ivr-remote-apps.component.scss']
})
export class IvrRemoteAppsComponent extends FlexBasePage implements OnInit {
  ivrRemoteAppRepo = IvrRemoteAppRepository.getInstance<IvrRemoteAppRepository>();
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: IvrRemoteAppEditComponent
  };

  filterString: string;
  form: FormGroup;
  @ViewChild('flexCrud', { static: false }) flexCrud;
  constructor(
    private dialogService: DialogService,
    private ivrRemoteAppService: IvrRemoteAppService,
    private toast: ToastService,
    private fb: FormBuilder) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.initDS();
  }

  initDS(): void {
    const sipphoneSource = this.ivrRemoteAppRepo.dataSource<IvrRemoteAppEntity>();
    this.dataSource = new DynamicFilterSource(sipphoneSource, this.filterFunc);

    this.tableConfig = {
      enableFilter: false,
      columns: [
        {
          name: 'name',
          label: _tk('ivrremoteapp.content.name'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (ivrRemote: IvrRemoteAppEntity) => {
            return ivrRemote.name.toLowerCase();
          }
        },
        {
          name: 'alias',
          label: _tk('ivrremoteapp.content.alias'),
          searchable: true,
          sortable: true,
          customSortFn: (ivrRemote: IvrRemoteAppEntity) => {
            return ivrRemote.alias.toLowerCase();
          }
        },
        {
          name: 'url',
          label: _tk('ivrremoteapp.content.url'),
          searchable: false,
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            hint: _tk('public.create'),
            callback: () => {
              return this.createIvrRemoteApp();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(this.onFormValueChange)
    );
  }

  filterFunc = (item: IvrRemoteAppEntity): boolean => {
    if (!this.filterString) {
      return true;
    }

    return regexSearch(item.name, this.filterString) || (item.alias && regexSearch(item.alias, this.filterString));
  }

  private onFormValueChange = (formValues: { filterString: string }) => {
    this.filterString = (formValues.filterString || '').trim();
    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  protected async resolveData(): Promise<any> {
    await this.ivrRemoteAppRepo.findAll();
    await EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
  }

  private async bulkDelete(items: IvrRemoteAppEntity[]): Promise<any> {
    const confirmed: boolean = await this.ivrRemoteAppService.getConfirmDeleteIvrRemoteApps(items);
    if (confirmed) {
      try {
        await this.ivrRemoteAppRepo.bulkDelete(items);
        this.flexCrud.closeSidePanel();
        this.toast.show(_ti('public.message.delete_success'));
      } catch (error) {
        this.showError(_ti('public.message.delete_failure'));
      }
    }
  }

  createIvrRemoteApp(): MatDialogRef<any> {
    const newItem = this.ivrRemoteAppRepo.create() as IvrRemoteAppEntity;
    return this.dialogService.openDialog2(CreateIvrRemoteAppModalComponent, {
      data: {
        ivrRemoteApp: newItem
      }
    });
  }
}
