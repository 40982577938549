import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DataSource } from '@angular/cdk/collections';
import { DynamicFilterSource } from './dynamic-filter-source';
import { IFlexDataSource } from 'src/app/wephone-utils/datasource/datasource';

export class VirtualScrollDataSource extends DataSource<any> {
  private readonly visibleData: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private pageSize: number;
  offsetChange = new BehaviorSubject(0);
  private _data: any[];
  private prevExtraData = 0;
  private startItemIndex = 0;
  private lastStartItemIndex = 0;
  private bottomReached: boolean;

  constructor(
    private originalSource: DynamicFilterSource | IFlexDataSource<Object>,
    private viewport?: CdkVirtualScrollViewport,
    private itemHeight?: number,
    private isExpansionDetailRow?: boolean,
  ) {
    super();

    this.pageSize = window.innerHeight / this.itemHeight * 2;
    this.setViewport(viewport);
    if (originalSource && originalSource.dataChanges) {
      originalSource.dataChanges.subscribe(val => {
        this._data = val;
        if (this.viewport) {
          // this.viewport.scrollToOffset(0);
          if (this._data) {
            this.viewport.setTotalContentSize(this.itemHeight * this._data.length);
          }
        }

        if (this.hasPaginator) {
          this.visibleData.next(val);
        } else {
          this.sendUpdatedData();
        }
      });
    }

    window.addEventListener('resize',
      () => {
        this.pageSize = window.innerHeight / this.itemHeight + 1;
      }
    );
  }

  get hasPaginator(): boolean {
    return !!this.originalSource.paginator;
  }

  setViewport(viewport: CdkVirtualScrollViewport): void {
    this.viewport = viewport;
    this.viewport.elementScrolled().subscribe((ev: any) => {
      if (this.hasPaginator) {
        return;
      }

      if (this.pageSize) {
        this.startItemIndex = Math.floor(ev.currentTarget.scrollTop / this.itemHeight);
        this.prevExtraData = this.startItemIndex > 5 ? 5 : 0;
        // const prevExtraData = 0;
        const offset = this.itemHeight * (this.startItemIndex - this.prevExtraData);
        if (this.startItemIndex < this.lastStartItemIndex || (this.startItemIndex > this.lastStartItemIndex && !this.bottomReached)) {
          this.lastStartItemIndex = this.startItemIndex;
          this.viewport.setRenderedContentOffset(offset);
          this.offsetChange.next(offset);
          this.sendUpdatedData();
        }
      }
    });
  }

  connect(): BehaviorSubject<any> {
    return this.visibleData;
  }

  disconnect(): void {
  }

  private sendUpdatedData(): void {
    let slicedData: any[];
    if (this.pageSize && this._data) {
      const lastIndex = this.startItemIndex + (this.pageSize - this.prevExtraData);
      slicedData = this._data.slice(this.startItemIndex - this.prevExtraData, lastIndex);
      this.bottomReached = lastIndex >= this._data.length;
      if (this.bottomReached && this.originalSource['load_more']) {
        (this.originalSource as IFlexDataSource<Object>).load_more().then(
          hasMore => {
            if (hasMore) {
              this.bottomReached = false;
            }
          }
        );
      }
    } else {
      slicedData = this._data;
      this.bottomReached = true;
    }

    let data: any[] = slicedData;
    if (this.isExpansionDetailRow && (slicedData || []).length) {
      data = [];
      slicedData.forEach(element => data.push(element, { detailRow: true, element }));
    }

    this.visibleData.next(data);
  }
}
