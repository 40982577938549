export interface IRoutingApp {
  getAppName(): RoutingAppName;
  getAppParams(): any;
  setAppParams(params: any): void;
  getAsJsonObject(): any;
  getDescription(): string;
  setRelatedObject(): void;
  getRelatedObject(): any;
}

export enum RoutingAppName {
  call_user = 'call_user',
  call_phone = 'call_phone',
  callqueue = 'callqueue',
  ivr_custom_menu = 'ivr_custom_menu',
  ivr_custom_main_menu = 'ivr_custom_main_menu',
  call_phone_number = 'call_phone_number',
  voicemail = 'voicemail',
  conference = 'conference',
  crm_route = 'crm_route',
  play_then_hangup = 'play_then_hangup',
  remote_application = 'remote_application',
  user_secondary_number = 'user_secondary_number',
  webservice = 'webservice',
  runvxmlscript = 'runvxmlscript',
  digitalsecretary = 'digitalsecretary',
  to_did = 'to_did',
  go_up = 'go_up',
  reprompt = 'reprompt',
  send_sms = 'send_sms',
  sip_trunk = 'sip_trunk',
}

export interface RoutingParamsQueue {
  queue: number;
}

export interface RoutingParamsSipphone {
  sipphone_id: number;
}

export interface RoutingParamsSipTrunk {
  siptrunk_id: number;
}

export interface RoutingParamsUser {
  id: number;
}

export interface RoutingParamsIvrMenuData {
  sound_id: number;
  key_length: number;
  keymap: { [key: string]: IRoutingAppDestinationData };
  timeout?: number;
}

export interface RoutingParamsIvrMenu {
  id?: number;
  sub_menu?: number;
  ivrscript_data?: RoutingParamsIvrMenuData;
  ivrscript_type?: number;
  ivr_custom_menu?: any;
}

export interface RoutingParamsPhoneNumber {
  number: string;
}

export interface RoutingParamsConference {
  conference: number;
}

export interface RoutingParamsCrmRoute {
  id: number;
}

export interface RoutingParamsPlayFileAndHangup {
  sound_id: number;
}

export interface RoutingParamsRunVxml {
  ivrscript_id: number;
}

export interface RoutingParamsRemoteApp {
  id: number;
}

export interface RoutingParamsWebservice {
  url: string;
}

export interface RoutingParamsSecondaryUserPhone {
  user_id: number;
}

export interface RoutingParamsVoicemail {
  mailbox_id: number;
}

export interface RoutingParamsDigitalSecretary {
  default_ext: string;
}

export interface RoutingParamsToDid {
  did_id: number;
}

export interface RoutingParamsSendSms {
  sms_id: number;
  sound_id: number;
}

export enum RoutingAppId {
  call_user = 1,
  call_phone = 2,
  callqueue = 3,
  ivr_custom_menu = 4,
  call_phone_number = 5,
  voicemail = 6,
  conference = 7,
  crm_route = 8,
  play_then_hangup = 9,
  remote_application = 10,
  user_secondary_number = 11,
  webservice = 12,
  runvxmlscript = 13,
  digitalsecretary = 14,
  to_did = 15,
  send_sms = 16,
}

export class IRoutingAppDestinationData {
  application: RoutingAppName;
  params: RoutingParamsQueue |
    RoutingParamsSipphone |
    RoutingParamsUser |
    RoutingParamsIvrMenu |
    RoutingParamsPhoneNumber |
    RoutingParamsConference |
    RoutingParamsPlayFileAndHangup |
    RoutingParamsRunVxml |
    RoutingParamsRemoteApp |
    RoutingParamsWebservice |
    RoutingParamsSecondaryUserPhone |
    RoutingParamsVoicemail |
    RoutingParamsDigitalSecretary |
    RoutingParamsToDid |
    RoutingParamsSendSms |
    RoutingParamsSipTrunk |
    any;
}

export function getRoutingAppIdByName(appName: RoutingAppName): number {
  return RoutingAppId[appName];
}
