import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { FormService, NoWhitespaceValidator } from '@wephone-utils';
import * as _ from 'lodash';
import { FlexBaseComponent } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { IvrEvaluationRepository } from '@wephone-core/model/repository/ivr_evaluation';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { IIvrEvaluationQuestionDataType } from '@wephone-core/model/entity/ivr_evaluation.i';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';

@Component({
  selector: 'app-create-update-ivr-evaluation',
  templateUrl: './create-update-ivr-evaluation.component.html',
  styleUrls: ['./create-update-ivr-evaluation.component.scss']
})
export class CreateUpdateIvrEvaluationComponent extends FlexBaseComponent implements OnInit {
  @Input() ivrEvaluation: IvrEvaluationEntity;
  @Output() readonly onChangedIvrEvaluation = new EventEmitter<IvrEvaluationEntity>(undefined);
  @Output() readonly onFormValueChanged = new EventEmitter<boolean>(false);

  form: FormGroup;

  private readonly ivrEvaluationRepo: IvrEvaluationRepository;

  constructor(private readonly fb: FormBuilder, private readonly formService: FormService, readonly em: EntityManager) {
    super();

    this.ivrEvaluationRepo = em.getRepository<IvrEvaluationRepository>('IvrEvaluationRepository');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initFormGroup();
  }

  get isEdit(): boolean {
    return this.ivrEvaluation && !!this.ivrEvaluation.id;
  }

  getChangeSet(): any {
    const formData: any = this.formService.getChangeSet(this.form);
    if ('name' in formData) {
      formData.name = _.trim(formData.name);
    }

    if ('welcome_msg' in formData) {
      formData.welcome_msg_id = formData.welcome_msg ? formData.welcome_msg.id : null;
      delete formData.welcome_msg;
    }

    if ('thankyou_msg' in formData) {
      formData.thankyou_msg_id = formData.thankyou_msg ? formData.thankyou_msg.id : null;
      delete formData.thankyou_msg;
    }

    if (!_.isEmpty(formData.questions)) {
      formData.question_data = formData.questions.map(o => {
        const obj: IIvrEvaluationQuestionDataType = {
          msg_id: o.msg ? o.msg.id : undefined,
          accepted_answer: o.accepted_answer
        };
        return obj;
      });
      delete formData.questions;
    }
    return formData;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.ivrEvaluation.name, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]],
      welcome_msg: [this.ivrEvaluation.welcome_msg],
      thankyou_msg: [this.ivrEvaluation.thankyou_msg, [Validators.required]],
      questions: this.fb.array(this.getQuestionControls())
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  get questionsControl(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  removeQuestion(index: number): void {
    this.questionsControl.markAsDirty();
    this.questionsControl.removeAt(index);
  }

  addQuestion(): void {
    this.questionsControl.markAsDirty();
    this.questionsControl.push(this.createQuestionControl(null));
  }

  private onFormValueChange(): void {
    if (this.onFormValueChanged) {
      this.onFormValueChanged.emit(this.form.dirty);
    }
  }

  private validateQuestionControl(questionControl: AbstractControl): void {
    questionControl.markAsTouched();

    questionControl.setErrors(null);

    const q = questionControl.value;

    const errors: any = {};
    if (!q || _.isEmpty(q.accepted_answer)) {
      errors.accepted_answer_required = true;
    }

    if (!q || _.isEmpty(q.msg)) {
      errors.msg_required = true;
    }

    if (!_.isEmpty(errors)) {
      questionControl.setErrors(errors);
    }
  }

  private updateValidatorQuestionData(): void {
    for (let index = 0; index < this.questionsControl.controls.length; index++) {
      const questionControl = this.questionsControl.controls[index];
      this.validateQuestionControl(questionControl as FormControl);

      // if (questionControl.getError('duplicated')) {
      //   delete questionControl.errors.duplicated;
      //   questionControl.setErrors(!_.isEmpty(errors) ? errors : undefined);
      // }

      // const duplicatedKeys = [];
      // for (const k of q.accepted_answer) {
      //   if (questionAnswerKeys.filter(key => key === k).length > 1) {
      //     duplicatedKeys.push(k);
      //   }
      // }

      // if (duplicatedKeys.length) {
      //   const errors: any = questionControl.errors || {};
      //   errors.duplicated = { keys: duplicatedKeys };
      //   questionControl.setErrors(errors);
      // }
    }
  }

  updateSoundB4WelcomeMsgFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData && soundData.new_file_entry;
    this.form.get('welcome_msg').markAsDirty();
    this.form.get('welcome_msg').setValue(file_entry);
  }

  updateSoundB4ThankyouMsgFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivr-evaluation');
    }
    this.form.get('thankyou_msg').markAsDirty();
    this.form.get('thankyou_msg').setValue(file_entry);
  }

  updateSoundB4QuestionMsgFile(soundData: { new_file_entry: FileEntryEntity }, index: number): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivr-evaluation');
    }

    const questionControl: FormControl = this.questionsControl.controls[index] as FormControl;

    const value: any = questionControl.value || {};
    value.msg = file_entry;

    questionControl.markAsDirty();
    questionControl.setValue(value);

    this.validateQuestionControl(questionControl);
  }

  phonePadPickerChanged(data: { keys: string[]; error?: string }, index: number): void {
    const questionControl: FormControl = this.questionsControl.controls[index] as FormControl;
    const value: any = questionControl.value || {};
    value.accepted_answer = data.keys;

    questionControl.markAsDirty();
    questionControl.setValue(value);

    this.validateQuestionControl(questionControl);
  }

  async submitForm(): Promise<any> {
    this.updateValidatorQuestionData();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.showError(_ti('public.message.data_invalid'));
      return undefined;
    }

    try {
      const updateData = this.getChangeSet();

      let retData;
      if (!this.isEdit) {
        retData = await this.ivrEvaluationRepo.createAndSave(updateData);
      } else {
        const updatedData = this.ivrEvaluationRepo.create(updateData) as IvrEvaluationEntity;
        updatedData.id = this.ivrEvaluation.id;

        retData = await this.ivrEvaluationRepo.saveAttrs(updatedData, Object.keys(updateData));
      }

      if (!retData || !retData.id) {
        this.showError(_ti('public.message.error_occurred'));
        return;
      }

      await this.ivrEvaluationRepo.wait_for_object_id(retData.id);
      const retIvrEvaluation = this.ivrEvaluationRepo.getObjectById(retData.id);

      this.successToast(_ti(this.isEdit ? 'public.message.update_success' : 'public.message.create_success'));
      this.onChangedIvrEvaluation.emit(retIvrEvaluation);
    } catch (e) {
      this.showError(e.message || _ti(this.isEdit ? 'public.message.update_failure' : 'public.message.create_failure'));
    }
  }

  private getFormResetData(): Object {
    return {
      name: this.ivrEvaluation.name,
      welcome_msg: this.ivrEvaluation.welcome_msg,
      thankyou_msg: this.ivrEvaluation.thankyou_msg
    };
  }

  private getQuestionControls(): FormControl[] {
    const questionControls: FormControl[] = [];

    if (this.isEdit) {
      const questionsValue = this.getQuestionsControllValue();
      for (const q of questionsValue) {
        questionControls.push(this.createQuestionControl(q));
      }
    } else {
      questionControls.push(this.createQuestionControl(null));
    }

    return questionControls;
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    this.resetQuestionsControl();

    const formData = this.getFormResetData();
    this.form.reset(formData, options);

    this.form.setControl('questions', this.fb.array(this.getQuestionControls()));

    this.form.markAsPristine();
    // this.formValueChanges.next(false);
  }

  private resetQuestionsControl(): void {
    while (this.questionsControl && this.questionsControl.length > 0) {
      this.questionsControl.removeAt(0);
    }

    const questionValue = this.getQuestionsControllValue();
    for (const value of questionValue) {
      this.questionsControl.controls.push(this.createQuestionControl(value));
    }
  }

  private createQuestionControl(value?: { msg: FileEntryEntity; accepted_answer: string[] }): FormControl {
    return new FormControl(value); // Manual validate
  }

  private getQuestionsControllValue(): any[] {
    const questions = [];
    if (!_.isEmpty(this.ivrEvaluation.question_data)) {
      for (const qData of this.ivrEvaluation.question_data) {
        const fileEntry: FileEntryEntity =
          qData.msg_id && this.em.getRepository<FileEntryRepository>('FileEntryRepository').getObjectById(qData.msg_id);
        const q: any = {
          msg: fileEntry,
          accepted_answer: qData.accepted_answer || []
        };

        questions.push(q);
      }
    }
    return questions;
  }
}
