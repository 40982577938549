import { Component, OnInit } from '@angular/core';
import { RoutingService } from './app.routing';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationError } from '@angular/router';
import { UIBlockerService } from '@wephone-utils';
import { TranslationService } from '@wephone-translation';
import { CommonCustomIconsService } from '@wephone-common/service';
import { FlexBaseComponent } from '@wephone-core-ui';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent extends FlexBaseComponent implements OnInit {
  constructor(
    translationService: TranslationService,
    routerService: RoutingService, // Must be injected
    private router: Router,
    private uiblockService: UIBlockerService,
    commonIconService: CommonCustomIconsService
  ) {
    super();
    console.log('Construct app component admin');
    translationService.setDefaultLang();
    commonIconService.registerCustomIcons();
  }

  ngOnInit(): void {
    console.log('Init app component admin');
    this.addSubscription(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationError) {
          console.error('NavigationError', event.error);
          this.router.navigateByUrl('s/404');
          return;
        }

        if (event instanceof RouteConfigLoadStart) {
          this.uiblockService.block();
        } else if (event instanceof RouteConfigLoadEnd) {
          this.uiblockService.unblock();
        }
      })
    );
  }
}
