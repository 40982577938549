import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';
import { QueueTransferNumberEntity } from '@wephone-core/model/entity/queue_transfernumber';
import { DidEntity } from '@wephone-core/model/entity/did';
import { IEntity } from '@wephone-core/model/model.interface';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { CallQueueItemEntity } from '@wephone-core/model/entity/callqueueitem';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { IvrEvaluationEntity } from './ivr_evaluation';
import { VoiceMailBoxEntity } from './voicemail_box';

export enum SurveyDirection {
  BOTH = 0, // Enable survey for both inbound/outbound calls
  INBOUND = 1, // 1: Only for inbound call
  OUTBOUND = 2, // 2: Only for outbound call
  NONE = 3, // 3: None
}
export interface ICallQueueEntity extends IEntity {
  id: number;

  queue_name: string;

  group_id: number;

  voicemail_id: number;

  voicemail_enabled: number;

  recording_mode: number;

  used_backup_phone: number;

  opening_hour_calendar_id: number;

  has_qualification: number;

  in_qualification_id: number;

  out_qualification_id: number;

  aftercall_pause_mode: number;

  is_archived: number;

  is_exclusive: number;

  agent_ids: number[];

  queue_priority: number;

  bo_url: string;

  logo_id: number;

  max_inqueue_time: number;

  after_call_time: number;

  bo_type: number;

  bo_in_new_tab: number;

  languages: string[];

  greeting_file_id: number;

  waiting_music_file_id: number;

  moh_file_id: number;

  opening_hour_file_id: number;

  no_agent_file_id: number;

  agent_selection_mode_main: number;

  agent_selection_mode_backup: number;

  voicemail_by_mail_enabled: number;

  voicemail_in_attachement: number;

  voicemail_by_mail_addrs: string[];

  alias: string;

  agent_display_number_type: number;

  agent_display_number_id: number;

  eoc_survey_id: number;

  eoc_survey_min_duration: number;

  default_did_id: number;

  play_entire_welcome: number;

  silent: number;

  xapi_agent_search: string;

  is_outbound_campaign: number;

  main_agents: CallQueueAgentLinkEntity[];
  backup_agents: CallQueueAgentLinkEntity[];
  transfer_numbers: QueueTransferNumberEntity[];
  did_list: DidEntity[];
  qualification_required: number;

  in_qualification: QualificationEntity;
  out_qualification: QualificationEntity;
  eoc_ivr_menu: IvrEvaluationEntity;
  is_outcall_campaign: boolean;
  opening_calendar: OpeningHourCalendarEntity;
  default_did: DidEntity;
  callqueue_items: CallQueueItemEntity[];
  logo: string;

  greeting_file: FileEntryEntity;
  waiting_music_file: FileEntryEntity;
  moh_file: FileEntryEntity;
  opening_hour_file: FileEntryEntity;
  no_agent_file: FileEntryEntity;
  agent_display_number: DidEntity;
  agent_ring_duration: number;
  seconds_before_backup: number;
  mailbox: VoiceMailBoxEntity;

  get_did_list(): DidEntity[];
  contain_agent_id(agent_id: number): boolean;
  belong_to_groups(group_ids: number[]): boolean;
  getTransferNumberList(): void;
  addNewTransferNumber(): void;
  agentIsMain(agent: AgentEntity): boolean;
}
