<div fxFill fxLayout="column" class="flex-container" *ngIf="customReportList?.length">
  <div align="center">
    <mat-form-field>
      <mat-label>{{ 'components.wallboard.filter_wallboard'|translate }}</mat-label>
      <mat-select [(ngModel)]="customReport" (selectionChange)="changeCustomReport()">
        <mat-option [value]="item" *ngFor="let item of customReportList">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="customReport">
    <custom-report-widget #customReportWidget class="custom-report-widget"
      [customReportConfig]="{dataSourceId: customReport.source_name, filters: customReport.filters}">
    </custom-report-widget>
  </ng-container>
</div>
