import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { IFlexDialogConfig, DialogActionButton, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentInOutState } from '@wephone-core/system';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentService } from '@wephone/services/agent.service';
import { AgentEntity } from '@wephone-core/model/entity/agent';

@Component({
  selector: 'app-agent-change-inout-state',
  templateUrl: './agent-change-inout-state.component.html',
  styleUrls: ['./agent-change-inout-state.component.scss']
})
export class AgentChangeInoutStateComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    hasBackdrop: true,
    size: 'xs',
  };

  dialogTitle = _tk('livecall.actions.change_inout_state');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.ok'),
      action: () => {
        return this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  // form: FormGroup;
  inOutStateList: AgentInOutState[] = [AgentInOutState.BOTH, AgentInOutState.INBOUND, AgentInOutState.OUTBOUND];

  constructor(
    dialogRef: MatDialogRef<AgentChangeInoutStateComponent>,
    private readonly agentService: AgentService,
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      agent: AgentEntity
    },
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.form = this.fb.group({
      inout_state: [this.data.agent.inout_state, Validators.compose([Validators.required])],
    });
  }

  async submitForm(): Promise<void> {
    try {
      if (!this.form.valid) {
        throw new Error(_ti('public.message.data_invalid'));
      }
      await this.agentService.set_inout_state(this.data.agent.id, this.form.get('inout_state').value);

      this.showInfo(_ti('public.message.update_success'));
      this.dismiss(null, false);
    } catch (e) {
      console.error('Change inout state error', e);
      this.showError(e.message || _ti('public.message.update_failure'));
    }
  }
}
