import * as _ from 'lodash';
import { ArchivableRepository } from '@wephone-core/model/repository/archivable_repository';

export class OutcallCampaignRepository extends ArchivableRepository {
  ENTITY_CLASS = OutcallCampaignEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'callcenter/outcallcampaign';

  protected METHOD_PATH_ENABLE = 'enable';
  protected METHOD_PATH_DISABLE = 'disable';
  protected METHOD_PATH_GET_PARTICULAR_AGENTS = 'get_particular_agents';

  private attr_general = [
    'id',
    'group_id',
    'name',
    'schedule_start_date',
    'schedule_end_date',
    'hour_start',
    'hour_end',
    'call_queue',
    'active'
  ];

  protected getURLAction(action: string): string {
    switch (action) {
      case 'ENABLE':
        return this.getUrlV2(this.METHOD_PATH_ENABLE);
      case 'GET_PARTICULAR_AGENTS':
        return this.getUrlV2(this.METHOD_PATH_GET_PARTICULAR_AGENTS);
      case 'DISABLE':
        return this.getUrlV2(this.METHOD_PATH_DISABLE);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'UPDATE_ATTRS_BULK':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS_BULK);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      case 'ARCHIVE':
        return this.getUrlV2(this.METHOD_PATH_ARCHIVE);
      default:
        return super.getURLAction(action);
    }
  }

  save(item: OutcallCampaignEntity, extra_data = null, attr_group: string = null): Promise<any> {
    if (!attr_group) {
      return super.save(item, extra_data, null);
    }

    let attr_list: string[];
    let action: string;
    switch (attr_group) {
      case 'general':
        attr_list = this.attr_general;
        action = item.getId() ? 'UPDATE' : 'ADD';
        break;
      default:
        throw new Error('Do not support for attribute group ' + attr_group);
    }

    const request_params = {};
    let i: number;
    for (i = 0; i <= attr_list.length; i++) {
      if (item[attr_list[i]] !== undefined) {
        request_params[attr_list[i]] = item[attr_list[i]];
      }
    }
    if (item.getId()) {
      request_params['id'] = item.getId();
    }

    const url = this.getURLAction(action);

    for (const agents of [request_params['backup_agents'], request_params['main_agents']]) {
      if (agents) {
        for (const queue_agent_link of agents) {
          delete queue_agent_link.queue;
        }
      }
    }

    return HttpEngine.getInstance()
      .post(url, request_params)
      .then((ret: any) => {
        item.setObjectData(ret.item, true);
        if (action === 'ADD') {
          this.object_list.push(item);
        }
        return ret;
      });
  }

  getParticularAgents(campaignId: number): Promise<ParticularAgent[]> {
    const url: string = this.getURLAction('GET_PARTICULAR_AGENTS');

    return HttpEngine.getInstance()
      .get(url, { campaign_id: campaignId });
  }

  changeActiveState(items: OutcallCampaignEntity[], state: CampaignActiveType): Promise<any> {
    const action: string = state === 1 ? 'ENABLE' : 'DISABLE';
    const url = this.getURLAction(action);
    const ids: number[] = new Array<number>();
    for (const item of items) {
      ids.push(item.getId());
    }
    return HttpEngine.getInstance()
      .post(url, { ids })
      .then((ret: any) => {
        if (ret.items) {
          for (const item of items) {
            const found = _.find(ret.items, { id: item.getId() });
            if (found) {
              item.active = state;
            }
          }
        }
        return ret;
      });
  }

  saveAttrs(item: OutcallCampaignEntity, attr_list: string[], extra_data: { [id: string]: any } = null): Promise<any> {
    if (attr_list && attr_list.length > 0) {
      if (item.queue_id) {
        attr_list.push('queue_id');
      }
    }
    return super.saveAttrs(item, attr_list, extra_data);
  }

  reload(): Promise<any> {
    return super.reload();
  }

  getObjectByQueue(queue: CallQueueEntity): OutcallCampaignEntity {
    return this.getObjectList().find(x => x.queue_id === queue.id) || null;
  }

  getObjectListByGroupId(group_id: number): OutcallCampaignEntity[] {
    const ret: OutcallCampaignEntity[] = [];
    for (const campaign of this.getObjectList()) {
      const queue: CallQueueEntity = CallQueueRepository.getInstance().getObjectById(campaign.queue_id);
      if (!queue) {
        console.error('No queue with id', campaign.queue_id);
        continue;
      }
      if (queue.group_id === group_id) {
        ret.push(campaign);
      }
    }

    return ret;
  }
}

import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { CallQueueEntity } from '../entity/callqueue';
import { CallQueueRepository } from './callqueue';
import { CampaignActiveType, ParticularAgent } from './outcallcampaign.i';
