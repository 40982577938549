import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class RoutingAppCallUser extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.call_user;
  private user: UserEntity;

  setUser(user: IUserEntity) {
    this.setAppParams({ id: user.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.id) {
      this.user = EntityManager.getRepository('UserRepository').getObjectById(this.appParams.id);
    }
  }

  getRelatedObject(): UserEntity {
    return this.user;
  }

  getDescription() {
    return 'To user ' + (this.user ? this.user.name : '<invalid>');
  }
}
