import { Component, OnInit, ElementRef, Self, ViewChild, Input, Optional, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FlexMatInputWrapper, ToastService } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { _ti } from '@wephone-translation';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'flex-duration-input',
  templateUrl: './flex-duration-input.component.html',
  styleUrls: ['./flex-duration-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexDurationInput }]
})
export class FlexDurationInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() predefined_values?: Record<number|string, string>; // {60: 'One minute', 120: 'Two minute'}
  @Output() readonly valueChange = new EventEmitter<number>();

  @ViewChild('durationSelect', { static: true }) durationSelect: MatSelect;
  @ViewChild('durationCustomOption', { static: true }) durationCustomOption: MatOption;

  flexDurationControl = new FormControl();
  hasPredefinedSelected = false;

  customMinute: number;
  customSecond: number;

  refreshed = true;

  constructor(
    elRef: ElementRef,
    @Optional() @Self() ngControl: NgControl,
    private readonly translate: TranslateService
  ) {
    super(ngControl, elRef);
    this.predefined_values = {};
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Refresh the pipe seconds_as_duration_text when language changes to fix the bug of not updating the duration text
    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.refreshed = false;
      setTimeout(() => {
        this.refreshed = true;
      });
    });

    this.flexDurationControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(val => {
      this.setCustomOptionValue();

      // Fix bug reset form control
      const selectedOption = this.durationSelect.options.find(o => o.value === val);
      if (selectedOption && !selectedOption.selected) {
        selectedOption.select();
      }

      if (this.valueChange) {
        this.valueChange.emit(this.value);
      }
    });

    this.setCustomOptionValue();
  }

  get wrappedControl(): FormControl {
    return this.flexDurationControl;
  }

  get controlType(): string {
    return 'flex-duration-input';
  }

  get predefinedValues(): number[] {
    return this.predefined_values && this.getObjectKeys(this.predefined_values).map(x => +x) || [];
  }

  writeValue(val: any): void {
    super.writeValue(val);
    this.setCustomValue(val);
  }

  private setCustomValue(val: any): void {
    const secs = parseInt(val);
    const dt = DateTime.fromMillis(secs * 1000);

    this.customMinute = dt.minute;
    this.customSecond = dt.second;
  }

  private setHasPredefinedSelectedOption(): void {
    if (_.isEmpty(this.predefined_values) || _.isUndefined(this.flexDurationControl.value)) {
      this.hasPredefinedSelected = false;
    } else {
      this.hasPredefinedSelected = _.includes(this.predefinedValues, parseInt(this.flexDurationControl.value));
    }
  }

  private setCustomOptionValue(): void {
    this.setHasPredefinedSelectedOption();

    if (!this.hasPredefinedSelected) {
      this.durationCustomOption.value = this.flexDurationControl.value;
    }
  }

  getObjectKeys(object: any): number[] {
    return Object.keys(object).map(i => parseInt(i, 10));
  }

  setCustomOption(min?: any, sec?: any): void {
    const seconds: number = parseInt(min || 0) * 60 + parseInt(sec || 0);
    this.durationCustomOption.value = seconds;
    this.flexDurationControl.setValue(seconds);

    this.setHasPredefinedSelectedOption();
    this.durationSelect.close();
  }

  compareWith = (a: any, b: any): boolean => {
    return a !== undefined && b !== undefined && a === b ? true : false;
  }
}
