
export enum SyncObjectType {
    CRM = 1
}

export enum SyncTaskType {
    SyncRoom = 'sync_room',
    SyncUser = 'sync_user'
}

export enum SyncTaskStatus {
    NotRunYet = 0,
    Running = 1,
    Success = 2,
    Failed = 3,
}
