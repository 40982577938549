import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IvrMenuType } from '@wephone-core/model/repository/ivr_custom_menu.i';
import * as _ from 'lodash';

@Component({
  selector: 'ivr-menu-select-form-input',
  templateUrl: './ivr-menu-select.component.html',
  styleUrls: ['./ivr-menu-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: IvrMenuSelectFormInput }]
})
export class IvrMenuSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<IvrCustomMenuEntity> {
  static nextId = 0;
  @Input() menuType: IvrMenuType;
  @Input() isConfigured: boolean;
  @Input() multiple: boolean;
  @Input() excludeIds: number[];

  flexSelect: FormControl = new FormControl();
  list: IvrCustomMenuEntity[];
  flexSelectOptions: IFlexSelectOptions;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: IvrCustomMenuEntity, filterString: string) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: IvrCustomMenuEntity, b: IvrCustomMenuEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.menuType === undefined) {
      this.menuType = IvrMenuType.IvrMenu;
    }

    this.setIvrMenuList();
  }

  setIvrMenuList(): void {
    const menuRepo = EntityManager.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository');
    let list = this.menuType === IvrMenuType.IvrMenu ? menuRepo.getMenuList() : menuRepo.getObjectList();

    if (this.isConfigured) {
      list = list.filter(i => i.isConfigured && !!i.routing_status);
    }

    if (this.excludeIds && this.excludeIds.length) {
      list = list.filter(i => !_.includes(this.excludeIds, i.id));
    }

    this.list = list;
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  get controlType(): string {
    return 'ivr-menu-select-form-input';
  }
}
