import { Entity } from '@wephone-core/model/model';

export class CallingProfileEntity extends Entity {
  static object_type_id = 'calling_profile';
  static CALLING_PROFILES = {
    internal: 1,
    national: 2,
    national_full: 3,
    international: 4
  };

  name: string;

  setId(id: number): void {
    this.id = id;
  }
}
