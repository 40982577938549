import { datafield, Entity } from '@wephone-core/model/model';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { OpeningHourRepository } from '@wephone-core/model/repository/openinghour';
import { OpeningHourSpecialDateRepository } from '@wephone-core/model/repository/openinghour_special_date';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { RoutingAppManager } from '@wephone-core/routing-app/routing-app-manager';
import { IRoutingApp } from '@wephone-core/routing-app/routing-app.interface';
import { IOpeningHourCalendarEntity } from './openinghour_calendar.i';
import { parseDateTime } from '@wephone-utils';

export enum LinkedObjectType {
  LINK_OBJECT_DID = 1, // time based dependent for did
  LINK_OBJECT_USER = 2 // time based dependent for user
}

export class OpeningHourCalendarEntity extends Entity implements IOpeningHourCalendarEntity {
  static object_type_id = 'opening_hour_calendar';

  id: number;
  @datafield
  name: string;
  @datafield
  group_id: number = null;
  @datafield
  enterprise_id: number;
  @datafield
  is_private: number;
  @datafield
  link_object_type: LinkedObjectType;
  @datafield
  link_object_id: number;
  @datafield
  link_object_order: number;
  @datafield
  data: any;

  creation_dt: any;

  openinghours: OpeningHourEntity[] = [];
  openinghours_special_date: OpeningHourSpecialDateEntity[] = [];

  dataDescription: string;

  parsedObject: any;

  get group(): UserGroupEntity {
    if (this.group_id) {
      return UserGroupRepository.getInstance().getObjectById(this.group_id);
    }
  }

  set group(group) {
    if (!group) {
      this.group_id = null;
      return;
    }

    this.group_id = group.id;
  }

  getRoutingApp(): IRoutingApp {
    if (this.link_object_type === LinkedObjectType.LINK_OBJECT_DID) {
      return this.parsedObject;
    }
  }

  setRoutingApp(data): void {
    this.data = data;
    this.parseData();
  }

  private parseData(): void {
    if (!this.data || this.data === 'null') {
      this.parsedObject = null;
      return;
    }

    if (this.link_object_type === LinkedObjectType.LINK_OBJECT_DID) {
      if (typeof this.data === 'string') {
        const app = JSON.parse(this.data);

        if (app && app.application) {
          this.parsedObject = RoutingAppManager.getInstance().createAppInstanceFromData(app);
        } else {
          console.error('Invalid routing data', app);
        }
      }
    }
  }

  fetchRelatedData(): void {
    this.parseData();
    this.updateList(
      this.openinghours,
      OpeningHourRepository.getInstance<OpeningHourRepository>().getWeeklyOpeningHourByCalendarId(this.id)
    );
    this.updateList(
      this.openinghours_special_date,
      OpeningHourSpecialDateRepository.getInstance<OpeningHourSpecialDateRepository>().getObjectListByCalendarId(
        this.id
      )
    );
  }

  addOpeningHour(openinghour?: OpeningHourEntity): OpeningHourCalendarEntity {
    // if (!openinghour) {
    //   openinghour = new OpeningHourEntity();
    // }
    this.openinghours.push(openinghour || new OpeningHourEntity());
    return this;
  }

  exportData(): any {
    const result = super.dumpObjectData(null, true);
    result['openinghours'] = this.openinghours;
    result['openinghours_special_date'] = this.openinghours_special_date;
    return result;
  }

  setObjectData(object_data: any, fetch_related_data = true): void {
    super.setObjectData(object_data, fetch_related_data);

    this.creation_dt = this.creation_dt && parseDateTime(this.creation_dt).toISO();
  }
}
