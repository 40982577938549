import { Entity, datafield } from '@wephone-core/model/model';

export class SkillEntity extends Entity {
  public static object_type_id: string = 'skill';
  public static MAX_LEVEL: number = 10;

  @datafield
  public group_id: number;

  @datafield
  public name: string;

  get group_name() {
    let group_names = [];
    let parentsgroup = EntityManager.getInstance()
      .getRepository<any>('SkillRepository')
      .getParentGroups(this.group_id);
    for (let parentgroup of parentsgroup) {
      group_names.push(parentgroup.name);
    }
    return group_names.join(' > ');
  }
}

import { EntityManager } from '@wephone-core/service/entity_manager';
