import * as _ from 'lodash';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { TranslateService } from '@ngx-translate/core';
import { ToastService, DialogActionButton, Colors, FormService } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrSubMenuComponent } from '@wephone/components/ivr-menu/ivr-sub-menu/ivr-sub-menu.component';

@Component({
  selector: 'ivr-sub-menu-edit-dialog',
  templateUrl: './ivr-sub-menu-edit-dialog.html',
  styleUrls: ['./ivr-sub-menu-edit-dialog.scss']
})
export class IvrSubMenuEditDialog extends DialogComponentBase implements OnInit {
  @ViewChild('ivrSubMenuForm') ivrSubMenuForm: IvrSubMenuComponent;

  dialogTitle = _tk('ivrmenu.title.edit');
  dialogLeftActions: DialogActionButton[] = [];
  dialogRightActions: DialogActionButton[] = [
    {
      id: 'validate',
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  originIvrSubMenu: IvrCustomMenuEntity;
  ivrSubMenu: IvrCustomMenuEntity;
  excludeIvrMenuId: number;

  constructor(
    dialogRef: MatDialogRef<IvrSubMenuEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formService: FormService,
  ) {
    super(dialogRef);
  }

  resolveData(): void {
    this.originIvrSubMenu = _.cloneDeep(this.data.ivrCustomMenu);
    this.ivrSubMenu = _.cloneDeep(this.originIvrSubMenu);
    this.excludeIvrMenuId = this.data.excludeIvrMenuId;
  }

  updateSubMenuParam(ivrMenu: IvrCustomMenuEntity): void {
    this.ivrSubMenu = ivrMenu;
  }

  submit(): void {
    if (!this.ivrSubMenuForm.validateForm()) {
      this.showError(_ti('public.message.data_invalid'));
      this.updateDialogLayout();
      return;
    }

    if (!this.ivrSubMenu) {
      this.showError(_ti('ivrmenu.validator.data_required'));
      return;
    }

    if (!this.formService.validateMenuData(this.ivrSubMenu.ivrscript_data)) {
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    this.close(this.ivrSubMenu);
  }

  close(result?: any): void {
    this.dismiss(result);
  }
}
