import { datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { Entity } from '@wephone-core/model/model';
import { ContactFormatFieldEntity } from '@wephone-core/model/entity/contact_format_field';
import { cloneDeep } from 'lodash';
import { _tk, _ti } from '@wephone-translation';

export class ContactFormatEntity extends Entity {
  public static object_type_id: string = 'contact_format';

  public static STANDARD_FIELDS: Array<any> = [
    {
      label: _tk('enterprise_contact.content.first_name'),
      field: 'first_name'
    },
    {
      label: _tk('enterprise_contact.content.last_name'),
      field: 'last_name'
    },
    {
      label: _tk('enterprise_contact.content.tel'),
      field: 'phone_number'
    },
    {
      label: _tk('enterprise_contact.content.email'),
      field: 'email'
    },
    {
      label: _tk('enterprise_contact.content.gender'),
      field: 'gender'
    },
    {
      label: _tk('enterprise_contact.content.description'),
      field: 'description'
    }
    //            ,
    //            {
    //                label: "enterprise_contact.content.address",
    //                field: "address"
    //            }
  ];

  public static DATA_TYPE: any = {
    string: 'data_type.string',
    number: 'data_type.number',
    date: 'data_type.date'
  };

  public static FORMAT_CONTACT_MAPPING: any = {
    first_name: 'first_name',
    last_name: 'last_name',
    phone_number: 'tel_work',
    email: 'email',
    gender: 'gender',
    description: 'description',
    address: 'address_work'
  };

  @datafield
  public campaign_id: number;
  @datafield
  public enterprise_id: number;
  @datafield
  public name: string;

  public contact_format_fields: Array<ContactFormatFieldEntity> = [];

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    let contact_format_fields = cloneDeep(object_data.contact_format_fields);

    this.contact_format_fields.splice(0, this.contact_format_fields.length);

    //add the contact format field
    for (let new_item_data of contact_format_fields) {
      let new_item_obj = new ContactFormatFieldEntity();
      new_item_obj.setObjectData(new_item_data, false);
      this.addContactFormatField(new_item_obj);
    }

    this.contact_format_fields.sort((field_1, field_2) => {
      let type_1 = field_1.standard_field_id ? 1 : 0;
      let type_2 = field_2.standard_field_id ? 1 : 0;
      return type_1 == type_2 ? field_1.position - field_2.position : type_1 - type_2;
    });
  }

  public addContactFormatField(contact_format_field) {
    //:ContactFormatFieldEntity
    let found = false;
    for (let format_field of this.contact_format_fields) {
      if (format_field.getId() == contact_format_field.getId()) {
        found = true;
        break;
      }
    }
    if (!found) {
      this.contact_format_fields.push(contact_format_field);
    }
  }

  public removeContactFormatFieldFromId(contact_format_field_id) {
    //:ContactFormatFieldEntity
    let found: any = null;
    for (let format_field of this.contact_format_fields) {
      if (format_field.getId() == contact_format_field_id) {
        found = format_field;
        break;
      }
    }
    if (found) {
      this.contact_format_fields.splice(this.contact_format_fields.indexOf(found), 1);
    }
  }

  get custom_fields() {
    let ret = [];
    for (let contact_format_field of this.contact_format_fields) {
      if (!contact_format_field.standard_field_id) {
        ret.push(contact_format_field);
      }
    }
    return ret;
  }

  get standard_fields() {
    let ret = [];
    for (let contact_format_field of this.contact_format_fields) {
      if (contact_format_field.standard_field_id) {
        ret.push(contact_format_field);
      }
    }
    return ret;
  }

  public getFieldObjectById(contact_format_field_id: number) {
    for (let contact_format_field of this.contact_format_fields) {
      if (contact_format_field.getId() == contact_format_field_id) {
        return contact_format_field;
      }
    }
    return null;
  }
}
