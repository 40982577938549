import { datafield, Entity } from '@wephone-core/model/model';
// import { EntityManager } from '@wephone-core/service/entity_manager';
// import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
// import { CallQueueSessionEntity } from '@wephone-core/model/entity/callqueuesession';
import { parseDateTime } from '@wephone-utils';
// import { CallQueueSessionRepository } from '@wephone-core/model/repository/callqueuesession';
// import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
// import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';

export class LiveUserCallEntity extends Entity {
  public static object_type_id = 'live_user_call';

  public id: number;
  @datafield public peer_call_id: number;
  @datafield public enterprise_id: number;
  @datafield public user_call_id: number;
  @datafield public user_id: number;
  @datafield public did_id: number;
  @datafield public call_queue_id: number;
  @datafield public group_id: number;
  @datafield public call_queue_session_id: number;
  @datafield public call_queue_item_id: number;
  @datafield public transfer_in_user_id: number;
  @datafield public sip_call_id: string;
  @datafield public remote_number: string;
  @datafield public local_number: string;
  @datafield public client_name: string;
  @datafield public start_time: any;
  @datafield public answer_time: any;
  @datafield public outbound: number;
  @datafield public onhold_since: any;
  @datafield public transfer_state: number; // 0: No transfer. 1: Transfering to new destination
  @datafield public transfer_in_reason: string;
  @datafield public transfer_in_type: number; // 0: Not assisted. 1: Assisted
  @datafield public recording_state: number; // 0: No  recording. 1: Recording paused. 2: Recording active
  @datafield public crm_id: number;
  @datafield public crm_contact_id: string;
  @datafield public outcall_campaign_id: number;
  @datafield public outcall_job_id: number;
  @datafield public outcall_task_id: number;
  @datafield public server_id: number;
  @datafield public is_bridged: number;

  // ------------- local attributes -------------------
  // get queue(): CallQueueEntity {
  //   return EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(
  //     this.call_queue_keyid
  //   ) as CallQueueEntity;
  // }

  // set queue(queue: CallQueueEntity) {
  //   this.call_queue_keyid = this.queue ? queue.id : undefined;
  // }

  // get session(): CallQueueSessionEntity {
  //   return EntityManager.getRepository<CallQueueSessionRepository>(
  //     'CallQueueSessionRepository'
  //   ).getObjectByCallQueueItemId(this.id);
  // }

  // get bridged_dt() {
  //   if (this.session && this.session.bridged_dt) {
  //     return this.session.bridged_dt;
  //   }
  //   return undefined;
  // }

  // get group() {
  //   if (this.group_id) {
  //     return UserGroupRepository.getInstance().getObjectById(this.group_id);
  //   }
  //   return undefined;
  // }

  // get waiting_minutes(): number {
  //   if (this.arrive_dt) {
  //     const now = new Date().getTime();
  //     return (now - this.arrive_dt.toJSDate().getTime()) / 60 / 1000;
  //   }
  //   return undefined;
  // }

  // public fetchRelatedData() {
  // this.queue = CallQueueRepository.getInstance().getObjectById(this.call_queue_keyid);
  // }

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    const datetime_field_list: string[] = ['start_time', 'answer_time', 'onhold_since'];
    for (const field of datetime_field_list) {
      this[field] = this[field] && parseDateTime(this[field]);
    }
  }
}
