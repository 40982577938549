import { datafield, mapped_datafield, Entity } from '@wephone-core/model/model';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { ContactFormatRepository } from '@wephone-core/model/repository/contact_format';
import { ContactFormatEntity } from './contact_format';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { localNow, parseDateTime } from '@wephone-utils';
import { IEntity } from '../model.interface';
import { DidEntity } from './did';
import { DidRepository } from '../repository/did';
import { DateTime } from 'luxon';
// import { AgentEntity } from './agent';
import { GroupEntity } from './group';
import { UserEntity } from './user';
import { CampaignType, OutcallCampaignState, OutcallCampaignaAMAction } from './outcallcampaign.i';


export class OutcallCampaignEntity extends Entity implements IEntity {
  static object_type_id = 'outcallcampaign';

  id: number;
  @mapped_datafield('campaign_name')
  name: string;
  //        @datafield
  //        group_id:number;
  @mapped_datafield('start_dt')
  start_date;
  @mapped_datafield('schedule_start_dt')
  schedule_start_date: DateTime;
  @mapped_datafield('schedule_end_dt')
  schedule_end_date: DateTime;
  @datafield
  hour_start: string;
  @datafield
  hour_end: string;
  @datafield
  alias: string;
  @datafield
  contact_format_id: number;
  @mapped_datafield('call_queue_keyid')
  queue_id: number;
  @datafield
  user_id: number;
  @datafield
  active: number;
  @datafield
  status: number;
  @datafield
  is_archived: number;
  @datafield
  ivr_id: number;
  @datafield
  campaign_type: CampaignType;
  @datafield
  calling_number_id: number;
  @datafield
  max_try_count: number;
  @datafield
  am_check_enabled: number;
  @datafield
  am_check_max_duration: number;
  @datafield
  am_action: OutcallCampaignaAMAction;

  campaign_status: OutcallCampaignState;

  private _queue: CallQueueEntity;

  private setCampaignStatus(): void {
    if (this.active) {
      // const current = new Date();
      // current.setHours(0, 0, 0, 0);
      // const currentDateTime: DateTime = DateTime.fromJSDate(current);

      // if (!this.schedule_end_date || this.schedule_end_date >= currentDateTime) {
      this.campaign_status = OutcallCampaignState.IN_PROGRESS;
      return;
      // }

      // this.campaign_status = OutcallCampaignState.NOT_PROGRESS;
      // return;
    }

    if (this.is_archived) {
      this.campaign_status = OutcallCampaignState.ARCHIVED;
      return;
    }

    if (this.status) {
      if (this.schedule_start_date && this.start_date) {
        this.campaign_status = OutcallCampaignState.FINISHED;
        return;
      }

      this.campaign_status = OutcallCampaignState.READY;
      return;
    }

    this.campaign_status = OutcallCampaignState.NOT_READY;
  }

  get call_agent_first(): boolean {
    return this.campaign_type === CampaignType.CallAgentFirst;
  }

  get is_finished(): boolean {
    return this.campaign_status === OutcallCampaignState.FINISHED;
  }

  get is_valid_scheduled_date(): boolean {
    const now = localNow();
    const todayDate: number = +now.toFormat('yyyyMMdd');
    const endDate = this.schedule_end_date;
    const startDate = this.schedule_start_date;

    const scheduleEndDate: number = endDate && +endDate.toFormat('yyyyMMdd');
    const scheduleStartDate: number = startDate && +startDate.toFormat('yyyyMMdd');

    if (scheduleEndDate) {
      if (scheduleEndDate < todayDate) {
        return false;
      }

      if (scheduleStartDate && scheduleStartDate > scheduleEndDate) {
        return false;
      }
    }

    return true;
  }

  get is_valid_scheduled_datetime(): boolean {
    return this.is_valid_scheduled_time && this.is_valid_scheduled_date;
  }

  get is_valid_queue_agent(): boolean {
    const queue: CallQueueEntity = this.queue;
    if (!queue || !queue.totalAgentNum) {
      console.error('No queue or no agent in queue');
      return false;
    }

    return true;
  }

  get is_valid_activated(): boolean {
    // Has valid scheduled date time
    if (!this.is_valid_scheduled_datetime) {
      console.error('Invalid scheduled time or date');
      return false;
    }

    // Has agent handling campaign
    if (!this.is_valid_queue_agent) {
      console.error('No queue or no agent in queue');
      return false;
    }

    return true;
  }

  get is_valid_scheduled_time(): boolean {
    const now = localNow();
    const todayTime: number = +now.toFormat('HHmm');
    const todayDate: number = +now.toFormat('yyyyMMdd');

    const endDate = this.schedule_end_date;
    const startDate = this.schedule_start_date;

    const endHour = this.hour_end;
    const startHour = this.hour_start;

    const scheduleEndDate: number = endDate && +endDate.toFormat('yyyyMMdd');
    const scheduleStartDate: number = startDate && +startDate.toFormat('yyyyMMdd');

    const hourEndTime: number = endHour && +parseDateTime(endHour).toFormat('HHmm');
    const hourStartTime: number = startHour && +parseDateTime(startHour).toFormat('HHmm');

    // Start Date = End Date and Start Time > End Time
    if (scheduleEndDate && scheduleStartDate &&
      scheduleEndDate === scheduleStartDate &&
      hourStartTime && hourEndTime && hourStartTime > hourEndTime
    ) {
      return false;
    }

    // End Date = Today Date and Today Time > End Time
    if (scheduleEndDate && todayDate === scheduleEndDate && hourEndTime && hourEndTime < todayTime) {
      return false;
    }

    return true;
  }

  get group(): GroupEntity {
    if (!this.queue) {
      return null;
    }

    return this.queue.group;
  }

  get queue(): CallQueueEntity {
    if (this.queue_id) {
      if (!this._queue) {
        this._queue = EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(this.queue_id);
      }
      return this._queue;
    }
  }

  set queue(queue: CallQueueEntity) {
    this.queue_id = queue ? queue.id : undefined;
    this._queue = queue;
  }

  get ivr(): IvrCustomMenuEntity {
    if (this.ivr_id) {
      return EntityManager.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getObjectById(this.ivr_id);
    }
  }

  set ivr(ivr: IvrCustomMenuEntity) {
    if (ivr.id) {
      this.ivr_id = ivr.id;
    }
  }

  get callingNumber(): DidEntity {
    if (this.calling_number_id) {
      return EntityManager.getRepository<DidRepository>('DidRepository').getObjectById(this.calling_number_id);
    }
  }

  set callingNumber(did: DidEntity) {
    if (did.id) {
      this.calling_number_id = did.id;
    }
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    const date_field_list = ['schedule_start_date', 'schedule_end_date', 'start_date'];
    for (const field of date_field_list) {
      this[field] = this[field] && parseDateTime(this[field]);
    }

    /*
        const time_field_list: Array<string> = ['hour_start', 'hour_end'];
        for (const field of time_field_list) {
          this[field] = this[field] ? parseStringWithFormat(this[field], 'hh:mm:ss').toFormat('hh:mm') : undefined;
        }
    */
    this.setCampaignStatus();
  }

  //        fetchRelatedData() {
  //            if (this.queue_id) {
  //                this.queue = CallQueueRepository.getInstance().getObjectById(this.queue_id);
  //            }
  //        }

  get contact_format(): ContactFormatEntity {
    if (this.contact_format_id) {
      return EntityManager.getRepository<ContactFormatRepository>('ContactFormatRepository').getObjectById(this.contact_format_id);
    }
  }

  isCallAgentFirst(): boolean {
    return this.campaign_type === 0;
  }

  isCallClientFirst(): boolean {
    return this.campaign_type === 1;
  }

  isOwner(user: UserEntity): boolean {
    return this.user_id === user.id;
  }
}
