import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';

export class CreditRechargeEntity extends Entity {
  public static object_type_id: string = 'credit_recharge';

  public id: number;
  @datafield
  public recharge_dt: any;
  @datafield
  public payment_method: string;
  @datafield
  public credit: number;
  @datafield
  public enterprise_id: number;

  get currency() {
    return 'EUR';
  }
}
