import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { SingletonBase } from '@wephone-utils';
import { StorageService } from '@wephone-core/service/storage.service';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';

@Injectable()
export class CommonAPI extends SingletonBase {

  public static httpClient;
  public static settings;
  // public static $log;
  // public static $filter;
  // public static $rootScope;
  // public static $timeout;
  // public static toaster;
  // public static localStorageService;

  public static APP_SETTINGS;

  private static _dialog_dict = {};

  constructor(private http: HttpClient, private storage: StorageService, private settings: FlexIvrSettings) {
    super();
    CommonAPI.httpClient = this.http;
    CommonAPI.settings = this.settings;
  }

  //--- HTTP request helper functions
  public static wsCall(method: string, url: string, params_in: any): Promise<any> {
    var params = {};
    if (params_in) {
      _.extend(params, params_in);
    }

    if (CommonAPI.settings) {
      url = CommonAPI.settings.getAbsoluteUrl(url);
    }

    if (method === 'GET') {
      // return CommonAPI.$http.get(url, {params: params});
      return new Promise((resolve, reject) => {
        if (CommonAPI.httpClient) {
          CommonAPI.httpClient.get(url, { params }).subscribe(data => resolve(data), err => reject(err));
        }
      });
    } else if (method === 'POST') {
      // return CommonAPI.$http.post(url, params);
      return new Promise((resolve, reject) => {
        CommonAPI.httpClient.post(url, params).subscribe(data => resolve(data), err => reject(err));
      });
    } else if (method === 'DELETE') {
      // return CommonAPI.$http.delete(url, {params: params});
      return new Promise((resolve, reject) => {
        CommonAPI.httpClient.delete(url, { params: params }).subscribe(data => resolve(data), err => reject(err));
      });
    } else {
      throw new Error('Unknown method ' + method);
    }
  }

  public static apiGet(url: string, params: any): Promise<any> {
    return this.wsCall('GET', CommonAPI.settings.getApiUrl(url), params);
  }

  public static apiPost(url: string, params: any): Promise<any> {
    return this.wsCall('POST', CommonAPI.settings.getApiUrl(url), params);
  }

  public static wsDelete(url: string, params: any): Promise<any> {
    return this.wsCall('DELETE', url, params);
  }

  public static wsGet(url: string, params: any): Promise<any> {
    return this.wsCall('GET', url, params);
  }

  public static wsPost(url: string, params: any): Promise<any> {
    return this.wsCall('POST', url, params);
  }

  public static isEmptyObject(obj): boolean {
    return _.isEqual({}, obj);
  }

  public static clearObject(obj): void {
    for (var member in obj) {
      delete obj[member];
    }
  }

  public static merge(dest, src): any {
    return _.merge(dest, src);
  }

  public static extend(dest, src): any {
    return _.extend(dest, src);
  }

  public static clone(src): any {
    return _.cloneDeep(src);
  }

  public static cloneArray(arr): any {
    if (arr) {
      return arr.slice(0);
    }
    return;
  }

  private static getKeyLocalStorage(key: string, for_domain: boolean = false): string {
    if (for_domain) {
      let domain = '';
      const myEnterprise = EntityManager.getInstance()
        .getRepository<any>('EnterpriseRepository')
        .getMyEnterprise();
      if (myEnterprise) {
        domain = myEnterprise.domain;
      }
      key += '_domain_' + domain;
    }
    return key;
  }

  public static async localSettingsGet(
    object_name: string,
    key: string = '',
    for_domain: boolean = false,
    default_value: any = null
  ): Promise<any> {
    const local_object = await CommonAPI.getInstance().storage.getAppConfig(object_name);
    if (!key) {
      return local_object ? local_object : default_value;
    }
    const key_domain = CommonAPI.getKeyLocalStorage(key, for_domain);
    return local_object && local_object[key_domain] !== undefined ? local_object[key_domain] : default_value;
  }

  public static async localSettingsSet(object_name: string, key: string, value: any, for_domain: boolean = false): Promise<any> {
    let local_object = await CommonAPI.getInstance().storage.getAppConfig(object_name);
    if (!local_object) {
      local_object = {};
    }
    const key_domain = CommonAPI.getKeyLocalStorage(key, for_domain);
    local_object[key_domain] = value;
    await CommonAPI.getInstance().storage.setAppConfig(object_name, local_object);
  }
}
