import { DateTime, Duration } from 'luxon';
// import { parseDate } from 'tough-cookie';

export function compareTimeStr(time1: string, time2: string): number {
    const dt1 = DateTime.fromSQL(time1);
    const dt2 = DateTime.fromSQL(time2);
    if (dt1 > dt2) {
        return 1;
    } else if (dt1 < dt2) {
        return -1;
    }
    return 0;
}

export function parseDateTime(timeObj): DateTime {

    if (timeObj instanceof DateTime) {
        return timeObj;
    }
    if (timeObj instanceof Date) {
        return DateTime.fromJSDate(timeObj);
    }

    let ret;
    if (typeof timeObj === 'string' || timeObj instanceof String) {
        try {
            ret = DateTime.fromISO(timeObj as string);
        } catch (e) {
        }

        if (!ret || !ret.isValid) {
            try {
                ret = DateTime.fromSQL(timeObj as string);
            }  catch (e) {
            }
        }
        if (ret && ret.isValid) {
            return ret;
        }
    }
}

export function dateToIsoString(obj): string {
    const t = parseDateTime(obj).toLocal();
    return t.toISO();
}

export function parseStringWithFormat(timeString: string, format: string): DateTime {
    // For format string see https://moment.github.io/luxon/docs/manual/parsing.html#table-of-tokens
    return DateTime.fromFormat(timeString, format);
}

export function isInThePast(timeObj): boolean {
    const t = parseDateTime(timeObj);
    return DateTime.utc() > t;
}

export function localNow(): DateTime {
    return DateTime.local();
}

export function utcNow(): DateTime {
    return DateTime.utc();
}

export function fromEpoch(secondsFromEpoch: number): DateTime {
    const dateObj = DateTime.utc();
    return DateTime.fromMillis(secondsFromEpoch * 1000, {zone: dateObj.zone.name});
}

export function durationFromSeconds(seconds: number): Duration {
    return Duration.fromMillis(seconds * 1000);
}

/**
 * fulltime: 12:12:12
 * @returns: 12:12
 */
export function toTimeHour(fulltime: string): string {
    if (!fulltime) {
        return;
    }
    const strSplit: string[] = fulltime.split(':');
    if (strSplit.length > 3 || strSplit.length < 2) {
        throw new Error('Argument should be HH:ii:ss');
    }
    return `${strSplit[0]}:${strSplit[1]}`;
}
