import { datafield, Entity } from '@wephone-core/model/model';
import { InvoiceItemEntity } from '@wephone-core/model/entity/invoice_item';
import { InvoiceItemRepository } from '../repository/invoice_item';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { parseDateTime } from '@wephone-utils';
import { _ti } from '@wephone-translation';

export enum InvoicePdfState {
  READY = 2,
  INPROGRESS = 1,
  NOT_READY = 0
}

export enum InvoiceType {
  MONTHLY = 0,
  BUY_NUMBER = 1,
  MANUAL = 2,
}

export enum InvoicePaymentMethod {
  UNKNOWN = 0,
  WIRE_TRANSFER = 1,
  CREDIT_CARD = 2,
  DEBIT_ORDERDIRECT = 3
}

export class InvoiceEntity extends Entity {
  static object_type_id = 'invoice';

  id: number;
  @datafield
  invoice_dt: any;
  @datafield
  email_last_sent_dt: any;
  @datafield
  currency: string;
  @datafield
  enterprise_id: number;
  @datafield
  didorder_id: number;
  @datafield
  start_dt: any;
  @datafield
  end_dt: any;
  @datafield
  vat: number;
  @datafield
  vat_total: number; // Is tax but calculated in server
  @datafield
  sub_total: number; // = subtotal of items but stored in db, not in used
  @datafield
  pdf_state: InvoicePdfState;
  @datafield
  seen: number;
  @datafield
  is_paid: number;
  @datafield
  invoice_type: InvoiceType;
  @datafield
  invoice_number: string;
  @datafield
  payment_status: string;
  @datafield
  last_payment_attempt_id: number;
  @datafield
  last_payment_failure_reason: string;
  @datafield
  payment_date: any;
  @datafield
  payment_method: InvoicePaymentMethod;

  get invoice_items(): InvoiceItemEntity[] {
    const invoice_items = EntityManager.getRepository<InvoiceItemRepository>('InvoiceItemRepository').getObjectList().filter(
      x => x.invoice_id === this.id
    );
    return invoice_items;
  }

  // Is sub_total but calculated using sub-items
  get items_total(): number {
    let sub_total = 0;
    if (this.invoice_items) {
      for (const item of this.invoice_items) {
        sub_total += item.item_total;
      }
    }
    return sub_total;
  }

  get total(): number {
    const total: number = this.items_total + this.tax;
    return Number(total.toFixed(3));
  }

  get tax(): number {
    return this.items_total * this.vat;
  }

  get isPdfReady(): boolean {
    return this.pdf_state === InvoicePdfState.READY;
  }

  get vat_percent(): number {
    return (this.vat || 0) * 100;
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    const dates = ['invoice_dt', 'email_last_sent_dt', 'start_dt', 'end_dt', 'payment_date'];
    for (const d of dates) {
      if (object_data[d]) {
        this[d] = parseDateTime(object_data[d]).toJSDate();
      }
    }

    if (object_data.invoice_items) {
      console.log('object_data.invoice_items', object_data.invoice_items);
      EntityManager.getRepository<InvoiceItemRepository>('InvoiceItemRepository').mergeUpdatedObjectList(object_data.invoice_items);
    }
  }

  get is_manual_invoice(): boolean {
    return this.invoice_type === InvoiceType.MANUAL;
  }

  get is_monthly_invoice(): boolean {
    return !this.didorder_id;
  }

  get type_name(): string {
    return !this.is_monthly_invoice ? 'invoice.content.new_numbers_invoice' : 'invoice.content.monthly_invoice';
  }

  get month_invoice(): string {
    if (!this.start_dt) {
      return;
    }

    const month: number = this.start_dt.getMonth() + 1;
    const year: number = this.start_dt.getFullYear();
    return month < 10 ? `0${month}-${year}` : `${month}-${year}`;
  }

  get payment_method_label(): string {
    const invoicePayment = this.payment_method || 0;
    const labels = {
      [InvoicePaymentMethod.UNKNOWN.toString()]: 'invoice.payment_method.other',
      [InvoicePaymentMethod.WIRE_TRANSFER.toString()]: 'invoice.payment_method.wire_transfer',
      [InvoicePaymentMethod.CREDIT_CARD.toString()]: 'invoice.payment_method.credit_card',
      [InvoicePaymentMethod.DEBIT_ORDERDIRECT.toString()]: 'invoice.payment_method.debit_orderdirect',
    };

    return _ti(labels[invoicePayment.toString()]);
  }
}
