<div fxLayout="column" fxFlex="grow">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="none" fxLayoutGap="10px">
    <div fxFlex>
      <a class="flex-link" (click)="selectAll()">
        <mat-icon>{{ selectedAll ? 'clear' : 'done_all' }}</mat-icon>&nbsp;
        {{ (selectedAll ? 'group.add_agent_dialog.clear_selection' : 'group.add_agent_dialog.select_all')|translate }}
      </a>
    </div>
    <div>
      <flex-search-input [(ngModel)]="filters.search_text" ngClass.xs="search-full-width"></flex-search-input>
    </div>
    <!-- <div fxFlex *ngIf="isServiceGroup">
      <mat-form-field class="full-width">
        <usergroup-select-form-input [ngModelOptions]="{standalone: true}" [(ngModel)]="filters.group" [placeholder]="'call_queue.content.select_group'|translate">
        </usergroup-select-form-input>
        <button *ngIf="filters.group" mat-icon-button matSuffix (click)="filters.group = undefined">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div> -->
    <div class="team-group-filter" *ngIf="hasTeam">
      <mat-form-field class="full-width">
        <teamgroup-select-form-input [ngModelOptions]="{standalone: true}" [(ngModel)]="filters.teamgroup"
          [hasUser]="true" [placeholder]="'components.teamgroupselector.choose_team'|translate">
        </teamgroup-select-form-input>
        <button [disabled]="!filters.teamgroup" mat-icon-button matSuffix (click)="filters.teamgroup = undefined">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="content" perfectScrollbar>
    <div fxLayout="row wrap" fxLayout.xs="column" class="agent-list">
      <ng-container *ngFor="let item of filteredItems">
        <div fxFlex.xs="auto" fxFlex="0 1 calc(33.3% - 10px)" class="agent-wrapper">
          <div (click)="selectQueueAgent(item)" class="bg-gray" [ngClass]="{'selected': hasSelected(item)}">
            <agent-item [avatar]="true" [agent]="item.agent" [displayCallInfo]="false">
            </agent-item>
          </div>
          <button type="button" mat-icon-button color="warn" (click)="unselectQueueAgent(item)" class="btn-unselect"
            *ngIf="hasSelected(item)">
            <mat-icon class="text-sm">close</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
