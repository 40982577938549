<ng-template #listFilterTpl>
  <form [formGroup]="form" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
    fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
    <div>
      <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterValue"
        ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>
<ng-template #matchTpl let-match="row">
  <span *ngIf="match.match_prefix">{{'public.button.yes'| translate}}</span>
  <span *ngIf="!match.match_prefix">{{'public.button.no'| translate}}</span>
</ng-template>
<ng-template #routingDataTpl let-routingData="row">
  <div [innerHTML]="renderApplicationNameCol(routingData)"></div>
</ng-template>
<flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_shortdialcode'|translate }}" [filterTemplate]="listFilterTpl"
  [tableConfig]="tableConfig" [dataSource]="dataSource"
  [columnTemplates]="{'match_prefix': matchTpl, 'routing_data': routingDataTpl}" [sidePannelOptions]="sidePannelOptions"
  fxLayout="row" fxFlex="grow">
</flex-crud-page>
