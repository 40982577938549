import { ArchivableRepository } from '@wephone-core/model/repository/archivable_repository';

export class PauseReasonRepository extends ArchivableRepository {
  ENTITY_CLASS = PauseReasonEntity;
  protected USE_PUSH_API = true;

  protected LIST_CONFIG_NAME = 'pause_reason';
  protected URL_PREFIX = 'callcenter/pause_reason';

  protected getURLAction(action: string): string {
    let url: string;
    switch (action) {
      case 'UPDATE':
        url = this.getUrlV2(this.METHOD_PATH_UPDATE);
        break;
      case 'UPDATE_ATTRS':
        url = this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
        break;
      case 'ADD':
        url = this.getUrlV2(this.METHOD_PATH_NEW);
        break;
      case 'ADD_ATTRS':
        url = this.getUrlV2(this.METHOD_PATH_NEW_ATTRS);
        break;
      case 'DELETE':
        url = this.getUrlV2(this.METHOD_PATH_DELETE);
        break;
      case 'ARCHIVE':
        url = this.getUrlV2(this.METHOD_PATH_ARCHIVE);
        break;
      default:
        url = super.getURLAction(action);
        break;
    }
    return url;
  }

  getActiveObjectList(): PauseReasonEntity[] {
    return this.getObjectList().filter(p => {
      return !p.is_archived;
    });
  }

  getObjectUsingLabel(label: string, id?: number): PauseReasonEntity {
    return this.getObjectList().find(p => {
      return p.label === label && (!id || id && p.id !== id);
    });
  }

  getAfterCallReason(): any {
    return this.getObjectList().find(p => {
      return p.after_call ? true : false;
    });
  }
}

import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
