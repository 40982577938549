import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class OpeningHourRepository extends BaseRepository {
  ENTITY_CLASS = OpeningHourEntity;
  protected RELATED_REPOSITORIES = ['opening_hour_calendar', 'did'];
  protected USE_PUSH_API = false;

  public getObjectListByCalendarId(calendar_id: number): Array<OpeningHourEntity> {
    let ret = [];
    for (let s of this.getObjectList()) {
      if (s.calendar_id === calendar_id) {
        ret.push(s);
      }
    }
    return ret;
  }

  public getWeeklyOpeningHourByCalendarId(calendar_id: number): Array<OpeningHourEntity> {
    let ret = [];
    for (let s of this.getObjectList()) {
      if (s.calendar_id == calendar_id && !s.special_date_id) {
        ret.push(s);
      }
    }
    return ret;
  }

  public getObjectBySpecialDateId(special_date_id: number): OpeningHourEntity {
    for (let s of this.getObjectList()) {
      if (s.special_date_id == special_date_id) {
        return s;
      }
    }
    return null;
  }
}

import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
