import { Injectable } from '@angular/core';
import { ImageEntity } from '@wephone-core/model/entity/image';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { IEntity } from '@wephone-core/model/model.interface';
import { ImageRepository } from '@wephone-core/model/repository/image';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { _ti } from '@wephone-translation';
import { DialogService, ToastService } from '@wephone-utils';
import * as _ from 'lodash';

@Injectable()
export class ImageService {

  constructor(
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly httpEngine: HttpEngine,
    private readonly em: EntityManager,
  ) { }

  getInUsedObjects(images: ImageEntity[]): Promise<{[key: string]: IEntity[]}> {
    return this.getInUsedObjectsByIds(images.map(x => x.id)) ;
  }

  async getInUsedObjectsByIds(ids: number[]): Promise<{[key: string]: IEntity[]}> {
    const objects = await (this.em.getRepository('ImageRepository') as ImageRepository).getObjectsUsed(ids);

    for (const item of objects) {
      const entityName: string = item[0];
      const entityId: number = +item[1];

      const repo = this.em.getRepositoryById(entityName);
      if (!repo) {
        console.error('No repo with name' + entityName);
        continue;
      }

      const ret: {[key: string]: IEntity[]} = {};
      if (repo instanceof SipPhoneRepository) {
        if (!ret[entityName]) {
          ret[entityName] = [];
        }

        const sipphone: SipPhoneEntity = this.em.getRepository('SipPhoneRepository').getObjectById(entityId);
        if (sipphone) {
          ret[entityName].push(sipphone);
        }
      }

      return ret;
    }
  }

  async bulkDelete(images: ImageEntity[], cb?: () => void): Promise<void> {
    // const inUsedObjects = this.getInUsedObjects(images);
    // if (!_.isEmpty(inUsedObjects)) {
    //   return this.deleteImages(images, cb);
    // }

    const confirmMessage = _ti('sound_manager.message.ui_enterprise_delete_confirm');
    // const msgs: string[] = [];

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      confirmMessage,
      async () => {
        await this.deleteImages(images);

        if (cb) {
          cb();
        }
      }
    );
  }

  private async deleteImages(images: ImageEntity[]): Promise<void> {
    try {
      await this.em.getRepository('ImageRepository').bulkDelete(images);

      this.toastService.showSuccess(_ti('public.message.delete_success'));
    } catch (error) {
      this.toastService.showError(_ti('public.message.delete_failed'));
    }
  }

}
