import { datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { Entity } from '@wephone-core/model/model';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';

export class LiveQoSWatchEntity extends Entity {
  public static object_type_id: string = 'liveqoswatch';

  public id: number;
  @datafield
  public name: string;
  @datafield
  public included_group_ids: Array<number>;
  @datafield
  public included_queue_ids: Array<number>;
  @datafield
  public excluded_queue_ids: Array<number>;
  @datafield
  public target_qos: number;
  @datafield
  public target_aht: number;
  @datafield
  public target_call_count: number;

  public included_groups: Array<UserGroupEntity>;
  public included_queues: Array<CallQueueEntity>;
  public excluded_queues: Array<CallQueueEntity>;

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    let ids: Array<number>;

    //update included_groups
    ids = [];
    this.included_groups = [];
    if (this.included_group_ids && this.included_group_ids.length) {
      for (let group_id of this.included_group_ids) {
        let group = UserGroupRepository.getInstance().getObjectById(group_id);
        if (group && ids.indexOf(group_id) == -1) {
          this.included_groups.push(group);
          ids.push(group_id);
        }
      }
    }

    //update included_queues
    ids = [];
    this.included_queues = [];
    if (this.included_queue_ids && this.included_queue_ids.length) {
      for (let queue_id of this.included_queue_ids) {
        let callqueue = CallQueueRepository.getInstance().getObjectById(queue_id);
        if (callqueue && ids.indexOf(queue_id) == -1) {
          this.included_queues.push(callqueue);
          ids.push(queue_id);
        }
      }
    }

    //update excluded_queues
    ids = [];
    this.excluded_queues = [];
    if (this.excluded_queue_ids && this.excluded_queue_ids.length) {
      for (let queue_id of this.excluded_queue_ids) {
        let callqueue = CallQueueRepository.getInstance().getObjectById(queue_id);
        if (callqueue && ids.indexOf(queue_id) == -1) {
          this.excluded_queues.push(callqueue);
          ids.push(queue_id);
        }
      }
    }
  }

  public updateIncludedGroupIds(): void {
    this.included_group_ids = [];
    if (this.included_groups && this.included_groups.length) {
      for (let group of this.included_groups) {
        this.included_group_ids.push(group.getId());
      }
    }
  }

  public updateIncludedQueueIds(): void {
    this.included_queue_ids = [];
    if (this.included_queues && this.included_queues.length) {
      for (let queue of this.included_queues) {
        this.included_queue_ids.push(queue.getId());
      }
    }
  }

  public updateExcludedQueueIds(): void {
    this.excluded_queue_ids = [];
    if (this.excluded_queues && this.excluded_queues.length) {
      for (let queue of this.excluded_queues) {
        this.excluded_queue_ids.push(queue.getId());
      }
    }
  }
}
