import { Injectable } from "@angular/core";
@Injectable()
export class SingletonBase {
  private static instance: any = undefined;

  static getInstance() {
    return this.instance;
  }

  static setInstance(instance: any): void {
    this.instance = instance;
  }

  static reset(): any {
    return this.instance = undefined;
  }

  constructor() {
    if (this.constructor['instance']) {
      throw new Error(`Cannot initialize ${this.constructor.name} twice`);
    }
    this.constructor['instance'] = this;
  }
}

/*
export function enforceSingleton(cls: {getInstance(): any}, force: boolean = false): any {
  return { provide: cls, useClass: singletonClass(cls), deps: new ReflectionCapabilities().parameters(cls as any) };
}

export function singletonClass(target: any) {
  // save a reference to the original constructor
  let original = target;

  // the new constructor behaviour
  const f: any = function (...args) {
    if (target.getInstance()) {
      return target.getInstance();
    }

    return  original.apply(this, args);
  };

  // copy prototype so intanceof operator still works
  f.__proto__ = original.__proto__;
  f.prototype = original.prototype;
  f.__annotations__ = original.__annotations__;
  Object.assign(f, original);
  Object.defineProperty(f, 'name', { value: target.name });
  // return new constructor (will override original)
  return f;
}

*/
/*
export function singletonClass(target: any) {
  // save a reference to the original constructor
  let originalContructor = target.prototype.constructor;

  // the new constructor behaviour
  const f: any = function (...args) {
    if (target.getInstance()) {
      return target.getInstance();
    }

    return  originalContructor(this, args);
  };

  f.prototype = 
  target.prototype.constructor = f;

  return target;
}
*/