import { Component } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  constructor(
    private authService: AuthenticationService,
  ) {
  }

  get userRole(): String {
    return this.authService.getUserRole();
  }
}
