import * as _ from 'lodash';

import { Component, OnInit, ElementRef, Optional, Self, ViewChild, Input } from '@angular/core';
import { FlexMatInputWrapper, TreeHelper } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormControl } from '@angular/forms';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { UserEntity } from '@wephone-core/model/entity/user';
import { AuthenticationService } from '@wephone-core/service/authentication';

@Component({
  selector: 'flex-select-group-input',
  templateUrl: './flex-select-group-input.html',
  styleUrls: ['./flex-select-group-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectGroupInput }]
})
export class FlexSelectGroupInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() groups: UserGroupEntity[];
  @Input() filteredIds: number[]; // TODO: Resolve conflict with limitedByRole
  @Input() groupType?: 'team' | 'service';
  @Input() multiple: boolean;
  @Input() limitedByRole: boolean;
  @Input() showCheckAll: boolean;
  @Input() hasAgentOrQueueOrDid: boolean;
  @ViewChild('flexSelectTreeInput') flexSelectTreeInput: FlexSelectTreeInput;

  // Private member var with default value
  private readonly groupRepo = UserGroupRepository.getInstance<UserGroupRepository>();
  // Private member var

  // Public member var with default value

  // Public member var
  myControl = new FormControl();
  groupNodes: FlexSelectTreeNode[] = [];
  currentUser: UserEntity;

  constructor(
    elRef: ElementRef,
    @Optional() @Self() ngControl: NgControl,
    private readonly authService: AuthenticationService,
  ) {
    super(ngControl, elRef);
    this.currentUser = this.authService.getUser();
  }

  ngOnInit(): void {
    super.ngOnInit();

    const allGroups = this.groupRepo.getObjectList();

    let avaiableGroups: UserGroupEntity[] = this.groups;
    if (_.isEmpty(avaiableGroups)) {
      avaiableGroups = allGroups;

      // if (this.currentUser.isAdmin()) {
      //   avaiableGroups = allGroups;
      // } else {
      //   avaiableGroups = this.currentUser.groups;
      // }
    } else {
      // if (this.limitedByRole && !this.currentUser.isAdmin()) {
      //   avaiableGroups = avaiableGroups.filter(g => {
      //     if (_.isEmpty(this.currentUser.group_ids)) {
      //       return false;
      //     }

      //     return _.includes(this.currentUser.group_ids, g.id);
      //   });
      // }
    }

    if (this.groupType) {
      avaiableGroups = avaiableGroups.filter(g => g.group_type === this.groupType);
    }

    if (this.hasAgentOrQueueOrDid) {
      avaiableGroups = avaiableGroups.filter(g => g.hasAgent() || g.hasQueue() || g.hasDid());
    }

    if (!_.isUndefined(this.filteredIds)) {
      avaiableGroups = avaiableGroups.filter(x => _.includes(this.filteredIds, x.id));
    }

    // Check missing parent
    const clonedAvailalbeGroups: UserGroupEntity[] = _.cloneDeep(avaiableGroups);
    // const avaiableGroupIds: number[] = avaiableGroups.map(x => x.id);

    const disabledIds = [];
    for (const a of clonedAvailalbeGroups) {
      if (a.parent && !_.includes(avaiableGroups.map(x => x.id), a.parent_id)) {
        disabledIds.push(a.parent_id);
        avaiableGroups.push(a.parent as UserGroupEntity);
      }
    }

    const nodeList = avaiableGroups.map(group => {
      return {
        id: group.id,
        name: group.name,
        parent_id: group.parent_id,
        disabled: _.includes(disabledIds, group.id)
      };
    });
    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.groupNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[] || [];
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-group-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }
}
