<div fxLayout="column" fxFill *ngIf="is_ready">
    <ng-template #tplListFilter>
        <form [formGroup]="filterForm" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
            fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.xs="0px">
            <div>
                <flex-search-input formControlName="search_text" [hintText]="'team.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
            </div>
        </form>
    </ng-template>
    <flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_team'|translate }}" [dataSource]="dataSource"
        [tableConfig]="tableConfig" [columnTemplates]="{'name': nameTpl, 'agents': tplAgents}"
        [sidePannelOptions]="sidePannelOptions" [filterTemplate]="tplListFilter" fxLayout="row" fxFlex="grow">

        <ng-template #tplAgents let-item="row">
            <div *ngIf="item.users" matTooltip="{{ item.users|map:'name'|join:', ' }}">
                <ng-container *ngFor="let user of item.users; let i = index; let last = last">
                    <ng-container *ngIf="i < 5">
                        <span>{{ user.name }}</span><span *ngIf="!last && i<4">, </span><span
                            *ngIf="i==4 && (item.users).length > 5">... </span>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="(item.users).length > 5">
                    <span>({{ 'group.content.agent_more'|translate: {value: (item.users).length - 5} }})</span>
                </ng-container>
            </div>
        </ng-template>
        <ng-template #nameTpl let-name="row">
          <div [innerHTML]="renderNameCol(name)"></div>
        </ng-template>
    </flex-crud-page>
</div>
