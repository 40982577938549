import { Component } from '@angular/core';
import { MenuService, MainMenuItem } from '../menu.service';
import { _tk, _ti } from '@wephone-translation';
import { AccessRight, BusinessType } from '@wephone-core/system';

const WATCHER_MENU_ITEMS: MainMenuItem[] = [
  {
    state: 'dashboard',
    name: _tk('site.dashboard'),
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'vocal_messages',
    name: _tk('menu.recorded_call'),
    type: 'sub',
    icon: 'voicemail',
    children: [
      // {
      //   state: 'voicemail-queue',
      //   accessPermission: AccessRight.VoiceMail,
      //   name: _tk('menu.voicemail')
      // },
      {
        state: 'recorded-call',
        accessPermission: AccessRight.Recording,
        name: _tk('menu.recorded_call')
      }
    ]
  },
  {
    state: 'statistics',
    name: _tk('menu.statistics'),
    type: 'sub',
    icon: 'equalizer',
    accessPermission: AccessRight.Stats,
    children: [
      { state: 'incoming', name: _tk('stats.callcenter.incoming') },
      { state: 'outgoing', name: _tk('stats.callcenter.outgoing') },
      { state: 'missedcall', name: _tk('stats.callcenter.missedcall') },
      { state: 'agent-status', name: _tk('stats.agent_status.title') },
      { state: 'connection-log', name: _tk('stats.agent_connection_log.title'), businessTypes: [BusinessType.CALLCENTER] }
    ]
  },
  {
    state: 'telephony',
    name: _tk('menu.telephony'),
    type: 'sub',
    icon: 'call',
    businessTypes: [BusinessType.CALLCENTER],
    accessPermission: AccessRight.CallHistory,
    children: [
      { state: 'cdr', name: _tk('menu.manage_cdr') },
    ]
  },
];

@Component({
  selector: 'watcher-menu',
  templateUrl: './watcher-menu.component.html',
  styleUrls: ['./watcher-menu.component.scss'],
  providers: [MenuService]
})
export class WatcherMenuComponent {

  constructor(
    public menuService: MenuService
  ) {
  }

  get watcherMenus(): Array<MainMenuItem> {
    return WATCHER_MENU_ITEMS;
  }
}
