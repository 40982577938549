import { OnInit, OnDestroy, ChangeDetectorRef, Directive } from '@angular/core';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { ConnectivityService } from './service/connectivity.service';
import { ToastService } from '@wephone-utils/services/toast.service';
import { UIBlockerService } from '@wephone-utils/services/uiblocker.service';
import { Deferred } from '@wephone-utils/utils/defer';
import { Subscription, interval } from 'rxjs';
import { _ti } from '../wephone-translation/static-methods';
import { DateAdapter } from '@angular/material/core';
import { TranslationService } from '../wephone-translation/translation.service';
import { Debug } from '@wephone-utils/debug';
import * as _ from 'lodash';

const debug = Debug('flexbasecomponent');
@Directive()
export class FlexBaseComponent implements OnInit, OnDestroy {
  dateAdapter: DateAdapter<Date>;

  PERIODICAL_UPDATE = false;
  realtime_pushapi = false;
  protected toastService: ToastService;
  private connService: ConnectivityService;
  protected uiBlocker: UIBlockerService;
  private hasChanges = false;
  protected subscriptionList: Subscription[] = new Array<Subscription>();
  protected _readinessDeferred: Deferred<boolean>;
  is_ready = false;
  ui_block = false;

  constructor(protected cdr?: ChangeDetectorRef) {
    this.toastService = StaticServiceLocator.injector.get(ToastService);
    this.connService = StaticServiceLocator.injector.get(ConnectivityService);
    this.uiBlocker = StaticServiceLocator.injector.get(UIBlockerService);
  }

  detectChanges(): void {
    this.hasChanges = false;
    this.cdr.markForCheck();
    if (!this.cdr['destroyed']) { // To fixbug Attempt to use a destroyed view: detectChanges
      this.cdr.detectChanges();
    }
  }

  blockUI(block: boolean, reason: string): void {
    if (!this.ui_block) {
      return;
    }
    if (block) {
      this.uiBlocker.block(reason);
    } else {
      this.uiBlocker.unblock(reason);
    }
  }

  ngOnInit(): void {
    this.blockUI(true, `${this.constructor.name}.ngOnInit`);
    try {
      this.checkReadiness().then(
        result => {
          return result;
        },
        err => {
          return err;
        }
      );
    } catch (e) {
      console.error('Error in checkReadiness function', e);
    } finally {
      this.blockUI(false, `${this.constructor.name}.ngOnInit`);
    }

    if (this.cdr) {
      this.addSubscription(
        interval(1000).subscribe(
          () => {
            if (this.PERIODICAL_UPDATE || this.hasChanges) {
              this.detectChanges();
            }
          }
        )
      );
    }

    if (this.cdr) {
      this.addSubscription(
        EntityManager.getInstance().dataChanged.subscribe(
          () => {
            this.hasChanges = true;
          }
        )
      );
    }

    if (!_.isEmpty(this.dateAdapter)) {
      this.setDatepickerLocale();
      this.addSubscription(
        TranslationService.getInstance().translate.onLangChange.subscribe(
          () => {
            this.setDatepickerLocale();
          }
        )
      );
    }
  }

  private setDatepickerLocale(): void {
    // const currentLang = TranslationService.getInstance().translate.currentLang;
    // this.dateAdapter.setLocale(currentLang === 'pt_BR' ? 'pt' : currentLang);
    this.dateAdapter.setLocale(TranslationService.getInstance().getCurrentLocale());
  }

  protected addSubscription(sub: Subscription): Subscription {
    this.subscriptionList.push(sub);
    return sub;
  }

  async checkReadiness(): Promise<boolean> {
    if (this._readinessDeferred) {
      return this._readinessDeferred.promise;
    }
    try {
      this.is_ready = false;
      this._readinessDeferred = new Deferred<boolean>();
      this.doResolveData().then(
        ret => {
          this.is_ready = true;
          this._readinessDeferred.resolve();

          if (this.cdr) {
            this.detectChanges();
          }
        },
        err => {
          console.error('Error in calling doResolveData() function', err);
          this._readinessDeferred.reject(err);
        }
      );

      return this._readinessDeferred.promise;
    } catch (e) {
      console.log('Error inside checkReadiness function', e);
      EntityManager.getInstance().releaseCallCenterLiveData();
      return false;
    }
  }

  private async doResolveData(): Promise<void> {
    if (this.isRealtime()) {
      await EntityManager.getInstance().requestCallCenterLiveData();
    }

    const resolvedData = this.resolveData();
    if (resolvedData && 'then' in resolvedData) {
      await resolvedData;
    }
  }

  protected resolveData(): any {
  }

  protected isRealtime(): boolean {
    return this.realtime_pushapi;
  }

  isReady(): boolean {
    return this.is_ready;
  }

  ngOnDestroy(): void {
    if (this.isRealtime()) {
      EntityManager.getInstance().releaseCallCenterLiveData();
    }
    for (const sub of this.subscriptionList) {
      sub.unsubscribe();
    }
    this.subscriptionList = [];
  }

  protected showDisconnected(): void { }

  protected successToast(message): void {
    this.toastService.show(message);
  }

  protected showError(message): void {
    this.toastService.showError(message);
  }

  protected showInfo(message): void {
    this.toastService.show(message);
  }

  protected showWarning(message): void {
    this.toastService.showWarning(message);
  }

  protected showUpdateConfirmation(message?: string): void {
    this.successToast(message || _ti('public.message.update_success'));
  }

  protected showErrorMessage(error: any, defaultMessage: string): void {
    this.toastService.showErrorMessage(error, defaultMessage);
  }
}
