import { Component, Input, OnInit } from '@angular/core';
import { SoundService } from '../../service/sound.service';
import { IAudioTrack } from '@wephone-audio';
import { FileEntryService } from '@wephone-core/service/file_entry_service';

@Component({
  selector: 'fileentry-player',
  templateUrl: './fileentry-player.html',
  styleUrls: ['./fileentry-player.scss']
})
export class FileentryPlayerComponent implements OnInit {
  @Input() title?: string;
  @Input() downloadNamePrefix?: string;
  @Input() enableDownload?: boolean;
  @Input() hideMute?: boolean;

  muted = false;

  constructor(
    private readonly soundService: SoundService,
    private readonly fileEntryService: FileEntryService
  ) {}

  ngOnInit(): void {
    if (this.audioTrack) {
      this.audioTrack.title = this.title;
    }
  }

  get audioTrack(): IAudioTrack {
    return this.soundService.getAudioTrack();
  }

  resume(): void {
    this.soundService.resume();
  }

  pause(): void {
    this.soundService.pause();
  }

  stop(): void {
    this.soundService.stopPlaying();
  }

  mute(): void {
    this.muted = !this.muted;
    this.soundService.mute(this.muted);
  }

  isPlaying(): boolean {
    return this.soundService.isPlaying();
  }

  isPaused(): boolean {
    return this.soundService.isPaused();
  }

  close(): void {
    this.stop();
  }

  download(): void {
    this.soundService.download();
  }
}
