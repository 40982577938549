import * as _ from 'lodash';

import { Component, OnInit, Input, ViewChild, ElementRef, Optional, Self, ChangeDetectorRef } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FormControl, NgControl } from '@angular/forms';
import { FlexMatInputWrapper, FlexMatInputBase } from '@wephone-utils';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CreditCardComponent }]
})
export class CreditCardComponent extends FlexMatInputBase implements MatFormFieldControl<any> {
  myControl = new FormControl();
  months = [];
  years = [];
  constructor(
    elRef: ElementRef,
    @Optional()
    @Self()
    ngControl: NgControl,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, elRef, cdr);
    for (let i = 1; i <= 12; i++) {
      this.months.push(i.toString());
    }

    for (let i = 2016; i <= 2050; i++) {
      this.years.push(i.toString());
    }
  }

  get controlType(): string {
    return 'app-credit-card';
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  writeValue(val): void {
    super.writeValue(val);
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

}
