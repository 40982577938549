import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class ShortDialCodeRepository extends BaseRepository {
  ENTITY_CLASS = ShortDialCodeEntity;
  protected URL_PREFIX = 'short-dial-code';
  private readonly METHOD_PATH_LIST = 'list';
  private last_filters: any = null;

  protected getUrl(suffix: string): string {
    return this.getUrlV2(suffix);
  }

  protected getURLAction(action: string): string {
    switch (action) {
      case 'LIST':
        return this.getUrlV2(this.METHOD_PATH_LIST);
      default:
        return super.getURLAction(action);
    }
  }

  protected getRemoteData(use_cache = true, filters?: any): Promise<any[]> {
    const period: string = !(!filters || _.isEmpty(filters) || _.isNull(filters)) ? filters : 'all';
    const url = this.getURLAction('LIST');

    if (use_cache && (this.object_list || []).length && this.last_filters === period) {
      return Promise.resolve(this.object_list);
    }

    return HttpEngine.getInstance().get(url, { period }).then((updated_list: any) => {
      // update list
      const object_list_data: any[] = [];
      for (const new_obj_data of updated_list) {
        const item = _.find(object_list_data, { id: new_obj_data.id });
        if (item) {
          item.setObjectData(new_obj_data);
          continue;
        }
        // create new object
        const new_enterprise_crm_obj = this.create();
        new_enterprise_crm_obj.setObjectData(new_obj_data, false);
        object_list_data.push(new_enterprise_crm_obj);
      }
      this.last_filters = period;
      this.setDataAsReady();
      this.setObjectList(object_list_data);

      return this.object_list;
    });
  }
}

import { ShortDialCodeEntity } from '@wephone-core/model/entity/short_dial_code';
import { HttpEngine } from '@wephone-core/service/http_engine';
