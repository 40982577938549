import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { EnterpriseConsumptionEntity } from '../entity/enterprise_consumption';

export class EnterpriseConsumptionRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseConsumptionEntity;
  protected URL_PREFIX = 'enterprise_consumption';
  protected METHOD_PATH_LIST = 'list';

  protected getUrl(suffix): string {
    return super.getUrlV2(suffix);
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/enterprise_consumption'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get enterprise_consumption list error', response);

          return [];
        }
      );
  }

  async findByEnterpriseId(eid: number): Promise<EnterpriseConsumptionEntity> {
    await this.findAll();

    return this.object_list.find(x => x.enterprise_id === eid);
  }
}
