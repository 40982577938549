<div class="pa-1">
  <form [formGroup]="form">

    <mat-form-field class="full-width">
      <mat-label>{{'tag.content.tag_name' | translate}}</mat-label>
      <input matInput placeholder="{{'tag.content.tag_name' | translate }}" formControlName="tag_name">
      
      <mat-error [flexFormControlError]="form.get('tag_name')"></mat-error>
    </mat-form-field>

  </form>
</div>
