<div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="10px">
  <div fxFlex="calc(100% - 110px)">
    <ul class="hour-container">
      <li *ngFor="let hour of hours" class="hour" [ngStyle]="{'width': hour.width}">{{ hour.hour }}h</li>
    </ul>
    <div #progress class="progress-container">
      <div *ngFor="let bar of bars" [matTooltip]="(bar.title|translate: { start_time: bar.start_time, duration: bar.duration }) + (bar.more_info ? '&#13;' + bar.more_info : '')" matTooltipClass="allow-cr"
        class="progress-bar {{ bar.styleClass }}" [style.width.px]="bar.width">
      </div>
    </div>
  </div>
  <div fxFlex="100px">
    <button mat-stroked-button color="primary" (click)="showDetail()">{{ 'public.button.detail' | translate}}</button>
  </div>
</div>
