import {
  Component, Input, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef,
  OnDestroy, Renderer2,
} from '@angular/core';
import { BoType, CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { QueueTransferNumberEntity } from '@wephone-core/model/entity/queue_transfernumber';
import { FlexIvrSettings, EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import {
  ToastService, EditingComponent, DialogActionButton, Colors, DialogService, joiValidator,
  FormService, CalendarInputParams, NoWhitespaceValidator, IFlexSelectOptions, Validator,
} from '@wephone-utils';
import { StorageService } from '@wephone-core/service/storage.service';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { QueueTransferNumberRepository } from '@wephone-core/model/repository/queue_transfernumber';
import { _tk, _ti } from '@wephone-translation';
import { DialogQueueHistoryComponent } from '@wephone/modals/dialog-queue-history/dialog-queue-history.component';
import { QueueAgentListControlValue } from '../queue-agent.i';
import { Validators, FormBuilder, FormControl, FormGroup, FormArray } from '@angular/forms';
import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { DidRepository } from '@wephone-core/model/repository/did';
import { QueueEditDidrouteComponent } from '@wephone/components/call-queue/queue-edit-didroute/queue-edit-didroute.component';
import {
  VoicemailOptionModal, VoicemailOptionInterface,
} from '@wephone/modals/call-queue/voicemail-option-modal/voicemail-option-modal.component';
import { Router } from '@angular/router';
import { NumberOpeningCalendar } from '@wephone/components/number-opening-calendar/number-opening-calendar.component';
import { DidEntity, DidRoutePriority } from '@wephone-core/model/entity/did';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { OneRequired, PhoneNumberValidated } from '@wephone/services/form-validator';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { ConfirmAgentEligibleComponent } from '@wephone/modals/agent/confirm-agent-eligible/confirm-agent-eligible.component';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';
import { CallQueueService } from '@wephone/services/callqueue.service';
import { QueueEstimatedWaitTimeComponent } from '../queue-estimated-wait-time/queue-estimated-wait-time.component';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { EnterpriseParamDict, SystemParam } from '@wephone-core/system';
import { SurveyDirection } from '@wephone-core/model/entity/callqueue.i';
import { IRoutingAppDestinationData, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CallDestinationComponent } from '@wephone/components/call-destination/call-destination.component';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { DateAdapter } from '@angular/material/core';
import { ArrayHelper } from '@wephone-core/helpers/array.helper';

@Component({
  selector: 'call-queue-edit',
  templateUrl: './call-queue-edit.component.html',
  styleUrls: ['./call-queue-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallQueueEditComponent extends EditingComponent implements OnInit, OnDestroy {
  static DefaultMinSurveyDuration = 120; // Seconds

  ALLOW_SCROLL = true;
  @Input() editingDID: DidEntity;
  @Input() editingItem: CallQueueEntity;
  @ViewChild('queueEstimatedWaitTime') queueEstimatedWaitTime: QueueEstimatedWaitTimeComponent;
  @ViewChild('numberOpeningCalendar') numberOpeningCalendar: NumberOpeningCalendar;
  @ViewChild('boUrlInput') boUrlInput: ElementRef;
  @ViewChild('mainArea') mainArea;
  @ViewChild('waitTimeoutConfigDestination', { static: false }) waitTimeoutConfigDestination: CallDestinationComponent;

  actions: DialogActionButton[];
  queue: CallQueueEntity;
  originQueue: CallQueueEntity;
  queueRepo: CallQueueRepository;
  didRepo: DidRepository;
  hasValidQualifications: boolean;
  hasAnonymous: boolean;

  priorityList: {
    label: string;
    value: number;
  }[];
  // queueSoundSilentList: {
  //   name: string;
  //   value: number;
  // }[];
  afterCallPauseModeList: {
    name: string;
    value: number;
  }[];

  readonly agentDisplayNumberDidNumber: number;
  agentDisplayNumberTypes: {
    value: number;
    label: string;
  }[];

  showNonOfficeTimeRouting = false;
  waitTimeoutConfigDestinationList: RoutingAppName[] = [
    RoutingAppName.callqueue,
    RoutingAppName.call_phone,
    RoutingAppName.call_phone_number,
    RoutingAppName.ivr_custom_menu,
    RoutingAppName.play_then_hangup,
    RoutingAppName.send_sms
  ];

  isAdmin: boolean;

  readonly isCallCenter: boolean;
  readonly isEnterprise: boolean;
  readonly backOfficeType = BoType;

  steps: any = {
    _1calendar: 'call_queue_edit.nav.calendar',
    _2queue_agents: 'call_queue_edit.section.main_agents',
    _3application: 'call_queue_edit.section.application',
    _4phone_number: 'call_queue_edit.section.phone_numbers',
    _5advanced: 'call_queue_edit.section.advanced'
  };

  listenFunc: Function;
  hasCalendar = true;
  enableEocSurveyDuration = false;
  usedDidList: DidEntity[] = [];
  callDirections = [
    { value: SurveyDirection.BOTH, name: 'call.both' },
    { value: SurveyDirection.INBOUND, name: 'call.incoming' },
    { value: SurveyDirection.OUTBOUND, name: 'call.outgoing' },
    { value: SurveyDirection.NONE, name: 'call.none' },
  ];
  enableCallAnalysis = false;

  private voicemailOption: VoicemailOptionInterface;
  private hasChangeVoicemailOption = false;
  private exclusiveConfirmedLinkEntities = [];

  pressKeys = [
    { label: 'public.key.number_sign', value: '#' },
    { label: 'public.key.asterisk', value: '*' },
  ];

  pressConfirmKeys = [
    { label: 'public.key.none', value: '' },
    { label: 'public.key.number_sign', value: '#' },
    { label: 'public.key.asterisk', value: '*' },
  ];

  percentageList: number[] = [];
  percentageSelectOptions: IFlexSelectOptions;

  // defaultHangupSound: FileEntryEntity;

  constructor(
    public toast: ToastService,
    public storage: StorageService,
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly cdr: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly configManager: ConfigManager,
    private readonly authService: AuthenticationService,
    flexIvrSettings: FlexIvrSettings,
    public readonly formService: FormService,
    private readonly callQueueService: CallQueueService,
    dateAdapter: DateAdapter<Date>,
  ) {
    super();
    this.dateAdapter = dateAdapter;

    this.listenFunc = this.renderer.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event && event.srcElement.classList.length > 0 && event.srcElement.classList.contains('link_create_qualification')) {
        this.router.navigate(['callcenter-params/qualification']);
      }
    });

    this.isCallCenter = flexIvrSettings.uiCallCenter();
    this.hasAnonymous = !!this.configManager.getSystemParam(SystemParam.telecom_has_anonymous);

    const enterpriseParams: EnterpriseParamDict = this.configManager.getEnterpriseParams();
    this.enableCallAnalysis = !!enterpriseParams.call_analysis_enable;

    // this.queueSoundSilentList = [
    //   {
    //     name: _ti('call_queue.silent_modes.play_greeting_waiting_sounds'),
    //     value: 1
    //   },
    //   {
    //     name: _ti('call_queue.silent_modes.not_play_greeting_waiting_sounds'),
    //     value: 0
    //   }
    // ];

    this.priorityList = [
      {
        value: DidRoutePriority.VeryHigh,
        label: 'call_queue.content.priority_level.very_high'
      },
      {
        value: DidRoutePriority.High,
        label: 'call_queue.content.priority_level.high'
      },
      {
        value: DidRoutePriority.Normal,
        label: 'call_queue.content.priority_level.normal'
      },
      {
        value: DidRoutePriority.Low,
        label: 'call_queue.content.priority_level.low'
      },
      {
        value: DidRoutePriority.VeryLow,
        label: 'call_queue.content.priority_level.very_low'
      }
    ];

    this.afterCallPauseModeList = [
      {
        name: 'call_queue.pause_modes.no_pause',
        value: 0
      },
      {
        name: 'call_queue.pause_modes.pause_both',
        value: 1
      },
      {
        name: 'call_queue.pause_modes.pause_inbound',
        value: 2
      },
      {
        name: 'call_queue.pause_modes.pause_outbound',
        value: 3
      }
    ];

    this.agentDisplayNumberDidNumber = CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER;
    this.agentDisplayNumberTypes = [
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_CUSTOMER_PHONE,
        label: 'call_queue.content.display_number.agent_display.client_phone'
      },
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_CALLER_NUMBER,
        label: 'call_queue.content.display_number.agent_display.caller_number'
      },
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER,
        label: 'call_queue.content.display_number.agent_display.did_number'
      }
    ];

    for (let i = 10; i <= 100; i += 10) {
      this.percentageList.push(i);
      // this.percentageList.push({ value: i, label: `${i}` });
    }

    this.percentageSelectOptions = {
      compareWith: (a: number, b: number) => a === b,
      filterFunc: (item: number, filterString: string): boolean => {
        if (!filterString) {
          return true;
        }
        return item === +filterString ? true : false;
      }
    };

    this.queueRepo = this.em.getRepository<CallQueueRepository>('CallQueueRepository');
    this.didRepo = this.em.getRepository<DidRepository>('DidRepository');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // Removes "listen" listener
    this.listenFunc();
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();

    this.isAdmin = this.authService.isAdmin();
    // load ivr-evaluation data
    await this.em.getRepository('IvrEvaluationRepository').findAll();

    const rootObjects = this.em.getRepository<QualificationRepository>('QualificationRepository').getValidRootObjectList();
    this.hasValidQualifications = rootObjects && rootObjects.length ? true : false;
    this.setOriginQueue(this.editingItem);

    if (!this.queue.max_inqueue_time) {
      this.queue.max_inqueue_time = 0;
    }

    this.actions = [
      {
        label: 'public.archive',
        action: () => {
          this.archive();
        },
        color: Colors.PRIMARY,
        visible: (): boolean => {
          return this.queue && !this.queue.is_archived;
        }
      },
      {
        label: 'public.unarchive',
        action: () => {
          this.unarchive();
        },
        color: Colors.PRIMARY,
        visible: (): boolean => {
          return this.queue && !!this.queue.is_archived;
        }
      },
      {
        label: 'public.delete',
        action: () => {
          this.delete();
        },
        color: Colors.PRIMARY,
        visible: (): boolean => {
          return !!this.queue;
        }
      },
      {
        label: 'call_queue.content.tab_history',
        action: () => {
          this.dialogQueueHistory();
        },
        color: Colors.PRIMARY,
        visible: (): boolean => {
          return this.queue && !!this.queue.id;
        }
      }
    ];

    if (this.queue.queue_priority === undefined) {
      this.queue.queue_priority = 2;
    }

    // remove agents step with case dedicated queue
    // if (this.queue.dedicated_user_id) {
    //   delete this.steps._2queue_agents;
    // }

    this.steps._0general = 'call_queue.content.tab_general';

    this.initFormGroup();

    this.cdr.detectChanges();
  }

  get voicemail_by_mail_enabled(): number {
    return this.queue.voicemail_by_mail_enabled;
  }

  get voicemail_in_attachement(): number {
    return this.queue.voicemail_in_attachement;
  }

  get voicemail_by_mail_addrs(): string[] {
    return this.queue.voicemail_by_mail_addrs;
  }

  get opening_calendar(): OpeningHourCalendarEntity {
    return this.queue.opening_calendar;
  }

  get is_survey_direction_null(): boolean {
    return this.form && this.form.get('eoc_survey_direction').value === SurveyDirection.NONE;
  }

  // get vm_enabled(): number {
  //   return this.queue.voicemail_enabled;
  // }

  private initVoicemailOption(): void {
    this.setVoicemailOption({
      voicemail_by_mail_enabled: this.voicemail_by_mail_enabled,
      voicemail_in_attachement: this.voicemail_in_attachement,
      voicemail_by_mail_addrs: this.voicemail_by_mail_addrs
    });
  }

  private setVoicemailOption(value: VoicemailOptionInterface): void {
    this.voicemailOption = {
      voicemail_by_mail_enabled: value.voicemail_by_mail_enabled,
      voicemail_in_attachement: value.voicemail_in_attachement,
      voicemail_by_mail_addrs: value.voicemail_by_mail_addrs,
    };
  }

  private setOriginQueue(q: CallQueueEntity): void {
    this.originQueue = q;
    this.queue = _.cloneDeep(q);

    this.initVoicemailOption();
    this.setUsedDidList();
  }

  private setUsedDidList(): void {
    this.usedDidList = this.queue.routedDids;
  }

  initFormGroup(): void {
    const queue_agents_main: QueueAgentListControlValue = {
      agent_selection_mode: this.queue.agent_selection_mode_main || 0,
      queue_agents: _.cloneDeep(this.queue.main_agents)
    };
    const queue_agents_backup: QueueAgentListControlValue = {
      agent_selection_mode: this.queue.agent_selection_mode_backup || 0,
      queue_agents: _.cloneDeep(this.queue.backup_agents)
    };

    if (!this.queue.default_did) {
      // console.error('Cannot edit queue with no connected DID');
      // return;
      console.log('Edit queue with no connected DID');
    }

    this.hasCalendar = !this.opening_calendar;

    let boUrl = this.queue.bo_url;
    const boMethod = boUrl && boUrl.startsWith('POST|') ? 'POST' : 'GET';
    boUrl = (boUrl || '').replace('POST|', '');

    const waitTimeoutConfig = this.getWaitTimeoutConfig();

    this.form = this.fb.group({
      // did_list: [this.queue.did_list],
      queue_name: [this.queue.queue_name, [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      agent_ring_duration: [this.queue.agent_ring_duration, [Validators.required, Validators.min(0), Validators.max(1800)]],
      seconds_before_backup: [this.queue.seconds_before_backup, [Validators.required, Validators.min(0), Validators.max(3600)]],
      queue_agents_main: [queue_agents_main],
      queue_agents_backup: [queue_agents_backup],
      availability: [this.queue.availability],
      // updateOpeningHour: [false],
      recording_mode: [!!this.queue.recording_mode],
      recording_percentage: [this.queue.recording_percentage || 90, [Validators.min(0), Validators.max(100)]],
      has_qualification: [!!this.queue.has_qualification],
      qualification_required: [!!this.queue.qualification_required],
      in_qualification: [this.queue.in_qualification],
      out_qualification: [this.queue.out_qualification],
      // language: [LangeuageObjectType],
      no_silent: [!this.queue.silent],
      play_entire_welcome: [!!this.queue.play_entire_welcome],
      no_agent_file: [this.queue.no_agent_file],
      moh_file: [this.queue.moh_file],
      greeting_file: [this.queue.greeting_file],
      waiting_music_file: [this.queue.waiting_music_file],
      eoc_survey_direction: [this.queue.eoc_survey_direction],
      eoc_ivr_menu: [this.queue.eoc_ivr_menu],
      eoc_survey_min_duration: [this.queue.eoc_survey_min_duration, [Validators.required, Validators.min(0), Validators.max(2000)]],
      aftercall_pause_mode: [this.queue.aftercall_pause_mode],
      after_call_time: [this.queue.after_call_time, [Validators.min(0), Validators.max(3600)]],
      bo_method: [boMethod],
      bo_type: [this.queue.bo_type, joiValidator(Joi.required())],
      bo_open_derection: [this.queue.bo_open_derection],
      bo_url: [boUrl, [UrlValidator.isValidUrl, Validators.maxLength(512)]],
      transfer_numbers: this.fb.array(
        this.getTransferNumbersFormControls(_.cloneDeep(this.queue.transfer_numbers) || [])
      ),
      max_inqueue: [this.queue.max_inqueue_time, joiValidator(Joi.number().required().min(0).max(7200))],
      // vm_enabled: [!!this.vm_enabled],
      // vm_on_timeout: [!!this.queue.vm_on_timeout],
      wait_timeout_config: [waitTimeoutConfig],
      group: [this.queue.group],
      queue_priority: [this.queue.queue_priority, joiValidator(Joi.required().valid(ArrayHelper.getEnumValues(DidRoutePriority)))],
      alias: [this.queue.alias, [Validators.maxLength(256)]],
      xapi_agent_search: [this.queue.xapi_agent_search, [Validators.maxLength(256)]],
      agent_display_number_type: [this.queue.agent_display_number_type],
      ewt_enable: [!!this.queue.ewt_enable],
      ewt_data: [this.queue.ewt_data],
      agent_display_number: [this.queue.agent_display_number],
      is_exclusive: [!!this.queue.is_exclusive],
      default_did: [this.queue.defaultCallingNumber || this.didRepo.getAnonymousDid()],
      satisfaction_survey_enable: [!!this.queue.satisfaction_survey_enable],
      survey_prompt_file: [this.queue.survey_prompt_file],
      survey_thank_message_file: [this.queue.survey_thank_message_file],
      request_callback_enable: [!!this.queue.request_callback_enable],
      request_callback_after: [this.queue.request_callback_after, [Validators.min(0), Validators.max(3600)]],
      request_callback_annon_sound: [this.queue.request_callback_annon_sound],
      request_callback_key: [this.queue.request_callback_key || ''],
      request_callback_sound: [this.queue.request_callback_sound],
      request_callback_confirm_key: [this.queue.request_callback_confirm_key || '', [Validators.max(1)]],
      request_callback_confirmation_sound: [this.queue.request_callback_confirmation_sound],
      analysis_enabled: [!!this.queue.analysis_enabled],
      analysis_start_dt: [this.queue.analysis_start_dt ? this.queue.analysis_start_dt.toJSDate() : null]
    });

    this.form.valueChanges.subscribe(changes => {
      this.updateQualificationValue(changes.has_qualification);
      this.onFormValueChange();
    });
    this.updateModelEocSurveyDuration(!!this.queue.eoc_survey_min_duration);
  }

  formHasChanged(): boolean {
    return super.formHasChanged() || this.numberOpeningCalendar && this.numberOpeningCalendar.formHasChanged() || this.hasChangeVoicemailOption;
  }

  // onChangeMaxQueueTime($event): void {
  //   const value: number = $event;
  //   console.log('max-queue-time changed', value);
  //   if (this.waitTimeoutConfigDestination) {
  //     this.waitTimeoutConfigDestination.clearValidations();
  //   }
  // }

  private getWaitTimeoutConfig(): IRoutingAppDestinationData {
    return this.queue && !_.isEmpty(this.queue.wait_timeout_config)
      ? _.cloneDeep(this.queue.wait_timeout_config) : new IRoutingAppDestinationData();
  }

  private getTransferNumbersFormControls(transfer_numbers: QueueTransferNumberEntity[]): FormGroup[] {
    const ret: FormGroup[] = [];
    for (const transfer_number of transfer_numbers) {
      ret.push(this.createTransferNumber(transfer_number));
    }

    return ret;
  }

  updateDidList(): void {
    this.dialogService.openDialog2(QueueEditDidrouteComponent, {
      size: 'l',
      data: {
        queue: _.cloneDeep(this.queue)
      }
    }, (resp: { id: number }) => {
      console.log('resp update did list', resp);
      if (resp && resp.id) {
        const updatedQueue: CallQueueEntity = this.em.getRepository('CallQueueRepository').getObjectById(resp.id);
        if (updatedQueue) {
          this.setOriginQueue(updatedQueue);
        }
      }
    });
  }

  createTransferNumber(transfer_number: QueueTransferNumberEntity): FormGroup {
    return this.fb.group({
      id: [transfer_number && transfer_number.id],
      label: [transfer_number && transfer_number.label, joiValidator(Joi.string().trim().max(64).required())],
      phone_number: [
        transfer_number && transfer_number.phone_number,
        [Validators.required, PhoneNumberValidated(), Validators.maxLength(20)]
      ]
    });
  }

  private getTransferNumberData(transfer_numbers: QueueTransferNumberEntity[]): any[] {
    const ret = [];
    for (const transfer_number of transfer_numbers || []) {
      ret.push(this.createTransferNumber(transfer_number).value);
    }

    return ret;
  }

  private resetTransferNumbersControls(): void {
    while (this.transferNumbersControl && this.transferNumbersControl.length > 0) {
      this.transferNumbersControl.removeAt(0);
    }

    if (_.isEmpty(this.queue.transfer_numbers)) {
      return;
    }

    for (const transfer_number of _.cloneDeep(this.queue.transfer_numbers)) {
      this.transferNumbersControl.push(this.createTransferNumber(transfer_number));
    }
  }

  numberOpeningCalendarChange(): any {
    const value = this.numberOpeningCalendar.getChangeSet();
    this.hasCalendar = !value.opening_calendar;
    const voicemailOption = Object.assign({},
      this.voicemailOption,
      _.pick(value, Object.keys(this.voicemailOption)),
    );
    this.setVoicemailOption(voicemailOption);
    this.onFormValueChange();
  }

  // private resetQueueValue(): void {
  //   this.queue = _.cloneDeep(this.originQueue);
  //   this.cdr.detectChanges();
  // }

  private resetWaitTimeoutConfigDestination(): void {
    if (this.waitTimeoutConfigDestination) {
      this.waitTimeoutConfigDestination.value = this.getWaitTimeoutConfig();
      this.waitTimeoutConfigDestination.resetForm();
    }
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    if (this.numberOpeningCalendar) {
      this.numberOpeningCalendar.resetForm(options);
    }

    this.resetWaitTimeoutConfigDestination();

    this.hasCalendar = !this.opening_calendar;
    // this.resetQueueValue();
    this.resetTransferNumbersControls();
    // this.initVoicemailOption();

    this.queue = this.queueRepo.getObjectById(this.queue.id);
    const formData = this.getFormResetData();
    this.updateModelEocSurveyDuration(!!formData.eoc_survey_min_duration);

    // reset estimated wait time data
    this.queueEstimatedWaitTime.setEstimatedItemsControls();

    if (this.form) {
      this.form.reset(formData, options);
    }

    this.form.markAsPristine();
    this.formValueChanges.next(false);

    // Reset sub form using queue as input
    // this.queue = _.cloneDeep(this.originQueue);
    this.setOriginQueue(_.cloneDeep(this.originQueue));
  }

  pristineForm(): void {
    if (this.numberOpeningCalendar) {
      this.numberOpeningCalendar.pristineForm();
    }
    this.form.markAsPristine();
    this.formValueChanges.next(false);
  }

  private getFormResetData(): any {

    const queue_agents_main: QueueAgentListControlValue = {
      agent_selection_mode: this.queue.agent_selection_mode_main || 0,
      queue_agents: _.cloneDeep(this.queue.main_agents)
    };
    const queue_agents_backup: QueueAgentListControlValue = {
      agent_selection_mode: this.queue.agent_selection_mode_backup || 0,
      queue_agents: _.cloneDeep(this.queue.backup_agents)
    };

    let boUrl = this.queue.bo_url;
    const boMethod = boUrl && boUrl.startsWith('POST|') ? 'POST' : 'GET';
    boUrl = (boUrl || '').replace('POST|', '');

    return {
      agent_ring_duration: this.queue.agent_ring_duration,
      seconds_before_backup: this.queue.seconds_before_backup,
      queue_agents_main,
      queue_agents_backup,
      group: this.queue.group,
      // updateOpeningHour: false,
      availability: this.queue.availability,
      max_inqueue: this.queue.max_inqueue_time,
      // vm_enabled: !!this.vm_enabled,
      // vm_on_timeout: !!this.queue.vm_on_timeout,
      wait_timeout_config: this.getWaitTimeoutConfig(),
      recording_mode: !!this.queue.recording_mode,
      recording_percentage: this.queue.recording_percentage,
      queue_priority: this.queue.queue_priority,
      no_silent: !this.queue.silent,
      play_entire_welcome: !!this.queue.play_entire_welcome,
      no_agent_file: this.queue.no_agent_file,
      moh_file: this.queue.moh_file,
      greeting_file: this.queue.greeting_file,
      waiting_music_file: this.queue.waiting_music_file,
      ewt_enable: !!this.queue.ewt_enable,
      ewt_data: this.queue.ewt_data,
      eoc_ivr_menu: this.queue.eoc_ivr_menu,
      eoc_survey_min_duration: this.queue.eoc_survey_min_duration,
      eoc_survey_direction: this.queue.eoc_survey_direction,
      enable_eoc_survey_duration: !!this.queue.eoc_survey_min_duration,
      aftercall_pause_mode: this.queue.aftercall_pause_mode,
      after_call_time: this.queue.after_call_time,
      has_qualification: !!this.queue.has_qualification,
      qualification_required: !!this.queue.qualification_required,
      in_qualification: this.queue.in_qualification,
      out_qualification: this.queue.out_qualification,
      bo_type: this.queue.bo_type,
      bo_method: boMethod,
      bo_url: boUrl,
      bo_open_derection: this.queue.bo_open_derection,
      transfer_numbers: this.getTransferNumberData(_.cloneDeep(this.queue.transfer_numbers)),
      alias: this.queue.alias,
      xapi_agent_search: this.queue.xapi_agent_search,
      agent_display_number_type: this.queue.agent_display_number_type,
      agent_display_number: this.queue.agent_display_number,
      is_exclusive: !!this.queue.is_exclusive,
      default_did: this.queue.defaultCallingNumber || this.didRepo.getAnonymousDid(),
      satisfaction_survey_enable: !!this.queue.satisfaction_survey_enable,
      survey_prompt_file: this.queue.survey_prompt_file,
      survey_thank_message_file: this.queue.survey_thank_message_file,
      queue_name: this.queue.queue_name,
      request_callback_enable: !!this.queue.request_callback_enable,
      request_callback_after: this.queue.request_callback_after,
      request_callback_annon_sound: this.queue.request_callback_annon_sound,
      request_callback_key: this.queue.request_callback_key || '',
      request_callback_sound: this.queue.request_callback_sound,
      request_callback_confirm_key: this.queue.request_callback_confirm_key || '',
      request_callback_confirmation_sound: this.queue.request_callback_confirmation_sound,
      analysis_enabled: !!this.queue.analysis_enabled,
      analysis_start_dt: this.queue.analysis_start_dt && this.queue.analysis_start_dt.toJSDate() || null,
    };
  }

  // tslint:disable-next-line:cyclomatic-complexity
  getChangeSet(): any {
    const formData = _.cloneDeep(super.getChangeSet());

    if ('queue_name' in formData) {
      formData.queue_name = _.trim(formData.queue_name);
    }
    if (formData.hasOwnProperty('greeting_file')) {
      formData['greeting_file_id'] = formData.greeting_file && formData.greeting_file.id || null;
      delete formData.greeting_file;
    }
    if (formData.hasOwnProperty('no_silent')) {
      formData['silent'] = !formData.no_silent;
      delete formData.no_silent;
    }

    if (formData.hasOwnProperty('waiting_music_file')) {
      formData['waiting_music_file_id'] = formData.waiting_music_file && formData.waiting_music_file.id || null;
      delete formData.waiting_music_file;
    }

    if (formData.hasOwnProperty('moh_file')) {
      formData['moh_file_id'] = formData.moh_file && formData.moh_file.id || null;
      delete formData.moh_file;
    }

    if (formData.hasOwnProperty('no_agent_file')) {
      formData['no_agent_file_id'] = formData.no_agent_file && formData.no_agent_file.id || null;
      delete formData.no_agent_file;
    }

    if (formData.hasOwnProperty('max_inqueue')) {
      formData['max_inqueue'] = parseInt(formData.max_inqueue);
    }

    // main_agents and backup_agents must be updated together
    if ('queue_agents_main' in formData || 'queue_agents_backup' in formData) {
      if ('queue_agents_main' in formData) {
        formData.agent_selection_mode_main = formData.queue_agents_main.agent_selection_mode;
        formData.main_agents = formData.queue_agents_main.queue_agents;
        delete formData.queue_agents_main;
      } else {
        formData.agent_selection_mode_main = this.queueAgentsMainControl.value.agent_selection_mode;
        formData.main_agents = this.queueAgentsMainControl.value.queue_agents;
      }

      if ('queue_agents_backup' in formData) {
        formData.agent_selection_mode_backup = formData.queue_agents_backup.agent_selection_mode;
        formData.backup_agents = formData.queue_agents_backup.queue_agents;
        delete formData.queue_agents_backup;
      } else {
        formData.agent_selection_mode_backup = this.queueAgentsBackupControl.value.agent_selection_mode;
        formData.backup_agents = this.queueAgentsBackupControl.value.queue_agents;
      }
    }

    if ('recording_mode' in formData) {
      formData.recording_mode = formData.recording_mode ? 1 : 0;
    }
    if ('has_qualification' in formData) {
      formData.has_qualification = formData.has_qualification ? 1 : 0;
    }
    if (!this.hasValidQualifications || !this.hasQualificationControl.value) {
      if (formData.hasOwnProperty('in_qualification')) {
        delete formData.in_qualification;
      }

      if (formData.hasOwnProperty('out_qualification')) {
        delete formData.out_qualification;
      }
    }
    if ('play_entire_welcome' in formData) {
      formData.play_entire_welcome = formData.play_entire_welcome ? 1 : 0;
    }

    if ('is_exclusive' in formData) {
      formData.is_exclusive = formData.is_exclusive ? 1 : 0;
    }

    // Estimated Waiting Time
    if ('ewt_enable' in formData) {
      formData.ewt_enable = formData.ewt_enable ? 1 : 0;
    }
    if ('ewt_data' in formData) {
      const changesetEwt: any = formData.ewt_data;
      console.log('formData ewt_data', changesetEwt);
      if (changesetEwt && changesetEwt.estimatedItems) {
        const ewtData: any[] = [];
        for (const ewt of changesetEwt.estimatedItems) {
          if (ewt.counter && ewt.is_default) {
            console.error('counter must be zero if it is default');
          }
          ewtData.push({
            counter: ewt.counter,
            sound_id: ewt.sound ? ewt.sound.id : undefined
          });
        }
        formData.ewt_data = ewtData;
      }
    }

    // Satisfaction survey
    if ('satisfaction_survey_enable' in formData) {
      formData.satisfaction_survey_enable = formData.satisfaction_survey_enable ? 1 : 0;
    }
    if (formData.hasOwnProperty('survey_prompt_file')) {
      formData['survey_prompt'] = formData.survey_prompt_file && formData.survey_prompt_file.id || null;
      delete formData.survey_prompt_file;
    }
    if (formData.hasOwnProperty('survey_thank_message_file')) {
      formData['survey_thank_msg'] = formData.survey_thank_message_file && formData.survey_thank_message_file.id || null;
      delete formData.survey_thank_message_file;
    }

    if ('agent_display_number' in formData) {
      formData['agent_display_number_id'] = formData.agent_display_number && formData.agent_display_number.id || null;
    }

    if (_.hasIn(formData, 'bo_type') && formData.bo_type !== this.backOfficeType.URL && _.hasIn(formData, 'bo_url')) {
      delete formData.bo_url;
    }

    const boType = formData.bo_type || this.form.get('bo_type').value;

    if (boType === this.backOfficeType.URL && (_.hasIn(formData, 'bo_url') || _.hasIn(formData, 'bo_method'))) {
      const boMethod = formData.bo_method || this.form.get('bo_method').value;
      const boUrl = formData.bo_url || this.form.get('bo_url').value;
      formData.bo_url = boMethod === 'POST' ? `${boMethod}|${boUrl}` : boUrl;
    }

    if (_.hasIn(formData, 'bo_method')) {
      delete formData.bo_method;
    }

    if ('request_callback_enable' in formData) {
      formData.request_callback_enable = formData.request_callback_enable ? 1 : 0;
    }

    if (formData.hasOwnProperty('request_callback_annon_sound')) {
      formData['request_callback_annon_sound_id']
        = formData.request_callback_annon_sound && formData.request_callback_annon_sound.id || null;
      delete formData.request_callback_annon_sound;
    }

    if (formData.hasOwnProperty('request_callback_sound')) {
      formData['request_callback_sound_id'] = formData.request_callback_sound && formData.request_callback_sound.id || null;
      delete formData.request_callback_sound;
    }

    if (formData.hasOwnProperty('request_callback_confirmation_sound')) {
      formData['request_callback_confirmation_sound_id']
        = formData.request_callback_confirmation_sound && formData.request_callback_confirmation_sound.id || null;
      delete formData.request_callback_confirmation_sound;
    }

    return formData;
  }

  private showFormFieldError(controlList: any[], field_list: string[]): void {
    for (const item of controlList) {
      const controls = item;

      for (const field of Object.keys(controls)) {
        if (!controls.hasOwnProperty(field) || (field_list && field_list.length && field_list.indexOf(field) === -1)) {
          continue;
        }

        const control = controls[field];
        if (control.invalid) {
          control.markAsTouched();
        }

        if (control instanceof FormArray) {
          const subControls = [];
          for (const subControl of control.controls as FormGroup[]) {
            subControls.push(subControl.controls);
          }
          this.showFormFieldError(subControls, field_list);
        }
      }
    }
  }

  private validQueueAgents(): boolean {
    // Remove required queue-agents
    // this.form.get('queue_agents_main').setErrors(null);
    // if (
    //   // !this.queue.dedicated_user_id &&
    //   (_.isEmpty(this.queueAgentsMainControl.value) || _.isEmpty(this.queueAgentsMainControl.value.queue_agents))
    // ) {
    //   console.error('Queue-agents is invalid');
    //   this.form.get('queue_agents_main').setErrors({ required: true });
    //   this.queueAgentsMainControl.markAsTouched();
    //   this.cdr.detectChanges();
    //   return false;
    // }

    if (
      // (this.agentRingDurationControl.value >= 0 && this.agentRingDurationControl.value < 10) ||
      (this.secondsBeforeBackupControl.value > 0 && this.secondsBeforeBackupControl.value < 10)
    ) {
      console.error('secondsBeforeBackupControl error');
      this.secondsBeforeBackupControl.setErrors({ min_time: true });
      this.secondsBeforeBackupControl.markAsTouched();
      console.error('Seconds before backup shoud be greater than 0 and lesser than 10');
      return false;
    }

    return true;
  }

  isValid(field_list: string[] = []): boolean {
    if (!this.validQueueAgents()) {
      return false;
    }

    if (this.form.get('ewt_enable').value && !this.queueEstimatedWaitTime.formIsValid()) {
      console.error('Form queue estimated wait time is invalid');
      return false;
    }

    if (!this.isValidQualification()) {
      return false;
    }

    if (this.form.get('has_qualification').hasError('required')) {
      this.form.get('has_qualification').setErrors(null);
      this.cdr.detectChanges();
    }

    if (this.form.valid) {
      return true;
    }

    let fieldValid = true;
    // Check valid only field_list
    if (!_.isEmpty(field_list)) {
      for (const field of Object.keys(this.form.controls)) {
        if (field_list.includes(field) && this.form.controls[field].invalid) {
          fieldValid = false;
          console.error('Field is invalid', field);
          // this.mainArea.scrollToElement(this.form.controls[field].nativeElement);
          break;
        }
      }
    }

    if (fieldValid) {
      return true;
    }

    this.showFormFieldError([this.form.controls], field_list);
    this.toast.showError(_ti('form.validator.data_invalid'));

    return false;
  }

  private getUpdateFields(): object {
    return {
      group: 'group_id',
      availability: undefined,
      max_inqueue: 'max_inqueue_time',
      recording_mode: undefined,
      recording_percentage: undefined,
      queue_priority: undefined,
      silent: undefined,
      no_agent_file_id: undefined,
      moh_file_id: undefined,
      greeting_file_id: undefined,
      waiting_music_file_id: undefined,
      play_entire_welcome: undefined,
      ewt_enable: undefined,
      ewt_data: undefined,
      eoc_ivr_menu: 'eoc_survey_id',
      eoc_survey_min_duration: undefined,
      eoc_survey_direction: undefined,
      aftercall_pause_mode: undefined,
      after_call_time: undefined,
      has_qualification: undefined,
      qualification_required: undefined,
      in_qualification: 'in_qualification_id',
      out_qualification: 'out_qualification_id',
      bo_type: undefined,
      bo_url: undefined,
      bo_open_derection: undefined,
      transfer_numbers: undefined,
      // Agents
      agent_ring_duration: undefined,
      seconds_before_backup: undefined,
      agent_selection_mode_main: undefined,
      agent_selection_mode_backup: undefined,
      main_agents: undefined,
      backup_agents: undefined,
      is_exclusive: undefined,
      // Did Route
      default_did: 'default_did_id',
      did_list: undefined,
      // Did Route: Update DidEntity
      action_office_hour: undefined,
      name: undefined,
      welcome_message: undefined,
      route_priority: undefined,
      number: undefined,
      same_routing: undefined,
      lang: undefined,
      gateway: undefined,
      gateway_id: undefined,
      default: undefined,
      is_default: undefined,
      username: undefined,
      password: undefined,
      linked_object_id: undefined,
      linked_object_type: undefined,
      ivrapp_name: undefined,
      comment: undefined,
      country_code: undefined,
      expire_date: undefined,
      is_paid: undefined,
      order_id: undefined,
      use_enterprise_calendar: undefined,
      skill_requirements: undefined,
      // Advance
      alias: undefined,
      xapi_agent_search: undefined,
      agent_display_number_type: undefined,
      agent_display_number_id: undefined,
      satisfaction_survey_enable: undefined,
      survey_prompt: undefined,
      survey_thank_msg: undefined,
      calendar_id: 'opening_hour_calendar_id',
      vm_enabled: undefined,
      // vm_on_timeout: undefined,
      wait_timeout_config: undefined,
      agenda_closed_config: undefined,
      opening_hour_file_id: undefined,
      voicemail_by_mail_enabled: undefined,
      voicemail_in_attachement: undefined,
      voicemail_by_mail_addrs: undefined,
      action_not_office_hour: 'didroutes',
      queue_name: undefined,
      request_callback_enable: undefined,
      request_callback_after: undefined,
      request_callback_annon_sound_id: undefined,
      request_callback_key: undefined,
      request_callback_sound_id: undefined,
      request_callback_confirm_key: undefined,
      request_callback_confirmation_sound_id: undefined,
      analysis_enabled: undefined,
      analysis_start_dt: undefined,
    };
  }

  private getQueueToUpdate(updatedData: any): CallQueueEntity {
    // updatedData = {calendar_id: 111} // form data to update
    // fields = {calendar_id: 'opening_hour_calendar_id'} // keymap between key form data & key to submit
    const updatedQueue = this.queueRepo.create() as CallQueueEntity;
    const fields = this.getUpdateFields();

    updatedQueue.id = this.queue.getId();

    for (const field of Object.keys(updatedData)) {
      if (!updatedData.hasOwnProperty(field) && !(field in fields)) {
        continue;
      }

      const updatedKeyQueue: string = fields[field] || field;

      switch (field) {
        // case 'vm_on_timeout':
        case 'has_qualification':
        case 'ewt_enable':
        case 'silent':
        case 'qualification_required':
        case 'play_entire_welcome':
        case 'analysis_enabled':
          updatedQueue[updatedKeyQueue] = updatedData[field] ? 1 : 0;
          break;
        case 'transfer_numbers':
          const transfer_numbers = [];
          if (updatedData[field].length) {
            for (const i of updatedData[field]) {
              const transfer_number =
                this.em.getRepository<QueueTransferNumberRepository>('QueueTransferNumberRepository').create() as QueueTransferNumberEntity;

              transfer_number.setObjectData(i);
              transfer_number.id = i.id || undefined;
              transfer_numbers.push(transfer_number);
            }
          }
          updatedQueue[updatedKeyQueue] = transfer_numbers;
          break;
        case 'max_inqueue':
          updatedQueue[updatedKeyQueue] = updatedData[field] || 0;
          break;
        case 'group':
        case 'in_qualification':
        case 'out_qualification':
        case 'eoc_ivr_menu':
        case 'default_did':
          updatedQueue[updatedKeyQueue] = updatedData[field] && updatedData[field].id || null;
          break;
        case 'wait_timeout_config':
        case 'agenda_closed_config':
          const waitTimeoutConfig = updatedData[field] || null;
          if (
            !_.isEmpty(waitTimeoutConfig) &&
            waitTimeoutConfig.application === RoutingAppName.voicemail &&
            !_.isEmpty(waitTimeoutConfig.params) && !waitTimeoutConfig.params.mailbox_id
          ) {
            waitTimeoutConfig.params = {};
          }
          updatedQueue[updatedKeyQueue] = waitTimeoutConfig;
          break;
        default:
          updatedQueue[updatedKeyQueue] = updatedData[field];
          break;
      }
    }

    if (updatedQueue.hasOwnProperty('max_inqueue_time') && !updatedQueue['max_inqueue_time']) {
      updatedQueue['wait_timeout_config'] = undefined;
    }

    return updatedQueue;
  }

  getNumberOpeningCalendarValue(): CalendarInputParams {
    // update default did
    let numberOpeningCalendarValue = this.numberOpeningCalendar && this.numberOpeningCalendar.getChangeSet();

    // if (this.hasCalendar && (this.form.get('vm_enabled').dirty || this.hasChangeVoicemailOption)) {
    if (this.hasChangeVoicemailOption) {
      numberOpeningCalendarValue = Object.assign({},
        // { vm_enabled: this.form.get('vm_enabled').value },
        numberOpeningCalendarValue,
        this.voicemailOption,
      );
    }

    if (!_.isEmpty(numberOpeningCalendarValue)) {
      if (numberOpeningCalendarValue.calendar_reroute && !this.formService.isValidCallDestination(numberOpeningCalendarValue.action_not_office_hour)) {
        throw new Error(_ti('form.validator.data_invalid'));
      }

      return this.formService.makeNumberOpeningCalendarValue(numberOpeningCalendarValue);
    }
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector('form .ng-invalid');
    if (firstInvalidControl) {
      this.mainArea.scrollToElement(firstInvalidControl);
    }
  }

  validateForm(): boolean {
    if (this.boTypeControl.value === this.backOfficeType.URL) {
      if (!this.boUrlControl.value) {
        console.error('boUrlControl error');
        this.boUrlControl.setErrors({ required: true });
      }

      const boUrl = (this.boUrlControl.value || '').replace('POST|', '');
      if (!boUrl) {
        console.error('boUrlControl error');
        this.boUrlControl.setErrors({ url_invalid: true });
      }
    } else {
      this.boUrlControl.setErrors(null);
    }

    // this.inQualificationControl.setErrors(null);
    // this.outQualificationControl.setErrors(null);
    // if (this.hasQualificationControl.value &&
    //   (!this.inQualificationControl.value || !this.inQualificationControl.value.id) &&
    //   (!this.outQualificationControl.value || !this.outQualificationControl.value.id)
    // ) {
    //   this.inQualificationControl.setErrors({ required: true });
    //   this.outQualificationControl.setErrors({ required: true });
    // }

    this.agentDisplayNumberControl.setErrors(null);
    if (this.agentDisplayNumberTypeControl.value &&
      this.agentDisplayNumberTypeControl.value === this.agentDisplayNumberDidNumber &&
      !this.agentDisplayNumberControl.value
    ) {
      console.error('agentDisplayNumberControl error');
      this.agentDisplayNumberControl.setErrors({ required: true });
    }

    this.form.get('wait_timeout_config').setErrors(null);

    if (this.form.get('max_inqueue').value) {
      const waitTimeoutConfig = this.form.get('wait_timeout_config').value;
      if (!_.isEmpty(waitTimeoutConfig) &&
        (!this.waitTimeoutConfigDestination || !this.waitTimeoutConfigDestination.validParam())
      ) {
        console.error('wait_timeout_config error');
        this.form.get('wait_timeout_config').setErrors({ callDestination: true });
      }
    }

    this.form.get('request_callback_annon_sound').setErrors(null);
    if (this.form.get('request_callback_enable').value && !this.form.get('request_callback_annon_sound').value) {
      console.error('request_callback_annon_sound error');
      this.form.get('request_callback_annon_sound').setErrors({ required: true });
    }

    this.form.get('request_callback_sound').setErrors(null);
    if (this.form.get('request_callback_enable').value && !this.form.get('request_callback_sound').value) {
      console.error('request_callback_sound error');
      this.form.get('request_callback_sound').setErrors({ required: true });
    }

    this.form.get('request_callback_confirmation_sound').setErrors(null);
    if (this.form.get('request_callback_enable').value
      && this.form.get('request_callback_confirm_key').value
      && !this.form.get('request_callback_confirmation_sound').value
    ) {
      console.error('request_callback_confirmation_sound error');
      this.form.get('request_callback_confirmation_sound').setErrors({ required: true });
    }

    this.form.markAllAsTouched();
    this.cdr.detectChanges();
    return this.numberOpeningCalendar.validateForm() && this.form.valid;
  }

  async submitForm(): Promise<CallQueueEntity> {
    try {
      // Clear validations
      if (this.waitTimeoutConfigDestination) {
        this.waitTimeoutConfigDestination.clearValidations();
      }

      this.eocIvrMenuControl.setErrors(null);
      this.eocSurveyMinDurationControl.setErrors(null);

      if (this.enableEocSurveyDuration) {
        if (!this.eocIvrMenuControl.value) {
          console.error('eocIvrMenuControl error');
          this.eocIvrMenuControl.setErrors({
            required: true
          });
        }

        if (!this.eocSurveyMinDurationControl.value || this.eocSurveyMinDurationControl.value <= 0) {
          console.error('eocSurveyMinDurationControl error');
          this.eocSurveyMinDurationControl.setErrors({
            greater_than_0: true
          });
        }
      }

      if (this.enableCallAnalysis) {
        if (this.form.get('analysis_enabled').value) {
          if (!this.form.get('analysis_start_dt').value) {
            this.form.get('analysis_start_dt').setErrors({
              required: true
            });
          }
        } else {
          this.form.get('analysis_start_dt').setErrors(null);
        }
      }

      if (!this.validateForm()) {
        this.scrollToFirstInvalidControl();
        throw new Error(_ti('form.validator.data_invalid'));
      }

      const updatedData = this.getChangeSet();

      // if (updatedData.eoc_survey_min_duration > 0 && !this.eocIvrMenuControl.value) {
      //   const eocIVRMenuControl: HTMLElement = this.elementRef.nativeElement.querySelector('form ivr-evaluation-select-form-input');
      //   this.mainArea.scrollToElement(eocIVRMenuControl);
      //   this.toast.showError(_ti('call_queue_edit.validators.ivr_evaluation_required'));
      //   return undefined;
      // }

      // Update other fields
      const update_queue_fields: string[] = [];
      const fields = this.getUpdateFields() as Object;

      const newNumberOpeningCalendarValue = this.getNumberOpeningCalendarValue();

      // Update default did
      if (newNumberOpeningCalendarValue) {
        if (this.editingDID) {
          await this.em.getRepository<DidRepository>('DidRepository')
            .saveAttrs(this.editingDID, Object.keys(newNumberOpeningCalendarValue), newNumberOpeningCalendarValue);
          this.hasChangeVoicemailOption = false;

          if (_.isEmpty(updatedData)) {
            this.toast.showSuccess(_ti('public.message.update_success'));
            return this.queue;
          }
        } else {
          _.extend(updatedData, newNumberOpeningCalendarValue);
          // console.log('updatedData', updatedData);
        }
      }

      // Update queue
      // const update_queue_fields = Object.keys(fields).map(field => fields[field] || field)
      //   .filter(f => updatedData.hasOwnProperty(f) && !_.isUndefined(updatedData[f]));
      for (const field of Object.keys(updatedData)) {
        if (fields.hasOwnProperty(field)) {
          update_queue_fields.push(fields[field] ? fields[field] : field);
        }
      }

      const updateQueue = this.getQueueToUpdate(updatedData);

      if (!update_queue_fields.length) {
        return this.queue;
      }

      // Check exclusive confirmation
      const mainAgentLink: CallQueueAgentLinkEntity[] =
        this.queueAgentsMainControl.value && (this.queueAgentsMainControl.value as QueueAgentListControlValue).queue_agents || [];

      const backupAgentLink: CallQueueAgentLinkEntity[] =
        this.queueAgentsMainControl.value && (this.queueAgentsBackupControl.value as QueueAgentListControlValue).queue_agents || [];

      const agentLinks: CallQueueAgentLinkEntity[] = mainAgentLink.concat(backupAgentLink.filter(x => !_.includes(mainAgentLink, x)));

      // Check to show exclusive confirmation or not
      if (this.exclusiveConfirmedLinkEntities.length &&
        (this.exclusiveConfirmedLinkEntities.length !== agentLinks.length ||
          this.exclusiveConfirmedLinkEntities.find(x => !_.includes(agentLinks.map(i => i.id), x.id)))
      ) {
        const agentFails: AgentEntity[] = [];
        const agents: AgentEntity[] = agentLinks.map(x => x.agent);
        for (const agent of agents) {
          const inboundQueues: CallQueueEntity[] = agent.inbound_queue_list;
          if (!_.isEmpty(inboundQueues) && inboundQueues.length > 1) {
            agentFails.push(agent);
          }
        }

        if (!_.isEmpty(agentFails)) {
          console.error('Form queue is-exclusive is invalid');
          // this.form.get('is_exclusive').setErrors({
          //   agent_inused: _ti('call_queue.message.exclusive_agent_inused', {  agent_names: agentFailNames.join(', ') })
          // });
          // return false;
          // this.toast.showWarning(_ti('call_queue.message.exclusive_agent_inused', {  agent_names: agentFailNames.join(', ') }));
          this.dialogService.openDialog2(
            ConfirmAgentEligibleComponent,
            {
              data: {
                description: _ti('call_queue.message.exclusive_eligible'), // queue is exclusive or not
                agent_links: agentLinks
              },
              size: 's'
            },
            (data_dialog: any) => {
              this.exclusiveConfirmedLinkEntities = data_dialog.agent_links;
              if (!data_dialog) {
                return;
              }
              if (data_dialog.agent_links) {
                for (const agentLink of data_dialog.agent_links) {
                  for (const ctrl of [this.queueAgentsMainControl, this.queueAgentsBackupControl]) {
                    const value = _.cloneDeep(ctrl.value);
                    const queueAgents: CallQueueAgentLinkEntity[] = [];
                    if (value && value.queue_agents as CallQueueAgentLinkEntity[]) {
                      const keptValue: CallQueueAgentLinkEntity = value.queue_agents.find(
                        x => x.call_queue_keyid === agentLink.call_queue_keyid && x.operator_keyid === agentLink.operator_keyid
                      );

                      if (keptValue) {
                        queueAgents.push(keptValue);
                      }
                    }

                    value.queue_agents = queueAgents;

                    ctrl.setValue(value);
                    ctrl.markAsDirty();
                    ctrl.markAsTouched();
                    ctrl.updateValueAndValidity();
                  }
                }
              }
            }
          );

          return;
        }
      }

      // validation data prevent submit
      if (!this.isValid(update_queue_fields)) {
        console.error('Update queue failure');
        this.form.markAsTouched();
        this.cdr.detectChanges();
        throw new Error(_ti('form.validator.data_invalid'));
      }

      const extraAttrsDefault = {
        voicemail_by_mail_enabled: 0,
        voicemail_in_attachement: 0,
        voicemail_by_mail_addrs: [],
      };
      const extraAttrs: any = {};
      for (const k of Object.keys(extraAttrsDefault)) {
        if (k in updatedData) {
          extraAttrs[k] = updatedData[k] || extraAttrsDefault[k];
        }
      }

      let result;
      try {
        result = await this.queueRepo.saveAttrs(updateQueue, update_queue_fields, extraAttrs);
      } catch (error) {
        throw new Error(_ti(error.message));
      }

      if (result) {
        this.hasChangeVoicemailOption = false;
        this.toast.showSuccess(_ti('call_queue.message.update_success'));
        const updatedQueue: CallQueueEntity = this.queueRepo.getObjectById(result.object_id);
        updatedQueue.fetchRelatedData();
        this.setOriginQueue(updatedQueue);
        this.updateModelEocSurveyDuration(!!updatedQueue.eoc_survey_min_duration);
      }

      // this.pristineForm();
      this.resetForm();
      return this.queue;
    } catch (error) {
      console.error('Update error', error);
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  // async updateQueueCalendar(
  //   value: {
  //     updateOpeningHour?: boolean,
  //     availability?: number;
  //     calendar?: OpeningHourCalendarEntity
  //   }
  // ): Promise<void> {
  //   console.log('updateQueueCalendar', value);
  //   if (!value) {
  //     console.error('No value changed');
  //     return;
  //   }

  //   if (value.hasOwnProperty('updateOpeningHour') && value.updateOpeningHour) {
  //     this.setFormControlValue('updateOpeningHour', true);
  //   }

  //   if (value.hasOwnProperty('availability')) {
  //     this.setFormControlValue('availability', value.availability);
  //   }

  //   this.onFormValueChange();
  // }

  async delete(): Promise<any> {
    const confirmed: boolean = await this.callQueueService.getConfirmDeleteCallQueues([this.queue]);
    if (confirmed) {
      const result = this.dialogService.confirmDialog(
        _ti('dialogs.confirmation'),
        _ti('user.title.delete'),
        async () => {
          try {
            await this.queueRepo.bulkDelete([this.queue]);
            this.close();
            this.toast.showSuccess(_ti('call_queue.message.delete_success'));
          } catch (e) {
            console.error('Delete call queue error', e);
            this.toast.showErrorMessage(e, _ti('call_queue.message.delete_failure'));
          }
        }
      );
      return result;
    }
  }

  archive(): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.archive_confirm'),
      () => {
        this.updateArchive(1);
      }
    );
  }

  unarchive(): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.unarchive_confirm'),
      () => {
        this.updateArchive(0);
      }
    );
  }

  private async updateArchive(archive: number): Promise<void> {
    try {
      await this.queueRepo.bulkArchive([this.queue], archive);
      this.queue.is_archived = archive; // manually set variable
      const successMsg = archive ? _ti('public.message.archive_success') : _ti('public.message.unarchive_success');
      this.toast.showSuccess(successMsg);
    } catch (e) {
      console.error('Archive/Unarchive call queue error', e);
      const defaultMsg = archive ? _ti('public.message.archive_failure') : _ti('public.message.unarchive_failure');
      this.toast.showErrorMessage(e, defaultMsg);
    }
  }

  get stepKeys(): string[] {
    return Object.keys(this.steps).sort();
  }

  get queueAgentsMainControl(): FormControl {
    return this.form.get('queue_agents_main') as FormControl;
  }

  get queueAgentsBackupControl(): FormControl {
    return this.form.get('queue_agents_backup') as FormControl;
  }

  // get agentRingDurationControl(): FormControl {
  //   return this.form.get('agent_ring_duration') as FormControl;
  // }

  get secondsBeforeBackupControl(): FormControl {
    return this.form.get('seconds_before_backup') as FormControl;
  }

  get hasQualificationControl(): FormControl {
    return this.form.get('has_qualification') as FormControl;
  }

  get qualificationRequiredControl(): FormControl {
    return this.form.get('qualification_required') as FormControl;
  }

  get inQualificationControl(): FormControl {
    return this.form.get('in_qualification') as FormControl;
  }

  get outQualificationControl(): FormControl {
    return this.form.get('out_qualification') as FormControl;
  }

  get availabilityControl(): FormControl {
    return this.form.get('availability') as FormControl;
  }

  // get languageControl(): FormControl {
  //   return this.form.get('language') as FormControl;
  // }

  get boTypeControl(): FormControl {
    return this.form.get('bo_type') as FormControl;
  }

  get boUrlControl(): FormControl {
    return this.form.get('bo_url') as FormControl;
  }

  get transferNumbersControl(): FormArray {
    return this.form && this.form.get('transfer_numbers') as FormArray;
  }

  get groupControl(): FormControl {
    return this.form.get('group') as FormControl;
  }

  get maxInqueueControl(): FormControl {
    return this.form.get('max_inqueue') as FormControl;
  }

  get queuePriorityControl(): FormControl {
    return this.form.get('queue_priority') as FormControl;
  }

  get aliasControl(): FormControl {
    return this.form.get('alias') as FormControl;
  }

  get xapiAgentSearchControl(): FormControl {
    return this.form.get('xapi_agent_search') as FormControl;
  }

  get agentDisplayNumberTypeControl(): FormControl {
    return this.form.get('agent_display_number_type') as FormControl;
  }

  get agentDisplayNumberControl(): FormControl {
    return this.form.get('agent_display_number') as FormControl;
  }

  get isExclusiveControl(): FormControl {
    return this.form.get('is_exclusive') as FormControl;
  }

  get eocSurveyMinDurationControl(): FormControl {
    return this.form.get('eoc_survey_min_duration') as FormControl;
  }

  get eocIvrMenuControl(): FormControl {
    return this.form.get('eoc_ivr_menu') as FormControl;
  }

  removeControlValue(controlName: string): void {
    const defaultValue = controlName === 'default_did' ? this.didRepo.getAnonymousDid() : undefined;
    this.setFormControlValue(controlName, defaultValue);

    if (controlName === 'eoc_ivr_menu') {
      this.disableEocSurveyDuration();
    }
  }

  removeRequestCallbackAfter(): void {
    this.setFormControlValue('request_callback_after', 0);
  }

  addTransferNumber(): void {
    const new_transfer_number = EntityManager.getRepository(
      'QueueTransferNumberRepository'
    ).create() as QueueTransferNumberEntity;
    this.transferNumbersControl.markAsDirty();
    this.transferNumbersControl.push(this.createTransferNumber(new_transfer_number));
  }

  removeTransferNumber(index: number): void {
    this.transferNumbersControl.markAsDirty();
    this.transferNumbersControl.removeAt(index);
  }

  hasControlValue(controlName: string): boolean {
    const controlValue = this.form.get(controlName).value;
    if (controlName === 'default_did' && this.hasAnonymous) {
      return !!this.form.get(controlName).value.id;
    }
    return controlName && controlValue ? true : false;
  }

  updateSoundFile(formControlName, uploadResult: { new_file_entry: FileEntryEntity; remove?: boolean }): void {
    const oldFileEntryMapping = {
      no_agent_file: this.form.get('no_agent_file').value,
      moh_file: this.form.get('moh_file').value,
      greeting_file: this.form.get('greeting_file').value,
      waiting_music_file: this.form.get('waiting_music_file').value,
      survey_prompt_file: this.form.get('survey_prompt_file').value,
      survey_thank_message_file: this.form.get('survey_thank_message_file').value,
      request_callback_sound: this.form.get('request_callback_sound').value,
      request_callback_confirmation_sound: this.form.get('request_callback_confirmation_sound').value,
      request_callback_annon_sound: this.form.get('request_callback_annon_sound').value,
    };

    if (!oldFileEntryMapping.hasOwnProperty(formControlName)) {
      console.error('Form control name not found', formControlName);

      return;
    }

    const fileEntry: FileEntryEntity = uploadResult.new_file_entry; // Empty new_file_entry and remove is true mean remove sound

    if (!uploadResult.remove && !fileEntry.is_default && (!fileEntry || fileEntry.getId() === null || fileEntry.getId() === undefined)) {
      console.error('No file entry created/updated');

      return;
    }

    this.setFormControlValue(formControlName, fileEntry);
  }

  updateEwtData(changes: any): void {
    // console.log('updateEwtData', changes);
    this.setFormControlValue('ewt_data', changes);
  }

  private setFormControlValue(formControlName: string, value: any): void {
    this.form.get(formControlName).markAsDirty();
    this.form.get(formControlName).setValue(value);
  }

  dragDropAgentsChange(
    currentField: string,
    changeRefData: {
      source: CallQueueAgentLinkEntity[];
      target?: CallQueueAgentLinkEntity[];
    }
  ): void {
    this.form.markAsDirty();

    // Only update data when drag item to other list
    if ('target' in changeRefData) {
      const updateControlValue: QueueAgentListControlValue = {
        agent_selection_mode: 0,
        queue_agents: changeRefData.target
      };

      if (currentField === 'main_agents') {
        updateControlValue.agent_selection_mode = this.queueAgentsBackupControl.value.agent_selection_mode;
        // this.queueAgentsBackupControl.setValue(updateControlValue);
        this.setFormControlValue('queue_agents_backup', updateControlValue);
      }

      if (currentField === 'backup_agents') {
        updateControlValue.agent_selection_mode = this.queueAgentsMainControl.value.agent_selection_mode;
        // this.queueAgentsMainControl.setValue(updateControlValue);
        this.setFormControlValue('queue_agents_main', updateControlValue);
      }
    }
  }

  dialogQueueHistory(): void {
    if (this.queue.id) {
      this.dialogService.openDialog2(DialogQueueHistoryComponent, {
        data: {
          queue_ids: [this.queue.id],
          show_filter: false
        }
      });
    }
  }

  openVoicemailByEmailSettings(): void {
    this.dialogService.openSideDialog(VoicemailOptionModal,
      {
        data: { voicemailOption: this.voicemailOption },
        size: 's'
      },
      (voicemailSetting: VoicemailOptionInterface) => {
        if (!voicemailSetting) {
          return;
        }
        this.voicemailOption = voicemailSetting;
        this.hasChangeVoicemailOption = true;
        this.numberOpeningCalendarUpdateVoicemailOption(voicemailSetting);
        this.onFormValueChange();
      }
    );
  }

  numberOpeningCalendarUpdateVoicemailOption(voicemailSetting: VoicemailOptionInterface): void {
    this.numberOpeningCalendar.form.get('voicemail_by_mail_enabled').setValue(voicemailSetting.voicemail_by_mail_enabled);
    this.numberOpeningCalendar.form.get('voicemail_in_attachement').setValue(voicemailSetting.voicemail_in_attachement);
    this.numberOpeningCalendar.form.get('voicemail_by_mail_addrs').setValue(voicemailSetting.voicemail_by_mail_addrs);
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  changeBoType(): void {
    if (this.boTypeControl.value !== this.backOfficeType.URL) {
      this.boUrlControl.markAsPristine();
    }
  }

  updateQualificationValue(hasQualification: boolean): void {
    this.form.clearValidators();
    if (!this.hasValidQualifications) {
      return;
    }

    if (!hasQualification) {
      if (this.qualificationRequiredControl.value) {
        this.qualificationRequiredControl.setValue(false);
      }
      this.inQualificationControl.setValue(this.queue.in_qualification, {
        onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true
      });
      this.outQualificationControl.setValue(this.queue.out_qualification, {
        onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true
      });
    } else if (!this.isValidQualification()) {
      this.form.setValidators([OneRequired(['in_qualification', 'out_qualification'])]);
      this.form.markAllAsTouched();
    }
  }

  private isValidQualification(): boolean {
    if (
      this.hasQualificationControl.value &&
      this.hasValidQualifications &&
      (!this.inQualificationControl.value || !this.inQualificationControl.value.id) &&
      (!this.outQualificationControl.value || !this.outQualificationControl.value.id)
    ) {
      // console.error('Form in_qualification or out_qualification is invalid');
      console.error('inQualificationControl error');
      this.inQualificationControl.setErrors({ oneRequired: true });
      this.outQualificationControl.setErrors({ oneRequired: true });
      this.inQualificationControl.markAsTouched();
      this.outQualificationControl.markAsTouched();
      return false;
    }

    return true;
  }

  updateModelEocSurveyDuration(enable: boolean): void {
    this.enableEocSurveyDuration = enable;
  }

  changeEocSurveyDuration($event: boolean): void {
    // console.log('changeEocSurveyDuration fired', $event);
    // if ($event.checked) {
    //   return;
    // }
    // const control = this.form.get('eoc_survey_min_duration');
    // if (control.value !== 0) {
    //   control.markAsDirty();
    //   control.setValue(0);
    // }
    this.enableEocSurveyDuration = $event;

    this.eocSurveyMinDurationControl.setValue(
      !$event ? 0 : this.originQueue && this.originQueue.eoc_survey_min_duration || CallQueueEditComponent.DefaultMinSurveyDuration
    );

    this.eocSurveyMinDurationControl.markAsDirty();
    this.form.updateValueAndValidity();
  }

  disableEocSurveyDuration(): void {
    this.changeEocSurveyDuration(false);
    // if (!this.enableEocSurveyDuration) {
    //   return;
    // }
    // this.enableEocSurveyDuration = false;
    // const control = this.form.get('eoc_survey_min_duration');
    // if (control.value !== 0) {
    //   control.markAsDirty();
    //   control.setValue(0);
    // }
  }

  updateWaitTimeoutConfig(data: IRoutingAppDestinationData): void {
    if (!this.form.get('max_inqueue').value) {
      return;
    }

    let waitTimeoutConfig: any;
    if (data && data.application) {
      waitTimeoutConfig = data;
    }

    this.form.get('wait_timeout_config').markAsDirty();
    this.form.get('wait_timeout_config').setValue(waitTimeoutConfig || null);
  }

  isVoicemailEnabled(): boolean {
    const waitTimeoutConfig = this.form.get('wait_timeout_config').value;
    return waitTimeoutConfig && waitTimeoutConfig.application === RoutingAppName.voicemail || false;
  }
}
