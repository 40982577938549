<div fxLayout="column" fxFlex="grow" class="flex-container" *ngIf="is_ready">
  <h2 class="mb-0">{{ 'voicemail.title'| translate }}</h2>
  <div class="filter-container">
    <form [formGroup]="filterForm">
      <period-select [startDate]="startDateControl.value" [endDate]="endDateControl.value"
        (changedPeriod)="changePeriod($event)"></period-select>
      <div *ngIf="filtersReadonly" align="center">
        <div fxLayout="column">
          <span *ngIf="startDateControl.value && endDateControl.value">
            {{startDateControl.value | timeFormatDateShort }} --&gt;
            {{ endDateControl.value | timeFormatDateShort }}
          </span>
          <span *ngIf="filterForm.get('listen_state').value !== ''">
            <strong>{{ 'recording_call.table_filter.listen_state'|translate }}</strong>:
            {{ filterForm.get('listen_state').value == 0 ?
            ('recording_call.table_filter.listen_state_unlistened' | translate) :
            ('recording_call.table_filter.listen_state_listened' | translate)
            }}
          </span>
          <span *ngIf="filterForm.get('archived').value !== ''">
            <strong data-ci="show_archived">{{ (filterForm.get('archived').value === true ?
              'public.show_list_item.archived_messages' : 'public.show_list_item.active_messages')|translate }}</strong>
          </span>
          <span *ngIf="filterForm.get('calling_number').value &&  filterForm.get('calling_number').value.trim()">
            <strong>{{ 'voicemail.calling_number'|translate }}</strong>: {{filterForm.get('calling_number').value}}
          </span>
          <span *ngIf="filterForm.get('groups').value.length">
            <strong>{{ 'stats.filter.group'|translate }}</strong>: {{
            rendererGroup(filterForm.get('groups').value)|truncateArray }}
          </span>
          <span *ngIf="filterForm.get('queues').value.length">
            <strong>{{ 'stats.filter.queue'|translate }}</strong>: {{
            rendererQueue(filterForm.get('queues').value)|truncateArray }}
          </span>
          <span *ngIf="filterForm.get('numbers').value.length">
            <strong>{{ 'stats.filter.phone_number'|translate }}</strong>:
            {{ rendererNumber(filterForm.get('numbers').value)|truncateArray }}
          </span>
          <a data-ci="btn-change-filtering" class="link" (click)="changeFilter()">{{ 'public.change'|translate }}</a>
        </div>
      </div>
      <div *ngIf="!filtersReadonly">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.from'|translate }}</mat-label>
              <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
                placeholder="{{ 'public.from'|translate }}">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>

              <mat-error [flexFormControlError]="filterForm.get('start_dt')"></mat-error>
              <!-- <mat-error
                *ngIf="(startDateControl.hasError('flexDatepickerMin') || startDateControl.hasError('required')) && startDateControl.touched">
                {{ 'form.validator.required'|translate }}
              </mat-error> -->
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.to'|translate }}</mat-label>
              <input matInput [min]="filterForm.get('start_dt').value" [matDatepicker]="endPicker"
                formControlName="end_dt" [matDatepickerFilter]="minEndDateFilter"
                placeholder="{{ 'public.to'|translate}}">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>

              <mat-error [flexFormControlError]="filterForm.get('end_dt')" [customMessages]="{
                matDatepickerMin: 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate:{min: filterForm.get('end_dt').errors?.matDatepickerMin.min |timeFormatDateShort }
              }"></mat-error>
              <!-- <mat-error *ngIf="endDateControl.invalid && endDateControl.touched">
                <ng-container *ngIf="endDateControl.hasError('required')">
                  {{ 'form.validator.required'|translate }}
                </ng-container>
                <ng-container
                  *ngIf="startDateControl.value && (endDateControl.hasError('flexDatepickerMin') || endDateControl.hasError('matDatepickerFilter'))">
                  {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
                  startDateControl.value | timeFormatDateShort} }}
                </ng-container>
              </mat-error> -->
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'recording_call.table_filter.listen_state'|translate }}</mat-label>
              <mat-select placeholder="{{'recording_call.table_filter.listen_state'|translate}}"
                formControlName="listen_state">
                <mat-option value="">{{'recording_call.table_filter.listen_state_any' | translate}}</mat-option>
                <mat-option value="0">{{'recording_call.table_filter.listen_state_unlistened' | translate}}</mat-option>
                <mat-option value="1">{{'recording_call.table_filter.listen_state_listened' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'voicemail.calling_number'|translate }}</mat-label>
              <input matInput placeholder="{{'voicemail.calling_number'|translate}}" formControlName="calling_number">
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'calling_number')"
                [disabled]="!filterForm.get('calling_number').value">
                <mat-icon>close</mat-icon>
              </button>

              <mat-error [flexFormControlError]="filterForm.get('calling_number')" [customMessages]="{
                invalidPhoneNumber: 'public.message.phone_number_invalid'|translate
              }"></mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'stats.filter.select_did'|translate }}</mat-label>
              <flex-select-did-input [multiple]="true" [showCheckAll]="true"
                placeholder="{{ 'stats.filter.select_did' | translate }}" formControlName="numbers">
              </flex-select-did-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'numbers', true)"
                [disabled]="!filterForm.get('numbers').value || !filterForm.get('numbers').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item" *ngIf="hasFeature('SERVICE_GROUP')">
            <mat-form-field class="full-width">
              <mat-label>{{ 'stats.filter.select_group'|translate }}</mat-label>
              <flex-select-group-input [limitedByRole]="true" [showCheckAll]="true" [multiple]="true"
                [hasAgentOrQueueOrDid]="true" groupType="service"
                placeholder="{{ 'stats.filter.select_group' | translate }}" formControlName="groups">
              </flex-select-group-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'groups', true)"
                [disabled]="!filterForm.get('groups').value || !filterForm.get('groups').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'stats.filter.select_queue'|translate }}</mat-label>
              <flex-select-queue-input [limitedByRole]="true" [showCheckAll]="true" [groupIds]="groupsControl.value"
                [multiple]="true" queueType="inbound" placeholder="{{ 'stats.filter.select_queue' | translate }}"
                formControlName="queues"></flex-select-queue-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'queues', true)"
                [disabled]="!filterForm.get('queues').value || !filterForm.get('queues').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-slide-toggle formControlName="archived"
              [matTooltip]="'public.show_list_item.archived_messages'|translate">
              {{ 'public.show_list_item.archived_messages'|translate }}
            </mat-slide-toggle>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
          <div class="text-center">
            <button data-ci="btn-filter" mat-raised-button color="accent" (click)="reloadClick()" class="button-show">{{
              'public.apply' | translate }}</button>
            <!--mat-button-toggle-group>
              <mat-button-toggle mat-raised-button color="warn" (click)="reloadClick()">{{ 'public.apply' | translate }}
              </mat-button-toggle>
              <mat-button-toggle mat-raised-button color="warn" [matMenuTriggerFor]="menu">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </mat-button-toggle>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="reloadClick()">{{ 'public.apply' | translate }}</button>
                <button mat-menu-item (click)="exportCsv()">{{ 'public.export' | translate }}</button>
              </mat-menu>
            </mat-button-toggle-group-->
          </div>
        </div>

      </div>
    </form>
  </div>
  <div class="display-voicemail">
    <h3 *ngIf="unlistened_number || totalNumber">
      <strong>
        <span class="mat-text-warn">
          {{ 'voicemail.voicemail_unlisten'|translate:{number_unlisten: unlistened_number} }}
        </span>/ {{totalNumber}}
      </strong>
    </h3>
  </div>
  <div fxFlex="grow">
    <flex-table #flexTable fxFill fxFlex [dataSource]="dataSource" [tableConfig]="tableConfig" [columnTemplates]="{calling_number: tplCallingNumber,
                                    called_number: tplCalledNumber,
                                    duration: tplDuration,
                                    receive_dt: tplReceiveDt,
                                    queue_name: tplQueueName,
                                    readers: tplReaders,
                                    action: tplAction}">
      <ng-template #tplCallingNumber let-item="row">
        <div class="client-info" [ngClass]="{'font-weight-bold': !item.is_read}">
          {{ item.calling_number|displayPhoneNumber }}
          <ng-container *ngIf="item.client_name">&nbsp;({{item.client_name}})</ng-container>
        </div>
      </ng-template>
      <ng-template #tplCalledNumber let-item="row">
        <span [ngClass]="{'font-weight-bold': !item.is_read}">{{ calledNumberRenderer(item) }}</span>
      </ng-template>
      <ng-template #tplDuration let-item="row">
        <span [ngClass]="{'font-weight-bold': !item.is_read}">{{ (item.duration || 0)|seconds_as_duration }}</span>
      </ng-template>
      <ng-template #tplReceiveDt let-item="row">
        <span [ngClass]="{'font-weight-bold': !item.is_read}">{{ item.receive_dt | timeFormatDateTimeShort }}</span>
      </ng-template>
      <ng-template #tplQueueName let-item="row">
        <span [ngClass]="{'font-weight-bold': !item.is_read}">{{ item.mailbox_type ==='queue' && item.queue_name ||
          ('voicemail.personal'|translate) }}</span>
      </ng-template>
      <ng-template #tplReaders let-item="row">
        <div fxLayout="column">
          <div *ngIf="item.readers && item.readers.length" class="col-renders-container"
            [ngClass]="{'font-weight-bold': !item.is_read}">
            <ng-container *ngFor="let user_id of item.readers; let last = last">
              <span>{{ userService.user_full_name(user_id) }}</span><span *ngIf="!last">, </span>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template #tplAction let-item="row">
        <div class="cell-actions">
          <!-- <ng-container *ngIf="item.file_entry_ready">
            <small class="mat-text-warn" *ngIf="!item.file_entry_ready">
              {{ 'fileentry.label.not_available'|translate }}</small>
            <fileentry-player-button *ngIf="item.sound_id" [filePublicId]="item.sound_id" lang=""
              (onPlay)="updateListened(item)"></fileentry-player-button>
          </ng-container> -->

          <fileentry-player-button [disabled]="!item.sound_id || !item.file_entry_ready" [filePublicId]="item.sound_id"
            lang="" (onPlay)="updateListened(item)"></fileentry-player-button>

          <button [disabled]="!canMakeCall()" mat-icon-button color="primary" (click)="makeCall(item)"
            title="{{ 'public.button.call_now'|translate }}">
            <mat-icon>phone</mat-icon>
          </button>

          <button data-ci="btn-archive" mat-icon-button color="primary"
            (click)="item.is_archived == 0 ? bulkArchive([item]) : bulkUnarchive([item])"
            title="{{ (item.is_archived ? 'public.unarchive' : 'public.archive')|translate }}">
            <mat-icon *ngIf="item.is_archived === 1; else BlockIconArchive">unarchive</mat-icon>
            <ng-template #BlockIconArchive>
              <mat-icon>archive</mat-icon>
            </ng-template>
          </button>

          <ng-container *ngIf="canTakeAction">
            <button data-ci="btn-actions" mat-icon-button color="primary" (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="menuAction">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menuAction="matMenu">
              <button mat-icon-button [disabled]="!item.file_entry_ready" (click)="downloadVoicemail(item)"
                matTooltip="{{ 'public.button.download'|translate }}">
                <mat-icon class="button-download">cloud_download</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteVoicemail(item)"
                matTooltip="{{ 'public.button.trash'|translate }}">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button (click)="detailVoicemail(item)"
                matTooltip="{{ 'call.actions.view_detail'|translate }}">
                <mat-icon>info</mat-icon>
              </button>
            </mat-menu>
          </ng-container>
        </div>
      </ng-template>
    </flex-table>
  </div>
</div>
