import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberService } from '../../services/phonenumber.service';

@Pipe({
  name: 'displayPhoneNumber'
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private phoneNumberService: PhoneNumberService) {
  }

  transform(value: any, args?: any): string {
    return this.phoneNumberService.getDisplayNumber(value);
  }

}
