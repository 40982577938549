import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { UserEntity } from '@wephone-core/model/entity/user';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { DidEntity } from '@wephone-core/model/entity/did';
import { SmsTemplateEntity } from '@wephone-core/model/entity/sms_template';

export class IvrMenuCallDestinationParamsType {
  number?: string;
  extension?: string;
  url?: string;
  queue?: number;
  default_ext?: string;
  ivrscript_id?: number;
  conference?: number;
  id?: number;
  sound_id?: number;
  script_params?: any;
  ivrscript_data?: any;
  ivrscript_type?: number;
  did_id?: number;
  ivr_custom_menu?: any;
  ivr_custom_main_menu?: any;
}

export class IvrMenuCallDestinationType {
  application: string;
  params?: IvrMenuCallDestinationParamsType;
  call_attributes?: object;
}

export class IvrMenuKeymapType {
  key: string[];
  call_destination: IvrMenuCallDestinationType;
}

export class RoutingAppParamType {
  call_phone_number?: string;
  digitalsecretary?: string;
  default_ext?: string;
  callqueue?: CallQueueEntity;
  conference?: ConferenceEntity;
  runvxmlscript?: IvrScriptEntity;
  call_phone?: SipPhoneEntity;
  remote_application?: IvrRemoteAppEntity;
  call_user?: UserEntity;
  ivr_custom_menu?: IvrCustomMenuEntity;
  ivr_custom_main_menu?: IvrCustomMenuEntity;
  play_then_hangup?: FileEntryEntity;
  to_did?: DidEntity;
  send_sms?: SmsTemplateEntity;
  play_after_send_sms?: FileEntryEntity;
  mailbox_id?: number;
}
