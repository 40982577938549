import { datafield, Entity } from '@wephone-core/model/model';
import { UI_MODE } from '@wephone-core/system';

export class RoutingAppEntity extends Entity {
  static object_type_id: string = 'routing_app';

  @datafield application: string;
  @datafield enterprise_id: number;
  @datafield app_name: string;
  @datafield html_hint: string;
  @datafield comment: string;

  params: any[];
  required_features: string[];
  ui_modes: UI_MODE[];
}
