import * as _ from 'lodash';
import { datafield, Entity } from '@wephone-core/model/model';

export class GroupEntity extends Entity {
  static object_type_id = 'object_group';

  id: number;
  @datafield name: string;
  @datafield description: string;
  @datafield group_type: string;
  @datafield enterprise_id: number;
  @datafield parent_id: number;
  @datafield level: number;
  @datafield transparent: string;
  @datafield path: number[];
  @datafield manager_user_id: number;
  @datafield crm_id: number;

  children: any[];
  path_name: string; // to sort by name alphabetically

  get parent(): GroupEntity {
    if (!this.parent_id) {
      return;
    }
    return EntityManager.getInstance().getRepository('GroupRepository').getObjectById(this.parent_id);
  }

  set parent(parent: GroupEntity) {
    this.parent_id = parent ? parent.getId() : undefined;
  }

  isSubgroupOf(group_id: number): boolean {
    if (this.id === group_id) {
      return true;
    }
    if (this.path && this.path.indexOf(group_id) > -1) {
      return true;
    }
    return false;
  }

  get label(): string {
    return new Array((this.level || 0) + 1).join('– ') + this.name;
  }

  get enterprise(): EnterpriseEntity {
    return EntityManager.getInstance().getRepository('EnterpriseRepository').getObjectById(this.enterprise_id);
  }

  set enterprise(enterprise: EnterpriseEntity) {
    this.enterprise_id = enterprise.getId();
  }

  setChildren(children: any[]): any {
    this.children = children;
    return this;
  }

  fetchRelatedData(): void {
    this._updatePathName(); // must do it in here instead of setObjectData because the data must be loaded
  }

  private _updatePathName(): string {
    if (!this.path) {
      this.path_name = '';
      return;
    }
    const path = _.cloneDeep(this.path);

    // insert 0 into path
    if (path[0] !== 0) {
      path.splice(0, 0, 0);
    }
    if (path.indexOf(this.id) == -1) {
      path.push(this.id);
    }

    // use array of name instead of id in order to sort by name alphabetically
    const path_group_name = [];
    for (const group_id of path) {
      if (!group_id) {
        continue;
      }

      const objectgroup = EntityManager.getInstance().getRepository('GroupRepository').getObjectById(group_id);
      path_group_name.push(objectgroup ? objectgroup.name.toLowerCase() : group_id);
    }
    // update property path_name
    this.path_name = path_group_name.join('_') + '_';
  }

  get all_parent(): GroupEntity[] {
    let all_parent = [];
    const parent = this.parent;
    if (parent) {
      all_parent.push(parent);
      all_parent = all_parent.concat(parent.all_parent);
    }
    return all_parent;
  }

  get all_children(): GroupEntity[] {
    let all_children = [];
    if (this.children) {
      for (const c of this.children) {
        all_children.push(c);
        all_children = all_children.concat(c.all_children);
      }
    }
    return all_children;
  }

  get manager_user(): IUserEntity {
    if (this.manager_user_id) {
      return EntityManager.getInstance().getRepository('UserRepository').getObjectById(this.manager_user_id);
    }
    return undefined;
  }

  set manager_user(user: IUserEntity) {
    this.manager_user_id = user ? user.getId() : undefined;
  }
}

import { EntityManager } from '@wephone-core/service/entity_manager';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
