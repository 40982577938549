import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: '[country-flag]',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent implements OnInit, OnChanges {
  @Input() countryCode2: string;
  countryCode: string;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.countryCode = (this.countryCode2 || '').toLowerCase();
  }
}
