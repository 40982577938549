import { Injectable } from '@angular/core';
import { DidEntity } from '@wephone-core/model/entity/did';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { _ti } from '@wephone-translation';
import { Deferred } from 'ts-deferred';
import * as _ from 'lodash';
import { DialogService } from '@wephone-utils';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';

@Injectable()
export class IvrService {
  constructor(
    private readonly dialogService: DialogService,
  ) { }

  getDidListConfigToIvr(ivrs: IvrCustomMenuEntity[]): DidEntity[] {
    const didList: DidEntity[] = [];
    for (const ivr of ivrs) {
      for (const did of ivr.routedDids) {
        if (!_.includes(didList, did)) {
          didList.push(did);
        }
      }
    }
    return didList;
  }

  async getConfirmDeleteIvrMenus(items: IvrCustomMenuEntity[]): Promise<boolean> {
    let msg: string = _ti('user.title.delete');
    const msgs: string[] = [];

    const routedDids: DidEntity[] = this.getDidListConfigToIvr(items);
    const routedCrmRoutings: CrmRoutingRuleEntity[] = [];

    for (const item of items) {
      for (const routedCrmRouting of item.routedCrmRoutings) {
        if (!_.includes(routedCrmRoutings, routedCrmRouting)) {
          routedCrmRoutings.push(routedCrmRouting);
        }
      }
    }

    if (routedDids.length) {
      let ivrMenuObjects: IvrCustomMenuEntity[] = items.filter(o => o.routedDids.length);
      ivrMenuObjects = _.uniqBy(ivrMenuObjects, o => o.id);

      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', {
        dependent_numbers: routedDids.map(x => x.display_number).join(', '),
        objects: ivrMenuObjects.map(x => x.name).join(', '),
      }));
    }

    if (routedCrmRoutings.length) {
      let ivrMenuObjects: IvrCustomMenuEntity[] = items.filter(o => o.routedCrmRoutings.length);
      ivrMenuObjects = _.uniqBy(ivrMenuObjects, o => o.id);

      msgs.push(_ti('crm_routing.message.confirm_delete_with_dependent_items', {
        crm_routing_names: routedCrmRoutings.map(x => x.name).join(', '),
        objects: ivrMenuObjects.map(x => x.name).join(', '),
      }));
    }

    const ret = new Deferred<boolean>();
    const canDelete = !msgs.length;

    if (!canDelete) {
      msgs.push(_ti('public.message.cannot_continue_action_with_dependent_items'));
      msg = msgs.join('\n');

      await this.dialogService.showAlert(_ti('dialogs.warning'), msg);
      ret.resolve(canDelete);
      return ret.promise;
    }

    await this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      msg,
      () => {
        ret.resolve(canDelete);
      }
    );
    return ret.promise;
  }
}
