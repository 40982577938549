<mat-form-field class="full-width">
  <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
  <input matInput [formControl]="flexTimePickerControl" [ngxTimepicker]="toggleTimepicker" 
    [disableClick]="disabled" [disableControl]="disabled" 
    [min]="min" [max]="max" [format]="format"
    readonly [placeholder]="placeholder">
  <div matSuffix fxLayout="row" fxLayoutAlign="start center">
    <ngx-material-timepicker-toggle [for]="toggleTimepicker" [disabled]="disabled"></ngx-material-timepicker-toggle>
    <button mat-icon-button *ngIf="nullable" mat-icon-button (click)="clearTimePicker($event)" [disabled]="!flexTimePickerControl.value"
      class="clear-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ngx-material-timepicker #toggleTimepicker 
    (timeSet)="onChangeTime()"
    [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
  </ngx-material-timepicker>
  <ng-template #cancelBtn>
    <button type="button" mat-button class="timepicker-button">{{ 'public.cancel'|translate }}</button>
  </ng-template>
  <ng-template  #confirmBtn>
    <button type="button" mat-flat-button color="primary" class="timepicker-button">{{ 'public.ok'|translate }}</button>
  </ng-template>
</mat-form-field>

