<form [formGroup]="form" *ngIf="form">
  <h4>
    <!-- {{ 'user.heading.telephone'|translate }} -->
    <button *ngIf="!user.sipphone" type="button" mat-stroked-button color="accent" (click)="enableVoip()">
      {{ 'dialogs.setup_sipphone.button.enable_voip'|translate }}
    </button>
  </h4>

  <div *ngIf="user.sipphone" fxLayout="column">
    <!--div fxLayout="column" fxFlex="100">
    <mat-form-field fxFlex="100">
      <input matInput type="text" 
        [formControl]="form.controls['sipphone_extension']"
        [placeholder]="'dialogs.setup_sipphone.label.number'|translate"
      />
    </mat-form-field>
    <small *ngIf="form.controls['sipphone_extension'].invalid && form.controls['sipphone_extension'].dirty" class="mat-text-warn">Sip phone extension is incorrect.</small>
  </div-->

    <!--extension-->
    <div class="full-width">
      <span>{{ 'dialogs.setup_sipphone.label.number'|translate }} : </span>
      <span class="ml-1 fontfamily-bold">{{ user.sipphone.extension }}</span>
    </div>

    <!--callout-enabled-->
    <div fxLayout="column" class="mt-1">
      <mat-slide-toggle fxFlex="100" [formControl]="form.controls['callout_enabled']"
        [checked]="form.controls['callout_enabled'].value == 1">
        <span class="fontfamily-bold">{{'user_page.outbound_enabled'|translate}}</span>
      </mat-slide-toggle>
      <small [flexFormControlError]="form.controls['callout_enabled']" class="mat-text-warn"></small>
    </div>

    <div *ngIf="form.controls['callout_enabled'].value == 1" fxLayout="column">
      <!--outbound-display-number-->
      <div fxLayout="column" class="mt-1">

        <mat-form-field class="full-width" *ngIf="!user.isWatcher()">
          <mat-label>{{ 'user_page.secretary_number' | translate }}</mat-label>
          <did-select-form-input [formControl]="form.controls['secretary_did_id']" [attr.data-ci]="'secretary_did'"
            [onlyConfigured]="true" [placeholder]="'user_page.secretary_number' | translate">
          </did-select-form-input>
          <button mat-icon-button matSuffix type="button" (click)="form.controls['secretary_did_id'].setValue(null)"
            [disabled]="!form.controls['secretary_did_id'].value">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error [flexFormControlError]="form.controls['secretary_did_id']"></mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'user_page.outbound_display_number' | translate }}</mat-label>
          <did-select-form-input [formControl]="form.controls['calling_number_id']" [attr.data-ci]="'calling_number'"
            [includeIds]="includedCallingNumberIds" [placeholder]="'user_page.outbound_display_number'|translate">
          </did-select-form-input>
          <!-- <calling-number-form-input [formControl]="form.controls['calling_number_id']"
            [placeholder]="'user_page.outbound_display_number'|translate"></calling-number-form-input> -->
          <button mat-icon-button matSuffix type="button" (click)="form.controls['calling_number_id'].setValue(null)"
            [disabled]="!form.controls['calling_number_id'].value || !form.controls['calling_number_id'].value.id">
            <mat-icon>close</mat-icon>
          </button>

          <mat-error [flexFormControlError]="form.controls['calling_number_id']"></mat-error>
        </mat-form-field>
      </div>

      <!--allow-destination-->
      <div fxLayout="column">
        <mat-form-field class="full-width">
          <mat-label>{{ 'user_page.allow_destination' | translate }}</mat-label>
          <calling-profile-select-form-input [formControl]="form.controls['calling_profile_id']"
            [placeholder]="'user_page.allow_destination'|translate"></calling-profile-select-form-input>

          <mat-error [flexFormControlError]="form.controls['calling_profile_id']"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <!--connection-info-->
    <div fxLayout="column"
      *ngIf="form.controls['callout_enabled'].value == 1 && currentUser.isAdmin() && user.sipphone.id">
      <app-sip-connection-info
        [data]="{username: user.sipphone?.extension, password: user.sipphone?.password}"></app-sip-connection-info>
      <!-- <table class="flex-table-info">
        <thead>
          <tr>
            <th colspan="2"> <span
                class="fontfamily-bold">{{ 'dialogs.setup_sipphone.label.connection_info'|translate }}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="label">{{ 'dialogs.setup_sipphone.label.domain'|translate }}</td>
            <td>{{ sip_domain }}</td>
          </tr>
          <tr>
            <td class="label">{{ 'login.user'|translate }}</td>
            <td><span *ngIf="user.sipphone" data-ci="sipphone-username">{{ user.sipphone.extension }}</span></td>
          </tr>
          <tr>
            <td class="label">{{ 'login.pass'|translate }}</td>
            <td class="label" valign="middle">
              <span class="vertical-align" [ngClass]="{'fontfamily-opensans': !show_sip_password}"
                data-ci="sipphone-password">
                {{ show_sip_password ? user.sipphone.password : '&bull;&bull;&bull;&bull;&bull;&bull;&bull;' }}</span>
              <button type="button" *ngIf="show_sip_password" mat-icon-button color="primary" class="float-right"
                title="{{ 'call_qualification.content.hide'|translate }}"
                (click)="show_sip_password=!show_sip_password">
                <mat-icon>visibility_off</mat-icon>
              </button>
              <button type="button" *ngIf="!show_sip_password" [attr.data-ci]="'show-sipphone-pw'" mat-icon-button
                color="primary" class="float-right" title="{{ 'call_qualification.content.show'|translate }}"
                (click)="show_sip_password=!show_sip_password">
                <mat-icon>visibility</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table> -->
      <div class="hr"></div>
    </div>
  </div>
</form>
