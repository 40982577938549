import * as _ from 'lodash';

import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Optional, Self, SimpleChanges } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexMatInputWrapper, TreeHelper } from '@wephone-utils';
import { NgControl, FormControl } from '@angular/forms';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';

@Component({
  selector: 'flex-select-qualification-input',
  templateUrl: './flex-select-qualification-input.html',
  styleUrls: ['./flex-select-qualification-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectQualificationInput }]
})
export class FlexSelectQualificationInput extends FlexMatInputWrapper implements OnInit, OnChanges, MatFormFieldControl<any> {
  @Input() groupIds: number[];
  @Input() multiple: boolean;
  @Input() showCheckAll: boolean;

  @ViewChild('flexSelectTreeInput', { static: false }) flexSelectTreeInput: FlexSelectTreeInput;

  // Private member var with default value
  private qualRepo;
  private _quals: QualificationEntity[];
  private initialized = false;

  // Public member var
  myControl = new FormControl();
  qualNodes: FlexSelectTreeNode[] = new Array<FlexSelectTreeNode>();

  constructor(
    elRef: ElementRef,
    @Optional()
    @Self()
    ngControl: NgControl,
    private em: EntityManager
  ) {
    super(ngControl, elRef);
    this.qualRepo = this.em.getRepository('QualificationRepository') as QualificationRepository;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loadQual();
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }

    if ('groupIds' in changes) {
      this.loadQual();
      setTimeout(() => {
        this.wrappedControl.setValue([]);
      });
    }
  }

  protected loadQual(): void {
    this._quals = this.qualRepo.getObjectList() as QualificationEntity[];

    if (this.groupIds && _.isArray(this.groupIds) && !_.isEmpty(this.groupIds)) {
      this._quals = this.getQualsByGroupIds(this.groupIds);
      console.log('this._quals', this._quals);
    }

    const nodeList = this._quals.map(qual => {
      return {
        id: qual.id,
        name: qual.value,
        parent_id: qual.parent_id
      };
    });
    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.qualNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }

  getDisplayedQualifications(): QualificationEntity[] {
    return this._quals;
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-qualification-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }

  protected getQualsByGroupIds(groupIds: number[]): QualificationEntity[] {
    // const all: QualificationEntity[] = this.qualRepo.getObjectList();
    // const map = {};
    // for (const q of all) {
    //   map[q.id] = q;
    // }

    const quals: QualificationEntity[] = this.qualRepo.getQualificationsByGroupIds(groupIds);
    // const mapQuals = {};
    // for (const q of quals) {
    //   mapQuals[q.id] = quals;
    // }

    // // Also add parent nodes
    // const ret = _.cloneDeep(quals);
    // for (const q of quals) {
    //   if (q.parent_id && !mapQuals[q.parent_id] && map[q.parent_id]) {
    //     const item = map[q.parent_id];
    //     if (!_.includes(ret.map(x => x.id), item.id)) {
    //       ret.push(item);
    //     }
    //   }
    // }

    // return ret;
    return quals;
  }
}
