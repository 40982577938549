import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { DialogActionButton, Colors, IFlexDialogConfig, NoWhitespaceValidator } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { RoutingServiceEntity } from '@wephone-core/model/entity/routing_service';
import { RoutingServiceRepository } from '@wephone-core/model/repository/routing_service';

@Component({
  selector: 'app-create-routing-service-modal',
  templateUrl: './create-routing-service-modal.component.html',
  styleUrls: ['./create-routing-service-modal.component.scss'],
})
export class CreateRoutingServiceModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '500px',
    minHeight: '90%',
  };
  private readonly routingServiceRepo = RoutingServiceRepository.getInstance<RoutingServiceRepository>();

  routingService: RoutingServiceEntity;
  // form: FormGroup;

  dialogTitle = _tk('routing_service.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  constructor(
    dialogRef: MatDialogRef<CreateRoutingServiceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly fb: FormBuilder
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.routingService = this.routingServiceRepo.create() as RoutingServiceEntity;
    this.initFormGroup();
  }

  cancel(result?): void {
    this.dismiss(result);
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.routingService.name, [Validators.required, NoWhitespaceValidator]],
    });
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.toastService.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      const formData = this.form.value;
      const createData = {
        name: _.trim(formData.name),
        url: formData.url,
      };
      const createdRoutingService = await this.routingServiceRepo.createAndSave(createData);
      this.showInfo(_ti('public.message.create_success'));
      this.dismiss(createdRoutingService, false);
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }
}
