<div fxFlexFill>
<mat-radio-group fxLayout="column" [(ngModel)]="checked_all">
  <mat-radio-button [value]="true" (change)="checkUncheckAll(true)">
    {{'livecall.agent_list.show_all_items'|translate}} </mat-radio-button>
  <mat-radio-button [value]="false" (change)="checkUncheckAll(false)">
    {{'livecall.agent_list.show_selected_items'|translate}} </mat-radio-button>
</mat-radio-group>
<mat-divider class="mt-1 mb-1"></mat-divider>

<flex-tree-input
  #flexTreeInput
  [enabled]="!checked_all" 
  [showCheckAll]="true" 
  [nodes]="groupNodes" 
  [multiple]="true" 
  [showFilter]="true" 
  [formControl]="selectedGroupIds">
</flex-tree-input>
</div>
