import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-truncate',
  templateUrl: './text-truncate.component.html',
  styleUrls: ['./text-truncate.component.scss']
})
export class TextTruncateComponent {
  @Input() text: string;
}
