import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { DidCountryEntity } from '@wephone-core/model/entity/did_country';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';

export class DidCountryRepository extends BaseRepository {
  ENTITY_CLASS = DidCountryEntity;
  protected URL_PREFIX = 'did_country';
  protected METHOD_PATH_SYNCHRONIZE = 'synchronize';

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  protected getURLAction(action: string): string {
    switch (action) {
      case 'SYNCHRONIZE':
        return this.getUrl(this.METHOD_PATH_SYNCHRONIZE);
      default:
        break;
    }

    return super.getURLAction(action);
  }

  getObjectByCode2(code2: string): DidCountryEntity {
    return this.getObjectList().find((item: DidCountryEntity): boolean => {
      return code2 && item.code2 && item.code2.toUpperCase() === code2.toUpperCase();
    });
  }

  synchronizeCountries(): Promise<any> {
    const url = this.getURLAction('SYNCHRONIZE');
    return HttpEngine.getInstance().postJson(url, null);
  }
}
