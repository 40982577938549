import { Component, Inject, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OutcallService } from '@wephone-core/service/outcall.service';
// import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { ToastService, DialogActionButton, Colors, IFlexDialogConfig } from '@wephone-utils';
// import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
// import { OutcallCampaignItemEntity } from '@wephone-core/model/entity/outcallcampaignitem';
// import { OutcallCampaignItemRepository } from '@wephone-core/model/repository/outcallcampaignitem';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FileSettings, getFileValidation } from '@wephone-utils/utils/file-util';

@Component({
  selector: 'app-outcallcampaign-import-contacts',
  templateUrl: './outcallcampaign-import-contacts.component.html',
  styleUrls: ['./outcallcampaign-import-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutcallcampaignImportContactsComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    minHeight: '90%',
  };

  dialogTitle = _tk('campaign.contact.import_contact');
  backButton: DialogActionButton =
    {
      action: () => {
        this.stepBack();
      },
      visible: () => {
        return this.fileImported && !!this.getStepBack();
      }
    };

  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.navigation.next'),
      action: () => {
        this.stepNext();
      },
      visible: () => {
        return !!this.getStepNext();
      },
      color: Colors.PRIMARY
    }
  ];

  private outcallcampaign_id: number;
  private steps: string[] = [
    'upload', 'import'
  ];

  fileSettings: FileSettings;
  error_fields: any = {};
  step: string;
  importFile: any;
  dataContact: any;
  fields: any = {};
  customFields: any[] = []; // To store all custom fields
  fileImported = false;

  standard_fields: any[] = [
    {
      label: _tk('enterprise_contact.content.first_name'),
      field: 'first_name'
    },
    {
      label: _tk('enterprise_contact.content.last_name'),
      field: 'last_name'
    },
    {
      label: _tk('enterprise_contact.content.tel'),
      field: 'phone_number'
    },
    {
      label: _tk('enterprise_contact.content.company'),
      field: 'company'
    },
    {
      label: 'enterprise_contact.content.custom1',
      field: 'custom1'
    },
    {
      label: 'enterprise_contact.content.custom2',
      field: 'custom2'
    },
    {
      label: 'enterprise_contact.content.custom3',
      field: 'custom3'
    },
    {
      label: 'enterprise_contact.content.agent_alias',
      field: 'agent_alias'
    }
  ];

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    dialogRef: MatDialogRef<OutcallcampaignImportContactsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private outcallService: OutcallService,
    private fileEntryService: FileEntryService,
    private settings: FlexIvrSettings,
    private cdRef: ChangeDetectorRef,
    private toast: ToastService
  ) {
    super(dialogRef);
    this.outcallcampaign_id = data.outcallcampaign_id;

    this.fileSettings = {
      extensions: ['.csv'],
      max_size: this.settings.max_upload_size * 1024 * 1024
    };

    this.step = this.steps[0];
  }

  get fieldContacts(): any[] {
    return this.dataContact.columns.filter(x => {
      return !this.hasField(x.index);
    });
  }

  downloadSampleFile(): void {
    const url = this.settings.getEnterpriseUrl('/callcenter/download_sample_csv_v3');
    this.fileEntryService.download(url, 'sample-contacts.csv');
  }

  private hasField(idx): boolean {
    for (const field of Object.keys(this.fields)) {
      if (this.fields[field] === idx) {
        return true;
      }
    }

    return false;
  }

  /**
   * @return: Promise {result:any} | {error:{message:string},status:string} 
   */
  async importCsv($event: any): Promise<any> {
    this.fileImported = false;
    this.importFile = $event.target && $event.target.files && $event.target.files.length ? $event.target.files[0] : undefined;
    if (!this.importFile) {
      console.error('No selected file');

      return Promise.resolve(undefined);
    }

    try {
      const fileValidation = getFileValidation(this.importFile, this.fileSettings);
      if (!fileValidation.valid) {
        this.importFile = undefined;
        throw new Error(fileValidation.message);
      }

      const importResult = await this.outcallService.importCsv(this.importFile);
      console.log('importResult', importResult);
      if (!importResult) {
        console.error('No result', importResult);

        return;
      }

      if (importResult.status === 'error') {
        this.toast.showError(importResult.messages);

        return;
      }

      this.fileImported = true;

      const item_resp: any = importResult.item;
      if (!item_resp) {
        console.error('No result');

        return;
      }

      this.dataContact = {
        temp_file_id: item_resp.temp_file_id,
        columns: [],
        data: item_resp.data,
        line: item_resp.line
      };

      for (const index of Object.keys(item_resp.columns)) {
        this.dataContact.columns.push({
          index,
          label: item_resp.columns[index]
        });
      }

      this.cdRef.detectChanges();
    } catch (resp) {
      console.error('Error', resp);
      const msg = resp && resp.error && resp.error.message || resp && resp.message || _ti('public.message.error_occurred');
      this.toast.showError(msg);
      this.importFile = undefined;
      this.fileInput.nativeElement.value = '';
      this.cdRef.detectChanges();
    }
  }

  async saveContact(): Promise<any> {
    try {
      const rs = await this.outcallService.saveContactFormat(this.getSubmitedContactFormat(), this.outcallcampaign_id);
      console.log('saveContactFormat', rs);
      const data = rs.outcall_item;
      this.cdRef.detectChanges();
      if (data && data.success) {
        this.toast.showSuccess(_ti('campaign.contact.upload.import_result',
          { success: data.success, error: data.error, duplicated: data.duplicated }));
        this.dismiss(data);
      } else {
        this.toast.showError(_ti('campaign.contact.upload.import_result',
          { success: data.success, error: data.error, duplicated: data.duplicated }));
      }
    } catch (e) {
      if (e && e.error && e.error.message) {
        this.toast.showError(e.error.message);
      }
    }
  }

  /**
   * @Return {
   *	'temp_file_id':'A23B3D87FDBN',
   *	'fields':{'5':'1', '2':'2', '3':'3'}
   * }
   */
  private getSubmitedContactFormat(): any {
    return {
      temp_file_id: this.dataContact.temp_file_id,
      fields: this.fields
    };
  }

  getStepBack(): string {
    const index = this.steps.indexOf(this.step);
    const back_index = index - 1;
    if (this.steps[back_index]) {
      return this.steps[back_index];
    }

    return undefined;
  }

  private validateStep(): any[] {
    if (this.step === 'upload' && !this.fileImported) {
      return [false, _ti('campaign.contact.upload.validator.no_imported_file')];
    }
    if (this.step === 'import') {
      this.error_fields = {};
      if (!this.fields[this.standard_fields[2].field]) {
        this.error_fields['phone_number'] = _ti('form.validator.field_required');

        return [false, _ti('campaign.contact.upload.validator.phone_number_required')];
      }
    }

    return [true];
  }

  getStepNext(): string {
    const index = this.steps.indexOf(this.step);
    const next_index = index + 1;
    if (typeof (this.steps[next_index]) !== 'undefined') {
      return this.steps[next_index];
    }

    if (next_index === this.steps.length) {
      return 'finish';
    }
  }

  stepBack(): void {
    const step_back = this.getStepBack();
    if (step_back) {
      this.step = step_back;
    }

    this.cdRef.detectChanges();
  }

  stepNext(): void {
    const validation = this.validateStep();
    if (!validation[0]) {
      this.toast.showError(validation[1]);

      return;
    }

    const step_next = this.getStepNext();
    if (step_next === 'finish') {
      this.saveContact();
    } else if (step_next) {
      this.step = step_next;
    }

    this.cdRef.detectChanges();
  }

  public cancel(result?): void {
    this.dismiss(result);
  }
}
