import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class OpeningHourSpecialDateRepository extends BaseRepository {
  ENTITY_CLASS = OpeningHourSpecialDateEntity;
  protected RELATED_REPOSITORIES = ['opening_hour_calendar', 'did'];
  protected USE_PUSH_API = false;

  public getObjectListByCalendarId(calendar_id) {
    let ret = [];
    for (let s of this.getObjectList()) {
      if (s.calendar_id == calendar_id) {
        ret.push(s);
      }
    }
    return ret;
  }
}

import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';
