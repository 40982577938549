<form [formGroup]="form" fxFill *ngIf="form">
  <div fxFlex="grow">
    <mat-tab-group [selectedIndex]="tabSelectedIndex" (selectedIndexChange)="updateTabSelectedIndex($event)"
      [dynamicHeight]="true">
      <mat-tab label="{{ 'enterprise_sip_gateway.form.tab_general' | translate }}">
        <div class="px-1" *ngIf="tabSelectedIndex == 0">
          <div>
            <mat-form-field class="full-width">
              <mat-label>{{'enterprise_sip_gateway.form.name'|translate}}</mat-label>
              <input matInput formControlName="name" data-ci="name"
                [placeholder]="'enterprise_sip_gateway.form.name'|translate">

              <mat-error [flexFormControlError]="form.get('name')"></mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div fxFlex="50">
              <mat-form-field class="full-width">
                <mat-label>{{'enterprise_sip_gateway.form.model'|translate}}</mat-label>
                <mat-select formControlName="model" data-ci="model" interface="popover">
                  <mat-option *ngFor="let model of modelList" [value]="model.value">
                    {{ model.label }}
                  </mat-option>
                </mat-select>

                <mat-error [flexFormControlError]="form.get('model')"></mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50">
              <mat-form-field class="full-width">
                <mat-label>{{'enterprise_sip_gateway.form.firmware'|translate}}</mat-label>
                <mat-select formControlName="firmware" data-ci="firmware" interface="popover">
                  <mat-option *ngFor="let firmware of firmwareObject[form.get('model').value] || []"
                    [value]="firmware.value">
                    {{ firmware.label }}
                  </mat-option>
                </mat-select>

                <mat-error [flexFormControlError]="form.get('firmware')"></mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
            <mat-form-field class="full-width">
              <mat-label>{{'enterprise_sip_gateway.form.mac'|translate}}</mat-label>
              <input matInput formControlName="mac" data-ci="mac"
                [placeholder]="'enterprise_sip_gateway.form.mac'|translate">

              <mat-error [flexFormControlError]="form.get('mac')" [customMessages]="{
                  pattern: 'enterprise_sip_gateway.validator.mac_address_pattern' | translate
                }"></mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="full-width">
              <mat-label>{{'enterprise_sip_gateway.form.port_count'|translate}}</mat-label>
              <input type="number" matInput formControlName="port_count" step="1" min="1" max="99" data-ci="port_count"
                [placeholder]="'enterprise_sip_gateway.form.port_count'|translate">

              <mat-error [flexFormControlError]="form.get('port_count')"></mat-error>
            </mat-form-field>
          </div>
          <button mat-stroked-button (click)="showProvisionLink()" data-ci="show_provision_link">
            {{'enterprise_sip_gateway.content.show_provision_link'| translate}}
          </button>
        </div>
      </mat-tab>

      <mat-tab label="{{ 'enterprise_sip_gateway.form.tab_port_config' | translate }}">
        <div cdkDropListGroup class="port-config-wrapper" fxLayout="row" fxLayoutGap="10px"
          *ngIf="tabSelectedIndex == 1">
          <div fxFlex="200px" fxLayout="column">
            <h3 class="px-1 block-title">{{ 'enterprise_sip_gateway.form.sipphone_list'|translate }}</h3>
            <flex-search-input [formControl]="filterSipPhone"></flex-search-input>
            <cdk-virtual-scroll-viewport itemSize="50" class="viewport" [hidden]="hiddenSipPhoneList()">
              <mat-card cdkDropList [cdkDropListData]="sipPhoneList" cdkDropListSortingDisabled>
                <mat-card-content>

                  <ng-container *ngFor="let sipPhone of sipPhoneList">
                    <div class="sipphone-box drag-item" [cdkDragData]="sipPhone" cdkDrag
                      *ngIf="sipPhoneVisible(sipPhone)">
                      <div class="flex-drag-placeholder" *cdkDragPlaceholder></div>
                      {{ sipPhone.extension_with_owner }}
                    </div>
                  </ng-container>

                </mat-card-content>
              </mat-card>
            </cdk-virtual-scroll-viewport>
          </div>
          <div fxFlex="calc(100%-210px)" fxLayout="column">
            <h3 class="block-title">{{ 'enterprise_sip_gateway.form.post_list'|translate }}</h3>
            <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="0" fxLayoutGap.gt-sm="10px">
                <div fxFlex="calc(50%-10px)" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                  fxLayoutAlign="start start" class="port-col" [ngClass.xs]="{'xs': true}" [ngClass.sm]="{'sm': true}"
                  *ngFor="let portConfigs of portConfigGroupList">

                  <ng-container *ngFor="let portConfig of portConfigs">
                    <mat-card fxFlex="50" fxFlex.xs="100" class="port-wrapper" id="port-{{ portConfig.port }}"
                      cdkDropList [cdkDropListData]="[portConfig]" (cdkDropListDropped)="dropSipPhoneToPort($event)">
                      <mat-card-content class="drag-item" cdkDrag [cdkDragDisabled]="true" [cdkDragData]="portConfig">
                        <div class="flex-drag-placeholder" *cdkDragPlaceholder></div>
                        <button type="button" mat-icon-button class="clear-port" [disabled]="!portConfig.data"
                          [matTooltip]="'public.remove'|translate" (click)="clearPortConfig(portConfig)"
                          *ngIf="portConfig.data">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <div class="port-number"
                          [matTooltip]="'enterprise_sip_gateway.form.port_number_hint'|translate">
                          {{ portConfig.port }}</div>
                        <div class="port-box" *ngIf="portConfig.data">
                          {{portConfig.data.extension_with_owner}}
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>

                </div>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</form>
