<ng-template #listFilterTpl>
  <form [formGroup]="crmRoutingForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
    fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
    <div>
      <flex-search-input class="full-width" formControlName="filterValue"
        placeholder="{{ 'public.search' | translate }}" [hintText]="'crm_routing.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>

<flex-crud-page #flexCrud pageTitle="{{ 'crm.title'|translate }}" [filterTemplate]="listFilterTpl"
  [tableConfig]="tableConfig" [dataSource]="dataSource" fxLayout="row" fxFlex="grow"
  [columnTemplates]="{'crm_id': tplCrmId, 'dest_default': tplDestDefault}" [sidePannelOptions]="sidePannelOptions">
  <ng-template #tplCrmId let-item="row">
    {{ crms[item.crm_id] ? crms[item.crm_id].name : '' }}
  </ng-template>
  <ng-template #tplDestDefault let-item="row">
    <number-routing-dest *ngIf="is_ready" [did]="getVirtualDid(item)" [updateLayout]="isUpdatedLayout"></number-routing-dest>
  </ng-template>
</flex-crud-page>
