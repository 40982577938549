import { Component, Input, OnInit } from '@angular/core';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';

@Component({
  selector: 'app-queue-mini-block',
  templateUrl: './queue-mini-block.component.html',
  styleUrls: ['./queue-mini-block.component.scss']
})
export class QueueMiniBlockComponent implements OnInit {
  @Input() callQueue: CallQueueEntity;

  constructor() { }

  ngOnInit(): void {
  }

}
