import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService } from '@wephone-utils';
import { DebugMessageCallModalComponent } from '@wephone/modals/debug-message-call-modal/debug-message-call-modal.component';

@Component({
  selector: 'debug-messages-call-renderer',
  templateUrl: './debug-messages-call-renderer.html',
})
export class DebugMessagesCallRenderer implements ICellRendererAngularComp {
  params: any;

  constructor(
    public dialogService: DialogService,
  ) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  viewMessage(): void {
    const dialogRef = this.dialogService.openDialog2(DebugMessageCallModalComponent, {
      data: {
        debugMessage: this.params.data
      }
    });
  }
}
