import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable, EventEmitter, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { joinURL } from '@wephone-utils/utils/url-util';
import { SingletonBase } from '@wephone-utils/utils/singleton';
import { PhoneNumberService } from '@wephone-utils/services/phonenumber.service';
import { IEntity } from '@wephone-core/model/model.interface';
import {
  BusinessType, UserRole, UserType, EnterpriseParamDict, EnterpriseParam, SystemParam,
  UserParamDict, UserParam, FeatureName,
} from '@wephone-core/system';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { ENTERPRISE_CRM } from '@wephone-core/constants/crm-config';
import { CALL_ROUTING_APPS } from '@wephone-core/constants/telephony-config';
import { PHONE_MODELS } from '@wephone-core/constants/phone-models';
import * as _ from 'lodash';
import { MyUserProfile } from './config_manager.i';

@Injectable()
export class ConfigManager extends SingletonBase {

  static RoleListForSuperAdmin = [
    UserRole.AGENT,
    UserRole.WATCHER,
    UserRole.USER,
    UserRole.SUPERVISOR,
    UserRole.ADMIN,
    UserRole.SUPER_ADMIN,
    UserRole.SUPER_ACCOUNTANT,
    UserRole.ACCOUNTANT,
    UserRole.API_OAT,
    UserRole.API_SMS_ENTERPRISE_ADMIN,
  ];

  static RoleListForClient = [
    UserRole.AGENT,
    UserRole.WATCHER,
    UserRole.ACCOUNTANT,
    UserRole.USER,
    UserRole.SUPERVISOR,
    UserRole.ADMIN,
  ];

  static UserTypesForCallCenter = [
    UserType.AGENT,
    UserType.WATCHER,
    UserType.USER,
    UserType.SUPERVISOR,
    UserType.ACCOUNTANT,
    UserType.ADMIN,
  ];

  static UserTypesForEnterprise = [
    UserType.USER,
    UserType.ACCOUNTANT,
    UserType.ADMIN,
  ];

  static UserTypesForHotel = [
    UserType.USER,
    UserType.ACCOUNTANT,
    UserType.ADMIN,
  ];

  static UserTypesForReseller = [
    // UserType.USER,
    UserType.ADMIN,
  ];

  static UserTypesForSipTrunk = [
    UserType.ADMIN,
  ];

  // private remote_url: string;
  private config_dict = {};
  private loading_promises = {};
  private enterprise_id = 0;
  private _data_ready = false;
  onConfigReceived: EventEmitter<any>;
  private authService: AuthenticationService;
  private phoneNumberService: PhoneNumberService;

  private admin_config_list = [
    'system_params',
    'myprofile',
    'roles',
    'user_type',
    'is_only_admin',
    'is_only_superadmin',
    'features',
    'sip_account',
    'contact_format',
    'enterprise',
    'subscription_pack'
  ];

  private admin_config_list_no_pushapi = [
    'system_params',
    'myprofile',
    'sip_account',
    'features',
    'roles',
    'user_type',
    'is_only_admin',
    'is_only_superadmin',
    'routing_app',
    'ivrapp',
    'ivrscript',
    'custom_stats_filter',
    'tag',
    'opening_hour_calendar',
    'opening_hour',
    'opening_hour_special_date',
    'voicemail',
    'did_order',
    //                                    'voicemail',
    'sipphone',
    'conference',
    'fileentry',
    'ivr_custom_menu',
    'user',
    'contact_format',
    'enterprise',
    'enterprise_crm',
    'agent',
    'object_group',
    // 'calling_profile',
    'phonebook',
    'crm_routing_rule',
    'subscription_pack',
    'did_country',
    'custom_report_config',
  ];

  private agent_config_list = [
    'system_params',
    'myprofile',
    'features',
    'contact_format',
    'sip_account',
    'voicemail'
  ];

  private superadmin_config_list = [
    // 'enterprise',
    'system_params',
    // 'subscription',
    'subscription_pack',
    // 'calling_profile',
    'did_country'
  ];

  private readonly reseller_config_list = [
    'enterprise',
    'system_params',
  ];

  private user_config_list = [
    'system_params',
    'myprofile',
    'user',
    'user_type',
    'features',
    'object_group',
    'sip_account'
  ];

  constructor(
    injector: Injector,
    private readonly APP_SETTINGS: FlexIvrSettings,
    public $http: HttpClient,
  ) {
    super();
    StaticServiceLocator.setServiceByName('ConfigManager', this);
    this.authService = injector.get(AuthenticationService);
    this.phoneNumberService = injector.get(PhoneNumberService);

    console.log('ConfigManager run constructor!');
    // this.remote_url = APP_SETTINGS.getApiUrl('system/config/get_config');
  }
  /*
    setRemoteUrl(url: string): void {
      this.remote_url = url;
    }
  */
  private get remote_url(): string {
    return this.APP_SETTINGS.getAbsoluteApiUrlv2('system/config/get_config');
  }

  private get em(): any {
    return StaticServiceLocator.getServiceByName('EntityManager');
  }

  hasPushAPI = (): boolean => {
    return this.APP_SETTINGS.push_api;
  }

  isDataReady = (): boolean => {
    return this._data_ready;
  }

  hasFeature = (feature_name: string): boolean => {
    if (feature_name === FeatureName.SERVICE_GROUP) {
      return !!this.getEnterpriseParamValue(EnterpriseParam.group_management);
    }

    if (feature_name === FeatureName.SMS) {
      return !!this.getEnterpriseParamValue(EnterpriseParam.sms_enable);
    }

    if (feature_name === FeatureName.SIP_TRUNK) {
      return !!this.getEnterpriseParamValue(EnterpriseParam.sip_trunk_enable);
    }

    return false;
  }

  getSystemParam = (param_name: SystemParam) => {
    if (this.config_dict && this.config_dict['system_params']) {
      return this.config_dict['system_params'][param_name];
    }
  }

  getEnterpriseParams(): EnterpriseParamDict {
    return this.config_dict && this.config_dict['system_params'] && this.config_dict['system_params']['enterprise_params'];
  }

  setEnterpriseParam(name: string, value: any): void {
    const enterpriseParams = this.config_dict && this.config_dict['system_params'] && this.config_dict['system_params']['enterprise_params'] || {};
    enterpriseParams[name] = value;
  }

  getEnterpriseParamValue = (param_name: EnterpriseParam): any => {
    const enterpriseParams = this.getEnterpriseParams();
    if (enterpriseParams) {
      return enterpriseParams[param_name];
    }
  }

  getUserParams(): UserParamDict {
    return this.config_dict && this.config_dict['system_params'] && this.config_dict['system_params']['user_params'];
  }

  getUserParamValue(param_name: UserParam, defaultValue?: any): any {
    const userParams = this.getUserParams();
    if (!userParams) {
      return defaultValue;
    }
    return userParams[param_name] || defaultValue;
  }

  setUserParams(config: any): void {
    const userParams = this.config_dict && this.config_dict['system_params'] && this.config_dict['system_params']['user_params'] || {};
    for (const [key, value] of Object.entries(config)) {
      userParams[key] = value;
    }
  }

  isRole(role_name): boolean {
    return this.authService.getUserRole() === role_name;
  }

  getCurrentEnterpriseId(): number {
    return this.enterprise_id;
  }

  getBaseURL(): string {
    return this.APP_SETTINGS.api_url_prefix;
  }

  getURL(suffix: string): string {
    return joinURL(this.getBaseURL(), suffix);
  }

  invalidateObjectCache(repository_name): void {
    if (repository_name in this.config_dict) {
      // tslint:disable-next-line:no-dynamic-delete
      delete this.config_dict[repository_name];
    }
  }

  invalidateCache(): void {
    this.config_dict = {};
  }

  reloadConfig(): Promise<any> {
    this._data_ready = false;
    let listConfig;

    const roleName = this.authService.getUserRole();
    switch (roleName) {
      case 'ROLE_SUPER_ACCOUNTANT':
      case 'ROLE_SUPER_ADMIN':
        listConfig = this.superadmin_config_list;
        break;
      case 'ROLE_AGENT':
        listConfig = this.agent_config_list;
        break;
      case 'ROLE_ENTERPRISE_USER':
      case 'ROLE_ACCOUNTANT':
        listConfig = this.user_config_list;
        break;
      case 'ROLE_SUPERVISOR':
      case 'ROLE_ADMIN':
        if (this.authService.getBusinessType() === BusinessType.RESELLER) {
          listConfig = this.reseller_config_list;
        } else {
          listConfig = this.hasPushAPI() ? this.admin_config_list : this.admin_config_list_no_pushapi;
        }
        break;
      default:
        listConfig = this.user_config_list;
        break;
    }
    const d = this.loadConfig(listConfig, false);

    return d.then(data => {
      this._data_ready = true;
      let title = 'Graam';

      if (data.system_params.enterprise_name) {
        title = title + ' - ' + data.system_params.enterprise_name;
      }

      document.title = title;

      return this.config_dict;
    });
  }

  async loadConfig(config_list, use_cache = true): Promise<any> {
    if (this.config_dict === undefined) {
      this.config_dict = {};
    }

    if (this.loading_promises === undefined) {
      this.loading_promises = {};
    }
    const request_configs: any = {};
    const waiting_promises = [];

    for (const config_name of config_list) {
      if (!(config_name in this.loading_promises && this.loading_promises[config_name] !== undefined)) {
        // config_name in this.config_dict||  only reload not loaded promises
        if (!(use_cache && this.config_dict[config_name])) {
          request_configs[config_name] = true;
        }
      } else {
        waiting_promises.push(this.loading_promises[config_name]);
      }
    }

    if (Object.keys(request_configs).length > 0) {
      let params = new HttpParams();
      for (const k of Object.keys(request_configs)) {
        if (request_configs.hasOwnProperty(k)) {
          params = params.append(k, request_configs[k].toString());
        }
      }
      const d_loading = this.$http.get(this.remote_url, { params });

      const d = d_loading.pipe(map(data => {
        for (const config_name of Object.keys(request_configs)) {
          if (request_configs.hasOwnProperty(config_name)) {
            if (this.config_dict === undefined) {
              this.config_dict = {};
            }

            if (this.loading_promises === undefined) {
              this.loading_promises = {};
            }

            this.loading_promises[config_name] = undefined;

            let dataConfig: any = data[config_name];
            if (config_name === 'routing_app') {
              dataConfig = CALL_ROUTING_APPS; // loaded from local
            }
            this.updateCacheData(config_name, dataConfig, false);
          }
        }
        this.em.resyncRepositories(config_list);

        return this.config_dict;
      }));

      for (const config_name of Object.keys(request_configs)) {
        if (request_configs.hasOwnProperty(config_name)) {
          this.loading_promises[config_name] = d;
        }
      }
      waiting_promises.push(d);
    }

    if (waiting_promises.length > 0) {
      return forkJoin(waiting_promises)
        .pipe(
          map(ret => {
            return this.config_dict;
          })
        )
        .toPromise();
    }

    return this.config_dict;
  }

  get_cached_config(config_name: string): any {
    return this.config_dict[config_name];
  }

  getUserTypesByBusinessType(businessType: BusinessType): {
    user_type: UserType;
    user_type_name: string;
  }[] {
    const listMap: any = {};
    listMap[BusinessType.CALLCENTER] = ConfigManager.UserTypesForCallCenter;
    listMap[BusinessType.HOTEL] = ConfigManager.UserTypesForHotel;
    listMap[BusinessType.ENTERPRISE] = ConfigManager.UserTypesForEnterprise;
    listMap[BusinessType.RESELLER] = ConfigManager.UserTypesForReseller;
    listMap[BusinessType.SIPTRUNK] = ConfigManager.UserTypesForSipTrunk;

    const userTypes: UserType[] = listMap[businessType] || [];
    return userTypes.map(x => {
      return {
        user_type: x,
        user_type_name: `user_type_names.${x}`
      };
    });
  }

  async get_config(config_name: string, config_value: boolean = true, use_cache = true): Promise<any> {
    if (config_name === 'config_enterprise_crm') {
      return Promise.resolve(ENTERPRISE_CRM);
    }

    const businessType: BusinessType = this.authService.getBusinessType();
    if (config_name === 'crm_types') {
      if (businessType === BusinessType.HOTEL) {
        return _.pick(ENTERPRISE_CRM.crm_list, ['oraclehospitality']);
      }

      return _.pick(ENTERPRISE_CRM.crm_list, _.remove(Object.keys(ENTERPRISE_CRM.crm_list), x => x !== 'oraclehospitality'));
    }

    if (config_name === 'phone_models') {
      return Promise.resolve(PHONE_MODELS);
    }

    if (config_name === 'roles') {
      const roleName: UserRole = this.authService.getUserRole();
      const userRoles: string[] = roleName === UserRole.SUPER_ADMIN ?
        ConfigManager.RoleListForSuperAdmin :
        ConfigManager.RoleListForClient;

      return userRoles.map(x => {
        return {
          role: x,
          role_name: `role_names.${x}`
        };
      });
    }

    if (config_name === 'user_type') {
      return this.getUserTypesByBusinessType(businessType);
    }

    const config_dict = this.config_dict ? this.config_dict : {};

    if (!this.loading_promises) {
      this.loading_promises = {};
    }

    // If the configuration has already been loaded
    if (use_cache && config_name in config_dict) {
      return config_dict[config_name];
    }
    // Otherwise, check if the config is being loaded
    let p = this.loading_promises[config_name];
    let data;
    if (p) {
      // It is being loaded
      data = await p.toPromise();

      return this.updateCacheData(config_name, data[config_name], true);
    }
    // If not, load it
    //        var request_params = {};
    //        request_params[config_name] = config_value;

    let params: HttpParams = new HttpParams();
    params = params.append(config_name, config_value.toString());

    p = this.$http.get(this.remote_url, { params });
    this.loading_promises[config_name] = p;

    data = await p.toPromise();
    if (!this.config_dict) {
      this.config_dict = {};
    }
    if (!this.loading_promises) {
      this.loading_promises = {};
    }
    this.loading_promises[config_name] = undefined;
    const result = this.updateCacheData(config_name, data[config_name], true);
    // console.log('get_config result: ', config_name, result);

    return result;
    // this.loading_promises[config_name] = p;
    // return p;
  }

  // tslint:disable-next-line:cyclomatic-complexity
  protected updateCacheData(config_name: string, list_data, resync: boolean = true): any {
    // NOTE: This function must return the config object because it is used as return value in getConfig method
    let i: number;
    let item: IEntity;
    const new_key_set = new Array<number>();
    let object_list: any[];
    const repository = this.em.getRepositoryById(config_name);

    if (this.config_dict && this.config_dict[config_name]) {
      if (repository) {
        object_list = this.config_dict[config_name];
      } else {
        // If the data doesn't have a corresponding repository, then list_data is an object (not a list)
        _.merge(this.config_dict[config_name], list_data);
        if (resync) {
          this.em.resyncRepository(config_name);
        }

        return this.config_dict[config_name];
      }
    } else {
      if (repository) {
        object_list = [];
        if (!this.config_dict) {
          this.config_dict = {};
        }
        this.config_dict[config_name] = object_list;
      } else {
        // If the data doesn't have a corresponding repository, then list_data is an object (not a list)
        // Set that value to the cache and return
        this.config_dict[config_name] = list_data;

        // Set phonenumber config
        if (config_name === 'system_params') {
          this.phoneNumberService.setTeleComCountryPrefix(this.config_dict[config_name]['telecom_country_prefix']);
        }
        if (resync) {
          this.em.resyncRepository(config_name);
        }

        return list_data;
      }
    }

    if (!list_data || list_data.length === 0) {
      object_list.length = 0; // Clear current object list
      repository.setDataAsReady(); // set data as ready
      if (resync) {
        this.em.resyncRepository(config_name);
      }
      return;
    }

    // Add new element to this.object_list
    for (const obj_data of list_data) {
      let existing_obj: any = object_list.filter(obj => obj.id === obj_data.id);
      if (existing_obj && existing_obj.length) {
        existing_obj = existing_obj[0];
        if (repository) {
          existing_obj.setObjectData(obj_data, false);
        } else {
          _.merge(existing_obj, obj_data);
        }
      } else {
        let new_obj = obj_data;
        if (repository) {
          new_obj = repository.create(obj_data);
          new_obj.setObjectData(obj_data, false);
        }
        object_list.push(new_obj);
      }
      new_key_set.push(obj_data.id);
    }
    // Remove all element that no longer exist in list_data
    if (new_key_set.length) {
      i = 0;
      while (i < object_list.length) {
        item = object_list[i];
        if (new_key_set.indexOf(item.getId()) !== -1) {
          i++;
        } else {
          object_list.splice(i, 1);
        }
      }
    }
    repository.setObjectList(object_list);
    repository.setDataAsReady(); // set data as ready
    if (resync) {
      this.em.resyncRepository(config_name);
    }
    return object_list;
  }

  getMyprofile(): Promise<MyUserProfile> {
    return this.get_config('myprofile');
  }

  getUserRoles(): any {
    return this.get_cached_config('roles');
  }

  getUserTypes(): any {
    return this.get_config('user_type');
  }

  isOnlyAdmin(): any {
    return this.get_config('is_only_admin');
  }

  getCustomStatsFilterList(): any {
    return this.get_config('custom_stats_filter');
  }

  getAgentList(): any {
    return this.get_config('agent');
  }

  getTagList(): any {
    return this.get_config('tag');
  }

  getSubscriptionPackList(): any {
    return this.get_config('subscription_pack');
  }

  getSubscriptionList(): any {
    return this.get_config('subscription');
  }

  getSipAccountList(): any {
    return this.config_dict['sip_account'];
  }

  // Has moved to phonenumber.service
  // getDisplayNumber(phone_number: string) {
  //   if (!phone_number) {
  //     return '';
  //   }

  //   const telecomCountryPrefix = this.getSystemParam('telecom_country_prefix');
  //   let newPhoneNumber = phone_number;

  //   if (!telecomCountryPrefix) {
  //     return phone_number;
  //   }

  //   if (phone_number.charAt(0) === '+') {
  //     newPhoneNumber = phone_number.substr(1);
  //   }

  //   if (newPhoneNumber.substring(0, telecomCountryPrefix.length) === telecomCountryPrefix) {
  //     return '0' + newPhoneNumber.substring(telecomCountryPrefix.length);
  //   }

  //   return newPhoneNumber;
  // }

  getCrmTypeList(): Promise<any> {
    return this.get_config('crm_types');
  }

  // Not in used
  // getCrmOauthRedirectUrl(): Promise<string> {
  //   return this.get_config('crm_oauth_redirect_url');
  // }

  getPhoneModels(): Promise<any> {
    return this.get_config('phone_models');
  }
}
