import { Injectable } from '@angular/core';

import { HttpEngine } from '@wephone-core/service/http_engine';
import { CommonAPI } from '@wephone-core/service/common_api';
import { StorageService } from '@wephone-core/service/storage.service';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { Platform } from '@wephone-utils';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx/';

@Injectable()
export class PushNotificationManager {
  private firebase_update_url: string = 'user/update_firebase_device';

  constructor(private httpEngine: HttpEngine, private plateform: Platform, private push: Push) {}

  updateFirebaseDevice() {
    const deviceType = this.plateform.isIOS() ? 'ios' : this.plateform.isAndroid() ? 'android' : 'windows';

    if (!(deviceType === 'ios' || deviceType === 'android')) {
      return;
    }

    const options: any = {
      android: {},
      ios: {
        alert: 'true',
        badge: true,
        sound: 'false'
      },
      windows: {},
      browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
      }
    };

    const pushObject: PushObject = this.push.init(options);

    pushObject
      .on('notification')
      .subscribe((notification: any) => console.log('Received a notification', notification));

    pushObject.on('registration').subscribe((registration: any) => {
      console.log('Device registeredId', registration);
      const registrationId = registration.registrationId;
      CommonAPI.apiPost(this.firebase_update_url, {
        registrationId: registrationId,
        deviceType: deviceType
      });
    });
  }
}
