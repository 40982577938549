<div fxLayout="column" class="wrapper">
  <div fxLayout="row" fxLayoutGap="10px" class="mb-1">
    <flex-search-input class="full-width" [(ngModel)]="filterValue" (ngModelChange)="onFilterChanged()"></flex-search-input>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <button mat-stroked-button color="accent" class="button-add" (click)="createCalendar()">
      <mat-icon class="icon-add">add</mat-icon>
      <span class="vertical-align">{{ 'openinghour_calendar.create_new'|translate }}</span>
    </button>
  </div>

  <div *ngFor="let calendar of calendarList; let i = index;" class="calendar-item mt-1"
    [ngClass]="{'selected': selectedCalendar?.id == calendar.id}" (click)="selectedCalendar = calendar">
    <mat-card data-ci="calendar" *ngIf="!calendar.id || !isSelected(calendar.id)" class="shadow-none card-calendar">
      <mat-card-title fxLayout="row" fxLayoutAlign="start center">
        <div class="name">{{ calendar.name }}</div>
        
        <div fxFlex fxLayoutAlign="end center">
          <mat-icon *ngIf="calendar.id; else DefaultCalendar" class="float-right icon-arrow">expand_more</mat-icon>
          <ng-template #DefaultCalendar>
            <button type="button" mat-flat-button mat-button-sm color="accent" (click)="selectedCalendar = calendar;applySelect()">
              {{ 'public.select_item'|translate }}
            </button>
          </ng-template>
        </div>
        
      </mat-card-title>
    </mat-card>
    <calendar-small *ngIf="isSelected(calendar.id) && calendar.id" [id]="calendar.id" [allowEdit]="true"
      [showSelector]="true" (onItemSelected)="applySelect()"></calendar-small>
  </div>
</div>
