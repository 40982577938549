import { Injectable } from '@angular/core';
import { IBrowserSpeechRecognition } from '../speech-recognition.i';
import { TranslationService } from '@wephone-translation';

@Injectable()
export class Speech2TextService {

  callback: (text: string) => void;
  SpeechRecognition: any;

  currentRecognitionInstance: IBrowserSpeechRecognition;

  constructor(
    private transService: TranslationService
  ) {
    this.SpeechRecognition = window['webkitSpeechRecognition'] || window['SpeechRecognition'];
  }

  start(callback: (text: string) => void): void {
    if (!this.SpeechRecognition) {
      return;
    }

    this.callback = callback;
    this.currentRecognitionInstance = new this.SpeechRecognition();

    this.currentRecognitionInstance.continuous = false;
    this.currentRecognitionInstance.lang = this.transService.getCurrLanguage().code;

    this.currentRecognitionInstance.onresult = (event: any) => {
      const text = event.results[0][0].transcript;

      if (this.callback) {
        this.callback(text);
      }
    };

    this.currentRecognitionInstance.start();
  }

  stop(): void {
    if (this.currentRecognitionInstance) {
      this.currentRecognitionInstance.stop();
    }
  }
}
