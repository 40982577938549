import { Component, OnInit, OnDestroy, Input, Self, Optional, ElementRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { CallingProfileEntity } from '@wephone-core/model/entity/callingprofile';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { CallingProfileRepository } from '@wephone-core/model/repository/callingprofile';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'calling-profile-select-form-input',
  templateUrl: './calling-profile-select.component.html',
  styleUrls: ['./calling-profile-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CallingProfileSelectFormInput }]
})
export class CallingProfileSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<CallingProfileEntity> {
  static nextId = 0;

  isLoading: boolean;
  flexSelect: FormControl = new FormControl();
  callingProfileList: CallingProfileEntity[];
  flexSelectOptions: IFlexSelectOptions;
  private _noInternalOption: boolean;
  private _noDefaultOption: boolean;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: CallingProfileEntity, filterString: string) => {
        const name = _ti(item.name);
        return regexSearch(name, filterString);
      },
      compareWith: (a: CallingProfileEntity, b: CallingProfileEntity) => {
        return a && b && a.getId() === b.getId() || !a && !b;
      }
    };
    this.isLoading = true;
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    this.initCallingProfileList();
    super.ngOnInit();
  }

  @Input()
  get noInternalOption(): boolean {
    return this._noInternalOption;
  }
  set noInternalOption(op) {
    this._noInternalOption = coerceBooleanProperty(op);
    this.stateChanges.next();
  }

  @Input()
  get noDefaultOption(): boolean {
    return this._noDefaultOption;
  }
  set noDefaultOption(op) {
    this._noDefaultOption = coerceBooleanProperty(op);
    this.stateChanges.next();
  }

  initCallingProfileList(): void {
    this.callingProfileList = [];

    const callingProfileRepo = CallingProfileRepository.getInstance<CallingProfileRepository>();
    if (!this._noDefaultOption) {
      const default_profile: CallingProfileEntity = CallingProfileRepository.default;
      this.callingProfileList.push(default_profile);

      if (!this.flexSelect.value) {
        this.flexSelect.setValue(default_profile);
      }
    }

    const list = callingProfileRepo.getObjectList();
    this.isLoading = false;
    if (list && list.length) {
      for (const i of list) {
        if (this._noInternalOption && i.id === CallingProfileEntity.CALLING_PROFILES['internal']) {
          continue;
        }
        this.callingProfileList.push(i);
      }
    }
  }

  get controlType(): string {
    return 'calling-profile-select-form-input';
  }
}
