import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class MyActiveCallRepository extends BaseRepository {
  ENTITY_CLASS = MyActiveCall;
  protected USE_PUSH_API = true;

  protected onObjectRemoved(obj: IEntity) {
    super.onObjectRemoved(obj);
    (obj as MyActiveCall).onHangup();
  }
}

import { IEntity } from '@wephone-core/model/model.interface';
import { MyActiveCall } from '@wephone-core/model/entity/my_active_call';
