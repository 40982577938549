<div *ngIf="user" class="user-avatar" [ngClass]="user.agent?.agent_status" [ngStyle]="{width: width, height: height}">
  <div class="avatar-inner">
    <div class="status-bg"
      [ngClass]="{'available': user.agent?.is_connected, 'in-only': user.agent?.inout_state==1, 'out-only': user.agent?.inout_state==2, 'manual':  user.agent?.inout_state==3}">
      <div [ngClass]="user.agent?.agent_status">
        <ng-container *ngIf="uploadAvatar;else imageAvatar">
          <a [title]="'fileentry.action.upload_image'|translate" [matMenuTriggerFor]="menu">
            <ng-container *ngTemplateOutlet="imageAvatar"></ng-container>
          </a>
        </ng-container>
        <ng-template #imageAvatar>
          <img mat-list-avatar [src]="user.avatarUrl" [ngStyle]="{width: width, height: height}"
            data-ci="avatar-image" />
        </ng-template>
        <input #fileInput type="file" [accept]="fileSettings.extensions|extensionAccept" style="display:none"
          (change)="onAvatarSelected($event)">
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="fileInput.click()">
            <mat-icon>cloud_upload</mat-icon>
            {{ 'fileentry.action.upload_image' |translate }}
          </button>
          <button mat-menu-item (click)="removeAvatar()" *ngIf="user.avatar_id">
            <mat-icon>delete</mat-icon>
            {{ 'fileentry.action.remove_image' | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="in-out-indicator" aling="center"
      *ngIf="user.is_online_cc && (user.agent?.inout_state != 0) && (user.agent?.is_postcall_paused || !user.agent?.is_paused)"
      fxLayout fxLayoutAlign="center center">
      <span *ngIf="user.agent?.inout_state==1">
        <fa-icon [icon]="FAIcons.faLongArrowAltDown"></fa-icon> IN
      </span>
      <span *ngIf="user.agent?.inout_state==2">
        <fa-icon [icon]="FAIcons.faLongArrowAltDown"></fa-icon> OUT
      </span>
    </div>
  </div>
</div>
