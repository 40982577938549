<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="filterForm" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterValue" [hintText]="'qualification.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>
  <ng-template #groupTpl let-qualification="row">
    <span>{{ qualification.group?.name }}</span>
  </ng-template>
  <flex-crud-page #flexCrud [dataSource]="dataSource" [tableConfig]="tableConfig" [columnTemplates]="{'group':groupTpl}"
    pageTitle="{{ 'menu.manage_call_qualification'| translate }}"
    [filterTemplate]="listFilterTpl" [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow"></flex-crud-page>
</div>
