import * as _ from 'lodash';

import { Component, OnInit, Input, ViewChild, ElementRef, Optional, Self } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FormControl, NgControl } from '@angular/forms';
import { FlexMatInputWrapper, TreeHelper } from '@wephone-utils';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserRepository } from '@wephone-core/model/repository/user';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { TeamGroupRepository } from '@wephone-core/model/repository/teamgroup';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'flex-select-user-input',
  templateUrl: './flex-select-user-input.html',
  styleUrls: ['./flex-select-user-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectUserInput }]
})
export class FlexSelectUserInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() excludeTypes: string[];
  @Input() filteredIds: number[];
  @Input() multiple: boolean;
  @Input() showCheckAll: boolean;
  @Input() hasUser: boolean;

  @ViewChild('flexSelectTreeInput') flexSelectTreeInput: FlexSelectTreeInput;

  // Private member var with default value
  private readonly teamRepo: TeamGroupRepository;
  private readonly userRepo: UserRepository;

  // Private member var
  private teams: TeamGroupEntity[] = [];
  private users: UserEntity[] = [];

  // Public member var with default value

  // Public member var
  myControl = new FormControl();
  userNodes: FlexSelectTreeNode[];

  constructor(
    elRef: ElementRef,
    @Optional()
    @Self()
    ngControl: NgControl,
    em: EntityManager
  ) {
    super(ngControl, elRef);

    this.teamRepo = em.getRepository<TeamGroupRepository>('TeamGroupRepository');
    this.userRepo = em.getRepository<UserRepository>('UserRepository');

    this.teams = this.teamRepo.getObjectList();
    this.users = this.userRepo.getObjectList();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!_.isEmpty(this.excludeTypes) && _.isArray(this.excludeTypes)) {
      this.users = this.users.filter(u => !this.excludeTypes.includes(u.user_type));
    }

    if (!_.isUndefined(this.filteredIds)) {
      this.users = this.users.filter(x => _.includes(this.filteredIds, x.id));
    }

    if (this.hasUser) {
      this.teams = this.teams.filter(t => t.hasUser(this.users));
    }

    this.loadUsers();
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-users-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }

  protected loadUsers(): void {
    const nodeList = [];
    const usersNotTeam = this.users.filter(user => !user.team_id);

    usersNotTeam.forEach(user => {
      nodeList.push({
        id: user.id,
        name: user.name,
        parent_id: undefined
      });
    });

    for (const team of this.teams) {
      nodeList.push({
        id: `team_${team.id}`,
        name: team.name,
        parent_id: team.parent_id ? `team_${team.parent_id}` : undefined
      });

      for (const user of team.users.filter(x => _.includes(this.users.map(i => i.id), x.id))) {
        nodeList.push({
          id: user.id,
          name: user.name,
          parent_id: `team_${team.id}`
        });
      }
    }

    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.userNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }
}
