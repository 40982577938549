import { datafield, Entity } from '@wephone-core/model/model';
import { DidEntity } from './did';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { parseDateTime } from '@wephone-utils';

export class SipTrunkEntity extends Entity {
  static object_type_id = 'sip_trunk';

  @datafield id: number;
  @datafield enterprise_id: number;
  @datafield sbc_group_id: number;
  @datafield lb_group_id: number;
  @datafield name: string;
  @datafield username: string;
  @datafield password: string;
  @datafield alias: string;
  @datafield prefix: string;
  @datafield proxy_host: string;
  @datafield proxy_port: number;
  @datafield secondary_proxy_host: string;
  @datafield secondary_proxy_port: number;
  @datafield secondary_proxy_mode: number;
  @datafield outgoing_enabled: number;
  @datafield backup_number: string;
  @datafield backup_number_enable: number;
  @datafield sip_ping_enable: number;
  @datafield channel_max: number;
  @datafield is_default: number;
  @datafield is_online: number;
  @datafield last_user_agent: string;
  @datafield last_online_dt: any;

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      if (did.routed_siptrunk && did.routed_siptrunk.id === this.id ||
        did.out_office_hours_routed_siptrunk && did.out_office_hours_routed_siptrunk.id === this.id ||
        (did.hasLinkedSipTrunk() && did.linked_object_id === this.id)) {
        ret.push(did);
      }
    }
    return ret;
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.last_online_dt = this.last_online_dt && parseDateTime(this.last_online_dt);
  }
}
