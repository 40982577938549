import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs/operators';
import {
  Component, OnInit, ElementRef, Optional, Input,
  EventEmitter, ChangeDetectorRef, Output
} from '@angular/core';
import { FlexMatInputBase, ToastService, DialogService, NoWhitespaceValidator } from '@wephone-utils';
import {
  FormControl,
  NgControl,
  FormBuilder,
  FormGroup,
  FormArray,
  ValidatorFn,
  AbstractControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { OpeningHourRepository } from '@wephone-core/model/repository/openinghour';
import { OpeningHourSpecialDateRepository } from '@wephone-core/model/repository/openinghour_special_date';
import { OpeningHourSpecialDateEntity, OpeningMode } from '@wephone-core/model/entity/openinghour_special_date';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { IOpeningCalendarEditDialogConfig } from '../';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidRepository } from '@wephone-core/model/repository/did';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { _ti } from '@wephone-translation';

export class CalendarErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const duplicateNameValidator = (id: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const name = control.value;
    const calendars = OpeningHourCalendarRepository.getInstance().getObjectList();
    let isDuplicateName;

    if (!id) {
      isDuplicateName = calendars.find(c => c.name === name);
    } else {
      isDuplicateName = calendars.find(c => c.id !== id && c.name === name);
    }

    return isDuplicateName ? { duplicateName: true } : undefined;
  };
};

export const duplicateOpeningHourValidator = (openingHoursControl: FormArray): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const openingHours = openingHoursControl.value;
    const openingHour = control.value;

    const isDuplicate = openingHours.filter((o) => {
      return (
        o.start_time === openingHour.start_time &&
        o.end_time === openingHour.end_time &&
        o.day_from === openingHour.day_from &&
        o.day_to === openingHour.day_to
      );
    });

    console.log('isDuplicate: ', isDuplicate);
    return isDuplicate.length > 1 ? { duplicateOpeingHour: true } : undefined;
  };
};

export const dateRangeValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | undefined => {
    const openingHourSpecialDate = control.value;
    const mStart = DateTime.fromJSDate(new Date(openingHourSpecialDate.start_dt)).startOf('day');
    const mEnd = DateTime.fromJSDate(new Date(openingHourSpecialDate.end_dt)).startOf('day');

    // const format = 'yyyy-MM-dd';
    // let startDateTime = DateTime.fromJSDate(new Date(openingHourSpecialDate.start_dt)).toFormat(format);
    // let endDateTime = DateTime.fromJSDate(new Date(openingHourSpecialDate.end_dt)).toFormat(format);

    // if (openingHourSpecialDate.opening_mode === 2 || openingHourSpecialDate.opening_mode === 3) {
    //   if (openingHourSpecialDate.openinghour) {
    //     // start_time has format is 'HH:mm'
    //     startDateTime = `${startDateTime} ${openingHourSpecialDate.openinghour.start_time}:00`;
    //     endDateTime = `${endDateTime} ${openingHourSpecialDate.openinghour.end_time}`;
    //     // format = format + 'HH:mm';
    //   }
    // }

    // const mStart = DateTime.fromSQL(startDateTime);
    // const mEnd = DateTime.fromSQL(endDateTime);

    return mStart > mEnd ? { dateRange: true } : undefined;
  };
};

export const timeRangeValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | undefined => {
    const openingHourSpecialDate = control.value;
    const startDate = DateTime.fromJSDate(new Date(openingHourSpecialDate.start_dt)).startOf('day');
    const endDate = DateTime.fromJSDate(new Date(openingHourSpecialDate.end_dt)).startOf('day');
    console.log('startDate > endDate', startDate > endDate);
    if (
      endDate.equals(startDate) &&
      (openingHourSpecialDate.opening_mode === OpeningMode.CloseDuringPeriod || openingHourSpecialDate.opening_mode === OpeningMode.OpenDuringPeriod) &&
      openingHourSpecialDate.openinghour
    ) {
      const format = 'yyyy-MM-dd';
      // start_time has format is 'HH:mm'
      const startDateTime = `${startDate.toFormat(format)} ${openingHourSpecialDate.openinghour.start_time}:00`;
      const endDateTime = `${endDate.toFormat(format)} ${openingHourSpecialDate.openinghour.end_time}:00`;
      // format = format + 'HH:mm';

      const mStart = DateTime.fromSQL(startDateTime);
      const mEnd = DateTime.fromSQL(endDateTime);
      return mStart >= mEnd ? { timeRange: true } : undefined;
    }

    return undefined;
  };
};

export const nameRequiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | undefined => {
    const openingHourSpecialDate = control.value;
    return !openingHourSpecialDate.name ? { nameRequired: true } : undefined;
  };
};

@Component({
  selector: 'calendar-form-input',
  templateUrl: './calendar-form-input.component.html',
  styleUrls: ['./calendar-form-input.component.scss']
})
export class CalendarFormInput extends FlexMatInputBase
  implements OnInit, MatFormFieldControl<OpeningHourCalendarEntity> {
  @Input() config: IOpeningCalendarEditDialogConfig = {};
  @Input() showSideSteps: boolean;
  @Output() readonly valueChange: EventEmitter<any> = new EventEmitter();

  // Private member var with default value
  private readonly openingHourRepo = OpeningHourRepository.getInstance<OpeningHourRepository>();
  private readonly openingHourSpecialDateRepo = OpeningHourSpecialDateRepository.getInstance<OpeningHourSpecialDateRepository>();

  readonly steps = {
    form: 'opening_hour_calendar.content.information',
    inused: 'opening_hour_calendar.content.usage',
  };

  usedQueueList: CallQueueEntity[] = [];
  usedQueueStr: string;

  usedDidList: DidEntity[] = [];
  usedDidStr: string;

  usedIvrList: IvrCustomMenuEntity[] = [];
  usedIvrStr: string;

  usedAgentList: AgentEntity[] = [];
  usedAgentStr: string;

  // Public member var
  matcher = new CalendarErrorStateMatcher();
  calendarForm: FormGroup;
  showException = false;
  hasChanged: boolean;

  constructor(
    elRef: ElementRef,
    @Optional() ngControl: NgControl,
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly toast: ToastService,
    public dialogService: DialogService,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, elRef, cdr);
    this.calendarForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      openingHours: this.fb.array([]),
      openingHoursSpecialDate: this.fb.array([])
    });
  }

  async ngOnInit(): Promise<void> {
    this.config = this.config || {};
    if (_.isUndefined(this.config.showUsedObjects)) {
      this.config.showUsedObjects = true;
    }

    this.calendarForm.valueChanges.pipe(
      takeUntil(this._onDestroy)
    )
      .subscribe(values => {
        const openingHourCalendar = this.value || {};
        openingHourCalendar.name = values['name'];
        openingHourCalendar.openinghours = values['openingHours'];
        openingHourCalendar.openinghours_special_date = values['openingHoursSpecialDate'];
        this.value = openingHourCalendar;
        this.valueChange.emit({ value: this.value });
      });

    if (this.value && !_.isEmpty(this.value.openinghours_special_date)) {
      this.showException = true;
    }

    try {
      await this.getObjectsUsingCalendar();
    } catch (error) {
      console.error('getObjectsUsingCalendar Error', error);
    }
  }

  get stepKeys(): string[] {
    return Object.keys(this.steps);
  }

  get valueHasInitialized(): boolean {
    return this.valueInitialized;
  }

  get hasUsedItem(): boolean {
    return this.usedDidList.length > 0 || this.usedAgentList.length > 0 || this.usedQueueList.length > 0 || this.usedIvrList.length > 0;
  }

  // Form controls
  get nameControl(): FormControl {
    return this.calendarForm.get('name') as FormControl;
  }

  get openingHoursControl(): FormArray {
    return this.calendarForm.get('openingHours') as FormArray;
  }

  get openingHoursSpecialDateControl(): FormArray {
    return this.calendarForm.get('openingHoursSpecialDate') as FormArray;
  }

  get controlType(): string {
    return 'flex-calendar-form-input';
  }

  async isValidCalendar(calendar: OpeningHourCalendarEntity): Promise<boolean> {
    for (let i = 0; i < calendar.openinghours.length; i++) {
      if (calendar.openinghours[i].day_from === calendar.openinghours[i].day_to) {
        if (calendar.openinghours[i].start_time === calendar.openinghours[i].end_time) {
          this.toast.showError(_ti('opening_hour_calendar.message.dulicate_time'));
          return false;
        }
      }
    }

    if (!calendar.openinghours.length) {
      this.toast.showError(_ti('opening_hour_calendar.message.not_blank_hour'));
      return false;
    }

    // if (this.hasUsedItem) {
    //   const ok: boolean = await this.confirmSaving();
    //   if (!ok) {
    //     return false;
    //   }
    // }

    if (!this.calendarForm.valid) {
      const formErrors = Object.values(this.calendarForm.controls).filter(control => !!control.errors).map(control => control.errors);
      console.error('Calendar form error', formErrors);
      return;
    }

    return true;
  }

  // async confirmSaving(): Promise<boolean> {
  //   const df = new Deferred<boolean>();
  //   await this.dialogService.confirmDialog(
  //     _ti('dialogs.confirmation'),
  //     _ti('opening_hour_calendar.message.confirm_used_saving'),
  //     () => {
  //       df.resolve(true);
  //     }
  //   );
  //   return df.promise;
  // }

  async getObjectsUsingCalendar(): Promise<void> {
    if (this.value && this.value.id) {
      const data: any[] = await this.em.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository')
        .getObjectsUsingCalendars([this.value.id]);

      this.usedQueueList = [];
      this.usedQueueStr = undefined;

      this.usedIvrList = [];
      this.usedIvrStr = undefined;

      this.usedDidList = [];
      this.usedDidStr = undefined;

      this.usedAgentList = [];
      this.usedAgentStr = undefined;

      for (const item of data) {
        const entityName: string = item[0];
        const entityId: number = +item[1];

        const repo = this.em.getRepositoryById(entityName);
        if (!repo) {
          console.error('No repo with name' + entityName);
          continue;
        }

        if (repo instanceof DidRepository) {
          const usedDid: DidEntity = repo.getObjectById(entityId);
          this.usedDidList.push(usedDid);
        }

        if (repo instanceof IvrCustomMenuRepository) {
          const usedIvr: IvrCustomMenuEntity = repo.getObjectById(entityId);
          this.usedIvrList.push(usedIvr);
        }

        if (repo instanceof AgentRepository) {
          const usedAgent: AgentEntity = repo.getObjectById(entityId);

          if (usedAgent.availability !== 2) {
            continue;
          }
          this.usedAgentList.push(usedAgent);
        }

        if (repo instanceof CallQueueRepository) {
          this.usedQueueList.push(repo.getObjectById(entityId));
          const usedQueue: CallQueueEntity = repo.getObjectById(entityId);

          if (usedQueue.availability !== 0) {
            continue;
          }

          // if (usedQueue.dedicated_user) {
          //   const dedicatedDid: DidEntity = this.em.getRepository<DidRepository>('DidRepository').getObjectList()
          //     .find((i: DidEntity) => i.hasLinkedUser());

          //   if (dedicatedDid && !this.usedDidList.find(x => x.id === dedicatedDid.id)) {
          //     this.usedDidList.push(dedicatedDid);
          //   }
          // }

          const didList: DidEntity[] = usedQueue.get_did_list();
          for (const d of didList) {
            if (!this.usedDidList.find(x => x.id === d.id)) {
              this.usedDidList.push(d);
            }
          }
        }
      }

      if (this.usedIvrList.length) {
        this.usedIvrStr = this.usedIvrList.map(x => x.name).join(', ');
      }

      if (this.usedQueueList.length) {
        this.usedQueueStr = this.usedQueueList.map(x => x.queue_name).join(', ');
      }

      if (this.usedDidList.length) {
        this.usedDidStr = this.usedDidList.map(x => x.number).join(', ');
      }

      if (this.usedAgentList.length) {
        this.usedAgentStr = this.usedAgentList.map(x => x.name).join(', ');
      }

      this.cdr.markForCheck();
    }
  }

  writeValue(val: OpeningHourCalendarEntity): void {
    const cloneVal = _.cloneDeep(val);
    while (this.openingHoursControl.length !== 0) {
      console.log('this.openingHoursControl', this.openingHoursControl);
      this.openingHoursControl.removeAt(0);
    }

    while (this.openingHoursSpecialDateControl.length !== 0) {
      this.openingHoursSpecialDateControl.removeAt(0);
    }

    if (cloneVal) {
      this.nameControl.setValue(cloneVal.name, { emitEvent: false });
      this.nameControl.setValidators([Validators.required, Validators.maxLength(100), NoWhitespaceValidator, duplicateNameValidator(cloneVal.id)]);

      cloneVal.openinghours.forEach(openingHour => {
        this.openingHoursControl.push(
          this.fb.control(openingHour, duplicateOpeningHourValidator(this.openingHoursControl))
        );
      });

      cloneVal.openinghours_special_date.forEach(openingHoursSpecialDate => {
        this.openingHoursSpecialDateControl.push(
          this.fb.control(openingHoursSpecialDate, [nameRequiredValidator(), dateRangeValidator(), timeRangeValidator()])
        );
      });
    }
    super.writeValue(cloneVal);
  }

  changeDestination(): void {
    if (this.config.editCustomDataCallback) {
      this.config.editCustomDataCallback(this.value).then(
        ret => {
          this.onChange();
          this.cdr.markForCheck();
        }
      );
    } else {
      console.error('editCustomDataCallback is not defined for editing calendar', this.value);
    }
  }

  addOpeningHour(): void {
    const newOpeninghour = this.openingHourRepo.create() as OpeningHourEntity;
    this.openingHoursControl.push(
      this.fb.control(newOpeninghour, duplicateOpeningHourValidator(this.openingHoursControl))
    );
    this.cdr.detectChanges();
  }

  removeOpeningHour(index: number): void {
    this.openingHoursControl.removeAt(index);
    this.cdr.detectChanges();
  }

  addOpeningHourSpecialDate(): void {
    const newOpeningHourSpecialDate = this.openingHourSpecialDateRepo.create() as OpeningHourSpecialDateEntity;
    newOpeningHourSpecialDate.setDefaultValue('Default');
    this.openingHoursSpecialDateControl.push(
      this.fb.control(newOpeningHourSpecialDate, [nameRequiredValidator(), dateRangeValidator(), timeRangeValidator()])
    );
    this.cdr.detectChanges();
  }

  removeOpeningHourSpecialDate(index: number): void {
    this.openingHoursSpecialDateControl.removeAt(index);
    this.cdr.detectChanges();
  }
}
