import { FormControl } from '@angular/forms';
import { ValidatorService } from '../services/validator.service';

export const EmailValidator = (control: FormControl) => {
  const value = control.value && control.value.trim() || '';
  if (value && !ValidatorService.VALID_EMAIL_PATTERN.test(value)) {
    return { wrongEmail: true };
  }
  return null;
};
