<mat-card class="ma-0">
    <mat-card-header>
        <mat-card-title>
            <strong>{{ 'sipphone.content.registered_history' | translate }}</strong>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <p *ngFor="let item of sipphoneConnectionLogs" class="log-line">
            <span *ngIf="item.disconnection;else RegisterConnection">{{
                'sipphone.message.connection_log_disconnect'|translate:{user_agent: item.user_agent, event_dt:
                (item.event_dt|timeFormatDateTimeShort)} }}</span>
            <ng-template #RegisterConnection>
                {{ 'sipphone.message.connection_log_connect'|translate:{user_agent: item.user_agent, event_dt:
                item.event_dt|timeFormatDateTimeShort} }}
            </ng-template>
        </p>
    </mat-card-content>

</mat-card>
