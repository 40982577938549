<div class="flex-dialog-title-bar gradient-background" fxLayoutAlign="start center" fxLayoutGap="10px">
  <button data-ci="back" class="flex-dialog-back-btn"
    *ngIf="dialogComponent.backButton && dialogComponent.backButton.visible()" mat-button type="button"
    (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h1 mat-dialog-title class="title"
    [ngClass]="{'padded-title' : !(dialogComponent.backButton && dialogComponent.backButton.visible()) }"
    *ngIf="dialogComponent.dialogTitle">{{ dialogComponent.dialogTitle | translate }}
  </h1>
  <div class="title-bar-buttons">
    <button data-ci="cancel" class="flex-dialog-cancel-btn" *ngIf="dialogComponent.cancelButton" mat-button
      type="button" [color]="dialogComponent.cancelButton.color" (click)="returnValue()">
      <mat-icon>close</mat-icon>
      {{ dialogComponent.cancelButton.label | translate }}
    </button>
  </div>
</div>

<div class="flex-dialog-content" [ngClass]="paddingContent ? '' : 'pa-0'">
  <ng-container flex-anchor></ng-container>
</div>
<hr>

<div *ngIf="hasCustomButtons()" class="flex-dialog-actions-bottom" fxLayoutAlign="start center" fxLayoutGap="10px">
  <div>
    <button [attr.data-ci]="'button-'+button.id"
      [hidden]="!(button.visible && button.visible())" mat-flat-button type="button"
      *ngFor="let button of dialogComponent.getDialogLeftActions()"
      [color]="button.color" (click)="button.action()"
      class="btn-actions">
      <span fxLayoutAlign="center center">
        <mat-icon *ngIf="button.icon" class="action-icon">{{ button.icon }}</mat-icon>
        <span *ngIf="button.label" class="font-weight-bold text-align-top">{{ button.label | translate }}</span>
        <span *ngIf="button.getLabel">{{ button.getLabel() }}</span>
      </span>
    </button>
  </div>
  <span fxFlex></span>

  <div class="main-actions">
    <button appFreezeClick [attr.data-ci]="button.id ? button.id : 'validate'"
      [hidden]="!(button.visible && button.visible())" mat-flat-button type="button"
      *ngFor="let button of dialogComponent.getDialogRightActions()"
      [color]="button.color" (click)="button.action()"
      class="mat-green btn-actions">
      <span fxLayoutAlign="center center">
        <mat-icon *ngIf="button.icon" class="action-icon">{{ button.icon }}</mat-icon>
        <span *ngIf="button.label" class="font-weight-bold text-align-top">{{ button.label | translate }}</span>
        <span *ngIf="button.getLabel">{{ button.getLabel() }}</span>
      </span>
    </button>
  </div>

</div>
