import { datafield, Entity } from '@wephone-core/model/model';

export class EnterpriseConsumptionEntity extends Entity {
  static object_type_id: string = 'enterprise_consumption';

  id: number;

  @datafield limit_national: number;

  @datafield limit_international: number;

  @datafield limit_vat: number;

  @datafield limit_daily_sms: number;

  @datafield limit_daily_call: number;

  @datafield limit_daily_call_minute: number;

  current_national: number;

  current_international: number;

  current_vat: number;

  current_daily_sms: number;

  current_daily_call: number;

  current_daily_call_minute: number;
}
