import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class EnterpriseCrmRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseCrmEntity;
  protected URL_PREFIX = 'enterprise_crm';
  private METHOD_PATH_LIST = 'list';
  private URL_LOG_CALL_TO_CRM_WITH_CALL_QUEUE_SESSION = 'log_call_to_crm_with_call_queue_session';
  private ADD_FROM_MARKET = 'add_from_market';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'LIST':
        return this.getUrlV2(this.METHOD_PATH_LIST);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  getMyEnterpriseCrm(): EnterpriseCrmEntity {
    return this.object_list.find(o => o.is_active !== 0);
  }

  async createEnterpriseCrmAndSave(object_data: any = null, extra_data: any = null): Promise<EnterpriseCrmEntity> {
    const obj = this.create(object_data);
    const ret = await this.save(obj, extra_data);
    if (!ret || !ret.id) {
      console.error('No response', ret);
      return;
    }

    return this.getObjectById(ret.id);
  }

  protected getRemoteData(use_cache = true, filters?: any): Promise<any[]> {
    const url = this.getURLAction('LIST');

    if (use_cache && (this.object_list || []).length) {
      return Promise.resolve(this.object_list);
    }

    return HttpEngine.getInstance().get(url).then((updated_list: any) => {
      this.setDataAsReady();

      return this.object_list;
    });
  }

  // logCallToCRM(call_queue_session_id): Promise<any> {
  //   const url = this.getUrl(this.URL_LOG_CALL_TO_CRM_WITH_CALL_QUEUE_SESSION);
  //   const params = {
  //     call_queue_session_id
  //   };

  //   return HttpEngine.getInstance().post(url, params);
  // }

  async addFromMarket(data: any): Promise<any> {
    return HttpEngine.getInstance().get(this.getUrlV2(this.ADD_FROM_MARKET), data);
  }

  nameExists(name: string): boolean {
    return !!this.object_list.find(i => i.name === name);
  }
}

import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { HttpEngine } from '@wephone-core/service/http_engine';
