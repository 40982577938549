import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import * as _ from 'lodash';
import { SipPhoneConnectionLogEntity } from '../entity/sipphone_connection_log';

export class SipPhoneConnectionLogRepository extends BaseRepository {
  ENTITY_CLASS = SipPhoneConnectionLogEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'sipphone_connection_log';

}
