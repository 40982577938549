import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FlexBaseComponent } from '@wephone-core-ui';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { ISipPhoneCustomSettings, ISipPhoneKeyConfig, SipphoneKeyConfigType } from '@wephone-core/model/entity/sipphone.i';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'app-sipphone-key-config',
  templateUrl: './sipphone-key-config.component.html',
  styleUrls: ['./sipphone-key-config.component.scss']
})
export class SipphoneKeyConfigComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() sipphone: SipPhoneEntity;
  @Input() settings: ISipPhoneCustomSettings;
  @Output() readonly onChange: EventEmitter<{
    type: string;
    data: number | string;
    label: string;
  }[]> = new EventEmitter();

  form: FormGroup;
  types: {
    label: string;
    value: string;
  }[] = [
      {
        label: 'sipphone.key_config_types.undefined',
        value: SipphoneKeyConfigType.Undefined
      },
      {
        label: 'sipphone.key_config_types.supervision',
        value: SipphoneKeyConfigType.Supervision
      },
      {
        label: 'sipphone.key_config_types.speeddial',
        value: SipphoneKeyConfigType.Speeddial
      }
    ];

  private hasInitialized: boolean;
  constructor(
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized) {
      return;
    }

    this.initForm();
  }

  ngOnInit(): void {
    this.hasInitialized = true;
    this.initForm();
  }

  private initForm(): void {
    if (!this.sipphone) {
      console.error('No input sipphone');
      return;
    }

    const keyLength: number = this.settings.screen_key_count;
    if (!this.sipphone.key_config) {
      this.sipphone.setDefaultKeyConfig(keyLength);
    }

    if (this.sipphone.key_config.length < keyLength) {
      for (let i = 0; i <= keyLength - this.sipphone.key_config.length; i++) {
        const newKeyConfig: ISipPhoneKeyConfig = {
          type: SipphoneKeyConfigType.Undefined,
          data: null,
          label: null
        };
        this.sipphone.key_config.push(newKeyConfig);
      }
    } else if (this.sipphone.key_config.length > keyLength) {
      // Remove last n keys limited by key-length
      this.sipphone.key_config.splice(keyLength - this.sipphone.key_config.length);
    }

    this.form = this.fb.group({
      key_config: this.fb.array(
        this.getKeyConfigsFormControls(this.sipphone.key_config)
      )
    });

    if (this.onChange) {
      this.addSubscription(
        this.form.valueChanges.subscribe(changes => {
          const keyConfigs = this.form.get('key_config').value || [];
          const value = [];
  
          for (const keyConfig of keyConfigs) {
            let data = keyConfig.data;
            if (data instanceof SipPhoneEntity) {
              data = data.id;
            }
  
            value.push({
              type: keyConfig.type,
              data,
              label: keyConfig.label
            });
          }
  
          this.onChange.emit(value);
        })
      );
    }
  }

  isValid(): boolean {
    for (const keyConfigControl of this.keyConfigsControl.controls) {
      keyConfigControl.get('type').setErrors(null);
      keyConfigControl.get('data').setErrors(null);
      keyConfigControl.get('label').setErrors(null);

      if (keyConfigControl.get('type').value !== SipphoneKeyConfigType.Undefined) {
        if (!keyConfigControl.get('data').value) {
          keyConfigControl.get('data').setErrors({ required: true });
        }

        if (!keyConfigControl.get('label').value || !keyConfigControl.get('label').value.trim()) {
          keyConfigControl.get('label').setErrors({ required: true });
        }
      }
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
    }

    return this.form.valid;
  }

  onChangeType(keyConfigControl): void {
    keyConfigControl.get('data').setValue(null);
    if (keyConfigControl.get('type').value === SipphoneKeyConfigType.Undefined) {
      keyConfigControl.get('label').setValue(null);
    }
  }

  private getKeyConfigsFormControls(keyConfigs: ISipPhoneKeyConfig[]): FormGroup[] {
    const ret: FormGroup[] = [];
    for (const keyConfig of keyConfigs) {
      ret.push(this.createKeyConfig(keyConfig));
    }

    return ret;
  }

  private createKeyConfig(keyConfig: ISipPhoneKeyConfig): FormGroup {
    let data = keyConfig.data;
    if (keyConfig.type === SipphoneKeyConfigType.Supervision && data) {
      data = this.em.getRepository('SipPhoneRepository').getObjectById(data);
    }

    return this.fb.group({
      type: [keyConfig.type],
      data: [data],
      label: [keyConfig.label]
    });
  }

  get keyConfigsControl(): FormArray {
    return this.form && this.form.get('key_config') as FormArray;
  }

}
