import { ILogger, IJsPhoneDriver, IJsPhoneLib } from './jsphonelib-types';
import { SipAccountManager } from './sip-account-manager';
import { setLogger, getLogger } from './logger';

export class JsPhoneLib implements IJsPhoneLib {
    driver: IJsPhoneDriver;
    accountManager: SipAccountManager = new SipAccountManager(this);

    setLogger(log: ILogger): void {
        setLogger(log);
    }

    getLogger(): ILogger {
        return getLogger();
    }

    setDriver(driver: IJsPhoneDriver): void {
        this.driver = driver;
    }

    getDriver(): IJsPhoneDriver {
        return this.driver;
    }
}
