import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class RoutingAppUserSecondaryNumber extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.user_secondary_number;
  private user: IUserEntity;

  setUser(user: IUserEntity): void {
    this.setAppParams({ user_id: user.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.user_id) {
      this.user = EntityManager.getRepository('UserRepository').getObjectById(this.appParams.user_id);
    }
  }

  getRelatedObject(): IUserEntity {
    return this.user;
  }

  getDescription(): string {
    return `To user's secondary number ${this.user && this.user.phone || ''}`;
  }
}
