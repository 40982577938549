import { NgModule } from '@angular/core';
import { HttpClientModule, WephoneUtilsModule } from '@wephone-utils';
import { DidOrderFrComponent } from './fr/did-order-fr/did-order-fr.component';
import { DidOrderService } from './services/did-order.service';
import { BrowserModule } from '@angular/platform-browser';
import { DidOrderGlobalComponent } from './did-order-global/did-order-global.component';
import { WephoneCommonModule } from '@wephone-common';

@NgModule({
  declarations: [ DidOrderFrComponent, DidOrderGlobalComponent ],
  imports: [
    BrowserModule,
    HttpClientModule,
    WephoneUtilsModule,
    WephoneCommonModule,
  ],
  exports: [ DidOrderFrComponent, DidOrderGlobalComponent ],
  entryComponents: [ DidOrderFrComponent, DidOrderGlobalComponent ],
  providers: [ DidOrderService ]
})
export class DidOrderModule {}
