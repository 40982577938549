import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { timeout, map, catchError, finalize } from 'rxjs/operators';
import * as md5 from 'md5';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { FlexProgressBarService, UIBlockerService } from '@wephone-utils';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { StaticServiceLocator } from './static_service_locator';
import * as _ from 'lodash';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  static _instance: HttpRequestInterceptor;

  constructor(
    private authService: AuthenticationService,
    private uiBlocker: UIBlockerService,
    private progressBarService: FlexProgressBarService
  ) {
    if (!authService) {
      throw new Error('Error: authService is required to create HttpRequestInterceptor');
    }
    if (HttpRequestInterceptor._instance) {
      throw new Error('Error: Instantiation failed: Use AuthenticationService.getInstance() instead of new.');
    }
    HttpRequestInterceptor._instance = this;
    this.uiBlocker = StaticServiceLocator.injector.get(UIBlockerService);
  }

  static getInstance(): HttpRequestInterceptor {
    return HttpRequestInterceptor._instance;
  }

  intercept = (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
    const domain_hash = this.authService.getEnterpriseDomain() ? md5(this.authService.getEnterpriseDomain()).toUpperCase() : '';
    let unblockRequired = false;
    let timeoutValue = 60000;
    if (req.headers.get('timeout')) {
      timeoutValue = Number(req.headers.get('timeout')) || timeoutValue;
      req.headers.delete('timeout');
    }

    let showProgressBar = true;
    if (req.headers.get('hide_progression')) {
      showProgressBar = false;
      req.headers.delete('hide_progression');
    }

    let headers = req.headers.set('X-SNAME', 'SNAME_' + domain_hash);
    if (this.authService.getAuthToken()) {
      headers = headers.set('X-PHPSESSID', this.authService.getAuthToken());
    }
    if (this.authService.getXSRFToken()) {
      headers = headers.set('X-XSRF-TOKEN', this.authService.getXSRFToken());
    }
    const dupReq = req.clone({ headers });

    if (showProgressBar) {
      this.progressBarService.startPendingAction();
    }

    try {
      return next
        .handle(dupReq)
        .pipe(
          timeout(timeoutValue),
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && this.is_api_url(req.url)) {
              const data = event.body;
              if (!data) {
                return event;
              }
              if (data.status && data.status === 'success') {

                const entity_manager = EntityManager.getInstance();
                if (!entity_manager) {
                  console.error('Cannot Not Get EntityManager');
                } else {
                  if (data.__version__ === 2) {
                    const metadata = data.__meta__;
                    if (metadata && (!_.isEmpty(metadata._updated_) || !_.isEmpty(metadata._deleted_))) {
                      EntityManager.getInstance().onWebserviceResponse(metadata._updated_, metadata._deleted_);
                    }
                  } else {
                    EntityManager.getInstance().onWebserviceResponse(data.updated_objects, data.deleted_objects);
                  }
                }

                if (data._format === 'entity') {
                  const object_id = data.answer['object_id'];
                  const object_type = data.answer['object_type'];

                  const repo = EntityManager.getRepositoryById(object_type);
                  const obj = repo.getObjectById(object_id);
                  /*
                            if (event.config.file) {
                                // A HACK here to make it work with angular UPLOAD service
                                //This service expect response object to be a http response (with data and status attributes)
                                return {'data': obj, 'status': '200'}
                            }
                            */
                  return event.clone({ body: obj });
                }

                if (!event.body.hasOwnProperty('answer')) {
                  return event.clone({ body: null });

                }
                return event.clone({ body: data.answer });
              }

              throw data.error || data;
            }

            return event;
          })
        )
        .pipe(
          catchError(
            err => {
              console.error('Http intercept error', err);
              if (err instanceof HttpErrorResponse) {
                if (err.status === 403 || err.status === 401) {
                  console.error('Got authentication error with last request');
                  if (err.url && err.url.indexOf('user/ping') > -1 && !this.authService.isReloadingSession) {
                    this.authService.reloadSession();
                  }

                  throw new Error('Interceptor: HTTP request with invalid credentials');
                }
                if (this.is_api_url(req.url) && err.error) {
                  throw err.error;
                }
              }

              return throwError(err);
          }),
        )
        .pipe(
          finalize(
            () => {
              if (req.method === 'POST' && unblockRequired) {
                this.uiBlocker.unblock();
                unblockRequired = false;
              }
              if (showProgressBar) {
                this.progressBarService.stopPendingAction();
              }
            }
          )
        );
    } catch (e) {
      console.error('Http error in interceptor', e);
      if (unblockRequired) {
        this.uiBlocker.unblock();
        unblockRequired = false;
      }
      if (showProgressBar) {
        this.progressBarService.stopPendingAction();
      }
    }
  }

  private is_api_url(url: string): boolean {
    const settings: FlexIvrSettings = FlexIvrSettings.getInstance();
    if (url.indexOf(settings.getApiUrl()) === 0 || url.indexOf(settings.getAbsoluteApiUrlv2()) === 0) {
      return true;
    }

    const prefixe_list = ['/noauth/', '/login_check', '/signin', '/signup'];
    for (const prefix of prefixe_list) {
      if (url.indexOf(settings.getAbsoluteUrl(prefix)) === 0 || url.indexOf(settings.getAbsoluteWSv2URL(prefix)) === 0) {
        return true;
      }
    }

    return false;
  }
}
