import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class CallQueueItemRepository extends BaseRepository {
  ENTITY_CLASS = CallQueueItemEntity;
  protected URL_PREFIX = 'callcenter/queue';
  protected METHOD_FORCE_AGENT = 'force_agent_for_call';
  protected METHOD_REMOVE_FORCE = 'remove_force_for_call';
  protected USE_PUSH_API = true;

  forceAgentForCall(call_queue_item_id: number, agent: AgentEntity, phone_number: string): Promise<any> {
    const url = this.getUrlV2(this.METHOD_FORCE_AGENT);
    return HttpEngine.getInstance().post(url, {
      queue_item_id: call_queue_item_id,
      agent_id: agent.id,
      agent_phone: phone_number
    });
  }

  removeForceForCall(call_queue_item_id: number): Promise<any> {
    const url = this.getUrlV2(this.METHOD_REMOVE_FORCE);
    return HttpEngine.getInstance().post(url, { queue_item_id: call_queue_item_id });
  }

  getObjectListByQueueId(callqueue_id: number): Array<CallQueueItemEntity> {
    const ret = [];
    for (const qi of this.getObjectList()) {
      if (qi.call_queue_keyid == callqueue_id) {
        ret.push(qi);
      }
    }
    return ret;
  }

  getHandledCalls(agent_id: number = null): Array<CallQueueItemEntity> {
    const ret: Array<CallQueueItemEntity> = [];
    for (const qi of this.getObjectList()) {
      if (qi.bridged_dt) {
        if (agent_id != null && agent_id != qi.forced_operator_id) {
          continue;
        }
        ret.push(qi);
      }
    }
    return ret;
  }

  getInWaitingCalls(): Array<CallQueueItemEntity> {
    const ret: Array<CallQueueItemEntity> = [];
    for (const qi of this.getObjectList()) {
      if (!qi.bridged_dt) {
        ret.push(qi);
      }
    }
    return ret;
  }
}

import { CallQueueItemEntity } from '@wephone-core/model/entity/callqueueitem';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { CommonAPI } from '@wephone-core/service/common_api';
import { HttpEngine } from '@wephone-core/service/http_engine';
