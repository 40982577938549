import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { IIvrScriptRepository } from '@wephone-core/model/repository/ivrscript.i';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { IEntity } from '../model.interface';
import * as _ from 'lodash';
import { HttpEngine } from '@wephone-core/service/http_engine';

export class IvrScriptRepository extends BaseRepository implements IIvrScriptRepository {
  ENTITY_CLASS = IvrScriptEntity;
  protected URL_PREFIX = 'ivrscript';
  protected USE_PUSH_API = true;

  getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }
  
  saveAttrs(item: IEntity, attr_list: string[], extra_data?: { [id: string]: any }): Promise<any> {
    if (!(attr_list instanceof Array)) {
      throw new Error('Attribute \'attr_list\' must be an array');
    }
    const action: string = item.getId() ? 'UPDATE_ATTRS' : 'ADD_ATTRS';
    const url = this.getURLAction(action);
    let item_data = item.dumpObjectData(attr_list, false); // Not use mapped name
    if (extra_data) {
      item_data = _.extend(item_data, extra_data);
    }
  
    const post_data = {};
    for (const attr of attr_list) {
      post_data[attr] = item_data[attr];
    }
    if (item_data.id) {
      post_data['id'] = parseInt(item_data.id);
    }
    post_data['__field_list__'] = attr_list;
  
    return HttpEngine.getInstance().postJson(url, post_data);
  }

  save(item: IEntity, extra_data?: { [id: string]: any }, attr_group?: string): Promise<any> {
    const action: string = item.getId() ? 'UPDATE' : 'ADD';
    const url = this.getURLAction(action);
    const post_data = item.dumpObjectData(undefined, false); // Not use mapped name
    if (extra_data) {
      _.extend(post_data, extra_data);
    }
    const field_list = [];
    for (const k of Object.keys(post_data)) {
      if (k !== 'eid') {
        field_list.push(k);
      }
    }
    post_data['__field_list__'] = field_list;
    return HttpEngine.getInstance().postJson(url, post_data);
  }
}
