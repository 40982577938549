import { datafield, Entity } from '@wephone-core/model/model';

export class UserParametersEntity extends Entity {
  static object_type_id = 'user_parameters';

  @datafield
  id: number;
  @datafield
  enterprise_id: number;
  @datafield
  user_id: number;
  @datafield
  open_bo_on_call: number;
  @datafield
  iframe_bo_position: number;
}
