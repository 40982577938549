import { ISipAccount, ISipAccountManager, IJsPhoneLib } from './jsphonelib-types';
import * as logger from './logger';

export class SipAccountManager implements ISipAccountManager {
  private accountList: Array<ISipAccount>;
  private offlineMode = false;
  private offlineModeTimer: any;
  private lastIpList: Array<string>;

  constructor(private jsphonelib: IJsPhoneLib) {
      this.accountList = new Array<ISipAccount>();
  }

  get defaultAccount(): ISipAccount {
      for (const acc of this.accountList) {
          if (acc.is_default) {
              return acc;
          }
      }
  }

  defaultAccountConnected(): boolean {
      const acc = this.defaultAccount;
      return !!(acc && acc.isRegistered());
  }

  getAccountByName(name: string): ISipAccount {
      for (const acc of this.accountList) {
          if (acc.name === name) {
              return acc;
          }
      }
  }

  clearAccounts(): void {
      for (let i = 0; i < this.accountList.length; i++) {
          const acc = this.accountList[i];
          acc.is_default = false;
          // acc.setRegistration(false);
          acc.destroy();
      }
      this.accountList = [];
  }

  removeAccountByName(name: string): void {
    for (let i = 0; i < this.accountList.length; i++) {
        const acc = this.accountList[i];
        if (acc.name === name) {
            acc.is_default = false;
            this.accountList.splice(i, 1);
            acc.destroy();
            return;
        }
    }
  }

  setDefault(account: ISipAccount) {
      let found = false;
      for (const a of this.accountList) {
          if (a === account) {
              a.is_default = true;
              found = true;
          }
      }
      if (found) {
          for (const a of this.accountList) {
              if (a !== account) {
                  a.is_default = false;
              }
          }
      }
      return found;
  }

  addAccount(acc: ISipAccount, is_default: boolean = false): void {
      this.accountList.push(acc);
      if (is_default || this.accountList.length === 1) {
        this.setDefault(acc);
      }
  }

  setOfflineMode(offline: boolean): void {
    this.offlineMode = offline;
    if (!this.offlineModeTimer) {
        this.offlineModeTimer =  setTimeout(
            () => {
                this.offlineModeTimer = undefined;

                if (!this.offlineMode) {
                    // This function will call pjsip's handleIpChange function to restart listening on IPs
                    try {
                        this.jsphonelib.getDriver().handleIpChange();
                    } catch (e) {
                        logger.error(e.message);
                    }

                    // Another way of making ReRegister is to call setRegistration(true) on each account
                    /*
                    for (let i = 0; i < this.accountList.length; i++) {
                        const acc = this.accountList[i];
                        acc.setRegistration(true);
                    }
                    */
                }
            },
            3000
        );
    }
  }
}
