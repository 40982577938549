import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ShortDialCodeRepository } from '@wephone-core/model/repository/short_dial_code';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  DynamicFilterSource,
  DialogService,
  ToastService,
  IFlexTableSidePannelOptions,
  IFlexTableConfig,
  FlexCRUDPageComponent,
  regexSearch,
} from '@wephone-utils';
// import { TranslateService } from '@ngx-translate/core';
import { ShortDialCodeEntity } from '@wephone-core/model/entity/short_dial_code';
import { CreateShortDialCodeModalComponent } from '@wephone/modals/create-short-dial-code-modal/create-short-dial-code-modal.component';
import { ShortDialCodeEditComponent } from '@wephone/pages/short-dial-code/short-dial-code-edit/short-dial-code-edit.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-short-dial-code-list',
  templateUrl: './short-dial-code-list.component.html',
  styleUrls: ['./short-dial-code-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortDialCodeListComponent extends FlexBasePage implements OnInit {
  private readonly shortDialRepo = ShortDialCodeRepository.getInstance<ShortDialCodeRepository>();

  form: FormGroup;
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: ShortDialCodeEditComponent
  };
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly fb: FormBuilder,
    // private readonly trans: TranslateService,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly toast: ToastService,
  ) {
    super();

    const shortDialSource = this.shortDialRepo.dataSource<ShortDialCodeEntity>();
    this.dataSource = new DynamicFilterSource(shortDialSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'dial_code',
          label: _tk('shortdialcode.content.dial_code'),
          sortable: true
        },
        {
          name: 'match_prefix',
          label: _tk('shortdialcode.content.match_prefix'),
          sortable: true
        },
        {
          name: 'routing_data',
          label: _tk('shortdialcode.content.routing_data')
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initDS();
  }

  initDS(): void {
    this.form = this.fb.group({
      filterValue: ['']
    });

    this.form.valueChanges.subscribe(this.onFormValueChange);
  }

  private readonly filterFunc = (item: ShortDialCodeEntity): boolean => {
    if (!this.filterValue) {
      return true;
    }
    return regexSearch(item.dial_code, this.filterValue);
  }

  private readonly onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  protected resolveData(): Promise<any> {
    return this.shortDialRepo.loadObjectList();
  }

  renderApplicationNameCol(item: ShortDialCodeEntity): any {
    if (!item.application_name) {
      return _ti('did.content.not_config');
    }

    let app_param = '';
    const application_name = _ti(item.application_name);

    const app_param_value = [];
    if (item.tooltip) {
      for (let i = 0; i < item.tooltip.length; i++) {
        const paramName = item.tooltip[i].param_name ? _ti(item.tooltip[i].param_name) : '';
        const paramValue = item.tooltip[i].param_value;
        app_param += '{0}: {1}<br />'
          .replace(/{(0)}/g, paramName)
          .replace(/{(1)}/g, paramValue);

        app_param_value.push(item.tooltip[i].param_value);
      }
    }
    const tooltip = _ti('did.content.ivr_tooltip_v2',
      JSON.stringify({ app_name: application_name, params: app_param }));

    if (item.routed_fileentry) {
      return '<div tooltip-placement="bottom" tooltip-html-unsafe="{0}">{1}</div>'
        .replace(/{(0)}/g, tooltip)
        .replace(/{(1)}/g, application_name);
    }

    return '<div tooltip-placement="bottom" tooltip-html-unsafe="{0}">{1}: {2}</div>'
      .replace(/{(0)}/g, tooltip)
      .replace(/{(1)}/g, application_name)
      .replace(/{(2)}/g, app_param_value.join(','));
  }

  bulkDelete(items: ShortDialCodeEntity[]): void {
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        if (!_.isEmpty(items)) {
          try {
            await this.shortDialRepo.bulkDelete(items);
            this.toast.show(_ti('public.message.delete_success'));
            this.flexCrud.closeSidePanel();
          } catch (e) {
            this.toast.showErrorMessage(e, _ti('public.message.delete_failure'));
          }
        }
      }
    );
  }

  async createNew(): Promise<void> {
    const dialogRef = this.dialogService.openDialog2(CreateShortDialCodeModalComponent);
    const newEntity = await dialogRef.afterClosed().toPromise();
    if (!newEntity) {
      return;
    }
    this.gotoEditTagPage(newEntity.id);
  }

  private gotoEditTagPage(id: number): void {
    this.router.navigateByUrl(`telephony/short-dial-code/${id}`);
  }
}
