import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { ElectronService } from 'ngx-electron';
import { SingletonBase } from '../utils/singleton';
import { Platform } from './platform.service';
import { Deferred } from 'ts-deferred';

@Injectable()
export class CookieService extends SingletonBase {
  app_root_url: string = 'https://admin.graam.com';

  private electronSession;

  constructor(private ngxCookie: NgxCookieService, private electron: ElectronService) { // , private platform: Platform
    super();
    if (this.electron.isElectronApp) {
      this.electronSession = window['EREMOTE'].session.fromPartition('persist:name');
    }
  }

  del(name: string): any {
    if (this.electron.isElectronApp) {
      return this.electronSession.cookies.remove(this.app_root_url, name).catch(
        error => {
          console.error(`Error when trying to remove cookie ${name} from domain ${this.app_root_url}`, error);
          throw error;
        }
      );
    }
    return this.ngxCookie.delete(name, '/');
  }

  set(name: string, value: string): any {
    if (this.electron.isElectronApp) {
      if (!this.app_root_url) {
        throw new Error('CookieService.app_root_url must be defined for Electron app\'s set cookie funciton to work');
      }
      return this.electronSession.cookies.set(
        {
          url: this.app_root_url,
          name,
          value
        }).catch(
        error => {
          console.error('Set cookie error', error);
          throw error;
        }
      );
    }
    // if (this.platform.isDesktop()) {
    return this.ngxCookie.set(name, value, undefined, '/');
    // }
  }

  get(name: string): Promise<any> {
    const ret: Deferred<any> = new Deferred();
    if (this.electron.isElectronApp) {
      if (!this.app_root_url) {
        ret.reject('CookieService.app_root_url must be defined for Electron app\'s get cookie funciton to work');
        // throw new Error('CookieService.app_root_url must be defined for Electron app\'s get cookie funciton to work');
      }
      this.electronSession.cookies.get(
        {
          name,
          url: this.app_root_url
        })
        .then(cookies => {
          ret.resolve((cookies && cookies.length) ? cookies[0].value : undefined);
        })
        .catch(error => {
          console.error('Cannot set electron cookie', error);
          ret.reject('Cannot set electron cookie');
        }
      );
    }
    else { // if (this.platform.isDesktop()) {
      ret.resolve(this.ngxCookie.get(name));
    }
    return ret.promise;
  }

}
