import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class SipTrunkRepository extends BaseRepository {
  ENTITY_CLASS = SipTrunkEntity;
  protected URL_PREFIX = 'sip-trunk';

  protected getUrl(suffix): string {
    return this.getUrlV2(suffix);
  }
}

import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
