import * as _ from 'lodash';

import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class InvoiceRepository extends BaseRepository {
  static URL_FIND_BY_ID = 'api/invoice/find_by_id/{invoice_id}';
  static URL_MAKE_PDF = 'api/invoice/make_pdf/{invoice_id}';

  ENTITY_CLASS = InvoiceEntity;
  protected URL_PREFIX = 'invoice';
  private URL_GET_INVOICES_LIST = 'list';
  private last_filters: any = null;

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  async findOneById(invoice_id: number): Promise<InvoiceEntity> {
    const url = InvoiceRepository.URL_FIND_BY_ID.replace('{invoice_id}', invoice_id.toString());
    await HttpEngine.getInstance().get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL(url));

    const item: InvoiceEntity = this.getObjectById(invoice_id);
    return item;
  }

  async makePdf(invoice_id: number): Promise<InvoiceEntity> {
    const url = InvoiceRepository.URL_MAKE_PDF.replace('{invoice_id}', invoice_id.toString());
    await HttpEngine.getInstance().post(FlexIvrSettings.getInstance().getAbsoluteWSv2URL(url), undefined, undefined, false, 30000);

    const item: InvoiceEntity = this.getObjectById(invoice_id);
    return item;
  }

  protected getRemoteData(use_cache = true, filtersRq: any = 'all'): Promise<any> {
    let filters = filtersRq;
    if (!filters) {
      filters = 'all';
    }
    const url = this.getUrl(this.URL_GET_INVOICES_LIST);
    if (use_cache && (this.object_list || []).length && this.last_filters === filters) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance().get(url, { period: filters }).then((updated_list: any) => {
      return this.getObjectList();
    });
  }
}

import { InvoiceEntity } from '@wephone-core/model/entity/invoice';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
