import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import * as _ from 'lodash';
import { LocationEntity } from '../entity/location';

export class LocationRepository extends BaseRepository {
  ENTITY_CLASS = LocationEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'location';

}
