import { Injectable } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { AccessRight, BusinessType } from '@wephone-core/system';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface BaseMenuItem {
  state: string;
  name: string;
  roles?: String[];
  type?: string;
  businessTypes?: BusinessType[];
  subPermissions?: { state?: string, permission: AccessRight }[];
  features?: string[];
}

export interface MainMenuItem extends BaseMenuItem {
  icon?: string;
  accessPermission?: AccessRight;
  badge?: BadgeItem[];
  children?: MainMenuItem[];
}

@Injectable()
export class MenuService {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly configManager: ConfigManager
  ) { }

  private hasFeature(menu: BaseMenuItem): boolean {
    if (menu.features) {
      for (const feature of menu.features) {
        if (!this.configManager.hasFeature(feature)) {
          return false;
        }
      }
    }

    return true;
  }

  showMenuItem(menu: BaseMenuItem): boolean {
    if (!this.hasFeature(menu)) {
      return false;
    }

    const roleOK = !menu.roles || menu.roles.includes(this.authService.getUserRole());
    const btOK = !menu.businessTypes || menu.businessTypes.includes(this.authService.getBusinessType());
    const smOk = this.hasSubMenu(menu);

    return roleOK && btOK && smOk;
  }

  hasAccessRight(rule: AccessRight, state: string = null): boolean {
    if (!rule) {
      return true;
    }

    return this.authService.hasAccessRight(rule, state);
  }

  hasSubMenu(menu: BaseMenuItem): boolean {
    if (!menu.subPermissions || menu.type !== 'sub') {
      return true;
    }

    return !!menu.subPermissions.find(sp => this.authService.hasAccessRight(sp.permission, sp.state));
  }
}
