import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService, DialogActionButton, Colors, regexSearch } from '@wephone-utils';
import {
  ConfirmAgentEligibleComponent,
} from '@wephone/modals/agent/confirm-agent-eligible/confirm-agent-eligible.component';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import * as _ from 'lodash';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { TeamGroupRepository } from '@wephone-core/model/repository/teamgroup';

export class AddAgentToQueueInputData {
  queue_id: number;
  group?: UserGroupEntity;
  queue_agent_list?: CallQueueAgentLinkEntity[];
  exclude_list?: CallQueueAgentLinkEntity[];
  is_exclusive?: boolean;
  on_selected: any;
}

@Component({
  selector: 'app-add-agent-to-queue',
  templateUrl: './add-agent-to-queue.component.html',
  styleUrls: ['./add-agent-to-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddAgentToQueueComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('call_queue.content.operators.available');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.button.add'),
      action: () => {
        this.addSelectedAgents();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    },
  ];

  queue: CallQueueEntity;
  queue_agent_list: CallQueueAgentLinkEntity[] = [];
  queue_agent_available_list: CallQueueAgentLinkEntity[] = [];
  exclude_list: CallQueueAgentLinkEntity[] = [];
  on_selected: any;

  filters: {
    group?: UserGroupEntity;
    teamgroup?: TeamGroupEntity;
    search_text?: string;
    show_eligible?: 0 | 1;
  } = {};
  isServiceGroup: boolean;

  selectedAgentIds: number[] = [];
  selectedItems: CallQueueAgentLinkEntity[] = [];
  selectedAll: boolean;
  hasTeam: boolean;

  constructor(
    dialogRef: MatDialogRef<AddAgentToQueueComponent>,
    private readonly configManager: ConfigManager,
    private readonly dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: AddAgentToQueueInputData,
    cdr?: ChangeDetectorRef,
  ) {
    super(dialogRef, cdr);
    this.isServiceGroup = this.configManager.hasFeature('SERVICE_GROUP');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.queue_agent_list = this.data.queue_agent_list || [];
    this.exclude_list = this.data.exclude_list || [];
    this.on_selected = this.data.on_selected;
    this.filters.group = this.data.group;
    this.filters.show_eligible = 0; // show all
    const queueId: number = this.data.queue_id;

    this.queue = CallQueueRepository.getInstance().getObjectById(queueId);

    const agent_list = this.queue.available_agents.filter(x => x.can_use_callcenter);

    // const agent_list = AgentRepository.getInstance<AgentRepository>().getCCStaffList();
    for (const agent of agent_list) {
      // TODO: @tvo Optimize this, use  agent_list instead of queue_agent_available_list for template
      const queue_agent_link = new CallQueueAgentLinkEntity(queueId, agent.id);
      this.queue_agent_available_list.push(queue_agent_link);
    }

    
    this.hasTeam = !!TeamGroupRepository.getInstance<TeamGroupRepository>().getObjectList().filter(t => t.hasUser()).length;
  }

  isExistInList = (item: CallQueueAgentLinkEntity, list): boolean => {
    return _.find(list, (i: CallQueueAgentLinkEntity) => i.agent_id === item.agent_id) ? true : false;
  }

  filterItem = (item: CallQueueAgentLinkEntity): boolean => {
    if (this.isExistInList(item, this.queue_agent_list) || this.isExistInList(item, this.exclude_list)) {
      return false;
    }

    const agent = item.agent;
    if (!agent || !agent.user || !agent.user.enabled) {
      return false;
    }

    if (
      this.filters.teamgroup &&
      (!item.agent.user.team || (item.agent.user.team && item.agent.user.team_id !== this.filters.teamgroup.id))
    ) {
      return false;
    }

    if (this.filters.search_text) {
      const filterValue: string = this.filters.search_text.trim();
      if (!regexSearch(item.agent.name || '', filterValue)) {
        return false;
      }
    }

    if (this.filters.show_eligible && !item.agent.isFreeFromInboundQueue(this.data.is_exclusive || false)) {
      return false;
    }

    return true;
  }

  get filteredItems(): CallQueueAgentLinkEntity[] {
    return this.queue_agent_available_list.filter((item: CallQueueAgentLinkEntity) => {
      return this.filterItem(item) && this.selectedAgentIds.indexOf(item.agent_id) === -1 ? true : false;
    }); // see filter in template
  }

  hasFilterData(): boolean {
    return (this.filteredItems || []).length > 0;
  }

  addAllDisplayedAgents(): void {
    // get all displayed
    const all_displayed = this.filteredItems;
    if ((all_displayed || []).length === 0) {
      console.warn('Agent filtered not found');

      return;
    }
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation', {}),
      _ti('components.queue_agent_list.confirm_add_queue_agents', {
        nb_agent: (all_displayed || []).length
      }),
      () => {
        this.addToQueue(all_displayed);
      }
    );
  }

  selectAll(): void {
    if ((this.filteredItems || []).length === 0) {
      console.warn('Agent filtered not found');
      return;
    }

    this.selectedAll = !this.selectedAll;

    if (this.selectedAll) {
      this.selectedItems = this.filteredItems;
    } else {
      this.selectedItems.splice(0);
    }
  }

  selectQueueAgent(item: CallQueueAgentLinkEntity): void {
    if (!this.hasSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.unselectQueueAgent(item);
    }
  }

  unselectQueueAgent(item: CallQueueAgentLinkEntity): void {
    const indexItem = this.selectedItems.findIndex(i => i.agent_id === item.agent_id);
    this.selectedItems.splice(indexItem, 1);
  }

  addToQueue(queue_agent_links: CallQueueAgentLinkEntity[]): void {
    const selected_agent_links: CallQueueAgentLinkEntity[] = [];

    // if add for outbound queue
    if (this.queue.is_outbound_campaign) {
      for (const queue_agent_link of queue_agent_links) {
        selected_agent_links.push(queue_agent_link);
      }
      this.onSelectedItem(selected_agent_links); // on selected

      return;
    }

    // if add for inbound queue, check the eligible
    const uneligible_agent_links = [];
    for (const queue_agent_link of queue_agent_links) {
      if (queue_agent_link.agent.isFreeFromInboundQueue(this.data.is_exclusive || false)) {
        selected_agent_links.push(queue_agent_link);
      } else {
        uneligible_agent_links.push(queue_agent_link);
      }
    }

    // if no uneligible agent
    if (!uneligible_agent_links.length) {
      this.onSelectedItem(selected_agent_links); // on selected
    } else {
      this.dialogService.openDialog2(
        ConfirmAgentEligibleComponent,
        {
          data: {
            description: this.data.is_exclusive ?
              _ti('call_queue.message.exclusive_eligible') :
              _ti('call_queue.message.unexclusive_eligible'), // queue is exclusive or not
            agent_links: uneligible_agent_links
          },
          size: 's'
        },
        (data_dialog: any) => {
          if (!data_dialog) {
            return;
          }

          const keepAgentLinks: CallQueueAgentLinkEntity[] = data_dialog.agent_links || [];
          if (keepAgentLinks.length) {
            for (const agent_link of data_dialog.agent_links) {
              selected_agent_links.push(agent_link); // Add more selected agent-link
            }
          }
          // Remove selected item if was unchecked
          for (const selectedItem of this.selectedItems) {
            if (!_.includes(keepAgentLinks.map(x => x.call_queue_keyid), selectedItem.call_queue_keyid) &&
              !_.includes(keepAgentLinks.map(x => x.operator_keyid), selectedItem.operator_keyid)
            ) {
              this.unselectQueueAgent(selectedItem);
            }
          }
          this.onSelectedItem(selected_agent_links); // on selected
        }
      );
    }
  }

  hasSelected(item: CallQueueAgentLinkEntity): boolean {
    for (const selectedItem of this.selectedItems) {
      if (selectedItem.agent_id === item.agent_id) {
        return true;
      }
    }
    return false;
  }

  addSelectedAgents(): void {
    this.addToQueue(this.selectedItems);
  }

  onSelectedItem(queueAgents: CallQueueAgentLinkEntity[]): void {
    for (const i of queueAgents) {
      this.selectedAgentIds.push(i.agent_id);
    }

    if (this.on_selected) {
      this.on_selected(queueAgents);
    }
  }
}
