import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    // SetTimeout to fix error Expression has changed after it was checked in incoming-stats page
    setTimeout(() => {
      this.ngControl.control[action]({emitEvent: false});
    });
  }

  constructor(private ngControl: NgControl) {
  }

}
