import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class RoutingAppRemoteApp extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.remote_application;
  private remoteApp: IvrRemoteAppEntity;

  setRemoveApp(app: IvrRemoteAppEntity): void {
    this.setAppParams({ id: app.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.id) {
      this.remoteApp = EntityManager.getRepository('IvrRemoteAppRepository').getObjectById(this.appParams.id);
    }
  }

  getRelatedObject(): IvrRemoteAppEntity {
    return this.remoteApp;
  }

  getDescription(): string {
    const remote_app_name = this.remoteApp ? this.remoteApp.name : '<invalid>';

    return `To remote application ${remote_app_name}`;
  }
}
