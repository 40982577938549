<div class="mini-block">
    <div class="flag">
        <span class="flag-icon maticon"><mat-icon>fact_check</mat-icon></span>
    </div>
    <div class="number-call">
        <span class="name font-weight-bold" matTooltip="{{ ivrEvaluation.name }}" matTooltipPosition="above">{{ ivrEvaluation.name | truncate:18 }}&nbsp;</span>
        <span class="number" *ngIf="ivrEvaluation.welcome_msg">
            <fileentry-player-button [filePublicId]="ivrEvaluation.welcome_msg.public_id" playerId="main"
                [disabled]="!ivrEvaluation.welcome_msg.ready_for_playing"></fileentry-player-button>
            <span>{{ ivrEvaluation.welcome_msg.name | truncate:14 }}</span>
        </span>
    </div>
</div>
