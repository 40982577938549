import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DidEntity } from '@wephone-core/model/entity/did';
import * as _ from 'lodash';
import { ConfigManager, EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { ConferenceRepository } from '@wephone-core/model/repository/conference';
import { DidRepository } from '@wephone-core/model/repository/did';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { RoutingAppManager } from '@wephone-core/routing-app/routing-app-manager';
import { RoutingAppName, IRoutingApp, IRoutingAppDestinationData } from '@wephone-core/routing-app/routing-app.interface';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { IRouteToData } from '@wephone/components/did/did-route-to-data.i';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { ValidatorService } from '@wephone-utils/services/validator.service';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
import { FeatureName } from '@wephone-core/system';

export interface IDidConfigWizardReturnData {
  masterDid?: DidEntity;
  app?: IRoutingApp;
}

@Component({
  selector: 'did-config-wizard-modal',
  templateUrl: './did-config-wizard-modal.component.html',
  styleUrls: ['./did-config-wizard-modal.component.scss']
})
export class DidConfigWizardModalComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('did.title.config_wizard');
  backButton: DialogActionButton =
    {
      action: () => {
        this.gotoStep(1);
      },
      visible: () => {
        return this.stepActive !== 0;
      },
    };
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('dialogs.did_setup_wizard.next'),
      action: () => {
        this.routingTo();
      },
      visible: () => {
        return this.stepActive === 0;
      },
      color: Colors.PRIMARY
    },
    {
      label: 'dialogs.did_setup_wizard.step4.click_to_finish',
      action: () => {
        this.updateRouting();
      },
      visible: () => {
        return this.stepActive !== 0;
      },
      color: Colors.PRIMARY
    }
  ];

  did: DidEntity;
  dependentCalendar: OpeningHourCalendarEntity;
  routingForCrm: boolean; // Is configured for routing to CRM: hide crm-routing & master-did
  dedicatedDidIds: number[] = [];
  ivrScripts: IvrScriptEntity[] = [];
  sipPhones: SipPhoneEntity[] = [];
  ivrMenus: IvrCustomMenuEntity[] = [];
  crmRoutingRules: CrmRoutingRuleEntity[] = [];
  remoteApplications: IvrRemoteAppEntity[] = [];
  sipTrunks: SipTrunkEntity[] = [];

  steps: any;
  stepActive = 0;
  finish = false;

  routeTo: IRouteToData;
  message: string;

  mapDestinationParamName = {
    call_phone_number: 'phone_number',
    call_phone: 'sipphone',
    call_user: 'user',
    callqueue: 'queue',
    ivr_custom_menu: 'ivrmenu',
    conference: 'conference',
    runvxmlscript: 'ivrscript',
    remote_application: 'remote_application',
    play_then_hangup: 'file_entry',
    webservice: 'url',
    crm_route: 'crm_routing_rule',
    master_did: 'master_did',
    siptrunk: 'siptrunk'
  };

  phoneNumberRegex = /^[0-9]{4,20}$/;
  isEnabledSipTrunk = false;

  constructor(
    dialogRef: MatDialogRef<DidConfigWizardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      full_option: boolean;
      did?: DidEntity;
      call_destination?: IRoutingAppDestinationData;
      is_out_of_office_hours?: boolean;
      save_did?: boolean;
      routing_for_crm?: boolean;
      dependent_calendar?: OpeningHourCalendarEntity;
    },
    private em: EntityManager,
    private settings: FlexIvrSettings,
    cdr: ChangeDetectorRef,
    configManager: ConfigManager
  ) {
    super(dialogRef, cdr);
    this.isEnabledSipTrunk = configManager.hasFeature(FeatureName.SIP_TRUNK);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.did = this.em.getRepository<DidRepository>('DidRepository').create() as DidEntity;
    this.did.action_office_hour = (this.data && this.data.call_destination || {}) as IRoutingAppDestinationData;

    if (this.data && this.data.did) {
      this.did = this.data.did;
    }

    this.dedicatedDidIds = (this.em.getRepository<DidRepository>('DidRepository') as DidRepository)
      .getDidListForDedicated()
      .filter(x => x.routing_status === 1)
      .map(x => x.id);
    if (this.did.id && _.includes(this.dedicatedDidIds, this.did.id)) {
      this.dedicatedDidIds.splice(this.dedicatedDidIds.indexOf(this.did.id), 1);
    }

    this.routingForCrm = this.data.routing_for_crm || false;
    this.dependentCalendar = this.data.dependent_calendar;

    this.steps = {
      step1: true,
      step2: this.did.hasRoutingData() ? true : false
    };

    this._initRouteData();

    this.ivrScripts = this.em.getRepository<IvrScriptRepository>('IvrScriptRepository').getObjectList();
    this.sipPhones = this.em.getRepository<SipPhoneRepository>('SipPhoneRepository').getObjectList();
    this.ivrMenus = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getMenuList();
    this.crmRoutingRules = this.em.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository').getObjectList();
    this.remoteApplications = this.em.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository').getObjectList();

    if (this.isEnabledSipTrunk) {
      this.sipTrunks = this.em.getRepository<SipTrunkRepository>('SipTrunkRepository').getObjectList();
    }
  }

  protected async resolveData(): Promise<void> {
    if (this.isEnabledSipTrunk) {
      await this.em.getRepository<SipTrunkRepository>('SipTrunkRepository').loadObjectList();
    }
  }

  get isCallCenter(): boolean {
    return this.settings.uiCallCenter();
  }

  private _initRouteData(): void {
    this.routeTo = {
      destination: undefined,
      create_new: 0
    };
    if (this.did.master_did) {
      this.routeTo.destination = 'master_did';
      this.routeTo.master_did = this.did.master_did;
    } else if (this.did.hasRoutingData()) {
      switch (this.did.action_office_hour.application) {
        case RoutingAppName.call_phone_number:
          this.routeTo.destination = 'call_phone_number';
          this.routeTo.phone_number = this.did.routed_phone_number;
          break;
        case RoutingAppName.call_phone:
          this.routeTo.destination = 'call_phone';
          this.routeTo.sipphone = this.did.routed_sipphone as any;
          break;
        case RoutingAppName.callqueue:
          this.routeTo.destination = 'callqueue';
          this.routeTo.queue = this.did.routed_queue;
          break;
        case RoutingAppName.ivr_custom_menu:
          this.routeTo.destination = 'ivr_custom_menu';
          this.routeTo.ivrmenu = this.did.routed_menu;
          break;
        case RoutingAppName.conference:
          this.routeTo.destination = 'conference';
          this.routeTo.conference = this.did.routed_conference;
          break;
        case RoutingAppName.call_user:
          this.routeTo.destination = 'call_user';
          this.routeTo.user = this.did.routed_user;
          break;
        case RoutingAppName.runvxmlscript:
          this.routeTo.destination = 'runvxmlscript';
          this.routeTo.ivrscript = this.did.routed_application;

          // const script_params = this.did.action_office_hour.params
          //   ? this.did.action_office_hour.params.script_params
          //   : undefined;
          break;
        case RoutingAppName.play_then_hangup:
          this.routeTo.destination = 'play_then_hangup';
          this.routeTo.file_entry = this.did.routed_fileentry;
          break;
        case RoutingAppName.webservice:
          this.routeTo.destination = 'webservice';
          this.routeTo.url = this.did.routed_webservice;
          break;
        case RoutingAppName.crm_route:
          this.routeTo.destination = 'crm_route';
          this.routeTo.crm_routing_rule = this.did.routed_crm_routing_rule;
          break;
        case RoutingAppName.remote_application:
          this.routeTo.destination = 'remote_application';
          this.routeTo.remote_application = this.did.routed_remote_application;
          break;
        case RoutingAppName.sip_trunk:
          this.routeTo.destination = 'sip_trunk';
          this.routeTo.siptrunk = this.did.routed_siptrunk;
          break;
        default:
          break;
      }
    }
  }

  gotoStep(step: number): void {
    const step_alias = `step_${step}`;
    this.steps[step_alias] = true;
    this.stepActive = step - 1;
    this._clearMessage();
    this.updateDialogLayout();
  }

  routingTo(): void {
    if (!this.routeTo.destination) {
      return;
    }
    this.gotoStep(2);
    this.updateDialogLayout();
  }

  private _returnValue(did: DidEntity): IDidConfigWizardReturnData { // app_name: RoutingAppName, app_params?: any
    const routingApp = did.action_office_hour;

    if (did.master_did) {
      return { masterDid: did.master_did };
    }

    if (!routingApp) {
      return;
    }

    const app_name: RoutingAppName = routingApp.application;
    const app_params: any = routingApp.params;

    const app: IRoutingApp = RoutingAppManager.getInstance().createAppInstance(app_name, app_params);

    return { app };
  }

  completed(): void {
    this.finish = true;
    this.dismiss(
      this._returnValue(this.did)
    );
  }

  hasSipPhone(): boolean {
    // return (this.em.getRepository<SipPhoneRepository>('SipPhoneRepository').getObjectList() || []).length > 0;
    return this.sipPhones.length > 0;
  }

  hasCrmRouting(): boolean {
    return this.crmRoutingRules.length > 0;
  }

  hasRemmoteApplication(): boolean {
    return this.remoteApplications.length > 0;
  }

  hasIvrMenu(): boolean {
    // return (this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getMenuList() || []).length > 0;
    return this.ivrMenus.length > 0;
  }

  hasIvrApplication(): boolean {
    return this.ivrScripts.length > 0;
  }

  hasSipTrunk(): boolean {
    return this.sipTrunks.length > 0;
  }

  private async _createQueue(): Promise<any> {
    const queueRepo = this.em.getRepository<CallQueueRepository>('CallQueueRepository');
    const newObject = {
      queue_priority: 2,
      max_inqueue_time: 1,
      after_call_time: 0,
      bo_type: 0,
      queue_name: this.routeTo.queue_name
    };

    try {
      const ret = await queueRepo.createAndSave(newObject);
      if (!_.isEmpty(ret)) {
        this.routeTo.queue = queueRepo.getObjectById(ret.object_id);

        return this.routeTo.queue;
      }
    } catch (e) {
      console.error('Cannot create Queue: ', e);
      // const msg = e && e.message || _ti('public.message.create_failure');
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  private async _createConference(): Promise<any> {
    const conferenceRepo = this.em.getRepository<ConferenceRepository>('ConferenceRepository');
    const newObject = {
      name: this.routeTo.conference_name,
      password: this.createRandomString(4)
    };

    try {
      const ret = await conferenceRepo.createAndSave(newObject);
      if (!_.isEmpty(ret)) {
        this.routeTo.conference = conferenceRepo.getObjectById(ret.id);

        return this.routeTo.conference;
      }
    } catch (e) {
      console.error('Cannot create conference: ', e);
      // const msg = e && e.message || _ti('public.message.create_failure');
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  private async _createIvrMenu(): Promise<IvrCustomMenuEntity> {
    const ivrmenuRepo = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository');
    const newObject = {
      name: this.routeTo.ivrmenu_name,
      ivrscript_type: IvrCustomMenuEntity.TYPE_IVRMENU
    };

    try {
      const ret = await ivrmenuRepo.createAndSave(newObject);
      if (!_.isEmpty(ret)) {
        this.routeTo.ivrmenu = ivrmenuRepo.getObjectById(ret.id);

        return this.routeTo.ivrmenu;
      }
    } catch (e) {
      console.error('Cannot create ivr-menu: ', e);
      // const msg = e && e.message || _ti('public.message.create_failure');
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  private createRandomString(length: number): string {
    const chars = 'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890';
    const pwd = _.sampleSize(chars, length || 12);
    return pwd.join('');
  }

  // get isValidData(): boolean {
  //   if (!this.routeTo.destination) {
  //     return false;
  //   }

  //   if (this.routeTo.destination === 'callqueue' || this.routeTo.destination === 'conference') {
  //     if (
  //       this.routeTo.create_new === 1 &&
  //       ((this.routeTo.destination === 'callqueue' && !this.routeTo.queue_name) ||
  //         (this.routeTo.destination === 'conference' && (!this.routeTo.conference_name)))
  //     ) {
  //       return false;
  //     }

  //     if (this.routeTo.create_new === 0 && !this.routeTo[this.mapDestinationParamName[this.routeTo.destination]]) {
  //       return false;
  //     }
  //   } else if (!this.routeTo[this.mapDestinationParamName[this.routeTo.destination]]) {
  //     return false;
  //   }

  //   return true;
  // }

  // tslint:disable-next-line:cyclomatic-complexity
  private _validData(): boolean {
    this._clearMessage();
    if (!this.routeTo.destination) {
      console.error('No destination selected');
      return false;
    }

    switch (this.routeTo.destination) {
      case 'callqueue':
        if (this.routeTo.create_new === 1 && !this.routeTo.queue_name) {
          this.message = _ti('dialogs.did_setup_wizard.validator.queue_name_empty');
          break;
        }

        if (!this.routeTo.create_new && !this.routeTo.queue) {
          this.message = _ti('dialogs.did_setup_wizard.validator.queue_required');
        }
        break;
      case 'conference':
        if (this.routeTo.create_new === 1 && !this.routeTo.conference_name) {
          this.message = _ti('dialogs.did_setup_wizard.validator.conference_name_empty');
          break;
        }

        if (!this.routeTo.create_new && !this.routeTo.conference) {
          this.message = _ti('dialogs.did_setup_wizard.validator.conference_required');
        }
        break;

      case 'master_did':
        if (!this.routeTo.master_did) {
          this.message = _ti('dialogs.did_setup_wizard.validator.master_did_required');
        }
        break;

      case 'ivr_custom_menu':
        if (this.routeTo.create_new === 1 && !this.routeTo.ivrmenu_name) {
          this.message = _ti('dialogs.did_setup_wizard.validator.queue_name_empty');
          break;
        }

        if (!this.routeTo.create_new && !this.routeTo.ivrmenu) {
          this.message = _ti('dialogs.did_setup_wizard.validator.ivrmenu_required');
        }
        break;

      case 'call_phone_number':
        if (!this.routeTo.phone_number) {
          this.message = _ti('dialogs.did_setup_wizard.validator.phone_number_required');
          break;
        }

        if (!this.phoneNumberRegex.test(this.routeTo.phone_number)) {
          this.message = _ti('dialogs.did_setup_wizard.validator.phone_number_invalid');
        }
        break;

      case 'call_phone':
        if (!this.routeTo.sipphone) {
          this.message = _ti('dialogs.did_setup_wizard.validator.sipphone_required');
        }
        break;

      case 'runvxmlscript':
        if (!this.routeTo.ivrscript) {
          this.message = _ti('dialogs.did_setup_wizard.validator.ivrscript_required');
        }
        break;

      case 'play_then_hangup':
        // if (!this.routeTo.file_entry) {
        //   this.message = _ti('dialogs.did_setup_wizard.validator.file_entry_required');
        //   break;
        // }
        break;

      case 'webservice':
        if (!this.routeTo.url) {
          this.message = _ti('dialogs.did_setup_wizard.validator.url_empty');
          break;
        }
        if (!(new ValidatorService()).isUrl(this.routeTo.url)) {
          this.message = _ti('form.validator.data_invalid');
        }
        break;

      case 'crm_route':
        if (!this.routeTo.crm_routing_rule) {
          this.message = _ti('dialogs.did_setup_wizard.validator.crm_route_required');
        }
        break;

      case 'remote_application':
        if (!this.routeTo.remote_application) {
          this.message = _ti('dialogs.did_setup_wizard.validator.remote_application_required');
        }
        break;

      case 'call_user':
        if (!this.routeTo.user) {
          this.message = _ti('dialogs.did_setup_wizard.validator.user_required');
        }
        break;

      case 'sip_trunk':
        if (!this.routeTo.siptrunk) {
          this.message = _ti('dialogs.did_setup_wizard.validator.sip_trunk_required');
        }
        break;

      default:
        this.message = _ti('dialogs.did_setup_wizard.validator.unknown');
        break;
    }

    this.updateDialogLayout();

    return !this.message;
  }

  async updateRouting(): Promise<any> {
    console.log('Update routing');
    if (!this._validData()) {
      return;
    }

    this.did.action_office_hour = { application: undefined, params: undefined };

    switch (this.routeTo.destination) {
      // {"application":"callqueue","params":{"queue":224}}
      case 'master_did':
        this.did.master_did = this.routeTo.master_did;
        this.did.action_office_hour = null;
        this.did.action_not_office_hour = null;
        break;

      case 'callqueue':
        if (this.routeTo.create_new === 1) {
          const result = await this._createQueue();
          if (!result) {
            console.error('No queue created');
            return;
          }
        }
        this.did.routeToQueue(this.routeTo.queue);
        break;

      // {"application":"conference","params":{"conference":2}}
      case 'conference':
        if (this.routeTo.create_new === 1) {
          const result = await this._createConference();
          if (!result) {
            console.error('No conference created');
            return;
          }
        }
        this.did.routeToConference(this.routeTo.conference);
        break;

      // {"application":"ivr_custom_menu","params":{"id":41}}
      case 'ivr_custom_menu':
        if (this.routeTo.create_new === 1) {
          const result = await this._createIvrMenu();
          if (!result) {
            console.error('No ivr-menu created');
            return;
          }
        }
        this.did.routeToMenu(this.routeTo.ivrmenu);
        break;

      // {"application":"call_phone_number","params":{"number":"123456"}}
      case 'call_phone_number':
        this.did.routeToPhoneNumber(this.routeTo.phone_number);
        break;

      // {"application":"call_phone","params":{"sipphone_id":123}}
      case 'call_phone':
        this.did.routeToSipPhone(this.routeTo.sipphone);
        break;

      // {"application":"runvxmlscript","params":{"ivrscript_id":23}}
      case 'runvxmlscript':
        this.did.routeToApplication(this.routeTo.ivrscript);
        break;

      // {"application":"play_then_hangup","params":{"sound_id":15373}}
      case 'play_then_hangup':
        this.did.routeToFileEntry(this.routeTo.file_entry);
        break;

      // {"application": "webservice", params: {"url": "https://externaldomain.com/callrouter"}}
      case 'webservice':
        this.did.routeToWebService(this.routeTo.url);
        break;

      // {"application": "crm_route", params: {"id": 8}}
      case 'crm_route':
        this.did.routeToCrmRoute(this.routeTo.crm_routing_rule);
        break;

      // {"application":"remote_application","params":{"id":2}}
      case 'remote_application':
        this.did.routeToRemoteApplication(this.routeTo.remote_application);
        break;

      case 'sip_trunk':
        this.did.routeToSipTrunk(this.routeTo.siptrunk);
        break;

      // {"application":"call_user","params":{"id":123}}
      // case 'call_user':
      //   this.did.routeToUser(this.routeTo.user);
      //   break;

      default:
        break;
    }

    this.completed();
  }

  private _clearMessage(): void {
    this.message = undefined;
  }

  routeToFileEntry(uploadEvent: { new_file_entry: FileEntryEntity }): void {
    this.routeTo.file_entry = uploadEvent.new_file_entry;
  }

  cancel(result?): void {
    this.dismiss(result);
  }
}
