<mat-card>
  <mat-card-content>
    <form class="" [formGroup]="sourceForm" (ngSubmit)="onSubmit()" class="checkout">
      <mat-form-field class="full-width">
        <mat-label>{{'add_sepa_debit_modal.content.name'| translate}}</mat-label>
        <input matInput placeholder="{{ 'add_sepa_debit_modal.content.name' | translate}}" formControlName="name">
        <mat-error *ngIf="!sourceForm.controls['name'].valid && sourceForm.controls['name'].touched">{{ 'public.message.data_invalid'|translate }}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>{{'add_sepa_debit_modal.content.email'| translate}}</mat-label>
        <input matInput placeholder="{{ 'add_sepa_debit_modal.content.email' | translate}}" formControlName="email">
        <mat-error *ngIf="!sourceForm.controls['email'].valid && sourceForm.controls['email'].touched">{{ 'public.message.data_invalid'|translate }}</mat-error>
      </mat-form-field>
      <div class="form-row mt-1">
        <!-- <div id="card-info" #ibanInfo></div> -->
        <div id="stripeIban"></div>
        <div class="mat-text-warn" role="alert" *ngIf="error">{{ error }}</div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
