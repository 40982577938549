import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  transform(input: any, key: string): any {
    if (!isArray(input) || !key) {
      return input;
    }

    return input.map(i => i[key]);
  }
}
