<div class="select-wrapper" fxLayout="row" fxLayout="start center">
  <mat-label data-ci="calendar-label" class="text-primary ma-1">{{ placeholder }}</mat-label>
  <div class="select-wrapper-inner">
    <div class="select-trigger-value" *ngIf="flexSelect.value">
      <ng-container *ngIf="displayMode === 'compact' || !flexSelect.value.id">
        <button *ngIf="buttonType;else LinkCalendar" mat-stroked-button matSuffix type="button" color="accent"
          (click)="openCalendarListDialog()"
          class="select-calendar">
          {{ isTruncate ? (flexSelect.value.name|truncate) : flexSelect.value.name }}
        </button>
        <ng-template #LinkCalendar>
          <a class="flex-link select-calendar" href="javascript:void;" (click)="openCalendarListDialog()">
            {{ isTruncate ? (flexSelect.value.name|truncate) : flexSelect.value.name }}
          </a>
        </ng-template>
      </ng-container>

      <calendar-small *ngIf="displayMode === 'full' && flexSelect.value?.id" [id]="flexSelect.value.id"
        (click)="openCalendarListDialog()"
        class="select-calendar"
        [allowEdit]="false"></calendar-small>

    </div>
    <div class="action-buttons" *ngIf="create" fxLayoutAlign="center center">
      <button mat-icon-button color="primary" *ngIf="!flexSelect.value?.id" (click)="createCalendar()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button color="accent" *ngIf="flexSelect.value?.id" (click)="editCalendar()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>


  </div>
</div>
