import { Component, OnInit } from '@angular/core';
import { FlexBasePage } from '@wephone-core-ui';
import { FormGroup, FormBuilder } from '@angular/forms';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { EnterpriseService } from '@wephone/services/enterprise.service';
import { EnterpriseOAuthSettings, EnterpriseOAuthSettingsReq } from '@wephone/services/enterprise.service.i';
import { EditingComponent, ToastService } from '@wephone-utils';

@Component({
  selector: 'app-enterprise-oauth-settings',
  templateUrl: './enterprise-oauth-settings.component.html',
  styleUrls: ['./enterprise-oauth-settings.component.scss']
})
export class EnterpriseOauthSettingsComponent extends EditingComponent implements OnInit {
  form: FormGroup;
  objectKeys = Object.keys;
  oAuthSettings: EnterpriseOAuthSettings[];
  oAuthSettingNames: { [key: string]: EnterpriseOAuthSettings; };

  constructor(
    private fb: FormBuilder,
    private enterpriseService: EnterpriseService,
    public readonly toast: ToastService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    this.oAuthSettings = await this.enterpriseService.getEnterpriseOAuthSettings();
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.oAuthSettingNames = {};
    const fbData: any = {};
    const formValues = this.getFormValues();
    for (const formKey of Object.keys(formValues)) {
      fbData[formKey] = [formValues[formKey].value];
      this.oAuthSettingNames[formKey] = formValues[formKey];
    }
    this.form = this.fb.group(fbData);

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });
  }

  private getFormValues(): any {
    const ret = {};
    let i = 0;
    for (const s of this.oAuthSettings) {
      const formKey = `${s.name}${s.id || i}`;
      // fbData[formKey] = [s.value];
      // this.oAuthSettingNames[formKey] = s;
      ret[formKey] = s;
      i ++;
    }

    return ret;
  }

  resetForm(): void {
    const formValues = this.getFormValues();
    const fbData = {};
    for (const formKey of Object.keys(formValues)) {
      fbData[formKey] = formValues[formKey].value;
    }

    this.form.reset(fbData);

    this.form.markAsPristine();
  }

  async save(): Promise<any> {
    try {
      const data: EnterpriseOAuthSettingsReq[] = [];
      for (const key of Object.keys(this.form.controls)) {
        const setting: EnterpriseOAuthSettings = this.oAuthSettingNames[key];
        data.push({
          name: setting.name,
          id: setting.id,
          value: this.form.get(key).value ? 1 : 0
        });
      }
      console.log('request data', data);
      const resp = await this.enterpriseService.updateEnterpriseOAuthSettings(data);
      console.log('Save OAuth setting success', resp);

      this.oAuthSettings = await this.enterpriseService.getEnterpriseOAuthSettings();
      this.resetForm();
      this.toast.showInfo(_ti('public.message.update_success'));
    } catch (error) {
      console.error('Save OAuth setting failure', error);
      this.toast.showError(_ti('public.message.update_failure'));
    }
  }

}
