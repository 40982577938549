import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment }  from '../../../src/environments/environment';

window['APPLICATION_VERSION'] = environment.VERSION;
window['VERSION_SHA'] = environment.VERSION_SHA;
window['BUILD_DATE'] = environment.BUILD_DATE;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
.bootstrapModule(AppModule)
.catch(err => console.log(err));
