<div fxLayout="row" fxLayoutAlign="end center" class="filter-container">
  <div fxFlex="60">
    <mat-form-field class="full-width">
      <input matInput type="search" placeholder="{{ 'public.search' | translate }}" [formControl]="filterCtrl">
      <button mat-button *ngIf="filterCtrl.value" matSuffix mat-icon-button attr.aria-label="Clear"
        (click)="filterCtrl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<mat-divider></mat-divider>

<h3>{{'call_transfer.dialogs.transfer.agents_available'|translate}}</h3>
<div fxLayout="row wrap">
  <div fxFlex.gt-sm="20" fxFlex.gt-xs="33.3333%" fxFlex="100" *ngFor="let agent of filteredAgents">
    <mat-card class="agent-card" (click)="onAgentSelect(agent)">
      <div fxLayout="row" fxLayoutAlign="center center">
        <user-avatar [user]="agent.user" width="50px" height="50px"></user-avatar>
      </div>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="center center" class="agent-name__text">
          <b>{{agent.name}}</b>
          <div *ngIf="agent.user.is_online_cc" class="status-bg {{agent.agent_status}}">
            {{agent.agent_status}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<h3>{{'call_transfer.dialogs.transfer.agents_busy'|translate}}</h3>
<div fxLayout="row wrap">
  <div fxFlex.gt-sm="20" fxFlex.gt-xs="33.3333%" fxFlex="100" *ngFor="let agent of filteredBusyAgents">
    <mat-card class="agent-busy-card">
      <div fxLayout="row" fxLayoutAlign="center center">
        <user-avatar [user]="agent.user" width="50px" height="50px"></user-avatar>
      </div>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="center center" class="agent-name__text">
          <b>{{agent.name}}</b>
          <div *ngIf="agent.user.is_online_cc" class="status-bg {{agent.agent_status}}">
            {{agent.agent_status}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
