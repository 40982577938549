import { Component, OnInit, Inject, ViewChild } from '@angular/core';
// import { DidRepository } from '@wephone-core/model/repository/did';
import { DidEntity } from '@wephone-core/model/entity/did';
import { FlexTableComponent, IFlexTableConfig } from '@wephone-utils';
// import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataSourceService } from '@wephone-core/service/datasource.service';

@Component({
  selector: 'app-user-action-log',
  templateUrl: './user-action-log.component.html',
  styleUrls: ['./user-action-log.component.scss']
})
export class UserActionLogComponent extends DialogComponentBase implements OnInit {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  dialogTitle = _tk('call_queue.content.tab_history');

  did: DidEntity;
  dataSource: FlexDataSource;
  tableConfig: IFlexTableConfig;

  constructor(
    dialogRef: MatDialogRef<UserActionLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    datasourceService: DataSourceService,
  ) {
    super(dialogRef);
    this.did = data.did;
    this.dataSource = datasourceService.createDataSource('user_actions');

    this.tableConfig = {
      multiSelect: false,
      enableExportCsv: false,
      rowHeight: 100,
      columns: [
        { name: 'action_dt', label: _tk('components.user_action.action_date') },
        { name: 'author', label: _tk('components.user_action.author') },
        { name: 'user_action', label: _tk('components.user_action.action') },
        { name: 'object', label: _tk('components.user_action.object'), hiddenOn: 'xs' },
        { name: 'detail', label: _tk('components.user_action.detail'), hiddenOn: 'xs' },
      ]
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    const newFilters: any = {
      dids: [this.did.id]
    };

    if (this.flexTable) {
      // this.dataSource.setFilter(newFilters);
      this.flexTable.reloadDataSource(newFilters);
      return;
    }

    this.dataSource.load(newFilters);
  }

}
