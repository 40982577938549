import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class InvoiceItemRepository extends BaseRepository {
  ENTITY_CLASS = InvoiceItemEntity;

  getObjectListByInvoiceId(invoice_id: number): InvoiceItemEntity[] {
    return this.getObjectList().filter(x => x.invoice_id === invoice_id).sort((a: InvoiceItemEntity, b: InvoiceItemEntity) => {
      return a.order > b.order ? 1 : -1;
    });
  }
}

import { InvoiceItemEntity } from '@wephone-core/model/entity/invoice_item';
