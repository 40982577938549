import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UIStateService {
  sizePanelOpened = false;
  private eventSubject = new Subject();
  constructor(
    private mediaObserver: MediaObserver,
    router: Router,
  ) {
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.sizePanelOpened = false;
    });
  }

  isScreenSize(query: string): boolean {
    return this.mediaObserver.isActive(query);
  }

  sizePanelIsOpen(): boolean {
    return this.sizePanelOpened;
  }

  setSizePanelOpenned(opened: boolean): void {
    this.sizePanelOpened = opened;
  }

  get events(): Observable<any> {
    return this.eventSubject.asObservable();
  }
}
