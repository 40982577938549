<div fxLayout="column">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'enterprise_info.transform_premium.license_count' | translate }}</mat-label>
        <input matInput placeholder="{{ 'enterprise_info.transform_premium.license_count' | translate }}" type="number"
          formControlName="ccLicense" min="0">
        <mat-error *ngIf="form.controls['ccLicense'].hasError('required')" class="mat-text-warn">
          {{ 'enterprise_info.cc_license_cannot_blank' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
