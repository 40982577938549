import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { GroupRepository } from '@wephone-core/model/repository/group';

export class CustomStatsFilterEntity extends Entity {
  static object_type_id = 'custom_stats_filter';

  id: number;
  @datafield
  name: string;
  @datafield
  expression: any; // json
  @datafield
  group_id: number;
  group: UserGroupEntity;

  setGroup(group: UserGroupEntity): void {
    this.group = group;
  }

  setExpression(expression): void {
    this.expression = expression;
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    if (object_data.expression && typeof object_data.expression === 'string') {
      try {
        object_data.expression = JSON.parse(object_data.expression);
      } catch (err) {
        console.error('CustomStatsFilterEntity: Unable to parse json data ', object_data.expression, err);
      }
    }
    super.setObjectData(object_data, fetch_related_data);
  }

  fetchRelatedData(): void {
    const group = GroupRepository.getInstance().getObjectById(this.group_id);
    if (group) {
      this.setGroup(group);
    }
  }

  // Cloned from model.ts
  dumpObjectData(field_list: [] = null, use_mapped_name: boolean = false): any {
    const ret = { id: this.getId() };
    let attr_dict = this.data_fields;
    if (field_list) {
      attr_dict = {};
      for (const k of field_list) {
        attr_dict[k] = k;
      }
    }
    for (const field_name of Object.keys(attr_dict)) {
      const data = EntityManager.getInstance().dumpObjectData(this[field_name], 0, false); // not check recursive
      const params = [field_name];
      if (this.data_fields[field_name] && use_mapped_name === true) {
        params.push(this.data_fields[field_name]);
      }
      // Request both of data_column & data_field
      for (const p of params) {
        ret[p] = data;
      }
    }
    return ret;
  }
}
