import { AudioBrowserPlayerComponent, FileentryPlayerComponent, FileentryPlayerButtonComponent, SoundUploadComponent, ChangeSoundModal } from './fileentry';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { WephoneUtilsModule } from '@wephone-utils';
import { AudioTrackProgressBarComponent } from './fileentry/fileentry-player/audio-track-progess-bar/audio-track-progess-bar';
import { WephoneCoreModule } from '@wephone-core/wephone-core.module';
import { FileEntrySelectFormInputComponent } from './fileentry/file-entry-select-form-input/file-entry-select-form-input.component';
import { SoundService } from './service';
import { Text2SpeechDataService } from './service';
import { WephoneCoreUIModule } from '../wephone-core-ui/wephone-core-ui.module';
import { ManageRingtonesModal } from './fileentry/manage-ringtones/manage-ringtones';
// import { WephoneCommonModule } from '@wephone-common';

@NgModule({
  imports: [
    WephoneUtilsModule,
    WephoneCoreModule,
    // WephoneCommonModule,
    WephoneCoreUIModule
  ],
  exports: [
    AudioBrowserPlayerComponent,
    FileentryPlayerComponent,
    FileentryPlayerButtonComponent,
    SoundUploadComponent,
    AudioTrackProgressBarComponent,
    ChangeSoundModal,
    FileEntrySelectFormInputComponent,
    ManageRingtonesModal,
  ],
  declarations: [
    AudioBrowserPlayerComponent,
    FileentryPlayerComponent,
    FileentryPlayerButtonComponent,
    SoundUploadComponent,
    AudioTrackProgressBarComponent,
    ChangeSoundModal,
    FileEntrySelectFormInputComponent,
    ManageRingtonesModal,
  ],
  entryComponents: [
    AudioBrowserPlayerComponent,
    FileentryPlayerComponent,
    FileentryPlayerButtonComponent,
    SoundUploadComponent,
    AudioTrackProgressBarComponent,
    ChangeSoundModal,
    FileEntrySelectFormInputComponent,
    ManageRingtonesModal,
  ],
})
export class WephoneAudioModule {
  static forRoot(): ModuleWithProviders<WephoneAudioModule> {
    return {
      ngModule: WephoneAudioModule,
      providers: [
        SoundService,
        Text2SpeechDataService
      ],
    };
  }
}
