<mat-toolbar>
  <h2>{{ 'public.multipleedit'|translate:{number:editingList.length} }}</h2>
</mat-toolbar>
<div fxFill fxLayout="column">
  <div class="wrapper">

    <div class="col-left" [fxHide.xs]="true">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of stepKeys; let i=index;">
            <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
              [ngClass]="{'active': mainArea.activeAnchor === step}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ steps[step] | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner">

        <form [formGroup]="form" *ngIf="form">
          <div [ngClass]="showCalendar && hasCalendar ? 'activity-stream' : ''">

            <ng-container *ngIf="did.hasLinkedUser(); else tplEditForm">
              <strong [innerHtml]="'did.content.dedicated_number_for'|translate: {user_name: ''}"></strong>&nbsp;
              <a class="flex-link" (click)="gotoEditUserPage(did.routed_user)" data-ci="link-user">{{
                did.routed_user.name }}
              </a>
            </ng-container>

            <ng-template #tplEditForm>
              <number-opening-calendar *ngIf="showCalendar" #numberOpeningCalendar [did]="did" class="shadow-none"
                id="_1calendar" anchorPart (formValueChanges)="numberOpeningCalendarChange()">
              </number-opening-calendar>

              <mat-card class="shadow-none" id="_2default_routing" anchorPart>
                <mat-card-header *ngIf="showBullet">
                  <div mat-card-avatar><i class="timeline-icon material-icons">check</i></div>
                  <mat-card-title>{{'call_queue_edit.section.if_opened'|translate}}</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                  <div fxLayout="column">
                    <div>
                      {{ 'did.content.default_destination'|translate }}&nbsp;
                      <a class="flex-link" data-ci="link-change-destination" (click)="setOtherDestination()">[{{
                        'public.edit'|translate }}]</a>
                    </div>
                    <number-routing-dest class="defaut-routing flex-info" [did]="didPreview"
                      [updateLayout]="updateLayout">
                    </number-routing-dest>
                    <mat-error *ngIf="form.invalid && form.touched">
                      {{ 'public.message.data_invalid'|translate }}
                    </mat-error>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="shadow-none" id="_3general_info" anchorPart>
                <mat-card-header *ngIf="showBullet">
                  <div mat-card-avatar><i class="timeline-icon material-icons">info</i></div>
                  <mat-card-title>{{ 'did.content.did_info' | translate }}</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                  <div fxLayout="column">
                    <!-- <mat-form-field class="full-width">
                      <input type="text" matInput formControlName="name" placeholder="{{ 'did.content.name' | translate }}">
                    </mat-form-field> -->

                    <mat-form-field class="full-width">
                      <mat-label>{{ 'did.content.welcome_message' | translate }}</mat-label>
                      <textarea matInput formControlName="welcome_message"
                        [placeholder]="'did.content.welcome_message' | translate"></textarea>
                    </mat-form-field>
                  </div>

                  <div>
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'did.content.select_crm'|translate }}</mat-label>
                      <flex-select-enterprise-crm-input formControlName="crm" [isConfigured]="true"
                        placeholder="{{ 'did.content.select_crm' | translate }}"></flex-select-enterprise-crm-input>
                      <button mat-icon-button matSuffix type="button" (click)="form.get('crm').setValue(null)"
                        [disabled]="!form.get('crm').value || !form.get('crm').value.id">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>

            </ng-template>
          </div>

        </form>

      </div>
    </div>
  </div>

</div>
