import { FormControl, ValidationErrors } from '@angular/forms';

export class UrlValidator {
  static isValidUrl(fc: FormControl): ValidationErrors | undefined {
    const urlPattern = /^(http|https):\/\/[a-zA-Z0-9\.\-]+[a-zA-Z]{2,5}/gi;
    const value: string = fc.value && fc.value.trim() || '';
    try {
      if (value && !urlPattern.test(value)) {
        throw new Error('Url must start with http:// or https://');
      }
      // new URL(fc.value);
      return;
    } catch (e) {
      return {
        isValidUrl: true
      };
    }
  }
}
