<form [formGroup]="form" *ngIf="form">
  <div fxLayout="column">
    <div fxLayout="row">
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'user_page.firstname'|translate }}</mat-label>
          <input data-ci="firstname" matInput [placeholder]="'user_page.firstname'|translate"
            formControlName="firstname">

          <mat-error [flexFormControlError]="form.get('firstname')"></mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="10px"></div>
      
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'user_page.lastname'|translate }}</mat-label>
          <input data-ci="lastname" matInput [placeholder]="'user_page.lastname'|translate" formControlName="lastname">

          <mat-error [flexFormControlError]="form.get('lastname')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'input.email.label'|translate }}</mat-label>
          <input data-ci="mail" matInput [placeholder]="'input.email.label'|translate"
            formControlName="email">
  
          <mat-error [flexFormControlError]="form.get('email')"
            [customMessages]="{
              'wrongEmail': 'user.validators.email_incorrect'|translate,
              'email_exist': 'user.validators.exists'|translate
            }"></mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="10px" *ngIf="!uiSipTrunk"></div>

      <div fxFlex *ngIf="!uiSipTrunk">
        <mat-form-field class="full-width">
          <mat-label>{{'account_basic_settings.content.user_role' | translate }}</mat-label>
          <mat-select formControlName="user_type" [placeholder]="'account_basic_settings.content.user_role' | translate">
            <mat-option *ngFor="let item of user_type_list" [value]="item.user_type">
              {{ ('user_type_names.'+ item.user_type) | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-error [flexFormControlError]="form.get('user_type')"></mat-error>
      </div>

    </div>

  </div>
</form>
