import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { DialogActionButton } from '@wephone-utils';
import { DidEntity } from '@wephone-core/model/entity/did';
import * as _ from 'lodash';
import { NumberRoutingEditBaseComponent } from '../number-routing-edit-base';
import { _tk, _ti } from '@wephone-translation';
import { UpdateNumberDescriptionModalComponent } from '@wephone/modals/did/update-number-description-modal/update-number-description-modal.component';
import { UserActionLogComponent } from '@wephone/modals/user-action-log/user-action-log.component';
import { Validators } from '@angular/forms';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { CreateSiptrunkModalComponent } from '@wephone/modals/create-siptrunk-modal/create-siptrunk-modal.component';

@Component({
  selector: 'app-siptrunk-number-routing-edit',
  templateUrl: './siptrunk-number-routing-edit.component.html',
  styleUrls: ['./siptrunk-number-routing-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SipTrunkNumberRoutingEditComponent extends NumberRoutingEditBaseComponent implements OnInit, OnDestroy {
  @Input() editingItem: DidEntity;

  didPreview: DidEntity;
  actions: DialogActionButton[] = [
    {
      id: 'clear_did',
      label: _tk('did.content.clear_did'),
      action: () => {
        console.log('action clicked');
        this.clearDid();
      }
    },
    {
      id: 'update_destination',
      label: _tk('did.content.update_number_description'),
      action: () => {
        console.log('action rename did clicked');
        this.updateNumberDestination();
      }
    },
    {
      id: 'view_history',
      label: _tk('call_queue.content.tab_history'),
      action: () => {
        console.log('action history did clicked');
        this.showNumberUserActionLog();
      }
    }
  ];

  sipTrunkDefault: SipTrunkEntity;
  sipTrunkList: SipTrunkEntity[] = [];

  async ngOnInit(): Promise<void> {
    this.setDid(this.editingItem);
    await super.ngOnInit();

    await this.initSipTrunkData();
  }

  private async initSipTrunkData(): Promise<void> {
    this.sipTrunkList = await this.em.getRepository<SipTrunkRepository>('SipTrunkRepository').findAll();
    this.sipTrunkDefault = this.sipTrunkList.find(sipTrunk => !!sipTrunk.is_default);
  }

  protected initForm(): void {
    this.form = this.fb.group({
      name: [this.did.name, Validators.compose([Validators.required, Validators.maxLength(100)])],
    });
  }

  setDid(item?: DidEntity): void {
    super.setDid(item);
    this.setDidPreview();
  }

  protected setDidPreview(): void {
    this.didPreview = _.cloneDeep(this.did);
    this.updateDisplayedRouting();
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    this.setDidPreview();
    this.form.reset({ name: this.did.name }, options);
    this.pristineForm();
  }

  protected pristineForm(): void {
    this.form.markAsPristine();
    this.formValueChanges.next(false);
    this.cdr.markForCheck();
  }

  // Actions form
  clearDid(): void {
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('dialogs.routing.reset_confirm_msg'),
      async () => {
        try {
          await this.didRepo.clear([this.did]);
          this.did.name = undefined;
          this.did.clearRoutingData();
          this.resetForm();
          this.updateDisplayedRouting();
          this.toast.showSuccess(_ti('did.message.reset_success'));
        } catch (error) {
          const msg = error && error.message ? error.message : _ti('public.message.update_failure');
          this.toast.showError(msg);
        }
      }
    );
  }

  updateNumberDestination(): void {
    this.dialogService.openSideDialog(UpdateNumberDescriptionModalComponent, { data: { id: this.did.id }, size: 's' }, ret => {
      // console.log('updateNumberDestination ret', ret);
      this.refreshDid();
    });
  }

  private refreshDid(): void {
    const did: DidEntity = this.didRepo.getObjectById(this.did.id);
    this.setDid(did);
    this.resetForm();
  }

  showNumberUserActionLog(): void {
    this.dialogService.openDialog2(UserActionLogComponent, { data: { did: this.did } });
  }

  async setDestinationSipTrunk(sipTrunk: SipTrunkEntity): Promise<void> {
    if (!sipTrunk) {
      console.error('SipTrunk not found');
      return;
    }

    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('did.message.change_did_destination_confirm'),
      async () => {
        try {
          await this.doRouteDidToSipTrunk(this.did.id, sipTrunk);
          this.toast.showSuccess(_ti('public.message.update_success'));

          this.refreshDid();

          this.pristineForm();
          this.updateDisplayedRouting();
        } catch (error) {
          console.error('Routing DID to default sip trunk error', error);

          this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
        }
      }
    );
  }

  private async doRouteDidToSipTrunk(id: number, sipTrunk: SipTrunkEntity): Promise<void> {
    const updateDid: DidEntity = this.didRepo.create({ id }) as DidEntity;
    updateDid.routeToSipTrunk(sipTrunk);
    const updateData = {
      action_office_hour: updateDid.action_office_hour,
      action_not_office_hour: updateDid.action_not_office_hour,
      linked_object_id: updateDid.linked_object_id,
      linked_object_type: updateDid.linked_object_type
    };

    await this.didRepo.saveAttrs(updateDid, Object.keys(updateData), updateData);
  }

  async saveDidName(): Promise<void> {
    try {
      const newName = this.form.get('name').value && this.form.get('name').value.trim() || null;
      if (newName) {
        const existName: DidEntity = this.didRepo.getDidByName(newName, this.did && this.did.id);
        if (existName) {
          this.form.get('name').setErrors({ name_exist: true });
        }
      }

      if (this.form.get('name').invalid) {
        this.form.get('name').markAsTouched();
        throw new Error(_ti('public.message.data_invalid'));
      }

      this.did.name = newName;
      await this.didRepo.saveAttrs(this.did, ['name']);

      this.toast.showSuccess(_ti('public.message.update_success'));
      this.refreshDid();
      this.pristineForm();
    } catch (e) {
      console.error('Save did error', e);
      this.toast.showErrorMessage(e, _ti('public.message.update_failure'));
    }
  }

  protected updateDisplayedRouting(): void {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  onFormValueChange(): void {
    super.onFormValueChange();
    this.cdr.markForCheck();
  }

  gotoEditSipTrunk(id: number): void {
    this.router.navigate(['telephony', 'sip-trunk', id]);
  }

  createSipTrunk(): any {
    this.dialogService.openDialog2(CreateSiptrunkModalComponent, null, async (ret) => {
      if (ret) {
        await this.initSipTrunkData();
        this.cdr.markForCheck();
      }
    });
  }
}
