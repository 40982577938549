import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';

export class EnterpriseFileEntryEntity extends Entity {
  public static object_type_id: string = 'enterprise_fileentry';

  public static TYPE_IMAGE = '0';

  public static CATEGORY_LOGO_QUEUE = 'logo_queue';
  public static CATEGORY_PHOTO_CONTACT = 'photo_contact';
  public static CATEGORY_AVATAR_USER = 'avatar_user';
  public static CATEGORY_LOGO_ENTERPRISE = 'logo_enterprise';

  @datafield
  public file_type: number; //{0: image}
  @datafield
  public file_extension: string;
  @datafield
  public title: string;
  @datafield
  public file_content: string;
  @datafield
  public category: string;

  getId(): number {
    return this.id;
  }
}
