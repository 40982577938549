<ng-template [ngIf]="currentImage" [ngIfElse]="ImageNull">
    <div fxLayout="row" fxLayoutAlign="start center" class="image-upload-wrap">
        <div *ngIf="title" fxFlex="auto">{{ title }}</div>
        <div fxFlex="5px"></div>
        <div fxFlex="auto">
            <strong class="image-name">{{ currentImage.name }}</strong>
        </div>
        <div fxFlex="initial" class="button-row">
            <div fxFlex="150px">
                <app-image-viewer *ngIf="publicImageId" width="150px" [filePublicId]="publicImageId">
                </app-image-viewer>
            </div>
            <button type="button" mat-icon-button color="accent" (click)="openDialogChangeImage()"
                data-ci="change-image">
                <mat-icon>create</mat-icon>
            </button>
            <button [hidden]="hideRemove" type="button" mat-icon-button color="warn"
                (click)="removeImage()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #ImageNull>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="image-upload-wrap">
        <div color="dark">{{ title }}</div>
        <div fxFlex="auto" fxLayoutAlign="end center">
            <button data-ci="define-image" type="button" mat-stroked-button color="accent" class="button-image"
                (click)="openDialogChangeImage()">
                {{ 'user.content.define_image' | translate }}
            </button>
        </div>
    </div>
</ng-template>
