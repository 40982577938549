import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { PaymentSourceEntity } from '@wephone-core/model/entity/payment_source';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';

export class PaymentSourceRepository extends BaseRepository {
  ENTITY_CLASS = PaymentSourceEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'payment_source';

  /**
   * For only enterprise's account
   */
  async getMyPaymentSource(): Promise<PaymentSourceEntity> {
    const l: PaymentSourceEntity[] = await this.findAll();
    return l && l.length ? l[0] : undefined;
  }

  async getPaymentSourceByEnterprise(enterpriseId: number): Promise<PaymentSourceEntity> {
    const l: PaymentSourceEntity[] = await this.findAll();
    return l.find(x => x.enterprise_id === enterpriseId);
  }

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/payment_source'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get subscription pack list error', response);

          return [];
        }
      );
  }
}
