import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DialogService, DynamicFilterSource, FlexCRUDPageComponent, IFlexTableConfig, IFlexTableSidePannelOptions, regexSearch } from '@wephone-utils';
import { BlackListRepository } from '@wephone-core/model/repository/blacklist';
import { BlackListEntity } from '@wephone-core/model/entity/blacklist';
import { EditBlackListComponent } from '@wephone/components/edit-black-list/edit-black-list.component';
import { CreateBlackListModalComponent } from '@wephone-common/modals/create-black-list-modal/create-black-list-modal.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { Router } from '@angular/router';
import { AgentEntity } from '@wephone-core/model/entity/agent';

@Component({
  selector: 'app-black-list',
  templateUrl: './black-list.component.html',
  styleUrls: ['./black-list.component.scss']
})
export class BlackListComponent extends FlexBasePage implements OnInit, OnDestroy {
  filterForm: FormGroup;
  blackList: BlackListEntity[];

  countryCodes: string[] = [];
  blockers: AgentEntity[] = [];

  filterCountryCode: string;
  filterBlockerId: number;
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditBlackListComponent
  };

  private blackListRepo: BlackListRepository;

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    readonly em: EntityManager,
  ) {
    super();
    this.blackListRepo = em.getRepository<BlackListRepository>('BlackListRepository');

    const blackListSource = this.blackListRepo.dataSource<BlackListEntity>();
    this.dataSource = new DynamicFilterSource(blackListSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'number',
          label: _tk('blacklist.content.number'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (blackList: BlackListEntity) => {
            return blackList.display_number;
          }
        },
        {
          name: 'block_reason',
          label: _tk('blacklist.content.block_reason'),
          searchable: true,
          sortable: true,
          customSortFn: (blackList: BlackListEntity) => {
            return blackList.block_reason.toLowerCase();
          }
        },
        {
          name: 'blocker',
          label: _tk('blacklist.content.blocker'),
          searchable: true,
          sortable: true,
          customSortFn: (blackList: BlackListEntity) => {
            return blackList.blocker_name ? blackList.blocker_name.toLowerCase() : '';
          },
          renderCell: (item: BlackListEntity, col: string) => {
            return item.blocker_name ? item.blocker_name.toLowerCase() : '';
          }
        },
        {
          name: 'block_dt',
          label: _tk('blacklist.content.block_date'),
          searchable: false,
          sortable: true,
          // customSortFn: (blackList: BlackListEntity) => {
          //   return blackList.block_reason.toLowerCase();
          // }
        },

      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filterForm = this.fb.group({
      blockerId: undefined,
      countryCode: undefined,
      filterValue: ['']
    });

    this.addSubscription(this.filterForm.valueChanges.subscribe(this.onFormValueChange));
  }

  protected async resolveData(): Promise<any> {
    await this.blackListRepo.loadObjectList();

    this.blackListRepo.getObjectList().map(item => {
      if (item.country_code) {
        const country_code = item.country_code.toLowerCase();
        if (!this.countryCodes.includes(country_code)) {
          this.countryCodes.push(country_code);
        }
      }

      if (item.blocker) {
        if (!this.blockers.find(i => i.id === item.blocker.id)) {
          this.blockers.push(item.blocker);
        }
      }
    });
  }

  private readonly filterFunc = (blackListItem: BlackListEntity): boolean => {
    return this.filterByCountry(blackListItem) && this.filterPredicate(blackListItem, this.filterValue) && this.filterByBlocker(blackListItem);
  }

  private onFormValueChange = (formValues: { countryCode: string; filterValue: string; blockerId: number }) => {
    this.filterCountryCode = formValues.countryCode;
    this.filterBlockerId = formValues.blockerId;
    this.filterValue = (formValues.filterValue || '').trim();

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  filterPredicate = (blackListItem: BlackListEntity, filterString: string): boolean => {
    if (!this.filterValue) {
      return true;
    }

    return regexSearch(blackListItem.display_number, this.filterValue) ||
      regexSearch(blackListItem.number, this.filterValue) ||
      (blackListItem.block_reason && regexSearch(blackListItem.block_reason, this.filterValue));
  }

  filterByCountry = (item: BlackListEntity): boolean => {
    if (!this.filterCountryCode) {
      return true;
    }
    if (item.country_code) {
      return item.country_code.toLowerCase() === this.filterCountryCode;
    }

    return false;
  }

  filterByBlocker = (item: BlackListEntity): boolean => {
    if (!this.filterBlockerId) {
      return true;
    }
    return item.blocker_id && item.blocker_id === this.filterBlockerId || false;
  }

  async bulkDelete(items: BlackListEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('user.title.delete'), () => {
      if (items) {
        this.blackListRepo.bulkDelete(items).then(
          response => {
            this.flexCrud.closeSidePanel();
            this.successToast(_ti('public.message.delete_success'));
          },
          response => {
            console.error('Delete blacklist items error', response);
            const message = response.error && response.error.message ? response.error.message : _ti('public.message.delete_failure');
            this.showError(message);
          }
        );
      }
    });
  }

  createNew(): any {
    return this.dialogService.openDialog2(CreateBlackListModalComponent, undefined, (item: BlackListEntity) => {
      if (item && item.id) {
        this.router.navigateByUrl(`/callcenter-params/black-list/${item.id}`);
      }
    });
  }
}
