import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

/**
 * Generated class for the AudioBrowserPlayerComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
interface FileReaderEventTarget extends EventTarget {
  result: string;
}

interface FileReaderEvent extends Event {
  target: FileReaderEventTarget;
  getMessage(): string;
}

@Component({
  selector: 'audio-browser-player',
  templateUrl: './audio-browser-player.html',
  styleUrls: ['./audio-browser-player.scss']
})
export class AudioBrowserPlayerComponent implements OnInit {
  @Input() file: any;
  @Input() hideStop: boolean;
  @Input() hideSeekbar: boolean;

  private audio: any;

  audioPlaying: boolean;
  seekbarValue: number;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    console.log('Hello AudioBrowserPlayerComponent Component');
  }

  ngOnInit(): void {
    if (!this.file) {
      console.error('No file given');
      return;
    }
    this.audio = new Audio();
    this.audioPlaying = false;

    const reader = new FileReader();
    reader.onloadend = ((evt: FileReaderEvent): any => {
      this.audio.src = evt.target.result;
      this.audio.ontimeupdate = () => {
        if (this.audio.duration) {
          this.seekbarValue = Math.ceil(this.audio.currentTime * 100 / this.audio.duration);
          this.cdr.markForCheck();
          if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
          }
        }
      };
    }) as any;
    reader.readAsDataURL(this.file);
  }

  updateTheTime(): void {
    if (!this.audio) {
      return;
    }
    console.log('this.seekbarValue', this.seekbarValue);
    this.audio.currentTime = this.seekbarValue;
  }

  play(): void {
    if (this.audio) {
      this.audio.play();
      this.audioPlaying = true;
    }
  }

  pause(): void {
    if (this.audio) {
      this.audio.pause();
      this.audioPlaying = false;
    }
  }

  restart(): void {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audioPlaying = false;
    }
  }

  stop(): void {
    this.restart();
  }
}
