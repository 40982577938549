<div fxLayoutAlign="end center">
  <mat-form-field>
    <input matInput [formControl]="filterCtrl" placeholder="{{ 'public.button.search'|translate }}" type="text">
    <div matSuffix>
      <mat-icon>search</mat-icon>
    </div>
  </mat-form-field>
</div>
<div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="0px">
  <ng-container *ngFor="let did of filteredDidAvailableList">
    <button mat-stroked-button fxFlex="calc(25%-10px)" fxFlex.sm="calc(33.33%-10px)" fxFlex.xs="100" mat-button type="button"
      (click)="addToQueue(did)" color="primary">
      <mat-icon>add</mat-icon>{{ did.number }}
    </button>
  </ng-container>
</div>
