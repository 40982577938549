import { Injectable, NgZone } from '@angular/core';
import { SingletonBase } from '../utils/singleton';

@Injectable()
export class TimerService extends SingletonBase {
  subscriberDict1000 = {};
  inteval: any;
  nextId = 1;

  constructor(private zone: NgZone) {
    super();
    this.inteval = setInterval(
      () => {
        for (const k of Object.keys(this.subscriberDict1000)) {
          try {
            this.subscriberDict1000[k]();
          } catch (e) {
            console.error('Error when running timer callback', this.subscriberDict1000[k], e);
            continue;
          }
        }
      },
      1000
    );
  }

  subscribe1000(cbFunc: () => any): number {
    const k = this.nextId++;
    this.subscriberDict1000[k] = cbFunc;
    return k;
  }

  unsubscribe(id: number): void {
    delete this.subscriberDict1000[id];
  }
}
