import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy, Self, Optional, ElementRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';

@Component({
  selector: 'usergroup-select-form-input',
  templateUrl: './usergroup-select.component.html',
  styleUrls: ['./usergroup-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UserGroupSelectFormInput }]
})
export class UserGroupSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<UserGroupEntity> {
  static nextId = 0;
  flexSelect: FormControl = new FormControl();
  list: UserGroupEntity[];
  flexSelectOptions: IFlexSelectOptions;

  @Input() filteredIds: number[];
  @Input() multiple: boolean;
  @Input() hasAgentOrQueueOrDid: boolean;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: UserGroupEntity, filterString) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: UserGroupEntity, b: UserGroupEntity) => {
        return !a && !b || a && b && a.id === b.id;
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list = UserGroupRepository.getInstance<UserGroupRepository>().getObjectList();
    if (this.hasAgentOrQueueOrDid) {
      this.list = this.list.filter(g => g.hasAgent() || g.hasQueue() || g.hasDid());
    }

    if (!_.isUndefined(this.filteredIds)) {
      this.list = this.list.filter(x => _.includes(this.filteredIds, x.id));
    }
  }

  get controlType(): string {
    return 'usergroup-select-form-input';
  }
}
