import * as _ from 'lodash';

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserRepository } from '@wephone-core/model/repository/user';
import { UserCreateModal } from '@wephone/modals/user-create/user-create-modal.component';
import { UserImportModal } from '@wephone/modals/user-import/user-import-modal.component';
import { AuthenticationService } from '@wephone-core/service/authentication';
import {
  DialogService,
  IFlexTableSidePannelOptions,
  FilterByPipe,
  FlexCRUDPageComponent,
  DynamicFilterSource,
  IFlexTableConfig,
  regexSearch,
} from '@wephone-utils';
import { UserEditComponent } from '@wephone/components/user/user-edit/user-edit.component';
import { ConfigManager, EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';

@Component({
  selector: 'user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss'],
  providers: [FilterByPipe]
})
export class UserPage extends FlexBasePage implements OnInit {
  realtime_pushapi = true;
  // user_list: UserEntity[];
  userCount: number;
  user_filtered_list: UserEntity[];
  phone_state_list: any[];
  filters: { search_text: string; phone_state: number } = { search_text: '', phone_state: null };

  filteredUserIds: number[];
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: UserEditComponent
  };

  filterForm: FormGroup;

  private readonly currentUser: UserEntity;
  private readonly userRepo: UserRepository;

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;
  // id: number;

  readonly uiSipTrunk = FlexIvrSettings.getInstance().uiSipTrunk();

  constructor(
    private readonly dialogService: DialogService,
    private readonly authService: AuthenticationService,
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly configManager: ConfigManager,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.userRepo = this.em.getRepository<UserRepository>('UserRepository');
    this.currentUser = this.authService.getUser();
    const entity_source = this.userRepo.dataSource<UserEntity>();
    this.dataSource = new DynamicFilterSource(entity_source, this.filterFunc);

    this.phone_state_list = [
      { text: _tk('public.all'), value: undefined },
      { text: _tk('hotelroom.content.phone_state_nophone'), value: -1 },
      { text: _tk('hotelroom.content.phone_state_connected'), value: 1 },
      { text: _tk('hotelroom.content.phone_state_disconnected'), value: 0 }
    ];
    this.tableConfig = {
      rowHeight: 48,
      columns: [
        {
          name: 'name',
          label: _tk('user.content.full_name'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (user: UserEntity) => {
            return user.name.toUpperCase();
          },
          exportedValue: u => u.name
        },
        {
          name: 'email',
          label: _tk('input.email.label'),
          hiddenOn: 'lt-lg',
          searchable: true,
          sortable: true
        },
        {
          name: 'user_type',
          label: _tk('user.content.roles'),
          hiddenOn: 'lt-md',
          searchable: true,
          sortable: true,
          customSortFn: (user: UserEntity) => {
            return _ti(`user_type_names.${user.user_type}`);
          },
          exportedValue: u => _ti(`user_type_names.${u.user_type}`)
        },
        {
          name: 'sipphone_is_online',
          label: _tk('hotelroom.content.phone_state'),
          sortable: true,
          searchable: false,
          exportable: false,
          visible: !this.uiSipTrunk
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'person_add',
            hint: _tk('user.title.create'),
            callback: () => {
              return this.createUser();
            }
          },
          {
            id: 'group_add',
            icon: 'group_add',
            hint: _tk('user.title.import'),
            callback: () => {
              return this.importUser();
            },
            visible: !this.uiSipTrunk
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: (data: any) => {
                return this.openConfirmDeleteUsers(data.selectedItems);
              }
            }
          ],
          secondary: [
            // Visible action buttons when some item is selected and user click on the button "..."
            {
              id: 'enable',
              icon: 'check_circle_outline',
              hint: _tk('public.enable'),
              callback: (data: any) => {
                return this.bulkEnable(data.selectedItems);
              }
            },
            {
              id: 'disable',
              icon: 'check_circle',
              hint: _tk('public.disable'),
              callback: (data: any) => {
                return this.bulkDisable(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    if (this.currentUser.isSupervisor()) {
      this.tableConfig.listActions.defaultActions = [];
      this.tableConfig.listActions.selectionActions.primary = [];
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filterForm = this.fb.group({
      phone_state: [this.filters.phone_state],
      search_text: [this.filters.search_text]
    });
    this.filterForm.valueChanges.subscribe(this.applyFilter);

    // this.resolveData();
  }

  bulkEnable(items: UserEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.bulk_enable_confirm_question'),
      async () => {
        try {
          const ret = await this.userRepo.bulkEnable(items);
          this.flexCrud.closeSidePanel();
          this.successToast(_ti('public.message.enable_success'));
        } catch (error) {
          const message = error.message ? error.message : _ti('public.message.enable_failure');
          this.showError(message);
        }
      }
    );
  }

  bulkDisable(items: UserEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.bulk_disable_confirm_question'),
      async () => {
        try {
          const ret = await this.userRepo.bulkDisable(items);
          this.flexCrud.closeSidePanel();
          this.successToast(_ti('public.message.disable_success'));
        } catch (error) {
          const message = error.message ? error.message : _ti('public.message.disable_failure');
          this.showError(message);
        }
      }
    );
  }

  protected async resolveData(): Promise<void> {
    // this.user_list = this.userRepo.getObjectList();
    if (this.authService.isSupervisor()) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      this.filteredUserIds = myProfile.managed_user_ids;
    }

    this.applyFilter();
    this.setData();
  }

  setData(): void {
    this.userCount = this.filteredUserIds ? this.filteredUserIds.length : this.userRepo.getObjectList().length;
  }

  applyFilter = () => {
    this.filters.phone_state = this.filterForm.get('phone_state').value;
    this.filters.search_text = (this.filterForm.get('search_text').value || '').trim();

    this.dataSource.filter = this.filters.search_text;
    this.dataSource.onFilterChange();
  }

  filterFunc = (user: UserEntity): boolean => {
    if (user.system_user) {
      return false;
    }

    if (!this.authService.isAdmin() && !this.authService.isSupervisor()) {
      if (!this.currentUser.group_ids) {
        return false;
      }

      let isShow = false;
      for (const i of user.group_ids) {
        if (this.currentUser.group_ids.indexOf(i) >= 0) {
          isShow = true;
          break;
        }
      }
      if (!isShow) {
        return false;
      }
    }

    if (this.filteredUserIds && !_.includes(this.filteredUserIds, user.id)) {
      return false;
    }

    if (this.filters.phone_state != null && user.sipphone_is_online !== this.filters.phone_state) { // 0,-1,1
      return false;
    }

    const searchText = _.trim(this.filters.search_text || '');
    if (!searchText) {
      return true;
    }

    const userType = _ti(`user_type_names.${user.user_type}`);
    return regexSearch(user.name, searchText) ||
      (user.email && regexSearch(user.email, searchText)) ||
      (user.user_type && regexSearch(userType, searchText));
  }

  openConfirmDeleteUsers(users: UserEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      () => {
        this.deleteUsers(users);
      }
    );
  }

  async deleteUsers(users: UserEntity[]): Promise<void> {
    try {
      await this.userRepo.bulkDelete(users);
      this.resolveData();
      this.showInfo(_ti('public.message.delete_success'));
      this.flexCrud.closeSidePanel();
    } catch (error) {
      console.error('Delete users failed', error);
      this.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  private async createUser(): Promise<void> {
    const dialogRef = this.dialogService.openDialog2(UserCreateModal, { size: 's' });
    const createdUser = await dialogRef.afterClosed().toPromise();
    if (!createdUser) {
      return;
    }

    this.router.navigate(['manage-users', 'users', createdUser.id], { skipLocationChange: false });
  }

  private async importUser(): Promise<void> {
    await this.dialogService.openDialog2(UserImportModal, { size: 's' }).afterClosed().toPromise();
    this.resolveData();
  }
}
