import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { TranslateService } from '@ngx-translate/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FlexBaseComponent } from '@wephone-core-ui';
import { AgentRepository } from '@wephone-core/model/repository/agent';

@Component({
  selector: 'agent-status-since',
  templateUrl: 'agent-status-since.html',
  styleUrls: [ './agent-status-since.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentStatusSinceComponent extends FlexBaseComponent implements OnInit {
  private _user_id: number;

  get user_id(): number {
      return this._user_id;
  }

  @Input('user_id')
  set user_id(value: number) {
      this._user_id = value;
      this.agent = this.em.getRepository<AgentRepository>('AgentRepository').getObjectByUserId(this._user_id);
  }

  agent: AgentEntity;

  constructor(
    protected translate: TranslateService,
    private em: EntityManager,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }
}
