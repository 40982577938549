import { Component, Input, OnInit } from '@angular/core';
import { ImageRepository } from '@wephone-core/model/repository/image';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Input() filePublicId: string;
  @Input() width: string;
  @Input() height: string;
  url: string;

  constructor(
    private readonly em: EntityManager
  ) { }

  ngOnInit(): void {
    this.url = (this.em.getRepository<ImageRepository>('ImageRepository') as ImageRepository).getFileUrl(this.filePublicId, false);
  }

}
