import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import * as _ from 'lodash';
import { IFlexTableConfig, FlexDataSource, IFlexDataSourceColumn, IFlexTableColumnConfig, FlexTableComponent } from '@wephone-utils';
import { FlexBaseComponent } from '@wephone-core-ui';
import { CustomReportConfigEntity } from '@wephone-core/model/entity/custom_report_config';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { ICustomReportConfig } from '../edit-custom-report-config/custom-report-config.i';
import { interval } from 'rxjs';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { _ti } from '@wephone-translation';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'custom-report-widget',
  templateUrl: './custom-report-widget.component.html',
  styleUrls: ['./custom-report-widget.component.scss']
})
export class CustomReportWidgetComponent extends FlexBaseComponent implements OnInit, OnDestroy {
  @Input() customReportConfig: ICustomReportConfig;

  // --- Widget templates ----
  // @ViewChild('tplAgentStatusDuration', { static: true }) agentStatusDurationTemplate: TemplateRef<any>;
  @ViewChild('tplAgentStatus', { static: true }) agentStatusTemplate: TemplateRef<any>;
  @ViewChild('tplAgentStatusSince', { static: true }) agentStatusSinceTemplate: TemplateRef<any>;
  @ViewChild('tplQueues', { static: true }) queuesTemplate: TemplateRef<any>;
  @ViewChild('tplTextCenter', { static: true }) textCenterTemplate: TemplateRef<any>;
  @ViewChild('tplCampaigns', { static: true }) campaignsTemplate: TemplateRef<any>;
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  dataSource: FlexDataSource;
  tableConfig: IFlexTableConfig;
  reportConfig: CustomReportConfigEntity;
  initialized = false;
  timerId: number;

  constructor(
    private datasourceService: DataSourceService,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataSource = this.datasourceService.createDataSource(this.customReportConfig.dataSourceId);
    console.log('datasource instance', this.dataSource instanceof MatTableDataSource);
    this.tableConfig = {
      // focusedRowHeight: 250,
      align: 'left',
      rowHeight: 80,
      multiSelect: false,
      enableFilter: false,
      enableExportCsv: false,
      columns: undefined,
      uiGetWidgetTemplate: this.getTemplateByWidgetId
    };
    this.loadData().then(resp => this.initialized = true);

    this.addSubscription(
      interval(60000).subscribe(
        () => {
          if (this.dataSource.columnLoaded) {
            if (this.flexTable) {
              this.flexTable.reloadDataSource();
            } else {
              this.dataSource.reloadData();
            }
          }
        }
      )
    );
  }

  async loadData(): Promise<void> {
    const columns: IFlexDataSourceColumn[] = await this.datasourceService.load_columns(this.customReportConfig.dataSourceId);
    const columnsCfg: IFlexTableColumnConfig[] = [];
    for (const c of columns) {
      const colCfg: IFlexTableColumnConfig = {
        name: c.name,
        label: c.displayName || c.name,
        type: c.type,
        uiWidget: c.uiWidget,
        visible: c.visible,
        searchable: c.searchable,
        sortable: c.enableSorting,
      };

      if (_.includes(['inbound_call_answered', 'outbound_call_count', 'outbound_call_answered'], c.name)) {
        colCfg.width = '80px';
      }
      if (_.includes(['status_since', 'aht_inbound', 'aht_outbound'], c.name)) {
        colCfg.width = '100px';
      }
      if (_.includes(['status_since'], c.name)) {
        colCfg.sortable = false;
      }
      if (_.includes(['status'], c.name)) {
        colCfg.width = '150px';
        colCfg.customSortFn = this.sortStatusFn;
      }
      if (_.includes(['agent_name'], c.name)) {
        colCfg.width = '120px';
        this.dataSource.setOrder('agent_name', 'asc'); // Not set colCfg.sort because it not set order datasource immediately
      }
      if (_.includes(['outbound_call_count', 'outbound_call_answered', 'inbound_call_answered'], c.name)) {
        colCfg.align = 'center';
      }

      if (_.includes(['outbound_queues', 'inbound_queues'], c.name)) {
        colCfg.sortable = false;
      }

      columnsCfg.push(colCfg);
    }
    this.tableConfig.columns = columnsCfg;

    const newFilters = this.customReportConfig.filters ? _.cloneDeep(this.customReportConfig.filters) : {};

    this.dataSource.load(newFilters);
  }

  sortStatusFn = (item: any): string => {
    const agent: AgentEntity = AgentRepository.getInstance<AgentRepository>().getObjectByUserId(item.status);
    return agent && _ti(`agent_status.${agent.agent_status_detail}`).trim().toUpperCase() || '';
  }

  getTemplateByWidgetId = widget_id => {
    if (widget_id === 'agent_status') {
      return this.agentStatusTemplate;
    } else if (widget_id === 'agent_status_since') {
      return this.agentStatusSinceTemplate;
    } else if (widget_id === 'inbound_queues') {
      return this.queuesTemplate;
    } else if (widget_id === 'outbound_queues') {
      return this.campaignsTemplate;
    }
    return this.textCenterTemplate;
  }

}
