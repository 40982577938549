import { Component, OnInit, Input, ViewChild, ElementRef, Self, Optional } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { FormControl, NgControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import * as _ from 'lodash';
import { TreeHelper, FlexMatInputWrapper } from '@wephone-utils';
import { DidRepository } from '@wephone-core/model/repository/did';
import { DidEntity } from '@wephone-core/model/entity/did';

@Component({
  selector: 'flex-select-did-input',
  templateUrl: './flex-select-did-input.html',
  styleUrls: ['./flex-select-did-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectDidInput }]
})
export class FlexSelectDidInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() multiple: boolean;
  @Input() showCheckAll: boolean;
  @Input() didList: DidEntity[];
  @Input() hasAnonymous: boolean;
  @ViewChild('flexSelectTreeInput') flexSelectTreeInput: FlexSelectTreeInput;

  // Private member var with default value
  private readonly didRepo = DidRepository.getInstance<DidRepository>();
  private _dids: DidEntity[];

  // Public member var
  myControl = new FormControl();
  didNodes: FlexSelectTreeNode[] = new Array<FlexSelectTreeNode>();

  constructor(
    elRef: ElementRef,
    @Optional() @Self() ngControl: NgControl,
    readonly em: EntityManager,
  ) {
    super(ngControl, elRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadDid();
  }

  protected loadDid(): void {
    if (this.didList && _.isArray(this.didList)) {
      this._dids = this.didList;
    } else {
      const dids: DidEntity[] = this.didRepo.getObjectList();
      this._dids = dids.filter(d => d.hasRoutingData() && d.routing_status);
    }

    const nodeList = this._dids.map(did => this.getTreeNode(did));
    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    if (this.hasAnonymous) {
      const anonymousNode = this.getTreeNode(this.didRepo.getAnonymousDid());
      sortedNodeList.unshift(anonymousNode);
    }
    this.didNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }

  private getTreeNode(did: DidEntity): any {
    const countryFlag = did.country_code && `<span class="flag-icon flag-icon-${did.country_code.toLowerCase()}"></span>  ` || '';
    return {
      id: did.id,
      name: countryFlag + did.number_with_name
    };
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-did-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    console.log('flex-select-did click event', event);
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }

}
