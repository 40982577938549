import { Injectable } from '@angular/core';
import { ENTERPRISE_CRM } from '@wephone-core/constants/crm-config';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { EntityManager } from './entity_manager';

export interface GetContactOpts {
  crmId: number;
  contactId: string;
  contactType: string;
}

@Injectable()
export class RoutingServiceService {
  private readonly GET_CONTACT_BY_ID_URL = 'crm/contact/get-contact-by-id';
  private readonly UPDATE_CONTACT_URL = 'crm/contact/update';
  private readonly CREATE_TASK_URL = 'crm/contact/create-task';
  private readonly CONTACT_OWNER_URL = 'crm/contact-owner';
  private readonly CONTACT_HISTORY_URL = 'crm/contact-history';
  private readonly FIND_CRM_CONTACT_INFO_BY_NUMBER_PATH = 'ws-v2/crm/find-contact-info-by-number';

  private readonly VERIFY_URL_URL = 'routing_service/verify-url';
  private readonly crmRepo: EnterpriseCrmRepository;

  constructor(
    private readonly em: EntityManager,
    private readonly httpEngine: HttpEngine,
  ) {
    this.crmRepo = this.em.getRepository<EnterpriseCrmRepository>('EnterpriseCrmRepository');
  }

  async getActiveCRM(useCache = true): Promise<EnterpriseCrmEntity> {
    await this.crmRepo.findAll(useCache);
    return this.crmRepo.getMyEnterpriseCrm();
  }

  getConfigCRM(crm: EnterpriseCrmEntity): any {
    if (!crm || !crm.type) {
      return undefined;
    }
    return ENTERPRISE_CRM.crm_list[crm.type];
  }

  getContactById(opts: GetContactOpts): Promise<any> {
    return this.httpEngine.apiGetV2(this.GET_CONTACT_BY_ID_URL, opts);
  }

  update(data: any): Promise<any> {
    return this.httpEngine.apiPostV2(this.UPDATE_CONTACT_URL, data);
  }

  createTask(data: any): Promise<any> {
    return this.httpEngine.apiPostV2(this.CREATE_TASK_URL, data);
  }

  getOwner(phoneNumber: string): Promise<any> {
    return this.httpEngine.apiGetV2(this.CONTACT_OWNER_URL + `/${phoneNumber}`);
  }

  getHistory(phoneNumber: string): Promise<any> {
    return this.httpEngine.apiGetV2(this.CONTACT_HISTORY_URL + `/${phoneNumber}`);
  }

  getCrmContactByNumber(crmId: number, phoneNumber: string): Promise<any> {
    return this.httpEngine.get(this.FIND_CRM_CONTACT_INFO_BY_NUMBER_PATH + `/${crmId}/${phoneNumber}`);
  }

  verify(data: any): Promise<any> {
    return this.httpEngine.apiPostV2(this.VERIFY_URL_URL, data);
  }
}
