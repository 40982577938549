<div class="pa-1" fxFill fxFlex>
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'ivr_module.content.name'|translate }}</mat-label>
          <input matInput placeholder="{{ 'ivr_module.content.name'|translate }}" formControlName="name" [required]="true">

          <mat-error [flexFormControlError]="form.get('name')"
            [customMessages]="{
              'name_exist': 'public.message.name_exist' | translate
            }"></mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'ivr_module.content.script_type'|translate }}</mat-label>
          <mat-select formControlName="scriptType" placeholder="{{ 'ivr_module.content.script_type'|translate }}" [compareWith]="compareScriptType">
            <mat-option *ngFor="let moduleType of ivrModuleTypes" value="{{ moduleType }}">{{ ivrModuleTypeLabels[moduleType] }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'ivr_module.content.script_url'|translate }}</mat-label>
          <input matInput placeholder="{{ 'ivr_module.content.script_url'|translate }}" formControlName="scriptUrl">
        </mat-form-field>
      </div>
    </form>
  </div>
  