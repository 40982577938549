<div id="global-search-panel" perfectScrollbar class="full-width" fxLayout="column" fxLayoutGap="10px">
  <ng-container *ngIf="hasResult;else NoResult">
    <div *ngIf="results.users.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.user'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let user of results.users" (click)="gotoPageUser(user)" class="link-search">{{ user.name }}</a>
      </div>
    </div>

    <div *ngIf="results.callqueues.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.queue'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let callqueue of results.callqueues" (click)="gotoPageQueue(callqueue)" class="link-search">{{
          callqueue.queue_name }}</a>
      </div>
    </div>

    <div *ngIf="results.outcallcampaigns.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.campaign'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let campaign of results.outcallcampaigns" (click)="gotoPageCampaign(campaign)" class="link-search"
          [ngClass]="{'archived': campaign.is_archived, 'finished': campaign.is_finished}">{{ campaign.name }}</a>
      </div>
    </div>

    <div *ngIf="results.dids.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.did'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let did of results.dids" (click)="gotoPageDid(did)" class="link-search">{{ did.number_with_name }}</a>
      </div>
    </div>

    <div *ngIf="results.groups.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.group'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let group of results.groups" (click)="gotoPageGroup(group)" class="link-search">{{ group.name }}</a>
      </div>
    </div>

    <div *ngIf="results.ivrcustommenus.length">
      <h3 class="ml-1 v"><strong>{{ 'quick_search.content.ivrmenu'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let ivrcustommenu of results.ivrcustommenus" (click)="gotoPageIvr(ivrcustommenu)"
          class="link-search">{{ ivrcustommenu.name }}</a>
      </div>
    </div>

    <div *ngIf="results.siptrunks.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.sip_trunk'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let siptrunk of results.siptrunks" (click)="gotoPageSipTrunk(siptrunk)"
          class="link-search">{{ siptrunk.name }}</a>
      </div>
    </div>

    <div *ngIf="results.pausereasons.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.pause_reason'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let pausereason of results.pausereasons" (click)="gotoPagePauseReason(pausereason)"
          class="link-search">{{ pausereason.label }}</a>
      </div>
    </div>

    <div *ngIf="results.cdrs.length">
      <h3 class="ml-1 title-search"><strong>{{ 'quick_search.content.calls'|translate }}</strong></h3>
      <div class="ml-2" fxLayout="column">
        <a *ngFor="let cdr of results.cdrs" (click)="gotoPageCdrDetail(cdr)" class="link-search">{{
          'quick_search.calls.call_description'|translate:{called_number: cdr.called_number|displayPhoneNumber,
          calling_number: cdr.calling_number|displayPhoneNumber, start_dt: cdr.start_dt|timeFormatDateTimeShort} }}</a>
      </div>
    </div>

  </ng-container>

  <ng-template #NoResult>
    <div class="ma-2">
      {{'public.message.no_results'|translate}}
    </div>
  </ng-template>
</div>
