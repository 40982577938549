import { Component, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { DidRepository } from '@wephone-core/model/repository/did';
import { DidEntity } from '@wephone-core/model/entity/did';
import { Subject, ReplaySubject } from 'rxjs';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import {
  DialogService,
  IFlexTableConfig,
  parseDateTime,
  regexSearch,
  IFlexSelectOptions,
  PhoneNumberService,
  localNow,
  MessageService,
  FlexTableComponent,
  FormService,
} from '@wephone-utils';
import * as _ from 'lodash';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { VoiceMailRepository } from '@wephone-core/model/repository/voicemail';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { SoundService } from '@wephone-audio';
import { UserService } from '@wephone/services/user.service';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
// import { IEntity } from '@wephone-core/model/model.interface';
import { FlexBasePage } from '@wephone-core-ui';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { VoiceMailEntity } from '@wephone-core/model/entity/voicemail';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { _tk, _ti } from '@wephone-translation';
import { DateAdapter } from '@angular/material/core';
import { FlexDatepickerMin, PhoneNumberValidated } from '@wephone/services/form-validator';
import { AgentService } from '@wephone/services/agent.service';
import { VMReaderData, VMReaderDataMsg } from '@wephone-core/model/repository/voicemail.i';
import { ICallInfoDataChanged, ICallLog } from '@wephone-app-phone/pages/history/calllog.i';
import { CallInfoModalComponent } from '@wephone-common/modals/call-info-modal/call-info-modal.component';

export interface VoicemailQueueType {
  id: number;
  queue_id: number;
  calling_number: string;
  called_number: string;
  duration: number;
  is_read: number;
  cdr_id: number;
  is_archived: number;
  queue_name: string;
  readers?: number[];
  sound_id?: string;
  receive_dt: string;
  file_entry_ready?: boolean;
  action?: string;
  handled_user_ids?: number[];
  out_qualification_id?: number;
  in_qualification_id?: number;
  qualification?: string;
  has_qualification?: number; // 0 | 1;
  qualification_ids?: number[];
  qualification_comment?: string;
}

@Component({
  selector: 'app-myvoicemailqueue',
  templateUrl: './myvoicemailqueue.component.html',
  styleUrls: ['./myvoicemailqueue.component.scss']
})
export class MyvoicemailqueueComponent extends FlexBasePage implements OnDestroy {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  private readonly voicemailRepo = VoiceMailRepository.getInstance<VoiceMailRepository>();
  private readonly didRepo = DidRepository.getInstance<DidRepository>();
  private readonly groupRepo = UserGroupRepository.getInstance<UserGroupRepository>();
  private readonly _onDestroy = new Subject<void>();

  didList = [];
  groups = [];
  unlistened_number: number;
  totalNumber: number;
  message_type = 'queue';

  filteredDidsMulti: ReplaySubject<DidEntity[]> = new ReplaySubject<DidEntity[]>(1);

  tableConfig: IFlexTableConfig;

  groupMultiFilterCtrl: FormControl = new FormControl();
  didMultiFilterCtrl: FormControl = new FormControl();
  dataSource: FlexDataSource;
  filterForm: FormGroup;
  filtersReadonly: boolean;
  canTakeAction: boolean;

  filterDidsSelectOptions: IFlexSelectOptions;
  userId: number;

  constructor(
    // private translate: TranslateService,
    datasourceService: DataSourceService,
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
    public userService: UserService,
    public fileEntryService: FileEntryService,
    public dialogService: DialogService,
    private readonly soundService: SoundService,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly authService: AuthenticationService,
    private readonly configManager: ConfigManager,
    private readonly agentService: AgentService,
    private readonly messageService: MessageService,
    private readonly formService: FormService,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;

    this.filterForm = this.fb.group({
      start_dt: [localNow().toJSDate(), [Validators.required]],
      end_dt: [localNow().toJSDate(), [Validators.required]],
      groups: [[]],
      queues: [[]],
      calling_number: ['', [PhoneNumberValidated()]],
      numbers: [[]],
      listen_state: [''],
      archived: [false],
    });

    this.addSubscription(
      this.startDateControl.valueChanges.subscribe(val => {
        this.endDateControl.markAsTouched();
      })
    );

    this.unlistenInfo();
    this.didList = this.didRepo.getObjectList();

    this.filterDidsSelectOptions = {
      compareWith: (a: DidEntity, b: DidEntity) => (a && b ? a.id === b.id : a === b),
      filterFunc: (item: DidEntity, filterString): boolean => {
        return regexSearch(item.number_with_name, filterString);
      }
    };

    this.groups = this.groupRepo.getObjectList();
    this.dataSource = datasourceService.createDataSource('pbx_voicemail_queue');
    this.dataSource.setOrder('receive_dt', 'desc');

    this.userId = this.authService.getUserId();
    this.canTakeAction = this.authService.isAdmin() || this.authService.isSupervisor();

    this.tableConfig = {
      multiSelect: this.canTakeAction,
      enableExportCsv: this.canTakeAction,
      columns: [
        { name: 'calling_number', label: _tk('voicemail.calling_number'), hiddenOn: 'xs', enableTooltip: true, renderCell: this.renderCallingNumber },
        { name: 'called_number', label: _tk('voicemail.called_number'), hiddenOn: 'xs' },
        { name: 'receive_dt', label: _tk('voicemail.received_date') },
        { name: 'queue_name', label: _tk('voicemail.queue_name'), enableTooltip: true },
        { name: 'duration', label: _tk('recording_call.length') },
        { name: 'readers', label: _tk('voicemail.listen_by'), hiddenOn: 'lt-md' },
        { name: 'action', label: _tk('public.action') }
      ]
    };

    if (this.canTakeAction) {
      this.tableConfig.listActions = {
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              hint: 'public.delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ],
          secondary: []
        }
      };
    }
  }

  get startDateControl(): FormControl {
    return this.filterForm.get('start_dt') as FormControl;
  }

  get endDateControl(): FormControl {
    return this.filterForm.get('end_dt') as FormControl;
  }

  get groupsControl(): FormControl {
    return this.filterForm.get('groups') as FormControl;
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilter = (d: Date): boolean => {
    const startDate = this.startDateControl.value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  setArchiveActionButton(): void {
    if (!this.canTakeAction) {
      return;
    }
    this.tableConfig.listActions.selectionActions.secondary = [
      this.filterForm.get('archived').value ?
        {
          id: 'unarchive',
          icon: 'unarchive',
          hint: 'public.unarchive',
          callback: data => {
            return this.bulkUnarchive(data.selectedItems);
          }
        } :
        {
          id: 'archive',
          icon: 'archive',
          hint: 'public.archive',
          callback: data => {
            return this.bulkArchive(data.selectedItems);
          }
        }
    ];
  }

  resolveData(): void {
    this.filteredDidsMulti.next(this.didList);

    this.didMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterDidMulti();
    });

    this.reloadClick();
    this.addSubscription(
      this.messageService.subscribeVMReaderUpdate((msg: VMReaderDataMsg) => {
        this.updateVoicemailReader(msg.data);
      })
    );
  }

  private updateVoicemailReader(data: VMReaderData): void {
    const item = this.dataSource.data && this.dataSource.data.find(x => x.id === data.vm_id);
    if (!item) {
      return;
    }

    if (!_.isArray(item.readers)) {
      item.readers = [data.user_id];
      item.is_read = 1;
      return;
    }

    if (!item.readers.includes(data.user_id)) {
      item.readers.push(data.user_id);
    }
  }

  exportCsv(): void {
    const formFilters = this.getFilters();
    this.dataSource.export_csv(formFilters);
  }

  isPlayerVisible(): boolean {
    return this.soundService.isPlayerVisible();
  }

  private getFilters(): any {
    const filterData = this.formService.normalizeData(this.filterForm.value);
    const formFilters = _.cloneDeep(filterData);
    const startDate = parseDateTime(formFilters.start_dt);
    const endDate = parseDateTime(formFilters.end_dt);

    return _.extend(formFilters, {
      start_dt: startDate.toISODate(),
      end_dt: endDate.toISODate(),
      called_numbers: this.didRepo.getObjectListByIds(formFilters.numbers).map(d => d.number),
      calling_number: formFilters.calling_number && formFilters.calling_number.trim() || null,
      numbers: undefined
    });
  }

  async reloadClick(): Promise<void> {
    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    const newFilters = this.getFilters();
    this.setArchiveActionButton();
    this.unlistenInfo();

    try {
      if (this.flexTable) {
        // this.dataSource.setFilter(newFilters);
        await this.flexTable.reloadDataSource(newFilters);
      } else {
        await this.dataSource.load(newFilters);
      }

      this.filtersReadonly = true;
    } catch (e) {
      console.error('Cannot reload data', e);
      this.showError(_ti('public.message.error_occurred'));
    }
  }

  private filterDidMulti(): void {
    if (!this.didList) {
      return;
    }

    const search = this.didMultiFilterCtrl.value;
    if (!search) {
      this.filteredDidsMulti.next(this.didList);
      return;
    }

    this.filteredDidsMulti.next(this.didList.filter(did => did.id > 0 && regexSearch(did.display_number, search)));
    // search = search.toLowerCase();u
    // this.filteredDidsMlti.next(this.didList.filter((did) => did.display_number.toLowerCase().indexOf(search) > -1));
  }

  unlistenInfo(): void {
    const type: string = this.message_type;
    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    const filters = this.getFilters();
    this.voicemailRepo.getUnlistenedNumber(type, filters).then((resp: { number: number, total: number }) => {
      this.unlistened_number = resp.number || 0;
      this.totalNumber = resp.total || 0;
      this.detectChanges();
    });
  }

  callingNumberRenderer(item: VoicemailQueueType): string {
    return this.phoneNumberService.getDisplayNumber(item.calling_number);
  }

  calledNumberRenderer(item: VoicemailQueueType): string {
    return this.phoneNumberService.getDisplayNumber(item.called_number);
  }

  downloadVoicemail(item: VoicemailQueueType): void {
    const fileName = 'voicemail-' + item.action + '.mp3';
    const entity = item;
    const download_url = this.fileEntryService.getFileUrl(entity.sound_id, true);
    this.fileEntryService.download(download_url, fileName);
  }

  bulkDelete(entities: VoicemailQueueType[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        const itemIds: number[] = entities.map(x => x.id);

        try {
          const data = await this.voicemailRepo.deleteItemsByIds(itemIds);
          console.log('deleteItems', data);
          this.deleteRows(entities, true);
          this.successToast(_ti('public.message.delete_success'));
        } catch (error) {
          console.error('Delete Voicemail Error: ', error);
        }
      }
    );
  }

  private deleteRows(entities: VoicemailQueueType[], updateListenInfo = true): void {
    for (const item of entities) {
      this.dataSource.deleteRow(item);
    }

    if (updateListenInfo) {
      this.unlistenInfo();
    }
  }

  detailVoicemail(item: VoicemailQueueType): void {
    const call: ICallLog = {
      id: item.cdr_id,
      is_voicemail: 1,
      message_id: item.id,
      is_outgoing: 0,
      called_number: item.called_number,
      calling_number: item.calling_number,
      queue_ids: [item.queue_id],
      start_time: null,
      duration: item.duration,
      voicemail_public_id: item.sound_id,
      handled_user_ids: item.handled_user_ids,
      out_qualification_id: item.out_qualification_id,
      in_qualification_id: item.in_qualification_id,
      qualification: item.qualification,
      has_qualification: item.has_qualification,
      qualification_ids: item.qualification_ids,
      qualification_comment: item.qualification_comment,
    };

    this.dialogService.openDialog2(
      CallInfoModalComponent,
      {
        data: {
          cdr_id: call.id
        },
        padding: false
      },
      (resp: ICallInfoDataChanged) => {
        if (!resp) {
          return;
        }

        let reloadListenInfo = false;
        if (resp.voicemail_has_deleted) {
          // Reload rows after deleting item from dialog
          this.deleteRows([item], false);
          reloadListenInfo = true;
        }

        if (resp.vm_message_is_read) {
          item.is_read = 1;
          reloadListenInfo = true;
        }

        if (resp.vm_message_readers) {
          item.readers = resp.vm_message_readers;
          reloadListenInfo = true;
        }

        if (reloadListenInfo) {
          this.unlistenInfo();
        }

        this.flexTable.detectChanges();
      }
    );
  }

  deleteVoicemail(item: VoicemailQueueType): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      () => {
        if (item) {
          this.bulkDelete([item]);
        }
      }
    );
  }

  async bulkArchive(voicemails: VoicemailQueueType[]): Promise<any> {
    try {
      const items: any[] = [];
      for (const voicemail of voicemails) {
        const item: VoiceMailEntity = this.voicemailRepo.create() as VoiceMailEntity;
        item.setObjectData(voicemail);
        items.push(item);
      }
      await this.voicemailRepo.archiveItems(items);

      // Filter the result after archiving
      if (!this.filterForm.get('archived').value) {
        for (const voicemail of voicemails) {
          this.dataSource.deleteRow(voicemail);
        }
      }
      this.successToast(_ti('public.message.archive_success'));

      this.unlistenInfo();
    } catch (error) {
      console.error('Toogle archive failure', error);
      this.successToast(_ti('public.message.archive_failure'));
    }
  }

  async bulkUnarchive(voicemails: VoicemailQueueType[]): Promise<any> {
    try {
      const items: any[] = [];
      for (const voicemail of voicemails) {
        const item: VoiceMailEntity = this.voicemailRepo.create() as VoiceMailEntity;
        item.setObjectData(voicemail);
        items.push(item);
      }
      await this.voicemailRepo.unarchiveItems(items);

      // Filter the result after unarchiving
      if (this.filterForm.get('archived').value) {
        for (const voicemail of voicemails) {
          this.dataSource.deleteRow(voicemail);
        }
      }
      this.successToast(_ti('public.message.unarchive_success'));

      this.unlistenInfo();
    } catch (error) {
      console.error('Toogle archive failure', error);
      this.successToast(_ti('public.message.unarchive_failure'));
    }
  }

  changeFilter(): void {
    this.filtersReadonly = false;
  }

  rendererQueue(queueIds: number[]): string[] {
    const queueNames = [];
    const queueList = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectList() || [];
    queueList.forEach((queue: CallQueueEntity) => {
      queueIds.forEach((qId: number) => {
        if (queue.id === qId) {
          queueNames.push(queue.queue_name);
        }
      });
    });

    return queueNames;
  }

  rendererGroup(groupIds: number[]): string[] {
    const groupNames = [];
    const groupList = this.em.getRepository<UserGroupRepository>('UserGroupRepository').getObjectList() || [];
    groupList.forEach((g: UserGroupEntity) => {
      groupIds.forEach((gId: number) => {
        if (g.id === gId) {
          groupNames.push(g.name);
        }
      });
    });

    return groupNames;
  }

  rendererNumber(ids: number[]): string[] {
    return this.didRepo.getObjectListByIds(ids).map(d => d.display_number);
  }

  async updateListened(item: VoicemailQueueType): Promise<void> {
    if (item.is_read && _.includes(item.readers, this.userId)) {
      console.error('Voice mail has been already listened');
      return;
    }
    try {
      if (!item.sound_id) {
        throw new Error('No sound public id');
      }
      const ret = await this.voicemailRepo.markVoiceMailListened(item.sound_id);
      console.log('updateVoiceMailListened', ret);

      // if (ret && ret.item) {
      //   item.readers = ret.item.readers;
      // }
      item.is_read = 1;
      if (_.isEmpty(item.readers)) {
        item.readers = [];
      }
      if (!_.includes(item.readers, this.userId)) {
        item.readers.push(this.userId);
      }

      this.unlistenInfo();
    } catch (error) {
      console.error('Update listened failure', error);
    }
  }

  async makeCall(item: VoicemailQueueType): Promise<void> {
    if (!item.called_number || !item.calling_number || !item.queue_id) {
      console.warn('Cannot make outcall');
      return;
    }

    try {
      await this.agentService.make_outcall(item.calling_number, item.queue_id, item.called_number);
    } catch (error) {
      this.showError(_ti('public.message.error_occurred'));
    }
  }

  canMakeCall(): boolean {
    return this.authService.isAdmin() || this.authService.isSupervisor() || this.authService.isAgent();
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  changePeriod(periodSelectData: { period: string, start_dt: Date, end_dt: Date }): void {
    if (periodSelectData.start_dt) {
      this.startDateControl.setValue(periodSelectData.start_dt);
    }
    if (periodSelectData.end_dt) {
      this.endDateControl.setValue(periodSelectData.end_dt);
    }

    this.reloadClick();
  }

  clearValue(event: MouseEvent, controlName: string, multiple: boolean = false): void {
    event.stopPropagation();
    this.filterForm.get(controlName).setValue(multiple ? [] : null);
  }

  renderCallingNumber = (row: any, colName: string): string => {
    const callingNumber = this.phoneNumberService.getDisplayNumber(row[colName]);
    if (row.client_name) {
      return `${callingNumber} (${row.client_name})`;
    }
    return callingNumber;
  }
}
