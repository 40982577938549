import * as _ from 'lodash';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { DidEntity } from '@wephone-core/model/entity/did';
// import { IOpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar.i';
// import { NumberRoutingEditBaseComponent } from '../number-routing-edit-base';
import { _tk, _ti } from '@wephone-translation';
import { EntityManager } from '@wephone-core/wephone-core.module';
// import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
// import { IRoutingAppDestinationData } from '@wephone-core/routing-app/routing-app.interface';
import { CallDestinationComponent } from '@wephone/components/call-destination/call-destination.component';
import { NumberOpeningCalendar } from '@wephone/components/number-opening-calendar/number-opening-calendar.component';
import { CalendarInputParams } from '@wephone-utils';
import { NumberRoutingEditComponent } from '../number-routing-edit/number-routing-edit';

@Component({
  selector: 'app-number-routing-multi-edit',
  templateUrl: './number-routing-multi-edit.component.html',
  styleUrls: ['./number-routing-multi-edit.component.scss']
})
export class NumberRoutingMultiEditComponent extends NumberRoutingEditComponent implements OnInit, OnDestroy {
  @Input() editingList: DidEntity[];
  @ViewChild('callDestination') callDestination: CallDestinationComponent;
  @ViewChild('numberOpeningCalendar') numberOpeningCalendar: NumberOpeningCalendar;

  // ALLOW_SCROLL = true;
  MULTI_EDITOR = true;

  // updateLayout = false;
  // showCalendar = false;
  // didPreview: DidEntity;

  async ngOnInit(): Promise<void> {
    await this.loadReferenceData();
    // this.setDid();
    this.resetDummyDidValue();
    this.initForm();
    // await super.ngOnInit();
  }

  protected initForm(): void {
    this.form = this.fb.group({
      action_office_hour: this.getActionOfficeHour(),
      master_did: this.did.master_did,
      // name: undefined,
      welcome_message: this.did.welcome_message,
      crm: this.did.enterprise_crm,
    });

    this.addSubscription(this.form.valueChanges.subscribe(val => {
      this.onFormValueChange();
    }));
  }

  // setEditingData(data: any): void {
  //   super.setEditingData(data);
  //   this.resetForm();
  // }

  // setDid(item?: DidEntity): void {
  //   super.setDid(item);
  // }

  private resetDummyDidValue(): void {
    this.setDid();
    const fieldsToEdit = this.getUpdateFields();

    for (const k of Object.keys(fieldsToEdit)) {
      if (fieldsToEdit.hasOwnProperty(k)) {
        const keyName = fieldsToEdit[k] || k;
        let sameValue = true;
        for (let i = 1; i < this.editingList.length; i++) {
          let firstValue = this.editingList[0][keyName];
          let compareValue = this.editingList[i][keyName];

          if (['action_office_hour', 'action_not_office_hour'].indexOf(keyName) > -1) {
            firstValue = JSON.stringify(firstValue);
            compareValue = JSON.stringify(compareValue);
          }

          if (compareValue !== firstValue) {
            sameValue = false;
            break;
          }
        }
        if (sameValue) {
          this.did[keyName] = this.editingList[0][keyName];
        }
      }
    }
    this.setDidPreview();
  }

  private getUpdateFields(): object {
    return {
      action_office_hour: undefined,
      // name: undefined,
      welcome_message: undefined,
      master_did_id: undefined,
      calendar_id: undefined,
      opening_hour_file_id: undefined,
      vm_enabled: undefined,
      action_not_office_hour: undefined,
      crm_id: undefined,
    };
  }

  resetForm(): void {
    this.resetDummyDidValue();
    console.log('resetForm this.did', this.did);
    console.log('getFormData', this.getFormData(this.did));
    if (this.numberOpeningCalendar) {
      this.numberOpeningCalendar.resetForm();
    }

    // this.setDidPreview();
    this.form.reset(this.getFormData(this.did));
    // this.form.markAsPristine();
    this.pristineForm();
    this.onFormValueChange();
  }

  get showBullet(): boolean {
    return this.showCalendar && this.hasCalendar;
  }

  async submitForm(): Promise<any> {
    try {
      this.validate();

      // const changeset: {
      //   action_office_hour: any;
      //   master_did: DidEntity;
      //   // name: string;
      //   welcome_message: string;
      // } = this.getChangeSet() || {};

      const updatedData: any = this.getChangeSet();

      // if (changeset.hasOwnProperty('master_did')) {
      //   updatedData.master_did_id = changeset.master_did ? changeset.master_did.id : null;
      //   delete updatedData.master_did;
      // }

      // Update other fields
      const newNumberOpeningCalendarValue: CalendarInputParams = this.getNumberOpeningCalendarValue();

      // Update default did
      if (newNumberOpeningCalendarValue) {
        _.extend(updatedData, newNumberOpeningCalendarValue);
      }

      this.did.setObjectData(updatedData, true);

      const ids: number[] = this.editingList.map(i => i.id);

      const returnedData = await this.didRepo.saveAttrsBulk(ids, this.did, Object.keys(updatedData));
      this.toast.show(_ti('did.message.update_success'));

      const didId = returnedData['object_ids'][0];
      const updatedDid: DidEntity = EntityManager.getInstance().getRepository('DidRepository').getObjectById(didId);
      if (updatedDid) {
        this.setDid(updatedDid);
      }

      // this.resetForm();

      this.pristineForm();
      this.updateDisplayedRouting();

      return returnedData;
    } catch (error) {
      console.error('Update failure', error);
      const msg: string = error && error.message ? error.message : _ti('public.message.update_failure');
      this.toast.showError(msg);
    }
  }
}
