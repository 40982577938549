import { BaseRepository } from './base_repository';
import { MyCallCenterStatus } from '../entity/my_callcenter_status';

export class MyCallCenterStatusRepository extends BaseRepository {
  ENTITY_CLASS = MyCallCenterStatus;
  protected USE_PUSH_API = true;

  private emptyStatus: MyCallCenterStatus = new MyCallCenterStatus();

  getMyStatus(): MyCallCenterStatus {
    if (this.object_list && this.object_list.length > 0) {
      return this.object_list[0];
    }
    return this.emptyStatus;
  }
}
