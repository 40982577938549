<div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'enterprise_sip_gateway.form.name'|translate}}</mat-label>
        <input matInput formControlName="name" data-ci="name"
          [placeholder]="'enterprise_sip_gateway.form.name'|translate">

        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="10px">
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'enterprise_sip_gateway.form.model'|translate}}</mat-label>
          <mat-select formControlName="model" data-ci="model">
            <!--  interface="popover" -->
            <mat-option *ngFor="let model of modelList" [value]="model.value">
              {{ model.label }}
            </mat-option>
          </mat-select>

          <mat-error [flexFormControlError]="form.get('model')"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <mat-label>{{'enterprise_sip_gateway.form.firmware'|translate}}</mat-label>
          <mat-select formControlName="firmware" data-ci="firmware">
            <!--  interface="popover" -->
            <mat-option *ngFor="let firmware of firmwareObject[form.get('model').value] || []" [value]="firmware.value">
              {{ firmware.label }}
            </mat-option>
          </mat-select>

          <mat-error [flexFormControlError]="form.get('firmware')"></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'enterprise_sip_gateway.form.mac'|translate}}</mat-label>
        <input matInput formControlName="mac" data-ci="mac" [placeholder]="'enterprise_sip_gateway.form.mac'|translate">
        
        <mat-error [flexFormControlError]="form.get('mac')"
          [customMessages]="{
            pattern: 'enterprise_sip_gateway.validator.mac_address_pattern' | translate
          }"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'enterprise_sip_gateway.form.port_count'|translate}}</mat-label>
        <input type="number" matInput formControlName="port_count" step="1" min="1" max="99" data-ci="port_count"
          [placeholder]="'enterprise_sip_gateway.form.port_count'|translate">
          
        <mat-error [flexFormControlError]="form.get('port_count')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
