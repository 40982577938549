import * as _ from 'lodash';
import { Component, OnInit, ElementRef, Optional, Input, ChangeDetectorRef } from '@angular/core';
import { DialogService, FlexMatInputBase, regexSearch, ToastService } from '@wephone-utils';
import { NgControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { DidEntity, DidRoutePriority } from '@wephone-core/model/entity/did';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { TranslateService } from '@ngx-translate/core';
import { AddDidToQueueModalComponent } from '@wephone/modals/call-queue/add-did-to-queue-modal/add-did-to-queue-modal.component';
import { EditDidInQueueModalComponent } from '@wephone/modals/call-queue/edit-did-in-queue-modal/edit-did-in-queue-modal.component';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DidRepository } from '@wephone-core/model/repository/did';
import { Router } from '@angular/router';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'queue-did-list-form-input',
  templateUrl: './queue-did-list.component.html',
  styleUrls: ['./queue-did-list.component.scss']
})
export class QueueDidListFormInput extends FlexMatInputBase implements OnInit, MatFormFieldControl<any> {
  @Input() queue: CallQueueEntity;
  @Input() notInUsed: boolean;

  searchText: string;
  priorityList: {
    label: string;
    value: number;
  }[];
  isAdmin: boolean;

  constructor(
    elRef: ElementRef,
    @Optional() ngControl: NgControl,
    cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private authService: AuthenticationService,
    public toast: ToastService,
    private router: Router,
  ) {
    super(ngControl, elRef, cdr);

    this.priorityList = [
      {
        value: DidRoutePriority.VeryHigh,
        label: _ti('call_queue.content.priority_level.very_high')
      },
      {
        value: DidRoutePriority.High,
        label: _ti('call_queue.content.priority_level.high')
      },
      {
        value: DidRoutePriority.Normal,
        label: _ti('call_queue.content.priority_level.normal')
      },
      {
        value: DidRoutePriority.Low,
        label: _ti('call_queue.content.priority_level.low')
      },
      {
        value: DidRoutePriority.VeryLow,
        label: _ti('call_queue.content.priority_level.very_low')
      }
    ];
  }

  get controlType(): string {
    return 'queue-did-list-form-input';
  }

  get didList(): DidEntity[] {
    const filterValue: string = (this.searchText || '').trim();
    if (!filterValue) {
      return this.value && this.value.sort(
        this.sortDidByPriority
      );
    }

    return _.filter(this.value, i =>
      regexSearch(i.display_number, this.searchText) ||
      regexSearch(i.name, this.searchText) ||
      regexSearch(i.welcome_message, this.searchText)
    ).sort(
      this.sortDidByPriority
    );
  }

  private sortDidByPriority = (a: DidEntity, b: DidEntity): number => {
    if (a.route_priority === b.route_priority) {
      return a.name > b.name ? 1 : -1;
    }

    return a.route_priority > b.route_priority ? 1 : -1;
  }

  ngOnInit(): void {
    this.isAdmin = this.authService.isAdmin();
  }

  private _updateDidInQueue(did: DidEntity): void {
    const updateDid: DidEntity = _.find(this.value, (i: DidEntity) => {
      return i.getId() === did.getId();
    });
    updateDid.setObjectData(did.dumpObjectData(), false);
  }

  openModalAddDidToQueue(): void {
    const modalConfig: MatDialogConfig = {
      data: {
        queue_did_list: this.value,
        not_in_used: this.notInUsed
      }
    };
    this.dialogService.openDialog2(AddDidToQueueModalComponent, modalConfig, ret => {
      if (ret && ret.did) {
        ret.did.routeToQueue(this.queue);
        this.openModalEditDid(ret.did, true);
      }
    });
  }

  openModalEditDid(did: DidEntity, addnew: boolean = false): void {
    const modalConfig: MatDialogConfig = {
      data: { did }
    };
    this.dialogService.openDialog2(EditDidInQueueModalComponent, modalConfig, (ret: { did: DidEntity }) => {
      if (ret && addnew === true) {
        this.value.push(ret.did);
      }
      if (ret && ret.did && addnew === false) {
        this._updateDidInQueue(ret.did);
      }
      this.onChange();

      this.cdr.detectChanges();
    });
  }

  removeDid(items: DidEntity[]): Promise<any> {
    if (_.isEmpty(items)) {
      console.error('No item selected');
      return;
    }

    return this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('user.title.delete'), () => {
      EntityManager.getRepository<DidRepository>('DidRepository').clear(items).then(
        response => {
          const removeIds = response.map(d => d.id);
          const newValue = _.filter(this.value, d => {
            return !removeIds.includes(d.id);
          });

          this.value = newValue;
        },
        response => {
          this.toast.showError(_ti('public.message.delete_failure'));
        }
      );
    });
  }

  gotoPageDid(did: DidEntity): void {
    this.router.navigate(['number-routing', did.id], {skipLocationChange: false});
  }
}
