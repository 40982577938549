import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { SubscriptionEntity } from '@wephone-core/model/entity/subscription';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';

export class SubscriptionRepository extends BaseRepository {
  ENTITY_CLASS = SubscriptionEntity;
  protected RELATED_REPOSITORIES = ['enterprise'];
  protected URL_PREFIX = 'subscription';

  protected getRemoteData(use_cache = true, filters?: any): Promise<any> {
    if (this.data_loaded && use_cache) {
      return Promise.resolve(this.object_list);
    }

    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/subscription'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get subscription list error', response);
          return [];
        }
      );
  }
}
