<mat-card [ngClass]="{'mat-card-flat': flatLayout}">
  <mat-card-content class="nopadding">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex>
        <div *ngFor="let openinghour of openinghours">
          <b>{{ weekdayLabels[openinghour.week_day[0]] | translate }}</b>
          <span ng-if="openinghour.week_day.length != 1">
            {{ 'public.to'|translate }}
            <b>{{ weekdayLabels[openinghour.week_day[openinghour.week_day.length -1]] | translate }}</b>
          </span>
          <span>,</span>
          <span>
            {{ 'public.from' | translate }}
            <b>{{ openinghour.start_time }}</b>
            {{ 'public.to'|translate }}
            <b>{{ openinghour.end_time }}</b>
          </span>
        </div>
      </div>
      <button mat-icon-button type="button" color="primary" (click)="edit()" *ngIf="clickToEdit">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
