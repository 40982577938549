import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { SingletonBase } from '@wephone-utils';

@Injectable()
export class NotificationService extends SingletonBase {
  private readonly REGISTER_TOKEN_PATH = 'notifications/register-token';

  constructor(
    private readonly httpEngine: HttpEngine,
  ) {
    super();
  }

  registerToken(data: {
    appName: string,
    token: string,
  }): Promise<any> {
    return this.httpEngine.apiPostV2(this.REGISTER_TOKEN_PATH, data);
  }
}
