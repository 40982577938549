import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { DidEntity } from '@wephone-core/model/entity/did';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { PhoneNumberService } from '@wephone-utils';

export class DidRepository extends BaseRepository {
  static URL_DID_SA_ADD = 'sa/did/add';
  ENTITY_CLASS = DidEntity;
  protected USE_PUSH_API = true;
  protected RELATED_REPOSITORIES = ['callqueue', 'sipphone'];

  protected URL_PREFIX = 'did';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'UPDATE':
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
        break;
      case 'UPDATE_ATTRS_BULK':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS_BULK);
        break;
      case 'ADD':
        // Only for SuperAdmin
        return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2(DidRepository.URL_DID_SA_ADD);
        break;
      default:
        return super.getURLAction(action);
    }
  }

  clear(items: DidEntity[]): Promise<any> {
    const ids = [];
    for (let i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    const url = this.getUrlV2('clear');
    return HttpEngine.getInstance()
      .post(url, { ids })
      .then(ret => {
        for (const item of items) {
          item.name = undefined;
          item.clearRoutingData();
        }

        return items;
      });
  }

  return(items: DidEntity[], enterpriseId?: number): Promise<any> {
    const ids: number[] = [];
    for (let i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    const url = this.getUrlV2('return');
    return HttpEngine.getInstance()
      .post(url, { ids, enterprise_id: enterpriseId })
      .then(ret => {
        for (const i of Object.keys(this.object_list)) {
          if (ids.indexOf(this.object_list[i].id) > -1) {
            this.object_list.splice(parseInt(i), 1);
            break;
          }
        }
        return ret;
      });
  }

  getEmptyDid(title: string): DidEntity {
    const did: DidEntity = new DidEntity();
    did.id = null;
    did.setNumberWithDisplay(title);
    return did;
  }

  getAnonymousDid(): DidEntity {
    const did: DidEntity = new DidEntity();
    did.id = 0;
    did.setNumberWithDisplay('Anonymous');
    return did;
  }

  getIdentifiedObjectList(): DidEntity[] {
    const ret: DidEntity[] = this.getObjectList();
    return ret.filter(did => {
      return did.id !== 0;
    });
  }

  getDefaultDid(): DidEntity[] {
    for (const did of this.getObjectList()) {
      if (did.default) {
        return did;
      }
    }
  }

  setDefaultDid(did: DidEntity): Promise<any> {
    const url: string = this.getUrl('setDefault');
    return HttpEngine.getInstance().post(url, { id: did.getId() });
  }

  getObjectListRoutedToQueue(callqueue_id: number): DidEntity[] {
    return this.getObjectList().filter((x: DidEntity) => {
      return x.routed_queue && x.routed_queue.id === callqueue_id ||
        x.out_office_hours_routed_queue && x.out_office_hours_routed_queue.id === callqueue_id;
    });
  }

  getObjectByNumber(phonenumber: string): DidEntity {
    return this.getObjectList().find(x => x.number === phonenumber);
  }

  getObjectByDisplayedNumber(phonenumber: string): DidEntity {
    const phoneService = PhoneNumberService.getInstance();
    return this.getObjectList().find(x => phonenumber === phoneService.getDisplayNumber(x.number));
  }

  getInvalidRoutingDid(): DidEntity[] {
    return this.getObjectList().filter(x => x.routing_status === 0);
  }

  getDidListForDedicated(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of this.getObjectList()) {
      // Must have routing data
      if (!did.hasRoutingData()) {
        continue;
      }
      // Must not be alias of any number
      if (did.hasMasterDid()) {
        continue;
      }
      ret.push(did);
    }
    return ret;
  }

  getLinkedsByUser(user_id: number): DidEntity[] {
    const dids = [];
    for (const did of this.object_list) {
      if (did.hasLinkedUser() && did.routed_user.id === user_id) {
        dids.push(did);
      }
    }
    return dids;
  }

  getDidByNumber(number: string): DidEntity {
    return this.getObjectList().find(x => x.number === number);
  }

  getDidByName(name: string, excludedId: number = null): DidEntity {
    return this.getObjectList().find(x => x.name === name && (!excludedId || excludedId && x.id !== excludedId));
  }

  save(item: DidEntity, extra_data?: { [id: string]: any }, attr_group?: string): Promise<any> {
    const action: string = item.getId() ? 'UPDATE' : 'ADD';
    const url = this.getURLAction(action);
    const post_data = _.cloneDeep(item.dumpObjectData(undefined, true));
    if (extra_data) {
      _.extend(post_data, extra_data);
    }

    // Remove some fields not use before request to service
    const exclude_fields = ['gateway', 'default', 'telecom_provider_id', 'eid'];
    for (const k of Object.keys(post_data)) {
      if (exclude_fields.includes(k)) {
        delete post_data[k];
      }
    }

    post_data['__field_list__'] = Object.keys(post_data);
    return HttpEngine.getInstance().postJson(url, post_data);
  }
}
