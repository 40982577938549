<flex-select [placeholder]="placeholder" 
              [formControl]="flexSelect" 
              [itemList] = "queueList"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              (selectionChange)="onSelectionChange($event)"
              [multiple]="false">
  <ng-template #itemTemplate let-queue="item" let-isTruncated="truncate">
    <span [matTooltip]="queue.queue_name">{{ isTruncated ? (queue.queue_name | truncate) : queue.queue_name }}</span>
  </ng-template>
</flex-select>
