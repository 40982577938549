import { Component, OnInit, ViewChild } from '@angular/core';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
// import { TranslateService } from '@ngx-translate/core';
import {
  ToastService,
  IFlexTableSidePannelOptions,
  DialogService,
  DynamicFilterSource,
  IFlexTableConfig,
  regexSearch,
  parseDateTime,
  localNow,
} from '@wephone-utils';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { SipPhoneEditComponent } from '@wephone/components/sipphone/sipphone-edit/sipphone-edit.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SipPhoneCreateModal } from '@wephone/modals/sipphone/sipphone-create/sipphone-create-modal.component';
import { FlexBasePage } from '@wephone-core-ui';
import { EditMultipleSipphoneComponent } from '@wephone/components/edit-multiple-sipphone/edit-multiple-sipphone.component';
import { _tk, _ti } from '@wephone-translation';
import { FAIcons } from '@wephone-custom-icon';
import { SipPhoneService } from '@wephone-common/service/sipphone.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { FlexIvrSettings, EntityManager } from '@wephone-core/wephone-core.module';
import { DateTime } from 'luxon';

@Component({
  selector: 'sipphone-page',
  templateUrl: './sipphone-page.component.html',
  styleUrls: ['./sipphone-page.component.scss']
})
export class SipPhonePage extends FlexBasePage implements OnInit {
  FAIcons = FAIcons;

  // Public member
  sipphoneRepo = SipPhoneRepository.getInstance<SipPhoneRepository>();
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: SipPhoneEditComponent,
    multiItemEditor: EditMultipleSipphoneComponent
  };

  filterShowConnected = false;
  filterString: string;

  form: FormGroup;
  @ViewChild('flexCrud') flexCrud;

  readonly is_hotel: boolean = FlexIvrSettings.getInstance().uiHotel();

  constructor(
    // public translate: TranslateService,
    public dialogService: DialogService,
    public sipPhoneService: SipPhoneService,
    public toast: ToastService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
  ) {
    super();

    const sipphoneSource = this.sipphoneRepo.dataSource<SipPhoneEntity>();
    this.dataSource = new DynamicFilterSource(sipphoneSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'extension',
          label: _tk('sipphone.content.extension_number'),
          searchable: true,
          sortable: true,
          sort: 'asc'
        },
        {
          name: 'owner',
          label: this.is_hotel && _tk('sipphone.content.owner') || _tk('sipphone.content.user_name'),
          searchable: true,
          sortable: true,
          customSortFn: (sipphone: SipPhoneEntity) => {
            return !_.isEmpty(sipphone.user) ? sipphone.user.name.toLowerCase() : '';
          }
        },
        {
          name: 'description',
          label: _tk('sipphone.content.description'),
          searchable: true,
          sortable: true,
          customSortFn: (sipphone: SipPhoneEntity) => {
            return sipphone.description && sipphone.description.toLowerCase();
          }
        },
        {
          name: 'last_online_dt',
          label: _tk('sipphone.content.last_online'),
          searchable: false,
          sortable: true,
          customSortFn: (sipphone: SipPhoneEntity) => {
            let lastOnline: DateTime = !_.isEmpty(sipphone.last_online_dt) ? parseDateTime(sipphone.last_online_dt) : undefined;
            if (sipphone.is_online) {
              lastOnline = localNow();
            }
            return lastOnline && lastOnline.toFormat('yyyyMMdd-HHmm') || '';
          }
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            hint: _tk('sipphone.title.create'),
            callback: () => {
              return this.createSipPhone();
            }
          },
          {
            icon: 'add_circle_outline',
            hint: _tk('sipphone.title.create_multiple'),
            callback: () => {
              return this.createNewMultiple();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ],
          secondary: [
            // Visible action buttons when some item is selected and user click on the button "..."
            {
              icon: 'block',
              hint: _tk('sipphone.actions.disable_all_selected_items'),
              callback: data => {
                return this.bulkDisable(data.selectedItems);
              }
            },
            {
              icon: 'check_circle_outline',
              hint: _tk('sipphone.actions.enable_all_selected_items'),
              callback: data => {
                return this.bulkEnable(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.form = this.fb.group({
      connected: [this.filterShowConnected],
      filterString: [this.filterString]
    });
    this.form.valueChanges.subscribe(this.onFormValueChage);
  }

  private readonly filterFunc = (phone: SipPhoneEntity) => {
    return this.filterByConnectivity(phone) && this.filterPredicate(phone, this.filterString);
  }

  private filterByConnectivity(phone: SipPhoneEntity): boolean {
    if (this.filterShowConnected) {
      return !!phone.is_online;
    }
    return true;
  }

  private filterPredicate(phone: SipPhoneEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(phone.extension, filterString) ||
      (phone.user && regexSearch(phone.user.name, filterString)) ||
      (phone.description && regexSearch(phone.description, filterString));
  }

  private readonly onFormValueChage = (formValues: any) => {
    this.filterShowConnected = formValues.connected;
    this.filterString = (formValues.filterString || '').trim();

    // this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  protected async resolveData(): Promise<any> {
    await EntityManager.getRepository('CrmRoutingRuleRepository').loadObjectList();
    if (this.is_hotel) {
      await EntityManager.getRepository('HotelRoomRepository').loadObjectList();
    }
  }

  private async bulkDelete(items: SipPhoneEntity[]): Promise<any> {
    const confirmed: boolean = await this.sipPhoneService.getConfirmDeleteSipPhones(items);
    if (confirmed) {
      try {
        await this.sipphoneRepo.bulkDelete(items);
        this.flexCrud.closeSidePanel();
        this.toast.show(_ti('public.message.delete_success'));
      } catch (error) {
        this.toast.showErrorMessage(error, _ti('public.message.delete_failure'));
      }
    }
  }

  private bulkEnable(items: SipPhoneEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.bulk_enable_confirm_question'),
      async () => {
        try {
          await this.sipphoneRepo.bulkEnable(items);
          this.toast.show(_ti('public.message.enable_success'));
        } catch (e) {
          this.toast.showErrorMessage(e, _ti('public.message.enable_failure'));
        }
      }
    );
  }

  private async bulkDisable(items: SipPhoneEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.bulk_disable_confirm_question'),
      async () => {
        try {
          await this.sipphoneRepo.bulkDisable(items);
          this.toast.show(_ti('public.message.disable_success'));
        } catch (e) {
          this.toast.showErrorMessage(e, _ti('public.message.disable_failure'));
        }
      }
    );
  }

  async createSipPhone(): Promise<any> {
    try {
      const createdSipPhone = await EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository').createAndSave();
      this.router.navigate(['telephony', 'sip-phones', createdSipPhone.id], { skipLocationChange: false });
      this.toast.show(_ti('public.message.create_success'));
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  async createNewMultiple(): Promise<any> {
    try {
      const dialogRef = this.dialogService.openDialog2(SipPhoneCreateModal, {});
      const updated = await dialogRef.afterClosed().toPromise();
      if (!updated || !updated.length) {
        return undefined;
      }

      const response = await EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository').addMultipleSipPhone(updated);
      if (response.error || response.warning) {
        const warnMsg = `${response.error || ''} ${response.warning || ''}`;
        this.showWarning(warnMsg);
      }

      this.successToast(_ti('public.message.create_success'));
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }
}
