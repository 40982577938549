export enum OutcallCampaignState {
  NOT_READY = -1,
  FINISHED = 0,
  NOT_PROGRESS = 1,
  IN_PROGRESS = 2,
  ARCHIVED = 3,
  READY = 4
}

export enum OutcallCampaignaAMAction {
  CampaignOK = 0,
  ClientUnreachabled = 1
}

export enum CampaignType {
  CallAgentFirst = 0,
  CallClientFirst = 1,
}
