<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="conferenceForm" class="filter-form" fxLayout="row" fxLayout.xs="column"
      fxLayoutAlign="end center" fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input class="full-width" placeholder="{{ 'public.search' | translate }}"
          formControlName="filterValue" [hintText]="'conference.search.hint'|translate"
          [hintText]="'conference.search.hint'|translate" ngClass.xs="search-full-width">
        </flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud pageTitle="{{ 'menu.conference'|translate }}" [filterTemplate]="listFilterTpl"
    [tableConfig]="tableConfig" [dataSource]="dataSource" [sidePannelOptions]="sidePannelOptions" fxLayout="row"
    fxFlex="grow">
  </flex-crud-page>
</div>
