export enum TransferDestinationType {
  USER = 'user',
  NUMBER = 'number',
  APPLICATION = 'application'
}

export enum DialogType {
  CALL_TRANSFER = 'call_transfer',
  ADD_PARTICIPANT = 'add_participant',
}

export class TransferRequest {
  public transferReason: string;

  constructor(
    public destination_type?: TransferDestinationType,
    public destination?: any,
    public assisted: boolean = false,
    public transfer_reason?: string,
    public params?: any
  ) { }
}
