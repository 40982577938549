import * as _ from 'lodash';
import {
  Component,
  OnInit,
  OnDestroy,
  Self,
  Optional,
  ElementRef,
  Input
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';

@Component({
  selector: 'sip-number-select-form-input',
  templateUrl: './sip-number-select.component.html',
  styleUrls: ['./sip-number-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SipNumberSelectFormInput }]
})
export class SipNumberSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<SipPhoneEntity> {
  static nextId = 0;

  /**
   * If true, only display SIP numbers in use (has user_id or hotel_room_id).
   * If false or undefined, display all items
   */
  @Input() isConfigured: boolean;
  @Input() excludeIds: number[];

  flexSelect: FormControl = new FormControl();
  sipPhoneList: SipPhoneEntity[];
  flexSelectOptions: IFlexSelectOptions;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private readonly em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: SipPhoneEntity, filterString) => {
        return regexSearch(`${item.extension}${item.user ? ` (${item.user.name})` : ''}`, filterString);
      }
    };

    // load hotel data
    HotelRoomRepository.getInstance().loadObjectList();
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    let sipPhoneList = this.em.getRepository<SipPhoneRepository>('SipPhoneRepository').getAllEnabled();

    if (this.isConfigured === true) {
      sipPhoneList = sipPhoneList.filter(s => !!s.user_id || !!s.hotel_room_id);
    }

    if (!_.isEmpty(this.excludeIds) && !_.isEmpty(sipPhoneList)) {
      sipPhoneList = sipPhoneList.filter(i => !_.includes(this.excludeIds, i.id));
    }

    this.sipPhoneList = sipPhoneList;
  }

  get controlType(): string {
    return 'sip-number-select-form-input';
  }
}
