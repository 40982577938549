import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  // Transform is the new "return function(value, args)" in Angular 1.x
  transform(items: Array<any>, args?) {
    if (!items || !_.isArray(items) || !args) {
      // console.error("filterBy items", items);
      return items;
    }

    let isArgsString: boolean = _.isString(args);
    let isArgsFunction: boolean = _.isFunction(args);

    return items.filter(item => {
      let check = true;
      let isItemString = _.isString(item);

      if (isArgsFunction) {
        return args(item);
      }

      for (let key in args) {
        let inputData = isItemString ? item : item[key];
        let argsData = isArgsString ? args : args[key];

        if (argsData == '' || argsData == null || argsData == undefined) {
          continue;
        }

        if (_.isNumber(argsData) && inputData != argsData) {
          check = false;
          break;
        }

        inputData = inputData.toUpperCase();
        argsData = argsData.toUpperCase();

        if (inputData.indexOf(argsData) == -1) {
          check = false;
          break;
        }
      }

      return check;
    });
  }
}
