<div fxFill fxLayout="column">
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.startDt'|translate }}</mat-label>
          <input matInput [matDatepicker]="startPicker" [(ngModel)]="filters.start_dt"
            placeholder="{{ 'campaign.startDt'|translate }}">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.endDt'|translate }}</mat-label>
          <input matInput [matDatepicker]="endPicker" [(ngModel)]="filters.end_dt"
            placeholder="{{ 'campaign.endDt'|translate}}">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'call_queue.content.called_number'|translate }}</mat-label>
          <input matInput [(ngModel)]="filters.called_number"
            placeholder="{{ 'call_queue.content.called_number'|translate }}">
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'hotelroom.content.call_direction'|translate }}</mat-label>
          <mat-select placeholder="{{'cdr.in_out'|translate}}" [(ngModel)]="filters.call_direction">
            <mat-option value="">{{'cdr.in_out' | translate}}</mat-option>
            <mat-option value="0">{{'cdr.incoming' | translate}}</mat-option>
            <mat-option value="1">{{'cdr.outgoing' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex.gt-xs="100px" class="mb-1" align="center">
      <button mat-raised-button (click)="reloadClick()" color="accent">
        {{ 'public.apply' | translate }}
      </button>
    </div>
  </div>

  <div fxFlex="auto" class="logs-data">
    <flex-grid gridName="hotelroom_call_history" [config]="config"></flex-grid>
  </div>
</div>
