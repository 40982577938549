import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { PhoneBookEntry } from '@wephone-core/model/entity/phonebook';

export class PhoneBookRepository extends BaseRepository {
  ENTITY_CLASS = PhoneBookEntry;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'phonebook';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'ADD_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_NEW_ATTRS);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      default:
        return super.getURLAction(action);
    }
  }

  getExistPhoneNumber(phoneNumber: string): PhoneBookEntry {
    return this.getObjectList().find(x => x.phone_office === phoneNumber || x.phone_mobile === phoneNumber);
  }
}
