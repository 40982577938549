import { Component, OnInit, ElementRef, Self, Optional, Output, EventEmitter, Input } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormControl } from '@angular/forms';
import { FlexMatInputWrapper } from '../../form-input/base/flex-mat-input-wrapper';
import * as _ from 'lodash';

@Component({
  selector: 'flex-search-input',
  templateUrl: './flex-search-input.component.html',
  styleUrls: ['./flex-search-input.component.scss']
})
export class FlexSearchInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() hintText: string;
  @Output() readonly onInput = new EventEmitter<string>();
  @Output() readonly onBlur = new EventEmitter<any>();
  @Output() readonly onKeyup = new EventEmitter<any>();
  @Output() readonly onEmpty = new EventEmitter<void>();

  flexSearchControl = new FormControl();

  constructor(
    elRef: ElementRef,
    @Optional()
    @Self()
    ngControl: NgControl
  ) {
    super(ngControl, elRef);
  }

  get wrappedControl(): FormControl {
    return this.flexSearchControl;
  }

  get controlType(): string {
    return 'flex-search-input';
  }

  inputEvent(): void {
    if (this.onInput) {
      this.onInput.emit(this.flexSearchControl.value);
    }
  }

  blurEvent(event): void {
    if (this.onBlur) {
      this.onBlur.emit(event);
    }
  }

  keyupEvent(event): void {
    if (this.onKeyup) {
      this.onKeyup.emit(event);
    }
  }

  clearSearch(): void {
    setTimeout(() => { // Prevent from error variable value changed
      this.flexSearchControl.setValue('');
    });

    if (this.onEmpty) {
      this.onEmpty.emit();
    }
  }
}
