import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IChangeAwareComponent, EditingComponent, FormService } from '@wephone-utils';
import { UserEntity } from '@wephone-core/model/entity/user';
import { FormBuilder, FormControl } from '@angular/forms';
import { AccessRight } from '@wephone-core/system';

@Component({
  selector: 'app-user-access-right',
  templateUrl: './user-access-right.component.html',
  styleUrls: ['./user-access-right.component.scss']
})
export class UserAccessRightComponent extends EditingComponent implements OnInit, OnChanges, IChangeAwareComponent {
  @Input() user: UserEntity;
  @Output() readonly formValueChanges: EventEmitter<boolean>;
  allAccess = false;
  private userRights: any;

  constructor(
    public formService: FormService,
    protected fb: FormBuilder,
  ) {
    super();
    this.formValueChanges = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.initialize();
  }

  ngOnChanges(): void {
    this.initialize();
  }

  private updateUserForForm(): void {
    this.userRights = {
      LIVEPAGE: this.user.isGrantedAccess(AccessRight.LivePage),
      CALLHISTORY: this.user.isGrantedAccess(AccessRight.CallHistory),
      EAVESDROP: this.user.isGrantedAccess(AccessRight.Eavesdrop),
      RECORDING: this.user.isGrantedAccess(AccessRight.Recording),
      VOICEMAIL: this.user.isGrantedAccess(AccessRight.VoiceMail),
      STATS: this.user.isGrantedAccess(AccessRight.Stats),
    };
    this.initFormGroup();
  }

  get hasLivePage(): boolean {
    return this.user && !this.user.isAgent() && !this.user.isUser();
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  private getFormResetData(): object {
    const result: any = {
      CALLHISTORY: this.user.isGrantedAccess(AccessRight.CallHistory),
      EAVESDROP: this.user.isGrantedAccess(AccessRight.Eavesdrop),
      RECORDING: this.user.isGrantedAccess(AccessRight.Recording),
      VOICEMAIL: this.user.isGrantedAccess(AccessRight.VoiceMail),
      STATS: this.user.isGrantedAccess(AccessRight.Stats),
    };
    if (this.hasLivePage) {
      result.LIVEPAGE = this.user.isGrantedAccess(AccessRight.LivePage);
    }
    return result;
  }

  getChangeSet(): any {
    return this.formService.getChangeSet(this.form);
  }

  initialize(): void {
    this.updateUserForForm();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      CALLHISTORY: [this.userRights.CALLHISTORY],
      EAVESDROP: [this.userRights.EAVESDROP],
      RECORDING: [this.userRights.RECORDING],
      VOICEMAIL: [this.userRights.VOICEMAIL],
      STATS: [this.userRights.STATS],
    });

    if (!this.user.isAgent() && !this.user.isUser()) {
      this.form.addControl('LIVEPAGE', new FormControl(this.userRights.LIVEPAGE));
    }

    this.form.valueChanges.subscribe(changes => {
      this.updateAllAccess();
      this.onFormValueChange();
    });
    this.updateAllAccess();
  }

  isValid(): boolean {
    return true;
  }

  updateAllAccess(): void {
    this.allAccess = Object.keys(this.form.controls).every(key => this.form.get(key).value);
  }

  someAccess(): boolean {
    return Object.keys(this.form.controls).filter(key => this.form.get(key).value).length > 0 && !this.allAccess;
  }

  setAll(access: boolean): void {
    this.allAccess = access;
    const result: any = {
      LIVEPAGE: access,
      CALLHISTORY: access,
      EAVESDROP: access,
      RECORDING: access,
      VOICEMAIL: access,
      STATS: access,
    };
    // tslint:disable-next-line: no-void-expression
    Object.keys(this.form.controls).forEach(key => this.form.get(key).markAsDirty());
    this.form.patchValue(result, { emitEvent: true });
  }
}
