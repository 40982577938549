import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class EavesdropRequestRepository extends BaseRepository {
  ENTITY_CLASS = EavesdropRequestEntity;
  protected USE_PUSH_API = true;

  /**
   * List did routing include not routed and exclude having linked user
   */
  //        public getActiveEavesdropRequestList():Array<EavesdropRequestEntity> {
  //        	let list:Array<EavesdropRequestEntity> = [];
  //            for (let item of this.getObjectList()) {
  //        		if (!item.finished) {
  //        			list.push(item);
  //        		}
  //        	}
  //        	return list;
  //        }
}

import { EavesdropRequestEntity } from '@wephone-core/model/entity/eavesdrop_request';
