import { timer, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SingletonBase } from '@wephone-utils';
import { HttpEngine } from './http_engine';
import { AuthenticationService } from './authentication';
import { ElectronService } from 'ngx-electron';
import { FlexIvrSettings } from './flexivr_settings';

@Injectable()
export class NetStatus extends SingletonBase {
  private pingUrl = 'user/ping';

  private pingOK = true;
  private timer: any;
  private _isOnline: boolean;
  private pingCount = 0;

  private onlineStateChangesSource: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpEngine: HttpEngine,
    private authService: AuthenticationService,
    private electron: ElectronService,
    private settings: FlexIvrSettings
  ) {
    super();
    this._isOnline = window.navigator.onLine;
    window.addEventListener('online',
      () => {
        this.doPing();
      }
    );
    window.addEventListener('offline',
      () => {
        this.pingCount++;
        this.setOnlineState(false);
      }
    );

    this.startPinging();
  }

  startPinging(): void {
    this.timer = timer(10000, 10000);
    this.timer.subscribe(
      () => {
        this.doPing();
      }
    );
  }

  private doPing(): void {
    if (this.pingUrl && this.authService.isAuthenticated()) {
      this.pingCount++;
      const pingCount = this.pingCount;
      if (window.navigator.onLine) {
        this.httpEngine.apiGetV2(`user/ping/${this.settings.app_type}`, undefined, {hide_progression: 'true'})
        .then(
          ret => {
            if (pingCount === this.pingCount) {
              this.pingOK = true;
              this.setOnlineState(true);
            }
          },
          err => {
            if (pingCount === this.pingCount) {
              this.pingOK = false;
              this.setOnlineState(false);
            }
          }
        );
      } else {
        this.setOnlineState(false);
      }
    }
  }

  get isOnline(): boolean {
    return this._isOnline;
  }

  private setOnlineState(isOnline: boolean): void {
    if (isOnline !== this._isOnline) {
      this._isOnline = isOnline;
      this.onlineStateChangesSource.next(isOnline);
      if (!isOnline) {
        if (this.electron.isElectronApp) {
          this.electron.ipcRenderer.send('mainwindow:bring_to_front');
        }
      }
    }
  }

  get onlineStateChanges(): Observable<boolean> {
    return this.onlineStateChangesSource.asObservable();
  }

}
