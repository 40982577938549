<flex-select [placeholder]="placeholder" 
              [formControl]="flexSelect" 
              [itemList] = "sipPhoneList"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              [multiple]="false">
  <ng-template #itemTemplate let-sipphone="item" >
    {{ sipphone.extension_with_owner }}
  </ng-template>
</flex-select>
