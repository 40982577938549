import { Injectable } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { SingletonBase } from '../utils/singleton';
import * as aesjs from 'aes-js';

@Injectable()
export class CryptoService extends SingletonBase {
  private aesCtr: any;
  private uuid: string;

  constructor(private electron: ElectronService) {
    super();
    const key = [3, 1, 4, 1, 5, 9, 2, 6, 5, 3, 5, 8, 9, 7, 9, 3];
    this.aesCtr = new aesjs.ModeOfOperation.ctr(key);
    this.uuid = window['MACHINE_UUID'];
  }

  encrypt(input: string): string {
    const textAsBytes = aesjs.utils.utf8.toBytes(input);
    const encryptedBytes = this.aesCtr.encrypt(textAsBytes);
    const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  }

  decrypt(crypted_data): string {
    // crypted_data is in hexa string format
    try {
      const encryptedBytes = aesjs.utils.hex.toBytes(crypted_data);
      const decryptedBytes = this.aesCtr.decrypt(encryptedBytes);
      const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
      return decryptedText;
    } catch (e) {
      console.error('Error while trying to decrypt saved password', e);
    }
  }
}
