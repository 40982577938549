<div fxLayout="column" fxFill>
  <ng-container *ngIf="showDashboardStats">
    <!-- Temporary disabled. View more at link https://github.com/graamteam/smartcontact-projects/issues/2120
      <div *ngIf="hasFeature('SERVICE_GROUP')" class="group-filter" fxLayoutAlign="center center">
      <button mat-button (click)="showGroupFilterDialog()" [ngClass]="{'filter-active': filtered_group_ids.length}"
        fxLayoutAlign="center center">
        <mat-icon>filter_alt</mat-icon><span>{{ 'livecall.actions.filter_by_group'|translate }}</span>
      </button>
    </div> -->
    <qos [filterGroupIds]="filtered_group_ids"></qos>
    <mat-card class="graph">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex.gt-sm="400px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start end"
          fxLayoutAlign.xs="start none">
          <h1 class="mb-0 pr-1 italic">{{ 'dashboard.content.today_calls' | translate }}</h1>
          <span class="h1 font-weight-bold mat-text-primary period-time">
            <span class="date">{{ call_filters.day | timeFormatDateShort }}</span>&nbsp;
            <!-- <span class="time">{{ call_filters.time_start }} - {{ call_filters.time_end }}</span> -->
            <span class="time" *ngIf="call_filters.time_start == '00:00' && call_filters.time_end == '00:00';else BlockTimePeriod">
              {{ 'dashboard.content.allday'|translate }}
            </span>
            <ng-template #BlockTimePeriod>
              <span class="time">{{ call_filters.time_start }} - {{ call_filters.time_end }}</span>
            </ng-template>
          </span>
        </div>
        <div fxFlex="grow" fxLayoutAlign="end center">
          <mat-chip-list fxFlex="400px" fxLayoutAlign="end center" *ngIf="filteredQueues.length">
            <mat-chip *ngFor="let queue of filteredQueues | slice: 0: 3" 
              [matTooltip]="queue.queue_name" (click)="filterChartData()">
              {{ queue.queue_name | truncate:12}}
              <!-- <mat-icon matChipRemove (click)="removeFilteredQueue(queue)">cancel</mat-icon> -->
            </mat-chip>
            <!-- <mat-chip *ngIf="filteredQueues.length > 3" (click)="filterChartData()">
              ...
            </mat-chip> -->
          </mat-chip-list>
          <!-- <button mat-button (click)="filterChartData()" data-ci="filter-chart-button">
            <mat-icon>filter_list</mat-icon>
            {{ 'dashboard.content.filter_by_queue' | translate }}
          </button> -->
          <!-- <mat-slide-toggle [checked]="filteredStats.include_outbound === 1" (change)="updateChartByIncludeOutbound()" color="primary" class="px-1">
            <span class="fontfamily-bold">{{ 'livecall.content.include_outgoing_call' | translate }}</span>
          </mat-slide-toggle> -->
          <button [matTooltip]="'dashboard.content.filter_by_queue' | translate" (click)="filterChartData()" mat-icon-button data-ci="filter-chart-button" class="ml-xs">
            <mat-icon>filter_list</mat-icon>
            <!-- {{ 'dashboard.content.filter_by_queue' | translate }} -->
            <span class="flex-notification-label" *ngIf="filteredQueues.length">{{ filteredQueues.length }}</span>
          </button>
        </div>
      </div>
      <!-- <mat-card-content> -->
      <div [chart]="lineChart" data-ci="line-chart"></div>
      <!-- </mat-card-content> -->
    </mat-card>
  </ng-container>
  <!-- <ng-container *ngIf="isAccountant()">
    <app-invoice></app-invoice>
  </ng-container> -->
</div>
