export const DefaultLangCode = 'default';
// Map country code and default locale
export const countryDefaultLocales = {
    af: 'ps',
    ax: 'en', //df
    al: 'sq',
    dz: 'ar',
    as: 'en', //df
    ad: 'ca',
    ao: 'en', //df
    ai: 'en', //df
    aq: 'en', //df 'Antarctica',
    ag: 'en',
    ar: 'es',
    am: 'hy',
    aw: 'nl',
    au: 'en',
    at: 'de',
    az: 'az',
    bs: 'en', //df 'Antarctica',
    bh: 'ar',
    bd: 'bn',
    bb: 'en', //df 'Barbados',
    by: 'be',
    be: 'de',
    bz: 'en', //df 'Belize',
    bj: 'en', //df 'Benin',
    bm: 'en', //df 'Bermuda',
    bt: 'dz',
    bo: 'es',
    ba: 'bs',
    bw: 'en',
    bv: 'en', //df 'Bouvet Island',
    br: 'pt',
    io: 'en', //df 'British Indian Ocean Territory',
    bn: 'en', //df 'Brunei Darussalam',
    bg: 'bg',
    bf: 'en', //df 'Burkina Faso',
    bi: 'en', //df 'Burundi',
    kh: 'km',
    cm: 'en', //df 'Cameroon',
    ca: 'fr',
    cv: 'en', //df 'Cape Verde',
    ky: 'en', //df 'Cayman Islands',
    cf: 'en', //df 'Central African Republic',
    td: 'en', //df 'Chad',
    cl: 'es',
    cn: 'zh',
    cx: 'en', //df 'Christmas Island',
    cc: 'en', //df 'Cocos (Keeling) Islands',
    co: 'es',
    km: 'en', //df 'Comoros',
    cg: 'en', //df 'Congo',
    cd: 'en', //df 'Congo, Democratic Republic of the ',
    ck: 'en', //df 'Cook Islands',
    cr: 'es',
    ci: 'en', //df 'Côte D\'Ivoire',
    hr: 'hr',
    cu: 'en', //df 'Cuba',
    cy: 'tr',
    cz: 'cs',
    dk: 'da',
    dj: 'do',
    dm: 'en', //df 'Dominica',
    do: 'en', //df 'Dominican Republic',
    ec: 'en', //df 'Ecuador',
    eg: 'ar',
    sv: 'en', //df 'El Salvador',
    gq: 'en', //df 'Equatorial Guinea',
    er: 'aa',
    ee: 'et',
    et: 'so',
    fk: 'en', //df 'Falkland Islands (Malvinas)',
    fo: 'en', //df 'Faroe Islands',
    fj: 'en', //df 'Fiji',
    fi: 'fi',
    fr: 'fr',
    gf: 'fr', //df 'French Guiana',
    pf: 'fr', //df 'French Polynesia',
    tf: 'fr', //df 'French Southern Territories',
    ga: 'en', //df 'Gabon',
    gm: 'en', //df 'Gambia',
    ge: 'en', //df 'Georgia',
    de: 'de',
    gh: 'en', //df 'Ghana',
    gi: 'en', //df 'Gibraltar',
    gr: 'el',
    gl: 'kl',
    gd: 'en', //df 'Grenada',
    gp: 'en', //df 'Guadeloupe',
    gu: 'en', //df 'Guam',
    gt: 'en', //df 'Guatemala',
    gg: 'en', //df 'Guernsey',
    gn: 'en', //df 'Guinea',
    gw: 'en', //df 'Guinea-Bissau',
    gy: 'en', //df 'Guyana',
    ht: 'ht',
    hm: 'en', //df 'Heard Island and Mcdonald Islands',
    va: 'it',
    hn: 'en', //df 'Honduras',
    hk: 'zh',
    hu: 'hu',
    is: 'en', //df 'Iceland',
    in: 'en',
    id: 'id',
    ir: 'fa',
    iq: 'ar',
    ie: 'en', //df 'Ireland',
    im: 'en', //df 'Isle of Man',
    il: 'he',
    it: 'it',
    jm: 'en', //df 'Jamaica',
    jp: 'ja',
    je: 'en', //df 'Jersey',
    jo: 'ar',
    kz: 'kk',
    ke: 'so',
    ki: 'en', //df 'Kiribati',
    kp: 'ko',
    kr: 'ko',
    kw: 'ar',
    kg: 'ky',
    la: 'lo',
    lv: 'lv',
    lb: 'ar',
    ls: 'en', //df 'Lesotho',
    lr: 'en', //df 'Liberia',
    ly: 'en', //df 'Libyan Arab Jamahiriya',
    li: 'en', //df 'Liechtenstein',
    lt: 'lt',
    lu: 'de',
    mo: 'zh',
    mk: 'mk',
    mg: 'mg',
    mw: 'en', //df 'Malawi',
    my: 'ms',
    mv: 'dv',
    ml: 'en', //df 'Mali',
    mt: 'mt',
    mh: 'en', //df 'Marshall Islands',
    mq: 'en', //df 'Martinique',
    mr: 'en', //df 'Mauritania',
    mu: 'en', //df 'Mauritius',
    yt: 'en', //df 'Mayotte',
    mx: 'es',
    fm: 'en', //df 'Micronesia, Federated States of',
    md: 'en', //df 'Moldova, Republic of',
    mc: 'en', //df 'Monaco',
    mn: 'en', //df 'Mongolia',
    me: 'sr',
    ms: 'en', //df 'Montserrat',
    ma: 'ar',
    mz: 'en', //df 'Mozambique',
    mm: 'my',
    na: 'en', //df 'Namibia',
    nr: 'en', //df 'Nauru',
    np: 'ne',
    nl: 'nl',
    an: 'nl',
    nc: 'en', //df 'New Caledonia',
    nz: 'en',
    ni: 'es',
    ne: 'en', //df 'Niger',
    ng: 'en',
    nu: 'en', //df 'Niue',
    nf: 'en', //df 'Norfolk Island',
    mp: 'en', //df 'Northern Mariana Islands',
    no: 'no',
    om: 'ar',
    pk: 'ur',
    pw: 'en', //df 'Palau',
    ps: 'en', //df 'Palestinian Territory, Occupied',
    pa: 'es',
    pg: 'en', //df 'Papua New Guinea',
    py: 'es',
    pe: 'es',
    ph: 'en',
    pn: 'en', //df 'Pitcairn',
    pl: 'pl',
    pt: 'br',
    pr: 'es',
    qa: 'ar',
    re: 'fr',
    ro: 'ro',
    ru: 'ru',
    rw: 'rw',
    bl: 'en', //df 'Saint-Barthélemy',
    sh: 'en', //df 'Saint Helena',
    kn: 'en', //df 'Saint Kitts and Nevis',
    lc: 'en', //df 'Saint Lucia',
    mf: 'en', //df 'Saint-Martin (French part)',
    pm: 'en', //df 'Saint Pierre and Miquelon',
    vc: 'en', //df 'Saint Vincent and the Grenadines',
    ws: 'en', //df 'Samoa',
    sm: 'en', //df 'San Marino',
    st: 'en', //df 'Sao Tome and Principe',
    sa: 'ar',
    sn: 'wo',
    rs: 'sr',
    sc: 'en', //df 'Seychelles',
    sl: 'en', //df 'Sierra Leone',
    sg: 'en',
    sk: 'sk',
    si: 'sl',
    sb: 'en', //df 'Solomon Islands',
    so: 'so',
    za: 'zu',
    gs: 'en', //df 'South Georgia and the South Sandwich Islands',
    ss: 'en', //df 'South Sudan',
    es: 'es',
    lk: 'si',
    sd: 'ar',
    sr: 'me',
    sj: 'en', //df 'Svalbard and Jan Mayen',
    sz: 'en', //df 'Swaziland',
    se: 'sv',
    ch: 'de',
    sy: 'ar',
    tw: 'zh',
    tj: 'tg',
    tz: 'en', //df 'Tanzania, United Republic of',
    th: 'th',
    tl: 'en', //df 'Timor-Leste',
    tg: 'en', //df 'Togo',
    tk: 'en', //df 'Tokelau',
    to: 'en', //df 'Tonga',
    tt: 'en', //df 'Trinidad and Tobago',
    tn: 'ar',
    tr: 'tr',
    tm: 'tk',
    tc: 'en', //df 'Turks and Caicos Islands',
    tv: 'en', //df 'Tuvalu',
    ug: 'lg',
    ua: 'ru',
    ae: 'ar',
    gb: 'en',
    us: 'en',
    um: 'en', //df 'United States Minor Outlying Islands',
    uy: 'es',
    uz: 'en', //df 'Uzbekistan',
    vu: 'en', //df 'Vanuatu',
    ve: 'es',
    vn: 'vi',
    vg: 'en', //df 'Virgin Islands, British',
    vi: 'en', //df 'Virgin Islands, U.S.',
    wf: 'en', //df 'Wallis and Futuna',
    eh: 'en', //df 'Western Sahara',
    ye: 'ar',
    zm: 'en', //df 'Zambia',
    zw: 'en',
};

/**
 * Mapping language_code:country_code (official country's language)
 */
export const LangcodeCountryMapping = {
    aa: 'dj',
    af: 'za',
    am: 'et',
    an: 'es',
    ar: 'ae',
    as: 'in',
    az: 'az',
    be: 'by',
    bg: 'bg',
    bn: 'bd',
    bo: 'in',
    br: 'fr',
    bs: 'ba',
    ca: 'es',
    cs: 'cz',
    cv: 'ru',
    cy: 'gb',
    da: 'dk',
    de: 'de',
    dv: 'mv',
    dz: 'bt',
    el: 'gr',
    en: 'us',
    es: 'es',
    et: 'ee',
    eu: 'es',
    fa: 'ir',
    fi: 'fi',
    fo: 'fo',
    fr: 'fr',
    fy: 'nl',
    ga: 'ie',
    gd: 'gb',
    gl: 'es',
    gu: 'in',
    gv: 'gb',
    ha: 'ng',
    he: 'il',
    hi: 'in',
    hr: 'hr',
    ht: 'ht',
    hu: 'hu',
    hy: 'am',
    id: 'id',
    ig: 'ng',
    ik: 'ca',
    is: 'is',
    it: 'it',
    iu: 'ca',
    iw: 'il',
    ja: 'jp',
    ka: 'ge',
    kk: 'kz',
    kl: 'gl',
    km: 'kh',
    kn: 'in',
    ko: 'kr',
    ks: 'in',
    ku: 'tr',
    kw: 'gb',
    ky: 'kg',
    lg: 'ug',
    li: 'be',
    lo: 'la',
    lt: 'lt',
    lv: 'lv',
    mg: 'mg',
    mi: 'nz',
    mk: 'mk',
    ml: 'in',
    mn: 'mn',
    mr: 'in',
    ms: 'my',
    mt: 'mt',
    my: 'mm',
    nb: 'no',
    ne: 'np',
    nl: 'nl',
    nn: 'no',
    no: 'no',
    nr: 'za',
    oc: 'fr',
    om: 'et',
    or: 'in',
    pa: 'pk',
    pl: 'pl',
    ps: 'af',
    pt: 'pt',
    ro: 'ro',
    ru: 'ru',
    rw: 'rw',
    sa: 'in',
    sc: 'it',
    sd: 'in',
    se: 'no',
    si: 'lk',
    sk: 'sk',
    sl: 'si',
    so: 'so',
    sq: 'al',
    sr: 'rs',
    ss: 'za',
    st: 'za',
    sv: 'se',
    ta: 'in',
    te: 'in',
    tg: 'tj',
    th: 'th',
    ti: 'er',
    tk: 'tm',
    tl: 'ph',
    tn: 'za',
    tr: 'tr',
    ts: 'za',
    tt: 'ru',
    ug: 'cn',
    uk: 'ua',
    ur: 'pk',
    ve: 'za',
    vi: 'vn',
    wa: 'be',
    wo: 'sn',
    xh: 'za',
    yi: 'us',
    yo: 'ng',
    zh: 'cn',
    zu: 'za'
}
