import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Self, Optional, ElementRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';

@Component({
  selector: 'crm-routing-rule-select-form-input',
  templateUrl: './crm-routing-rule-select.component.html',
  styleUrls: [ './crm-routing-rule-select.component.scss' ],
  providers: [ { provide: MatFormFieldControl, useExisting: CrmRoutingRuleSelectFormInput } ]
})
export class CrmRoutingRuleSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<CrmRoutingRuleEntity> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  crmRoutingRuleList: Array<CrmRoutingRuleEntity> = [];
  flexSelectOptions: IFlexSelectOptions;

  loadedData = false;

  private configManager = ConfigManager.getInstance() as ConfigManager;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: CrmRoutingRuleEntity, filterString) => {
        if (!item) {
          return false;
        }
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: CrmRoutingRuleEntity, b: CrmRoutingRuleEntity) => {
        return a.getId() === b.getId();
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._loadData();
  }

  private async _loadData(): Promise<any> {
    this.crmRoutingRuleList = await this.em.getRepository('CrmRoutingRuleRepository').loadObjectList();
    this.loadedData = true;
  }

  get controlType(): string {
    return 'flex-crm-routing-rule-select-form-input';
  }
}
