import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'phone-number-renderer',
  template: `<span>{{ params.value | displayPhoneNumber }}</span>`
})
export class PhoneNumberRenderer implements ICellRendererAngularComp {
  params: any;
  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
