<flex-alert-upgrade [appPhone]="false"></flex-alert-upgrade>
<block-ui name="main">
  <div class="app mat-typography" [dir]="options.dir"
    [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
    <flex-progress-bar></flex-progress-bar>
    <app-header (toggleSidenav)="toggleMenu()" (toggleNotificationSidenav)="notifications.toggle()"
      [menuOpen]="isMenuOpen"></app-header>

    <mat-sidenav-container class="app-inner">
      <mat-sidenav #sidemenu class="sidebar-panel bg-dark"
        [ngClass.gt-xs]="{'sidebar-panel-gtxs': true, 'sidebar-panel-small': sidebarPanelSmall}" id="sidebar-panel"
        [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (openedStart)="sidePanelOpened = true"
        (closedStart)="sidePanelOpened = false" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()"
        (openedChange)="onOpenedChange($event)">
        <app-sidebar></app-sidebar>
      </mat-sidenav>
      <mat-sidenav #notifications position="end" class="chat-panel" mode="over" opened="false">
        <app-notification></app-notification>
      </mat-sidenav>
      <div class="router-outlet-container" data-ci="router-outlet-container">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-container>

    <!-- Demo Purposes Only -->
    <!--
        <app-options (messageEvent)="receiveOptions($event)"></app-options>
        -->
    <!-- /Demo Purposes Only -->
  </div>
</block-ui>
