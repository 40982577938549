<flex-select [placeholder]="placeholder" 
              [formControl]="flexSelect" 
              [itemList] = "didList"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              [multiple]="false">
  <ng-template #itemTemplate let-item="item" let-isTruncated="truncate">
    <span country-flag *ngIf="item.country_code" [countryCode2]="item.country_code"></span>
    <app-text-truncate *ngIf="isTruncated;else NotTruncate" [text]="item.number_with_name"></app-text-truncate>
    <ng-template #NotTruncate>{{ item.number_with_name }}</ng-template>
  </ng-template>
</flex-select>
