import { Entity, datafield } from '@wephone-core/model/model';
import { CrmType } from './enterprise';
import { CrmCallLogState, HotelCustomData, LogTo } from './enterprise_crm.i';
import * as _ from 'lodash';
export class EnterpriseCrmEntity extends Entity {
  public static object_type_id = 'enterprise_crm';

  @datafield public id: number;
  @datafield public enterprise_id: number;
  @datafield public name: string;
  @datafield public type: string;
  @datafield public url: string;
  @datafield public callstart_url: string;
  @datafield public callend_url: string;
  @datafield public username: string;
  @datafield public password: string;
  @datafield public apikey: string;
  @datafield public public_id: string;
  @datafield public is_active: number;
  @datafield public use_agent_id: number;
  @datafield public is_authenticated: number;
  @datafield public auth_method: string;
  @datafield public auto_run_task: number;
  @datafield public create_contact_inbound_call: number;
  @datafield public is_syncing: number;
  @datafield public sync_state: number;
  @datafield public version: string;
  @datafield public last_error_msg: string;
  @datafield public log_state_outbound: CrmCallLogState;
  @datafield public log_state_outbound_text: string;
  @datafield public log_state_inbound: CrmCallLogState;
  @datafield public log_state_inbound_text: string;
  @datafield public log_state_missed: CrmCallLogState;
  @datafield public log_state_missed_text: string;
  @datafield public mailbox_id: string;
  @datafield public mailboxes: object[] | null;
  @datafield public log_call_to: LogTo;
  @datafield public custom_data: HotelCustomData | undefined;

  get oh_client_id(): string {
    return this.custom_data && this.custom_data.client_id;
  }

  get oh_client_secret(): string {
    return this.custom_data && this.custom_data.client_secret;
  }

  get hotel_id(): string {
    return this.custom_data && this.custom_data.hotel_id;
  }

  get cashier_id(): string {
    return this.custom_data && this.custom_data.cashier_id;
  }

  get transaction_code(): string {
    return this.custom_data && this.custom_data.transaction_code;
  }

  private setDefaultCustomDataForOH(): void {
    if (!this.custom_data) {
      this.custom_data = {
        client_id: undefined,
        client_secret: undefined,
        hotel_id: undefined,
        cashier_id: undefined,
        transaction_code: undefined
      };
    }
  }

  set hotel_id(hotelId: string) {
    this.setDefaultCustomDataForOH();
    _.extend(this.custom_data, {
      hotel_id: hotelId
    });
  }

  set cashier_id(cashierId: string) {
    this.setDefaultCustomDataForOH();
    _.extend(this.custom_data, {
      cashier_id: cashierId
    });
  }

  set transaction_code(tranasctionCode: string) {
    this.setDefaultCustomDataForOH();
    _.extend(this.custom_data, {
      transaction_code: tranasctionCode
    });
  }

  get isConfigured(): boolean {
    if (this.is_authenticated || (this.type === CrmType.CUSTOMCRM && this.url)) {
      return true;
    }

    return false;
  }

  get isOracleHospitality(): boolean {
    return this.type === CrmType.ORACLEHOSPITALITY;
  }
}
