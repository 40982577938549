import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, ViewChild } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { DidCountryEntity } from '@wephone-core/model/entity/did_country';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { CountryFlagComponent } from '@wephone-common/form/country-flag/country-flag.component';
import * as ct from 'countries-and-timezones';
import { CountryGlobal, LocalManager } from '@wephone-core/service/local_manager';


@Component({
  selector: 'app-country-global-select',
  templateUrl: './country-global-select.component.html',
  styleUrls: ['./country-global-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CountryGlobalSelectComponent }]
})
export class CountryGlobalSelectComponent extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<any> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  countryList: any[];
  flexSelectOptions: IFlexSelectOptions;

  @ViewChild(CountryFlagComponent, { static: false }) countryFlag: CountryFlagComponent;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private localManager: LocalManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: CountryGlobal, filterString) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: CountryGlobal, b: CountryGlobal) => {
        return a && b && a.code2 === b.code2 || !b && !a;
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.countryList = this.localManager.getAllCountries();
  }

  get controlType(): string {
    return 'country-global-select-form-input';
  }
}
