<div>
  <a class="text-label" [hidden]="showInput" (click)="showText(true)">
    <span *ngIf="wrappedControl.value; else elseBlock">{{ wrappedControl.value }}</span>
    <ng-template #elseBlock>
      {{ emptyLabel }}
    </ng-template>
  </a>
  <mat-form-field [hidden]="!showInput" class="full-width">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <input #textInput [formControl]="myControl" [placeholder]="placeholder" matInput (blur)="showText(false)" />
  </mat-form-field>
</div>
