<ng-template #tplCellDefault let-row="row" let-col="col">
  <span class="flex-align-{{ getAlign(col) }}">{{ getCellValue(row, col) }}</span>
</ng-template>

<ng-template #BlockReloadDatasource>
  <a class="reload-link flex-blink" (click)="reloadDataSource()">
    {{ 'public.message.new_item_updated'|translate }}</a>
</ng-template>
<ng-template #tplExpandedDetailDefault let-row="row" let-is_selected="is_selected"></ng-template>

<div class="grouped_action_container" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
  <ng-container *ngIf="!inSelectionMode()">
    <button *ngFor="let item of defaultListActions" mat-fab [ngClass]="item.id" [attr.data-ci]="'button-' + item.id"
      [matTooltip]="item.hint | translate" matTooltipPosition="left" (click)="runActionCallback(item)">
      <mat-icon>{{item.icon}}</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="inSelectionMode()">
    <button *ngFor="let item of primarySelectionActions" mat-fab [attr.data-ci]="'button-' + item.id"
      [matTooltip]="item.hint | translate" matTooltipPosition="left" (click)="runActionCallback(item)">
      <mat-icon>{{item.icon}}</mat-icon>
    </button>

    <eco-fab-speed-dial #fabMore
      *ngIf="tableConfig?.listActions?.selectionActions?.secondary && secondarySelections.length"
      (mouseenter)="fabMore.open = true" (mouseleave)="fabMore.open = false" direction="up" animationMode="fling">
      <eco-fab-speed-dial-trigger [spin]="true">
        <button [attr.data-ci]="'button-more-actions'" mat-fab>
          <mat-icon>more_horiz</mat-icon>
        </button>
      </eco-fab-speed-dial-trigger>

      <eco-fab-speed-dial-actions>
        <button *ngFor="let item of secondarySelections" mat-mini-fab [attr.data-ci]="'button-' + item.id"
          [matTooltip]="item.hint | translate" matTooltipPosition="left" (click)="runActionCallback(item)">
          <mat-icon>{{item.icon}}</mat-icon>
        </button>
      </eco-fab-speed-dial-actions>
    </eco-fab-speed-dial>
  </ng-container>
</div>

<ng-template #exportTemplate>
  <button type="button" mat-raised-button color="primary" attr.aria-label="{{ 'public.export'|translate }}"
    (click)="onExportCsv()">
    <mat-icon>assignment_returned</mat-icon>
    <span>{{ 'public.export'|translate }}</span>
  </button>
</ng-template>

<div fxLayout="column" fxFlex="grow" fxLayoutGap="5px">
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="filterContainerVisible()" class="position-button">
    <div *ngIf="filterTemplate" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="start end">
      <div fxFlex="initial" *ngIf="tableConfig.enableExportCsv">
        <ng-container *ngTemplateOutlet="exportTemplate"></ng-container>
      </div>
      <div fxFlex="auto"></div>
      <div fxFlex *ngIf="tableConfig.enableFilter !== false">
        <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="20px"
      fxLayoutGap.xs="0" *ngIf="!filterTemplate">
      <div fxFlex="300px" align="start" fxFlex.xs="none" *ngIf="tableConfig.enableExportCsv">
        <ng-container *ngTemplateOutlet="exportTemplate"></ng-container>
      </div>

      <ng-container *ngIf="tableConfig.enableFilter; else BlockNoFilter">
        <div fxFlex fxFlex.xs="none">
          <div class="text-center" *ngIf="showReloadLink">
            <ng-container *ngTemplateOutlet="BlockReloadDatasource"></ng-container>
          </div>
        </div>

        <div fxFlex="300px" fxFlex.xs="none" align="end" fxLayoutAlign="end center">
          <flex-search-input (onKeyup)="onKeyupFilterStr($event)"
            (onBlur)="onBlurFilterStr($event)" (onEmpty)="onClearFilterStr($event)" ngClass.xs="search-full-width"
            [hintText]="searchHintText"></flex-search-input>
        </div>
      </ng-container>
    </div>

    <ng-template #BlockNoFilter>
      <div align="right" fxFlex="auto" *ngIf="showReloadLink">
        <ng-container *ngTemplateOutlet="BlockReloadDatasource"></ng-container>
      </div>
    </ng-template>

  </div>

  <div fxLayout="column" class="table_container" [ngClass]="tableConfigScrollable ? 'min_height' : ''">
    <cdk-virtual-scroll-viewport itemSize="40" class="viewport" [hidden]="!tableConfigScrollable">
      <ng-container *ngIf="tableConfigScrollable">
        <ng-container *ngTemplateOutlet="tplMainTable"></ng-container>
      </ng-container>
    </cdk-virtual-scroll-viewport>
    <ng-container *ngIf="!tableConfigScrollable">
      <ng-container *ngTemplateOutlet="tplMainTable"></ng-container>
    </ng-container>

    <ng-template #tplMainTable>
      <!-- <mat-table [hidden]="!dataSource" [dataSource]="hasPaginator ? dataSource : viewportDataSource" -->
      <mat-table [hidden]="!dataSource" [dataSource]="viewportDataSource"
        [multiTemplateDataRows]="multiTemplateDataRows" matSort [matSortActive]="dataSource?.sort?.active"
        [matSortStart]="dataSource?.sort?.start" [matSortDirection]="dataSource?.sort?.direction"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="select" *ngIf="tableConfig.multiSelect" class="mat-blue">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="masterToggle($event)" [attr.data-ci]="'chk-checkall'"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox [disabled]="isReadonly(row)" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="row_toggle" *ngIf="tableConfig.enableExpandDetail">
          <mat-header-cell *matHeaderCellDef fxFlex="40px">&nbsp;</mat-header-cell>
          <mat-cell *matCellDef="let row" fxFlex="40px" fxLayoutAlign="start center">
            <button mat-icon-button (click)="toggleDetail($event, row)">
              <mat-icon>{{ expandedElement == row ? 'expand_less' : 'expand_more'}}</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container *ngFor="let headerConfig of visibleheaders" [matColumnDef]="headerConfig.name">
          <ng-container *ngIf="headerConfig.sortable;else BlockHeaderConfigNoSortable">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="{{headerConfig?.width}}">{{ headerConfig.label |
              translate }}</mat-header-cell>
          </ng-container>
          <ng-template #BlockHeaderConfigNoSortable>
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerConfig?.width}}">{{ headerConfig.label | translate }}
            </mat-header-cell>
          </ng-template>

          <mat-cell *matCellDef="let row" fxFlex="{{headerConfig?.width}}"
            [ngClass]="{'enable-tooltip': headerConfig.enableTooltip}"
            fxLayoutAlign="{{headerConfig?.align=='right'?'flex-end':'start center'}}">
            <ng-container *ngIf="!headerConfig.enableTooltip; else tplEnableTooltip">
              <div class="flex-cell-inner">
                <ng-container
                  *ngTemplateOutlet="getCellTemplate(headerConfig);context:{row:row, col:headerConfig.name, is_selected:row === focusedRow}">
                </ng-container>
              </div>
            </ng-container>

            <ng-template #tplEnableTooltip>
              <div class="flex-cell-inner" [matTooltip]="getCellValue(row, headerConfig.name)"
                matTooltipPosition="above"
                [style.max-height.px]="row === focusedRow ? tableConfig.focusedRowHeight || tableConfig.rowHeight : tableConfig.rowHeight"
                [style.-webkit-line-clamp]="headerConfig.lineClamp || 2" [style.overflow-y]="'hidden'">
                <ng-container
                  *ngTemplateOutlet="getCellTemplate(headerConfig);context:{row:row, col:headerConfig.name, is_selected:row === focusedRow}">
                </ng-container>
              </div>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail" *ngIf="tableConfig.enableExpandDetail">
          <mat-cell *matCellDef="let detail">
            <ng-container
              *ngTemplateOutlet="getExpandedDetailTemplate();context:{row:detail.element, is_selected:detail.element === focusedRow}">
            </ng-container>
          </mat-cell>
        </ng-container>

        <mat-header-row [hidden]="noHeader" class="list-header" *matHeaderRowDef="columns_with_select; sticky: true">
        </mat-header-row>

        <!-- cdkVirtualScrollViewport uses transform: translateY to correct for all elements that are removed. 
    We will use a plcaholder row in the table instead because the translate causes problems with the sticky header -->
        <mat-row [style.height.px]="placeholderHeight"
          *matRowDef="let row; let index = index; columns: []; when: placeholderWhen"></mat-row>

        <ng-container *ngIf="!tableConfig.enableExpandDetail;else EnableExpandDetail">
          <mat-row *matRowDef="let row; columns: columns_with_select" [ngClass]="getRowClass(row)"
            (click)="onRowClicked(row)" (dblclick)="onRowDoubleClicked(row)"
            [style.height.px]="row === focusedRow ? tableConfig.focusedRowHeight || tableConfig.rowHeight : tableConfig.rowHeight">
          </mat-row>
        </ng-container>
        <ng-template #EnableExpandDetail>
          <mat-row *matRowDef="let row; columns: columns_with_select;" [ngClass]="getRowClass(row)"
            class="flex-table-row" (click)="onRowClicked(row)" (dblclick)="onRowDoubleClicked(row)"
            [style.height.px]="row === focusedRow ? tableConfig.focusedRowHeight || tableConfig.rowHeight : tableConfig.rowHeight"
            [class.expanded]="expandedElement == row">
          </mat-row>
          <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
            [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
            [ngClass]="getRowClass(row.element)" class="detail-expand" style="overflow: hidden">
          </mat-row>
        </ng-template>

      </mat-table>
    </ng-template>

    <div class="flex-pagination" [hidden]="!hasPaginator" fxLayoutAlign="center start">
      <mat-paginator [hidden]="!hasPaginator" flex-paginator [length]="totalItems" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions" (page)="getNextPage($event)"></mat-paginator>
    </div>

  </div>

  <div class="spinner">
    <mat-spinner *ngIf="isLoading()" [diameter]="50"></mat-spinner>
  </div>
</div>
