import { Injectable } from '@angular/core';
import { IDialogFlowResponse } from '../dialog-flow.i';
import { HttpEngine } from '@wephone-core/service/http_engine';

@Injectable()
export class DialogFlowService {
  SERVER_URL = '/ws-v2/api/chatbot/send';
  componentRegistry = {};

  constructor(
    private httpEngine: HttpEngine
  ) {
  }

  async sendRequest(req: string): Promise<IDialogFlowResponse> {
    const data = {
      msg: req
    };
    const ret = await this.httpEngine.post(this.SERVER_URL, data);
    const params = {};
    if (ret.param) {
      for (const k in ret.param) {
        if (ret.param.hasOwnProperty(k)) {
          const v = ret.param[k];
          params[k] = v[v.kind];
        }
      }
    }
    const information = {
      intentId: ret.intentId,
      intentName: ret.intentName,
      params
    };

    return information;
    /*return {
      intentId: 'abcd',
      intentName: 'number-request',
      params: {
        number: 1,
        did_type: 'mobile',
        location: 'paris'
      }
    };*/
  }

  registerComponent(intentName: string, componentClass: any): void {
    this.componentRegistry[intentName] = componentClass;
  }

  getComponentForIntent(intentName: string): any {
    const componentClass = this.componentRegistry[intentName];
    return componentClass;
  }
}
