import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class HotelRoomRepository extends BaseRepository {
  ENTITY_CLASS = HotelRoomEntity;
  // protected USE_PUSH_API = true;
  protected URL_PREFIX = 'hotelroom';
  protected RELATED_REPOSITORIES: string[] = ['sipphone'];
  private URL_ADD_MULTIPLE_HOTELROOM = 'add_multiple';
  // private URL_GET_HOTELROOM_DATA = 'hotelroom/get_room_data/__ROOM_ID__';
  private URL_GET_HOTELROOM_RESERVAVTION = 'hotelroom/reservation_info/{{room_id}}';

  protected getUrl(suffix): string {
    return super.getUrlV2(suffix);
  }

  createMultipleHotelRoom(rangesNumber: IHotelRoomRRequestRangesNumber[], hasSipphone: boolean): Promise<any> {
    const url = this.getUrl(this.URL_ADD_MULTIPLE_HOTELROOM);
    return HttpEngine.getInstance().post(url, { ranges_number: rangesNumber, has_sipphone: hasSipphone ? 1 : 0 });
  }

  // async getHotelRoomById(roomId: number): Promise<HotelRoomEntity> {
  //   await HttpEngine.getInstance().apiGetV2(this.URL_GET_HOTELROOM_DATA.replace('__ROOM_ID__', `${roomId}`));
  //   return this.getObjectById(roomId);
  // }
  async getRoomReservationById(roomId: number): Promise<OracleHospitalityReservationInfo> {
    const ret = await HttpEngine.getInstance().apiGetV2(this.URL_GET_HOTELROOM_RESERVAVTION.replace('{{room_id}}', `${roomId}`));
    return ret;
  }

  getExistRoomNumber(roomNumber: string, excludedId?: number): HotelRoomEntity {
    return this.getObjectList().find(x => x.room_number === roomNumber && (!excludedId || excludedId && x.id !== excludedId));
  }
}

import { HotelRoomEntity } from '@wephone-core/model/entity/hotelroom';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { IHotelRoomRRequestRangesNumber, OracleHospitalityReservationInfo } from '../entity/hotelroom.i';
