import { Directive, AfterViewInit, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { TimerService } from '../../services/timer.service';

@Directive({
  selector: '[flexAnchorArea]',
  exportAs: 'flexAnchorArea'
})
export class FlexAnchorAreaDirective implements AfterViewInit, OnDestroy {
  activeAnchor: string;
  anchorParts = [];
  timerId: number;
  recalculationNeeded = false;
  anchorSelected = false;

  constructor(
    private el: ElementRef,
    private timer: TimerService
  ) {
  }

  ngAfterViewInit(): void {
    this.anchorParts = this.el.nativeElement.querySelectorAll('[anchorPart]');
    setTimeout(() => {
      this.activeAnchor = this.anchorParts[0] ? this.anchorParts[0].id : undefined;
    });

    this.timerId = this.timer.subscribe1000(
      () => {
        if (this.recalculationNeeded) {
          this.recalculationNeeded = false;
          this.setActiveAnchor();
        }
      }
    );
  }

  @HostListener('scroll', ['$event'])
  scrollHandler($event: any): void {
    if (this.anchorSelected) {
      this.anchorSelected = false;
    } else {
      this.recalculationNeeded = true;
    }
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe(this.timerId);
  }

  setActiveAnchor = () => {
    for (let i = 0; i < this.anchorParts.length; i++) {
      const currentAnchorPart = this.anchorParts[i];
      const nextAnchorPart = this.anchorParts[i + 1];
      const position = this.el.nativeElement.scrollTop + this.el.nativeElement.offsetTop;

      if (nextAnchorPart) {
        if (position < nextAnchorPart.offsetTop) {
          this.activeAnchor = currentAnchorPart.id;
          break;
        }
      } else {
        if (position >= currentAnchorPart.offsetTop) {
          this.activeAnchor = currentAnchorPart.id;
          break;
        }
      }
    }
  }

  scrollToElement(target: Element | string): void {
    const element: Element = typeof target === 'string' ? this.el.nativeElement.querySelectorAll(`#${target}`)[0] : target;
    element.scrollIntoView();
    this.activeAnchor = element.id;
    this.recalculationNeeded = false;
    this.anchorSelected = true;
  }
}
