
<div fxLayout="column" *ngIf="isUpload">
  <div fxLayout="row">
    <div fxFlex="100">
      <span>{{ 'public.message.download_sample_file'|translate }}</span>
      <button [attr.data-ci]="'button-downloadsample'" type="button" mat-icon-button (click)="downloadSampleFile()">
        <mat-icon [attr.aria-label]="'public.message.download_sample_file'|translate">save</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" class="my-1">
    <div fxFlex="100">
      <button [attr.data-ci]="'button-import_file'" (click)="fileInput.click()" mat-raised-button color="primary">{{'public.upload'|translate}}</button>
      <span *ngIf="importFile">{{importFile.name}}</span>
      <input #fileInput type="file" [accept]="fileSettings.extensions|extensionAccept" style="display:none" (change)="importCsv($event)">
    </div>
  </div>
</div>

<div fxLayout="column" *ngIf="isUploadResult">
  <ng-container fxFlex="100" *ngIf="errors.length; else BlockImportSuccess">
    <h4>{{ 'public.message.error_occurred'|translate }}</h4>
    <mat-error *ngFor="let msg of errors">{{ msg }}</mat-error>
  </ng-container>
  <ng-template #BlockImportSuccess>
    <div fxLayoutAlign="start center" class="text-left" fxLayout="column">
      <h3 class="mt-0">{{ 'public.message.import_result'|translate }}</h3>

      <div fxLayout="row" class="full-width">
        <mat-icon class="mat-text-primary">done_all</mat-icon>
        <div class="pl-1">{{ 'public.success'|translate }}: <strong>{{ importResult.result.success }}</strong></div>
      </div>
      
      <div fxLayout="row" class="full-width">
        <mat-icon class="mat-text-warn">error</mat-icon>
        <div class="pl-1">{{ 'public.failure'|translate }}: <strong>{{ importResult.result.failure }}</strong></div>
      </div>
      
      <div fxLayout="row" class="full-width">
        <mat-icon class="mat-text-warn">warning</mat-icon>
        <div class="pl-1">{{ 'public.duplicated'|translate }}: <strong>{{ importResult.result.duplicate }}</strong></div>
      </div>

      <div fxLayout="column" class="full-width mat-text-warn" *ngIf="importResult.messages">
        <p class="ma-0" *ngFor="let msg of importResult.messages">{{ msg }}</p>
      </div>
    </div>
  </ng-template>
</div>
