<ng-template #tplListFilter>
  <form [formGroup]="form" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
    fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.xs="0">
    <div>
      <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterString"
        [hintText]="'reusable_sound.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>

<flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_reusablesound'|translate }}" [dataSource]="dataSource"
  [tableConfig]="tableConfig" [columnTemplates]="{'name': tplName}" [filterTemplate]="tplListFilter"
  [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">
  <ng-template #tplName let-fileentry="row">
    {{ fileentry.name }}
  </ng-template>
</flex-crud-page>
