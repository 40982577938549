import { Component, Input } from "@angular/core";

@Component({
  selector: 'if-loading',
  templateUrl: './if-loading.component.html',
  styleUrls: ['./if-loading.component.scss'],
})

export class IfLoadingComponent {
  @Input() comptact: boolean;

}
