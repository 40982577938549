<flex-select [placeholder]="placeholder" 
              [formControl]="flexSelect" 
              [itemList] = "ivrScriptList"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              [multiple]="false">
  <ng-template #itemTemplate let-ivrScript="item" let-isTruncated="truncate">
    <app-text-truncate *ngIf="isTruncated;else NotTruncate" [text]="ivrScript.name"></app-text-truncate>
    <ng-template #NotTruncate>{{ ivrScript.name }}</ng-template>
  </ng-template>
</flex-select>
