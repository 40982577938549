import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { IEntity } from '@wephone-core/model/model.interface';
import { _tk } from '@wephone-translation';


@Component({
  selector: 'app-image-confirm-delete',
  templateUrl: './image-confirm-delete.component.html',
  styleUrls: ['./image-confirm-delete.component.scss']
})
export class ImageConfirmDeleteComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('dialogs.warning');
  inUsedObjects: any = {};

  constructor(
    dialogRef: MatDialogRef<ImageConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      inUsedObjects: {[key: string]: IEntity[]}
    },
    cdr: ChangeDetectorRef,
  ) {
    super(dialogRef, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.inUsedObjects = this.data.inUsedObjects;
  }

  public cancel(): void {
    this.dismiss();
  }
}
