import { Component, OnInit, ViewChild } from '@angular/core';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  IFlexTableSidePannelOptions,
  DialogService,
  ToastService,
  FlexCRUDPageComponent,
  DynamicFilterSource,
  regexSearch,
} from '@wephone-utils';
import { PauseReasonEditComponent } from '@wephone/components/pause-reason-edit/pause-reason-edit.component';
import { CreatePauseReasonModalComponent } from '@wephone/modals/create-pause-reason-modal/create-pause-reason-modal.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pause-reasons',
  templateUrl: './pause-reasons.component.html',
  styleUrls: ['./pause-reasons.component.scss']
})
export class PauseReasonsComponent extends FlexBasePage implements OnInit {

  private readonly pauseReasonRepo = PauseReasonRepository.getInstance<PauseReasonRepository>();
  private readonly pauseReasonList: PauseReasonEntity[];

  filterForm: FormGroup;
  lbLabel;
  filterValue: string;
  tableConfig: any;
  dataSource: DynamicFilterSource;
  filterShowActive = true;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: PauseReasonEditComponent,
    beforeEditorOpen: PauseReasonsComponent.checkBeforeOpeningEditor
  };

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;
  static checkBeforeOpeningEditor = (item: PauseReasonEntity) => {
    if (item.after_call) {
      return false;
    }
    return true;
  }
  constructor(
    private readonly fb: FormBuilder,
    public readonly dialogService: DialogService,
    public readonly toast: ToastService,
    private readonly router: Router,
  ) {
    super();
    this.pauseReasonList = this.pauseReasonRepo.getObjectList();
    const pauseReasonSource = this.pauseReasonRepo.dataSource<PauseReasonEntity>();
    this.dataSource = new DynamicFilterSource(pauseReasonSource, this.filterFn);

    this.tableConfig = {
      columns: [
        {
          name: 'label',
          label: _tk('pause_reason.title.custom_pause'),
          enableTooltip: true,
          lineClamp: 2,
          sortable: true,
          sort: 'asc',
          customSortFn: (pauseReason: PauseReasonEntity) => {
            return pauseReason.label.toLowerCase();
          },
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            hint: _tk('pause_reason.title.create'),
            callback: () => {
              this.createNew();
            },
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              hint: _tk('public.delete'),
              callback: (params: any) => {
                return this.bulkDelete(params.selectedItems);
              },
            },
            {
              icon: 'archive',
              hint: _tk('public.archive'),
              callback: (params: any) => {
                return this.archivePauseReason(params.selectedItems);
              },
            },
            {
              icon: 'unarchive',
              hint: _tk('public.unarchive'),
              callback: (params: any) => {
                return this.unarchivePauseReason(params.selectedItems);
              },
            },
          ]
        }
      },
      isItemCheckableCb: this.isItemCheckable
    };

    this.filterForm = this.fb.group({
      active: [true],
      filterValue: [''],
      // pauseDisabled: [{ value: this.pauseDisabled && this.pauseDisabled.label, disabled: true }],
    });

    this.filterForm.valueChanges.subscribe(this.onFormValueChange);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataSource.setReadOnlyItems(this.pauseReasonList.filter(x => x.after_call === 1));
  }

  private readonly onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.filterShowActive = formValues.active;

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  private readonly filterFn = (pauseReason: PauseReasonEntity) => {
    return this.filterByArchived(pauseReason) && this.filterPredicate(pauseReason, this.filterValue);
  }

  private filterPredicate(item: PauseReasonEntity, filterValue: string): boolean {
    if (!filterValue) {
      return true;
    }

    return regexSearch(item.label, filterValue);
  }

  private readonly filterByArchived = (pauseReason: PauseReasonEntity): boolean => {
    return this.filterShowActive
      ? pauseReason.is_archived === 0
      : pauseReason.is_archived === 1;
  }

  private async createNew(): Promise<void> {
    const dialogRef = this.dialogService.openDialog2(CreatePauseReasonModalComponent, {});
    const createdPauseReason = await dialogRef.afterClosed().toPromise();
    if (!createdPauseReason) {
      return;
    }

    this.router.navigate(['callcenter-params', 'pause-reasons', createdPauseReason.id], { skipLocationChange: false });
  }

  private async updateArchives(pauseReasons: PauseReasonEntity[], isArchive: boolean): Promise<any> {
    try {
      const archive: number = isArchive ? 1 : 0;
      const pauseReason = await this.pauseReasonRepo.bulkArchive(pauseReasons, archive);
      this.filterByArchived(pauseReason);
      const msgSuccess = isArchive ? _ti('public.message.archive_success') : _ti('public.message.unarchive_success');
      this.toast.showSuccess(msgSuccess);
      return pauseReason;
    } catch (error) {
      const msgErrorDefault = isArchive ? _ti('public.message.archive_failure') : _ti('public.message.unarchive_failure');
      this.toastService.showErrorMessage(error, msgErrorDefault);
    }
  }

  private async bulkDelete(pauseReasons: PauseReasonEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('pause_reason.content.confirm_delete_message'),
      async () => {
        if (pauseReasons) {
          try {
            await this.pauseReasonRepo.bulkDelete(pauseReasons);
            this.flexCrud.closeSidePanel();
            this.toast.showSuccess(_ti('pause_reason.message.delete_success'));
          } catch (error) {
            this.toast.showErrorMessage(error, _ti('public.message.delete_failure'));
          }
        }
      }
    );
  }

  private async archivePauseReason(pauseReasons: PauseReasonEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.archive_confirm'),
      () => {
        this.updateArchives(pauseReasons, true);
      }
    );
  }

  private async unarchivePauseReason(pauseReasons: PauseReasonEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.unarchive_confirm'),
      () => {
        this.updateArchives(pauseReasons, false);
      }
    );
  }

  isItemCheckable = (pauseReason: PauseReasonEntity) => {
    return !pauseReason.after_call;
  }
}
