import { Entity, datafield } from '@wephone-core/model/model';

export class ShortcutEntity extends Entity {
  public static object_type_id = 'shortcut';

  public id: number;
  @datafield
  public display_text: string;
  @datafield
  public destination: string;
  @datafield
  public in_new_window: number;
}
