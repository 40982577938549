<ng-template #loading>
  <loading-screen fxFlexFill></loading-screen>
</ng-template>
<div fxLayout="column" class="pb-1" *ngIf="is_ready; else loading">
  <mat-toolbar class="no-drag active-calls-header bg-black top-radius text-white">
    {{ 'livecall.call_inprogress_list.list_title'|translate }}
  </mat-toolbar>

  <ng-container *ngIf="active_calls.length">
    <div *ngIf="dragDropEnable;else NotDragDropEnabled" id="active_calls" class="active-calls" fxLayout="row wrap"
      cdkDropList [cdkDropListData]="active_calls" (cdkDropListDropped)="dropCall($event)">
      <ng-container *ngFor="let queueItem of active_calls; let i = index">
        <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2px" class="row-calls active-call"
          [attr.call-id]="queueItem.id" cdkDrag [cdkDragDisabled]="!canDrag(queueItem)" [cdkDragData]="queueItem.id">
          <ng-container *ngTemplateOutlet="tplActiveCall; context: {queueItem: queueItem, idx: i}"></ng-container>
        </div>
      </ng-container>
    </div>
    <ng-template #NotDragDropEnabled>
      <div class="active-calls" fxLayout="row wrap">
        <ng-container *ngFor="let queueItem of active_calls; let i = index">
          <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2px" class="row-calls active-call">
            <ng-container *ngTemplateOutlet="tplActiveCall; context: {queueItem: queueItem, idx: i}"></ng-container>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</div>


<ng-template #tplActiveCall let-queueItem="queueItem" let-i="idx">
  <div fxFlex="auto" fxLayout="row" class="main-container" (mouseenter)="onMouseEnter(i)"
    (mouseleave)="onMouseLeave(i)">
    <!-- content -->
    <div fxFlex="auto" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
      <!-- avatar -->
      <div fxFlex="50px" fxFlex.sm="40px" fxLayout="column" fxLayoutAlign="center center"
        class="bg-gray py-0-5 call-avatar">
        <user-avatar fxShow fxHide.sm [user]="handingUser(queueItem)" width="50px" height="50px"
          matTooltip="{{ handingAgentName(queueItem) }}">
        </user-avatar>
        <user-avatar fxHide fxShow.sm [user]="handingUser(queueItem)" width="35px" height="35px"
          matTooltip="{{ handingAgentName(queueItem) }}">
        </user-avatar>
      </div>

      <!-- call number, group, queue -->
      <div fxFlex="auto" fxLayout="column" fxLayoutAlign="center start" class="bg-gray pa-0-5 call-number"
        [ngClass.sm]="{'pa-0-5': false, 'pa-7': true}">
        <div fxLayout="row" fxLayoutAlign="start center" class="phone-number font-weight-bold text-lg"
          fxLayoutGap="3px">
          <!-- call direction -->
          <span fxLayout="column" fxLayoutAlign="center center" class="bg-gray py-0" [ngClass]="{
              'bg-onhold': isSessionOnhold(queueItem), 
              'bg-ring': !queueItem.is_handled || !isSessionBridged(queueItem),
              'bg-handled': isSessionBridged(queueItem),
              'call-direction': true
            }">
            <ng-container *ngIf="queueItem.is_outgoing; else inCall">
              <mat-icon>arrow_upward</mat-icon>
            </ng-container>
            <ng-template #inCall>
              <mat-icon>arrow_downward</mat-icon>
            </ng-template>
          </span>
          <span class="font-18">{{ normalizeNumber(queueItem.remote_number) }}</span>
        </div>
        <!-- group, queue -->
        <div fxLayout="row" fxLayoutAlign="start center" class="call-info" fxLayoutGap="3px">
          <mat-icon class="icon-group">group</mat-icon>
          <span class="queue-name">{{ queueItem.queue?.queue_name }}</span>
          <span *ngIf="queueItem.group_id && hasFeatureGroup" class="group-name pl-1">({{
            queueItem.group?.name }})</span>
        </div>
      </div>

      <!-- call status -->
      <div fxFlex="300px" fxFlex.xs="initial" fxFlex.sm="initial" fxLayout="column" fxLayoutAlign="center center"
        class="bg-gray" [ngClass]="{
        'bg-onhold': isSessionOnhold(queueItem), 
        'bg-ring': !queueItem.is_handled || !isSessionBridged(queueItem),
        'bg-handled': isSessionBridged(queueItem),
        'call-status': true,
        'pa-0-5': true
      }" [ngClass.sm]="{
        'bg-onhold': isSessionOnhold(queueItem), 
        'bg-ring': !queueItem.is_handled || !isSessionBridged(queueItem),
        'bg-handled': isSessionBridged(queueItem),
        'call-status': true,
        'pa-7': true
      }">
        <div *ngIf="isSessionBridged(queueItem); else notSessionBridged">
          <ng-container *ngIf="queueItem.is_outgoing; else inCall">
            {{ 'livecall.call_outgoing'|translate }}
          </ng-container>
          <ng-template #inCall>
            {{ 'livecall.call_incoming'|translate }}
          </ng-template>
        </div>
        <ng-template #notSessionBridged>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <mat-chip-list fxFlex="100" *ngIf="queueItem.forced_operator_id">
              <mat-chip color="warn" selected class="pt-0 pb-0">
                ( {{ getAgentById(queueItem.forced_operator_id)?.name }}*)
              </mat-chip>
            </mat-chip-list>
            <div fxFlex="auto">{{ 'livecall.call_waiting'|translate }}</div>
            <mat-chip-list fxFlex="auto">
              <mat-chip color="warn" selected class="pt-0 pb-0">( {{ (queueItem.is_outgoing ? 'livecall.call_outgoing'
                :
                'livecall.call_incoming')|translate }} )
              </mat-chip>
            </mat-chip-list>
          </div>
        </ng-template>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <div fxFlex="auto">
            <mat-icon class="icon-time">access_time</mat-icon>&nbsp;
            <timer-since *ngIf="isSessionBridged(queueItem)" [since]="queueItem.session.bridged_dt"
              class="timer-since font-weight-bold"></timer-since>
            <timer-since *ngIf="!(isSessionBridged(queueItem) || !queueItem.arrive_dt)" [since]="queueItem.arrive_dt"
              class="timer-since font-weight-bold"></timer-since>
          </div>
          <div fxFlex="auto" matTooltip="{{ 'livecall.call_onhold'|translate }}" *ngIf="queueItem.session?.is_onhold">
            <mat-icon class="icon-hourglass">hourglass_empty</mat-icon>&nbsp;
            <timer-since [since]="queueItem.session.last_onhold_dt" class="timer-since font-weight-bold">
            </timer-since>
          </div>
        </div>
      </div>

    </div>

    <!-- actions -->
    <div fxLayoutAlign="center end" class="menu-container">
      <button mat-icon-button class="icon-action" [matMenuTriggerFor]="actionMenu" (menuOpened)="onMenuOpened(i)"
        (menuClosed)="onMenuClosed(i)" *ngIf="showActionBtn[i] && getActiveActions(queueItem).length">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #actionMenu="matMenu" x-position="before">
        <button mat-menu-item (click)="action.callback(queueItem)" *ngFor="let action of getActiveActions(queueItem)">
          <mat-icon [ngClass]="action.classIcon ? action.classIcon : ''">{{ action.icon }}</mat-icon>
          <span>{{ action.label|translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</ng-template>
