import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { DialogService } from '@wephone-utils';
import { AuthenticationService } from '@wephone-core/service/authentication';
// import { TranslateService } from '@ngx-translate/core';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import * as _ from 'lodash';
import { _ti } from '@wephone-translation';
import { FlexBaseComponent } from '@wephone-core-ui';

@Component({
  selector: 'agent-groups',
  templateUrl: 'agent-groups.html'
})
export class AgentGroupsComponent extends FlexBaseComponent {
  @Input() agent: AgentEntity;
  @Input() removeGroup: boolean;
  @Output() readonly on_change: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly dialogService: DialogService,
    private readonly authService: AuthenticationService,
    // private readonly translateService: TranslateService,
  ) {
    super();
  }

  get sortServiceGroupList(): any[] {
    if (!this.agent.group_ids) {
      return [];
    }
    return _.sortBy(this.agent.service_group_list, [a => a.name]);
  }

  is_admin_or_supervisor(): boolean {
    return this.removeGroup && (this.authService.isAdmin() || this.authService.isSupervisor());
  }

  agent_remove_group(group: UserGroupEntity): any {
    if (!this.is_admin_or_supervisor()) {
      console.error('Nor admin or supervisor');

      return;
    }

    const yes_cb = async () => {
      const extra_data = { user_ids: [] };
      for (const user of group.users) {
        if (user.getId() !== this.agent.user_id) {
          extra_data['user_ids'].push(user.getId());
        }
      }

      try {
        await UserGroupRepository.getInstance<UserGroupRepository>().saveAttrs(group, ['user_ids'], extra_data);
        this.successToast(_ti('public.message.delete_success'));
        this.on_changed(group);
      } catch (error) {
        console.error('Remove Agent from group Error: ', error);
        // let msg = _ti('public.message.delete_failure');
        // if (error) {
        //   if (error.message) {
        //     msg = error.message;
        //   } else if (error.error && error.error.message) {
        //     msg = error.error.message;
        //   }
        // }
        this.showErrorMessage(error, _ti('public.message.delete_failure'));
      }
    };

    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      this.getConfirmMessageRemoveAgentInGroup(group),
      yes_cb
    );
  }

  getConfirmMessageRemoveAgentInGroup(group: UserGroupEntity): string {
    return _ti('livecall.agent_list.confirm_group_remove_agent', { agent_name: this.agent.name, group_name: group.name });
  }

  on_changed(group: any): any {
    if (this.on_change) {
      this.on_change.emit(group);
    }
  }
}
