<div fxFill fxLayout="column" *ngIf="form">
  <form [formGroup]="form" fxLayout="column" class="form-change-highlight">
    <h2 fxFlex>{{'campaign.campaign_item.form.contact_information'|translate}}</h2>

    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">
      <!--Company-->
      <div fxFlex="calc(50%-10px)" fxFlex.xs="100">
        <mat-form-field class="full-width">
          <mat-label>{{ 'enterprise_contact.content.company'|translate }}</mat-label>
          <input class="full-width" matInput [placeholder]="'enterprise_contact.content.company'|translate"
            formControlName="company">

          <mat-error [flexFormControlError]="form.get('company')"></mat-error>
        </mat-form-field>
      </div>

      <!--AgentAlias-->
      <div fxFlex="calc(50%-10px)" fxFlex.xs="100">
        <mat-form-field class="full-width">
          <mat-label>{{ 'enterprise_contact.content.agent_alias'|translate }}</mat-label>
          <input class="full-width" matInput [placeholder]="'enterprise_contact.content.agent_alias'|translate"
            formControlName="agent_alias">
  
          <mat-error [flexFormControlError]="form.get('agent_alias')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">
      <!--FirstName-->
      <div fxFlex="calc(50%-10px)" fxFlex.xs="100">
        <mat-form-field class="full-width">
          <mat-label>{{ 'enterprise_contact.content.first_name' | translate }}</mat-label>
          <input matInput formControlName="first_name"
            [placeholder]="'enterprise_contact.content.first_name' | translate">

          <mat-error [flexFormControlError]="form.get('first_name')"></mat-error>
        </mat-form-field>
      </div>

      <!--LastName-->
      <div fxFlex="calc(50%-10px)" fxFlex.xs="100">
        <mat-form-field class="full-width">
          <mat-label>{{ 'enterprise_contact.content.last_name' | translate }}</mat-label>
          <input matInput formControlName="last_name"
            [placeholder]="'enterprise_contact.content.last_name' | translate">

          <mat-error [flexFormControlError]="form.get('last_name')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <!--Custom 1-->
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'enterprise_contact.content.custom1' | translate }}</mat-label>
        <input class="full-width" matInput [placeholder]="'enterprise_contact.content.custom1'|translate"
          [formControl]="form.controls['custom1']">

        <mat-error [flexFormControlError]="form.get('custom1')"></mat-error>
      </mat-form-field>
    </div>

    <!--Custom 2-->
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'enterprise_contact.content.custom2' | translate }}</mat-label>
        <input class="full-width" matInput [placeholder]="'enterprise_contact.content.custom2'|translate"
          [formControl]="form.controls['custom2']">

        <mat-error [flexFormControlError]="form.get('custom2')"></mat-error>
      </mat-form-field>
    </div>

    <!--Custom 3-->
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'enterprise_contact.content.custom3' | translate }}</mat-label>
        <input class="full-width" matInput [placeholder]="'enterprise_contact.content.custom3'|translate"
          [formControl]="form.controls['custom3']">
          
        <mat-error [flexFormControlError]="form.get('custom3')"></mat-error>
      </mat-form-field>
    </div>

    <h2>{{'campaign.campaign_item.form.for_call_information'|translate}}</h2>

    <!--PhoneNumber-->

    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">
      <!--Called-number-->
      <div fxFlex="calc(50%-10px)" fxFlex.xs="100">
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.campaign_item.form.called_number' | translate }}</mat-label>
          <input matInput placeholder="{{'campaign.campaign_item.form.called_number'|translate}} *"
            formControlName="called_number">
    
          <mat-error [flexFormControlError]="form.get('called_number')"
            [customMessages]="{
              'exists': 'campaign.validator.called_number_already_exists'|translate
            }"
          ></mat-error>
        </mat-form-field>
      </div>

      <!--Calling-number-->
      <div fxFlex="calc(50%-10px)" fxFlex.xs="100">
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.campaign_item.form.calling_number' | translate }}</mat-label>
          <input matInput placeholder="{{'campaign.campaign_item.form.calling_number'|translate}}"
            formControlName="calling_number">

          <mat-error [flexFormControlError]="form.get('called_number')"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <!--StartDate-->
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'campaign.schedule_start_dt' | translate }}</mat-label>
        <input matInput [matDatepicker]="scheduleStartDtPicker" [formControl]="form.controls['scheduled_dt']"
          [placeholder]="'campaign.schedule_start_dt' | translate">
        <mat-datepicker-toggle matSuffix [for]="scheduleStartDtPicker"></mat-datepicker-toggle>
        <mat-datepicker #scheduleStartDtPicker></mat-datepicker>
        <!-- <mat-error *ngIf="form.controls['scheduled_dt'].invalid && form.controls['scheduled_dt'].dirty">
          {{ 'form.validator.field_required'|translate }}</mat-error> -->
      </mat-form-field>
    </div>

    <!--ShowTime-->
    <div>
      <mat-slide-toggle (change)="changeShowTime($event)" [(ngModel)]="show_timepicker"
        [ngModelOptions]="{standalone: true}">{{'campaign.set_schedule_time'|translate}}</mat-slide-toggle>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.xs="0">
      <!--Hour start-->
      <div [hidden]="!show_timepicker" fxFlex="calc(50%-10px)" fxFlex.xs="100">
        <flex-time-picker formControlName="hour_start" placeholder="{{ 'campaign.hour_start'|translate }}">
        </flex-time-picker>
        <mat-error [flexFormControlError]="form.get('hour_start')"></mat-error>
      </div>

      <!--Hour end-->
      <div fxFlex="calc(50%-10px)" fxFlex.xs="100" *ngIf="show_timepicker">
        <flex-time-picker formControlName="hour_end" [min]="form.controls['hour_start'].value" [nullable]="true"
          placeholder="{{ 'campaign.hour_end'|translate }}"></flex-time-picker>
        
        <mat-error [flexFormControlError]="form.get('hour_end')"
          [customMessages]="{
            'greater': 'campaign.message.hour_end_must_greater_than_hour_start'|translate
          }"></mat-error>
      </div>
    </div>
    <!--Max-try-count-->
    <!-- <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.campaign_item.form.max_try_count' | translate }}</mat-label>
          <input type="number" matInput [formControl]="form.controls['max_try_count']" [placeholder]="'campaign.campaign_item.form.max_try_count' | translate">
          <mat-error *ngIf="form.controls['max_try_count'].invalid && form.controls['max_try_count'].dirty">
            {{ 'form.validator.field_required'|translate }}</mat-error>
        </mat-form-field>
      </div> -->
  </form>
</div>
