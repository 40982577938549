import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IvrAppEntity } from '@wephone-core/model/entity/ivrapp';
import { IEntity } from '../model.interface';
import { HttpEngine } from '@wephone-core/service/http_engine';


export class IvrAppRepository extends BaseRepository {
  ENTITY_CLASS = IvrAppEntity;
  protected URL_PREFIX: string = 'ivrapp';
  protected USE_PUSH_API = true;

}
