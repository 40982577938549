import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateArray'
})
export class TruncateArrayPipe implements PipeTransform {
  transform(values: string[], limit = 5, ellipsis = '...'): string {
    if (!values || !values.length) {
      return '';
    }
    return values.length > limit ? values.slice(0, limit).join(', ') + ellipsis : values.join(', ');
  }
}
