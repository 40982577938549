import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { VoiceMailRepository } from '@wephone-core/model/repository/voicemail';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ToastService } from '@wephone-utils';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import * as _ from 'lodash';

@Component({
  selector: 'sound-renderer',
  template: `<fileentry-player-button *ngIf="file_entry_ready" (onPlay)="listenVoicemail()" [filePublicId]="file_public_id" playerId="main"></fileentry-player-button>
  <span *ngIf="!file_entry_ready">{{ "fileentry.label.not_available"|translate }}</span>
  `
})
export class SoundRenderer implements ICellRendererAngularComp {
  file_entry_ready: any;
  file_public_id: any;
  file_on_play: any;
  params;
  message_type = 'queue';
  private userId: number;

  constructor(
    public authService: AuthenticationService,
    public toastService: ToastService,
    public fileentryService: FileEntryService,
  ) {
    this.userId = this.authService.getUserId();
  }

  agInit(params: any): void {
    this.params = params;
    this.file_public_id = params.value;
    this.file_entry_ready = params && params.data ? params.data.file_entry_ready === !!1 : false;
    this.file_on_play = this.fileOnPlay;
  }

  fileOnPlay = () => {
    console.log('File played', this.file_public_id);
  }

  refresh(): boolean {
    return false;
  }

  async listenVoicemail(): Promise<void> {
    try {
      if (this.params.data.is_read == 1 && _.includes(this.params.data.readers || [], this.userId)) {
        throw new Error('Voice mail has been already listened');
      }
      if (!this.file_public_id) {
        throw new Error('Voice message has no public id');
      }
      const voicemail_repository: VoiceMailRepository = VoiceMailRepository.getInstance();
      const resp = await voicemail_repository.markVoiceMailListened(this.file_public_id);
      console.log('updateVoiceMailListened response', resp);
      this.params.data.is_read = 1;
      if (!this.params.data.readers) {
        this.params.data.readers = [];
      }
      this.params.data.readers.push(this.authService.getUserId());
      this.params.context.componentParent.unlistenInfo(this.message_type);
    } catch (error) {
      console.error('Update listened failure', error);
    }
  }
}
