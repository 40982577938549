import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { _ti } from '@wephone-translation';

@Injectable()
export class DataBuilderService {
  URL_DATA_SOURCE = '/WS/private/builder/';

  constructor(
    public http: HttpClient,
    private settings: FlexIvrSettings
  ) {
  }

  protected convertColumnType(serverType): string {
    switch (serverType) {
      case 'numeric':
        return 'numericColumn';
      case 'date':
      case 'datetime':
      default:
        return undefined;
    }
  }

  trans(input): string {
    if (!input) {
      return '';
    }

    if (typeof input === 'string') {
      const value = _ti(input);

      return value;
    }

    const params = _.cloneDeep(input.params);
    let translatedKey = _ti(input.key, params);

    if (_.isEmpty(params)) {
      // Manual translate with param because angular translate does not support %translated_key%
      for (const key of Object.keys(params)) {
        const val = params[key];
        translatedKey = translatedKey.replace(`%${key}%`, val);
      }

      return translatedKey;
    }
  }

  private wsPost(url, requestParams): Promise<any> {
    return this.http.post(this.settings.getAbsoluteUrl(url), requestParams).toPromise();
  }

  async loadColumns(dataSource, filters, config): Promise<any[]> {
    const response = await this.wsPost(`${this.URL_DATA_SOURCE}${dataSource}/columns`, filters);
    const columns = response['answer'];
    const newColumns = [];

    for (const key of Object.keys(columns)) {
      const c = this.convertColumn(key, columns[key], config);
      newColumns.push(c);
    }
    // newColumns.sort((a, b) => a.position - b.position);

    const sortedColumns = _.sortBy(newColumns, 'position', 'asc');
    sortedColumns.forEach((c, index) => {
      c.position = index;
    });

    return sortedColumns;
  }

  async delete_data(dataSource, filters): Promise<any> {
    const response = await this.wsPost(`${this.URL_DATA_SOURCE}${dataSource}/delete`, filters);

    return response;
  }

  async loadData(dataSource, filters, columns, parameters, includeTotal = false): Promise<any> {
    try {
      let params = _.cloneDeep(parameters);
      params = _.isEmpty(params) ? { start: 0, length: 100 } : params;
      _.extend(params, filters);
      const result = await this.wsPost(`${this.URL_DATA_SOURCE}${dataSource}/data`, params);
      let data = result.answer.data.map(row => {
        const newRow = {};
        for (const index of Object.keys(columns)) {
          const field = columns[index].field;
          newRow[field] = row[index];
        }

        return newRow;
      });

      if (includeTotal) {
        data = { data, total: result.answer.recordsTotal };
      }

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  private convertColumn(key, col, config): Object {
    const title = col.title ? this.trans(col.title) : '';
    const tooltip = col.tooltip ? this.trans(col.tooltip) : '';
    const newCol: any = {
      field: key,
      headerName: title,
      headerTooltip: tooltip || title,
      type: this.convertColumnType(col.type),
      position: col.position && parseInt(col.position) || 0,
      hide: col.visible === false,
      width: undefined,
      sort: col.sort,
      format: col.format,
      searchable: col.searchable,
      title: col.title,
    };

    // Custom column from ts
    if (config.columns && config.columns[key]) {
      if (config.columns[key].cellRenderer) {
        newCol.cellRenderer = config.columns[key].cellRenderer;
      }

      if (config.columns[key].valueGetter) {
        newCol.valueGetter = config.columns[key].valueGetter;
      }

      if (config.columns[key].cellStyle) {
        newCol.cellStyle = config.columns[key].cellStyle;
      }

      if (config.columns[key].width) {
        newCol.width = config.columns[key].width;
      }

      if (config.columns[key].maxWidth) {
        newCol.maxWidth = config.columns[key].maxWidth;
      }

      if (config.columns[key].minWidth) {
        newCol.minWidth = config.columns[key].minWidth;
      }
    }

    return newCol;
  }

  async exportCSV(dataSource, filters, parameters): Promise<any> {
    try {
      const url = `${this.URL_DATA_SOURCE}${dataSource}/data`;
      const params = _.cloneDeep(parameters);
      _.extend(params, filters);
      const fileName = `${dataSource}.csv`;
      params.__export_all__ = fileName;
      const data = await this.http.post(this.settings.getAbsoluteUrl(url), params, { responseType: 'text' }).toPromise();
      const a: any = document.createElement('a');
      a.href = `data:attachment/csv;charset=utf-8,%EF%BB%BF${encodeURI(data)}`;
      a.target = '_blank';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error(error);
    }
  }
}
