import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ToastService, SingletonBase } from '@wephone-utils';
import { AccessRight, UserRole } from '@wephone-core/system';
import { _ti } from '@wephone-translation';

@Injectable()
export class RoleGuardService extends SingletonBase implements CanActivate, CanActivateChild {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly toastService: ToastService
  ) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/s/signin']);

      return false;
    }

    const userRole: UserRole = this.authService.getUserRole();
    const expectedRoles: UserRole[] = route.data.expectedRoles;
    const accessPermission: AccessRight = route.data.accessPermission;
    const state: string = route.data.state; // for check more with access-right

    if (!expectedRoles || !expectedRoles.includes(userRole)) {
      this.toastService.showError(_ti('error_403.description'));
      this.router.navigate(['/']);

      return false;
    }

    if (accessPermission && !this.authService.hasAccessRight(accessPermission, state)) {
      this.toastService.showError(_ti('error_403.description'));
      this.router.navigate(['/dashboard']);

      return false;
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.canActivate(route);
  }
}
