<div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="0">
  <h4 *ngIf="data.description">{{ data.description }}</h4>
  <table class="table table-striped full-width">
    <tr class="text-left">
      <th>#</th>
      <th translate="call_queue.content.operator_associated">Agent</th>
      <th translate="call_queue.content.noneligible_queues">Illeligible queues</th>
      <!-- <th translate="call_queue.content.force_queue">New queue</th> -->
    </tr>
    <tr *ngFor="let agent_link of data.agent_links">
      <td>
        <mat-checkbox [(ngModel)]="selected_agent_links[agent_link.agent_id+'-'+agent_link.queue_id]"></mat-checkbox>
      </td>
      <td>{{ agent_link.agent.name }}</td>
      <td>{{ agent_link.uneligible_queue_names.join(', ') }}</td>
      <!-- <td>{{ agent_link.queue.queue_name }}</td> -->
    </tr>
  </table>
</div>
