import { datafield, Entity } from '@wephone-core/model/model';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { UserRepository } from '@wephone-core/model/repository/user';

enum VoiceMailMailboxType {
  USER = 'user',
  QUEUE = 'queue',
}
export class VoiceMailEntity extends Entity {
  static object_type_id = 'voicemail_message';
  static TYPE_USER = 'user';
  static TYPE_QUEUE = 'queue';

  id: number;
  mailbox_type: VoiceMailMailboxType;

  // for voice message
  is_read: number; // is read by current user, was set from outside, not from database
  is_deleted: number;
  is_archived: number;
  readers: number[];
  file_entry: FileEntryEntity;

  get is_type_user(): boolean {
    return this.mailbox_type === VoiceMailMailboxType.USER;
  }

  get is_type_queue(): boolean {
    return this.mailbox_type === VoiceMailMailboxType.QUEUE;
  }

  get reader_names(): string {
    if (this.readers && this.readers.length) {
      const user_names: string[] = [];
      for (const user_id of this.readers) {
        const user = UserRepository.getInstance().getObjectById(user_id);
        user_names.push(user.name || '');
      }
      return user_names.join(', ');
    }
    return '';
  }
}
