<form [formGroup]="form">
  <div>
    <mat-form-field class="full-width">
      <mat-label>{{ 'did.content.name'|translate }}</mat-label>
      <input matInput formControlName="name" data-ci="input-name">

      <mat-error 
        [flexFormControlError]="form.get('name')"
        [customMessages]="{
          'name_exist': 'public.message.name_exist'|translate
        }"></mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="!uiSipTrunk">
    <mat-form-field class="full-width">
      <mat-label>{{ 'did.content.welcome_message'|translate }}</mat-label>
      <textarea matInput formControlName="welcome_message" data-ci="textarea-welcome_message"></textarea>
      
      <mat-error [flexFormControlError]="form.get('welcome_message')"></mat-error>
    </mat-form-field>
  </div>
</form>
