<div class="pa-1">
  <form [formGroup]="qualForm" (ngSubmit)="onSubmit()">
    <div *ngIf="formConfig.editName">
      <mat-form-field class="full-width">
        <mat-label>{{ 'call_qualification.content.name' | translate }}</mat-label>
        <input type="text" matInput placeholder="{{ 'call_qualification.content.name' | translate }}"
          formControlName="name">
        <mat-error *ngIf="nameControl.hasError('required')">
          {{'form.validator.field_required'|translate}}
        </mat-error>
        <mat-error *ngIf="nameControl.hasError('maxlength')">
          {{'public.message.label_max_length_255'|translate}}
        </mat-error>
        <mat-error *ngIf="nameControl.hasError('whitespace')">
          {{'public.message.data_invalid'|translate}}
        </mat-error>
        <mat-error *ngIf="nameControl.hasError('duplicated')">
          {{'public.message.name_exist'|translate}}
        </mat-error>

      </mat-form-field>
    </div>
    <div *ngIf="formConfig.editGroup && hasFeature('SERVICE_GROUP')">
      <mat-form-field class="full-width">
        <mat-label>{{ 'call_qualification.content.group'|translate }}</mat-label>
        <mat-select placeholder="{{ 'call_qualification.content.group_name' | translate }}" formControlName="group">
          <mat-option>{{ 'public.empty' | translate }}</mat-option>
          <mat-option *ngFor="let group of groups" [value]="group">
            {{ group.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="formConfig.editGoal" class="full-width">
      <section>
        <mat-checkbox formControlName="isGoal">{{ 'call_qualification.content.is_goal' | translate }}</mat-checkbox>
      </section>
    </div>
  </form>
</div>
