import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { ConferenceRepository } from '@wephone-core/model/repository/conference';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { UserRepository } from '@wephone-core/model/repository/user';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { UserEntity } from './user';

export class ShortDialCodeEntity extends Entity {
  public static object_type_id = 'short_dial_code';

  public id: number;
  @datafield public dial_code: string;
  @datafield public match_prefix: string;
  @datafield public routing_data: any;
  @datafield public comment: string;

  get application_name(): string {
    return this.application ? 'routing_application.' + this.application + '.app_name' : '';
  }

  get application(): string {
    return this.routing_data && this.routing_data.application ? this.routing_data.application : undefined;
  }

  get routed_queue(): CallQueueEntity {
    return this.routedQueue();
  }

  private routedQueue(): CallQueueEntity {
    if (this.application === 'callqueue') {
      if (!this.routing_data.params.queue) {
        return undefined;
      }
      return EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(this.routing_data.params.queue);
    }
    return undefined;
  }

  get routed_menu(): IvrCustomMenuEntity {
    return this.routedMenu();
  }

  private routedMenu(): IvrCustomMenuEntity {
    if (this.application === 'ivr_custom_menu') {
      if (!this.routing_data.params.id) {
        return undefined;
      }
      return EntityManager.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getObjectById(this.routing_data.params.id);
    }
    return undefined;
  }

  get routed_application(): IvrScriptEntity {
    return this.routedApplication();
  }
  private routedApplication(): IvrScriptEntity {
    if (this.application === 'runvxmlscript') {
      if (!this.routing_data.params.ivrscript_id) {
        return undefined;
      }
      return EntityManager.getRepository<IvrScriptRepository>('IvrScriptRepository').getObjectById(this.routing_data.params.ivrscript_id);
    }
    return undefined;
  }

  get routed_remote_application(): IvrRemoteAppEntity {
    return this.routedRemoteApplication();
  }

  private routedRemoteApplication(): IvrRemoteAppEntity {
    if (this.application === 'remote_application') {
      if (!this.routing_data.params.id) {
        return undefined;
      }
      // required loaded remote application
      return EntityManager.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository').getObjectById(this.routing_data.params.id);
    }
    return undefined;
  }

  get routed_conference(): ConferenceEntity {
    return this.routedConference();
  }

  private routedConference(): ConferenceEntity {
    if (this.application === 'conference') {
      if (!this.routing_data.params.conference) {
        return undefined;
      }
      return EntityManager.getRepository<ConferenceRepository>('ConferenceRepository').getObjectById(this.routing_data.params.conference);
    }
    return undefined;
  }

  get routed_phone_number(): string {
    return this.routedPhoneNumber();
  }

  private routedPhoneNumber(): string {
    if (this.application === 'call_phone_number') {
      if (!this.routing_data.params.number) {
        return undefined;
      }
      return this.routing_data.params.number;
    }
    return undefined;
  }

  get routed_fileentry(): FileEntryEntity {
    return this.routedFileEntry();
  }

  private routedFileEntry(): FileEntryEntity {
    if (this.application === 'play_then_hangup') {
      if (!this.routing_data.params.sound_id) {
        return undefined;
      }
      return EntityManager.getRepository<FileEntryRepository>('FileEntryRepository').getObjectById(this.routing_data.params.sound_id);
    }
    return undefined;
  }

  get routed_webservice(): string {
    return this.routedWebService();
  }

  private routedWebService(): string {
    if (this.application === 'webservice') {
      if (!this.routing_data.params.url) {
        return undefined;
      }
      return this.routing_data.params.url;
    }
    return undefined;
  }

  get routed_user(): UserEntity {
    return this.routedUser();
  }

  private routedUser(): UserEntity {
    if (this.application === 'call_user') {
      if (!this.routing_data.params.id) {
        return undefined;
      }
      return EntityManager.getRepository<UserRepository>('UserRepository').getObjectById(this.routing_data.params.id);
    }
    return undefined;
  }

  get tooltip(): any {
    return this.getTooltip();
  }

  private getTooltip(): any {
    const action_data = this.routing_data;

    if (!action_data) {
      return;
    }

    const tooltip = [];
    const entity_manager = EntityManager.getInstance();

    for (const key of Object.keys(action_data.params)) {
      if (!action_data.params.hasOwnProperty(key)) {
        continue;
      }

      const param = action_data.params[key];
      const _get_tooltip = (value, key) => {
        return {
          param_name: `routing_application.params.${key}`,
          param_value: value
        };
      };
      const _getObjectParam = (param) => {
        let obj;
        switch (action_data.application) {
          case 'remote_application':
            // required loaded data
            obj = entity_manager.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository').getObjectById(param);
            break;
          case 'runvxmlscript':
            obj = entity_manager.getRepository<IvrScriptRepository>('IvrScriptRepository').getObjectById(param);
            break;
          case 'callqueue':
            obj = entity_manager.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(param);
            break;
          case 'conference':
            obj = entity_manager.getRepository<ConferenceRepository>('ConferenceRepository').getObjectById(param);
            break;
          case 'ivr_custom_menu':
            obj = entity_manager.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getObjectById(param);
            break;
          case 'call_phone':
            obj = entity_manager.getRepository<SipPhoneRepository>('SipPhoneRepository').getObjectById(param);
            break;
          case 'call_user':
            obj = entity_manager.getRepository<UserRepository>('UserRepository').getObjectById(param);
            break;
          case 'crm_route':
            obj = EntityManager.getRepository('CrmRoutingRuleRepository').getObjectById(param);
            break;
          default:
            break;
        }
        return obj;
      };

      const param_obj = _getObjectParam(param);
      let tooltip_item;
      switch (key) {
        case 'ivrscript_id':
        case 'conference':
        case 'id':
          tooltip_item = _get_tooltip(param_obj ? param_obj.name : '', key);
          break;
        case 'queue':
          tooltip_item = _get_tooltip(param_obj ? param_obj.queue_name : '', key);
          break;
        case 'sipphone_id':
          tooltip_item = _get_tooltip(param_obj ? param_obj.extension : '', key);
          break;
        case 'number':
        case 'extension':
        case 'default_ext':
        case 'url':
          tooltip_item = _get_tooltip(param, key);
          break;
        default:
          break;
      }
      if (tooltip_item) {
        tooltip.push(tooltip_item);
      }
    }
    return tooltip;
  }

  get routed_sipphone(): SipPhoneEntity {
    if (this.application === 'call_phone' && this.routing_data && this.routing_data.params && this.routing_data.params.sipphone_id) {
      return EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository').getObjectById(this.routing_data.params.sipphone_id);
    }
  }

  public routeToUser(user: IUserEntity): void {
    this.routing_data = { application: 'call_user', params: { id: user.id } };
  }

  public routeToQueue(queue: CallQueueEntity): void {
    this.routing_data = { application: 'callqueue', params: { queue: queue.id } };
  }

  public routeToConference(conference: ConferenceEntity): void {
    this.routing_data = { application: 'conference', params: { conference: conference.id } };
  }

  public routeToSipPhone(sipphone: SipPhoneEntity): void {
    this.routing_data = { application: 'call_phone', params: { sipphone_id: sipphone.id } };
  }

  public routeToApplication(ivrscript: IvrScriptEntity): void {
    this.routing_data = {
      application: 'runvxmlscript',
      params: {
        ivrscript_id: ivrscript.id
      }
    };
  }

  public routeToRemoteApplication(remoteApplication: IvrRemoteAppEntity): void {
    this.routing_data = {
      application: 'remote_application',
      params: { id: remoteApplication.id }
    };
  }

  public routeToMenu(ivrCustomMenu: IvrCustomMenuEntity): void {
    this.routing_data = { application: 'ivr_custom_menu', params: { id: ivrCustomMenu.id } };
  }

  public routeToPhoneNumber(phoneNumber: string): void {
    this.routing_data = { application: 'call_phone_number', params: { number: phoneNumber } };
  }

  public routeToFileEntry(fileEntry: FileEntryEntity): void {
    this.routing_data = { application: 'play_then_hangup', params: { sound_id: fileEntry.id } };
  }

  public routeToWebService(url: string): void {
    this.routing_data = { application: 'webservice', params: { url } };
  }
}
