<ng-container *ngIf="is_ready">

  <mat-toolbar fxLayout="row" class="no-drag agent-wrapper-header" [ngStyle]="headerStyle">
    <div fxFlex>
      <span class="title">{{ header }}</span><span class="item-count">({{ totalAgents }})</span>
    </div>
    <div fxFlex="40px">
      <button type="button" mat-icon-button (click)="displayContent = !displayContent">
        <mat-icon *ngIf="!displayContent">expand_more</mat-icon>
        <mat-icon *ngIf="displayContent">expand_less</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <mat-card class="mat-card-flat pa-0 ma-0 agent-wrapper-content" fxLayout="column"
    [ngClass]="{'mb-1': !displayContent}">

    <!-- <ng-content></ng-content> -->
    <available-agents [hidden]="!displayContent" (onDataUpdated)="onChangeAgentList($event)" #listAgents
      [agentSort]="agentSort" [displayType]="displayType" [searchText]="searchText" [dropEnable]="dropEnable"
      [callDirection]="callDirection" [filterGroupIds]="filterGroupIds" [filterUngroupedAgents]="filterUngroupedAgents"
      [filterEavesdrop]="filterEavesdrop" [filterAgentStatus]="filterAgentStatus">
    </available-agents>

  </mat-card>
</ng-container>
