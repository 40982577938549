import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { CallDirection, ICallDial, ICallLog } from '@wephone-app-phone/pages/history/calllog.i';
import { SingletonBase } from '@wephone-utils';
import { CallHistoryItem } from './cdr_service';

@Injectable()
export class CallLogService extends SingletonBase {
  static URL_GET_MY_CALL_LOG = 'cdr/mine_v2';
  static URL_GET_LAST_DEALS = 'cdr/last_deals';

  constructor(
    private readonly httpEngine: HttpEngine,
  ) {
    super();
  }

  getRemoteNumber(call: ICallLog): string {
    return call.user_direction === CallDirection.OUTBOUND ? call.called_number : call.calling_number;
  }

  getLocalNumber(call: ICallLog): string {
    return call.user_direction === CallDirection.OUTBOUND ? call.calling_number : call.called_number;
  }

  // convert from cdr
  cdrToCallLog(call: CallHistoryItem): ICallLog {
    const callLog: ICallLog = {
      id: call.id,
      called_number: call.called_number,
      calling_number: call.calling_number,
      connected_time: new Date(call.connected_dt),
      start_time: new Date(call.start_dt),
      // tslint:disable-next-line: strict-comparisons
      duration: call.hangup_dt >= call.connected_dt ?
        ((new Date(call.hangup_dt)).getTime() - (new Date(call.connected_dt)).getTime()) / 1000
        : 0,
      wait_duration: call.start_dt && call.hangup_dt && ((new Date(call.hangup_dt)).getTime() - (new Date(call.connected_dt)).getTime()) / 1000 || 0,
      user_direction: call.user_direction,
      is_missed: call.is_missed,
      is_voicemail: call.is_voicemail,
      is_read: call.is_read,
      readers: call.readers,
      agent_name: call.agent_name,
      voicemail_public_id: call.voicemail_public_id,
    };
    return callLog;
  }

  getCallLogs(params?: { start_dt: string, end_dt: string }): Promise<ICallLog[]> {
    return this.httpEngine.apiGetV2(CallLogService.URL_GET_MY_CALL_LOG, params);
  }

  getLastDialedCalls(): Promise<ICallDial[]> {
    return this.httpEngine.apiGetV2(CallLogService.URL_GET_LAST_DEALS);
  }
}
