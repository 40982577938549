import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { ConfigManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';

export interface ImportPhoneBookResult {
  data: any[];
  result: {
    success: number;
    failure: number;
    duplicate: number;
  };
  messages?: string[];
}
@Injectable()
export class PhoneBookService {
  private static URL_PHONEBOOK_IMPORT_CSV = 'phonebook/import_csv';
  private static URL_PHONEBOOK_DOWNLOAD_SAMPLE_CSV = 'phonebook/download_sample_csv';

  constructor(private httpEngine: HttpEngine) {}

  importCsv(file: any): Promise<ImportPhoneBookResult> {
    // console.log('Upload file', file);
    if (!file) {
      console.error('No file uploaded');

      return Promise.resolve(undefined);
    }

    const options: any = {
      data: {},
      file,
      method: 'POST',
      headers: { 'Content-Type': file.type }
    };

    const body = new FormData();
    body.append('file', file);

    return this.httpEngine.apiPostV2(PhoneBookService.URL_PHONEBOOK_IMPORT_CSV, body, options);
  }

  getPhonebookDownloadSampleUrl(): string {
    return FlexIvrSettings.getInstance().getEnterpriseUrlV2(PhoneBookService.URL_PHONEBOOK_DOWNLOAD_SAMPLE_CSV);
  }
}
