import { NgModule } from '@angular/core';
import { SpeechRecognitionButton } from './speech-recognition-button/speech-recognition-button.component';
import { Speech2TextService } from './service/speech-recognition.service';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { FormsModule } from '@angular/forms';
import { DialogFlowService } from './service/dialog-flow.service';
import { WephoneUtilsModule } from '@wephone-utils';

@NgModule({
  declarations: [ SpeechRecognitionButton, ChatbotComponent],
  imports: [
    FormsModule,
    WephoneUtilsModule
  ],
  exports: [ SpeechRecognitionButton, ChatbotComponent],
  entryComponents: [],
  providers: [ Speech2TextService, DialogFlowService ]
})
export class ChatbotModule {
}
