import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { IChangeAwareComponent, EditingComponent, FormService, DialogService, NoWhitespaceValidator, joiValidator, EmailValidator } from '@wephone-utils';
import { UserEntity } from '@wephone-core/model/entity/user';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ChangeUserPasswordModal } from '@wephone/modals/change-user-password/change-user-password';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { FlexIvrSettings, ConfigManager } from '@wephone-core/wephone-core.module';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef } from '@angular/material/dialog';
import { UserType } from '@wephone-core/system';
import { PhoneNumberOnlyDigitValidated } from '@wephone/services/form-validator';
import { UserRepository } from '@wephone-core/model/repository/user';
import { IRoutingAppDestinationData, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CallDestinationComponent } from '@wephone/components/call-destination/call-destination.component';
import { UserService } from '@wephone/services/user.service';
import { PermissionService } from '@wephone/services/permission.service';

@Component({
  selector: 'app-user-general',
  templateUrl: './user-general.component.html',
  styleUrls: ['./user-general.component.scss']
})
export class UserGeneralComponent extends EditingComponent implements OnInit, IChangeAwareComponent, OnChanges {
  @Input() user: UserEntity;
  @Output() readonly formValueChanges: EventEmitter<boolean>;
  @ViewChild('waitTimeoutConfigDestination', { static: false }) waitTimeoutConfigDestination: CallDestinationComponent;

  user_type_list: any[];
  sipphone: SipPhoneEntity;

  readonly uiSipTrunk: boolean = FlexIvrSettings.getInstance().uiSipTrunk();
  isCallCenter: boolean;
  hasServiceGroup: boolean;
  isAdmin: boolean;

  waitTimeoutConfigDestinationList: RoutingAppName[] = [
    RoutingAppName.callqueue,
    RoutingAppName.call_phone,
    RoutingAppName.call_phone_number,
    RoutingAppName.ivr_custom_menu,
    RoutingAppName.play_then_hangup,
    RoutingAppName.send_sms
  ];

  currentUserId: number;

  private hasInitialized = false;

  constructor(
    private readonly dialogService: DialogService,
    authService: AuthenticationService,
    public formService: FormService,
    protected readonly configManager: ConfigManager,
    protected readonly userService: UserService,
    protected readonly permissionService: PermissionService,
    protected readonly fb: FormBuilder
  ) {
    super();

    this.isAdmin = authService.isAdmin();
    this.formValueChanges = new EventEmitter<boolean>();

    this.user_type_list = [];
    this.configManager.getUserTypes().then(list => {
      this.user_type_list = list;
    });

    this.isCallCenter = FlexIvrSettings.getInstance().uiCallCenter();
    this.hasServiceGroup = this.configManager.hasFeature('SERVICE_GROUP');
    this.currentUserId = authService.getUserId();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initialize();
  }

  canChangeUser(user: UserEntity): boolean {
    return this.permissionService.canChangeUserFromGroup(user);
  }

  openChangeUserPasswordDialog($event: Event): MatDialogRef<any> {
    $event.preventDefault();
    $event.stopPropagation();
    return this.dialogService.openDialog2(ChangeUserPasswordModal, {
      data: { id: this.user.id }
    });
  }

  ngOnChanges(): void {
    if (!this.hasInitialized) {
      return;
    }
    // this.updateUserForForm();
    this.initialize();
  }

  updateUserForForm(): void {
    console.log(!this.hasInitialized ? 'init' : 'change' + ' user', this.user);
    this.sipphone = this.user.sipphone;
    this.initFormGroup();
  }

  async submitForm(): Promise<any> {
  }

  private resetWaitTimeoutConfigDestination(): void {
    if (this.waitTimeoutConfigDestination) {
      this.waitTimeoutConfigDestination.value = this.user.wait_timeout_config;
      this.waitTimeoutConfigDestination.resetForm();
    }
  }

  resetForm(): void {
    this.resetWaitTimeoutConfigDestination();
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  formHasChanged(): boolean {
    return super.formHasChanged();
  }

  private getFormResetData(): any {
    return {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      user_type: this.user.user_type,
      email: this.user.email,
      phone: this.user.phone,
      recording_mode: this.user.recording_mode,
      voicemail_enabled: this.user.voicemail_enabled,
      voicemail_by_mail_enabled: this.user.voicemail_by_mail_enabled,
      // voicemail_in_attachement: this.user.voicemail_in_attachement,
      group_ids: this.user.groups,
      tags: this.user.tagList,
      team_id: this.user.team,
      voicemail_welcome_file: this.user.voicemail_welcome_file,
      vm_timeout: this.user.voicemail_timeout || 0,
      wait_timeout_config: this.getWaitTimeoutConfig(),
    };
  }

  getChangeSet(): any {
    const changeset: any = this.formService.getChangeSet(this.form);
    if (this.isAccountantUser()) {
      changeset.group_ids = [];
    }

    const data: { [key: string]: any } = {};
    for (const field of Object.keys(changeset)) {
      switch (field) {
        case 'vm_timeout':
          data[field] = changeset[field] || 0;
          break;
        case 'wait_timeout_config':
          const waitTimeoutConfig = changeset[field] || null;
          if (
            !_.isEmpty(waitTimeoutConfig) &&
            waitTimeoutConfig.application === RoutingAppName.voicemail &&
            !_.isEmpty(waitTimeoutConfig.params) && !waitTimeoutConfig.params.mailbox_id
          ) {
            waitTimeoutConfig.params = {};
          }
          data[field] = waitTimeoutConfig;
          break;
        default:
          data[field] = changeset[field];
          break;
      }
    }

    if (data.hasOwnProperty('vm_timeout') && !data['vm_timeout']) {
      data['wait_timeout_config'] = null;
    }

    return data;
  }

  initialize(): void {
    this.updateUserForForm();
    this.hasInitialized = true;
  }

  initFormGroup(): void {
    const waitTimeoutConfig = this.getWaitTimeoutConfig();
    this.form = this.fb.group({
      firstname: [this.user.firstname, [Validators.maxLength(50), NoWhitespaceValidator]],
      lastname: [this.user.lastname, [Validators.maxLength(50), Validators.required, NoWhitespaceValidator]],
      user_type: [this.user.user_type],
      email: [this.user.email, [Validators.maxLength(255), Validators.required, EmailValidator]],
      phone: [this.user.phone, [Validators.maxLength(20), PhoneNumberOnlyDigitValidated()]],
      recording_mode: [this.user.recording_mode],
      voicemail_by_mail_enabled: [this.user.voicemail_by_mail_enabled],
      group_ids: [this.user.groups],
      tags: [this.user.tagList],
      team_id: [this.user.team],
      voicemail_welcome_file: [this.user.voicemail_welcome_file],
      vm_timeout: [this.user.voicemail_timeout || 0, joiValidator(Joi.number().min(0).max(3600).required())],
      wait_timeout_config: [waitTimeoutConfig]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );

    this.addSubscription(
      this.form.get('user_type').valueChanges.subscribe(changes => {
        if (this.isAccountantUser() || this.isWatcherUser()) {
          // reset config timeout
          this.vmTimeoutControl.setValue(this.user.voicemail_timeout);
          this.waitTimeoutConfigControl.setValue(waitTimeoutConfig);
        }
      })
    );
  }

  private getWaitTimeoutConfig(): IRoutingAppDestinationData {
    return this.user && !_.isEmpty(this.user.wait_timeout_config)
      ? _.cloneDeep(this.user.wait_timeout_config) : new IRoutingAppDestinationData();
  }

  async updateAttrs(user: UserEntity, update_user_fields: string[], user_attributes: any): Promise<UserEntity> {
    return Promise.resolve(user);
  }

  updateMailboxWelcomeFile = ($event: { new_file_entry: FileEntryEntity; remove?: boolean }) => {
    const new_file_entry: FileEntryEntity = $event.new_file_entry;
    if (!new_file_entry && !$event.remove) {
      console.error('File entry no longer exist');
      return;
    }

    console.log('updateMailboxWelcomeFile', new_file_entry);
    // this.user.voicemail_welcome_file = _.cloneDeep(new_file_entry);
    this.setFormControlValue('voicemail_welcome_file', new_file_entry);
  }

  private setFormControlValue(formControlName: string, value: any): void {
    this.form.get(formControlName).markAsDirty();
    this.form.get(formControlName).setValue(value);
  }

  isValid(): boolean {
    const existName: UserEntity = UserRepository.getInstance<UserRepository>().getUserByEmail(this.form.get('email').value, this.user.id);
    if (existName) {
      this.form.get('email').setErrors({ name_exist: true });
    }

    this.waitTimeoutConfigControl.setErrors(null);
    this.form.get('voicemail_welcome_file').setErrors(null);

    if (this.vmTimeoutControl.value) {
      const waitTimeoutConfig = this.waitTimeoutConfigControl.value;
      if (!_.isEmpty(waitTimeoutConfig) &&
        this.waitTimeoutConfigDestination
      ) {
        if (!this.waitTimeoutConfigDestination.validParam()) {
          console.error('wait_timeout_config error');
          this.waitTimeoutConfigControl.setErrors({ callDestination: true });
        }

        if (waitTimeoutConfig.application === RoutingAppName.voicemail && 
          !this.form.get('voicemail_welcome_file').value) {
          this.form.get('voicemail_welcome_file').setErrors({ required: true });
        }
      }
    }

    if (this.form.get('phone').valid) {
      this.form.get('phone').setErrors(null);
    }

    if (this.form.get('phone').value) {
      if (!this.userService.isValidSecondaryPhoneNumber(this.form.get('phone').value)) {
        this.form.get('phone').setErrors({ isForbidden: true });
      }
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    return true;
  }

  isAccountantUser(): boolean {
    return this.form.get('user_type').value === UserType.ACCOUNTANT;
  }

  isWatcherUser(): boolean {
    return this.form.get('user_type').value === UserType.WATCHER;
  }

  get vmTimeoutControl(): FormControl {
    return this.form.get('vm_timeout') as FormControl;
  }

  get waitTimeoutConfigControl(): FormControl {
    return this.form.get('wait_timeout_config') as FormControl;
  }

  updateWaitTimeoutConfig(data: IRoutingAppDestinationData): void {
    if (!this.vmTimeoutControl.value) {
      return;
    }

    let waitTimeoutConfigData: any;
    if (data && data.application) {
      waitTimeoutConfigData = data;
    }

    this.waitTimeoutConfigControl.markAsDirty();
    this.waitTimeoutConfigControl.setValue(waitTimeoutConfigData || null);
  }

  isVoicemailEnabled(): boolean {
    const waitTimeoutConfig = this.waitTimeoutConfigControl.value;
    return waitTimeoutConfig && waitTimeoutConfig.application === RoutingAppName.voicemail || false;
  }
}
