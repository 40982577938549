import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
// import { RoutingAppManager } from '@wephone-core/routing-app/routing-app-manager';
import { VoiceMailBoxEntity } from '@wephone-core/model/entity/voicemail_box';

export class RoutingAppVoicemail extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.voicemail;

  private mailbox: VoiceMailBoxEntity;

  setMailbox(mailbox: VoiceMailBoxEntity): void {
    this.setAppParams({ mailbox_id: mailbox.id });
  }

  setRelatedObject(): void {}

  getRelatedObject(): number {
    return this.appParams.mailbox_id;
  }

  getDescription(): string {
    return 'To my voicemail';
  }
}
