import { Component, OnInit, Self, Optional, ElementRef, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { HotelRoomEntity } from '@wephone-core/model/entity/hotelroom';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import * as _ from 'lodash';

@Component({
  selector: 'flex-select-hotel-room-form-input',
  templateUrl: './flex-select-hotel-room.component.html',
  styleUrls: ['./flex-select-hotel-room.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectHotelRoomFormInput }]
})
export class FlexSelectHotelRoomFormInput extends FlexMatInputWrapper
  implements OnInit, MatFormFieldControl<HotelRoomEntity> {
  static nextId = 0;

  @Input() disabledOptionFunc?: (item) => boolean;

  flexSelect: FormControl = new FormControl();
  hotelRoomList: HotelRoomEntity[];
  flexSelectOptions: IFlexSelectOptions;

  isLoading = true;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private readonly em: EntityManager,
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: HotelRoomEntity, filterString: string) => {
        return regexSearch(item.room_number, filterString);
      },
      compareWith: (a: HotelRoomEntity, b: HotelRoomEntity) => {
        if (!a || !b) {
          return false;
        }
        return a.id === b.id;
      },
      disabledOptionFunc: (a: HotelRoomEntity) => {
        return this.disabledOptionFunc && this.disabledOptionFunc(a) || false;
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.em
      .getRepository('HotelRoomRepository')
      .findAll()
      .then(hotelRooms => {
        this.hotelRoomList = _.sortBy(hotelRooms, ['room_number'], ['ASC']);
        this.isLoading = false;
      });
  }

  get controlType(): string {
    return 'flex-select-hotel-room-form-input';
  }
}
