import { Injectable } from '@angular/core';

import { ConfigManager } from '@wephone-core/service/config_manager';
import { WsRpcService } from '@wephone-core/service/wsrpc_service';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { MessageService } from '@wephone-utils';
import { ElectronService } from 'ngx-electron';
import { DialogService } from '@wephone-utils';

@Injectable()
export class AutoUpdateService {
  isDownloading: boolean = false;
  downloadPercent: number = 0;
  updateAvailable: boolean = false;

  constructor(
    private messageService: MessageService,
    private electron: ElectronService,
    private dlgService: DialogService
  ) {
    // this.electron.ipcRenderer.on('autoupdate.download_started', this.onDownloadStarted);
    // this.electron.ipcRenderer.on('autoupdate.update_available', this.onUpdateAvailable);
    // this.electron.ipcRenderer.on('autoupdate.download_progress', this.onDownloadProgress);
    // this.electron.ipcRenderer.on('autoupdate.update_downloaded', this.onUpdateDownloaded);
  }

  public askForDownload() {
    if (this.updateAvailable) {
      return this.dlgService.confirmDialog('New version is available', 'Download now and upgrade?', () => {
        this.startDownloading();
      });
    }
  }

  startDownloading() {
    // this.electron.ipcRenderer.send('autoupdate:download');
  }

  public checkForUpdate() {
    // this.electron.ipcRenderer.send('autoupdate:check');
  }

  onDownloadStarted = (data: any) => {
    this.isDownloading = true;
  };

  onDownloadProgress = (data: any) => {
    this.isDownloading = true;
    this.downloadPercent = data.percent;
  };

  onUpdateAvailable = (data: any) => {
    this.updateAvailable = true;
  };

  onUpdateDownloaded = () => {
    this.isDownloading = false;
  };
}
