import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
// import { SubscriptionEntity } from '@wephone-core/model/entity/subscription';
// import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
// import { EntityManager } from '@wephone-core/wephone-core.module';
// import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
// import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
// import { DialogService } from '@wephone-utils';
// import { SubscriptionPacksModal } from '@wephone/modals/subscription-packs-modal/subscription-packs-modal.component';

// const SMALL_WIDTH_BREAKPOINT = 960;
const SMALL_WIDTH_BREAKPOINT = 600;

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  // private readonly EnterpriseRepo: EnterpriseRepository = EntityManager.getRepository('EnterpriseRepository');

  mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  url: string;
  sidePanelOpened;
  options = {
    collapsed: true,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };

  @ViewChild('sidemenu') sidemenu;
  @ViewChild(PerfectScrollbarDirective) directiveScroll: PerfectScrollbarDirective;

  config: PerfectScrollbarConfigInterface = {};
  sidebarPanelSmall = false;
  isMenuOpen: boolean;

  // closeUpgrade = false;
  // leftDays: number;

  constructor(
    private readonly router: Router,
    private readonly routeSnapshot: ActivatedRoute,
    mediaObserver: MediaObserver,
    // private readonly dialogService: DialogService,
  ) {
    mediaObserver.media$.subscribe((change: MediaChange) => {
      this.options.collapsed = mediaObserver.isActive('xs');
    });
  }

  ngOnInit(): void {
    this.url = this.router.url;

    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const el = document.querySelector('.app-inner > .mat-drawer-content > div');
        if (el) {
          el.scrollTop = 0;
        }
        // document.querySelector('.app-inner > .mat-drawer-content > div').scrollTop = 0;
        this.url = event.url;
        this.runOnRouteChange();
      });

    // await this.checkSubscriptionSoonExpired();
  }

  // async checkSubscriptionSoonExpired(): Promise<void> {
  //   this.leftDays = undefined;
  //   const myEnterprise: EnterpriseEntity = this.EnterpriseRepo.getMyEnterprise();
  //   const subscription: SubscriptionEntity = await myEnterprise.getCurrentSubscription();
  //   const subscriptionPack: SubscriptionPackEntity = await myEnterprise.getCurrentSubscriptionPack();

  //   if (subscriptionPack && subscription.expired_dt) {
  //     const finalDate = subscription.expired_dt.diff(subscription.start_dt, ['days', 'hours']).toObject();
  //     this.leftDays = finalDate.days;
  //   }

  // }

  // showAlertUpgrade(): boolean {
  //   // return this.subscription && this.subscriptionPack && this.leftDays && this.leftDays <= 30 && !this.closeUpgrade;
  //   return this.leftDays !== undefined && this.leftDays <= 30;
  // }

  // openUpgrade(): void {
  //   this.dialogService.openDialog2(SubscriptionPacksModal, { hasBackdrop: true, size: 'l' }, async result => {
  //     if (!result) {
  //       console.log('Just close dialog, no payment was made');
  //       return;
  //     }

  //     await this.checkSubscriptionSoonExpired();
  //   });
  // }

  ngOnDestroy(): void {
    this._router.unsubscribe();
  }

  runOnRouteChange(): void {
    // tslint:disable-next-line: no-console
    console.debug('runOnRouteChange: ', this.routeSnapshot.url, this.routeSnapshot.data);
    if (this.isOver()) {
      this.sidemenu.close();
    }
  }

  receiveOptions($event): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (
      this.url === '/apps/messages' ||
      this.url === '/apps/calendar' ||
      this.url === '/apps/media' ||
      this.url === '/maps/leaflet' ||
      this.url === '/taskboard'
    ) {
      return true;
    }

    return this.mediaMatcher.matches;
  }

  menuMouseOver(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'side';
    }
  }

  updatePS(): void {
    if (!this.mediaMatcher.matches && !this.options.compact) {
      setTimeout(() => {
        this.directiveScroll.update();
      }, 350);
    }
  }

  toggleMenu(): void {
    const classList = this.sidemenu._elementRef.nativeElement.className.split(' ');
    if (classList.indexOf('sidebar-panel-gtxs') === -1) {
      this.sidebarPanelSmall = false;
      this.sidemenu.toggle();
    } else {
      this.sidemenu.open();
      this.sidebarPanelSmall = !this.sidebarPanelSmall;
      this.isMenuOpen = !this.sidebarPanelSmall;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    const innerWidth = window.innerWidth;
    if (innerWidth >= SMALL_WIDTH_BREAKPOINT) {
      this.sidebarPanelSmall = false;
    }
  }

  onOpenedChange($event): void {
    this.isMenuOpen = $event;
  }
}
