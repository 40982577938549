import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { EntityManager } from '@wephone-core/wephone-core.module';
// import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'enterprise-select-form-input',
  templateUrl: './enterprise-select.component.html',
  styleUrls: [ './enterprise-select.component.scss' ],
  providers: [ { provide: MatFormFieldControl, useExisting: EnterpriseSelectFormInput } ]
})
export class EnterpriseSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<EnterpriseEntity> {
  static nextId = 0;
  @Input() filterFn?: any;
  @Input() multiple: boolean;
  @Input() allowEmpty: boolean;

  flexSelect: FormControl = new FormControl();
  list: EnterpriseEntity[];
  flexSelectOptions: IFlexSelectOptions;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private em: EntityManager,
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: EnterpriseEntity, filterString: string) => {
        return item && item.name ? regexSearch(item.name, filterString) : false;
      },
      compareWith: (a: EnterpriseEntity, b: EnterpriseEntity) => {
        if (!a || !b) {
          return false;
        }

        return a.id === b.id;
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.em.getRepository<EnterpriseRepository>('EnterpriseRepository').findAll().then(
      (objectList: EnterpriseEntity[]) => {
        this.list = objectList;
        if (this.allowEmpty && !this.list.filter(x => !x.id).length) {
          this.list.unshift(new EnterpriseEntity());
        }

        if (this.filterFn) {
          this.list = this.list.filter(x => this.filterFn(x));
        }
      }
    );
  }

  get controlType(): string {
    return 'enterprise-select-form-input';
  }
}
