import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { IConferenceRepository } from '@wephone-core/model/repository/conference.i';

export class ConferenceRepository extends BaseRepository implements IConferenceRepository {
  ENTITY_CLASS = ConferenceEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'conference';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'UPDATE_ATTRS_BULK':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS_BULK);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'ADD_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_NEW_ATTRS);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }
}
