import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk } from 'jsphonelib/translation';
import * as _ from 'lodash';

export interface TestUrlServiceResultDataType {
  errors?: string[];
  responseData: any;
}

@Component({
  selector: 'app-test-url-service-result-modal',
  templateUrl: './test-url-service-result-modal.component.html',
  styleUrls: ['./test-url-service-result-modal.component.scss']
})
export class TestUrlServiceResultModalComponent extends DialogComponentBase {

  dialogTitle = _tk('dialog.test_result');

  errors: string[];
  hasError: boolean;
  responseData: any;

  constructor(
    dialogRef: MatDialogRef<TestUrlServiceResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: TestUrlServiceResultDataType,
  ) {
    super(dialogRef);
    this.hasError = !_.isEmpty(data.errors);
    this.errors = data.errors || [];
    this.responseData = data.responseData;
  }

}
