<div class="py-1">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div *ngIf="!isBlockCaller">
      <mat-form-field class="full-width">
        <mat-label>{{'blacklist.content.number'| translate}}</mat-label>
        <input matInput placeholder="{{'blacklist.content.number'| translate}}" formControlName="number">

        <mat-error [flexFormControlError]="form.get('number')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'blacklist.content.block_reason'| translate}}</mat-label>
        <input matInput placeholder="{{'blacklist.content.block_reason'| translate}}" formControlName="blockReason">

        <mat-error [flexFormControlError]="form.get('blockReason')"></mat-error>
      </mat-form-field>
    </div>

    <div class="mb-1">
      <mat-button-toggle-group fxLayout="row" formControlName="blockAction" class="button-multiple-choice">
        <mat-button-toggle fxFlex="50" data-ci="phoneNumberBlockAction.block" [value]="phoneNumberBlockAction.block"
          class="button-multiple-choice">
          {{'blacklist.content.block'|translate}}
        </mat-button-toggle>
        <mat-button-toggle fxFlex="50" data-ci="phoneNumberBlockAction.low_priority"
          [value]="phoneNumberBlockAction.low_priority" class="button-multiple-choice">
          {{'blacklist.content.low_priority'|translate}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div>
      <h2>{{ 'blacklist.content.block_expire' | translate }}</h2>
      <div class="date-expire">
        <span class="mat-text-warn" *ngIf="!form.get('blockExpire').value; else tplBlockExpire">{{
          'blacklist.content.block_duration.forever' | translate }}</span>
        <ng-template #tplBlockExpire>
          <span class="mat-text-warn" >{{ form.get('blockExpire').value | timeFormatDateWithSeconds }}</span>
        </ng-template>
      </div>
      <div>
        <mat-button-toggle-group class="button-multiple-choice">
          <mat-button-toggle *ngFor="let team of blockDurationList" [value]="team.value"
            [checked]="team.value == selectedBlock" (change)="changeExpire(team.value)" class="button-multiple-choice">
            {{ team.label }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>
</div>
