<div class="flexslide">
    <button *ngIf="!audioPlaying" mat-icon-button color="primary" (click)="play()"><mat-icon>play_arrow</mat-icon></button>
    <button *ngIf="audioPlaying" mat-icon-button color="primary" (click)="pause()"><mat-icon>pause</mat-icon></button>
    <ng-container *ngIf="!hideStop">
        <button mat-icon-button color="primary" *ngIf="audioPlaying" (click)="restart()"><mat-icon>stop</mat-icon></button>
    </ng-container>
    <ng-container *ngIf="!hideSeekbar">
        <mat-slider class="full-width" [(ngModel)]="seekbarValue"></mat-slider>
    </ng-container>
</div>
