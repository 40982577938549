import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { ToastService } from '@wephone-utils';
// import { TranslateService } from '@ngx-translate/core';
import { EnterpriseFileEntryService } from '@wephone-core/service/enterprise_file_entry_service';
import { EnterpriseFileEntryEntity } from '@wephone-core/model/entity/enterprise_fileentry';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FlexBaseComponent } from '@wephone-core-ui';
import { FAIcons } from '@wephone-custom-icon';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { UserRepository } from '@wephone-core/model/repository/user';
import { _ti } from '@wephone-translation';
import { FileSettings, getFileValidation } from '@wephone-utils/utils/file-util';

@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.html',
  styleUrls: ['./user-avatar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent extends FlexBaseComponent implements OnInit {
  PERIODICAL_UPDATE = true;

  FAIcons = FAIcons;

  private _user: IUserEntity;

  get user(): IUserEntity {
    return this._user;
  }
  @Input() set user(val: IUserEntity) {
    this._user = val;
    this.cdr.markForCheck();
  }
  @Input() width: string;
  @Input() height: string;
  @Input() uploadAvatar?: boolean;

  file_avatar: any;
  fileSettings: FileSettings;
  is_uploading: boolean;

  constructor(
    // private readonly userService: UserService,
    private readonly settings: FlexIvrSettings,
    private readonly toast: ToastService,
    private readonly entityManager: EntityManager,
    private readonly enterpriseFileentryService: EnterpriseFileEntryService,
    // private readonly translate: TranslateService,
    private readonly authService: AuthenticationService,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.file_avatar = undefined;
    if (!this.width) {
      this.width = 'auto';
    }

    if (!this.height) {
      this.height = 'auto';
    }

    // File avatar
    this.fileSettings = {
      extensions: ['.bmp', '.gif', '.jpg', '.jpeg', '.png', '.tiff'],
      max_size: this.settings.max_upload_size * 1024 * 1024
    };
  }

  async onAvatarSelected($event): Promise<EnterpriseFileEntryEntity> {
    this.file_avatar = undefined;
    if (!$event || !$event.srcElement || !$event.srcElement.files) {
      // not found solution to validate in local
      console.error('No file selected');

      return Promise.resolve(undefined);
    }

    this.file_avatar = $event.srcElement.files[0];
    if (this.file_avatar) {
      const fileValidation = getFileValidation(this.file_avatar, this.fileSettings);

      if (!fileValidation.valid) {
        this.file_avatar = undefined;

        this.toast.showError(fileValidation.message);

        return Promise.resolve(undefined);
      }
    }

    const avatar_title = `Avatar - ${(this.user.name)}`;
    this.is_uploading = true;

    try {
      const enterpriseFileEntry: any = await this.enterpriseFileentryService.upload(
        this.file_avatar,
        avatar_title,
        EnterpriseFileEntryEntity.CATEGORY_AVATAR_USER,
        EnterpriseFileEntryEntity.TYPE_IMAGE);

      if (enterpriseFileEntry) {
        const res: any = await this.updateUserAvatar(enterpriseFileEntry.id);
        if (res) {
          this.user = this.entityManager.getRepository('UserRepository').getObjectById(res.object_id);
        }
        this.is_uploading = false;
      }
      this.toast.showSuccess(_ti('public.message.update_success'));
      return enterpriseFileEntry;
    } catch (e) {
      this.is_uploading = false;
      this.toast.showErrorMessage(e, _ti('campaign.contact.upload.validator.file_not_accepted'));
    } finally {
      $event.target.value = '';
    }
  }

  async updateUserAvatar(fileId: number): Promise<UserEntity> {
    if (this.user.id === this.authService.getUserId()) {
      return this.entityManager.getRepository<UserRepository>('UserRepository').updateProfile(
        this.user, { avatar_id: fileId, replace_avatar: 1 });
    }

    return this.entityManager.getRepository('UserRepository').saveAttrs(
      this.user, ['avatar_id', 'replace_avatar'], { avatar_id: fileId, replace_avatar: 1 });
  }

  public async removeAvatar(): Promise<any> {
    try {
      this.user.avatar_id = null;
      if (this.user.id === this.authService.getUserId()) {
        await this.entityManager.getRepository<UserRepository>('UserRepository')
          .updateProfile(this.user, { avatar_id: null, replace_avatar: 1 });
      } else {
        await this.entityManager.getRepository('UserRepository')
          .saveAttrs(this.user, ['avatar_id', 'replace_avatar'], { replace_avatar: 1 });
      }
      this.toast.showSuccess(_ti('public.message.delete_success'));
    } catch (error) {
      console.error('Delete avatar error: ' + error);
      this.toast.showErrorMessage(error, _ti('public.message.delete_failure'));
    }
  }
}
