<div class="pack-container" *ngIf="isReady()">
  <div fxLayout="row" class="pack-pricing" fxLayoutAlign="center center" fxLayoutGap="30px">
    <div fxFlex="30" fxFill fxLayout="column" *ngFor="let pack of subscriptionPacks">
      <div class="pack-wrapper" [ngClass]="'pack-'+pack.pack_id">
        <div class="header">
          <div class="pack-logo">
            <img title="{{ pack.title }}" src="assets/images/pack-{{ pack.pack_id }}.png" alt="{{ pack.title }}">
          </div>
          <h1 class="pack-name">{{ pack.title }}</h1>
          <p class="text-center">{{ pack.subtitle }}</p>
          <div class="price-container">
            <div>
              <span
                class="price">{{ pack.price }}</span>
              <sup>{{ pack.currency|currencySymbol }}</sup>
            </div>
            <p><b>{{ pack.price_desc }}</b></p>
          </div>
          <hr class="mtb-10">
          <ul class="plan">
            <li>
              <b>{{ pack.description }}</b>
            </li>
            <li *ngFor="let feature of pack.feature_list">
              {{ feature }}
            </li>
            <li></li>
            <li></li>
          </ul>
        </div>

        <div class="action">
          <div class="more-details">
            <p><a target="_blank" [attr.href]="detailLink">{{'subscription-pack.upgrade.more-details'|translate}}</a></p>
            <button mat-raised-button color="accent" (click)="selectPack(pack)">Choose Plan</button>
          </div>
          
        </div>
      </div>
    </div>
  </div>

</div>
