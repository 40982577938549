import * as _ from 'lodash';

import { Component, OnInit, Input } from '@angular/core';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import {
  ToastService,
  EditingComponent,
  NoWhitespaceValidator,
} from '@wephone-utils';
import { FormBuilder, Validators } from '@angular/forms';
import { _tk, _ti } from '@wephone-translation';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { WorkingPauseType } from '@wephone-core/model/entity/pause_reason.i';

@Component({
  selector: 'app-pause-reason-edit',
  templateUrl: './pause-reason-edit.component.html',
  styleUrls: ['./pause-reason-edit.component.scss']
})
export class PauseReasonEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: PauseReasonEntity;

  private readonly pauseReasonRepo = PauseReasonRepository.getInstance<PauseReasonRepository>();
  private pauseReason;

  constructor(
    public toast: ToastService,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.pauseReason = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      label: [this.pauseReason.label, [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      nonWorkingPause: [this.pauseReason.working_pause === WorkingPauseType.NON_WORKING_REASON ? true : false],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  private getFormResetData(): any {
    return {
      label: this.pauseReason.label,
      nonWorkingPause: this.pauseReason.working_pause === WorkingPauseType.NON_WORKING_REASON ? true : false,
    };
  }

  async submitForm(): Promise<void> {
    const label: string = this.form.get('label').value.trim();
    const pauseReason: PauseReasonEntity = (EntityManager.getInstance().getRepository<PauseReasonRepository>('PauseReasonRepository'))
      .getObjectUsingLabel(label, this.pauseReason.id);

    if (pauseReason) {
      this.form.get('label').setErrors({ duplicated: true });
    }

    if (this.form.invalid) {
      this.toast.showError(_ti('form.validator.data_invalid'));
      return;
    }

    const updatedItem = this.pauseReasonRepo.create();
    updatedItem.setObjectData({
      id: this.pauseReason.id,
      label: _.trim(this.form.get('label').value),
      working_pause: this.form.get('nonWorkingPause').value ? WorkingPauseType.NON_WORKING_REASON : WorkingPauseType.WORKING_REASON,
    });

    try {
      const responseUpdate: any = await this.pauseReasonRepo.save(updatedItem);
      this.toast.show(_ti('pause_reason.message.update_success'));
      console.log('responseUpdate', responseUpdate);
      this.pauseReason = PauseReasonRepository.getInstance().getObjectById(responseUpdate.id);
      this.resetForm();
    } catch (e) {
      if (e.error && e.error.message) {
        this.toast.showError(e.error.message);
      } else {
        this.toast.showError(_ti('public.message.update_failure'));
      }
    }
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }
}
