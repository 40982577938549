<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="filterForm" class="filter-form" fxLayout="column">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start" fxLayoutGap="10px"
        fxLayoutGap.xs="0">
        <div>
          <mat-slide-toggle formControlName="active">{{ ('public.active') | translate }}</mat-slide-toggle>
        </div>
        <div>
          <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterValue"
            [hintText]="'pause_reason.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
        </div>
      </div>
      <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.xs="0px">
        <div *ngIf="filterForm.get('active').value">
          <mat-form-field class="width-auto mb-0">
            <input matInput disabled placeholder="{{'pause_reason.title.system_pause' | translate}}" formControlName="pauseDisabled" class="text-green">
          </mat-form-field>
          <span class="text-green">{{'pause_reason.message.system_pause'|translate }}</span>
        </div>
      </div> -->
    </form>
  </ng-template>

  <flex-crud-page #flexCrud [filterTemplate]="listFilterTpl" [tableConfig]="tableConfig" [dataSource]="dataSource"
    pageTitle="{{ 'menu.manage_pause_reason'| translate }}" [columnTemplates]="{'label': lbLabel}"
    [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">
  </flex-crud-page>
</div>
