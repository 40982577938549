import { Injectable } from '@angular/core';
import { SingletonBase, HttpClient } from '@wephone-utils';
import { IDataSourceService, IDataSourceFilter } from '../../wephone-utils/datasource/datasource-types';
import { FlexDataSource, IFlexDataSourceColumn } from 'src/app/wephone-utils/datasource/datasource';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';

/**
 * Service for getting data to ui-grid
 */

const URL_DATA_SOURCE = '/WS/private/builder/';

@Injectable()
export class DataSourceService extends SingletonBase implements IDataSourceService {
  private readonly HTTP_TIMEOUT = 120000;

  constructor(
    public httpEngine: HttpEngine,
    public httpClient: HttpClient,
  ) {
    super();
  }

  createDataSource(dsName: string): FlexDataSource {
    return new FlexDataSource(this, dsName);
  }

  // private convertColumnType(serverType: string): string {
  //   switch (serverType) {
  //     case 'numeric':
  //       return 'number';
  //     case 'date':
  //     case 'datetime':
  //       return 'date';
  //     default:
  //       return 'string';
  //   }
  // }

  private convertColumn(key, v: any): IFlexDataSourceColumn {
    const ret: IFlexDataSourceColumn = {
      name: key,
      displayName: v.title,
      type: v.type,
      uiWidget: v.ui_widget,
      // type: this.convertColumnType(v.type),
      // format: v.format || undefined,
      // headerTooltip: v.tooltip || true,
      visible: v.visible !== false,
      searchable: v.searchable !== false,
      enableSorting: v.sortable !== false,
      position: v.position ? parseInt(v.position) : 0,
      // width: v.width || undefined,
      // customFilter: v.customFilter,
      // expandRenderer: v.rendererExpandCallback
      // sort: v.sort || undefined
    };

    return ret;
  }

  delete_data(data_source, filters?: IDataSourceFilter): Promise<any> {
    return this.httpEngine.post(URL_DATA_SOURCE + data_source + '/delete', filters, undefined, false, this.HTTP_TIMEOUT).then(
      response => {
        return { result: 'OK' };
      },
      response => {
        console.error('load_data error', response);

        return response;
      }
    );
  }

  // using THEN in the function below instead of success/error
  // to be able to chain request
  load_columns(data_source, filters?: IDataSourceFilter): Promise<IFlexDataSourceColumn[]> {
    return this.httpEngine.post(URL_DATA_SOURCE + data_source + '/columns', filters, undefined, false, this.HTTP_TIMEOUT).then(
      // return remoteapi.load_data_columns(data_source, filters).then(
      res => {
        const c: IFlexDataSourceColumn[] = [];
        const columns = res.answer;
        for (const k of Object.keys(columns)) {
          if (!columns.hasOwnProperty(k)) {
            continue;
          }
          const v = columns[k];
          c.push(this.convertColumn(k, v));
        }

        return _.orderBy(c, 'position', 'asc');
      },
      res => {
        console.error('Cannot load column');
        throw new Error('Cannot load column');
        // return;
      }
    );
  }

  load_data(data_source, filters: IDataSourceFilter = {}, columns, parameters): Promise<any> {
    const params = parameters ? _.clone(parameters) : { start: 0, length: 20 };
    _.extend(params, filters);

    return this.httpEngine.post(URL_DATA_SOURCE + data_source + '/data', params, undefined, false, this.HTTP_TIMEOUT).then(
      response => {
        const data = [];
        if (response && response.answer && response.answer.data) {
          const receivedLines = response.answer.data;
          for (const k of Object.keys(receivedLines)) {
            if (!receivedLines.hasOwnProperty(k)) {
              continue;
            }
            const val = receivedLines[k];
            const row = {};
            for (const idx of Object.keys(columns)) {
              if (columns.hasOwnProperty(idx)) {
                const col = columns[idx];
                row[col.name] = val[idx];
              }
            }
            data.push(row);
          }
        }

        return { data, total: response.answer.recordsTotal };
        // return { data, total: response.answer.recordsFiltered };
      },
      err => {
        console.error('load_data error', err);
        throw new Error('Cannot load data');
        // return err;
      }
    );
  }

  export_csv(data_source, filters): void {
    const url = URL_DATA_SOURCE + data_source + '/data';

    // const params = _.clone(parameters);
    const params: any = {};
    _.extend(params, filters);

    const filename = data_source + '.csv';
    params.__export_all__ = filename;

    this.httpClient.post(url, params, { headers: { timeout: `${this.HTTP_TIMEOUT}` }, responseType: 'text' }).subscribe(data => {
      const a: any = document.createElement('a');
      a.href = 'data:attachment/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data);
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }

}
