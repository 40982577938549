import { Component, OnInit, Self, Optional, ElementRef, Input } from '@angular/core';
import { FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { SipPhoneRepository as SipTrunkRepository } from '@wephone-core/model/repository/sipphone';

@Component({
  selector: 'sip-trunk-select-form-input',
  templateUrl: './sip-trunk-select.component.html',
  styleUrls: ['./sip-trunk-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SipTrunkSelectFormInput }]
})
export class SipTrunkSelectFormInput extends FlexMatInputWrapper
  implements OnInit, MatFormFieldControl<SipTrunkEntity>, ControlValueAccessor {
  static nextId = 0;

  @Input() disabledOptionFunc?: () => boolean;

  flexSelect: FormControl = new FormControl();
  sipTrunkList: SipTrunkEntity[] = [];
  flexSelectOptions: IFlexSelectOptions;

  constructor(
    private readonly em: EntityManager,
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: SipTrunkEntity, filterString) => {
        if (!filterString) {
          return true;
        }

        return regexSearch(item.name, filterString);
      }

    };

    if (this.disabledOptionFunc) {
      this.flexSelectOptions.disabledOptionFunc = this.disabledOptionFunc;
    }
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sipTrunkList = this.em.getRepository<SipTrunkRepository>('SipTrunkRepository').getObjectList() || [];
  }

  get controlType(): string {
    return 'sip-trunk-select-form-input';
  }
}
