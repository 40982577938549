import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FlexBaseComponent } from '@wephone-core-ui';
import { LocationEntity } from '@wephone-core/model/entity/location';
import { LocationService } from '@wephone-core/service/locaction.service';
import { _ti, _tk } from '@wephone-translation';
import { DialogService, DynamicFilterSource, IFlexTableConfig, regexSearch } from '@wephone-utils';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-registered-sip-phones',
  templateUrl: './registered-sip-phones.component.html',
  styleUrls: ['./registered-sip-phones.component.scss']
})
export class RegisteredSipPhonesComponent extends FlexBaseComponent implements OnInit, OnDestroy {
  @Input() sipPhoneExtension: string;
  @Input() enableRestartSipPhone: boolean;

  tableConfig: IFlexTableConfig;
  displayedColumns = [];
  dataSource: DynamicFilterSource;
  locationList: LocationEntity[] = [];
  filterCtrl = new FormControl();
  hasData: boolean;

  // @ViewChild(MatSort) sort: MatSort;
  private readonly _dataSource: BehaviorSubject<LocationEntity[]>;

  constructor(
    private readonly locationService: LocationService,
    private readonly dialogService: DialogService,
  ) {
    super();
    this._dataSource = new BehaviorSubject<LocationEntity[]>([]);
  }

  getDataSource<T extends LocationEntity = any>(): Observable<T[]> {
    return this._dataSource.asObservable() as Observable<T[]>;
  }

  ngOnInit(): void {
    const ds = this.getDataSource<LocationEntity>();
    this.dataSource = new DynamicFilterSource(ds, this.filterFunc);

    this.tableConfig = {
      rowHeight: 80,
      // focusedRowHeight: 80,
      scrollable: false,
      multiSelect: false,
      enableFilter: false,
      enableExportCsv: false,
      columns: [
        {
          name: 'last_modified',
          label: _tk('location.content.last_modified'),
          searchable: false,
          sortable: true,
          width: '90px'
        },
        {
          name: 'expires',
          label: _tk('location.content.expires'),
          searchable: false,
          sortable: true,
          width: '90px'
        },
        {
          name: 'contact',
          label: _tk('location.content.contact'),
          searchable: true,
          sortable: true,
        },
        {
          name: 'user_agent',
          label: _tk('location.content.user_agent'),
          searchable: true,
          sortable: true,
          width: '100px',
        },
      ]
    };

    // this.displayedColumns = ['registration_time', 'expiration_time', 'contact', 'user_agent'];
    if (this.enableRestartSipPhone) {
      // this.displayedColumns.push('action');
      this.tableConfig.columns.push({
        name: 'action',
        label: _tk('public.action'),
        searchable: false,
        width: '50px'
      });
    }

    // this.dataSource.sort = this.sort;
    this.addSubscription(
      this.filterCtrl.valueChanges.subscribe(val => {
        // if (val) {
        //   this.dataSource.filter = val.trim().toLowerCase();
        //   return;
        // }
        // this.dataSource.filter = val;
        this.dataSource.onFilterChange();
      })
    );

    super.ngOnInit();
  }

  async resolveData(): Promise<any> {
    if (this.sipPhoneExtension) {
      // this.dataSource.data = await this.locationService.getLocationsBySipNumber(this.sipPhoneExtension);;
      const list = await this.locationService.getLocationsBySipNumber(this.sipPhoneExtension);
      this._dataSource.next(list);
      this.hasData = list.length > 0;
    }
  }

  private readonly filterFunc = (item: LocationEntity): boolean => {
    const filterString = this.filterCtrl.value;
    return this.filterPredicate(item, filterString);
  }

  private filterPredicate(item: LocationEntity, filterString: string): boolean {
    if (!filterString || !filterString.trim()) {
      return true;
    }

    return regexSearch(item.contact, filterString) ||
      regexSearch(item.user_agent, filterString);
  }

  restartSipPhone(e: MouseEvent, location: LocationEntity): any {
    e.stopPropagation();
    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('sipphone.content.confirm_restart_sip_phone'),
      async () => {
        try {
          await this.locationService.restartSipPhone(location.id);
          this.successToast(_ti('sipphone.message.restart_sip_phone_success'));
        } catch (e) {
          console.error('Restart sip phone error', e);
          this.showError(_ti('sipphone.message.restart_sip_phone_failure'));
          // const msg = e && e.error && e.error.message || e && e.message || _ti('sipphone.message.restart_sip_phone_failure');
          // this.toast.showError(msg);
        }

      }
    );
  }

}
