import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { EditingComponent, IFlexSelectOptions, joiValidator, regexSearch, ToastService, DialogService, IFlexDialogConfig, NoWhitespaceValidator } from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { HotelRoomEntity } from '@wephone-core/model/entity/hotelroom';
import { HotelWakeupCallStateType, OracleHospitalityReservationInfo } from '@wephone-core/model/entity/hotelroom.i';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { DateTime } from 'luxon';
import { HotelRoomLogModalComponent } from '@wephone/modals/hotel-room-log-modal/hotel-room-log-modal.component';
import { HotelWakeupCallRepository } from '@wephone-core/model/repository/hotel_wakeup_call';
import { HotelWakeupCallEntity } from '@wephone-core/model/entity/hotel_wakeup_call';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';

@Component({
  selector: 'app-edit-hotelroom',
  templateUrl: './edit-hotelroom.component.html',
  styleUrls: ['./edit-hotelroom.component.scss']
})
export class EditHotelroomComponent extends EditingComponent implements OnInit, OnDestroy {
  @Input() editingItem: HotelRoomEntity;

  hotelRoom: HotelRoomEntity;
  myEnterprise: EnterpriseEntity;
  hotelRoomSipPhones: SipPhoneEntity[] = [];
  filterSipPhoneSelectOptions: IFlexSelectOptions;
  form: FormGroup;
  lastWakeup: {
    state: string;
    wakeup_time: DateTime;
  };
  wakeupCallStateType: HotelWakeupCallStateType;

  steps: any;

  private hotelRoomRepo: HotelRoomRepository;
  roomReservation: OracleHospitalityReservationInfo;
  roomRangeDate: Date[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly em: EntityManager,
  ) {
    super();
    this.hotelRoomRepo = em.getRepository<HotelRoomRepository>('HotelRoomRepository');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    // reload hotel data [hotel-room, hotel-wakeup-call]
    // const hotelRoom = await this.hotelRoomRepo.getHotelRoomById(this.editingItem.id);
    this.myEnterprise = EnterpriseRepository.getInstance<EnterpriseRepository>().getMyEnterprise();
    await this.em.getRepository<HotelWakeupCallRepository>('HotelWakeupCallRepository').findAll(false);
    await this.hotelRoomRepo.findAll(false);

    const hotelRoom = this.hotelRoomRepo.getObjectById(this.editingItem.id);

    this.setHotelRoom(hotelRoom);

    this.steps = {
      _0room_information: 'hotelroom.content.room_information',
      _1client_information: 'hotelroom.content.client_information',
      _2wakeup_call: 'hotelroom.content.wakeup_call',
      _4call_log: 'hotelroom.content.call_log',
    };
    if (this.hotelRoom.sip_phone) {
      this.steps._3sip_info = 'sipphone.content.sip_connection_info';
    }

    const sipPhones: SipPhoneEntity[] = this.em.getRepository<SipPhoneRepository>('SipPhoneRepository').getObjectList();
    this.hotelRoomSipPhones = sipPhones.filter(sp => !sp.user_id);

    this.filterSipPhoneSelectOptions = {
      compareWith: (a: SipPhoneEntity, b: SipPhoneEntity) => (a && b ? a.id === b.id : a === b),
      filterFunc: (item: SipPhoneEntity, filterString): boolean => {
        return regexSearch(item.extension, filterString);
      },
      disabledOptionFunc: (item: SipPhoneEntity) => {
        return item.hotel_room_id && item.hotel_room_id !== this.hotelRoom.id;
      }
    };

    this.initFormGroup();

    await this.setRoomReservation();
  }

  setHotelRoom(hotelRoom: HotelRoomEntity): void {
    this.hotelRoom = hotelRoom && _.cloneDeep(hotelRoom);
    if (this.hotelRoom.last_wakeup_call &&
      (
        this.hotelRoom.last_wakeup_call.state === HotelWakeupCallStateType.FINISHED ||
        this.hotelRoom.last_wakeup_call.state === HotelWakeupCallStateType.FAILED
      ) &&
      this.hotelRoom.last_wakeup_call.wakeup_time
    ) {
      this.lastWakeup = {
        state: this.hotelRoom.last_wakeup_call.state === HotelWakeupCallStateType.FINISHED ? _ti('public.ok') : _ti('public.failure'),
        wakeup_time: this.hotelRoom.last_wakeup_call.wakeup_time.setZone(this.myEnterprise.timezone)
      };
    }
  }

  onChangeWakeupDate($event): void {
    this.form.get('wakeup_time').markAsDirty();
    this.form.get('wakeup_time').markAsTouched();
  }

  initFormGroup(): void {
    const wakeupTime = this.getWakeupTime();

    this.form = this.fb.group({
      room_number: [this.hotelRoom.room_number, [joiValidator(Joi.string().min(1).max(10).required()), NoWhitespaceValidator]],
      sip_phone: [this.hotelRoom.sip_phone],
      client_name: [this.hotelRoom.client_name, joiValidator(Joi.string().max(128).optional().allow('', null))],
      // is_checkout: [false],
      wakeup_time_enable: [wakeupTime.wakeup_time_enable],
      wakeup_time: [wakeupTime.wakeup_time],
      wakeup_date: [wakeupTime.wakeup_date && DateTime.fromFormat(wakeupTime.wakeup_date, 'yyyy-MM-dd').toJSDate()],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );

    // this.addSubscription(
    //   this.form.get('is_checkout').valueChanges.subscribe(changes => {
    //     this.cdr.markForCheck();
    //   })
    // );
  }

  get stepKeys(): string[] {
    return this.steps && Object.keys(this.steps).sort() || [];
  }

  get hasRoomCloud(): boolean {
    return !!this.hotelRoom &&
      !!this.hotelRoom.crm_hotel_room_id &&
      !!this.hotelRoom.crm_hotel_reservation_id;
  }

  private getWakeupTime(): {
    wakeup_time_enable: boolean;
    wakeup_time: string;
    wakeup_date: string;
  } {
    const wakeupCall: HotelWakeupCallEntity = this.hotelRoom.wakeup_call;
    let wakeupTimeEnable = false;
    let wakeupDateTime: DateTime;

    if (wakeupCall && wakeupCall.state === HotelWakeupCallStateType.ACTIVE) {
      wakeupTimeEnable = true;
    }

    wakeupDateTime = wakeupCall && wakeupCall.wakeup_time && wakeupCall.wakeup_time.setZone(this.myEnterprise.timezone);

    return {
      wakeup_time_enable: wakeupTimeEnable,
      wakeup_time: wakeupDateTime && wakeupDateTime.toFormat('HH:mm') || '',
      wakeup_date: wakeupDateTime && wakeupDateTime.toFormat('yyyy-MM-dd') || '',
    };
  }

  private getFormResetData(): Object {
    const wakeupTime = this.getWakeupTime();

    return {
      room_number: this.hotelRoom.room_number,
      sip_phone: this.hotelRoom.sip_phone,
      client_name: this.hotelRoom.client_name,
      // is_checkout: false,
      wakeup_time_enable: wakeupTime.wakeup_time_enable,
      wakeup_time: wakeupTime.wakeup_time,
      wakeup_date: wakeupTime.wakeup_date,
    };
  }

  getChangeSet(): any {
    const data: any = super.getChangeSet();
    if ('room_number' in data && data.room_number) {
      data.room_number = _.trim(data.room_number);
    }

    if ('sip_phone' in data) {
      data.sipphone_id = data.sip_phone && data.sip_phone.id || null;
      delete data.sip_phone;
    }

    // if ('is_checkout' in data) {
    //   data.checkout = data.is_checkout ? 1 : 0;
    //   delete data.is_checkout;
    // }

    if ('wakeup_time_enable' in data) {
      if (!data.wakeup_time_enable) {
        data.wakeup_time = null;
      }

      delete data.wakeup_time_enable;
    }

    if ('wakeup_time' in data && data.wakeup_time) {
      data.wakeup_time = data.wakeup_time;
    }

    if ('wakeup_date' in data && data.wakeup_date) {
      if (data.wakeup_date instanceof Date) {
        data.wakeup_date = DateTime.fromJSDate(data.wakeup_date).toFormat('yyyy-MM-dd');
      }
    }

    return data;
  }

  private isValidForm(): boolean {
    let valid: boolean = this.form.valid;

    if (!this.form.get('room_number').invalid && !!this.hotelRoomRepo.getExistRoomNumber(this.form.get('room_number').value, this.hotelRoom.id)) {
      this.form.get('room_number').setErrors({ exists: true });
      valid = false;
    }

    if (
      this.hotelRoom.client_name &&
      this.hotelRoom.client_name !== this.form.get('client_name').value
    ) {
      this.form.get('client_name').setErrors({ 'any.required': true });
      valid = false;
    } else if (this.form.get('client_name').hasError('any.required')) {
      this.form.get('client_name').setErrors(null);
    }

    this.form.get('wakeup_time').setErrors(null);
    this.form.get('wakeup_date').setErrors(null);
    if (this.form.get('wakeup_time_enable').value) {
      if (!this.form.get('wakeup_time').value) {
        this.form.get('wakeup_time').setErrors({ required: true });
        valid = false;
      }

      if (this.roomReservation && !this.form.get('wakeup_date').value) {
        this.form.get('wakeup_date').setErrors({ required: true });
        valid = false;
      }
    }

    return valid;
  }

  async submitForm(): Promise<any> {
    try {
      const updateData = this.getChangeSet();
      const attrList = Object.keys(updateData);

      if (!this.isValidForm() || _.isEmpty(updateData)) {
        this.form.markAllAsTouched();
        throw Error(_ti('public.message.data_invalid'));
      }

      await this.hotelRoomRepo.saveAttrs(this.hotelRoom, attrList, updateData);
      this.toast.showSuccess(_ti('public.message.update_success'));

      const hotelRoom = this.hotelRoomRepo.getObjectById(this.hotelRoom.id);
      this.setHotelRoom(hotelRoom);
      this.resetForm();
    } catch (e) {
      console.error('Cannot update hotel room', e);
      const msg = e && e.error && e.error.message || e && e.message || _ti('public.message.update_failure');
      this.toast.showError(msg);
    }
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    this.form.reset(this.getFormResetData(), options);
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  markWakeupTimeAsDirty(): void {
    const formItems = ['wakeup_time'];

    if (this.roomReservation) {
      formItems.push('wakeup_date');
    }

    for (const item of formItems) {
      this.form.get(item).markAsTouched();
      this.form.get(item).markAsDirty();
      this.form.get(item).updateValueAndValidity();
    }
  }

  changeWakeupCall(): void {
    this.markWakeupTimeAsDirty();
    this.isValidForm();
  }

  private async setRoomReservation(): Promise<void> {
    // if (!this.form.get('wakeup_time_enable').value) {
    //   return;
    // }

    this.roomReservation = null;
    if (this.editingItem.has_reservation) {
      try {
        this.roomReservation = await this.hotelRoomRepo.getRoomReservationById(this.hotelRoom.id);
      } catch (error) {
        console.error('Cannot get room reservation info', error);
      }
  
      if (this.roomReservation) {
        this.roomRangeDate[0] = DateTime.fromFormat(this.roomReservation.roomStay.arrivalDate, 'yyyy-MM-dd').toJSDate();
        this.roomRangeDate[1] = DateTime.fromFormat(this.roomReservation.roomStay.departureDate, 'yyyy-MM-dd').toJSDate();

        if (!this.form.get('wakeup_date').value) {
          this.form.get('wakeup_date').setValue(this.roomRangeDate[1]);
        }
      }
    }
  }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.form.get(controlName).setValue(null);
  }

  openCallLog(): void {
    const modalConfig: IFlexDialogConfig = {
      data: { hotel_room_id: this.hotelRoom.id }
    };
    this.dialogService.openDialog2(HotelRoomLogModalComponent, modalConfig);
  }
}
