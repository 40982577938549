import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class RoutingAppIvrMenu extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.ivr_custom_menu;
  private menu: IvrCustomMenuEntity;

  setIvrMenu(menu: IvrCustomMenuEntity): void {
    this.setAppParams({ id: menu.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.id) {
      this.menu = EntityManager.getRepository('IvrCustomMenuRepository').getObjectById(this.appParams.id);
    }
  }

  getRelatedObject(): IvrCustomMenuEntity {
    return this.menu;
  }

  getDescription(): string {
    const menu_name = this.menu ? this.menu.name : '<invalid>';

    return `To ivr menu ${menu_name}`;
  }
}
