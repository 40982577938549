import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService, MessageService, ToastService } from '@wephone-utils';
import { TranslatePipe } from '@ngx-translate/core';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { RecordingService } from '@wephone-core/service/recording_service';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'action-renderer',
  template: `<button color="warn" mat-icon-button (click)="deleteRecoredCall()"><mat-icon>delete</mat-icon></button>
  <button mat-icon-button [disabled]="!isFileReady" (click)="downloadRecoredCall()"><mat-icon>cloud_download</mat-icon></button>`,
  providers: [TranslatePipe]
})
export class ActionRecordingRenderer implements ICellRendererAngularComp {

  params: any;
  isFileReady;

  constructor(
    private dialogService: DialogService,
    private translatePipe: TranslatePipe,
    public messageService: MessageService,
    public fileEntryService: FileEntryService,
    public toastService: ToastService,
    public recordingService: RecordingService
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.isFileReady = params && params.data ? params.data.file_entry_ready == 1 : false;
  }

  refresh(): boolean {
    return false;
  }

  translate(key: string, params: any = {}): any {
    return this.translatePipe.transform(key, params);
  }

  async deleteRecoredCall() {
    const entity: any = this.params.data;
    console.log('delete', entity);

    this.dialogService.confirmDialog(
      this.translate('dialogs.confirmation'),
      this.translate('user.title.delete'),
      () => {
        this.bulkDelete([entity]);
      }
    );
  }

  downloadRecoredCall() {
    const fileName = 'recording_call-' + this.params.value + '.mp3';
    const entity: any = this.params.data;
    const download_url = this.fileEntryService.getFileUrl(entity.file_public_id, true);
    this.fileEntryService.download(download_url, fileName);
  }

  public bulkDelete(entities: Array<any>) {
    const items: Array<number> = [];
    for (const entity of entities) {
      items.push(entity.id);
    }

    this.recordingService.deleteRecordCalls(items).then(
      data => {
        this.params.context.componentParent.reloadClick();
        this.toastService.show(_ti('public.message.delete_success'));
      },
      resp => {
        console.error('Delete Voicemail Error: ', resp);
      }
    );
  }
}
