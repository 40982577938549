import { GroupEntity } from '@wephone-core/model/entity/group';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { EntityManager } from '@wephone-core/service/entity_manager';
// import { AgentRepository } from '../repository/agent';
// import { GroupRepository } from '../repository/group';
import { IGroupRepository } from '../repository/group.i';
import { UserRepository } from '../repository/user';
import { UserEntity } from './user';

export class TeamGroupEntity extends GroupEntity {
  static object_type_id = 'teamgroup';

  user_ids: number[] = [];
  users: IUserEntity[] = [];

  constructor() {
    super();
    this.group_type = 'team';
  }

  fetchRelatedData(): void {
    super.fetchRelatedData();
    this.updateList(
      this.users,
      EntityManager.getInstance()
        .getRepository<any>('UserRepository')
        .findByTeamId(this.id)
    );
  }

  hasUser(users?: UserEntity[] | undefined): boolean {
    const userList = users || UserRepository.getInstance<UserRepository>().getObjectList();
    // const teamIds = [this.id, ...GroupRepository.getInstance<GroupRepository>().getAllChildrenGroup(this).map(i => i.id)];
    const teamIds = [this.id, ...(EntityManager.getInstance().getRepository('GroupRepository') as IGroupRepository).getAllChildrenGroup(this).map(i => i.id)];
    const found = userList.find(u => u.team_id && teamIds.includes(u.team_id));
    return !!found;
  }
}
