import * as _ from 'lodash';
import { Debug } from '@wephone-utils/debug';

const debug = Debug('websocket');

export interface ConnectionStateCb {
  (event: ConnectionStateEvent): any;
}

export interface RpcDataCallback {
  (data: any): any;
}

export interface ConnectionStateEventParam {
  reconnection: boolean;
}

export enum ConnectionState {
  Disconnected = 'DISCONNECTED',
  Connected = 'CONNECTED',
  Connecting = 'CONNECTING',
  WaitToReconnect = 'WAIT_TO_RECONNECT'
}

export class ConnectionStateEvent {
  constructor(public name: ConnectionState, public params: ConnectionStateEventParam) {}
}

export class WSJsonRPCEngine {
  private _pending_requests: any = {};
  private _websocket?: any;
  private _is_connected = false;
  private _had_connected = false;
  private _auto_reconnect = true;
  private _next_request_id = 1;
  private _connection_state: string = ConnectionState.Disconnected;
  private _reconnection_time;
  private _reconnection_count = 0;
  private _ui_blocked: boolean;
  private connection_state_cb: ConnectionStateCb;
  private remote_url: string;

  constructor(
      private data_handled_cb?: RpcDataCallback
  ) {
    document.addEventListener('online', this.onNetworkOnline);
    document.addEventListener('offline', this.onNetworkOffline);
  }

  private onNetworkOnline() {
    console.log('Internet connection available');
  }

  private onNetworkOffline() {
    console.log('Internet connection lost');
    this.onConnectionState(ConnectionState.Disconnected);
  }

  private log_error(message?: any, ...args: any[]): void {
    console.error(message, ...args);
  }

  public ui_blocked(): boolean {
    return this._ui_blocked;
  }

  public isConnected(): boolean {
    return this._is_connected;
  }

  public getConnectionState() {
    return this._connection_state;
  }

  public getReconnectionTime() {
    return this._reconnection_time;
  }

  private onConnectionState(state, params?: ConnectionStateEventParam) {
    if (this._connection_state !== state) {
      this._connection_state = state;
      return this.connection_state_cb(new ConnectionStateEvent(state, params));
    }
  }

  public setRemoteUrl(remote_url: string): void {
    this.remote_url = remote_url;
  }

  public connect(connection_state_cb: ConnectionStateCb) {
    this._had_connected = false;
    this._auto_reconnect = true;
    return this._do_connect(connection_state_cb);
  }

  private _do_connect(connection_state_cb: ConnectionStateCb) {
    this.connection_state_cb = connection_state_cb;
    this.onConnectionState(ConnectionState.Connecting);

    // only one socket per engine
    if (this._websocket == undefined) {
      this._websocket = new WebSocket(this.remote_url);
    } else {
      if (this._websocket.readyState === 2) {
        // CLOSING
        const callback = () => {
          this._do_connect(connection_state_cb);
        };
        setTimeout(callback, 50);
      } else if (this._websocket.readyState === 3) {
        this._websocket = new WebSocket(this.remote_url);
      }
    }

    this._websocket.onopen = () => {};

    this._websocket.onclose = evt => {
      if (this._is_connected) {
        debug('Websocket is now DISCONNECTED');
      }
      this._is_connected = false;

      this.clearPendingRequests(evt);
      if (this._auto_reconnect && !AuthenticationService.getInstance().hasLoggedOut()) {
        this._reconnection_count += 1;
        const now = new Date();

        const callback = () => {
          if (this._auto_reconnect) {
            this._do_connect(connection_state_cb);
          }
        };

        if (this._reconnection_count > 1) {
          const reconnectionTimeout = 5000;
          this._reconnection_time = new Date(now.getTime() + reconnectionTimeout);
          this.onConnectionState(ConnectionState.WaitToReconnect);
          setTimeout(callback, reconnectionTimeout);
        } else {
          setTimeout(callback, 0);
        }
      } else {
        this.onConnectionState(ConnectionState.Disconnected);
      }
    };

    this._websocket.onerror = evt => {
      this.log_error('Error on web socket', evt);
      this.clearPendingRequests(evt);
      if (this._websocket) {
        this._websocket.close();
      }
      this._is_connected = false;
      this.onConnectionState(ConnectionState.Disconnected, evt);
    };

    this._websocket.onmessage = evt => {
      const res = JSON.parse(evt.data);
      let json_msg: any;
      let success_cb: RpcDataCallback;
      let failure_cb: RpcDataCallback;
      let is_subscription: boolean;
      let gui_blocked: boolean;

      if (!res.id) {
        if (res.result.message === 'AUTHENTICATED') {
          this._reconnection_time = undefined;
          this._reconnection_count = 0;
          this._is_connected = true;
          debug('Websocket is now CONNECTED');
          this.onConnectionState(ConnectionState.Connected, { reconnection: this._had_connected });
          this._had_connected = true;
          this.start_pinging();
        }
      }
      if (res.id in this._pending_requests) {
        [json_msg, success_cb, failure_cb, is_subscription, gui_blocked] = this._pending_requests[res.id];
        // We should consider unblocking the GUI only if the reply is for a UI blocking call
        let can_unlock_gui = gui_blocked;
        if (!is_subscription) {
          delete this._pending_requests[res.id];
        }
        if (res.success) {
          if (success_cb) {
            success_cb(res.result);
            if (this.data_handled_cb) {
              this.data_handled_cb(res.result);
            }
          }
        } else {
          debug('Error received from server %s', res.error);
          if (failure_cb) {
            failure_cb(res.error);
          } else {
            this.log_error(res.error);
          }
        }
        if (can_unlock_gui) {
          // Check if there are other pending UI blocking calls, if yes, do not unblock the GUI
          for (const k in this._pending_requests) {
            if (this._pending_requests.hasOwnProperty(k)) {
              const v = this._pending_requests[k];
              if (v[4]) {
                this.log_error('Cannot unlock gui because of ', v[0]);
              }
              can_unlock_gui = false;
              break;
            }
          }
        }
        if (can_unlock_gui) {
          this._ui_blocked = false;
        }
      }
    };
  }

  public clearPendingRequests(evt: any) {
    for (let i in this._pending_requests) {
      let failure_cb = this._pending_requests[i][2];
      if (failure_cb) {
        failure_cb(evt);
      }
    }
    this._pending_requests = [];
  }

  public disconnect() {
    this._auto_reconnect = false;
    if (this._websocket) {
      this._websocket.close();
    }
  }

  private _call_remote_method(
    method: string,
    params?: any,
    success_cb?: RpcDataCallback,
    failure_cb?: RpcDataCallback,
    is_subscription: boolean = false,
    block_ui: boolean = false
  ) {
    if (!this._is_connected) {
      this.log_error('Method ', method, params, 'got called while engine is not connected');
      return;
    }
    let json_msg = {
      jsonrpc: '2.0',
      method: method,
      params: params,
      id: this.get_next_request_id()
    };
    this._pending_requests[json_msg.id] = [json_msg, success_cb, failure_cb, is_subscription, block_ui];
    if (block_ui) {
      this._ui_blocked = true;
    }
    debug('sending data: %o', json_msg);
    this._websocket.send(JSON.stringify(json_msg));
  }

  /*Call remote method and block the GUI while waiting for an answer
     * @param method: The name of the method to be called
     * @param params: The arguments of the method
     * @param success_cb: The callback function that will be called when succeed
     * @param failure_cb: The callback function that will be called when fail
     */
  /*
     public call_remote(method:string, params:any = null, success_cb:RpcDataCallback = null, failure_cb:RpcDataCallback = null, block_ui:boolean = true) {
     return this._call_remote_method(method, params, success_cb, failure_cb, false, block_ui);
     }
     */
  public call_remote_v2(method: string, params?: any, block_ui: boolean = true) {
    const d = new Promise((resolve: (str: string) => void, reject: (str: string) => void) => {
      const success_cb = ret => {
        resolve(ret);
      };
      const failure_cb = fail => {
        reject(fail);
      };

      this._call_remote_method(method, params, success_cb, failure_cb, false, block_ui);
    });
    return d;
  }

  /*Call remote as background task. i.e, won't block the GUI
     * @params: See call_remote method
     */
  public call_remote_bg(
    method: string,
    params?: any,
    success_cb?: RpcDataCallback,
    failure_cb?: RpcDataCallback
  ) {
    this._call_remote_method(method, params, success_cb, failure_cb, false, false);
  }

  public subscribe_remote_event(event_name, params?: any, success_cb?: RpcDataCallback, failure_cb?: RpcDataCallback) {
    let event_data = { event_name, data: params };
    return this._call_remote_method('subscribe', event_data, success_cb, failure_cb, true, false);
  }

  public unsubscribe_remote_event(event_name) {
    return this._call_remote_method('unsubscribe', {event_name});
  }

  private get_next_request_id(): number {
    return this._next_request_id++;
  }

  private start_pinging = () => {
    setTimeout(() => {
      if (this.isConnected()) {
        this._call_remote_method(
          'ping',
          undefined,
          result => {
            this.start_pinging();
          },
          error => {
            console.error('Error pinging server', error);
            this.start_pinging();
          }
        );
      }
    }, 30000);
  };
}

import { AuthenticationService } from '@wephone-core/service/authentication';import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';

