import { Component } from '@angular/core';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'agent-name-renderer',
  template: '{{ agentName }}'
})
export class AgentNameRenderer implements ICellRendererAngularComp {
  params: any;
  agentName: string;

  constructor(
    private em: EntityManager
  ) { }

  agInit(params: any): void {
    if (this.params && params.value === this.params.value) {
      return;
    }

    this.params = params;
    const user: UserEntity = this.em.getRepository('UserRepository').getObjectById(this.params.value);
    this.agentName = user && user.name || '';
  }

  refresh(params: any): boolean {
    return false;
  }
}
