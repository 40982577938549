import { isDevMode, Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import * as screenfull from 'screenfull';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { Router } from '@angular/router';
import { SoundService } from '@wephone-audio';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { DialogService } from '@wephone-utils';
import { UserProfileDialog } from '@wephone-common/modals';
import { EnterpriseService } from '@wephone/services/enterprise.service';
import { ConfigManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { EnterpriseParam } from '@wephone-core/system';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FlexBaseComponent } from '@wephone-core-ui';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends FlexBaseComponent implements OnInit {
  @Output() readonly toggleSidenav = new EventEmitter<void>();
  @Output() readonly toggleNotificationSidenav = new EventEmitter<void>();
  @Input() readonly menuOpen: boolean;
  globalSearch: string;
  globalSearchText: string;
  globalSearchTextChanged: Subject<string> = new Subject<string>();
  loggedUser: UserEntity;
  myenterprise: EnterpriseEntity;
  sipphoneProvisioning: number;

  devMode = false;
  isAdmin: boolean;
  isSupervisor: boolean;
  isAccountant: boolean;

  uiSipTrunk = FlexIvrSettings.getInstance().uiSipTrunk();

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly soundService: SoundService,
    private readonly dialogService: DialogService,
    private readonly enterpriseService: EnterpriseService,
    private readonly configService: ConfigManager,
  ) {
    super();
    this.devMode = isDevMode();
  }

  ngOnInit(): void {
    this.loggedUser = this.authService.getUser();
    this.isAdmin = this.authService.isAdmin();
    this.isSupervisor = this.authService.isSupervisor();
    this.isAccountant = this.authService.isAccountant();
    this.myenterprise = EnterpriseRepository.getInstance<EnterpriseRepository>().getMyEnterprise();
    this.sipphoneProvisioning = this.configService.getEnterpriseParamValue(EnterpriseParam.sipphone_provisioning);

    this.addSubscription(
      this.globalSearchTextChanged
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((model: string) => {
        this.globalSearch = model;
      })
    );
  }

  onGlobalSearchChange(value: string): void {
    if (!value) {
      this.globalSearch = '';
    }
    this.globalSearchTextChanged.next(value);
  }

  clearGlobalSearch(): void {
    this.globalSearch = '';
    this.globalSearchText = '';
  }

  showConfigRequests(): void {
    // this.dialogService.openSideDialog(PendingConfigRequestsComponent, { data: {}, size: 's' }, dataResponse => {
    //   console.log('Resp closed', dataResponse);
    // });
    this.router.navigateByUrl('sip-phone-provisioning-token');
  }

  fullScreenToggle(): void {
    if (screenfull && screenfull.enabled) {
      screenfull.toggle();
    }
  }

  showUserProfile(): void {
    this.dialogService.openDialog2(UserProfileDialog, { size: 's', height: '580px' });
  }

  logout(): any {
    return this.authService.logoutAndGoToLoginPage();
  }

  isPlayerVisible(): boolean {
    return this.soundService.isTopPlayerVisible();
  }

  connectAs($event, version = 'v3'): Promise<void> {
    $event.stopPropagation();
    return this.enterpriseService.connectAsEnterprise(null, null, version);
  }
}
