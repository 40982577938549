import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { IFlexDialogConfig } from '@wephone-utils';

@Component({
  selector: 'app-debug-message-call-modal',
  templateUrl: './debug-message-call-modal.component.html',
  styleUrls: ['./debug-message-call-modal.component.scss']
})
export class DebugMessageCallModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '500px',
    minHeight: '90%',
  };

  debugMessage: any;
  constructor(
    dialogRef: MatDialogRef<DebugMessageCallModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.debugMessage = _.cloneDeep(this.data.debugMessage);
  }

}
