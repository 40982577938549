import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'render-nature',
  template: `<span>{{ ('cdr.nature.type_' + (params.value || '0'))| translate }}</span>`,
})
export class RenderNature implements ICellRendererAngularComp {
  params: any;

  constructor(
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
