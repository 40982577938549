import { GroupRepository } from '@wephone-core/model/repository/group';

export class TeamGroupRepository extends GroupRepository {
  ENTITY_CLASS = TeamGroupEntity;
  protected URL_PREFIX: string = 'team_group';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  getObjectList(): TeamGroupEntity[] {
    return EntityManager.getRepository<GroupRepository>('GroupRepository').getTeamGroupList() as TeamGroupEntity[];
  }

  create(object_data: any = null): IEntity {
    if (!object_data) {
      object_data = {};
    }
    object_data.group_type = 'team';
    return super.create(object_data);
  }

  createTeamAndSave(object_data, extra_data = null): Promise<any> {
    const obj = this.create(object_data);
    return this.save(obj, extra_data);
  }

  // Do it because cannot call this from parent.
  reloadRelatedData(): void {
    for (const obj of this.getObjectList()) {
      obj.fetchRelatedData();
    }
  }
}

import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { IEntity } from '@wephone-core/model/model.interface';
