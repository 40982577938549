<mat-card class="pa-0">
  <mat-toolbar fxLayoutAlign="end center">
    <h3 fxFlex="auto" class="ma-0 fontfamily-bold" *ngIf="title" class="title-group">{{ title }}</h3>
    <button [attr.data-ci]="'add-agents'" mat-stroked-button color="accent" (click)="openDialogAddUser()">{{ 'public.add'|translate }}</button>
  </mat-toolbar>
  <mat-card-content class="px-1">
    <div fxLayout="row" fxLayoutAlign="end center" class="mt-1" *ngIf="users.length >= 5 || filterString">
      <div>
        <form [formGroup]="form">
          <flex-search-input formControlName="filterString" [placeholder]="'public.search'|translate"></flex-search-input>
        </form>
      </div>
    </div>
    <div class="my-1">
      <mat-chip-list *ngIf="users.length; else noData">
        <mat-chip *ngFor="let user of users" [color]="isDirectUser(user) ? 'primary' : 'default'" selected>
          <mat-chip-avatar>
            <user-avatar [user]="user" width="100%" height="100%" class="avatar"></user-avatar>
          </mat-chip-avatar>
          {{user.name}}
          <mat-icon *ngIf="isDirectUser(user) && canChangeUser(user)" matChipRemove (click)="removeUser(user)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <ng-template #noData>
        <div class="mat-text-muted"></div>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>
