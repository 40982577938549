import { Component, OnInit, OnDestroy, Self, Optional, ElementRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'remote-application-select-form-input',
  templateUrl: './remote-application-select.component.html',
  styleUrls: ['./remote-application-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RemoteApplicationSelectFormInput }]
})
export class RemoteApplicationSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<IvrRemoteAppEntity> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  remoteAppList: Array<IvrRemoteAppEntity>;
  flexSelectOptions: IFlexSelectOptions;

  loadedData = false;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private configManager: ConfigManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: IvrRemoteAppEntity, filterString: string) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: IvrRemoteAppEntity, b: IvrRemoteAppEntity) => {
        return a.getId() === b.getId();
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._loadData();
  }

  private async _loadData(): Promise<void> {
    this.remoteAppList = await EntityManager.getRepository('IvrRemoteAppRepository').loadObjectList();
    this.loadedData = true;
  }

  get controlType(): string {
    return 'remote-application-select-form-input';
  }
}
