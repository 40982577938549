<div fxLayout="column" fxFlex="grow" class="flex-container">
  <h2 class="mb-0">{{'menu.my_recorded_call'|translate}}</h2>
  <div class="filter-container">
    <form [formGroup]="filterForm">
      <div *ngIf="filtersReadonly" align="center">
        <div fxLayout="column">
          <span *ngIf="filterForm.get('start_dt').value && filterForm.get('end_dt').value">
            {{filterForm.get('start_dt').value|timeFormatDateShort }} -->
            {{filterForm.get('end_dt').value|timeFormatDateShort}}</span>
          <span
            *ngIf="filterForm.get('start_time').value != filterForm.get('end_time').value">{{filterForm.get('start_time').value}}
            to {{filterForm.get('end_time').value}}</span>
          <span *ngIf="filterForm.get('qualifications').value.length">
            <strong>{{ 'stats.handled_call.columns.qualification'|translate }}</strong>:
            {{ rendererQualifi(filterForm.get('qualifications').value)|truncateArray }}
          </span>
          <span
            *ngIf="filterForm.get('local_numbers').value.length">{{rendererNumber(filterForm.get('local_numbers').value)|truncateArray}}</span>
          <a class="link" (click)="changeFilter()" data-ci="change-link">{{'public.change'
            | translate}}</a>
        </div>
      </div>
      <div *ngIf="!filtersReadonly">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="20px" fxLayoutGap.xs="0">
          <mat-form-field fxFlex="calc(20%-20px)" fxFlex.xs="60">
            <mat-label>{{ 'public.from'|translate }}</mat-label>
            <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
              placeholder="{{'public.from'|translate}}">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <div fxFlex="calc(10%-20px)" fxFlex.xs="40">
            <flex-time-picker formControlName="start_time" placeholder="{{'campaign.hour_start'|translate}}">
            </flex-time-picker>
          </div>
          <mat-form-field fxFlex="calc(20%-20px)" fxFlex.xs="60">
            <mat-label>{{'public.to'|translate }}</mat-label>
            <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
              placeholder="{{'public.to'|translate}}">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
          <div fxFlex="calc(10%-20px)" fxFlex.xs="40">
            <flex-time-picker formControlName="end_time" placeholder="{{'campaign.hour_end'|translate}}">
            </flex-time-picker>
          </div>
          <mat-form-field fxFlex="calc(40%-20px)" fxFlex.xs="100" *ngIf="!isInDevMode">
            <mat-label>{{'recording_call.table_filter.audio_state'|translate}}</mat-label>
            <mat-select placeholder="{{'recording_call.table_filter.audio_state'|translate}}"
              formControlName="audio_state">
              <mat-option value="">{{'public.all' | translate}}</mat-option>
              <mat-option value="0">{{'recording_call.audio_state.0' | translate}}</mat-option>
              <mat-option value="1">{{'recording_call.audio_state.1' | translate}}</mat-option>
              <mat-option value="2">{{'recording_call.audio_state.2' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <mat-form-field fxFlex="auto" fxFlex.xs="100">
            <mat-label>{{'stats.filter.select_qualification'|translate}}</mat-label>
            <flex-select-qualification-input [multiple]="true" [showCheckAll]="true" formControlName="qualifications"
              placeholder="{{ 'stats.filter.select_qualification' | translate }}"></flex-select-qualification-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'qualifications', true)"
              [disabled]="!filterForm.get('qualifications').value || !filterForm.get('qualifications').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field fxFlex="auto" fxFlex.xs="100">
            <mat-label>{{ 'stats.filter.select_did' | translate }}</mat-label>
            <flex-select-did-input [multiple]="true" [showCheckAll]="true" [didList]="filteredDidsMulti | async"
              [hasAnonymous]="hasAnonymous" placeholder="{{ 'stats.filter.select_did' | translate }}"
              formControlName="local_numbers">
            </flex-select-did-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'local_numbers', true)"
              [disabled]="!filterForm.get('local_numbers').value || !filterForm.get('local_numbers').value.length">
              <mat-icon>close</mat-icon>
            </button>
            <!-- <mat-label>{{'stats.filter.phone_number'|translate}}</mat-label>
            <mat-select placeholder="{{'stats.filter.select_did'|translate}}" multiple formControlName="local_numbers">
              <ngx-mat-select-search [formControl]="didMultiFilterCtrl" placeholderLabel="{{'public.search'|translate}}"
                noEntriesFoundLabel="{{'public.message.no_results'|translate }}"></ngx-mat-select-search>
              <mat-option *ngFor="let did of filteredDidsMulti | async" [value]="did.number">
                <span *ngIf="did.country_code" country-flag [countryCode2]="did.country_code"></span>
                {{ did.number_with_name }}
              </mat-option>
            </mat-select>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'local_numbers', true)"
              [disabled]="!filterForm.get('local_numbers').value || !filterForm.get('local_numbers').value.length">
              <mat-icon>close</mat-icon>
            </button> -->
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div fxFlex="auto" fxFlex.xs="100">
            <mat-checkbox formControlName="filter_duration">{{'recording_call.table_filter.number'|translate}}
            </mat-checkbox>
          </div>
          <mat-form-field fxFlex="auto" fxFlex.xs="100" *ngIf="filterForm.get('filter_duration').value">
            <mat-label>{{'public.from'|translate}}</mat-label>
            <input matInput placeholder="{{'public.from'|translate}}" type="number" min="0" formControlName="start_min">
            <mat-hint>
              <strong>{{'recording_call.table_filter.minutes'|translate}}</strong>
            </mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex="auto" fxFlex.xs="100" *ngIf="filterForm.get('filter_duration').value">
            <mat-label>{{'public.to'|translate}}</mat-label>
            <input matInput placeholder="{{'public.to'|translate}}" type="number" min="0" formControlName="end_min">
            <mat-hint>
              <strong>{{'recording_call.table_filter.minutes'|translate}}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="text-center">
          <button mat-raised-button color="accent" (click)="reloadClick()" data-ci="button-reload">{{ 'public.apply' |
            translate }}</button>
        </div>
      </div>
    </form>
  </div>
  <div fxFlex="grow">
    <flex-table #flexTable fxFill fxFlex [dataSource]="dataSource" [tableConfig]="tableConfig" [columnTemplates]="{
        local_number: tplLocalNumber,
        duration: tplDuration, 
        direction: tplDirection, 
        recorded_dt: tplRecordedDate, 
        action: tplAction
      }">
      <ng-template #tplLocalNumber let-item="row">
        <span>{{ item.local_number|displayPhoneNumber }}</span>
      </ng-template>
      <ng-template #tplDuration let-item="row">
        <span>{{ (item.duration || 0)|seconds_as_duration }}</span>
      </ng-template>
      <ng-template #tplDirection let-item="row">
        <span *ngIf="item.direction === 1; else callOut" class="mat-text-primary">
          {{ 'cdr.incoming'|translate }}</span>
        <ng-template #callOut>
          <span class="mat-text-warn">{{ 'cdr.outgoing'|translate }}</span>
        </ng-template>
      </ng-template>
      <ng-template #tplRecordedDate let-item="row">
        {{ item.recorded_dt|timeFormatDateTimeShort }}
      </ng-template>
      <ng-template #tplAction let-item="row">
        <fileentry-player-button *ngIf="item.file_entry_ready" [filePublicId]="item.file_public_id">
        </fileentry-player-button>
        <!--button mat-icon-button (click)="deleteRecoredCall(item)" matTooltip="{{ 'public.button.trash'|translate }}">
          <mat-icon>delete</mat-icon>
        </button-->
        <button mat-icon-button [disabled]="!item.file_entry_ready" (click)="downloadRecoredCall(item)"
          matTooltip="{{ 'public.button.download'|translate }}">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </ng-template>
    </flex-table>
  </div>
</div>
