import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ShortDialCodeEntity } from '@wephone-core/model/entity/short_dial_code';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { DialogActionButton, Colors, IFlexDialogConfig, NoWhitespaceValidator } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { ShortDialCodeRepository } from '@wephone-core/model/repository/short_dial_code';

@Component({
  selector: 'app-create-short-dial-code-modal',
  templateUrl: './create-short-dial-code-modal.component.html',
  styleUrls: ['./create-short-dial-code-modal.component.scss']
})
export class CreateShortDialCodeModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's',
  };

  // public form: FormGroup;

  dialogTitle = _tk('shortdialcode.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY,
    },
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  constructor(
    dialogRef: MatDialogRef<CreateShortDialCodeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly fb: FormBuilder,
    cdr?: ChangeDetectorRef,
  ) {
    super(dialogRef, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      dial_code: ['', [Validators.required, NoWhitespaceValidator, Validators.maxLength(15)]],
    });
  }

  async submit(): Promise<void> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.detectChanges();
        this.toastService.showError(_ti('public.message.data_invalid'));
        return;
      }

      const data = {
        dial_code: _.trim(this.form.get('dial_code').value),
      };
      const repo = ShortDialCodeRepository.getInstance<ShortDialCodeRepository>();
      const res = await repo.createAndSave(data);
      let newEntity: ShortDialCodeEntity;
      if (res && res.object_id) {
        await repo.wait_for_object_id(res.object_id);
        newEntity = repo.getObjectById(res.object_id);
      }
      this.successToast(_ti('public.message.create_success'));
      return this.dismiss(newEntity, false);
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }
}
