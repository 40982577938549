import { Component, OnInit, ViewChild } from '@angular/core';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { DialogActionButton, Colors, IFlexDialogConfig } from '@wephone-utils';
import * as _ from 'lodash';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { CreateUpdateIvrEvaluationComponent } from '@wephone/components/create-update-ivr-evaluation/create-update-ivr-evaluation.component';

@Component({
  selector: 'create-ivr-evaluation-modal',
  templateUrl: './create-ivr-evaluation-modal.component.html',
  styleUrls: ['./create-ivr-evaluation-modal.component.scss']
})
export class CreateIvrEvaluationModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  @ViewChild('createUpdateIvrEvaluationComponent') createUpdateIvrEvaluationComponent: CreateUpdateIvrEvaluationComponent;

  dialogTitle = _tk('ivr_evaluation.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  ivrEvaluation: IvrEvaluationEntity;

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  ngOnInit(): void {
    super.ngOnInit();

    this.ivrEvaluation = new IvrEvaluationEntity();
  }

  submitForm(): void {
    if (!this.createUpdateIvrEvaluationComponent) {
      return;
    }

    this.createUpdateIvrEvaluationComponent.submitForm();
  }

  onCreatedIvrEvaluation($event: IvrEvaluationEntity): void {
    if ($event) {
      this.dismiss($event, false);
    }
  }
}
