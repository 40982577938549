<mat-card class="mat-card-top pa-0 ma-0" fxFill>
  <div class="side-panel-opened" fxLayout="column" fxLayoutGap="0" fxFill>
    <mat-toolbar class="chat-toolbar" fxLayout="column">
      <h5 class="font-weight-bold ma-0 text-md full-width client-name" fxFlex>
        {{ smsDialog.client_name ? smsDialog.client_name : smsDialog.remote_number|displayPhoneNumber }}</h5>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width sms-info">
        <div fxFlex="calc(50%-60px)" fxLayout="row" fxLayoutAlign="start center">
          <div *ngIf="canMakeCall && smsDialog.remote_number">
            <button class="btn-call" mat-icon-button (click)="makeCall(smsDialog.remote_number)" color="primary"
            matTooltip="{{ 'public.button.call_now' | translate }}">
            <mat-icon>phone</mat-icon>
          </button>
          </div>

          <div *ngIf="smsDialog.remote_number">
            <span class="mat-text-muted client-number">
              {{ smsDialog.remote_number|displayPhoneNumber }}</span>
          </div>
        </div>
        <div>
          <mat-icon>swap_horiz</mat-icon>
        </div>
        <div fxFlex="calc(50%-60px)" align="right">
          <span class="mat-text-muted client-number">
            <ng-container *ngIf="did;else SenderName">
              {{ did.number|displayPhoneNumber }}
            </ng-container>
            <ng-template #SenderName>
              {{ smsDialog.sender_name }}
            </ng-template>
          </span>
        </div>
      </div>
    </mat-toolbar>
    <mat-card-content class="message-content pa-1">
      <div *ngFor="let conversationItem of conversations" class="chat-conversation-user"
        [ngClass]="{'them': conversationItem.direction == 0, 'me': conversationItem.direction == 1}">
        <div class="chat-conversation-message">
          <div class="inner-message" fxLayout="column">
            <p>
              {{ conversationItem.message }}
            </p>
            <div class="chat-date" fxLayout="row" fxLayoutAlign="start center">
              <i *ngIf="conversationItem.direction == smsDirection.OUTBOUND">
                {{ displayStatus(conversationItem.send_status) }}
              </i>&nbsp;

              <span>
                {{ conversationItem.message_time | timeFormatDateTimeMed }}
              </span>

            </div>

            <div class="chat-date" fxLayout="row" fxLayoutAlign="start center" *ngIf="isScheduled(conversationItem)">
              <mat-icon class="icon-scheduled">
                schedule
              </mat-icon>

              <span>
                {{ 'sms.content.scheduled_on'|translate }} {{ conversationItem.scheduled_send_dt |
                timeFormatDateTimeMed }}
              </span>
            </div>
          </div>
        </div>

        <div class="chat-action" *ngIf="conversationItem.direction == 1 && isScheduled(conversationItem)">
          <button data-ci="btn-action-sms" mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #appMenu="matMenu">
            <button data-ci="btn-unschedule-sms" mat-menu-item color="warn"
              (click)="unscheduleSendingSms(conversationItem)" title="{{ 'sms.cancel_schedule'|translate }}">
              <mat-icon>cancel_schedule_send</mat-icon> {{ 'sms.cancel_schedule'|translate }}
            </button>
          </mat-menu>
        </div>
      </div>
      <div #bottomContent id="sms_dialog_bottom"></div>
    </mat-card-content>
    <hr>
    <mat-card-actions class="px-2">
      <form [formGroup]="replyForm">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="mr-1 ml-1" fxFlex>
            <textarea matInput #messageInput rows="2" formControlName="message"
              [placeholder]="'sms.compose_message'|translate"
              [maxlength]="hasLimitedSms() && smsContentInfo && (smsContentInfo.max+1) || smsMaxLength"></textarea>
            <mat-hint *ngIf="smsContentInfo" align="end">
              <span *ngIf="hasLimitedSms();else CountSMS">{{ smsContentInfo.length || 0 }}/{{ smsContentInfo.max }}</span>
              <ng-template #CountSMS>{{ 'sms.content.frag'|translate }} {{ smsContentInfo.frag }}</ng-template>
            </mat-hint>
            <mat-error *ngIf="replyForm.get('message').invalid">
              <ng-container *ngIf="replyForm.get('message').hasError('required'); else tplSmsWhitespace">
                {{ 'sms.alert.message_blank'|translate }}
              </ng-container>
            </mat-error>
            <ng-template #tplSmsWhitespace>
              <ng-container *ngIf="replyForm.get('message').hasError('whitespace'); else tplSmsMax">
                {{'form.validator.whitespace'|translate}}
              </ng-container>
            </ng-template>
            <ng-template #tplSmsMax>
              <ng-container *ngIf="replyForm.get('message').hasError('smsMax'); else tplSmsMaxlength">
                {{ 'form.validator.max_length' | translate:{ max: replyForm.get('message').errors.smsMax.requiredLength } }}
              </ng-container>
            </ng-template>
            <ng-template #tplSmsMaxlength>
              <ng-container *ngIf="replyForm.get('message').hasError('maxlength'); else tplSmsMinlength">
                {{ 'form.validator.max_length' | translate:{ max: replyForm.get('message').errors.maxlength.requiredLength } }}
              </ng-container>
            </ng-template>
            <ng-template #tplSmsMinlength>
              <ng-container *ngIf="replyForm.get('message').hasError('minlength'); else tplInvalidData">
                {{ 'form.validator.min_length' | translate:{ min: replyForm.get('message').errors.minlength.requiredLength } }}
              </ng-container>
            </ng-template>
            <ng-template #tplInvalidData>
              {{ 'form.validator.data_invalid'|translate }}
            </ng-template>
          </mat-form-field>

          <div>
            <mat-button-toggle-group [disabled]="replyDisabled" color="primary">
              <mat-button-toggle [disabled]="replyDisabled" (click)="!replyDisabled && replySms(false)" fxLayout="row"
                fxLayoutAlign="start center">
                <button class="btn-flex" type="button" [disabled]="replyDisabled">
                  <mat-icon>send</mat-icon>
                  <span>{{ 'sms.send'|translate }}</span>
                </button>
              </mat-button-toggle>

              <mat-button-toggle [disabled]="replyDisabled">
                <button type="button" [matMenuTriggerFor]="shortcut" mat-icon-button [disabled]="replyDisabled">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #shortcut="matMenu" x-position="after">
                  <button type="button" mat-menu-item (click)="sendScheduled()">
                    <mat-icon>schedule_send</mat-icon>
                    <span>{{ 'sms.send_scheduled'|translate }}</span>
                  </button>
                </mat-menu>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

        </div>
      </form>
    </mat-card-actions>
  </div>
</mat-card>
