import { datafield, Entity } from '@wephone-core/model/model';

export class RoutingServiceEntity extends Entity {
  public static object_type_id = 'routing_service';

  public id: number;
  @datafield
  public name: string;
  @datafield
  public url: string;
}
