import { Entity, datafield } from '@wephone-core/model/model';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { IQualificationEntity } from './qualification.i';

export class QualificationEntity extends Entity implements IQualificationEntity {
  static object_type_id = 'call_qualification';
  static positionStep = 10;

  id: number;
  @datafield root_id: number;
  @datafield parent_id: number;
  @datafield position: number;
  @datafield value: string;
  @datafield next_question: string;
  @datafield level: number;
  @datafield group_id: number;
  @datafield is_goal: number;

  path_name: string; // To sort by name alphabetically

  get children(): QualificationEntity[] {
    return (this.getRepository() as QualificationRepository).getObjectListByParentId(this.id);
  }

  get grandChildren(): QualificationEntity[] {
    return (this.getRepository() as QualificationRepository).getObjectListByRootId(this.id);
  }

  isValid(): boolean {
    return this.children.length > 0;
  }

  fetchRelatedData(): void {
    this._updatePathName(); // Must do it in here instead of setObjectData because the data must be loaded
  }

  get group(): UserGroupEntity {
    if (this.root_group_id) {
      return GroupRepository.getInstance().getObjectById(this.root_group_id);
    }

    return undefined;
  }
  set group(group) {
    const groupId = group ? group.id : undefined;

    if (!this.parent_id) {
      this.group_id = groupId;

      return;
    }

    if (this.root) {
      this.root.group_id = groupId;
    }
  }

  get root_group_id(): number {
    if (!this.parent_id) {
      return this.group_id;
    }

    if (!this.root) {
      return undefined;
    }

    return this.root.group_id;
  }

  get parent(): QualificationEntity {
    if (this.parent_id) {
      return (this.getRepository() as QualificationRepository).getObjectById(this.parent_id);
    }

    return undefined;
  }

  get root(): QualificationEntity {
    if (this.root_id) {
      return (this.getRepository() as QualificationRepository).getObjectById(this.root_id);
    }

    return undefined;
  }

  private getUpperObjectList(): Array<QualificationEntity> {
    let list: Array<QualificationEntity> = [this];
    if (this.parent_id && this.parent) {
      list = list.concat(this.parent.getUpperObjectList());
    }

    return list;
  }

  private _updatePathName(): void {
    const upper_objects = this.getUpperObjectList();
    const path_names = [];
    for (const q of upper_objects) {
      path_names.push((q.value && q.value.toLowerCase()) || 0);
    }
    this.path_name = `${path_names.reverse().join('_')}_`;
  }

  get parent_ids(): Array<number> {
    const upper_objects = this.getUpperObjectList();
    const parent_ids = [];
    for (const q of upper_objects) {
      parent_ids.unshift(q.id);
    }

    return parent_ids;
  }
}
