import { LiveQueueData } from '@wephone-core/service/live-stats.service';

export enum ChartPeriod {
  day = 'day',
  today = 'today',
  yesterday = 'yesterday',
  this_week = 'this_week',
  this_month = 'this_month',
  per_week = 'per_week',
  per_month = 'per_month',
}

export class QosDataType {
  answered: number;
  total_inbound: number;
  total_outbound: number;
  handled: number;
  total: number;
  waiting_call_count: number;
  total_handling_time: number;
  total_after_call_waiting_time: number;
  total_before_answer_waiting_time: number;
  qos: number;
  dmc: number;
  acw: number;
  dmt: number;
  dmr: number;
}

export interface ChartGroupData {
  id: number;
  name: string;
  children: any[];
  all_children_ids: number[];
  queues: LiveQueueData[];
  qos_data: QosDataType;
}
