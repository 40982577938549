import * as _ from 'lodash';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  HostBinding,
  Self,
  Optional,
  ElementRef
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FlexSelect, FlexMatInputBase, IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';

@Component({
  selector: 'conference-select-form-input',
  templateUrl: './conference-select.component.html',
  styleUrls: ['./conference-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ConferenceSelectFormInput }]
})
export class ConferenceSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<ConferenceEntity> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  conferenceList: Array<ConferenceEntity>;
  flexSelectOptions: IFlexSelectOptions;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: ConferenceEntity, filterString) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: ConferenceEntity, b: ConferenceEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setConferenceList();
  }

  /**
   * Hard reload list after create new item
   */
  setConferenceList(): void {
    this.conferenceList = this.em.getRepository('ConferenceRepository').getObjectList();
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  get controlType(): string {
    return 'conference-select-form-input';
  }
}
