import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DialogService } from '@wephone-utils';
import { PaymentWizardModelComponent } from '@wephone/modals/payment/payment-wizard-model/payment-wizard-model.component';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentService } from './payment.service';
import { PaymentAttemptEntity } from '@wephone-core/model/entity/payment_attempt';

@Injectable()
export class PaymentDialogService {

  constructor(
    private dialogService: DialogService,
    private paymentService: PaymentService) {
  }

  async show_payment_dialog_go_premium(params: any): Promise<any> {
    const payment_attempt: PaymentAttemptEntity = await this.paymentService.prepare_payment_attempt_premium(params);
    if (!payment_attempt) {
      console.error('Request payment error');
      return;
    }
    return this.show_payment_dialog(payment_attempt);
  }

  async show_payment_dialog_did_order(params: any): Promise<any> {
    const payment_attempt: PaymentAttemptEntity = await this.paymentService.prepare_payment_attempt_buy_did(params);
    if (!payment_attempt) {
      console.error('Request payment error');
      return;
    }
    return this.show_payment_dialog(payment_attempt);
  }

  async show_payment_dialog_update_subscription(params: any): Promise<any> {
    const payment_attempt: PaymentAttemptEntity = await this.paymentService.prepare_payment_attempt_update_subscription(params);
    if (!payment_attempt) {
      console.error('Request payment error');
      return;
    }
    return this.show_payment_dialog(payment_attempt);
  }

  // Not used anymore
  // async show_payment_dialog_invoice(invoice_id: number): Promise<any> {
  //   const payment_attempt: PaymentAttemptEntity = await this.paymentService.prepare_payment_invoice_by_id(invoice_id);
  //   if (!payment_attempt) {
  //     console.error('Request payment error');
  //     return;
  //   }
  //   return this.show_payment_dialog(payment_attempt);
  // }

  async show_payment_dialog_credit_recharge(amount: number): Promise<any> {
    const payment_attempt: PaymentAttemptEntity = await this.paymentService.prepare_payment_credit_recharge({amount});
    if (!payment_attempt) {
      console.error('Request payment error');
      return;
    }
    return this.show_payment_dialog(payment_attempt);
  }

  show_payment_dialog(payment_attempt: PaymentAttemptEntity): MatDialogRef<any, any> {
    console.log('Payment dialog', payment_attempt);
    return this.dialogService.openDialog2(
      PaymentWizardModelComponent,
      {
        data: {
          payment_attempt
        }
      }
    );
  }

}
