<div class="page-root">
  <div class="page-header">
    <h2>{{ 'enterprise_params.title'| translate }}</h2>
  </div>

  <div class="table-container">

    <form [formGroup]="form">
      <mat-card class="item-detail shadow-none">
        <div fxFill fxLayout="column">
          <mat-toolbar class="mat-primary" fxLayout="row" fxLayoutAlign="start center">
            <div class="button-container" *ngIf="formHasChanged()">
              <button data-ci="btn-save" type="button" mat-button class="border-white-button" (click)="update()">
                {{ 'public.save'|translate }}
              </button>
              <button data-ci="btn-cancel" type="button" mat-button class="border-white-button ml-1" (click)="resetForm()">
                {{ 'public.cancel'|translate }}
              </button>
            </div>
          </mat-toolbar>

          <div fxLayout="column" fxFlex="grow" class="side-pannel-content pa-1">
            <div class="full-width" fxLayout="row">
              <label fxFlex="300px"
                fxFlex.xs="250px">{{'enterprise_params.content.cc_allow_call_direction_selection'|translate}}</label>
              <div fxFlex>
                <mat-slide-toggle formControlName="cc_allow_call_direction_selection" color="primary">
                  <span [hidden]="form.controls['cc_allow_call_direction_selection'].value"
                    class="mat-text-muted">
                    {{'public.button.no'|translate}}
                  </span>
                  <span [hidden]="!form.controls['cc_allow_call_direction_selection'].value"
                    class="mat-text-primary">
                    {{'public.button.yes'|translate}}</span>
                </mat-slide-toggle>
              </div>
            </div>

            <div class="full-width" fxLayout="row">
              <label fxFlex="300px"
                fxFlex.xs="250px">{{'enterprise_params.content.cc_allow_select_queue_outbound'|translate}}</label>
              <div fxFlex>
                <mat-slide-toggle formControlName="dialer_queue_selector" color="primary">
                  <span [hidden]="form.controls['dialer_queue_selector'].value" class="mat-text-muted">
                    {{'public.button.no'|translate}}
                  </span>
                  <span [hidden]="!form.controls['dialer_queue_selector'].value"
                    class="mat-text-primary">
                    {{'public.button.yes'|translate}}</span>
                </mat-slide-toggle>
              </div>
            </div>

            <div class="full-width" fxLayout="row">
              <label fxFlex="300px"
                fxFlex.xs="250px">{{'enterprise_params.content.disable_phone_in_iframe'|translate}}</label>
              <div fxFlex>
                <mat-slide-toggle formControlName="disable_phone_in_iframe" color="primary">
                  <span [hidden]="form.controls['disable_phone_in_iframe'].value" class="mat-text-muted">
                    {{'public.button.no'|translate}}
                  </span>
                  <span [hidden]="!form.controls['disable_phone_in_iframe'].value"
                    class="mat-text-primary">
                    {{'public.button.yes'|translate}}</span>
                </mat-slide-toggle>
              </div>
            </div>

            <div class="full-width" fxLayout="row">
              <label fxFlex="300px" fxFlex.xs="250px">
                {{'enterprise_params.content.agent_auto_disconnect'|translate}}
              </label>
              <div fxFlex>
                <mat-slide-toggle formControlName="agent_auto_disconnect" color="primary">
                  <span [hidden]="form.controls['agent_auto_disconnect'].value" class="mat-text-muted">
                    {{'public.button.no'| translate }}
                  </span>
                  <span [hidden]="!form.controls['agent_auto_disconnect'].value"
                    class="mat-text-primary">
                    {{'public.button.yes'| translate }}
                  </span>
                </mat-slide-toggle>
              </div>
            </div>
            <div class="full-width" fxLayout="row">
              <label fxFlex="300px" fxFlex.xs="250px">
                {{'enterprise_params.content.my_own_recorded_calls'|translate}}
              </label>
              <div fxFlex>
                <mat-slide-toggle formControlName="my_own_recorded_calls" color="primary">
                  <span [hidden]="form.controls['my_own_recorded_calls'].value" class="mat-text-muted">
                    {{'public.button.no'| translate }}
                  </span>
                  <span [hidden]="!form.controls['my_own_recorded_calls'].value"
                    class="mat-text-primary">
                    {{'public.button.yes'| translate }}
                  </span>
                </mat-slide-toggle>
              </div>
            </div>

            <div class="full-width" fxLayout="row">
              <label fxFlex="300px" fxFlex.xs="250px">
                {{'enterprise_params.content.call_quality_enable'|translate}}
              </label>
              <div fxFlex>
                <mat-slide-toggle formControlName="call_quality_enable" color="primary">
                  <span [hidden]="form.controls['call_quality_enable'].value" class="mat-text-muted">
                    {{'public.button.no'| translate }}
                  </span>
                  <span [hidden]="!form.controls['call_quality_enable'].value"
                    class="mat-text-primary">
                    {{'public.button.yes'| translate }}
                  </span>
                </mat-slide-toggle>
              </div>
            </div>
            
            <div class="full-width" fxLayout="row">
              <label fxFlex="300px" fxFlex.xs="250px">
                {{'enterprise_params.content.call_analysis_enable'|translate}}
              </label>
              <div fxFlex>
                <mat-slide-toggle formControlName="call_analysis_enable" color="primary">
                  <span [hidden]="form.controls['call_analysis_enable'].value" class="mat-text-muted">
                    {{'public.button.no'| translate }}
                  </span>
                  <span [hidden]="!form.controls['call_analysis_enable'].value"
                    class="mat-text-primary">
                    {{'public.button.yes'| translate }}
                  </span>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </form>
  </div>
</div>
