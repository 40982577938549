import { datafield, Entity } from '@wephone-core/model/model';
import { DateTime } from 'luxon';
import { parseDateTime } from '@wephone-utils';
import { SyncObjectType, SyncTaskStatus, SyncTaskType } from './sync_task.i';

export class SyncTaskEntity extends Entity {
  static object_type_id = 'sync_task';

  @datafield id: number;
  @datafield public_id: string;
  @datafield object_id: number;
  @datafield object_type: SyncObjectType;
  @datafield task_type: SyncTaskType;
  @datafield status: SyncTaskStatus;
  @datafield creation_dt: Date;
  @datafield last_run_dt: Date;
  @datafield expired_dt: Date;
  @datafield last_error_msg: string;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    // this.creation_dt = object_data.creation_dt ? parseDateTime(object_data.creation_dt) : undefined;
    // this.last_run_dt = object_data.last_run_dt ? parseDateTime(object_data.last_run_dt) : undefined;
    // this.expired_dt = object_data.expired_dt ? parseDateTime(object_data.expired_dt) : undefined;
  }

  get is_running(): boolean {
    return this.status === SyncTaskStatus.Running;
  }

  get is_not_processed(): boolean {
    return this.status === SyncTaskStatus.NotRunYet;
  }

  get is_success(): boolean {
    return this.status === SyncTaskStatus.Success;
  }

  get is_failed(): boolean {
    return this.status === SyncTaskStatus.Failed;
  }
}
