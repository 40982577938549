<div class="alert-upgrade-container" *ngIf="showAlertUpgrade()" (click)="openUpgrade()">
  <div class="alert alert-upgrade">
    <div class="alert-content">
      {{'subscription-pack.upgrade.alert'|translate: {left_days: leftDays} }}
    </div>
    <!-- <div class="alert-close">
      <button mat-icon-button>
        <mat-icon color="warn">close</mat-icon>
      </button>
    </div> -->
  </div>
</div>
