<div  fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
    <div class="spinner"></div>
    <div *ngIf="errorMessage" class="mt-2 text-danger">{{ errorMessage }}</div>
    <div *ngIf="!errorMessage" class="mt-2">{{ 'livecall.loading_page' | translate }}</div>
</div>
<!-- <div class="full-screen" fxLayout="column" fxFlexFill>
    <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
        <img src="assets/images/loading.gif" alt="Graam" title="Graam" class="logo">
        <p *ngIf="errorMessage" ion-text color="danger">{{ errorMessage }}</p>
        <div class="spinner"></div>
        <div class="mb-20">{{ 'livecall.loading_page' | translate }}</div>
        <div>
        </div>
    </div>
</div> -->
