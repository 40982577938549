<div fxLayout="column" fxFill>
  <ng-template #tplListFilter>
    <form [formGroup]="form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start"
      fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterString"
          [hintText]="'routing_service.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud *ngIf="is_ready" pageTitle="{{ 'menu.manage_routing_service'| translate }}"
    [tableConfig]="tableConfig" [dataSource]="dataSource" [sidePannelOptions]="sidePannelOptions"
    [filterTemplate]="tplListFilter" fxLayout="row" fxFlex="grow">
  </flex-crud-page>
</div>
