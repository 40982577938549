import { Injectable } from '@angular/core';
import { SingletonBase } from '@wephone-utils';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import {
  IRoutingAppDestinationData, RoutingAppName, RoutingParamsConference,
  RoutingParamsIvrMenu, RoutingParamsPhoneNumber, RoutingParamsPlayFileAndHangup,
  RoutingParamsQueue, RoutingParamsRemoteApp, RoutingParamsRunVxml, RoutingParamsSendSms, RoutingParamsSipphone,
  RoutingParamsToDid, RoutingParamsUser, RoutingParamsWebservice
} from '@wephone-core/routing-app/routing-app.interface';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { DidEntity } from '@wephone-core/model/entity/did';
import { EntityManager } from '@wephone-core/service/entity_manager';
import * as _ from 'lodash';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { SmsTemplateEntity } from '@wephone-core/model/entity/sms_template';

@Injectable()
export class RoutingAppService extends SingletonBase {
  private em: EntityManager;

  static getInstance(): RoutingAppService {
    return super.getInstance();
  }

  constructor(
  ) {
    super();
    console.log('RoutingAppService run constructor!');
    this.em = EntityManager.getInstance();
  }

  getRoutedCallQueue(appData: IRoutingAppDestinationData): CallQueueEntity {
    if (appData.application !== RoutingAppName.callqueue) {
      return;
    }
    const actionParams: RoutingParamsQueue = appData.params as RoutingParamsQueue;
    if (!actionParams.queue) {
      return;
    }
    // return CallQueueRepository.getInstance().getObjectById(actionParams.queue);
    return this.em.getRepository('CallQueueRepository').getObjectById(actionParams.queue);
  }

  getRoutedIvrMenu(appData: IRoutingAppDestinationData): IvrCustomMenuEntity {
    if (appData.application !== RoutingAppName.ivr_custom_menu) {
      return;
    }
    const actionParams: RoutingParamsIvrMenu = appData.params as RoutingParamsIvrMenu;
    if (!actionParams.id) {
      return;
    }
    // return IvrCustomMenuRepository.getInstance().getObjectById(actionParams.id);
    return this.em.getRepository('IvrCustomMenuRepository').getObjectById(actionParams.id);
  }

  getRoutedRunVxml(appData: IRoutingAppDestinationData): IvrScriptEntity {
    if (appData.application !== RoutingAppName.runvxmlscript) {
      return;
    }
    const actionParams: RoutingParamsRunVxml = appData.params as RoutingParamsRunVxml;
    if (!actionParams.ivrscript_id) {
      return;
    }
    // return IvrScriptRepository.getInstance().getObjectById(actionParams.ivrscript_id);
    return this.em.getRepository('IvrScriptRepository').getObjectById(actionParams.ivrscript_id);
  }

  getRoutedRemoteApp(appData: IRoutingAppDestinationData): IvrRemoteAppEntity {
    if (appData.application !== RoutingAppName.remote_application) {
      return;
    }
    const actionParams: RoutingParamsRemoteApp = appData.params as RoutingParamsRemoteApp;
    if (!actionParams.id) {
      return;
    }
    // return IvrRemoteAppRepository.getInstance().getObjectById(actionParams.id);
    return this.em.getRepository('IvrRemoteAppRepository').getObjectById(actionParams.id);
  }

  getRoutedConference(appData: IRoutingAppDestinationData): ConferenceEntity {
    if (appData.application !== RoutingAppName.conference) {
      return;
    }
    const actionParams: RoutingParamsConference = appData.params as RoutingParamsConference;
    if (!actionParams.conference) {
      return;
    }
    // return ConferenceRepository.getInstance().getObjectById(actionParams.conference);
    return this.em.getRepository('ConferenceRepository').getObjectById(actionParams.conference);
  }

  getRoutedPhoneNumber(appData: IRoutingAppDestinationData): string {
    if (appData.application !== RoutingAppName.call_phone_number) {
      return;
    }

    const actionParams: RoutingParamsPhoneNumber = appData.params as RoutingParamsPhoneNumber;
    return actionParams.number;
  }

  getRoutedSipPhone(appData: IRoutingAppDestinationData): string {
    if (appData.application !== RoutingAppName.call_phone) {
      return;
    }

    const actionParams: RoutingParamsSipphone = appData.params as RoutingParamsSipphone;
    if (!actionParams.sipphone_id) {
      return;
    }
    return this.em.getRepository('SipPhoneRepository').getObjectById(actionParams.sipphone_id);
  }

  getRoutedFileEntryHangup(appData: IRoutingAppDestinationData): FileEntryEntity {
    if (appData.application !== RoutingAppName.play_then_hangup) {
      return;
    }
    const actionParams: RoutingParamsPlayFileAndHangup = appData.params as RoutingParamsPlayFileAndHangup;
    if (!actionParams.sound_id) {
      return;
    }
    // return FileEntryRepository.getInstance().getObjectById(actionParams.sound_id);
    return this.em.getRepository('FileEntryRepository').getObjectById(actionParams.sound_id);
  }

  getRoutedDid(appData: IRoutingAppDestinationData): DidEntity {
    if (appData.application !== RoutingAppName.to_did) {
      return;
    }
    const actionParams: RoutingParamsToDid = appData.params as RoutingParamsToDid;
    if (!actionParams.did_id) {
      return;
    }
    // return DidRepository.getInstance().getObjectById(actionParams.did_id);
    return this.em.getRepository('DidRepository').getObjectById(actionParams.did_id);
  }

  getRoutedWebservice(appData: IRoutingAppDestinationData): string {
    if (appData.application !== RoutingAppName.webservice) {
      return;
    }
    const actionParams: RoutingParamsWebservice = appData.params as RoutingParamsWebservice;
    return actionParams.url;
  }

  getRoutedUser(appData: IRoutingAppDestinationData): IUserEntity {
    if (appData.application !== RoutingAppName.call_user) {
      return;
    }
    const actionParams: RoutingParamsUser = appData.params as RoutingParamsUser;
    if (!actionParams.id) {
      return;
    }
    // return UserRepository.getInstance().getObjectById(actionParams.id);
    return this.em.getRepository('UserRepository').getObjectById(actionParams.id);
  }

  getRoutedSmsTemplate(appData: IRoutingAppDestinationData): SmsTemplateEntity {
    if (appData.application !== RoutingAppName.send_sms) {
      return undefined;
    }
    const actionParams = appData.params as RoutingParamsSendSms;
    if (!actionParams.sms_id) {
      return undefined;
    }
    return this.em.getRepository('SmsTemplateRepository').getObjectById(actionParams.sms_id);
  }

  getRoutedIvrMenusToEntity(itemId: number, app: RoutingAppName): IvrCustomMenuEntity[] {
    const ret: IvrCustomMenuEntity[] = [];
    const ivrMenus: IvrCustomMenuEntity[] = EntityManager.getRepository('IvrCustomMenuRepository').getObjectList();
    if (_.isEmpty(ivrMenus)) {
      return [];
    }

    for (const ivrMenu of ivrMenus) {
      switch (app) {
        case RoutingAppName.callqueue:
          const routedQueues: CallQueueEntity[] = ivrMenu.routed_queues;
          if (_.isEmpty(routedQueues) || !_.includes(routedQueues.map(x => x.id), itemId)) {
            continue;
          }
          break;
        case RoutingAppName.conference:
          const routedConferences: ConferenceEntity[] = ivrMenu.routed_conferences;
          if (_.isEmpty(routedConferences) || !_.includes(routedConferences.map(x => x.id), itemId)) {
            continue;
          }
          break;
        case RoutingAppName.to_did:
          const routedDids: DidEntity[] = ivrMenu.routed_dids;
          if (_.isEmpty(routedDids) || !_.includes(routedDids.map(x => x.id), itemId)) {
            continue;
          }
          break;
        case RoutingAppName.play_then_hangup:
          const routedFileEntries: FileEntryEntity[] = ivrMenu.routed_fileentries;
          if (_.isEmpty(routedFileEntries) || !_.includes(routedFileEntries.map(x => x.id), itemId)) {
            continue;
          }
          break;
        case RoutingAppName.remote_application:
          const routedRmoteApps: IvrRemoteAppEntity[] = ivrMenu.routed_remote_apps;
          if (_.isEmpty(routedRmoteApps) || !_.includes(routedRmoteApps.map(x => x.id), itemId)) {
            continue;
          }
          break;
        case RoutingAppName.runvxmlscript:
          const routedIvrScripts: IvrScriptEntity[] = ivrMenu.routed_ivrscripts;
          if (_.isEmpty(routedIvrScripts) || !_.includes(routedIvrScripts.map(x => x.id), itemId)) {
            continue;
          }
          break;
        case RoutingAppName.call_phone:
          const routedSipPhones: SipPhoneEntity[] = ivrMenu.routed_sipphones;
          if (_.isEmpty(routedSipPhones) || !_.includes(routedSipPhones.map(x => x.id), itemId)) {
            continue;
          }
          break;
        case RoutingAppName.call_user:
          const routedUsers: IUserEntity[] = ivrMenu.routed_users;
          if (_.isEmpty(routedUsers) || !_.includes(routedUsers.map(x => x.id), itemId)) {
            continue;
          }
          break;
        default:
          throw new Error(`Not support routing app ${app}`);
          continue;
          break;
      }

      // Get parent if item is child
      let addedIvrMenu: IvrCustomMenuEntity = ivrMenu;
      if (ivrMenu.parent_id) {
        addedIvrMenu = ivrMenu.parent;
      }

      if (addedIvrMenu && !_.includes(ret, addedIvrMenu)) {
        ret.push(addedIvrMenu);
      }
    }
    return ret;
  }

}
