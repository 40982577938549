<div class="edit-form-container" fxFill *ngIf="did; else tplNoDID">
  <ng-container *ngIf="!did.hasRoutingData(false); else tplEditRoutedObject">
    <did-setup-wizard #didSetupWizard [did]="did" (onFinish)="updateConfigDid($event)" fxFill></did-setup-wizard>
  </ng-container>

  <ng-template #tplEditRoutedObject>
    <div fxFill fxLayout="column">
      <div class="wrapper">

        <div class="col-left" [fxHide.xs]="true">
          <div class="inner">
            <ul class="stepper stepper-vertical">
              <li *ngFor="let step of stepKeys; let i=index;">
                <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
                  [ngClass]="{'active': mainArea.activeAnchor === step}">
                  <span class="circle">{{ i+1 }}</span>
                  <span class="label">{{ steps[step] | translate }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
          <div class="inner">
            <form [formGroup]="form" class="form-change-highlight" *ngIf="form">
              <div [ngClass]="showCalendar && hasCalendar ? 'activity-stream' : ''">

                <ng-container *ngIf="did.hasLinkedUser(); else tplEditForm">

                  <!-- <div *ngIf="did.master_did" class="my-2 text-center">
                    <strong [innerHtml]="'did.content.tracking_number_of'|translate: {did_number: ''}"></strong>&nbsp;
                    <a class="flex-link" (click)="gotoEditDidPage(did.master_did)"
                      data-ci="link-master">{{ did.master_did.display_number }}</a>
                  </div> -->
                  <strong [innerHtml]="'did.content.dedicated_number_for'|translate: {user_name: ''}"></strong>&nbsp;
                  <a class="flex-link" (click)="gotoEditUserPage(did.routed_user)" data-ci="link-user">{{
                    did.routed_user.name }}
                  </a>

                  <!-- <ng-container *ngIf="isDestType('callqueue') || did.hasLinkedUser()">
                    <ng-container *ngIf="isDestType('callqueue') || canUseCallCenter(); else tplNotDedicatedForAgent">
                      <call-queue-edit #queueEdit [editingItem]="getDestinationQueue()" [editingDID]="did"
                        (formValueChanges)="onFormValueChange()"></call-queue-edit>
                    </ng-container>
                    <ng-template #tplNotDedicatedForAgent>
                      <div class="my-2" align="center"
                        innerHtml="{{ 'did.content.dedicated_number_for'|translate: {user_name: did.routed_user.name} }}">
                        Dedicated number for {{ did.routed_user.name }}
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container *ngIf="isDestType('ivr_custom_menu')">
                    <ivr-menu-edit #ivrmenuEdit [editingItem]="did.getDestinationObject()" (formValueChanges)="onFormValueChange()">
                    </ivr-menu-edit>
                  </ng-container>
                  <ng-container *ngIf="isDestType('conference')">
                    <app-edit-conference #conferenceEdit [editingItem]="did.getDestinationObject()"
                      (formValueChanges)="onFormValueChange()"></app-edit-conference>
                  </ng-container>
                  <ng-container *ngIf="isDestType('call_phone_number')">
                    <div class="routed-phone-number pa-1">
                      <h2 class="mt-3 font-weight-bold">{{ did.routed_phone_number }}</h2>
                    </div>
                  </ng-container> -->
                </ng-container>

                <ng-template #tplEditForm>
                  <!-- <mat-card class="shadow-none" id="_0general" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar><i class="timeline-icon material-icons">call</i></div>
                      <mat-card-title>{{'call_queue_edit.section.incoming_call'|translate}}</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                      <div class="px-1">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div class="mr-1 font-weight-bold">{{ 'did.content.time_dependent_routing'|translate }}</div>
                          <div>
                            <button mat-icon-button (click)="addDependentRouting()" data-ci="add-dependent-routing" class="green"
                              title="{{ 'public.add_new'|translate }}">
                              <mat-icon>add</mat-icon>
                            </button>
                          </div>
                        </div>
                    
                        <time-dependent-routing #timeDependentRouting class="mb-1" [calendarList]="dependentCalendars"
                          (changedList)="setDependentCalendars($event)">
                        </time-dependent-routing>
                      </div>
                      
                      <ng-container *ngIf="did.master_did">
                        <div class="my-2" align="center">
                          {{ 'did.content.tracking_number_of'|translate: {did_number: ''} }} <a class="flex-link"
                            (click)="gotoEditDidPage(did.master_did)" data-ci="link-master">{{ did.master_did.display_number }}</a>
                        </div>
                      </ng-container>
                    </mat-card-content>
                  </mat-card> -->

                  <number-opening-calendar *ngIf="showCalendar" #numberOpeningCalendar [did]="did" class="shadow-none"
                    id="_1calendar" anchorPart (formValueChanges)="numberOpeningCalendarChange()">
                  </number-opening-calendar>

                  <mat-card class="shadow-none" id="_2default_routing" anchorPart>
                    <mat-card-header *ngIf="showCalendar && hasCalendar">
                      <div mat-card-avatar><i class="timeline-icon material-icons">check</i></div>
                      <mat-card-title>{{'call_queue_edit.section.if_opened'|translate}}</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                      <div fxLayout="column">
                        <div>
                          {{ 'did.content.default_destination'|translate }}&nbsp;
                          <a class="flex-link" data-ci="link-change-destination" (click)="setOtherDestination()">[{{
                            'public.edit'|translate }}]</a>
                        </div>
                        <number-routing-dest class="defaut-routing flex-info" [did]="didPreview"
                          [updateLayout]="updateLayout">
                        </number-routing-dest>

                        <div fxLayout="row" class="full-width hidden-label" *ngIf="did.master_did_id">
                          <div fxFlex="10px"></div>
                          <div fxFlex>
                            <mat-slide-toggle formControlName="is_hidden">
                              {{ 'did.content.is_hidden'|translate }}
                            </mat-slide-toggle>
                            <p class="flex-hint">
                              <mat-hint>{{ 'did.content.hint_is_hidden'|translate }}</mat-hint>
                            </p>
                          </div>
                        </div>

                        <mat-error
                          *ngIf="!form.get('name').invalid && !form.get('inbound_sms_emails').invalid && form.invalid">
                          {{ 'public.message.data_invalid'|translate }}
                        </mat-error>
                      </div>
                    </mat-card-content>
                  </mat-card>

                </ng-template>
              </div>

              <div class="ma-1">
                <mat-divider [inset]="true"></mat-divider>
              </div>

              <mat-card class="shadow-none ma-0" id="_3number_information" anchorPart>
                <mat-card-content>
                  <h2>{{ 'did.content.did_info'|translate }}</h2>
                  <div>
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'did.content.name'|translate }}</mat-label>
                      <input matInput formControlName="name" data-ci="input-name">

                      <mat-error 
                        [flexFormControlError]="form.get('name')"
                        [customMessages]="{
                          'name_exist': 'public.message.name_exist'|translate
                        }"></mat-error>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'did.content.welcome_message'|translate }}</mat-label>
                      <textarea matInput formControlName="welcome_message"
                        data-ci="textarea-welcome_message"></textarea>

                      <mat-error [flexFormControlError]="form.get('welcome_message')"></mat-error>
                    </mat-form-field>
                  </div>
                  <div *ngIf="hasCrmAuthenticated">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'did.content.select_crm'|translate }}</mat-label>
                      <flex-select-enterprise-crm-input formControlName="crm" [isConfigured]="true"
                        [isAuthenticated]="true" placeholder="{{ 'did.content.select_crm' | translate }}">
                      </flex-select-enterprise-crm-input>
                      <button mat-icon-button matSuffix type="button" (click)="removeControlValue('crm')"
                        [disabled]="!form.get('crm').value || !form.get('crm').value.id">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <div *ngIf="hasRoutingService">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'did.content.select_routing_service'|translate }}</mat-label>
                      <flex-select-routing-service-input formControlName="routing_service"
                        placeholder="{{ 'did.content.select_routing_service' | translate }}">
                      </flex-select-routing-service-input>
                      <button mat-icon-button matSuffix type="button" (click)="removeControlValue('routing_service')"
                        [disabled]="!form.get('routing_service').value || !form.get('routing_service').value.id">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <div *ngIf="hasFeature('SERVICE_GROUP')">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'call_queue.content.group'|translate }}</mat-label>
                      <usergroup-select-form-input formControlName="group"
                        [placeholder]="'call_queue.content.group'|translate">
                      </usergroup-select-form-input>
                      <button mat-icon-button matSuffix type="button" (click)="removeControlValue('group')"
                        [disabled]="!hasControlValue('group')">
                        <mat-icon>close</mat-icon>
                      </button>

                      <mat-error [flexFormControlError]="form.get('group')"></mat-error>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card *ngIf="showSmsEmails()" class="shadow-none ma-0" id="_4inbound_sms" anchorPart>
                <mat-card-content>
                  <h2>{{ 'did.content.inbound_sms'|translate }}</h2>
                  <div>
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'did.content.inbound_sms_emails'|translate }}</mat-label>
                      <input matInput formControlName="inbound_sms_emails">
                      <mat-hint>{{ 'input.email.hint_multi_emails'|translate }}</mat-hint>
                      
                      <mat-error [flexFormControlError]="form.get('inbound_sms_emails')"
                        [customMessages]="{
                          'incorrect': 'public.message.email_invalid'|translate
                        }"></mat-error>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>

            </form>
          </div>
        </div>

      </div>
    </div>
  </ng-template>

</div>

<ng-template #tplNoDID>
  {{ 'did.content.no_did_set' }}
</ng-template>
