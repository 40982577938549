import * as _ from 'lodash';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { CallQueueItemEntity } from '@wephone-core/model/entity/callqueueitem';
import { PhoneNumberService, regexSearch } from '@wephone-utils';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { CallQueueItemRepository } from '@wephone-core/model/repository/callqueueitem';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { FlexBaseComponent } from '@wephone-core-ui';
import { _ti } from '@wephone-translation';
import { UserEntity } from '@wephone-core/model/entity/user';
import { AccessRight, UserType } from '@wephone-core/system';
import { AgentStatusType } from '@wephone/components/agents/agent-status-type';
import { EavesdropService } from '@wephone-common/service/eavesdrop.service';
import { CallQueueSessionEntity } from '@wephone-core/model/entity/callqueuesession';
import { IAgentItemActionMenu } from '../agent-item/agent-item.i';
import { EavesdropRequestEntity } from '@wephone-core/model/entity/eavesdrop_request';

@Component({
  selector: 'phone-busy-agents',
  templateUrl: 'phone-busy-agents.html',
  styleUrls: ['phone-busy-agents.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneBusyAgentsComponent extends FlexBaseComponent implements OnInit {
  PERIODICAL_UPDATE = true;
  realtime_pushapi = true;
  @Input() header: string;
  @Input() headerStyle?: any;
  @Input() searchText: string;
  @Input() displayGroup: boolean;

  private readonly currentUser: UserEntity;
  private readonly queueItemRepo: CallQueueItemRepository;

  agents: AgentEntity[] = [];
  agentActions: IAgentItemActionMenu[];
  busyAgents: AgentEntity[] = [];
  displayContent = true;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly configManager: ConfigManager,
    private readonly em: EntityManager,
    private readonly eavesdropService: EavesdropService,
    cdr: ChangeDetectorRef,

  ) {
    super(cdr);
    this.currentUser = this.authService.getUser();
    this.queueItemRepo = this.em.getRepository<CallQueueItemRepository>('CallQueueItemRepository');
  }

  ngOnInit(): any {
    super.ngOnInit();
    this.agents = this.em.getRepository<AgentRepository>('AgentRepository').getObjectList();
    this.setActionMenu();
  }

  detectChanges(): void {
    this.setActionMenu();
    super.detectChanges();
  }

  callEavesdrop(item: CallQueueItemEntity): Promise<any> {
    const sessionId: number = item.session && item.session.id;
    if (!sessionId) {
      console.error('No session id');
      return;
    }
    return this.eavesdropService.call_eavesdrop(sessionId);
  }

  hasAccessRightEavesdrop(): boolean {
    return this.currentUser &&
      (this.currentUser.isAdmin() ||
        this.currentUser.isSupervisor() ||
        !this.authService.isAdmin() && !this.authService.isSupervisor() && this.authService.hasAccessRight(AccessRight.Eavesdrop));
  }

  canEavesdrop(item: CallQueueItemEntity): boolean {
    const agent: AgentEntity = this.handlingAgent(item);
    if (agent) {
      return this.currentUser.canEavesdrop(agent.user);
    }
  }

  private handlingAgent(item: CallQueueItemEntity): AgentEntity {
    if (!item.session || !item.session.operator_id) {
      return;
    }

    return this.getAgentById(item.session.operator_id);
  }

  isEavesDroppedByMe(session: CallQueueSessionEntity): boolean {
    return session && session.eavesdropper_ids && session.eavesdropper_ids.indexOf(this.authService.getUserId()) !== -1;
  }

  private filterBusyAgent(agent: AgentEntity): boolean {
    if (!this.currentUser) {
      return false;
    }

    if (!agent.enabled) {
      return false;
    }

    if (agent.user && agent.user.user_type === UserType.ACCOUNTANT) {
      return false;
    }

    const current_user_group_ids: number[] = this.currentUser.group_ids || [];
    if (this.authService.isWatcher()
      && (this.configManager.hasFeature('SERVICE_GROUP')
        && agent.group_ids && current_user_group_ids.length
        && !agent.group_ids.filter((n: number) => {
          return current_user_group_ids.indexOf(n) !== -1;
        }).length)
    ) {
      return false;
    }

    if (!agent.reserved && agent.agent_status !== AgentStatusType.PAUSE && agent.agent_status !== AgentStatusType.PAUSE_POSTCALL) {
      return false;
    }

    return true;
  }

  private searchAgent(agent: AgentEntity): boolean {
    if (!this.searchText) {
      return true;
    }

    // const re = new RegExp(this.searchText, 'i');
    const searchTextList = [agent.name];
    for (const queue of agent.inbound_queue_list) {
      searchTextList.push(queue.queue_name);
    }

    if (agent.reserved) {
      const queueItem = this.getQueueItemByAgent(agent);
      if (queueItem) {
        searchTextList.push(queueItem.remote_number);
      }
    }

    for (const searchText of searchTextList) {
      if (regexSearch(searchText, this.searchText)) {
        return true;
      }
    }

    return false;
  }

  get has_busy_agent(): boolean {
    this.busyAgents = _.orderBy(
      this.agents.filter(a => this.filterBusyAgent(a) && this.searchAgent(a)),
      ['name'],
      ['asc'],
    );

    return this.busyAgents.length > 0;
  }

  getAgentById(agent_id: number): AgentEntity {
    if (!agent_id) {
      return undefined;
    }
    return this.em.getRepository<AgentRepository>('AgentRepository').getObjectById(agent_id);
  }

  /* Queue item state template helper*/
  isSessionOnhold(item: CallQueueItemEntity): boolean {
    return item && item.session && item.session.is_onhold ? true : false;
  }

  isSessionBridged(item: CallQueueItemEntity): boolean {
    return item && item.session && item.session.bridged_dt;
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  normalizeNumber(number: string): string {
    return PhoneNumberService.getInstance().getDisplayNumber(number);
  }

  getQueueItemByAgent(agent: AgentEntity): CallQueueItemEntity {
    return this.queueItemRepo.getObjectList().find(c => c.session && c.session.operator_id === agent.id);
  }

  private setActionMenu(): void {
    this.agentActions = [
      {
        id: 'eavsedrop-agent',
        icon: 'headphones',
        label: 'livecall.actions.eavesdrop',
        callback: (agent: AgentEntity) => {
          return this.handleEavesdropAgent(agent);
        },
        visible: (agent: AgentEntity) => {
          return this.eavesdropService.canEavesdropAgent(this.currentUser, agent);
        }
      },
      {
        id: 'remove-eavesdrop-agent',
        icon: 'headset_off',
        color: 'warn',
        label: 'livecall.eavesdrop.remove',
        callback: (agent: AgentEntity) => {
          return this.handleRemoveEavesdropAgent(agent);
        },
        visible: (agent: AgentEntity) => {
          return this.eavesdropService.canRemoveEavesdrop(this.currentUser, agent);
        }
      },
    ];
  }

  isVisibleBusyAgentEavesdrop(call: CallQueueItemEntity): boolean {
    return this.eavesdropService.canEavesdropAgent(this.currentUser, call.agent);
  }

  isVisibleBusyAgentRemoveEavesdrop(call: CallQueueItemEntity): boolean {
    return this.eavesdropService.canRemoveEavesdrop(this.currentUser, call.agent);
  }

  async handleEavesdropAgent(agent: AgentEntity): Promise<void> {
    await this.eavesdropService.call_eavesdrop_agent(agent.user.id);
    this.detectChanges();
  }

  async handleRemoveEavesdropAgent(agent: AgentEntity): Promise<void> {
    const eavesdropAgent: EavesdropRequestEntity = this.eavesdropService.getEavesdropAgent(this.currentUser, agent);
    if (!eavesdropAgent) {
      this.toastService.showError('Eavesdrop not found');
      return;
    }

    try {
      await this.eavesdropService.removeEavesdropRequest(eavesdropAgent.id);
      this.toastService.showSuccess(_ti('public.message.delete_success'));
      this.detectChanges();
    } catch (error) {
      let msg = _ti('public.message.delete_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toastService.showError(msg);
    }
  }

  handleClickEavesdropAgent(call: CallQueueItemEntity): Promise<void> {
    return this.handleEavesdropAgent(call.agent);
  }

  handleClickRemoveEavesdropAgent(call: CallQueueItemEntity): Promise<void> {
    return this.handleRemoveEavesdropAgent(call.agent);
  }

  handleClickEavesdropCall(call: CallQueueItemEntity): Promise<void> {
    const sessionId: number = call.session && call.session.id;
    if (!sessionId) {
      this.toastService.showError('No session id');
      return;
    }
    return this.eavesdropService.call_eavesdrop(sessionId);
  }
}
