<ng-container *ngIf="hintText; else tplNoHintText">
  <div fxLayout="row" fxLayoutAlign="start center" class="search-wrapper" fxLayout="row" [matTooltip]="hintText">
    <ng-container *ngTemplateOutlet="tplSearchBox"></ng-container>
  </div>
</ng-container>

<ng-template #tplNoHintText>
  <div fxLayout="row" fxLayoutAlign="start center" class="search-wrapper" fxLayout="row">
    <ng-container *ngTemplateOutlet="tplSearchBox"></ng-container>
  </div>
</ng-template>

<ng-template #tplSearchBox>
  <mat-form-field class="round-border-input mat-input-sm filled full-width" appearance="outline"
    [matTooltip]="hintText">
    <mat-icon matPrefix>search</mat-icon>
    <input class="search-input" matInput [formControl]="flexSearchControl"
      [placeholder]="placeholder || ('public.search'|translate)" (input)="inputEvent()" (blur)="blurEvent($event)"
      (keyup)="keyupEvent($event)">
    <button type="button" mat-icon-button matSuffix (click)="clearSearch()" [disabled]="!flexSearchControl.value">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</ng-template>
