<ng-template #tplListFilter>
    <form [formGroup]="form">
        <flex-search-input [placeholder]="'public.search'|translate" formControlName="filterString"
            ngClass.xs="search-full-width"></flex-search-input>
    </form>
</ng-template>

<flex-table #flexTable [dataSource]="dataSource" [tableConfig]="tableConfig" 
    [filterTemplate]="tplListFilter"
    [columnTemplates]="{agent_id: tplAgent}"
    fxFlex="grow">
    <ng-template #tplAgent let-item="row">
        <span *ngIf="agents[item.agent_id]">{{ agents[item.agent_id].name }}</span>
    </ng-template>
</flex-table>
