import * as _ from 'lodash';
import { datafield, Entity } from '@wephone-core/model/model';
import { _tk } from '@wephone-translation';
import { parseDateTime } from '@wephone-utils';
import { DidEntity } from './did';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { CrmRoutingRuleEntity } from './crm_routing_rule';
import { IvrCustomMenuEntity } from './ivr_custom_menu';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CallQueueEntity } from './callqueue';

export enum DefaultSoundType {
  music_on_hold = 'music_on_hold', // use for both waiting_music_file and music_on_holding
  waiting_music = 'waiting_music', // currently not in used
  greeting_music = 'greeting_music',
  service_closed = 'service_closed', // Use same as greeting_music
  service_closed_voicemail = 'service_closed_voicemail', // Use same as greeting_music
  no_agent_music = 'no_agent_music',
  out_of_work_music = 'out_of_work_music',
  no_agent_announcement = 'no_agent_announcement'
}

export enum FileEntryMethod {
  UPLOAD = 0,
  TEXT2SPEECH = 1,
  PHONERECORD = 2,
}

export enum FileEntryWebstatus {
  NOT_READY = 0,
  AVAIL_IVR_SERVER = 1,
  AVAIL_BOTH = 2,
  AVAIL_AWS_S3 = 3,
}

export enum FileEntryCategory {
  USER_CONTENT = 0,
  VOICE_MAIL = 1,
  RECORDING_CALL = 2,
  RINGTONE = 3,
}

export enum SsmlVoiceGender {
  SSML_VOICE_GENDER_UNSPECIFIED = 'SSML_VOICE_GENDER_UNSPECIFIED',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NEUTRAL = 'NEUTRAL',
}

interface TTSData {
  text: string;
  lang: string;
  voice: SsmlVoiceGender;
}

export class FileEntryEntity extends Entity {
  static LANG_DEFAULT = 'default';

  static object_type_id = 'fileentry';

  id: number;
  @datafield public_id: string;
  @datafield creation_method: FileEntryMethod; // ("0: Upload, 1: Text2Speech, 2: Phone Record")
  @datafield tts_data: TTSData;
  @datafield langs: string[];
  @datafield modified_dt: any;
  @datafield created_dt: any;
  @datafield is_temp: number;
  @datafield category: FileEntryCategory; // 0: USER CONTENT, 1: Voicemail, 2: Call Record
  @datafield is_shared: number; //  0: Not shared, 1: Shared
  @datafield name: string; // name of shared file
  @datafield alias: string;
  @datafield web_status: FileEntryWebstatus;
  @datafield user_id: number;

  remote_url: string;
  is_system = false;

  get creation_method_label(): string {
    return _tk(`fileentry.content.creation_method.${this.creation_method}`);
  }

  get is_default(): boolean {
    return !this.id && _.includes(FileEntryEntity.getDefaultPublicIds(), this.public_id);
  }

  get ready_for_playing(): boolean {
    if (this.is_default) {
      return true;
    }

    if (this.web_status !== FileEntryWebstatus.AVAIL_BOTH && this.web_status !== FileEntryWebstatus.AVAIL_AWS_S3) {
      return false;
    }

    // check web_status if categoryis voice mail or recording call or user_content
    const files = [FileEntryCategory.VOICE_MAIL, FileEntryCategory.RECORDING_CALL, FileEntryCategory.USER_CONTENT];
    return files.indexOf(this.category) !== -1;
  }

  static getDefaultPublicIds(): string[] {
    return Object.keys(DefaultSoundType);
  }

  getLangs(): string[] {
    return this.langs || [FileEntryEntity.LANG_DEFAULT];
  }

  hasLang(language: string): boolean {
    const lang = language || FileEntryEntity.LANG_DEFAULT;
    if (!this.langs || !this.langs.length) {
      return true;
    }
    return this.langs.indexOf(lang) > -1; // If this.langs contain given lang
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    if (this.langs && typeof this.langs === 'string') {
      try {
        this.langs = JSON.parse(this.langs);
      } catch (e) {
        console.error('Invalid json', this.langs, e);
      }
    }
    this.setModifiedDate(object_data.modified_dt);
    if (object_data.created_dt) {
      this.created_dt = parseDateTime(object_data.created_dt);
    }
  }

  setRemoteUrl(remote_url: string): void {
    this.remote_url = remote_url;
  }

  getRemoteUrl(): string {
    return this.remote_url;
  }

  setModifiedDate(modified_dt: string): void {
    if (modified_dt) {
      this.modified_dt = parseDateTime(modified_dt);
    }
  }

  get routedQueues(): CallQueueEntity[] {
    const ret: CallQueueEntity[] = [];
    for (const queue of EntityManager.getRepository('CallQueueRepository').getObjectList()) {
      const keys = [
        'greeting_file_id',
        'waiting_music_file_id',
        'moh_file_id',
        'opening_hour_file_id',
        'no_agent_file_id',
        'survey_prompt',
        'survey_thank_msg'
      ];

      for (const k of keys) {
        if (queue[k] === this.id && !_.includes(ret.map(x => x.id), queue.id)) {
          ret.push(queue);
          break;
        }
      }

      if (queue.ewt_data) {
        for (const ewt of queue.ewt_data) {
          if (ewt.sound_id === this.id && !_.includes(ret.map(x => x.id), queue.id)) {
            ret.push(queue);
            break;
          }
        }
      }

    }
    return ret;
  }

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      if (did.routed_fileentry && did.routed_fileentry === this.id ||
        did.out_office_hours_routed_fileentry && did.out_office_hours_routed_fileentry === this.id) {
        ret.push(did);
      }
    }
    return ret;
  }

  get routedCrmRoutings(): CrmRoutingRuleEntity[] {
    const ret: CrmRoutingRuleEntity[] = [];
    for (const item of EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList() as CrmRoutingRuleEntity[]) {
      if (item.routed_fileentry && item.routed_fileentry.id === this.id) {
        ret.push(item);
        continue;
      }
      if (!_.isEmpty(item.routed_fileentries_conditional) && _.includes(item.routed_fileentries_conditional.map(x => x.id), this.id)) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routedIvrMenus(): IvrCustomMenuEntity[] {
    return RoutingAppService.getInstance().getRoutedIvrMenusToEntity(this.id, RoutingAppName.play_then_hangup);
  }
}
