<div class="wrapper-calendar">
  <div *ngIf="!embed">
    <div class="name">
      <span class="name-calendar">{{ openinghourCalendar?.name }}</span>
      <ng-container *ngIf="allowEdit && openinghourCalendar?.id">
        <button data-ci="select" *ngIf="showSelector" type="button" mat-flat-button mat-button-sm color="accent" class="float-right mr-3" (click)="onSelectedCalendar()">
          {{ 'public.select_item'|translate }}
        </button>
        <button class="action-edit" mat-icon-button color="accent" (click)="editWorkCalendar(openinghourCalendar.id)">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="table-calendar">
    <table class="calendar_info full-width">
      <tbody>
        <tr *ngFor="let openinghour of openinghourCalendar.openinghours">
          <td>
            <b>{{ 'opening_hour_calendar.content.week_day.' + openinghour.day_from | translate }}</b>
            {{ 'opening_hour_calendar.content.open_to' | translate }}
            <b>{{ 'opening_hour_calendar.content.week_day.' + openinghour.day_to | translate }}</b>
          </td>
          <td>
            <b>{{ openinghour.start_time }}</b>
            {{ 'public.symbol.hour' | translate }}
            {{ 'opening_hour_calendar.content.open_to'| translate }}
            <b>{{ openinghour.end_time }}</b>
            {{ 'public.symbol.hour' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
