import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import {
  ToastService, EditingComponent, DialogActionButton, DialogService, Colors, FormService, NoWhitespaceValidator,
  FlexAnchorAreaDirective,
} from '@wephone-utils';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IvrMenuStatisticsComponent } from '@wephone/modals/ivr-menu/ivr-menu-statistics/ivr-menu-statistics.component';
import { IvrService } from '@wephone-core/service/ivr.service';
import { IvrSubMenuComponent } from '../ivr-sub-menu/ivr-sub-menu.component';
import { RoutingAppName, RoutingParamsIvrMenu } from '@wephone-core/routing-app/routing-app.interface';
import { DidEntity } from '@wephone-core/model/entity/did';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';

@Component({
  selector: 'ivr-menu-edit',
  templateUrl: './ivr-menu-edit.component.html',
  styleUrls: ['./ivr-menu-edit.component.scss']
})
export class IvrMenuEditComponent extends EditingComponent implements OnInit {
  private readonly ivrMenuRepo = IvrCustomMenuRepository.getInstance<IvrCustomMenuRepository>();

  @Input() editingItem: IvrCustomMenuEntity;
  @ViewChild('ivrSubMenu') ivrSubMenu: IvrSubMenuComponent;
  @ViewChild('mainArea') mainArea: FlexAnchorAreaDirective;

  actions: DialogActionButton[];
  form: FormGroup;
  ivrmenu: IvrCustomMenuEntity;
  usedDidList: DidEntity[] = [];

  steps = {
    _1config: 'ivrmenu.title.config_ivr',
    _2message_and_actions: 'ivrmenu.title.config_message_and_actions',
    _3phone_number: 'call_queue_edit.section.phone_numbers',
    _4advance: 'ivrmenu.title.advance',
  };

  loaded = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly dialogService: DialogService,
    private readonly elementRef: ElementRef,
    public readonly formService: FormService,
    public readonly ivrService: IvrService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setIvrmenu();
    this.setUsedDidList();

    this.initFormGroup();

    this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });

    this.actions = [
      {
        label: 'public.delete',
        action: () => {
          this.delete();
        },
        color: Colors.PRIMARY,
        visible: (): boolean => {
          return true;
        }
      },
      {
        label: _tk('ivr_menu.content.view_statistics'),
        action: () => {
          console.log('action ivrmenu view statistics clicked');
          this.showIvrStatistics();
        },
        visible: () => {
          return !!this.ivrmenu.id;
        }
      },
    ];

    this.loaded = true;
  }

  private initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.ivrmenu.name, [Validators.required, Validators.maxLength(128), NoWhitespaceValidator]],
      missed_on_hangup: [this.ivrmenu.missed_on_hangup ? true : false],
      direct_extension: [this.ivrmenu.direct_extension ? true : false],
      ivrscript_data: [_.cloneDeep(this.ivrmenu.ivrscript_data), Validators.compose([Validators.required])],
      alias: [this.ivrmenu.alias, [Validators.maxLength(128)]],
    });
  }

  private setUsedDidList(): void {
    this.usedDidList = this.ivrmenu.routedDids;
  }

  private setIvrmenu(): void {
    this.ivrmenu = _.cloneDeep(this.editingItem);

    if (!this.ivrmenu.ivrscript_data || _.isNull(this.ivrmenu.ivrscript_data)) {
      this.ivrmenu.ivrscript_data = {
        sound_id: undefined,
        key_length: 1,
        keymap: undefined,
        timeout: undefined,
      };
    }

    if (!this.ivrmenu.ivrscript_data.timeout) {
      this.ivrmenu.ivrscript_data.timeout = undefined;
    }
  }

  private getFormResetData(): object {
    return {
      name: this.ivrmenu.name,
      missed_on_hangup: this.ivrmenu.missed_on_hangup ? true : false,
      direct_extension: this.ivrmenu.direct_extension ? true : false,
      ivrscript_data: _.cloneDeep(this.ivrmenu.ivrscript_data),
      alias: this.ivrmenu.alias,
    };
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    this.setIvrmenu();
    this.form.reset(this.getFormResetData(), options);
    this.form.markAsPristine();
    this.formValueChanges.next(false);
  }

  pristineForm(): void {
    this.form.markAsPristine();
    this.formValueChanges.next(false);
  }

  private getIvrScriptData(ivrmenu: IvrCustomMenuEntity): any {
    const data: any = {};
    if (ivrmenu.sound_message && ivrmenu.sound_message.id) {
      data.sound_id = ivrmenu.sound_message.id;
    }

    data.key_length = 1;
    if (ivrmenu.ivrscript_data && ivrmenu.ivrscript_data.hasOwnProperty('key_length')) {
      data.key_length = +ivrmenu.ivrscript_data.key_length;
    }

    data.keymap = {};
    if (ivrmenu.ivrscript_data) {
      if (!ivrmenu.ivrscript_data.keymap) {
        console.error('No ivr script data keymap');
        return;
      }
      for (const key of Object.keys(ivrmenu.ivrscript_data.keymap)) {
        const subMenuData = ivrmenu.ivrscript_data.keymap[key];
        const routedToMainMenu = subMenuData && subMenuData.params && subMenuData.params.sub_menu === 0 ? true : false;

        if (subMenuData && subMenuData.application === RoutingAppName.ivr_custom_menu && !routedToMainMenu) {
          let subIvrCustomMenu: IvrCustomMenuEntity;
          const subMenuDataParams: RoutingParamsIvrMenu = subMenuData.params as RoutingParamsIvrMenu;
          if (subMenuDataParams.ivr_custom_menu) {
            // Create new menu
            subIvrCustomMenu = subMenuDataParams.ivr_custom_menu;
          } else if (subMenuDataParams.id) {
            // Edit a menu
            subIvrCustomMenu = this.ivrMenuRepo.getObjectById(subMenuData.params.id);
          }
          if (!subIvrCustomMenu) {
            console.warn('No requested sub menu');
            continue;
          }
          subMenuDataParams.ivrscript_data = this.getIvrScriptData(subIvrCustomMenu);
          if (!subMenuDataParams.ivrscript_data) {
            console.error('No ivr script data', subMenuDataParams);
            return;
          }
          subMenuDataParams.ivrscript_type = IvrCustomMenuEntity.TYPE_IVRMENU;
          subMenuDataParams.ivr_custom_menu = undefined; // set to null to prevent requesting into server
        }
        ivrmenu.ivrscript_data.keymap[key] = subMenuData;
      }
      data.keymap = ivrmenu.ivrscript_data.keymap;
    }
    if (ivrmenu.ivrscript_data) {
      data.timeout = ivrmenu.ivrscript_data.timeout;
    }

    return data;
  }

  private validate(updatedIvrmenu: IvrCustomMenuEntity): boolean {
    if (!updatedIvrmenu.name) {
      this.toast.showError(_ti('ivrmenu.validator.name_required'));
      return false;
    }

    if (!this.formService.validateMenuData(updatedIvrmenu.ivrscript_data)) {
      return false;
    }

    return true;
  }

  get ivrscriptDataControl(): FormControl {
    return this.form.get('ivrscript_data') as FormControl;
  }

  updateIvrMenuData(ivrmenu: IvrCustomMenuEntity): void {
    const ivrMenuData = this.getIvrScriptData(ivrmenu);
    this.ivrscriptDataControl.markAsDirty();
    this.ivrscriptDataControl.setValue(ivrMenuData);
  }

  private scrollToFirstInvalidControl(): void {
    setTimeout(() => { // Settimeout to await rendered HTML with invalid class
      const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector('.mat-error');
      if (!firstInvalidControl) {
        console.error('No invalid control to scroll to');
        return;
      }
      this.mainArea.scrollToElement(firstInvalidControl);
    });
  }

  async saveIvrMenu(): Promise<any> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        throw new Error(_ti('public.message.data_invalid'));
      }

      if (!this.ivrSubMenu.validateForm()) {
        throw new Error(_ti('public.message.data_invalid'));
      }

      const newData = {
        name: _.trim(this.form.get('name').value),
        missed_on_hangup: this.form.get('missed_on_hangup').value ? 1 : 0,
        direct_extension: this.form.get('direct_extension').value ? 1 : 0,
        ivrscript_data: this.ivrscriptDataControl.value,
        ivrscript_type: IvrCustomMenuEntity.TYPE_IVRMENU,
        alias: _.trim(this.form.get('alias').value || ''),
      };

      const updatedIvrmenu = this.ivrMenuRepo.create(newData) as IvrCustomMenuEntity;
      updatedIvrmenu.id = this.ivrmenu.getId();

      if (!updatedIvrmenu.ivrscript_data.timeout) {
        delete updatedIvrmenu.ivrscript_data.timeout;
      }

      if (!this.validate(updatedIvrmenu)) {
        return undefined;
      }

      const ret = await this.ivrMenuRepo.save(updatedIvrmenu, { ivrscript_data: updatedIvrmenu.ivrscript_data });

      this.ivrmenu = this.ivrMenuRepo.getObjectById(this.ivrmenu.id);
      this.resetForm();

      return ret;
    } catch (error) {
      console.error('IvrMenuEditComponent save IVR-Menu error', error);
      this.scrollToFirstInvalidControl();
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  async submitForm(): Promise<boolean> {
    if (this.form.invalid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return false;
    }

    const retData = await this.saveIvrMenu();
    if (!_.isEmpty(retData)) {
      this.ivrmenu = this.ivrMenuRepo.getObjectById(retData.id);
      this.ivrscriptDataControl.setValue(this.ivrmenu.ivrscript_data);

      this.pristineForm();
      this.toast.showSuccess(_ti('public.message.update_success'));
      return true;
    }
  }

  async delete(): Promise<void> {
    const confirmed: boolean = await this.ivrService.getConfirmDeleteIvrMenus([this.ivrmenu]);
    if (confirmed) {
      const returnedData = await this.ivrMenuRepo.bulkDelete([this.ivrmenu], false);
      if (returnedData) {
        this.close();
        this.toast.showSuccess(_ti('public.message.delete_success'));
      }
    }
  }

  showIvrStatistics(): void {
    this.dialogService.openDialog2(IvrMenuStatisticsComponent, { data: { ivrmenu: this.ivrmenu } }, ret => {
      console.log('IvrMenuStatisticsComponent ret', ret);
    });
  }
}
