import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActionButton, Colors, ToastService } from '@wephone-utils';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { PaymentService } from '@wephone/services/payment.service';
// import { TranslateService } from '@ngx-translate/core';
import { PaymentAdyenService } from '@wephone/services/payment-adyen.service';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { PaymentAttemptEntity } from '@wephone-core/model/entity/payment_attempt';
import { _tk, _ti } from '@wephone-translation';

enum PaymentType {
  UPGRADE_PREMIUM = 1,
  INVOICE = 2,
  BUY_DID = 3,
  CREDIT_RECHARGE = 4
}

@Component({
  selector: 'app-payment-wizard-model',
  templateUrl: './payment-wizard-model.component.html',
  styleUrls: ['./payment-wizard-model.component.scss']
})
export class PaymentWizardModelComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('components.didtopay.dialog_title');
  backButton: DialogActionButton =
    {
      action: () => {
        this.goBackStep();
      },
      visible: (): boolean => {
        return this.selectionStep > 0;
      }
    };
  dialogRightActions: DialogActionButton[] = [
    // Step = 0
    {
      label: _tk('payment_wizard.dialog.payment_cc_default'),
      action: () => {
        this.gotoStepAdyen();
      },
      visible: (): boolean => {
        return this.selectionStep === 0 &&
          this.myenterprise && this.myenterprise.has_default_credit_card === 1;
      },
      color: Colors.PRIMARY
    },
    {
      label: _tk('payment_wizard.dialog.payment_prepaid_credit'),
      action: () => {
        this.payWithPrepaidCredit();
      },
      visible: (): boolean => {
        return this.selectionStep === 0 &&
          this.payment_attempt.payment_type !== 4 &&
          this.myenterprise && this.myenterprise.remaining_credit >= this.payment_attempt.total_amount;
      },
      color: Colors.PRIMARY
    },
    {
      label: _tk('payment_wizard.dialog.payment_paypal'),
      action: () => {
        this.payWithPaypal();
      },
      visible: (): boolean => {
        return this.selectionStep === 0 &&
          this.myenterprise && this.myenterprise.has_default_credit_card === 0;
      },
      color: Colors.PRIMARY
    },
    // Step = 1
    {
      label: _tk('payment_wizard.dialog.payment_paynow'),
      action: () => {
        this.payWithCreditCard();
      },
      visible: (): boolean => {
        return this.selectionStep === 1;
      },
      color: Colors.PRIMARY
    }
  ];

  payment_attempt: PaymentAttemptEntity;
  selectionStep: number;
  myenterprise: EnterpriseEntity;
  credit_card_info: any = {
    number: '5100 0811 1222 3332',
    cvc: '737',
    holderName: 'Mr ABC',
    expiryMonth: 8,
    expiryYear: 2018
  };
  payment_reason: string;

  constructor(
    dialogRef: MatDialogRef<PaymentWizardModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private paymentService: PaymentService,
    private paymentAdyenService: PaymentAdyenService,
    private toast: ToastService,
    // private trans: TranslateService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selectionStep = 0;
    this.myenterprise = (EnterpriseRepository.getInstance() as EnterpriseRepository).getMyEnterprise();

    this.credit_card_info = {
      number: '5100 0811 1222 3332',
      cvc: '737',
      holderName: 'Mr ABC',
      expiryMonth: '8',
      expiryYear: '2018'
    };

    this.payment_attempt = this.data.payment_attempt;
    if (!this.payment_attempt) {
      console.error('No payment attempt');
      return;
    }
    switch (this.payment_attempt.payment_type) {
      case PaymentType.INVOICE:
        this.payment_reason = _ti('payment_wizard.payment_reasion.payment_invoice');
        break;
      case PaymentType.UPGRADE_PREMIUM:
        this.payment_reason = _ti('payment_wizard.payment_reasion.payment_switch_premium');
        break;
      case PaymentType.BUY_DID:
        this.payment_reason = _ti('payment_wizard.payment_reasion.payment_buy_did');
        break;
      case PaymentType.CREDIT_RECHARGE:
        this.payment_reason = _ti('payment_wizard.payment_reasion.payment_credit_recharge');
        break;
      default:
        break;
    }
  }

  gotoStepAdyen(): void {
    this.selectionStep = 1;
  }

  goBackStep(): void {
    if (this.selectionStep > 0) {
      this.selectionStep--;
    }
  }

  // async payWithDefaultCreditCard(): Promise<void> {
  //   try {
  //     await this.paymentService.processPaymentAttemptDefault(this.payment_attempt);
  //     // close
  //     this.dismiss();
  //   } catch (error) {
  //     this.toast.showError(_ti('Error'));
  //   }
  // }

  async payWithPrepaidCredit(): Promise<void> {
    try {
      const resp = await this.paymentService.processPaymentAttemptPrepaidCredit(this.payment_attempt);
      console.log('payWithPrepaidCredit Response', resp);
      // close
      this.dismiss();
    } catch (error) {
      console.error('payWithPrepaidCredit Error', error);
      this.toast.showError(_ti('invoice.message.payment_failure'));
    }
  }

  async payWithPaypal(): Promise<void> {
    try {
      await this.paymentService.processPaymentAttempt(this.payment_attempt, null, 'paypal', false);
    } catch (error) {
      console.error('payWithPaypal Error', error);
      this.toast.showError(_ti('invoice.message.payment_failure'));
    }
  }

  async payWithCreditCard(): Promise<void> {
    if (!this.isValidCreditCard()) {
      this.toast.showError(_ti('payment_wizard.message.credit_cart_incorrect'));
      return Promise.reject();
    }

    try {
      const encryped_data = await this.paymentAdyenService.encryptCardData(this.credit_card_info);
      console.log('encryped_data', encryped_data);

      if (encryped_data === false) {
        this.toast.showError(_ti('payment_wizard.message.invalid_card_data'));
      } else {
        await this.paymentService.processPaymentAttempt(this.payment_attempt, encryped_data, 'adyen', false);
        // close
        this.dismiss();
      }
    } catch (error) {
      this.toast.showError(_ti('invoice.message.payment_failure'));
      console.error('Process payment attemption error', error);
    }

  }

  isValidCreditCard(): boolean {
    console.log('credit_card_info', this.credit_card_info);
    if (!this.credit_card_info ||
      !this.credit_card_info.number || !this.credit_card_info.cvc || !this.credit_card_info.holderName ||
      !this.credit_card_info.expiryMonth || !this.credit_card_info.expiryYear) {
      return false;
    }

    return true;
  }

}
