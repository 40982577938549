import { Entity, datafield } from '@wephone-core/model/model';

export class SipPhoneProvisioningTokenEntity extends Entity {
  static object_type_id = 'sip_phone_provisioning_token';

  @datafield id: number;
  @datafield enterprise_id: number;
  @datafield name: string;
  @datafield phone_model: string;
  @datafield token: string;
  @datafield valid_until: Date;
  @datafield creation_dt: Date;
  @datafield config_template: string;

  setObjectData(object_data: any, fetch_related_data = true): void {
    super.setObjectData(object_data, fetch_related_data);

    this.creation_dt = object_data.creation_dt && new Date(object_data.creation_dt);
    this.valid_until = object_data.valid_until && new Date(object_data.valid_until);
  }
}
