
import { Component } from '@angular/core';
import { NetStatus } from '@wephone-core/service/net-status.service';
import { IJsPhoneDriver } from '@jsphonelib';
import { ConnectivityService } from '@wephone-core-ui';

@Component({
    selector: 'net-status',
    templateUrl: 'net-status.component.html',
    styleUrls: ['./net-status.component.scss']
})
export class NetStatusComponent  {

    constructor(
        private netStatus: NetStatus,
        private connectivity: ConnectivityService
    ) {
    }

    everythingIsOK(): boolean {
        if (!this.netStatus.isOnline) {
            return false;
        }
        return this.connectivity.connectivityOK();
    }

    isOffline(): boolean {
        return !this.netStatus.isOnline;
    }

    sipDisconnected(): boolean {
        return !this.connectivity.phoneConnectionOK();
    }

}
