import { Component, Input } from '@angular/core';
import { ITabSubRoute, ParamRoutableComponent } from '..';
import { Router } from '@angular/router';
import { BadgeFunction } from '../routable-tabgroup';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'routable-tab-navigation-menu',
  templateUrl: './routable-tab-navigation-menu.html',
  styleUrls: ['./routable-tab-navigation-menu.scss']
})
export class RoutableTabNavigationMenu extends ParamRoutableComponent {
  @Input() childRoutes: ITabSubRoute[];
  @Input() footer: boolean;
  @Input() color: string;
  @Input() bgColor: string;

  private activeView: string;

  selectedTabIndex = 0;

  constructor(
    private router: Router
  ) {
    super();
  }

  protected onRouteQueryParamChange(params: any): any {
    this.activeView = params.view;
    this.selectedTabIndex = this.childRoutes.findIndex(r => r.name === this.activeView);
  }

  goToSubRoute(index: number): void {
    const route = this.childRoutes[index];
    if (route) {
      this.router.navigate([], { queryParams: { view: route.name }, queryParamsHandling: 'merge' });
    }
  }

  badge(fnBadge: number | BadgeFunction): any {
    return typeof fnBadge === 'number' ? fnBadge : fnBadge();
  }
}
