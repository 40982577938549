import { Injectable } from '@angular/core';

import { MyCallCenterStatusRepository } from '@wephone-core/model/repository/my_callcenter_status';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { AgentEventRepository } from '@wephone-core/model/repository/agent_event';
import { BlackListRepository } from '@wephone-core/model/repository/blacklist';
import { CallingProfileRepository } from '@wephone-core/model/repository/callingprofile';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { CallQueueAgentLinkRepository } from '@wephone-core/model/repository/callqueue_agent_link';
import { CallQueueItemRepository } from '@wephone-core/model/repository/callqueueitem';
import { CallQueueSessionRepository } from '@wephone-core/model/repository/callqueuesession';
import { ConferenceRepository } from '@wephone-core/model/repository/conference';
import { ContactFormatRepository } from '@wephone-core/model/repository/contact_format';
import { ContactFormatFieldRepository } from '@wephone-core/model/repository/contact_format_field';
import { CreditRechargeRepository } from '@wephone-core/model/repository/credit_recharge';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { CustomStatsFilterRepository } from '@wephone-core/model/repository/customstatsfilter';
import { DidRepository } from '@wephone-core/model/repository/did';
import { DidCountryRepository } from '@wephone-core/model/repository/did_country';
import { DidOrderRepository } from '@wephone-core/model/repository/did_order';
import { EavesdropRequestRepository } from '@wephone-core/model/repository/eavesdrop_request';
import { EmailRepository } from '@wephone-core/model/repository/email';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import { EnterpriseFileEntryRepository } from '@wephone-core/model/repository/enterprise_fileentry';
import { EnterpriseContactRepository } from '@wephone-core/model/repository/enterprisecontact';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';
import { HotelWakeupCallRepository } from '@wephone-core/model/repository/hotel_wakeup_call';
import { HotelRoomLogRepository } from '@wephone-core/model/repository/hotel_room_log';
import { InvoiceRepository } from '@wephone-core/model/repository/invoice';
import { InvoiceItemRepository } from '@wephone-core/model/repository/invoice_item';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { IvrAppRepository } from '@wephone-core/model/repository/ivrapp';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { LiveQoSWatchRepository } from '@wephone-core/model/repository/liveqoswatch';
import { MissedCallRepository } from '@wephone-core/model/repository/missed_call';
import { OpeningHourRepository } from '@wephone-core/model/repository/openinghour';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { OpeningHourSpecialDateRepository } from '@wephone-core/model/repository/openinghour_special_date';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { OutcallCampaignItemRepository } from '@wephone-core/model/repository/outcallcampaignitem';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { QueueTransferNumberRepository } from '@wephone-core/model/repository/queue_transfernumber';
import { RoutingAppRepository } from '@wephone-core/model/repository/routing_app';
import { ShortDialCodeRepository } from '@wephone-core/model/repository/short_dial_code';
import { ShortcutRepository } from '@wephone-core/model/repository/shortcut';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { SkillRepository } from '@wephone-core/model/repository/skill';
import { SkillAgentRequirementRepository } from '@wephone-core/model/repository/skill_requirement';
import { SkillGroupRepository } from '@wephone-core/model/repository/skillgroup';
import { SubscriptionRepository } from '@wephone-core/model/repository/subscription';
import { SubscriptionPackRepository } from '@wephone-core/model/repository/subscription_pack';
import { TaskRepository } from '@wephone-core/model/repository/task';
import { TeamGroupRepository } from '@wephone-core/model/repository/teamgroup';
import { UserRepository } from '@wephone-core/model/repository/user';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { VoiceMailRepository } from '@wephone-core/model/repository/voicemail';
import { VoiceMailBoxRepository } from '@wephone-core/model/repository/voicemail_box';
import { MyActiveCallRepository } from '@wephone-core/model/repository/my_active_call';
import { IRepository } from '@wephone-core/model/model.interface';
import { PhoneBookRepository } from '@wephone-core/model/repository/phonebook';
import { SingletonBase } from '@wephone-utils';
import { LiveUserCallRepository } from '@wephone-core/model/repository/liveusercall';
import { EndorsementRepository } from '@wephone-core/model/repository/endorsement';
import { PaymentAttemptRepository } from '@wephone-core/model/repository/payment_attempt';
import { SlackChannelRepository } from '@wephone-core/model/repository/slack_channel';
import { EnterpriseParametersRepository } from '@wephone-core/model/repository/enterprise_parameters';
import { DidCountryStateRepository } from '@wephone-core/model/repository/did_country_state';
import { CustomReportConfigRepository } from '@wephone-core/model/repository/custom_report_config';
import { PaymentSourceRepository } from '@wephone-core/model/repository/payment_source';
import { TagRepository } from '@wephone-core/model/repository/tag';
import { IvrEvaluationRepository } from '@wephone-core/model/repository/ivr_evaluation';
// import { TelecomBillingPlanRepository } from '@wephone-core/model/repository/telecom_billing_plan';
import { RoutingServiceRepository } from '@wephone-core/model/repository/routing_service';
import { UserParametersRepository } from '@wephone-core/model/repository/user_parameters';
import { SipPhoneProvisioningTokenRepository } from '@wephone-core/model/repository/sip_phone_provisioning_token';
import { SmsTemplateRepository } from '@wephone-core/model/repository/sms_template';
import { EnterpriseTelecomParamsRepository } from '@wephone-core/model/repository/enterprise_telecom_params';
import { EnterpriseConsumptionRepository } from '@wephone-core/model/repository/enterprise_consumption';
import { EnterpriseSipGatewayRepository } from '@wephone-core/model/repository/enterprise_sip_gateway';
import { SyncTaskRepository } from '@wephone-core/model/repository/sync_task';
import { EnterpriseObjectTemplateRepository } from '@wephone-core/model/repository/enterprise_object_template';
import { ImageRepository } from '@wephone-core/model/repository/image';
import { SipPhoneConnectionLogRepository } from '@wephone-core/model/repository/sipphone_connection_log';
import { LocationRepository } from '@wephone-core/model/repository/location';

@Injectable()
export class RepositoryService extends SingletonBase {
  getRepositoryList(): any[] {
    return [
      [MyCallCenterStatusRepository, 'MyCallCenterStatusRepository'],
      [MyActiveCallRepository, 'MyActiveCallRepository'],
      [AgentRepository, 'AgentRepository'],
      [AgentEventRepository, 'AgentEventRepository'],
      [BlackListRepository, 'BlackListRepository'],
      [CallingProfileRepository, 'CallingProfileRepository'],
      [CallQueueRepository, 'CallQueueRepository'],
      [CallQueueAgentLinkRepository, 'CallQueueAgentLinkRepository'],
      [CallQueueItemRepository, 'CallQueueItemRepository'],
      [CallQueueSessionRepository, 'CallQueueSessionRepository'],
      [ConferenceRepository, 'ConferenceRepository'],
      [ContactFormatRepository, 'ContactFormatRepository'],
      [ContactFormatFieldRepository, 'ContactFormatFieldRepository'],
      [CreditRechargeRepository, 'CreditRechargeRepository'],
      [CrmRoutingRuleRepository, 'CrmRoutingRuleRepository'],
      [CustomStatsFilterRepository, 'CustomStatsFilterRepository'],
      [DidRepository, 'DidRepository'],
      [DidCountryRepository, 'DidCountryRepository'],
      [DidCountryStateRepository, 'DidCountryStateRepository'],
      [DidOrderRepository, 'DidOrderRepository'],
      [PaymentSourceRepository, 'PaymentSourceRepository'],
      [EavesdropRequestRepository, 'EavesdropRequestRepository'],
      [EmailRepository, 'EmailRepository'],
      [EnterpriseRepository, 'EnterpriseRepository'],
      [EnterpriseCrmRepository, 'EnterpriseCrmRepository'],
      [EnterpriseFileEntryRepository, 'EnterpriseFileEntryRepository'],
      [EnterpriseContactRepository, 'EnterpriseContactRepository'],
      [FileEntryRepository, 'FileEntryRepository'],
      [ImageRepository, 'ImageRepository'],
      [GroupRepository, 'GroupRepository'],
      [HotelRoomRepository, 'HotelRoomRepository'],
      [HotelWakeupCallRepository, 'HotelWakeupCallRepository'],
      [HotelRoomLogRepository, 'HotelRoomLogRepository'],
      [InvoiceRepository, 'InvoiceRepository'],
      [InvoiceItemRepository, 'InvoiceItemRepository'],
      [IvrCustomMenuRepository, 'IvrCustomMenuRepository'],
      [IvrRemoteAppRepository, 'IvrRemoteAppRepository'],
      [IvrAppRepository, 'IvrAppRepository'],
      [IvrScriptRepository, 'IvrScriptRepository'],
      [LiveQoSWatchRepository, 'LiveQoSWatchRepository'],
      [MissedCallRepository, 'MissedCallRepository'],
      [OpeningHourRepository, 'OpeningHourRepository'],
      [OpeningHourCalendarRepository, 'OpeningHourCalendarRepository'],
      [OpeningHourSpecialDateRepository, 'OpeningHourSpecialDateRepository'],
      [OutcallCampaignRepository, 'OutcallCampaignRepository'],
      [OutcallCampaignItemRepository, 'OutcallCampaignItemRepository'],
      [PauseReasonRepository, 'PauseReasonRepository'],
      [QualificationRepository, 'QualificationRepository'],
      [QueueTransferNumberRepository, 'QueueTransferNumberRepository'],
      [RoutingAppRepository, 'RoutingAppRepository'],
      [ShortDialCodeRepository, 'ShortDialCodeRepository'],
      [ShortcutRepository, 'ShortcutRepository'],
      [SipPhoneRepository, 'SipPhoneRepository'],
      [SipTrunkRepository, 'SipTrunkRepository'],
      [EnterpriseObjectTemplateRepository, 'EnterpriseObjectTemplateRepository'],
      [SkillRepository, 'SkillRepository'],
      [SkillAgentRequirementRepository, 'SkillAgentRequirementRepository'],
      [SkillGroupRepository, 'SkillGroupRepository'],
      [PaymentAttemptRepository, 'PaymentAttemptRepository'],
      [SubscriptionRepository, 'SubscriptionRepository'],
      // [TelecomBillingPlanRepository, 'TelecomBillingPlanRepository'],
      [SubscriptionPackRepository, 'SubscriptionPackRepository'],
      [EndorsementRepository, 'EndorsementRepository'],
      [TaskRepository, 'TaskRepository'],
      [TeamGroupRepository, 'TeamGroupRepository'],
      [UserRepository, 'UserRepository'],
      [UserGroupRepository, 'UserGroupRepository'],
      [VoiceMailRepository, 'VoiceMailRepository'],
      [VoiceMailBoxRepository, 'VoiceMailBoxRepository'],
      [PhoneBookRepository, 'PhoneBookRepository'],
      [EnterpriseParametersRepository, 'EnterpriseParametersRepository'],
      [LiveUserCallRepository, 'LiveUserCallRepository'],
      [SlackChannelRepository, 'SlackChannelRepository'],
      [CustomReportConfigRepository, 'CustomReportConfigRepository'],
      [TagRepository, 'TagRepository'],
      [IvrEvaluationRepository, 'IvrEvaluationRepository'],
      [RoutingServiceRepository, 'RoutingServiceRepository'],
      [UserParametersRepository, 'UserParametersRepository'],
      [SipPhoneProvisioningTokenRepository, 'SipPhoneProvisioningTokenRepository'],
      [SmsTemplateRepository, 'SmsTemplateRepository'],
      [EnterpriseTelecomParamsRepository, 'EnterpriseTelecomParamsRepository'],
      [EnterpriseConsumptionRepository, 'EnterpriseConsumptionRepository'],
      [EnterpriseSipGatewayRepository, 'EnterpriseSipGatewayRepository'],
      [SyncTaskRepository, 'SyncTaskRepository'],
      [LocationRepository, 'LocationRepository'],
      [SipPhoneConnectionLogRepository, 'SipPhoneConnectionLogRepository'],
    ];
  }
}
