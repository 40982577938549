import { AgentPhoneMode } from '@wephone-core/system';
import { IEntity } from '@wephone-core/model/model.interface';
import { IUserEntity } from './user.i';
import { ICallQueueEntity } from './callqueue.i';
import { ISipphoneEntity } from './sipphone.i';
import { CallQueueSessionEntity } from './callqueuesession';
import { SkillRequirementEntity } from './skill_requirement';
import { PauseReasonEntity } from './pause_reason';
import { IOpeningHourCalendarEntity } from './openinghour_calendar.i';

export enum AgentReservedStatus {
  InternalReserved = 2,
  ExternalReserved = 1,
  NoReserved = 0,
}
export interface IAgentEntity extends IEntity {
  id: number;

  enabled: number;
  user_id: number;
  reserving_server_id: number;
  reserved_dt: any;
  reserved: AgentReservedStatus;
  phone_mode: AgentPhoneMode;
  phone_number: string;
  is_connected: number;
  is_connected_calendar: number;
  always_available: number;
  unreachable_count: number;
  last_hangup_dt: any;
  last_pickup_dt: any;
  last_unreachable_dt: any;
  last_release_dt: any;
  team: string;
  pause_event_id: number;
  pause_reason_id: number;
  connection_event_id: number;
  reserving_call_id: number;
  last_session_id: number;
  last_session_type: string;
  inout_state: number;
  session_auth_token: string;
  agent_id: number;
  available_since: any;
  pause_since: any;
  connection_time: any;
  availability: number;
  working_calendar_id: number;
  default_queue_id: number;

  user: IUserEntity;
  skills: SkillRequirementEntity[];
  pause_reason: PauseReasonEntity;

  is_available_cc: boolean;

  sipphone: ISipphoneEntity;

  default_queue: ICallQueueEntity;

  working_calendar: IOpeningHourCalendarEntity;

  is_online: boolean;

  is_online_calendar: boolean;

  is_online_cc: boolean;

  is_postcall_paused: boolean;

  is_paused: boolean;

  inbound_queue_list: ICallQueueEntity[];

  inbound_queue_names: string[];

  service_group_list: any[];

  is_free_from_inbound_queue: boolean;

  handled_queue_list: ICallQueueEntity[];

  name: string;

  group_ids: number[];

  tags: number[];

  active_call: CallQueueSessionEntity;

  agent_status_detail: string;

  agent_status: string;

  last_call_displayable: boolean;

  phone_enabled: boolean;

  can_use_callcenter: boolean;

  getUser(): IUserEntity;

  getPauseReasonLabel(): string;
}
