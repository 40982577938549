<div class="pa-1">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'shortcut.content.display_text'|translate}}</mat-label>
        <input matInput [placeholder]="'shortcut.content.display_text'|translate" formControlName="displayText">

        <mat-error [flexFormControlError]="form.get('displayText')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'shortcut.content.destination'|translate}}</mat-label>
        <input matInput [placeholder]="'shortcut.content.destination'|translate" formControlName="destination">

        <mat-error [flexFormControlError]="form.get('destination')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
