<div class="pa-1" fxFill fxFlex>
  <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'crm_routing.content.name'|translate }}</mat-label>
        <input matInput placeholder="{{ 'crm_routing.content.name'|translate }}" formControlName="name">

        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'crm_routing.content.crm'|translate }}</mat-label>
        <mat-select placeholder="{{ 'crm_routing.content.crm' | translate }}" formControlName="crm">
          <mat-option *ngFor="let c of crmList" [value]="c.id">
            {{c.name}}
          </mat-option>
        </mat-select>

        <mat-error [flexFormControlError]="form.get('crm')"></mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span fxFlex="150px"><strong>{{ 'crm_routing.content.dest_default' | translate }}</strong></span>
      <div fxFlex="150px">
        <a (click)="changeCallDestination()" class="define-context">{{ (crmRouting.dest_default ? 'did.form.change' :
          'did.form.define_call_destination')|translate }}</a>
      </div>
      <div fxFlex fxLayout="row" *ngIf="virtualDid">
        <!-- {{ crmRoutingDisplayed.application_name | translate }}&nbsp;
        <a *ngIf="!crmRoutingDisplayed.routed_application && !crmRoutingDisplayed.routed_webservice && !crmRoutingDisplayed.routed_user;else BlockToolTip" class="define-context"
          (click)="changeDidRoutedData()">
          <ng-container *ngTemplateOutlet="BlockToolTip"></ng-container>
        </a>
        <ng-template #BlockToolTip>
          <span>{{ crmRoutingDisplayed.tooltip && crmRoutingDisplayed.tooltip.length ? crmRoutingDisplayed.tooltip[0].param_value : '' }}</span>
        </ng-template> -->
        <number-routing-dest class="flex-info" [did]="virtualDid" [updateLayout]="isUpdatedLayout"></number-routing-dest>
      </div>
    </div>
    <div style="margin-top: 10px">
      <button mat-raised-button color="green" type="button" (click)="addRoutingDataItem()">
        <mat-icon>add</mat-icon>
        {{ 'public.add' | translate }}
      </button>
    </div>
    <div style="margin-top: 10px">
      <div formArrayName="routing_data">
        <div class="timeslot" *ngFor="let subRoutingDataControl of routingDataControls.controls; let i=index"
          [formGroupName]="i">
          <button class="close-button" mat-icon-button (click)="removeRoutingData(i)">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="form-select" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center">
              <strong fxFlex="calc(25%-10px)">{{ 'public.if' | translate }}</strong>
              <div fxFlex="calc(75%-10px)">
                <object-condition-select formControlName="cond"></object-condition-select>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <strong fxFlex="calc(25%-10px)">{{ 'crm_routing.content.send_call' | translate }}</strong>
              <div fxFlex="calc(75%-10px)">
                <call-destination #callDestinationComps [isSubMenu]="false" [required]="true" [canAddSubMenu]="false"
                  [value]="subRoutingDataControl.value?.dest"
                  (valueChanged)="updateCallDestination($event, subRoutingDataControl)"></call-destination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
