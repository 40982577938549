<ng-template [ngIf]="fileEntry" [ngIfElse]="blockNotFileEntry">
  <div fxLayout="row" fxLayoutAlign="start center" class="sound-upload-wrap">
    <div *ngIf="title" fxFlex="auto">{{ title }}</div>
    <div fxFlex="5px"></div>
    <div fxFlex="auto">
      <strong class="sound-name">{{ fileEntry.name }}</strong>
      <span *ngIf="fileEntry.tts_data">{{ fileEntry.tts_data.text }}</span>
    </div>
    <div fxFlex="initial" class="button-row">
      <div fxFlex="50px">
        <fileentry-player-button style="display:inline" [filePublicId]="fileEntry.public_id" playerId="main"
          [lang]="language" [disabled]="!fileEntry.ready_for_playing"></fileentry-player-button>
      </div>
      <button type="button" mat-icon-button color="accent" (click)="openDialogChangeSound()" data-ci="change-sound">
        <mat-icon>create</mat-icon>
      </button>
      <button *ngIf="fileEntry && fileEntry.id && !isDefault" [hidden]="hideRemove" type="button" mat-icon-button color="warn" (click)="removeEntry()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #blockNotFileEntry>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="sound-upload-wrap">
    <div color="dark">{{ title }}</div>
    <div fxFlex="auto" fxLayoutAlign="end center">
      <button data-ci="define-sound" type="button"  mat-stroked-button color="accent" class="button-sound" (click)="openDialogChangeSound()">
        {{ 'user.content.define_sound' | translate }}
      </button>
    </div>
  </div>
</ng-template>
