<div fxLayout="column">
  <form [formGroup]="form" class="form-change-highlight">
    <div class="active-voicemail-by-mail">
      <mat-slide-toggle formControlName="voicemail_by_mail_enabled">
        {{ 'call_queue_edit.content.active_voicemail_by_mail'|translate }}
      </mat-slide-toggle>
    </div>

    <div class="ml-2" *ngIf="voicemailByMailEnabledControl.value">
      <div class="active-voicemail-in-attachement">
        <mat-slide-toggle formControlName="voicemail_in_attachement">
          {{ 'call_queue_edit.content.active_voicemail_in_attachement'|translate }}
        </mat-slide-toggle>
      </div>

      <div formArrayName="voicemail_by_mail_addrs" fxLayout="column">
        <div class="full-width" *ngFor="let mailAddrControl of voicemailByMailAddrsControl.controls; let i = index;">
          <mat-form-field class="full-width">
            <mat-label>{{ 'call_queue.content.email_addr.email'|translate }}</mat-label>
            <input matInput type="email" [formControlName]="i"
              placeholder="{{'call_queue.content.email_addr.email'|translate}}">
            <button mat-icon-button matSuffix type="button" (click)="removeVoicemailByMailAddr(i)"
              title="{{ 'public.remove'|translate }}" class="mat-text-warn">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <mat-error *ngIf="mailAddrControl.hasError('required') && mailAddrControl.touched">
              {{ 'form.validator.field_required' | translate }}
            </mat-error>
            <mat-error *ngIf="mailAddrControl.hasError('wrongEmail') && mailAddrControl.touched">
              {{ 'public.message.email_invalid'|translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-1">
          <button type="button" mat-stroked-button (click)="addVoicemailByMailAddr()" color="green">
            <mat-icon>add</mat-icon> {{'call_queue.content.email_addr.add_email'|translate}}
          </button>
        </div>
        <mat-error *ngIf="voicemailByMailAddrsControl.hasError('required') && voicemailByMailAddrsControl.touched">
          {{ 'form.validator.field_required' | translate }}
        </mat-error>
      </div>
    </div>
  </form>
</div>
