import { Injectable } from '@angular/core';
import { CommonAPI } from '@wephone-core/service/common_api';
import { HttpEngine } from './http_engine';

@Injectable()
export class RecordingService {
  private readonly URL_DELETE_RECORDING_CALLS = '/api/recordingcall/delete';
  private readonly GET_TRANSCRIPTION_PATH = 'transcriber';

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly common_api: CommonAPI,
  ) { }

  public deleteRecordCalls = (ids: number[]) => {
    const params = {
      ids,
    };

    return CommonAPI.wsPost(this.URL_DELETE_RECORDING_CALLS, params);
  }

  async getTranscription(id: number): Promise<any> {
    return this.httpEngine.apiGetV2(`${this.GET_TRANSCRIPTION_PATH}/${id.toString()}/transcription`);
  }

}
