import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { HttpEngine } from '@wephone-core/service/http_engine';
// import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { EnterpriseParametersEntity } from '../entity/enterprise_parameters.entity';

export class EnterpriseParametersRepository extends BaseRepository {
  // static URL_FIND_CURRENT_PARAM = 'find_current_param';
  ENTITY_CLASS = EnterpriseParametersEntity;

  protected URL_PREFIX = 'enterprise_parameters';

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  // updateCurrentParams(item: EnterpriseParametersEntity): Promise<any> {
  //   const action =  'UPDATE';
  //   const url = this.getURLAction(action);
  //   const post_data = item.dumpObjectData(undefined, true);

  //   const field_list = [];
  //   for (const k in post_data) {
  //     if (k !== 'eid') {
  //       field_list.push(k);
  //     }
  //   }
  //   post_data['__field_list__'] = field_list;
  //   return HttpEngine.getInstance().postJson(url, post_data);
  // }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded && use_cache) {
      return Promise.resolve(this.object_list);
    }

    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/enterprise_parameters'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get enterprise list error', response);

          return [];
        }
      );
  }

  async findByEnterpriseId(eid: number): Promise<EnterpriseParametersEntity> {
    await this.findAll();

    return this.object_list.find(x => x.enterprise_id === eid);
  }

}
