import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';

export class RoutingAppCallPhone extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.call_phone;
  private sipphone: SipPhoneEntity;

  setSipPhone(sipphone: SipPhoneEntity): void {
    this.setAppParams({ sipphone_id: sipphone.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.sipphone_id) {
      this.sipphone = EntityManager.getRepository('SipPhoneRepository').getObjectById(this.appParams.sipphone_id);
    }
  }

  getRelatedObject(): SipPhoneEntity {
    return this.sipphone;
  }

  getDescription(): string {
    const sip_number: string = this.sipphone ? this.sipphone.extension : '<invalid>';

    return `To SIP number ${sip_number}`;
  }
}
