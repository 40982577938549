import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class CallQueueSessionRepository extends BaseRepository {
  ENTITY_CLASS = CallQueueSessionEntity;
  protected USE_PUSH_API = true;
  private userSessionHistoryList: CallQueueSessionEntity[] = [];

  getObjectByCallQueueItemId(callqueue_item_id): CallQueueSessionEntity {
    for (const s of this.object_list) {
      if (s.call_queue_item_keyid == callqueue_item_id) {
        return s;
      }
    }
  }

  /**
   * Get the call queue session of Agent that recent terminated
   */
  getRecentObjectByOperatorId(operator_id): CallQueueSessionEntity {
    const sessions = [];
    for (const s of this.object_list) {
      if (s.operator_id == operator_id) {
        sessions.push(s);
      }
    }
    if (sessions.length) {
      // Sort by start_dt desc
      sessions.sort((s1, s2) => {
        if (s1.start_dt == s2.start_dt) {
          return 0;
        }
        return s1.start_dt > s2.start_dt ? -1 : 1;
      });
      return sessions[0];
    }
    return null;
  }

  // Return the most recent session of the currently logged in user
  // This maybe an active session or a terminated session
  getActiveUserSession(): CallQueueSessionEntity {
    // Look into active session list first
    const agent_id = AuthenticationService.getInstance().getAgentId();
    for (const obj of this.object_list) {
      if (obj.operator_id == agent_id) {
        return obj;
      }
    }
    /*
        //If not found, look into session history
        if (this.userSessionHistoryList.length > 0) {
            return this.userSessionHistoryList[0];
        }
*/
  }

  getUserSessionHistory(): CallQueueSessionEntity[] {
    return this.userSessionHistoryList;
  }

  protected onObjectRemoved(obj: CallQueueSessionEntity): void {
    obj.terminated = 1;
    const agent_id = AuthenticationService.getInstance().getAgentId();
    if (obj.bridged_dt && obj.operator_id === agent_id) {
      let found = false;
      for (const o of this.userSessionHistoryList) {
        if (o.getId() === obj.getId()) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.userSessionHistoryList.splice(0, 0, obj);
      }
    }
  }

  /**
   * Get only active object (terminated = 0)
   */
  getActiveObjectByOperatorId(operator_id): CallQueueSessionEntity {
    // Sort by ID desc
    const sortedList = this.object_list.sort((a, b) => {
      return a.id < b.id ? 1 : (a.id === b.id ? 0 : -1);
    });

    return sortedList.find(x => x.operator_id === operator_id && x.terminated === 0);
  }
}

import { CallQueueSessionEntity } from '@wephone-core/model/entity/callqueuesession';
import { AuthenticationService } from '@wephone-core/service/authentication';
