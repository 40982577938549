import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DynamicFilterSource,
  IFlexTableConfig,
  DialogService,
  ToastService,
  IFlexTableSidePannelOptions,
  FlexCRUDPageComponent,
  regexSearch,
  PhoneNumberService
} from '@wephone-utils';
import { FlexBasePage } from '@wephone-core-ui';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PhoneBookEntry } from '@wephone-core/model/entity/phonebook';
import { PhoneBookRepository } from '@wephone-core/model/repository/phonebook';
import { CreatePhoneBookModalComponent } from '@wephone/modals/create-phone-book-modal/create-phone-book-modal.component';
import { EditPhoneBookComponent } from '@wephone/components/edit-phone-book/edit-phone-book.component';
import { _tk, _ti } from '@wephone-translation';
import { PhonebookImportComponent } from '@wephone/modals/phonebook-import/phonebook-import.component';

@Component({
  selector: 'app-phone-book-management',
  templateUrl: './phone-book-management.component.html',
  styleUrls: ['./phone-book-management.component.scss']
})
export class PhoneBookManagementComponent extends FlexBasePage implements OnInit {

  filterValue: string;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditPhoneBookComponent
  };
  phoneBookForm: FormGroup;
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;
  constructor(
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    public dialogService: DialogService,
    public toast: ToastService,
    public readonly phoneNumberService: PhoneNumberService,
  ) {
    super();
    const phoneBookSource = EntityManager.getRepository('PhoneBookRepository').dataSource<PhoneBookEntry>();
    this.dataSource = new DynamicFilterSource(phoneBookSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'first_name',
          label: _tk('phonebook.content.first_name'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (phoneBook: PhoneBookEntry) => {
            return phoneBook.first_name && phoneBook.first_name.toLowerCase();
          }
        },
        {
          name: 'last_name',
          label: _tk('phonebook.content.last_name'),
          searchable: true,
          sortable: true,
          customSortFn: (phoneBook: PhoneBookEntry) => {
            return phoneBook.last_name && phoneBook.last_name.toLowerCase();
          }
        },
        {
          name: 'email',
          label: _tk('phonebook.content.email'),
          searchable: true,
          sortable: true,
        },
        {
          name: 'phone_office',
          label: _tk('phonebook.content.phone_office'),
          searchable: true,
          sortable: true,
          customSortFn: (phoneBook: PhoneBookEntry) => {
            return this.phoneNumberService.getDisplayNumber(phoneBook.phone_office);
          }
        },
        {
          name: 'phone_mobile',
          label: _tk('phonebook.content.phone_mobile'),
          searchable: true,
          sortable: true,
          customSortFn: (phoneBook: PhoneBookEntry) => {
            return this.phoneNumberService.getDisplayNumber(phoneBook.phone_mobile);
          }
        },
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'person_add',
            callback: () => {
              return this.createNew();
            }
          },
          {
            id: 'group_add',
            icon: 'group_add',
            callback: () => {
              return this.importContacts();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.phoneBookForm = this.fb.group({
      filterValue: ['']
    });

    this.phoneBookForm.valueChanges.subscribe(this.onFormValueChange);
  }

  ngOnInit(): void {
    super.ngOnInit();
    PhoneBookRepository.getInstance().findAll();
  }

  filterFunc = (item: PhoneBookEntry): boolean => {
    if (!this.filterValue) {
      return true;
    }

    return regexSearch(item.first_name, this.filterValue)
      || (item.last_name && regexSearch(item.last_name, this.filterValue))
      || (item.email && regexSearch(item.email, this.filterValue))
      || (item.phone_mobile && regexSearch(this.phoneNumberService.getDisplayNumber(item.phone_mobile), this.filterValue))
      || (item.phone_office && regexSearch(this.phoneNumberService.getDisplayNumber(item.phone_office), this.filterValue));
  }

  private readonly onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;

    this.dataSource.onFilterChange();
  }

  public async bulkDelete(items: PhoneBookEntry[]): Promise<any> {
    const values = await this.translate
      .get(['dialogs.confirmation', 'user.title.delete', 'dialogs.no', 'dialogs.yes'])
      .toPromise();

    return this.dialogService.confirmDialog(values['dialogs.confirmation'], values['user.title.delete'], () => {
      if (items) {
        EntityManager.getRepository<PhoneBookRepository>('PhoneBookRepository').bulkDelete(items).then(
          response => {
            this.flexCrud.closeSidePanel();
            this.toast.show(_ti('public.message.delete_success'));
          },
          response => {
            this.toast.showError(_ti('public.message.delete_failure'));
          }
        );
      }
    });
  }

  async importContacts(): Promise<void> {
    return this.dialogService.openDialog2(PhonebookImportComponent, { size: 's' }).afterClosed().toPromise();
  }

  async createNew(): Promise<any> {
    return this.dialogService.openDialog2(CreatePhoneBookModalComponent);
  }
}
