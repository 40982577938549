import { Entity, datafield } from '@wephone-core/model/model';
import { parseDateTime } from '@wephone-utils';
import { DateTime } from 'luxon';

export class PaymentSourceEntity extends Entity {
  public static object_type_id = 'payment_source';

  @datafield public name: string;
  @datafield public enterprise_id: number;
  @datafield public active: number;
  @datafield public source_type: number;
  @datafield public description: string;
  @datafield public psp_id: number;
  @datafield public psp_source_id: string;
  @datafield public psp_data: any;
  @datafield public expiration_dt: DateTime;
  public expires: string;

  get can_make_payment(): boolean {
    const now = parseDateTime(new Date());
    const expirationDt = this.expiration_dt;
    if (!expirationDt) {
      return true;
    }

    // expiration_dt was stored just month + year, so it is valid till the midnight of the last day of the specified month
    // For example: the expired date in card: 06/2019, so it can be used at : 23:59:59 30/06/2019
    // So we check current date with 0h:00:00 from the first day of the next month
    const nextMonth = expirationDt.plus({month: 1});
    return now <= nextMonth;
  }

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    if (object_data.expiration_dt) {
      this.expiration_dt = parseDateTime(object_data.expiration_dt);
      this.expires = this.expiration_dt.toFormat('MM/yyyy');
    }
  }
}
