import { Component, OnInit, Input } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { SipPhoneService } from '@wephone-common/service';
import { SipPhoneConnectionLogEntity } from '@wephone-core/model/entity/sipphone_connection_log';

@Component({
  selector: 'app-sipphone-connection-log',
  templateUrl: './sipphone-connection-log.component.html',
  styleUrls: ['./sipphone-connection-log.component.scss']
})
export class SipphoneConnectionLogComponent extends FlexBaseComponent implements OnInit {
  @Input() sipphone: SipPhoneEntity;
  repo: SipPhoneRepository;
  sipphoneConnectionLogs: SipPhoneConnectionLogEntity[] = [];

  constructor(
    private readonly em: EntityManager,
    private readonly sipPhoneService: SipPhoneService,
  ) {
    super();
    this.repo = this.em.getRepository('SipPhoneRepository');
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();

  }

  protected async resolveData(): Promise<void> {
    this.sipphoneConnectionLogs = await this.sipPhoneService.getSipPhoneConnectionLog(this.sipphone.id, 2);
  }
}
