import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';

export class TaskEntity extends Entity {
  public static object_type_id: string = 'task';

  public static origin_object_type_call: number = 1;
  public static task_type_custom: number = 1;
  public static task_type_missedcall: number = 2;
  public static ower_type_user: number = 1;
  public static ower_type_queue: number = 2;

  public id: number;
  @datafield
  public origin_object_id: number;
  @datafield
  public origin_object_type: number;
  @datafield
  public task_type: number;
  @datafield
  public task_state: number;
  @datafield
  public creator_user_id: number;
  @datafield
  public owner_id: number;
  @datafield
  public owner_type: number;
  @datafield
  public creation_dt: any;
  @datafield
  public update_dt: any;
  @datafield
  public validity_start_date: any;
  @datafield
  public validity_end_date: any;
  @datafield
  public validity_start_time: string;
  @datafield
  public validity_end_time: string;
  @datafield
  public task_data: string;
}
