<div fxLayout="column" fxFill>
  <ng-template #tplFilter>
    <form [formGroup]="filterForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
      fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input class="full-width" placeholder="{{ 'public.search' | translate }}"
          formControlName="filterValue" [hintText]="'ivrevaluation.search.hint'|translate"
          ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud [pageTitle]="'menu.manage_ivrevaluation'|translate" [filterTemplate]="tplFilter"
    [tableConfig]="tableConfig" [dataSource]="dataSource" [sidePannelOptions]="sidePannelOptions" fxLayout="row"
    fxFlex="grow">
  </flex-crud-page>
</div>
