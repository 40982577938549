import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  private onClickYes: any;
  showButtons = true;
  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.onClickYes = data.onConfirm;
    if (data.showButtons === false) {
      this.showButtons = false;
    }
  }

  ok(): void {
    if (this.onClickYes) {
      this.onClickYes();
    }
  }
}
