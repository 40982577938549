import { Injectable, NgZone } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SingletonBase } from '../utils/singleton';
import { SmsMessage } from '@wephone-sms/sms.types';
import { VMReaderData, VMReaderDataMsg } from '@wephone-core/model/repository/voicemail.i';

interface Message {
  type: string;
  payload: any;
}

type MessageCallback = (payload: any) => void;

export interface MissedCallMessage {
  message: string;
  enterprise_id?: number;
  data: {
    call_id: number;
    owner_id: number;
    recall_state: number;
  };
}

@Injectable()
export class MessageService extends SingletonBase {

  static readonly type_data_source = '$flexLoadDataSource';
  static readonly type_fileentry_player = '$fileEntryPlayerRequest';
  private handler = new Subject<Message>();

  static getInstance(): MessageService {
    if (!super.getInstance()) {
      new MessageService();
    }
    return super.getInstance();
  }

  broadcast(type: string, payload?: any): void {
    this.handler.next({ type, payload });
  }

  subscribe(type: string, callback: MessageCallback): Subscription {
    return this.handler.pipe(
      filter(message => {
        if (message.type === type) {
          return true;
        }
        return message.type.length > type.length && message.type.indexOf(type) === 0 && message.type[type.length] === '.';
      }
      ),
      map(message => message.payload)
    )
      .subscribe(callback);
  }

  private getBroadcastTypeDS(gridname: string): string {
    return MessageService.type_data_source + gridname;
  }

  broadcastDatasource(gridname: string, payload?: any): void {
    return this.broadcast(this.getBroadcastTypeDS(gridname), payload);
  }

  subscribeDatasource(gridname: string, callback: MessageCallback): Subscription {
    return this.subscribe(this.getBroadcastTypeDS(gridname), callback);
  }

  private getBroadcastTypeFileEntry(playerId: string): string {
    return MessageService.type_fileentry_player + playerId;
  }

  broadcastFileEntry(playerId: string, payload?: any): void {
    return this.broadcast(this.getBroadcastTypeDS(playerId), payload);
  }

  subscribeFileEntry(playerId: string, callback: MessageCallback): Subscription {
    return this.subscribe(this.getBroadcastTypeDS(playerId), callback);
  }

  private getBroadcastTypeFlexSlide(slideName: string): string {
    return MessageService.type_fileentry_player + slideName;
  }

  broadcastFlexSlide(slideName: string, payload?: any): void {
    return this.broadcast(this.getBroadcastTypeFlexSlide(slideName), payload);
  }

  subscribeFlexSlide(slideName: string, callback: MessageCallback): Subscription {
    return this.subscribe(this.getBroadcastTypeFlexSlide(slideName), callback);
  }

  broadcastNewSms(): void {
    return this.broadcast('sms.notification.new_message', undefined);
  }

  subscribeNewSms(callback: MessageCallback): Subscription {
    return this.subscribe('sms.notification.new_message', callback);
  }

  broadcastQualificationDone(data: {
    callqueue_session_id: number;
    type: 'audio'|'call'
  }): void {
    return this.broadcast('qualification.notification.done', data);
  }

  subscribeQualificationDone(callback: MessageCallback): Subscription {
    return this.subscribe('qualification.notification.done', callback);
  }

  broadcastStatusSms(sms?: SmsMessage): void {
    return this.broadcast('sms.notification.update_status', sms);
  }

  subscribeStatusSms(callback: MessageCallback): Subscription {
    return this.subscribe('sms.notification.update_status', callback);
  }

  broadcastMissedCallStatus(data?: MissedCallMessage): void {
    return this.broadcast('missedcall.notification.update_status', data);
  }

  broadcastVMReaderUpdate(data?: VMReaderDataMsg): void {
    this.broadcast('voicemail.notification.reader_update', data);
  }

  subscribeMissedCallStatus(callback: MessageCallback): Subscription {
    return this.subscribe('missedcall.notification.update_status', callback);
  }

  subscribeVMReaderUpdate(callback: MessageCallback): Subscription {
    return this.subscribe('voicemail.notification.reader_update', callback);
  }
}
