import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, joiValidator, ToastService, DialogService, NoWhitespaceValidator } from '@wephone-utils';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as Joi from 'joi-browser';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { _tk, _ti } from '@wephone-translation';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';

@Component({
  selector: 'app-ivr-remote-app-edit',
  templateUrl: './ivr-remote-app-edit.component.html',
  styleUrls: ['./ivr-remote-app-edit.component.scss']
})
export class IvrRemoteAppEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: IvrRemoteAppEntity;

  private ivrRemoteAppRepo: IvrRemoteAppRepository;

  form: FormGroup;
  ivrRemoteApp: IvrRemoteAppEntity;
  constructor(
    private fb: FormBuilder,
    public toast: ToastService,
    private em: EntityManager
  ) {
    super();
    this.ivrRemoteAppRepo = this.em.getRepository('IvrRemoteAppRepository') as IvrRemoteAppRepository;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.ivrRemoteApp = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.ivrRemoteApp.name, [Validators.required, NoWhitespaceValidator, Validators.maxLength(128)]],
      alias: [this.ivrRemoteApp.alias, [Validators.maxLength(255)]],
      url: [this.ivrRemoteApp.url, [UrlValidator.isValidUrl, Validators.maxLength(512)]],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  validateForm(): void {
    this.form.markAllAsTouched();
    const name: string = this.form.get('name').value;
    const existName: IvrRemoteAppEntity = IvrRemoteAppRepository.getInstance<IvrRemoteAppRepository>().getObjectList().find(
      x => x.name === name && x.id !== this.ivrRemoteApp.id
    );
    if (existName) {
      this.form.get('name').setErrors({ name_exist: true });
    }
  }

  async submitForm(): Promise<any> {
    const updateIvrRemoteApp = this.ivrRemoteAppRepo.create();
    updateIvrRemoteApp.setObjectData({
      id: this.ivrRemoteApp.getId(),
      name: this.form.get('name').value,
      alias: this.form.get('alias').value,
      url: this.form.get('url').value,
    });

    try {
      this.validateForm();
      if (!this.form.valid) {
        console.error('Form error', this.form);
        return;
      }

      const attrList = ['name', 'alias', 'url'];
      const resp = await this.ivrRemoteAppRepo.saveAttrs(updateIvrRemoteApp, attrList, null);
      console.log('returnedIvrRemoteApp', resp);
      this.toast.show(_ti('public.message.update_success'));
      this.ivrRemoteApp = this.ivrRemoteAppRepo.getObjectById(this.ivrRemoteApp.getId());
      this.resetForm();
    } catch (error) {
      console.log('Error: ', error);
      this.toast.showError(_ti('public.message.update_failure'));
    }
  }

  private getFormResetData(): any {
    return {
      name: this.ivrRemoteApp.name,
      alias: this.ivrRemoteApp.alias,
      url: this.ivrRemoteApp.url,
    };
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

}
