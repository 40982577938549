import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import * as _ from 'lodash';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { HttpEngine } from '@wephone-core/service/http_engine';

export class QualificationRepository extends BaseRepository {
  ENTITY_CLASS = QualificationEntity;
  protected METHOD_PATH_CLONE = 'clone';
  protected METHOD_PATH_UPDATE_POSITION = 'update_position';

  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'qualification';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'CLONE':
        return this.getUrlV2(this.METHOD_PATH_CLONE);
      case 'UPDATE_POSITION':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_POSITION);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'ADD_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_NEW_ATTRS);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  cloneAndSave(item: QualificationEntity): Promise<any> {
    const url = this.getURLAction('CLONE');
    const post_data = { id: item.getId() };
    return HttpEngine.getInstance().postJson(url, post_data);
  }

  getQualificationsByGroupIds(groupIds: number[], includeEmptyGroup = true): QualificationEntity[] {
    if (_.isEmpty(groupIds)) {
      return this.getObjectList(); // Get all qualifications if groupIds is empty
    }

    const rootList: QualificationEntity[] = this.getRootObjectList();
    if (_.isEmpty(rootList)) {
      return [];
    }

    // Get all group ids including their children
    let allGroupIds = _.cloneDeep(groupIds);
    for (const groupId of groupIds) {
      const subGroupIds = EntityManager.getRepository<GroupRepository>('GroupRepository').getSubGroupIdsById(groupId, true);
      if (!_.isEmpty(subGroupIds)) {
        allGroupIds = _.union(allGroupIds, subGroupIds);
      }
    }

    let ret = [];
    for (const q of rootList) {
      if (_.includes(allGroupIds, q.group_id) || !q.group_id && includeEmptyGroup) {
        ret.push(q);

        // Get children & grand children
        const grandChildren = q.grandChildren;
        if (!_.isEmpty(grandChildren)) {
          ret = _.union(ret, grandChildren);
        }
      }
    }

    return ret;
  }

  getExistQualificationName(name: string, parentId: number, excludedId: number): QualificationEntity {
    return this.getObjectList().find(x => x.value === name && x.parent_id == parentId && (!excludedId || excludedId && x.id !== excludedId));
  }

  getObjectListByRootId(rootId: number): QualificationEntity[] {
    return this.getObjectList().filter(x => x.root_id === rootId);
  }

  getObjectListByParentId(parentId: number): QualificationEntity[] {
    return this.getObjectList().filter(x => x.parent_id === parentId);
  }

  savePositionsV3(qualifications): Promise<any> {
    const url = this.getURLAction('UPDATE_POSITION');

    return HttpEngine.getInstance().postJson(url, { qualifications });
  }

  getRootObjectList(): QualificationEntity[] {
    return this.getObjectList().filter(x => !x.parent_id);
  }

  getValidRootObjectList(): QualificationEntity[] {
    return this.getRootObjectList().filter(x => x.isValid());
  }

  save(item: QualificationEntity, extra_data?: { [id: string]: any }, attr_group?: string): Promise<any> {
    const updatedValue = _.cloneDeep(item);
    if (updatedValue.hasOwnProperty('root_id')) {
      delete updatedValue.root_id;
    }
    return super.save(updatedValue, extra_data, attr_group);
  }

  getDisplayedQualification(qualIds: number[]): string {
    const qualNames = [];
    if (qualIds && qualIds.length) {
      for (const qId of qualIds) {
        const qual = this.getObjectById(qId);
        if (qual) {
          qualNames.push(qual.value);
        }
      }
    }
    return qualNames.join(' > ');
  }

  bulkDelete(items: QualificationEntity[], manual_remove = true): Promise<any> {
    const ids: number[] = [];
    for (let i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    const url = this.getURLAction('DELETE');
    return HttpEngine.getInstance().postJson(url, { ids }).then(ret => {
      if (manual_remove) {
        const not_delete_qualif_ids = ret && ret.error && ret.error.ids || [];
        for (const i of ids) {
          if (not_delete_qualif_ids.indexOf(i) === -1) {
            this.removeObjectById(i);
          }
        }
      }
      return ret;
    });
  }
}
