import { Component, OnInit, Input, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { FlexAnchorDirective } from '../../directives/flex-anchor/flex-anchor.directive';
import { FlexTableComponent } from '../flex-table/flex-table.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IItemEditor, IItemEditorClass } from '../base/editor.i';
import { deferToNextTick } from '../../utils/defer';
import { UIStateService } from '../../services/ui-state.service';
import { Deferred } from 'ts-deferred';
import { DynamicFilterSource } from '../flex-table/datasource/dynamic-filter-source';
import { DialogActionButton } from '../flex-dialog';
import { trigger, transition, useAnimation } from '@angular/animations';
import { slideInRight } from 'ng-animate';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FlexTableSelectionChangeEvent } from '../flex-table/flex-table.i';
// import { deferToNextTick } from '@wephone-utils';

export interface IFlexTableSidePannelOptions {
  singleItemEditor: IItemEditorClass;
  multiItemEditor?: IItemEditorClass;
  editorOptions?: any;
  beforeEditorOpen?(item: any): boolean;
}

@Component({
  selector: 'flex-table-side-pannel',
  templateUrl: './flex-table-side-pannel.component.html',
  animations: [
    trigger('slideInRight', [
      transition('void => *', useAnimation(slideInRight, {
        params: { timing: 0.5, a: '2000px', d: '*' }
      }))
    ])
  ],
  styleUrls: ['./flex-table-side-pannel.component.scss']
})
export class FlexTableSidePannelComponent implements OnInit, OnDestroy {
  @Input() dataSource: DynamicFilterSource;
  @Input() flexTable: FlexTableComponent;
  @Input() options: IFlexTableSidePannelOptions;

  @ViewChild(FlexAnchorDirective, { static: false }) anchor: FlexAnchorDirective;

  private _hasEditor = false;
  private _maximize = false;
  private _currentItem: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  private dLoading: Deferred<void>;
  private loadingId = 0;
  slideInRight: any;

  editingComponent: IItemEditor;

  hasChanges = false;
  constructor(
    private location: Location,
    private activeRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private uiStateService: UIStateService,
    private readonly router: Router,
  ) {
  }

  get actions(): DialogActionButton[] {
    if (this.editingComponent && this.editingComponent.actions) {
      return this.editingComponent.actions.filter(x => !x.hasOwnProperty('visible') || x.hasOwnProperty('visible') && x.visible() === true);
    }
    return [];
  }

  get title(): string {
    if (this.editingComponent && this.editingComponent.title) {
      return this.editingComponent.title;
    }
    return '';
  }

  ngOnInit(): void {
    if (this.flexTable) {
      this.flexTable.onSelectionChange.pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((e: FlexTableSelectionChangeEvent) => {
        if (!e) {
          return;
        }
        if (e.selectedItems.length > 1) {
          if (this.options && this.options.multiItemEditor && e.fireActionMultiEdit) {
            this.loadEditor(e.selectedItems, true);
          } else {
            this.closeEditor();
          }
        } else if (e.focusedItem) {
          if (e.focusedItem !== this._currentItem &&
            (
              !this.options ||
              !this.options.beforeEditorOpen ||
              (this.options.beforeEditorOpen && this.options.beforeEditorOpen(e.focusedItem))
            )
          ) {
            this.loadEditor(e.focusedItem);
          }
        } else {
          this.closeEditor();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openMenuAction(): void {

  }

  async loadEditor(item: any, multiple: boolean = false): Promise<void> {
    this.hasChanges = false;
    this._currentItem = item;
    if (!this.options) {
      console.warn('No options given for flex-table-side-panel');
      return;
    }
    const loadingId = ++this.loadingId;
    if (this.dLoading) {
      await this.dLoading;
      if (loadingId < this.loadingId) {
        // this function has been called again during the wait (other editor is opening)
        return;
      }
    }
    this.dLoading = new Deferred<void>();
    const useMultiEditor = multiple && !!this.options.multiItemEditor;

    if (useMultiEditor && this.editingComponent && this.editingComponent.MULTI_EDITOR) {
      this.editingComponent.setEditingData(item);
      return;
    }

    const itemEditor = useMultiEditor ? this.options.multiItemEditor : this.options.singleItemEditor;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(itemEditor);

    const viewContainerRef = this.anchor.viewContainerRef;
    viewContainerRef.clear();
    this.editingComponent = undefined;
    this.setHasEditor(true);

    deferToNextTick(
      () => {
        try {
          const componentRef = viewContainerRef.createComponent(componentFactory);
          const editor: IItemEditor = componentRef.instance;
          editor.setEditingData(item);
          editor.dataSource = this.dataSource;

          editor.options = this.options.editorOptions;
          editor.editorClose.then(
            resp => {
              this.onEditorClosed();
            }
          );
          editor.formValueChanges.pipe(
            takeUntil(this.ngUnsubscribe)
          ).subscribe((changed: boolean) => {
              this.hasChanges = changed;
          });
          this.editingComponent = editor;

          const id = this.activeRoute.snapshot.paramMap.get('id');
          const url = this.router.routerState.snapshot.url;
          const itemId = item.id || 0;
          if (!id) {
            this.location.replaceState(`${url}/${itemId}`);
          } else if (id != itemId) {
            // this.location.replaceState(`${url.replace(`${id}`, itemId)}`);
            const newUrl: string = url.replace(`/${id}`, `/${itemId}`);
            this.router.navigateByUrl(newUrl, {skipLocationChange: true});
            this.location.replaceState(newUrl);
          }
        }
        finally {
          this.setHasEditor(this.editingComponent !== undefined);
          if (this.dLoading) {
            this.dLoading.resolve();
            this.dLoading = undefined;
          }
        }
      }
    );
  }

  hasEditor(): boolean {
    return this._hasEditor;
  }

  maximize(): boolean {
    return this._maximize;
  }

  private setHasEditor(hasEditor: boolean): void {
    this._hasEditor = hasEditor;
    setTimeout(() => {
      this.uiStateService.setSizePanelOpenned(hasEditor);
    });
  }

  maximizeToggle(): void {
    this._maximize = !this._maximize;
  }

  setMaximize(maximize: boolean): void {
    this._maximize = maximize;
  }

  protected onEditorClosed(): void {
    this.anchor.viewContainerRef.clear();
    this.editingComponent = undefined;
    this.setHasEditor(false);
    this.uiStateService.setSizePanelOpenned(false);
    this._currentItem = undefined;

    const id = this.activeRoute.snapshot.paramMap.get('id');
    let url = this.router.routerState.snapshot.url;
    if (id) {
      url = url.replace(`/${id}`, '');
      this.router.navigateByUrl(url);
    }
    this.location.replaceState(`${url}`);
  }

  closeEditor(): void {
    if (this.editingComponent) {
      this.editingComponent.close();
    }
  }

  async submit(): Promise<any> {
    await this.editingComponent.submitForm();
  }

  reset(): void {
    this.editingComponent.resetForm({ emitEvent: false });
  }
}
