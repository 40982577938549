<!-- <ng-template #invalidData>
  <span>This number is routed directly to a queue. Please configure it as secondary number to another phone number or
    use V1 to edit it</span>
</ng-template> -->

<!-- <div fxFill fxLayout="column" *ngIf="queue && form; else invalidData"> -->
<div fxFill fxLayout="column" *ngIf="queue && form">
  <div class="wrapper">
    <div class="col-left" [fxHide.xs]="true">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of stepKeys; let i=index;">
            <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
              [ngClass]="{'active': mainArea.activeAnchor === step}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ steps[step] | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner">
        <!-- <mat-card class="shadow-none ma-0 pa-0" *ngIf="queue.dedicated_user_id">
          <mat-card-header>
            <mat-card-title>
              {{ 'call_queue_edit.section.dedicated_queue'|translate: {agent_name: queue.dedicated_user?.name || ''} }}
            </mat-card-title>
          </mat-card-header>
        </mat-card> -->
        <form [formGroup]="form" class="form-change-highlight">
          <div class="activity-stream">
            <ng-container *ngIf="steps._0general">
              <mat-card class="shadow-none" id="_0general" anchorPart>
                <mat-card-header>
                  <div mat-card-avatar><i class="timeline-icon material-icons">settings_applications</i></div>
                  <mat-card-title>{{steps._0general|translate}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div>
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'call_queue.content.name' | translate }}</mat-label>
                      <input matInput placeholder="{{ 'call_queue.content.name' | translate }}"
                        formControlName="queue_name">

                      <mat-error [flexFormControlError]="form.get('queue_name')"></mat-error>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>

            </ng-container>

            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">call</i></div>
                <mat-card-title>{{'call_queue_edit.section.incoming_call'|translate}}</mat-card-title>
              </mat-card-header>
            </mat-card>
            <number-opening-calendar #numberOpeningCalendar [queue]="queue" class="shadow-none" id="_1calendar"
              anchorPart (formValueChanges)="numberOpeningCalendarChange()">
            </number-opening-calendar>
            <mat-card class="shadow-none" *ngIf="hasCalendar">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">check</i></div>
                <mat-card-title>{{'call_queue_edit.section.if_opened'|translate}}</mat-card-title>
              </mat-card-header>
            </mat-card>

            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar>
                  <i class="timeline-icon material-icons">
                    library_music
                  </i>
                </div>
                <mat-card-title>{{'call_queue_edit.section.sounds'|translate}}</mat-card-title>
                <mat-card-subtitle>{{'call_queue_edit.section.sounds_hint'|translate}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column">
                  <div>
                    <mat-slide-toggle data-cy="no_silent" formControlName="no_silent">
                      {{ 'call_queue.content.no_silent'|translate }}
                    </mat-slide-toggle>
                  </div>
                  <div *ngIf="form.get('no_silent').value">
                    <mat-slide-toggle data-cy="play_entirely" formControlName="play_entire_welcome">
                      {{ 'call_queue.content.play_entirely_before_entering_queue'|translate }}
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="sound-container mt-1" *ngIf="form.get('no_silent').value">
                  <sound-upload fxFlex class="wrapper-sound-upload" [attr.data-ci]="'sound-upload-greeting-file'"
                    [fileEntry]="form.get('greeting_file').value"
                    title="{{'call_queue.content.greeting_file'|translate}}" [hideRemove]="false"
                    (onChange)="updateSoundFile('greeting_file', $event)">
                  </sound-upload>
                </div>

                <div class="sound-container mt-1" *ngIf="form.get('no_silent').value">
                  <sound-upload fxFlex class="wrapper-sound-upload" defaultSound="music_on_hold"
                    [attr.data-ci]="'sound-upload-waiting-music'" [fileEntry]="form.get('waiting_music_file').value"
                    title="{{'call_queue.content.waiting_music_file'|translate}}" [hideRemove]="false"
                    (onChange)="updateSoundFile('waiting_music_file', $event)">
                  </sound-upload>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar>
                  <i class="timeline-icon material-icons">
                    save
                  </i>
                </div>
                <mat-card-title>{{'call_queue_edit.content.recording_call'|translate}}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.content.recording_call_hint'|translate }}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <mat-slide-toggle formControlName="recording_mode">
                  {{ 'call_queue_edit.content.active_recording_call'|translate }}
                </mat-slide-toggle>

                <div *ngIf="form.get('recording_mode').value">
                  <!-- <mat-form-field class="full-width round-border-input mat-input-sm filled" appearance="outline"> -->
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'call_queue_edit.content.recording_percentage'|translate }}</mat-label>
                    <flex-select [placeholder]="'call_queue_edit.content.select_recording_percentage'| translate"
                      formControlName="recording_percentage" [itemList]="percentageList" [itemTemplate]="itemTemplate"
                      [selectTriggerTemplate]="selectTriggerTemplate" [options]="percentageSelectOptions">
                      <ng-template #selectTriggerTemplate let-selectedValue="selectedValue">
                        <div *ngIf="selectedValue">
                          {{ selectedValue }}%
                        </div>
                      </ng-template>
                      <ng-template #itemTemplate let-item="item">
                        {{ item }}%
                      </ng-template>
                    </flex-select>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none" id="_2queue_agents" anchorPart data-ci="queue_agents_main">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">people</i></div>
                <mat-card-title>{{ 'call_queue_edit.section.main_agents'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.section.main_agents_hint'|translate }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <queue-agent-list-form-input formControlName="queue_agents_main" dragDropGroup="backup_agents"
                  dragDropId="main_agents" (dragDropChange)="dragDropAgentsChange('main_agents', $event)"
                  [queueId]="queue.id" [group]="form.get('group').value" [isExclusive]="form.get('is_exclusive').value"
                  [excludeItems]="queueAgentsBackupControl.value.queue_agents"
                  [title]="'call_queue.content.main_agent_list'|translate" [allowRingAll]="true" ngDefaultControl>
                </queue-agent-list-form-input>

                <mat-error [flexFormControlError]="queueAgentsMainControl"></mat-error>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none"
              *ngIf="queueAgentsMainControl.value?.queue_agents && queueAgentsMainControl.value?.queue_agents.length && queueAgentsMainControl.value.agent_selection_mode!==2"
              data-ci="queue_agents_backup">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">people_outline</i></div>
                <mat-card-title>{{ 'call_queue_edit.section.secondary_agents'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.section.secondary_agents_hint'|translate }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="10px">
                  <mat-form-field class="full-width">
                    <label mat-label>{{ 'call_queue.content.seconds_before_backup'|translate }}</label>
                    <flex-duration-input [formControl]="secondsBeforeBackupControl"
                      [predefined_values]="{
                        '0': 'public.default'|translate
                      }">
                    </flex-duration-input>
                    <mat-hint>{{ 'call_queue.content.agent_duration_info'|translate: {value:0, seconds: 60} }}
                    </mat-hint>

                    <mat-error [flexFormControlError]="secondsBeforeBackupControl"
                      [customMessages]="{
                        'min_time': 'call_queue.content.min_time'|translate
                      }"></mat-error>
                  </mat-form-field>
                  <queue-agent-list-form-input formControlName="queue_agents_backup" dragDropGroup="main_agents"
                    dragDropId="backup_agents" (dragDropChange)="dragDropAgentsChange('backup_agents', $event)"
                    [queueId]="queue.id" [group]="form.get('group').value"
                    [isExclusive]="form.get('is_exclusive').value"
                    [excludeItems]="queueAgentsMainControl.value.queue_agents"
                    [title]="'call_queue.content.backup_agent_list'|translate" [allowRingAll]="false" ngDefaultControl>
                  </queue-agent-list-form-input>

                  <mat-error [flexFormControlError]="queueAgentsBackupControl"></mat-error>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar>
                  <i class="timeline-icon material-icons">
                    timer
                  </i>
                </div>
                <mat-card-title>{{ 'call_queue_edit.section.timeout'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.section.timeout_hint'|translate }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column">
                  <div>
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'call_queue.content.max_inqueue_time'|translate }}</mat-label>
                      <flex-duration-input formControlName="max_inqueue" [predefined_values]="{
                        '0': 'call_queue.content.max_inqueue.never'|translate
                      }">
                      </flex-duration-input>
                      
                      <mat-error [flexFormControlError]="maxInqueueControl"></mat-error>
                    </mat-form-field>
                  </div>

                  <ng-container *ngIf="form.get('max_inqueue').value">

                    <mat-label class="mat-card-title mt-1">
                      {{ 'call_queue.content.wait_timeout_config'|translate }}
                    </mat-label>
                    <call-destination class="full-width -mt-2" data-id="wait_timeout_config"
                      #waitTimeoutConfigDestination [destinations]="waitTimeoutConfigDestinationList"
                      [excludeQueueId]="queue.id"
                      [routeAppLabelObject]="{voicemail: 'call_queue_edit.content.voicemail'|translate}"
                      [voicemail]="{ voicemail_enabled: true, mailbox_id: queue.voicemail_id }"
                      [value]="queue.wait_timeout_config" [required]="false"
                      (valueChanged)="updateWaitTimeoutConfig($event)">
                    </call-destination>

                    <div class="pb-1" *ngIf="isVoicemailEnabled()">
                      <a class="flex-link link-open-voicemail-settings" data-ci="open-voicemail-email-setting"
                        (click)="openVoicemailByEmailSettings()">{{ 'voicemail.email_notification'|translate }}</a>

                      <sound-upload class="wrapper-sound-upload" defaultSound="no_agent_announcement"
                        [attr.data-ci]="'sound-upload-no-agent'" [fileEntry]="form.get('no_agent_file').value"
                        title="{{'call_queue.content.no_agent_file'|translate}}"
                        (onChange)="updateSoundFile('no_agent_file', $event)">
                      </sound-upload>

                    </div>
                    <!-- </div> -->
                  </ng-container>
                </div>
              </mat-card-content>
            </mat-card>

            <!-- Disable temporarily the Call Satisfaction Survey feature -->
            <!--
            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar>
                  <i class="timeline-icon material-icons">
                    sentiment_satisfied
                  </i>
                </div>
                <mat-card-title>{{ 'call_queue_edit.section.satisfaction_survey'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.section.satisfaction_survey_hint'|translate }}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <mat-slide-toggle formControlName="satisfaction_survey_enable">
                    {{ 'call_queue_edit.content.enable_satisfaction_survey_enable'|translate }}
                  </mat-slide-toggle>
                </div>
                <div fxLayout="column" [hidden]="!form.get('satisfaction_survey_enable').value">
                  <sound-upload [fileEntry]="queue.survey_prompt_file"
                    title="{{'call_queue_edit.content.survey_prompt_message'|translate}}"
                    (onChange)="updateSoundFile('survey_prompt_file', $event)">
                  </sound-upload>
                  <div>{{ 'call_queue_edit.content.survey_prompt_information'|translate }}</div>
                </div>
                <div fxLayout="column" [hidden]="!form.get('satisfaction_survey_enable').value">
                  <sound-upload [fileEntry]="queue.survey_thank_message_file"
                    title="{{'call_queue_edit.content.survey_thank_message'|translate}}"
                    (onChange)="updateSoundFile('survey_thank_message_file', $event)">
                  </sound-upload>
                  <div>{{ 'call_queue_edit.content.survey_thank_message_information'|translate }}</div>
                </div>
              </mat-card-content>
            </mat-card>
-->
            <mat-card class="shadow-none" anchorPart>
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">pause</i></div>
                <mat-card-title>{{ 'call_queue_edit.section.pause_agent'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.section.pause_agent_hint'|translate }} </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.xs="0px">
                  <div>
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'call_queue.content.aftercall_pause_mode'|translate }}</mat-label>
                      <mat-select formControlName="aftercall_pause_mode"
                        placeholder="{{ 'call_queue.content.aftercall_pause_mode'|translate }}">
                        <mat-option *ngFor="let item of afterCallPauseModeList" [value]="item.value">{{ item.name|translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="form.get('aftercall_pause_mode').value !== 0">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'call_queue.content.after_call_time'|translate }}</mat-label>
                      <flex-duration-input formControlName="after_call_time" [predefined_values]="{
                        '15': 'call_queue.content.wait_after_call_time.n_seconds'|translate:{ 'n': 15 },
                        '30': 'call_queue.content.wait_after_call_time.n_seconds'|translate:{ 'n': 30 },
                        '60': 'call_queue.content.max_inqueue.n_minutes'|translate:{ 'n': 1 },
                        '120': 'call_queue.content.max_inqueue.n_minutes'|translate:{ 'n': 2 },
                        '0': 'call_queue.content.wait_after_call_time.until_unpause'|translate
                      }"
                        [placeholder]="'call_queue.content.after_call_time'|translate">
                      </flex-duration-input>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none" anchorPart>
              <mat-card-header>
                <div mat-card-avatar>
                  <mat-icon class="timeline-icon">phone_forwarded</mat-icon>
                </div>
                <mat-card-title>{{ 'call_queue_edit.section.invite_to_ivrevaluation'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.section.invite_to_ivrevaluation_hint'|translate }}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.xs="0px">
                  <div fxLayout="row" fxLayoutGap="20px">
                    <div fxFlex="130px">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'call_queue.content.eoc_survey_direction'|translate }}</mat-label>
                        <mat-select formControlName="eoc_survey_direction">
                          <mat-option *ngFor="let derection of callDirections" [value]="derection.value">
                            {{ derection.name | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div fxFlex="auto">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'call_queue.content.ivr_evaluation'|translate }}</mat-label>
                        <ivr-evaluation-select-form-input formControlName="eoc_ivr_menu"
                          [enabled]="!is_survey_direction_null"
                          [placeholder]="'call_queue.content.ivr_evaluation'|translate">
                        </ivr-evaluation-select-form-input>
                        <button mat-icon-button matSuffix type="button" data-ci="remove-eoc_ivr_menu"
                          [disabled]="is_survey_direction_null" (click)="removeControlValue('eoc_ivr_menu')"
                          [disabled]="!hasControlValue('eoc_ivr_menu')">
                          <mat-icon>close</mat-icon>
                        </button>

                        <mat-error [flexFormControlError]="form.get('eoc_ivr_menu')"
                          [customMessages]="{
                            'required': 'call_queue_edit.validators.ivr_evaluation_required'|translate
                          }"></mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div>
                    <mat-slide-toggle [(ngModel)]="enableEocSurveyDuration" [ngModelOptions]="{standalone: true}"
                      [disabled]="is_survey_direction_null" (ngModelChange)="changeEocSurveyDuration($event)"
                      data-ci="enable-eoc-survey-duration">
                      {{'call_queue.content.required_eoc_survey_min_duration'|translate}}
                    </mat-slide-toggle>
                    <div class="ml-1" [hidden]="!enableEocSurveyDuration" fxLayout="row" fxLayoutAlign="start center"
                      fxLayoutGap="30px">
                      <div fxFlex="initial">
                        <label
                          [attr.disabled]="is_survey_direction_null">{{'call_queue.content.eoc_survey_min_duration'|translate}}</label>
                      </div>
                      <div fxFlex="70px">
                        <mat-form-field class="full-width">
                          <input [attr.disabled]="is_survey_direction_null?'true':null" matInput type="number"
                            formControlName="eoc_survey_min_duration">

                          <mat-error [flexFormControlError]="form.get('eoc_survey_min_duration')"
                            [customMessages]="{
                              'greater_than_0': 'form.validator.field_greater_min_value'|translate: {min: 0}
                            }"></mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none" anchorPart>
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">note_add</i></div>
                <mat-card-title>{{ 'call_queue.content.call_tagging'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.section.call_tagging_hint'|translate }} </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p *ngIf="!hasValidQualifications; else hasQualificationsBlock" class="create_qualification"
                  [innerHTML]="'call_queue.content.hint_create_qualification'|translate">
                </p>
                <ng-template #hasQualificationsBlock>
                  <div fxLayout="column">
                    <div class="has-qualification">
                      <mat-slide-toggle formControlName="has_qualification" class="full-width">
                        {{ 'call_queue.content.active_evaluation'|translate }}
                      </mat-slide-toggle>
                      <span *ngIf="form.get('has_qualification').invalid" class="mat-text-warn">{{
                        'call_queue.errors.qualification_required'|translate }}</span>
                    </div>
                    <div class="qualification-required mt-1"
                      *ngIf="hasValidQualifications && hasQualificationControl.value">
                      <mat-slide-toggle formControlName="qualification_required">
                        <span class="fontfamily-bold">
                          {{ 'call_queue.content.qualification_required'|translate }}
                        </span>
                      </mat-slide-toggle>
                    </div>
                  </div>
                  <div fxLayout="column" *ngIf="hasValidQualifications && hasQualificationControl.value">
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
                      <div fxFlex fxFlex.xs="none">
                        <mat-form-field class="full-width">
                          <mat-label>{{ 'call_queue.content.in_qualification'|translate }}</mat-label>
                          <qualification-select-form-input formControlName="in_qualification"
                            placeholder="{{ 'call_queue.content.in_qualification'|translate }}">
                          </qualification-select-form-input>
                        </mat-form-field>
                      </div>
                      <div fxFlex fxFlex.xs="none">
                        <mat-form-field class="full-width">
                          <mat-label>{{ 'call_queue.content.out_qualification'|translate }}</mat-label>
                          <qualification-select-form-input formControlName="out_qualification"
                            placeholder="{{ 'call_queue.content.out_qualification'|translate }}">
                          </qualification-select-form-input>
                        </mat-form-field>
                      </div>
                    </div>

                    <mat-error 
                      [ngClass]="{'qualification-error-onerequeried': inQualificationControl.hasError('oneRequired')}" 
                      [flexFormControlError]="inQualificationControl"
                      [customMessages]="{
                        'oneRequired': 'call_queue.validator.qualification_required'|translate
                      }"></mat-error>
                  </div>
                </ng-template>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none" anchorPart>
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">phone_callback</i></div>
                <mat-card-title>{{ 'call_queue_edit.section.request_callback'|translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="10px">
                  <div>
                    <mat-slide-toggle formControlName="request_callback_enable" class="full-width"
                      data-ci="request-callback-enable">
                      {{ 'call_queue.content.active_request_callback'|translate }}
                    </mat-slide-toggle>
                  </div>
                  <div *ngIf="form.get('request_callback_enable').value" fxLayout="column" fxLayoutGap="20px">
                    <div>
                      <mat-form-field class="full-width">
                        <label mat-label>{{ 'call_queue.content.request_callback_after'|translate }}</label>
                        <flex-duration-input formControlName="request_callback_after"></flex-duration-input>

                        <mat-error [flexFormControlError]="form.get('request_callback_after')"
                          [customMessages]="{
                            'min_time': 'call_queue.content.min_time'|translate
                          }"></mat-error>

                        <button mat-icon-button matSuffix type="button" (click)="removeRequestCallbackAfter()"
                          [disabled]="!hasControlValue('request_callback_after')">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>

                    <div fxLayout="column">
                      <sound-upload fxFlex class="wrapper-sound-upload" data-ci="request-callback-annon-sound"
                        [fileEntry]="form.get('request_callback_annon_sound').value"
                        title="{{'call_queue.content.request_callback_annon_sound'|translate}}" [hideRemove]="false"
                        (onChange)="updateSoundFile('request_callback_annon_sound', $event)">
                      </sound-upload>
                      <span class="mat-error" *ngIf="form.get('request_callback_annon_sound').hasError('required')">
                        {{ 'form.validator.field_required' | translate }}
                      </span>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                      <div fxFlex="initial">
                        <span>{{ 'call_queue.content.request_callback_key'|translate }}</span>
                      </div>
                      <div fxFlex="100px">
                        <mat-form-field class="full-width">
                          <mat-select formControlName="request_callback_key" data-ci="request-callback-key">
                            <mat-option *ngFor="let pressKey of pressKeys" [value]="pressKey.value">
                              {{ pressKey.label|translate }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="column">
                      <sound-upload fxFlex class="wrapper-sound-upload" data-ci="request-callback-sound"
                        [fileEntry]="form.get('request_callback_sound').value"
                        title="{{'call_queue.content.request_callback_sound'|translate}}" [hideRemove]="false"
                        (onChange)="updateSoundFile('request_callback_sound', $event)">
                      </sound-upload>
                      <span class="mat-error" *ngIf="form.get('request_callback_sound').hasError('required')">
                        {{ 'form.validator.field_required' | translate }}
                      </span>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                      <div fxFlex="initial">
                        <span>{{ 'call_queue.content.request_callback_confirm_key'|translate }}</span>
                      </div>
                      <div fxFlex="100px">
                        <mat-form-field class="full-width">
                          <mat-select formControlName="request_callback_confirm_key"
                            data-ci="request-callback-confirm-key">
                            <mat-option *ngFor="let pressKey of pressConfirmKeys" [value]="pressKey.value">
                              {{ pressKey.label|translate }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="column" *ngIf="form.get('request_callback_confirm_key').value">
                      <sound-upload fxFlex class="wrapper-callback-confirmation-sound"
                        data-ci="request_callback_confirmation_sound"
                        [fileEntry]="form.get('request_callback_confirmation_sound').value"
                        title="{{'call_queue.content.request_callback_confirmation_sound'|translate}}"
                        [hideRemove]="false"
                        (onChange)="updateSoundFile('request_callback_confirmation_sound', $event)">
                      </sound-upload>
                      <span class="mat-error"
                        *ngIf="form.get('request_callback_confirmation_sound').hasError('required')">
                        {{ 'form.validator.field_required' | translate }}
                      </span>
                    </div>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="my-1">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

          <div class="block-wrapper" id="_3application" anchorPart>
            <h2>{{ 'call_queue_edit.section.application'|translate }}</h2>
            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">open_in_browser</i></div>
                <mat-card-title>{{ 'call_queue_edit.content.action_backoffice'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.content.action_backoffice_hint'|translate }} </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content class="padded-content">
                <mat-button-toggle-group fxLayout="row" formControlName="bo_type" class="button-multiple-choice"
                  (change)="changeBoType()">
                  <mat-button-toggle fxFlex="50" [value]="backOfficeType.NULL" class="button-multiple-choice">
                    {{'call_queue.content.open_none'|translate}}
                  </mat-button-toggle>
                  <mat-button-toggle fxFlex="50" [value]="backOfficeType.URL" class="button-multiple-choice">
                    {{'call_queue.content.open_url'|translate}}
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <div fxLayout="column" *ngIf="boTypeControl.value == backOfficeType.URL">
                  <div fxLayout="row" fxLayoutGap="20px" class="mt-1">
                    <div fxFlex="100px">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'call_queue.content.bo_method'|translate }}</mat-label>
                        <mat-select formControlName="bo_method">
                          <mat-option value="GET">GET</mat-option>
                          <mat-option value="POST">POST</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div fxFlex="130px">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'call_queue.content.bo_open_derection'|translate }}</mat-label>
                        <mat-select formControlName="bo_open_derection">
                          <mat-option *ngFor="let direction of callDirections" [value]="direction.value">
                            {{ direction.name | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div fxFlex="auto">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'call_queue.content.bo_url'|translate }}</mat-label>
                        <input #boUrlInput matInput formControlName="bo_url"
                          placeholder="{{ 'call_queue.content.bo_url'|translate }}">
                          
                          <mat-error [flexFormControlError]="boUrlControl"></mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">phone_forwarded</i></div>
                <mat-card-title>{{ 'call_queue_edit.content.transfer_numbers'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.content.transfer_numbers_hint'|translate }} </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content class="padded-content">
                <div fxLayout="column" formArrayName="transfer_numbers">
                  <ng-container *ngFor="let transferNumberControl of transferNumbersControl.controls; let i=index;">
                    <div [formGroupName]="i" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
                      fxLayoutAlign.xs="none" fxLayoutGap="10px" fxLayoutGap.xs="0px" class="mb-1">
                      <div fxFlex fxFlex.xs="none">
                        <mat-form-field class="full-width">
                          <mat-label>{{ 'call_queue.content.transfer_number.label'|translate }}</mat-label>
                          <input type="text" matInput formControlName="label"
                            placeholder="{{ 'call_queue.content.transfer_number.label'|translate }}" [required]="true">
                          
                          <mat-error [flexFormControlError]="transferNumberControl.controls.label"></mat-error>
                          
                        </mat-form-field>
                      </div>
                      <div fxFlex fxFlex.xs="none">
                        <mat-form-field class="full-width">
                          <mat-label>{{ 'call_queue.content.transfer_number.number'|translate }}</mat-label>
                          <input type="text" matInput formControlName="phone_number"
                            placeholder="{{ 'call_queue.content.transfer_number.number'|translate }}" [required]="true">
                          
                          <mat-error [flexFormControlError]="transferNumberControl.controls.phone_number"></mat-error>
                        </mat-form-field>
                      </div>
                      <div fxFlex="50px" fxFlex.xs="none">
                        <button type="button" mat-icon-button (click)="removeTransferNumber(i)"
                          title="{{ 'public.remove'|translate}}" class="mat-text-warn">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <div fxFlex>
                    <button mat-stroked-button color="green" (click)="addTransferNumber()" type="button">
                      <mat-icon class="icon-add">add</mat-icon> <span>
                        {{ 'call_queue.content.transfer_number.add'|translate }}</span>
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="my-1">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

          <div class="block-wrapper" id="_4phone_number" anchorPart>
            <h2>{{ 'call_queue_edit.section.phone_numbers'|translate }}</h2>
            <mat-card class="shadow-none">
              <mat-card-content class="padded-content">
                <ng-container *ngIf="usedDidList.length;else NoUsedDid">
                  <p>
                    <a [attr.data-ci]="'link-update-did-routing-params'" class="flex-link" (click)="updateDidList()">
                      {{ 'call_queue_edit.content.update_did_number_list'|translate }}
                    </a>
                  </p>

                  <div fxLayout="row wrap">
                    <div [attr.data-ci]="'queue-in-used-did'" class="inused-item" *ngFor="let did of usedDidList">
                      <app-did-mini-block [did]="did"></app-did-mini-block>
                    </div>
                  </div>
                </ng-container>

                <ng-template #NoUsedDid>
                  <span>{{ 'did.content.no_routed_phone_number'|translate }}</span>
                </ng-template>
              </mat-card-content>
            </mat-card>
          </div>


          <div class="my-1">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

          <div class="block-wrapper" id="_5advanced" anchorPart>
            <h2>{{ 'call_queue_edit.section.advanced'|translate }}</h2>
            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">pause_presentation</i></div>
                <mat-card-title>{{ 'call_queue.content.holding_file'|translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="padded-content">
                <div fxLayout="column">
                  <sound-upload class="wrapper-sound-upload" defaultSound="music_on_hold"
                    [attr.data-ci]="'sound-upload-music-on-hold'" [fileEntry]="form.get('moh_file').value"
                    title="{{'call_queue.content.holding_file'|translate}}"
                    (onChange)="updateSoundFile('moh_file', $event)">
                  </sound-upload>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">call</i></div>
                <mat-card-title>{{ 'call_queue.content.default_calling_number'|translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="padded-content">
                <div fxLayout="column">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'call_queue.content.default_calling_number'|translate }}</mat-label>
                    <did-select-form-input formControlName="default_did" [onlyConfigured]="true"
                      [allowAnonymous]="hasAnonymous">
                    </did-select-form-input>
                    <mat-hint>{{ 'did.title.calling_number_hint'|translate }}</mat-hint>
                    <button mat-icon-button matSuffix type="button" (click)="removeControlValue('default_did')"
                      [disabled]="!hasControlValue('default_did')">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">list</i></div>
                <mat-card-title>{{ 'call_queue_edit.section.advanced_attributes'|translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="padded-content">
                <div *ngIf="hasFeature('SERVICE_GROUP')">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'call_queue.content.group'|translate }}</mat-label>
                    <usergroup-select-form-input formControlName="group" [enabled]="isAdmin"
                      [placeholder]="'call_queue.content.group'|translate">
                    </usergroup-select-form-input>
                    <button mat-icon-button matSuffix type="button" (click)="removeControlValue('group')"
                      [disabled]="!hasControlValue('group') || !isAdmin">
                      <mat-icon>close</mat-icon>
                    </button>

                    <mat-error [flexFormControlError]="groupControl"></mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'call_queue.content.priority'|translate }}</mat-label>
                    <mat-select formControlName="queue_priority"
                      placeholder="{{ 'call_queue.content.priority'|translate }}">
                      <mat-option *ngFor="let priority of priorityList" [value]="priority.value">{{ priority.label|translate }}
                      </mat-option>
                    </mat-select>
                    
                    <mat-error [flexFormControlError]="queuePriorityControl"></mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'call_queue.content.alias' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'call_queue.content.alias' | translate }}" formControlName="alias">
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">sim_card</i></div>
                <mat-card-title>{{ 'call_queue_edit.content.agent_displayed_number_title'|translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'call_queue_edit.content.agent_displayed_number_title_hint'|translate }}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content class="padded-content">
                <div fxLayout="row" fxLayout.xs="column">
                  <div fxFlex>
                    <mat-form-field fxFlex>
                      <mat-label>{{'call_queue.content.display_number.agent_display_number_type'|translate}}</mat-label>
                      <mat-select formControlName="agent_display_number_type">
                        <mat-option *ngFor="let i of agentDisplayNumberTypes" [value]="i.value">{{ i.label|translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="pl-1" fxFlex
                    *ngIf="agentDisplayNumberTypeControl.value && agentDisplayNumberTypeControl.value === agentDisplayNumberDidNumber">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'call_queue.content.display_number.displayed_number'|translate }}</mat-label>
                      <did-select-form-input formControlName="agent_display_number"
                        placeholder="{{'call_queue.content.display_number.displayed_number'|translate}}">
                      </did-select-form-input>
                      
                      <mat-error [flexFormControlError]="agentDisplayNumberControl"></mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">trending_down</i></div>
                <mat-card-title>{{'call_queue_edit.section.estimated_wait_time'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="section-content">
                <div fxLayout="column">
                  <mat-slide-toggle formControlName="ewt_enable">
                    {{ 'call_queue_edit.content.enable_ewt'|translate }}
                  </mat-slide-toggle>
                  <app-queue-estimated-wait-time #queueEstimatedWaitTime [hidden]="!form.get('ewt_enable').value"
                    [queue]="queue" (onValueChange)="updateEwtData($event)">
                  </app-queue-estimated-wait-time>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">stars</i></div>
                <mat-card-title>{{'call_queue.content.exclusive.title'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="section-content">
                <div fxLayout="column">
                  <mat-slide-toggle formControlName="is_exclusive">
                    {{ 'call_queue.content.exclusive.is_exclusive'|translate }}
                  </mat-slide-toggle>

                  <mat-error *ngIf="form.get('is_exclusive').errors" [flexFormControlError]="form.get('is_exclusive')"
                    [customMessages]="{
                      'agent_inused': form.get('is_exclusive').errors.agent_inused
                    }"></mat-error>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="shadow-none" *ngIf="enableCallAnalysis">
              <mat-card-header>
                <div mat-card-avatar><i class="timeline-icon material-icons">insights</i></div>
                <mat-card-title>{{'call_queue.content.analysis.title'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="section-content">
                <div fxLayout="column">
                  <div class="pt-0">
                    <mat-slide-toggle formControlName="analysis_enabled" [attr.data-ci]="'analysis_enabled'">
                      <span>{{'call_queue.content.analysis.analysis_enabled'|translate}}</span>
                    </mat-slide-toggle>
                  </div>

                  <mat-form-field class="pt-0" [hidden]="!form.get('analysis_enabled').value">
                    <mat-label>{{ 'call_queue.content.analysis.analysis_start_dt'|translate }}</mat-label>

                    <input matInput [matDatepicker]="startPicker" formControlName="analysis_start_dt"
                      placeholder="{{ 'call_queue.content.analysis.analysis_start_dt' | translate }}">

                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                    
                    <mat-error [flexFormControlError]="form.get('analysis_start_dt')"></mat-error>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
