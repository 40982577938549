import { ILogger } from './jsphonelib-types';

var _logger: ILogger;

export function debug(msg: string, ...params:any[]) {
    if (_logger) {
        _logger.debug(msg, ...params);
    }
}

export function info(msg: string, ...params:any[]) {
    if (_logger) {
        _logger.info(msg, ...params);
    }
}

export function warn(msg: string, ...params:any[]) {
    if (_logger) {
        _logger.warn(msg, ...params);
    }
}

export function error(msg: string, ...params:any[]) {
    if (_logger) {
        _logger.error(msg, ...params);
    }
}

export function getLogger(): ILogger {
    return _logger;
}

export function setLogger(l: ILogger) {
    _logger = l;
}
