import { EntityManager } from '@wephone-core/service/entity_manager';
import { IEntity, IRepository } from '@wephone-core/model/model.interface';
import * as _ from 'lodash';

export function datafield(target: any, name: string) {
  if (!target.data_fields) {
    target.data_fields = {};
  }
  target.data_fields[name] = null;
}

export function mapped_datafield(field_name: string) {
  return function(target: any, name: string) {
    if (!target.data_fields) {
      target.data_fields = {};
    }
    target.data_fields[name] = field_name;
  };
}

export class Entity implements IEntity {
  static object_type_id: string;
  protected data_fields: { [id: string]: string };
  protected object_type_id: string = null;
  protected id: number;
  private repository: IRepository = null;

  getObjectType(): string {
    return this.object_type_id;
  }

  getRepository(): IRepository {
    return EntityManager.getInstance().getRepositoryById(Object.getPrototypeOf(this).constructor.object_type_id);
  }

  getId(): number {
    return this.id;
  }

  getIntId(): number {
    return this.id;
  }

  getEnterpriseId(): number {
    if (this['enterprise_id']) {
      return this['enterprise_id'];
    }
    return null;
  }

  setObjectData(object_data: any, fetch_related_data = true): void {
    for (const field_name in this.data_fields) {
      if (this.data_fields.hasOwnProperty(field_name)) {
        delete this[field_name];
      }
    }
    if (object_data) {
      try {
        _.extend(this, object_data);
      } catch (e) {
        console.error('Error while trying to extend object data ', e);
      }
      // Use field_name if mapped_datafield is provided
      for (const field_name in this.data_fields) {
        if (this.data_fields.hasOwnProperty(field_name)) {
          const column_name = this.data_fields[field_name];

          if (column_name != null) {
            this[field_name] = object_data[column_name];
          }
        }
      }

      if (fetch_related_data) {
        this.fetchRelatedData();
      }
    }
  }

  dumpObjectData(field_list: Array<string> = null, use_mapped_name: boolean = false): any {
    const ret = { id: this.getId() };
    let attr_dict = this.data_fields;
    if (field_list) {
      attr_dict = {};
      for (const k of field_list) {
        attr_dict[k] = k;
      }
    }
    for (const field_name of Object.keys(attr_dict)) {
      if (attr_dict.hasOwnProperty(field_name)) {
        const data = EntityManager.getInstance().dumpObjectData(this[field_name]);
        const params = [field_name];
        if (this.data_fields[field_name] && use_mapped_name === true) {
          params.push(this.data_fields[field_name]);
        }
        // Request both of data_column & data_field
        for (const p of params) {
          ret[p] = data;
        }
      }
    }
    return ret;
  }

  fetchRelatedData() {}

  updateList(old_list, new_list): void {
    for (let i: number = old_list.length - 1; i >= 0; i--) {
      let found = false;
      for (let j = 0; j < new_list.length; j++) {
        if (old_list[i].id === new_list[j].id) {
          found = true;
          break;
        }
      }
      if (!found) {
        old_list.splice(i, 1);
      }
    }
    for (let i = 0; i < new_list.length; i++) {
      let found = false;
      for (let j = 0; j < old_list.length; j++) {
        if (old_list[j].id === new_list[i].id) {
          found = true;
          break;
        }
      }
      if (!found) {
        old_list.push(new_list[i]);
      }
    }
  }

  exportData(): any {
    return this.dumpObjectData(null, true);
  }
}
