<div fxLayout="column" fxFill class="flex-container" *ngIf="is_ready">
  <form [formGroup]="filterForm">
    <period-select [startDate]="startDateControl.value" [endDate]="endDateControl.value"
      (changedPeriod)="changePeriodFilter($event)"></period-select>
    <div class="pb-1" *ngIf="filtersReadonly; else BlockFilterForm" align="center">
      <div fxLayout="column">
        <span *ngIf="startDateControl.value && endDateControl.value">
          {{ startDateControl.value|timeFormatDateShort }} -->
          {{ endDateControl.value|timeFormatDateShort }}
        </span>
        <span *ngIf="(filterForm.get('queue_ids').value || []).length">
          <strong>{{ 'stats.filter.queue'|translate }}</strong>: {{
          rendererQueue(filterForm.get('queue_ids').value)|truncateArray }}
        </span>
        <span *ngIf="(filterForm.get('ivrmenu_ids').value || []).length">
          <strong>{{ 'stats.filter.ivrmenu'|translate }}</strong>: {{
          rendererIvrMenu(filterForm.get('ivrmenu_ids').value)|truncateArray }}
        </span>
        <span *ngIf="(filterForm.get('called_number_ids').value || []).length">
          <strong>{{ 'stats.filter.called_numbers'|translate }}</strong>:
          {{ rendererCalledNumbers(filterForm.get('called_number_ids').value)|truncateArray }}
        </span>
        <span *ngIf="filterForm.get('phone_number').value">
          <strong>{{ 'stats.missed_call.filter.phone_number'|translate }}</strong>: {{
          filterForm.get('phone_number').value }}
        </span>
        <span *ngIf="filterForm.get('recall_status').value">
          <strong>{{ 'stats.filter.select_missed_call_status'|translate }}</strong>: {{ ('stats.missed_call.status.' +
          filterForm.get('recall_status').value)|translate }}
        </span>
        <span *ngIf="hasServiceGroup && filterForm.get('groups').value.length">
          <strong>{{ 'stats.filter.group'|translate }}</strong>:
          {{ rendererGroup(filterForm.get('groups').value)|truncateArray }}
        </span>
        <a class="link" (click)="changeFilter()">{{'public.change'|translate}}</a>
      </div>
    </div>
    <ng-template #BlockFilterForm>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="20px"
        fxLayoutGap.xs="0" fxLayoutGap.xs="0">
        <div fxFlex class="custom-form">
          <mat-form-field class="full-width">
            <mat-label>{{ 'public.from'|translate }}</mat-label>
            <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
              placeholder="{{ 'public.from'|translate }}">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error
              *ngIf="startDateControl.hasError('flexDatepickerMin') || startDateControl.hasError('required') && startDateControl.touched">
              {{ 'form.validator.required'|translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex class="custom-form">
          <mat-form-field class="full-width">
            <mat-label>{{ 'public.to'|translate }}</mat-label>
            <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
              [matDatepickerFilter]="minEndDateFilter" placeholder="{{ 'public.to'|translate}}">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="endDateControl.hasError('required') && endDateControl.touched">
              {{ 'form.validator.required'|translate }}
            </mat-error>
            <mat-error
              *ngIf="endDateControl.hasError('flexDatepickerMin') || endDateControl.hasError('matDatepickerFilter')">
              {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
              startDateControl.value | timeFormatDateShort} }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="full-width">
            <mat-label>{{ 'stats.filter.select_missed_call_status' | translate }}</mat-label>
            <mat-select formControlName="recall_status"
              [placeholder]="'stats.filter.select_missed_call_status'|translate">
              <ng-container *ngFor="let recallStatus of missedCallStatusList">
                <mat-option [value]="recallStatus">{{ ('stats.missed_call.status.' + recallStatus)|translate }}
                </mat-option>
              </ng-container>
            </mat-select>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'recall_status', false)"
              [disabled]="!filterForm.get('recall_status').value">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="20px"
        fxLayoutGap.xs="0" class="text-center">
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'stats.filter.select_queue' | translate }}</mat-label>
            <flex-select-queue-input [multiple]="true" [showCheckAll]="true" queueType="inbound"
              [filteredIds]="filteredQueueIds"
              placeholder="{{ 'stats.filter.select_queue' | translate }}" formControlName="queue_ids">
            </flex-select-queue-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'queue_ids', true)"
              [disabled]="!filterForm.get('queue_ids').value || !filterForm.get('queue_ids').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'stats.filter.select_ivrmenu' | translate }}</mat-label>
            <flex-select-menu-input [menuType]="0" [isConfigured]="true" [multiple]="true" [showCheckAll]="true"
              placeholder="{{ 'stats.filter.select_ivrmenu' | translate }}" formControlName="ivrmenu_ids">
            </flex-select-menu-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'ivrmenu_ids', true)"
              [disabled]="!filterForm.get('ivrmenu_ids').value || !filterForm.get('ivrmenu_ids').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'call_queue.content.called_number' | translate }}</mat-label>
            <flex-select-did-input [multiple]="true" [showCheckAll]="true"
              placeholder="{{ 'stats.filter.select_did' | translate }}" formControlName="called_number_ids">
            </flex-select-did-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'called_number_ids', true)"
              [disabled]="!filterForm.get('called_number_ids').value || !filterForm.get('called_number_ids').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start" fxLayoutGap="20px"
        fxLayoutGap.xs="0" class="text-center">
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'stats.missed_call.filter.phone_number' | translate }}</mat-label>
            <input matInput formControlName="phone_number"
              placeholder="{{ 'stats.missed_call.filter.phone_number'|translate }}">
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'phone_number', false)"
              [disabled]="!filterForm.get('phone_number').value">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxFlex *ngIf="hasServiceGroup">
          <mat-form-field class="full-width flex-select">
            <mat-label>{{ 'stats.filter.select_group' | translate }}</mat-label>
            <flex-select-group-input [limitedByRole]="true" [multiple]="true" [showCheckAll]="true" [hasAgentOrQueueOrDid]="true"
              [filteredIds]="filteredGroupIds"
              groupType="service" placeholder="{{ 'stats.filter.select_group' | translate }}" formControlName="groups">
            </flex-select-group-input>
            <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'groups', true)"
              [disabled]="!filterForm.get('groups').value || !filterForm.get('groups').value.length">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
          <div class="pl-1" align="left">
            <mat-checkbox formControlName="recall_request_status">
              {{'stats.filter.select_recall_request_status'|translate}}
            </mat-checkbox>
          </div>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
          <div class="pl-1" align="left">
            <mat-checkbox formControlName="out_of_calendar">
              {{'stats.filter.display_calls_out_of_calendar'|translate}}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div>
        <mat-checkbox formControlName="only_owner" *ngIf="hasServiceGroup">
          {{'stats.filter.display_his_own_calls'|translate}}
        </mat-checkbox>
      </div>
      <div align="center" class="my-1">
        <button mat-raised-button color="accent" (click)="applyFilter()">{{ 'public.apply' | translate }}</button>
      </div>
    </ng-template>
  </form>

  <flex-table #flexTable fxFlex="grow" [dataSource]="dataSource" [tableConfig]="tableConfig" [columnTemplates]="{
      dest_name: tplDestName,
      start_dt: tplStartDate,
      recall_dt: tplRecallDate,
      recall_time: tplRecallTime,
      status: tplStatus,
      sound_id: tplSoundPlayer,
      action: tplAction,
      recall_request_status: tplRecallRequestStatus
    }" [expandedDetailTemplate]="tplExpandedDetailTemplate">

    <ng-template #tplStartDate let-item="row">
      {{ item.start_dt|timeFormatDateTimeShort }}
    </ng-template>

    <ng-template #tplDestName let-item="row">
      <span class="inline-icon">
        <ng-container [ngSwitch]="item.dest_type">
          <mat-icon *ngSwitchCase="2">notes</mat-icon>
          <mat-icon *ngSwitchCase="3">swap_horiz</mat-icon>
          <mat-icon *ngSwitchDefault>phone_missed</mat-icon>
        </ng-container>
        {{ getDestName(item) }}
      </span>
    </ng-template>

    <ng-template #tplRecallDate let-item="row">
      {{ item.recall_dt|timeFormatDateTimeShort }}
    </ng-template>

    <ng-template #tplRecallTime let-item="row">
      <div class="recall-time-wrapper">
        <ng-container *ngIf="shouldDisplayRecall(item)">
          <ng-container *ngIf="item.recall_time; else asapBlock">
            {{ item.recall_time|timeFormatDateTimeShort }}
          </ng-container>
          <ng-template #asapBlock>
            ASAP
          </ng-template>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #tplRecallRequestStatus let-item="row">
      <div class="recall-time-wrapper">
        <mat-icon *ngIf="item.recall_request_status === 1">notification_important</mat-icon>
      </div>
    </ng-template>

    <ng-template #tplStatus let-item="row">
      {{ displayStatus(item) }}
      <span *ngIf="item.recall_count">&nbsp;(x{{item.recall_count}})</span>
    </ng-template>

    <!-- <ng-template #tplHandled let-item="row">
      {{ displayAgent(item.last_outbound_user_id) }}
    </ng-template> -->

    <!-- <ng-template #tplAssignee let-item="row">
      {{ displayAgent(item.owner_id) }}
    </ng-template> -->

    <ng-template #tplSoundPlayer let-item="row">
      <ng-container *ngIf="item.file_entry_ready">
        <small class="mat-text-warn" *ngIf="!item.is_read">{{ 'stats.missed_call.message_new'|translate }}</small>
        <fileentry-player-button *ngIf="item.sound_id" [filePublicId]="item.sound_id" lang=""
          (onPlay)="updateListened(item)"></fileentry-player-button>
      </ng-container>
      <span *ngIf="item.status === 'recall_success' && item.qualification" [matTooltip]="item.qualification_comment">
        {{ displayQualification(item.qualification) }}
      </span>
    </ng-template>

    <ng-template #tplAction let-item="row">
      <div class="cell-actions">
        <button mat-icon-button color="primary" (click)="recallMissedCall(item.last_inbound_cdr_id, $event)"
          [disabled]="!item.calling_number || item.calling_number==='anonymous'" title="{{ 'public.button.call_now'|translate }}">
          <mat-icon>phone</mat-icon>
        </button>
        <button mat-icon-button color="default" (click)="detailMissedCall(item, $event)"
          title="{{ 'stats.missed_call.actions.detail_call_info'|translate }}">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </ng-template>

    <ng-template #tplExpandedDetailTemplate let-row="row">
      <div class="full-width" *ngIf="row.data">
        <missed-call-expand [data]="row.data"></missed-call-expand>
      </div>
    </ng-template>
  </flex-table>
</div>
