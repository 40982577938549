<div fxFill fxLayout="column">
  <div class="wrapper">

    <div class="col-left" [fxHide.xs]="true">
      <ul class="stepper stepper-vertical">
        <li *ngFor="let step of stepKeys; let i=index;">
          <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
            [ngClass]="{'active': mainArea.activeAnchor === step}">
            <span class="circle">{{ i+1 }}</span>
            <span class="label">{{ steps[step] | translate }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner" *ngIf="qualForm">
        <div class="block-wrapper" id="form" anchorPart>
          <ng-container *ngTemplateOutlet="formEdit"></ng-container>
        </div>

        <div class="my-2">
          <mat-divider></mat-divider>
        </div>

        <div class="block-wrapper" id="inused" anchorPart>
          <ng-container *ngTemplateOutlet="inUsed"></ng-container>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #formEdit>
  <h4 class="title fontfamily-bold ma-0">
    <span><i class="timeline-icon material-icons">info</i></span>
    <span class="title-text">{{ 'opening_hour_calendar.content.information' | translate }}</span>
  </h4>
  <mat-card class="mat-card-flat shadow-none py-0">
    <mat-card-content>
      <form [formGroup]="qualForm">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutALign="start center" fxLayoutAlign.xs="start"
          fxLayoutGap="10px" fxLayoutALignGap.xs="0">
          <div fxFlex="calc(50% - 10px)">
            <mat-form-field class="full-width">
              <mat-label>{{ 'call_qualification.content.name' | translate }}</mat-label>
              <input type="text" matInput placeholder="{{ 'call_qualification.content.name' | translate }} *"
                formControlName="name">
              <mat-error *ngIf="nameControl.hasError('required')">
                {{'form.validator.field_required'|translate}}
              </mat-error>
              <mat-error *ngIf="nameControl.hasError('duplicated')">
                {{'public.message.name_exist'|translate}}
              </mat-error>
              <mat-error *ngIf="nameControl.hasError('maxlength')">
                {{'public.message.label_max_length_255'|translate}}
              </mat-error>
              <mat-error *ngIf="nameControl.hasError('whitespace')">
                {{'public.message.data_invalid'|translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50% - 10px)" *ngIf="hasFeature('SERVICE_GROUP')">
            <mat-form-field class="full-width">
              <mat-label>{{ 'call_qualification.content.group_name' | translate }}</mat-label>
              <mat-select placeholder="{{ 'call_qualification.content.group_name' | translate }}"
                formControlName="group">
                <mat-option>{{ 'public.empty' | translate }}</mat-option>
                <mat-option *ngFor="let group of groups" [value]="group">
                  {{ group.name }}
                </mat-option>
              </mat-select>
              <button mat-icon-button matSuffix type="button" (click)="clearValue('group')"
                [disabled]="!qualForm.get('group').value">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div>
        <button mat-stroked-button class="add" color="primary" *ngIf="nodes.length === 0" (click)="addFirstNode()">
          <mat-icon>add</mat-icon>
          {{ 'call_qualification.content.add_first_branch' | translate }}
        </button>
      </div>
      <div *ngIf="nodes.length" fxLayout="row" fxLayoutALign="start center" class="qual-action__container">
        <div class="button-row">
          <button mat-stroked-button color="primary" (click)="tree.treeModel.expandAll()">
            <mat-icon>expand_more</mat-icon>
            {{ 'skill.tree.expand_all' | translate }}
          </button>
          <button mat-stroked-button color="warn" (click)="tree.treeModel.collapseAll()">
            <mat-icon>expand_less</mat-icon>
            {{ 'skill.tree.collapse_all' | translate }}
          </button>
        </div>
        <span fxFlex></span>
        <button mat-raised-button color="warn" (click)="testQualification()">
          <mat-icon>check</mat-icon>
          {{ 'call_qualification.content.test' | translate}}
        </button>
      </div>

      <tree-root #tree [nodes]="nodes" [options]="treeOptions" (updateData)="onUpdateData($event)"
        (moveNode)="onMoveNode($event)">
        <ng-template #treeNodeWrapperTemplate let-node let-index="index">
          <tree-node-checkbox *ngIf="node.options.useCheckbox" [node]="node"></tree-node-checkbox>
          <ng-container *ngIf="node.hasChildren">
            <button mat-icon-button [class.toggle-children-wrapper-expanded]="node.isExpanded"
              [class.toggle-children-wrapper-collapsed]="node.isCollapsed" class="toggle-children-wrapper"
              (click)="node.mouseAction('expanderClick', $event)">
              <span class="toggle-children"></span>
            </button>
          </ng-container>
          <div class="node-content-wrapper" [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-focused]="node.isFocused" (click)="node.mouseAction('click', $event)"
            (dblclick)="node.mouseAction('dblClick', $event)" (contextmenu)="node.mouseAction('contextMenu', $event)"
            (treeDrop)="node.onDrop($event)" (treeDropDragOver)="node.mouseAction('dragOver', $event)"
            (treeDropDragLeave)="node.mouseAction('dragLeave', $event)"
            (treeDropDragEnter)="node.mouseAction('dragEnter', $event)" [treeAllowDrop]="node.allowDrop"
            [treeDrag]="node" [treeDragEnabled]="node.allowDrag()">

            <ng-template #treeNodeTemplate let-node let-index="index">
              <div class="node__container">
                <span *ngIf="node?.children?.length === 0">
                  <button mat-icon-button [color]="node.data.is_goal ? 'warn' : 'primary'">
                    <mat-icon>assignment</mat-icon>
                  </button>
                </span>
                <span class="node-name___text">
                  {{ node.data.name }}
                </span>
                <div class="button-action__container">
                  <button mat-icon-button (click)="editNode($event, node)" matTooltip="{{ 'public.edit' | translate }}">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" *ngIf="!node.data.is_goal" (click)="toggleGoal($event, node)"
                    matTooltip="{{ 'call_qualification.content.turn_is_goal_on' | translate }}">
                    <mat-icon>highlight_off</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" *ngIf="node.data.is_goal" (click)="toggleGoal($event, node)"
                    matTooltip="{{ 'call_qualification.content.turn_is_goal_off' | translate }}">
                    <mat-icon>adjust</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="appendSiblingNode($event, node)"
                    matTooltip="{{ 'public.append_sibling' | translate }}">
                    <mat-icon>add</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="appendChildNode($event, node)"
                    matTooltip="{{ 'public.append_child' | translate }}">
                    <mat-icon>subdirectory_arrow_left</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="deleteNode($event, node)"
                    matTooltip="{{ 'public.remove' | translate }}">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>
            <tree-node-content [node]="node" [index]="index" [template]="treeNodeTemplate">
            </tree-node-content>
          </div>
        </ng-template>
      </tree-root>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #inUsed>
  <h4 class="title fontfamily-bold ma-0">
    <span><i class="timeline-icon material-icons">miscellaneous_services</i></span>
    <span class="title-text">{{ 'opening_hour_calendar.content.usage' | translate }}</span>
  </h4>
  <mat-card class="mat-card-flat shadow-none py-0">
    <mat-card-content>
      <!-- <h4 class="title fontfamily-bold">{{ 'opening_hour_calendar.content.usage' | translate }}</h4> -->
      <!-- <ng-container *ngIf="usedObjectList.length; else noItems">
    <div *ngIf="usedDidList.length" class="pb-1">
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="full-width">
        <div class="calendarbox" *ngFor="let did of usedDidList">
          <app-did-mini-block [did]="did"></app-did-mini-block>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noItems></ng-template> -->

      <ng-container *ngIf="usedObjectList.length; else noItems">
        <div *ngIf="usedQueueList.length" class="pb-1">
          <p [matTooltip]="usedQueueStr" matTooltipPosition="above" class="fontfamily-bold">
            {{ 'opening_hour_calendar.content.queue_inused'|translate }}</p>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="full-width">
            <!-- <mat-card [attr.data-ci]="'qualification-in-used-queue'" class="mat-card-flat agent-item inused-item"
              *ngFor="let queue of usedQueueList">
              <span class="name font-weight-bold" matTooltip="{{ queue.queue_name }}" matTooltipPosition="above">{{
                queue.queue_name }}&nbsp;</span>
            </mat-card> -->
            <div [attr.data-ci]="'qualification-in-used-queue'" 
              class="inused-item"
              *ngFor="let queue of usedQueueList">
              <app-queue-mini-block [callQueue]="queue"></app-queue-mini-block>
            </div>
            
          </div>
        </div>

        <div *ngIf="usedCampaignList.length" class="pb-1">
          <p [matTooltip]="usedCampaignStr" matTooltipPosition="above" class="fontfamily-bold">
            {{ 'call_qualification.content.campaign_inused'|translate }}</p>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="full-width">
            <!-- <mat-card [attr.data-ci]="'qualification-in-used-campaign'" class="mat-card-flat agent-item inused-item"
              *ngFor="let campaign of usedCampaignList">
              <span class="name font-weight-bold" matTooltip="{{ campaign.name }}" matTooltipPosition="above">{{
                campaign.name }}&nbsp;</span>
            </mat-card> -->
            
            <div [attr.data-ci]="'qualification-in-used-campaign'"
              *ngFor="let campaign of usedCampaignList"
              class="inused-item">
              <app-outcallcampaign-mini-block [campaign]="campaign"></app-outcallcampaign-mini-block>
            </div>
          </div>
        </div>

        <div *ngIf="usedDidList.length" class="pb-1">
          <p [matTooltip]="usedDidStr" matTooltipPosition="above" class="fontfamily-bold">
            {{ 'did.content.inused'|translate }}</p>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="full-width">
            <div [attr.data-ci]="'qualification-in-used-did'" class="calendarbox inused-item"
              *ngFor="let did of usedDidList">
              <app-did-mini-block [did]="did"></app-did-mini-block>
            </div>
          </div>
        </div>

      </ng-container>
      <ng-template #noItems>

      </ng-template>
    </mat-card-content>
  </mat-card>
</ng-template>
