import * as _ from 'lodash';
import { ArchivableRepository } from '@wephone-core/model/repository/archivable_repository';
import { ICallQueueRepository } from '@wephone-core/model/repository/callqueue.i';

export class CallQueueRepository extends ArchivableRepository implements ICallQueueRepository {
  ENTITY_CLASS = CallQueueEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'callcenter/queue';
  protected METHOD_PATH_RECORDING_ENABLE = 'enable_recording';
  protected METHOD_PATH_RECORDING_DISABLE = 'disable_recording';
  protected METHOD_PATH_CLONE = 'clone';
  protected METHOD_PATH_SET_AGENT_QUEUE_LIST = 'set_agent_list';
  protected METHOD_PATH_REMOVE_AGENT = 'remove_agent';

  private attr_general = [
    'id',
    'queue_name',
    'group_id',
    'max_inqueue_time',
    'after_call_time',
    'backup_phone',
    'voicemail_id',
    'voicemail_enabled',
    'recording_mode',
    'used_backup_phone',
    'bo_type',
    'bo_url',
    'greeting_file',
    'moh_file',
    'opening_hour_file',
    'logo_id',
    'has_qualification',
    'in_qualification_id',
    'out_qualification_id',
    'aftercall_pause_mode',
    'queue_priority',
    'seconds_before_backup',
    'opening_hour_calendar_id',
    'transfer_numbers',
    'languages'
  ];

  protected managed_queue_id_list = {};
  private emptyCallQueue: CallQueueEntity;

  constructor() {
    super();

    if (!this.emptyCallQueue) {
      this.emptyCallQueue = new CallQueueEntity();
      this.emptyCallQueue.id = null;
    }
  }

  protected getURLAction(action: string): string {
    switch (action) {
      case 'RECORDING_ENABLE':
        return this.getUrlV2(this.METHOD_PATH_RECORDING_ENABLE);
      case 'RECORDING_DISABLE':
        return this.getUrlV2(this.METHOD_PATH_RECORDING_DISABLE);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'UPDATE_ATTRS_BULK':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS_BULK);
      case 'CLONE':
        return this.getUrlV2(this.METHOD_PATH_CLONE);
      case 'SET_AGENT_QUEUE_LIST':
        return this.getUrlV2(this.METHOD_PATH_SET_AGENT_QUEUE_LIST);
      case 'REMOVE_AGENT':
        return this.getUrlV2(this.METHOD_PATH_REMOVE_AGENT);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  createAndSave(object_data?: any, extra_data?: any): Promise<ResponsedItem> {
    const obj = this.create(object_data || {});
    // obj.setObjectData({}); // Clear to prevent from posting redundant fields
    return this.save(obj, extra_data);
  }

  /**
   * get queues belong to group
   * @params: group_id:number
   * @params: include_children_group:boolean true|false
   * @params: queue_type:string inbound|outbound|undefined
   * @params: includeEmptyGroup:boolean
   */
  get_queues_by_group_id(
    group_id: number,
    include_children_group: boolean,
    queue_type?: 'inbound' | 'outbound' | undefined,
    includeEmptyGroup?: boolean
  ): CallQueueEntity[] {
    let group_ids = group_id ? [group_id] : [];
    if (include_children_group && group_id) {
      group_ids = EntityManager.getRepository<GroupRepository>('GroupRepository').getSubGroupIdsById(group_id, true);
    }

    const ret = [];
    for (const queue of this.object_list) {
      if (
        (
          !queue.group_id && includeEmptyGroup ||
          !queue.group_id && !group_id ||
          queue.group_id && _.includes(group_ids, queue.group_id)
        ) &&
        (
          !queue_type ||
          (queue_type === 'inbound' && !queue.is_outbound_campaign) ||
          (queue_type === 'outbound' && queue.is_outbound_campaign && queue.is_outcall_campaign)
        )
      ) {
        ret.push(queue);
      }
    }
    return ret;
  }

  /**
   * Get all queues by agent (queue list that contain agent)
   */
  getQueuesByAgentId(agent_id: number): CallQueueEntity[] {
    const queue_list = this.getObjectList();
    return queue_list.filter(queue => queue.contain_agent_id(agent_id)); // !queue.dedicated_user_id &&
  }

  getObjectListByIds(ids: number[]): CallQueueEntity[] {
    const queues = this.getObjectList();
    return queues.filter(x => ids.indexOf(x.getId()) !== -1); // !queue.dedicated_user_id &&
  }

  /**
   * Get inbound queues by agent (queue list that contain agent)
   */
  getInboundQueuesByAgentId(agent_id: number): CallQueueEntity[] {
    return this.getInboundQueues().filter(q => q.contain_agent_id(agent_id));
  }

  /**
   * Get all inbound queues
   */
  getInboundQueues = (): CallQueueEntity[] => {
    return this.object_list.filter(obj => !obj.is_outbound_campaign); // !obj.dedicated_user_id &&
  }

  getInboundQueuesManagedByUser(user: UserEntity): CallQueueEntity[] {
    const queues = this.getInboundQueues();
    return queues.filter((queue: CallQueueEntity): boolean => {
      if (!user || !(user.isAdmin() || queue.belong_to_groups(user.group_ids))) {
        return false;
      }
      return true;
    });
  }

  setAgentQueueList(agent_id, queue_ids: number[]): Promise<any> {
    const url = this.getURLAction('SET_AGENT_QUEUE_LIST');
    return HttpEngine.getInstance().post(url, { agent_id, queue_ids });
  }

  removeAgent(agent_id, queue_id): Promise<any> {
    const url = this.getURLAction('REMOVE_AGENT');
    return HttpEngine.getInstance().post(url, { agent_id, queue_id });
  }

  bulkChangeStateRecordingCall(items: CallQueueEntity[], state: number): Promise<any> {
    const action: string = state === 1 ? 'RECORDING_ENABLE' : 'RECORDING_DISABLE';
    const url = this.getURLAction(action);
    const ids = [];
    let i: number;
    for (i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    return HttpEngine.getInstance().post(url, { ids }).then(ret => {
      for (i = 0; i < items.length; i++) {
        items[i].recording_mode = state;
      }
      return ret;
    });
  }

  findByGroupId(group_id: number): CallQueueEntity[] {
    if (!this.data_loaded) {
      console.error('You need run findAll at first');
    }
    const queues = [];
    for (let j = 0; j < this.object_list.length; j++) {
      const queue = this.object_list[j];
      if (group_id === queue.group_id) {
        queues.push(queue);
      }
    }
    return queues;
  }

  save(item: IEntity, extra_data?: any, attr_group?: string): Promise<ResponsedItem> {
    if (!attr_group) {
      return super.save(item, extra_data, undefined);
    }

    let attr_list: string[];
    let action: string;
    switch (attr_group) {
      case 'general':
        attr_list = this.attr_general;
        action = item.getId() ? 'UPDATE' : 'ADD';
        break;
      default:
        throw new Error('Do not support for attribute group ' + attr_group);
    }

    const request_data = item.dumpObjectData(attr_list, true);
    _.extend(request_data, EntityManager.getInstance().dumpObjectData(extra_data));
    const url = this.getURLAction(action);

    return HttpEngine.getInstance().post(url, request_data);
  }

  clone(source: CallQueueEntity): IEntity {
    const ret: CallQueueEntity = new CallQueueEntity();
    ret.setObjectData(source);
    if (source.main_agents) {
      // ret.main_agents = CommonAPI.cloneArray(source.main_agents);
      ret.main_agents = (source.main_agents || []).slice(0); // clone to new array
    }
    if (source.backup_agents) {
      // ret.backup_agents = CommonAPI.cloneArray(source.backup_agents);
      ret.backup_agents = (source.backup_agents || []).slice(0); // clone to new array
    }
    if (source.transfer_numbers) {
      // ret.transfer_numbers = CommonAPI.cloneArray(source.transfer_numbers);
      ret.transfer_numbers = (source.transfer_numbers || []).slice(0); // clone to new array
    }
    return ret;
  }

  cloneAndSave(item: CallQueueEntity): Promise<ResponsedItem> {
    const url = this.getURLAction('CLONE');
    const post_data = { id: item.getId() };
    return this.postUpdateRequest(url, post_data);
  }

  getQueuesUsedForCallCenter(): CallQueueEntity[] {
    const queueList: CallQueueEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      for (const q of this.getObjectList() as CallQueueEntity[]) {
        if (q.hasRoutedDid(did) && !(queueList.find(i => i.id === q.id))) {
          queueList.push(q);
        }
      }
    }
    return queueList;
  }

  getQueuesRouted(): CallQueueEntity[] {
    return this.getObjectList().filter(q => q.hasRouted());
  }

  getEmptyCallQueue(label: string = null): CallQueueEntity {
    if (label) {
      this.emptyCallQueue.queue_name = label;
    } else {
      this.emptyCallQueue.queue_name = _ti('call_queue.content.all_queues');
    }

    return this.emptyCallQueue;
  }
}

import { EntityManager } from '@wephone-core/service/entity_manager';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { IEntity, ResponsedItem } from '@wephone-core/model/model.interface';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { UserEntity } from '../entity/user';
import { _ti } from '@wephone-translation';
