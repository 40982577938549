import { Component, OnInit } from '@angular/core';
import { TranslationService, TranslationLocale } from '@wephone-translation';

@Component({
  selector: 'app-language-swiching',
  templateUrl: './language-swiching.component.html',
  styleUrls: ['./language-swiching.component.scss']
})
export class LanguageSwichingComponent implements OnInit {
  currentLang: TranslationLocale;
  langs: TranslationLocale[] = [];

  constructor(
    private translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.updateLangs();
  }

  updateLangs(): void {
    this.currentLang = this.translationService.getCurrLanguage();
    this.langs = this.translationService.availableLangs.filter(x => x.code !== this.currentLang.code);
  }

  refreshLangs(): void {
    setTimeout(() => {
      this.updateLangs();
    }, 500);
  }

  setLocale(langCode: string): void {
    this.translationService.setAppLanguage(langCode);
    this.refreshLangs();
  }
}
