<div class="mini-block">
    <div class="flag">
        <span class="flag-icon maticon"><mat-icon>tty</mat-icon></span>
    </div>
    <div class="number-call">
        <span class="name font-weight-bold" [matTooltip]="ivrMenu.name" matTooltipPosition="above">{{ ivrMenu.name | truncate:18 }}&nbsp;</span>
        <span class="number" *ngIf="ivrMenu.sound_message" [matTooltip]="ivrMenu.sound_message.name" matTooltipPosition="above">
            <fileentry-player-button [filePublicId]="ivrMenu.sound_message.public_id" playerId="main"
                [disabled]="!ivrMenu.sound_message.ready_for_playing"></fileentry-player-button>
            {{ ivrMenu.sound_message.name | truncate:14 }}
        </span>
    </div>
</div>
