import * as _ from 'lodash';

import { Component, Inject, ViewChild } from '@angular/core';
import { ClassRegistry, DialogActionButton, Colors } from '@wephone-utils';
// import { UserProfileService } from '@wephone/services/user-profile.service';
// import { ValidatorService } from '@wephone/services/validator.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { UserCallReceptionComponent } from '@wephone-common/user';
import { AgentService } from '@wephone/services/agent.service';

@Component({
  selector: 'user-call-reception-dialog',
  templateUrl: 'user-call-reception-dialog.html'
})
export class UserCallReceptionDialog extends DialogComponentBase {
  dialogTitle = _tk('change_agent_number.title');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.apply();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  @ViewChild('userCallReception') userCallReception: UserCallReceptionComponent;
  agent: any;

  constructor(
    dialogRef: MatDialogRef<UserCallReceptionDialog>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private agentService: AgentService
  ) {
    super(dialogRef);
    this.agent = _.cloneDeep(this.dialogData);
  }

  // updateUserSecondaryNumber(new_number: string): void {
  //   this.agent.user.phone = new_number;
  //   this.userProfileService.updateProfileAttrs(this.agent.user, ['phone']).then(
  //     () => {
  //       this.toastService.show(_ti('public.message.update_success'));
  //     }, error => {
  //       const error_data = error && error.error ? error.error : undefined;
  //       console.error('Edit Item error', error_data.error);
  //       const msg = error_data && error_data.error
  //         && error_data.error.message ? error_data.error.message : 'public.message.update_failure';
  //       this.toastService.showError(msg);
  //     }
  //   );
  // }

  async apply(): Promise<void> {
    if (!this.userCallReception.isValid()) {
      this.toastService.showError(_ti('public.message.data_invalid'));
      this.updateDialogLayout();
      return;
    }

    const callReception = this.userCallReception.getChangeSet();
    // if (callReception && callReception.phoneMode === 1) {
    //   if (!(callReception.backupPhone && this.validatorService.isPhoneNumber(callReception.backupPhone))) {
    //     this.toastService.showError(_ti('public.errors.invalid_number'));
    //     return;
    //   }

    //   this.updateUserSecondaryNumber(callReception.backupPhone);
    // }

    // this.dismiss({ data: callReception });
    if (_.isEmpty(callReception)) {
      console.warn('Invalid data');
      return;
    }

    try {
      await this.agentService.change_number(this.agent.id, callReception.phoneMode, callReception.backupPhone);
      this.toastService.show(_ti('public.message.update_success'));
      this.dismiss();
    } catch (error) {
      console.error('Update failure', error);
      this.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  // dismiss(data?): void {
  //   super.dismiss(data);
  // }
}

ClassRegistry.register(UserCallReceptionDialog);
