import { Component, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'chatbot-dialog',
  templateUrl: 'chatbot.dialog.html',
  styleUrls: ['chatbot.dialog.scss']
})
export class ChatbotDialog extends DialogComponentBase {
  dialogTitle = _tk('livecall.overview.enter_phone_number');

  constructor(
    dialogRef: MatDialogRef<ChatbotDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe
  ) {
    super(dialogRef);
  }

}
