import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DialogService, EditingComponent, NoWhitespaceValidator, ToastService } from '@wephone-utils';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { FormBuilder, NgForm, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserRepository } from '@wephone-core/model/repository/user';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { takeUntil } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { UserService } from '@wephone/services/user.service';
import { _tk, _ti } from '@wephone-translation';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import * as _ from 'lodash';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidRepository } from '@wephone-core/model/repository/did';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { AuthenticationService } from '@wephone-core/service/authentication';

export class GroupErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: UserGroupEntity;

  private readonly groupUserRepo = UserGroupRepository.getInstance<UserGroupRepository>();
  private readonly groupRepo = GroupRepository.getInstance<GroupRepository>();
  private readonly userRepo = UserRepository.getInstance<UserRepository>();
  private readonly queueRepo = CallQueueRepository.getInstance<CallQueueRepository>();
  private readonly outcallCampaignRepo = OutcallCampaignRepository.getInstance<OutcallCampaignRepository>();
  private readonly crmRepo = EnterpriseCrmRepository.getInstance<EnterpriseCrmRepository>();
  private readonly _onDestroy = new Subject();

  matcher = new GroupErrorStateMatcher();
  group: UserGroupEntity;
  users: UserEntity[] = [];
  dids: DidEntity[] = [];
  queues: CallQueueEntity[] = [];
  campaigns: OutcallCampaignEntity[] = [];
  directUsers: UserEntity[] = [];
  groups: UserGroupEntity[] = [];
  excludedIds: number[];
  usergroupList: UserGroupEntity[] = [];
  excludedGroups: GroupEntity[];
  isAdmin: boolean;
  currentUser: UserEntity;
  filteredUsersMulti: ReplaySubject<UserEntity[]> = new ReplaySubject<UserEntity[]>(1);
  usersMultiFilterCtrl: FormControl = new FormControl();
  crmList: EnterpriseCrmEntity[] = [];

  constructor(
    private readonly toast: ToastService,
    private readonly fb: FormBuilder,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.crmRepo.findAll(false);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    this.isAdmin = this.authService.isAdmin();
    this.currentUser = this.authService.getUser();
    this.users = this.userRepo.getObjectList();
    this.groups = this.groupUserRepo.getObjectList();
    this.group = _.cloneDeep(this.editingItem);
    this.dids = DidRepository.getInstance<DidRepository>().getObjectList().filter(d => d.group_id === this.group.id);
    this.queues = this.queueRepo.getObjectList().filter(q => !q.is_outbound_campaign && q.group_id === this.group.id && !q.is_archived);
    this.campaigns = this.outcallCampaignRepo.getObjectListByGroupId(this.group.id);
    this.crmList = this.crmRepo.getObjectList().filter(crm => crm.is_authenticated);
    this.initFormGroup();

    this.filteredUsersMulti.next(this.users.slice());
    this.usersMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterUsersMulti();
    });

    this.excludedIds = [];
    if (this.group && this.group.id) {
      const excludedId = +this.group.id;
      this.excludedIds.push(excludedId);
      this.excludedGroups = _.cloneDeep(this.groupRepo.getAllChildrenGroupById(excludedId));
      this.excludedGroups.filter(group => {
        return this.excludedIds.push(group.id);
      });

      const result = this.groups.filter(group => {
        if (this.excludedIds.includes(group.id)) {
          return false;
        }

        return true;
      });

      this.usergroupList = result;
    }

    await this.initData();
  }

  async initData(): Promise<void> {
    this.directUsers = await this.userService.getDirectUsersFromGroup(this.group.id);
    console.log('this.directUsers', this.directUsers);
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.group.name, [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      parentId: [this.group.parent ? this.group.parent.id : undefined],
      crmId: [this.group.crm_id],
      description: [this.group.description, [Validators.maxLength(512)]],
      users: [this.group.users.map(u => u.id)]
    });

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });
  }

  async submitForm(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toast.showError(_ti('public.message.data_invalid'));
      return;
    }

    const updatedGroup = this.groupUserRepo.create() as UserGroupEntity;
    updatedGroup.setObjectData({
      id: this.group.getId(),
      name: _.trim(this.form.get('name').value),
      parent_id: this.form.get('parentId').value,
      description: _.trim(this.form.get('description').value),
      crm_id: this.form.get('crmId').value,
    });

    const changeset = this.formService.getChangeSet(this.form);
    if (changeset && changeset.users) {
      const userIds: number[] = this.getFormUpdateUserIds();
  
      const oldUserIds: number[] = this.group.users && this.group.users.map(x => x.id) || [];
  
      const removedUserIds: number[] = oldUserIds.filter(x => !_.includes(userIds, x));
  
      if (!_.isEmpty(removedUserIds)) {
        const queueNames = [];
        for (const userId of removedUserIds) {
          const user: UserEntity = EntityManager.getInstance().getRepository('UserRepository').getObjectById(userId);
          const oldGroupIds = user.group_ids;
          if (oldGroupIds.indexOf(this.group.id) >= 0) {
            const updatedGroupIds = _.cloneDeep(oldGroupIds);
            updatedGroupIds.splice(oldGroupIds.indexOf(this.group.id), 1);
            const removedQueues: CallQueueEntity[] = this.userService.getRemovedQueues(user, oldGroupIds, updatedGroupIds);
  
            for (const queue of removedQueues) {
              if (!_.includes(queueNames, queue.queue_name)) {
                queueNames.push(queue.queue_name);
              }
            }
          }
        }
  
        if (queueNames.length) {
          await this.dialogService.confirmDialog(
            _ti('dialogs.confirmation'),
            _ti('user.message.group_change_cause_remove_queues_confirm', { queues: queueNames.join(', ') }),
            async () => {
              await this.doUpdateGroupWithUsers(updatedGroup, userIds);
            }
          );
          return;
        }
      }

      await this.doUpdateGroupWithUsers(updatedGroup, userIds);
    } else {
      await this.doUpdateGroup(updatedGroup, null);
    }

  }

  private async doUpdateGroup(updatedGroup: UserGroupEntity, extraData: any): Promise<void> {
    try {
      const returnedGroup = await this.groupUserRepo.save(updatedGroup, extraData);
      await this.groupUserRepo.reload();
      this.toast.show(_ti('public.message.update_success'));
      const groupList = this.groupUserRepo.getObjectList();

      this.filterSort(groupList);

      // Manually update group because dont know how to get from remote
      // this.group = returnedGroup;
      this.group = this.groupUserRepo.getObjectById(returnedGroup.object_id);
      this.group.users = !_.isEmpty(this.form.get('users').value) ? this.userRepo.getObjectListByIds(this.form.get('users').value) : [];
      this.resetForm();
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  private doUpdateGroupWithUsers(updatedGroup: UserGroupEntity, userIds: number[]): Promise<void> {
    const extraData = { user_ids: userIds };
    return this.doUpdateGroup(updatedGroup, extraData);
  }

  private getFormUpdateUserIds(): number[] {
    const userIds = this.form.get('users').value || [];
    return userIds.filter((uId: number) => {
      const currentUserIds = this.group.users.map(u => u.id);
      const directUserIds = this.directUsers.map(u => u.id);
      return directUserIds.includes(uId) || !currentUserIds.includes(uId) ? true : false;
    });
  }

  private getFormResetData(): any {
    return {
      name: this.group.name,
      parentId: this.group.parent ? this.group.parent.id : undefined,
      description: this.group.description,
      users: this.group.users.map(u => u.id),
      crmId: this.group.crm_id,
    };
  }

  async resetForm(): Promise<void> {
    await this.initData();
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();

    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  private filterUsersMulti(): void {
    if (!this.users) {
      return;
    }

    let search = this.usersMultiFilterCtrl.value;
    if (!search) {
      this.filteredUsersMulti.next(this.users.slice());

      return;
    }

    search = search.toLowerCase();

    this.filteredUsersMulti.next(this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1));
  }

  removeOption($event, controlName: string): void {
    $event.stopPropagation();
    this.form.get(controlName).markAsDirty();
    this.form.get(controlName).setValue(null);
    this.onFormValueChange();
  }

  filterSort(groups: UserGroupEntity[]): UserGroupEntity[] {
    return groups.sort((a, b) => {
      const comparison = a.path_name.localeCompare(b.path_name);
      if (comparison === 0) {
        return a.name.localeCompare(b.name);
      }

      return comparison;
    });
  }
}
