import { Settings } from 'luxon';
import { Injectable } from '@angular/core';
import { BusinessType, UI_MODE, UserRole } from '@wephone-core/system';
import { SingletonBase } from '@wephone-utils/utils/singleton';
import { joinURL } from '@wephone-utils/utils/url-util';

interface FlexIvrSettingsOptions {
  push_api?: boolean;
  push_api_persistent?: boolean;
  php_session_name?: string;
  enterprise_domain?: string;
  root_url?: string;
  ui_mode?: UI_MODE;
  user_role?: UserRole;
  max_upload_size?: number;
  ws2_url?: string;
  inside_salesforce?: boolean;
}

export enum FlexIVRAppType {
  superadmin = 'superadmin',
  admin = 'admin',
  phone = 'phone',
}

// Luxon SETTINGS
Settings.throwOnInvalid = true;

@Injectable()
export class FlexIvrSettings extends SingletonBase implements FlexIvrSettingsOptions {

  push_api = true;
  php_session_name: string;
  enterprise_domain: string;
  root_url: string;
  api_url_prefix = '/api/';
  bt_type: BusinessType = BusinessType.CALLCENTER;
  ui_mode: UI_MODE;
  user_role: UserRole;
  max_upload_size = 5; // 5MB
  ws2_url: string;
  app_type: FlexIVRAppType;
  inside_salesforce = false;

  static getInstance(): FlexIvrSettings {
    if (!super.getInstance()) {
      new FlexIvrSettings();
    }

    return super.getInstance();
  }

  setProjectAdmin(): void {
    this.app_type = FlexIVRAppType.admin;
  }
  isProjectAdmin(): boolean {
    return this.app_type === FlexIVRAppType.admin;
  }
  setProjectSuperAdmin(): void {
    this.app_type = FlexIVRAppType.superadmin;
  }
  isProjectSuperAdmin(): boolean {
    return this.app_type === FlexIVRAppType.superadmin;
  }
  setProjectPhone(): void {
    this.app_type = FlexIVRAppType.phone;
  }
  isProjectPhone(): boolean {
    return this.app_type === FlexIVRAppType.phone;
  }

  update(settings: FlexIvrSettingsOptions): FlexIvrSettings {
    for (const k of Object.keys(settings)) {
      if (settings.hasOwnProperty(k)) {
        this[k] = settings[k];
      }
    }

    return this;
  }

  getAbsoluteUrl(url: string, root_url?: string): string {
    if (!url) {
      return;
    }
    let prefix = root_url || this.root_url;
    if (!prefix) {
      return url;
    }
    const pattern = /^((http|https):\/\/)/;
    if (!pattern.test(url)) {
      if (prefix[prefix.length - 1] === '/') {
        prefix = prefix.substring(0, prefix.length - 1);
      }
      if (url[0] === '/') {
        return prefix + url;
      }

      return prefix + '/' + url;
    }
    return url;
  }

  getRelativeWSv2URL(url: string): string {
    if (this.ws2_url) {
      return joinURL(this.ws2_url, url);
    }

    return url;
  }

  getAbsoluteWSv2URL(url: string): string {
    if (this.ws2_url) {
      return this.getAbsoluteUrl(url, this.getAbsoluteUrl(this.ws2_url));
    }

    return this.getAbsoluteUrl(url);
  }

  private getBaseApiUrl(api_path: string = ''): string {
    return joinURL(this.api_url_prefix, api_path);
  }

  getRelativeApiUrlV2(api_path: string = ''): string {
    return this.getRelativeWSv2URL(this.getBaseApiUrl(api_path));
  }

  getAbsoluteApiUrlv2(api_path: string = ''): string {
    return this.getAbsoluteWSv2URL(this.getBaseApiUrl(api_path));
  }

  getEnterpriseUrl(url: string): string {
    if (!this.enterprise_domain) {
      return url;
    }
    return this.getAbsoluteUrl('/a/' + this.enterprise_domain + url);
  }

  getEnterpriseUrlV2(url: string): string {
    if (this.isProjectSuperAdmin()) {
      return this.getAbsoluteWSv2URL(joinURL('/d/*', url));
    }

    if (!this.enterprise_domain) {
      return url;
    }

    return this.getAbsoluteWSv2URL(joinURL('/d/', this.enterprise_domain, url));
  }

  getApiUrl(api_path: string = ''): string {
    return this.getAbsoluteUrl(this.getBaseApiUrl(api_path));
  }

  btCallCenter(): boolean {
    // Business Type Call Center
    return this.bt_type === BusinessType.CALLCENTER;
  }

  btEnterprise(): boolean {
    // Business Type Enterprise
    return this.bt_type === BusinessType.ENTERPRISE;
  }

  btHotel(): boolean {
    // Business Type Hotel
    return this.bt_type === BusinessType.HOTEL;
  }

  uiCallCenter(): boolean {
    return this.ui_mode === UI_MODE.CALLCENTER;
  }

  uiReseller(): boolean {
    return this.ui_mode === UI_MODE.RESELLER;
  }

  uiEnterprise(): boolean {
    return this.ui_mode === UI_MODE.ENTERPRISE;
  }

  uiHotel(): boolean {
    return this.ui_mode === UI_MODE.HOTEL;
  }

  uiSipTrunk(): boolean {
    return this.ui_mode === UI_MODE.SIPTRUNK;
  }

  setBusinessType(bt: BusinessType): void {
    this.bt_type = bt;
    this.setUIMode();
  }

  setUserRole(user_role: UserRole): void {
    this.user_role = user_role;
    this.setUIMode();
  }

  setUIMode(ui_mode?: UI_MODE): void {
    switch (this.bt_type) {
      case BusinessType.ENTERPRISE: {
        this.ui_mode = UI_MODE.ENTERPRISE;
        break;
      }
      case BusinessType.CALLCENTER: {
        if (this.user_role === UserRole.ADMIN) {
          if (ui_mode === UI_MODE.ENTERPRISE) {
            this.ui_mode = UI_MODE.ENTERPRISE;
          } else {
            this.ui_mode = UI_MODE.CALLCENTER;
          }
        } else if (this.user_role === UserRole.USER) {
          this.ui_mode = UI_MODE.ENTERPRISE;
        } else {
          this.ui_mode = UI_MODE.CALLCENTER;
        }
        break;
      }
      case BusinessType.HOTEL: {
        this.ui_mode = UI_MODE.HOTEL;
        break;
      }
      case BusinessType.RESELLER: {
        this.ui_mode = UI_MODE.RESELLER;
        break;
      }
      case BusinessType.SIPTRUNK: {
        this.ui_mode = UI_MODE.SIPTRUNK;
        break;
      }
      default:
        break;
    }
  }
}
