import { DateTime, Duration } from 'luxon';
import * as _ from 'lodash';

import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { parseStringWithFormat, localNow, parseDateTime } from '@wephone-utils';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
import { _ti } from '@wephone-translation';

export const SEPARATE_EVENT_DATA = ',';
export const SEPARATE_COLUMN_DATA = '___';
export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';
export const DURATION_TIME_FORMAT = 'hh:mm:ss';

interface IBarData {
  styleClass?: string;
  width?: number;
  title?: string;
  duration?: string;
  start_time?: string;
  more_info?: string;
}

interface IEventType {
  id: number;
  type: number;
  state?: number;
  start_dt: any;
  end_dt: any;
  pause_reason_id?: number;
}
@Component({
  selector: 'app-time-slot-graph-renderer',
  templateUrl: './time-slot-graph-renderer.html',
  styleUrls: ['./time-slot-graph-renderer.scss']
})
export class TimeSlotGraphRenderer implements ICellRendererAngularComp {
  private data: any;
  private config = {
    data: '',
    start_dt: undefined,
    start_hour: 0,
    end_hour: 24,
  };

  hours = [];
  bars = [];

  @ViewChild('progress', { static: false }) process: ElementRef;

  agInit(params: any): void {
    this.data = params.value;

    if (this.data) {
      setTimeout(() => {
        this.parseData();
      });
    }
  }

  refresh(): boolean {
    this.parseData();

    return false;
  }

  private parseData(): void {
    const barTotalWidth = this.process.nativeElement.offsetWidth || 400;
    this.config = _.extend(this.config, this.data);
    this.config.start_hour = parseStringWithFormat(this.data.start_hour, 'HH:mm').hour;
    this.config.end_hour = parseStringWithFormat(this.data.end_hour, 'HH:mm').hour;

    const hour_duration = this.config.end_hour - this.config.start_hour;
    let width = 0;
    let count = 0;
    const count_step = 2;
    for (let i = this.config.start_hour; i < this.config.end_hour; i++) {

      count++;

      if (count % count_step === 0) {
        continue;
      }

      width = Math.ceil(count_step * 100 / hour_duration);
      this.hours.push({ width: `${width}%`, hour: i });
    }

    // get this.bars
    const data_columns = this.config.data.split(SEPARATE_COLUMN_DATA);
    const events: IEventType[] = [];

    if (data_columns.length) {
      // parse all params
      const data_events_id = data_columns[0].split(SEPARATE_EVENT_DATA);
      const data_events_type = data_columns[1].split(SEPARATE_EVENT_DATA);
      const data_events_start_dt = data_columns[2].split(SEPARATE_EVENT_DATA);
      const data_events_end_dt = data_columns[3].split(SEPARATE_EVENT_DATA);
      const data_events_pause = data_columns[4].split(SEPARATE_EVENT_DATA);
      const data_events_state = data_columns[5].split(SEPARATE_EVENT_DATA);

      // get one date to make a start date and end date as an event
      // let today_date: DateTime;
      // for (let i = 0; i < data_events_id.length; i++) {
      //   if (data_events_start_dt[i] && !today_date) {
      //     today_date = parseStringWithFormat(data_events_start_dt[i], DATETIME_FORMAT);
      //     break;
      //   }
      // }

      const now: DateTime = localNow();
      const filterDate: DateTime = parseDateTime(this.config.start_dt);

      const start_dt = filterDate.set({
        hour: this.config.start_hour,
        second: 1,
      }).startOf('hour');

      const end_dt = filterDate.set({
        hour: this.config.end_hour,
        second: 0,
      }).startOf('hour');

      for (let i = 0; i < data_events_id.length; i++) {
        if (!data_events_id[i] || !data_events_start_dt[i]) {
          continue;
        }

        const eventStateDt = parseStringWithFormat(data_events_start_dt[i], DATETIME_FORMAT);
        const eventEndDt = data_events_end_dt[i] ? parseStringWithFormat(data_events_end_dt[i], DATETIME_FORMAT) : now;

        events.push({
          id: +data_events_id[i],
          type: data_events_pause[i] ? 1 : (data_events_type[i] === '1' ? 0 : -1),
          state: +data_events_state[i],
          // tslint:disable-next-line: strict-comparisons
          start_dt: eventStateDt < start_dt ? start_dt : eventStateDt,
          // tslint:disable-next-line: strict-comparisons
          end_dt: eventEndDt > end_dt ? end_dt : eventEndDt,
          pause_reason_id: +data_events_pause[i],
        });
      }

      events.push({
        id: 0,
        type: -1,
        start_dt,
        end_dt
      });

      // sort events by start_dt
      events.sort((a, b) => {
        if (a.start_dt > b.start_dt) {
          return 1;
        }

        if (a.start_dt === b.start_dt) {
          return 0;
        }
        return -1;
      });

      // make the periods from the retrieve events
      const periods = [];
      const nodes = [];

      for (const event of events) {
        // tslint:disable-next-line: strict-comparisons
        if (event.start_dt >= start_dt && !nodes.includes(event.start_dt)) {
          nodes.push(event.start_dt);
        }

        // tslint:disable-next-line: strict-comparisons
        if (event.end_dt <= end_dt && !nodes.includes(event.end_dt)) {
          nodes.push(event.end_dt);
        }
      }

      nodes.sort((a, b) => a - b);

      for (let i = 0; i < nodes.length; i++) {
        if (typeof (nodes[i + 1]) !== 'undefined') {
          periods.push([nodes[i], nodes[i + 1]]);
        }
      }

      // create bars from the periods
      let bar: IBarData = {};
      let suit_event: any = {};
      const bar_style_class = {
        0: { styleClass: 'success', title: 'stats.agent_connection_log.state_v2.connected' },
        1: { styleClass: 'warning', title: 'stats.agent_connection_log.state_v2.pause' },
        '-1': { styleClass: 'danger', title: 'stats.agent_connection_log.state_v2.disconnected' }
      };

      let total_width = 0;
      periods.forEach((period, idx) => {
        const suit_events = [];
        const period_start = period[0];
        const period_end = period[1];

        events.forEach(e => {
          if (period_start >= e.start_dt && period_end <= e.end_dt) {
            suit_events.push(e);
          }
        });

        if (suit_events.length) {
          suit_events.sort((a, b) => {
            return b.start_dt - a.start_dt;
          });
          // get the nearest event
          suit_event = _.extend({}, suit_events[0]);
          // if start_dt is selected begin date and having more suit events (in case of connected from last day)
          if (suit_events.length > 1 && suit_events[0].start_dt === start_dt) {
            suit_event = _.extend({}, suit_events[1]);
          }

          const duration = Duration.fromMillis(suit_event.end_dt.diff(suit_event.start_dt));
          // const duration = durationn.toFormat(DURATION_TIME_FORMAT);
          const width_percent = (period_end.diff(period_start) / 1000) * 100 / (hour_duration * 3600);
          let styleClass = bar_style_class[suit_event.type] ? bar_style_class[suit_event.type].styleClass : '';
          if (styleClass === 'success') {
            if (suit_event.state === 1) {
              styleClass = 'inbound';
            } else if (suit_event.state === 2) {
              styleClass = 'outbound';
            }
          }

          bar = {
            styleClass,
            width: Math.ceil(width_percent * barTotalWidth / 100) || 1,
            title: bar_style_class[suit_event.type] ? bar_style_class[suit_event.type].title : '',
            duration: duration.toFormat(DURATION_TIME_FORMAT),
            start_time: suit_event.start_dt.toFormat(TIME_FORMAT)
          };

          if (suit_event.type === 1 && suit_event.pause_reason_id) {
            const pauseReason: PauseReasonEntity = PauseReasonRepository.getInstance().getObjectById(suit_event.pause_reason_id);
            bar.more_info = pauseReason && pauseReason.label || '';
          }

          if (idx === periods.length - 1) {
            // bcoz count from 0
            bar.width = barTotalWidth - total_width;
          } else {
            total_width += bar.width;
          }

          this.bars.push(bar);
        }
      });
    }
  }

  showDetail(): void {
    if (!this.data.showDetail) {
      return;
    }

    this.data.showDetail(this.config);
  }

  getBarTooltip(bar: IBarData): string {
    const tooltips = [];
    tooltips.push(_ti(bar.title, { start_time: bar.start_time, duration: bar.duration }));
    if (bar.more_info) {
      tooltips.push(bar.more_info);
    }
    tooltips.push();
    const newLine = '&#13;';
    return tooltips.join(newLine);
  }
}
