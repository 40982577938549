<form [formGroup]="form">
  <div fxLayout="column">
    <mat-form-field class="full-width">
      <mat-label>{{ 'user_page.alias'|translate }}</mat-label>
      <input matInput [formControl]="form.controls['alias']" [placeholder]="'user_page.alias'|translate">

      <mat-error [flexFormControlError]="form.get('alias')"></mat-error>
    </mat-form-field>
  </div>
</form>
