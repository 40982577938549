import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService, ToastService } from '@wephone-utils';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
// import { TranslateService } from '@ngx-translate/core';
import { QualificationService, CallCenterQualificationDialog } from '@wephone-common';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'action-handled-calls-renderer',
  templateUrl: './action-handled-calls-renderer.html',
  styleUrls: ['./action-handled-calls-renderer.scss']
})
export class ActionHandledCallsRenderer implements ICellRendererAngularComp {

  private callQueueRepo: CallQueueRepository;
  private qualRepo: QualificationRepository;
  params: any;

  constructor(
    private dialogService: DialogService,
    private toastService: ToastService,
    private em: EntityManager,
    // private translate: TranslateService,
    private qualService: QualificationService
  ) {
    this.callQueueRepo = this.em.getRepository('CallQueueRepository');
    this.qualRepo = this.em.getRepository('QualificationRepository');
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  reQualify(call): void {
    const queueId = call.queue_ids[0] || undefined;
    if (!queueId) {
      this.toastService.showError('Queue has no ID');

      return;
    }

    const queue: CallQueueEntity = this.callQueueRepo.getObjectById(queueId);

    // if (!queue.has_qualification) {
    //   this.toastService.showError('Queue has no qualification');
    // }

    const qualifications: QualificationEntity[] = EntityManager.getRepository<QualificationRepository>('QualificationRepository').getValidRootObjectList();
    if (!qualifications.length) {
      console.error('No valid qualification available');
      return;
    }

    let rootQual: QualificationEntity;
    if (queue) {
      rootQual = call.is_outgoing ? queue.out_qualification : queue.in_qualification;
    }

    const pathQualIds: QualificationEntity[] = [];

    const dialogData = {
      hasAnsweringMachine: call.is_outgoing,
      pathQualIds: call.qualification_ids,
      finished: pathQualIds && pathQualIds.length ? true : false,
      root_id: rootQual && rootQual.id || null,
      comment: call.qualification_comment,
      canQualify: call.canQualify()
    };

    const dialogRef = this.dialogService.openDialog2(CallCenterQualificationDialog, { width: '60%', data: dialogData });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (result.isAnsweringMachine) {
        return this.updateQualification(call.id, null, null, 1, call);
      }

      return this.updateQualification(call.id, result.selectedQualificationIds, result.qualificationComment, 0, call);
    });
  }

  updateQualification(id: number, qualIds: number[], comment, answeringMachine, call): void {
    this.qualService.qualifySession(id, 1, qualIds, comment, answeringMachine).then(() => {
      let rowNode;
      this.params.api.forEachNode(rn => {
        if (rn.data.id === call.id) {
          rowNode = rn;
        }
      });

      if (!rowNode) {
        return;
      }

      if (answeringMachine) {
        rowNode.setDataValue('qualification_ids', []);
        rowNode.setDataValue('qualification_comment', '');
        rowNode.setDataValue('qualification', _ti('qualification.dialog.answering_machine'));

        return;
      }

      const qualNames = [];
      qualIds.forEach(qId => {
        const qual = this.qualRepo.getObjectById(qId);
        if (qual) {
          qualNames.push(qual.value);
        }
      });

      if (rowNode) {
        rowNode.setDataValue('qualification_ids', qualIds);
        rowNode.setDataValue('qualification_comment', comment);
        rowNode.setDataValue('qualification', qualNames.join(' > '));
      }
    });
  }
}
