<mat-progress-spinner *ngIf="isLoading"></mat-progress-spinner>
<flex-select *ngIf="!isLoading" [placeholder]="placeholder" 
              [formControl]="flexSelect"
              [itemList] = "callingProfileList"
              [itemTemplate] = "itemTemplate"
              [options] = "flexSelectOptions"
              [multiple]="false">
  <ng-template #itemTemplate let-callingProfile="item">
    {{ callingProfile.name | translate }}
  </ng-template>
</flex-select>
