import { takeUntil } from 'rxjs/operators';
import { Component, ElementRef, Optional, Input, OnInit } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { FlexMatInputWrapper } from '@wephone-utils';
import { FormControl, NgControl, FormGroup, FormBuilder } from '@angular/forms';
import { OpeningMode } from '@wephone-core/model/entity/openinghour_special_date';

@Component({
  selector: 'opening-hour-form-input',
  templateUrl: './opening-hour-form-input.component.html',
  styleUrls: ['./opening-hour-form-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: OpeningHourFormInput }]
})
export class OpeningHourFormInput extends FlexMatInputWrapper
  implements OnInit, MatFormFieldControl<OpeningHourEntity> {
  @Input() type: string;

  // Public member var
  weekDays = [
    { value: 0, label: 'opening_hour_calendar.content.week_day.0' },
    { value: 1, label: 'opening_hour_calendar.content.week_day.1' },
    { value: 2, label: 'opening_hour_calendar.content.week_day.2' },
    { value: 3, label: 'opening_hour_calendar.content.week_day.3' },
    { value: 4, label: 'opening_hour_calendar.content.week_day.4' },
    { value: 5, label: 'opening_hour_calendar.content.week_day.5' },
    { value: 6, label: 'opening_hour_calendar.content.week_day.6' }
  ];
  openingModes = [
    { value: OpeningMode.CloseAllday, label: 'opening_hour_calendar.content.opening_modes.0' },
    { value: OpeningMode.OpenAllday, label: 'opening_hour_calendar.content.opening_modes.1' },
    { value: OpeningMode.CloseDuringPeriod, label: 'opening_hour_calendar.content.opening_modes.2' },
    { value: OpeningMode.OpenDuringPeriod, label: 'opening_hour_calendar.content.opening_modes.3' }
  ];

  openingHourControl = new FormControl();
  openingHourForm: FormGroup;

  constructor(elRef: ElementRef, @Optional() ngControl: NgControl, private fb: FormBuilder) {
    super(ngControl, elRef);

    this.openingHourForm = this.fb.group({
      dayFrom: [],
      dayTo: [],
      startTime: [],
      endTime: []
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.openingHourForm.valueChanges.pipe(
      takeUntil(this._onDestroy)
    ).subscribe(
      values => {
        const openingHour: any = this.openingHourControl.value || {};
        openingHour.day_from = values['dayFrom'];
        openingHour.day_to = values['dayTo'];
        openingHour.start_time = values['startTime'];
        openingHour.end_time = values['endTime'];
        this.openingHourControl.setValue(openingHour);
      }
    );
  }

  get wrappedControl(): FormControl {
    return this.openingHourControl;
  }

  get controlType(): string {
    return 'flex-opening-hour-form-input';
  }

  writeValue(val?: OpeningHourEntity): void {
    this.openingHourForm.setValue({
      dayFrom: val ? val.day_from : 1,
      dayTo: val ? val.day_to : 0,
      startTime: val ? val.start_time : '09:00',
      endTime: val ? val.end_time : '17:00'
    });

    this.openingHourControl.setValue(val);
  }
}
