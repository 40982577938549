import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { _ti, TranslationService, TranslationLocale } from '@wephone-translation';
import { FlexBaseComponent } from '@wephone-core-ui';
import { DialogService } from '@wephone-utils';
import { PaymentService } from '@wephone/services/payment.service';
import { SubscriptionService } from '@wephone/services/subscription.service';
import { SubscriptionPack } from '@wephone/services/subscription.service.i';
import { Router } from '@angular/router';
import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { SubscriptionPackRepository } from '@wephone-core/model/repository/subscription_pack';
import { UpdatePaymentMethodModal } from '@wephone/modals/update-payment-method/update-payment-method.component';
import { PaymentSourceEntity } from '@wephone-core/model/entity/payment_source';
import { SubscriptionEntity } from '@wephone-core/model/entity/subscription';

@Component({
  selector: 'app-choose-subscription-packs',
  templateUrl: './choose-subscription-packs.component.html',
  styleUrls: ['./choose-subscription-packs.component.scss']
})
export class ChooseSubscriptionPacksComponent extends FlexBaseComponent implements OnInit {
  @Input() readonly redirect: boolean;
  @Output() readonly onValidateDone = new EventEmitter<any>();
  subscriptionPacks: SubscriptionPack[] = [];
  subscriptionPackEntities: SubscriptionPackEntity[] = [];
  detailLink = 'https://www.graam.com/tarifs/#table-details';
  constructor(
    private readonly dialogService: DialogService,
    private readonly paymentService: PaymentService,
    private readonly router: Router,
    private readonly em: EntityManager,
    private readonly translationService: TranslationService,
    private readonly subscriptionService: SubscriptionService,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected async resolveData(): Promise<any> {
    const locale: TranslationLocale = this.translationService.getCurrLanguage();
    this.subscriptionPackEntities = await (this.em.getRepository('SubscriptionPackRepository') as SubscriptionPackRepository).findValidPacks();
    this.subscriptionPacks = await this.subscriptionService.getSubscriptionPacks(locale ? locale.code : 'en');
    this.detectChanges();
  }

  async selectPack(s: SubscriptionPack): Promise<void> {
    if (!s) {
      console.error('No subscription pack selected');
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    const myPaymentSource: PaymentSourceEntity = await this.paymentService.mySource();
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      myPaymentSource ? _ti('subscription_pack.message.subscribe_pack_without_card')
      : _ti('subscription_pack.message.subscribe_pack_with_card'),
      async () => {
        if (!myPaymentSource) {
          const dialogRef = this.dialogService.openDialog2(UpdatePaymentMethodModal, { hasBackdrop: true, size: 's', data: {
            extend_subscription_pack: s.pack_id
          }});
          dialogRef.afterClosed().subscribe((data: any) => {
            console.log('data update payment', data);
            if (data) {
              this.onSelectpackSuccess(data);
            }
          });
        } else {
          const subscription: SubscriptionEntity = await this.subscriptionService.subscribeSubscriptionPack(s.pack_id);
          if (subscription) {
            this.onSelectpackSuccess(subscription);
          }
        }

        // try {
        //   // const paidResp: any = await this.paymentService.makePaymentUpdateSubscription(s.pack_id);
        //   // console.log('Paid subscription response', paidResp);
        //   // this.showInfo(_ti('invoice.message.payment_success'));

        // } catch (e) {
        //   console.error('makePaymentInvoice', e);
        //   this.showError(e.message || _ti('invoice.message.payment_failure'));
        // }
      }
    );
  }

  onSelectpackSuccess(data): void {
    this.showInfo(_ti('subscription_pack.message.subscribe_success'));
    if (this.onValidateDone) {
      this.onValidateDone.emit(data);
    }
    if (this.redirect) {
      this.router.navigateByUrl('dashboard');
    }
  }
}
