import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class OutcallCampaignItemRepository extends BaseRepository {
  ENTITY_CLASS = OutcallCampaignItemEntity;
  protected URL_PREFIX = 'callcenter/outcallcampaign_item';
  protected METHOD_PATH_LIST = 'list';
  // protected METHOD_PATH_IMPORT = 'import';

  outcall_campaign_item_list = {};

  protected getURLAction(action: string): string {
    switch (action) {
      case 'LIST':
        return this.getUrlV2(this.METHOD_PATH_LIST);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      // case 'IMPORT':
      //   return this.getUrl(this.METHOD_PATH_IMPORT);
      default:
        return super.getURLAction(action);
    }
  }

  async findByCampaignId(campaignId: number, reload: boolean = false): Promise<OutcallCampaignItemEntity[]> {
    const eid = ConfigManager.getInstance().getCurrentEnterpriseId();
    if (!this.outcall_campaign_item_list[eid]) {
      this.outcall_campaign_item_list[eid] = {};
    }
    if (reload === false && this.outcall_campaign_item_list[eid][campaignId]) {
      return Promise.resolve(this.outcall_campaign_item_list[eid][campaignId]);
    }

    if (reload) {
      this.outcall_campaign_item_list[eid][campaignId] = null;
    }

    const request_params = { campaign_id: campaignId };
    const url = this.getURLAction('LIST');

    try {
      const list_data = await HttpEngine.getInstance().get(url, request_params);

      if (!this.outcall_campaign_item_list[eid][campaignId]) {
        this.outcall_campaign_item_list[eid][campaignId] = new Array<OutcallCampaignItemEntity>();
      }
      const campaign_item_list = this.outcall_campaign_item_list[eid][campaignId];

      if (list_data) {
        for (const obj_data of list_data) {
          const existing_obj: OutcallCampaignItemEntity = _.find(campaign_item_list, obj => {
            if (obj.id === obj_data.id) {
              return obj;
            }
          });

          if (existing_obj) {
            existing_obj.setObjectData(obj_data, true);
          } else {
            const new_obj = this.create();
            new_obj.setObjectData(obj_data, true);
            campaign_item_list.push(new_obj);
          }
        }
      }

      return campaign_item_list;
    } catch (e) {
      console.error('findByCampaignId error', e);
      return Promise.reject(e);
    }
  }

  delete(item: OutcallCampaignItemEntity): Promise<any> {
    const url = this.getURLAction('DELETE');
    const id: number = item.getId();

    return HttpEngine.getInstance()
      .post(url, { ids: [id] })
      .then((ret: any) => {
        if (ret.deleted_items) {
          const eid = ConfigManager.getInstance().getCurrentEnterpriseId();
          for (const deleted_obj of ret.deleted_items) {
            this.removeOutcallCampaignItemEntityById(
              deleted_obj.id,
              this.outcall_campaign_item_list[eid][item.campaign_id]
            );
          }
        }

        return ret;
      });
  }

  bulkDelete(items: OutcallCampaignItemEntity[]): Promise<any> {
    const ids = [];
    const eid = ConfigManager.getInstance().getCurrentEnterpriseId();
    let campaign_id: number;

    for (let i = 0; i < items.length; i++) {
      campaign_id = items[i].campaign_id;
      ids.push(items[i].getId());
    }

    return super.bulkDelete(items).then((ret: any) => {
      if (ret.deleted_items) {
        for (const deleted_obj of ret.deleted_items) {
          this.removeOutcallCampaignItemEntityById(deleted_obj.id, this.outcall_campaign_item_list[eid][campaign_id]);
        }
      }

      return ret;
    });
  }

  getObjectByCalledNumber(calledNumber: string, campaignId: number, excludedId?: number): OutcallCampaignItemEntity {
    const eid = ConfigManager.getInstance().getCurrentEnterpriseId();
    const objectList = this.outcall_campaign_item_list[eid] && this.outcall_campaign_item_list[eid][campaignId] || [];

    return objectList.find(x => (x.called_number === calledNumber || x.called_number_displayed === calledNumber) && x.campaign_id === campaignId &&
      (!excludedId || excludedId && x.id !== excludedId));
  }

  private removeOutcallCampaignItemEntityById(item_id, item_list): void {
    for (let i = 0; i < item_list.length; i++) {
      if (item_list[i].getId() === item_id) {
        item_list.splice(i, 1);
        break;
      }
    }
  }
}

import { OutcallCampaignItemEntity } from '@wephone-core/model/entity/outcallcampaignitem';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { HttpEngine } from '@wephone-core/service/http_engine';
