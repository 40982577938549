<mat-nav-list appAccordion class="navigation">
  <mat-list-item appAccordionLink *ngFor="let menuItem of menus" [attr.data-ci]="menuItem.name" group="{{ menuItem.state }}">
    <ng-container *ngIf="menuService.showMenuItem(menuItem) && menuService.hasAccessRight(menuItem.accessPermission, menuItem.state)">
      <a appAccordionToggle class="relative" [routerLink]="['/' + menuItem.state ]" *ngIf="menuItem.type === 'link'">
        <mat-icon class="color-primary">{{ menuItem.icon }}</mat-icon>
        <span>{{ menuItem.name | translate }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle class="relative" href="{{ menuItem.state }}" *ngIf="menuItem.type === 'extLink'">
        <mat-icon class="color-primary">{{ menuItem.icon }}</mat-icon>
        <span>{{ menuItem.name | translate }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle class="relative" href="{{ menuItem.state }}" target="_blank" *ngIf="menuItem.type === 'extTabLink'">
        <mat-icon class="color-primary">{{ menuItem.icon }}</mat-icon>
        <span>{{ menuItem.name | translate }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle class="relative" href="javascript:;" *ngIf="menuItem.type === 'sub'">
        <mat-icon class="color-primary">{{ menuItem.icon }}</mat-icon>
        <span>{{ menuItem.name | translate }}</span>
        <span fxFlex></span>
        <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
        <mat-icon class="color-primary menu-caret">arrow_drop_down</mat-icon>
      </a>
      <mat-nav-list class="sub-menu" *ngIf="menuItem.type === 'sub'">
        <ng-container *ngFor="let childitem of menuItem.children">
          <mat-list-item *ngIf="menuService.showMenuItem(childitem) && menuService.hasAccessRight(childitem.accessPermission, childitem.state)" routerLinkActive="open" [attr.data-ci]="childitem.name">
            <a [routerLink]="['/', menuItem.state, childitem.state ]" class="relative">{{ childitem.name | translate }}</a>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </ng-container>
  </mat-list-item>
</mat-nav-list>
