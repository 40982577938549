import { Injectable } from '@angular/core';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { _ti } from '@wephone-translation';
import { DialogService, ToastService } from '@wephone-utils';
import {
  ReusableSoundConfirmDeleteComponent,
} from '@wephone/modals/reusable-sound-confirm-delete/reusable-sound-confirm-delete.component';
import * as _ from 'lodash';

@Injectable()
export class ReusableSoundService {

  constructor(
    private readonly dialogService: DialogService,
    private readonly fileEntryService: FileEntryService,
    private readonly toastService: ToastService,
  ) { }

  async bulkDelete(fileEntryList: FileEntryEntity[], cb?: () => void): Promise<void> {
    const inUsedObjects = await this.fileEntryService.getInUsedObjects(fileEntryList, 5);
    if (!_.isEmpty(inUsedObjects)) {
      return this.openReusableSoundConfirmDeleteDialog(inUsedObjects);
    }

    let confirmMessage = _ti('sound_manager.message.ui_enterprise_delete_confirm');
    const msgs: string[] = [];

    // Already checked from NestJS
    // const routedQueues = this.fileEntryService.getQueueListConfigToFile(fileEntryList);
    // if (routedQueues && routedQueues.length) {
    //   msgs.push(_ti('did.message.confirm_delete_with_dependent_queue', { dependent_queues: routedQueues.map(x => x.queue_name).join(', ') }));
    // }

    const routedDids = this.fileEntryService.getDidListConfigToFile(fileEntryList);
    if (routedDids && routedDids.length) {
      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', { dependent_numbers: routedDids.map(x => x.number).join(', ') }));
    }

    const routedRoutingRules = this.fileEntryService.getCrmRoutingListConfigToFile(fileEntryList);
    if (routedRoutingRules && routedRoutingRules.length) {
      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', { dependent_numbers: routedDids.map(x => x.number).join(', ') }));
    }

    if (msgs.length) {
      msgs.push(_ti('routing_application.message.confirm_clear_routing'));
      confirmMessage = msgs.join('. ');
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      confirmMessage,
      () => {
        this.deleteAll(fileEntryList, cb);
      }
    );
  }

  private openReusableSoundConfirmDeleteDialog(inUsedObjects: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dialogService.openDialog2(
        ReusableSoundConfirmDeleteComponent,
        {
          data: {
            inUsedObjects,
          },
          size: 's',
        },
        result => {
          reject(result);
        },
      );
    });
  }

  private async deleteAll(fileEntryList: FileEntryEntity[], cb?: () => void): Promise<void> {
    try {
      const public_ids: string[] = fileEntryList.map(x => x.public_id);
      const res = await this.fileEntryService.removeFileEntries(public_ids);
      if (res.data && res.data.error) {
        this.toastService.showError(res.data.error.message);
        return;
      }
      this.toastService.showSuccess(_ti('public.message.delete_success'));
      if (cb) {
        cb();
      }
    } catch (error) {
      let msg = _ti('fileentry.error.remove');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toastService.showError(msg);
    }
  }
}
