import * as _ from 'lodash';
import { IRoutingApp, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { EntityManager } from '@wephone-core/service/entity_manager';

export abstract class RoutingAppBase implements IRoutingApp {
  protected APP_NAME: RoutingAppName = null;
  protected appParams: any;
  _destinationObject: any;

  constructor(appParams?: any) {
    if (appParams) {
      this.setAppParams(appParams);
    }
  }

  getAppName(): RoutingAppName {
    return this.APP_NAME;
  }

  getAppParams(): any {
    return this.appParams;
  }

  setAppParams(params: any) {
    this.appParams = params;
    this.setRelatedObject();
    // EntityManager.getInstance().waitForPushedConfig().then(
    //     () => {
    //         this.getRelatedObject();
    //     }
    // )
  }

  getAsJsonObject(): any {
    return {
      application: this.APP_NAME,
      params: this.appParams
    };
  }

  abstract setRelatedObject(): any;
  abstract getRelatedObject(): any;
  abstract getDescription(): string;
}
