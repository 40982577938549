import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { DataBuilderService } from '@wephone/services/data-builder.service';
import { MessageService, ToastService } from '@wephone-utils';
import {
  PipeCellRenderer,
  DateRenderer,
  DateTimeRenderer,
  TimeRenderer,
  TimeWithSecondRenderer,
  SoundRenderer,
  CalledNumberrRenderer,
  ActionRenderer,
  DirectionRenderer,
  ActionRecordingRenderer,
  DurationRenderer,
  RenderInoutColumn,
  RenderNature,
  CallDirectionRenderer,
  StatusRenderer,
  MessageRenderer,
  MissedCallsActionRenderer,
  TimeSlotGraphRenderer,
  ActionHandledCallsRenderer,
  DebugMessagesCallRenderer,
  AgentNameRenderer,
  DetailQueueRenderer,
} from '../ag-renderers';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
// import { TranslateService } from '@ngx-translate/core';
import { TranslationService, _ti } from '@wephone-translation';
import { PhoneNumberRenderer } from '../ag-renderers/phone-number-renderer/phone-number-renderer';

export const ROW_HEIGHT = 35;
export const ROW_BUFFER = 0;
export const PAGINATION_PAGE_SIZE = 100;
export const CACHE_OVERFLOW_SIZE = 1;
export const MAX_CONCURRENT_DATASOURCE_REQUESTS = 1;
export const INFINITE_INITIAL_ROW_COUNT = 1;
export const MAX_BLOCKS_IN_CACHE = 2;

export interface FlexGridConfig {
  columns?: any;
  sizeColumnsToFit?: number;
  export?: any;
  row_height?: number;
  context?: any;
  autoLoad?: boolean;
  searchable?: boolean;
}

@Component({
  selector: 'flex-grid',
  templateUrl: 'flex-grid.html',
  styleUrls: ['./flex-grid.scss']
})
export class FlexGridComponent implements OnInit, OnDestroy {
  private gridApi: GridApi;
  private gridColumnApi;
  private columnDefs = [];
  private readonly params: any;
  private filters: any;
  private subscription: Subscription;
  private readonly subscribeChangeLang: Subscription;
  private _searchHintText: string;

  gridOptions: GridOptions;
  rowBuffer;
  rowSelection;
  rowModelType;
  paginationPageSize;
  cacheOverflowSize;
  maxConcurrentDatasourceRequests;
  infiniteInitialRowCount;
  maxBlocksInCache;
  frameworkComponents;
  searchCtrl = new FormControl('');
  hasLoadedColumns = false;
  searchable = true;
  firstLoadedData: boolean;

  @Input() gridName;
  @Input() config: FlexGridConfig;
  @Input() gridControl?: any;

  @BlockUI('flex-grid-block') blockUIList: NgBlockUI;

  constructor(
    private readonly dataBuilder: DataBuilderService,
    private readonly toast: ToastService,
    private readonly messageService: MessageService,
    // private readonly translate: TranslateService
  ) {
    this.rowBuffer = ROW_BUFFER;
    this.rowSelection = 'multiple';
    this.rowModelType = 'infinite';
    this.paginationPageSize = PAGINATION_PAGE_SIZE;
    this.cacheOverflowSize = CACHE_OVERFLOW_SIZE;
    this.maxConcurrentDatasourceRequests = MAX_CONCURRENT_DATASOURCE_REQUESTS;
    this.infiniteInitialRowCount = INFINITE_INITIAL_ROW_COUNT;
    this.maxBlocksInCache = MAX_BLOCKS_IN_CACHE;
    this.params = {
      columns: [],
      order: [],
      search: {
        value: undefined
      }
    };

    this.frameworkComponents = {
      debugMessagesCallRenderer: DebugMessagesCallRenderer,
      durationRenderer: DurationRenderer,
      dateRenderer: DateRenderer,
      dateTimeRenderer: DateTimeRenderer,
      timeRenderer: TimeRenderer,
      timeWithSecondRenderer: TimeWithSecondRenderer,
      soundRenderer: SoundRenderer,
      numberRenderer: CalledNumberrRenderer,
      actionRenderer: ActionRenderer,
      directionRenderer: DirectionRenderer,
      actionRecordingRenderer: ActionRecordingRenderer,
      rendersInout: RenderInoutColumn,
      renderNature: RenderNature,
      callDirectionRenderer: CallDirectionRenderer,
      statusRenderer: StatusRenderer,
      messageRenderer: MessageRenderer,
      missedCallsActionRenderer: MissedCallsActionRenderer,
      timeSlotGraphRenderer: TimeSlotGraphRenderer,
      actionHandledCallsRenderer: ActionHandledCallsRenderer,
      agentNameRenderer: AgentNameRenderer,
      detailQueueRenderer: DetailQueueRenderer,
      phoneNumberRenderer: PhoneNumberRenderer,
    };

    this.subscribeChangeLang = TranslationService.getInstance().translate.onLangChange.subscribe(() => {
      if (_.isArray(this.columnDefs)) {
        this.columnDefs.forEach(c => {
          c.headerName = c.title ? this.dataBuilder.trans(c.title) : '';
        });

        this.renderCellTemplate(this.columnDefs);
        this.gridApi.setColumnDefs(this.columnDefs);
        this.gridApi.refreshHeader();
      }
    });
  }

  ngOnInit(): void {
    this.gridOptions = {};
    this.gridOptions.columnDefs = [];
    this.gridOptions.rowHeight = this.config.row_height || ROW_HEIGHT;
    this.gridOptions.rowStyle = { 'vertical-align': 'middle' };
    this.gridOptions.context = this.config.context;
    const resizeRefreshColumns = [];
    if (!_.isEmpty(this.config.columns)) {
      for (const columnName of Object.keys(this.config.columns)) {
        if (this.config.columns[columnName] && this.config.columns[columnName].resizeRefresh) {
          resizeRefreshColumns.push(columnName);
        }
      }
    }

    if (this.config.hasOwnProperty('searchable') && !this.config.searchable) {
      this.searchable = false;
    }

    this.gridOptions.onColumnResized = event => {
      event.api.refreshCells({ columns: resizeRefreshColumns });
    };

    this.searchCtrl.valueChanges
      .subscribe(value => {
        const val = (value || '').trim();
        _.extend(this.params.search, { value: val });
        const columns = this.gridColumnApi.getAllColumns();
        for (const col of columns) {
          const colDef = col.getColDef();
          this.addColumnToParams(colDef.field, { searchable: colDef.searchable });
        }

        this.resetData();
        // if (this.gridApi.getInfiniteRowCount() === 0) {
        //   this.loadDataSource();
        // }
      });
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // Auto load
    // if (this.config && this.config.autoLoad === true) {
    //   this.loadDataSource();
    // }

    if (this.gridControl) {
      this.gridControl = this;
    }

    this.subscription = this.messageService.subscribeDatasource(this.gridName, filters => {
      this.filters = filters;
      this.loadDataSource();
    });
  }

  private resetData(): void {
    this.gridApi.refreshInfiniteCache();
  }

  private renderCellTemplate(columnDefs): void {
    for (const col of columnDefs) {
      this.setCellTemplate(col);
    }
  }

  private setCellTemplate(col): void {
    if (col.type === 'date') {
      col.filter = 'date';
      col.cellRenderer = params => {
        return `${params.value.getDate()}/${params.value.getMonth() + 1}/${params.value.getFullYear()}`;
      };
    }

    if (this.config.columns) {
      const name = col.field;
      const col_render = this.config.columns[name] || undefined;

      if (col_render) {
        if (col_render.renderComponent) {
          col.cellRendererFramework = col_render.renderComponent;
        }

        if (col_render.renderFunction) {
          col.cellRenderer = col_render.renderFunction;
        }

        if (col_render.renderFilter) {
          col.cellRendererFramework = PipeCellRenderer;
          col.cellRendererParams = {
            // Complementing the Cell Renderer parameters
            renderPipe: col_render.renderFilter
          };
        }
      }
    }
  }

  async loadDataSource(): Promise<void> {
    const columns: any[] = await this.dataBuilder.loadColumns(this.gridName, this.filters, this.config) || [];
    columns.forEach(col => {
      if (col.sort) {
        const c = _.find(this.params.order, { column: col.field });
        if (!c) {
          this.params.order.push({ column: col.field, dir: col.sort.direction });
        }
      }
    });

    this.renderCellTemplate(columns);

    this.columnDefs = columns;
    this.gridApi.setColumnDefs(columns);

    this.setSearchHintText();

    // Show column by position of column
    this.gridColumnApi.getAllColumns().forEach(c => {
      this.gridColumnApi.moveColumn(c.colDef.field, c.colDef.position);
    });

    this.gridApi.refreshHeader();

    if (this.firstLoadedData && this.gridApi.getInfiniteRowCount()) {
      this.resetData();
      return;
    }

    const dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        this.params.start = params.startRow;
        this.params.length = params.endRow - params.startRow;

        const message = _ti('public.message.loading');
        this.blockUIList.start(message);
        try {
          const data: any = await this.dataBuilder.loadData(this.gridName, this.filters, this.columnDefs, this.params);
          const rowsThisPage = data;
          let lastRow = -1;

          if (data.length < this.params.length) {
            lastRow = params.startRow + data.length;
          }

          params.successCallback(rowsThisPage, lastRow);

          if (this.config.sizeColumnsToFit) {
            // Set to fit
            this.gridApi.sizeColumnsToFit();
          }

          this.firstLoadedData = true;

          this.blockUIList.stop();
        } catch (e) {
          if (e.error && e.error.message) {
            this.toast.showError(e.error.message);
          }

          params.successCallback([], 0);
          this.blockUIList.stop();
        }
      }
    };

    if (this.gridApi) {
      this.gridApi.setDatasource(dataSource);
      this.hasLoadedColumns = true;
    }
  }

  private addColumnToParams(name, data): Object {
    const cols = _.filter(this.params.columns, c => c.name.includes(name));
    let col;

    if (!cols.length) {
      col = { name };
      this.params.columns.push(col);
    } else {
      col = cols[0];
    }

    _.extend(col, data);

    return col;
  }

  export(): void {
    if (this.config.export) {
      return this.config.export();
    }

    this.dataBuilder.exportCSV(this.gridName, this.filters, this.params);
  }

  private setSearchHintText(): void {
    if (!_.isEmpty(this.columnDefs)) {
      const labels: any[] = this.columnDefs.filter(c => !_.hasIn(c, 'searchable') || c.searchable !== false)
        .map(c => c.headerName || _ti(c.title));

      if (labels.length) {
        this._searchHintText = _ti('flex_grid.search.hint', { criterias: labels.join(', ') });
      }
    }
  }

  get searchHintText(): string {
    return this._searchHintText;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscribeChangeLang) {
      this.subscribeChangeLang.unsubscribe();
    }
  }
}
