import { Component, OnInit, EventEmitter, HostListener, Output, Input, HostBinding, ChangeDetectorRef } from '@angular/core';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { _ti } from '@wephone-translation';
import { FileSettings, getFileValidation } from '@wephone-utils/utils/file-util';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() allowedExtensions: string[];
  @Input() title: string;
  @Input() icon: string;
  @Output() readonly onFileChange: EventEmitter<FileList> = new EventEmitter();
  soundFile: any;
  error: string;
  browsedExtensions: string;
  fileSettings: FileSettings;

  @HostBinding('style.background') background = '#fff';

  @HostListener('drop', ['$event'])
  onDrop(evt): void {
    evt.preventDefault();
    evt.stopPropagation();

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.soundFile = files[files.length - 1];

      // validate
      const validation: boolean = this.validateFile();
      this.updateLayout();
      if (!validation) {
        return;
      }
      this.onSelectFile();
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private settings: FlexIvrSettings,
  ) {
    this.fileSettings = {
      extensions: ['*'],
      max_size: this.settings.max_upload_size * 1024 * 1024
    };
  }

  ngOnInit(): void {
    this.browsedExtensions = '*';
    if (this.allowedExtensions && this.allowedExtensions.length) {
      const arrExtensions = this.allowedExtensions.map(x => `.${x}`);
      this.browsedExtensions = arrExtensions.join(',');
      this.fileSettings.extensions = arrExtensions;
    }
  }

  onSelectFile(): void {
    if (!this.soundFile) {
      console.warn('No valid file selected');
      return;
    }
    this.onFileChange.emit(this.soundFile);
  }

  validateFile(): boolean {
    if (!this.soundFile) {
      console.error('No selected file');
      this.error = _ti('fileentry.error.no_file_selected');
      return false;
    }

    const fileValidation = getFileValidation(this.soundFile, this.fileSettings);
    if (!fileValidation.valid) {
      this.error = fileValidation.message;
      this.soundFile = undefined;
      return false;
    }

    return true;
  }

  private updateLayout(): void {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  fileLoadedForBrowser(event): void {
    this.soundFile = undefined;
    setTimeout(() => {
      this.soundFile = event.srcElement.files[0];
      const validation: boolean = this.validateFile();
      this.updateLayout();
      if (!validation) {
        return;
      }

      this.onSelectFile();
    }, 500);
  }

}
