<div fxFill fxLayout="column" *ngIf="loaded">
  <div class="wrapper">
    <div class="col-left">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of steps | keyvalue; let i=index;">
            <a (click)="mainArea.scrollToElement(step.key)" class="step {{ step.key }}"
              [ngClass]="{'active': mainArea.activeAnchor === step.key}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ step.value | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
          <mat-card class="shadow-none pa-0" id="_1config" anchorPart>
            <mat-card-header>
              <div mat-card-avatar>
                <h2><i class="material-icons">settings_input_component</i></h2>
              </div>
              <mat-card-title>
                <h2>{{ 'ivrmenu.title.config_ivr' | translate }}</h2>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="form-group">
              <p>{{ 'ivrmenu.content.note' | translate }}</p>
              <mat-form-field class="full-width">
                <mat-label>{{ 'ivrmenu.content.name'|translate }}</mat-label>
                <input matInput placeholder="{{ 'ivrmenu.content.name'|translate }}" formControlName="name">
                
                <mat-error [flexFormControlError]="form.get('name')"></mat-error>
              </mat-form-field>
              <section>
                <mat-slide-toggle data-ci="missed_on" formControlName="missed_on_hangup">
                  {{ 'ivrmenu.content.missed_on_hangup' | translate }}
                </mat-slide-toggle>
              </section>
              <section>
                <mat-slide-toggle data-ci="direct_extension" formControlName="direct_extension">
                  {{ 'ivrmenu.content.direct_extension' | translate }}
                </mat-slide-toggle>
              </section>
              <!-- <mat-checkbox formControlName="missed_on_hangup" [value]="1">
              {{ 'ivrmenu.content.missed_on_hangup' | translate }}
            </mat-checkbox> -->
              <!-- <div>
              <mat-checkbox formControlName="direct_extension" [value]="1">
                {{ 'ivrmenu.content.direct_extension' | translate }}</mat-checkbox>
            </div> -->
            </mat-card-content>
          </mat-card>

          <mat-divider [inset]="true" class="section-divider"></mat-divider>

          <mat-card class="shadow-none pa-0" id="_2message_and_actions" anchorPart>
            <mat-card-header>
              <div mat-card-avatar>
                <h2><i class="material-icons">message</i></h2>
              </div>
              <mat-card-title>
                <h2>{{ 'ivrmenu.title.config_message_and_actions' | translate }}</h2>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="form-group">
              <ivr-sub-menu #ivrSubMenu [ivrmenu]="ivrmenu" [canAddSubMenu]="true" [isSubMenu]="false" [excludeIvrMenuId]="ivrmenu && ivrmenu.id"
                [shouldViewSubMenu]="true" (valueChange)="updateIvrMenuData($event)"></ivr-sub-menu>

              <span class="mat-error" [flexFormControlError]="form.get('ivrscript_data')"></span>
            </mat-card-content>
          </mat-card>

          <mat-divider [inset]="true" class="section-divider"></mat-divider>
          <div class="block-wrapper" id="_3phone_number" anchorPart>
            <h2>{{ 'call_queue_edit.section.phone_numbers'|translate }}</h2>
            <mat-card class="shadow-none">
              <mat-card-content class="padded-content">
                <div fxLayout="row wrap" *ngIf="usedDidList.length;else NoUsedDid">
                  <div [attr.data-ci]="'queue-in-used-did'" class="inused-item" *ngFor="let did of usedDidList">
                    <app-did-mini-block [did]="did"></app-did-mini-block>
                  </div>
                </div>

                <ng-template #NoUsedDid>
                  <span>{{ 'did.content.no_routed_phone_number'|translate }}</span>
                </ng-template>
              </mat-card-content>
            </mat-card>
          </div>

          <mat-divider [inset]="true" class="section-divider"></mat-divider>

          <mat-card class="shadow-none pa-0" id="_4advance" anchorPart>
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>settings</mat-icon>
              </div>
              <mat-card-title>
                <h2>{{ 'ivrmenu.title.advance'|translate }}</h2>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="padded-content">
              <mat-form-field class="full-width">
                <mat-label>{{ 'ivrmenu.content.alias' | translate }}</mat-label>
                <input matInput placeholder="{{ 'ivrmenu.content.alias' | translate }}" formControlName="alias">

                <mat-error [flexFormControlError]="form.get('alias')"></mat-error>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </form>
      </div>
    </div>
  </div>
</div>
