<div class="mini-block">
    <div class="flag">
        <span class="flag-icon maticon">
            <mat-icon>sip</mat-icon>
        </span>
    </div>
    <div class="number-call">
        <span class="name font-weight-bold" [matTooltip]="sipphone.extension"
            matTooltipPosition="above">
            <span>{{ sipphone.extension }}</span>
        </span>
    </div>
</div>
