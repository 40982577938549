import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicFilterSource, IFlexTableConfig, IFlexTableSidePannelOptions, parseDateTime, regexSearch } from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InvoiceRepository } from '@wephone-core/model/repository/invoice';
import { InvoiceEntity, InvoiceType } from '@wephone-core/model/entity/invoice';
import { InvoiceDetailComponent } from '@wephone/components/invoice-detail/invoice-detail.component';
import { FlexBasePage } from '@wephone-core-ui';
import { ActivatedRoute } from '@angular/router';
import { _tk, _ti } from '@wephone-translation';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { DateShortPipe } from '@wephone-utils/pipes/date-format/date-format.pipe';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent extends FlexBasePage implements OnInit {
  private invoiceRepo = InvoiceRepository.getInstance() as InvoiceRepository;

  invoiceForm: FormGroup;

  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: InvoiceDetailComponent,
    editorOptions: {
    }
  };
  @ViewChild('flexCrud') flexCrud;
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    const invoiceSource = this.invoiceRepo.dataSource<InvoiceEntity>();
    this.dataSource = new DynamicFilterSource(invoiceSource, this.filterFn);

    this.tableConfig = {
      multiSelect: false,
      columns: [
        {
          name: 'type_name',
          label: _tk('invoice.content.type_name'),
          searchable: true,
          sortable: true,
          renderCell: this.renderInvoiceTitle
        },
        {
          name: 'invoice_dt',
          label: _tk('invoice.content.invoice_date'),
          searchable: true,
          sortable: true,
          sort: 'desc'
        },
        {
          name: 'total',
          label: _tk('invoice.content.total'),
          searchable: false,
          sortable: true,
        },
        {
          name: 'is_paid',
          label: _tk('invoice.content.status'),
          searchable: false,
          sortable: true,
        }
      ]
    };

    this.invoiceForm = this.fb.group({
      filterValue: ['']
    });

    this.invoiceForm.valueChanges.subscribe(this.onFormValueChange);
  }

  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  protected async resolveData(): Promise<any> {
    const invoices = await this.invoiceRepo.findAll();
    console.log('invoices', invoices);

    this.activeRoute.queryParams.subscribe(
      params => {
        if (!params['paid_success']) {
          return;
        }
        const paidSuccess = parseInt(params['paid_success']);
        if (paidSuccess) {
          this.showInfo(_ti('invoice.message.payment_success'));
        } else {
          this.showError(_ti('invoice.message.payment_failure'));
        }
      }
    );
  }

  filterFn = (item: InvoiceEntity): boolean => {
    if (!this.filterValue) {
      return true;
    }

    const invoiceDt = item.invoice_dt && (new DateShortPipe()).transform(item.invoice_dt);
    const invoicetitle = this.renderInvoiceTitle(item);
    return regexSearch(invoicetitle, this.filterValue) || (invoiceDt && regexSearch(invoiceDt, this.filterValue));
  }

  renderId(item: InvoiceEntity): string {
    return `#${item.invoice_number}`;
  }

  // renderDate(item: InvoiceEntity): string {
  //   const date = new Date(item.invoice_dt);
  //   return parseDateTime(date).toISODate();
  // }

  renderInvoiceTitle = (item: InvoiceEntity): string => {
    let title: string;
    switch (item.invoice_type) {
      case InvoiceType.MONTHLY:
        const startDate: DateTime = parseDateTime(item.start_dt);
        title = _ti('invoice.content.monthly_invoice') + startDate.toFormat('MM-yyyy');
        break;
      case InvoiceType.BUY_NUMBER:
        title = _ti('invoice.content.new_numbers_invoice');
        break;
      case InvoiceType.MANUAL:
        title = _ti('invoice.content.manual_invoice');
        break;
      default:
        title = '';
    }

    return title;
  }
}
