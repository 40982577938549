import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { HotelRoomEntity } from '@wephone-core/model/entity/hotelroom';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';
import { DynamicFilterSource, IFlexTableConfig, DialogService, IFlexTableSidePannelOptions, regexSearch, FlexCRUDPageComponent } from '@wephone-utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { CreateMultipleHotelroomModalComponent } from '@wephone/modals/create-multiple-hotelroom-modal/create-multiple-hotelroom-modal.component';
import { CreateHotelroomModalComponent } from '@wephone/modals/create-hotelroom-modal/create-hotelroom-modal.component';
import * as _ from 'lodash';
import { HotelRoomLogRepository } from '@wephone-core/model/repository/hotel_room_log';
import { HotelWakeupCallRepository } from '@wephone-core/model/repository/hotel_wakeup_call';
import { EditHotelroomComponent } from '@wephone/components/edit-hotelroom/edit-hotelroom.component';
import { Router } from '@angular/router';
import { HotelRoomPhoneStateType } from '@wephone-core/model/entity/hotelroom.i';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';

@Component({
  selector: 'hotel-room-page',
  templateUrl: './hotel-room-page.component.html',
  styleUrls: ['./hotel-room-page.component.scss']
})
export class HotelRoomPageComponent extends FlexBasePage implements OnInit, OnDestroy {
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditHotelroomComponent
  };

  filterForm: FormGroup;
  filterValue: string;
  phoneState: number;
  phoneStateList: { text: string; value: number }[];
  activePms: EnterpriseCrmEntity;

  private hotelRoomRepo: HotelRoomRepository;

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    readonly em: EntityManager,
  ) {
    super();
    this.hotelRoomRepo = em.getRepository<HotelRoomRepository>('HotelRoomRepository');

    const dataSource = this.hotelRoomRepo.dataSource<HotelRoomEntity>();
    this.dataSource = new DynamicFilterSource(dataSource, this.filterFn);

    this.phoneStateList = [
      { text: _ti('hotelroom.content.phone_state_nophone'), value: -1 },
      { text: _ti('hotelroom.content.phone_state_disconnected'), value: 0 },
      { text: _ti('hotelroom.content.phone_state_connected'), value: 1 }
    ];

    this.tableConfig = {
      columns: [
        {
          name: 'room_number',
          label: _tk('hotelroom.content.room_number'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (i: HotelRoomEntity) => {
            return i.room_number;
          }
        },
        {
          name: 'room_state',
          label: _tk('hotelroom.content.room_state'),
          searchable: false,
          sortable: true,
          customSortFn: (i: HotelRoomEntity) => {
            return i.room_state ? _ti('hotelroom.content.room_state_occupied') : _ti('hotelroom.content.room_state_free');
          },
          exportedValue: i => i.room_state ? _ti('hotelroom.content.room_state_occupied') : _ti('hotelroom.content.room_state_free')
        },
        {
          name: 'client_name',
          label: _tk('hotelroom.content.client_name'),
          enableTooltip: true,
          lineClamp: 2,
          searchable: true,
          sortable: true,
          exportedValue: i => i.client_name || '',
        },
        {
          name: 'sipphone_is_online',
          label: _tk('hotelroom.content.phone_state'),
          searchable: false,
          sortable: true,
          exportable: false,
          customSortFn: (i: HotelRoomEntity) => {
            let state: string;
            switch (i.sipphone_is_online) {
              case 0:
                state = _ti('hotelroom.content.phone_state_disconnected');
                break;
              case 1:
                state = _ti('hotelroom.content.phone_state_connected');
                break;
              default:
                _ti('hotelroom.content.phone_state_nophone');
            }
            return state;
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          },
          {
            id: 'add_multiple',
            icon: 'group_add',
            callback: () => {
              return this.createNewMultiple();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.filterForm = this.fb.group({
      phone_state: [this.phoneState],
      filter_value: [this.filterValue]
    });

    this.addSubscription(
      this.filterForm.valueChanges.subscribe(this.onFormValueChange)
    );
  }

  async resolveData(): Promise<any> {
    const enterpriseCrmRepo = this.em.getRepository<EnterpriseCrmRepository>('EnterpriseCrmRepository');
    const pmses = await enterpriseCrmRepo.findAll();
    await this.hotelRoomRepo.findAll(false);
    await this.em.getRepository<HotelWakeupCallRepository>('HotelWakeupCallRepository').findAll(false);
    await this.em.getRepository<HotelRoomLogRepository>('HotelRoomLogRepository').findAll(false);

    this.activePms = pmses && pmses.find(i => i.is_authenticated && i.is_active) || undefined;
  }

  private onFormValueChange = (formValues: { phone_state: number; filter_value: string; }) => {
    this.phoneState = formValues.phone_state;
    this.filterValue = (formValues.filter_value || '').trim();
    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  filterFn = (item: HotelRoomEntity): boolean => {
    const phoneStatus = [HotelRoomPhoneStateType.CONNECTED, HotelRoomPhoneStateType.DISCONECTED, HotelRoomPhoneStateType.NO_PHONE];
    const checkPhoneState = !_.isUndefined(this.phoneState) && _.includes(phoneStatus, this.phoneState) || false;

    if (_.isEmpty(this.filterValue) && !checkPhoneState) {
      return true;
    }

    if (
      !_.isEmpty(this.filterValue) &&
      !(regexSearch(item.room_number, this.filterValue) || item.client_name && regexSearch(item.client_name, this.filterValue))
    ) {
      return false;
    }

    if (checkPhoneState && item.sipphone_is_online !== this.phoneState) {
      return false;
    }

    return true;
  }

  createNew(): void {
    try {
      this.dialogService.openDialog2(CreateHotelroomModalComponent, undefined, resp => {
        if (resp && resp.object_id) {
          this.gotoEditHotelRoomPage(resp.object_id);
          this.successToast(_ti('public.message.create_success'));
        }
      });
    } catch (e) {
      console.error('Create hotel room error', e);
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  createNewMultiple(): void {
    try {
      this.dialogService.openDialog2(CreateMultipleHotelroomModalComponent, undefined, resp => {
        if (resp) {
          this.successToast(_ti('public.message.create_success'));
        }
      });
    } catch (e) {
      console.error('Create multiple hotel room error', e);
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  bulkDelete(items: HotelRoomEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      () => {
        this.deleteHotelRooms(items);
      }
    );
  }

  private async deleteHotelRooms(items: HotelRoomEntity[]): Promise<void> {
    try {
      await this.hotelRoomRepo.bulkDelete(items);
      this.showInfo(_ti('public.message.delete_success'));
      this.flexCrud.closeSidePanel();
    } catch (e) {
      console.error('Delete hotel rooms error', e);
      this.showErrorMessage(e, _ti('public.message.delete_failure'));
    }
  }

  gotoEditHotelRoomPage(hotelRoomId: number): void {
    this.router.navigateByUrl(`hotelroom/${hotelRoomId}`);
  }

}
