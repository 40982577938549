import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslationService } from '@wephone-translation';
import { DateTime } from 'luxon';
import { parseDateTime } from '../../utils';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

export class DateFormatBasePipe {
  protected locale: string;
  constructor() {
    // const currentLang = TranslationService.getInstance().getCurrLanguage();
    // this.locale = currentLang ? currentLang.code : 'en';
    const transService = TranslationService.getInstance(); // .translate
    const lang = transService && transService.translate && transService.translate.currentLang || 'en';
    this.setLocale(lang);

    transService.translate.onLangChange.subscribe((langEvent: LangChangeEvent) => {
      this.setLocale(langEvent.lang);
    });
  }

  private setLocale(lang: string): void {
    this.locale = TranslationService.getInstance().getLocaleByLanguage(lang);
  }

  transform(value: any, args?: any): any {
    const d = parseDateTime(value);
    if (!d) {
      return value;
    }
    return this.doTransform(d.setLocale(this.locale), args);
  }

  doTransform(d: DateTime, args?: any): string {
    throw new Error('This method need to be overriden by subclass');
  }
}

// Format date using a format given by user

@Pipe({
  name: 'timeFormatCustom'
})
export class DateFormatPipe implements PipeTransform {
  protected locale: string;
  constructor() {
    // const currentLang = TranslationService.getInstance().getCurrLanguage();
    // this.locale = currentLang ? currentLang.code : 'en';
    // const transService = TranslationService.getInstance().translate;
    // const language = transService && transService.currentLang || 'en';
    // this.locale = language === 'pt_BR' ? 'pt' : language;
    this.locale = TranslationService.getInstance().getCurrentLocale();
  }

  transform(value: any, args?: any): any {
    const d = parseDateTime(value);
    if (!d) {
      return value;
    }
    return this.doTransform(d.setLocale(this.locale), args);
  }

  doTransform(d: DateTime, fmt: string): string {
    return d.toFormat(fmt);
  }
}

// Bellow is date format helpers pipes. For details about the formats, check this link
// https://github.com/moment/luxon/blob/master/docs/formatting.md

/**
 *  DATE ONLY formating
 */

@Pipe({
  name: 'timeFormatDateShort', // EN 10/14/1983	FR 14/10/1983
  pure: false
})
export class DateShortPipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.DATE_SHORT);
  }
}

@Pipe({
  name: 'timeFormatDateMed' // EN Oct 14, 1983 FR 14/10/1983
})
export class DateMedPipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.DATE_MED);
  }
}

@Pipe({
  name: 'timeFormatDateFull' // EN October 14, 1983	/ FR 14 octobre 1983
})
export class DateFullPipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.DATE_FULL);
  }
}

/**
 *  TIME ONLY formating
 */

@Pipe({
  name: 'timeFormatTimeSimple', // EN: 1:30 PM  FR: 13:30
  pure: false
})
export class TimeSimplePipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.TIME_SIMPLE);
  }
}

@Pipe({
  name: 'timeFormatTimeWithSeconds', // EN: 1:30:23 PM	FR: 13:30:23
  pure: false
})
export class TimeWithSecondsPipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.TIME_WITH_SECONDS);
  }
}

/**
 *  DATE and TIME formating
 */

@Pipe({
  name: 'timeFormatDateTimeShort', // EN: 10/14/1983, 1:30 PM	/ FR: 14/10/1983 à 13:30
  pure: false
})
export class DateTimeShortPipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.DATETIME_SHORT);
  }
}

@Pipe({
  name: 'timeFormatDateWithSeconds', // EN: 10/14/1983, 1:30:23 PM	/ FR: 14/10/1983, 1:30:23 PM
  pure: false
})
export class DateTimeShortWithSecondsPipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
  }
}

@Pipe({
  name: 'timeFormatDateTimeMed' // EN: Oct 14, 1983, 1:30 PM	/ FR: 14 oct. 1983 à 13:30
})
export class DateTimeMedPipe extends DateFormatBasePipe implements PipeTransform {
  doTransform(d: DateTime, args?: any): any {
    return d.toLocaleString(DateTime.DATETIME_MED);
  }
}
