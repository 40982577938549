import { datafield, Entity } from '@wephone-core/model/model';

export class SlackChannelEntity extends Entity {
  static object_type_id = 'slack_channel';

  public id: number;
  @datafield
  public enterprise_id: number;
  @datafield
  public public_id: string;
  @datafield
  public access_token: string;
  @datafield
  public team_id: string;
  @datafield
  public team_name: string;
  @datafield
  public scope: string;
  @datafield
  public incoming_webhook_channel: string;
  @datafield
  public incoming_webhook_url: string;
  @datafield
  public is_authenticated: number;
  @datafield
  public is_active: number;
}
