<div fxFill fxLayout="column" *ngIf="form">
  <div class="wrapper">
    <div class="col-left" [ngClass.xs]="{'hidden': true}">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let key of step_keys; let i=index">
            <a (click)="mainArea.scrollToElement(key)" class="step"
              [ngClass]="{'active': mainArea.activeAnchor === key}">
              <span class="circle">{{ i }}</span>
              <span class="label">{{ steps[key] | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-right activeAnchor wrapper-button" flexAnchorArea #mainArea="flexAnchorArea">
      <mat-toolbar class="toolbar">
        <div fxLayout="row wrap" class="md-grey-100" fxLayoutAlign="center center">
          <ng-container *ngIf="!campaign.active;else ActiveCampaignBlock">
            <button (click)="enable()" mat-icon-button color="accent" [matTooltip]="'campaign.active_campaign'|translate" data-ci="campaign-activate">
              <mat-icon [attr.aria-label]="'campaign.active_campaign'|translate">play_circle_filled</mat-icon>
            </button>
          </ng-container>

          <ng-template #ActiveCampaignBlock>
            <button (click)="disable()" mat-icon-button color="primary" [matTooltip]="'campaign.deactive_campaign'|translate" data-ci="campaign-deactivate">
              <mat-icon [attr.aria-label]="'campaign.deactive_campaign'|translate">pause_circle_filled</mat-icon>
            </button>
          </ng-template>

          <button (click)="openDialogImportContactPhone()" mat-icon-button [matTooltip]="'call_queue_edit.import_contacts'|translate">
            <mat-icon [attr.aria-label]="'call_queue_edit.import_contacts'|translate">import_contacts</mat-icon>
          </button>

          <button (click)="openDialogViewParticularAgents()" mat-icon-button [matTooltip]="'call_queue_edit.view_particular_agents'|translate">
            <mat-icon [attr.aria-label]="'call_queue_edit.view_particular_agents'|translate">people</mat-icon>
          </button>

          <button (click)="export()" mat-icon-button matTooltip="{{'public.export'|translate}}">
            <mat-icon attr.aria-label="{{'export_callcenter_data.title.export_call'|translate}}">save_alt</mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <div class="inner">
        <form [formGroup]="form" class="form-change-highlight">

          <div class="option-group-container" id="step1" #step1 anchorPart>
            <h2>{{ 'campaign.tab_campaign_attributes' | translate }}</h2>
            <div class="sub-form-wrapper">
              <app-outcallcampaign-general #generalEditor [hidden]="!campaign" [campaign]="campaign" [queue]="queue"
                (formValueChanges)="onFormValueChange()">
              </app-outcallcampaign-general>
            </div>
          </div>
          <div class="my-1">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

          <div id="step2" #step2 anchorPart>
            <h2>{{ 'call_queue_edit.section.main_agents' | translate }}</h2>
            <div class="sub-form-wrapper" data-ci="queue_agents_main">
              <!-- <app-outcallcampaign-agent [hidden]="!queue" [queue]="queue"></app-outcallcampaign-agent> -->
              <queue-agent-list-form-input formControlName="queue_agents_main"
                [queueId]="queue.id" [group]="queue.group" [isExclusive]="queue.is_exclusive"
                [title]="'call_queue.content.main_agent_list'|translate" [allowRingAll]="false" ngDefaultControl>
              </queue-agent-list-form-input>
              <mat-error *ngIf="form.get('queue_agents_main').invalid && (form.get('queue_agents_main').dirty || form.get('queue_agents_main').touched)">
                {{ 'form.validator.field_required'|translate }}
              </mat-error>
            </div>
          </div>
          <div class="my-1">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

          <div id="step3" #step3 class="block-wrapper" anchorPart>
            <h2>{{ 'campaign.tab_campaign_contacts' | translate }}</h2>
            <div class="sub-form-wrapper">
              <ng-container *ngIf="hasContactItems;else NoContactItems">
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="full-width">
                  <mat-card class="mat-card-flat agent-item campaign-item"
                    *ngFor="let item of contactItems | slice: 0: limitDisplayContacts">
                    <span class="name font-weight-bold" matTooltip="{{ item.name }}" matTooltipPosition="above">{{
                      item.called_number|displayPhoneNumber }}&nbsp;</span>
                  </mat-card>
                  <button type="button" mat-stroked-button color="accent" (click)="openDialogContactPhone()" data-ci="open-contact-phone-dialog">
                    <span *ngIf="contactItems.length <= limitDisplayContacts;else LabelMoreItem">{{ 'call_queue_edit.open_contacts'|translate }}</span>
                    <ng-template #LabelMoreItem>{{ 'call_queue_edit.open_more_contacts'|translate:{more_number:(contactItems.length-limitDisplayContacts)} }}</ng-template>
                  </button>
                </div>
              </ng-container>
              <ng-template #NoContactItems>
                <button type="button" mat-stroked-button color="accent" (click)="openDialogImportContactPhone()">
                  {{ 'call_queue_edit.import_contacts'|translate }}
                </button>
              </ng-template>
            </div>
          </div>
          <div class="my-1">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

          <div id="step4" #step4 class="block-wrapper" anchorPart>
            <h2>{{ 'call_queue.content.tab_advanced' | translate }}</h2>
            <div class="sub-form-wrapper">
              <app-outcallcampaign-advance #advancedEditor [hidden]="!queue" [queue]="queue" (formValueChanges)="onFormValueChange()">
              </app-outcallcampaign-advance>
            </div>
          </div>
          <div class="my-1">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
