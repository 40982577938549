import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class LiveQoSWatchRepository extends BaseRepository {
  ENTITY_CLASS = LiveQoSWatchEntity;
  protected URL_PREFIX: string = 'liveqoswatch';
  private URL_GET_LIVEQOSWATCH_LIST: string = 'list';

  createAndSave(object_data: any = null, extra_data: any = null) {
    const obj = this.create(object_data);
    return this.save(obj, extra_data);
  }

  save(item: IEntity, extra_data: { [id: string]: any } = null, attr_group: string = null) {
    return super.save(item, extra_data, attr_group).then(ret => {
      return this.reload().then(() => {
        return ret;
      });
    });
  }

  saveAttrs(item: IEntity, attr_list: Array<string>, extra_data: { [id: string]: any } = null) {
    return super.saveAttrs(item, attr_list, extra_data).then(ret => {
      return this.reload().then(() => {
        return ret;
      });
    });
  }

  protected getRemoteData(use_cache = true, filters: any = null) {
    const url = this.getUrl(this.URL_GET_LIVEQOSWATCH_LIST);
    if (use_cache && this.object_list) {
      return Promise.resolve(this.object_list);
    }
    return CommonAPI.wsGet(url, {}).then((updated_list: any) => {
      //update LiveQoSWatchEntity list
      for (let new_obj_data of updated_list) {
        let item = _.find(this.object_list, { id: new_obj_data.id }) || null;
        if (item) {
          item.setObjectData(new_obj_data);
          continue;
        }
        //create new object
        let new_obj = this.create();
        new_obj.setObjectData(new_obj_data, false);
        this.object_list.push(new_obj);
      }
      this._data_loaded = true;
      return this.object_list;
    });
  }
}

import { LiveQoSWatchEntity } from '@wephone-core/model/entity/liveqoswatch';
import { IEntity } from '@wephone-core/model/model.interface';
import { CommonAPI } from '@wephone-core/service/common_api';
