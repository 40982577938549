import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateSelectorPipe } from './translateSelector';
import { parseDateTime, utcNow } from '../utils';

@Pipe({
  name: 'time_since_as_text'
})
export class TimeSinceAsTextPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {
  }

  transform(timeObj: any): any {
    const time = parseDateTime(timeObj);

    if (time) {
      let ret;

      const now = utcNow();
      let n = Math.floor(now.diff(time, 'years').years);
      if (n >= 1) {
        ret = this.translate('time_since.years_ago', { n }, n);

        return ret;
      }

      n = Math.floor(now.diff(time, 'months').months);
      if (n >= 1) {
        return this.translate('time_since.months_ago', { n }, n);
      }

      n = Math.floor(now.diff(time, 'days').days);
      if (n >= 1) {
        return this.translate('time_since.days_ago', { n }, n);
      }

      n = Math.floor(now.diff(time, 'hours').hours);
      if (n >= 1) {
        return this.translate('time_since.hours_ago', { n }, n);
      }

      n = Math.floor(now.diff(time, 'minutes').minutes);
      if (n >= 1) {
        return this.translate('time_since.minutes_ago', { n }, n);
      }

      return this.translate('time_since.few_seconds_ago');
    }
  }

  private translate(key, params: any = {}, n?: any): string | any {
    let text = this.translateService.instant(key, params);
    text = new TranslateSelectorPipe().transform(text, n);
    return text;
  }
}
