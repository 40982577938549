import { Injectable } from '@angular/core';
import { countryDefaultLocales } from '@wephone-core/constants/locales';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { SingletonBase } from '@wephone-utils';
import * as _ from 'lodash';
import * as ct from 'countries-and-timezones';
import { EntityManager } from './entity_manager';

interface TimezoneCt {
  name: string; // 'Mexico/BajaSur',
  country: string; // 'MX',
  utcOffset: number; // -420,
  utcOffsetStr: string; // '-07:00',
  dstOffset: number; // -360,
  dstOffsetStr: string; // '-06:00',
  aliasOf: string; // 'America/Mazatlan'
}
// interface TimezoneCt extends ct.Timezone {
  
// }

export interface CountryGlobal {
  code2: string;
  name: string;
  timezones: string[];
}


export interface Timezone {
  label: string;
  value: string;
}
@Injectable()
export class LocalManager extends SingletonBase {

  // Map country code and default locale
  static countryDefaultLocales: any = {
    fr: 'fr',
    vn: 'vi'
  };

  static getInstance(): LocalManager {
    return super.getInstance();
  }

  getDefaultLocale(countryCode: string): string {
    return LocalManager.countryDefaultLocales[countryCode] ? LocalManager.countryDefaultLocales[countryCode] : 'en';
  }

  /**
   * Mapping language_code:country_code (official country's language)
   */
  getUILanguageList(): any {
    return {
      en: 'us',
      fr: 'fr',
      vi: 'vn'
    };
  }

  getLangForDefaultFileEntry(): string {
    const defaultLang = 'en';
    const myEnterprise: EnterpriseEntity = (EntityManager.getRepository('EnterpriseRepository') as EnterpriseRepository).getMyEnterprise();
    let langCode: string = myEnterprise && this.getLangCodeFromCountry(myEnterprise.country) || defaultLang;
    if (!_.includes(['fr', 'en', 'vi'], langCode)) {
      langCode = defaultLang;
    }
    return langCode;
  }

  getLangCodeFromCountry(countryCode2: string): string {
    if (!countryCode2) {
      return;
    }

    const country_code: string = countryCode2.toLowerCase();
    return countryDefaultLocales[country_code];
  }

  /**
   * @param countryCode : country-code-2-letters
   */
  getTimezonesByCountry(countryCode: string): Timezone[] {
    // const code2 = countryCode.toLowerCase();
    // const list = this.getTimezoneConfig();

    // const timezones = list[code2];
    // if (!timezones) {
    //   console.error('Not found country code', countryCode);
    //   return [];
    // }
    // const ret: Array<Timezone> = [];
    // for (const tz of timezones) {
    //   ret.push({
    //     value: Object.keys(tz)[0],
    //     label: tz[Object.keys(tz)[0]]
    //   });
    // }
    // return ret;
    const timezones: TimezoneCt[] = ct.getTimezonesForCountry(countryCode.toUpperCase());

    if (!timezones || !timezones.length) {
      return;
    }
    // const timezone = timezones[0];
    const ret: Timezone[] = [];
    for (const tz of timezones) {
      ret.push({
        value: tz.name,
        label: tz.name
      });
    }
    // return timezones.map(x => {
    //     label: x.name,
    //     value: x.name
    //   });
    return ret;
  }

  // getTimezoneConfig(): any {
  //   return countryTimezoneMap;
  // }

  // [br: [
  //   {
  //       'AMERICA/ARAGUAINA': 'America > Araguaina'
  //   }, ]
  // ]
  getAllTimezones(): Timezone[] {
    let ret = [];
    const list: string[] = this.getCountryCodeList();
    for (const countryCode of list) {
      ret = ret.concat(this.getTimezonesByCountry(countryCode));
    }
    return ret;
  }

  getCountryByCode2(code2: string): CountryGlobal {
    const countries: CountryGlobal[] = this.getAllCountries();
    return countries.find(x => x.code2 === code2);
  }

  getAllCountries(): CountryGlobal[] {
    const countryList: {[key: string]: {
      id: string;
      name: string;
      timezones: string[];
    }} = ct.getAllCountries();

    const ret: CountryGlobal[] = [];
    for (const key of Object.keys(countryList)) {
      const item = countryList[key];
      ret.push({
        code2: item.id,
        name: item.name,
        timezones: item.timezones
      });
    }

    return ret;
  }

  private standardizeTz(tz: string): string {
    let timezone: string = tz.toLowerCase();
    timezone = timezone.charAt(0).toUpperCase() + timezone.slice(1);
    const capSplitChars = ['/', '_'];
    for (const c of capSplitChars) {
      const pos = timezone.indexOf(c);
      if (pos !== -1 && pos !== timezone.length) {
        timezone = timezone.substring(0, pos + 1) + timezone.charAt(pos + 1).toUpperCase() + timezone.slice(pos + 2);
      }
    }

    return timezone;
  }

  /**
   *
   * @param value : timezone, e.g: AMERICA/ARAGUAINA
   */
  getTimezoneByValue(value: string): Timezone {
    // if (value) {
    //   const timezones = this.getAllTimezones();
    //   return timezones.find(x => x.value.toLowerCase() === value.toLowerCase());
    // }
    if (!value) {
      return;
    }

    let timezone: TimezoneCt = ct.getTimezone(value);
    if (!timezone) {
      // Should standardize timezone value because in some cases timezone stored in database was uppercase
      const tzStd: string = this.standardizeTz(value);
      timezone = ct.getTimezone(tzStd);
    }

    return timezone ? {
      label: timezone.name,
      value: timezone.name
    } : undefined;
  }

  /**
   *
   * @returns Array of country-code (uppercase)
   */
  private getCountryCodeList(): string[] {
    // return supportedCountryList;
    return Object.keys(ct.getAllCountries()); //.map(x => x.toLowerCase())
  }
}
