import * as _ from 'lodash';

import { Component, OnInit, ElementRef, Optional, Self, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
import { FlexMatInputWrapper, TreeHelper } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { UserService } from '@wephone/services/user.service';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';

@Component({
  selector: 'flex-select-campaign-input',
  templateUrl: './flex-select-campaign-input.html',
  styleUrls: ['./flex-select-campaign-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectCampaignInput }]
})
export class FlexSelectCampaignInput extends FlexMatInputWrapper implements OnInit, OnChanges, MatFormFieldControl<any> {
  @Input() groupId: number;
  @Input() multiple: boolean;
  @Input() showCheckAll: boolean;
  @ViewChild('flexSelectTreeInput') flexSelectTreeInput: FlexSelectTreeInput;

  private readonly campaignRepo: OutcallCampaignRepository;
  private hasInitilized = false;
  private _campaigns: OutcallCampaignEntity[];

  myControl = new FormControl();
  campaignNodes: FlexSelectTreeNode[];

  constructor(
    elRef: ElementRef,
    @Optional() @Self() ngControl: NgControl,
    em: EntityManager,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
  ) {
    super(ngControl, elRef);
    this.campaignRepo = em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loadCampaigns();
    this.hasInitilized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasInitilized && changes['groupId']) {
      this.loadCampaigns();
      const validIds: number[] = [];
      const ids: number[] = this.wrappedControl.value;
      for (const id of ids) {
        if (!_.isEmpty(this._campaigns) && !!this._campaigns.find(x => x.id === id) && !_.includes(validIds, id)) {
          validIds.push(id);
        }
      }
      this.writeValue(validIds);
    }
  }

  // writeValue(val: any): void {
  //   if (val != this.value) {
  //     super.writeValue(val);
  //     this.flexSelectTreeInput.writeValue(val);
  //   }
  // }

  private getLimitedGroupIds(): number[] {
    const currentUser = this.authService.getUser();
    let limitedGroupIds = [];
    if (!currentUser.isAdmin()) {
      const managedGroups: UserGroupEntity[] = this.userService.getManagedGroups(currentUser);
      limitedGroupIds = managedGroups.map(x => x.id);
      if (this.groupId) {
        limitedGroupIds = limitedGroupIds.filter(gId => gId === this.groupId);
      }
    }

    return limitedGroupIds;
  }

  protected loadCampaigns(): void {
    const limitedGroupIds: number[] = this.getLimitedGroupIds();

    if (!_.isEmpty(limitedGroupIds)) {
      this._campaigns = this.getCampaignsByGroupIds(limitedGroupIds);
    } else {
      this._campaigns = this.campaignRepo.getObjectList();
    }

    const nodeList = this._campaigns.map(campaign => {
      return {
        id: campaign.id,
        name: campaign.name,
        parent_id: undefined
      };
    });
    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.campaignNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-campaign-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }

  protected getCampaignsByGroupIds(groupIds: number[]): OutcallCampaignEntity[] {
    const ret: OutcallCampaignEntity[] = [];
    for (const groupId of groupIds) {
      const campaigns: OutcallCampaignEntity[] = this.campaignRepo.getObjectListByGroupId(groupId);
      for (const c of campaigns) {
        if (!ret.find(i => i.id === c.id)) {
          ret.push(c);
        }
      }
    }
    return ret;
  }

  stripHtml(html: string): string {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
