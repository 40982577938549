<mat-select #durationSelect [formControl]="flexDurationControl" [placeholder]="placeholder" [compareWith]="compareWith"
  [disableControl]="disabled">
  <mat-select-trigger>
    <span *ngIf="predefined_values && predefined_values[flexDurationControl.value]; else DisplayTime">
      {{ predefined_values[flexDurationControl.value] }}
    </span>
    <ng-template #DisplayTime>
      <span *ngIf="refreshed">{{ (flexDurationControl.value||0) | seconds_as_duration_text }}</span>
    </ng-template>

  </mat-select-trigger>
  <div class="px-1">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="mt-1">
      <div fxFlex="80px">
        <mat-form-field class="full-width">
          <mat-label>{{ 'public.symbol.minute'|translate }}</mat-label>
          <input data-ci="duration-minutes" matInput [(ngModel)]="customMinute" type="number" min="0"
            placeholder="{{ 'public.symbol.minute'|translate }}" class="text-center">
        </mat-form-field>
      </div>
      <div fxFlex="80px">
        <mat-form-field class="full-width">
          <mat-label>{{ 'public.symbol.second'|translate }}</mat-label>
          <input data-ci="duration-seconds" matInput [(ngModel)]="customSecond" type="number" min="0"
            placeholder="{{ 'public.symbol.second'|translate }}" class="text-center">
        </mat-form-field>
      </div>
      <div fxFlex>
        <button data-ci="btn-duration-apply" mat-stroked-button color="primary"
          (click)="setCustomOption(customMinute, customSecond)" class="border-button float-right">{{
          'public.apply'|translate }}</button>
      </div>
    </div>
  </div>
  <mat-option [hidden]="hasPredefinedSelected" #durationCustomOption [disabled]="hasPredefinedSelected">
    <span *ngIf="refreshed">{{ flexDurationControl.value | seconds_as_duration_text }}</span>
  </mat-option>
  <mat-option [value]="key" *ngFor="let key of predefinedValues">
    {{ predefined_values[key] }}
  </mat-option>
</mat-select>
