import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { _tk, _ti } from '@wephone-translation';
import { EntityManager } from '@wephone-core/wephone-core.module';
export class CalendarUtils {

  // constructor() { }

  get em(): EntityManager {
    return EntityManager.getInstance();
  }

  getNewName(): string {
    const defaultName: string = _ti('opening_hour_calendar.default_calendar_name');
    let newName = defaultName;
    let i = 1;

    while (this.checkNameCalendar(newName)) {
      newName = defaultName + ' ' + i;
      i++;
    }

    return newName;
  }

  checkNameCalendar(name: string): boolean {
    const calendarNames: Array<string> = this.em.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository')
      .getObjectList()
      .map((c: OpeningHourCalendarEntity) => c.name.toLowerCase());
    return calendarNames.indexOf(name.toLowerCase()) > -1 ? true : false;
  }
}
