import { Component, OnInit, Input, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { EditingComponent, ToastService, joiValidator, DialogService } from '@wephone-utils';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { FormGroup, FormBuilder, FormArray, FormControl, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidConfigWizardModalComponent } from '@wephone/modals/did/did-config-wizard-modal/did-config-wizard-modal.component';
import { Router } from '@angular/router';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { _tk, _ti } from '@wephone-translation';
import { CallDestinationComponent } from '../call-destination/call-destination.component';
import { IRoutingRuleCondition } from '@wephone-core/model/entity/crm_routing_rule.i';
import { IRoutingAppDestinationData } from '@wephone-core/routing-app/routing-app.interface';

@Component({
  selector: 'app-edit-crm-routing',
  templateUrl: './edit-crm-routing.component.html',
  styleUrls: ['./edit-crm-routing.component.scss']
})
export class EditCrmRoutingComponent extends EditingComponent implements OnInit {
  @Input() editingItem: CrmRoutingRuleEntity;

  // @Output() readonly change = new EventEmitter<any>();
  @ViewChildren('callDestinationComps') callDestinationComps: QueryList<CallDestinationComponent>;
  crmRouting: CrmRoutingRuleEntity;
  // crmRoutingDisplayed: CrmRoutingRuleEntity;
  crmList: CrmRoutingRuleEntity[];
  virtualDid: DidEntity;
  dids: { [key: number]: DidEntity; } = {};
  isUpdatedLayout = false;

  private crmRoutingRuleRepo: CrmRoutingRuleRepository;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly em: EntityManager,
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
  ) {
    super();

    this.crmRoutingRuleRepo = this.em.getRepository('CrmRoutingRuleRepository');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    await this.resovelData();

    this.crmList = this.em.getRepository('EnterpriseCrmRepository').getObjectList();
    this.crmRouting = _.cloneDeep(this.editingItem);
    this.setCrmRoutingDisplayed(this.crmRouting);
    this.initFormGroup();
  }

  private async resovelData(): Promise<void> {
    await this.em.getRepository('SipTrunkRepository').loadObjectList();
  }

  private setCrmRoutingDisplayed(crmRouting: CrmRoutingRuleEntity): void {
    if (!crmRouting) {
      return;
    }
    // this.crmRoutingDisplayed = _.cloneDeep(crmRouting);
    this.virtualDid = this.dids[crmRouting.id] || new DidEntity();
    this.dids[crmRouting.id] = this.virtualDid;
    this.virtualDid.action_office_hour = crmRouting.dest_default;
    this.isUpdatedLayout = !this.isUpdatedLayout;
  }

  private initFormGroup(): void {
    const routingDataGroups: FormGroup[] = this.crmRouting.routing_data && this.getRoutingData(this.crmRouting.routing_data) || [];
    this.form = this.fb.group({
      name: [this.crmRouting.name, joiValidator(Joi.string().max(128).required())],
      crm: [this.crmRouting.crm_id],
      dest_default: [this.crmRouting.dest_default],
      routing_data: this.fb.array(routingDataGroups)
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(() => {
        this.onFormValueChange();
      })
    );
  }

  private getFormResetData(): Object {
    return {
      name: this.crmRouting.name,
      crm: this.crmRouting.crm_id,
      routing_data: this.crmRouting.routing_data
    };
  }

  resetForm(): void {
    this.resetRoutingDataControls();
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.setCrmRoutingDisplayed(this.editingItem);
    this.onFormValueChange();
  }

  private createRoutingDataItem(routingData?: IRoutingRuleCondition): FormGroup {
    return this.fb.group({
      cond: [routingData && routingData.cond || undefined],
      dest: [routingData && routingData.dest || undefined]
    });
  }

  get routingDataControls(): FormArray {
    return this.form.get('routing_data') as FormArray;
  }

  addRoutingDataItem(): void {
    const newRoutingData: IRoutingRuleCondition = {
      cond: {
        op: 'equals_to',
        field: 'contact_type',
        operand: 'lead'
      },
      dest: undefined
    };
    this.routingDataControls.markAsDirty();
    this.routingDataControls.push(this.createRoutingDataItem(newRoutingData));
    this.routingDataControls.markAsTouched();
  }

  private getRoutingData(routingData: IRoutingRuleCondition[]): FormGroup[] {
    const ret: FormGroup[] = [];
    for (const item of routingData || []) {
      ret.push(this.createRoutingDataItem(item));
    }

    return ret;
  }

  removeRoutingData(index: number): void {
    this.routingDataControls.markAsDirty();
    this.routingDataControls.removeAt(index);
  }

  private resetRoutingDataControls(): void {
    while (this.routingDataControls.length > 0) {
      this.routingDataControls.removeAt(0);
    }

    for (const routingData of _.cloneDeep(this.crmRouting.routing_data || [])) {
      this.routingDataControls.push(this.createRoutingDataItem(routingData));
    }
  }

  async changeCallDestination(): Promise<any> {
    try {
      const virtualDid: DidEntity = this.em.getRepository('DidRepository').create() as DidEntity;
      virtualDid.number = this.crmRouting.name;
      virtualDid.action_office_hour = this.crmRouting.dest_default;
      const data = {
        call_destination: virtualDid.action_office_hour,
        is_out_of_office_hours: false,
        save_did: false,
        routing_for_crm: true,
        full_option: true
      };

      const dialogRef = this.dialogService.openDialog2(DidConfigWizardModalComponent, { data });
      const updatedConference: { app?: any, masterDid?: DidEntity } = await dialogRef.afterClosed().toPromise();
      const destDefault = {
        application: updatedConference.app.APP_NAME,
        params: updatedConference.app.appParams
      };
      this.crmRouting.dest_default = destDefault;
      this.form.get('dest_default').markAsDirty();
      this.form.get('dest_default').setValue(destDefault);
      this.setCrmRoutingDisplayed(this.crmRouting);

    } catch (e) {
      return false;
    }
  }

  gotoPage(item: any, state: string): void {
    this.router.navigate([state, item.id], { skipLocationChange: false });
  }

  // changeDidRoutedData = () => {
  //   if (this.crmRouting.routed_queue) {
  //     this.gotoPage(this.crmRouting.routed_queue, 'queues');
  //   }

  //   if (this.crmRouting.routed_conference) {
  //     this.gotoPage(this.crmRouting.routed_conference, 'conferences');
  //   }

  //   if (this.crmRouting.routed_sipphone) {
  //     this.gotoPage(this.crmRouting.routed_sipphone, 'sip-phones');
  //   }

  //   if (this.crmRouting.routed_menu) {
  //     this.gotoPage(this.crmRouting.routed_menu, 'ivr/ivr-menus');
  //   }

  //   if (this.crmRouting.routed_remote_application) {
  //     this.gotoPage(this.crmRouting.routed_remote_application, 'settings/ivr-remote-apps');
  //   }
  // }

  updateCallDestination(destValue: IRoutingAppDestinationData, destControl: FormControl | AbstractControl): void {
    const value = destControl.value || {};
    _.extend(value, { dest: destValue });

    this.routingDataControls.markAsDirty();
    destControl.setValue(value);
  }

  async submitForm(): Promise<void> {
    const updatedCrmRouting = this.crmRoutingRuleRepo.create() as CrmRoutingRuleEntity;
    updatedCrmRouting.setObjectData({
      id: this.crmRouting.id,
      name: this.form.get('name').value,
      crm_id: this.form.get('crm').value,
      dest_default: this.crmRouting.dest_default,
      // routing_data: this.form.get('routing_data').value
    });

    try {
      if (!this.formIsValid()) {
        throw new Error('Form data invalid');
      }

      for (const callDestinationComp of this.callDestinationComps.toArray()) {
        if (!callDestinationComp.validParam()) {
          this.cdr.markForCheck();
          throw new Error('Form field routing_data is invalid');
        }
      }
    } catch (error) {
      console.error('Form validate error: ', error);
      this.toast.showError(_ti('form.validator.data_invalid'));
      return;
    }

    try {
      // if (!this.form.get('name').valid) {
      //   console.error('Form field name is invalid');
      //   return;
      // }

      // for (const callDestinationComp of this.callDestinationComps.toArray()) {
      //   if (!callDestinationComp.validParam()) {
      //     this.cdr.markForCheck();
      //     console.error('Form field routing_data is invalid');
      //     this.toast.showError(_ti('form.validator.data_invalid'));
      //     return;
      //   }
      // }

      const attrList = ['name', 'crm_id', 'dest_default', 'routing_data'];
      const extraData = {
        routing_data: this.form.get('routing_data').value
      };

      const returned = await this.crmRoutingRuleRepo.saveAttrs(updatedCrmRouting, attrList, extraData);

      this.crmRouting = this.crmRoutingRuleRepo.getObjectById(returned.id);
      this.resetForm();

      this.toast.show(_ti('public.message.update_success'));
    } catch (error) {
      console.error('Update CRM Routing Rule error: ', error);
      this.toast.showError(_ti('public.message.update_failure'));
    }
  }
}
