import { Directive, ElementRef, HostListener, HostBinding, Input, Renderer2 } from '@angular/core';
// import { FlexBaseComponent } from '../..//flex-base-component';

@Directive({
  selector: '[appFreezeClick]'
})
export class FreezeClickDirective { // extends FlexBaseComponent

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('click', ['$event.target']) onClick(btn): void {
    console.log('click started', btn);
    this.el.nativeElement.setAttribute('disabled', true);
    setTimeout(() => {
      // this.el.nativeElement.setAttribute('disabled', false);
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      console.log('click ended');
    }, 3000);
  }

}
