import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { CallQueueEntity } from '../entity/callqueue';

export class CallQueueAgentLinkRepository extends BaseRepository {
  ENTITY_CLASS = CallQueueAgentLinkEntity;
  protected RELATED_REPOSITORIES = ['callqueue', 'agent'];
  protected USE_PUSH_API = true;
  private queuesByAgentIdMap;

  getObjectByQueueId(callqueue_id: number, is_backup = undefined): CallQueueAgentLinkEntity[] {
    const ret = [];
    for (const link of this.object_list) {
      if (link.queue_id === callqueue_id) {
        if (
          is_backup === undefined ||
          (is_backup === true && link.is_backup) ||
          (is_backup === false && link.is_backup === 0)
        ) {
          ret.push(link);
        }
      }
    }
    return ret;
  }

  getObjectByAgentQueue(agent_id: number, queue_id: number): CallQueueAgentLinkEntity {
    for (const link of this.getObjectList()) {
      if (link.queue_id === queue_id && link.agent_id === agent_id) {
        return link;
      }
    }
    return null;
  }

  getQueueListByAgentId(agent_id): CallQueueEntity[] {
    return this.queuesByAgentIdMap[agent_id.toString()] || [];
  }

  isAgentInQueue(agent_id: number, queue_id: number): boolean {
    if (!this.queuesByAgentIdMap || !this.queuesByAgentIdMap[`${agent_id}`]) {
      return false;
    }
    return this.queuesByAgentIdMap[agent_id.toString()].find(x => x.id === queue_id) ? true : false;
  }

  onRemoteDataUpdated(): void {
    this.queuesByAgentIdMap = {};
    const queue_repository = CallQueueRepository.getInstance();
    // for (const i of Object.keys(this.queuesByAgentIdMap)) {
    //   this.queuesByAgentIdMap[i].length = 0; // Remove all elements from list
    // }
    for (const link of this.object_list) {
      const q = queue_repository.getObjectById(link.queue_id);
      if (q) {
        if (link.agent_id in this.queuesByAgentIdMap) {
          this.queuesByAgentIdMap[link.agent_id.toString()].push(q);
        } else {
          this.queuesByAgentIdMap[link.agent_id.toString()] = [q];
        }
      } else {
        console.error('Invalid queue id in queue agent link: ', link.queue_id);
      }
    }
  }
}
