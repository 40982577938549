import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { _ti } from '@wephone-translation';
import { DidEntity } from '@wephone-core/model/entity/did';
import { Deferred } from 'ts-deferred';
import { DialogService } from '@wephone-utils';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';

@Injectable()
export class CrmRoutingRuleService {
  // Constructor
  constructor(
    private dialogService: DialogService,
  ) { }

  async getConfirmDeleteCrmRoutingRules(items: CrmRoutingRuleEntity[]): Promise<boolean> {
    let msg: string = _ti('user.title.delete');
    const msgs: string[] = [];
    let routedDids: DidEntity[] = [];
    for (const item of items) {
      const routedsDids: DidEntity[] = item.routedDids;
      if (routedsDids && routedsDids.length) {
        routedDids = _.concat(routedDids, routedsDids);
      }
    }

    if (routedDids.length) {
      let crmRoutingRuleObjects: CrmRoutingRuleEntity[] = items.filter(o => o.routedDids.length);
      crmRoutingRuleObjects = _.uniqBy(crmRoutingRuleObjects, o => o.id);

      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', {
        dependent_numbers: routedDids.map(x => x.display_number).join(', '),
        objects: crmRoutingRuleObjects.map(x => x.name).join(', ')
      }));
    }

    const ret = new Deferred<boolean>();
    const canDelete = !msgs.length;

    if (!canDelete) {
      msgs.push(_ti('public.message.cannot_continue_action_with_dependent_items'));
      msg = msgs.join('\n');

      await this.dialogService.showAlert(_ti('dialogs.warning'), msg);

      ret.resolve(canDelete);
      return ret.promise;
    }

    await this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      msg,
      () => {
        ret.resolve(true);
      }
    );
    return ret.promise;
  }
}
