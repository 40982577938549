import { datafield, Entity } from '@wephone-core/model/model';
import { SubscriptionPackRepository } from '@wephone-core/model/repository/subscription_pack';
import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
import { parseDateTime } from '@wephone-utils/utils/time-util';
import { DateTime } from 'luxon';

export class SubscriptionEntity extends Entity {
  public static object_type_id: string = 'subscription';

  public id: number;
  @datafield
  public pack_id: number;
  @datafield
  public enterprise_id: number;
  @datafield
  public billing_plan_id: number;
  @datafield
  public expired_dt: DateTime;
  @datafield
  public start_dt: any;
  // public subscription_pack: SubscriptionPackEntity;

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    if (this.expired_dt) {
      this.expired_dt = parseDateTime(this.expired_dt);
    }
    if (this.start_dt) {
      this.start_dt = parseDateTime(this.start_dt);
    }
    // if (this.pack_id) {
    //   this.subscription_pack = SubscriptionPackRepository.getInstance().getObjectById(this.pack_id);
    // }
  }

  get subscription_pack(): SubscriptionPackEntity {
    if (this.pack_id) {
      return SubscriptionPackRepository.getInstance().getObjectById(this.pack_id);
    }
  }

  //must run subscription_pack findAll before
  //    get subscription_pack() {
  //    	return SubscriptionPackRepository.getInstance().getObjectById(this.pack_id);
  //    }
}
