import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class DidOrderRepository extends BaseRepository {
  ENTITY_CLASS = DidOrderEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX: string = 'did_order';

  public getActiveByUserId(user_id: number): DidOrderEntity {
    for (let i of this.getObjectList()) {
      if (i.owner_id == user_id && i.state == DidOrderEntity.STATE_NOT_PAID) {
        return i;
      }
    }
  }
}

import { DidOrderEntity } from '@wephone-core/model/entity/did_order';
