<div fxFlexFill fxLayout="column" class="flex-container" *ngIf="is_ready">
  <div fxFlex="initial">
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.lt-md="5px">
      <ng-container *ngFor="let tab of period_list">
        <button class="btn-period" mat-raised-button [color]="tab.period == period ? 'accent' : 'primary'"
          (click)="changePeriod(tab.period)">{{ tab.label | translate }}</button>
      </ng-container>
    </div>

    <div *ngIf="period=='per_week'" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
      fxLayoutAlign.xs="start" class="mt-1">
      <div fxFlex="49" fxFlex.xs="auto" fxLayoutAlign="end center" fxLayoutAlign.xs="center">
        <mat-form-field>
          <input matInput [matDatepicker]="periodStartDtPicker" [(ngModel)]="period_start_date"
            [placeholder]="'public.from' | translate">
          <mat-datepicker-toggle matSuffix [for]="periodStartDtPicker"></mat-datepicker-toggle>
          <mat-datepicker #periodStartDtPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="49" fxFlex.xs="auto" fxLayoutAlign="start center" fxLayoutAlign.xs="center">
        <mat-form-field>
          <input matInput [matDatepicker]="periodEndDtPicker" [(ngModel)]="period_end_date"
            [placeholder]="'public.to' | translate">
          <mat-datepicker-toggle matSuffix [for]="periodEndDtPicker"></mat-datepicker-toggle>
          <mat-datepicker #periodEndDtPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="my-1">
      <!-- <mat-slide-toggle [(ngModel)]="include_outbound" (change)="refresh()" color="primary" class="px-1">
        <span class="fontfamily-bold">{{ 'livecall.content.include_outgoing_call' | translate }}</span>
      </mat-slide-toggle> -->
      <button mat-raised-button color="warn" (click)="refresh()">
        <mat-icon class="mr-1">loop</mat-icon>
        {{ 'public.reload' | translate }}
      </button>
    </div>

    <div *ngIf="has_hidden_group" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px" class="mb-1">
      <span class="mr-1">{{ 'livecall.actions.groups_hidden'|translate }}</span>
      <mat-chip *ngFor="let group_id of ui_pref_group_hidden" (removed)="set_ui_pref_group_hidden(group_id, false)">
        {{ get_group_name_by_id(group_id) }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" class="mb-1">

      <div fxFlex="320px" fxFlex.lt-md="none"
        fxLayout="row"
        fxLayoutAlign="start end">
        <h1 class="mb-0 pr-1 italic">{{ 'dashboard.content.time_period' | translate }}</h1>
        <span class="h1 font-weight-bold mat-text-primary time"
          *ngIf="call_filters.time_start && call_filters.time_end">
          <ng-container *ngIf="call_filters.time_start == '00:00' && call_filters.time_end == '00:00';else BlockTimePeriod">
            {{ 'dashboard.content.allday'|translate }}
          </ng-container>
          <ng-template #BlockTimePeriod>
            {{ call_filters.time_start }} - {{ call_filters.time_end }}
          </ng-template>
        </span>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <mat-chip-list fxFlex="400px" fxLayoutAlign="end center" *ngIf="filteredQueues.length">
          <mat-chip *ngFor="let queue of filteredQueues | slice: 0: 3" 
            [matTooltip]="queue.queue_name" (click)="filterChartData()">
            {{ queue.queue_name | truncate:12}}
          </mat-chip>
        </mat-chip-list>

        <button mat-icon-button (click)="filterChartData()" data-ci="filter-chart-button" class="ml-xs">
          <mat-icon>filter_list</mat-icon>
          <!-- {{ 'public.filter' | translate }} -->
          <span class="flex-notification-label" *ngIf="filteredQueues.length">{{ filteredQueues.length }}</span>
        </button>
      </div>

    </div>
  </div>


  <div fxFlex="grow" fxLayout="column" class="overview-scroll">
    <ng-container *ngFor="let g of grouped_queues">
      <div *ngIf="g.queues && g.queues.length || g.children && g.children.length">
        <app-graph-chart-group [hiddenGroups]="ui_pref_group_hidden" (onHidePanel)="toogleGroup($event)"
          [filteredGroupIds]="filteredGroupIds"
          [callFilters]="call_filters" [period]="period" [group]="g" [showEavesdrop]="showEavesdrop">
        </app-graph-chart-group>
      </div>
    </ng-container>
  </div>

</div>
