import { Component } from '@angular/core';
import { MenuService, MainMenuItem } from '../menu.service';
import { _tk, _ti } from '@wephone-translation';

const ACCOUNTANT_MENU_ITEMS: MainMenuItem[] = [
  {
    state: 'enterprise/invoice',
    name: _tk('menu.manage_invoice'),
    type: 'link',
    icon: 'description'
  },
];

@Component({
  selector: 'app-accountant-menu',
  templateUrl: './accountant-menu.component.html',
  styleUrls: ['./accountant-menu.component.scss'],
  providers: [MenuService]
})
export class AccountantMenuComponent {

  constructor(
    public menuService: MenuService
  ) { }

  get menus(): MainMenuItem[] {
    return ACCOUNTANT_MENU_ITEMS;
  }
}
