import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { SubscriptionPack } from './subscription.service.i';
import { SubscriptionEntity } from '@wephone-core/model/entity/subscription';

@Injectable()
export class SubscriptionService {
  static GET_SUBSCRIPTION_PACKS_URL = 'subscription_pack/public_packs';
  static SUBSCRIBE_SUBSCRIPTION_PACKS_URL = 'subscription_pack/subscribe';

  constructor(
    private httpEngine: HttpEngine) {
  }

  getSubscriptionPacks(locale: string): Promise<SubscriptionPack[]> {
    return this.httpEngine.apiGetV2(SubscriptionService.GET_SUBSCRIPTION_PACKS_URL, {lang: locale});
  }

  subscribeSubscriptionPack(packId: string, sourceId?: string, cardType?: string): Promise<SubscriptionEntity> {
    return this.httpEngine.apiPostV2(SubscriptionService.SUBSCRIBE_SUBSCRIPTION_PACKS_URL, {
      pack_id: packId,
      source_id: sourceId,
      type: cardType
    }, undefined, true);
  }
}
