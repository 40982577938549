<div fxFill fxLayout="column">
  <mat-toolbar class="mat-primary" fxLayout="row" fxLayoutAlign="start center">
    <div class="button-container" *ngIf="hasChanges">
      <button data-ci="save" mat-button class="border-white-button" (click)="submit()"
        [@slideInRight]="slideInRight">{{ 'public.save'|translate }}
      </button>
      <button data-ci="reset" mat-button class="border-white-button ml-1" (click)="reset()" [@slideInRight]="slideInRight">
        {{ 'public.button.cancel'|translate }}
      </button>
    </div>
    <div *ngIf="title">{{ title }}</div>
    <div class="ml-auto">
      <button id="side-pannel-more-btn" mat-button [matMenuTriggerFor]="appMenu" *ngIf="actions?.length">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #appMenu="matMenu" yPosition="below">
        <div [attr.data-ci]="action.id || ''" (click)="action.action()" mat-menu-item *ngFor="let action of actions">
          {{ action.label|translate }}
        </div>
      </mat-menu>
      <button (click)="maximizeToggle()" mat-icon-button>
        <mat-icon *ngIf="!maximize()">fullscreen</mat-icon>
        <mat-icon *ngIf="maximize()">fullscreen_exit</mat-icon>
      </button>
      <button data-ci="close" mat-button (click)="closeEditor()">
        <!-- <i class="material-icons text-white">close</i> -->
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div fxLayout="column" fxFlex="grow" class="side-pannel-content">
    <ng-template flex-anchor></ng-template>
  </div>
</div>
