import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class EnterpriseContactRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseContactEntity;
  protected URL_PREFIX: string = 'enterprise_contact/';

  public getObjectByPhoneNumber(phone_number: string) {
    for (let item of this.getObjectList()) {
      if (item.tel_work == phone_number || item.tel_home == phone_number) {
        return item;
      }
    }
    return null;
  }
}

import { EnterpriseContactEntity } from '@wephone-core/model/entity/enterprisecontact';
