import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import * as _ from 'lodash';

export class EnterpriseTelecomParamsRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseTelecomParamsEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'enterprise_telecom_params';

  protected METHOD_PATH_LIST = 'list';

  protected getUrl(suffix: string): string {
    return super.getUrlV2(suffix);
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/enterprise_telecom_params'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get enterprise_telecom_params list error', response);

          return [];
        }
      );
  }

  async findByEnterpriseId(eid: number): Promise<EnterpriseTelecomParamsEntity> {
    await this.findAll();

    return this.object_list.find(x => x.enterprise_id === eid);
  }

  async getCurrentEnterprsieTelecomParams(): Promise<EnterpriseTelecomParamsEntity> {
    await this.findAll();

    const list = this.getObjectList();
    return list && list[0] || this.create();
  }
}

import { EnterpriseTelecomParamsEntity } from '../entity/enterprise_telecom_params';
