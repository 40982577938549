<div class="edit-form-container" fxFill *ngIf="did">
  <ng-container *ngIf="!did.name; else tplEditRoutedObject">
    <form [formGroup]="form" class="form-change-highlight" *ngIf="form">
      <mat-card class="shadow-none">
        <mat-card-content>
          <div ngClass.gt-xs="mt-3 mx-3">
            <h2 class="title text-center">{{ 'did_setup_wizard.step1.title'|translate }}</h2>
            <div>
              <mat-form-field class="full-width mt-1">
                <mat-label>{{ 'did_setup_wizard.give_did_name'|translate }}</mat-label>
                <input matInput formControlName="name" placeholder="{{ 'did.name.placeholder'|translate }}"
                  data-ci="phone_name">

                <mat-error [flexFormControlError]="form.get('name')" [customMessages]="{
                    'name_exist': 'public.message.name_exist'|translate
                  }"></mat-error>
              </mat-form-field>
            </div>
            <div align="center">
              <button data-ci="save-name" mat-raised-button color="primary" (click)="saveDidName()" class="mt-1">
                {{ 'public.save'|translate }}</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </ng-container>
</div>

<ng-template #tplEditRoutedObject>
  <mat-card class="shadow-none">
    <mat-card-content>
      <div class="mb-4">
        <div>
          {{ 'did.content.default_destination'|translate }}
          <!-- <a class="flex-link" data-ci="link-change-destination" (click)="changeDestinationSipTrunk()">[{{
            'public.edit'|translate }}]</a> -->
        </div>
        <div class="defaut-routing flex-info" data-ci="default-destination">
          <ng-container *ngIf="did.routed_siptrunk; else tplDestinationDefault">
            <strong class="pr-1">{{ 'routing_application.sip_trunk.app_name'|translate }}</strong>
            <a class="flex-link" (click)="gotoEditSipTrunk(did.routed_siptrunk.id)">{{ did.routed_siptrunk.name }}</a>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="shadow-none" data-ci="siptrunk-links" *ngIf="sipTrunkList.length > 1">
    <mat-card-content>
      <h4 class="heading">{{ 'did.content.choose_destination_for'|translate: {did_number: did.display_number} }}</h4>
      <div class="pb-2">{{ 'did.content.choose_destination_description'|translate }}</div>
      <ng-container *ngFor="let sipTrunk of sipTrunkList">
        <div class="sip-item" fxLayout="row" *ngIf="!did.hasRoutingData() && !sipTrunk.is_default || did.routed_siptrunk && did.routed_siptrunk.id !== sipTrunk.id">
          <mat-icon color="primary">alt_route</mat-icon>
          <div fxFlex="auto">
            <a class="flex-link" (click)="setDestinationSipTrunk(sipTrunk)"
              [title]="'did.content.choose_destination_description'|translate">{{ sipTrunk.name }}</a>
            <!-- <span class="ml-1" *ngIf="sipTrunk.is_default">{{ 'public.default'|translate }}</span> -->
          </div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #tplDestinationDefault>
  <strong class="pr-1">{{ 'did.content.default_siptrunk'|translate }}</strong>
  <a *ngIf="sipTrunkDefault" class="flex-link" (click)="gotoEditSipTrunk(sipTrunkDefault.id)">{{
    sipTrunkDefault.name }}</a>
  <span *ngIf="!sipTrunkDefault">
    {{ 'did.content.no_default_siptrunk'|translate }}
    <a class="flex-link" (click)="createSipTrunk()">{{ 'did.content.create_default_siptrunk'|translate }}</a>
  </span>
</ng-template>
