import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { IFlexTableConfig, DialogService, parseDateTime, localNow, MessageService, FlexTableComponent, FormService } from '@wephone-utils';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { FlexBasePage } from '@wephone-core-ui';
import { AgentService } from '@wephone/services/agent.service';
import { _tk, _ti } from '@wephone-translation';
import { CallDirection, ICallInfoDataChanged, ICallLog } from '@wephone-app-phone/pages/history/calllog.i';
import { ActivatedRoute } from '@angular/router';
// import { MissedCallInfoDialogComponent } from '../missed-call-info-dialog/missed-call-info-dialog.component';
import { UserEntity } from '@wephone-core/model/entity/user';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { IDataSourceFilter } from '@wephone-utils/datasource/datasource-types';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { DateAdapter } from '@angular/material/core';
import { PeriodSelectType } from '@wephone-common/period-select/priod-select-type';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { DidRepository } from '@wephone-core/model/repository/did';
import { VoiceMailRepository } from '@wephone-core/model/repository/voicemail';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FlexDatepickerMin, PhoneNumberValidated } from '@wephone/services/form-validator';
import { MissedCallData, MissedCallDataMsg, RecallState, RecallStateText } from '@wephone-core/model/entity/missed_call.i';
import { CdrService, MissedCallDetail } from '@wephone-core/service/cdr_service';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { CallInfoModalComponent } from '@wephone-common/modals/call-info-modal/call-info-modal.component';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';

// interface FilteredParams {
//   start_dt: Date;
//   end_dt: Date;
//   phone_number: string;
//   called_number_ids: number[];
//   queue_ids: number[];
// }

enum MissedCallStatus {
  client_recall = 'client_recall',
  agent_recall = 'agent_recall',
  to_try = 'to_try',
  outbound_voicemail = 'outbound_voicemail',
}

enum DestType {
  Unknown = 0,
  User = 1,
  Queue = 2,
  IvrMenu = 3
}

interface MissedCallItem {
  id: number;
  call_id: number;
  message_id: number; // voicemail message id
  is_archived: number; // voicemail archived
  owner_id: number;
  last_inbound_cdr_id: number;
  last_outbound_user_id: number;
  qualification: string;
  qualification_comment: string;
  is_read: number; // For last voicemail
  file_entry_ready: number; // For last voicemail
  duration: number; // For last voicemail
  readers: number[]; // For last voicemail
  start_dt: string;
  recall_state: number;
  recall_time: string;
  recall_request_status: number;
  call_miss_reason: number;
  finished: number;
  calling_number: string;
  called_number: string;
  client_name: string;
  queue_name: string;
  dest_id: number;
  dest_name: string;
  dest_type: DestType;
  agent_user_id: number;
  agent_name: string;
  missed_call_count: number;
  recall_dt: string;
  recall_count: number;
  status: MissedCallStatus;
  sound_id: string;
  action: string;
}

@Component({
  selector: 'app-missed-calls',
  templateUrl: './missed-calls.component.html',
  styleUrls: ['./missed-calls.component.scss'],
})
export class MissedCallsComponent extends FlexBasePage implements OnInit, OnDestroy {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  private selectedPeriod: PeriodSelectType;
  private userId: number;

  tableConfig: IFlexTableConfig;

  calledDidIds: number[];

  hasServiceGroup: boolean;
  filterForm: FormGroup;
  filtersReadonly = false;

  missedCallStatusList: string[] = [
    MissedCallStatus.outbound_voicemail,
    MissedCallStatus.agent_recall,
    MissedCallStatus.client_recall,
    MissedCallStatus.to_try
  ];

  // filteredUserIds: number[];
  filteredQueueIds: number[];
  filteredGroupIds: number[];

  dataSource: FlexDataSource;
  readonly defaultPeriod: PeriodSelectType;
  private readonly users: Record<number, UserEntity> = {};
  private readonly queues: Record<number, CallQueueEntity> = {};
  private readonly ivrMenus: Record<number, IvrCustomMenuEntity> = {};
  private readonly qualifications: Record<number, QualificationEntity> = {};
  private readonly missedCallDetails: { [key: number]: MissedCallDetail } = {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly datasourceService: DataSourceService,
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly agentService: AgentService,
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService,
    private readonly authService: AuthenticationService,
    public readonly cdrService: CdrService,
    private readonly formService: FormService,
    readonly configManager: ConfigManager,
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;
    this.dataSource = this.datasourceService.createDataSource('callcenter_missed_call');
    this.dataSource.setOrder('start_dt', 'desc');
    this.defaultPeriod = PeriodSelectType.today;
    this.hasServiceGroup = configManager.hasFeature('SERVICE_GROUP');
    this.userId = this.authService.getUserId();
  }

  ngOnInit(): void {
    console.log('Init missed-call page');
    this.tableConfig = {
      // focusedRowHeight: 200,
      multiSelect: false,
      enableFilter: false,
      enableExportCsv: this.authService.isAdmin() || this.authService.isSupervisor(),
      enableExpandDetail: true,
      scrollable: true,
      rowClass: this.getRowClass,
      expandCallback: this.expandCallback,
      columns: [
        { name: 'calling_number', label: _tk('call_queue.content.calling_number') },
        { name: 'called_number', label: _tk('call_queue.content.called_number') },
        { name: 'client_name', label: _tk('call_queue.content.client_name'), enableTooltip: true },
        { name: 'dest_name', label: _tk('stats.missed_call.columns.dest_name'), visible: true, enableTooltip: true },
        { name: 'start_dt', label: _tk('call_queue.content.time') },
        { name: 'recall_dt', label: _tk('stats.missed_call.columns.recall_at') },
        { name: 'recall_time', label: _tk('stats.missed_call.columns.recall_time') },
        { name: 'recall_request_status', label: _tk('stats.missed_call.columns.recall_request_status') },
        { name: 'status', label: _tk('stats.missed_call.columns.status') },
        { name: 'agent_name', label: _tk('stats.missed_call.columns.handled_by'), enableTooltip: true, renderCell: this.renderAgentName },
        { name: 'assignee_name', label: _tk('stats.missed_call.columns.assignee'), searchable: false, enableTooltip: true, renderCell: this.renderAgentName },
        { name: 'missed_call_count', align: 'center', visible: true, label: _tk('stats.missed_call.columns.missed_call_count') },
        { name: 'sound_id', width: '50px', label: _tk('stats.missed_call.columns.voice_message') },
        { name: 'action', label: _tk('public.action') }
      ]
    };

    const now: DateTime = localNow().startOf('days');
    this.filterForm = this.fb.group({
      start_dt: [now.toJSDate(), [Validators.required]],
      end_dt: [now.toJSDate(), [Validators.required]],
      phone_number: ['', [PhoneNumberValidated()]],
      called_number_ids: [[]],
      queue_ids: [[]],
      ivrmenu_ids: [[]],
      recall_status: [],
      groups: [[]],
      recall_request_status: [false],
      out_of_calendar: [false],
      only_owner: [false],
    }, { validator: FlexDatepickerMin('end_dt', 'start_dt') });

    super.ngOnInit();
  }

  async resolveData(): Promise<void> {
    const users: UserEntity[] = this.em.getRepository('UserRepository').getObjectList();
    for (const u of users) {
      this.users[u.id] = u;
    }

    const queues: CallQueueEntity[] = this.em.getRepository('CallQueueRepository').getObjectList();
    for (const q of queues) {
      this.queues[q.id] = q;
    }

    const ivrMenus: IvrCustomMenuEntity[] = this.em.getRepository('IvrCustomMenuRepository').getObjectList();
    for (const q of ivrMenus) {
      this.ivrMenus[q.id] = q;
    }

    const qualifications: QualificationEntity[] = this.em.getRepository('QualificationRepository').getObjectList();
    for (const q of qualifications) {
      this.qualifications[q.id] = q;
    }

    if (!this.authService.isAdmin()) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      // this.filteredUserIds = myProfile.managed_user_ids;
      this.filteredQueueIds = myProfile.managed_queue_ids;
      this.filteredGroupIds = myProfile.managed_group_ids;
    }

    this.route.queryParamMap.subscribe(() => {
      if (!this.selectedPeriod || this.selectedPeriod === PeriodSelectType.today) {
        this.initTodayPeriod();
      }
    });

    this.addSubscription(
      this.messageService.subscribeMissedCallStatus((msg: MissedCallDataMsg) => {
        this.updateMissedCall(msg.data);
      })
    );
  }

  getDestName(item: MissedCallItem): string {
    if (item.dest_type === DestType.User) {
      return this.users[item.dest_id] ? this.users[item.dest_id].name : '';
    }

    if (item.dest_type === DestType.Queue) {
      return this.queues[item.dest_id] ? this.queues[item.dest_id].queue_name : '';
    }

    if (item.dest_type === DestType.IvrMenu) {
      return this.ivrMenus[item.dest_id] ? this.ivrMenus[item.dest_id].name : '';
    }

    return item.dest_name;
  }

  get startDateControl(): FormControl {
    return this.filterForm.get('start_dt') as FormControl;
  }

  get endDateControl(): FormControl {
    return this.filterForm.get('end_dt') as FormControl;
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilter = (d: Date): boolean => {
    const startDate = this.startDateControl.value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  private updateMissedCall(data: MissedCallData): void {
    console.log('updateMissedCall', data);
    const item = this.dataSource.data && this.dataSource.data.find(x => x.id === data.id);
    if (!item) {
      console.error('Missed call not found', data);
      this.dataSource.notifyNewItem(data);
      return;
    }

    // Update state
    item.status = RecallStateText[data.recall_state || RecallState.TO_TRY];

    item.missed_call_count = data.missed_call_count;

    // Update handled-agent
    item.owner_id = data.owner_id;
    item.last_outbound_user_id = data.last_outbound_user_id;
    item.recall_request_status = data.recall_request_status;

    this.dataSource.refreshData();
  }

  getRowClass = (row: MissedCallItem): string => {
    if (row.finished === 1 || row.status === RecallStateText[RecallState.AGENT_RECALL]) {
      return 'misscall-finished';
    }

    if (row.status === RecallStateText[RecallState.OUTBOUND_VOICEMAIL] && !row.finished) {
      return 'misscall-unhandled_voicemail';
    }

    // if (row.recall_time) {
    //   const now = localNow();
    //   const recallTime = parseDateTime(row.recall_time);
    //   // tslint:disable-next-line: strict-comparisons
    //   // if (recallTime > now) {
    //   //   return 'recall_disabled';
    //   // }
    // }

    return 'misscall-unfinished';
  }

  expandCallback = async (row: any): Promise<void> => {
    const cdrId: number = row.last_inbound_cdr_id;
    if (!cdrId) {
      return;
    }

    try {
      let ret: MissedCallDetail = this.missedCallDetails[cdrId];
      if (_.isEmpty(ret)) {
        ret = await this.cdrService.getMissedCallDetail(row.last_inbound_cdr_id) || {};
        this.missedCallDetails[cdrId] = ret;
      }

      row.data = ret;
      this.cdr.markForCheck();
    } catch (e) {
      console.error('Load missed call detail error', e);
    }
  }

  renderAgentName = (row: any, colName: string): string => {
    const cols = {
      assignee_name: 'owner_id',
      agent_name: 'last_outbound_user_id',
    };
    const agentId: number = Object.keys(cols).includes(colName) ? row[cols[colName]] : row[colName];
    return this.displayAgent(agentId);
  }

  renderAssigneeName = (row: any, colName: string): string => {
    return this.displayAgent(row.owner_id);
  }

  async applyFilter(): Promise<void> {
    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    const newFilters: IDataSourceFilter | any = this.getFilters();

    newFilters.start_dt = parseDateTime(newFilters.start_dt).toISODate();
    newFilters.end_dt = parseDateTime(newFilters.end_dt).toISODate();
    newFilters.only_owner = newFilters.only_owner ? 1 : 0;

    try {
      if (this.flexTable) {
        // this.dataSource.setFilter(newFilters);
        this.flexTable.reloadDataSource(newFilters);
      } else {
        await this.dataSource.load(newFilters);
      }

      this.filtersReadonly = true;
    } catch (e) {
      console.error('Cannot reload data', e);
      this.showError(_ti('public.message.error_occurred'));
    }
  }

  private getFilters(): any {
    const filterData = this.formService.normalizeData(this.filterForm.value);
    const formFilters = _.cloneDeep(filterData);

    const startDate = parseDateTime(formFilters.start_dt);
    const endDate = parseDateTime(formFilters.end_dt);
    if (formFilters.called_number_ids && formFilters.called_number_ids.length) {
      const called_number_ids = this.em.getRepository('DidRepository').getObjectList()
        .filter(d => (formFilters.called_number_ids).includes(d.id))
        .map(d => d.id);
      formFilters.called_number_ids = called_number_ids;
    }

    return _.extend(formFilters, {
      start_dt: startDate.toISODate(),
      end_dt: endDate.toISODate(),
      recall_request_status: formFilters.recall_request_status ? 1 : 0,
      out_of_calendar: formFilters.out_of_calendar ? 1 : 0,
      // queue_ids: (formFilters.queue_ids || []).map(q => q.id) || null,
      // called_number_ids: (formFilters.called_number_ids || []).map(d => d.number) || null,
    });
  }

  changeFilter(): void {
    this.filtersReadonly = false;
  }

  // yesterdayClick(): void {
  //   this.selectedPeriod = 'yesterday';
  //   const now = DateTime.local();

  //   _.extend(this.filters, {
  //     start_dt: now.minus({ days: 1 }).toISODate(),
  //     end_dt: now.minus({ days: 1 }).toISODate()
  //   });

  //   this.applyFilter();
  // }

  // customClick(): void {
  //   this.selectedPeriod = 'custom';
  //   const newFilters = _.cloneDeep(this.filters);
  //   const startDate = parseDateTime(newFilters.start_dt);

  //   const endDate = parseDateTime(newFilters.end_dt);

  //   _.extend(this.filters, {
  //     start_dt: startDate && startDate.toISODate(),
  //     end_dt: endDate && endDate.plus({ days: 1 }).toISODate()
  //   });

  //   this.applyFilter();
  // }

  initTodayPeriod(): void {
    const now = localNow().startOf('days');
    const periodData = { period: PeriodSelectType.today, start_dt: now.toJSDate(), end_dt: now.toJSDate() };
    this.changePeriodFilter(periodData);
  }

  displayQualification(qIds: string): string {
    if (!qIds) {
      return undefined;
    }
    const ret: string[] = [];
    for (const qid of JSON.parse(qIds)) {
      if (!this.qualifications[qid]) {
        continue;
      }
      ret.push(this.qualifications[qid].value);
    }
    return ret.join(' > ');
  }

  displayStatus(item: MissedCallItem): string {
    if (item.status) {
      return _.includes(Object.values(MissedCallStatus), item.status)
        ? _ti(`stats.missed_call.status.${item.status}`)
        : item.status;
    }
  }

  displayAgent(userId: number): string {
    return this.users[userId] ? this.users[userId].name : '';
  }

  shouldDisplayRecall(item: MissedCallItem): boolean {
    if (item.recall_request_status !== 1) {
      return false;
    }
    // if (item.recall_time) {
    //   const now = localNow();
    //   const recallTime = parseDateTime(item.recall_time);
    //   // tslint:disable-next-line: strict-comparisons
    //   return now >= recallTime;
    // }

    return true;
  }

  shouldDisplayCallsOutOfCalendar(item: MissedCallItem): boolean {
    if (item.call_miss_reason !== 1) {
      return false;
    }
    // if (item.recall_time) {
    //   const now = localNow();
    //   const recallTime = parseDateTime(item.recall_time);
    //   // tslint:disable-next-line: strict-comparisons
    //   return now >= recallTime;
    // }

    return true;
  }

  recallMissedCall(cdrId: number, $event = null): void {
    if ($event) {
      $event.stopPropagation();
    }
    this.agentService.make_recall_missed_call(cdrId);
  }

  onMakeCall = (phoneNumber: string, cdrId: number) => {
    console.log('Calling number', phoneNumber);
    this.recallMissedCall(cdrId);
  }

  detailMissedCall(call: MissedCallItem, $event = null): void {
    console.log('Detail missed calls', call);

    if ($event) {
      $event.stopPropagation();
    }

    const qids: number[] = call.qualification && JSON.parse(call.qualification) || [];
    const callLog: ICallLog = {
      called_number: call.called_number,
      calling_number: call.calling_number,
      id: call.last_inbound_cdr_id,
      start_time: call.start_dt ? new Date(call.start_dt) : undefined,
      duration: call.duration,
      is_missed: 1,
      missed_call_id: call.id,
      is_read: call.is_read,
      voicemail_public_id: call.file_entry_ready && call.sound_id || null,
      recall_request_status: call.recall_request_status,
      queue_ids: call.dest_type === 2 && call.dest_id ? [call.dest_id] : [],
      handled_user_ids: call.agent_user_id ? [call.agent_user_id] : [],
      recall_time: call.recall_time,
      message_id: call.message_id,
      is_outgoing: 0,
      user_direction: CallDirection.INBOUND,
      wait_duration: 0,
      qualification_ids: qids,
      qualification: !_.isEmpty(qids) && EntityManager.getRepository<QualificationRepository>('QualificationRepository').getDisplayedQualification(qids)
    };

    // this.dialogService.openDialog2(
    //   MissedCallInfoDialogComponent,
    //   {
    //     data: {
    //       call: callLog,
    //       onMakeCall: this.onMakeCall,
    //     },
    //     padding: false,
    //     size: 's',
    //   }
    // );

    // const voicemailInfo: IVoicemailInfo = {
    //   is_archived: call.is_archived,
    //   id: call.message_id,
    //   is_read: call.is_read,
    //   file_entry_ready: call.file_entry_ready,
    //   readers: call.readers,
    //   file_public_id: call.sound_id
    // };

    this.dialogService.openDialog2(
      CallInfoModalComponent,
      {
        data: {
          cdr_id: call.last_inbound_cdr_id,
        },
        padding: false
      },
      (resp: ICallInfoDataChanged) => {
        if (_.isEmpty(resp)) {
          return;
        }

        if (resp.hasOwnProperty('voicemail_has_deleted')) {
          // Reload rows after deleting item from dialog
          call.sound_id = null;
          call.message_id = null;
          call.file_entry_ready = 0;
        }

        if (resp.hasOwnProperty('vm_message_is_read')) {
          call.is_read = 1;
        }

        if (resp.hasOwnProperty('vm_message_readers')) {
          call.readers = resp.vm_message_readers;
        }

        this.detectChanges();
      }
    );
  }

  changePeriodFilter(periodData: { period: PeriodSelectType, start_dt: Date, end_dt: Date }): void {
    this.selectedPeriod = periodData.period;

    if (periodData.start_dt) {
      this.startDateControl.setValue(periodData.start_dt);
    }
    if (periodData.end_dt) {
      this.endDateControl.setValue(periodData.end_dt);
    }

    this.applyFilter();
  }

  rendererQueue(queueIds: number[]): string[] {
    if (!_.isEmpty(queueIds)) {
      const queueList = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectList() || [];
      return queueList.filter(q => queueIds.includes(q.id)).map(q => q.queue_name);
    }
  }

  rendererIvrMenu(menuIds: number[]): string[] {
    if (!_.isEmpty(menuIds)) {
      const menuList = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getObjectList() || [];
      return menuList.filter(o => menuIds.includes(o.id)).map(o => o.name);
    }
  }

  rendererCalledNumbers(didIds: number[]): string[] {
    if (!_.isEmpty(didIds)) {
      const didList = this.em.getRepository<DidRepository>('DidRepository').getObjectList() || [];
      return didList.filter(d => didIds.includes(d.id)).map(d => d.number_with_name);
    }
  }

  rendererGroup(groupIds: number[]): string[] {
    if (!_.isEmpty(groupIds)) {
      const groupList = this.em.getRepository<UserGroupRepository>('UserGroupRepository').getObjectList() || [];
      return groupList.filter(g => groupIds.includes(g.id)).map(g => g.name);
    }
  }

  async updateListened(item: MissedCallItem): Promise<void> {
    try {
      if (item.is_read && _.includes(item.readers, this.userId)) {
        console.log('Voice mail has been already listened by current user');
        return;
      }

      await this.em.getRepository<VoiceMailRepository>('VoiceMailRepository').markVoiceMailListened(item.sound_id);
      item.is_read = 1;

      if (!item.readers) {
        item.readers = [];
      }
      item.readers.push(this.userId);
      // this.cdr.markForCheck();
    } catch (error) {
      console.error('Mark as listened error', error);
    }
  }

  clearValue(event: MouseEvent, controlName: string, multiple: boolean = false): void {
    event.stopPropagation();
    this.filterForm.get(controlName).setValue(multiple ? [] : null);
  }
}
