import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ImageEntity } from '@wephone-core/model/entity/image';
import { DialogService } from '@wephone-utils';
import { TranslatePipe } from '@ngx-translate/core';
import { ImageCreateDialogComponent } from '../image-create-dialog/image-create-dialog.component';
import { _ti } from '@wephone-translation';
import { FlexBaseComponent } from '@wephone-core-ui';


@Component({
  selector: 'app-image-change',
  templateUrl: './image-change.component.html',
  styleUrls: ['./image-change.component.scss'],
  providers: [TranslatePipe]
})
export class ImageChangeComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() image: ImageEntity;
  @Input() hideRemove: boolean;
  @Input() title: string;
  @Output() readonly onChange = new EventEmitter<{
    new_image: ImageEntity; remove?: boolean
  }>();

  currentImage: ImageEntity;
  private hasInitialized: boolean;

  constructor(
    private readonly dialogService: DialogService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  get publicImageId(): string {
    return this.currentImage && this.currentImage.public_id;
  }

  ngOnInit(): void {
    this.hasInitialized = true;
    this.init();
  }

  ngOnChanges(): void {
    if (!this.hasInitialized) {
      return;
    }
    this.currentImage = null;
    setTimeout(() => {
      this.init();
    });
  }

  init(): void {
    this.currentImage = this.image;
    this.detectChanges();
  }

  openDialogChangeImage(): void {
    const data = {
      image: this.currentImage,
      selectExisting: true,
      showInputName: true,
    };

    this.dialogService.openSideDialog(ImageCreateDialogComponent, { data, size: 's' }, dataResponse => {
      if (!dataResponse) {
        console.log('Do cancel dialog');

        return;
      }

      this.currentImage = dataResponse.image;
      // this.cdr.detectChanges();
      console.log('onChange image upload', this.currentImage);
      this.onChange.emit({ new_image: this.currentImage });
    });
  }

  removeImage(): void {
    if (!this.currentImage) {
      console.error('No image selected');

      return;
    }

    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('dialogs.image.delete_confirm_msg'),
      () => {
        this.currentImage = null;
        this.onChange.emit({ new_image: null, remove: true });
      }
    );
  }
}
