import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';
import { DateTime } from 'luxon';
import { parseDateTime } from '@wephone-utils';

export class AgentEventEntity extends Entity {
  public static object_type_id: string = 'agent_event';

  @datafield
  public operator_keyid: number;
  @datafield
  public event_type: number;
  @datafield
  public start_dt;
  @datafield
  public end_dt;
  @datafield
  public is_aftercall_pause: number;
  @datafield
  public pause_reason_id: string;
  @datafield
  public call_queue_session_id: number;

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.start_dt = this.start_dt ? parseDateTime(this.start_dt) : undefined;
    this.end_dt = this.end_dt ? parseDateTime(this.end_dt) : undefined;
  }
}
