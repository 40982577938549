<form [formGroup]="form" fxLayout="column">
  <div fxLayout="column" fxLayoutAlign="space-between stretch" formArrayName="estimatedItems" class="full-width"
    fxLayoutGap="7px">
    <div fxLayout="row" fxLayoutAlign="center stretch" class="full-width" fxLayoutGap="7px"
      *ngFor="let estimatedItemControl of estimatedItemsControl.controls; let i=index">
      <div fxFlex="20" class="action-item" [ngClass]="{'px-1 pt-1':!estimatedItemControl.get('is_default').value}"
        fxLayoutAlign="center center">
        <div *ngIf="!estimatedItemControl.get('is_default').value;else isDefault">
          <mat-form-field class="mat-input-xs">
            <mat-label>{{ 'call_queue.content.estimated_people_or_more'|translate }}</mat-label>
            <input matInput type="number" [formControl]="estimatedItemControl.get('counter')" />
            <mat-error *ngIf="estimatedItemControl.get('counter').hasError('min')">
              {{ 'form.validator.min_value' | translate:{min: estimatedItemControl.get('counter').errors.min.min} }}
            </mat-error>
            <mat-error *ngIf="estimatedItemControl.get('counter').hasError('max')">
              {{ 'form.validator.max_value' | translate:{max: estimatedItemControl.get('counter').errors.max.max} }}
            </mat-error>
            <mat-error *ngIf="estimatedItemControl.get('counter').hasError('duplicateCounter')">
              {{ 'public.duplicated' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <ng-template #isDefault>
          <span>{{ 'call_queue.content.estimated_default_message'|translate }}</span>
        </ng-template>
      </div>
      <div fxFlex class="action-item">
        <div fxLayout="column">
          <div fxLayout="row">
            <sound-upload fxFlex [hideRemove]="true" class="wrapper-sound-upload"
              [fileEntry]="estimatedItemControl.get('sound').value"
              title="{{'call_queue_edit.section.estimated_wait_time'|translate}}"
              (onChange)="updateSoundFile(i, $event)">
            </sound-upload>
            <div fxFlex="50px" fxLayoutAlign="start center">
              <button *ngIf="!estimatedItemControl.get('is_default').value" class="close-button" mat-icon-button
                color="warn" (click)="removePeopleSound(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <span class="mat-error" *ngIf="estimatedItemControl.get('sound').hasError('required')">
            {{ 'form.validator.field_required' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-1">
    <button mat-stroked-button color="green" (click)="addMorePeopleSound()" type="button">
      <mat-icon class="icon-add">add</mat-icon>
      <span>{{ 'sipphone.content.add_more'|translate }}</span>
    </button>
  </div>
</form>
