import {
  ViewChild,
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  HostListener,
} from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { AgentService } from '@wephone/services/agent.service';
import { DialogService } from '@wephone-utils';
import { CallQueueItemRepository } from '@wephone-core/model/repository/callqueueitem';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FlexBaseComponent } from '@wephone-core-ui';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { UserRepository } from '@wephone-core/model/repository/user';
import { CallQueueItemEntity } from '@wephone-core/model/entity/callqueueitem';
import { IAgentItemActionMenu } from './agent-item.i';
import { MatDialogRef } from '@angular/material/dialog';
import { PauseAgentDialog } from '@wephone-common/modals/dialog-pause-agent/dialog-pause-agent';
import { _ti } from '@wephone-translation';
import { AgentReservedStatus } from '@wephone-core/model/entity/agent.i';

@Component({
  selector: 'agent-item',
  templateUrl: 'agent-item.html',
  styleUrls: ['./agent-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentItemComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() avatar: boolean;
  @Input() agent: AgentEntity;
  @Input() actions: IAgentItemActionMenu[];
  @Input() actionDisplayType: string;
  @Input() displayCallInfo: boolean;
  @ViewChild('menuTrigger') menuTrigger: any;

  dropCallList: any[] = [];
  currentUser: IUserEntity;
  activeActions: IAgentItemActionMenu[] = [];
  showActionBtn = false;
  menuOpened = false;
  AgentReservedStatus = AgentReservedStatus;

  private hasInitilized = false;
  // private isMatMenuOpen: boolean;

  @HostListener('mouseenter') onMouseEnter(): void {
    this.showActionBtn = true;
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (!this.menuOpened) {
      this.showActionBtn = false;
    }

    // setTimeout(() => {
    //   if (!this.isMatMenu2Open && !this.enteredButton) {
    //     this.isMatMenuOpen = false;
    //     trigger.closeMenu();
    //     this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
    //     this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
    //   } else {
    //     this.isMatMenuOpen = false;
    //   }
    // }, 1000);
  }

  constructor(
    private readonly authService: AuthenticationService,
    private readonly agentService: AgentService,
    private readonly dialogService: DialogService,
    private readonly em: EntityManager,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
    this.avatar = true;
    this.displayCallInfo = true;

    this.currentUser = this.em
      .getRepository<UserRepository>('UserRepository')
      .getObjectById(this.authService.getUserId());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitilized) {
      return;
    }

    if ('actions' in changes) {
      this.setActiveActions();
      this.detectChanges();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setActiveActions();
    this.hasInitilized = true;
  }

  setActiveActions(): void {
    this.activeActions = this.actions && this.actions.filter(x => !x.visible || x.visible(this.agent)) || [];
  }

  onMenuOpened(): void {
    this.showActionBtn = true;
    this.menuOpened = true;
    console.log('onMenuOpened');
  }
  onMenuClosed(): void {
    this.showActionBtn = false;
    this.menuOpened = false;
    console.log('onMenuClosed');
  }

  cancelAssignCallToAgent(): void {
    if (!this.call_queue_item) {
      console.warn(`Call assigned for agent ${this.agent.name} not found`);

      return;
    }
    this.em
      .getRepository<CallQueueItemRepository>('CallQueueItemRepository')
      .removeForceForCall(this.call_queue_item.getId());
  }

  get call_queue_item(): CallQueueItemEntity {
    const queue_item_list: CallQueueItemEntity[] = this.em.getRepository<CallQueueItemRepository>('CallQueueItemRepository').getObjectList();

    if (!this.currentUser) {
      return undefined;
    }

    return queue_item_list.find((obj: CallQueueItemEntity) => {
      return (
        this.agent.getId() === obj.forced_operator_id &&
        this.currentUser.getId() === obj.forcing_user_id &&
        !obj.finished
      );
    });
  }

  private canPauseReason(agent: AgentEntity): boolean {
    if (this.is_admin || this.is_supervisor) {
      return true;
    }

    if (agent.user_id === this.currentUser.id) {
      return true;
    }

    return false;
  }

  agentChangePauseReason(agent: AgentEntity): MatDialogRef<any> {
    if (!this.canPauseReason(agent)) {
      console.warn(`Can't change other user's pause reason`);
      return;
    }

    return this.dialogService.openDialog2(PauseAgentDialog, {
      data: {
        pauseReason: agent.pause_reason
      }
    }, async (ret: { pause_reason_id: number }) => {
      if (!ret || !ret.pause_reason_id) {
        return;
      }

      try {
        await this.agentService.agentChangePauseReason(agent.id, ret.pause_reason_id);
        this.detectChanges();
        this.successToast(_ti('agent_panel.message.change_pause_reason_success'));
      } catch (response) {
        console.error('Change agent pause reason error', response);
        let message: string;
        if (response) {
          message = response.error && response.error.message || response.message;
        }
        this.toastService.showError(message || _ti('agent_panel.message.change_pause_reason_failure'));
      }
    });
  }

  get has_call_assigned_to_agent(): boolean {
    if (!this.call_queue_item) {
      return false;
    }
    return !(this.call_queue_item.session && this.call_queue_item.session.bridged_dt);
  }

  get can_eavesdrop_agent(): boolean {
    return this.currentUser && this.currentUser.canEavesdrop(this.agent.user);
  }

  get is_supervisor(): boolean {
    return this.authService.isSupervisor();
  }

  get is_admin(): boolean {
    return this.authService.isAdmin();
  }

  // get inout_css_class(): string {
  //   switch (this.agent.inout_state) {
  //     case 1: { // IN
  //       return 'arrow_downward';
  //     }
  //     case 2: { // OUT
  //       return 'arrow_upward';
  //     }
  //     case 3: { // NONE
  //       return 'block';
  //     }
  //     default: { // BOLD
  //       return 'import_export';
  //     }
  //   }
  // }
}
