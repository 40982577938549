import * as _ from 'lodash';

import { Component, OnInit } from '@angular/core';
import { ShortcutEntity } from '@wephone-core/model/entity/shortcut';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  DialogActionButton,
  Colors,
  IFlexDialogConfig,
  NoWhitespaceValidator,
} from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { ShortcutRepository } from '@wephone-core/model/repository/shortcut';

@Component({
  selector: 'app-create-shortcut-modal',
  templateUrl: './create-shortcut-modal.component.html',
  styleUrls: ['./create-shortcut-modal.component.scss']
})
export class CreateShortcutModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '500px',
    minHeight: '90%',
  };

  dialogTitle = _tk('callcenter_shortcut.title.add');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  private readonly shortcutRepo = ShortcutRepository.getInstance<ShortcutRepository>();

  shortcut: ShortcutEntity;
  // public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    dialogRef: MatDialogRef<CreateShortcutModalComponent>,
  ) {
    super(dialogRef);
    this.shortcut = this.shortcutRepo.create() as ShortcutEntity;
    this.initFormGroup();
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  initFormGroup(): void {
    this.form = this.fb.group({
      displayText: [this.shortcut.display_text, [Validators.required]],
      destination: [this.shortcut.destination, [Validators.required, NoWhitespaceValidator]],
      newWindow: [this.shortcut.in_new_window]
    });
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      const formValue = this.form.value;
      this.shortcut.display_text = _.trim(formValue.displayText);
      this.shortcut.destination = _.trim(formValue.destination);
      this.shortcut.in_new_window = formValue.newWindow;

      const result = await this.shortcutRepo.save(this.shortcut);
      this.toastService.show(_ti('public.message.create_success'));
      this.dismiss(result, false);
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  public cancel(result?): void {
    this.dismiss(result);
  }
}
