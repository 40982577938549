import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogActionButton, Colors, DynamicFilterSource, regexSearch, DialogService } from '@wephone-utils';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import * as _ from 'lodash';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CalendarUtils } from '../calendar.utils';
import { OpeningCalendarEditDialogComponent } from '../../opening-calendar-edit-dialog/opening-calendar-edit-dialog.component';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';
import { IOpeningCalendarEditDialogConfig } from '../../calendar-types';

@Component({
  selector: 'calendar-select-list-dialog',
  templateUrl: './calendar-select-list-dialog.component.html',
  styleUrls: ['./calendar-select-list-dialog.component.scss']
})
export class CalendarSelectListDialogComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('calendar_select_list_dialog.title');

  // Public member var
  selectedCalendar: OpeningHourCalendarEntity;
  filterValue: string;
  hasEmpty: boolean;
  dataSource: DynamicFilterSource;

  private hasAddedDefault: boolean;

  constructor(
    dialogRef: MatDialogRef<CalendarSelectListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private em: EntityManager,
    public dialogService: DialogService,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.selectedCalendar = _.cloneDeep(this.data.calendar);
    this.hasEmpty = !!this.data.hasEmpty;

    const calendarRepo: OpeningHourCalendarRepository = this.em.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository');
    const calendarSource = calendarRepo.dataSource<OpeningHourCalendarEntity>();
    this.dataSource = new DynamicFilterSource(calendarSource, this.filterItemOnSearch);
  }

  isSelected(i: number): boolean {
    return this.selectedCalendar && this.selectedCalendar.id === i;
  }

  get calendarList(): OpeningHourCalendarEntity[] {
    const calendarList: OpeningHourCalendarEntity[] = this.dataSource.data || [];
    if (this.hasEmpty && !this.hasAddedDefault) {
      calendarList.unshift(this.getNoCalendarObject());
      this.hasAddedDefault = true;
    }
    return calendarList;
  }

  onFilterChanged(): void {
    this.dataSource.onFilterChange();
  }

  filterItemOnSearch = (item: OpeningHourCalendarEntity): boolean => {
    const filterValue: string = (this.filterValue || '').trim();
    return !item.is_private ? (filterValue ? regexSearch(item.name, filterValue) : true) : false;
  }

  private getNoCalendarObject(): OpeningHourCalendarEntity {
    const nocalendarObject = EntityManager.getRepository('OpeningHourCalendarRepository').create({
      id: null,
      name: _ti('call_queue.content.no_calendar')
    }) as OpeningHourCalendarEntity;

    return nocalendarObject;
  }

  applySelect(): void {
    this.dismiss(this.selectedCalendar);
  }

  createCalendar(): void {
    try {
      const calendarUtils = new CalendarUtils();
      const newName = calendarUtils.getNewName();
      const newCalendar = this.em.getRepository<OpeningHourCalendarRepository>(
        'OpeningHourCalendarRepository'
      ).createDefaultCalendar(newName) as OpeningHourCalendarEntity;
      const config: IOpeningCalendarEditDialogConfig = {
        editCustomData: false,
        editName: true,
        editGroup: true,
        showSideSteps: false,
        showUsedObjects: false,
      };
      this.dialogService.openDialog2(
        OpeningCalendarEditDialogComponent,
        {
          data: {
            calendar: newCalendar,
            config
          }
        },
        async (calendar: OpeningHourCalendarEntity) => {
          if (calendar) {
            await this.createUpdateCalendarCb(calendar);
          }
        }
      );
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  private async createUpdateCalendarCb(calendar: OpeningHourCalendarEntity): Promise<void> {
    const extra_param: any = {
      openinghours: (calendar.openinghours || []).map((i: OpeningHourEntity) => i.dumpObjectData()),
      openinghours_special_date: (calendar.openinghours_special_date || []).map((i: OpeningHourSpecialDateEntity) => i.dumpObjectData()),
    };

    const updatedCalendar: OpeningHourCalendarEntity = await EntityManager.getRepository<OpeningHourCalendarRepository>(
      'OpeningHourCalendarRepository'
    ).save(calendar, extra_param);

    if (updatedCalendar) {
      this.onFilterChanged();

      const newCalendar = new OpeningHourCalendarEntity();
      newCalendar.setObjectData(updatedCalendar, true);
      this.selectedCalendar = newCalendar;

      this.applySelect();
      this.successToast(_ti('public.message.create_success'));
    }
  }
}
