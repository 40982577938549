import { Component, Input, OnInit } from '@angular/core';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';

@Component({
  selector: 'app-crm-routing-rule-mini-block',
  templateUrl: './crm-routing-rule-mini-block.component.html',
  styleUrls: ['./crm-routing-rule-mini-block.component.scss']
})
export class CrmRoutingRuleMiniBlockComponent implements OnInit {
  @Input() crmRoutingRule: CrmRoutingRuleEntity;
  constructor() { }

  ngOnInit(): void {
  }

}
