import { Entity, datafield } from '@wephone-core/model/model';
import { TelecomBillingPlanEntity } from './telecom_billing_plan';
import { TelecomBillingPlanRepository } from '../repository/telecom_billing_plan';

export enum SubscriptionPackDefined {
  TRIAL = 'trial',
  // BASIC = 'basic',
  // SILVER = 'silver',
  // GOLD = 'gold',
  SENDINBLUE = 'sendinblue',
}
export class SubscriptionPackEntity extends Entity {
  public static object_type_id = 'subscription_pack';
  public static PACKAGE_TRIAL = SubscriptionPackDefined.TRIAL;

  public id: number;
  @datafield
  public name: string;
  @datafield
  public package_id: string;
  @datafield
  public prepaid: number;
  @datafield
  public limit_user_count: number;
  @datafield
  public fallback_pack_id: number;
  @datafield
  public price: number;
  @datafield
  public per_number_nat: number;
  @datafield
  public per_number_mob: number;
  @datafield
  public per_number_geo: number;
  @datafield
  public per_user: number;
  @datafield
  public per_number: number;
  @datafield
  public per_cc_license: number;
  @datafield
  public ft_per_user: number;
  @datafield
  public ft_per_cc_license: number;
  @datafield
  public free_user: number;
  @datafield
  public free_cc_license: number;
  @datafield
  public free_did_per_user: number;
  @datafield
  public unlimited_outbound: number; // (0|1)
  @datafield
  public currency: string;
  @datafield
  public default_billing_plan_id: number;

  isTrial(): boolean {
    return this.package_id === SubscriptionPackEntity.PACKAGE_TRIAL;
  }

  get default_billing_plan(): TelecomBillingPlanEntity {
    return this.default_billing_plan_id ?
      TelecomBillingPlanRepository.getInstance().getObjectById(this.default_billing_plan_id) : undefined;
  }

  set default_billing_plan(val: TelecomBillingPlanEntity) {
    if (val) {
      this.default_billing_plan_id = val.id;
    } else {
      this.default_billing_plan_id = undefined;
    }
  }
}
