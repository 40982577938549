import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class FileEntryRepository extends BaseRepository implements IFileEntryRepository {
  ENTITY_CLASS = FileEntryEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'fileentry';

  getSharedFileEntries(): Array<FileEntryEntity> {
    const fileentries = [];
    for (const fileentry of this.getObjectList()) {
      if (fileentry.is_shared && !fileentry.is_temp) {
        fileentries.push(fileentry);
      }
    }
    return fileentries;
  }

  getRingtonesByUser(user: UserEntity): FileEntryEntity[] {
    return this.getObjectList().filter((f: FileEntryEntity) => {
      return f.user_id === user.id && f.category === FileEntryCategory.RINGTONE && f.is_shared && !f.is_temp;
    });
  }

  getObjectByPublicId(public_id: string): FileEntryEntity {
    if (_.includes(FileEntryEntity.getDefaultPublicIds(), public_id)) {
      return this.getDefaultFileEntry(public_id);
    }

    for (const fileentry of this.getObjectList()) {
      if (fileentry.public_id === public_id) {
        return fileentry;
      }
    }
  }

  removeObjectFromList(item: FileEntryEntity): void {
    const index: number = this.object_list.indexOf(item);
    if (index !== -1) {
      this.object_list.splice(index, 1);
    }
  }

  getDefaultFileEntry(defaultName: string): FileEntryEntity {
    const fileEntry: FileEntryEntity = new FileEntryEntity();
    fileEntry.id = 0;
    fileEntry.name = _ti(`default_sounds.${defaultName}`);
    fileEntry.public_id = defaultName;
    return fileEntry;
  }

  nameExist(name: string, excludedId?: number): boolean {
    const existFileEntry: FileEntryEntity = this.getObjectList().find(
      x => x.name === name.trim() && !x.is_temp && (!excludedId || excludedId && x.id !== excludedId)
    );
    return !!existFileEntry;
  }

  ringtoneExist(userId: number, name: string): boolean {
    return !!this.getObjectList().find(x => x.name === name.trim() && x.user_id === userId && !x.is_temp && x.category === FileEntryCategory.RINGTONE);
  }
}

import { FileEntryEntity, FileEntryCategory } from '@wephone-core/model/entity/fileentry';
import { IFileEntryRepository } from '@wephone-core/model/repository/fileentry.i';
import { _ti } from '@wephone-translation'; import { UserEntity } from '../entity/user';
