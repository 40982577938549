<div fxLayout="column">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="none"
    class="filter-container">
    <div fxFlex="50" fxFlex.xs="none" fxLayoutAlign="start center">
      <h2 class="ma-0">{{ 'campaign.sort'|translate }}</h2>
    </div>
    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxFlex="40px">
        <button title="{{'public.refresh'|translate}}" mat-icon-button color="black" (click)="refreshList()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div fxFlex="auto">
        <mat-form-field class="full-width">
          <mat-select [(ngModel)]="orderBy" (selectionChange)="changeSort($event)" [placeholder]="'Sort by'">
            <mat-option value="name">
              {{'sort_by.name'|translate}}
            </mat-option>
            <mat-option value="connected_agents">
              {{'sort_by.connected_agents'|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="40px">
        <button color="black" [title]="(orderDir ? 'livecall.sort.descending' : 'livecall.sort.ascending')|translate"
          mat-icon-button (click)="changeDirection($event)">
          <mat-icon *ngIf="orderDir">
            <fa-icon [icon]="FAIcons.faSortAlphaDown"></fa-icon>
          </mat-icon>
          <mat-icon *ngIf="!orderDir">
            <fa-icon [icon]="FAIcons.faSortAlphaUp"></fa-icon>
          </mat-icon>
        </button>
      </div>
      <div>
        <flex-search-input [placeholder]="'public.button.search' | translate" (onInput)="filterCampaigns()"
          [(ngModel)]="search" (ngModelChange)="filterCampaigns()"
          [hintText]="'active_outbound_campaign.search.hint'|translate">
        </flex-search-input>
      </div>
    </div>
  </div>

  <mat-card class="flex-header">
    <mat-card-header>
      <mat-card-title fxLayout="row">
        <div fxFlex="30">
          {{ 'campaign.sort'|translate }}
        </div>
        <div>{{ 'campaign.available_agents'|translate }}</div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="campaign-item pt-1" *ngFor="let campaign of sortedDisplayedCampaigns" fxLayout="row"
        fxLayoutAlign="start start" fxLayoutAlign.xs="start" fxFlexFill fxLayoutGap="5px" fxLayoutGap.xs="0">

        <div fxFlex="30" fxLayout="column">
          <mat-nav-list>
            <h2 mat-subheader class="font-weight-bold">{{ campaign.name }}</h2>
            <!-- <mat-list-item>
              <span class="text-md" matLine>{{'livecall.active_campaign.handled_per_total_calls'|translate}}
                {{campaign.processed_calls}} / {{campaign.total_calls}}</span>
            </mat-list-item> -->
            <mat-list-item>
              <div class="full-width">
                <app-flex-progress-line color="success" tooltip="livecall.active_campaign.handled_per_total_calls"
                  [value]="campaign.processed_percent">
                </app-flex-progress-line>
              </div>
            </mat-list-item>

            <mat-list-item>
              <span matLine>{{'livecall.active_campaign.total_calls'|translate}}</span>
              <strong>{{campaign.total_calls}}</strong>
            </mat-list-item>

            <!-- <mat-list-item>
              <span matLine>{{'livecall.active_campaign.processed_calls'|translate}}</span>
              <strong>{{campaign.processed_calls}}</strong>
            </mat-list-item> -->

            <mat-list-item>
              <span matLine>{{'livecall.active_campaign.success_calls'|translate}}</span>
              <strong>{{campaign.success_today_calls}}/{{campaign.success_calls}}</strong>
            </mat-list-item>

            <mat-list-item>
              <span matLine>{{'livecall.active_campaign.failure_calls'|translate}}</span>
              <strong>{{campaign.failure_calls}}</strong>
            </mat-list-item>

            <mat-list-item>
              <span matLine>{{'livecall.active_campaign.remaining_calls'|translate}}</span>
              <strong>{{campaign.remaining_calls}}</strong>
            </mat-list-item>

            <mat-list-item>
              <span matLine>{{ 'livecall.active_campaign.connected_agents'|translate }}</span>
              <strong>{{campaign.connected_agents}}</strong>
            </mat-list-item>

          </mat-nav-list>
        </div>
        <div fxFlex>
          <available-agents [agentSort]="agentSort" [filterQueue]="campaign.queue" [filterUngroupedAgents]="true"
            displayType="compact" callDirection="both">
          </available-agents>
        </div>
      </div>
    </mat-card-content>

    <!-- <table class="tbl-campaign mat-table">
      <tbody *ngFor="let campaign of sortedDisplayedCampaigns" class="campaign-item">
        <tr><td colspan="3"><h3><strong>{{ campaign.name }}</strong></h3></td></tr>
        <tr>
          <td>{{'livecall.active_campaign.handled_per_total_calls'|translate}}</td>
          <td>
            <p>
              <strong>{{campaign.processed_calls}} / {{campaign.total_calls}}</strong>
            </p>
          </td>
          <td rowspan="3">
            <queue-agents 
              [(queue)]="campaign.queue" 
              [addQueue]="true" 
              (onChange)="updateQueueAttrs(campaign.queue, ['main_agents'])">
            </queue-agents>
          </td>
        </tr>
        <tr>
          <td >{{'livecall.active_campaign.remaining_calls'|translate}}</td>
          <td>
            <p>
              <strong>{{campaign.remaining_calls}}</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td >{{ 'livecall.active_campaign.connected_agents'|translate }}</td>
          <td>
            <p>
              <strong>{{campaign.connected_agents}}</strong>
            </p>
          </td>
        </tr>
      </tbody>

    </table> -->

  </mat-card>
</div>
