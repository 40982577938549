import { ITranslationService } from './translation-types';

let serviceInstance: ITranslationService;

export function setTranslationServiceInstance(service: ITranslationService): void {
  serviceInstance = service;
}

// tk = Translation Key
export function _tk(key: string): string {
  return key;
}

// ti = Translate Instantly
export function _ti(key: string, params?: any): string {
  if (serviceInstance) {
    return serviceInstance.instant(key, params);
  }

  throw new Error('Translation static instance is not set. Please set it first using setServiceInstance method');
}
