import { Component, OnInit } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { DialogService, ToastService } from '@wephone-utils';
import { TransformPremiumPage } from '@wephone/modals/transform-premium/transform-premium';
import { PaymentService } from '@wephone/services/payment.service';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-enterprise-billing-plan',
  templateUrl: './enterprise-billing-plan.component.html',
  styleUrls: ['./enterprise-billing-plan.component.scss']
})
export class EnterpriseBillingPlanComponent implements OnInit {
  static URL = '/ws-v2/api/enterprise/get_billing_plan';

  billingPlan: any;
  enterprise: EnterpriseEntity;
  constructor(
    private httpEngine: HttpEngine,
    private em: EntityManager,
    private dialogService: DialogService,
    private paymentService: PaymentService,
    public toast: ToastService,
  ) {
    this.enterprise = this.em.getRepository<EnterpriseRepository>('EnterpriseRepository').getMyEnterprise();
  }

  async ngOnInit(): Promise<any> {

    this.billingPlan = await this.resolveData();
  }

  resolveData(): Promise<any> {
    return this.httpEngine.get(EnterpriseBillingPlanComponent.URL, undefined).then(data => {
      if (!data) {
        console.error('No data responsed');
        return;
      }
      return data['billing_plan'];
    });
  }

  async changeModal(): Promise<any> {
    try {
      const dialogRef = this.dialogService.openDialog2(TransformPremiumPage, {
        data: {
          enterprise: this.enterprise
        }
      });

      const enterprise = await dialogRef.afterClosed().toPromise();

      if (!enterprise) {
        return;
      }

      const result = await this.em.getRepository<EnterpriseRepository>('EnterpriseRepository').updateEnterprise(enterprise);
      this.toast.show(_ti('public.message.update_success'));
      return true;
    } catch (error) {
      return false;
    }
  }
}
