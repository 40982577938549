<ng-container *ngIf="selectionStep === 0">
  <div fxLayout="column" class="text-center">
    <h2>{{ 'payment_wizard.tabs.select_payment_method'|translate }}</h2>
    <span>{{ 'payment_wizard.dialog.payment_description'|translate }}</span>
    <p>{{ payment_attempt.total_amount | currency: payment_attempt.currency_code : 'symbol' : '0.2-2' }}</p>
    <p>{{ 'payment_wizard.dialog.payment_reasion'|translate:{ payment_reasion: payment_reason } }}</p>
  </div>
</ng-container>

<ng-container *ngIf="selectionStep === 1">
  <div fxLayout="column">
    <h2>Card information</h2>
    <app-credit-card [(ngModel)]="credit_card_info"></app-credit-card>
  </div>
</ng-container>
