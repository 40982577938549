export class Deferred<T=any> {
  _isCalled = false;
  private readonly _promise: Promise<T>;
  private _resolve: (value?: T | PromiseLike<T>) => void;
  private _reject: (reason?: any) => void;

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  get promise(): Promise<T> {
    return this._promise;
  }

  get isCalled(): boolean {
    return this._isCalled;
  }

  resolve = (value?: T | PromiseLike<T>): void => {
    this._isCalled = true;
    this._resolve(value);
  }

  reject = (reason?: any): void => {
    this._isCalled = true;
    this._reject(reason);
  }
}

export function deferToNextTick(f: (...args) => any, ...args): Promise<any> {
    const d = new Deferred();
    setTimeout(
      () => {
        d.resolve(f(...args));
      },
      1
    );
    return d.promise;
}

export function asyncPause(miliseconds: number): Promise<void> {
  const d = new Deferred<void>();
  setTimeout(
    () => {
      d.resolve();
    },
    miliseconds
  );
  return d.promise;
}
