<div class="mt-1" data-ci="did-order-global">
  <!-- <h3 *ngIf="numberProvider">{{ numberProviderLabel }}</h3> -->
  <ng-container *ngIf="step == 'select_country'">
    <div fxLayout="column" fxLayoutAlign="start left" [attr.data-ci]="step">
      <h2>{{ 'buy_did.title.select_country'|translate }}</h2>
      <div class="full-width">
        <flex-search-input [(ngModel)]="filterCountry"></flex-search-input>
      </div>
      <div class="full-width">
        <div fxLayout="row wrap">
          <div fxFlex="20" *ngFor="let country of getCountries">
            <mat-card fxFlex="grow" class="card-widget text-xs-center" (click)="selectCountry(country)" [attr.data-ci]="country.code2">
              <div mat-card-widget>
                <div class="pa-0 full-width">
                  <span country-flag [countryCode2]="country.code2"></span>
                  <p class="mat-card-subtitle">+{{ country.phone_code }}</p>
                  <p class="mat-card-subtitle">{{ country.name.toLowerCase() }}</p>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step == 'select_number_type'">
    <div fxLayout="column" fxLayoutAlign="start left" [attr.data-ci]="step">
      <h2>{{ 'buy_did.title.select_did_type'|translate }}</h2>
      <div class="full-width">
        <div fxLayout="row wrap">
          <div fxFlex="20" *ngFor="let type of types">
            <mat-card fxFlex="grow" [attr.data-ci]="type.value" class="card-widget text-xs-center" (click)="selectType(type)">
              <div mat-card-widget>
                <div class="pa-0 full-width">
                  <span country-flag [countryCode2]="country.code2"></span>
                  <p class="mat-card-subtitle">+{{ country.phone_code }} {{ type.area_codes_display }} </p>
                  <p class="mat-card-subtitle">{{ type.name|translate }}</p>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step == 'select_state'">
    <div fxLayout="column" fxLayoutAlign="start left" [attr.data-ci]="step">
      <h2>{{ 'buy_did.title.select_state'|translate }}</h2>
      <div class="full-width">
        <div fxLayout="row wrap">
          <div fxFlex="20" *ngFor="let state of states">
            <mat-card fxFlex="grow" [attr.data-ci]="state.name" class="card-widget text-xs-center ma-0" (click)="selectState(state)">
              <div mat-card-widget>
                <div class="pa-0 full-width">
                  <span country-flag [countryCode2]="country.code2"></span>
                  <p class="mat-card-subtitle">+{{ country.phone_code }} {{ state.state_code }}
                  </p>
                  <p class="mat-card-subtitle">{{ state.state_name }}</p>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step == 'select_region'">
    <div fxLayout="column" fxLayoutAlign="start left" [attr.data-ci]="step">
      <h2>{{ 'buy_did.title.select_prefix'|translate }}</h2>
      <div>
        <flex-search-input [(ngModel)]="filterRegion"></flex-search-input>
      </div>
      <div class="full-width">
        <div fxLayout="row wrap">
          <div fxFlex="20" *ngFor="let region of getDidRegions">
            <mat-card fxFlex="grow" [attr.data-ci]="region.name" class="card-widget text-xs-center" (click)="selectRegion(region)">
              <div mat-card-widget>
                <div class="pa-0 full-width">
                  <span country-flag [countryCode2]="country.code2"></span>
                  <p class="mat-card-subtitle">+{{ country.phone_code }}
                    {{ region.prefix }}</p>
                  <p class="mat-card-subtitle">{{ region.name }}</p>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step == 'confirm'">
    <div fxLayout="column" fxLayoutAlign="start left" [attr.data-ci]="step">
      <h2>{{ 'buy_did.title.your_selection'|translate }}</h2>
      <table>
        <thead>
          <tr>
            <td>{{ 'did.content.country'|translate }}</td>
            <td>{{ 'did_order.fr.content.area_code'|translate }}</td>
            <td>{{ 'invoice.content.quantity'|translate }}</td>
            <td>{{ 'did_order.fr.content.cost_per_month'|translate }}</td>
            <td>{{ 'public.action'|translate }}</td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of cart">
            <tr [attr.data-ci]="item?.country?.code2 + '-' + item?.region?.name">
              <td>
                <span country-flag [countryCode2]="item?.country?.code2"></span>
                {{ item?.country?.name }} (+{{ item?.country?.phone_code }})
              </td>
              <td><span *ngIf="item?.region">{{ item?.region?.name }} ({{ item?.region?.prefix }})</span></td>
              <td>
                <mat-form-field class="field-small">
                  <input matInput type="number" [(ngModel)]="item.quantity" />
                </mat-form-field>
              </td>
              <td>{{ calculatePrice(item) }} &euro;</td>
              <td>
                <button mat-icon-button data-ci="deleteItem" (click)="deleteItem(item)" matTooltip="{{ 'public.button.trash'|translate }}">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="(item.region && item.region.prefix || item.country && item.country.code2) && errors[item.region && item.region.prefix || item.country && item.country.code2]">
              <td colspan="5">
                <span
                  class="mat-error">{{ errors[item.region && item.region.prefix || item.country && item.country.code2]|translate }}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div>
        <button mat-raised-button color="default" data-ci="addMore"
          (click)="addMore()">{{ 'components.didtopay.add_more_number'|translate }}</button>
      </div>
    </div>
  </ng-container>
</div>
