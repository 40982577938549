import { Component, Input, Output, OnInit, EventEmitter, OnChanges } from '@angular/core';
import { EavesdropService } from '../../../../../wephone-common/service/eavesdrop.service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import * as _ from 'lodash';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ChartGroupData, ChartPeriod } from '../graph-chart-type';
import { LiveQueueData, IChartFilter } from '@wephone-core/service/live-stats.service';

@Component({
  selector: 'app-graph-chart-group',
  templateUrl: './graph-chart-group.component.html',
  styleUrls: ['./graph-chart-group.component.scss']
})
export class GraphChartGroupComponent implements OnInit, OnChanges {
  groupChildrenDisplayed: any[] = [];
  groupChildrenHidden: any[] = [];

  constructor(
    private eavesdropService: EavesdropService
  ) { }
  @Input() hiddenGroups: number[];
  @Input() filteredGroupIds: number[];
  @Input() group: ChartGroupData;
  @Input() period: ChartPeriod;
  @Input() showEavesdrop: boolean;
  @Input() callFilters: IChartFilter;
  @Output() readonly onHidePanel: EventEmitter<any> = new EventEmitter<any>();

  has_show_group: boolean;

  private initialized: boolean;

  ngOnInit(): void {
    this.setHasShowGroup();

    this.initialized = true;
  }

  ngOnChanges(): void {
    if (!this.initialized) {
      return;
    }

    this.setHasShowGroup();
  }

  hideGroup($event: any): void {
    this.onHidePanel.emit($event);
  }

  set_ui_pref_group_hidden(group_id: number, hidden: boolean): void {
    this.onHidePanel.emit({ group_id, hidden });
  }

  call_eavesdrop_group(group_id: number): Promise<any> {
    return this.eavesdropService.call_eavesdrop_group(group_id);
  }

  getQueuesFromGroup(group: ChartGroupData): LiveQueueData[] {
    let queues: LiveQueueData[] = group.queues;

    if (this.callFilters && !_.isEmpty(this.callFilters.queues)) {
      queues = queues.filter(x => _.includes(this.callFilters.queues, x.queue_id));
    }
    return queues;
  }

  hasPermissionView(groupId: number): boolean {
    return !groupId || !this.filteredGroupIds || _.includes(this.filteredGroupIds, groupId);
  }

  private setHasShowGroup(): void {
    if (this.group.children) {
      this.groupChildrenDisplayed = this.group.children.filter(
        group => {
          return this.hiddenGroups.indexOf(group.id) === -1;
        }
      );

      this.groupChildrenHidden = this.group.children.filter(
        group => {
          return this.hiddenGroups.indexOf(group.id) !== -1;
        }
      );
    }

    if (!_.isEmpty(this.group.queues)) {
      this.has_show_group = true;
      return;
    }

    const groupIds: number[] = _.cloneDeep(this.group.all_children_ids || []);
    groupIds.push(this.group.id);

    const queueRepo = EntityManager.getInstance().getRepository<CallQueueRepository>('CallQueueRepository');
    // const queue_ids_used = queueRepo.getQueuesUsedForCallCenter().map(q => q.id);

    const queueList: CallQueueEntity[] = queueRepo.getObjectList();

    const queueGroupIds: number[] = queueList.filter(
      q => !!q.group_id
      // && queue_ids_used.includes(q.id)
      && (_.isEmpty(this.callFilters) || _.isEmpty(this.callFilters.queues) || _.includes(this.callFilters.queues, q.id))
    ).map(q => q.group_id);

    const groupIntersection: number[] = groupIds.filter(gId => queueGroupIds.includes(gId));

    this.has_show_group = !_.isEmpty(groupIntersection);
  }
}
