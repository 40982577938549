import { Component, Input, OnInit, OnDestroy, ElementRef, ChangeDetectionStrategy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { DateTime, Duration } from 'luxon';
import * as _ from 'lodash';
import { TimerService } from '../../services';
import { parseDateTime } from '../../utils';
import { _ti } from '@wephone-translation';


/**
 * Generated class for the TimerSinceDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Component({
  selector: 'timer-since', // Attribute selector
  template: `<span>{{displayText}}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerSinceDirective implements OnInit, OnDestroy {
  timerId: any;
  _since: any;
  _sinceObj: DateTime;
  duration: Duration;
  displayText: string;

  @Input() max_duration: number;
  @Input() get since(): any {
    return this._since;
  }

  set since(v: any) {
    if (!v) {
      this._since = v;
      this._sinceObj = undefined;
      return;
    }

    this._since = v;
    if (!DateTime.isDateTime(v)) {
      this._sinceObj = parseDateTime(v);
    } else {
      this._sinceObj = v;
    }
  }

  constructor(
    private timer: TimerService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (_.isUndefined(this.max_duration)) {
      this.max_duration = 24;
    }

    this.timerId = this.timer.subscribe1000(() => {
      if (!this._sinceObj) {
        if (this.displayText !== '') {
          this.displayText = '';
          this.cdr.markForCheck();
        }
        return;
      }

      this.duration = DateTime.utc().diff(this._sinceObj);
      const minutes: number = +this.duration.toFormat('m');
      const durationFormat = minutes < 60 ? 'mm:ss' : 'hh:mm:ss';
      let s = this.duration.toFormat(durationFormat);

      if (this.showMaxDuration) {
        s = _ti('timer_since.max_duration_format', { max_duration: this.max_duration });
      }

      this.displayText = s;
      this.cdr.markForCheck();
    });
  }

  get showMaxDuration(): boolean {
    if (this.duration) {
      const hours: number = +this.duration.toFormat('h');
      return hours > this.max_duration;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe(this.timerId);
  }
}
