import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-flex-progress-line',
  templateUrl: './flex-progress-line.component.html',
  styleUrls: ['./flex-progress-line.component.scss']
})
export class FlexProgressLineComponent implements OnInit {
  @Input() tooltip: string;
  @Input() value: number;
  @Input() color: string;
  constructor() { }

  ngOnInit() {
  }

}
