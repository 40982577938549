import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, OnChanges, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { CountryGlobal, LocalManager, Timezone } from '@wephone-core/service/local_manager';

@Component({
  selector: 'timezone-select-form-input',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TimezoneSelectComponent }]
})
export class TimezoneSelectComponent extends FlexMatInputWrapper
implements OnInit, OnChanges, OnDestroy, MatFormFieldControl<any> {
  static nextId = 0;
  @Input() country: CountryGlobal;

  flexSelect: FormControl = new FormControl();
  list: Timezone[];
  flexSelectOptions: IFlexSelectOptions;

  objectKeys = Object.keys;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private readonly localManager: LocalManager,
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: Timezone, filterString) => {
        if (!item) {
          return false;
        }
        return regexSearch(item.label, filterString) || regexSearch(item.value, filterString);
      },
      compareWith: (a: Timezone, b: Timezone) => {
        return a && b && a.value === b.value || !b && !a;
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list = this.getTimezones();
  }

  ngOnChanges(): void {
    this.list = this.getTimezones();
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  get controlType(): string {
    return 'timezone-select-form-input';
  }

  getTimezones(): Timezone[] {
    const countryCode = this.country && this.country.code2 ? this.country.code2.toUpperCase() : undefined;

    const allTimezones = this.localManager.getAllTimezones();
    const defaultTz: Timezone[] = countryCode ?
      this.localManager.getTimezonesByCountry(countryCode) :
      allTimezones;

    if (this.value && allTimezones.find(x => x && x.value === this.value.value)) {
      if (!defaultTz.find(i => this.value && i && i.value === this.value.value)) {
        defaultTz.unshift(this.value);
      }
    }

    // Fixbug on init, the value is undefined.
    // if (!this.value && defaultTz.length) {
    //   this.value = defaultTz[0];
    // }

    return defaultTz;
  }
}
