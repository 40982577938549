import { Injectable } from '@angular/core';
import { Deferred } from '@wephone-utils/utils';

@Injectable()
export class DynamicScriptLoader {
    private scripts: any = {};

    load(...scripts: string[]): Promise<any> {
        const promises: any[] = [];
        scripts.forEach(script => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string): Promise<any> {
        // resolve if already loaded
        if (this.scripts[name]) {
            return this.scripts[name];
        }

        const ret = new Deferred();
        this.scripts[name] = ret;

        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = name;
        if (script['readyState']) {  // IE
            const readyState = script['readyState'];
            script['onreadystatechange'] = () => {
                if (readyState === 'loaded' || readyState === 'complete') {
                    script['onreadystatechange'] = undefined;
                    ret.resolve(name);
                }
            };
        } else {  // Others
            script.onload = () => {
                ret.resolve(name);
            };
        }
        script.onerror = (error: any) => ret.reject(error);
        document.getElementsByTagName('head')[0].appendChild(script);

        return ret.promise;
    }

    async importModule(moduleName: string): Promise<any>{
        const importedModule = await import(moduleName);
        return importedModule;
    }
}
