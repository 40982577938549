import { DidTelecomProvider } from '@wephone-core/model/entity/did';
import { DateTime } from 'luxon';

export interface SmsConversationItem {
    id: number;
    direction: number; // 0: in, 1: out
    message: string;
    client_number: string;
    send_status: SmsSendStatus;
    message_time: DateTime;
    scheduled_send_dt: DateTime;
}

export enum SmsSendStatus {
    SEND_STATUS_WAITING = 0,
    SEND_STATUS_TRYING = 1,
    SEND_STATUS_SENT = 2,
    SEND_STATUS_DELIVERED = 3,
    SEND_STATUS_FAILURE = 4
}

export enum SmsTelecomProvider {
  voxbone = DidTelecomProvider.voxbone,
  twilio = DidTelecomProvider.twilio,
  colibri = 3
}

export interface SmsDialog {
    id: number;
    remote_number: string;
    message_time: string;
    message: string;
    client_name: string;
    unread: number;
    did_id: number;
    sender_name: string;
    direction: number; // 0: sms-in, 1: sms-out
}

export interface SmsMessage {
    id: number;
    direction: number; // 0: in, 1: out
    message: string;
    client_number: string;
    send_status: number;
    creation_timee: string;
    client_name?: string;
    sender_name?: string;
    dialog_id?: number;
    remote_number?: string;
    creation_time?: string;
    failure_count?: number;
    failure_reason?: string;
    failure_reason_id?: number;
    is_manual?: number; // 0|1
    lang?: string;
    last_state_time?: string;
    notification_time?: string;
    provider_error_code?: string;
}
