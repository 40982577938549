import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { IEntity } from '@wephone-core/model/model.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { SortDirection, MatSort, Sort } from '@angular/material/sort';

class DynamicMatSort extends MatSort {
  customSortFn?(item: any): any;
}
export class DynamicFilterSource extends MatTableDataSource<any> {
  private lastValue;
  private dataSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private customSort: DynamicMatSort;
  private _readOnlyList: IEntity[] = [];

  constructor(private originalSource: Observable<any[]>, private filterFunc?: (any: any) => boolean) {
    super();

    this.customSort = this.customSort as DynamicMatSort;
    originalSource.subscribe(val => {
      this.lastValue = val;
      this.onFilterChange();
    });
  }

  setOrder(name: string, direction: SortDirection, customSortFn?: any): void {
    if (!this.customSort) {
      this.customSort = new DynamicMatSort();
    }

    _.extend(this.customSort, {
      active: name,
      direction,
      customSortFn
    });

    this.sort = _.extend(this.sort || new MatSort(), _.pick(this.customSort, ['active', 'direction']));

    this.onFilterChange();
  }

  private getIndexRow(row: any): number {
    return this.data ? this.data.indexOf(row) : undefined;
  }

  deleteRow(row: any): void {
    const idx: number = this.getIndexRow(row);
    if (idx >= 0) {
      this.data.splice(idx, 1);
      this.dataSubject.next(this.data);
    }
  }

  onFilterChange = () => {
    this.data = _.filter(this.lastValue, this.filterFunc);
    if (this.customSort && this.customSort.active && this.customSort.direction) {
      this.data.sort((a: any, b: any): number => {
        const greaterCmp = this.customSort.direction === 'desc' ? -1 : (this.customSort.direction === 'asc' ? 1 : 0);
        if (greaterCmp === 0) {
          return 0;
        }
        let v1 = a[this.customSort.active] || undefined;
        let v2 = b[this.customSort.active] || undefined;
        if (this.customSort.customSortFn) {
          v1 = this.customSort.customSortFn(a) || undefined;
          v2 = this.customSort.customSortFn(b) || undefined;
        }
        return v1 === v2 || !v1 && !v2 ? 0 : (v1 > v2 || !v2 ? greaterCmp : greaterCmp * -1);
        // }
        // console.error('No property associated with object', this.customSort.active, a, b);
      });
    }
    this.dataSubject.next(this.data);
  }

  getSelectedEntity(id: number): IEntity {
    return this.data ? this.data.find(x => x.id === id) : undefined;
  }

  setReadOnlyItems(items: IEntity[]): void {
    this._readOnlyList = items;
  }

  isRowReadonly(row: IEntity): boolean {
    return !_.isEmpty(this._readOnlyList) && _.includes(this._readOnlyList, row);
  }

  get dataChanges(): Observable<any[]> {
    return this.dataSubject.asObservable();
  }
}
