import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { joiValidator, ToastService, IFlexDialogConfig, DialogActionButton, Colors } from '@wephone-utils';
import * as Joi from 'joi-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';

@Component({
  selector: 'app-create-crm-routing-modal',
  templateUrl: './create-crm-routing-modal.component.html',
  styleUrls: ['./create-crm-routing-modal.component.scss']
})
export class CreateCrmRoutingModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('crm_routing.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  crmRouting: CrmRoutingRuleEntity;
  // form: FormGroup;
  crmList;
  constructor(
    private fb: FormBuilder,
    dialogRef: MatDialogRef<CreateCrmRoutingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public toast: ToastService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.crmRouting = _.cloneDeep(this.data.crmRouting);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.crmRouting.name, joiValidator(Joi.string().required())],
      crm: [this.crmRouting.crm_id, joiValidator(Joi.number().required())]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.crmRouting.name = changes.name;
        this.crmRouting.crm_id = changes.crm;
      })
    );
  }

  async resolveData(): Promise<void> {
    await EntityManager.getRepository('EnterpriseCrmRepository').findAll();
    this.crmList = EntityManager.getRepository('EnterpriseCrmRepository').getObjectList();
  }

  async submit(): Promise<void> {
    try {
      this.form.markAllAsTouched();

      const nameExist: boolean = (EntityManager.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository')as CrmRoutingRuleRepository)
        .isNameExist(this.form.get('name').value, this.crmRouting.id);
      if (nameExist) {
        this.form.get('name').setErrors({nameExist: true});
      }

      if (!this.form.valid) {
        console.error('Form invalid', this.form);
        throw new Error(_ti('public.message.data_invalid'));
      }

      const updatedCrmRouting = this.crmRouting;
      const response = await EntityManager.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository')
        .createAndSave(updatedCrmRouting);

      this.showInfo(_ti('public.message.create_success'));
      this.dismiss(updatedCrmRouting, false);
    } catch (error) {
      console.error('Create crm routing failure', error);
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  cancel(result?): void {
    this.dismiss(result);
  }
}
