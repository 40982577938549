import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { _tk, _ti } from '@wephone-translation';

export class CallingProfileRepository extends BaseRepository {
  static default: CallingProfileEntity;
  static calling_profiles: any = [
    {
      id: 1,
      name: _tk('calling_profile.internal')
    },
    {
      id: 2,
      name: _tk('calling_profile.national')
    },
    {
      id: 3,
      name: _tk('calling_profile.national_full')
    },
    {
      id: 4,
      name: _tk('calling_profile.international')
    }
  ];

  ENTITY_CLASS = CallingProfileEntity;
  protected URL_PREFIX = 'calling_profile';

  constructor() {
    super();
    this.initObjectList();
    if (!CallingProfileRepository.default) {
      const default_profile: any = new CallingProfileEntity();
      default_profile.name = 'calling_profile.default';
      default_profile.id = 0;
      CallingProfileRepository.default = default_profile;
    }
  }

  private initObjectList(): void {
    if (this.object_list && this.object_list.length) {
      return;
    }
    this.object_list = [];
    for (const callingprofile of CallingProfileRepository.calling_profiles) {
      const entity: CallingProfileEntity = this.create(callingprofile) as CallingProfileEntity;
      this.object_list.push(entity);
    }
  }
}

import { CallingProfileEntity } from '@wephone-core/model/entity/callingprofile';
