import { Component, OnInit, ElementRef, Optional, Input } from '@angular/core';
import { FlexMatInputWrapper, DialogService } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { FlexSelectTreeDialogOptions } from '@wephone-core/core/flex-select-tree-dialog-options';
import { FlexSelectTreeDialog } from '../flex-select-tree-dialog/flex-select-tree-dialog';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'flex-select-tree-input',
  templateUrl: './flex-select-tree-input.html',
  styleUrls: ['./flex-select-tree-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectTreeInput }]
})
export class FlexSelectTreeInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() multiple: boolean;
  @Input() title: string;
  @Input() nodes: FlexSelectTreeNode[];
  @Input() showCheckAll: boolean;

  selectedControl: FormControl;
  selectedText = '';
  nodeList: FlexSelectTreeNode[];

  private hasInitilized = false;

  constructor(
    elRef: ElementRef,
    @Optional() ngControl: NgControl,
    private dialogService: DialogService
  ) {
    super(ngControl, elRef);
    this.selectedControl = new FormControl();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.nodeList = this.getNestedNode(this.nodes);

    if (!this.placeholder) {
      this.placeholder = _ti('placeholder.default.select');
    }
    this.hasInitilized = true;
  }

  get wrappedControl(): FormControl {
    return this.selectedControl;
  }

  get controlType(): string {
    return 'flex-select-tree-input';
  }

  get empty(): boolean {
    if (this.multiple && _.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  writeValue(val): void {
    // fix for bug clear value button and init data
    if (!this.hasInitilized) {
      setTimeout(() => {
        this.selectedText = this.getSelectedText(val);
      });
    } else {
      this.selectedText = this.getSelectedText(val);
    }
    this.wrappedControl.setValue(val);
  }

  protected getSelectedText(val: any): string {
    if ((!_.isArray(val) && val !== 0 && !val) || (_.isArray(val) && _.isEmpty(val))) {
      return '';
    }

    let selectedValue = val;
    if (!_.isArray(selectedValue)) {
      selectedValue = [selectedValue];
    }

    let selectedNodes = [];
    if (this.nodeList) {
      selectedNodes = this.nodeList.filter(node => selectedValue.includes(node.id));
    }

    return selectedNodes.map(node => node.name).join(', ');
  }

  protected getNestedNode(nodes, list = []): FlexSelectTreeNode[] {
    for (const node of nodes) {
      list.push(_.extend(node, {
        checkbox: true
      }));

      if (node.children) {
        this.getNestedNode(node.children, list);
      }
    }

    return list;
  }

  onContainerClick(event: MouseEvent): void {
    this.focused = true;
    this.openDialog();
  }

  async openDialog(): Promise<any> {
    const options: FlexSelectTreeDialogOptions = {
      title: this.placeholder,
      nodes: this.nodes,
      multiple: this.multiple,
      selected: this.selectedControl.value,
      showCheckAll: this.showCheckAll
    };

    const dialogRef = this.dialogService.openDialog2(FlexSelectTreeDialog, { data: { options } });
    const data = await dialogRef.afterClosed().toPromise();
    this.focused = false;
    this.stateChanges.next();

    if (!data) { return; }

    this.selectedText = data.selectedText;
    this.selectedControl.setValue(data.selected);
  }

  stripHtml(html: string): string {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
