import { Entity, datafield } from '@wephone-core/model/model';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { EnterpriseContactEntity } from '@wephone-core/model/entity/enterprisecontact';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { EnterpriseContactRepository } from '@wephone-core/model/repository/enterprisecontact';
import { parseDateTime, PhoneNumberService } from '@wephone-utils';
import { DateTime } from 'luxon';

export class OutcallCampaignItemEntity extends Entity {
  static object_type_id = 'outcall_campaign_item';

  id: number;

  @datafield
  campaign_id: number;
  @datafield
  handling_user_id: number;
  @datafield
  last_cdr_id: number;
  @datafield
  queue_id: number;
  @datafield
  contact_id: number;
  @datafield
  finished: number;
  @datafield
  reserved: number;
  @datafield
  last_state_id: number;
  @datafield
  rescheduled: number;
  @datafield
  last_state_dt: DateTime;
  @datafield
  deadline_dt: DateTime;
  @datafield
  scheduled_dt: DateTime;
  @datafield
  upload_dt: DateTime;
  @datafield
  calling_number: string;
  @datafield
  called_number: string;
  @datafield
  hour_start: string;
  @datafield
  hour_end: string;
  @datafield
  last_state: string;
  @datafield
  first_name: string;
  @datafield
  last_name: string;
  @datafield
  company: string;
  @datafield
  custom1: string;
  @datafield
  custom2: string;
  @datafield
  custom3: string;
  @datafield
  agent_alias: string;
  @datafield
  max_try_count: number;
  @datafield
  custom_data: object;
  @datafield
  vxml_script: string;
  @datafield
  lang: string;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    this.scheduled_dt = object_data.scheduled_dt && parseDateTime(object_data.scheduled_dt);
  }

  get called_number_displayed(): string {
    return this.called_number && PhoneNumberService.getInstance().getDisplayNumber(this.called_number);
  }

  get name(): string {
    let name = this.first_name || '';
    if (this.last_name) {
      name = `${name} ${this.last_name}`;
    }
    return name;
  }

  get campaign(): OutcallCampaignEntity {
    return OutcallCampaignRepository.getInstance().getObjectById(this.campaign_id);
  }

  // Must findAll enterprise contacts before
  get contact(): EnterpriseContactEntity {
    return EnterpriseContactRepository.getInstance().getObjectById(this.contact_id);
  }
}
