import { Component } from '@angular/core';
import { MenuService, MainMenuItem } from '../menu.service';
import { _tk, _ti } from '@wephone-translation';
import { AccessRight, BusinessType } from '@wephone-core/system';

const USER_MENU_ITEMS: MainMenuItem[] = [
  {
    state: 'dashboard',
    name: _tk('site.dashboard'),
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'vocal_messages',
    name: _tk('menu.vocal_messages'),
    type: 'sub',
    icon: 'voicemail',
    children: [
      {
        state: 'voicemail-queue',
        accessPermission: AccessRight.VoiceMail,
        name: _tk('menu.voicemail')
      },
      {
        state: 'recorded-call-agent',
        accessPermission: AccessRight.Recording,
        name: _tk('menu.my_recorded_call')
      },
      {
        state: 'recorded-call',
        accessPermission: AccessRight.Recording,
        name: _tk('menu.recorded_call')
      },
    ]
  },
  {
    state: 'statistics',
    name: _tk('menu.statistics'),
    type: 'sub',
    icon: 'equalizer',
    children: [
      { state: 'user-stats', name: _tk('stats.user_stats.title') },
      { state: 'incoming', name: _tk('stats.callcenter.incoming'), accessPermission: AccessRight.Stats },
      { state: 'outgoing', name: _tk('stats.callcenter.outgoing'), accessPermission: AccessRight.Stats },
      { state: 'missedcall', name: _tk('stats.callcenter.missedcall'), accessPermission: AccessRight.Stats },
      { state: 'agent-status', name: _tk('stats.agent_status.title'), accessPermission: AccessRight.Stats },
      { state: 'connection-log', name: _tk('stats.agent_connection_log.title'), businessTypes: [BusinessType.CALLCENTER], accessPermission: AccessRight.Stats }
    ]
  },
  {
    state: 'telephony',
    name: _tk('menu.telephony'),
    type: 'sub',
    icon: 'call',
    businessTypes: [BusinessType.CALLCENTER],
    accessPermission: AccessRight.CallHistory,
    children: [
      { state: 'cdr', name: _tk('menu.manage_cdr') },
    ]
  },
];

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  providers: [MenuService]
})
export class UserMenuComponent {

  constructor(
    public menuService: MenuService
  ) {
  }

  get userMenus(): MainMenuItem[] {
    return USER_MENU_ITEMS;
  }
}
