import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AgentService } from '@wephone/services/agent.service';
import { DialogService } from '@wephone-utils';
import { MissedCallInfoDialogComponent } from '@wephone-common';

@Component({
  selector: 'missed-calls-action-renderer',
  template: `<button class="call-now" (click)="recallMissedCall(params)" matTooltip="{{ 'public.button.call_now'|translate }}"><mat-icon>phone</mat-icon></button>
  <button class="view-button" (click)="showDialogMissedCallInfo(params)" matTooltip="{{ 'stats.missed_call.actions.detail_call_info'|translate }}"><mat-icon>info</mat-icon></button>
  `,
  styleUrls: ['./missed-calls-action-renderer.scss']
})
export class MissedCallsActionRenderer implements ICellRendererAngularComp {
  params: any;

  constructor(
    private readonly dialogService: DialogService,
    private readonly agentService: AgentService
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  recallMissedCall = (params: any) => {
    this.agentService.make_recall_missed_call(params.data.id);
  }

  public showDialogMissedCallInfo = (params: any) => {
    this.dialogService.openDialog2(MissedCallInfoDialogComponent, { data: { cdr_id: params.data.id } });
  }
}
