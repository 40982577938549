<div class="form-group padding-none margin-none">
  <mat-card class="shadow-none header-text-no-margin">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon color="primary" class="title-icon">person</mat-icon>
      </div>
      <mat-card-title>
        <h2>{{'user.heading.access_rights'|translate }}</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="form-group">

      <form [formGroup]="form" class="full-width">
        <mat-list>
          <mat-list-item>
            <span matLine>{{'public.all'|translate}}</span>
            <mat-checkbox [checked]="allAccess" [indeterminate]="someAccess()" (change)="setAll($event.checked)"
              color="primary">
            </mat-checkbox>
          </mat-list-item>

          <mat-list-item *ngIf="form.get('LIVEPAGE')">
            <span matLine>{{'acl.feature_name.page_live'|translate}}</span>
            <mat-checkbox formControlName="LIVEPAGE"></mat-checkbox>
          </mat-list-item>

          <mat-list-item>
            <span matLine>{{'acl.feature_name.call_history'|translate}}</span>
            <mat-checkbox formControlName="CALLHISTORY"></mat-checkbox>
          </mat-list-item>

          <mat-list-item>
            <span matLine>{{'acl.feature_name.eavesdrop'|translate}}</span>
            <mat-checkbox formControlName="EAVESDROP"></mat-checkbox>
          </mat-list-item>

          <mat-list-item>
            <span matLine>{{'acl.feature_name.recording'|translate}}</span>
            <mat-checkbox formControlName="RECORDING"></mat-checkbox>
          </mat-list-item>

          <mat-list-item>
            <span matLine>{{'acl.feature_name.voicemail'|translate }}</span>
            <mat-checkbox formControlName="VOICEMAIL"></mat-checkbox>
          </mat-list-item>

          <mat-list-item>
            <span matLine>{{'acl.feature_name.stats'|translate }}</span>
            <mat-checkbox formControlName="STATS"></mat-checkbox>
          </mat-list-item>
        </mat-list>
      </form>
    </mat-card-content>
  </mat-card>
</div>
