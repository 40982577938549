import { Component, OnInit, Input, OnDestroy, Self, Optional, ElementRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { TagEntity } from '@wephone-core/model/entity/tag';

@Component({
  selector: 'user-tag-select-form-input',
  templateUrl: './user-tag-select.component.html',
  styleUrls: ['./user-tag-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UserTagSelectFormInput }]
})
export class UserTagSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<UserGroupEntity> {
  static nextId = 0;
  flexSelect: FormControl = new FormControl();
  list: TagEntity[];
  flexSelectOptions: IFlexSelectOptions;
  @Input() multiple: boolean;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: TagEntity, filterString) => {
        return regexSearch(item.tag_name, filterString);
      },
      compareWith: (a: TagEntity, b: TagEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    EntityManager.getRepository('TagRepository').findAll(false).then(() => {
      this.list = this.em.getRepository('TagRepository').getObjectList();
    });
  }

  get controlType(): string {
    return 'user-tag-select-form-input';
  }
}
