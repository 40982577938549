import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  DynamicFilterSource,
  DialogService,
  IFlexTableConfig,
  IFlexTableSidePannelOptions,
  FlexCRUDPageComponent,
  regexSearch,
} from '@wephone-utils';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { QualificationEditComponent } from '@wephone/pages/qualification/qualification-edit/qualification-edit.component';
import { QualificationFormDialogComponent } from '@wephone/pages/qualification/dialogs/qualification-form-dialog/qualification-form-dialog.component';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { QualificationService } from '@wephone-common';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';

@Component({
  selector: 'qualification-list',
  templateUrl: './qualification-list.component.html',
  styleUrls: ['./qualification-list.component.scss']
})
export class QualificationListComponent extends FlexBasePage implements OnInit {
  // Private member var with default value
  private readonly qualificationRepo: QualificationRepository;

  // Public member var
  filterForm: FormGroup;
  filterString: string;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: QualificationEditComponent
  };
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;
  constructor(
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly qualifService: QualificationService,
    configManager: ConfigManager,
  ) {
    super();
    this.qualificationRepo = this.em.getRepository<QualificationRepository>('QualificationRepository');
    const qualificationSource = this.qualificationRepo.dataSource<QualificationEntity>();
    this.dataSource = new DynamicFilterSource(qualificationSource, this.filterItemOnSearch);

    this.tableConfig = {
      columns: [
        {
          name: 'value',
          label: _tk('call_qualification.content.name'),
          enableTooltip: true,
          lineClamp: 2,
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (qualif: QualificationEntity) => {
            return qualif.value.toLowerCase();
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.create();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ],
          secondary: [
            {
              id: 'clone',
              unique: true,
              icon: 'file_copy',
              hint: _tk('public.clone'),
              callback: data => {
                return this.cloneQualification(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    if (configManager.hasFeature('SERVICE_GROUP')) {
      this.tableConfig.columns.push({
        name: 'group',
        label: _tk('call_qualification.content.group_name')
      });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.filterForm = this.fb.group({
      filterValue: [this.filterString]
    });

    this.filterForm.valueChanges.subscribe(this.onFormValueChange);
  }

  async cloneQualification(items: QualificationEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    try {
      if (items.find(x => !!x.parent_id)) {
        throw new Error('Cannot clone qualification children');
      }
      const resp = await this.qualificationRepo.cloneAndSave(items[0]);
      console.log('resp clone', resp);
      this.successToast(_ti('public.message.clone_success'));
    } catch (error) {
      console.log('resp error', error);
      this.showError(_ti('public.message.clone_failure'));
    }
  }

  private readonly onFormValueChange = formValues => {
    // this.dataSource.filter = formValues.filterValue;
    this.filterString = (formValues.filterValue || '').trim();
    this.dataSource.onFilterChange();
  }

  private filterPredicate(item: QualificationEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(item.value, filterString) || (item.group && regexSearch(item.group.name, filterString));
  }

  filterItemOnSearch = (item: QualificationEntity) => {
    return !item.parent_id && this.filterPredicate(item, this.filterString);
  }

  private async create(): Promise<any> {
    const dialogConfig = {
      data: {
        formConfig: {
          editName: true,
          editGroup: true
        }
      }
    };

    await this.dialogService.openDialog2(QualificationFormDialogComponent, dialogConfig, (qualification: QualificationEntity) => {
      if (qualification) {
        this.successToast(_ti('call_qualification.message.create_success'));
        this.gotoEditQualification(qualification);
      }
    }).afterClosed().toPromise();
  }

  bulkDelete(quals: QualificationEntity[]): any {
    const msgs: string[] = [];
    const qualIds = quals.map(q => q.id);
    const objectInuseds = this.qualifService.getObjectsUsingQualifications(qualIds);
    const usedQueueList = objectInuseds.filter(x => x instanceof CallQueueEntity) as CallQueueEntity[];
    const usedCampaignList = objectInuseds.filter(x => x instanceof OutcallCampaignEntity) as OutcallCampaignEntity[];

    if (usedQueueList.length) {
      const usedQueues = quals.filter(q => {
        return !!usedQueueList.find(queue => queue.has_qualification && (queue.in_qualification_id === q.id || queue.out_qualification_id === q.id));
      });
      msgs.push(_ti('qualification.message.confirm_delete_with_dependent_queues', {
        dependent_queues: usedQueueList.map(x => x.queue_name).join(', '),
        objects: usedQueues.map(i => i.value),
      }));
    }

    if (usedCampaignList.length) {
      const usedCampaigns = quals.filter(q => {
        return !!usedCampaignList.find(c => {
          return c.queue && c.queue.has_qualification && (c.queue.in_qualification_id === q.id || c.queue.out_qualification_id === q.id);
        });
      });
      msgs.push(_ti('qualification.message.confirm_delete_with_dependent_campaigns', {
        dependent_campaigns: usedCampaignList.map(x => x.name).join(', '),
        objects: usedCampaigns.map(i => i.value),
      }));
    }

    if (objectInuseds.length) {
      msgs.unshift(_ti('public.message.cannot_delete_inused'));
      const msg = msgs.join('\n');
      return this.dialogService.showAlert(_ti('dialogs.warning'), msg);
    }

    return this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('user.title.delete'), () => {
      if (quals) {
        this.qualificationRepo.bulkDelete(quals).then(
          res => {
            this.flexCrud.closeSidePanel();

            if (res && res.error && res.error.message) {
              this.showError(res.error.message);
              return undefined;
            }

            this.successToast(_ti('public.message.delete_success'));
          }, e => {
            console.error('Delete error', e);
            this.flexCrud.closeSidePanel();

            const msg = (e.error && e.error.message ? e.error.message : e.message) || '';
            this.showError(_ti('public.message.update_failure') + ` ${msg}`);
          }
        );
      }
    });
  }

  private gotoEditQualification(qualification: QualificationEntity): void {
    this.router.navigate(['callcenter-params', 'qualification', qualification.id], { skipLocationChange: false });
  }
}
