import { Component, Inject } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { Speech2TextService } from '../service/speech-recognition.service';

@Component({
  selector: 'speech-recognition-button',
  templateUrl: './speech-recognition-button.component.html',
  styleUrls: ['./speech-recognition-button.component.scss']
})
export class SpeechRecognitionButton extends FlexBaseComponent {
  listening = false;

  constructor(private text2speech: Speech2TextService) {
    super();
  }

  onClick(): void {
    this.listening = !this.listening;
    if (this.listening) {
      this.text2speech.start(this.onResult);
    }
  }

  onResult(text: string): void {
    console.log('Text received is ', text);
  }

}
