export enum CrmCallLogState {
  DO_NOTHING = 0,
  LOG_COMPLETED = 1,
  LOG_INPROGRESS = 2,
  LOG_NOTSTARTED = 3,
  LOG_CUSTOM = 4,
}

export enum LogTo {
  CONTACT = 0,
  LEAD = 1,
  DEAL = 2,
}

export interface HotelCustomData {
  client_id: string;
  client_secret: string;
  hotel_id: string;
  cashier_id: string;
  transaction_code: string;
}
