import { Entity, mapped_datafield, datafield } from '@wephone-core/model/model';
import { parseDateTime } from '@wephone-utils';
import { DateTime } from 'luxon';

export class SipPhoneConnectionLogEntity extends Entity {
  static object_type_id = 'sipphone_connection_log';
  
  id: number;
  @datafield user_agent: string;
  @datafield sipphone_id: number;
  @datafield event_dt: DateTime;
  @datafield expire_dt: DateTime;
  @datafield disconnection: number;


  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.event_dt = object_data.event_dt ? parseDateTime(object_data.event_dt) : null;
    this.expire_dt = object_data.expire_dt ? parseDateTime(object_data.expire_dt) : null;
  }

}
