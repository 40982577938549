import { Entity, datafield } from '@wephone-core/model/model';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserRepository } from '@wephone-core/model/repository/user';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { parseDateTime, isInThePast } from '@wephone-utils';

export class EavesdropRequestEntity extends Entity {
  public static object_type_id = 'eavesdrop_request';
  public static EAVESDROP_TARGET_USER = 0;
  public static EAVESDROP_TARGET_QUEUE = 1;
  public static EAVESDROP_TARGET_GROUP = 2;

  public id: number;
  @datafield
  public eavesdropper_user_id: number;
  @datafield
  public eavesdropper_phone: string;
  @datafield
  public deadline: any;
  @datafield
  public target_id: number;
  @datafield
  public target_type: number;
  @datafield
  public creation_dt: any;
  @datafield
  public finished: number;
  @datafield
  public enterprise_id: number;
  @datafield
  public is_listening: number;
  @datafield
  public callpark_id: string;
  @datafield
  public session_ids: number[];

  public setObjectData(object_data: any, fetch_related_data = true): void {
    super.setObjectData(object_data, fetch_related_data);
    this.deadline = this.deadline && parseDateTime(this.deadline);
  }

  get eavesdropper_user(): IUserEntity {
    if (this.eavesdropper_user_id) {
      return UserRepository.getInstance().getObjectById(this.eavesdropper_user_id);
    }
    return null;
  }

  get target_name(): string {
    const target_user = this.target_user;
    if (target_user) {
      return target_user.name;
    }

    const target_queue = this.target_queue;
    if (target_queue) {
      return target_queue.queue_name;
    }

    const target_group = this.target_group;
    if (target_group) {
      return target_group.name;
    }
  }

  get target_user(): IUserEntity {
    if (this.target_type !== EavesdropRequestEntity.EAVESDROP_TARGET_USER) {
      return null;
    }
    return UserRepository.getInstance().getObjectById(this.target_id);
  }

  get target_queue(): CallQueueEntity {
    if (this.target_type !== EavesdropRequestEntity.EAVESDROP_TARGET_QUEUE) {
      return null;
    }
    return CallQueueRepository.getInstance().getObjectById(this.target_id);
  }

  get target_group(): UserGroupEntity {
    if (this.target_type !== EavesdropRequestEntity.EAVESDROP_TARGET_GROUP) {
      return;
    }
    return GroupRepository.getInstance().getObjectById(this.target_id);
  }

  get is_active(): boolean {
    return this.finished == 0 && (!this.deadline || !isInThePast(this.deadline));
  }
}
