import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { SingletonBase } from '@wephone-utils';

@Injectable()
export class AuthGuardService extends SingletonBase implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      // const stateUrl = state.url === '/' ? '/dashboard' : state.url;
      const stateUrl = state.url === '/' ? null : state.url;
      const returnUrl = route.queryParamMap.has('returnUrl') ? route.queryParamMap.get('returnUrl') : stateUrl;

      // this.router.navigate(['/s/signin'] , { queryParams: { returnUrl } });

      if (returnUrl) {
        this.router.navigate(['/s/signin'], { queryParams: { returnUrl } });
      } else {
        this.router.navigate(['/s/signin']);
      }

      return false;
    }

    return true;
  }
}
