import * as _ from 'lodash';

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { EditingComponent, NoWhitespaceValidator, ToastService } from '@wephone-utils';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserRepository } from '@wephone-core/model/repository/user';
import { takeUntil } from 'rxjs/operators';
import { TeamGroupRepository } from '@wephone-core/model/repository/teamgroup';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { _tk, _ti } from '@wephone-translation';
import { FormInputErrorStateMatcher } from '@wephone/services/form-validator';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.scss']
})
export class TeamEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: TeamGroupEntity;

  private readonly teamRepo = TeamGroupRepository.getInstance<TeamGroupRepository>();
  private readonly userRepo = UserRepository.getInstance<UserRepository>();
  private readonly _onDestroy = new Subject();

  team: TeamGroupEntity;
  users: UserEntity[] = [];
  teams: TeamGroupEntity[] = [];
  excludedUsers: UserEntity[] = [];
  excludedIds: number[];
  userteamList: TeamGroupEntity[];
  excludedGroups: TeamGroupEntity[];

  matcher = new FormInputErrorStateMatcher();
  filteredUsersMulti: ReplaySubject<UserEntity[]> = new ReplaySubject<UserEntity[]>(1);
  usersMultiFilterCtrl: FormControl = new FormControl();

  constructor(
    public toast: ToastService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.users = this.userRepo.getObjectList();
    this.teams = this.teamRepo.getObjectList();
    this.team = _.cloneDeep(this.editingItem);
    this.initFormGroup();

    this.filteredUsersMulti.next(this.users.slice());
    this.usersMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterUsersMulti();
    });

    this.excludedIds = [];
    if (this.team && this.team.id) {
      const excludedId = +this.team.id;
      this.excludedIds.push(excludedId);
      this.excludedGroups = this.teamRepo.getAllChildrenGroupById(excludedId) as TeamGroupEntity[];

      for (const t of this.excludedGroups) {
        this.excludedIds.push(t.id);
      }

      this.userteamList = this.teams.filter(x => !_.includes(this.excludedIds, x.id));

      this.setExcludedUsers();
    }

  }

  setExcludedUsers(): void {
    let excludedUsers: UserEntity[] = [];
    for (const t of this.teamRepo.getObjectList()) {
      if (!_.isEmpty(t.users) && t.id !== this.team.id) {
        excludedUsers = excludedUsers.concat(t.users as UserEntity[]);
      }
    }

    if (!_.isEmpty(this.form.get('users').value)) {
      excludedUsers = excludedUsers.concat(this.form.get('users').value);
    }

    this.excludedUsers = excludedUsers;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.team.name, [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      parentId: [this.team.parent ? this.team.parent.id : undefined],
      description: [this.team.description],
      users: [this.team.users.map(u => u.id)]
    });

    this.form.valueChanges.subscribe(changes => {
      console.log('changes', changes);
      this.onFormValueChange();
    });
  }

  async submitForm(): Promise<void> {
    try {
      const name: string = this.form.get('name').value.trim();
      const teamGroup: TeamGroupEntity = this.teamRepo.getObjectUsingName(name, this.team.id) as TeamGroupEntity;

      if (teamGroup) {
        this.form.get('name').setErrors({ duplicated: true });
      }

      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.toast.showError(_ti('public.message.data_invalid'));
        return;
      }

      const updatedGroup = this.teamRepo.create();
      updatedGroup.setObjectData({
        id: this.team.getId(),
        name: _.trim(this.form.get('name').value),
        parent_id: this.form.get('parentId').value || null,
        description: _.trim(this.form.get('description').value),
      });

      const extraData = { user_ids: this.getFormUpdateUserIds() };
      const response: { object_id: number } = await this.teamRepo.save(updatedGroup, extraData);
      await this.teamRepo.reload();
      this.toast.show(_ti('public.message.update_success'));
      const teamList = this.teamRepo.getObjectList();

      this.filterSort(teamList);

      // Manually update team because dont know how to get from remote
      this.team = this.teamRepo.getObjectById(response.object_id);
      this.team.users = !_.isEmpty(this.form.get('users').value) ? this.userRepo.getObjectListByIds(this.form.get('users').value) : [];
      this.resetForm();

    } catch (error) {
      let msg = _ti('public.message.create_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toast.showError(msg);
    }
  }

  private getFormUpdateUserIds(): number[] {
    const userIds = this.form.get('users').value || [];
    return userIds;
  }

  private getFormResetData(): any {
    return {
      name: this.team.name,
      parentId: this.team.parent ? this.team.parent.id : undefined,
      description: this.team.description,
      users: this.team.users.map(u => u.id)
    };
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();

    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  private filterUsersMulti(): void {
    if (!this.users) {
      return;
    }

    let search: string = this.usersMultiFilterCtrl.value;
    if (!search) {
      this.filteredUsersMulti.next(this.users.slice());

      return;
    }

    search = search.toLowerCase();

    this.filteredUsersMulti.next(this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1));
  }

  clearParentValue(e: MouseEvent): void {
    e.stopPropagation();
    this.form.get('parentId').setValue(null);
  }

  get parentTeam(): TeamGroupEntity {
    if (!this.form) {
      return;
    }

    const parentId: number = +this.form.get('parentId').value;
    if (!parentId) {
      return;
    }

    return this.teamRepo.getObjectById(parentId);
  }

  filterSort(teams: TeamGroupEntity[]): TeamGroupEntity[] {
    return teams.sort((a, b) => {
      const comparison = a.path_name.localeCompare(b.path_name);
      if (comparison === 0) {
        return a.name.localeCompare(b.name);
      }

      return comparison;
    });
  }
}
