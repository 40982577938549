<div class="pa-1">
  <form [formGroup]="form" *ngIf="form">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'group.content.name' | translate }}</mat-label>
        <input matInput placeholder="{{'group.content.name' | translate}}" formControlName="name"
          [errorStateMatcher]="matcher">
  
        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="usergroupList.length > 0">
      <mat-form-field class="full-width flex-select">
        <mat-label>{{'group.content.parent'|translate}}</mat-label>
        <flex-select-group-input [groups]="usergroupList" placeholder="{{'group.content.parent'|translate}}"
          groupType="service" formControlName="parentId">
        </flex-select-group-input>
        <button mat-icon-button matSuffix type="button" (click)="removeOption($event, 'parentId')"
          [disabled]="!form.controls['parentId'].value">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'group.content.description' | translate }}</mat-label>
        <textarea matInput rows="2" placeholder="{{ 'group.content.description' | translate }}"
          formControlName="description"></textarea>
        
        <mat-error [flexFormControlError]="form.get('description')"></mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="crmList.length">
      <mat-form-field class="full-width">
        <mat-label>{{'group.content.crm'| translate}}</mat-label>
        <mat-select placeholder="{{ 'group.content.crm' | translate }}" formControlName="crmId">
          <mat-option *ngFor="let crm of crmList" [value]="crm.id">
            {{crm.name}}
          </mat-option>
        </mat-select>
        <button mat-icon-button matSuffix type="button" (click)="removeOption($event, 'crmId')"
          [disabled]="!form.controls['crmId'].value">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <group-user-list formControlName="users" [directUsers]="directUsers"
        [title]="'call_queue.content.operator_associated'|translate"></group-user-list>
      <!-- <mat-form-field class="full-width">
        <mat-select placeholder="{{ 'call_queue.content.operator_associated'|translate }}" multiple formControlName="users">
          <ngx-mat-select-search [formControl]="usersMultiFilterCtrl" placeholderLabel="{{ 'public.search' | translate }}" noEntriesFoundLabel="{{ 'public.message.no_results' | translate }}"></ngx-mat-select-search>
          <mat-option *ngFor="let user of filteredUsersMulti | async" [value]="user.id">{{ user.name }}</mat-option>
        </mat-select>
      </mat-form-field> -->
      <mat-card class="pa-0">
        <div fxLayout="column" *ngIf="dids.length">
          <mat-toolbar fxLayoutAlign="end center">
            <h3 fxFlex="auto" class="ma-0 fontfamily-bold title-group">
              <strong>{{ 'group.content.associated_did'|translate }}</strong>
            </h3>
          </mat-toolbar>
          <mat-card-content class="px-1" [attr.data-ci]="'group-in-used-did'">
            <div class="my-1">
              <mat-chip-list>
                <mat-chip *ngFor="let did of dids" color="primary">
                  {{ did.number_with_name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-card-content>
        </div>
      </mat-card>

      <mat-card class="pa-0">
        <div fxLayout="column" *ngIf="queues.length">
          <mat-toolbar fxLayoutAlign="end center">
            <h3 fxFlex="auto" class="ma-0 fontfamily-bold title-group">
              <strong>{{ 'group.content.associated_queue'|translate }}</strong>
            </h3>
          </mat-toolbar>
          <mat-card-content class="px-1" [attr.data-ci]="'group-in-used-queues'">
            <div class="my-1">
              <mat-chip-list>
                <mat-chip *ngFor="let queue of queues" color="primary">
                  {{queue.queue_name}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-card-content>
        </div>
      </mat-card>

      <mat-card class="pa-0">
        <div fxLayout="column" *ngIf="campaigns.length">
          <mat-toolbar fxLayoutAlign="end center">
            <h3 fxFlex="auto" class="ma-0 fontfamily-bold title-group">
              <strong>{{ 'group.content.associated_campaigns'|translate }}</strong>
            </h3>
          </mat-toolbar>
          <mat-card-content class="px-1" [attr.data-ci]="'group-in-used-campaigns'">
            <div class="my-1">
              <mat-chip-list>
                <mat-chip *ngFor="let campaign of campaigns" color="primary">
                  {{campaign.name}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </form>
</div>
