import { Injector } from '@angular/core';

export class StaticServiceLocator {
  static injector: Injector;
  static serviceMap: Map<string, any> = new Map<string, any>();

  static setServiceByName(service_name: string, service) {
    StaticServiceLocator.serviceMap[service_name] = service;
  }

  static getServiceByName(service_name: string) {
    return StaticServiceLocator.serviceMap[service_name];
  }
}
