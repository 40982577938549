import { Component, OnInit, Inject } from '@angular/core';
import { SubscriptionPackRepository } from '@wephone-core/model/repository/subscription_pack';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { Colors, DialogActionButton, IFlexDialogConfig } from '@wephone-utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-transform-premium',
  templateUrl: 'transform-premium.html',
  styleUrls: ['transform-premium.scss']
})
export class TransformPremiumPage extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('enterprise_info.transform_premium.go_premium');
  dialogRightActions: Array<DialogActionButton> = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  // private subscriptionPackRepo = SubscriptionPackRepository.getInstance() as SubscriptionPackRepository;
  form: FormGroup;
  enterprise: EnterpriseEntity;
  constructor(
    dialogRef: MatDialogRef<TransformPremiumPage>,
    @Inject(MAT_DIALOG_DATA) public data,
    // private configManager: ConfigManager,
    private fb: FormBuilder,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.enterprise = _.cloneDeep(this.data.enterprise);
    this.initFormGroup();
  }

  public cancel(result?): void {
    this.dismiss(result);
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      ccLicense: ['', Validators.required]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.enterprise.cc_license = changes.ccLicense;
      })
    );
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.error('Invalid form');
      return;
    }
    this.dismiss(this.enterprise, false);
  }
}
