import { Injectable } from '@angular/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { GroupTreeModal } from '@wephone/modals/group-tree-modal/group-tree-modal';
import { DialogService } from '@wephone-utils';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { DialogQueueHistoryComponent } from '@wephone/modals/dialog-queue-history/dialog-queue-history.component';

@Injectable()
export class DashboardService {
  constructor(private em: EntityManager, private dialogService: DialogService) {}

  getServiceGroupList(): GroupEntity[] {
    return this.em.getRepository<GroupRepository>('GroupRepository').getServiceGroupList() as GroupEntity[];
  }

  showGroupFilterDialog(
    data: { group_ids: number[]; checked_all?: boolean; show_ungrouped_agents?: boolean },
    callback: (data) => void
  ): Promise<any> {
    return this.dialogService.openDialog2(GroupTreeModal, { data, size: 's' }, callback).afterClosed().toPromise();
  }

  showActionLogsDialog(): Promise<any> {
    return this.dialogService.openDialog2(DialogQueueHistoryComponent, {}).afterClosed().toPromise();
  }
}
