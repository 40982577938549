import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FlexBasePage } from '@wephone-core-ui';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { MissedCallDetail } from '@wephone-core/service/cdr_service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _ti } from '@wephone-translation';
import * as _ from 'lodash';

@Component({
  selector: 'missed-call-expand',
  templateUrl: './missed-call-expand.component.html',
  styleUrls: ['./missed-call-expand.component.scss']
})
export class MissedCallExpandComponent extends FlexBasePage implements OnInit, OnChanges {
  @Input() data: MissedCallDetail;

  private agentList: AgentEntity[] = [];
  private hasInitilized = false;

  constructor(
    private readonly em: EntityManager,
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasInitilized) {
      this.setAgentList();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.hasInitilized = true;
  }

  protected resolveData(): any {
    this.setAgentList();
  }

  get agent_names(): string[] {
    return this.agentList.map(a => a.name);
  }

  get missed_call_reason(): string {
    if (!this.data.missed_call_reason) {
      return '';
    }

    return _ti(`missed_call.missed_call_reason.reason_${this.data.missed_call_reason}`);
  }

  private setAgentList(): void {
    if (this.data && !_.isEmpty(this.data.agent_list)) {
      const agentList = this.em.getRepository<AgentRepository>('AgentRepository').getObjectList();
      this.agentList = _.sortBy(agentList.filter(a => this.data.agent_list.includes(a.id)), [a => a.name], ['asc']);
    }
  }

}
