import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonAPI } from '@wephone-core/service/common_api';
import { SingletonBase } from '@wephone-utils';

@Injectable()
export class LiveQoSWatchService extends SingletonBase {
  private static URL_QOS_WATCH_DATA = '/api/liveqoswatch/get_qos_watch_data';

  constructor(private datePipe: DatePipe, private common_api: CommonAPI) {
    super();
  }

  public getQoSWatchData(liveqoswatch_id: number) {
    let params: any = { liveqoswatch_id: liveqoswatch_id };
    return CommonAPI.wsGet(LiveQoSWatchService.URL_QOS_WATCH_DATA, params);
  }
}
