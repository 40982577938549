<ng-template #tplAgentStatus let-row="row" let-col="col">
    <agent-status class="full-width" [user_id]="row[col]"></agent-status>
</ng-template>

<ng-template #tplAgentStatusSince let-row="row" let-col="col">
    <agent-status-since class="full-width" [user_id]="row[col]"></agent-status-since>
</ng-template>

<ng-template #tplTextCenter let-row="row" let-col="col">
    <span class="full-width text-center">{{ row[col] }}</span>
</ng-template>

<ng-template #tplQueues let-row="row" let-col="col">
    <!-- <div class="scrollable">
        <mat-chip-list *ngIf="row.inbound_queues" fxLayout="row">
            <mat-chip color="primary" *ngFor="let item of row.inbound_queues" matTooltip="{{ item }}"><span
                    class="queue-name">{{ item }}</span></mat-chip>
        </mat-chip-list>
    </div> -->
    <span *ngIf="row.inbound_queues" mat-icon-button [matTooltip]="row.inbound_queues.join(', ')">
        {{ row.inbound_queues.slice(0,2).join(', ') }}
        <i *ngIf="row.inbound_queues.length > 2">...</i>
    </span>
</ng-template>

<ng-template #tplCampaigns let-row="row" let-col="col">
    <!-- <mat-chip-list *ngIf="row.outbound_queues" fxLayout="row">
        <mat-chip color="primary" *ngFor="let item of row.outbound_queues" matTooltip="{{ item }}"><span
                class="queue-name">{{ item }}</span></mat-chip>
    </mat-chip-list> -->
    <span *ngIf="row.outbound_queues" mat-icon-button [matTooltip]="row.outbound_queues.join(', ')">
        {{ row.outbound_queues.slice(0,2).join(', ') }}
        <i *ngIf="row.outbound_queues.length > 2">...</i>
    </span>
</ng-template>

<flex-table #flexTable *ngIf="initialized" [dataSource]="dataSource" [tableConfig]="tableConfig" fxLayout="column" fxFlex="grow">
</flex-table>
