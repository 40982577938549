import { Component, OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { FlexProgressBarService } from '../../services/flex-progress-bar.service';
import { SubscriptionManagedComponent } from '../base';

@Component({
  selector: 'flex-progress-bar',
  templateUrl: './flex-progress-bar.component.html',
  styleUrls: ['./flex-progress-bar.component.scss'],
})

export class FlexProgressBarComponent extends SubscriptionManagedComponent implements OnInit, OnDestroy {
  constructor(
      private progressBarService: FlexProgressBarService,
      private el: ElementRef,
      private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(
      this.progressBarService.pendingActionChange.subscribe(
        (pendingActionCount: number) => {
          this.renderer.setStyle(this.el.nativeElement, 'visibility', pendingActionCount > 0 ? 'visible' : 'hidden');
//          this.cdr.detectChanges();
        }
      )
    );
  }
}
