export const CALL_ROUTING_APPS = [
  {
    id: 1,
    application: 'call_phone_number',
    app_name: 'routing_application.call_phone_number.app_name',
    html_hint: 'routing_application.call_phone_number.html_hint',
    params: [
      {
        param_name: 'number',
        param_type: 'string',
        param_value: '',
        default_value: null,
        required: 1
      },
    ],
  },
  {
    id: 2,
    application: 'callqueue',
    app_name: 'routing_application.callqueue.app_name',
    html_hint: 'routing_application.callqueue.html_hint',
    params: [
      {
        param_name: 'queue',
        param_type: 'string',
        param_value: null,
        default_value: null,
        required: 1
      },
    ],
  },
  {
    id: 3,
    application: 'call_phone',
    app_name: 'routing_application.call_phone.app_name',
    html_hint: 'routing_application.call_phone.html_hint',
    params: [
      {
        param_name: 'sipphone_id',
        param_type: 'int',
        param_value: null,
        default_value: null,
        required: 1
      },
    ],
  },
  {
    id: 4,
    application: 'conference',
    app_name: 'routing_application.conference.app_name',
    html_hint: 'routing_application.conference.html_hint',
    params: [
      {
        param_name: 'conference',
        param_type: 'int',
        param_value: null,
        default_value: null,
        required: 1
      },
    ],
  },
  {
    id: 5,
    application: 'ivr_custom_menu',
    app_name: 'routing_application.ivr_custom_menu.app_name',
    html_hint: 'routing_application.ivr_custom_menu.html_hint',
    params: [
      { param_name: 'id', param_type: 'int', param_value: null, default_value: null, required: 1 },
    ]
  },
  {
    id: 6,
    application: 'runvxmlscript',
    app_name: 'routing_application.runvxmlscript.app_name',
    html_hint: 'routing_application.runvxmlscript.html_hint',
    ui_modes: ['ui_callcenter'],
    params: [
      { param_name: 'ivrscript_id', param_type: 'int', param_value: null, default_value: null, required: 1 },
    ]
  },
  {
    id: 7,
    application: 'play_then_hangup',
    app_name: 'routing_application.play_then_hangup.app_name',
    html_hint: 'routing_application.play_then_hangup.html_hint',
    params: [
      { param_name: 'sound_id', param_type: 'int', param_value: null, default_value: null, required: 1 },
    ]
  },
  {
    id: 8,
    application: 'go_up',
    app_name: 'routing_application.go_up.app_name',
    html_hint: 'routing_application.go_up.html_hint',
  },
  {
    id: 9,
    application: 'reprompt',
    app_name: 'routing_application.reprompt.app_name',
    html_hint: 'routing_application.reprompt.html_hint'
  },
  {
    id: 11,
    application: 'outbound_agent',
    app_name: 'routing_application.outbound_agent.app_name',
    html_hint: 'routing_application.outbound_agent.html_hint',
    ui_modes: ['ui_callcenter'],
  },
  {
    id: 12,
    application: 'call_user',
    app_name: 'routing_application.call_user.app_name',
    html_hint: 'routing_application.call_user.html_hint',
    params: [
      { param_name: 'id', param_type: 'int', param_value: null, default_value: null, required: 1 },
    ]
  },
  {
    id: 15,
    application: 'remote_application',
    app_name: 'routing_application.remote_application.app_name',
    html_hint: 'routing_application.remote_application.html_hint',
    ui_modes: ['ui_callcenter'],
    params: [
      { param_name: 'id', param_type: 'int', param_value: null, default_value: null, required: 0 },
      { param_name: 'alias', param_type: 'string', param_value: null, default_value: null, required: 0 },
    ]
  },
  {
    id: 16,
    application: 'to_did',
    app_name: 'routing_application.to_did.app_name',
    html_hint: 'routing_application.to_did.html_hint',
    params: [
      { param_name: 'id', param_type: 'int', param_value: null, default_value: null, required: 1 },
    ],
  },
  {
    id: 17,
    application: 'send_sms',
    app_name: 'routing_application.send_sms.app_name',
    html_hint: 'routing_application.send_sms.html_hint',
    params: [
      { param_name: 'sms_id', param_type: 'int', param_value: null, default_value: null, required: 1 },
      { param_name: 'sound_id', param_type: 'int', param_value: null, default_value: null, required: 1 },
    ],
  }
];
