import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { MessageService } from '@wephone-utils/services/message.service';
import { SingletonBase } from '@wephone-utils/utils/singleton';


export class ApiCallError extends Error {
  constructor(public code: string, public message: string) {
    super(message);
  }
}

@Injectable()
export class HttpEngine extends SingletonBase {
  constructor(private $http: HttpClient, private settings: FlexIvrSettings, private messageService: MessageService) {
    super();
  }

  static getInstance(): HttpEngine {
    return super.getInstance() as HttpEngine;
  }

  get(url: string, query_params?: any, headers?: any, timeout?: number): Promise<any> {
    this.checkUrlValidity(url);
    let h;
    if (timeout) {
      h = { timeout: `${timeout}` };
      _.extend(h, headers);
    } else {
      h = headers;
    }

    const params = new HttpParams({ fromObject: query_params });
    const requestParams = { params };
    if (h) {
      requestParams['headers'] = new HttpHeaders(h);
    }
    return this.$http.get(this.settings.getAbsoluteUrl(url), requestParams).toPromise();
  }

  delete(url: string, query_params?: any): Promise<any> {
    this.checkUrlValidity(url);

    // query_params = query_params || {};
    // let params = new HttpParams();
    // for (let k in query_params) {
    //   params = params.append(k, query_params[k]);
    // }
    const params = new HttpParams({ fromObject: query_params });
    return this.$http.delete(this.settings.getAbsoluteUrl(url), { params }).toPromise();
  }

  post(url, body?: any, headers?: any, blocking: boolean = true, timeout?: number): Promise<any> {
    this.checkUrlValidity(url);
    let h;
    if (timeout) {
      h = { timeout: `${timeout}` };
      _.extend(h, headers);
    } else {
      h = headers;
    }

    const ret: any = h
      ? this.$http.post(this.settings.getAbsoluteUrl(url), body, { headers: new HttpHeaders(h) })
      : this.$http.post(this.settings.getAbsoluteUrl(url), body);

    if (!blocking) {
      return ret.toPromise();
    }

    this.messageService.broadcast('uiblocker.add', `Block UI for POST request to ${url}`);
    return ret.toPromise().then(
      (response: any) => {
        this.messageService.broadcast('uiblocker.remove', `Got response for post to ${url}`);

        return response;
      },
      (err: any) => {
        console.log('Asking to unblock UI after POST request error', url);
        this.messageService.broadcast('uiblocker.remove', `Got error for post to ${url}`);

        throw err;
      }
    );
  }

  async apiPost(url: string, body: any, headers?: any, blocking: boolean = true, timeout?: number): Promise<any> {
    this.checkUrlValidity(url);

    const ret = await this.post(this.settings.getApiUrl(url), body, headers, blocking, timeout);
    console.log('Ret is ', ret);
    if (ret.status === 'error') {
      throw new ApiCallError(ret.error.code, ret.error.message);
    }

    return ret;
  }

  apiGet(url: string, query_params?: any, timeout?: number): Promise<any> {
    return this.get(this.settings.getApiUrl(url), query_params, undefined, timeout);
  }

  apiGetV2(url: string, query_params?: any, headers?: any, timeout?: number): Promise<any> {
    return this.get(this.settings.getAbsoluteApiUrlv2(url), query_params, headers, timeout);
  }

  apiPostV2(url: string, query_params?: any, headers?: any, blocking: boolean = true, timeout?: number): Promise<any> {
    return this.post(this.settings.getAbsoluteApiUrlv2(url), query_params, headers, blocking, timeout);
  }

  postFormData(url: string, params: any, headers?: any, blocking: boolean = true, timeout?: number): Promise<any> {
    this.checkUrlValidity(url);

    const _params = params || {};
    const _headers = headers || {};
    _headers['Content-Type'] = 'application/x-www-form-urlencoded';
    let data = '';
    for (const k of Object.keys(_params)) {
      if (!_params.hasOwnProperty(k)) {
        continue;
      }

      if (data.length) {
        data += '&';
      }
      data += k + '=' + encodeURIComponent(_params[k]);
    }
    return this.post(url, data, _headers, blocking, timeout);
  }

  postJson(url: string, data: any, headers?: any): Promise<any> {
    const hds: any = headers || {};
    hds['Content-Type'] = 'application/json';
    return this.post(url, data, hds);
  }

  postArrayBufferAsFile(url: string, fileName: string, payload: ArrayBuffer): Promise<any> {
    const fileObj: any = new Blob([payload]);
    fileObj.name = fileName;

    const formData = new FormData();
    formData.append('upload', fileObj);

    return this.post(url, formData, false);
  }

  checkUrlValidity(url: string): void {
    if (!url) {
      throw new Error('Invalid URL');
    }
  }
}
