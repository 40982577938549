import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { EnterpriseFileEntryEntity } from '@wephone-core/model/entity/enterprise_fileentry';
import { CommonAPI } from '@wephone-core/service/common_api';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { HttpEngine } from '@wephone-core/service/http_engine';

export class EnterpriseFileEntryRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseFileEntryEntity;
  protected URL_PREFIX: string = 'enterprise_file_entry';

  private BASE_GET_FILE_URL = 'resource';

  private METHOD_PATH_FILE_DELETE: string = 'delete';
  private METHOD_PATH_FILE_URL: string = 'get';
  private METHOD_PATH_FILE_LIST: string = 'get_list';
  private METHOD_PATH_FILE_UPLOAD: string = 'upload';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'GET_FILE_URL':
        return ['', this.BASE_GET_FILE_URL, this.URL_PREFIX, this.METHOD_PATH_FILE_URL].join('/');
      case 'LIST':
        return this.getUrl(this.METHOD_PATH_FILE_LIST);
      case 'DELETE':
        return this.getUrl(this.METHOD_PATH_FILE_DELETE);
      case 'GET_UPLOAD_URL':
        return this.getUrlV2(this.METHOD_PATH_FILE_UPLOAD);
      default:
        return super.getURLAction(action);
    }
  }

  private enterprise_fileentry_list = {};

  public getFileLogo() {
    return this.getFileList(EnterpriseFileEntryEntity.CATEGORY_LOGO_QUEUE, EnterpriseFileEntryEntity.TYPE_IMAGE);
  }

  public getFilePhotoContact() {
    return this.getFileList(EnterpriseFileEntryEntity.CATEGORY_PHOTO_CONTACT, EnterpriseFileEntryEntity.TYPE_IMAGE);
  }

  public getFileAvatarUser() {
    return this.getFileList(EnterpriseFileEntryEntity.CATEGORY_AVATAR_USER, EnterpriseFileEntryEntity.TYPE_IMAGE);
  }

  public getFileEnterpriseLogo() {
    return this.getFileList(EnterpriseFileEntryEntity.CATEGORY_LOGO_ENTERPRISE, EnterpriseFileEntryEntity.TYPE_IMAGE);
  }

  public invalidateCacheList(category: string, fileType: string) {
    let eid = ConfigManager.getInstance().getCurrentEnterpriseId();
    let key = eid + category + fileType;
    delete this.enterprise_fileentry_list[key];
  }

  public getFileList(category: string, fileType: string) {
    let eid = ConfigManager.getInstance().getCurrentEnterpriseId();
    console.log('getFileList eid', eid);
    let key = eid + category + fileType;

    // If the configuration has already been loaded
    if (typeof this.enterprise_fileentry_list[key] != 'undefined') {
      return Promise.resolve(this.enterprise_fileentry_list[key]);
    }

    let params = {
      category: category,
      fileType: fileType
    };
    let url = this.getURLAction('LIST');
    return CommonAPI.wsGet(url, params).then((ret: any) => {
      if (ret && ret.file_entries) {
        let file_entries = [];
        for (let item of ret.file_entries) {
          let new_obj = this.create();
          new_obj.setObjectData(item);
          file_entries.push(new_obj);
        }
        this.enterprise_fileentry_list[key] = file_entries;
        return file_entries;
      }
      return null;
    });
  }

  public deleteFiles(ids: Array<number>) {
    let eid = ConfigManager.getInstance().getCurrentEnterpriseId();
    let url = this.getURLAction('DELETE');
    let params = {
      ids: ids,
      eid: eid
    };

    return HttpEngine.getInstance().post(url, params);
  }

  public getUploadUrl(): string {
    return this.getURLAction('GET_UPLOAD_URL');
  }
}
