// import * as Raven from 'raven-js';
import * as Sentry from "@sentry/browser";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, isDevMode, ModuleWithProviders } from '@angular/core';
import { MessageService, WephoneUtilsModule, joinURL } from '@wephone-utils';
import { IAuthSuccessInfo } from '@wephone-core/service/authentication.i';
import { FlexIvrSettings, WephoneCoreModule } from '@wephone-core/wephone-core.module';
import { environment }  from '../../../src/environments/environment';
import { NGXLogger, NGXLoggerMonitor, NGXLogInterface, NgxLoggerLevel } from 'ngx-logger';


// const appPrefix = '/app/www/phone/';

// const  configObj = {
//     // the rest of configuration
//     dataCallback: function (data) {
//       console.log('data', data);
//       var stacktrace = data.exception && data.exception.values && data.exception.values[0].stacktrace;

//       if (stacktrace && stacktrace.frames) {
//         stacktrace.frames.forEach(function(frame) {
//           console.log('file name', frame.filename);
//           const i = frame.filename.indexOf(appPrefix);
//           if ( i >= 0 ) {
//             const relativePath = frame.filename.substring(i + appPrefix.length);
//             frame.filename = joinURL("~/", relativePath);
//             console.log('frame.filename apres modification', frame.filename);
//           }
//         });
//       }

//       return data;
//   }
// }

export class RavenErrorHandler extends ErrorHandler {
  handleError(err: any): void {
    if (!isDevMode()) {
      // Raven.captureException(err.originalError || err);
      Sentry.captureException(err.originalError || err);
    }
    super.handleError(err);
  }
}

export class NgxRavenLoggerMonitor implements NGXLoggerMonitor {
  onLog(log: NGXLogInterface): void {
    if (log.level >= NgxLoggerLevel.ERROR) {
      // Raven.captureMessage(log.message, {extra: log.additional});
      Sentry.captureMessage(log.message);
      Sentry.setExtra('extra', log.additional);
    }
  }
}

@NgModule({
  imports: [
    BrowserModule,
    WephoneUtilsModule,
    WephoneCoreModule
  ]
})
export class WephoneSentryModule {
  constructor(
    msgService: MessageService,
    settings: FlexIvrSettings,
    ngxLogger: NGXLogger
  ) {
    
    if (environment.sentryUrl) {
      Sentry.init({
        dsn: environment.sentryUrl,
        release: `${FlexIvrSettings.getInstance().app_type}-${environment.VERSION}`,
        ignoreErrors : [
          'cordova_not_available',
          'Interceptor: HTTP request with invalid credentials'
        ]
      });

      Sentry.setTags({
        appType: settings.app_type
      });
    }

    msgService.subscribe('auth:login:success',
      (authInfo: IAuthSuccessInfo) => {
        // Raven.setUserContext(
        //   {
        //     username: authInfo.username,
        //     domain: authInfo.domain
        //   }
        // );
        Sentry.setUser({
          username: authInfo.username,
          domain: authInfo.domain
        });
      }
    );

    msgService.subscribe('sentry:log',
      (logMsg: any) => {
        Sentry.withScope((scope) => {
          const extra = logMsg.extra;
          const level = logMsg.level;
          if (extra) {
            Object.entries(extra).forEach(([key, value]) => {
              scope.setExtra(key, value);
            });
          }
          if (level === 'error') {
            Sentry.captureException(logMsg.exception);
          } 
          else {
            Sentry.captureMessage(logMsg.message);
          }
        })
      }
    );

    ngxLogger.registerMonitor(new NgxRavenLoggerMonitor());
  }

  static forRoot(): ModuleWithProviders<WephoneSentryModule> {
    return {
      ngModule: WephoneSentryModule,
      providers: [
        { provide: ErrorHandler, useClass: RavenErrorHandler }
      ]
    };
  }
}
