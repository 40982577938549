import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { _ti } from '@wephone-translation';
import { CalendarSelectListDialogComponent } from '../calendar';
import { DialogService } from '@wephone-utils';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import * as _ from 'lodash';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AgentAvailability, AgentEntity } from '@wephone-core/model/entity/agent';
import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';

@Component({
  selector: 'app-opening-hour-calendar-select',
  templateUrl: './opening-hour-calendar-select.component.html',
  styleUrls: ['./opening-hour-calendar-select.component.scss']
})
export class OpeningHourCalendarSelectComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() forUser: boolean;
  @Input() agent: AgentEntity;
  @Output() readonly valueChange: EventEmitter<any> = new EventEmitter();

  calendar: OpeningHourCalendarEntity;

  queueRepo: CallQueueRepository;
  hasUpdateCalendar = false;
  usedQueueList: CallQueueEntity[];
  form: FormGroup;
  invalidMessage: string;

  private hasInitialized = false;

  constructor(
    private em: EntityManager,
    private dialogService: DialogService,
    private fb: FormBuilder,
    cdr: ChangeDetectorRef) {
    super(cdr);
    this.queueRepo = em.getRepository<CallQueueRepository>('CallQueueRepository');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.initFormGroup();
    this.hasInitialized = true;
  }

  ngOnChanges(): void {
    if (!this.hasInitialized) {
      return;
    }
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.calendar = this.agent.working_calendar as OpeningHourCalendarEntity;

    this.form = this.fb.group({
      calendar: [this.calendar],
      availability: [this.agent.availability]
    });

    this.form.get('availability').valueChanges.subscribe(changes => {
      this.changeAvailability(changes);
    });

  }

  updateOpeningHours($event: any): void {
    // console.log('updateOpeningHours', $event);
    this.onValueChanged({ updateOpeningHour: true });
  }

  private async getObjectsUsingCalendar(): Promise<void> {
    const calendar: OpeningHourCalendarEntity = this.form.get('calendar').value;
    if (calendar && calendar.id) {
      const data: any[] = await this.em.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository')
        .getObjectsUsingCalendars([calendar.id]);

      this.usedQueueList = [];

      for (const item of data) {
        const entityName: string = item[0];
        const entityId: number = +item[1];

        const repo = this.em.getRepositoryById(entityName);
        if (!repo) {
          console.error('No repo with name' + entityName);
          continue;
        }

        if (repo instanceof CallQueueRepository) {
          const q: CallQueueEntity = repo.getObjectById(entityId);
          this.usedQueueList.push(q);
        }
      }
    }
  }

  openCalendarListDialog(): void {
    // this.checkOpeningHourChanged = false;
    this.dialogService.openSideDialog(
      CalendarSelectListDialogComponent,
      {
        data: {
          calendar: this.calendar,
          displayMode: 'compact',
          hasEmpty: false
        },
        size: 's'
      },
      (calendar: OpeningHourCalendarEntity) => {
        if (!calendar) {
          console.log('No calendar selected, do nothing');
          return;
        }
        // console.log('selected calendar', calendar);
        this.form.get('calendar').setValue(calendar);
        this.getObjectsUsingCalendar();
        this.onValueChanged({ availability: this.form.get('availability').value, calendar });
      }
    );
  }

  onValueChanged(changedValues: any): void {
    this.isValid();
    this.valueChange.emit(changedValues);
  }

  changeAvailability(availability: number): void {
    this.onValueChanged({ availability });
  }

  isValid(): boolean {
    this.form.get('calendar').setErrors(null);
    if (this.form.get('availability').value === AgentAvailability.OnCalendar &&
      !this.form.get('calendar').value
    ) {
      this.form.get('calendar').setErrors({
        required: true
      });
      this.form.get('calendar').markAllAsTouched();
    }

    return this.form.valid;
  }

  async updateCalendar(): Promise<any> {
    try {
      if (!this.isValid()) {
        throw new Error(_ti('public.message.data_invalid'));
      }
      const calendar = this.form.get('calendar').value;
      const extra_params = {
        openinghours: (calendar.openinghours || []).map((i: OpeningHourEntity) => i.dumpObjectData()),
        openinghours_special_date: (calendar.openinghours_special_date || []).map((i: OpeningHourSpecialDateEntity) => i.dumpObjectData()),
      };
      console.log('Updating calendar', extra_params);
      for (let i = 0; i < extra_params.openinghours.length; i++) {
        if (extra_params.openinghours[i].day_from === extra_params.openinghours[i].day_to) {
          if (extra_params.openinghours[i].start_time === extra_params.openinghours[i].end_time) {
            throw new Error(_ti('opening_hour_calendar.message.dulicate_time'));
          }
        }
      }

      const ret = await this.em
        .getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository')
        .save(calendar, extra_params);
      this.hasUpdateCalendar = false;
      this.successToast(_ti('public.message.update_success'));
      await this.em.reloadRepositories(['opening_hour_calendar', 'opening_hour', 'opening_hour_special_date']);
      return ret;
    } catch (e) {
      console.error('Update calendar error: ', e);
      if (e.error) {
        this.showError(_ti('public.message.update_failure') + ', ' + e.error.message);
      }

      if (e.message) {
        this.showError(e.message);
      }
      Promise.reject(e);
    }
  }
}
