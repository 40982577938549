import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { SyncTaskEntity } from '../entity/sync_task';

export class SyncTaskRepository extends BaseRepository {
  ENTITY_CLASS = SyncTaskEntity;
  protected readonly URL_PREFIX: string = 'sync_task';

  protected getUrl(suffix): string {
    return super.getUrlV2(suffix);
  }
}
