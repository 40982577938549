<mat-tab-group mat-stretch-tabs [headerPosition]="footer? 'below' : 'above'" [selectedIndex]="selectedTabIndex"
  (selectedIndexChange)="goToSubRoute($event)" [backgroundColor]="bgColor" [color]="color">
  <mat-tab *ngFor="let r of childRoutes" label="{{ r.label | translate }}">
    <ng-template mat-tab-label>
      <mat-icon *ngIf="r.icon && (!r.badge || !badge(r.badge))" [attr.data-ci]="r.name" class="example-tab-icon"
        matTooltip="{{r.label|translate}}">
        {{ r.icon }}
      </mat-icon>
      <mat-icon *ngIf="r.icon && r.badge && badge(r.badge)" [attr.data-ci]="r.name" class="example-tab-icon"
        [matBadge]="badge(r.badge)" matBadgeColor="warn">{{ r.icon }}</mat-icon>
      <span *ngIf="!r.icon" [attr.data-ci]="r.name">{{ r.label | translate }}</span>
    </ng-template>
  </mat-tab>
</mat-tab-group>
