import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class CustomReportConfigRepository extends BaseRepository {
  ENTITY_CLASS = CustomReportConfigEntity;
  protected URL_PREFIX = 'custom_report_config';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  findBySourceName(source_name: string, use_cache: boolean = true): Promise<any> {
    if (!source_name) {
      return;
    }

    return this.findAllItems(use_cache).then(object_list => {
      const obj_list = this.getObjectList();
      if (obj_list) {
        return obj_list.find(x => x.source_name === source_name);
      }
    });
  }
}

import { CustomReportConfigEntity } from '@wephone-core/model/entity/custom_report_config'; import { HttpEngine } from '@wephone-core/service/http_engine';
