<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div fxLayout="column" formArrayName="ranges_number">
    <ng-container *ngFor="let rangeNumberGroup of rangesNumberControl.controls; let i=index;">
      <div [formGroupName]="i" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="full-width">
        <div fxFlex="calc(50%-50px)">
          <mat-form-field class="full-width">
            <mat-label>{{'hotelroom.content.from_number'| translate}}</mat-label>
            <input matInput type="number" placeholder="{{ 'hotelroom.content.from_number'|translate }}"
              formControlName="from" (blur)="updateValidation($event, rangeNumberGroup.get('to'))">
            <mat-error *ngIf="rangeNumberGroup.get('from').hasError('any.required')">
              {{'form.validator.field_required'|translate}}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('from').hasError('number.base')">
              {{'form.validator.data_invalid'|translate}}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('from').hasError('number.min')">
              {{'form.validator.min_value'|translate:{max: 1} }}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('from').hasError('number.max')">
              {{'form.validator.max_value'|translate:{max: 999999999} }}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('from').hasError('exists')">
              {{rangeNumberGroup.get('from').errors.message}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(50%-50px)">
          <mat-form-field class="full-width">
            <mat-label>{{'hotelroom.content.to_number'| translate}}</mat-label>
            <input matInput type="number" placeholder="{{ 'hotelroom.content.to_number'|translate }}"
              formControlName="to"
              (blur)="updateValidation(rangeNumberGroup.get('from').value, rangeNumberGroup.get('to'))">
            <mat-error *ngIf="rangeNumberGroup.get('to').hasError('any.required')">
              {{'form.validator.field_required'|translate}}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('to').hasError('number.base')">
              {{'form.validator.data_invalid'|translate}}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('to').hasError('number.min')">
              {{'form.validator.min_value'|translate:{min: rangeNumberGroup.get('from').value || 1} }}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('to').hasError('number.max')">
              {{'form.validator.max_value'|translate:{max: 999999999} }}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('to').hasError('exists')">
              {{rangeNumberGroup.get('to').errors.message}}
            </mat-error>
            <mat-error *ngIf="rangeNumberGroup.get('to').hasError('lesser')">
              {{'form.validator.data_invalid'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="40px">
          <button type="button" mat-icon-button (click)="removeRangeNumber(i)" title="{{ 'public.remove'|translate}}"
            [disabled]="rangesNumberControl.controls.length === 1"
            [ngClass]="{'mat-text-warn': rangesNumberControl.controls.length > 1}" data-ci="remove_range_number">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <div *ngIf="form.get('ranges_number').hasError('max_length') && form.get('ranges_number').touched" class="mat-text-warn">
      {{ 'form.validator.max_value'|translate:{max:100} }}
    </div>

    <div class="my-1">
      <button mat-stroked-button color="green" (click)="addRangeNumber()" type="button" data-ci="add_range_number">
        <mat-icon class="icon-add">add</mat-icon>
        <span>{{ 'hotelroom.content.add_range_number'|translate }}</span>
      </button>
    </div>
  </div>
  <div>
    <mat-slide-toggle formControlName="has_sipphone" color="primary">
      {{'hotelroom.content.also_create_sipphone'|translate}}
      <span [hidden]="!form.get('has_sipphone').value" class="mat-text-primary">
        {{ 'sipphone.content.enabled'|translate }}</span>
      <span [hidden]="form.get('has_sipphone').value" class="mat-text-muted">
        {{ 'sipphone.content.disabled'|translate }}</span>
    </mat-slide-toggle>
  </div>
</form>
