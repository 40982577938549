import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { DialogService } from '@wephone-utils';
import { AuthenticationService } from '@wephone-core/service/authentication';
// import { TranslateService } from '@ngx-translate/core';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import * as _ from 'lodash';
import { AgentChangeQueueListDialog } from '@wephone/modals/agent/agentChangeQueueList/agentChangeQueueList';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatDialogRef } from '@angular/material/dialog';
import { FlexBaseComponent } from '@wephone-core-ui';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'agent-queues',
  templateUrl: 'agent-queues.html',
  styleUrls: ['agent-queues.scss']
})
export class AgentQueuesComponent extends FlexBaseComponent {
  @Input() agent: AgentEntity;
  @Input() add_queue: boolean;
  @Output() readonly on_change: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly dialogService: DialogService,
    private readonly authService: AuthenticationService,
    // private readonly translateService: TranslateService,
  ) {
    super();
  }

  get sortedInboundQueueList(): CallQueueEntity[] {
    if (this.agent && this.agent.inbound_queue_list) {
      return _.sortBy(this.agent.inbound_queue_list, [o => o.queue_name]);
    }
  }

  agent_add_queues(): MatDialogRef<any, any> {
    const agent_queues = EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').getQueuesByAgentId(this.agent.getId());
    const dialog_params = {
      agent: this.agent,
      queues: agent_queues
    };

    return this.dialogService.openSideDialog(AgentChangeQueueListDialog,
      {
        data: dialog_params
      },
      data => {
        this.on_changed(data, false);
      }
    );
  }

  isAdminOrSupervisor(): boolean {
    return this.authService.isAdmin() || this.authService.isSupervisor();
  }

  agent_is_main(queue: CallQueueEntity): boolean {
    return queue.agentIsMain(this.agent);
  }

  removeQueue(queue: CallQueueEntity): any {
    if (!this.isAdminOrSupervisor()) {
      console.error('Nor admin or supervisor');
      return;
    }

    this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      this.getConfirmMessageRemoveAgentInQueue(queue),
      async () => {
        try {
          await CallQueueRepository.getInstance<CallQueueRepository>()
            .removeAgent(this.agent.getId(), queue.getId());

          this.successToast(_ti('public.message.delete_success'));
          this.on_changed(queue, true);
        } catch (error) {
          console.error('Remove Agent from queue Error: ', error);
          this.showErrorMessage(error, _ti('public.message.delete_failure'));
        }
      }
    );
  }

  getConfirmMessageRemoveAgentInQueue(queue: CallQueueEntity): string | any {
    return _ti('livecall.agent_list.confirm_queue_remove_agent', { agent_name: this.agent.name, queue_name: queue.queue_name });
  }

  on_changed(data: any, remove: boolean = false): any {
    if (this.on_change) {
      this.on_change.emit({ data, remove });
    }
  }
}
