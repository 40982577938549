import { Entity, datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { DidCountryEntity } from './did_country';

export class DidCountryStateEntity extends Entity {
  static object_type_id: string = 'did_country_state';

  id: number;
  @datafield
  state_id: number;
  @datafield
  name: string;
  @datafield
  state_code: string;
  @datafield
  country_id: number;

  country: DidCountryEntity;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    this.country = EntityManager.getRepository('DidCountryRepository').getObjectById(this.country_id);
  }
}
