import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class GroupRepository extends BaseRepository implements IGroupRepository {
  ENTITY_CLASS = GroupEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'object_group';
  private _user_group_list: GroupEntity[] = [];
  private _user_group_tree: GroupEntity[] = [];
  private _skill_group_list: GroupEntity[] = [];
  private _skill_group_tree: GroupEntity[] = [];
  private _team_group_list: GroupEntity[] = [];
  private _team_group_tree: GroupEntity[] = [];

  createUserGroup(): GroupEntity {
    const ret: GroupEntity = new UserGroupEntity();
    return ret;
  }

  createTeamGroup(): IGroupEntity {
    const ret: GroupEntity = new TeamGroupEntity();
    return ret;
  }

  createSkillGroup(): GroupEntity {
    const ret: GroupEntity = new SkillGroupEntity();
    return ret;
  }

  create(object_data: any = null): IEntity {
    let ret: IGroupEntity;
    if (!object_data) {
      throw new Error('Don\'t know how to create object group');
    }
    if (object_data.group_type === 'service') {
      ret = this.createUserGroup();
    } else if (object_data.group_type === 'team') {
      ret = this.createTeamGroup();
    } else if (object_data.group_type === 'skill') {
      ret = this.createSkillGroup();
    } else {
      throw new Error('Don\'t know how to create object group ');
    }
    ret.setObjectData(object_data, false);
    return ret;
  }

  getObjectUsingName(name: string, exludedId?: number): GroupEntity {
    return this.getObjectList().find(p => {
      return p.name === name && (!exludedId || exludedId && p.id !== exludedId);
    });
  }

  getObjectListByIds(ids: number[]): GroupEntity[] {
    if (!ids) {
      console.error('No ids given');
      return [];
    }
    const ret: GroupEntity[] = [];
    const groups: GroupEntity[] = this.getObjectList();
    for (const group of groups) {
      if (ids.indexOf(group.getId()) !== -1) {
        ret.push(group);
      }
    }
    return ret;
  }

  getAllChildrenGroupById(group_id): GroupEntity[] {
    const children = [];
    for (const g of this.getObjectList()) {
      if (g.path && g.path.indexOf(group_id) !== -1) {
        children.push(g);
      }
    }

    return children;
  }

  getParentGroups(group_id): GroupEntity[] {
    const groups = new Array<GroupEntity>();
    const group = this.getObjectById(group_id);
    if (!group) {
      return groups;
    }

    for (const gid of group.path) {
      const g = this.getObjectById(gid);
      if (g) {
        groups.push(g);
      }
    }
    return groups;
  }

  getChildrenGroupById(group_id): GroupEntity[] {
    const children = [];
    const object_list = this.object_list;
    for (let i = 0; i < object_list.length; i++) {
      const g = object_list[i];
      if (g.parent_id === group_id) {
        children.push(g);
      }
    }
    return children;
  }

  setDataAsReady(): void {
    if (this._data_loaded) {
      return;
    }
    this._data_loaded = true;
    EntityManager.getRepository('SkillGroupRepository').setDataAsReady();
    EntityManager.getRepository('UserGroupRepository').setDataAsReady();
    EntityManager.getRepository('TeamGroupRepository').setDataAsReady();
  }

  onRemoteDataUpdated(): void {
    this._user_group_list.length = 0;
    this._skill_group_list.length = 0;
    this._team_group_list.length = 0;
    for (const g of this.object_list) {
      if (g.group_type === 'service') {
        this._user_group_list.push(g);
      } else if (g.group_type === 'skill') {
        this._skill_group_list.push(g);
      } else if (g.group_type === 'team') {
        this._team_group_list.push(g);
      }
    }
    this._build_structured_group_list();
  }

  getServiceGroupList(): GroupEntity[] {
    return this._user_group_list;
  }

  getUserGroupTree(): GroupEntity[] {
    return this._user_group_tree;
  }

  getTeamGroupList(): GroupEntity[] {
    return this._team_group_list;
  }

  getTeamGroupTree(): GroupEntity[] {
    return this._team_group_tree;
  }

  getSkillGroupTree(): GroupEntity[] {
    return this._skill_group_tree;
  }

  getSkillGroupList(): GroupEntity[] {
    return this._skill_group_list;
  }

  getSubGroupIdsById(id: number, include_self: boolean = true): number[] {
    const group = this.getObjectById(id);
    if (!group) {
      throw new Error('Group with id ' + id + ' not found');
    }
    const ret = [];
    if (include_self) {
      ret.push(group.id);
    }

    const subGroupIds: number[] = this.object_list.filter(g => _.includes(group.path, g.id)).map(g => g.id);
    // for (const g of this.object_list) {
    //   if (g.id in group.path) {
    //     ret.push(g.id);
    //   }
    // }
    return ret.concat(subGroupIds);
  }

  private _build_structured_group_list(): void {
    this._user_group_tree = [];
    this._skill_group_tree = [];
    this._team_group_tree = [];
    for (const g of this.object_list) {
      g.setChildren(this.getChildrenGroupById(g.getId()));
      if (!g.parent_id) {
        if (g.group_type === 'service') {
          this._user_group_tree.push(g);
        } else if (g.group_type === 'skill') {
          this._skill_group_tree.push(g);
        } else if (g.group_type === 'team') {
          this._team_group_tree.push(g);
        }
      }
    }
  }

  protected sortByPathName(group_1, group_2): number {
    if (group_1.path_name === group_2.path_name) {
      if (group_1.name === group_2.name) {
        return 0;
      }
      return group_1.name > group_2.name ? 1 : -1;
    }
    return group_1.path_name > group_2.path_name ? 1 : -1;
  }

  getAllChildrenGroup(group: GroupEntity): GroupEntity[] {
    const id: number = group.id;
    const children: GroupEntity[] = this.getObjectList().filter(x => x.parent_id === id);
    let ret: GroupEntity[] = children;

    for (const child of children) {
      const grandChildren: GroupEntity[] = this.getAllChildrenGroup(child);
      if (!_.isEmpty(grandChildren)) {
        ret = ret.concat(grandChildren);
      }
    }

    return ret;
  }

  bulkDelete(items: IEntity[], manual_remove = true): Promise<any> {
    // Check delete
    const deletedIds: number[] = items.map(x => (x as GroupEntity).id);
    let children: GroupEntity[] = [];
    for (const item of items) {
      children = children.concat(this.getAllChildrenGroup(item as GroupEntity));
    }

    const childrenIds: number[] = children.map(x => x.id);
    const childrenIdsNotDeleted: number[] = childrenIds.filter(x => !_.includes(deletedIds, x));

    if (!_.isEmpty(childrenIdsNotDeleted)) {
      const undeletedChildren = this.getObjectList().filter(x => _.includes(childrenIdsNotDeleted, x.id));
      const childrenNames: string[] = undeletedChildren.map(x => x.name);
      throw new Error(_ti('group.message.delete_parent_having_children', { children: childrenNames.join(', ') }));
    }

    return super.bulkDelete(items, manual_remove);
  }
}

import { EntityManager } from '@wephone-core/service/entity_manager';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { SkillGroupEntity } from '@wephone-core/model/entity/skillgroup';
import { IEntity } from '@wephone-core/model/model.interface';
import { IGroupEntity } from '@wephone-core/model/entity/group.interface';
import { IGroupRepository } from '@wephone-core/model/repository/group.i';
import * as _ from 'lodash';
import { _ti } from '@wephone-translation';
