import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexMatInputBase, IFlexSelectOptions, PhoneNumberService, regexSearch } from '@wephone-utils';
import codes from 'country-calling-code';
import { takeUntil } from 'rxjs/operators';

interface ICountryCallingCode {
  country: string;
  countryCodes: string[];
  isoCode2: string;
  isoCode3: string;
}

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: PhoneNumberInputComponent }
  ]
})
export class PhoneNumberInputComponent extends FlexMatInputBase
implements OnInit, OnDestroy, MatFormFieldControl<any> {
  static nextId = 0;
  @Output() readonly valueChange: EventEmitter<string> = new EventEmitter();

  myForm: FormGroup;
  codes: ICountryCallingCode[] = [];
  flexSelectOptions: IFlexSelectOptions;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    private fb: FormBuilder,
    elRef: ElementRef
  ) {
    super(ngControl, elRef);

    this.flexSelectOptions = {
      filterFunc: (item: ICountryCallingCode, filterString) => {
        return regexSearch(item.country, filterString) || regexSearch(item.isoCode2, filterString) || regexSearch(item.isoCode3, filterString);
      },
      compareWith: (a: ICountryCallingCode, b: ICountryCallingCode) => {
        return a && b && a.isoCode2 === b.isoCode2 || !b && !a;
      }
    };

    this.codes = codes;

    this.myForm = this.fb.group({
      telephone_code: [],
      telephone_number: []
    });

    this.addSubscription(
      this.myForm.valueChanges.subscribe(() => {
        this.ngControl.control.markAllAsTouched();
        this.ngControl.control.markAsDirty();
        this.ngControl.control.markAsTouched();
      })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    const telePhoneCode = this.myForm.get('telephone_code').value;
    if (!telePhoneCode) {

      const phoneNumberService: PhoneNumberService = PhoneNumberService.getInstance();
      const defaultTelephoneCode = phoneNumberService.getTeleComCountryPrefix();
      // console.log('defaultTelephoneCode', defaultTelephoneCode && this.codes.find(code => code.countryCodes.includes(defaultTelephoneCode.toUpperCase())));
      this.myForm.get('telephone_code').setValue(
        defaultTelephoneCode && this.codes.find(code => code.countryCodes.includes(defaultTelephoneCode.toUpperCase())) || undefined
      );
    }

    this.myForm.valueChanges.pipe(
      takeUntil(this._onDestroy)
    )
    .subscribe((formValue: any) => {
      const telephoneNumber = formValue.telephone_number && formValue.telephone_number.trim();
      if (telephoneNumber) {
        const telephoneCode = formValue.telephone_code && formValue.telephone_code.countryCodes
         && formValue.telephone_code.countryCodes[0] && formValue.telephone_code.countryCodes[0].replace(/\D/g, '') || '';
  
        this.value = `${telephoneCode}${telephoneNumber}`.trim();
      } else {
        this.value = '';
      }
    });
  }

  get empty(): boolean {
    return !this.value;
  }

  writeValue(val: string): void {
    if (!val) {
      return super.writeValue(val);
    }

    const value = val.replace(/\D/g, '');

    let prefix = '';
    let prefixCode: ICountryCallingCode;
    for (const code of this.codes) {
      const phoneCode = code.countryCodes && code.countryCodes[0] && code.countryCodes[0].replace(/\D/g, '') || '';
      if (value.startsWith(phoneCode)) {
        prefix = phoneCode;
        prefixCode = code;
        break;
      }
    }

    let suffix = value;
    if (prefix) {
      suffix = value.replace(prefix, '');
    }

    this.myForm.get('telephone_code').setValue(prefixCode);
    this.myForm.get('telephone_number').setValue(suffix);

    super.writeValue(suffix ? value : '');
  }

  get controlType(): string {
    return 'phone-number-form-input';
  }

  onClickInput($event): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  setErrorState(state: boolean): void {
    if (state) {
      this.focused = true;
      this.stateChanges.next();
    }

    return super.setErrorState(state);
  }

}
