<div *ngIf="queue" class="queue-avatar" [ngStyle]="{width: width, height: height}">
  <div class="status-bg">
      <div>
          <a *ngIf="uploadAvatar;else imageAvatar" 
              [title]="'fileentry.action.upload_image'|translate" 
              [matMenuTriggerFor]="menu">
              <img mat-list-avatar 
                  *ngIf="queue_avatar_url"
                  [src]="queue_avatar_url" 
                  [ngStyle]="{width: width, height: height}"/>
          </a>
          <ng-template #imageAvatar>
              <img mat-list-avatar 
                  *ngIf="queue_avatar_url"
                  [src]="queue_avatar_url" 
                  [ngStyle]="{width: width, height: height}" />
          </ng-template>
          <input #fileInput type="file" [accept]="fileSettings.extensions|extensionAccept" style="display:none" (change)="onAvatarSelected($event)">
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="fileInput.click()">
                <mat-icon>cloud_upload</mat-icon>
                {{ 'fileentry.action.upload_image' |translate }}
            </button>
            <button mat-menu-item (click)="removeEntry()" *ngIf="queue.logo_id">
                <mat-icon>delete</mat-icon>
                {{ 'fileentry.action.remove_image' | translate }}
            </button>
           </mat-menu>
      </div>
  </div> 
  
  <!-- <div class="in-out-indicator" *ngIf="queue.is_online_cc && (queue.agent.inout_state != 0) && (queue.agent.is_postcall_paused || !queue.agent.is_paused)" fxLayout fxLayoutAlign="center center">
      <span *ngIf="queue.agent.inout_state==1"><mat-icon>flight_land</mat-icon> IN</span>
      <span *ngIf="queue.agent.inout_state==2"><mat-icon>flight_takeoff</mat-icon> OUT</span>
  </div> -->
</div>
