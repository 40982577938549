import { Injectable } from '@angular/core';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { _ti } from '@wephone-translation';
import { Deferred } from 'ts-deferred';
import * as _ from 'lodash';
import { DialogService } from '@wephone-utils';
import { SmsTemplateEntity } from '@wephone-core/model/entity/sms_template';

@Injectable()
export class SmsTemplateService {
  constructor(
    private readonly dialogService: DialogService,
  ) { }

  async getConfirmDelete(items: SmsTemplateEntity[]): Promise<boolean> {
    let msg: string = _ti('user.title.delete');
    const msgs: string[] = [];
    const routedIVRList: IvrCustomMenuEntity[] = [];

    for (const item of items) {
      for (const routedIvrCustomMenu of item.routedIvrCustomMenuList) {
        if (!_.includes(routedIVRList, routedIvrCustomMenu)) {
          routedIVRList.push(routedIvrCustomMenu);
        }
      }
    }

    if (routedIVRList.length) {
      let smsTemplateObjects: SmsTemplateEntity[] = items.filter(o => o.routedIvrCustomMenuList.length);
      smsTemplateObjects = _.uniqBy(smsTemplateObjects, o => o.id);

      msgs.push(_ti('sms_template.message.confirm_delete_with_dependent_items', {
        sms_template_names: smsTemplateObjects.map(x => x.name).join(', '),
        objects: routedIVRList.map(x => x.name).join(', '),
      }));
    }

    const ret = new Deferred<boolean>();
    const canDelete = !msgs.length;

    if (!canDelete) {
      msgs.push(_ti('public.message.cannot_continue_action_with_dependent_items'));
      msg = msgs.join('\n');

      await this.dialogService.showAlert(_ti('dialogs.warning'), msg);
      ret.resolve(canDelete);
      return ret.promise;
    }

    await this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      msg,
      () => {
        ret.resolve(canDelete);
      }
    );

    return ret.promise;
  }
}
