import { Inject, Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntl } from './components/flex-table/pagination-intl';

import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { _ti } from '../wephone-translation/static-methods';

@Injectable()
export class CustomOwlDateTimeIntl extends OwlDateTimeIntl {
  /** A label for the up second button (used by screen readers).  */
  upSecondLabel = _ti('date_time_picker.up_second_label');

  /** A label for the down second button (used by screen readers).  */
  downSecondLabel = _ti('date_time_picker.down_second_label');

  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel = _ti('date_time_picker.up_minute_label');

  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel = _ti('date_time_picker.down_minute_label');

  /** A label for the up hour button (used by screen readers).  */
  upHourLabel = _ti('date_time_picker.up_hour_label');

  /** A label for the down hour button (used by screen readers).  */
  downHourLabel = _ti('date_time_picker.down_hour_label');

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = _ti('date_time_picker.prev_month_label');

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = _ti('date_time_picker.next_month_label');

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = _ti('date_time_picker.prev_year_label');

  /** A label for the next year button (used by screen readers). */
  nextYearLabel = _ti('date_time_picker.next_year_label');

  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel = _ti('date_time_picker.prev_multi_year_label');

  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel = _ti('date_time_picker.next_multi_year_label');

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = _ti('date_time_picker.switch_to_month_view_label');

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel = _ti('date_time_picker.switch_to_multi_year_view_label');

  /** A label for the cancel button */
  cancelBtnLabel = _ti('public.cancel');

  /** A label for the set button */
  setBtnLabel = _ti('date_time_picker.set');

  /** A label for the range 'from' in picker info */
  rangeFromLabel = _ti('public.from');

  /** A label for the range 'to' in picker info */
  rangeToLabel = _ti('public.to');

  /** A label for the hour12 button (AM) */
  hour12AMLabel = _ti('date_time_picker.am');

  /** A label for the hour12 button (PM) */
  hour12PMLabel = _ti('date_time_picker.fm');
};

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSidenavModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatBadgeModule,
    MatChipsModule,
    MatTableModule,
    MatTreeModule,
    MatTooltipModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    ScrollingModule,
    DragDropModule,
    MatBottomSheetModule,
    MatPaginatorModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,
    
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
  ],
  declarations: [],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSidenavModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatBadgeModule,
    MatChipsModule,
    MatTableModule,
    MatTreeModule,
    MatTooltipModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    ScrollingModule,
    // Extensions
    NgxMatSelectSearchModule,
    EcoFabSpeedDialModule,
    DragDropModule,
    MatBottomSheetModule,
    MatPaginatorModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,
    
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorIntl(translateService).getPaginatorIntl()
    },
    {
      provide: OwlDateTimeIntl,
      useClass: CustomOwlDateTimeIntl
    },
  ]
})
export class MaterialModule {}
