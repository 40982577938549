<form [formGroup]="form" fxFill>
    <ng-container *ngIf="step == 'select_item'">
        <div fxLayout="column" fxLayoutAlign="start left" fxFill>
            <h2>{{ 'image.content.select_item'|translate }}</h2>
            <div class="mb-1" fxLayout="row" fxLayoutAlign="end center">
                <div fxFlex="50" fxFlex.xs="70">
                    <flex-search-input [formControl]="searchCtrl"></flex-search-input>
                </div>
            </div>

            <div class="existing-image-list" fxFlex="1 0 1px" perfectScrollbar>
                <div fxLayout="row" fxLayoutAlign="start center" class="existing-image-row" *ngFor="let file of newImageList; let i = index"
                    (mouseenter)="showBtnSelects[i]=true" (mouseleave)="showBtnSelects[i]=false">
                    <div class="existing-image-player" fxFlex="100px">
                        <app-image-viewer *ngIf="file.public_id" height="33px" [filePublicId]="file.public_id">
                        </app-image-viewer>
                    </div>

                    <span fxFlex="grow" class="existing-image-name" [matTooltip]="file.name">
                        {{file.name}}
                    </span>

                    <div fxFlexLayout="end center">
                        <button data-ci="button-select-image" *ngIf="showBtnSelects[i]" mat-button color="accent"
                            (click)="chooseExisting(file)">
                            {{ 'image.content.select_this_item'|translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="mt-1">
                <button data-ci="add-image" mat-stroked-button color="accent" (click)="addSound()" class="button-add">
                    <mat-icon class="icon-add">add</mat-icon>
                    <span class="vertical-align">{{ 'image.content.add'|translate }}</span>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step == 'add_item'">
        <div fxLayout="column" fxLayoutAlign="start left">
            <div [hidden]="showInputName == false">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'image.content.name' | translate }}</mat-label>
                    <input matInput data-ci="image_name" type="text"
                        [placeholder]="'image.content.name' | translate" formControlName="name">

                    <mat-error [flexFormControlError]="form.get('name')"
                        [customMessages]="{
                            'duplicated': 'public.message.name_exist'|translate
                        }"></mat-error>
                </mat-form-field>
            </div>
            <h2> {{ 'fileentry.action.upload_file' | translate }} </h2>
            <div fxLayout="row">
                <ng-container *ngIf="localFile;else UploadFileLocal">
                    <mat-card class="full-width nopadding">
                        <div mat-card-widget fxLayout="row" class="pa-1">
                            <div fxFlex="80px">
                                <img class="full-width" [src]="localFileUrl" *ngIf="localFileUrl" />
                            </div>
                            <div fxFlex class="pl-1 pr-1">
                                <span>
                                    {{localFile.name}}
                                    <mat-icon color="primary" class="verified-icon">check</mat-icon>
                                </span>
                            </div>
                            <button fxFlex="40px" mat-icon-button class="mat-text-muted" (click)="deleteSound()">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-card>

                </ng-container>

                <ng-template #UploadFileLocal>
                    <app-file-upload class="full-width" (onFileChange)="fileLoadedForBrowser($event)" icon="perm_media"
                        [allowedExtensions]="allowedUploadExtensions"
                        [title]="'fileentry.action.upload_file_hint'|translate">
                    </app-file-upload>
                </ng-template>
            </div>

        </div>
    </ng-container>
</form>
