<div fxFlexFill fxLayout="column" *ngIf="isReady();else LoadingSpinner">
  <ng-container *ngIf="isSelectQualication; else QualificationCommentBlock">
    <div fxFlex="initial" fxLayout="row" fxLayoutAlign="end center" class="pa-1">
      <flex-search-input placeholder="{{ 'public.search' | translate }}" [formControl]="searchCtrl"
        class="search-input">
      </flex-search-input>
    </div>
    <div fxFlex="grow" fxLayout="column" class="pa-1 overview-scroll">
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let q of childrenQual" (click)="selectQualification(q.id)">
          <div matLine class="qualification" [ngClass]="{'selected': isSelectedQualification(q.id) ? true: false}">
            {{ q.value }}</div>
          <mat-divider></mat-divider>
          <mat-icon mat-icon-button>arrow_forward_ios</mat-icon>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </ng-container>

  <ng-template #QualificationCommentBlock>
    <form [formGroup]="form">
      <h3 class="mx-1">{{ 'qualification.dialog.enter_comment' | translate }}</h3>
      <div class="px-1">
        <mat-form-field class="full-width">
          <textarea matInput #comment_input formControlName="comment"
            placeholder="{{ 'call_qualification.content.comment_placeholder' | translate }}"></textarea>
        </mat-form-field>
      </div>
    </form>
  </ng-template>
</div>

<ng-template #LoadingSpinner>
  <if-loading></if-loading>
</ng-template>
