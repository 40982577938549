<form [formGroup]="form" *ngIf="is_ready" class="full-height">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                {{ 'sipphone.custom_config.networking'|translate }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="main-content" fxLayout="column" fxLayoutGap="10px">
                <div>
                    <mat-slide-toggle formControlName="wifi_enable">
                        <span class="fontfamily-bold">{{ 'sipphone.custom_config.wifi_enable'|translate }}</span>
                    </mat-slide-toggle>
                </div>

                <ng-container *ngIf="form.get('wifi_enable').value">
                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.wifi_ssid'|translate }}</mat-label>
                            <input data-ci="wifi_ssid" matInput type="text" formControlName="wifi_ssid">
                            
                            <mat-error [flexFormControlError]="form.get('wifi_ssid')"></mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.wifi_secure_mode'|translate }}</mat-label>

                            <mat-select formControlName="wifi_secure_mode">
                                <mat-option [value]="null">{{ 'public.null'|translate }}</mat-option>
                                <mat-option *ngFor="let item of secureModes" [value]="item">
                                    {{ item }}
                                </mat-option>
                            </mat-select>

                            <mat-error [flexFormControlError]="form.get('wifi_secure_mode')"></mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.wifi_encryption_type'|translate }}</mat-label>

                            <mat-select formControlName="wifi_encryption_type">
                                <mat-option [value]="null">{{ 'public.null'|translate }}</mat-option>
                                <mat-option *ngFor="let item of encryptionTypes" [value]="item">
                                    {{ item }}
                                </mat-option>
                            </mat-select>

                            <mat-error [flexFormControlError]="form.get('wifi_encryption_type')"></mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.wifi_password'|translate }}</mat-label>
                            <input data-ci="wifi_password" matInput type="text" formControlName="wifi_password">
                            
                            <mat-error [flexFormControlError]="form.get('wifi_password')"></mat-error>
                        </mat-form-field>
                    </div>

                </ng-container>

                <div>
                    <mat-slide-toggle formControlName="dhcp">
                        <span class="fontfamily-bold">{{ 'sipphone.custom_config.dhcp'|translate }}</span>
                    </mat-slide-toggle>
                </div>

                <ng-container *ngIf="!form.get('dhcp').value">
                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.ip'|translate }}</mat-label>
                            <input data-ci="ip" matInput type="text" formControlName="ip">
                            
                            <mat-error [flexFormControlError]="form.get('ip')"></mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.subnet_mask'|translate }}</mat-label>
                            <input data-ci="subnet_mask" matInput type="text" formControlName="subnet_mask">
                            
                            <mat-error [flexFormControlError]="form.get('subnet_mask')"></mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.gateway'|translate }}</mat-label>
                            <input data-ci="gateway" matInput type="text" formControlName="gateway">
                            
                            <mat-error [flexFormControlError]="form.get('gateway')"></mat-error>
                        </mat-form-field>
                    </div>

                </ng-container>

                <div>
                    <mat-slide-toggle formControlName="auto_dns">
                        <span class="fontfamily-bold">{{ 'sipphone.custom_config.auto_dns'|translate }}</span>
                    </mat-slide-toggle>
                </div>

                <ng-container *ngIf="!form.get('auto_dns').value">

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.primary_dns'|translate }}</mat-label>
                            <input data-ci="primary_dns" matInput type="text" formControlName="primary_dns">
                            
                            <mat-error [flexFormControlError]="form.get('primary_dns')"></mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.secondary_dns'|translate }}</mat-label>
                            <input data-ci="secondary_dns" matInput type="text" formControlName="secondary_dns">
                            
                            <mat-error [flexFormControlError]="form.get('secondary_dns')"></mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div>
                    <mat-slide-toggle formControlName="vlan_pc_enabled">
                        <span class="fontfamily-bold">{{ 'sipphone.custom_config.vlan_pc_enabled'|translate }}</span>
                    </mat-slide-toggle>
                </div>

                <ng-container *ngIf="form.get('vlan_pc_enabled').value">
                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.vlan_pc_id'|translate }}</mat-label>
                            <input data-ci="vlan_pc_id" matInput type="number" formControlName="vlan_pc_id">
                            
                            <mat-error [flexFormControlError]="form.get('vlan_pc_id')"></mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div>
                    <mat-slide-toggle formControlName="vlan_phone_enabled">
                        <span class="fontfamily-bold">{{ 'sipphone.custom_config.vlan_phone_enabled'|translate }}</span>
                    </mat-slide-toggle>
                </div>
                
                <ng-container *ngIf="form.get('vlan_phone_enabled').value">
                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.custom_config.vlan_phone_id'|translate }}</mat-label>
                            <input data-ci="vlan_phone_id" matInput type="number" formControlName="vlan_phone_id">

                            <mat-error [flexFormControlError]="form.get('vlan_phone_id')"></mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                {{ 'sipphone.custom_config.codec'|translate }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="row" fxLayoutGap="10px">
                <div fxFlex>
                    <h3>{{ 'sipphone.custom_config.codec_available'|translate }}</h3>
                    <ul class="codec-list">
                        <li *ngFor="let c of codecsAvailable">
                            <strong class="codec_text">{{ c }}</strong>
                            <button mat-icon-button color="primary" type="button" (click)="addCodec(c)">
                                <mat-icon>forward</mat-icon>
                            </button>
                        </li>
                    </ul>
                </div>

                <div fxFlex>
                    <h3>{{ 'sipphone.custom_config.codec_enabled'|translate }}</h3>
                    <ul class="codec-list">
                        <li *ngFor="let c of codecsEnabled;let i = index" fxLayout="row" fxLayoutAlign="start center">
                            <div class="codec_actions" fxFlex="80px">
                                <button *ngIf="i < codecsEnabled.length-1" mat-icon-button color="accent" type="button"
                                    (click)="decreasePositionCodec(c)">
                                    <mat-icon>arrow_downward</mat-icon>
                                </button>

                                <button *ngIf="i > 0" class="m-1" mat-icon-button color="primary" type="button"
                                    (click)="increasePositionCodec(c)">
                                    <mat-icon>arrow_upward</mat-icon>
                                </button>
                            </div>

                            <strong fxFlex="80px">{{ c }}</strong>

                            <button mat-icon-button color="warn" type="button" (click)="removeCodec(c)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'sipphone.custom_config.background_image'|translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="column">
                <app-image-change [image]="backgroundImage"
                    [title]="'sipphone.custom_config.background_image'|translate"
                    (onChange)="onChangeBackgroundImage($event)"></app-image-change>
            </div>
        </mat-card-content>
    </mat-card>

</form>
