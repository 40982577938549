import { Component } from '@angular/core';
import { DialogActionButton } from '@wephone-utils';
import { UserService } from '@wephone/services/user.service';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { DialogComponentBase } from '@wephone-core-ui';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { FileSettings, getFileValidation } from '@wephone-utils/utils/file-util';
import { MatDialogRef } from '@angular/material/dialog';

export enum UserImportSteps {
  UPLOAD = 'upload',
  RESULT = 'result'
}

@Component({
  selector: 'user-import-modal',
  templateUrl: './user-import-modal.component.html',
  styleUrls: ['./user-import-modal.component.scss']
})
export class UserImportModal extends DialogComponentBase {
  dialogTitle = _tk('user.title.import');
  backButton: DialogActionButton =
    {
      action: () => {
        this.goPrev();
      },
      visible: () => {
        return this.isUploadResult();
      }
    };
  dialogRightActions: DialogActionButton[] = [];

  private steps = UserImportSteps;
  errors: string[] = [];
  step: string;
  importFile: any;
  sendEmail: boolean;
  fileSettings: FileSettings;

  constructor(
    dialogRef: MatDialogRef<UserImportModal>,
    private userService: UserService,
    private fileEntryService: FileEntryService,
    private settings: FlexIvrSettings
  ) {
    super(dialogRef);
    this.step = this.steps.UPLOAD;

    this.fileSettings = {
      extensions: ['.csv'],
      max_size: this.settings.max_upload_size * 1024 * 1024
    };
  }

  downloadSampleFile(): void {
    const url = this.settings.getEnterpriseUrlV2('/api/user/sample/download/csv');
    this.fileEntryService.download(url, 'sample-users.csv');
  }

  /**
   * @return: Promise {result:any} | {error:{message:string},status:string} 
   */
  async importCsv($event): Promise<any> {
    this.importFile =
      $event.target && $event.target.files && $event.target.files.length ? $event.target.files[0] : undefined;
    if (!this.importFile) {
      console.error('No selected file');
      return Promise.resolve(undefined);
    }

    try {
      if (this.importFile) {
        const fileValidation = getFileValidation(this.importFile, this.fileSettings);
        if (!fileValidation.valid) {
          this.importFile = undefined;
          throw new Error(fileValidation.message);
        }
      }

      const importResult = await this.userService.importCsv(this.importFile, this.sendEmail);

      if (importResult.result && importResult.result.status === 'error') {
        this.errors = importResult.result.messages;
      }
    } catch (e) {
      this.errors.push(e.message);
    } finally {
      this.importFile = undefined;
      this.goNext();
    }
  }

  private goNext(): void {
    this.step = this.steps.RESULT;
    this.updateDialogLayout();
  }

  goPrev(): void {
    this.errors = [];
    this.step = this.steps.UPLOAD;
    this.updateDialogLayout();
  }

  isUpload(): boolean {
    return this.step === this.steps.UPLOAD;
  }

  isUploadResult(): boolean {
    return this.step === this.steps.RESULT;
  }
}
