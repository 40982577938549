import { Entity, datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { FileEntryEntity } from './fileentry';
import { FileEntryRepository } from '../repository/fileentry';

export class EnterpriseTelecomParamsEntity extends Entity {
  static object_type_id = 'enterprise_telecom_params';

  id: number;
  @datafield vlan_phone: number;
  @datafield vlan_pc: number;
  @datafield onhold_music_id: number;

  _onhold_music: FileEntryEntity;

  // greeting_file
  set onhold_music(onholdMusic: FileEntryEntity) {
    this.onhold_music_id = onholdMusic ? onholdMusic.id : null;
  }

  get onhold_music(): FileEntryEntity {
    if (this.onhold_music_id) {
      if (!this._onhold_music || this._onhold_music.id !== this.onhold_music_id) {
        this._onhold_music = EntityManager.getRepository<FileEntryRepository>('FileEntryRepository').getObjectById(this.onhold_music_id);
      }

      return this._onhold_music;
    }
  }
}
