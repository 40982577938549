import { TemplateRef } from '@angular/core';
import { ColumnTypes } from '@wephone-utils/datasource';

export interface IFlexTableColumnConfig {
  name: string;
  label: string;
  type?: ColumnTypes;
  uiWidget?: string;
  visible?: boolean;
  searchable?: boolean;
  sortable?: boolean;
  width?: string;
  align?: 'center' | 'left' | 'right';
  exportable?: boolean;
  exportedValue?: any; // function callback
  sort?: 'asc' | 'desc';
  customSortFn?: any;
  hiddenOn?: string; // Screen size on which this column is invisible. Possible values: xs, lt-md, md, gt-md, etc.
  visibleInCompactMode?: boolean;
  // See https://github.com/angular/flex-layout/wiki/Responsive-API
  enableTooltip?: boolean;
  lineClamp?: number; // The number of lines after which the content will be display on the cell. Only works when the tooltip has been activated.
  renderCell?(row: any, colName?: string): string;
}

export interface IFlexTableGroupedActionEvent {
  selectedItems: any[];
}

export interface IFlexTableAction {
  id?: string;
  icon: string;
  hint?: string;
  unique?: boolean;
  visible?: boolean;
  callback?(event: IFlexTableGroupedActionEvent): any;
}

export interface IFlexTableListActions {
  defaultActions?: IFlexTableAction[];
  selectionActions?: {
    primary: IFlexTableAction[];
    secondary?: IFlexTableAction[];
  };
}

export interface IFlexTableConfig {
  columns: IFlexTableColumnConfig[];
  rowHeight?: number;
  rowClass?: (row: any) => string;
  align?: 'left' | 'right' | 'center';
  focusedRowHeight?: number;
  multiSelect?: boolean;
  enableFilter?: boolean;
  scrollable?: boolean;
  enableExportCsv?: boolean;
  enableExpandDetail?: boolean;
  listActions?: IFlexTableListActions;
  isItemCheckableCb?: Function;
  uiGetWidgetTemplate?(widget_id: string): TemplateRef<any>;
  expandCallback?(row: any): any;
}

export class FlexTableSelectionChangeEvent {
  constructor(public focusedItem: any, public selectedItems?: any[], public fireActionMultiEdit?: boolean) { }
}

export enum FlexPaginatorConfigDefault {
  PAGINATOR_LENGTH = 20,
  PAGINATOR_PAGE_SIZE = 20
}
