import { datafield, Entity } from '@wephone-core/model/model';

export class OpeningHourEntity extends Entity {
  public static object_type_id = 'opening_hour';

  id: number;
  @datafield
  public calendar_id: number;
  @datafield
  public special_date_id: number;
  @datafield
  public start_time = '09:00';
  @datafield
  public end_time = '17:00';
  @datafield
  public week_day = [1, 5];
  @datafield
  public enterprise_id: number;

  public setObjectData(object_data: any, fetch_related_data = true): void {
    super.setObjectData(object_data, fetch_related_data);

    // 00:00:00
    if (this.start_time && this.start_time.length === 8) {
      this.start_time = this.start_time.substring(0, 5);
    }

    // 00:00:00
    if (this.end_time && this.end_time.length === 8) {
      this.end_time = this.end_time.substring(0, 5);
    }
  }

  get day_from(): number {
    if ((this.week_day || []).length > 0) {
      return this.week_day[0];
    }

    return 1;
  }

  set day_from(d: number) {
    const week_day = [];
    if (this.day_to === null || this.day_to === undefined) {
      week_day.push(d);
    } else {
      const ranges = [];
      const d1 = this.day_to;
      if (d > d1) {
        ranges.push({ from: d, to: 6 });
        ranges.push({ from: 0, to: d1 });
      } else {
        ranges.push({ from: d, to: d1 });
      }
      for (const range of ranges) {
        for (let dn = range.from; dn <= range.to; dn++) {
          week_day.push(dn);
        }
      }
    }

    this.week_day = week_day;
  }

  get day_to(): number {
    if ((this.week_day || []).length > 0) {
      return this.week_day[this.week_day.length - 1];
    }

    return 0;
  }

  set day_to(d1: number) {
    const week_day = [];
    if (this.day_from === null || this.day_from === undefined) {
      week_day.push(d1);
    } else {
      const ranges = [];
      const d = this.day_from;
      if (d > d1) {
        ranges.push({ from: d, to: 6 });
        ranges.push({ from: 0, to: d1 });
      } else {
        ranges.push({ from: d, to: d1 });
      }
      for (const range of ranges) {
        for (let dn = range.from; dn <= range.to; dn++) {
          week_day.push(dn);
        }
      }
    }

    this.week_day = week_day;
  }
}
