import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

import { BlockUIService } from 'ng-block-ui';
// import { TranslationService } from '@wephone-translation';
import { SingletonBase } from '../utils/singleton';
import { Debug } from '@wephone-utils/debug';

const debug = Debug('uiblocker');

const mainBlockerElementName = 'main';

@Injectable()
export class UIBlockerService extends SingletonBase {
  block_counter = 0;
  default_message: string;

  constructor(
    private blockUI: BlockUIService,
    // private translation: TranslationService,
    private messageService: MessageService
  ) {
    super();
    this.messageService.subscribe('uiblocker.add', msg => {
      this.block(msg);
    });
    this.messageService.subscribe('uiblocker.remove', msg => {
      this.unblock(msg);
    });

    // this.default_message = this.translation.instant('public.message.loading');
    this.default_message = '';
  }

  block(reason?: string, message?: string): Promise<void> {
    const display_msg = message || this.default_message;
    this.block_counter++;
    if (window['Cypress']) {
      // Do not block screen while running e2e test
      return;
    }
    debug(`start blocking with message %s (%s)`, display_msg, reason);
    if (!this.blockUI.isActive(mainBlockerElementName)) {
      this.blockUI.start(mainBlockerElementName, display_msg);
    }
  }

  unblock(reason?: string): void {
    if (this.block_counter <= 0) {
      debug(`unblock got called while block_counter is %d (${reason})`, this.block_counter);
      return;
    }
    debug(`Stop blocking (${reason})`);
    this.block_counter--;
    if (window['Cypress']) {
      return;
    }
    if (this.block_counter === 0 && this.blockUI.isActive(mainBlockerElementName)) {
      this.blockUI.stop(mainBlockerElementName);
    }
  }
}
