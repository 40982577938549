import * as _ from 'lodash';
import {
  Component,
  OnInit,
  OnDestroy,
  Self,
  Optional,
  ElementRef,
  Input
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { UserRepository } from '@wephone-core/model/repository/user';

@Component({
  selector: 'user-select-form-input',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UserSelectFormInput }]
})
export class UserSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<UserEntity> {
  static nextId = 0;

  @Input() hideDisableUsers: boolean;
  @Input() selectedUserIds: number[];

  flexSelect: FormControl = new FormControl();
  userList: UserEntity[];
  flexSelectOptions: IFlexSelectOptions;
  private readonly userRepo: UserRepository;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private readonly em: EntityManager
  ) {
    super(ngControl, elRef);
    this.userRepo = this.em.getRepository<UserRepository>('UserRepository');
    this.flexSelectOptions = {
      filterFunc: (item: UserEntity, filterString) => {
        return regexSearch(item.name, filterString);
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();

    let userList: UserEntity[];
    if (this.hideDisableUsers) {
      userList = this.userRepo.getAllEnabled();
    } else {
      userList = this.userRepo.getObjectList();
    }

    if (this.selectedUserIds) {
      userList = userList.filter(x => _.includes(this.selectedUserIds, x.id));
    }

    this.userList = userList;
  }

  get controlType(): string {
    return 'user-select-form-input';
  }
}
