import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import {
  DialogActionButton,
  Colors,
  IFlexDialogConfig,
  NoWhitespaceValidator,
  AsciiValidator,
  SmsMaxValidator,
  SmsMaxLength,
} from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TagEntity } from '@wephone-core/model/entity/tag';
import { SmsTemplateRepository } from '@wephone-core/model/repository/sms_template';
import { NotPhoneNumberValidated } from '@wephone/services/form-validator';
import { SmsMessageCount, SmsService } from '@wephone-sms/service/sms.service';

@Component({
  selector: 'app-create-sms-template-modal',
  templateUrl: './create-sms-template-modal.component.html',
  styleUrls: ['./create-sms-template-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSmsTemplateModalComponent extends DialogComponentBase implements OnInit, OnDestroy {
  static modalConfig: IFlexDialogConfig = {
    size: 's',
  };

  dialogTitle = _tk('sms_template.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  // form: FormGroup;
  smsContentInfo: SmsMessageCount;
  smsMaxLength: number = SmsMaxLength;

  constructor(
    private readonly fb: FormBuilder,
    private readonly smsService: SmsService,
    dialogRef: MatDialogRef<CreateSmsTemplateModalComponent>,
    cdr: ChangeDetectorRef,
  ) {
    super(dialogRef, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50), NoWhitespaceValidator]],
      sender_name: ['', [Validators.maxLength(11), NoWhitespaceValidator, AsciiValidator, NotPhoneNumberValidated()]],
      content: ['', {
        validators: [Validators.required, NoWhitespaceValidator],
        updateOn: 'change'
      }],
    });

    this.setSmsContentInfo();
    this.setValidatorsSmsMessage();

    this.addSubscription(
      this.form.get('sender_name').valueChanges.subscribe(() => {
        this.setValidatorsSmsMessage();
      })
    );

    this.addSubscription(
      this.form.get('content').valueChanges.subscribe(() => {
        this.setSmsContentInfo();
        this.form.get('content').markAsTouched();
      })
    );
  }

  hasLimitedSms(): boolean {
    return !!this.form.get('sender_name').value;
  }

  private setSmsContentInfo(): void {
    this.smsContentInfo = this.smsService.getSmsMessageCount(this.form.get('content').value);
  }

  private setValidatorsSmsMessage(): void {
    if (this.form.get('sender_name').value) {
      this.form.get('content').setValidators([Validators.required, SmsMaxValidator, NoWhitespaceValidator]);
    } else {
      this.form.get('content').setValidators([Validators.required, NoWhitespaceValidator]);
    }
  }

  async submit(): Promise<void> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.detectChanges();
        this.toastService.showError(_ti('public.message.data_invalid'));
        return undefined;
      }

      const data = {
        name: _.trim(this.form.get('name').value),
        sender_name: this.form.get('sender_name').value && _.trim(this.form.get('sender_name').value) || '',
        content: _.trim(this.form.get('content').value),
      };
      const repo = SmsTemplateRepository.getInstance<SmsTemplateRepository>();
      const res = await repo.createAndSave(data);
      let newSmsTemplate: TagEntity;
      if (res && res.object_id) {
        await repo.wait_for_object_id(res.object_id);
        newSmsTemplate = repo.getObjectById(res.object_id);
      }
      this.successToast(_ti('public.message.create_success'));
      return this.dismiss(newSmsTemplate, false);
    } catch (error) {
      console.error('Create new sms-template error', error);
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }
}
