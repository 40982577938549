<mat-card class="mat-card-flat" *ngIf="is_ready">
  <mat-card-content>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.xs="5px">
      <ng-container *ngFor="let tab of period_list">
        <button type="button" mat-raised-button [color]="period && tab.period == period ? 'accent' : 'primary'"
          (click)="changePeriod(tab.period)">{{ tab.label | translate }}</button>
      </ng-container>
    </div>

    <!-- <div *ngIf="period=='custom'" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
      fxLayoutAlign.xs="start" class="mt-1">
      <div fxFlex="32" fxFlex.xs="auto" fxLayoutAlign="end center" fxLayoutAlign.xs="center">
        <mat-form-field>
          <input matInput [matDatepicker]="periodStartDtPicker" [(ngModel)]="period_start_date"
            [placeholder]="'public.from' | translate">
          <mat-datepicker-toggle matSuffix [for]="periodStartDtPicker"></mat-datepicker-toggle>
          <mat-datepicker #periodStartDtPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="32" fxFlex.xs="auto" fxLayoutAlign="start center" fxLayoutAlign.xs="center">
        <mat-form-field>
          <input matInput [matDatepicker]="periodEndDtPicker" [(ngModel)]="period_end_date"
            [placeholder]="'public.to' | translate">
          <mat-datepicker-toggle matSuffix [for]="periodEndDtPicker"></mat-datepicker-toggle>
          <mat-datepicker #periodEndDtPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="32" fxFlex.xs="auto" fxLayoutAlign="end start" fxLayoutAlign.xs="center">
        <button mat-button (click)="applyFilter()"></button>
      </div>
    </div> -->
  </mat-card-content>
</mat-card>
