<div fxFill fxLayout="column">
  <h2>{{'sipphone_provisioning_token.provisioning_token'| translate}}</h2>
  <div *ngIf="provisioningToken" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <div>
      <h3 class="ma-0">{{provisioningToken}}</h3>
    </div>
    <div>
      <button mat-icon-button [matTooltip]="'clipboard.copy_title'|translate" (click)="copy()">
        <mat-icon color="primary">content_copy</mat-icon>
      </button>
    </div>
  </div>
</div>
