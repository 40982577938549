import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';

export class ContactFormatFieldEntity extends Entity {
  public static object_type_id: string = 'contact_format_field';

  @datafield
  public contact_format_id: number;
  @datafield
  public label: string;
  @datafield
  public data_type: string;
  @datafield
  public mapped_column_id: number;
  @datafield
  public standard_field_id: string;
  @datafield
  public original_column_label: string;
  @datafield
  public position: number;

  public setId(id) {
    this.id = id;
  }
}
