import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ToastService,
  IFlexTableSidePannelOptions,
  DialogService,
  DynamicFilterSource,
  IFlexTableConfig,
  regexSearch,
} from '@wephone-utils';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { RoutingServiceRepository } from '@wephone-core/model/repository/routing_service';
import { RoutingServiceEntity } from '@wephone-core/model/entity/routing_service';
import { RoutingServiceEditComponent } from '../routing-service-edit/routing-service-edit.component';
// import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateRoutingServiceModalComponent } from '@wephone/modals/create-routing-service-modal/create-routing-service-modal.component';

@Component({
  selector: 'app-routing-service-list',
  templateUrl: './routing-service-list.component.html',
  styleUrls: ['./routing-service-list.component.scss']
})
export class RoutingServiceListComponent extends FlexBasePage implements OnInit {
  routingServiceRepo = RoutingServiceRepository.getInstance<RoutingServiceRepository>();
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: RoutingServiceEditComponent,
  };

  filterString: string;
  form: FormGroup;
  @ViewChild('flexCrud', { static: false }) flexCrud;
  constructor(
    private readonly dialogService: DialogService,
    private readonly toast: ToastService,
    private readonly fb: FormBuilder,
    // private readonly translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initDS();
  }

  initDS(): void {
    const dataSource = this.routingServiceRepo.dataSource<RoutingServiceEntity>();
    this.dataSource = new DynamicFilterSource(dataSource, this.filterFunc);

    this.tableConfig = {
      enableFilter: false,
      columns: [
        {
          name: 'name',
          label: _tk('ivrremoteapp.content.name'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (item: RoutingServiceEntity) => {
            return item.name.toLowerCase();
          }
        },
        {
          name: 'url',
          label: _tk('ivrremoteapp.content.url'),
          searchable: false,
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            hint: _tk('public.create'),
            callback: () => {
              return this.create();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(this.onFormValueChange)
    );
  }

  filterFunc = (item: IvrRemoteAppEntity): boolean => {
    if (!this.filterString) {
      return true;
    }

    return regexSearch(item.name, this.filterString);
  }

  private readonly onFormValueChange = (formValues: { filterString: string }) => {
    this.filterString = (formValues.filterString || '').trim();
    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  protected async resolveData(): Promise<void> {
    await this.routingServiceRepo.findAll();
    // await EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
  }

  create(): MatDialogRef<any> {
    return this.dialogService.openDialog2(CreateRoutingServiceModalComponent, {});
  }

  private async bulkDelete(items: IvrRemoteAppEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        if (_.isEmpty(items)) {
          return;
        }

        try {
          await this.routingServiceRepo.bulkDelete(items);
          this.flexCrud.closeSidePanel();
          this.toast.show(_ti('public.message.delete_success'));
          this.routingServiceRepo.reload();
        } catch (e) {
          console.error('Error deleting items', e);
          this.toast.showErrorMessage(e, _ti('public.message.delete_failure'));
        }
      }
    );
  }
}
