<div fxLayout="row wrap" fxLayoutGap="10px" *ngFor="let custom_attr of custom_attrs">
  <mat-form-field fxFlex="calc(50-50px)">
    <mat-label>{{ 'ivrmenu.content.custom_attr.attr_name' | translate }}</mat-label>
    <input matInput type="text" [(ngModel)]="custom_attr.name" [required]="true" placeholder="{{ 'ivrmenu.content.custom_attr.attr_name' | translate }}">
    <mat-error [hidden]="!errors[index]?.name">{{ 'form.validator.field_required' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field fxFlex="calc(50-50px)">
    <mat-label>{{ 'ivrmenu.content.custom_attr.attr_value' | translate }}</mat-label>
    <input matInput type="text" [(ngModel)]="custom_attr.value" placeholder="{{ 'ivrmenu.content.custom_attr.attr_value' | translate }}">
  </mat-form-field>
  <div fxFlex="40px">
    <button mat-icon-button type="button" color="danger" (click)="removeCustomAttr(custom_attr)" title="{{ 'public.remove' | translate }}">
      <mat-icon class="mat-text-warn">remove_circle</mat-icon>
    </button>
  </div>
</div>

<button mat-button type="button" color="secondary" (click)="addCustomAttr()">
  <mat-icon class="mat-text-accent">add_circle</mat-icon> {{ 'ivrmenu.content.custom_attr.btn_add' | translate }}
</button>
