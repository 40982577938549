import * as _ from 'lodash';
import {
  Component,
  OnInit,
  ElementRef,
  Optional,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DialogService, FlexMatInputBase, regexSearch } from '@wephone-utils';
import { NgControl, NgModel } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';
import { AddAgentToQueueComponent } from '@wephone/modals/agent/add-agent-to-queue/add-agent-to-queue.component';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { QueueAgentListControlValue } from '../queue-agent.i';
import { IAgentItemActionMenu } from '@wephone-common/user/agent-item/agent-item.i';
import { AgentEntity } from '@wephone-core/model/entity/agent';

@Component({
  selector: 'queue-agent-list-form-input',
  templateUrl: './queue-agent-list.component.html',
  styleUrls: ['./queue-agent-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueAgentListFormInput extends FlexMatInputBase implements OnInit, MatFormFieldControl<any> {
  @Input() title: string;
  @Input() queueId: number;
  @Input() group?: UserGroupEntity;
  @Input() isExclusive?: boolean;
  @Input() allowRingAll?: boolean;
  @Input() excludeItems?: CallQueueAgentLinkEntity[] = [];
  @Input() dragDropGroup?: string;
  @Input() dragDropId: string;
  @Output() readonly dragDropChange = new EventEmitter();

  @ViewChildren(NgModel) formFields: QueryList<NgModel>;

  queueAgentList: CallQueueAgentLinkEntity[];
  agentNameList: string[];
  selectionMode: number;
  searchText: string;
  agentActions: IAgentItemActionMenu[];

  constructor(
    elRef: ElementRef,
    @Optional() ngControl: NgControl,
    private readonly dialogService: DialogService,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, elRef, cdr);
  }

  ngOnInit(): void {
    this.initDragAndDrop();
    this.agentActions = [
      {
        icon: 'clear',
        label: 'public.delete',
        color: 'warn',
        callback: (agent: AgentEntity) => {
          this.removeQueueAgent(agent);
        }
      }
    ];
  }

  onSelectionModeChange(): void {
    this.cdr.markForCheck();
    this.onChange();
  }

  get value(): QueueAgentListControlValue {
    return {
      agent_selection_mode: this.selectionMode,
      queue_agents: this.queueAgentList
    };
  }

  writeValue(val: QueueAgentListControlValue): void {
    this.selectionMode = val.agent_selection_mode;
    this.setQueueAgentList(val.queue_agents);
    if (this.formFields) {
      this.formFields.forEach(model => {
        model.control.markAsPristine();
      }
      );
    }
  }

  get controlType(): string {
    return 'flex-queue-agent-list-form-input';
  }

  private setQueueAgentList(queueAgentList: CallQueueAgentLinkEntity[]): void {
    const list: CallQueueAgentLinkEntity[] = queueAgentList.filter(x => x.agent && x.queue);
    this.queueAgentList = _.clone(list);
    this.agentNameList = this.queueAgentList.filter(x => x.agent && x.queue).map(qa => qa.agent.name);
  }

  addAgentToQueue = (queueAgents: CallQueueAgentLinkEntity[]): void => {
    if (!_.isEmpty(queueAgents)) {
      this.setQueueAgentList(this.queueAgentList.concat(queueAgents || []));
      this.cdr.markForCheck();
      this.onChange();
    }
  }

  openModalAddAgentToQueue(): void {
    const modalConfig: MatDialogConfig = {
      data: {
        exclude_list: this.excludeItems || [],
        queue_agent_list: this.queueAgentList || [],
        queue_id: this.queueId,
        group: this.group,
        is_exclusive: this.isExclusive,
        on_selected: this.addAgentToQueue
      }
    };
    this.dialogService.openSideDialog(AddAgentToQueueComponent, modalConfig);
  }

  removeQueueAgent(agent: AgentEntity): void {
    if (this.queueAgentList) {
      _.remove(this.queueAgentList, (qa: CallQueueAgentLinkEntity) => {
        return qa.agent && qa.agent.id === agent.id;
      });
    }
    this.cdr.markForCheck();
    this.onChange();
  }

  private initDragAndDrop(): void {
    this.dragDropGroup = this.dragDropGroup || 'default';
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.cdr.markForCheck();
    this.onChange();
  }

  hasFiltered(queueAgent: CallQueueAgentLinkEntity): boolean {
    const filterValue: string = (this.searchText || '').trim();
    if (!filterValue) {
      return true;
    }

    const agentNamesFiltered = this.agentNameList.filter(name => {
      return regexSearch(name, filterValue);
    }) || [];

    return !!agentNamesFiltered.find(name => name === queueAgent.agent.name);
  }
}
