<div *ngIf="hasData; else noPhonesConnected">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start" fxLayoutGap="10px"
    fxLayoutGap.xs="0" class="search-form">
    <div fxFlex="30">
      <flex-search-input class="full-width" [formControl]="filterCtrl">
      </flex-search-input>
    </div>
  </div>
  <!-- <table mat-table [dataSource]="dataSource" class="flat-table" matSort>
    <ng-container matColumnDef="registration_time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'location.content.last_modified' | translate }}
      </th>
      <td mat-cell *matCellDef="let extension">
        <span *ngIf="extension.last_modified">{{extension.last_modified|timeFormatDateTimeShort}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="expiration_time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'location.content.expires' | translate }}</th>
      <td mat-cell *matCellDef="let extension">
        <span *ngIf="extension.expires">{{ extension.expires|timeFormatDateTimeShort}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'location.content.contact' | translate }}</th>
      <td mat-cell *matCellDef="let extension">{{ extension.contact }}</td>
    </ng-container>
    <ng-container matColumnDef="user_agent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'location.content.user_agent' | translate }}
      </th>
      <td mat-cell *matCellDef="let extension">{{ extension.user_agent}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'public.action' | translate }}
      </th>
      <td mat-cell *matCellDef="let extension">
        <button type="button" mat-icon-button [matTooltip]="'sipphone.content.restart_sip_phone'|translate"
          (click)="restartSipPhone($event, extension)">
          <mat-icon>restart_alt</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table> -->

  <flex-table [dataSource]="dataSource" [tableConfig]="tableConfig" class="flat-table"
      [columnTemplates]="{'last_modified': tplTime, 'expires': tplTime, 'action': tplAction}">

      <ng-template #tplTime let-item="row" let-col="col">
        {{item[col]|timeFormatDateTimeShort}}
      </ng-template>

      <ng-template #tplAction let-item="row">
        <button type="button" mat-icon-button [matTooltip]="'sipphone.content.restart_sip_phone'|translate"
          (click)="restartSipPhone($event, item)">
          <mat-icon>restart_alt</mat-icon>
        </button>
      </ng-template>
  </flex-table>
</div>
<ng-template #noPhonesConnected>{{ 'sipphone.message.no_phone_connected' | translate }}</ng-template>
