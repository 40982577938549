import { Entity, datafield } from '@wephone-core/model/model';

export class PhoneBookEntry extends Entity {
  static object_type_id = 'phonebook';

  id: number;

  @datafield
  first_name: string;

  @datafield
  last_name: string;

  @datafield
  email: string;

  @datafield
  phone_office: string;

  @datafield
  phone_mobile: string;

  dumpObjectData(): any {
    const ret: any = super.dumpObjectData();
    if (!this.getId()) {
      delete ret.id;
    }
    return ret;
  }
}
