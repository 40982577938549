import { environment } from '../../environments/environment';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class WephoneMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (!environment.production) {
      return '[MISSING]: ' + params.key;
    }
    return params.key;
  }
}
