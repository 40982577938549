import { Component } from '@angular/core';
import { DialogService } from '@wephone-utils';

@Component({
  selector: 'app-layout',
  styleUrls: ['./auth-layout.component.scss'],
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent {
  constructor(
    private readonly dialogService: DialogService,
  ) {
  }

}
