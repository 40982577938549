<form [formGroup]="form">
  <mat-accordion displayMode="flat" multi="true">
    <mat-expansion-panel [expanded]="true" *ngIf="!multipleEdit">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{ 'call_queue_edit.section.api_section'|translate }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <mat-form-field fxFlex class="pt-0">
          <mat-label>{{ 'call_queue.content.alias'|translate }}</mat-label>
          <input type="text" matInput formControlName="alias" placeholder="{{ 'call_queue.content.alias'|translate }}">
        </mat-form-field>

        <mat-form-field fxFlex [hidden]="true" class="pt-0">
          <mat-label>{{ 'call_queue.content.xapi_agent_search'|translate }}</mat-label>
          <input type="text" matInput formControlName="xapi_agent_search"
            placeholder="{{ 'call_queue.content.xapi_agent_search'|translate }}">
        </mat-form-field>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{ 'call_queue_edit.content.agent_displayed_number_title'|translate }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <mat-form-field fxFlex class="pt-0">
          <mat-label>{{'call_queue.content.display_number.agent_display_number_type'|translate}}</mat-label>
          <mat-select formControlName="agent_display_number_type">
            <mat-option *ngFor="let i of agentDisplayNumberTypes" [value]="i.value">{{ i.label }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="pt-0" fxFlex
          *ngIf="agentDisplayNumberTypeControl.value && agentDisplayNumberTypeControl.value === agentDisplayNumberDidNumber">
          <mat-label>{{ 'call_queue.content.display_number.displayed_number'|translate }}</mat-label>
          <did-select-form-input formControlName="agent_display_number"
            placeholder="{{'call_queue.content.display_number.displayed_number'|translate}}" [required]="true">
          </did-select-form-input>

          <mat-error [flexFormControlError]="agentDisplayNumberControl"></mat-error>
        </mat-form-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3> {{ 'call_queue.content.exclusive.title'|translate }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-slide-toggle formControlName="is_exclusive">
        {{ 'call_queue.content.exclusive.is_exclusive'|translate }}
      </mat-slide-toggle>
      <!-- <mat-checkbox formControlName="is_exclusive">{{ 'call_queue.content.exclusive.is_exclusive'|translate
        }}</mat-checkbox> -->
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3> {{ 'call_queue.content.analysis.title'|translate }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div class="pt-0">
          <!-- <mat-label>{{'call_queue.content.analysis.analysis_enabled'|translate}}</mat-label> -->

          <mat-slide-toggle formControlName="analysis_enabled" [attr.data-ci]="'analysis_enabled'">
            <span>{{'call_queue.content.analysis.analysis_enabled'|translate}}</span>
          </mat-slide-toggle>
        </div>

        <mat-form-field class="pt-0" *ngIf="form.get('analysis_enabled').value">
          <mat-label>{{ 'call_queue.content.analysis.analysis_start_dt'|translate }}</mat-label>

          <input matInput [matDatepicker]="startPicker" formControlName="analysis_start_dt"
            placeholder="{{ 'call_queue.content.analysis.analysis_start_dt' | translate }}">

          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>

          <mat-error [flexFormControlError]="form.get('analysis_start_dt')" [customMessages]="{
            oneRequired: 'form.validator.field_required'|translate
          }"></mat-error>
        </mat-form-field>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>
