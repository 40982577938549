import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class EnterpriseSipGatewayRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseSipGatewayEntity;
  protected readonly URL_PREFIX: string = 'enterprise_sip_gateway';

  protected getUrl(suffix): string {
    return super.getUrlV2(suffix);
  }

  bulkDelete(items: EnterpriseSipGatewayEntity[], manual_remove = true): Promise<any> {
    return super.bulkDelete(items, manual_remove).then(ret => {
      return super.reload().then(() => {
        return ret;
      });
    });
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/enterprise_sip_gateway'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get enterprise_sip_gateway list error', response);

          return [];
        }
      );
  }
}

import { EnterpriseSipGatewayEntity } from '../entity/enterprise_sip_gateway';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
