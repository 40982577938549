import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, Inject, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import {
  IPValidator,
  NoWhitespaceValidator,
} from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { ISipPhoneCustomConfig, ISipPhoneCustomSettings, SipphoneCodec, SipphoneEncryptionType, SipphoneSecureMode } from '@wephone-core/model/entity/sipphone.i';
import { ArrayHelper } from '@wephone-core/helpers/array.helper';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { ImageEntity } from '@wephone-core/model/entity/image';
import { ImageRepository } from '@wephone-core/model/repository/image';


@Component({
  selector: 'app-sipphone-custom-config',
  templateUrl: './sipphone-custom-config.component.html',
  styleUrls: ['./sipphone-custom-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SipphoneCustomConfigComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() sipphone: SipPhoneEntity;
  @Input() settings: ISipPhoneCustomSettings;
  @Output() readonly onChange: EventEmitter<ISipPhoneCustomConfig> = new EventEmitter();

  form: FormGroup;
  repo: SipPhoneRepository;
  
  secureModes: SipphoneSecureMode[] = ArrayHelper.getEnumValues(SipphoneSecureMode);
  encryptionTypes: SipphoneEncryptionType[] = ArrayHelper.getEnumValues(SipphoneEncryptionType);
  codecsAvailable: SipphoneCodec[] = [];
  codecsEnabled: SipphoneCodec[] = [];
  codecsAll: SipphoneCodec[] = []; // ArrayHelper.getEnumValues(SipphoneCodec);

  private hasInitialized: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    private readonly elementRef: ElementRef,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.repo = this.em.getRepository('SipPhoneRepository');
  }

  get customConfig(): ISipPhoneCustomConfig {
    return this.sipphone && this.sipphone.custom_config || this.settings.default_config;
  }

  get backgroundImage(): ImageEntity {
    const publicId: string = this.form.get('background_image').value;
    return publicId ? (this.em.getRepository('ImageRepository') as ImageRepository).getObjectByPublicId(publicId) : null;
  }

  protected async resolveData(): Promise<void> {
    await this.em.getRepository('ImageRepository').findAll();
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector('form .ng-invalid');
    firstInvalidControl.scrollIntoView();
    firstInvalidControl.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized) {
      return;
    }

    this.initForm();
  }

  async ngOnInit(): Promise<void> {
    this.hasInitialized = true;
    await super.ngOnInit();

    this.initForm();
  }

  private initForm(): void {
    this.codecsAll = this.settings.available_codecs || [];

    this.form = this.fb.group({
      wifi_enable: [this.customConfig && this.customConfig.wifi_enable ? true : false],
      wifi_ssid: [this.customConfig && this.customConfig.wifi_ssid],
      wifi_password: [this.customConfig && this.customConfig.wifi_password],
      wifi_secure_mode: [this.customConfig && this.customConfig.wifi_secure_mode],
      wifi_encryption_type: [this.customConfig && this.customConfig.wifi_encryption_type],
      dhcp: [this.customConfig && this.customConfig.dhcp ? true : false],
      auto_dns: [this.customConfig && this.customConfig.auto_dns ? true : false],
      ip: [this.customConfig && this.customConfig.ip],
      subnet_mask: [this.customConfig && this.customConfig.subnet_mask],
      gateway: [this.customConfig && this.customConfig.gateway],
      primary_dns: [this.customConfig && this.customConfig.primary_dns],
      secondary_dns: [this.customConfig && this.customConfig.secondary_dns],
      vlan_pc_enabled: [this.customConfig && this.customConfig.vlan_pc_enabled ? true : false],
      vlan_phone_enabled: [this.customConfig && this.customConfig.vlan_phone_enabled ? true : false],
      vlan_phone_id: [this.customConfig && this.customConfig.vlan_phone_id],
      vlan_pc_id: [this.customConfig && this.customConfig.vlan_pc_id],
      codec_list: [this.customConfig && this.customConfig.codec_list],
      background_image: [this.customConfig && this.customConfig.background_image],
    });

    this.codecsEnabled = this.customConfig && this.customConfig.codec_list && this.customConfig.codec_list.filter(x => _.includes(this.codecsAll, x)) || [];
    this.codecsAvailable = this.codecsAll.filter(x => !_.includes(this.codecsEnabled, x));


    if (this.onChange) {
      this.addSubscription(
        this.form.valueChanges.subscribe(changes => {
          const formValue = this.getFormValue();
          this.onChange.emit(formValue);
        })
      );
    }
  }

  private setValidationForm(): void {
    this.setValidationFormNetworking();
  }

  private setValidationFormNetworking(): void {
    for (const elementName of ['wifi_enable', 'dhcp', 'auto_dns', 'vlan_phone_enabled', 'vlan_pc_enabled']) {
      this.validateFormItemNetworking(elementName);
    }

    // this.form.get('vlan_phone_id').setValidators(RequiedDependencyValidator(this.form, 'vlan_pc_id'));
    // this.form.get('vlan_phone_id').updateValueAndValidity();

    // this.form.get('vlan_pc_id').setValidators(RequiedDependencyValidator(this.form, 'vlan_phone_id'));
    // this.form.get('vlan_pc_id').updateValueAndValidity();
  }

  formChanged = (): boolean => {
    return this.form && this.form.dirty;
  }

  removeCodec(codec: SipphoneCodec): void {
    if (_.includes(this.codecsEnabled, codec)) {
      this.codecsEnabled.splice(this.codecsEnabled.indexOf(codec), 1);
    }

    if (!_.includes(this.codecsAvailable, codec)) {
      this.codecsAvailable.push(codec);
    }
    this.onChangeEnabledCodec();
  }

  addCodec(codec: SipphoneCodec): void {
    if (_.includes(this.codecsAvailable, codec)) {
      this.codecsAvailable.splice(this.codecsAvailable.indexOf(codec), 1);
    }

    if (!_.includes(this.codecsEnabled, codec)) {
      this.codecsEnabled.push(codec);
    }
    this.onChangeEnabledCodec();
  }

  increasePositionCodec(codec: SipphoneCodec): void {
    const currentIdx = this.codecsEnabled.indexOf(codec);
    if (currentIdx >= 0) {
      ArrayHelper.arrayMove(this.codecsEnabled, currentIdx, currentIdx - 1);
    }
    this.onChangeEnabledCodec();
  }

  decreasePositionCodec(codec: SipphoneCodec): void {
    const currentIdx = this.codecsEnabled.indexOf(codec);
    if (currentIdx >= 0) {
      ArrayHelper.arrayMove(this.codecsEnabled, currentIdx, currentIdx + 1);
    }
    this.onChangeEnabledCodec();
  }

  onChangeBackgroundImage($event: {
    new_image: ImageEntity; remove: boolean
  }): void {
    const newImage: ImageEntity = $event.remove ? null : $event.new_image;
    this.form.get('background_image').setValue(newImage ? newImage.public_id : null);
    this.form.get('background_image').markAsDirty();
    this.detectChanges();
  }

  onChangeEnabledCodec(): void {
    this.form.get('codec_list').setValue(this.codecsEnabled);
    this.form.get('codec_list').markAsDirty();
    this.detectChanges();
  }

  getFormValue(): ISipPhoneCustomConfig {
    const formValue = _.extend(this.form.value, {
      wifi_enable: this.form.value.wifi_enable ? 1 : 0,
      dhcp: this.form.value.dhcp ? 1 : 0,
      auto_dns: this.form.value.auto_dns ? 1 : 0,
      vlan_pc_enabled: this.form.value.vlan_pc_enabled ? 1 : 0,
      vlan_phone_enabled: this.form.value.vlan_phone_enabled ? 1 : 0,
    });

    return formValue;
  }

  isValid(): boolean {
    this.form.setErrors(null);
    this.setValidationForm();
    if (this.form.invalid) {
      this.form.markAllAsTouched();

      // Goto tab error
      for (const field of Object.keys(this.form.controls)) {
        if (this.form.controls[field].invalid) {
          setTimeout(() => {
            this.scrollToFirstInvalidControl();
          });
          break;
        }
      }

      this.detectChanges();
      return false;
    }

    return true;
  }

  private validateFormItemNetworking(elementName: string): void {
    const enabled: boolean = this.form.get(elementName).value;
    const dependencyElems = {
      wifi_enable: [
        'wifi_ssid',
        'wifi_password'
      ],
      dhcp: [
        'ip',
        'subnet_mask',
        'gateway',
      ],
      auto_dns: [
        'primary_dns',
        'secondary_dns'
      ],
      vlan_phone_enabled: [
        'vlan_phone_id',
      ],
      vlan_pc_enabled: [
        'vlan_pc_id'
      ],
    };

    let required = false;
    switch (elementName) {
      case 'wifi_enable':
        required = enabled;
        break;
      case 'vlan_pc_enabled':
        required = enabled;
        break;
      case 'vlan_phone_enabled':
        required = enabled;
        break;
      default:
        break;
    }

    // Validate required dependencies
    for (const dependencyName of dependencyElems[elementName]) {
      const formItem = this.form.get(dependencyName);
      if (required) {
        const validators = [Validators.required, NoWhitespaceValidator];
        if (_.includes(['ip', 'subnet_mask', 'gateway'], dependencyName)) {
          validators.push(IPValidator);
        }

        if (_.includes(['wifi_ssid', 'wifi_password', 'ip', 'subnet_mask', 'gateway', 'primary_dns', 'secondary_dns'], dependencyName)) {
          validators.push(Validators.maxLength(255));
        }

        if (_.includes(['vlan_phone_id', 'vlan_pc_id'], dependencyName)) {
          validators.push(Validators.min(0), Validators.max(4095));
        }

        formItem.setValidators(validators);
      } else {
        formItem.setValidators([]);
      }
      formItem.updateValueAndValidity();
    }
  }
}
