import { Component, Input, OnInit } from '@angular/core';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';

@Component({
  selector: 'app-outcallcampaign-mini-block',
  templateUrl: './outcallcampaign-mini-block.component.html',
  styleUrls: ['./outcallcampaign-mini-block.component.scss']
})
export class OutcallcampaignMiniBlockComponent implements OnInit {
  @Input() campaign: OutcallCampaignEntity;
  constructor() { }

  ngOnInit(): void {
  }

}
