<div fxLayout="row wrap" fxLayoutCap="5px">
  <span class="btn-tag" *ngFor="let queue of sortedInboundQueueList">
    <div class="tag-inner">
      <a (click)="removeQueue(queue)" *ngIf="isAdminOrSupervisor()">
        <mat-icon>clear</mat-icon>
      </a>
      <span class="tag-label" [matTooltip]="queue.queue_name">{{queue.queue_name|truncate:20}}</span>
    </div>
  </span>
</div>

<button type="button" class="my-1" mat-raised-button color="primary" (click)="agent_add_queues()"
  *ngIf="isAdminOrSupervisor() && add_queue == true">
  <mat-icon>add_to_queue</mat-icon>
  {{ 'livecall.actions.add_agent_queue'|translate }}
</button>
