import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IEntity } from '@wephone-core/model/model.interface';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';

export class VoiceMailRepository extends BaseRepository {
  ENTITY_CLASS = VoiceMailEntity;
  protected USE_PUSH_API = false;
  protected URL_PREFIX = 'voicemail';

  protected METHOD_PATH_VOICEMAIL_MY_MESSAGE = 'my_voicemail';
  protected METHOD_PATH_VOICEMAIL_QUEUE_MESSAGE = 'queue';
  // protected METHOD_PATH_VOICEMAIL_UPDATE_LISTENED = 'update_listened';
  protected METHOD_PATH_VOICEMAIL_GET_UNLISTENED = 'get_unlistened_number';
  protected METHOD_PATH_VOICEMAIL_MARK_LISTENED = 'mark_as_listened';
  protected METHOD_PATH_VOICEMAIL_ARCHIVE = 'archive';
  protected METHOD_PATH_VOICEMAIL_UNARCHIVE = 'unarchive';

  USER_TYPE = 'user';
  QUEUE_TYPE = 'queue';

  protected voicemail_message_list_user = {};
  protected voicemail_message_list_queue = {};

  protected getURLAction(action: string): string {
    switch (action) {
      // case 'VOICEMAIL_MY_MESSAGE':
      //   return this.getUrl(this.METHOD_PATH_VOICEMAIL_MY_MESSAGE);
      // case 'VOICEMAIL_QUEUE_MESSAGE':
      //   return this.getUrl(this.METHOD_PATH_VOICEMAIL_QUEUE_MESSAGE);
      // case 'VOICEMAIL_UPDATE_LISTENED':
      //   return this.getUrl(this.METHOD_PATH_VOICEMAIL_UPDATE_LISTENED);
      case 'VOICEMAIL_GET_UNLISTENED':
        return this.getUrlV2(this.METHOD_PATH_VOICEMAIL_GET_UNLISTENED);
      case 'VOICEMAIL_MARK_LISTENED':
        return this.getUrlV2(this.METHOD_PATH_VOICEMAIL_MARK_LISTENED);
      case 'ARCHIVE':
        return this.getUrlV2(this.METHOD_PATH_VOICEMAIL_ARCHIVE);
      case 'UNARCHIVE':
        return this.getUrlV2(this.METHOD_PATH_VOICEMAIL_UNARCHIVE);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  markVoiceMailListened(publicId: string): Promise<any> {
    const url: string = this.getURLAction('VOICEMAIL_MARK_LISTENED');
    return HttpEngine.getInstance().post(url, {
      file_entry_public_id: publicId
    });
  }

  // findMyVoiceMailMessages(reload = false): Promise<any> {
  //   const eid = ConfigManager.getInstance().getCurrentEnterpriseId();
  //   // If the configuration has already been loaded
  //   if (reload === false && this.voicemail_message_list_user[eid]) {
  //     return Promise.resolve(this.voicemail_message_list_user[eid]);
  //   }

  //   const url = this.getURLAction('VOICEMAIL_MY_MESSAGE');
  //   return HttpEngine.getInstance()
  //     .get(url, {})
  //     .then(voicemail_message_list => {
  //       if (!this.voicemail_message_list_user[eid]) {
  //         this.voicemail_message_list_user[eid] = null;
  //       }
  //       console.log('voicemail_message_list', voicemail_message_list);
  //       this.voicemail_message_list_user[eid] = this._mapRepository(voicemail_message_list);

  //       console.log('this.voicemail_message_list_user[eid]', this.voicemail_message_list_user[eid]);
  //       return this.voicemail_message_list_user[eid];
  //     });
  // }

  // findVoiceMailMessagesOfQueue(reload = false): Promise<any> {
  //   const eid = ConfigManager.getInstance().getCurrentEnterpriseId();
  //   // If the configuration has already been loaded
  //   if (reload === false && this.voicemail_message_list_queue[eid]) {
  //     return Promise.resolve(this.voicemail_message_list_queue[eid]);
  //   }

  //   const url = this.getURLAction('VOICEMAIL_QUEUE_MESSAGE');
  //   return HttpEngine.getInstance()
  //     .get(url, {})
  //     .then(voicemail_message_list => {
  //       if (!this.voicemail_message_list_queue[eid]) {
  //         this.voicemail_message_list_queue[eid] = null;
  //       }
  //       this.voicemail_message_list_queue[eid] = this._mapRepository(voicemail_message_list);
  //       return this.voicemail_message_list_queue[eid];
  //     });
  // }

  getUnlistenedNumber(type: string, filter?: {
    called_numbers?: string[],
    calling_number?: string,
    groups?: number[],
    queues?: number[],
    start_dt?: string,
    end_dt?: string,
    listen_state?: string,
    archived?: number,
  }): Promise<any> {
    const url: string = this.getURLAction('VOICEMAIL_GET_UNLISTENED');
    const params = {
      type,
      start_dt: filter.start_dt,
      end_dt: filter.end_dt,
      calling_number: filter.calling_number || '',
      'called_numbers[]': filter.called_numbers && filter.called_numbers.filter(n => !!n) || '',
      'groups[]': filter.groups && filter.groups.filter(g => !!g) || '',
      'queues[]': filter.queues && filter.queues.filter(q => !!q) || '',
      archived: filter.archived ? 1 : 0
    };
    return HttpEngine.getInstance().get(url, params);
  }

  // updateVoiceMailListened(id: number): Promise<any> {
  //   const url: string = this.getURLAction('VOICEMAIL_UPDATE_LISTENED');
  //   return HttpEngine.getInstance().postFormData(url, { id });
  // }

  archiveItems(items: IEntity[]): Promise<any> {
    if (_.isEmpty(items)) {
      console.error('No items selected');
      return;
    }
    const url: string = this.getURLAction('ARCHIVE');
    const ids: number[] = items.map(x => x.getId());

    return HttpEngine.getInstance()
      .post(url, { ids });
  }

  unarchiveItems(items: IEntity[]): Promise<any> {
    if (_.isEmpty(items)) {
      console.error('No items selected');
      return;
    }
    const url: string = this.getURLAction('UNARCHIVE');
    const ids: number[] = items.map(x => x.getId());

    return HttpEngine.getInstance()
      .post(url, { ids });
  }

  unarchiveItemsByIds(itemIds: number[]): Promise<any> {
    const items = this.getItemsByIds(itemIds);

    return this.unarchiveItems(items);
  }

  archiveItemsByIds(itemIds: number[]): Promise<any> {
    const items = this.getItemsByIds(itemIds);

    return this.archiveItems(items);
  }

  deleteItemsByIds(itemIds: number[]): Promise<any> {
    const items = this.getItemsByIds(itemIds);

    return this.deleteItems(items);
  }

  private getItemsByIds(ids: number[]): VoiceMailEntity[] {
    const items: VoiceMailEntity[] = [];
    for (const id of ids) {
      const item = this.create({
        id
      }, false) as VoiceMailEntity;
      items.push(item);
    }

    return items;
  }

  deleteItems(items: IEntity[]): Promise<any> {
    const url: string = this.getURLAction('DELETE');
    const ids: number[] = [];
    for (const item of items) {
      ids.push(item.getId());
    }

    return HttpEngine.getInstance()
      .post(url, { ids })
      .then(ret => {
        for (const id of ids) {
          this.removeItemFromListById(id, this.voicemail_message_list_user);
          this.removeItemFromListById(id, this.voicemail_message_list_queue);
        }
        return ret;
      });
  }

  // private _mapRepository(object_list): any[] {
  //   const new_list = [];
  //   for (const item of object_list) {
  //     const new_item = this.create();
  //     new_item.setObjectData(item);
  //     new_list.push(new_item);
  //   }
  //   return new_list;
  // }
}

import { VoiceMailEntity } from '@wephone-core/model/entity/voicemail';
// import { ConfigManager } from '@wephone-core/service/config_manager';
