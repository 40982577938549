import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';

export interface ResellerEnterpriseInfo {
  default_pack: string;
}
@Injectable()
export class ResellerService {

  static SIP_PUBLIC_PROXY_LIST_PATH = 'reseller/sip-public-proxy';
  static MY_ENTERPRISE_INFO = 'reseller/info';

  constructor(
    private readonly httpEngine: HttpEngine,
  ) { }

  getSipPublicProxy(): Promise<any> {
    return this.httpEngine.apiGetV2(ResellerService.SIP_PUBLIC_PROXY_LIST_PATH);
  }

  getMyEnterpriseInfo(): Promise<ResellerEnterpriseInfo> {
    return this.httpEngine.apiGetV2(ResellerService.MY_ENTERPRISE_INFO);
  }
}
