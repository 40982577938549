<ng-container *ngIf="is_ready">
  <p *ngIf="message" class="pl-1 text-warning">
    {{ message }}
  </p>
  <agent-wrapper *ngIf="shouldDisplayEavesdrop" [header]="'livecall.agent_list.list_eavesdrop'|translate"
    [headerStyle]="{'background-color': '#FFEA00', 'color': '#000'}" [agentSort]="agentSort"
    [displayType]="agent_display_type" [searchText]="search_text" [callDirection]="callDirection"
    [filterGroupIds]="filterGroupIds" [filterUngroupedAgents]="filterUngroupedAgents" [filterEavesdrop]="true"
    [dropEnable]="true" data-ci="eavesdrop_agent_list">
  </agent-wrapper>

  <agent-wrapper [header]="'livecall.agent_list.list_available'|translate"
    [headerStyle]="{'background-color': '#23ad44'}" [agentSort]="agentSort" [displayType]="agent_display_type"
    [searchText]="search_text" [callDirection]="callDirection" [filterGroupIds]="filterGroupIds"
    [filterUngroupedAgents]="filterUngroupedAgents" [filterAgentStatus]="[agentStatusType.AVAILABLE]"
    [dropEnable]="true" (onTotalUpdated)="onTotalAvailableAgentUpdated($event)" data-ci="available_agent_list">
  </agent-wrapper>

  <agent-wrapper [header]="'livecall.agent_list.list_incall_title'|translate"
    [headerStyle]="{'background-color': '#e63c3f'}" [agentSort]="agentSort" [displayType]="agent_display_type"
    [searchText]="search_text" [callDirection]="callDirection" [filterGroupIds]="filterGroupIds"
    [filterUngroupedAgents]="filterUngroupedAgents" [filterAgentStatus]="[agentStatusType.IN_CALL]"
    data-ci="incall_agents">
  </agent-wrapper>

  <agent-wrapper [header]="'livecall.agent_list.list_pause_title'|translate"
    [headerStyle]="{'background-color': '#ff8421'}" [agentSort]="pauseAgentSort" [displayType]="agent_display_type"
    [searchText]="search_text" [callDirection]="callDirection" [filterGroupIds]="filterGroupIds"
    [filterUngroupedAgents]="filterUngroupedAgents"
    [filterAgentStatus]="[agentStatusType.PAUSE, agentStatusType.PAUSE_POSTCALL]" data-ci="pause_agent_list">
  </agent-wrapper>

</ng-container>
