import { _ti } from '@wephone-translation';
import * as _ from 'lodash';


export interface FileSettings {
  max_size?: number; // Bytes
  extensions?: string[]; // .csv .jpeg
}

const isSeparator = (value: string): boolean => value === '/'|| value === '\\' || value === ':';

const getExtension = (path: string): string => {
  for (let i = path.length - 1; i > -1; --i) {
      const value = path[i];
      if (value === '.') {
          if (i > 1) {
              if (isSeparator(path[i - 1])) {
                  return '';
              }
              return path.substring(i + 1);
          }
          return '';
      }
      if (isSeparator(value)) {
          return '';
      }
  }
  return '';
};

export function getFileValidation(file: File, fileSettings: FileSettings): {
  valid: boolean;
  message?: string;
} {
  const ret = {
    valid: true,
    message: undefined
  };

  if (fileSettings.max_size && file && file.size > fileSettings.max_size) {
    ret.message = _ti('fileentry.error.file_max_size_reach', {file_size: fileSettings.max_size});
    ret.valid = false;
  }

  // let fileExt: string = path.extname(file.name) || ''; // .csv
  const fileExt: string = getExtension(file.name) || ''; // csv

  if (fileSettings.extensions && (!fileExt || fileExt && !_.includes(fileSettings.extensions, `.${fileExt}`))) {
    ret.message = _ti('fileentry.error.extension_not_supported', {extension: fileExt});
    ret.valid = false;
  }

  return ret;
}
