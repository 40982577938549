<div fxLayout="column" *ngIf="value">
  <div fxFlex="130px" fxFlex.xs="none" fxLayoutAlign="space-between center">
    <div [ngClass.xs]="{'text-center': true}">
      <img src="assets/images/payments/ob.png" alt="OB card" title="OB card" [ngStyle.xs]="{'width': '90%'}" />
    </div>
    <div [ngClass.xs]="{'text-center': true}">
      <img src="assets/images/payments/visa.png" alt="Visa card" title="Visa card" [ngStyle.xs]="{'width': '90%'}" />
    </div>
    <div [ngClass.xs]="{'text-center': true}">
      <img src="assets/images/payments/master-card.png" alt="Master card" title="Master card" [ngStyle.xs]="{'width': '90%'}" />
    </div>
    <div [ngClass.xs]="{'text-center': true}">
      <img src="assets/images/payments/american-express.png" alt="American express card" title="American express card" [ngStyle.xs]="{'width': '90%'}" />
    </div>
  </div>
  <div>
    <mat-form-field class="full-width">
      <input matInput placeholder="{{ 'components.creditcard_input.card_number' | translate }}" [(ngModel)]="value.number">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <input matInput placeholder="{{ 'components.creditcard_input.holder_name' | translate }}" [(ngModel)]="value.holderName">
    </mat-form-field>
  </div>
  <div>
    <label>{{ 'components.creditcard_input.expires_at' | translate }}</label>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.xs="0px">
    <div fxFlex="calc(33.33%-10px)" fxFlex.xs="none">
      <mat-form-field class="full-width">
        <mat-select placeholder="{{ 'cdr.stats.filter_month' | translate }}" [(ngModel)]="value.expiryMonth">
          <!--mat-option [value]="value.expiryMonth">8</mat-option-->
          <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="calc(33.33%-10px)" fxFlex.xs="none">
      <mat-form-field class="full-width">
        <mat-select placeholder="{{ 'cdr.stats.filter_year' | translate }}" [(ngModel)]="value.expiryYear">
          <!--mat-option [value]="value.expiryYear">2018</mat-option-->
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="calc(33.33%-10px)" fxFlex.xs="none">
      <mat-form-field class="full-width">
        <input matInput placeholder="{{ 'components.creditcard_input.cvc' | translate }}" [(ngModel)]="value.cvc">
      </mat-form-field>
    </div>
  </div>
  
  <div class="security-node" fxLayout="row" fxLayoutAlign="start center">
    <img src="assets/images/payments/mini-card.png" alt="Mini card" title="Mini card" class="mini-card">
    <span class="message">{{ 'components.creditcard_input.security_note'|translate }}</span>
  </div>
</div>
  