<div class="dropzone">
  <div fxLayout="row" fxLayoutAlign="start center" class="text-wrapper" (click)="fileInput.click()">
    <button *ngIf="icon" mat-icon-button>
      <mat-icon>{{ icon }}</mat-icon>
    </button>
    <div fxLayout="column">
      <div [innerHtml]="title"></div>
      <span *ngIf="allowedExtensions">{{ 'reusable_sound.content.allow_extensions'|translate: {extensions:browsedExtensions} }}</span>
      <div *ngIf="error" class="mat-text-warn">{{ error }}</div>
    </div>
  </div>
  
  <input #fileInput type="file" [accept]="browsedExtensions" style="display:none" (change)="fileLoadedForBrowser($event)">
</div>
