<div fxFill fxLayout="column">
  <div class="wrapper">
    <div class="col-left">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of steps | keyvalue; let i=index;">
            <a (click)="mainArea.scrollToElement(step.key)" class="step {{ step.key }}"
              [ngClass]="{'active': mainArea.activeAnchor === step.key}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ step.value | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      
      <div class="inner">
        <div id="general" anchorPart>
          
          <app-create-update-ivr-evaluation #createUpdateIvrEvaluationComponent
            *ngIf="ivrEvaluation"
            [ivrEvaluation]="ivrEvaluation"
            (onChangedIvrEvaluation)="onCreatedIvrEvaluation($event)"
            (onFormValueChanged)="onFormValueChanged($event)"
            ></app-create-update-ivr-evaluation>

        </div>

        <div class="mt-3">
          <mat-divider [inset]="true" class="section-divider"></mat-divider>
        </div>

        <div id="inused" anchorPart>
          <h1 class="font-weight-bold">{{ 'ivr_evaluation.content.using'|translate }}</h1>

          <ng-container *ngIf="!usedQueues.length; else tplUsedQueues">
            <p>{{ 'ivr_evaluation.content.no_data'|translate }}</p>
          </ng-container>

          <ng-template #tplUsedQueues>
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="pb-1 full-width">
              <div class="inused-item" *ngFor="let item of usedQueues" (click)="gotoPageQueue(item)">
                <app-queue-mini-block [callQueue]="item"></app-queue-mini-block>
              </div>
              <!-- <ng-container *ngFor="let item of usedQueues">
                <button mat-stroked-button color="accent" (click)="gotoPageQueue(item)">{{ item.queue_name }}</button>
              </ng-container> -->
            </div>
          </ng-template>

        </div>
      </div>

    </div>
  </div>
</div>
