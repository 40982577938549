import { Injectable } from '@angular/core';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { UserEntity } from '@wephone-core/model/entity/user';
import * as _ from 'lodash';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { UserType } from '@wephone-core/system';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';

@Injectable()
export class PermissionService {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly configManager: ConfigManager
  ) {
  }

  /**
   * Supervisor canonly change group of low-level user
   * @param user UserEntity
   * @returns boolean
   */
  canChangeUserFromGroup(user: UserEntity): boolean {
    const currentUser: UserEntity = this.authService.getUser();
    const userType: UserType = currentUser.user_type;
    switch (userType) {
      case UserType.ADMIN:
        return true;
      case UserType.SUPERVISOR:
        return !user.isAdmin() && !user.isSupervisor();
      default:
        return false;
    }
  }

  canChangeGroup(item: GroupEntity, managedGroupIds: number[]): boolean {
    const currentUser: UserEntity = this.authService.getUser();
    const userType: UserType = currentUser.user_type;

    if (userType === UserType.ADMIN) {
      return true;
    }

    if (!currentUser.group_ids) {
      return false;
    }

    return _.includes(managedGroupIds, item.id);
  }

  /**
   * Include can view group and can change group
   * @param item 
   * @param includeChildren 
   * @returns 
   */
  canViewGroup(item: GroupEntity, includeChildren = false): boolean {
    // const currentUser: UserEntity = this.authService.getUser();
    // const userType: UserType = currentUser.user_type;

    // if (userType === UserType.ADMIN) {
    //   return true;
    // }

    // if (!currentUser.group_ids) {
    //   return false;
    // }

    // let groupIds = [item.id];
    // if (includeChildren && _.isEmpty(item.all_children)) {
    //   groupIds = groupIds.concat(item.all_children.map(x => x.id));
    // }

    // return _.intersection(groupIds, currentUser.group_ids).length > 0;
    return true;
  }

  /**
   * To have same group or same queue
   * @param campaign 
   */
  async camViewCampaign(campaign: OutcallCampaignEntity): Promise<boolean> {
    const currentUser: UserEntity = this.authService.getUser();
    const userType: UserType = currentUser.user_type;

    if (userType === UserType.ADMIN) {
      return true;
    }

    // Check from group
    if (campaign.group) {
      return true;
    }

    // Check from queue
    const myProfile: MyUserProfile = await this.configManager.getMyprofile();
    const managedQueueIds = myProfile.managed_queue_ids || [];
    if (campaign.queue && _.includes(managedQueueIds, campaign.queue_id)) {
      return true;
    }

    return false;
  }

  /**
   * To have same group or same queue
   * @param queue 
   */
  async camViewQueue(queue: CallQueueEntity): Promise<boolean> {
    const currentUser: UserEntity = this.authService.getUser();
    const userType: UserType = currentUser.user_type;

    if (userType === UserType.ADMIN) {
      return true;
    }

    // Check from group
    if (queue.group) {
      return true;
    }

    // Check from queue
    const myProfile: MyUserProfile = await this.configManager.getMyprofile();
    const managedQueueIds = myProfile.managed_queue_ids || [];
    if (queue && _.includes(managedQueueIds, queue.id)) {
      return true;
    }

    return false;
  }

}
