import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import {
  DialogActionButton,
  IFlexDialogConfig,
  MessageService,
  parseDateTime,
  localNow,
} from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlexDatepickerMin, PhoneNumberValidated } from '@wephone/services/form-validator';
import { FlexGridConfig } from '@wephone-grid';

@Component({
  selector: 'sip-phone-log-modal',
  templateUrl: './sip-phone-log-modal.component.html',
  styleUrls: ['./sip-phone-log-modal.component.scss'],
})
export class SipPhoneLogModal extends DialogComponentBase implements OnInit, AfterViewInit {

  static modalConfig: IFlexDialogConfig = {
    size: 'l'
  };

  // filters: any = {};
  config: FlexGridConfig = {
    searchable: false,
    columns: {
      called_number: { cellRenderer: 'phoneNumberRenderer' },
      calling_number: { cellRenderer: 'phoneNumberRenderer' },
      start_time: { cellRenderer: 'dateTimeRenderer' },
      connected_time: { cellRenderer: 'dateTimeRenderer' },
      duration: { cellRenderer: 'durationRenderer' },
      in_out: { cellRenderer: 'rendersInout' },
      nature: { cellRenderer: 'renderNature' }
    }
  };

  dialogTitle = _tk('sipphone.content.tab_logs');
  dialogRightActions: DialogActionButton[] = [];

  filterForm: FormGroup;

  constructor(
    dialogRef: MatDialogRef<SipPhoneLogModal>,
    public readonly configManager: ConfigManager,
    @Inject(MAT_DIALOG_DATA) public readonly data,
    public readonly messageService: MessageService,
    dateAdapter: DateAdapter<Date>,
    private readonly fb: FormBuilder,
  ) {
    super(dialogRef);
    this.dateAdapter = dateAdapter;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initForm();
    // const now = localNow();
    // this.filters = {
    //   start_dt: now.minus({ days: 7 }).toJSDate(),
    //   end_dt: now.toJSDate(),
    //   in_out: '',
    //   phone_number: '',
    //   sip_phone_id: this.data.sipPhoneId
    // };
  }

  initForm(): void {
    const today = localNow();
    this.filterForm = this.fb.group({
      start_dt: [today.minus({ days: 7 }).toJSDate(), Validators.required],
      end_dt: [today.toJSDate(), Validators.required],
      in_out: [''],
      phone_number: ['', [PhoneNumberValidated()]],
    }, { validator: FlexDatepickerMin('end_dt', 'start_dt') });
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilter = (d: Date): boolean => {
    const startDate = this.filterForm.get('start_dt').value;
    if (!d || !startDate) {
      return true;
    }

    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.reloadClick();
    });
  }

  private getFilters(): {
    start_dt: Date,
    end_dt: Date,
    in_out: string,
    phone_number: string,
    sip_phone_id: number
  } {
    const formValue = this.filterForm.value;
    return {
      start_dt: formValue.start_dt,
      end_dt: formValue.end_dt,
      in_out: formValue.in_out,
      phone_number: formValue.phone_number,
      sip_phone_id: this.data.sipPhoneId
    };
  }

  reloadClick(): void {
    if (this.filterForm.invalid) {
      this.showError('form.validator.data_invalid');
      return;
    }
    // const newFilters = _.cloneDeep(this.filters);
    const newFilters: any = this.getFilters();
    newFilters.start_dt = parseDateTime(newFilters.start_dt).toISODate();
    newFilters.end_dt = parseDateTime(newFilters.end_dt).toISODate();
    this.messageService.broadcastDatasource('pbx_cdr_sipphone', newFilters);
  }
}
