import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { EditingComponent, ToastService } from '@wephone-utils';
import * as _ from 'lodash';
import { _tk, _ti } from '@wephone-translation';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { Router } from '@angular/router';
import { IvrEvaluationService } from '@wephone-core/service/ivr_evaluation.service';
import { CreateUpdateIvrEvaluationComponent } from '../create-update-ivr-evaluation/create-update-ivr-evaluation.component';

@Component({
  selector: 'edit-ivr-evaluation',
  templateUrl: './edit-ivr-evaluation.component.html',
  styleUrls: ['./edit-ivr-evaluation.component.scss']
})
export class IvrEvaluationEditComponent extends EditingComponent implements OnInit {
  @ViewChild('createUpdateIvrEvaluationComponent') createUpdateIvrEvaluationComponent: CreateUpdateIvrEvaluationComponent;
  @Input() editingItem: IvrEvaluationEntity;

  ivrEvaluation: IvrEvaluationEntity;
  usedQueues: CallQueueEntity[] = [];

  readonly steps = {
    general: 'ivr_evaluation.content.general',
    inused: 'ivr_evaluation.content.using',
  };

  constructor(
    public readonly toast: ToastService,
    private readonly router: Router,
    private readonly ivrEvaluationService: IvrEvaluationService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.ivrEvaluation = this.editingItem;
    this.usedQueues = this.ivrEvaluationService.getUsedQueuesOfIvrEvaluations([this.ivrEvaluation.id]);
  }

  get stepKeys(): string[] {
    return Object.keys(this.steps);
  }

  async submitForm(): Promise<any> {
    if (!this.createUpdateIvrEvaluationComponent) {
      return;
    }

    this.createUpdateIvrEvaluationComponent.submitForm();
  }

  onFormValueChanged($event: boolean): void {
    this.formValueChanges.next($event);
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    if (!this.createUpdateIvrEvaluationComponent) {
      return;
    }

    this.createUpdateIvrEvaluationComponent.resetForm(options);
    this.formValueChanges.next(false);
  }

  onCreatedIvrEvaluation($event: IvrEvaluationEntity): void {
    if ($event) {
      this.ivrEvaluation = $event;
      this.resetForm();
    }
  }

  gotoPageQueue(queue: CallQueueEntity): void {
    this.router.navigateByUrl(`/queues/${queue.id}`);
  }
}
