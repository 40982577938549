import { Entity, datafield } from '@wephone-core/model/model';
import { DateTime } from 'luxon';
import { parseDateTime } from '@wephone-utils';
import { DidRepository } from '@wephone-core/model/repository/did';
import { DidEntity } from './did';
import * as _ from 'lodash';

export class DidOrderEntity extends Entity {
  public static object_type_id = 'did_order';
  public static STATE_NOT_PAID = 0;
  public static STATE_PAID = 1;
  public static STATE_EXPIRED = 2;

  @datafield
  public token: string;
  @datafield
  public owner_id: number;
  @datafield
  public apikey: string;
  @datafield
  public expire_dt: any;
  @datafield
  public state: number;
  public did_ids: number[];

  get dids_ordered(): DidEntity[] {
    if (!_.isEmpty(this.did_ids)) {
      const didRepo = DidRepository.getInstance<DidRepository>();
      const dids: DidEntity[] = [];
      for (const i of didRepo.getObjectList()) {
        if (this.did_ids.indexOf(i.id) >= 0) {
          dids.push(i);
        }
      }
      return dids;
    }
  }

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    this.expire_dt = this.expire_dt && parseDateTime(this.expire_dt);
  }

  get is_expired(): boolean {
    if (!this.expire_dt) {
      return true;
    }

    return DateTime.utc() > this.expire_dt;
  }
}
