<ng-template #tplListFilter>
  <form [formGroup]="filterForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
    fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
    <div>
      <flex-search-input formControlName="filterValue" placeholder="{{ 'public.search' | translate }}"
        [hintText]="'ivrmodule.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>

<flex-crud-page #flexCrud *ngIf="is_ready" pageTitle="{{ 'menu.manage_ivrmodule'|translate }}"
  [filterTemplate]="tplListFilter" [tableConfig]="tableConfig" [dataSource]="dataSource" [columnTemplates]="{}"
  [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">

</flex-crud-page>
