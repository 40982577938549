import { NgModule, ModuleWithProviders } from '@angular/core';
import { WephoneUtilsModule } from '@wephone-utils';

import { WephoneCoreModule } from '@wephone-core/wephone-core.module';
import { ConnectivityService } from './service';

@NgModule({
  imports: [
    WephoneUtilsModule,
    WephoneCoreModule
  ],
  exports: [
  ],
  declarations: [
    
  ],
  entryComponents: [

  ],
  providers: [
  ]
})
export class WephoneCoreUIModule {
  static forRoot(): ModuleWithProviders<WephoneCoreUIModule> {
    return {
      ngModule: WephoneCoreUIModule,
      providers: [
        ConnectivityService
      ],
    };
  }
}
