import { Injectable } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { MessageService } from './message.service';

@Injectable()
export class WindowService {

    constructor(
            private electron: ElectronService,
            private messageService: MessageService
        ) {
    }

    reloadMainWindow(): void {
        if (this.electron.isElectronApp) {
            this.electron.ipcRenderer.send('mainwindow:reload');
        } else {
            window.location.reload(false);
        }
    }

    bringToFront(): void {
        if (this.electron.isElectronApp) {
            this.electron.ipcRenderer.send('mainwindow:bring_to_front');
        }
    }
}
