<div class="mini-block">
  <div class="flag">
    <span class="flag-icon maticon">
      <mat-icon>calendar_today</mat-icon>
    </span>
  </div>
  <div class="number-call">
    <span class="name font-weight-bold" [matTooltip]="calendar.name" matTooltipPosition="above">
      {{ calendar.name | truncate:15 }}
    </span>
    <!-- <span class="number" [matTooltip]="'opening_hour_calendar.content.announcement_message'|translate">
      {{ 'opening_hour_calendar.content.announcement_message'|translate | truncate:15 }}
    </span> -->
  </div>
</div>
