import { datafield, Entity } from '@wephone-core/model/model';
import { Deferred } from 'ts-deferred';
import { DidRepository } from '@wephone-core/model/repository/did';
import { DidEntity } from '@wephone-core/model/entity/did';
import { CallQueueEntity } from './callqueue';
import { CallQueueRepository } from '../repository/callqueue';

export enum CallRecordingState {
  NO_RECORDING = 0,
  PAUSED = 1,
  ACTIVE = 2
}

export class MyActiveCall extends Entity {
  public static object_type_id = 'my_active_call';
  private readonly d_hangup: Deferred<MyActiveCall>;
  private readonly d_answer: Deferred<MyActiveCall>;
  public is_answered: boolean;

  public id: number;
  @datafield public user_call_id: number;
  @datafield public peer_call_id: number;
  @datafield public enterprise_id: number;
  @datafield public user_id: number;
  @datafield public did_id: number;
  @datafield public remote_number: string;
  @datafield public local_number: string;
  @datafield public client_name: string;
  @datafield public start_time: any;
  @datafield public answer_time: any;
  @datafield public outbound: number;
  @datafield public onhold_since: any;
  @datafield public transfer_state: number;
  @datafield public group_id: number;
  @datafield public call_queue_id: number;
  @datafield public call_queue_item_id: number;
  @datafield public call_queue_session_id: number;
  @datafield public sip_call_id: string;
  @datafield public client_public_call_id: string;
  @datafield public outcall_campaign_id: number;
  @datafield public recording_state: CallRecordingState;
  @datafield public transfer_in_type: number;
  @datafield public transfer_in_user_id: number;
  @datafield public transfer_in_reason: string;
  @datafield public crm_id: number;
  @datafield public crm_contact_id: string;
  @datafield public crm_contact_type: number;
  @datafield public qualification: number[];
  @datafield public qualification_comment: string;
  @datafield public is_bridged: number;

  is_hungup: boolean;
  did: DidEntity;

  private _queue: CallQueueEntity;

  constructor() {
    super();
    this.d_hangup = new Deferred<MyActiveCall>();
    this.d_answer = new Deferred<MyActiveCall>();
    this.is_answered = false;
    this.is_hungup = false;
  }

  public setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    if (!this.is_answered && this.answer_time) {
      this.is_answered = true;
      this.d_answer.resolve(this);
    }
  }

  public fetchRelatedData(): void {
    this.did = DidRepository.getInstance<DidRepository>().getDidByNumber(this.local_number);
    this._queue = undefined;
    if (this.call_queue_id) {
      this._queue = CallQueueRepository.getInstance<CallQueueRepository>().getObjectById(this.call_queue_id);
    }
  }

  public isHungup(): boolean {
    return this.is_hungup;
  }

  public onHangup(): void {
    this.is_hungup = true;
    this.d_hangup.resolve();
  }

  public watchHangup(callback: (val: MyActiveCall) => any): Promise<any> {
    return this.d_hangup.promise.then(callback);
  }

  public watchAnswer(callback: (val: MyActiveCall) => any): Promise<any> {
    return this.d_answer.promise.then(callback);
  }

  get queue(): CallQueueEntity {
    return this._queue;
  }

  getContext(): string {
    // return (this.queue && this.queue.queue_name) || (this.did && this.did.name);
    return this.did && this.did.name;
  }

  getWelcomeMessage(): string {
    return this.did && this.did.welcome_message;
  }
}
