import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'render-inout-column',
  template: `<span [ngClass]="params.value==1?'into-direction':'out-direction'">{{ (params.value==1?'cdr.outgoing':'cdr.incoming') | translate }}</span>`,
  styleUrls: ['./render-inout-column.scss']
})
export class RenderInoutColumn implements ICellRendererAngularComp {
  params: any;

  constructor(
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}