import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ElementRef, Optional } from '@angular/core';
import { FlexMatInputBase } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'object-condition-select',
  templateUrl: './object-condition-select.html',
  styleUrls: ['./object-condition-select.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ObjectConditionSelect }]
})
export class ObjectConditionSelect extends FlexMatInputBase implements OnInit, MatFormFieldControl<any> {

  objectConditionFrom: FormGroup;

  constructor(
    elRef: ElementRef,
    @Optional()
    ngControl: NgControl,
    private fb: FormBuilder,
  ) {
    super(ngControl, elRef);

    this.objectConditionFrom = this.fb.group({
      field: [],
      op: [],
      operand: [],
    });
  }

  ngOnInit(): void {
    this.objectConditionFrom.valueChanges.pipe(
      takeUntil(this._onDestroy)
    )
      .subscribe(values => {
        let objectCondition: any = {};
        if (this.value) {
          objectCondition = _.cloneDeep(this.value);
        }

        objectCondition.field = values && values['field'] || undefined;
        objectCondition.op = values && values['op'] || undefined;
        objectCondition.operand = values && values['operand'] || undefined;
        this.value = objectCondition;
      });
  }

  setFormData(val: any): void {
    this.fieldControl.setValue(val && val.field || undefined, { emitEvent: false });
    this.opControl.setValue(val && val.op || undefined, { emitEvent: false });
    this.operandControl.setValue(val && val.operand || undefined, { emitEvent: false });
  }

  get controlType(): string {
    return 'object-condition-select';
  }

  get fieldControl(): FormControl {
    return this.objectConditionFrom.get('field') as FormControl;
  }

  get opControl(): FormControl {
    return this.objectConditionFrom.get('op') as FormControl;
  }

  get operandControl(): FormControl {
    return this.objectConditionFrom.get('operand') as FormControl;
  }

  writeValue(value: any): void {
    const _value = value && _.cloneDeep(value) || undefined;
    this.setFormData(_value);

    super.writeValue(_value);
  }
}
