<!-- <h2 mat-dialog-title>
  {{ 'campaign.contact.import_contact' | translate }}
</h2>

<mat-dialog-content> -->
  <div fxLayout="column" *ngIf="step==='upload'">
    <div fxLayout="row">
      <div>
        <span class="mr-1">{{ 'public.message.download_sample_file'|translate }}</span>
        <button type="button" mat-icon-button (click)="downloadSampleFile()">
          <mat-icon attr.aria-label="{{ 'public.message.download_sample_file'|translate }}">save</mat-icon>
        </button>
      </div>
    </div>

    <div fxLayout="row" class="my-1">
      <div fxFlex>
        <button (click)="fileInput.click()" mat-raised-button color="primary">{{'public.upload'|translate}}</button>
        <span class="ma-1" *ngIf="importFile;else noFileImport">{{importFile.name}}</span>
        <ng-template #noFileImport><span class="ma-1">{{ 'fileentry.error.no_file_selected'|translate }}</span></ng-template>
        <input #fileInput type="file" [accept]="fileSettings.extensions|extensionAccept" style="display:none" (change)="importCsv($event)">
      </div>
    </div>
    
    <ng-container *ngIf="dataContact">
      <div fxLayout="row">
        <span>{{ 'Line number' }}: &nbsp;<strong>{{ dataContact.line }}</strong></span>
      </div>
      <div fxLayout="row">
        <table class="mat-table full-width">
          <thead>
            <tr class="mat-header-row">
              <th class="mat-header-cell" *ngFor="let column of dataContact.columns">{{column.label}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="mat-row" *ngFor="let data of dataContact.data">
              <td class="mat-cell" *ngFor="let cell of data">{{cell}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

  </div>

  <div fxLayout="row" fxLayoutGap="10px" *ngIf="step==='import'">
    <div fxFlex>
      <h3 class="text-center">{{'campaign.contact.fields_mapping'|translate}}</h3>
      <table class="full-width">
        <tbody>
          <tr *ngFor="let standard_field of standard_fields">
            <td>
              <span>{{ standard_field.label | translate }}
                <i *ngIf="standard_field.field=='phone_number'">*</i>
              </span>
            </td>
            <td fxLayout="column">
              <mat-form-field class="full-width">
                <mat-select [(ngModel)]="fields[standard_field.field]">
                  <mat-option *ngFor="let item of dataContact.columns" [value]="item.index">{{ item.label }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="error_fields[standard_field.field]">
                  {{ error_fields[standard_field.field] }}
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div fxFlex>
      <h3 class="text-center">{{'campaign.contact.fields_not_imported'|translate}}</h3>
      <mat-list>
        <mat-list-item *ngFor="let item of fieldContacts">
          <h3 matLine class="text-center">{{ item.label }}</h3>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
<!-- </mat-dialog-content>

<mat-dialog-actions fxLayout="row">
  <button mat-raised-button mat-dialog-close color="warn">{{ 'public.cancel' | translate }}</button>
  <span fxFlex></span>
  <button mat-raised-button color="primary" *ngIf="fileImported" [disabled]="!getStepBack()" (click)="stepBack()">
    {{ 'public.navigation.back' | translate }}</button>
  <button mat-raised-button color="primary" [disabled]="!getStepNext()" (click)="stepNext()">
    {{ 'public.navigation.next' | translate }}</button>
</mat-dialog-actions> -->
