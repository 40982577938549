import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import {
  DialogService,
  ToastService,
  IFlexTableSidePannelOptions,
  IFlexTableConfig,
  DynamicFilterSource,
  ParamRoutableCRUDComponent,
  FlexCRUDPageComponent,
  regexSearch,
  // localNow,
  parseDateTime,
} from '@wephone-utils';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { OutcallcampaignEditComponent } from '@wephone/components/outcallcampaign/outcallcampaign-edit/outcallcampaign-edit.component';
import { OutcallcampaignMultiEditComponent } from '@wephone/components/outcallcampaign/outcallcampaign-multi-edit/outcallcampaign-multi-edit.component';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DateShortPipe } from '@wephone-utils/pipes/date-format/date-format.pipe';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { DateTime } from 'luxon';
// import { FlexDatepickerMin } from '@wephone/services/form-validator';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { ArrayHelper } from '@wephone-core/helpers/array.helper';
import { UserEntity } from '@wephone-core/model/entity/user';
import { ArchiveStateType, CampaignActiveType } from '@wephone-core/model/repository/outcallcampaign.i';
import { OutcallCampaignState } from '@wephone-core/model/entity/outcallcampaign.i';

interface IFilterFormData {
  start_dt: DateTime;
  end_dt: DateTime;
  group_ids: number[];
  campaign_status: OutcallCampaignState;
  archived: boolean;
}

@Component({
  selector: 'app-campaign-page',
  host: { class: 'flex-page-component' },
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.scss'],
  providers: [TranslatePipe]
})
export class CampaignPageComponent extends ParamRoutableCRUDComponent implements OnInit, OnDestroy {
  // Public member
  campaignRepo = this.em.getRepository('OutcallCampaignRepository');
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: OutcallcampaignEditComponent,
    multiItemEditor: OutcallcampaignMultiEditComponent,
  };

  labelStatus: { [keys: number]: string } = {};
  filterString: string;
  form: FormGroup;
  filteredQueueIds: number[];
  filteredGroupIds: number[];
  filtersReadonly = true;
  filterForm: FormGroup;
  currentUser: UserEntity;
  campaignStatusList: OutcallCampaignState[] = ArrayHelper.getEnumValues(OutcallCampaignState).filter(i => i !== OutcallCampaignState.NOT_PROGRESS);

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly em: EntityManager,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    public translate: TranslateService,
    public dialogService: DialogService,
    public toast: ToastService,
    public translatePipe: TranslatePipe,
    private readonly authService: AuthenticationService,
    private readonly configManager: ConfigManager,
    // private readonly permissionService: PermissionService,
  ) {
    super();
    const dataSource = this.campaignRepo.dataSource<OutcallCampaignEntity>();
    this.dataSource = new DynamicFilterSource(dataSource, this.filterFunc);
    // This is optional. Use this for custom row search only
    this.labelStatus[OutcallCampaignState.FINISHED] = _ti('campaign.finished');
    this.labelStatus[OutcallCampaignState.IN_PROGRESS] = _ti('campaign.in_progress');
    // this.labelStatus[OutcallCampaignState.NOT_PROGRESS] = _ti('campaign.not_progress');
    this.labelStatus[OutcallCampaignState.ARCHIVED] = _ti('campaign.archived');
    this.labelStatus[OutcallCampaignState.NOT_READY] = _ti('campaign.not_ready');
    this.labelStatus[OutcallCampaignState.READY] = _ti('campaign.ready');

    this.tableConfig = {
      enableFilter: false,
      enableExportCsv: false,
      rowClass: this.getRowClass,
      columns: [
        {
          name: 'name',
          label: _tk('campaign.name'),
          enableTooltip: true,
          lineClamp: 2,
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (campaign: OutcallCampaignEntity) => {
            return campaign.name.toLowerCase();
          }
        },
        {
          name: 'status',
          label: _tk('campaign.status'),
          searchable: true,
          sortable: true,
          exportedValue: (item: OutcallCampaignEntity) => {
            return this.labelStatus[item.campaign_status] || '';
          },
          customSortFn: (campaign: OutcallCampaignEntity) => {
            return this.labelStatus[campaign.campaign_status];
          }
        },
        {
          name: 'schedule_start_date',
          label: _tk('campaign.schedule_start_dt'),
          searchable: true,
          sortable: true,
          exportedValue: (item: OutcallCampaignEntity) => {
            return item.schedule_start_date && item.schedule_start_date.toFormat('yyyy-MM-dd') || '';
          },
        },
        {
          name: 'schedule_end_date',
          label: _tk('campaign.schedule_end_dt'),
          searchable: true,
          sortable: true,
          exportedValue: (item: OutcallCampaignEntity) => {
            return item.schedule_end_date && item.schedule_end_date.toFormat('yyyy-MM-dd') || '';
          },
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected

        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.delete(data.selectedItems);
              }
            }
          ],
          secondary: [
            // Visible action buttons when some item is selected and user click on the button "..."
            {
              icon: 'play_arrow',
              hint: _tk('campaign.active_campaign'),
              callback: data => {
                console.log('Disable button clicked');

                return this.enable(data.selectedItems);
              }
            },
            {
              icon: 'pause_circle_filled',
              hint: _tk('campaign.deactive_campaign'),
              callback: data => {
                console.log('Disable button clicked');

                return this.disable(data.selectedItems);
              }
            },
            // {
            //   icon: 'loop',
            //   hint: _tk('public.reset'),
            //   callback: data => {
            //     console.log('Reset button clicked');

            //     return this.resetItem(data.selectedItems);
            //   }
            // },
            {
              icon: 'archive',
              hint: _tk('public.archive'),
              callback: data => {
                console.log('Disable button clicked');

                return this.archive(data.selectedItems);
              }
            },
            {
              icon: 'unarchive',
              hint: _tk('public.unarchive'),
              callback: data => {
                console.log('Disable button clicked');

                return this.unarchive(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    if (this.configManager.hasFeature('SERVICE_GROUP')) {
      const groupColumn = {
        name: 'group_name',
        label: _tk('call_queue.content.group'),
        searchable: true,
        sortable: true,
        customSortFn: this.sortGroupName,
        exportedValue: (item: OutcallCampaignEntity) => {
          return item.queue && item.queue.group && item.queue.group.name || '';
        },
      };
      this.tableConfig.columns.splice(1, 0, groupColumn);
    }

    this.form = this.fb.group({
      filterString: [this.filterString]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(this.onFormValueChange)
    );
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    const archived = this.activatedRoute.snapshot.queryParams['archived'] ? true : false;

    this.filterForm = this.fb.group({
      start_dt: [],
      end_dt: [],
      group_ids: [[]],
      campaign_status: [],
      archived: [archived],
    });

    this.addSubscription(
      this.activatedRoute.queryParams.subscribe(queryParams => {
        const _archived = queryParams['archived'] ? true : false;
        this.filterForm.get('archived').setValue(_archived);
        this.applyFilter();
      })
    );

    this.addSubscription(
      this.filterForm.valueChanges.subscribe(val => {
        const startDt = this.filterForm.get('start_dt').value;
        const endDt = this.filterForm.get('end_dt').value;
        if (startDt && endDt) {
          const startDate = parseDateTime(startDt).startOf('day');
          const endDate = parseDateTime(endDt).endOf('day');
          if (startDate > endDate) {
            this.filterForm.get('end_dt').setErrors({ flexDatepickerMin: true });
          }
        }
      })
    );

    await this.resolveData();
    this.applyFilter();
  }

  async resolveData(): Promise<any> {
    this.currentUser = this.authService.getUser();
    const isAdmin: boolean = this.currentUser.isAdmin();
    if (isAdmin || this.currentUser.isSupervisor()) {
      this.tableConfig.listActions.defaultActions = [
        {
          id: 'add',
          icon: 'add',
          callback: () => {
            return this.createNew();
          }
        }
      ];
    }

    if (!isAdmin) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      this.filteredQueueIds = myProfile.managed_queue_ids;
      this.filteredGroupIds = myProfile.managed_group_ids;
    }
  }

  sortGroupName = (item: OutcallCampaignEntity): any => {
    return item.queue && item.queue.group && item.queue.group.name || '';
  }

  getRowClass = (row: any): string => {
    return !row.active ? 'mat-text-muted' : '';
  }

  filterFunc = (item: OutcallCampaignEntity): boolean => {
    // Check group permission and queue permission
    if (this.currentUser && !this.currentUser.isAdmin() && !item.isOwner(this.currentUser)
      && (
        _.isEmpty(this.filteredQueueIds) ||
        !item.queue_id ||
        !_.isEmpty(this.filteredQueueIds) && item.queue_id && !_.includes(this.filteredQueueIds, item.queue_id)
      ) && (
        _.isEmpty(this.filteredGroupIds) ||
        _.isEmpty(item.group) ||
        !_.isEmpty(this.filteredGroupIds) && item.group && !_.includes(this.filteredGroupIds, item.group.id)
      )
    ) {
      return false;
    }

    if (!this.filterPredicate(item)) {
      return false;
    }

    const filterValue: string = (this.filterString || '').trim();
    if (!filterValue) {
      return true;
    }

    const dateShortPipe = new DateShortPipe();
    const scheduleStartDate = dateShortPipe.transform(item.schedule_start_date);
    const scheduleEndDate = dateShortPipe.transform(item.schedule_end_date);

    return regexSearch(item.name, filterValue) ||
      regexSearch(this.labelStatus[item.campaign_status], filterValue) ||
      (scheduleStartDate && regexSearch(scheduleStartDate, filterValue)) ||
      (scheduleEndDate && regexSearch(scheduleEndDate, filterValue))
      // || item.queue && item.queue.group && regexSearch(item.queue.group.name, filterValue)
      ;
  }

  filterPredicate(item: OutcallCampaignEntity): boolean {
    const filterFormValue: IFilterFormData = this.getFilterFormValue();

    if (
      !item.schedule_start_date && (filterFormValue.start_dt || filterFormValue.end_dt) ||
      filterFormValue.start_dt && item.schedule_start_date < filterFormValue.start_dt ||
      filterFormValue.end_dt && item.schedule_start_date > filterFormValue.end_dt ||
      filterFormValue.campaign_status && item.campaign_status !== filterFormValue.campaign_status ||
      filterFormValue.group_ids.length && (!item.queue.group_id || !_.includes(filterFormValue.group_ids, item.queue.group_id)) ||
      filterFormValue.archived !== _.includes([OutcallCampaignState.ARCHIVED, OutcallCampaignState.FINISHED], item.campaign_status)
    ) {
      return false;
    }

    return true;
  }

  private readonly onFormValueChange = (formValues: { filterString: string }) => {
    this.applyFilter();
  }

  private getFilterFormValue(): IFilterFormData {
    const filterValue = this.filterForm && this.filterForm.value || {};
    const startDate = filterValue.start_dt && parseDateTime(filterValue.start_dt).startOf('day') || undefined;
    const endDate = filterValue.end_dt && parseDateTime(filterValue.end_dt).endOf('day') || undefined;
    const groupIds = filterValue.group_ids || [];
    const archived = !!filterValue.archived;
    let campaignStatus = filterValue.campaign_status;
    if (!campaignStatus || !_.includes(this.campaignStatusFilteredList, campaignStatus)) {
      campaignStatus = undefined;
    }

    return {
      start_dt: startDate,
      end_dt: endDate,
      group_ids: groupIds,
      campaign_status: campaignStatus,
      archived,
    };
  }

  applyFilter(): void {
    if (!this.filterForm && this.filterForm.invalid) {
      console.warn('Filter data not valid');
      return undefined;
    }

    const formValues = this.form.value;
    this.filterString = formValues.filterString;
    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
    this.filtersReadonly = true;
  }

  enable(items: OutcallCampaignEntity[]): Promise<any> {
    return this.changeActiveState(items, CampaignActiveType.Active);
  }

  disable(items: OutcallCampaignEntity[]): Promise<any> {
    return this.changeActiveState(items, CampaignActiveType.Deactive);
  }

  unarchive(items: OutcallCampaignEntity[]): Promise<any> {
    return this.updateArchives(items, ArchiveStateType.Unarchived);
  }

  archive(items: OutcallCampaignEntity[]): Promise<any> {
    const inprogressCampaigns = items.filter(i => i.campaign_status === OutcallCampaignState.IN_PROGRESS);
    if (inprogressCampaigns.length) {
      return this.dialogService.confirmDialog(
        _ti('dialogs.confirmation'),
        _ti('campaign.message.confirm_archive_multi', { campaigns: inprogressCampaigns.map(i => i.name).join(',') }),
        async () => {
          return this.updateArchives(items, ArchiveStateType.Archived);
        }
      );
    }
    return this.updateArchives(items, ArchiveStateType.Archived);
  }

  private async updateArchives(items: OutcallCampaignEntity[], archive: ArchiveStateType): Promise<any> {
    try {
      const inprogressCampaigns = items.filter(i => i.campaign_status === OutcallCampaignState.IN_PROGRESS);
      if (inprogressCampaigns.length) {
        await this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository').changeActiveState(inprogressCampaigns, CampaignActiveType.Deactive);
      }
      await this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository').bulkArchive(items, archive);
      this.toast.showSuccess(_ti('public.message.archive_success'));
    } catch (e) {
      console.error('Update Archive Error', e);
      const msg = e && e.error && e.error.message || _ti('public.message.archive_failure');
      this.toast.showError(msg);
      return false;
    }
  }

  private async changeActiveState(items: OutcallCampaignEntity[], state: CampaignActiveType): Promise<any> {
    try {
      let validItems = _.cloneDeep(items);
      let invalidItems: OutcallCampaignEntity[] = [];
      let invalidMessage = '';
      if (state === CampaignActiveType.Active) {
        invalidItems = items.filter(x => !x.is_valid_activated);
      }

      let message = '';
      if (validItems.length) {
        const response: { items: any[] } =
          await (this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository')).changeActiveState(validItems, state);

        if (response && response.items && response.items.length) {
          message = state ? _ti('public.message.enable_success') : _ti('public.message.disable_success');
        }
        invalidItems = validItems.filter(x => !_.includes((response && response.items || []).map(i => i.id), x.id));
      }

      if (invalidItems.length) {
        validItems = items.filter(x => !_.includes(invalidItems, x));
        invalidMessage = _ti('campaign.validator.active_failure', { number: `${invalidItems.length}/${items.length}` });
      }

      if (invalidMessage) {
        this.toast.showWarning(message ? `${message}. ${invalidMessage}` : invalidMessage);
      } else {
        this.toast.showSuccess(message);
      }
    } catch (e) {
      console.error('Activate/Deactivate Campaign error', e);
      const msg = e && e.error && e.error.message || _ti('public.message.enable_failure');
      this.toast.showError(msg);
    }
  }

  async createNew(): Promise<void> {
    try {
      const res: { object_id: number } = await this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository').createAndSave();
      if (res && res.object_id) {
        this.toast.showSuccess(_ti('public.message.create_success'));
        this.router.navigate(['out-call-campaigns', res.object_id], { skipLocationChange: false });
      }
    } catch (e) {
      console.error('Create campaign failure', e);
      const msg = e && e.error && e.error.message || _ti('public.message.create_failure');
      this.toast.showError(msg);
    }
  }

  delete(items: OutcallCampaignEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        try {
          await this.em.getRepository('OutcallCampaignRepository').bulkDelete(items);
          this.toast.showSuccess(_ti('public.message.delete_success'));
          this.flexCrud.closeSidePanel();
        } catch (e) {
          console.error('Delete campaign failure', e);
          const msg = e && e.error && e.error.message || _ti('public.message.delete_failure');
          this.toast.showError(msg);
        }
      }
    );
  }

  rendererGroups(groupIds: number[]): string[] {
    return GroupRepository.getInstance().getObjectList().filter(g => _.includes(groupIds, g.id)).map(g => g.name);
  }

  clearValue(event: MouseEvent, controlName: string, multiple: boolean = false): void {
    event.stopPropagation();
    this.filterForm.get(controlName).setValue(multiple ? [] : null);
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilter = (d: Date): boolean => {
    const startDate = this.filterForm.get('start_dt').value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  get campaignStatusFilteredList(): OutcallCampaignState[] {
    const archived = this.filterForm && !!this.filterForm.get('archived').value || false;
    return this.campaignStatusList.filter(i => {
      return archived === _.includes([OutcallCampaignState.ARCHIVED, OutcallCampaignState.FINISHED], i);
    });
  }

  hasFilterByCampaignStatus(): boolean {
    const campaignStatus = this.filterForm.get('campaign_status').value;
    if (campaignStatus === undefined || campaignStatus === null) {
      return false;
    }
    return _.includes(this.campaignStatusFilteredList, campaignStatus);
  }
}
