import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import * as _ from 'lodash';

export class SipPhoneProvisioningTokenRepository extends BaseRepository {
  ENTITY_CLASS = SipPhoneProvisioningTokenEntity;
  protected URL_PREFIX = 'sip-phone-provisioning-token';
  private readonly METHOD_PATH_LIST = 'list';
  private readonly METHOD_PATH_GET_PHONE_CONFIG_ACCESS_TOKENS = 'get_phone_config_access_tokens/_ID_';
  private readonly METHOD_PATH_GET_DEFAULT_TEMPLATE_CONFIG = 'get_default_template_config/_ID_';
  private readonly METHOD_PATH_CLEAR_TEMPLATE_CONFIG = 'clear_template_config';

  private last_filters: any = null;

  protected getURLAction(action: string): string {
    switch (action) {
      case 'LIST':
        return this.getUrlV2(this.METHOD_PATH_LIST);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      case 'GET_PHONE_CONFIG_ACCESS_TOKENS':
        return this.getUrlV2(this.METHOD_PATH_GET_PHONE_CONFIG_ACCESS_TOKENS);
      case 'GET_DEFAULT_TEMPLATE_CONFIG':
        return this.getUrlV2(this.METHOD_PATH_GET_DEFAULT_TEMPLATE_CONFIG);
      case 'CLEAR_TEMPLATE_CONFIG':
        return this.getUrlV2(this.METHOD_PATH_CLEAR_TEMPLATE_CONFIG);
      default:
        return super.getURLAction(action);
    }
  }

  protected getRemoteData(use_cache = true, filters?: any): Promise<any[]> {
    const period: string = !(!filters || _.isEmpty(filters) || _.isNull(filters)) ? filters : 'all';
    const url = this.getURLAction('LIST');

    if (use_cache && (this.object_list || []).length && this.last_filters === period) {
      return Promise.resolve(this.object_list);
    }

    return HttpEngine.getInstance().get(url, { period }).then((updated_list: any) => {
      // update list
      const object_list_data: any[] = [];
      for (const new_obj_data of updated_list) {
        const item = _.find(object_list_data, { id: new_obj_data.id });
        if (item) {
          item.setObjectData(new_obj_data);
          continue;
        }
        // create new object
        const new_enterprise_crm_obj = this.create();
        new_enterprise_crm_obj.setObjectData(new_obj_data, false);
        object_list_data.push(new_enterprise_crm_obj);
      }
      this.last_filters = period;
      this.setDataAsReady();
      this.setObjectList(object_list_data);

      return this.object_list;
    });
  }

  getPhoneConfigAccessTokensById(id: number): Promise<any[]> {
    const url = this.getURLAction('GET_PHONE_CONFIG_ACCESS_TOKENS').replace('_ID_', `${id}`);
    return HttpEngine.getInstance().get(url);
  }

  getDefaultTemplateConfig(id: number): Promise<any> {
    const url = this.getURLAction('GET_DEFAULT_TEMPLATE_CONFIG').replace('_ID_', `${id}`);
    return HttpEngine.getInstance().get(url);
  }

  clearTemplateConfig(id: number): Promise<any> {
    const url = this.getURLAction('CLEAR_TEMPLATE_CONFIG');
    return HttpEngine.getInstance().post(url, { id });
  }
}

import { SipPhoneProvisioningTokenEntity } from '@wephone-core/model/entity/sip_phone_provisioning_token';
import { HttpEngine } from '@wephone-core/service/http_engine';
