<form [formGroup]="form">
  <div fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>{{ 'call_queue.content.didroute.did_number'|translate }}</mat-label>
      <input matInput formControlName="number" placeholder="{{ 'call_queue.content.didroute.did_number'|translate }}"
        [readonly]="true">

      <mat-error [flexFormControlError]="form.get('number')"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'did.content.name'|translate }}</mat-label>
      <input matInput formControlName="name" placeholder="{{ 'did.content.name'|translate }}"
        disabled="true">

      <mat-error [flexFormControlError]="form.get('name')"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'call_queue.content.didroute.welcome_message'|translate }}</mat-label>
      <input matInput formControlName="welcome_message" placeholder="{{ 'call_queue.content.didroute.welcome_message'|translate }}"
        disabled="true">
        
      <mat-error [flexFormControlError]="form.get('welcome_message')"></mat-error>
    </mat-form-field>
    <div fxFlex fxLayout="column">
      <mat-form-field>
        <mat-label>{{ 'call_queue.content.didroute.priority'|translate }}</mat-label>
        <mat-select formControlName="route_priority" placeholder="{{ 'call_queue.content.didroute.priority'|translate }}">
          <mat-option *ngFor="let priority of priorityList" [value]="priority.value">{{ priority.label }}</mat-option>
        </mat-select>
        
        <mat-error [flexFormControlError]="routePriorityControl"></mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
