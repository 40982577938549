<div fxLayout="column" fxFill>
  <ng-template #tplListFilter>
    <form [formGroup]="form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start"
      fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterString"
          [hintText]="'ivrmenu.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud [dataSource]="dataSource" [tableConfig]="tableConfig"
    [columnTemplates]="{'name': tplName, 'sound_id': tplPlayer}" pageTitle="{{ 'menu.manage_ivrmenu'|translate }}"
    [sidePannelOptions]="sidePannelOptions" [filterTemplate]="tplListFilter" fxLayout="row" fxFlex="grow">
    <ng-template #tplName let-ivrmenu="row">
      <span class="ivrmenu-name" [ngClass]="{'is-configured': ivrmenu.isConfigured}">{{ ivrmenu.name }}</span>
      <span *ngIf="ivrmenu.routing_error">&nbsp;<mat-icon class="mat-text-warn"
          [matTooltip]="ivrmenu.routing_error|translate">warning</mat-icon></span>
    </ng-template>
    <ng-template #tplPlayer let-ivrmenu="row">
      <div (click)="notOpenEditor($event)" *ngIf="ivrmenu.sound_message">
        <fileentry-player-button [filePublicId]="ivrmenu.sound_message.public_id" playerId="main"
          [disabled]="!ivrmenu.sound_message.ready_for_playing"></fileentry-player-button>
        <span>{{ ivrmenu.sound_message.name }}</span>
      </div>
    </ng-template>
  </flex-crud-page>
</div>
