export const PHONE_MODELS = [
  {
    label: 'Grandstream GRP26xx',
    value: 'grandstream_grp26xx',
  },
  {
    label: 'Grandstream WP820',
    value: 'grandstream_wp820',
  },
  {
    label: 'Grandstream GXP17xx',
    value: 'grandstream_gxp17xx',
  },
  {
    label: 'Yealink T42x',
    value: 'yealink_t42x',
  },
  {
    label: 'Yealink T33G',
    value: 'yealink_t33g',
  },
  {
    label: 'Yealink T53W',
    value: 'yealink_t53w',
  },
  {
    label: 'Yealink T54W',
    value: 'yealink_t54w',
  },
  {
    label: 'Linkvil W611W',
    value: 'linkvil_w611w',
  },
  {
    label: 'SNOM HD100',
    value: 'snom_hd100',
  },
];
