import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, NoWhitespaceValidator, ToastService } from '@wephone-utils';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { FormBuilder, Validators } from '@angular/forms';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'ivr-module-edit',
  templateUrl: './ivr-module-edit.component.html',
  styleUrls: [ './ivr-module-edit.component.scss' ]
})
export class IvrModuleEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: IvrScriptEntity;

  ivrModule: IvrScriptEntity;
  ivrModuleRepo: IvrScriptRepository;
  ivrModuleTypes: number[] = [ IvrScriptEntity.SCRIPT_TYPE_JSON, IvrScriptEntity.SCRIPT_TYPE_VXML ];
  ivrModuleTypeLabels: {[keys: number]: string} = {
    [IvrScriptEntity.SCRIPT_TYPE_JSON]: 'Json',
    [IvrScriptEntity.SCRIPT_TYPE_VXML]: 'Vxml',
  };

  constructor(public toast: ToastService, private fb: FormBuilder, private em: EntityManager) {
    super();
    this.ivrModuleRepo = em.getRepository<IvrScriptRepository>('IvrScriptRepository');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.ivrModule = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [ this.ivrModule.name, [Validators.required, NoWhitespaceValidator] ],
      scriptType: [ this.ivrModule.script_type ],
      scriptUrl: [ this.ivrModule.script_url ]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe((changes: any) => {
        this.onFormValueChange();
      })
    );
  }

  validate(): boolean {
    const name: string = this.form.get('name').value;
    const existName: IvrScriptEntity = this.ivrModuleRepo.getObjectList().find(x => x.name === name && 
      (!this.ivrModule.id || this.ivrModule.id && x.id !== this.ivrModule.id)
    );

    if (existName) {
      this.form.get('name').setErrors({name_exist: true});
    }

    return this.form.valid;
  }

  private getFormResetData(): any {
    return {
      name: this.ivrModule.name,
      scriptType: this.ivrModule.script_type,
      scriptUrl: this.ivrModule.script_url
    };
  }

  saveIvrModule(): Promise<any> {
    if (!this.validate()) {
      this.form.markAllAsTouched();
      return Promise.reject({ valid: false });
    }

    const updateData = {
      id: this.ivrModule.id || undefined,
      name: this.form.get('name').value,
      script_type: this.form.get('scriptType').value,
      script_url: this.form.get('scriptUrl').value
    };

    const updatedItem = new IvrScriptEntity();
    _.extend(updatedItem, updateData);

    return this.ivrModuleRepo.save(updatedItem);
  }

  async submitForm(): Promise<any> {
    try {
      const returnedData = await this.saveIvrModule();
      this.toast.showSuccess(_ti('ivr_module.message.update_success'));
      if (returnedData) {
        this.ivrModule.setObjectData(returnedData);
      }
      this.resetForm();
    } catch (e) {
      console.error('Create/Edit IVR moldule Error: ', e);
      this.toast.showErrorMessage(e, _ti('public.message.update_failure'));
    }
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  compareScriptType(a: any, b: any): boolean {
    return a.toString() === b.toString();
  }
}
