import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class WephoneTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    let translation_file = './assets/i18n/flexivr.' + lang + '.json';
    if (window['ASSETS_PREFIX']) {
      translation_file = window['ASSETS_PREFIX'] + '/assets/i18n/flexivr.' + lang + '.json';
    }
    return this.http.get(translation_file);
  }
}
