<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="payment-info pa-1">
  <div fxFlex="auto">
    <div *ngIf="myPaymentSource && myPaymentSource.source_type === 0">
      <div fxLayout="row">
        <div fxFlex="20"><b>Credit card:</b></div>
        <div fxFlex="80"><b>xxxx xxxx xxxx {{ myPaymentSource.description }}</b></div>
      </div>
      <div fxLayout="row">
        <div fxFlex="20"><b>Expires:</b></div>
        <div fxFlex="80"><b>{{ myPaymentSource.expires }}</b></div>
      </div>
    </div>
    <div *ngIf="myPaymentSource && myPaymentSource.source_type === 1">
      <div fxLayout="row">
        <div fxFlex="20"><b>SEPA:</b></div>
        <div fxFlex="80"><b>xxxxxxxxxxxxxxxxxx{{ myPaymentSource.description }}</b></div>
      </div>
      <div fxLayout="row">
        <div fxFlex="20"><b>Name:</b></div>
        <div fxFlex="80"><b>{{ myPaymentSource.name }}</b></div>
      </div>
    </div>
  </div>
  <div fxFlex="initial">
    <button data-ci="updatePaymentInfo" mat-stroked-button color="green" (click)="updatePaymentInfo()">
      {{ 'enterprise_info.update_payment_info' | translate }}
    </button>
  </div>
  <div fxFlex="initial">
    <button mat-icon-button color="warn" (click)="deletePaymentInfo()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
