import { datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { Entity } from '@wephone-core/model/model';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';

export class CallQueueAgentLinkEntity extends Entity {
  static object_type_id: string = 'agent_queue_link';

  @datafield id: number;
  @datafield call_queue_keyid: number;
  @datafield operator_keyid: number;
  @datafield position: number;
  @datafield is_backup: number;
  @datafield always_available: number;

  //position:number;
  private _agent: AgentEntity;
  private _queue: CallQueueEntity;

  constructor(queue_id, agent_id) {
    super();
    this.call_queue_keyid = queue_id;
    this.operator_keyid = agent_id;
    //this.position = this.backup_level;
  }

  get agent_id(): number {
    return this.operator_keyid;
  }
  set agent_id(val: number) {
    this.operator_keyid = val;
  }

  get queue_id(): number {
    return this.call_queue_keyid;
  }
  set queue_id(val: number) {
    this.call_queue_keyid = val;
  }

  get agent(): AgentEntity {
    if (!this._agent) {
      this._agent = AgentRepository.getInstance().getObjectById(this.agent_id);
    }
    return this._agent;
  }

  get queue(): CallQueueEntity {
    if (!this._queue) {
      this._queue = CallQueueRepository.getInstance().getObjectById(this.queue_id);
    }
    return this._queue;
  }

  /**
   * Check queue had been handled by agent
   */
  get is_handled(): boolean {
    if (!this.agent) {
      return false;
    }
    return this.agent.hasHandledQueue(this.queue);
  }

  get uneligible_queues(): CallQueueEntity[] {
    return this.agent.getUneligibleQueues(this.queue);
  }
  get uneligible_queue_names(): string[] {
    return this.uneligible_queues.map(x => x.queue_name);
  }

  get isConnected(): boolean {
    if (!this.agent) {
      return false;
    }

    return this.agent.is_online_cc;
  }
}
