<div fxFlexFill fxLayout="row" class="bg-gray pa-1 main-container full-width"
  [ngClass]="agent.user && !agent.user.enabled ? 'agent-disabled' : ''" *ngIf="agent"
  [attr.data-ci]="agent.user ? agent.user.name : ''">

  <!-- avatar -->
  <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center center" *ngIf="avatar && agent">
    <user-avatar class="user-avatar" [user]="agent.user" [uploadAvatar]="false"
      matTooltip="{{ agent.connection_time | time_since_as_text }}" width="50px" height="50px">
    </user-avatar>
  </div>

  <!-- content -->
  <div fxFlex="auto" fxLayout="column" fxLayoutAlign="center start" class="infor">
    <div class="agent-name font-weight-bold font-lg">{{ agent.name }} </div>

    <ng-container *ngIf="displayCallInfo">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <span class="icon-call-status" *ngIf="!agent.phone_enabled;else PhoneEnabled">
          <mat-icon [attr.data-ci]="'state_phone_disabled'" class="mat-text-warn"
            [matTooltip]="'agent_panel.content.phone_disabled'|translate">phone_disabled</mat-icon>
        </span>
        <ng-template #PhoneEnabled>
          <span class="icon-call-status mat-blue icon-call-out-in" [ngSwitch]="agent.inout_state">
            <mat-icon [attr.data-ci]="'state_out'" [matTooltip]="'agent_panel.content.inout_state_1'|translate"
              *ngSwitchCase="1">arrow_downward</mat-icon>
            <mat-icon [attr.data-ci]="'state_in'" [matTooltip]="'agent_panel.content.inout_state_2'|translate"
              *ngSwitchCase="2">arrow_upward</mat-icon>
            <mat-icon [attr.data-ci]="'state_none'" [matTooltip]="'agent_panel.content.inout_state_3'|translate"
              *ngSwitchCase="3">block</mat-icon>
            <mat-icon [attr.data-ci]="'state_both'" [matTooltip]="'agent_panel.content.inout_state_0'|translate"
              *ngSwitchDefault>import_export</mat-icon>
          </span>
        </ng-template>

        <!-- if in-paused -->
        <ng-container *ngIf="agent.pause_since">
          <timer-since [matTooltip]="'agent_panel.timer_since.pause'|translate" class="timer-since font-weight-bold"
            [since]="agent.pause_since"></timer-since>
        </ng-container>
        <!-- if available -->
        <ng-container *ngIf="!agent.reserved && !agent.pause_since && !agent.always_available && agent.available_since">
          <timer-since [matTooltip]="'agent_panel.timer_since.available'|translate" class="timer-since font-weight-bold"
            [since]="agent.available_since"></timer-since>
        </ng-container>
        <!-- if in-call -->
        <ng-container *ngIf="agent.reserved && agent.active_call && agent.active_call.bridged_dt">
          <timer-since [matTooltip]="'agent_panel.timer_since.reserved'|translate" class="timer-since font-weight-bold"
            [since]="agent.active_call.bridged_dt"></timer-since>
        </ng-container>
      </div>

      <ng-container *ngIf="agent.is_paused">
        <div data-ci="agent_pause_reason_name" (click)="agentChangePauseReason(agent)"
          class="agent-state bg {{ agent.agent_status_detail }}"
          [matTooltip]="(agent.pause_reason ? agent.pause_reason.label : 'agent_status.pause')|translate">
          {{ (agent.pause_reason ? agent.pause_reason.label : 'agent_status.pause')|translate }}
        </div>
      </ng-container>

      <ng-container *ngIf="agent.reserved">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          class="agent-state bg {{ agent.agent_status_detail }}"
          [matTooltip]="('agent_status.' + agent.agent_status_detail)|translate">
          <mat-icon *ngIf="agent.reserved === AgentReservedStatus.InternalReserved">social_distance</mat-icon>
          <span>{{ ('agent_status.' + agent.agent_status_detail )|translate }}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="agent.last_call_displayable && !agent.reserved">
        <div class="last-call"
          matTooltip="{{ 'livecall.agent_list.last_hangup_hint' | translate: { time: agent.last_hangup_dt.toFormat('HH:mm') } }}">
          {{'livecall.agent_list.last_hangup_at' | translate:{'time': agent.last_hangup_dt.toFormat('HH:mm:ss')} }}
        </div>
      </ng-container>

      <div *ngIf="(is_admin || is_supervisor) && has_call_assigned_to_agent">
        <button mat-raised-button color="warn" (click)="cancelAssignCallToAgent()">
          {{ 'public.cancel'|translate }}
        </button>
      </div>
    </ng-container>
  </div>

  <!-- actions -->
  <div *ngIf="activeActions.length > 0" class="menu-container" fxLayoutAlign="center end">
    <ng-container *ngIf="actionDisplayType === 'toolbar'; else defaultActionsBlock">
      <div class="menu-toolbar-wrapper">
        <button type="button" mat-icon-button [color]="action.color" (click)="action.callback(agent)"
          class="icon-action" *ngFor="let action of activeActions">
          <mat-icon *ngIf="action.icon; else ActiveActionLabel">{{ action.icon }}</mat-icon>
          <ng-template #ActiveActionLabel>
            {{ action.label|translate }}
          </ng-template>
        </button>
      </div>
    </ng-container>

    <ng-template #defaultActionsBlock>
      <div *ngIf="showActionBtn" class="menu-trigger-wrapper">
        <ng-container *ngIf="activeActions.length==1; else BlockMore">
          <button type="button" mat-icon-button [color]="action.color" (click)="action.callback(agent)"
            class="icon-action" *ngFor="let action of activeActions">
            <mat-icon *ngIf="action.icon; else DefaultActionLabel" class="bt-cancel">{{ action.icon }}</mat-icon>
            <ng-template #DefaultActionLabel>
              {{ action.label|translate }}
            </ng-template>
          </button>
        </ng-container>

        <ng-template #BlockMore>
          <button type="button" data-ci="btn-agent-more-action" mat-icon-button [matMenuTriggerFor]="actionMenu"
            [matMenuTriggerData]="{agent: agent}" (menuOpened)="onMenuOpened()" (menuClosed)="onMenuClosed()"
            attr.aria-label="Open card menu" class="icon-action">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu class="agent-actions-menu" #actionMenu x-position="before">
            <ng-template matMenuContent let-agent="agent">
              <ng-container *ngFor="let action of activeActions">
                <button mat-menu-item [attr.data-ci]="action.id" (click)="action.callback(agent)">
                  <mat-icon [color]="action.color">{{ action.icon }}</mat-icon>
                  <span>{{ action.label|translate }}</span>
                </button>
              </ng-container>
            </ng-template>
          </mat-menu>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
