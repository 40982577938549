import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { DidEntity } from '@wephone-core/model/entity/did';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { UserRepository } from '@wephone-core/model/repository/user';
import { UserEntity } from '@wephone-core/model/entity/user';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { DidRepository } from '@wephone-core/model/repository/did';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
import { Router } from '@angular/router';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { CallDetail, CdrService } from '@wephone-core/service/cdr_service';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import * as _ from 'lodash';
import { CallInfoModalComponent } from '@wephone-common/modals/call-info-modal/call-info-modal.component';
import { DialogService, PhoneNumberService, regexSearch } from '@wephone-utils';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';

export interface GlobalSearchResult {
  users: UserEntity[];
  callqueues: CallQueueEntity[];
  outcallcampaigns: OutcallCampaignEntity[];
  dids: DidEntity[];
  groups: UserGroupEntity[];
  ivrcustommenus: IvrCustomMenuEntity[];
  pausereasons: PauseReasonEntity[];
  cdrs: CallDetail[];
  siptrunks: SipTrunkEntity[];
}

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() keyword: string;

  private currentUser: UserEntity;
  private hasInitialized: boolean;

  results: GlobalSearchResult = {
    users: [],
    callqueues: [],
    outcallcampaigns: [],
    dids: [],
    groups: [],
    ivrcustommenus: [],
    pausereasons: [],
    cdrs: [],
    siptrunks: []
  };

  private isBusinessSipTrunk: boolean;

  constructor(
    private router: Router,
    cdr: ChangeDetectorRef,
    private authService: AuthenticationService,
    private em: EntityManager,
    private cdrService: CdrService,
    private readonly dialogService: DialogService,
    private readonly phoneNumberService: PhoneNumberService,
  ) {
    super(cdr);
    this.currentUser = this.authService.getUser() as UserEntity;
    this.isBusinessSipTrunk = this.authService.isBusinessSipTrunk();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.hasInitialized = true;

    this.setData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized) {
      return;
    }

    this.setData();
  }

  private search(pattern: string, kw: string): boolean {
    const searchText = kw && _.trim(kw) || '';
    if (!pattern || !searchText) {
      return false;
    }

    return regexSearch(pattern, searchText);
  }

  private setData(): void {
    this.results = {
      users: [],
      callqueues: [],
      outcallcampaigns: [],
      dids: [],
      groups: [],
      ivrcustommenus: [],
      pausereasons: [],
      cdrs: [],
      siptrunks: []
    };

    const search = this.keyword && _.trim(this.keyword) || '';
    if (search) {
      // Users
      const users = this.em.getRepository<UserRepository>('UserRepository').getObjectList();
      this.results.users = users.filter(x => this.search(x.name, this.keyword));

      // Dids
      const dids: DidEntity[] = (this.em.getRepository('DidRepository') as DidRepository).getObjectList();
      const searchNumber = this.phoneNumberService.getDisplayNumber(this.keyword);
      this.results.dids = dids.filter(x => this.search(x.number_with_name, searchNumber));

      if (!this.isBusinessSipTrunk) {
        // Call queues
        const callQueues = this.em.getRepository<CallQueueRepository>('CallQueueRepository')
          .getInboundQueuesManagedByUser(this.currentUser);
        this.results.callqueues = callQueues.filter(x => this.search(x.queue_name, this.keyword));

        // Outcall campaign
        const outcallCampaigns = this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository').getObjectList();
        this.results.outcallcampaigns = outcallCampaigns.filter(x => this.search(x.name, this.keyword));

        // Groups
        const groups = this.em.getRepository<UserGroupRepository>('UserGroupRepository').getObjectList();
        this.results.groups = groups.filter(x => this.search(x.name, this.keyword));

        // Ivr Menu
        const ivrcustommenus = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getObjectList();
        this.results.ivrcustommenus = ivrcustommenus.filter(x => this.search(x.name, this.keyword));

        // Pause reason
        const pausereasons = this.em.getRepository<PauseReasonRepository>('PauseReasonRepository').getActiveObjectList();
        this.results.pausereasons = pausereasons.filter(x => !x.after_call && this.search(x.label, this.keyword));

        // Cdr
        this.cdrService.findCall(search).then((cdrs: CallDetail[]) => {
          this.results.cdrs = cdrs;
          this.detectChanges();
        });
      }

      // siptrunks
      this.em.getRepository<SipTrunkRepository>('SipTrunkRepository').findAll().then((siptrunks: SipTrunkEntity[]) => {
        this.results.siptrunks = siptrunks.filter(x => this.search(x.name, this.keyword));
        this.detectChanges();
      });
    }

    this.detectChanges();
  }

  private clearInput(): void {
    this.keyword = '';
  }

  gotoPageCdrDetail(cdr: CallDetail): void {
    // console.log('cdr detail', cdr);const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    // const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    // const prevOSN = this.router.onSameUrlNavigation;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';

    // this.router.navigate(['dashboard', 'call-detail', cdr.id], { skipLocationChange: false, queryParams: { t: (new Date()).getTime() } }).then(() => {
    //   this.router.routeReuseStrategy.shouldReuseRoute = prev;
    //   this.router.onSameUrlNavigation = prevOSN;
    // });

    // Open dialog
    this.dialogService.openDialog2(
      CallInfoModalComponent,
      {
        data: {
          cdr_public_id: cdr.public_id
        },
        padding: false
      }
    );
  }

  gotoPageUser(user: UserEntity): void {
    // Must add queryParams to avoid error redirect to another item from the same page
    this.router.navigate(['manage-users', 'users', user.id], { skipLocationChange: false, queryParams: { id: user.id } });
    this.clearInput();
  }

  gotoPageQueue(queue: CallQueueEntity): void {
    this.router.navigate(['queues', queue.id], { skipLocationChange: false, queryParams: { id: queue.id } });
    this.clearInput();
  }

  gotoPageCampaign(campaign: OutcallCampaignEntity): void {
    const queryParams: any = { id: campaign.id };
    if (campaign.is_archived || campaign.is_finished) {
      queryParams.archived = 1;
    }
    this.router.navigate(['out-call-campaigns', campaign.id], { skipLocationChange: false, queryParams });
    this.clearInput();
  }

  gotoPageDid(did: DidEntity): void {
    this.router.navigate(['number-routing', did.id], { skipLocationChange: false, queryParams: { id: did.id } });
    this.clearInput();
  }

  gotoPageGroup(group: UserGroupEntity): void {
    this.router.navigate(['groups', group.id], { skipLocationChange: false, queryParams: { id: group.id } });
    this.clearInput();
  }

  gotoPageIvr(ivr: IvrCustomMenuEntity): void {
    this.router.navigate(['ivr', 'ivr-menus', ivr.id], { skipLocationChange: false, queryParams: { id: ivr.id } });
    this.clearInput();
  }

  gotoPagePauseReason(pausereason: PauseReasonEntity): void {
    this.router.navigate(['callcenter-params', 'pause-reasons', pausereason.id], { skipLocationChange: false, queryParams: { id: pausereason.id } });
    this.clearInput();
  }

  gotoPageSipTrunk(trunk: SipTrunkEntity): void {
    this.router.navigate(['telephony', 'sip-trunk', trunk.id], { skipLocationChange: false, queryParams: { id: trunk.id } });
    this.clearInput();
  }

  get hasResult(): boolean {
    if (!this.results) {
      return false;
    }

    for (const key of Object.keys(this.results)) {
      if (this.results[key] && this.results[key].length) {
        return true;
      }
    }

    return false;
  }
}
