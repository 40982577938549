import { Observable, Observer } from "rxjs";

// *** THIS FILE SHOULD ONLY CONTAIN INTERFACES, NOT CLASSES ***
// Because this file is imported by drivers, if it contain a class,
// that class will also be compiled into the driver's lib output. We would then the same class in TWO libraries (jsphonelib and the driver)

export interface ILogger {
  debug(msg: string, ...additionals): void;
  info(msg: string, ...additionals): void;
  warn(msg: string, ...additionals): void;
  error(msg: string, ...additionals): void;
}

export enum AudioDeviceType {
  INPUT = 0,
  OUTPUT = 1
}
export interface IAudioDevice {
  id: number|string;
  name: string;
  is_input: number;
  is_output: number;
}
export interface IJsPhoneConfig {
  user_agent?: string;
  local_sip_port?: string;
  ringtone_file?: string;
  ringbacktone_file?: string;
  input_device?: string;
  ring_device?: string;
  output_device?: string;
  input_volume?: number;
  output_volume?: number;
  ring_volume?: number;
  sipstack_enabled?: boolean;
  silent_mode?: boolean;
  ask_user_on_new_device?: number;
}

export interface IJsPhoneLib {
  accountManager: ISipAccountManager;

  setLogger(logger: ILogger);

  getLogger(): ILogger;

  setDriver(driver: IJsPhoneDriver): void;

  getDriver(): IJsPhoneDriver;
}

// -- ACCOUNT-- 

enum RegistrationState {
  REGISTERING,
  REGISTERED,
  UNREGISTERED,
}

export interface ISIPAccountConfig {
  username: string;
  password: string;
  domain: string;
  outbound_proxy: string;
  websocket_url: string;
  turn_servers?: string;
  transcoding?: number;
}

export interface ISipAccount {
  is_default: boolean;
  name: string;
  config : ISIPAccountConfig;

  isRegistered(): boolean;

  isRegistering(): boolean;

  isUnregistered(): boolean;

  setRegistration(active: boolean): void;

  regStatusCode(): number;

  regStatusMessage(): string;

  makeCall(dest: string, calling_number?: string, headers?: any): ISipCall;

  destroy(): void;
}

export interface ISipAccountManager {
  readonly defaultAccount: ISipAccount;

  defaultAccountConnected(): boolean;

  getAccountByName(name: string): ISipAccount;

  clearAccounts();

  removeAccountByName(name: string);

  // getSipsterAccountName(account: SipsterAccount): string;

  setDefault(account: ISipAccount);

  addAccount(acc: ISipAccount, is_default?: boolean);

  setOfflineMode(offline: boolean): void;
}

// --- Calls ---
export enum CallState {
  INVALID = 'INVALID',
  CREATED = 'CREATED',
  EARLY = 'EARLY',
  CALLING = 'CALLING',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED'
}

export enum CallEventType {
  NEW_CALL = 0,
  STATE_CHANGE = 1,
  DTMF = 2
}

export interface CallEvent {
  type: CallEventType;
  call: ISipCall;
  data?: any;
}

export interface ISipCall {
  callId: number;
  callEvent$: Observable<CallEvent>;
  callEventSource: Observer<CallEvent>;
  remoteDisplayName: string;
  remoteNumber: string;
  localDisplayName: string;
  localNumber: string;
  player: any;
  active: boolean;
  state: CallState;
  sipCallId: string;
  account?: ISipAccount;

  watchHangup(callback: (val: ISipCall) => any);

  watchAnswer(callback: (val: ISipCall) => any);

  startPlaying(file: string);

  stopPlaying();

  sendCallEvent(event_type: CallEventType, data?: any);

  lastStatusCode: number;

  setMuted(muted: boolean);

  isMuted();

  isInbound();

  answer(code?: number, reason?: string);

  hangup();

  hold();

  transfer();

  getStatsDump();

  dtmf(dtmf_string: string);

  setActive(active: boolean);

  setState(state: CallState, statusCode?: number, reason?: string);

  getState(): CallState;

  isHungup(): boolean;

  isAnswered(): boolean;

  setConnectedTime();

  getConnectedTime(): Date;

  getStartTime(): Date;
}
// ----

export interface IJsPhoneDriver {
  sipstackEnabled: boolean;
  accountManager: ISipAccountManager;

  systemHasInputDevice: boolean;
  systemHasOutputDevice: boolean;
  readonly userAgent: string;
  readonly driverName: string;

  logout(): void;

  publishEvent(eventName: string, eventParams?: any): void;

  onIncomingCall(call: any): void;

  getPhoneConfig(): IJsPhoneConfig;

  setEventPublisher(event_publisher: any): void;

  init(phone_config?: IJsPhoneConfig, user_agent?: string): void;

  updateConfig(phone_config: IJsPhoneConfig): void;

  updateConfigAsync(phone_config: IJsPhoneConfig): Promise<void>;

  getRingToneFile(): string;

  enableSipStack(enabled: boolean);

  setRingToneFile(f: string): void;

  makeCall(called_number: string, calling_number?: string, headers?: any): Promise<ISipCall>;

  makeCallAsync(called_number: string, calling_number?: string, headers?: any): void;

  getCallBySipCallId(sip_call_id: string): ISipCall;

  publishEvent(eventName: string, eventParams: any): void;

  getPhoneInfo(): any;

  answerCall(): void;

  hangupCall();

  resetConfig();

  configureAccount(account_config: any, account_name?: string, is_default?: boolean);

  getAudioDevices();

  setPlaybackDeviceByName(dev_name?: string): boolean;

  setCaptureDeviceByName(dev_name?: string): boolean;

  setPlaybackDevice(dev_id: number): void;

  getActivePlaybackDeviceName(): string;

  getActiveCaptureDeviceName(): string;

  getActiveRingDeviceName(): string;

  setCaptureDevice(dev_id: number): void;

  getCaptureMedia(): any;

  getPlaybackMedia(): any;

  adjustPlaybackVolume(): void;

  adjustCaptureVolume(): void;

  createPlayer(source_file: string): any;

  createRecorder(dest_file: string, format?: string): any;

  codecEnum(): Array<any>;

  codecSetPriority(codecId: string, priority: number);

  startRecordingVocalCommand();

  isRecordingVoiceCommand(): boolean;

  stopRecordingVocalCommand(): void;

  handleIpChange(): void;

  getVocalCommandData();

  sendPing() : Promise<number>;
}
