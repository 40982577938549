import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, Self, Optional, ElementRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { DidEntity } from '@wephone-core/model/entity/did';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { DidRepository } from '@wephone-core/model/repository/did';
import { SystemParamService } from '@wephone-core/service/system-param-service';
import { SystemParam } from '@wephone-core/system';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'did-select-form-input',
  templateUrl: './did-select.component.html',
  styleUrls: ['./did-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DidSelectFormInput }]
})
export class DidSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnChanges, OnDestroy, MatFormFieldControl<DidEntity> {
  static nextId = 0;

  @Input() allowAnonymous: boolean;
  @Input() allowEmpty: boolean;
  @Input() allowNoDefault: boolean;
  @Input() emptyLabel: string;
  @Input() excludeIds: number[] = [];
  @Input() includeIds: number[];
  @Input() onlyConfigured: boolean;

  flexSelect: FormControl = new FormControl();
  didList: DidEntity[];
  flexSelectOptions: IFlexSelectOptions;

  private readonly didRepo = DidRepository.getInstance<DidRepository>();
  private readonly emptyDid: DidEntity;
  private readonly noDefaultDid: DidEntity;
  private hasInitialized: boolean;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private readonly systemParamService: SystemParamService
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      compareWith: (a: DidEntity, b: DidEntity) => (!a && !b || a && b && a.id === b.id),
      filterFunc: (item: DidEntity, filterString) => {
        return item.name && regexSearch(item.name, filterString) || regexSearch(item.number, filterString) || regexSearch(item.display_number, filterString);
      }
    };

    this.emptyDid = this.didRepo.getEmptyDid(_ti('call_queue.content.enterprise_default_number'));
    this.noDefaultDid = this.didRepo.getEmptyDid(_ti('call_queue.content.no_default_number'));
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  writeValue(val: DidEntity): void {
    let _val = val;
    if (this.allowEmpty && !val) {
      _val = this.emptyDid;
    }
    if (this.allowNoDefault && !val) {
      _val = this.noDefaultDid;
    }
    this.wrappedControl.setValue(_val);
  }

  ngOnInit(): void {
    this.hasInitialized = true;
    super.ngOnInit();
    this.initialize();
  }

  private initialize(): void {
    this.didList = _.cloneDeep(this.didRepo.getObjectList());
    if (this.onlyConfigured) {
      this.didList = this.didList.filter(d => d.hasRoutingData() && d.routing_status);
    }

    if (this.includeIds) {
      this.didList = this.didList.filter(d => this.includeIds.indexOf(d.id) !== -1);
    }

    if (this.excludeIds && this.excludeIds.length) {
      this.didList = this.didList.filter(d => this.excludeIds.indexOf(d.id) === -1);
    }

    this.didList.sort((a, b) => a.id - b.id);

    if (this.allowAnonymous && this.systemParamService.getParam(SystemParam.telecom_has_anonymous, true)) {
      const anonymousDid = this.didRepo.getAnonymousDid();
      this.didList.unshift(anonymousDid);
    }

    if (this.allowEmpty) {
      this.didList.unshift(this.emptyDid);
    }

    if (this.allowNoDefault) {
      this.didList.unshift(this.noDefaultDid);
    }
  }

  ngOnChanges(): void {
    if (!this.hasInitialized) {
      return;
    }
    this.initialize();
  }

  get controlType(): string {
    return 'did-select-form-input';
  }
}
