import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class BlackListRepository extends BaseRepository {
  ENTITY_CLASS = BlackListEntity;
  protected readonly URL_PREFIX: string = 'blacklist';
  private readonly URL_GET_BLACKLIST_LIST: string = 'list';

  protected getURLAction(action: string): string {
    switch (action) {
      case 'LIST':
        return this.getUrlV2(this.URL_GET_BLACKLIST_LIST);
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'UPDATE_ATTRS':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS);
      case 'UPDATE_ATTRS_BULK':
        return this.getUrlV2(this.METHOD_PATH_UPDATE_ATTRS_BULK);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  getObjectByNumber(phoneNumber: string): BlackListEntity {
    return this.getObjectList().find(a => a.number === phoneNumber || a.display_number === phoneNumber);
  }

  getObjectListByBlocker(blocker_id: number): BlackListEntity[] {
    return this.getObjectList().filter(
      a => a.blocker_id == blocker_id
    );
  }

  bulkDelete(items: BlackListEntity[], manual_remove = true): Promise<any> {
    return super.bulkDelete(items, manual_remove).then(ret => {
      return super.reload().then(() => {
        return ret;
      });
    });
  }

  createAndSave(data: ICreateBlackList): Promise<{id: number}> {
    return super.createAndSave(data);
  }

  blockCall(callData: IBlockCall): Promise<{id: number}> {
    const url = this.getUrlV2('block_call');

    return HttpEngine.getInstance().post(url, callData);
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    const url = this.getURLAction('LIST');
    if (use_cache && (this.object_list || []).length) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance().get(url, {}).then((updated_list: any) => {
      // // update BlackListEntity list
      // const object_list_data = [];
      // for (const new_obj_data of updated_list) {
      //   const item = _.find(object_list_data, { id: new_obj_data.id }) || null;
      //   if (item) {
      //     item.setObjectData(new_obj_data);
      //     continue;
      //   }
      //   // create new object
      //   const new_blacklist_obj = this.create();
      //   new_blacklist_obj.setObjectData(new_obj_data, false);
      //   object_list_data.push(new_blacklist_obj);
      // }
      this.setDataAsReady();
      // this.setObjectList(object_list_data || []);
      return this.object_list;
    });
  }
}

import { BlackListEntity } from '@wephone-core/model/entity/blacklist';
import { HttpEngine } from '@wephone-core/service/http_engine';import { IBlockCall, ICreateBlackList } from './blacklist.i';

