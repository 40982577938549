import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, ClassRegistry, MessageService, DialogService, SmsMaxValidator, NoWhitespaceValidator, SmsMaxLength } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SmsMessageCount, SmsService } from '../../service/sms.service';
import { DidEntity } from '@wephone-core/model/entity/did';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DidRepository } from '@wephone-core/model/repository/did';
import { SmsDialog, SmsConversationItem, SmsMessage, SmsSendStatus } from '@wephone-sms/sms.types';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { SmsSetScheduleComponent } from '../sms-set-schedule/sms-set-schedule.component';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { PhoneService } from '@wephone-app-phone/services/phone/phone.service';

@Component({
  selector: 'app-conversation-detail',
  templateUrl: './conversation-detail.component.html',
  styleUrls: ['./conversation-detail.component.scss']
})
export class ConversationDetailComponent extends DialogComponentBase implements OnInit, AfterViewInit, OnDestroy {
  dialogTitle = _tk('conversation.content.detail');
  dialogRightActions: DialogActionButton[] = [];

  @ViewChild('messageInput') messageElRef: ElementRef;

  smsDialog: SmsDialog;
  did: DidEntity;
  replyForm: FormGroup;
  conversations: SmsConversationItem[] = [];
  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.closeDialog();
    }
  };

  smsDirection = {
    INBOUND: 0,
    OUTBOUND: 1
  };
  smsContentInfo: SmsMessageCount;
  smsMaxLength: number = SmsMaxLength;

  private hasChangeConversation = false;
  formSubmitting = false;
  isEmptyMsg = true;
  canMakeCall = false;

  constructor(
    cdr: ChangeDetectorRef,
    private readonly smsService: SmsService,
    private readonly em: EntityManager,
    dialogRef: MatDialogRef<ConversationDetailComponent>,
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService,
    private readonly authService: AuthenticationService,
    private readonly phoneService: PhoneService,
    @Inject(MAT_DIALOG_DATA) public data: {
      item: SmsDialog
    },
  ) {
    super(dialogRef, cdr);
    this.replyForm = new FormGroup({
      message: new FormControl('', {
        // validators: [Validators.required, SmsMaxValidator, NoWhitespaceValidator],
        updateOn: 'change'
      }),
    });

    this.setForm(this.replyForm);
  }

  get replyDisabled(): boolean {
    return this.formSubmitting || this.isEmptyMsg;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.canMakeCall = this.authService.isAdmin() || this.authService.isSupervisor() || this.authService.isAgent();

    this.smsDialog = this.data.item;

    this.setValidatorsSmsMessage();

    if (this.smsDialog.did_id) {
      this.did = this.em.getRepository<DidRepository>('DidRepository').getObjectById(this.smsDialog.did_id);
    }

    this.setSmsContentInfo();
    this.refreshConversationList();

    if (this.smsDialog.unread) {
      this.markSmsAsHandled();
    }

    this.addSubscription(
      this.messageService.subscribeNewSms(() => {
        this.hasChangeConversation = true;
        this.markSmsAsHandled();
        this.refreshConversationList();
      })
    );

    this.addSubscription(
      this.messageService.subscribeStatusSms(sms => {
        this.updateConversationStatus(sms);
      })
    );

    this.addSubscription(
      this.replyForm.get('message').valueChanges.subscribe(value => {
        this.isEmptyMsg = !value || !_.trim(value);
        if (this.isEmptyMsg) {
          this.replyForm.markAsPristine();
        }
        // this.replyForm.get('message').markAsTouched();
        this.setSmsContentInfo();
        this.detectChanges();
        console.log('replyDisabled', this.replyDisabled);
      })
    );
  }

  hasLimitedSms(): boolean {
    return !!this.smsDialog.sender_name;
  }

  private setSmsContentInfo(): void {
    this.smsContentInfo = this.smsService.getSmsMessageCount(this.replyForm.get('message').value);
  }

  private updateConversationStatus(sms: SmsMessage): void {
    // console.log('this.conversations', this.conversations);
    const conversation = this.conversations.find(i => i.id === sms.id);
    if (conversation) {
      conversation.send_status = sms.send_status;
      this.detectChanges();
    }
  }

  private markSmsAsHandled(): void {
    this.smsService.markSmsAsHandled(this.smsDialog.id, 0).then(
      resp => {
        this.smsDialog.unread = 0;
        this.smsService.updateTotalConversationUnreadSms();
      }
    );
  }

  private setValidatorsSmsMessage(): void {
    if (this.smsDialog.sender_name) {
      // this.replyForm.get('message').setValidators([Validators.required, SmsMaxValidator, NoWhitespaceValidator]);
      this.replyForm.get('message').setValidators([Validators.minLength(1), SmsMaxValidator, NoWhitespaceValidator]);
    }
  }

  async makeCall(phoneNumber: string): Promise<void> {
    try {
      await this.phoneService.makeCall(phoneNumber);
    } catch (error) {
      this.showError(_ti('public.message.error_occurred'));
    }
  }

  isScheduled(conversationItem: SmsConversationItem): boolean {
    return conversationItem.scheduled_send_dt && conversationItem.send_status === SmsSendStatus.SEND_STATUS_WAITING;
  }

  closeDialog(): void {
    const data = this.hasChangeConversation ? { item: this.smsDialog } : undefined;
    this.dismiss(data);
  }

  sendScheduled(): void {
    this.replySms(true);
  }

  unscheduleSendingSms(conversationItem: SmsConversationItem): Promise<void> {
    console.log('conversationItem', conversationItem);
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('sms.alert.confirm_unschedule'),
      async () => {
        try {
          const res = await this.smsService.unscheduleSms(conversationItem.id);

          this.conversations.splice(this.conversations.indexOf(conversationItem), 1);
          this.hasChangeConversation = true;

          if (!this.conversations.length) {
            // Close dialog
            this.closeDialog();
          } else {
            this.detectChanges();
            this.scrollToBottomContent();
          }

          this.toastService.show(_ti('sms.alert.unschedule_success'));
        } catch (e) {
          this.toastService.showErrorMessage(e, _ti('sms.alert.unschedule_failure'));
        }
      }
    );
  }

  refreshConversationList(): void {
    this.smsService.getSmsDialogMessages(this.smsDialog.id).then(
      (conversations: SmsConversationItem[]) => {
        this.conversations = conversations;
        console.log('conversations', conversations);
        this.detectChanges();
        this.scrollToBottomContent();
      }
    );
  }

  displayStatus(status): string {
    return this.smsService.displayStatus(status);
  }

  get messageControl(): AbstractControl {
    return this.replyForm.get('message');
  }

  async replySms(scheduled: boolean = false): Promise<void> {
    const message = this.messageControl.value;
    if (!_.trim(message)) {
      this.messageControl.reset();
      return;
    }

    if (!message) {
      return;
    }

    if (scheduled) {
      this.dialogService.openDialog2(SmsSetScheduleComponent, {

      }, async (scheduledDate: DateTime) => {
        if (!scheduledDate) {
          return;
        }

        await this.sendSms(message, scheduledDate);
      });
    } else {
      await this.sendSms(message, null);
    }
    this.form.markAsPristine();
  }

  private async sendSms(message: string, scheduledDate: DateTime): Promise<void> {
    if (this.replyForm.invalid) {
      this.replyForm.markAllAsTouched();
      return;
    }

    try {
      this.formSubmitting = true;
      this.detectChanges();

      const resp = await this.smsService.sendSmsFromDialog(this.smsDialog.id, message, scheduledDate);
      const updatedSmsDialog = resp[0].dialog;

      console.log('send sms resp', resp);
      if (!resp || !resp.length || resp.length !== 1) {
        console.error('No proper response', resp);
      }

      this.hasChangeConversation = true;

      // Update sms-dialog
      if (updatedSmsDialog && this.smsDialog.client_name !== updatedSmsDialog.client_name) {
        this.smsDialog.client_name = updatedSmsDialog.client_name;
      }

      // Clear chat message
      this.messageControl.reset();

      this.showInfo(scheduledDate ? _ti('sms.alert.schedule_success') : _ti('sms.alert.send_success'));
    } catch (error) {
      console.error('Reply sms error', error);
      this.showErrorMessage(error, scheduledDate ? _ti('sms.alert.schedule_failure') : _ti('sms.alert.send_failure'));
    } finally {
      this.formSubmitting = false;
      this.refreshConversationList();
      this.detectChanges();
    }
  }

  scrollToBottomContent(): void {
    const el = document.getElementById('sms_dialog_bottom');
    el.scrollIntoView();
  }

  ngAfterViewInit(): void {
    this.messageElRef.nativeElement.focus();
  }

}

ClassRegistry.register(ConversationDetailComponent);
