import { Injectable } from '@angular/core';

import { EntityManager } from '@wephone-core/service/entity_manager';
import { CallLogService } from '@wephone-core/service/call_log_service';

@Injectable()
export class HistoryService {
  file: string =
    ' [ { "number": "0565987545", "datetime": "29/05/42 23h56", "incoming": "0"},{ "number": "0565987545", "datetime": "29/05/42 23h56", "incoming": "0" },{"number": "0565987545","datetime": "29/05/42 23h56","incoming": "0"}, {  "number": "0565987545",  "datetime": "29/05/42 23h56",  "incoming": "0" },{ "number": "0565987545", "datetime": "29/05/42 23h56", "incoming": "0"},{ "number": "0565987545", "datetime": "29/05/42 23h56", "incoming": "0"},{ "number": "0565987545", "datetime": "29/05/42 23h56", "incoming": "0"},{ "number": "0565987545", "datetime": "29/05/42 23h56", "incoming": "0"},{ "number": "0565987545", "datetime": "29/05/42 23h56", "incoming": "0"} ]';

  constructor(private callLogService: CallLogService) {}

  getCallHistory() {
    return this.callLogService.getCallLogs();
    //return (this.file);
  }
}
