import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormService, EditingComponent, ToastService } from '@wephone-utils';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { FormBuilder } from '@angular/forms';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidRepository } from '@wephone-core/model/repository/did';
import { UserEntity } from '@wephone-core/model/entity/user';
import { CallingProfileEntity } from '@wephone-core/model/entity/callingprofile';
import { CallingProfileRepository } from '@wephone-core/model/repository/callingprofile';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { UserService } from '@wephone/services/user.service';
import { _ti } from '@wephone-translation';
import { SystemParam } from '@wephone-core/system';
import { UserProfileService, CallingNumber } from '@wephone/services/user-profile.service';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { UserRepository } from '@wephone-core/model/repository/user';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-sip',
  templateUrl: './user-sip.component.html',
  styleUrls: ['./user-sip.component.scss']
})
export class UserSipComponent extends EditingComponent implements OnInit, OnChanges {
  @Input() user: UserEntity;
  @Output() readonly formValueChanges: EventEmitter<boolean>;

  private readonly enterpriseRepo: EnterpriseRepository = EnterpriseRepository.getInstance();

  myEnterprise: EnterpriseEntity;
  currentUser: UserEntity;
  // sip_domain: string;
  // show_sip_password: boolean;
  // excludedCallingNumberIds: number[] = [];
  includedCallingNumberIds: number[] = [];

  private hasInitialized = false;

  constructor(
    public formService: FormService,
    protected configManager: ConfigManager,
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly toast: ToastService,
    private readonly em: EntityManager,
    private readonly userProfileService: UserProfileService,
    protected fb: FormBuilder
  ) {
    super();
    this.currentUser = this.authService.getUser();
    this.formValueChanges = new EventEmitter<boolean>();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    this.myEnterprise = this.enterpriseRepo.getMyEnterprise();

    // this.sip_domain = this.configManager.getSystemParam(SystemParam.sip_domain);
    // const sip_port = this.configManager.getSystemParam(SystemParam.sip_port);
    // if (+sip_port !== 5060) {
    //   this.sip_domain = `${this.sip_domain}:${sip_port}`;
    // }

    await this.initialize();
  }

  async getDefaultCallingNumbers(): Promise<void> {
    const dids: DidEntity[] = this.em.getRepository('DidRepository').getObjectList();
    const userCallingNumbers: CallingNumber[] = await this.userProfileService.getUserCallingNumbers(this.user.id);
    // this.excludedCallingNumberIds = dids.filter(x => !userCallingNumbers.find(i => i.id === x.id)).map(x => x.id);
    this.includedCallingNumberIds = dids.filter(x => userCallingNumbers.find(i => i.id === x.id)).map(x => x.id);

  }

  async onChangeSecretaryNumber(): Promise<void> {
    console.log('onChangeSecretaryNumber');
    await this.getDefaultCallingNumbers();

    // Remove old secretary did from calling-list
    const currentSecretaryDid: DidEntity = this.user.secretary_did;
    if (currentSecretaryDid && _.includes(this.includedCallingNumberIds, currentSecretaryDid.id)) {
      this.includedCallingNumberIds.splice(this.includedCallingNumberIds.indexOf(currentSecretaryDid.id), 1);
    }

    // Include new secretary number
    const secretaryDid: DidEntity = this.form && this.form.get('secretary_did_id').value;
    if (secretaryDid) {
      if (!_.includes(this.includedCallingNumberIds, secretaryDid.id)) {
        this.includedCallingNumberIds.push(secretaryDid.id);
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.hasInitialized) {
      return;
    }

    // await this.initialize(changes.user && changes.user.currentValue.secretary_did_id !== changes.user.previousValue.secretary_did_id);
    await this.initialize(true); // Reload calling-number in all cases
  }

  async initialize(reloadCallingNumber: boolean = true): Promise<void> {
    if (reloadCallingNumber) {
      await this.getDefaultCallingNumbers();
    }
    this.initForm();
    this.hasInitialized = true;
  }

  private initForm(): void {
    this.form = this.fb.group({
      callout_enabled: [this.user.callout_enabled ? true : false],
      calling_number_id: [this.callingNumber],
      secretary_did_id: [this.user.secretary_did],
      calling_profile_id: [this.callingProfile]
    });

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });

    this.form.get('secretary_did_id').valueChanges.subscribe(changes => {
      this.onChangeSecretaryNumber();
    });
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  get callingNumber(): DidEntity {
    let callingNumber: DidEntity = DidRepository.getInstance().create() as DidEntity;
    if (this.user.calling_number_id) {
      callingNumber = DidRepository.getInstance().getObjectById(this.user.calling_number_id) as DidEntity;
    }
    return callingNumber;
  }

  async enableVoip(): Promise<any> {
    try {
      // create voip and assign to user
      await this.userService.createVoip(this.user.id);
      this.user = UserRepository.getInstance().getObjectById(this.user.id);
      this.toast.showSuccess(_ti('public.message.update_success'));
    } catch (error) {
      this.toast.showError(error.message || _ti('public.message.update_failure'));
      let msg = _ti('public.message.update_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toast.showError(msg);
    }
  }

  get callingProfile(): CallingProfileEntity {
    let callingProfile: CallingProfileEntity = CallingProfileRepository.default;
    if (this.user.calling_profile_id) {
      callingProfile = CallingProfileRepository.getInstance().getObjectById(this.user.calling_profile_id);
    }
    return callingProfile;
  }

  private getFormResetData(): any {
    return {
      callout_enabled: this.user.callout_enabled ? true : false,
      calling_number_id: this.callingNumber,
      secretary_did_id: this.user.secretary_did,
      calling_profile_id: this.callingProfile,
    };
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  isValid(): boolean {
    return true;
  }
}
