import { Component, OnInit, ElementRef, Self, Input, ViewChildren, ViewChild, ChangeDetectorRef, Optional } from '@angular/core';
import { FlexMatInputWrapper } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'inline-textbox',
  templateUrl: './inline-textbox.component.html',
  styleUrls: ['./inline-textbox.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: InlineTextboxComponent }]
})
export class InlineTextboxComponent extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() emptyLabel: string;
  myControl = new FormControl();
  showInput = false;
  @ViewChild('textInput') textInput: ElementRef;
  // private textInput: ElementRef;

  constructor(
    elRef: ElementRef,
    @Optional()
    @Self()
    private cdRef: ChangeDetectorRef,
    ngControl: NgControl
  ) {
    super(ngControl, elRef);
    // this.textInput = elRef;
  }

  showText(show: boolean): void {
    this.showInput = show;
    if (show) {
      // this.wrappedControl.markAsDirty();
      this.cdRef.detectChanges();
      this.textInput.nativeElement.focus();
    }
  }

  writeValue(val): void {
    this.wrappedControl.setValue(val);
  }

  get label(): string {
    return this.wrappedControl.value || this.emptyLabel;
  }

  get controlType(): string {
    return 'flex-inline-textbox';
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
