import { Component, ElementRef, Optional, OnInit, OnDestroy } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexMatInputWrapper } from '@wephone-utils';
import { FormControl, NgControl, FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { OpeningHourSpecialDateEntity, OpeningMode } from '@wephone-core/model/entity/openinghour_special_date';
import { OpeningHourRepository } from '@wephone-core/model/repository/openinghour';
import { DateAdapter } from '@angular/material/core';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

export function OpeningHourSpecialDateValidator(): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    const openingHourSpecialDate = formGroup.value;
    const dStart: DateTime = DateTime.fromJSDate(new Date(openingHourSpecialDate.startDt)).startOf('day');
    const dEnd: DateTime = DateTime.fromJSDate(new Date(openingHourSpecialDate.endDt)).startOf('day');

    // if (dStart > dEnd) {
    //   formGroup.get('endDt').markAsDirty();
    //   return {dateRange: true};
    // }

    if (
      dStart.equals(dEnd) &&
      (openingHourSpecialDate.openingMode === OpeningMode.CloseDuringPeriod || openingHourSpecialDate.openingMode === OpeningMode.OpenDuringPeriod) &&
      openingHourSpecialDate.openingHour
    ) {
      const tStart = DateTime.fromFormat(`${openingHourSpecialDate.openingHour.start_time}:00`, 'HH:mm:ss');
      const tEnd = DateTime.fromFormat(`${openingHourSpecialDate.openingHour.end_time}:00`, 'HH:mm:ss');

      if (tStart >= tEnd) {
        formGroup.get('openingHour').setErrors({ timeRange: true });
        return { timeRange: true };
      }
    }

    formGroup.get('openingHour').setErrors(null);
    return null;
  };
}

@Component({
  selector: 'opening-hour-special-date-form-input',
  templateUrl: './opening-hour-special-date-form-input.component.html',
  styleUrls: ['./opening-hour-special-date-form-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: OpeningHourSpecialDateFormInput }]
})
// tslint:disable-next-line:max-line-length
export class OpeningHourSpecialDateFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<OpeningHourSpecialDateEntity> {
  // Private member var
  private openingHourRepo = OpeningHourRepository.getInstance() as OpeningHourRepository;
  // private subscribeChangeLang: Subscription;

  // Public member var
  openingModes = [
    { value: OpeningMode.CloseAllday, label: 'opening_hour_calendar.content.opening_modes.0' },
    { value: OpeningMode.OpenAllday, label: 'opening_hour_calendar.content.opening_modes.1' },
    { value: OpeningMode.CloseDuringPeriod, label: 'opening_hour_calendar.content.opening_modes.2' },
    { value: OpeningMode.OpenDuringPeriod, label: 'opening_hour_calendar.content.opening_modes.3' }
  ];

  formControl = new FormControl();
  form: FormGroup;

  openingModeValues = {
    CloseAllday: OpeningMode.CloseAllday,
    OpenAllday: OpeningMode.OpenAllday,
    CloseDuringPeriod: OpeningMode.CloseDuringPeriod,
    OpenDuringPeriod: OpeningMode.OpenDuringPeriod,
  };

  constructor(
    elRef: ElementRef,
    @Optional() ngControl: NgControl,
    private fb: FormBuilder,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(ngControl, elRef);
    this.dateAdapter = dateAdapter;
    this.form = this.fb.group({
      name: [undefined, [Validators.required]],
      startDt: [undefined, [Validators.required]],
      endDt: [undefined, [Validators.required]],
      openingMode: [],
      openingHour: [],
      announcement: [],
    }, { validators: [OpeningHourSpecialDateValidator()] });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.addSubscription(
      this.form.valueChanges.subscribe(values => {
        const openingHourSpecialDate: OpeningHourSpecialDateEntity = this.formControl.value;
        openingHourSpecialDate.name = values['name'];
        openingHourSpecialDate.start_dt = values['startDt'];
        openingHourSpecialDate.end_dt = values['endDt'];
        openingHourSpecialDate.opening_mode = values['openingMode'];
        openingHourSpecialDate.openinghour = values['openingHour'];
        openingHourSpecialDate.announcement = values['announcement'];

        this.formControl.setValue(openingHourSpecialDate);
        this.form.markAllAsTouched();
      })
    );
  }

  get wrappedControl(): FormControl {
    return this.formControl;
  }

  get controlType(): string {
    return 'flex-opening-hour-special-date-form-input';
  }

  get openingModeForm(): FormControl {
    return this.form.get('openingMode') as FormControl;
  }

  get announcementForm(): FormControl {
    return this.form.get('announcement') as FormControl;
  }

  get announcement(): FileEntryEntity {
    return this.formControl.value && this.formControl.value.announcement;
  }

  writeValue(val: OpeningHourSpecialDateEntity): void {
    this.form.setValue({
      name: val.name,
      startDt: val.start_dt,
      endDt: val.end_dt,
      openingMode: val.opening_mode,
      openingHour: val.openinghour,
      announcement: val.announcement
    });

    this.formControl.setValue(val);
    super.writeValue(val);
  }

  updateAnnouncementSoundFile(uploadResult: { new_file_entry: FileEntryEntity; remove?: boolean }): void {
    const fileEntry: FileEntryEntity = uploadResult.new_file_entry; // Empty new_file_entry and remove is true mean remove sound
    console.log('updateAnnouncementSoundFile', uploadResult);
    if (!uploadResult.remove && (!fileEntry || !fileEntry.getId())) {
      console.error('No file entry announcement created/updated');

      return;
    }

    console.log('announcementForm.setValue', fileEntry);
    this.announcementForm.setValue(fileEntry);
  }

  changeOpeningMode(): void {
    const openingMode = this.openingModeForm.value;
    const openingHourControl = this.form.get('openingHour');
    if (openingMode === OpeningMode.CloseDuringPeriod || openingMode === OpeningMode.OpenDuringPeriod) {
      if (!openingHourControl.value) {
        const openingHour = this.openingHourRepo.create() as OpeningHourEntity;
        openingHourControl.setValue(openingHour);
      }
    }
  }

  // private setDatepickerLocale(): void {
  //   // const currentLang = TranslationService.getInstance().translate.currentLang;
  //   // this.dateAdapter.setLocale(currentLang === 'pt_BR' ? 'pt' : currentLang);
  //   this.dateAdapter.setLocale(TranslationService.getInstance().getCurrentLocale());
  // }

  // ngOnDestroy(): void {
  //   this.subscribeChangeLang.unsubscribe();
  // }
}
