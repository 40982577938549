import { ActiveCallInfo, IPhoneCall } from '@wephone-app-phone/services/phone/phonecall.i';

export interface ConfigExecScript {
  inbound?: boolean;
  outbound?: boolean;
  script_path?: string;
}

export interface IPhoneService {
  // Deprecated
  makeCallByWebservice(called_number, calling_number?: string): Promise<void>;

  hangupCallByWebservice(call: IPhoneCall): Promise<any>;

  makeCall(called_number: string): Promise<IPhoneCall>;

  getCrmContact(crmId: number, crmContactId: string, crmContactType: number): Promise<any>;

  getConfigExecScript(): Promise<any>;
  setConfigExecScript(config: ConfigExecScript): Promise<void>;
  runExternalApp(appPath: string, params: string[]): void;
  // callBO(url: string): void;
  isElectronApp(): boolean;
  getActiveCallInfo(): Promise<ActiveCallInfo>;
  getActiveCallInfoById(callPublicId: string): Promise<ActiveCallInfo>;
  terminateActiveCall(call: IPhoneCall): void;
  clearActiveCall(): void;
}

export enum VocalCommandAction {
  OPEN_URL = 'open_url'
}
export interface IVocalCommandReply {
  action: VocalCommandAction;
  params: {
    url: string;
  };
}
