import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';

export class RoutingAppWebService extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.webservice;

  setPhoneNumber(url: string): void {
    this.setAppParams({ url });
  }

  setRelatedObject(): void {}

  getRelatedObject(): string {
    return this.appParams.url;
  }

  getDescription(): string {
    const url: string = this.appParams ? this.appParams.url : '<invalid>';

    return `To web service url ${url}`;
  }
}
