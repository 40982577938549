<div fxFill fxLayout="column" class="pa-1">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div>
        <h2>{{'sip_token.manage'|translate}}</h2>
      </div>
      <div>
        <a (click)="onCreate()">
          <h2 class="action-link" data-ci="button-add-ip-phone-provisioning-token">{{'public.add'|translate}}</h2>
        </a>
      </div>
    </div>

    <flex-table #FlexTableProvisionToken [dataSource]="dataSourceProvisionToken"
      [tableConfig]="tableConfigProvisionToken" [columnTemplates]="{
        creation_dt: tplDateProvisionToken,
        valid_until: tplDateProvisionToken,
        action: tplActionProvisionToken
      }">

      <ng-template #tplDateProvisionToken let-col="col" let-item="row">
        <span *ngIf="item && item[col]"> {{ item[col]|timeFormatDateTimeShort }}</span>
      </ng-template>

      <ng-template #tplActionProvisionToken let-item="row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div>
            <button mat-icon-button [title]="'public.edit'|translate" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button [title]="'public.delete'|translate" class="mat-text-warn" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button [title]="'public.get_link'|translate" class="mat-text-secondary"
              data-ci="get-provisioning-token" (click)="getLink(item)">
              <mat-icon>link</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button [title]="'sip_token.content.customize_config'|translate"
              class="button-customize-config" data-ci="customize-config" (click)="onEditCustomConfig(item)">
              <mat-icon>article</mat-icon>
              <mat-icon class="mark-edit-icon">edit</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button [title]="'sip_token.content.clear_customize_config'|translate"
              class="button-customize-config" data-ci="clear-customize-config" (click)="onClearCustomConfig(item)"
              [disabled]="!item.config_template">
              <mat-icon>article</mat-icon>
              <mat-icon class="mark-edit-icon mat-text-warn">clear</mat-icon>
            </button>
          </div>
        </div>
      </ng-template>
    </flex-table>

    <div class="pt-1">
      <h2>{{'phone_config_request.content.pending_request'|translate}}</h2>
      <p>
        {{ 'phone_config_request.content.direction_click'|translate }}
        <a class="action-link" data-ci="refresh_pending_request" (click)="fetchData()">
          {{'phone_config_request.content.refresh_list'|translate }}</a>
      </p>

      <flex-table #FlexTablePendingConfigRequests [dataSource]="dataSourcePendingConfigRequests"
        [tableConfig]="tableConfigPendingConfigRequests" [columnTemplates]="{
          last_request_time: tplDateLastRqTime,
          action: tplActionPendingConfigRequests
        }">

        <ng-template #tplDateLastRqTime let-col="col" let-item="row">
          <span *ngIf="item && item[col]"> {{ item[col]|timeFormatDateTimeShort }}</span>
        </ng-template>

        <ng-template #tplActionPendingConfigRequests let-item="row">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div>
              <button mat-icon-button [title]="'public.configure'|translate"
                (click)="setConfigPhonePendingRequest(item)" data-ci="configure-sipphone">
                <mat-icon>build</mat-icon>
              </button>
            </div>
            <div>
              <button mat-icon-button [title]="'public.delete'|translate" class="mat-text-warn"
                (click)="deletePendingRequest(item)" data-ci="delete-pending-request">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </flex-table>

    </div>

    <div class="pt-1">
      <h2>{{'phone_config_request.content.configured_phones'|translate}}</h2>

      <flex-table #FlexTableConfiguredPhones [dataSource]="dataSourceConfiguredPhones"
        [tableConfig]="tableConfigConfiguredPhones" [columnTemplates]="{
          mac_addr: tplMacAddr,
          extension_with_owner: tplSipphoneExtension,
          action: tplActionConfiguredPhones
        }">
        <ng-template #tplMacAddr let-col="col" let-item="row">
          {{ item.mac_addr }}
        </ng-template>

        <ng-template #tplSipphoneExtension let-col="col" let-item="row">
          {{ item.sipphone?.extension_with_owner }}
        </ng-template>

        <ng-template #tplActionConfiguredPhones let-item="row">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div>
              <button mat-icon-button [title]="'public.change'|translate" (click)="changeConfigPhone(item)"
                data-ci="change-sipphone">
                <mat-icon>settings</mat-icon>
              </button>
            </div>
            <div>
              <button mat-icon-button [title]="'public.remove'|translate" class="mat-text-warn"
                (click)="removeSipPhone(item)" data-ci="remove-sipphone">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div>
              <button mat-icon-button [title]="'phone_config_request.content.download_config_file'|translate"
                (click)="downloadConfigSipphone(item)" data-ci="download-configure-sipphone">
                <mat-icon>download</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </flex-table>
    </div>

  </div>
