import * as _ from 'lodash';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IFlexTableConfig, parseDateTime, FlexDataSource, localNow, DialogService, FlexTableComponent, FormService } from '@wephone-utils';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { FlexBasePage } from '@wephone-core-ui';
import { ICallQualify, IQualifyResult, QualificationService } from 'src/app/wephone-common/qualification';
import { _tk, _ti } from '@wephone-translation';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { DateAdapter } from '@angular/material/core';
import { ICallInfoDataChanged, ICallLog } from '@wephone-app-phone/pages/history/calllog.i';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { CrmContactService } from '@wephone-core/service/crm-contact.service';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { UserRepository } from '@wephone-core/model/repository/user';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { UserEntity } from '@wephone-core/model/entity/user';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { CallInfoModalComponent } from '@wephone-common/modals/call-info-modal/call-info-modal.component';
import { AgentService } from '@wephone/services/agent.service';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { UserRole } from '@wephone-core/system';
import { PhoneNumberValidated } from '@wephone/services/form-validator';

@Component({
  selector: 'handled-calls',
  templateUrl: './handled-calls-page.html',
  styleUrls: ['./handled-calls-page.scss'],
})
export class HandledCallsComponent extends FlexBasePage implements OnInit {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  use_datepicker = true;
  showAgentList: boolean;
  tableConfig: IFlexTableConfig;

  dataSource: FlexDataSource;
  filterForm: FormGroup;
  filtersReadonly = false;
  hasServiceGroup: boolean;

  activeCRM: EnterpriseCrmEntity;
  crmConfig: any;
  canMakeCall: boolean;
  userRole: UserRole;

  filteredUserIds: number[];
  filteredQueueIds: number[];
  filteredGroupIds: number[];

  private readonly _crmContactUrls: any = {};
  private readonly userList: UserEntity[];
  private readonly queueList: CallQueueEntity[];
  private readonly groupList: UserGroupEntity[];

  constructor(
    private readonly datasourceService: DataSourceService,
    private readonly qualService: QualificationService,
    private readonly fb: FormBuilder,
    private readonly crmContactService: CrmContactService,
    private readonly authService: AuthenticationService,
    private readonly dialogService: DialogService,
    readonly configManager: ConfigManager,
    dateAdapter: DateAdapter<Date>,
    private readonly agentService: AgentService,
    private readonly formService: FormService,
  ) {
    super();

    this.dateAdapter = dateAdapter;

    this.filterForm = this.fb.group({
      start_dt: [localNow().toJSDate(), [Validators.required]],
      end_dt: [localNow().toJSDate(), [Validators.required]],
      phone_number: ['', [PhoneNumberValidated()]],
      agents: [[]],
      queue_ids: [[]],
      groups: [[]],
      public_call_id: ['']
    });

    this.dataSource = this.datasourceService.createDataSource('callcenter_handled_call');
    this.dataSource.setOrder('start_dt', 'desc');

    this.hasServiceGroup = configManager.hasFeature('SERVICE_GROUP');
    this.userRole = authService.getUserRole();
    this.showAgentList = authService.isAdmin() || authService.isSupervisor();
    this.userList = EntityManager.getRepository<UserRepository>('UserRepository').getObjectList();
    this.queueList = EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').getObjectList() || [];
    this.groupList = EntityManager.getRepository<UserGroupRepository>('UserGroupRepository').getObjectList() || [];

    this.canMakeCall = this.authService.isAdmin() || this.authService.isSupervisor() || this.authService.isAgent();
  }

  ngOnInit(): void {
    this.tableConfig = {
      // rowHeight: 60,
      // focusedRowHeight: 200,
      multiSelect: false,
      enableFilter: false,
      enableExportCsv: this.authService.isAdmin() || this.authService.isSupervisor(),
      columns: [
        { name: 'handled_user_id', label: _tk('stats.handled_call.columns.agent') },
        { name: 'calling_number', label: _tk('call_queue.content.calling_number') },
        { name: 'called_number', label: _tk('call_queue.content.called_number') },
        { name: 'client_name', label: _tk('call_queue.content.client_name'), enableTooltip: true },
        { name: 'queue_name', label: _tk('stats.missed_call.columns.queue'), enableTooltip: true },
        { name: 'duration', label: _tk('cdr.duration') },
        { name: 'qualification', label: _tk('stats.handled_call.columns.qualification'), enableTooltip: true },
        { name: 'qualification_comment', label: _tk('stats.handled_call.columns.qualification_comment'), enableTooltip: true },
        { name: 'is_outgoing', label: _tk('stats.handled_call.columns.in_out_call') },
        { name: 'start_dt', label: _tk('stats.handled_call.columns.start_dt') },
        { name: 'action', label: _tk('public.action'), width: '220px' }
      ]
    };
    super.ngOnInit();
  }

  async resolveData(): Promise<any> {
    if (!this.activeCRM) {
      this.activeCRM = await this.crmContactService.getActiveCRM(false);
      this.crmConfig = this.crmContactService.getConfigCRM(this.activeCRM);
      if (this.activeCRM) {
        this._crmContactUrls[this.activeCRM.type] = {};
      }
    }

    if (!this.authService.isAdmin()) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      this.filteredUserIds = myProfile.managed_user_ids;
      this.filteredQueueIds = myProfile.managed_queue_ids;
      this.filteredGroupIds = myProfile.managed_group_ids;
    }

    this.applyFilter();
  }

  applyFilter(): void {
    const filterData = this.filterForm.value;
    const newFilters = this.formService.normalizeData(_.cloneDeep(filterData));
    const startDate = parseDateTime(newFilters.start_dt);
    newFilters.start_dt = startDate && startDate.toISODate();

    const endDate = parseDateTime(newFilters.end_dt);
    newFilters.end_dt = endDate && endDate.toISODate();

    if (this.flexTable) {
      // this.dataSource.setFilter(newFilters);
      this.flexTable.reloadDataSource(newFilters);
    } else {
      this.dataSource.load(newFilters);
    }
    this.filtersReadonly = true;
  }

  changeFilter(): void {
    this.filtersReadonly = false;
  }

  async makeCall(call: ICallLog): Promise<void> {
    try {
      await this.agentService.make_outcall(call.calling_number, call.queue_ids ? call.queue_ids[0] : null, call.called_number);
    } catch (error) {
      this.showError(_ti('public.message.error_occurred'));
    }
  }

  canQualify = (item: ICallLog): boolean => {
    if (this.userRole === UserRole.WATCHER) {
      return false;
    }

    if (!item.has_qualification) {
      return false;
    }

    if (this.userRole !== UserRole.ADMIN &&
      (
        this.filteredUserIds &&
        !_.intersection(this.filteredUserIds, item.handled_user_ids).length
      )
    ) {
      return false;
    }

    return item.is_outgoing && !!item.out_qualification_id || !item.is_outgoing && !!item.in_qualification_id;
  }

  async reQualify(call: ICallLog): Promise<void> {
    const callQualify: ICallQualify = {
      id: call.id,
      qualification_comment: call.qualification_comment,
      out_qualification_id: call.out_qualification_id,
      in_qualification_id: call.in_qualification_id,
      queue_ids: call.queue_ids,
      is_outgoing: call.is_outgoing,
      qualification_ids: call.qualification_ids,
      // qualification: call.qualification,
    };

    const result: IQualifyResult = await this.qualService.reQualify(callQualify);
    if (result) {
      _.extend(call, {
        qualification_ids: result.qualification_ids,
        qualification_comment: result.qualification_comment,
        qualification: result.qualification,
      });
    }

    this.flexTable.detectChanges();
  }

  async getCrmContactUrl(phoneNumber: string): Promise<string> {
    if (!this.activeCRM) {
      console.error('No enterprise crm active');
      return undefined;
    }
    let crmContactUrl = this._crmContactUrls[this.activeCRM.type][phoneNumber];
    if (!crmContactUrl) {
      try {
        const contactData = await this.crmContactService.getCrmContactByNumber(this.activeCRM.id, phoneNumber);
        crmContactUrl = contactData.profileUrl;
        this._crmContactUrls[this.activeCRM.type][phoneNumber] = crmContactUrl;
      } catch (e) {
        this.showError(e && e.error && e.error.message || _ti('crm_contact.message.not_found'));
      }
    }
    return crmContactUrl;
  }

  async openContactProfile(callLog: ICallLog): Promise<void> {
    const callNumber = callLog.is_outgoing ? callLog.called_number : callLog.calling_number;
    const url = await this.getCrmContactUrl(callNumber);
    if (url) {
      window.open(url, '_blank');
    }
  }

  changePeriodFilter(periodSelectData: { period?: string, start_dt?: Date, end_dt?: Date }): void {
    if (!periodSelectData) {
      console.error('PeriodSelect data is empty', periodSelectData);
      return;
    }
    if (periodSelectData.start_dt) {
      this.filterForm.get('start_dt').setValue(periodSelectData.start_dt);
    }
    if (periodSelectData.end_dt) {
      this.filterForm.get('end_dt').setValue(periodSelectData.end_dt);
    }

    this.applyFilter();
  }

  rendererUser(userIds: number[]): string[] {
    return this.userList.filter(u => userIds.includes(u.id)).map(u => u.name);
  }

  rendererQueue(queueIds: number[]): string[] {
    if (!_.isEmpty(queueIds)) {
      return this.queueList.filter(q => queueIds.includes(q.id)).map(q => q.queue_name);
    }
  }

  rendererGroup(groupIds: number[]): string[] {
    if (!_.isEmpty(groupIds)) {
      return this.groupList.filter(g => groupIds.includes(g.id)).map(g => g.name);
    }
  }

  clearValue(event: MouseEvent, controlName: string, multiple: boolean = false): void {
    event.stopPropagation();
    this.filterForm.get(controlName).setValue(multiple ? [] : null);
  }

  detailHandledCallInfo(call: ICallLog): void {
    this.dialogService.openDialog2(
      CallInfoModalComponent,
      {
        data: {
          cdr_id: call.id
        },
        padding: false
      },
      (resp?: ICallInfoDataChanged) => {
        if (!resp) {
          return;
        }

        if (resp.hasOwnProperty('qualification')) {
          call.qualification_ids = resp.qualification;
        }

        if (resp.hasOwnProperty('qualification_comment')) {
          call.qualification_comment = resp.qualification_comment;
        }

        if (resp.hasOwnProperty('qualification_displayed')) {
          call.qualification = resp.qualification_displayed;
        }

        if (resp.hasOwnProperty('vm_message_is_read')) {
          call.is_read = 1;
        }

        if (resp.hasOwnProperty('vm_message_readers')) {
          call.readers = resp.vm_message_readers;
        }

        this.flexTable.detectChanges();
      }
    );
  }
}
