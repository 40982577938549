import { NgModule } from '@angular/core';
import { NumberFormatPipe, WephoneUtilsModule } from '@wephone-utils';
import { FlexGridComponent } from './flex-grid/flex-grid';
import { AgGridModule } from 'ag-grid-angular';
import {
  TimeRenderer,
  TimeWithSecondRenderer,
} from './ag-renderers/time-renderer/time-renderer';
import {
  DurationRenderer,
  VoicemailNumberCellRenderer,
  VoicemailActionCellRenderer,
  PipeCellRenderer,
  DateRenderer,
  DateTimeRenderer,
  SoundRenderer,
  CalledNumberrRenderer,
  ActionRenderer,
  DirectionRenderer,
  ActionRecordingRenderer,
  CallDirectionRenderer,
  StatusRenderer,
  MessageRenderer,
  MissedCallsActionRenderer,
  TimeSlotGraphRenderer,
  ActionHandledCallsRenderer,
  RenderInoutColumn,
  RenderNature,
  DebugMessagesCallRenderer,
  AgentNameRenderer,
  DetailQueueRenderer,
} from './ag-renderers';
import { WephoneAudioModule } from '@wephone-audio';
import { PhoneNumberRenderer } from './ag-renderers/phone-number-renderer/phone-number-renderer';

@NgModule({
  imports: [
    WephoneAudioModule,
    WephoneUtilsModule,
    AgGridModule.withComponents([
      DurationRenderer,
      VoicemailNumberCellRenderer,
      VoicemailActionCellRenderer,
      PipeCellRenderer,
      DateRenderer,
      DateTimeRenderer,
      TimeRenderer,
      TimeWithSecondRenderer,
      SoundRenderer,
      CalledNumberrRenderer,
      ActionRenderer,
      DirectionRenderer,
      ActionRecordingRenderer,
      CallDirectionRenderer,
      StatusRenderer,
      MessageRenderer,
      MissedCallsActionRenderer,
      TimeSlotGraphRenderer,
      ActionHandledCallsRenderer,
      RenderInoutColumn,
      RenderNature,
      DebugMessagesCallRenderer,
      AgentNameRenderer,
      DetailQueueRenderer,
      PhoneNumberRenderer,
    ])
  ],
  exports: [
    AgGridModule,
    FlexGridComponent
  ],
  declarations: [
    FlexGridComponent,
    DurationRenderer,
    VoicemailNumberCellRenderer,
    VoicemailActionCellRenderer,
    PipeCellRenderer,
    DateRenderer,
    DateTimeRenderer,
    TimeRenderer,
    TimeWithSecondRenderer,
    TimeRenderer,
    TimeWithSecondRenderer,
    SoundRenderer,
    CalledNumberrRenderer,
    ActionRenderer,
    DirectionRenderer,
    ActionRecordingRenderer,
    CallDirectionRenderer,
    StatusRenderer,
    MessageRenderer,
    MissedCallsActionRenderer,
    TimeSlotGraphRenderer,
    ActionHandledCallsRenderer,
    RenderInoutColumn,
    RenderNature,
    DebugMessagesCallRenderer,
    AgentNameRenderer,
    DetailQueueRenderer,
    PhoneNumberRenderer,
  ],
  entryComponents: [
    FlexGridComponent
  ],
  providers: [
  ]
})
export class WephoneGridModule {
}
