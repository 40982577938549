import { Component, OnInit, ElementRef, Self, Input, EventEmitter, Output, Optional } from '@angular/core';
import { FlexMatInputWrapper } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormControl } from '@angular/forms';

@Component({
  selector: 'flex-time-picker',
  templateUrl: './flex-time-picker.component.html',
  styleUrls: ['./flex-time-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexTimePickerInput }]
})
export class FlexTimePickerInput extends FlexMatInputWrapper implements OnInit, MatFormFieldControl<any> {
  @Input() format: number; // 12 or 24
  @Input() min?: string; // string or DateTime
  @Input() max?: string; // string or DateTime
  @Input() nullable?: boolean; // Display clear time value button
  @Output() readonly timeSet = new EventEmitter<string>();

  flexTimePickerControl = new FormControl();

  constructor(
    elRef: ElementRef,
    @Optional()
    @Self()
    ngControl: NgControl
  ) {
    super(ngControl, elRef);

    // this.flexTimePickerControl = new FormControl();
    this.format = 24;
    this.enabled = true;
  }

  get wrappedControl(): FormControl {
    return this.flexTimePickerControl;
  }

  get controlType(): string {
    return 'flex-time-picker-input';
  }

  onChangeTime(): void {
    if (this.timeSet) {
      this.timeSet.emit(this.flexTimePickerControl.value);
    }
  }

  clearTimePicker(e: Event): void {
    e.stopPropagation();
    this.flexTimePickerControl.setValue(null);
    this.onChangeTime();
  }
}
