import { MatDialogConfig } from '@angular/material/dialog';

export interface IFlexDialogConfig extends MatDialogConfig {
  size?: 'xs' | 's' | 'l' | 'fs' | 'auto' | 'none';
  padding?: boolean;
  contentClass?: string;
}

export enum Colors {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn'
}

export interface DialogLayoutConfig {
  dialogTitle?: string;
  hideHeader?: boolean;
  hideCloseButton?: boolean;
  actions?: DialogActionButton[];
  contentClass?: string;
}

export interface DialogActionButton {
  id?: string;
  label?: string;
  icon?: string;
  action?: any;
  color?: Colors;
  visible?(): boolean;
  getLabel?(): string;
}

export interface DialogComponentClass {
  new(...args: any[]): IDialogComponent;
}

export interface IDialogComponent {
  dialogTitle?: string;
  dialogLeftActions?: DialogActionButton[];
  dialogRightActions?: DialogActionButton[];
  cancelButton: DialogActionButton;
  backButton: DialogActionButton;

  setModalComponent(dlg: IDialogWrapper): void;
  getDialogLeftActions(): DialogActionButton[];
  getDialogRightActions(): DialogActionButton[];
}

export interface IDialogWrapper {
  createSubComponent(): void;
  returnValue(value: any): void;
}
