import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable()
export class LoggerService {
  constructor(private ngxLogger: NGXLogger,
              private messageService: MessageService)
  {
    messageService.subscribe('logger:log',
      msg => {
        const msgArgs = msg.message_args || [];
        if (msg.level === 'error') {
          this.error(msg.message, ...msgArgs);
        } else if (msg.level === 'warn') {
          this.warn(msg.message, ...msgArgs);
        } else {
          this.debug(msg.message, ...msgArgs);
        }
      }
    );
  }

  trace(message: any, ...additional: any[]): void {
    return this.ngxLogger.trace(message, ...additional);
  }

  log(message: any, ...additional: any[]): void {
    return this.ngxLogger.debug(message, ...additional);
  }

  debug(message: any, ...additional: any[]): void {
    return this.ngxLogger.debug(message, ...additional);
  }

  warn(message: any, ...additional: any[]): void {
    return this.ngxLogger.warn(message, ...additional);
  }

  error(message: any, ...additional: any[]): void {
    return this.ngxLogger.error(message, ...additional);
  }
}
