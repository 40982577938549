import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

export const AsciiValidator = (control: FormControl) => {
  if (control.value) {
    const hasMoreThanAscii = !/^[\u0000-\u007f]*$/.test(control.value);
    if (hasMoreThanAscii) {
      return { ascii: true };
    }
  }
  return null;
};
