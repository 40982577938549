import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IPhonepadPickerOptionType } from './phonepad-picker.i';

@Component({
  selector: 'phonepad-picker',
  templateUrl: './phonepad-picker.component.html',
  styleUrls: ['./phonepad-picker.component.scss']
})
export class PhonepadPickerComponent implements OnInit, OnChanges {
  static MaxKeyNumber = 5;
  static KeySeparated = ',';

  @Input() keys: string[] = [];
  @Input() options: IPhonepadPickerOptionType;
  @Input() multiple: boolean;
  @Output() readonly valueChange: EventEmitter<any> = new EventEmitter<any>();

  private initilized = false;

  selectedKeys: string[];
  padOpened = false;
  availableKeys: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  sKeys: string;
  errorCode: string;
  inputKeyMaxLength: number;
  keyLength: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initilized) {
      return;
    }

    if ('options' in changes) {
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initData();
    this.initilized = true;
  }

  private initData(): void {
    this.options = _.cloneDeep(this.options || {});
    this.options.display_grouped_number = this.options.display_grouped_number || true;
    this.keyLength = this.options.hasOwnProperty('key_length') ? this.options.key_length : 1;

    this.selectedKeys = _.cloneDeep(this.keys || []);
    this.sKeys = this.selectedKeys.join(PhonepadPickerComponent.KeySeparated);

    this.inputKeyMaxLength = this.keyLength * PhonepadPickerComponent.MaxKeyNumber + (PhonepadPickerComponent.MaxKeyNumber - 1);
    if (this.keyLength === 0) {
      this.inputKeyMaxLength = undefined;
    }
  }

  displayKeys(): string {
    if (_.isEmpty(this.selectedKeys)) {
      return '';
    }
    if (!this.options.display_grouped_number) {
      return this.selectedKeys.join(PhonepadPickerComponent.KeySeparated);
    }

    this.selectedKeys.sort();
    const keyarr: string[] = [];
    let keyarritem: string[] = [];
    let j = 0;
    for (let i = 0; i < this.selectedKeys.length; i++) {
      j = i;
      keyarritem = [this.selectedKeys[i]];
      if (/^\d+$/.test(this.selectedKeys[i])) {
        while (
          typeof this.selectedKeys[j + 1] !== 'undefined' &&
          /^\d+$/.test(this.selectedKeys[j + 1]) &&
          parseInt(this.selectedKeys[j]) + 1 === parseInt(this.selectedKeys[j + 1])
        ) {
          j = j + 1;
        }
        if (j !== i) {
          keyarritem.push(this.selectedKeys[j]);
        }
      }
      keyarr.push(keyarritem.join('~'));
      i = j;
    }
    return keyarr.join(PhonepadPickerComponent.KeySeparated);
  }

  updateKeys(value: string): void {
    const arrKey = value && value.replace(/\s/g, '').split(PhonepadPickerComponent.KeySeparated) || [];
    this.inputKey(arrKey);
  }

  isSelectedKey = (key: string) => {
    return this.selectedKeys && this.selectedKeys.includes(key);
  }

  changeValue(): void {
    if (this.valueChange) {
      setTimeout(() => {
        this.valueChange.emit({ keys: this.selectedKeys, error: this.errorCode });
      });
    }
  }

  tooglePicker(): void {
    this.padOpened = !this.padOpened;
  }

  closePicker(): void {
    this.padOpened = false;
  }

  clickKey(key: string): void {
    if (!this.multiple) {
      this.inputKey([key]);
      this.closePicker();
      return;
    }

    const keys = _.cloneDeep(this.selectedKeys);
    const keyIndex = keys.indexOf(key);
    if (keyIndex === -1) {
      keys.push(key);
    } else {
      keys.splice(keyIndex, 1);
    }

    this.inputKey(keys);
  }

  validateKeys(keys: string[]): boolean {
    this.errorCode = undefined;
    if (_.isEmpty(keys)) {
      this.errorCode = 'UNDEFINED';
      console.error('Keys array was undefined');
      return;
    }

    for (const key of keys) {
      if (!key || (this.keyLength !== 0 && key && key.length !== this.keyLength)) {
        console.error('Key length invalid', key);
        this.errorCode = 'LENGTH_INVALID';
        return false;
      }

      // allowed keys has contain character '*'
      let filteredKey = key.replace(/[*]/g, '');
      if (this.keyLength !== 0) {
        // allowed keys has contain character '#' if key length was not required
        filteredKey = filteredKey.replace(/[#]/g, '');
      }

      if (filteredKey && /^\d+$/.test(filteredKey) === false) {
        console.error('Key invalid', key);
        this.errorCode = 'FORMAT_INVALID';
        return false;
      }

      // if ((keys.filter(i => i === key)).length > 1) {
      //   console.error(`Key ${key} was duplicated`);
      //   this.errorCode = 'DUPLICATED';
      //   return false;
      // }
    }
    return true;
  }

  inputKey(keys: string[]): void {
    this.validateKeys(keys);
    this.selectedKeys = keys;
    this.changeValue();
  }
}
