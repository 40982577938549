import * as _ from 'lodash';
import { ParamRoutableComponent } from './param-routable-component';
// import { DynamicFilterSource } from '../flex-table/datasource/dynamic-filter-source';
import { ComponentFactoryResolver, ViewChild, OnInit, ComponentFactory, Directive } from '@angular/core';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { FlexAnchorDirective } from '../../directives/flex-anchor/flex-anchor.directive';
import { Router } from '@angular/router';
import { AccessRight } from '@wephone-core/system';

export type BadgeFunction = () => void;

export interface ITabSubRoute {
    name: string;
    component: any;
    label: string;
    icon?: string;
    default?: boolean;
    cache?: boolean;
    hidden?: boolean;
    badge?: number | BadgeFunction;
    // accessPermission?: AccessRight;
}

@Directive()
export class RoutableTabGroup extends ParamRoutableComponent {
    childRoutes: ITabSubRoute[] = [];
    // Example of value of childRoutes
    // [
    //    {'name': 'dashboard', title: 'My Dashboard', component: MyComponent, default: false }
    // ];
    private viewNames: string[];
    private viewCache = {};

    private componentFactoryResolver: ComponentFactoryResolver;
    private router: Router;
    private currentViewName: string;
    isLoading = false;

    @ViewChild(FlexAnchorDirective, { static: true }) anchor: FlexAnchorDirective;

    constructor() {
        super();
        this.componentFactoryResolver = StaticServiceLocator.injector.get(ComponentFactoryResolver);
        this.router = StaticServiceLocator.injector.get(Router);
    }

    protected onRouteQueryParamChange(params: any): any {
        const viewName = params.view;
        this.openView(viewName);
    }

    openView(viewName: string): void {
        const routeDef: ITabSubRoute = this.getRouteDefinitionByViewName(viewName);
        if (!routeDef) {
            return;
        }
        const routeViewName = routeDef.name; // routeDef may be a default route with a different name

        this.isLoading = true;
        const viewContainerRef = this.anchor.viewContainerRef;
        if (this.currentViewName) {
            const currentRouteDef = this.getRouteDefinitionByViewName(this.currentViewName);
            if (currentRouteDef.cache === undefined || currentRouteDef.cache) {
                this.viewCache[this.currentViewName] = viewContainerRef.detach();
            }
        }
        viewContainerRef.clear();

        if (routeViewName in this.viewCache) {
            try {
                this.currentViewName = routeViewName;
                viewContainerRef.insert(this.viewCache[routeViewName]);
                this.isLoading = false;
                return;
            } catch (e) {
                console.error('Cannot restore view from cache', e);
            }
        }

        const component = routeDef.component;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

        setTimeout(
            () => {
                const componentRef = viewContainerRef.createComponent(componentFactory);
                this.currentViewName = routeViewName;
                this.isLoading = false;
            },
            1
        );
    }

    getRouteDefinitionByViewName(viewName: string): ITabSubRoute {
        let defaultRoute;

        if (this.childRoutes) {
            for (const r of this.childRoutes) {
                if (r.name === viewName) {
                    return r;
                }
                if (r.default) {
                    defaultRoute = r;
                }
            }
        }

        if (defaultRoute) {
            return defaultRoute;
        }
        if (this.childRoutes) {
            return this.childRoutes[0];
        }
    }
}
