import { EntityManager } from '@wephone-core/service/entity_manager';
import { Entity, datafield, mapped_datafield } from '@wephone-core/model/model';
import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';
import { QueueTransferNumberEntity } from '@wephone-core/model/entity/queue_transfernumber';
import { DidEntity } from '@wephone-core/model/entity/did';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { CallQueueItemEntity } from '@wephone-core/model/entity/callqueueitem';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { GroupEntity } from '@wephone-core/model/entity/group';
import { QueueTransferNumberRepository } from '@wephone-core/model/repository/queue_transfernumber';
import { ICallQueueEntity, SurveyDirection } from '@wephone-core/model/entity/callqueue.i';
import { IRoutingAppDestinationData, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { VoiceMailBoxEntity } from './voicemail_box';
import { CrmRoutingRuleEntity } from './crm_routing_rule';
import * as _ from 'lodash';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { IvrEvaluationEntity } from './ivr_evaluation';
import { OutcallCampaignEntity } from './outcallcampaign';
import { OutcallCampaignRepository } from '../repository/outcallcampaign';
import { UserEntity } from './user';
import { IAgentEntity } from './agent.i';
import { IUserEntity } from './user.i';
import { DateTime } from 'luxon';
import { parseDateTime } from '@wephone-utils';

export enum RecordingMode {
  RecordingAuthorized = 0,
  AlwaysRecord = 1,
  NeverRecord = 2,
}

export enum BoOpenDerection {
  BOTH = 0,
  INCOMING = 1,
  OUTGOING = 2,
}

export enum RequestCallbackKey {
  NUMBER_SIGN = '#',
  ASTERISK = '*',
}

export enum RequestCallbackConfirmKey {
  NONE = '',
  NUMBER_SIGN = '#',
  ASTERISK = '*',
}

export enum BoType {
  NULL = 0,
  CONTACT = 1,
  URL = 2
}

export enum BoOpenIn {
  IFrame = 0,
  NewTab = 1
}

export enum AgentDisplayNumer {
  CUSTOMER_PHONE = 0,
  CALLER_NUMBER = 1,
  DID_NUMBER = 2
}



export class CallQueueEntity extends Entity implements ICallQueueEntity {
  static object_type_id = 'callqueue';

  static BO_TYPE_NULL = BoType.NULL;
  static BO_TYPE_CONTACT = BoType.CONTACT;
  static BO_TYPE_URL = BoType.URL;

  static AGENT_DISPLAY_NUM_CUSTOMER_PHONE = AgentDisplayNumer.CUSTOMER_PHONE;
  static AGENT_DISPLAY_NUM_CALLER_NUMBER = AgentDisplayNumer.CALLER_NUMBER;
  static AGENT_DISPLAY_NUM_DID_NUMBER = AgentDisplayNumer.DID_NUMBER;

  id: number;
  @datafield
  queue_name: string;
  @datafield
  group_id: number;
  @mapped_datafield('vm_id')
  voicemail_id: number;
  @mapped_datafield('vm_enabled')
  voicemail_enabled: number;
  @datafield
  vm_on_timeout: number;
  @datafield
  recording_mode: RecordingMode;
  @datafield
  recording_percentage: number;
  @datafield
  ewt_enable: number;
  @datafield
  ewt_data: any;
  @mapped_datafield('use_backup_phone')
  used_backup_phone: number;
  @mapped_datafield('calendar_id')
  opening_hour_calendar_id: number;
  @datafield
  has_qualification: number;
  @datafield
  in_qualification_id: number;
  @datafield
  out_qualification_id: number;
  @datafield
  aftercall_pause_mode: number;
  @datafield
  is_archived: number;
  @datafield
  is_exclusive: number;
  @datafield
  analysis_enabled: number;
  @datafield
  analysis_start_dt: DateTime;

  agent_ids: number[];

  @datafield queue_priority: number;
  @datafield bo_url: string;
  @datafield bo_open_derection: number;
  @datafield logo_id: number;
  @datafield max_inqueue_time: number;
  @datafield after_call_time: number;
  @datafield bo_type: BoType;
  @datafield bo_in_new_tab: BoOpenIn;
  @datafield languages: string[];
  @datafield seconds_before_backup: number;
  @datafield greeting_file_id: number;
  @datafield waiting_music_file_id: number;
  @datafield moh_file_id: number;
  @datafield opening_hour_file_id: number;
  @datafield no_agent_file_id: number;
  @datafield agent_selection_mode_main: number;
  @datafield agent_selection_mode_backup: number;
  @datafield alias: string;
  @datafield agent_display_number_type: AgentDisplayNumer;
  @datafield agent_display_number_id: number;
  @datafield eoc_survey_id: number;
  @datafield eoc_survey_min_duration = 0;
  @datafield eoc_survey_direction: SurveyDirection = SurveyDirection.NONE;
  @datafield default_did_id: number;
  @datafield play_entire_welcome: number;
  @datafield silent: number;
  @datafield agent_ring_duration: number;
  @datafield qualification_required: number;
  @datafield result_notification_url: string;
  @datafield availability: number;
  @datafield satisfaction_survey_enable: number;
  @datafield survey_prompt: number;
  @datafield survey_thank_msg: number;
  @datafield dedicated_user_id: number; // Deprecated since October 2020
  @datafield action_not_office_hour: IRoutingAppDestinationData;
  @datafield request_callback_enable = 0;
  @datafield request_callback_key: RequestCallbackKey = RequestCallbackKey.ASTERISK;
  @datafield request_callback_sound_id: number;
  @datafield request_callback_confirm_key: RequestCallbackConfirmKey = RequestCallbackConfirmKey.NONE;
  @datafield request_callback_confirmation_sound_id: number;
  @datafield request_callback_after = 0;
  @datafield request_callback_annon_sound_id: number;
  @datafield wait_timeout_config: IRoutingAppDestinationData;
  @datafield agenda_closed_config: IRoutingAppDestinationData;

  // voicemail_by_mail_enabled: number;
  // voicemail_in_attachement: number;
  // voicemail_by_mail_addrs: string[];

  xapi_agent_search: string;
  is_outbound_campaign: number;

  main_agents: CallQueueAgentLinkEntity[] = [];
  backup_agents: CallQueueAgentLinkEntity[] = [];
  transfer_numbers: QueueTransferNumberEntity[] = [];
  did_list: DidEntity[] = [];
  _greeting_file: FileEntryEntity;
  _waiting_music_file: FileEntryEntity;
  _moh_file: FileEntryEntity;
  _opening_hour_file: FileEntryEntity;
  _no_agent_file: FileEntryEntity;
  _survey_prompt_file: FileEntryEntity;
  _survey_thank_msg_file: FileEntryEntity;
  _mailbox: VoiceMailBoxEntity;
  _request_callback_sound: FileEntryEntity;
  _request_callback_confirmation_sound: FileEntryEntity;
  _request_callback_annon_sound: FileEntryEntity;
  _outcall_campaign: OutcallCampaignEntity;

  private _group: UserGroupEntity | GroupEntity;

  setId(id = null): void {
    if (id) {
      this.id = id;
    } else if (this.id) {
      delete this.id;
    }
  }

  // get dedicated_user(): UserEntity {
  //   if (this.dedicated_user_id) {
  //     return EntityManager.getRepository('UserRepository').getObjectById(this.dedicated_user_id) as UserEntity;
  //   }
  // }

  get group(): UserGroupEntity | GroupEntity {
    if (this.group_id) {
      if (!this._group) {
        this._group = EntityManager.getRepository('UserGroupRepository').getObjectById(this.group_id) as UserGroupEntity;
      }
      return this._group;
    }
  }

  set group(group: UserGroupEntity | GroupEntity) {
    this.group_id = group ? group.getId() : null;
    this._group = group;
  }

  get eoc_ivr_menu(): IvrEvaluationEntity {
    if (this.eoc_survey_id) {
      return EntityManager.getRepository('IvrEvaluationRepository').getObjectById(this.eoc_survey_id) as IvrEvaluationEntity;
    }
    return null;
  }

  set eoc_ivr_menu(ivrEvaluation: IvrEvaluationEntity) {
    this.eoc_survey_id = ivrEvaluation ? ivrEvaluation.id : null;
  }

  get in_qualification(): QualificationEntity {
    if (this.in_qualification_id) {
      return EntityManager.getRepository('QualificationRepository').getObjectById(this.in_qualification_id) as QualificationEntity;
    }
    return null;
  }

  set in_qualification(qualification: QualificationEntity) {
    this.in_qualification_id = null;
    if (qualification) {
      this.in_qualification_id = qualification.id;
    }
  }

  get out_qualification(): QualificationEntity {
    if (this.out_qualification_id) {
      return EntityManager.getRepository('QualificationRepository').getObjectById(this.out_qualification_id) as QualificationEntity;
    }
    return null;
  }

  set out_qualification(qualification: QualificationEntity) {
    this.out_qualification_id = null;
    if (qualification) {
      this.out_qualification_id = qualification.id;
    }
  }

  get outcall_campaign(): OutcallCampaignEntity {
    if (this._outcall_campaign !== undefined) {
      return this._outcall_campaign;
    }

    this._outcall_campaign = (EntityManager.getRepository('OutcallCampaignRepository') as OutcallCampaignRepository).getObjectByQueue(this);
    return this._outcall_campaign;
  }

  get is_outcall_campaign(): boolean {
    return !!this.outcall_campaign;
  }

  get opening_calendar(): OpeningHourCalendarEntity {
    if (this.opening_hour_calendar_id) {
      return EntityManager.getRepository('OpeningHourCalendarRepository').getObjectById(this.opening_hour_calendar_id) as OpeningHourCalendarEntity;
    }
  }

  set opening_calendar(calendar: OpeningHourCalendarEntity) {
    if (calendar) {
      this.opening_hour_calendar_id = calendar.id;
    }
  }

  get default_did(): DidEntity {
    const didList = EntityManager.getRepository('DidRepository').getObjectList();

    // if (this.dedicated_user_id) {
    //   return didList.find(d => d.linked_object_type === DidEntity.LINK_TYPE_USER && d.linked_object_id === this.dedicated_user_id);
    // }

    return didList.find(did => (did.action_not_office_hour
      && did.action_not_office_hour.application === RoutingAppName.callqueue
      && did.action_not_office_hour.params.queue === this.id)
      || (did.linked_object_type === DidEntity.LINK_TYPE_QUEUE
        && did.linked_object_id === this.id));
  }

  set default_did(default_did: DidEntity) {
    this.default_did_id = default_did ? default_did.getId() : null;
  }

  get defaultCallingNumber(): DidEntity {
    if (!this.default_did_id) {
      return undefined;
    }
    return EntityManager.getRepository('DidRepository').getObjectById(this.default_did_id);
  }

  get mailbox(): VoiceMailBoxEntity {
    const vmBox: VoiceMailBoxEntity = this.voicemail_id ? EntityManager.getRepository('VoiceMailBoxRepository').getObjectById(this.voicemail_id) : undefined;
    return vmBox || this._mailbox;
  }

  set mailbox(mailbox: VoiceMailBoxEntity) {
    this._mailbox = mailbox;
    this.voicemail_id = mailbox && mailbox.id;
  }

  get callqueue_items(): CallQueueItemEntity[] {
    return EntityManager.getRepository<any>('CallQueueItemRepository').getObjectListByQueueId(this.id);
  }

  get logo(): string {
    return this.logo_id ? 'resource/enterprise_file_entry/get?id=' + this.logo_id : null; // add logo_id to avoid caching image
  }

  get voicemail_by_mail_enabled(): number {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_by_mail_enabled : undefined;
  }

  set voicemail_by_mail_enabled(value: number) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for queue');
      return;
    }
    this.mailbox.voicemail_by_mail_enabled = value;
  }

  get voicemail_in_attachement(): number {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_in_attachement : undefined;
  }

  set voicemail_in_attachement(value: number) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for queue');
      return;
    }
    this.mailbox.voicemail_in_attachement = value;
  }

  get voicemail_by_mail_addrs(): string[] {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_by_mail_addrs : undefined;
  }

  set voicemail_by_mail_addrs(value: string[]) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for queue');
      return;
    }
    this.mailbox.voicemail_by_mail_addrs = value;
  }

  // Get all the available users that enable to add into queue
  get available_users(): IUserEntity[] {
    const users: UserEntity[] = EntityManager.getInstance().getRepository('UserRepository').getObjectList();
    if (!this.group_id) {
      return users;
    }

    return users.filter(x => _.isEmpty(x.group_ids) ||
      _.includes(x.group_ids, this.group_id)
    );
  }

  // Get all the available agents that enable to add into queue
  get available_agents(): IAgentEntity[] {
    const users: IUserEntity[] = this.available_users;

    return users.filter(x => !!x.agent).map(x => x.agent);
  }

  private resetObjectCached(object_data: any): void {
    const _cachedObjects = {
      group_id: '_group',
      greeting_file_id: '_greeting_file',
      waiting_music_file_id: '_waiting_music_file',
      moh_file_id: '_moh_file',
      opening_hour_file_id: '_no_agent_file',
      no_agent_file_id: '_no_agent_file',
      survey_prompt: '_survey_prompt_file',
      survey_thank_msg: '_survey_thank_msg_file',
    };

    for (const field of Object.keys(_cachedObjects)) {
      if (this[field] !== object_data[field]) {
        this[_cachedObjects[field]] = undefined;
      }
    }
  }

  setObjectData(object_data: any, fetch_related_data = true): void {
    this.resetObjectCached(object_data);
    super.setObjectData(object_data, fetch_related_data);

    if (this.bo_type && typeof this.bo_type === 'string') {
      this.bo_type = parseInt(this.bo_type, 10);
    }

    if (typeof object_data.ewt_data === 'string') {
      this.ewt_data = JSON.parse(object_data.ewt_data || '{}');
    }

    if (typeof object_data.action_not_office_hour === 'string') {
      this.action_not_office_hour = JSON.parse(object_data.action_not_office_hour || '{}');
    }

    if (typeof object_data.wait_timeout_config === 'string') {
      this.wait_timeout_config = JSON.parse(object_data.wait_timeout_config || '{}');
    }

    if (typeof object_data.agenda_closed_config === 'string') {
      this.agenda_closed_config = JSON.parse(object_data.agenda_closed_config || '{}');
    }

    this.analysis_start_dt = object_data.analysis_start_dt ? parseDateTime(object_data.analysis_start_dt) : undefined;
  }

  get_did_list(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      did.setActionHourJson();
      const action_office_hour = did.action_office_hour;
      if (action_office_hour && action_office_hour.application && action_office_hour.application === 'callqueue') {
        if (
          action_office_hour.params &&
          action_office_hour.params.queue &&
          action_office_hour.params.queue === this.getId()
        ) {
          if (did.route_priority === undefined) {
            did.route_priority = 2;
          }
          ret.push(did);
        }
      }
    }

    return ret.sort((a: DidEntity, b: DidEntity) => {
      return a.route_priority > b.route_priority ? -1 : 1;
    });
  }

  contain_agent_id(agent_id: number): boolean {
    return EntityManager.getRepository<any>('CallQueueAgentLinkRepository').isAgentInQueue(agent_id, this.id);
  }

  belong_to_groups(group_ids: number[]): boolean {
    return !group_ids || !group_ids.length || !this.group_id || group_ids.indexOf(this.group_id) !== -1;
  }

  getTransferNumberList(): QueueTransferNumberEntity[] {
    return EntityManager.getRepository<QueueTransferNumberRepository>(
      'QueueTransferNumberRepository'
    ).getObjectListByQueueId(this.id);
  }

  addNewTransferNumber(): void {
    this.transfer_numbers.push(EntityManager.getRepository(
      'QueueTransferNumberRepository'
    ).create() as QueueTransferNumberEntity);
  }

  fetchRelatedData(): void {
    const agentqueuelink_repo = EntityManager.getRepository<any>('CallQueueAgentLinkRepository');
    this.updateList(this.main_agents, agentqueuelink_repo.getObjectByQueueId(this.id, false));
    this.updateList(this.backup_agents, agentqueuelink_repo.getObjectByQueueId(this.id, true));

    // sort agents by position
    const sort_func = (agent_1, agent_2) => {
      if (agent_1.position === agent_2.position) {
        if (!agent_1.agent || !agent_2.agent) {
          return -1;
        }
        return agent_1.agent.name > agent_2.agent.name ? 1 : -1;
      }
      return agent_1.position > agent_2.position ? 1 : -1;
    };
    this.backup_agents.sort(sort_func);
    this.main_agents.sort(sort_func);

    this.updateList(
      this.transfer_numbers,
      EntityManager.getRepository<QueueTransferNumberRepository>(
        'QueueTransferNumberRepository'
      ).getObjectListByQueueId(this.id)
    );
    this.updateList(this.did_list, this.get_did_list());
    // this.group = EntityManager.getRepository('GroupRepository').getObjectById(this.group_id);
  }

  getAgents(): AgentEntity[] {
    const mainAgentLinks: CallQueueAgentLinkEntity[] = this.main_agents || [];
    const agentLinks: CallQueueAgentLinkEntity[] = mainAgentLinks.concat((this.backup_agents || []).filter(x => !_.includes(mainAgentLinks, x)));
    return agentLinks.map(x => x.agent);
  }

  agentIsMain(agent: AgentEntity): boolean {
    for (const agent_link of this.main_agents) {
      if (agent_link.agent.getId() === agent.getId()) {
        return true;
      }
    }
    return false;
  }

  // greeting_file
  set greeting_file(greeting_file: FileEntryEntity) {
    this.greeting_file_id = greeting_file ? greeting_file.getId() : null;
  }

  get greeting_file(): FileEntryEntity {
    if (this.greeting_file_id) {
      if (!this._greeting_file || this._greeting_file.id !== this.greeting_file_id) {
        this._greeting_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.greeting_file_id);
      }

      return this._greeting_file;
    }
  }

  // waiting_music_file
  set waiting_music_file(waiting_music_file: FileEntryEntity) {
    this.waiting_music_file_id = waiting_music_file ? waiting_music_file.getId() : null;
  }
  get waiting_music_file(): FileEntryEntity {
    if (this.waiting_music_file_id) {
      if (!this._waiting_music_file || this._waiting_music_file.id !== this.waiting_music_file_id) {
        this._waiting_music_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.waiting_music_file_id);
      }

      return this._waiting_music_file;
    }
  }
  // moh_file
  set moh_file(moh_file: FileEntryEntity) {
    this.moh_file_id = moh_file ? moh_file.getId() : null;
  }

  get moh_file(): FileEntryEntity {
    if (this.moh_file_id) {
      if (!this._moh_file || this._moh_file.id !== this.moh_file_id) {
        this._moh_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.moh_file_id);
      }

      return this._moh_file;
    }
  }
  // opening_hour_file
  set opening_hour_file(opening_hour_file: FileEntryEntity) {
    this.opening_hour_file_id = opening_hour_file ? opening_hour_file.getId() : null;
  }
  get opening_hour_file(): FileEntryEntity {
    if (this.opening_hour_file_id) {
      if (!this._opening_hour_file || this._opening_hour_file.id !== this.opening_hour_file_id) {
        this._opening_hour_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.opening_hour_file_id);
      }

      return this._opening_hour_file;
    }
  }
  // no_agent_file
  set no_agent_file(no_agent_file: FileEntryEntity) {
    this.no_agent_file_id = no_agent_file ? no_agent_file.getId() : null;
  }
  get no_agent_file(): FileEntryEntity {
    if (this.no_agent_file_id) {
      if (!this._no_agent_file || this._no_agent_file.id !== this.no_agent_file_id) {
        this._no_agent_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.no_agent_file_id);
      }

      return this._no_agent_file;
    }
  }

  set agent_display_number(did: DidEntity) {
    this.agent_display_number_id = did ? did.getId() : null;
  }

  get agent_display_number(): DidEntity {
    if (
      this.agent_display_number_id &&
      this.agent_display_number_type === CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER
    ) {
      return EntityManager.getRepository('DidRepository').getObjectById(this.agent_display_number_id);
    }
  }

  set survey_prompt_file(survey_prompt_file: FileEntryEntity) {
    this.survey_prompt = survey_prompt_file ? survey_prompt_file.getId() : undefined;
  }
  get survey_prompt_file(): FileEntryEntity {
    if (this.survey_prompt) {
      if (!this._survey_prompt_file || this._survey_prompt_file.id !== this.survey_prompt) {
        this._survey_prompt_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.survey_prompt);
      }

      return this._survey_prompt_file;
    }
  }

  set survey_thank_message_file(survey_thank_message_file: FileEntryEntity) {
    this.survey_thank_msg = survey_thank_message_file ? survey_thank_message_file.getId() : undefined;
  }
  get survey_thank_message_file(): FileEntryEntity {
    if (this.survey_thank_msg) {
      if (!this._survey_thank_msg_file || this._survey_thank_msg_file.id !== this.survey_thank_msg) {
        this._survey_thank_msg_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.survey_thank_msg);
      }

      return this._survey_thank_msg_file;
    }
  }

  get has_recording(): boolean {
    return this.recording_mode !== RecordingMode.NeverRecord;
  }

  get totalAgentNum(): number {
    return this.main_agents.length + this.backup_agents.length;
  }

  get connectedAgentNum(): number {
    return this.main_agents.filter(a => a.isConnected).length + this.backup_agents.filter(a => a.isConnected).length;
  }

  hasRoutedDid(did: DidEntity): boolean {
    if (did.routed_queue && did.routed_queue.id === this.id ||
      did.out_office_hours_routed_queue && did.out_office_hours_routed_queue.id === this.id ||
      (did.linked_object_type === DidEntity.LINK_TYPE_QUEUE && did.linked_object_id === this.id)) {
      return true;
    }
    return false;
  }

  /**
   * Get routed ivr-menus to call-queue but just get parent items
   */
  get routedIvrMenus(): IvrCustomMenuEntity[] {
    return RoutingAppService.getInstance().getRoutedIvrMenusToEntity(this.id, RoutingAppName.callqueue);
  }

  get routedDids(): DidEntity[] {
    return EntityManager.getRepository('DidRepository').getObjectList().filter((did: DidEntity) => {
      return this.hasRoutedDid(did);
    }).sort((a: DidEntity, b: DidEntity) => {
      return a.route_priority > b.route_priority ? 1 :
        (a.route_priority === b.route_priority ? (a.name > b.name ? 1 : -1) : -1);
    });
  }

  get routedCrmRoutings(): CrmRoutingRuleEntity[] {
    const ret: CrmRoutingRuleEntity[] = [];
    for (const item of EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList() as CrmRoutingRuleEntity[]) {
      if (item.routed_queue && item.routed_queue.id === this.id) {
        ret.push(item);
        continue;
      }
      if (!_.isEmpty(item.routed_queues_conditional) && _.includes(item.routed_queues_conditional.map(x => x.id), this.id)) {
        ret.push(item);
      }
    }
    return ret;
  }

  hasRouted(): boolean {
    const foundDid = EntityManager.getRepository('DidRepository').getObjectList().find((did: DidEntity) => this.hasRoutedDid(did));
    if (foundDid) {
      return true;
    }

    const foundCrmRouting = EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList().find((item: CrmRoutingRuleEntity) => {
      if (item.routed_queue && item.routed_queue.id === this.id) {
        return true;
      }
      if (!_.isEmpty(item.routed_queues_conditional) && _.includes(item.routed_queues_conditional.map(x => x.id), this.id)) {
        return true;
      }
    });

    if (foundCrmRouting) {
      return true;
    }

    if (!_.isEmpty(this.routedIvrMenus)) {
      return true;
    }

    return false;
  }

  // request callback sound
  set request_callback_sound(fileEntry: FileEntryEntity) {
    this.request_callback_sound_id = fileEntry ? fileEntry.getId() : null;
  }
  get request_callback_sound(): FileEntryEntity {
    if (this.request_callback_sound_id) {
      if (!this._request_callback_sound || this._request_callback_sound.id !== this.request_callback_sound_id) {
        this._request_callback_sound = EntityManager.getRepository('FileEntryRepository').getObjectById(this.request_callback_sound_id);
      }

      return this._request_callback_sound;
    }
  }

  // request callback confirmation sound
  set request_callback_confirmation_sound(fileEntry: FileEntryEntity) {
    this.request_callback_confirmation_sound_id = fileEntry ? fileEntry.getId() : null;
  }
  get request_callback_confirmation_sound(): FileEntryEntity {
    if (this.request_callback_confirmation_sound_id) {
      if (!this._request_callback_confirmation_sound
        || this._request_callback_confirmation_sound.id
        !== this.request_callback_confirmation_sound_id
      ) {
        this._request_callback_confirmation_sound =
          EntityManager.getRepository('FileEntryRepository').getObjectById(this.request_callback_confirmation_sound_id);
      }

      return this._request_callback_confirmation_sound;
    }
  }

  // request callback confirmation sound
  set request_callback_annon_sound(fileEntry: FileEntryEntity) {
    this.request_callback_annon_sound_id = fileEntry ? fileEntry.getId() : null;
  }
  get request_callback_annon_sound(): FileEntryEntity {
    if (this.request_callback_annon_sound_id) {
      if (!this._request_callback_annon_sound
        || this._request_callback_annon_sound.id
        !== this.request_callback_annon_sound_id
      ) {
        this._request_callback_annon_sound =
          EntityManager.getRepository('FileEntryRepository').getObjectById(this.request_callback_annon_sound_id);
      }

      return this._request_callback_annon_sound;
    }
  }
}
