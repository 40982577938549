<h3 class="ma-0 font-weight-bold" *ngIf="showFilter">{{ 'stats.history.filter_title'|translate }}</h3>
<ng-template #tplfilter>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start none"
    fxLayoutGap="10px" fxLayoutGap.xs="0px">
    <div fxFlex fxFlex.xs="auto">
      <mat-form-field class="full-width">
        <mat-label>{{'stats.filter.select_author'|translate}}</mat-label>
        <flex-select-user-input #flexSelectUserInput [multiple]="true" [showCheckAll]="true"
          placeholder="{{'stats.filter.select_author'|translate }}" [formControl]="filterAgentControl">
        </flex-select-user-input>
        <button mat-icon-button matSuffix (click)="clearFilter($event, filterAgentControl)"
          [disabled]="filterAgentControl.value.length == 0">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxFlex fxFlex.xs="auto">
      <mat-form-field class="full-width">
        <mat-label>{{'stats.filter.select_queue'|translate}}</mat-label>
        <flex-select-queue-input #flexSelectQueueInput [multiple]="true" [showCheckAll]="true"
          placeholder="{{'stats.filter.select_queue'|translate}}" [formControl]="filterQueueControl">
        </flex-select-queue-input>
        <button mat-icon-button matSuffix (click)="clearFilter($event, filterQueueControl)"
          [disabled]="filterQueueControl.value.length == 0">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <button data-ci="btn-submit" type="button" mat-raised-button color="accent" (click)="reloadClick()">
      {{ 'public.apply' | translate }}</button>
  </div>
</ng-template>
<flex-table #flexTable [dataSource]="dataSource" [tableConfig]="tableConfig"
  [columnTemplates]="{detail: tplDetail, action_dt: tplActionDate}" [filterTemplate]="tplfilter" fxLayout="column"
  fxFlex="grow">
  <ng-template #tplActionDate let-item="row">
      {{ item.action_dt|timeFormatDateTimeShort }}
  </ng-template>

  <ng-template #tplDetail let-item="row">
    <div class="col-detail-container">
      {{ item.detail }}
    </div>
  </ng-template>
</flex-table>
