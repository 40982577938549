<div fxFill fxFlex>
  <form [formGroup]="form" (ngSubmit)="submitForm()" class="pa-1" *ngIf="form">
    <div>
      <mat-form-field class="full-width">
        <input matInput placeholder="{{'pause_reason.content.label' | translate}}" formControlName="label">
        
        <mat-error [flexFormControlError]="form.get('label')"
          [customMessages]="{
            'duplicated': 'public.message.name_exist'|translate
          }"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox formControlName="nonWorkingPause">
        {{'pause_reason.content.non_working_pause' | translate }}
      </mat-checkbox>
    </div>
    <!-- <div>
      <mat-checkbox *ngIf="teams.length" formControlName="isShowTeams">{{ 'components.teamgroupmultipleselector.limit_with_team' | translate }}</mat-checkbox>
    </div>
    <div>
      <mat-form-field *ngIf="teams.length && form.get('isShowTeams').value" class="full-width">
        <mat-select placeholder="{{ 'components.teamgroupmultipleselector.choose_some_teams' | translate }}" multiple formControlName="pauseReasonTeams">
          <mat-option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
  </form>
</div>
