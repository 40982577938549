import { Component, OnInit, OnDestroy, Self, Optional, ElementRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';

@Component({
  selector: 'ivr-script-select-form-input',
  templateUrl: './ivr-script-select.component.html',
  styleUrls: ['./ivr-script-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: IvrScriptSelectFormInput }]
})
export class IvrScriptSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<IvrScriptEntity> {
  static nextId = 0;

  flexSelect: FormControl = new FormControl();
  ivrScriptList: Array<IvrScriptEntity>;
  flexSelectOptions: IFlexSelectOptions;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: IvrScriptEntity, filterString: string) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: IvrScriptEntity, b: IvrScriptEntity) => {
        return a.getId() === b.getId();
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.ivrScriptList = this.em.getRepository('IvrScriptRepository').getObjectList();
  }

  get controlType(): string {
    return 'ivr-script-select-form-input';
  }
}
