<div fxLayout="column" fxFill>
  <h2 class="filter-form">{{ 'menu.manage_outcall_campaign'|translate }}</h2>
  <ng-template #tplListFilter>
    <form [formGroup]="filterForm" class="full-width" fxLayout="column">
      <ng-container *ngIf="filtersReadonly;else BlockFormFilter">
        <div fxLayout="column" align="center" class="form-item">
          <span *ngIf="filterForm.get('start_dt').value && filterForm.get('end_dt').value">
            {{filterForm.get('start_dt').value | timeFormatDateShort }} -->
            {{filterForm.get('end_dt').value | timeFormatDateShort}}
          </span>
          <span *ngIf="filterForm.get('group_ids').value && filterForm.get('group_ids').value.length">
            <strong>{{ 'stats.filter.group'|translate }}</strong>: {{
            rendererGroups(filterForm.get('group_ids').value)|truncateArray }}
          </span>
          <span *ngIf="hasFilterByCampaignStatus()">
            <strong>{{ 'campaign.filter_campaign_stats'|translate }}</strong>: {{
            labelStatus[filterForm.get('campaign_status').value] }}
          </span>
          <span>
            <strong>
              {{ (filterForm.get('archived').value ? 'campaign.show_archived_items' :
              'campaign.show_active_items')|translate }}
            </strong>
          </span>

          <a data-ci="btn-change-filtering" class="link" (click)="filtersReadonly = false">
            {{ 'public.change'|translate }}</a>
        </div>
      </ng-container>

      <ng-template #BlockFormFilter>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.from' | translate }}</mat-label>
              <input matInput [matDatepicker]="startPicker" formControlName="start_dt"
                placeholder="{{ 'public.from' | translate }}">
              <div matSuffix fxLayout="row">
                <mat-datepicker-toggle [for]="startPicker"></mat-datepicker-toggle>
                <button mat-icon-button type="button" (click)="clearValue($event, 'start_dt', false)"
                  [disabled]="!filterForm.get('start_dt').value">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <mat-datepicker #startPicker></mat-datepicker>
              <!-- <mat-error
              *ngIf="filterForm.get('start_dt').hasError('flexDatepickerMin') || filterForm.get('start_dt').hasError('required')">
              {{ 'form.validator.required'|translate }}
            </mat-error> -->
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width">
              <mat-label>{{ 'public.to' | translate }}</mat-label>
              <input matInput [matDatepicker]="endPicker" formControlName="end_dt"
                placeholder="{{ 'public.to' | translate }}">
              <div matSuffix fxLayout="row">
                <mat-datepicker-toggle [for]="endPicker"></mat-datepicker-toggle>
                <button mat-icon-button type="button" (click)="clearValue($event, 'end_dt', false)"
                  [disabled]="!filterForm.get('end_dt').value">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <mat-datepicker #endPicker></mat-datepicker>

              <!-- <mat-error *ngIf="filterForm.get('end_dt').hasError('required')">
              {{ 'form.validator.required'|translate }}
            </mat-error> -->
              <mat-error
              *ngIf="filterForm.get('end_dt').hasError('flexDatepickerMin') || filterForm.get('end_dt').hasError('matDatepickerFilter')">
              {{ 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate: {min:
              filterForm.get('start_dt').value | timeFormatDateShort} }}
            </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width flex-select">
              <mat-label>{{'campaign.filter_campaign_stats'|translate}}</mat-label>
              <mat-select formControlName="campaign_status"
                [placeholder]="'campaign.select_campaign_status' | translate">
                <mat-option *ngFor="let campaignStatus of campaignStatusFilteredList" [value]="campaignStatus">
                  {{ labelStatus[campaignStatus] }}
                </mat-option>
              </mat-select>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'campaign_status', false)"
                [disabled]="!filterForm.get('campaign_status').value && filterForm.get('campaign_status').value != 0">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-form-field class="full-width flex-select">
              <mat-label>{{'stats.filter.select_group'|translate}}</mat-label>
              <flex-select-group-input [multiple]="true" [showCheckAll]="true" groupType="service"
                placeholder="{{ 'stats.filter.select_group' | translate }}" formControlName="group_ids">
              </flex-select-group-input>
              <button mat-icon-button matSuffix type="button" (click)="clearValue($event, 'group_ids', true)"
                [disabled]="!filterForm.get('group_ids').value || !filterForm.get('group_ids').value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div fxFlex="33%" fxFlex.xs="50%" class="filter-item">
            <mat-slide-toggle formControlName="archived">
              {{ 'campaign.show_archived_items'|translate }}
            </mat-slide-toggle>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button type="submit" mat-raised-button color="accent" (click)="applyFilter()">
            {{ 'public.apply' | translate}}
          </button>
        </div>
      </ng-template>
    </form>


    <form [formGroup]="form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start"
      fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterString"
          [hintText]="'campaign.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud [dataSource]="dataSource" [tableConfig]="tableConfig" [exportable]="true" exportFileName="exported_outcall_campaigns"
    [columnTemplates]="{'status': tplStatus, 'schedule_start_date': tplStartDate, 'schedule_end_date': tplEndDate, 'group_name': tplGroupName}"
    [sidePannelOptions]="sidePannelOptions" [filterTemplate]="tplListFilter" [focusedRow]="focusedRow"
    [sidePanelMaximized]="true" fxLayout="row" fxFlex="grow">
    <ng-template #tplStatus let-campaign="row">
      {{ labelStatus[campaign.campaign_status] }}
    </ng-template>
    <ng-template #tplStartDate let-campaign="row">
      {{ campaign.schedule_start_date | timeFormatDateShort }}
    </ng-template>
    <ng-template #tplEndDate let-campaign="row">
      {{ campaign.schedule_end_date | timeFormatDateShort }}
    </ng-template>
    <ng-template #tplGroupName let-campaign="row">
      <span *ngIf="campaign.queue?.group">
        {{ campaign.queue.group.name }}
      </span>
    </ng-template>
  </flex-crud-page>
</div>
