import { Injectable, NgZone } from '@angular/core';
import {
  IJsPhoneConfig,
  IJsPhoneDriver,
  ISipAccount,
  jsPhoneLib,
  IAudioDevice,
  ISIPAccountConfig,
  ISipCall
} from '@jsphonelib';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { PhoneCall } from './phonecall';
import { EntityEvent, EntityEventType } from '@wephone-core/model/repository/base_repository';
import { MyActiveCall } from '@wephone-core/model/entity/my_active_call';
import {
  MessageService,
  ToastService,
  Platform,
  isValidUrl,
  UIBlockerService,
  sleepAsync,
  DynamicScriptLoader,
  PhoneNumberService,
  DialogService,
  get_browser
} from '@wephone-utils';
import { TransferRequest, TransferDestinationType } from '@wephone-core/core/call_transfer';
import { UserEntity } from '@wephone-core/model/entity/user';
import { StorageService } from '@wephone-core/service/storage.service';
import { ConfigManager, EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { AppConfigService } from 'src/app/wephone-common/service/appconfig.service';
import { SipAccountService } from '@wephone-app-phone/services/phone/sip-account.service';
import {
  IPhoneService,
  IVocalCommandReply,
  VocalCommandAction,
  ConfigExecScript
} from '@wephone-app-phone/services/phone/phone.service.i';
import { ActiveCallInfo, IPhoneCall } from '@wephone-app-phone/services/phone/phonecall.i';
import { Router } from '@angular/router';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { Subject, Observable } from 'rxjs';
import { NetStatus } from '@wephone-core/service/net-status.service';
import { _ti } from '@wephone-translation';
import { QualificationService } from '@wephone-common/qualification/qualification.service';
import { UserProfileService, CallingNumber } from '@wephone/services/user-profile.service';
import { ElectronService } from 'ngx-electron';
import { ServiceWorkerService } from '../service-worker.service';
import { AgentService } from '@wephone/services/agent.service';
import { CdrService } from '@wephone-core/service/cdr_service';
import { EnterpriseParam } from '@wephone-core/system';

interface ITelUrlRequest {
  called_number: string;
  calling_number: string;
  data?: any;
}

@Injectable()
export class PhoneService implements IPhoneService {
  private static instance: PhoneService;

  private readonly URL_ACTIVE_CALL_INFO = 'callcenter/agent/get_active_call_info';
  private readonly URL_ACTIVE_CALL_INFO_BY_ID = 'callcenter/agent/get_active_call_info_v2/:sip_call_id';
  private readonly URL_MAKE_OUTCALL = 'pbx/outbound_callback';
  private readonly URL_TRANSFER_CALL = 'pbx/transfer';
  private readonly URL_TRANSFER_CONFIRM = 'pbx/transfer_confirm';
  private readonly URL_TRANSFER_CANCEL = 'pbx/transfer_cancel';
  private readonly URL_SET_ONHOLD = 'pbx/set_onhold';
  private readonly URL_SET_RECORDING = 'pbx/set_recording';
  private readonly URL_HANGUP_CALL = 'pbx/hangup';
  private readonly URL_GET_CRM_CONTACT = 'ws-v2/crm/contact';
  private readonly CONFERENCE_ADD_LEG_URL = 'pbx/conference_add_leg';
  private readonly CONFERENCE_REMOVE_LEG_URL = 'pbx/conference_remove_leg';

  phoneConfig: IJsPhoneConfig;
  private _activeCall: IPhoneCall;
  private _standbyCall: IPhoneCall;
  private jsphone: IJsPhoneDriver;
  private dialingNumber: string;
  private last_sip_call_id: string // Last active SIP call id
  // private calling_number: CallingNumber;
  private dialingNumberSubject: Subject<string> = new Subject<string>();
  private _ready = false;
  private serverPingIntervalId: NodeJS.Timeout;
  private CPUCheckIntervalId: NodeJS.Timeout;

  constructor(
    private httpEngine: HttpEngine,
    private sipAccountService: SipAccountService,
    private appConfig: AppConfigService,
    private em: EntityManager,
    private toastService: ToastService,
    private messageService: MessageService,
    private storage: StorageService,
    private router: Router,
    private platform: Platform,
    private authService: AuthenticationService,
    // private agentPanelService: AgentPanelService,
    private ngZone: NgZone,
    private netStatus: NetStatus,
    private qualificationService: QualificationService,
    private cdrService: CdrService,
    private uiBlocker: UIBlockerService,
    private phoneNumberService: PhoneNumberService,
    private userProfileService: UserProfileService,
    private scriptLoader: DynamicScriptLoader,
    private electron: ElectronService,
    private serviceWorker: ServiceWorkerService,
    // private evaluationService: CallEvaluationService,
    private readonly dialogService: DialogService,
    private readonly agentService: AgentService,
    private readonly settings: FlexIvrSettings,
    private readonly config: ConfigManager
  ) {
    this.watchMyActiveCallEvent();
    PhoneService.instance = this;

    // Reregister all SIP accounts when network becomes available (again)
    this.netStatus.onlineStateChanges.subscribe(connected => {
      if (this.jsphone) {
        this.jsphone.accountManager.setOfflineMode(!connected);
      }
    });

    // Unregister all SIP accounts on user logout
    this.messageService.subscribe('auth:logged_out', () => {
      if (this.jsphone) {
        this.jsphone.logout();
      }
    });

    window.addEventListener('beforeunload', (e: any) => {
      // if (this.hasCall()) {
      //   e.preventDefault();
      //   this.dialogService.showAlert(
      //     _ti('dialogs.warning'),
      //     _ti('call.actions.leave_page_has_call'),
      //   );
      //   e.returnValue = '';
      // } else {
      //   return true;
      // }
      return 'ban co muon roi khoi khong';
    });
  }

  static getInstance(): PhoneService {
    if (PhoneService.instance) {
      return PhoneService.instance;
    }

    throw Error('PhoneService.getInstance got called before PhoneService instance is created');
  }

  get dialingNumberChanges(): Observable<string> {
    return this.dialingNumberSubject.asObservable();
  }

  init(): void {
    let jsPhoneInstance: IJsPhoneDriver;
    if (window && window['jsphone_api']) {
      jsPhoneInstance = window['jsphone_api'];
    } else {
      // const platform = window['device'].platform;
      // console.log(platform);
      // if (platform === 'Android') {
      if (this.platform.isAndroid()) {
        console.log('Calling sispsteradroid');
        /*
        const sipster = new SipsterAndroid();
        setSipsterInstance(sipster);
        //jsPhoneInstance = new JsPhone(sipster);
        */
      }
    }
    if (jsPhoneInstance) {
      jsPhoneLib.setDriver(jsPhoneInstance);
      this.jsphone = jsPhoneInstance;
      this.jsphone.init();
    }

    this.messageService.subscribe('application:login:success', async () => {
      await this.loadPhoneConfig();
      if (this.phoneConfig.sipstack_enabled) {
        await this.loadJsPhonelib();
        this.jsphone.updateConfig(this.phoneConfig);
      }
      this.configureAccounts();
      // this.calling_number = await this.userProfileService.getDefaultCallingNumber();
    });

    this.messageService.subscribe('phone:open_dialer', async (telUrl: string) => {
      const hotkeyConfig = await this.storage.getAppConfig('hotkey_config');
      this.ngZone.run(
        // Run inside ngZone because this event is called from a nodejs callback
        () => {
          const callRequest = this.parseTelUrl(telUrl);
          if (callRequest.called_number === 'anwser') {
            if (this.activeCall.canAnswerHere()) {
              this.activeCall.answer();
            }
          } else {
            this.openDialer(callRequest.called_number, callRequest.calling_number);
            if ((this.canMakeCall() && hotkeyConfig.instant_dial) || this.platform.isSalesforcePhone()) {
              this.makeCall(callRequest.called_number, callRequest.calling_number);
            }
          }
        }
      );
    });

    this.messageService.subscribe('phone:call_action', data => {
      if (!this.activeCall) {
        return;
      }
      if (data['action'] === 'answer') {
        if (this.activeCall.canAnswerHere()) {
          this.activeCall.answer();
        }
      } else if (data['action'] === 'hangup') {
        this.activeCall.hangup();
      }
    });
  }

  getPhoneDriver(): IJsPhoneDriver {
    return this.jsphone;
  }

  setRingToneUrl(ringtoneId?: number): void {
    const cacheId = ringtoneId ? ringtoneId : new Date().valueOf();
    if (this.jsphone) {
      this.jsphone.setRingToneFile(
        `/ws-v2/d/${this.settings.enterprise_domain}/assets/user/current_ringtone.mp3?t=${cacheId}`
      );
    }
  }

  async loadJsPhonelib(): Promise<void> {
    if (!this.jsphone) {
      const version = window['APPLICATION_VERSION'];
      await this.scriptLoader.loadScript(`jsphonelib_webrtc_plugin.js?ver=${version}`);
      const jssipPlugin = window['jsphonelib_webrtc_plugin'];
      if (jssipPlugin) {
        jssipPlugin.initDriver(jsPhoneLib);
        this.jsphone = jsPhoneLib.getDriver();
        const publisher = {
          publish: (name, params) => {
            this.messageService.broadcast(name, params);
          }
        };
        const browser = get_browser();
        this.jsphone.setEventPublisher(publisher);
        this.jsphone.init(this.phoneConfig, `Graam ${version} - ${browser.name} ${browser.version}`);
        this.setRingToneUrl();
      }
    }
  }

  parseTelUrl(url: string): ITelUrlRequest {
    const ret: ITelUrlRequest = {
      called_number: url,
      calling_number: undefined
    };

    let s = decodeURIComponent(url);
    const protocolList = ['tel:', 'callto:', 'web+graam:', 'graam:'];
    for (const p of protocolList) {
      s = s.replace(p, '');
    }

    const i = s.indexOf('?');
    if (i >= 0) {
      ret.called_number = s.substring(0, i);
      const params = new URLSearchParams(s.substr(i + 1));
      if (params.has('calling_number')) {
        ret.calling_number = params.get('calling_number');
      }
      if (params.has('data')) {
        ret.data = params.get('data');
      }
    } else {
      ret.called_number = s;
    }

    return ret;
  }

  audioDevicesOK(): boolean {
    if (!this.jsphone) {
      return true;
    }
    return this.jsphone.systemHasInputDevice && this.jsphone.systemHasOutputDevice;
  }

  chromeNotificationOK(): boolean {
    return 'Notification' in window && Notification.permission === 'granted';
  }

  canMakeCall(): boolean {
    if (this.phoneConfig && this.phoneConfig.sipstack_enabled) {
      return this.isRegistered();
    }

    return this.authService.isAuthenticated();
  }

  get activeCall(): IPhoneCall {
    return this._activeCall;
  }

  get standbyCall(): IPhoneCall {
    return this._standbyCall;
  }

  hasCall(): boolean {
    return !!(this._activeCall || this._standbyCall);
  }

  getDialingNumber(): string {
    return this.dialingNumber;
  }

  setDialingNumber(dialing_number: string): void {
    if (dialing_number !== this.dialingNumber) {
      this.dialingNumber = dialing_number;
      this.dialingNumberSubject.next(this.dialingNumber);
    }
  }

  openDialer = (called_number?: string, calling_number?: string): void => {
    if (called_number) {
      this.setDialingNumber(this.phoneNumberService.cleanupNumber(called_number));
    }

    // if (calling_number) {
    //   const callingNumber = await this.userProfileService.getMyCallingNumberByNumber(calling_number);
    //   if (callingNumber) {
    //     this.setCallingNumber(callingNumber);
    //   }
    // }
    this.router.navigate(['dashboard'], { queryParams: { viewName: 'agent_page' } });
  };

  clearDialingNumber(): void {
    if (this.dialingNumber) {
      this.setDialingNumber('');
    }
  }

  isSipStackAvailable(): boolean {
    return this.jsphone ? true : false;
  }

  isSipStackEnabled(): boolean {
    return this.jsphone && this.phoneConfig && this.phoneConfig.sipstack_enabled;
  }

  publish(eventName, eventParams): void {
    this.messageService.broadcast(eventName, eventParams);
  }

  private subscribeJsPhoneEvents = () => {
    this.messageService.subscribe('jsphone:call_start', (sipCallId: string) => {
      const call: ISipCall = this.jsphone && this.jsphone.getCallBySipCallId(sipCallId) || undefined;
      console.log('New call received', call);
      if (!this.activeCall) {
        this._activeCall = new PhoneCall((this as any) as IPhoneService, this.messageService, call);

        this.notifyCallStart();

        if (this.authService.autoAnwser()) {
          this._activeCall.answer();
        }
      } else if (this.activeCall.sipCallId === call.sipCallId) {
        this._activeCall.setSipCall(call);
      } else if (!this.standbyCall) {
        this._standbyCall = this._activeCall;
        this._activeCall = new PhoneCall((this as any) as IPhoneService, this.messageService, call);
        this.notifyCallStart();
      } else if (this.standbyCall.sipCallId === call.sipCallId) {
        this._standbyCall.setSipCall(call);
      } else {
        console.warn('Call arrived when there are already 2 calls. Refusing it: ', call);
        call.hangup();
        return;
      }
    });

    this.messageService.subscribe('jsphone:call_end', (sipCallId: string) => {
      console.log(`Call with Id ${sipCallId} ended`);
      if (this._activeCall && this._activeCall.sipCallId === sipCallId) {
        const c = this._activeCall;
        this._activeCall = this._standbyCall;
        this._standbyCall = undefined;
        this.notifyCallEnd(c);
      } else if (this._standbyCall && this._standbyCall.sipCallId === sipCallId) {
        const c = this._standbyCall;
        this._standbyCall = undefined;
        this.notifyCallEnd(c);
      } else {
        this.messageService.broadcast('sentry:log', {
          message: `No match for SIP hangup message`,
          extra: { 
            hangup_sip_id: sipCallId ,
            active_call_id : this._activeCall && this._activeCall.sipCallId,
            standby_call_id : this._standbyCall && this._standbyCall.sipCallId
          }
        });
        return;
      }
    });
  };

  private async notifyCallStart(): Promise<void> {
    console.log('Call started, getActiveCallInfo now', DateTime.local().toFormat('hh:mm:ss'));
    await this._activeCall.setExtraInfo();
    this.messageService.broadcast('phone:call_start', this._activeCall);
  }

  private async notifyCallEnd(call: IPhoneCall): Promise<void> {
    if (!call.isInbound() && call.lastStatusCode === 403) {
      this.toastService.showError(_ti('connectivity.destination_denied'));
    }
    this.messageService.broadcast('phone:call_end', call);

    // Temporarily comment till getting new solution for detecting outcall from a missed-call
    /*
        if (call.wasContactWithMachine()) {
          const activeCall: ActiveCallInfo = call && call.extraInfo;
          console.log('active-call-info', activeCall);
          const missedCallId: number = activeCall && activeCall.details && activeCall.details.missed_call_id;
    
          if (missedCallId) {
            await this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('call_active.message.answer_machine_communication_confirm'), async () => {
              if (call.calldata) {
                await this.cdrService.updateCallOutboundVoicemail(call.calldata.user_call_id);
              } else {
                console.error('No call data');
              }
            });
          } else {
            console.log('Was contact with machine but no missed call found');
          }
        }
    */

    if (call.isCampaign() && call.wasAnsweringMachineFromCampaign()) {
      await this.dialogService.confirmDialog(
        _ti('dialogs.confirmation'),
        _ti('call_active.message.answer_machine_communication_confirm'),
        async () => {
          if (call.calldata) {
            await this.cdrService.updateCallOutboundAMInfo(call.calldata.outcall_job_id);
          } else {
            console.error('No call data');
          }
        }
      );
    }

    if (call.canQualify() && !call.pathQualIds.length) {
      if (call.isAnswered() || !call.isInbound()) {
        this.qualificationService.endCallOpenQualification(call);
      }
    }
  }

  runExternalApp(appPath: string, params: string[]): void {
    if (!this.electron || !this.electron.isElectronApp) {
      return;
    }
    console.log('PhoneService: Trying to launch external app', appPath, params);
    this.electron.ipcRenderer.send('mainwindow:run_external_app', { appPath, params });
  }

  async runExternalAppOnNewCall(call: PhoneCall): Promise<void> {
    if (!this.electron || !this.electron.isElectronApp) {
      return;
    }

    const externalAppConfig = await this.getConfigExecScript();
    if (
      externalAppConfig &&
      externalAppConfig.script_path &&
      call && ((call.isInbound() && externalAppConfig.inbound) || (!call.isInbound() && externalAppConfig.outbound))
    ) {
      const params = [call.remoteNumber, call.localNumber];

      console.log('PhoneService: Trying to launch external app', externalAppConfig.script_path, params);
      this.electron.ipcRenderer.send('mainwindow:run_external_app', { appPath: externalAppConfig.script_path, params });
    }
  }

  private watchMyActiveCallEvent(): void {
    this.em.getRepository('MyActiveCallRepository').event$.subscribe((event: EntityEvent) => {
      const callData = event.entity as MyActiveCall;
      console.log('watchMyActiveCallEvent callData', callData);
      const isNewCall = this.last_sip_call_id !== callData.sip_call_id;
      if (isNewCall) {
        this.last_sip_call_id = callData.sip_call_id;
      } 
      // else if (!this._activeCall) {
      //   console.log(`watchMyActiveCallEvent: Out of date active call event received ${callData.is_hungup}, is_answered: ${callData.is_answered} sip_call_id: ${callData.sip_call_id}`);
      //   return;
      // }

      // const sipCall: ISipCall = undefined;
      const sipCall: ISipCall = callData && callData.sip_call_id && this.jsphone && this.jsphone.getCallBySipCallId(callData.sip_call_id) || undefined;
      console.log(`watchMyActiveCallEvent web call data: is_hungup: ${callData.is_hungup}, is_answered: ${callData.is_answered} sip_call_id: ${callData.sip_call_id}`);
      console.log(`watchMyActiveCallEvent sipCall state : ${sipCall && sipCall.state}`);

      if ([EntityEventType.NEW, EntityEventType.UPDATE].indexOf(event.type) >= 0) {
        if (!this._activeCall) {
          if (isNewCall) { // Do not create new call for out of date active call event
            this._activeCall = new PhoneCall((this as any) as IPhoneService, this.messageService, sipCall, callData);

            // Uncomment this to simulate a waiting call
            // this._standbyCall = this.activeCall;
            this.messageService.broadcast('phone:call_start', this._activeCall);
          }
        } else if (this._activeCall.sipCallId === callData.sip_call_id) {
          this._activeCall.setCallData(callData);
        } else if (!this._standbyCall) {
          this._standbyCall = new PhoneCall((this as any) as IPhoneService, this.messageService, sipCall, callData);
          this.messageService.broadcast('phone:call_start', this._standbyCall);
        } else if (this._standbyCall.sipCallId === callData.sip_call_id) {
          this._standbyCall.setCallData(callData);
        }
      } else if (event.type === EntityEventType.REMOVE) {
        if (!callData || !callData.answer_time) {
          // Open missed call tab by broadcasting phone:missed_call event.
          this.messageService.broadcast('phone:missed_call');
        }
        if (this._activeCall && this._activeCall.sipCallId === callData.sip_call_id) {
          if (!this._activeCall.hasSipCall()) {
            const call = this._activeCall;
            this._activeCall = this._standbyCall;
            this._standbyCall = undefined;
            this.notifyCallEnd(call);
          }
        } else if (this._standbyCall && this._standbyCall.sipCallId === callData.sip_call_id) {
          if (!this._standbyCall.hasSipCall()) {
            const call = this._standbyCall;
            this._standbyCall = undefined;
            this.notifyCallEnd(call);
          }
        }
      }
    });
  }

  disable(): void {
    if (this.jsphone) {
      this.jsphone.enableSipStack(false);
    }
  }

  async configureAccounts(): Promise<void> {
    this._ready = false;
    try {
      if (this.jsphone) {
        this.jsphone.resetConfig();
        await sleepAsync(2000); // Wait 2 seconds for unregister process to complete
      }

      if (!this.phoneConfig.sipstack_enabled) {
        return;
      }
      if (!this.jsphone) {
        console.error('jsphone is not available. Cannot setup SIP accounts');
        return;
      }
      const account_list: ISIPAccountConfig[] = this.sipAccountService.getSipAccountList();
      if (!account_list) {
        return;
      }

      for (const a of account_list) {
        const acc_config: ISIPAccountConfig = {
          username: a.username,
          password: a.password,
          domain: a.domain,
          outbound_proxy: a.outbound_proxy,
          websocket_url: a.websocket_url,
          transcoding: a.transcoding
          // outbound_proxy: 'sip:' + a.domain + (a.sip_port ? ':' +  a.sip_port : '')
          // outbound_proxy: 'sip:sip.wephone.io:5091'
        };

        if (this.jsphone) {
          this.jsphone.configureAccount(acc_config, a.username + a.domain);
        }
        
        await sleepAsync(1000);
      }
      this.subscribeJsPhoneEvents();
      this.startPingSipServer();
//    this.startCheckingCPUFreeze();
    } finally {
      this._ready = true;
    }
  }

  get defaultAccount(): ISipAccount {
    return this.jsphone ? this.jsphone.accountManager.defaultAccount : undefined;
  }

  isRegistered(): boolean {
    return this.defaultAccount && this.defaultAccount.isRegistered();
  }

  isReady(): boolean {
    return this._ready;
  }

  getRegisterErrorMessage(): string {
    if (this.defaultAccount) {
      return this.defaultAccount.regStatusCode() + ' ' + this.defaultAccount.regStatusMessage();
    }
  }

  getSipDriverName(): string {
    return this.jsphone && this.jsphone.driverName;
  }

  getAudioDevices(): IAudioDevice[] {
    if (this.jsphone) {
      return this.jsphone.getAudioDevices();
    }
    return [];
  }

  // Deprecated
  makeCallByWebservice(called_number, calling_number?: string): Promise<void> {
    if (this.hasCall()) {
      throw new Error('Cannot make outbound call because there is already an active call');
    }

    if (!called_number) {
      throw new Error('Invalid destination number');
    }
    return this.httpEngine
      .apiPostV2(
        this.URL_MAKE_OUTCALL,
        {
          called_number,
          calling_number,
          auto_answer: 1
        },
        undefined,
        true
      )
      .then(
        response => {
          const status = this.agentService.getMyCallcenterStatus();
          if (status.phone_mode === 0) {
            this.toastService.showSuccess(_ti('phoneservice.callback_sipphone_ok'));
          } else {
            this.toastService.showSuccess(_ti('phoneservice.callback_secondary_ok'));
          }

          this.clearDialingNumber();
          console.log('make_outcall response', response);
        },
        err => {
          this.toastService.showError(_ti('phoneservice.call_failure'));
          console.error('make_outcall response', err);
        }
      );
  }

  async hangupCallByWebservice(call: IPhoneCall): Promise<any> {
    try {
      await this.httpEngine.apiPostV2(this.URL_HANGUP_CALL, {
        call_id: call.sipCallId
      });
    } catch (err) {
      if (call.calldata && call.calldata.id) {
        this.em.getRepository('MyActiveCallRepository').removeObjectById(call.calldata.id);
      }
      throw err;
    }
  }

  private async _makeCall(called_number: string, calling_number?: string, queue_id_in?: number): Promise<void> {
    if (this.hasCall()) {
      throw new Error('Cannot make outbound call because there is already an active call');
    }
    let dest: string = called_number;
    const queue_id = queue_id_in || this.userProfileService.getDefaultQueueId();
    if (!dest) {
      throw new Error('Invalid destination number');
    }

    dest = this.phoneNumberService.cleanupNumber(dest);
    this.setDialingNumber(dest);

    let normalizedFromNumber = calling_number;
    if (normalizedFromNumber) {
      normalizedFromNumber = this.phoneNumberService.normalizeNumber(normalizedFromNumber);
    } else {
      const callingNumber = this.getCallingNumber();
      if (callingNumber) {
        normalizedFromNumber = callingNumber.number;
      }
    }

    normalizedFromNumber = normalizedFromNumber && normalizedFromNumber.toLowerCase();
    let sipHeaders;
    if (queue_id) {
      sipHeaders = { 'X-Queue-Id': queue_id };
    }

    this.uiBlocker.block('Dialing, please wait...');
    await sleepAsync(100); // Sleep 100 seconds for the ui blocker to appear
    try {
      if (this.phoneConfig.sipstack_enabled) {
        if (this.jsphone) {
          await this.jsphone.makeCall(dest, normalizedFromNumber, sipHeaders);
        }
      } else {
        // const response = await this.makeCallByWebservice(dest, normalizedFromNumber);
        const response = await this.agentService.make_outcall(dest, queue_id, normalizedFromNumber, true);

        const status = this.agentService.getMyCallcenterStatus();
        if (status.phone_mode === 0) {
          this.toastService.showSuccess(_ti('phoneservice.callback_sipphone_ok'));
        } else {
          this.toastService.showSuccess(_ti('phoneservice.callback_secondary_ok'));
        }

        // this.clearDialingNumber();
        console.log('make_outcall response', response);
      }
      this.clearDialingNumber();
    } catch (e) {
      console.error('Cannot make call', e);
      this.toastService.showError(_ti('phoneservice.call_failure'));
      console.error('make_outcall response', e);
    } finally {
      this.uiBlocker.unblock();
    }
  }

  async loadPhoneConfig(): Promise<IJsPhoneConfig> {
    if (!this.phoneConfig) {
      this.phoneConfig = {
        input_device: undefined,
        output_device: undefined,
        ring_device: undefined,
        input_volume: 100,
        output_volume: 100,
        ring_volume: 100,
        sipstack_enabled: true,
        silent_mode: false,
        ask_user_on_new_device: 1
      };
      for (const k of Object.keys(this.phoneConfig)) {
        if (this.phoneConfig.hasOwnProperty(k)) {
          const v = await this.storage.getAppConfig<string>('jsphone.' + k);
          if (v != null) {
            // check for null or undefined value
            this.phoneConfig[k] = v;
          }
        }
      }

      // If disable_phone_in_iframe and app loaded within iframe, set sipstack_enabled false
      // console.log('GRAAM_IN_IFRAME', !!window['GRAAM_IN_IFRAME']);
      // console.log('disable_phone_in_iframe', this.config.getEnterpriseParamValue(EnterpriseParam.disable_phone_in_iframe));
      if (!!window['GRAAM_IN_IFRAME'] && this.config.getEnterpriseParamValue(EnterpriseParam.disable_phone_in_iframe)) {
        console.log('Set sipstack_enabled to false');
        this.phoneConfig.sipstack_enabled = false;
      }

      if (this.jsphone) {
        this.jsphone.updateConfig(this.phoneConfig);
        await this.savePhoneConfig(this.jsphone.getPhoneConfig());
      }
    }

    return this.phoneConfig;
  }

  async applyPhoneConfig(phoneConfig: IJsPhoneConfig): Promise<void> {
    await this.savePhoneConfig(phoneConfig);
    if (this.jsphone) {
      await this.jsphone.updateConfigAsync(this.phoneConfig);
    }
  }

  async savePhoneConfig(phoneConfig: IJsPhoneConfig): Promise<void> {
    const excludedFields = ['ringbacktone_file'];
    for (const k of Object.keys(phoneConfig)) {
      if (phoneConfig.hasOwnProperty(k) && excludedFields.indexOf(k) < 0) {
        const v = phoneConfig[k];
        this.phoneConfig[k] = v;
        await this.storage.setAppConfig('jsphone.' + k, this.phoneConfig[k]);
      }
    }
  }

  async makeCall(called_number: string, calling_number?: string, queue_id?: number): Promise<IPhoneCall> {
    if (!called_number) {
      this.toastService.showError(_ti('phoneservice.number_invalid'));
      return;
    }
    if (this.platform.isDesktop() && this.phoneConfig.sipstack_enabled && this.jsphone) {
      if (!this.jsphone.systemHasInputDevice || !this.jsphone.systemHasOutputDevice) {
        if (!this.jsphone.systemHasInputDevice) {
          this.toastService.showError('No Audio Input Device');
          return;
        }
        if (!this.jsphone.systemHasOutputDevice) {
          this.toastService.showError('No Audio Output Device');
          return;
        }
      }
    }
    this._makeCall(called_number, calling_number, queue_id);
  }

  // Current not in-used
  // setActiveCall(call: IPhoneCall): void {
  //   this._activeCall = call;
  // }

  getActiveCall(): IPhoneCall {
    return this._activeCall;
  }

  getStandbyCall(): IPhoneCall {
    return this._standbyCall;
  }

  terminateActiveCall(call: IPhoneCall): void {
    this.messageService.broadcast('phone:call_terminate', call);
  }

  clearActiveCall(): void {
    this._activeCall = undefined;
    this._standbyCall = undefined;
  }

  // setCallingNumber(callingNumber: CallingNumber): void {
  //   this.calling_number = callingNumber;
  // }

  private getCallingNumber(): CallingNumber {
    // return this.calling_number;
    return this.userProfileService.getMyDefaultCallingDID();
  }

  async transferCall(call: PhoneCall, transferRequest: TransferRequest): Promise<boolean> {
    let destination: string | number;
    if (transferRequest.destination_type === TransferDestinationType.USER) {
      destination = (transferRequest.destination as UserEntity).getId();
    } else {
      destination = transferRequest.destination;
    }

    let response = await this.httpEngine.apiPostV2(this.URL_TRANSFER_CALL, {
      call_id: call.sipCallId,
      dest_type: transferRequest.destination_type,
      dest: destination,
      assisted: transferRequest.assisted ? 1 : 0,
      transfer_reason: transferRequest.transfer_reason,
      params: transferRequest.params
    });
    if (response) {
      return true;
    }
  }

  conferenceAddLeg(call: PhoneCall, transferRequest: TransferRequest): Promise<any> {
    let destination: string | number;
    if (transferRequest.destination_type === TransferDestinationType.USER) {
      destination = (transferRequest.destination as UserEntity).getId();
    } else if (transferRequest.destination_type === TransferDestinationType.NUMBER) {
      destination = transferRequest.destination;
    }
    return this.httpEngine
      .apiPostV2(this.CONFERENCE_ADD_LEG_URL, {
        call_id: call.sipCallId,
        dest_type: transferRequest.destination_type,
        dest: destination
      })
      .then(
        res => {
          console.log('Conderence add leg seccess: ', res);
        },
        error => {
          console.error('Conderence add leg error: ', error);
        }
      );
  }

  conferenceRemoveLeg(call: PhoneCall): Promise<any> {
    return this.httpEngine
      .apiPostV2(this.CONFERENCE_REMOVE_LEG_URL, {
        call_id: call.sipCallId
      })
      .then(
        res => {
          console.log('Conderence add leg seccess: ', res);
        },
        error => {
          console.error('Conderence add leg error: ', error);
        }
      );
  }

  confirmTransfer(call: PhoneCall): Promise<any> {
    return this.httpEngine
      .apiPostV2(this.URL_TRANSFER_CONFIRM, {
        call_id: call.sipCallId
      })
      .then(
        response => {
          console.log('confirmTransfer response', response);
        },
        response => {
          console.error('confirmTransfer response', response);
        }
      );
  }

  cancelTransfer(call: PhoneCall): Promise<any> {
    return this.httpEngine
      .apiPostV2(this.URL_TRANSFER_CANCEL, {
        call_id: call.sipCallId
      })
      .then(
        res => {
          console.log('cancelTransfer request succeedded', res);
        },
        err => {
          console.error('cancelTransfer request failed', err);
        }
      );
  }

  setOnhold(call: PhoneCall, hold: boolean): Promise<boolean> {
    return this.httpEngine
      .apiPostV2(this.URL_SET_ONHOLD, {
        call_id: call.sipCallId,
        hold: hold ? 1 : 0
      })
      .then(
        res => {
          console.log('Onhold request succeeded', res);
          call.setActive(!hold);
          return true;
        },
        err => {
          console.error('Onhold request failed with error', err);
          return false;
        }
      );
  }

  setRecordingState(call: PhoneCall, enabled: boolean, discard: boolean): Promise<any> {
    return this.httpEngine
      .apiPostV2(this.URL_SET_RECORDING, {
        call_id: call.sipCallId,
        enabled: enabled ? 1 : 0,
        discard: discard ? 1 : 0
      })
      .then(
        res => {
          console.log('setRecordingState request succeeded', res);
        },
        err => {
          console.error('setRecordingState request failed', err);
        }
      );
  }

  startRecordingVocalCommand(): any {
    return this.jsphone ? this.jsphone.startRecordingVocalCommand() : undefined;
  }

  stopRecordingVocalCommand(): any {
    return this.jsphone ? this.jsphone.stopRecordingVocalCommand() : undefined;
  }

  isRecordingVoiceCommand(): boolean {
    return this.jsphone ? this.jsphone.isRecordingVoiceCommand() : undefined;
  }

  async getConfigExecScript(): Promise<ConfigExecScript> {
    const execScriptCfg: ConfigExecScript = {
      inbound: false,
      outbound: false,
      script_path: ''
    };
    const execScriptData: ConfigExecScript = await this.storage.getAppConfig('exec_script');
    _.extend(execScriptCfg, execScriptData);
    return execScriptCfg;
  }

  async setConfigExecScript(execScriptConfig: ConfigExecScript): Promise<void> {
    await this.storage.setAppConfig('exec_script', execScriptConfig);
  }

  async sendVocalCommandData(): Promise<any> {
    if (!this.jsphone) {
      console.error('No js phone driver available');
      return;
    }

    const data: ArrayBuffer = await this.jsphone.getVocalCommandData();
    const url = await this.storage.getAppConfig('vocal_command_url');
    try {
      const ret: IVocalCommandReply = await this.httpEngine.postArrayBufferAsFile(url, 'vocal-command.wav', data);
      console.log('Result returned from remote url for vocal command is', ret);
      if (ret.action === VocalCommandAction.OPEN_URL && isValidUrl(ret.params.url)) {
        window.open(ret.params.url, 'sm_call_info', undefined, true);
      }
    } catch (e) {
      console.error('Error sending vocal command to', url, e);
    }
  }

  getCrmContact(crmId: number, crmContactId: string, crmContactType: number): Promise<any> {
    return this.httpEngine.get(
      `${this.URL_GET_CRM_CONTACT}/${crmId}/${crmContactId}/${crmContactType ? crmContactType : 0}`
    );
  }

  getActivePlaybackDeviceName(): string {
    return this.jsphone ? this.jsphone.getActivePlaybackDeviceName() : undefined;
  }

  getActiveCaptureDeviceName(): string {
    return this.jsphone ? this.jsphone.getActiveCaptureDeviceName() : undefined;
  }

  getActiveRingDeviceName(): string {
    return this.jsphone ? this.jsphone.getActiveRingDeviceName() : undefined;
  }

  // callBO(url: string): void {
  //   this.httpEngine.post(url);
  // }

  isElectronApp(): boolean {
    return this.electron && this.electron.isElectronApp;
  }

  getActiveCallInfo(): Promise<ActiveCallInfo> {
    return this.httpEngine.apiGetV2(this.URL_ACTIVE_CALL_INFO, {});
  }

  getActiveCallInfoById(sipCallId: string): Promise<ActiveCallInfo> {
    return this.httpEngine.apiGetV2(this.URL_ACTIVE_CALL_INFO_BY_ID.replace(':sip_call_id', sipCallId), {});
  }

  startPingSipServer(): void {
    if (this.serverPingIntervalId || !this.jsphone) {
      console.error('No jsphone available or ping already started');
      return;
    }
    this.serverPingIntervalId = setInterval(async () => {
      if (this.isRegistered()) {
        try {
          const roundTripTime = await this.jsphone.sendPing();
          if (roundTripTime > 1000) {
            this.messageService.broadcast('sentry:log', {
              message: `SIP Ping round trip time is too high`,
              extra: { roundTripTime }
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, 30000);
  }

  startCheckingCPUFreeze(): void {
    if (this.CPUCheckIntervalId) {
      return;
    }
    let lastTime = Date.now();
    let lastSentryLogTime;
    this.CPUCheckIntervalId = setInterval(async () => {
      let delay = Date.now() - lastTime;
      try {
        if (delay > 3000) {
          if (!lastSentryLogTime || Date.now() - lastSentryLogTime > 300000) {
            const currentLocalTime = DateTime.local().toString();
            this.messageService.broadcast('sentry:log', {
              message: `CPU Freeze detected`,
              extra: { delay, localTime: currentLocalTime }
            });
            lastSentryLogTime = Date.now();
          }
        }
      } finally {
        lastTime = Date.now();
      }
    }, 2000);
  }
}
