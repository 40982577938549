// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { default as buildData } from './build-data.json';
import { IEnvironment } from './environment.i';

export const environment: IEnvironment = {
  VERSION: buildData.VERSION,
  VERSION_SHA: buildData.VERSION_SHA,
  BUILD_DATE: buildData.BUILD_DATE,
  production: true,
  test: false,
  dev: false,
  apiUrl: 'https://admin.graam.com',
  sentryUrl: 'https://cd447a0491344f889f1949e44ae7adeb@secure.wengo.com/postsentry/2189',
  webpushPublicKey: 'BGuoG2C9leKJDvnlIfKZgzioHjyaAeadvAE-ocp0lXllYHlmrPZ8iONC6MYA2DZ4O0t2_b5qWbco4WI0UoqfRyM',
};
