<div fxFill *ngIf="isReady()">
  <ng-container *ngIf="step == 'select_sound'">
    <div fxLayout="column" fxLayoutAlign="start left" fxFill>
      <h2>{{ 'reusable_sound.content.select_sound'|translate }}</h2>
      <div class="mb-1" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex="50" fxFlex.xs="70">
          <flex-search-input [formControl]="searchCtrl"></flex-search-input>
        </div>
      </div>

      <div class="existing-sound-list" fxFlex="1 0 1px" perfectScrollbar>
        <div fxLayout="row" [ngClass]="fileEntry && fileEntry.id == file.id?'selected-sound-row':'existing-sound-row'"
          *ngFor="let file of newFileEntryList; let i = index"
          (mouseenter)="showBtnSelects[i]=true" (mouseleave)="showBtnSelects[i]=false">
          <div class="existing-sound-player">
            <fileentry-player-button *ngIf="file.public_id" [disabled]="!file.ready_for_playing"
              [filePublicId]="file.public_id">
            </fileentry-player-button>
          </div>
          <!-- <mat-icon fxFlex="25px" *ngIf="fileEntry && fileEntry.id == file.id">check_circle</mat-icon> -->
          <span fxFlex="grow" class="existing-sound-name" [matTooltip]="file.name">
            {{file.name}}
          </span>
          <div fxFlexLayout="end center">
            <button data-ci="button-select-sound" *ngIf="showBtnSelects[i] && (!fileEntry || fileEntry.id !== file.id)" mat-button color="accent"
              (click)="chooseExisting(file)">
              {{ 'reusable_sound.content.select_this_sound'|translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <button data-ci="add-sound" mat-stroked-button color="accent" (click)="addSound()" class="button-add">
          <mat-icon class="icon-add">add</mat-icon>
          <span class="vertical-align">{{ 'reusable_sound.content.add_sound'|translate }}</span>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="step == 'add_sound'">
    <div fxLayout="column" fxLayoutAlign="start left">
        <!--Name-->
        <ng-container *ngTemplateOutlet="FormName"></ng-container>

        <h2> {{ 'fileentry.action.upload_file' | translate }} </h2>
        <div fxLayout="row">
          <app-file-upload class="full-width" (onFileChange)="fileLoadedForBrowser($event)" icon="music_note"
            [allowedExtensions]="allowedUploadExtensions" [title]="'fileentry.action.upload_file_hint'|translate">
          </app-file-upload>
        </div>
        <!--sound-upload-row-->

        <h2> {{ 'sound_manager.content.text2speech' | translate }} </h2>
      
        <form [formGroup]="formGenerateVoice" fxLayout="column" *ngIf="formGenerateVoice">
          <div fxLayout="row">
            <div fxFlex>
              <mat-form-field class="full-width">
                <mat-label>{{ 'sound_manager.content.lang' | translate }}</mat-label>
                <mat-select data-ci="lang_search" 
                  formControlName="lang"
                  (selectionChange)="changeText2speechLang()" [placeholder]="'sound_manager.content.lang' | translate">
                  <ngx-mat-select-search 
                    [(ngModel)]="langSearch" [ngModelOptions]="{standalone: true}" 
                    [placeholderLabel]="'public.search' | translate"
                    [noEntriesFoundLabel]="'public.message.no_results' | translate">
                  </ngx-mat-select-search>
                  <mat-option *ngFor="let text_lang of langList" [value]="text_lang.value">
                    {{ text_lang.label }}
                  </mat-option>
                </mat-select>

                <mat-error [flexFormControlError]="formGenerateVoice.get('lang')"></mat-error>
              </mat-form-field>
            </div>
            <div class="pl-1"></div>
            <div fxFlex>
              <mat-form-field class="full-width">
                <mat-label>{{ 'public.voice' | translate }}</mat-label>
                <mat-select data-ci="voice_lang" 
                  formControlName="voice" (selectionChange)="clearTTSSound()"
                  [placeholder]="'public.voice' | translate">
                  <ng-container *ngIf="formGenerateVoice.get('lang').value">
                    <mat-option *ngFor="let voice_lang of text2speech_lang_voices[formGenerateVoice.get('lang').value]"
                      [value]="voice_lang.value">{{ voice_lang.label }}
                    </mat-option>
                  </ng-container>
                </mat-select>

                <mat-error [flexFormControlError]="formGenerateVoice.get('voice')"></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div>
            <mat-form-field class="full-width">
              <mat-label>{{ 'sound_manager.content.text' | translate }}</mat-label>
              <textarea data-ci="tts-text" matInput rows="5" 
                formControlName="text"
                [placeholder]="'sound_manager.content.text' | translate"></textarea>
                
              <mat-error [flexFormControlError]="formGenerateVoice.get('text')"></mat-error>
            </mat-form-field>
          </div>

          <div class="text-right mt-1">
            <button data-ci="generate" type="button" mat-stroked-button color="accent" (click)="recordByText2speech()"
              [disabled]="!formGenerateVoice.get('text').value || !formGenerateVoice.get('text').value.trim()">
              {{'fileentry.action.generate'|translate}}
            </button>
          </div>
        </form>
      <!--text-to-speech-row-->

      <mat-card *ngIf="newFileEntry" class="card-widge sound-file mt-1">
        <div mat-card-widget>
          <span fxFlex>{{ newFileEntryOptions.name }}</span>
          <div fxFlex="50" class="pl-1 pr-1">
            <p>
              <fileentry-player-button [filePublicId]="newFileEntry?.public_id" playerId="main">
              </fileentry-player-button>
            </p>
          </div>
          <button fxFlex="40px" mat-icon-button class="mat-text-muted" (click)="clearTTSSound()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-card>
      <!--text-to-speech-sound-->

    </div>
  </ng-container>

  <ng-container *ngIf="step == 'confirm_sound'">
    <div fxLayout="column" fxLayoutAlign="start left">
      <!--Name-->
      <ng-container *ngTemplateOutlet="FormName"></ng-container>

      <ng-container *ngIf="soundFile">
        <mat-card class="card-widge sound-name">
          <div mat-card-widget>
            <button mat-mini-fab mat-card-icon color="primary">
              <mat-icon>music_note</mat-icon>
            </button>
            <div fxFlex class="pl-1 pr-1">
              <span>
                {{soundFile.name}}
                <mat-icon color="primary">check</mat-icon>
              </span>
            </div>
            <button fxFlex="40px" mat-icon-button class="mat-text-muted" (click)="deleteSound()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card>

        <mat-card class="card-widge sound-file" *ngIf="canPlayLocalFile()">
          <div mat-card-widget>
            <span fxFlex class="filename">{{ newFileEntryOptions.name }}</span>
            <div fxFlex="50">
              <p>
                <audio-browser-player #audioPlayer [hideStop]="true" [file]="soundFile"></audio-browser-player>
              </p>
            </div>
            <div fxFlex="40px">
              <button mat-icon-button color="primary" *ngIf="audioPlayer && audioPlayer.audioPlaying"
                (click)="stopSound()">
                <mat-icon>stop</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>

      </ng-container>

      <div class="full-width mt-2">
        <button mat-stroked-button color="accent" (click)="goPrevStep()">{{ 'sound_manager.content.text2speech' |
          translate }}</button>
      </div>
    </div>
  </ng-container>
</div>


<ng-template #FormName>
  <form [formGroup]="formNameSound" *ngIf="formNameSound">
    <div [hidden]="showInputName == false">
      <mat-form-field class="full-width">
        <mat-label>{{ 'sound_manager.content.sound_file_name' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'sound_manager.content.sound_file_name' | translate"
          formControlName="name">

        <mat-error [flexFormControlError]="formNameSound.get('name')"
          [customMessages]="{
            exist: 'sound_manager.message.name_is_empty'|translate
          }"></mat-error>
      </mat-form-field>
      <!-- <span *ngIf="errors.name" class="mat-text-warn">{{ errors.name|translate }}</span> -->
    </div>
  </form>

  <!-- <div [hidden]="showInputName == false">
    <mat-form-field class="full-width">
      <mat-label>{{ 'sound_manager.content.sound_file_name' | translate }}</mat-label>
      <input matInput data-ci="sound_name" type="text"
        [placeholder]="'sound_manager.content.sound_file_name' | translate"
        formControlName="name" />

      <mat-error [flexFormControlError]="formGenerateVoice.get('name')"
        [customMessages]="{
          exist: 'sound_manager.message.name_is_empty'|translate
        }"></mat-error>
    </mat-form-field>
  </div> -->
</ng-template>
