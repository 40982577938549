import { Component, OnInit, Inject } from '@angular/core';
import { PhoneBookEntry } from '@wephone-core/model/entity/phonebook';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService, joiValidator, DialogActionButton, Colors, IFlexDialogConfig, EmailValidator } from '@wephone-utils';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PhoneBookRepository } from '@wephone-core/model/repository/phonebook';
import { PhoneNumberValidated, OneRequired } from '@wephone/services/form-validator';

@Component({
  selector: 'app-create-phone-book-modal',
  templateUrl: './create-phone-book-modal.component.html',
  styleUrls: ['./create-phone-book-modal.component.scss']
})
export class CreatePhoneBookModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '600px',
    minHeight: '90%',
  };

  dialogTitle = _tk('public.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  phoneBook: PhoneBookEntry;
  // public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    dialogRef: MatDialogRef<CreatePhoneBookModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public toast: ToastService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.phoneBook = EntityManager.getRepository('PhoneBookRepository').create() as PhoneBookEntry;
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      firstName: [this.phoneBook.first_name, joiValidator(Joi.string().required())],
      lastName: [this.phoneBook.last_name],
      email: [this.phoneBook.email, [EmailValidator]],
      phoneOffice: [this.phoneBook.phone_office, [PhoneNumberValidated()]],
      phoneMobile: [this.phoneBook.phone_mobile, [PhoneNumberValidated()]]
    }, {
      validators: OneRequired(['phoneOffice', 'phoneMobile'])
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.phoneBook.first_name = changes.firstName && changes.firstName.trim();
        this.phoneBook.last_name = changes.lastName && changes.lastName.trim();
        this.phoneBook.email = changes.email && changes.email.trim();
        this.phoneBook.phone_office = changes.phoneOffice && changes.phoneOffice.trim();
        this.phoneBook.phone_mobile = changes.phoneMobile && changes.phoneMobile.trim();
      }));
  }

  private validForm(): boolean {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return false;
    }

    let existName: boolean = false;
    const phones: any[] = [this.form.get('phoneOffice'), this.form.get('phoneMobile')];
    for (const phoneCtrl of phones) {
      const phoneNumber = phoneCtrl.value && phoneCtrl.value.trim();
      if (!phoneNumber) {
        continue;
      }

      const existPhoneBook: PhoneBookEntry = (EntityManager.getRepository<PhoneBookRepository>('PhoneBookRepository') as PhoneBookRepository)
        .getExistPhoneNumber(phoneNumber);
      if (existPhoneBook) {
        phoneCtrl.setErrors({nameExist: true});
        existName = true;
      }
    }

    if (existName) {
      return false;
    }

    return true;
  }

  async submit(): Promise<void> {
    if (!this.validForm()) {
      return;
    }

    try {
      const updatedPhoneBook = this.phoneBook;

      if (!updatedPhoneBook) {
        this.toast.showError(_ti('public.message.data_invalid'));
        return;
      }

      const response = await EntityManager.getRepository<PhoneBookRepository>('PhoneBookRepository')
        .save(updatedPhoneBook);

      this.toast.show(_ti('public.message.create_success'));
      this.dismiss(updatedPhoneBook, false);
    } catch (error) {
      console.error('Create new error', error);
      this.toast.showError(_ti('public.message.create_failure'));
    }
  }

  cancel(result?): void {
    this.dismiss(result);
  }

  onTouched(controlNames: string[]): void {
    controlNames.forEach(name => this.form.get(name).markAsTouched());
  }
}
