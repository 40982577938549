import * as _ from 'lodash';
import { datafield, Entity } from '@wephone-core/model/model';
import { _tk } from '@wephone-translation';
import { DateTime } from 'luxon';
import { parseDateTime } from '@wephone-utils';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ImageRepository } from '../repository/image';

export class ImageEntity extends Entity {
  static object_type_id = 'image';

  id: number;
  @datafield public_id: string;
  @datafield created_dt: DateTime;
  @datafield modified_dt: DateTime;
  @datafield is_temp: number;
  @datafield name: string;
  @datafield file_size: string;
  @datafield file_ext: string;
  @datafield author_id: number;

  remote_url: string;
  is_system = false;

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.created_dt = object_data.created_dt ? parseDateTime(object_data.created_dt) : null;
    this.modified_dt = object_data.created_dt ? parseDateTime(object_data.modified_dt) : null;
    this.remote_url = (EntityManager.getInstance().getRepository('ImageRepository') as ImageRepository).getFileUrl(this.public_id, false);
  }

}
