import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
// import { DateAdapter } from '@angular/material/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { localNow, parseDateTime } from '@wephone-utils';
import { PeriodSelectType } from './priod-select-type';
import * as _ from 'lodash';
import { DateTime } from 'luxon';

@Component({
  selector: 'period-select',
  templateUrl: './period-select.component.html',
  styleUrls: ['./period-select.component.scss']
})
export class PeriodSelectComponent extends FlexBaseComponent implements OnChanges {
  @Input() readonly displayPeriodList?: PeriodSelectType[];
  @Input() readonly defaultPeriod?: PeriodSelectType;
  @Input() readonly startDate?: Date;
  @Input() readonly endDate?: Date;
  @Output() readonly changedPeriod = new EventEmitter<any>();

  period_list: { period: PeriodSelectType, label: string }[];
  period_start_date: Date;
  period_end_date: Date;
  period: PeriodSelectType;

  private initilized = false;

  constructor(
    // readonly dateAdapter: DateAdapter<Date>
  ) {
    super();
    // this.dateAdapter = dateAdapter;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initilized) {
      return;
    }

    let hasChangedData = false;
    if ('startDate' in changes && this.hasChangedDate(changes.startDate.currentValue, changes.startDate.previousValue)) {
      hasChangedData = true;
      this.setPeriodStartDate(changes.startDate.currentValue);
    }

    if ('endDate' in changes && this.hasChangedDate(changes.endDate.currentValue, changes.endDate.previousValue)) {
      hasChangedData = true;
      this.setPeriodEndDate(changes.endDate.currentValue);
    }

    if (hasChangedData) {
      this.updatePeriod();
    }
  }

  resolveData(): void {
    this.period = this.defaultPeriod;
    this.setPeriodList();
    this.setPeriodStartDate(this.startDate);
    this.setPeriodEndDate(this.endDate);
    this.updatePeriod();

    if (this.period) {
      this.updatePeriodDate(this.period);
    }

    this.initilized = true;
  }

  private hasChangedDate(d1: Date, d2: Date): boolean {
    const dt1 = parseDateTime(d1).startOf('days');
    const dt2 = parseDateTime(d2).startOf('days');

    return dt1.diff(dt2).as('days') !== 0;
  }

  private setPeriodList(): void {
    const _periodList = [
      { period: PeriodSelectType.today, label: 'period_select.today' },
      { period: PeriodSelectType.yesterday, label: 'period_select.yesterday' },
      { period: PeriodSelectType.this_week, label: 'period_select.this_week' },
      { period: PeriodSelectType.last_week, label: 'period_select.last_week' },
      { period: PeriodSelectType.this_month, label: 'period_select.this_month' },
      { period: PeriodSelectType.last_month, label: 'period_select.last_month' },
      { period: PeriodSelectType.this_year, label: 'period_select.this_year' },
      // { period: PeriodSelectType.custom, label: 'period_select.custom' },
    ];
    this.period_list = _periodList.filter(periodItem => {
      if (_.isEmpty(this.displayPeriodList)) {
        return true;
      }

      return _.includes(this.displayPeriodList, periodItem.period);
    });
  }

  private setPeriodStartDate(startDate: Date): void {
    if (_.isDate(startDate)) {
      this.period_start_date = _.cloneDeep(startDate);
    }
  }

  private setPeriodEndDate(endDate: Date): void {
    if (_.isDate(endDate)) {
      this.period_end_date = _.cloneDeep(endDate);
    }
  }

  updatePeriod(): void {
    const now = localNow();
    const startDate: DateTime = parseDateTime(this.period_start_date);
    const endDate: DateTime = parseDateTime(this.period_end_date);

    const nowStartOf = now.startOf('day');
    const startDateStartOf = startDate.startOf('day');
    const endDateStartOf = endDate.startOf('day');
    const endDateEndOf = endDate.endOf('day');

    if (nowStartOf.diff(startDateStartOf, 'days').days === 0 && nowStartOf.diff(endDateStartOf, 'days').days === 0) {
      this.period = PeriodSelectType.today;
      return;
    }

    if (nowStartOf.diff(startDateStartOf, 'days').days === 1 && nowStartOf.diff(endDateStartOf, 'days').days === 1) {
      this.period = PeriodSelectType.yesterday;
      return;
    }

    if (now.startOf('week').diff(startDateStartOf, 'days').days === 0 && now.endOf('week').diff(endDateEndOf, 'days').days === 0) {
      this.period = PeriodSelectType.this_week;
      return;
    }

    const lastWeek = now.minus({ week: 1 });
    if (lastWeek.startOf('week').diff(startDateStartOf, 'days').days === 0 && lastWeek.endOf('week').diff(endDateEndOf, 'days').days === 0) {
      this.period = PeriodSelectType.last_week;
      return;
    }

    if (now.startOf('month').diff(startDateStartOf, 'days').days === 0 && (
      nowStartOf.diff(endDateStartOf, 'days').days === 0 || now.endOf('month').diff(endDateEndOf, 'days').days === 0
    )) {
      this.period = PeriodSelectType.this_month;
      return;
    }

    const lastMonth = now.minus({ month: 1 });
    if (lastMonth.startOf('month').diff(startDateStartOf, 'days').days === 0 && lastMonth.endOf('month').diff(endDateEndOf, 'days').days === 0) {
      this.period = PeriodSelectType.last_month;
      return;
    }

    if (now.startOf('year').diff(startDateStartOf, 'days').days === 0 && now.endOf('year').diff(endDateEndOf, 'days').days === 0) {
      this.period = PeriodSelectType.this_year;
      return;
    }

    this.period = undefined;
  }

  updatePeriodDate(period: PeriodSelectType): void {
    const now = localNow();
    switch (period) {
      case PeriodSelectType.today:
        this.period_start_date = now.startOf('days').toJSDate();
        this.period_end_date = now.startOf('days').toJSDate();
        break;
      case PeriodSelectType.yesterday:
        const yesterday = now.minus({ day: 1 }).startOf('days').toJSDate();
        this.period_start_date = yesterday;
        this.period_end_date = yesterday;
        break;
      case PeriodSelectType.this_week:
        this.period_start_date = now.startOf('week').toJSDate();
        this.period_end_date = now.endOf('week').toJSDate();
        break;
      case PeriodSelectType.last_week:
        const lastWeek = now.minus({ week: 1 });
        this.period_start_date = lastWeek.startOf('week').toJSDate();
        this.period_end_date = lastWeek.endOf('week').toJSDate();
        break;
      case PeriodSelectType.this_month:
        this.period_start_date = now.startOf('month').toJSDate();
        this.period_end_date = now.endOf('month').toJSDate();
        break;
      case PeriodSelectType.last_month:
        const lastMonth = now.minus({ month: 1 });
        this.period_start_date = lastMonth.startOf('month').toJSDate();
        this.period_end_date = lastMonth.endOf('month').toJSDate();
        break;
      case PeriodSelectType.this_year:
        this.period_start_date = now.startOf('year').toJSDate();
        this.period_end_date = now.endOf('year').toJSDate();
        break;
      // case PeriodSelectType.custom:
      //   break;
      default:
        throw new Error('Not valid period');
    }
  }

  changePeriod(period: PeriodSelectType): void {
    if (this.period !== period) {
      this.period = period;
      this.updatePeriodDate(this.period);

      // if (period !== PeriodSelectType.custom) {
      this.applyFilter();
      // }
    }
  }

  applyFilter(): void {
    this.changedPeriod.emit({ period: this.period, start_dt: this.period_start_date, end_dt: this.period_end_date });
  }
}
