<mat-card class="flex-header">
  <mat-card-header>
    <mat-card-title>{{ 'dashboard.content.qos_today'|translate }}</mat-card-title>
  </mat-card-header>
  <!-- <mat-card-content class="pa-1"> -->
  <div fxLayout="row" fxLayout.xs="column" class="stats-today">
    <div fxFlex="33.33" fxFlex.xs="none" fxLayout="column" [ngClass.xs]="{'qos-pb': true}">
      <h1 fxFlexAlign="center">{{ 'dashboard.content.qos_title'|translate }}</h1>
      <div fxFlexAlign="center">
        <div class="chart">
          <div #pieCanvas class="percentage">
            <span class="percent">{{ statsDailyLive.qos || 0 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex fxFlex.xs="none" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="qox-pt qos-pb">
        <h1 fxFlex class="ma-0">{{ 'dashboard.content.missed_calls' | translate }}</h1>
        <div fxFlex>
          <div class="counter">
            <h2 class="mt-0 mb-0">
              <small class="label-number">{{ 'dashboard.content.unhandled_missedcall'|translate }} &nbsp;</small>
              <span>{{ statsDailyLive.missed_call_unhandled || 0 }}</span>
            </h2>
            <p class="mt-0 mb-0 text-md mat-text-muted">
              {{ 'dashboard.content.handled_missedcall'|translate }} {{ statsCallCounter.handled_missedcall }} ({{ statsCallCounter.handled_missedcall_percent }}%)
            </p>
            <p class="mt-0 mb-0 text-md mat-text-muted">{{ 'dashboard.content.total_missedcall'|translate }} {{ statsDailyLive.missed_call_count || 0 }}</p>
          </div>
        </div>

        <div fxFlex align="end">
          <button mat-stroked-button color="accent" class="button-outline" (click)="todayMissedCalls()">
            {{ 'public.view'|translate }}
          </button>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="qos-pt qos-pb">

        <h1 fxFlex class="ma-0">{{ 'dashboard.content.voice_messages' | translate }}</h1>
        <div fxFlex>
          <div class="counter">
            <h2 class="mt-0 mb-0">
              <small class="label-number">{{ 'dashboard.content.unlistened_voicemail'|translate }} &nbsp;</small>
              <span>{{ statsDailyLive.unread_voicemail_today || 0 }}</span>
            </h2>
            <p class="mt-0 mb-0 text-md mat-text-muted">
              {{ 'dashboard.content.listened_voicemail'|translate }}  {{ statsCallCounter.listened_voicemail }} ({{ statsCallCounter.listened_voicemail_percent }}%)
            </p>
            <p class="mt-0 mb-0 text-md mat-text-muted">
              {{ 'dashboard.content.total_voicemail'|translate }} {{ statsDailyLive.total_voicemail_today || 0 }}
            </p>
          </div>
        </div>

        <div fxFlex align="end">
          <button mat-stroked-button color="accent" class="button-outline"
            (click)="gotoPage('vocal_messages/voicemail-queue')">
            {{ 'public.view' | translate }}
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.xs="10px" class="qox-pt qos-pb">
        <div fxFlex>
          <div fxFill fxLayout="column" fxLayoutAlign="start center" class="block-info call-inprogress"
            (click)="gotoTab('agent-list')">
            <div class="number" fxLayoutAlign="center center">
              <mat-icon class="mr-1">phone_callback</mat-icon>
              <span>{{ statsDailyLive.active_inbound_call || 0 }}</span>
            </div>
            <div class="title font-weight-bold">{{ 'dashboard.content.active_inbound_call'|translate }}</div>
          </div>
        </div>
        <div fxFlex>
          <div fxFill fxLayout="column" fxLayoutAlign="start center" class="block-info call-waiting"
            (click)="gotoTab('agent-list')">
            <div class="number" fxLayoutAlign="center center">
              <mat-icon class="mr-1">phone_in_talk</mat-icon>
              <span>{{statsDailyLive.active_outbound_call||0 }}</span>
            </div>
            <div class="title font-weight-bold">{{'dashboard.content.active_outbound_call'|translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="none"
    class="stats-agent-connection">
    <div fxFlex="33.33" fxFlex.xs="none" fxLayout="column" [ngClass.xs]="{'qos-pb': true}">
      <h1 fxFlexAlign="center">{{ 'dashboard.content.agent_connected'|translate }}</h1>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div class="mr-1">
          <div class="wrapper-avatar">
            <img src="assets/images/avatar-connected.png" class="avatar-connected">
          </div>
        </div>
        <div class="number text-primary">{{ statsDailyLive.agent_connected || 0 }}</div>
      </div>
    </div>
    <div fxFlex fxFlex.xs="none" fxLayout="column">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="none"
        fxLayoutGap="20px" fxLayoutGap.xs="10px">
        <div fxFlex fxFlex.xs="none" fxLayout="column" fxLayoutAlign="center center" class="block-info agent-available"
          (click)="gotoTab('agent-list')">
          <span class="number">{{ statsDailyLive.agent_available || 0 }}</span>
          <span class="title font-weight-bold">{{ 'dashboard.content.agents_available' | translate }}</span>
        </div>
        <div fxFlex fxFlex.xs="none" fxLayout="column" fxLayoutAlign="center center" class="block-info agent-inprogress"
          (click)="gotoTab('agent-list')">
          <span class="number">{{ statsDailyLive.agent_incall || 0 }}</span>
          <span class="title font-weight-bold">{{ 'dashboard.content.agents_inprogress' | translate }}</span>
        </div>
        <div fxFlex fxFlex.xs="none" fxLayout="column" fxLayoutAlign="center center" class="block-info agent-paused"
          (click)="gotoTab('agent-list')">
          <span class="number">{{ statsDailyLive.agent_pause || 0 }}</span>
          <span class="title font-weight-bold">{{ 'dashboard.content.agents_paused' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- </mat-card-content> -->
</mat-card>
