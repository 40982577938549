import { IIvrCustomMenuRepository, IvrMenuType } from '@wephone-core/model/repository/ivr_custom_menu.i';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class IvrCustomMenuRepository extends BaseRepository implements IIvrCustomMenuRepository {
  ENTITY_CLASS = IvrCustomMenuEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'ivr_custom_menu';

  CUSTOMER_EVALUATION_TYPE = 1; // Customer evaluation

  protected getURLAction(action: string): string {
    switch (action) {
      case 'UPDATE':
        return this.getUrlV2(this.METHOD_PATH_UPDATE);
      case 'ADD':
        return this.getUrlV2(this.METHOD_PATH_NEW);
      case 'DELETE':
        return this.getUrlV2(this.METHOD_PATH_DELETE);
      default:
        return super.getURLAction(action);
    }
  }

  getMenuList(): IvrCustomMenuEntity[] {
    const _top_menu_list = [];
    for (const ivrmenu of this.getObjectList()) {
      if (!ivrmenu.parent_id && ivrmenu.ivrscript_type === IvrMenuType.IvrMenu) {
        _top_menu_list.push(ivrmenu);
      }
    }

    return _top_menu_list;
  }
}
