import { datafield, mapped_datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { IvrAppRepository } from '@wephone-core/model/repository/ivrapp';
import { IvrAppEntity } from '@wephone-core/model/entity/ivrapp';
import { DidEntity } from './did';
import * as _ from 'lodash';
import { CrmRoutingRuleEntity } from './crm_routing_rule';
import { IvrCustomMenuEntity } from './ivr_custom_menu';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';

export class IvrScriptEntity extends Entity {
  static object_type_id = 'ivrscript';

  static SCRIPT_TYPE_VXML = 1;
  static SCRIPT_TYPE_JSON = 0;

  id: number;
  @mapped_datafield('module_name') name: string;
  @mapped_datafield('application_id') app_id: number;
  @datafield script_type: number; // 0: json, 1: vxml
  @datafield script_url: string;
  @datafield params_desc: any;

  get application_name(): string {
    const app = this.getApplication();

    return app ? app.application_name : undefined;
  }

  getApplication(): IvrAppEntity {
    if (this.app_id) {
      return EntityManager.getRepository<IvrAppRepository>('IvrAppRepository').getObjectById(this.app_id);
    }
  }

  setObjectData(object_data: any, fetch_related_data = true): void {
    super.setObjectData(object_data, fetch_related_data);
    this.params_desc = object_data.params_desc ? JSON.parse(object_data.params_desc) : undefined;
  }

  dumpObjectData(field_list?: Array<string>, use_mapped_name = false): any {
    const ret = super.dumpObjectData(field_list, use_mapped_name);
    if (field_list && field_list.indexOf('params_desc') >= 0) {
      ret.params_desc = JSON.stringify(this.params_desc);
    }

    return ret;
  }

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      if (did.routed_application && did.routed_application.id === this.id ||
        did.out_office_hours_routed_application && did.out_office_hours_routed_application.id === this.id) {
        ret.push(did);
      }
    }
    return ret;
  }

  get routedCrmRoutings(): CrmRoutingRuleEntity[] {
    const ret: CrmRoutingRuleEntity[] = [];
    for (const item of EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList() as CrmRoutingRuleEntity[]) {
      if (item.routed_application && item.routed_application.id === this.id) {
        ret.push(item);
        continue;
      }
      if (!_.isEmpty(item.routed_applications_conditional) && _.includes(item.routed_applications_conditional.map(x => x.id), this.id)) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routedIvrMenus(): IvrCustomMenuEntity[] {
    return RoutingAppService.getInstance().getRoutedIvrMenusToEntity(this.id, RoutingAppName.runvxmlscript);
  }
}
