<div fxLayout="row" class="margin-btm">
  <!-- <div fxFlex="100px" *ngIf="isAdmin">
    <button type="button" mat-stroked-button color="accent" (click)="openModalAddDidToQueue()">
      {{ 'call_queue.content.add_didroute' | translate }}
    </button>
  </div> -->
  <div fxFlex fxLayoutAlign="end center">
    <flex-search-input [(ngModel)]="searchText" placeholder="{{ 'public.button.search'|translate }}"></flex-search-input>
  </div>
</div>
<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="5px" class="list-header margin-btm">
    <div fxFlex="150px" fxLayoutAlign="start center" class="bg-gray">
      {{ 'did.content.number'|translate }}
    </div>

    <div fxFlex fxLayoutAlign="start center" class="bg-gray">
      {{ 'did.content.name'|translate }}
    </div>

    <div fxFlex fxLayoutAlign="start center" class="bg-gray">
      {{ 'did.content.welcome_message'|translate }}
    </div>

    <div fxFlex="100px" fxLayoutAlign="start center" class="bg-gray">
      {{ 'call_queue.content.didroute.priority'|translate }}
    </div>

    <div fxFlex="80px" fxLayoutAlign="center center" class="bg-gray">
      {{ 'public.action'|translate }}
    </div>
  </div>
  <ng-container *ngFor="let did of didList; let i = index; last as last">
    <div fxLayout="row" fxLayoutGap="5px" class="margin-btm">
      <div fxFlex="150px" fxLayoutAlign="start center" class="bg-gray">
        <a (click)="gotoPageDid(did)" class="number">{{ did.display_number }}</a>
      </div>

      <div fxFlex fxLayoutAlign="start center" class="bg-gray">
        <inline-textbox 
          class="full-width"
          [attr.data-ci]="'inline-text-didname'"
          [emptyLabel]="'did.form.define_did_name'|translate"
          [(ngModel)]="did.name" 
          [placeholder]="'did.content.name'|translate"
          (change)="onChange()">
        </inline-textbox>
      </div>

      <div fxFlex fxLayoutAlign="start center" class="bg-gray">
        <inline-textbox 
          class="full-width"
          [attr.data-ci]="'inline-text-welcomemsg'"
          [emptyLabel]="'did.form.define_welcome_message'|translate"
          [(ngModel)]="did.welcome_message" 
          [placeholder]="'call_queue.content.didroute.welcome_message'|translate"
          (change)="onChange()">
        </inline-textbox>
      </div>

      <div fxFlex="100px" fxLayoutAlign="start center" class="bg-gray">
        <mat-form-field class="full-width">
          <mat-label>{{ 'call_queue.content.didroute.priority'|translate }}</mat-label>
          <mat-select 
            [attr.data-ci]="'mat-select-priority'"
            [(ngModel)]="did.route_priority" placeholder="{{ 'call_queue.content.didroute.priority'|translate }}" (selectionChange)="onChange()">
            <mat-option *ngFor="let priority of priorityList" [value]="priority.value">{{ priority.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!did.route_priority" class="mat-text-warn">{{ 'form.validator.field_required'|translate }}</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="80px" fxLayoutAlign="center center" class="bg-gray">
        <button mat-icon-button data-ci="btn-edit-did" color="primary" (click)="openModalEditDid(did)" title="{{ 'public.edit'|translate }}">
          <mat-icon>edit</mat-icon>
        </button>
        <!-- <button data-ci="btn-update-did" mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #appMenu="matMenu">
          <button data-ci="btn-edit-did" mat-menu-item color="primary" (click)="openModalEditDid(did)" title="{{ 'public.edit'|translate }}">
            <mat-icon>edit</mat-icon> {{ 'public.edit'|translate }}
          </button>
          <button data-ci="btn-remove-did" *ngIf="isAdmin" mat-menu-item color="warn" (click)="removeDid([did])" title="{{ 'public.remove'|translate }}">
            <mat-icon>delete</mat-icon> {{ 'public.remove'|translate }}
          </button>
        </mat-menu> -->
      </div>
    </div>
  </ng-container>
</div>
