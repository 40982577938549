<div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <ng-template #tplMultiple>
      <div class="fontfamily-bold phone-number-text">
        {{ displayKeys() }}
      </div>
    </ng-template>


    <ng-container *ngIf="multiple || keyLength === 1;else InputTextarea">
      <ng-container *ngIf="!multiple; else tplMultiple">
        <button *ngIf="selectedKeys && selectedKeys.length" type="button" mat-mini-fab
          class="fontfamily-bold phone-number-text">
          {{ displayKeys() }}
        </button>
      </ng-container>
      <button class="exlude-click-outside" data-ci="dialpad" type="button" mat-icon-button color="secondary"
        (click)="tooglePicker()">
        <mat-icon>dialpad</mat-icon>
      </button>
    </ng-container>
    <ng-template #InputTextarea>
      <mat-form-field class="key-input" [ngClass]="{'mat-form-field-invalid': errorCode}">
        <textarea matInput [(ngModel)]="sKeys" (ngModelChange)="updateKeys($event)"
          [attr.maxlength]="inputKeyMaxLength"></textarea>
        <!-- <textarea matInput (input)="updateKeys($event)" pattern="^[0-9,]*$" [attr.maxlength]="inputKeyMaxLength">{{ sKeys }}</textarea> -->
      </mat-form-field>
    </ng-template>
  </div>
  <div class="pad-wrapper">
    <div *ngIf="padOpened" class="ba dialPad compact position-{{options.position}}" (clickOutside)="closePicker()"
      [exclude]="'.exlude-click-outside'">
      <div class="dials">
        <ol>
          <li class="digits numpad" mat-fab *ngFor="let key of availableKeys; let i = index" [attr.data-index]="i"
            [ngClass]="{'disabled': isSelectedKey(key)}">
            <button type="button" [attr.data-index]="i" mat-mini-fab (click)="clickKey(key)" class="fontfamily-bold">{{
              key }}</button>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
