import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'call-direction-renderer',
  templateUrl: './call-direction-renderer.html',
  styleUrls: ['./call-direction-renderer.scss']
})
export class CallDirectionRenderer implements ICellRendererAngularComp {
  params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
