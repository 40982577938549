<div class="no-scroll" fxLayout="column" fxFill *ngIf="form">
  <ng-template #tplListFilter>
    <form [formGroup]="form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="center"
      fxLayoutGap="10px">
      <div>
        <mat-slide-toggle formControlName="filterActivated">
          <span *ngIf="form.get('filterActivated').value === true;else InActive">{{
            'public.show_list_item.active_items'|translate }}</span>
          <ng-template #InActive>
            <span>{{ 'public.show_list_item.archived_items'|translate }}</span>
          </ng-template>
        </mat-slide-toggle>
      </div>
      <div>
        <flex-search-input class="full-width" formControlName="filterString" [placeholder]="'public.search'|translate"
          [hintText]="'call_queue.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud pageTitle="{{ 'call_queue.title.manage'|translate }}" listWidth="40"
    [dataSource]="dataSource" [tableConfig]="tableConfig"
    [columnTemplates]="{'queue_name': tplQueueName, 'group_name': tplGroupName, 'connected_agents': tplConnectedAgents}"
    [sidePannelOptions]="sidePannelOptions" [filterTemplate]="tplListFilter" [sidePanelMaximized]="true" fxLayout="row"
    fxFlex="grow">
    <ng-template #tplQueueName let-queue="row">
      <span [ngClass]="{exclusive: queue.is_exclusive === 1}">{{ queue.queue_name }}</span>
    </ng-template>
    <ng-template #tplGroupName let-queue="row">
      <span *ngIf="queue.group">
        {{ queue.group.name }}
      </span>
    </ng-template>
    <ng-template #tplConnectedAgents let-queue="row">
      <span class="connected-agents"><strong>{{ queue.connectedAgentNum }}</strong></span>&nbsp;/&nbsp;
      <span>{{ queue.totalAgentNum }}</span>
    </ng-template>
  </flex-crud-page>
</div>
