import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SingletonBase } from '../utils/singleton';
import { ElectronService } from 'ngx-electron';

@Injectable()
export class Platform extends SingletonBase {

    constructor(
        private deviceService: DeviceDetectorService,
        private electron: ElectronService
    ) {
        super();
    }

    isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    isDesktop(): boolean {
        return this.deviceService.isDesktop();
    }

    isTablet(): boolean {
        return this.deviceService.isTablet();
    }

    isAndroid(): boolean {
        // const platform = window['device'].platform;
        // return platform === 'Android';
        // TODO: Check Android
        return false;
    }

    isIOS(): boolean {
        // const platform = window['device'].platform;
        // return platform === 'iOS';
        // TODO: Check IOS
        return false;
    }

    isElectronApp(): boolean {
        return this.electron.isElectronApp;
    }

    isSalesforcePhone(): boolean {
        return !!window['sforce'];
    }
}
