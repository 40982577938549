import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { CrmRoutingRuleEntity } from './crm_routing_rule';
import { DidEntity } from './did';
import * as _ from 'lodash';
import { IvrCustomMenuEntity } from './ivr_custom_menu';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';

export class IvrRemoteAppEntity extends Entity {
  static object_type_id: string = 'ivrremoteapp';

  id: number;
  @datafield name: string;
  @datafield alias: string;
  @datafield url: string;
  @datafield in_agent_transfer_dialog: number;

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      if (did.routed_remote_application && did.routed_remote_application.id === this.id ||
        did.out_office_hours_routed_remote_application && did.out_office_hours_routed_remote_application.id === this.id) {
        ret.push(did);
      }
    }
    return ret;
  }

  get routedCrmRoutings(): CrmRoutingRuleEntity[] {
    const ret: CrmRoutingRuleEntity[] = [];
    for (const item of EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList() as CrmRoutingRuleEntity[]) {
      if (item.routed_remote_application && item.routed_remote_application.id === this.id) {
        ret.push(item);
        continue;
      }
      if (!_.isEmpty(item.routed_remote_application_conditional) && _.includes(item.routed_remote_application_conditional.map(x => x.id), this.id)) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routedIvrMenus(): IvrCustomMenuEntity[] {
    return RoutingAppService.getInstance().getRoutedIvrMenusToEntity(this.id, RoutingAppName.remote_application);
  }
}
