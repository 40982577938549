import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogActionButton, Colors, FormService } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { PhoneService } from '@wephone-app-phone/services';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ValidatorService } from '@wephone-utils/services/validator.service';
import { PhoneNumberValidated, RequiredByEnableStatusValidator } from '@wephone/services/form-validator';

@Component({
  selector: 'dialog-enter-phone-number',
  templateUrl: 'dialog-enter-phone-number.html',
  styleUrls: ['dialog-enter-phone-number.scss']
})
export class EnterPhoneNumberDialog extends DialogComponentBase implements OnInit {
  static time_format = 'HH:mm';
  dialogTitle = _tk('livecall.overview.enter_eavesdrop_destination');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.enter_phone_number();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  eavesdrop_duration: number;
  eavesdrop_until_hour: string;
  hourMidnight: Date;
  eavesdropUntilTime: Date;
  user: UserEntity;

  phone_number: string;
  eavesdrop_duration_enabled: boolean;
  form: FormGroup;
  nextHours: number[] = [1, 2, 3, 4];
  selectedHour: number;
  sipRegistered: boolean = false;

  constructor(
    dialogRef: MatDialogRef<EnterPhoneNumberDialog>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      eavesdrop_period: boolean;
      user_id: number;
    },
    private datePipe: DatePipe,
    private readonly flexIvrSettings: FlexIvrSettings,
    protected readonly authService: AuthenticationService,
    protected readonly formService: FormService,
    protected readonly validatorService: ValidatorService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.flexIvrSettings.isProjectPhone()) {
      this.sipRegistered = PhoneService.getInstance().isRegistered();
    } else {
      const currentUser: IUserEntity = this.authService.getUser();
      this.sipRegistered = currentUser && !!currentUser.sipphone_is_online;
    }

    this.form = this.fb.group({
      phone_number: ['', [Validators.maxLength(20), PhoneNumberValidated()]],
      eavesdrop_duration: [0],
      eavesdrop_listen_from: [this.sipRegistered ? 0 : 1],
      eavesdrop_until_hour: [''],
    }, {
      validators: RequiredByEnableStatusValidator('phone_number', 'eavesdrop_listen_from')
    });

    this.eavesdrop_duration_enabled = this.data.eavesdrop_period;

    this.setEavesdropDuration();
    this.setEavesdropListenFrom();

    if (this.data.user_id) {
      this.user = EntityManager.getInstance().getRepository('UserRepository').getObjectById(this.data.user_id);
    }

    const today = new Date();
    this.hourMidnight = new Date();
    this.hourMidnight.setDate(today.getDate() + 1);
    this.hourMidnight.setHours(0, 0, 0, 0);
  }

  setCustomNextHourCalling(): void {
    this.selectedHour = null;
  }

  setNextHourCalling(hour: number): void {
    this.selectedHour = hour;
    this.eavesdropUntilTime = new Date();
    this.eavesdropUntilTime.setHours(this.eavesdropUntilTime.getHours() + hour); // add n hour
    this.form.get('eavesdrop_until_hour').setValue(this.datePipe.transform(this.eavesdropUntilTime, EnterPhoneNumberDialog.time_format));
  }

  setEavesdropDuration(): void {
    if (this.eavesdrop_duration_enabled && this.form.get('eavesdrop_duration').value === 1) {
      this.setNextHourCalling(1);
    }
  }

  setEavesdropListenFrom(): void {
    if (!this.form.get('eavesdrop_listen_from').value) {
      this.form.get('phone_number').setValue('');
    }
  }

  get nextCallingDate(): Date {
    if (!this.form.get('eavesdrop_duration').value) {
      return;
    }

    const timeStr: string = this.form.get('eavesdrop_until_hour').value;
    if (!timeStr) {
      return;
    }

    const time = timeStr.split(':');
    const eavesdropUntilTime = new Date();
    eavesdropUntilTime.setHours(parseInt(time[0]));
    eavesdropUntilTime.setMinutes(parseInt(time[1]));

    return eavesdropUntilTime;
  }

  enter_phone_number(): void {
    // const schema = Joi.alternatives().conditional(Joi.object({
    //   eavesdrop_listen_from: 1
    // }).unknown(), {
    //   switch: [
    //     {
    //       is: 0,
    //       then: Joi.object({
    //         phone_number: Joi.string().optional(),
    //         eavesdrop_duration: Joi.number().valid(0, 1),
    //         eavesdrop_listen_from: Joi.number().valid(0, 1),
    //         eavesdrop_until_hour: Joi.string().allow(null).allow(''),
    //       }),
    //     },
    //     {
    //       is: 1,
    //       then: Joi.object({
    //         phone_number: Joi.string().regex(/^[0-9]{1,}$/).required(),
    //         eavesdrop_duration: Joi.number().valid(0, 1),
    //         eavesdrop_listen_from: Joi.number().valid(0, 1),
    //         eavesdrop_until_hour: Joi.string().allow(null).allow(''),
    //       }),
    //     }],
    // });

    // this.form.get('phone_number').setErrors(null);
    // if (this.form.get('eavesdrop_listen_from').value) {
    //   const phoneNb: string = this.form.get('phone_number').value;
    //   if (!phoneNb || !this.validatorService.isPhoneNumber(phoneNb)) {
    //     this.form.get('phone_number').setErrors({
    //       invalid_phone: true
    //     });
    //   }
    // }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.error('Form invalid', this.form);
      this.showError(_ti('form.validator.data_invalid'));
      return;
    }

    const phoneNumber: string = this.form.get('eavesdrop_listen_from').value ? this.form.get('phone_number').value : '';
    const eavesdropDuration: number = this.form.get('eavesdrop_duration').value;
    const data: any = {
      phone_number: _.trim(phoneNumber),
      eavesdrop_duration: eavesdropDuration
    };

    if (this.eavesdrop_duration_enabled && eavesdropDuration === 1) {
      const eavesdropUntilTime = _.cloneDeep(this.nextCallingDate);

      if (!this.isToday) {
        eavesdropUntilTime.setDate(eavesdropUntilTime.getDate() + 1);
      }

      data.eavesdrop_until_time = eavesdropUntilTime.toISOString();
    }

    this.dismiss({ data }, false);
  }

  get isToday(): boolean {
    const today = new Date();
    const nextCallingDate: Date = this.nextCallingDate;
    return this.hourMidnight > nextCallingDate && nextCallingDate > today;
  }
}
