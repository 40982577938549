import { Component } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk } from 'jsphonelib/translation';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-packs-modal',
  templateUrl: './subscription-packs-modal.component.html',
  styleUrls: ['./subscription-packs-modal.component.scss']
})
export class SubscriptionPacksModal extends DialogComponentBase {
  dialogTitle = 'subscription-pack.title.upgrade';

  constructor(dialogRef: MatDialogRef<SubscriptionPacksModal>) {
    super(dialogRef);
  }
  // moveTo(el: HTMLElement): void {
  //   el.scrollIntoView();
  // }

  closeDialog($event): void {
    console.log('event', $event);
    this.dismiss($event);
  }
}
