<div fxLayout="column" fxFill>
  <div fxFlex="initial">
    <routable-tab-navigation-menu class="bg-white sticky" [childRoutes]="childRoutes" fxFelx="50">
    </routable-tab-navigation-menu>
  </div>
  <div fxFlex="grow" fxLayout="column" class="overview-scroll">
    <div fxFlex="50" *ngIf="isLoading" fxLayout="column" fxLayoutAlign="end center">
      <h2>Loading, please wait</h2>
    </div>
    <ng-template flex-anchor></ng-template>
  </div>
</div>
