import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import {
  IFlexTableSidePannelOptions,
  DynamicFilterSource,
  DialogService,
  FlexCRUDPageComponent,
  IFlexTableConfig,
  regexSearch,
} from '@wephone-utils';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { CallQueueEditComponent } from '@wephone/components/call-queue/call-queue-edit/call-queue-edit.component';
import { FlexBasePage } from '@wephone-core-ui';
import { Router } from '@angular/router';
import { CallQueueMultiEditComponent } from '@wephone/components/call-queue/call-queue-multi-edit/call-queue-multi-edit.component';
import { _tk, _ti } from '@wephone-translation';
import { CallQueueService } from '@wephone/services/callqueue.service';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import * as _ from 'lodash';

@Component({
  selector: 'app-call-queue',
  templateUrl: './call-queue.component.html',
  styleUrls: ['./call-queue.component.scss']
})
export class CallQueuePage extends FlexBasePage implements OnInit, OnDestroy {
  @ViewChild('flexCrud', { static: false }) flexCrud: FlexCRUDPageComponent;

  // Public member
  queueRepo = CallQueueRepository.getInstance<CallQueueRepository>();
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: CallQueueEditComponent,
    multiItemEditor: CallQueueMultiEditComponent
  };
  actions: any[];
  filterActivated = true;
  filterString: string;
  form: FormGroup;
  tplListFilter: any;
  filteredQueueIds: number[];
  filteredGroupIds: number[];
  isAdmin: boolean;

  private currentUser: IUserEntity;

  constructor(
    private readonly dialogService: DialogService,
    private readonly callQueueService: CallQueueService,
    private readonly fb: FormBuilder,
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly em: EntityManager,
    private readonly configManager: ConfigManager,
  ) {
    super();
    this.filterActivated = true;
    this.currentUser = this.authService.getUser();
    const queueSource = this.queueRepo.dataSource<CallQueueEntity>();
    this.dataSource = new DynamicFilterSource(queueSource, this.filterFunc);
    // this.dataSource.filterPredicate = this.filterPredicate; // This is optional. Use this for custom row search only

    this.tableConfig = {
      // enableFilter: true,
      // enableExportCsv: true,
      columns: [
        {
          name: 'queue_name',
          label: _tk('call_queue.content.name'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          enableTooltip: true,
          lineClamp: 2
        },
        {
          name: 'connected_agents',
          label: _tk('call_queue.content.connected_agents'),
          searchable: false,
          sortable: true,
          customSortFn: this.sortConnectedAgents
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createQueue();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ],
          secondary: [
            {
              id: 'enable',
              icon: 'mic',
              hint: _tk('recording_call.enable_action'),
              callback: data => {
                return this.bulkEnableRecording(data.selectedItems);
              }
            },
            {
              id: 'disable',
              icon: 'mic_none',
              hint: _tk('recording_call.disable_action'),
              callback: data => {
                return this.bulkDisableRecording(data.selectedItems);
              }
            },
            {
              id: 'archive',
              icon: 'archive',
              hint: _tk('public.archive'),
              callback: data => {
                return this.bulkArchive(data.selectedItems);
              }
            },
            {
              id: 'unarchive',
              icon: 'unarchive',
              hint: _tk('public.unarchive'),
              callback: data => {
                return this.bulkUnarchive(data.selectedItems);
              }
            },
            {
              id: 'clone',
              unique: true,
              icon: 'file_copy',
              hint: _tk('public.clone'),
              callback: data => {
                return this.cloneQueue(data.selectedItems);
              }
            },
            // {
            //   icon: 'unsubscribe',
            //   hint: _tk('voicemail.disable_voicemail'),
            //   callback: data => {
            //     return this.bulkDisableVoiceMail(data.selectedItems);
            //   }
            // },
            // {
            //   icon: 'email',
            //   hint: _tk('voicemail.enable_voicemail'),
            //   callback: data => {
            //     return this.bulkEnableVoiceMail(data.selectedItems);
            //   }
            // }
          ]
        }
      }
    };

    if (this.configManager.hasFeature('SERVICE_GROUP')) {
      this.tableConfig.columns.push({
        name: 'group_name',
        label: _tk('call_queue.content.group'),
        searchable: true,
        sortable: true,
        exportedValue: this.renderExportedGroupName,
        customSortFn: this.sortGroupName
      });
    }

    if (this.currentUser.isSupervisor()) {
      this.tableConfig.listActions.defaultActions = [];
      this.tableConfig.listActions.selectionActions.primary = [];
    }

  }

  async resolveData(): Promise<any> {
    await EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
    await this.em.getRepository('IvrEvaluationRepository').findAll();

    this.isAdmin = this.authService.isAdmin();
    if (!this.isAdmin) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      this.filteredQueueIds = myProfile.managed_queue_ids;
      this.filteredGroupIds = myProfile.managed_group_ids;
    }

    this.form = this.fb.group({
      filterActivated: [this.filterActivated],
      filterString: [this.filterString]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(this.onFormValueChage)
    );

    this.onFormReload();
  }

  sortConnectedAgents = (queue: CallQueueEntity): any => {
    return queue.totalAgentNum;
  }

  sortGroupName = (queue: CallQueueEntity): any => {
    return queue.group ? queue.group.name : '';
  }

  filterFunc = (queue: CallQueueEntity): boolean => {
    // Check the role by filteredQueueIds
    if (!this.currentUser) { //  || (!this.currentUser.isAdmin() && !this.currentUser.isSupervisor() && queue.belong_to_groups(this.currentUser.group_ids)),
      return false;
    }

    if (!this.isAdmin) {
      if ((_.isEmpty(this.filteredQueueIds) || !_.isEmpty(this.filteredQueueIds) && queue.id && !_.includes(this.filteredQueueIds, queue.id)) && 
        (_.isEmpty(this.filteredGroupIds) || !queue.group_id || !_.isEmpty(this.filteredGroupIds) && queue.group_id && !_.includes(this.filteredGroupIds, queue.group_id))
      ) {
        return false;
      }
    }

    return !queue.is_outbound_campaign &&
      (!this.form || this.form && !queue.is_archived === this.form.get('filterActivated').value) &&
      this.filterPredicate(queue, this.filterString);
  }

  private filterPredicate(queue: CallQueueEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(queue.queue_name, filterString) || (queue.group && regexSearch(queue.group.name, filterString));
  }

  renderExportedGroupName(row: CallQueueEntity): string {
    return row.group ? row.group.name : '';
  }

  private readonly onFormValueChage = (formValues: { filterString: string, filterActivated: boolean }): void => {
    // this.filterActivated = formValues.filterActivated;
    // this.filterString = (formValues.filterString || '').trim();

    // this.dataSource.filter = this.filterString;
    // this.dataSource.onFilterChange();
    this.onFormReload();
  }

  onFormReload(): void {
    const formValues = this.form.value;
    this.filterActivated = formValues.filterActivated;
    this.filterString = (formValues.filterString || '').trim();

    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  private async bulkDelete(items: CallQueueEntity[]): Promise<any> {
    const confirmed: boolean = await this.callQueueService.getConfirmDeleteCallQueues(items);
    if (confirmed) {
      try {
        await this.queueRepo.bulkDelete(items);
        this.flexCrud.closeSidePanel();
        this.successToast(_ti('call_queue.message.delete_success'));
      } catch (error) {
        console.error('Delete queue error', error);
        this.showErrorMessage(error, _ti('call_queue.message.delete_failure'));
      }
    }
  }

  async createQueue(): Promise<any> {
    try {
      const repo = EntityManager.getRepository<CallQueueRepository>('CallQueueRepository');
      const response: { object_id: number } = await repo.createAndSave();
      const newQueue: CallQueueEntity = repo.getObjectById(response.object_id);
      this.gotoPageEditCallQueue(newQueue);
    } catch (error) {
      console.error('Create queue failure', error);
      this.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  gotoPageEditCallQueue(queue: CallQueueEntity): void {
    this.router.navigate(['queues', queue.id], { skipLocationChange: false });
  }

  // public async bulkEnableVoiceMail(items: CallQueueEntity[]): Promise<any> {
  //   // const values = await this.translate
  //   //   .get(['dialogs.confirmation', 'user.content.confirm_enable_voicemail', 'dialogs.no', 'dialogs.yes'])
  //   //   .toPromise();
  //   if (!items || !items.length) {
  //     return;
  //   }

  //   return this.dialogService.confirmDialog(
  //     _ti('dialogs.confirmation'),
  //     _ti('user.content.confirm_enable_voicemail'),
  //     () => {
  //       EntityManager.getRepository<CallQueueRepository>('CallQueueRepository')
  //         .bulkChangeStateVoiceMail(items, 1)
  //         .then(response => {
  //           this.successToast(_ti('public.message.enable_success'));
  //         }, erorr => {
  //           this.showError(_ti('public.message.enable_failure'));
  //         });
  //     }
  //   );
  // }

  // public async bulkDisableVoiceMail(items: CallQueueEntity[]): Promise<any> {
  //   if (!items || !items.length) {
  //     return;
  //   }

  //   return this.dialogService.confirmDialog(
  //     _ti('dialogs.confirmation'),
  //     _ti('user.content.confirm_disable_voicemail'),
  //     () => {
  //       EntityManager.getRepository<CallQueueRepository>('CallQueueRepository')
  //         .bulkChangeStateVoiceMail(items, 0)
  //         .then(response => {
  //           this.successToast(_ti('public.message.disable_success'));
  //         }, error => {
  //           this.showError(_ti('public.message.disable_failure'));
  //         });
  //     }
  //   );
  // }

  async bulkEnableRecording(items: CallQueueEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.content.confirm_enable_recording'),
      async () => {
        try {
          await EntityManager.getRepository<CallQueueRepository>('CallQueueRepository')
            .bulkChangeStateRecordingCall(items, 1);
          this.successToast(_ti('public.message.update_success'));
        } catch (error) {
          console.error('Enable recording call of queue error', error);
          this.showErrorMessage(error, _ti('public.message.update_failure'));
        }
      }
    );
  }

  async bulkDisableRecording(items: CallQueueEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.content.confirm_disable_recording'),
      async () => {
        try {
          await EntityManager.getRepository<CallQueueRepository>('CallQueueRepository')
            .bulkChangeStateRecordingCall(items, 0);
          this.successToast(_ti('public.message.update_success'));
        } catch (error) {
          console.error('Disable recording call of queue error', error);
          this.showErrorMessage(error, _ti('public.message.update_failure'));
        }
      }
    );
  }

  async cloneQueue(items: CallQueueEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    try {
      const resp = await EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').cloneAndSave(items[0]);
      // console.log('resp clone', resp);
      this.successToast(_ti('public.message.clone_success'));
    } catch (error) {
      console.log('Clone queue error', error);
      this.showErrorMessage(error, _ti('public.message.clone_failure'));
    }
  }

  async bulkArchive(items: CallQueueEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.archive_confirm'),
      () => {
        this.updateArchives(items, true);
      }
    );
  }

  async bulkUnarchive(items: CallQueueEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('public.message.unarchive_confirm'),
      () => {
        this.updateArchives(items, false);
      }
    );
  }

  private async updateArchives(items: CallQueueEntity[], isArchive: boolean): Promise<any> {
    const archive = isArchive ? 1 : 0;
    try {
      await EntityManager.getRepository<CallQueueRepository>('CallQueueRepository').bulkArchive(items, archive);
      const successMsg = isArchive ? _ti('public.message.archive_success') : _ti('public.message.unarchive_success');
      this.successToast(successMsg);
    } catch (error) {
      console.error('Change archive state of queue error', error);
      const errorMsgDefault = isArchive ? _ti('public.message.archive_failure') : _ti('public.message.unarchive_failure');
      this.showErrorMessage(error, errorMsgDefault);
    }
  }
}
