import { Injectable } from '@angular/core';
import { IEnterpriseGatewayConfig } from '@wephone-core/model/entity/enterprise_sip_gateway.i';
import { HttpEngine } from '@wephone-core/service/http_engine';
import * as _ from 'lodash';

@Injectable()
export class EnterpriseSipGatewayService {
  static GET_CONFIGURED_ENTERPRISE_GATEWAY_PATH = 'enterprise_sip_gateway/gateway_config';
  static GET_CONFIGURED_ENTERPRISE_GATEWAY_PROVISIONING_TOKEN_PATH = 'enterprise_sip_gateway/provisioning_token/_ID_';

  constructor(
    private readonly httpEngine: HttpEngine
  ) { }

  async getEnterpriseGatewayConfig(): Promise<IEnterpriseGatewayConfig> {
    return this.httpEngine.apiGetV2(EnterpriseSipGatewayService.GET_CONFIGURED_ENTERPRISE_GATEWAY_PATH);
  }

  async getEnterpriseGatewayProvisioningToken(id: number): Promise<string> {
    const url = EnterpriseSipGatewayService.GET_CONFIGURED_ENTERPRISE_GATEWAY_PROVISIONING_TOKEN_PATH.replace('_ID_', `${id}`);
    return this.httpEngine.apiGetV2(url);
  }
}
