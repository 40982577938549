import { datafield, Entity } from '@wephone-core/model/model';

export class QueueTransferNumberEntity extends Entity {
  public static object_type_id = 'queue_transfernumber';

  public id: number;

  @datafield
  public call_queue_keyid: number;

  @datafield
  public label: string;

  @datafield
  public phone_number: string;
}
