import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IFlexTableSidePannelOptions,
  DialogService,
  DynamicFilterSource,
  IFlexTableConfig,
  regexSearch,
  FlexCRUDPageComponent,
} from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { EnterpriseSipGatewayRepository } from '@wephone-core/model/repository/enterprise_sip_gateway';
import { EnterpriseSipGatewayEntity } from '@wephone-core/model/entity/enterprise_sip_gateway';
import { EnterpriseSipGatewayCreateModalComponent } from '@wephone/modals/enterprise-sip-gateway-create-modal/enterprise-sip-gateway-create-modal.component';
import { EditEnterpriseSipGatewayComponent } from '@wephone/components/edit-enterprise-sip-gateway/edit-enterprise-sip-gateway.component';

@Component({
  selector: 'app-enterprise-sip-gateway',
  templateUrl: './enterprise-sip-gateway.component.html',
  styleUrls: ['./enterprise-sip-gateway.component.scss']
})
export class EnterpriseSipGatewayComponent extends FlexBasePage implements OnInit {
  sipGatewayRepo = EnterpriseSipGatewayRepository.getInstance<EnterpriseSipGatewayRepository>();
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditEnterpriseSipGatewayComponent,
  };

  filterString: string;
  form: FormGroup;

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
  ) {
    super();

    const sipGatewaySource = this.sipGatewayRepo.dataSource<EnterpriseSipGatewayEntity>();
    this.dataSource = new DynamicFilterSource(sipGatewaySource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('enterprise_sip_gateway.form.name'),
          searchable: true,
          sortable: true,
          sort: 'asc'
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            hint: _tk('public.create'),
            callback: () => {
              return this.createNewSipGateway();
            }
          },
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ],
        }
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });
    this.form.valueChanges.subscribe(this.onFormValueChage);
  }

  protected async resolveData(): Promise<any> {
    await this.sipGatewayRepo.loadObjectList();
  }

  private readonly filterFunc = (item: EnterpriseSipGatewayEntity) => {
    return this.filterPredicate(item, this.filterString);
  }

  private filterPredicate(item: EnterpriseSipGatewayEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }

    return regexSearch(item.name, filterString) ||
      regexSearch(item.model, filterString) ||
      regexSearch(item.mac, filterString);
  }

  private readonly onFormValueChage = (formValues: any) => {
    this.filterString = (formValues.filterString || '').trim();
    this.dataSource.onFilterChange();
  }

  private async bulkDelete(items: EnterpriseSipGatewayEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        try {
          await this.sipGatewayRepo.bulkDelete(items);
          this.flexCrud.closeSidePanel();
          this.successToast(_ti('public.message.delete_success'));
        } catch (e) {
          console.error('Delete Enterprise SIP Gateway error', e);
          this.showErrorMessage(e, _ti('public.message.delete_failure'));
        }
      }
    );
  }

  async createNewSipGateway(): Promise<any> {
    const dialogRef = this.dialogService.openDialog2(EnterpriseSipGatewayCreateModalComponent, { size: 's' });
    const respData = await dialogRef.afterClosed().toPromise();
    if (respData && respData.id) {
      this.router.navigate(['telephony', 'enterprise-gateway', respData.id], { skipLocationChange: false });
    }
  }
}
