import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OutcallService } from '@wephone-core/service/outcall.service';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { ToastService, DialogActionButton, Colors } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-outcallcampaign-reset-modal',
  templateUrl: './outcallcampaign-reset-modal.component.html',
  styleUrls: ['./outcallcampaign-reset-modal.component.scss']
})
export class OutcallcampaignResetModalComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('dialogs.confirmation');
  dialogRightActions: DialogActionButton[] = [
    {
      id: 'resetOnlyUnsuccessful',
      label: 'campaign.btn.reset_only_unsuccessful',
      action: () => {
        this.resetOnlyUnsuccessfullCampaigns();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    },
    {
      id: 'resetAll',
      label: _tk('campaign.btn.reset_all'),
      action: () => {
        this.resetAllCampaigns();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    },
  ];

  private readonly outcall_campaign: OutcallCampaignEntity;

  constructor(
    dialogRef: MatDialogRef<OutcallcampaignResetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public outcallService: OutcallService,
    public toast: ToastService,
  ) {
    super(dialogRef);
    this.outcall_campaign = data.outcall_campaign;
  }

  ngOnInit(): void {
    super.ngOnInit();
 }

  on_cancel(): void {
    this.dismiss();
  }

  async resetAllCampaigns(): Promise<void> {
    try {
      await this.outcallService.resetAllCampaigns(this.outcall_campaign.getId());
      this.dismiss();
      this.toast.showSuccess(_ti('campaign.message.reset_campaigns_success'));
    } catch (e) {
      console.error('Cannot reset all campaign', e);
      const msg = e && e.message || _ti('campaign.message.reset_campaigns_failure');
      this.toast.showError(msg);
    }
  }

  async resetOnlyUnsuccessfullCampaigns(): Promise<any> {
    try {
      await this.outcallService.resetOnlyUnsuccessfullCampaigns(this.outcall_campaign.getId())
      this.dismiss();
      this.toast.showSuccess(_ti('campaign.message.reset_campaigns_success'));
    } catch (e) {
      console.error('Cannot reset all campaign', e);
      const msg = e && e.message || _ti('campaign.message.reset_campaigns_failure');
      this.toast.showError(msg);
    }
  }
}
