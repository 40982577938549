import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DynamicFilterSource, DialogService, IFlexTableSidePannelOptions, regexSearch, FlexCRUDPageComponent, IFlexTableConfig } from '@wephone-utils';
import { FlexBasePage } from '@wephone-core-ui';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { TagRepository } from '@wephone-core/model/repository/tag';
import { TagEntity } from '@wephone-core/model/entity/tag';
import { TagEditComponent } from '@wephone/components/tag/tag-edit/tag-edit.component';
import { CreateTagModalComponent } from '@wephone/modals/create-tag-modal/create-tag-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tag-list',
  host: { class: 'flex-page-component' },
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent extends FlexBasePage implements OnInit, OnDestroy {
  private tagRepo = TagRepository.getInstance() as TagRepository;

  tagForm: FormGroup;
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: TagEditComponent
  };
  @ViewChild('flexCrud', { static: false }) flexCrud: FlexCRUDPageComponent;
  constructor(
    private fb: FormBuilder,
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {
    super();
    const tagSource = EntityManager.getRepository('TagRepository').dataSource<TagEntity>();
    this.dataSource = new DynamicFilterSource(tagSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'tag_name',
          label: _tk('tag.content.tag_name'),
          sortable: true,
          sort: 'asc',
          customSortFn: (tag: TagEntity) => {
            return tag.tag_name.toLowerCase();
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.tagForm = this.fb.group({
      filterValue: [this.filterValue]
    });

    this.addSubscription(this.tagForm.valueChanges.subscribe(this.onFormValueChange));
  }

  protected async resolveData(): Promise<any> {
    return EntityManager.getRepository<TagRepository>('TagRepository').loadObjectList();
  }

  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  private filterFunc = (item: TagEntity) => {
    return this.filterPredicate(item, this.filterValue);
  }

  private filterPredicate(item: TagEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(item.tag_name, filterString);
  }

  async bulkDelete(tags: TagEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        if (!tags) {
          return;
        }

        try {
          this.flexCrud.closeSidePanel();
          await this.tagRepo.bulkDelete(tags);
          this.successToast(_ti('public.message.delete_success'));
        } catch (e) {
          console.error('Delete Tag erorr: ' + e);
          const errMsg = e && e.error && e.error.message || e && e.message || '';
          this.showError(`${_ti('public.message.delete_failure')} ${errMsg}`);
        }
      }
    );
  }

  async createNew(): Promise<any> {
    try {
      const dialogRef = this.dialogService.openDialog2(CreateTagModalComponent, { size: 's' });
      const newTag = await dialogRef.afterClosed().toPromise();

      if (!newTag) {
        return;
      }

      this.gotoEditTagPage(newTag.id);
    } catch (e) {
      console.error('Create Enterprise CRM erorr: ' + e);
      const errMsg = e && e.error && e.error.message || e && e.message || '';
      this.showError(_ti('public.message.create_failure') + ` ${errMsg}`);
      return false;
    }
  }

  private gotoEditTagPage(tagId: number): void {
    this.router.navigateByUrl(`crm/tag/${tagId}`);
  }
}
