import * as _ from 'lodash';
import { Component, OnInit, Inject } from '@angular/core';
import { Colors, DialogActionButton } from '@wephone-utils';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';

@Component({
  selector: 'queue-edit-didroute',
  templateUrl: './queue-edit-didroute.component.html',
  styleUrls: ['./queue-edit-didroute.component.scss']
})
export class QueueEditDidrouteComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('call_queue.content.number_routed_to_queue');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.ok'),
      action: () => {
        this.saveDidRoute();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  form: FormGroup;
  queue: CallQueueEntity;

  constructor(
    dialogRef: MatDialogRef<QueueEditDidrouteComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected fb: FormBuilder,
    private em: EntityManager,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.queue = this.data.queue; //_.cloneDeep();
    if (this.queue.queue_priority === undefined) {
      this.queue.queue_priority = 2;
    }
    this.initFormGroup();
  }

  initFormGroup(): void {
    // const defaultDid = this.queue.default_did ? this.queue.default_did : this.getEmptyDid();
    this.form = this.fb.group({
      // defaultDid: [defaultDid],
      did_list: [this.queue.did_list]
    });
  }

  // get defaultDidControl(): FormControl {
  //   return this.form.get('defaultDid') as FormControl;
  // }

  get didListControl(): FormArray {
    return this.form.get('did_list') as FormArray;
  }

  async saveDidRoute(): Promise<any> {
    // const fieldList = ['default_did_id'];
    const fieldList = ['didroutes'];
    // this.queue.default_did = this.defaultDidControl.value;
    // this.queue.did_list = this.didListControl.value;
    const didRoutes: {
      id?: number;
      name?: string;
      welcome_message?: string;
      route_priority?: number;
      skill_requirements?: any[];
    }[] = [];

    if (this.didListControl.value) {
      for (const r of this.didListControl.value as DidEntity[]) {
        didRoutes.push({
          id: r.id,
          name: r.name,
          welcome_message: r.welcome_message,
          route_priority: r.route_priority
        });
      }
    }

    const extraParams = {
      didroutes: didRoutes
    };

    try {
      const returnedQueue: {
        object_id: number;
      } = await this.em.getRepository<CallQueueRepository>('CallQueueRepository').saveAttrs(this.queue, fieldList, extraParams);

      if (returnedQueue) {
        this.successToast(_ti('call_queue.message.update_success'));
        const ret = { id: returnedQueue.object_id };
        this.dismiss(ret, false);
        return ret;
      }
    } catch (e) {
      this.showErrorMessage(e, _ti('call_queue.message.update_failure'));
    }

  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
  }

  private getFormResetData(): object {
    return {
      // defaultDid: this.queue.default_did,
      did_list: _.cloneDeep(this.queue.did_list)
    };
  }

  // getChangeSet(): object {
  //   const formData = _.cloneDeep(this.formService.getChangeSet(this.form));

  //   if (formData && formData.did_list) {
  //     // Add removed did list into request server
  //     for (const did of _.cloneDeep(this.queue.did_list)) {
  //       const found = _.find(formData.did_list, (i: DidEntity) => i.id === did.id);
  //       if (formData.did_list.length === 0 || !found) {
  //         did.clearRoutingData();
  //         formData.did_list.push(did);
  //       }
  //     }
  //   }

  //   return formData;
  // }

  // public isValid(): boolean {
  //   return true;
  // }

  // private getEmptyDid(): DidEntity {
  //   return DidRepository.getInstance<DidRepository>().getEmptyDid(
  //     _ti('call_queue.content.enterprise_default_number')
  //   );
  // }
}
