import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';

export class RoutingApSipTrunk extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.sip_trunk;
  private sipTrunk: SipTrunkEntity;

  setSipPhone(sipTrunk: SipTrunkEntity): void {
    this.setAppParams({ siptrunk_id: sipTrunk.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.sipphone_id) {
      this.sipTrunk = EntityManager.getRepository('SipTrunkRepository').getObjectById(this.appParams.siptrunk_id);
    }
  }

  getRelatedObject(): SipTrunkEntity {
    return this.sipTrunk;
  }

  getDescription(): string {
    const name: string = this.sipTrunk ? this.sipTrunk.name : '<invalid>';
    return `To SIP trunk ${name}`;
  }
}
