<div fxfill class="flex-did-config-wizard flex-wrapper">
  <div class="col-left" fxHide.xs="true">
    <img src="assets/images/onboarding/bg-did-config-wizard-monkey.png" alt="Monkey" title="Monkey" class="monkey" />
  </div>
  <div class="col-right">
    <div class="inner">
      <ng-container *ngIf="stepActive == 1; then step1"></ng-container>
      <ng-container *ngIf="stepActive == 2; then step2"></ng-container>
      <ng-container *ngIf="stepActive == 3; then step3"></ng-container>
    </div>
  </div>
</div>

<ng-template #step1>
  <div class="step-one text-center" ngClass.gt-xs="mt-3">
    <h2 class="title">{{ 'did_setup_wizard.step1.title'|translate }}</h2>
    <div>
      <mat-form-field class="full-width mt-1">
        <mat-label>{{ 'did_setup_wizard.give_did_name'|translate }}</mat-label>
        <input data-ci="phone_name" matInput [formControl]="didNameControl"
          placeholder="{{ 'did.name.placeholder'|translate }}">
        
        <mat-error [flexFormControlError]="didNameControl"
          [customMessages]="{
            'name_exist': 'public.message.name_exist'|translate
          }"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <button data-ci="save-name" mat-raised-button color="primary" (click)="saveDidName()"
        class="mt-1">{{ 'public.save'|translate }}</button>
    </div>
  </div>
</ng-template>

<ng-template #step2>
  <div class="step-two text-center">
    <h2 class="title">{{ 'did_setup_wizard.step2.title'|translate }}</h2>
    <mat-chip-list class="mat-chip-list-stacked route-to-list text-center mt-3">
      <mat-chip data-cy="route-to-queue" color="primary" (click)="setRouteApplication('callqueue')"
        class="route-to-item mt-1">
        <mat-icon class="option-icon">people_outline</mat-icon>
        <span class="option-title">{{'dialogs.did_setup_wizard.step2.route_to_queue'|translate}}</span>
        <span class="ml-1 explain-choice">{{'dialogs.did_setup_wizard.step2.route_to_queue_hint'|translate}}</span>
      </mat-chip>
      <mat-chip data-cy="route-to-sipphone" color="primary" (click)="setRouteApplication('call_phone')"
        class="route-to-item mt-1">
        <mat-icon class="option-icon">people_outline</mat-icon>
        <span class="option-title">{{'dialogs.did_setup_wizard.step2.route_to_sipphone'|translate}}</span>
        <span class="ml-1 explain-choice">{{'dialogs.did_setup_wizard.step2.route_to_sipphone_hint'|translate}}</span>
      </mat-chip>
      <mat-chip data-cy="route-to-ivr" color="primary" (click)="setRouteApplication('ivr_custom_menu')"
        class="route-to-item mt-1">
        <mat-icon class="option-icon">dialpad</mat-icon>
        <span class="option-title">{{'dialogs.did_setup_wizard.step2.route_to_ivrmenu'|translate}}</span>
        <span class="ml-1 explain-choice">{{'dialogs.did_setup_wizard.step2.route_to_ivrmenu_hint'|translate}}</span>
      </mat-chip>
      <mat-chip *ngIf="dedicatedDidIds.length" data-cy="route-to-alias" color="primary"
        (click)="setRouteApplication('alias')" class="route-to-item mt-1">
        <mat-icon class="option-icon">phone</mat-icon>
        <span class="option-title">{{'dialogs.did_setup_wizard.step2.tracking_number'|translate}}</span>
        <span class="ml-1 explain-choice">{{'dialogs.did_setup_wizard.step2.tracking_number_hint'|translate}}</span>
      </mat-chip>

      <mat-chip data-cy="route-to-other" color="primary" (click)="setOtherDestination()" class="route-to-item mt-1">
        <mat-icon class="option-icon">more_horiz</mat-icon>
        <span class="option-title">{{'dialogs.did_setup_wizard.step2.route_to_other'|translate}}</span>
        <span class="ml-1 explain-choice">{{'dialogs.did_setup_wizard.step2.route_to_other_hint'|translate}}</span>
      </mat-chip>

    </mat-chip-list>
  </div>
</ng-template>

<ng-template #step3>
  <div class="step-three text-center">
    <h2 class="title">{{'dialogs.did_setup_wizard.step3.routing_option'|translate}}</h2>

    <ng-container [ngSwitch]="routeTo.destination">
      <!-- <div *ngSwitchCase="'call_phone_number'">
        <mat-form-field class="full-width mt-1">
          <mat-label>{{'dialogs.did_setup_wizard.step3.phone_number'|translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="routeTo.phone_number" placeholder="{{ 'input.phone.placeholder'|translate }}" pattern="^[0-9]{4,20}$" [required]="true" />
        </mat-form-field>
      </div> -->

      <ng-container *ngSwitchCase="'call_phone'">
        <div>
          <mat-form-field class="full-width mt-1">
            <mat-label>{{'dialogs.did_setup_wizard.step3.selected_sipphone'|translate}}</mat-label>
            <sipphone-select-form-input [(ngModel)]="routeTo.sipphone" [isConfigured]="true"></sipphone-select-form-input>
            <mat-error [hidden]="!message">{{ message }}</mat-error>
          </mat-form-field>
          <!-- <span class="mat-error" [hidden]="!message">{{ message }}</span> -->
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'callqueue'">
        <div>
          <mat-form-field class="full-width mt-1">
            <mat-label>{{ 'dialogs.did_setup_wizard.step3.selected_queue'|translate }}</mat-label>
            <queue-select-form-input #queueSelectInput [(ngModel)]="routeTo.queue"></queue-select-form-input>
            <!--  (ngModelChange)="updateConfigRoute($event)" -->
            <mat-error [hidden]="!message">{{ message }}</mat-error>
          </mat-form-field>
        </div>
        <div class="text-left">
          <button appFreezeClick mat-stroked-button color="accent"
            (click)="createQueue()">{{ 'dialogs.did_setup_wizard.step3.create_queue'|translate }}</button>
        </div>
      </ng-container>

      <!-- <ng-container *ngSwitchCase="'conference'">
        <div>
          <mat-form-field class="full-width mt-1">
            <mat-label>{{'dialogs.did_setup_wizard.step3.selected_conference'|translate}}</mat-label>
            <conference-select-form-input #conferenceSelectInput [(ngModel)]="routeTo.conference"></conference-select-form-input>
            <mat-error [hidden]="!message">{{ message }}</mat-error>
          </mat-form-field>
        </div>
        <div class="text-left">
          <button  mat-stroked-button color="accent" (click)="createConference()">{{ 'dialogs.did_setup_wizard.step3.create_conference'|translate }}</button>
        </div>
      </ng-container> -->

      <ng-container *ngSwitchCase="'ivr_custom_menu'">
        <div>
          <mat-form-field class="full-width mt-1">
            <mat-label>{{'dialogs.did_setup_wizard.step3.selected_ivrmenu'|translate}}</mat-label>
            <ivr-menu-select-form-input #ivrmenuSelectInput [(ngModel)]="routeTo.ivrmenu" [menuType]="0"
              [isConfigured]="true" [required]="true"></ivr-menu-select-form-input>
            <!-- (ngModelChange)="updateConfigRoute($event)" -->
          </mat-form-field>
        </div>
        <div class="text-left">
          <button appFreezeClick data-ci="add-ivr-menu" mat-stroked-button color="accent"
            (click)="createIvrMenu()">{{ 'did_setup_wizard.create_new_ivrmenu'|translate }}</button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'alias'">
        <div>
          <mat-form-field class="full-width mt-1">
            <mat-label>{{'dialogs.did_setup_wizard.step3.select_master_did'|translate}}</mat-label>
            <did-select-form-input [attr.data-ci]="'master_dids'" [(ngModel)]="did.master_did"
              [includeIds]="dedicatedDidIds"></did-select-form-input>
            <!-- (ngModelChange)="updateConfigRoute($event)" -->
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
    <div class="mt-3">
      <div class="mat-error mb-1" *ngIf="message">{{ message }}</div>
      <div class="actions">
        <button mat-stroked-button color="accent" (click)="prevStep()">{{ 'public.navigation.back'|translate }}</button>
        <button appFreezeClick data-ci="finish" mat-raised-button color="accent"
          (click)="updateDidConfig()">{{ 'public.finish'|translate }}</button>
      </div>
    </div>
  </div>
</ng-template>
