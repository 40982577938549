<div fxLayout="column" fxFill>
  <ng-template #tplListFilter>
    <form [formGroup]="form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start"
      fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <mat-checkbox formControlName="connected">{{ 'sipphone.content.show_only_connected'|translate }}</mat-checkbox>
      </div>
      <div>
        <flex-search-input formControlName="filterString" [hintText]="'sipphone.search.hint'|translate"
          ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>

  <flex-crud-page #flexCrud pageTitle="{{ 'sipphone.title.manage'|translate }}" [filterTemplate]="tplListFilter"
    [tableConfig]="tableConfig" [dataSource]="dataSource"
    [columnTemplates]="{'extension': tplExtension, 'owner': tplOwner, 'last_online_dt': tplLastOnlineDt, 'description': tplDescription}"
    [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">
    <ng-template #tplExtension let-sipphone="row">
      <fa-icon [icon]="FAIcons.faCircle" class="mr-1"
        [ngClass]="{'mat-text-muted': !sipphone.enabled || !sipphone.is_online, 'mat-text-success': sipphone.enabled && sipphone.is_online}">
        brightness_1</fa-icon>
      <span [ngClass]="{'mat-text-muted': !sipphone.enabled}"> {{sipphone.extension}} </span>
    </ng-template>
    <ng-template #tplDescription let-sipphone="row">
      <span [ngClass]="{'mat-text-muted': !sipphone.enabled}"> {{sipphone.description}} </span>
    </ng-template>
    <ng-template #tplOwner let-sipphone="row">
      <span class="owner" *ngIf="sipphone.user_id" [ngClass]="{'mat-text-muted': !sipphone.enabled}">
        <mat-icon *ngIf="is_hotel">person</mat-icon>{{sipphone.user?.name }}
      </span>
      <span class="owner" *ngIf="sipphone.hotel_room_id" [ngClass]="{'mat-text-muted': !sipphone.enabled}">
        <mat-icon>hotel_class</mat-icon>
        {{ 'hotelroom.content.room'|translate}} {{ sipphone.hotelroom?.room_number }}
      </span>
    </ng-template>
    <ng-template #tplLastOnlineDt let-sipphone="row">
      <div *ngIf="!sipphone.is_online" [ngClass]="{'mat-text-muted': !sipphone.enabled}">{{
        sipphone.last_online_dt|timeFormatDateTimeShort }}</div>
      <div *ngIf="sipphone.is_online">{{ 'sipphone.content.online_now'|translate }}</div>
    </ng-template>
  </flex-crud-page>
</div>
