import { Component, OnInit, ViewChild } from '@angular/core';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ToastService, IFlexTableSidePannelOptions, DynamicFilterSource, regexSearch, IFlexTableConfig } from '@wephone-utils';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { OpeningHourCalendarEditComponent } from '@wephone/components/opening-hour-calendar-edit/opening-hour-calendar-edit.component';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { FlexBaseComponent } from '@wephone-core-ui';
import { OpeningCalendarEditDialogComponent, IOpeningCalendarEditDialogConfig } from '../../components/calendar';
import * as _ from 'lodash';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';
import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';
import { Router } from '@angular/router';
import { DidEntity } from '@wephone-core/model/entity/did';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { AgentEntity } from '@wephone-core/model/entity/agent';

@Component({
  selector: 'app-opening-hours',
  host: { class: 'flex-page-component' },
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent extends FlexBaseComponent implements OnInit {
  private openingCalendarRepo = OpeningHourCalendarRepository.getInstance() as OpeningHourCalendarRepository;
  // private enterpriseRepo: EnterpriseEntity;
  public myEnterprise: EnterpriseEntity;
  private openingCalendarList: OpeningHourCalendarEntity[];

  public tplName;
  public calendar: OpeningHourCalendarEntity;
  public filterForm: FormGroup;
  newOpeningCalendarList = [];
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: OpeningHourCalendarEditComponent
  };

  singleItemEditor;
  defaultCalendarControl;

  config: IOpeningCalendarEditDialogConfig = {
    editCustomData: false,
    editName: true,
    editGroup: true
  };

  @ViewChild('flexCrud') flexCrud;
  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private router: Router,
    private readonly em: EntityManager,
  ) {
    super();
    const enterpriseRepo: EnterpriseRepository = EntityManager.getRepository<EnterpriseRepository>(
      'EnterpriseRepository'
    );
    this.openingCalendarList = this.openingCalendarRepo.getObjectList();
    const openingCalendarSource = this.openingCalendarRepo.dataSource<OpeningHourCalendarEntity>();
    this.dataSource = new DynamicFilterSource(openingCalendarSource, this.filterItemOnSearch);
    this.myEnterprise = enterpriseRepo.getMyEnterprise() as EnterpriseEntity;

    this.newOpeningCalendarList = _.cloneDeep(this.openingCalendarList);
    const noCalendar: any = this.openingCalendarRepo.create();
    noCalendar.name = _ti('call_queue.content.no_calendar');
    noCalendar.id = null;
    this.newOpeningCalendarList.unshift(noCalendar);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('opening_hour_calendar.content.calendar_name'),
          enableTooltip: true,
          lineClamp: 2,
          sortable: true,
          sort: 'asc',
          customSortFn: (calendar: OpeningHourCalendarEntity) => {
            return calendar.name.toLowerCase();
          }
        },
        {
          name: 'default',
          label: _tk('public.default'),
          searchable: false,
          sortable: false,
          width: '100px'
        }
      ],
      listActions: {
        defaultActions: [
          // Visible action buttons when no item is selected
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: (params: any) => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    let selectedOpeningCalendar = this.newOpeningCalendarList.find(
      o => o.id === this.myEnterprise.opening_calendar_id
    );
    if (!selectedOpeningCalendar) {
      selectedOpeningCalendar = this.newOpeningCalendarList[0];
    }

    this.defaultCalendarControl = new FormControl(selectedOpeningCalendar);

    this.filterForm = this.fb.group({
      filterValue: ['']
    });

    this.filterForm.valueChanges.subscribe(this.onFormValueChange);
    this.defaultCalendarControl.valueChanges.subscribe((selectedCalendar: OpeningHourCalendarEntity) => {
      return enterpriseRepo.updateEnterprise({ opening_calendar_id: selectedCalendar.id }).then(
        (response: any) => {
          this.showUpdateConfirmation();
        },
        (response: any) => {
          this.showError(response.data.error.message);
        }
      );
    });
  }

  filterItemOnSearch = (item: OpeningHourCalendarEntity) => {
    return !item.is_private && this.filterPredicate(item, this.filterValue);
  }

  private filterPredicate(item: OpeningHourCalendarEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(item.name, filterString);
  }

  renderGroupName(item: OpeningHourCalendarEntity): String {
    return item.group ? item.group.name : '';
  }

  private onFormValueChange = (formValues: any) => {
    console.log('formValues: ', formValues);

    this.filterValue = (formValues.filterValue || '').trim();
    // this.defaultCalendarControl = formValues.defaultCalendarControl;

    // this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  async createNew(): Promise<any> {
    try {
      const newName = this.getNewName();
      const newCalendar = this.openingCalendarRepo.createDefaultCalendar(newName) as OpeningHourCalendarEntity;
      const config = _.cloneDeep(this.config);
      config.showSideSteps = false;
      config.showUsedObjects = false;

      const dialogRef = this.dialogService.openDialog2(OpeningCalendarEditDialogComponent, {
        data: {
          calendar: newCalendar,
          config
        }
      });

      const createCalendar = await dialogRef.afterClosed().toPromise();
      if (!createCalendar) {
        console.warn('No calendar created');
        return;
      }
      const extra_param: any = {
        openinghours: (createCalendar.openinghours || []).map((o: OpeningHourEntity) => o.dumpObjectData()),
        openinghours_special_date: (createCalendar.openinghours_special_date || []).map((s: OpeningHourSpecialDateEntity) => s.dumpObjectData())
      };

      const createdCalendar = await this.openingCalendarRepo.save(createCalendar, extra_param);

      if (createdCalendar && createdCalendar.id) {
        // redirect to edit page after created
        this.router.navigateByUrl(`/callcenter-params/opening-hour/${createdCalendar.id}`);
      }

      this.successToast(_ti('public.message.create_success'));
      return true;
    } catch (e) {
      console.error('Craete opening hour failure', e);
      this.showError(e && e.message || _ti('public.message.create_failure'));
    }
  }

  private getEntityUsingCalendar(entityType: string, id: number): any {
    return this.em.getRepositoryById(entityType).getObjectById(id);
  }

  private async getConfirmDeleteCalendars(calendars: OpeningHourCalendarEntity[]): Promise<any> {
    const msgs: string[] = [];
    const dids: DidEntity[] = [];
    const agents: AgentEntity[] = [];
    const callQueues: CallQueueEntity[] = [];
    const calendarIds = calendars.map(c => c.id);
    const data: any[] = await this.openingCalendarRepo.getObjectsUsingCalendars(calendarIds);

    // for (const calendar of calendars) {
    //   const data: any[] = await this.openingCalendarRepo.getObjectsUsingCalendar(calendar.id);

    for (const item of data) {
      const entityType: string = item[0];
      const id = item[1];

      if (!entityType || !id) {
        continue;
      }

      const entity = this.getEntityUsingCalendar(entityType, id);

      switch (entityType) {
        case DidEntity.object_type_id:
          if (!_.includes(dids.map(d => d.id), entity.id)) {
            dids.push(entity);
          }
          break;
        case AgentEntity.object_type_id:
          if (!_.includes(agents.map(a => a.id), entity.id)) {
            agents.push(entity);
          }
          break;
        case CallQueueEntity.object_type_id:
          if (!_.includes(callQueues.map(q => q.id), entity.id)) {
            callQueues.push(entity);
          }
          break;
        default:
      }
    }
    // }

    if (dids.length) {
      let calendarObjects: OpeningHourCalendarEntity[] = calendars.filter(c => _.includes(dids.map(d => d.calendar_id), c.id));
      calendarObjects = _.uniqBy(calendarObjects, c => c.id);

      msgs.push(_ti('work_calendar.message.confirm_delete_with_dependent_did', {
        did_numbers: dids.map(x => x.display_number).join(', '),
        calendar_names: calendarObjects.map(x => x.name).join(', '),
      }));
    }

    if (agents.length) {
      let calendarObjects: OpeningHourCalendarEntity[] = calendars.filter(c => _.includes(agents.map(a => a.working_calendar_id), c.id));
      calendarObjects = _.uniqBy(calendarObjects, c => c.id);

      msgs.push(_ti('work_calendar.message.confirm_delete_with_dependent_agent', {
        agent_names: agents.map(x => x.name).join(', '),
        calendar_names: calendarObjects.map(x => x.name).join(', '),
      }));
    }

    if (callQueues.length) {
      let calendarObjects: OpeningHourCalendarEntity[] = calendars.filter(c => _.includes(callQueues.map(q => q.opening_hour_calendar_id), c.id));
      calendarObjects = _.uniqBy(calendarObjects, c => c.id);

      msgs.push(_ti('work_calendar.message.confirm_delete_with_dependent_queue', {
        queue_names: callQueues.map(x => x.queue_name).join(', '),
        calendar_names: calendarObjects.map(x => x.name).join(', '),
      }));
    }

    return msgs;
  }

  async bulkDelete(items: OpeningHourCalendarEntity[]): Promise<any> {
    const msgInUses: string[] = await this.getConfirmDeleteCalendars(items);

    if (msgInUses.length) {
      msgInUses.unshift(_ti('public.message.cannot_delete_inused'));
      return this.dialogService.showAlert(_ti('dialogs.warning'), msgInUses.join(' \n '));
    }

    return this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('user.title.delete'), () => {
      if (items && !msgInUses.length) {
        this.openingCalendarRepo.bulkDelete(items).then(resp => {
          if (resp) {
            this.flexCrud.closeSidePanel();
            this.successToast(_ti('opening_hour_calendar.message.delete_success'));
          }
        }, resp => {
          let msg = _ti('public.message.delete_failure');
          if (resp && resp.message) {
            msg = resp.message;
          }
          this.showError(msg);
        });
      }
    });
  }

  getNewName(): string {
    const defaultName = _ti('opening_hour_calendar.default_calendar_name');
    let newName: string;
    let i = 1;

    do {
      newName = defaultName + ' ' + i;
      i++;
    } while (this.checkNameCalendar(newName));

    return newName;
  }

  checkNameCalendar(name): boolean {
    for (const calendar of this.openingCalendarList) {
      if (name === calendar.name) {
        return true;
      }
    }

    return false;
  }
}
