import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { DidEntity, DidTelecomProvider } from '@wephone-core/model/entity/did';
import { _ti } from '@wephone-translation';
import { Deferred } from 'ts-deferred';
import { DialogService } from '@wephone-utils';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';

export interface DidNumberTypesResponse {
  count: number;
  area_codes: string[];
  did_type: number;
}
export interface DidCountryItem {
  id?: number;
  code2: string;
  phone_code: string;
  name: string;
}

export interface DidGroupsByTypeResponse {
  city_name: string;
  area_code: string;
  setup_cost: string;
  monthly_cost: string;
}

export interface UpdatePortingNumberParamRequest {
  id: number;
  number: string;
  country_code: string;
  trial_eligible?: number;
}

@Injectable()
export class DidService {
  // Constants

  // static URL_LIST_DID_COUNTRIES = 'did/get_did_country_info';
  static URL_LIST_DID_COUNTRIES = 'did/did_country';
  static URL_GET_DID_COUNTRY_BY_CITY = 'did/get_country_by_city';
  static URL_LIST_DID_CITIES = 'did/city_list/{country_id}';
  static URL_LIST_DID_GROUPS = 'did/get_did_group';
  static URL_BUY_DID = 'did/buy_did';
  static URL_SA_UPDATE_DID = 'sa/did/update';
  static URL_GET_DID_SA_LIST = 'sa/did/list_did';
  static URL_DID_SA_ASSIGN = 'sa/did/assign_numbers';
  static URL_DID_SA_UNASSIGN = 'sa/did/unassign_number';
  static URL_GET_DID_LIST = 'did/list_did';
  static URL_GET_DID_NUMBER_TYPES = 'did/list_did_number_type';
  static URL_GET_DID_GROUPS = 'did/list_did_groups';
  static URL_DID_ROUTE_TO_QUEUE = 'did/route_to_queue';
  static URL_DID_SET_DEDICATED = 'did/set_dedicated_for_user';
  static URL_DID_CHECKING_NUMBERS_VALIDITY = 'sa/did/checking_numbers_validity';
  static URL_DID_MARK_AS_POSTED = 'sa/did/mark_as_ported';
  static URL_DID_UPDATE_PORTING_NUMBER = 'sa/did/update_porting_number';
  static URL_DID_DELETE_PORTING_NUMBER = 'sa/did/delete_porting_number';
  static RESELLER_GET_DID_LIST_PATH = 'reseller/did/list';

  // Constructor
  constructor(
    private httpEngine: HttpEngine,
    private dialogService: DialogService,
  ) { }

  getDidCountries(provider: DidTelecomProvider): Promise<DidCountryItem[]> {
    return this.httpEngine.apiGetV2(DidService.URL_LIST_DID_COUNTRIES, { provider });
  }

  getDidCountryByCity(cityName: string): Promise<any> {
    return this.httpEngine.apiGetV2(DidService.URL_GET_DID_COUNTRY_BY_CITY, { city_name: cityName });
  }

  getDidCities(countryId: number): Promise<{ id: number; name: string; area_code: string }[]> {
    const url = _.replace(DidService.URL_LIST_DID_CITIES, '{country_id}', countryId.toString());
    return this.httpEngine.apiGetV2(url, {});
  }

  getDidsEnterprise(eid: number = 0): Promise<any> {
    return this.httpEngine.apiGetV2(DidService.URL_GET_DID_SA_LIST, { eid });
  }

  resellerGetDidListOfEnterprise(eid: number = 0): Promise<any> {
    return this.httpEngine.apiGetV2(DidService.RESELLER_GET_DID_LIST_PATH, { eid });
  }

  updateDid(didId: number, attrs: any): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_SA_UPDATE_DID, _.extend(attrs, {
      id: didId
    }));
  }

  assignDid(didIdList: number[], enterpriseId: number): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_DID_SA_ASSIGN, { did_id_list: didIdList, enterprise_id: enterpriseId });
  }

  unassignDid(didId: number, enterpriseId: number): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_DID_SA_UNASSIGN, { did_id: didId, enterprise_id: enterpriseId });
  }

  getDidsList(): Promise<any> {
    return this.httpEngine.apiGetV2(DidService.URL_GET_DID_LIST);
  }

  getDidNumberTypes(countryId: number): Promise<DidNumberTypesResponse[]> {
    return this.httpEngine.apiGetV2(DidService.URL_GET_DID_NUMBER_TYPES, { id: countryId });
  }

  getDidGroupsByType(countryId: number, countryStateId: number, didType: number): Promise<DidGroupsByTypeResponse[]> {
    return this.httpEngine.apiGetV2(DidService.URL_GET_DID_GROUPS, {
      country_id: countryId,
      country_state_id: countryStateId,
      did_type: didType,
    });
  }

  // Not in-used
  getDidGroups(countryId, stateId): Promise<any> {
    const params: any = { country_id: countryId };

    if (stateId) {
      params.state_id = stateId;
    }

    return this.httpEngine.apiGet(DidService.URL_LIST_DID_GROUPS, params);
  }

  // Not in-used
  buyDid(didGroupId: number): Promise<any> {
    return this.httpEngine.apiPost(DidService.URL_BUY_DID, { did_group_id: didGroupId });
  }

  routeToQueue(didId: number): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_DID_ROUTE_TO_QUEUE, {
      did_id: didId,
    });
  }

  setDedicatedForUser(didId: number, userId: number): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_DID_SET_DEDICATED, {
      id: didId,
      user_id: userId
    });
  }

  async getConfirmClearRoutingDids(dids: DidEntity[], defaultMsg: string = _ti('user.title.delete')): Promise<boolean> {
    let msg: string = defaultMsg;
    const msgs: string[] = [];
    const routedDids: DidEntity[] = [];
    const routedCrmRoutings: CrmRoutingRuleEntity[] = [];
    const routedIvrMenus: IvrCustomMenuEntity[] = [];
    const aliasDids: DidEntity[] = [];

    for (const did of dids) {
      for (const aliasDid of did.alias_dids) {
        if (!_.includes(aliasDids, aliasDid)) {
          aliasDids.push(aliasDid);
        }
      }

      for (const routedIvrMenu of did.routedIvrMenus) {
        if (!_.includes(routedIvrMenus, routedIvrMenu)) {
          routedIvrMenus.push(routedIvrMenu);
        }
      }

      for (const routedDid of did.routedDids) {
        if (!_.includes(routedDids, routedDid)) {
          routedDids.push(routedDid);
        }
      }

      for (const routedCrmRouting of did.routedCrmRoutings) {
        if (!_.includes(routedCrmRoutings, routedCrmRouting)) {
          routedCrmRoutings.push(routedCrmRouting);
        }
      }
    }

    if (aliasDids.length) {
      let masterDids: DidEntity[] = dids.filter(d => d.alias_dids.length);
      masterDids = _.uniqBy(masterDids, d => d.id);

      const translateKey = aliasDids.length > 1 ? 'did.message.clear_multiple_routing_master' : 'did.message.clear_routing_master';
      msgs.push(_ti(translateKey, {
        alias_numbers: aliasDids.map(x => x.display_number).join(', '),
        master_numbers: masterDids.map(x => x.display_number).join(', ')
      }));
    }

    if (routedDids.length) {
      let didObjects: DidEntity[] = dids.filter(d => d.routedDids.length);
      didObjects = _.uniqBy(didObjects, d => d.id);

      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', {
        dependent_numbers: routedDids.map(x => x.display_number).join(', '),
        objects: didObjects.map(x => x.display_number).join(', '),
      }));
    }

    if (routedCrmRoutings.length) {
      let didObjects: DidEntity[] = dids.filter(d => d.routedCrmRoutings.length);
      didObjects = _.uniqBy(didObjects, d => d.id);

      msgs.push(_ti('crm_routing.message.confirm_delete_with_dependent_items', {
        crm_routing_names: routedCrmRoutings.map(x => x.name).join(', '),
        objects: didObjects.map(x => x.display_number).join(', '),
      }));
    }

    if (routedIvrMenus.length) {
      let didObjects: DidEntity[] = dids.filter(d => d.routedIvrMenus.length);
      didObjects = _.uniqBy(didObjects, d => d.id);

      msgs.push(_ti('ivrmenu.message.confirm_delete_with_dependent_items', {
        ivrmenu_names: routedIvrMenus.map(x => x.name).join(', '),
        objects: didObjects.map(x => x.display_number).join(', '),
      }));
    }

    const ret = new Deferred<boolean>();
    const canDelete = !msgs.length;

    if (!canDelete) {
      msgs.push(_ti('public.message.cannot_continue_action_with_dependent_items'));
      msg = msgs.join('\n');

      await this.dialogService.showAlert(_ti('dialogs.warning'), msg);
      ret.resolve(canDelete);
      return ret.promise;
    }

    await this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      msg,
      () => {
        ret.resolve(canDelete);
      }
    );
    return ret.promise;
  }

  checkingNumbersValidity(phoneNumbers: string[]): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_DID_CHECKING_NUMBERS_VALIDITY, { numbers: phoneNumbers });
  }

  markAsPortedNumber(id: number): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_DID_MARK_AS_POSTED, { ids: [id] });
  }

  updatePortingNumber(id: number, params: UpdatePortingNumberParamRequest): Promise<any> {
    params.id = +id;
    return this.httpEngine.apiPostV2(DidService.URL_DID_UPDATE_PORTING_NUMBER, params);
  }

  deletePortingNumber(id: number): Promise<any> {
    return this.httpEngine.apiPostV2(DidService.URL_DID_DELETE_PORTING_NUMBER, { id: +id });
  }
}
