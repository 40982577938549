import * as _ from 'lodash';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';

export class HotelRoomLogRepository extends BaseRepository {
  ENTITY_CLASS = HotelRoomLogEntity;
  protected URL_PREFIX = 'hotel_room_log';

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }
}

import { HotelRoomLogEntity } from '../entity/hotel_room_log';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
