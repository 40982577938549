import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EntityManager } from '@wephone-core/service/entity_manager';

export class RoutingAppQueue extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.callqueue;
  private queue: CallQueueEntity;

  setCallQueue(queue: CallQueueEntity): void {
    this.setAppParams({ queue: queue.id });
  }

  setRelatedObject(): void {
    if (this.appParams && this.appParams.queue) {
      this.queue = EntityManager.getRepository('CallQueueRepository').getObjectById(this.appParams.queue);
    }
  }

  getRelatedObject(): CallQueueEntity {
    return this.queue;
  }

  getDescription(): string {
    const queue_name = this.queue ? this.queue.queue_name : '<invalid>';

    return `To queue ${queue_name}`;
  }
}
