<div fxFill fxLayout="column" *ngIf="!loading && image">
  <div class="wrapper">

    <div class="col-left" [fxHide.xs]="true">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of stepKeys; let i=index;">
            <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
              [ngClass]="{'active': mainArea.activeAnchor === step}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ steps[step] | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner">
        <div id="characteristic" anchorPart>
          <div fxLayout="column" class="md-grey-100" fxLayoutGap="10px">
            <h1 fxFlex class="font-weight-bold">{{ 'image.content.general' | translate }}</h1>
            <div fxFlex fxLayout="column" fxLayoutAlign="end center" *ngIf="image">
              <div class="full-width text-center">
                <app-image-viewer [filePublicId]="image.public_id" height="150px">
                </app-image-viewer>
              </div>

              <div class="full-width text-center">
                <button title="{{ 'public.button.download' | translate }}" color="accent" mat-icon-button mat-button-sm
                  (click)="downloadEntry(image)">
                  <mat-icon>save_alt</mat-icon>
                </button>
              </div>

            </div>
          </div>

          <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div fxLayout="row" fxLayoutAlign="start end" fxFlexFill fxLayoutGap="10px" fxLayoutGap.xs="0"
              class="full-width">
              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'image.content.name' | translate }}</mat-label>
                  <input matInput [placeholder]="'image.content.name' | translate" formControlName="name">

                  <mat-error [flexFormControlError]="form.get('name')"
                    [customMessages]="{
                        'duplicated': 'public.message.name_exist'|translate
                    }"></mat-error>
                </mat-form-field>
              </div>

            </div>
          </form>

          <table class="flex-table-info full-width mt-2">
            <tbody>
              <tr>
                <td class="label font-weight-bold">{{ 'image.content.file_size' | translate }}</td>
                <td>{{ image.file_size }} {{ 'public.measure.bytes' | translate }}</td>
              </tr>
              <tr>
                <td class="label font-weight-bold">{{ 'image.content.file_extension' | translate }}</td>
                <td>{{ image.file_ext }}</td>
              </tr>
              <tr>
                <td class="label font-weight-bold">{{ 'image.content.last_changed' | translate }}</td>
                <td>{{ image.modified_dt | timeFormatDateShort }}</td>
              </tr>
              <tr>
                <td class="label font-weight-bold">{{ 'image.content.created_dt' | translate }}</td>
                <td>{{ image.created_dt | timeFormatDateShort }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-3">
          <mat-divider [inset]="true" class="section-divider"></mat-divider>
        </div>

        <div id="inused" anchorPart>
          <h1 class="font-weight-bold">{{ 'image.content.objects_used'|translate }}</h1>

          <ng-container *ngIf="noObjectUsing; else elementsUsedSoundEntry">
            <p>{{ 'public.message.no_results'|translate }}</p>
          </ng-container>

          <ng-template #elementsUsedSoundEntry>
            <p>{{ 'image.content.objects_used'|translate }}</p>

            <ng-container *ngIf="inUsedObjects && inUsedObjects['sipphone']">

              <h4 class="font-weight-bold mb-0">{{ 'phone_config_request.content.sipphone'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <div *ngFor="let item of inUsedObjects['sipphone']" class="inused-item"
                  (click)="gotoPageSipPhone(item)">
                  <app-sipphone-mini-block [sipphone]="item"></app-sipphone-mini-block>
                </div>
              </div>
            </ng-container>

          </ng-template>
        </div>

      </div>

    </div>
  </div>
</div>
