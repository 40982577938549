import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { WephoneTranslationModule } from '@wephone-translation';

import { FlexLayoutModule } from '@angular/flex-layout';
import { BidiModule } from '@angular/cdk/bidi';
// import { SubscriptionService } from '@wephone/services/subscription.service';

import {
  AdminMenuComponent,
  WatcherMenuComponent,
  UserMenuComponent,
  AgentMenuComponent,
  AccountantMenuComponent,
  SupervisorMenuComponent,
  HeaderComponent,
  SidebarComponent,
  NotificationComponent,
  AdminLayoutComponent,
  AuthLayoutComponent
} from './core';

import { AppRoutes, RoutingService } from './app.routing';
import { AppComponent } from './app.component';
import { WephoneModule } from '@wephone/wephone.module';
import { WephoneUtilsModule, CookieService } from '@wephone-utils';
import { LayoutModule } from '@angular/cdk/layout';
import { WephoneCoreModule, FlexIvrSettings, ConfigManager } from '@wephone-core/wephone-core.module';
import { OptionsComponent } from './core/options/options.component';
import { WephoneAudioModule } from '@wephone-audio';
import { WephoneCommonModule } from '@wephone-common';
import { RepositoryService } from '@wephone-core/service/repository_service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { WephoneCoreUIModule } from '@wephone-core-ui';
import { WephoneSentryModule } from 'src/app/wephone-common/wephone-sentry.module';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { APP_BASE_HREF } from '@angular/common';
import { ChatbotModule } from 'src/app/chatbot';
// Import your library
import { NgxStripeModule } from 'ngx-stripe';
import { PendingConfigRequestsComponent } from './modals/pending-config-requests/pending-config-requests.component';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { RecordingCallAnalysisResultComponent } from '@wephone/modals/recording-call-analysis-result/recording-call-analysis-result.component';

FlexIvrSettings.getInstance().update({
  ws2_url: '/ws-v2/'
}).setProjectAdmin();

const formFieldAppearance: MatFormFieldDefaultOptions = {
  appearance: 'standard',
  floatLabel: 'always'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    NotificationComponent,
    AdminMenuComponent,
    WatcherMenuComponent,
    AgentMenuComponent,
    SupervisorMenuComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    OptionsComponent,
    AccountantMenuComponent,
    UserMenuComponent,
    PendingConfigRequestsComponent,
    // RecordingCallAnalysisResultComponent
  ],
  imports: [
    WephoneSentryModule.forRoot(),
    BrowserModule,
    NoopAnimationsModule,
    HammerModule,
    // RouterModule.forRoot(AppRoutes, { useHash: true, enableTracing: true }),
    RouterModule.forRoot(AppRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
    FormsModule,
    HttpClientModule,
    DeviceDetectorModule,
    WephoneTranslationModule.forRoot(),
    WephoneUtilsModule.forRoot(),
    WephoneCoreModule.forRoot(),
    WephoneCoreUIModule.forRoot(),
    WephoneAudioModule.forRoot(),
    WephoneCommonModule.forRoot(),
    NgxStripeModule.forRoot(),
    WephoneModule,
    LayoutModule,
    FlexLayoutModule,
    BidiModule,
    ChatbotModule
  ],
  entryComponents: [
    PendingConfigRequestsComponent,
    RecordingCallAnalysisResultComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: formFieldAppearance
    },
    {
      provide: APP_BASE_HREF,
      useValue: window['_app_base'] || '/'
    },
    RepositoryService,
    RoutingService,
    RoutingAppService
    // SubscriptionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(cookieService: CookieService, settings: FlexIvrSettings, config: ConfigManager) {
    cookieService.app_root_url = settings.root_url;
    // config.setRemoteUrl(settings.getApiUrl('system/config/get_config'));
    //config.setRemoteUrl(settings.getAbsoluteApiUrlv2('system/config/get_config'));
  }
}
