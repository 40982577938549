import { RoutingAppBase } from '@wephone-core/routing-app/routing-app-base';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';

export class RoutingAppPlayThenHangup extends RoutingAppBase {
  protected APP_NAME = RoutingAppName.play_then_hangup;

  setFileEntry(file_entry: FileEntryEntity): void {
    this.setAppParams({ sound_id: file_entry.id });
  }

  setRelatedObject(): void {}

  getRelatedObject(): number {
    return this.appParams && this.appParams.sound_id;
  }

  getDescription(): string {
    return 'Play a sound then hangup';
  }
}
