<div class="pa-1">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div fxFlex="calc(50%-10px)">
          <mat-form-field class="full-width" [attr.data-ci]="'first_name'">
            <mat-label>{{ 'phonebook.content.first_name'|translate }} *</mat-label>
            <input matInput placeholder="{{ 'phonebook.content.first_name'|translate }}" formControlName="firstName">
            
            <mat-error [flexFormControlError]="form.get('firstName')"></mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(50%-10px)">
          <mat-form-field class="full-width" [attr.data-ci]="'last_name'">
            <mat-label>{{ 'phonebook.content.last_name'|translate }}</mat-label>
            <input matInput placeholder="{{ 'phonebook.content.last_name'|translate }}" formControlName="lastName">
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field class="full-width" [attr.data-ci]="'email'">
          <mat-label>{{ 'phonebook.content.email'|translate }}</mat-label>
          <input matInput placeholder="{{ 'phonebook.content.email'|translate }}" formControlName="email">
          
          <mat-error [flexFormControlError]="form.get('email')"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div fxFlex="calc(50%-10px)">
          <mat-form-field class="full-width" [attr.data-ci]="'phone_office'">
            <mat-label>{{ 'phonebook.content.phone_office'|translate }}</mat-label>
            <input matInput placeholder="{{ 'phonebook.content.phone_office'|translate }}" formControlName="phoneOffice"
              (click)="onTouched(['phoneMobile', 'phoneOffice'])">
              
            <mat-error [flexFormControlError]="form.get('phoneOffice')"
              [customMessages]="{
                'nameExist': 'phonebook.validator.number_exist'|translate
              }"></mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(50%-10px)">
          <mat-form-field class="full-width" [attr.data-ci]="'phone_mobile'">
            <mat-label>{{ 'phonebook.content.phone_mobile'|translate }}</mat-label>
            <input matInput placeholder="{{ 'phonebook.content.phone_mobile'|translate }}" formControlName="phoneMobile"
              (click)="onTouched(['phoneMobile', 'phoneOffice'])">
              
            <mat-error [flexFormControlError]="form.get('phoneMobile')"
              [customMessages]="{
                'nameExist': 'phonebook.validator.number_exist'|translate
              }"></mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        *ngIf="form.get('phoneOffice').hasError('oneRequired') && form.get('phoneMobile').hasError('oneRequired') && (form.get('phoneOffice').touched || form.get('phoneMobile').touched)"
        class="phone-validate mat-text-warn">
        {{'phonebook.validator.enter_office_phone_or_mobile_phone'|translate}}
      </div>
    </div>
  </form>
</div>
