import { OnInit, ChangeDetectorRef, Directive } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalManager } from '@wephone-core/service/local_manager';
import { ToastService, EditingComponent, DialogService } from '@wephone-utils';
// import { IRoutingApp, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';

import { EntityManager } from '@wephone-core/service/entity_manager';
import { DidEntity } from '@wephone-core/model/entity/did';
// import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';

import * as _ from 'lodash';
// import { DidConfigWizardModalComponent } from '@wephone/modals/did/did-config-wizard-modal/did-config-wizard-modal.component';
import { IOpeningCalendarEditDialogConfig } from '../../components/calendar';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { DidRepository } from '@wephone-core/model/repository/did';
import { _tk, _ti } from '@wephone-translation';
import { Router } from '@angular/router';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import { ValidatorService } from '@wephone-utils/services/validator.service';

@Directive()
export class NumberRoutingEditBaseComponent extends EditingComponent implements OnInit {
  did: DidEntity;
  calendarEditConfig: IOpeningCalendarEditDialogConfig;

  didRepo: DidRepository;

  constructor(
    protected fb: FormBuilder,
    protected dialogService: DialogService,
    protected localManager: LocalManager,
    protected translate: TranslateService,
    protected toast: ToastService,
    protected cdr: ChangeDetectorRef,
    protected em: EntityManager,
    protected router: Router,
    protected readonly validatorService: ValidatorService
  ) {
    super();
    // this.calendarEditConfig = {
    //   editCustomData: true,
    //   editCustomDataCallback: (calendar: OpeningHourCalendarEntity): Promise<any> => {
    //     return this.changeCalendarDestination(calendar);
    //   }
    // };

    // this.form = this.fb.group({
    //   // useEnterpriseCalendar: undefined,
    //   // actionOfficeHour: undefined,
    //   // actionNotOfficeHour: undefined,
    //   // sameRouting: undefined,
    //   // calendars: this.fb.control([]),
    //   // language: undefined,
    //   // name: undefined,
    //   // welcomeMessage: undefined
    //   action_office_hour: undefined,
    //   master_did: undefined,
    //   name: undefined,
    //   welcome_message: undefined,
    //   crm: undefined,
    // });

    this.didRepo = em.getRepository<DidRepository>('DidRepository');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    // required load remote application before use
    await this.loadReferenceData();
    this.initForm();
    // // await this.em.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository').loadObjectList();
    // this.form.patchValue(this.getFormData(this.did));
    // this.form.valueChanges.subscribe((values: any) => {
    //   this.onFormValueChange();
    // });
    this.cdr.markForCheck();
  }

  protected initForm(): void {
    throw new Error('This method must be overriden by subclass');
    // this.form = this.fb.group({
    //   // useEnterpriseCalendar: undefined,
    //   // actionOfficeHour: undefined,
    //   // actionNotOfficeHour: undefined,
    //   // sameRouting: undefined,
    //   // calendars: this.fb.control([]),
    //   // language: undefined,
    //   // name: undefined,
    //   // welcomeMessage: undefined
    //   action_office_hour: undefined,
    //   master_did: undefined,
    //   name: undefined,
    //   welcome_message: undefined,
    //   crm: undefined,
    // });

    // this.form.valueChanges.subscribe(val => {
    //   this.onFormValueChange();
    // });
  }

  onFormValueChange(): void {
    super.onFormValueChange();
    this.cdr.markForCheck();
  }

  protected async loadReferenceData(): Promise<any> {
    // required load data before use
    const ivrRemoteListPromise = this.em.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository').loadObjectList();
    const crmRoutingRuleListPromise = this.em.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository').loadObjectList();
    const enterpriseCrmPromise = this.em.getRepository<EnterpriseCrmRepository>('EnterpriseCrmRepository').loadObjectList();

    return Promise.all([ivrRemoteListPromise, crmRoutingRuleListPromise, enterpriseCrmPromise]);
  }

  protected setDid(did?: DidEntity): void {
    this.did = did ? did : this.didRepo.create() as DidEntity;
  }

  // protected getFormData(did: DidEntity): Object {
  //   return {
  //     useEnterpriseCalendar: did.use_enterprise_calendar,
  //     actionOfficeHour: did.action_office_hour,
  //     actionNotOfficeHour: did.action_not_office_hour,
  //     sameRouting: did.same_routing,
  //     calendars: did.dependent_calendars,
  //     language: did.language,
  //     name: did.name,
  //     welcomeMessage: did.welcome_message,
  //     master_did: did.master_did
  //   };
  // }

  formHasChanged(): boolean {
    return this.form.dirty;
  }

  // changeCalendarDestination(calendar: OpeningHourCalendarEntity): Promise<any> {
  //   const routingApp: IRoutingApp = calendar.getRoutingApp();
  //   const data = {
  //     call_destination: routingApp ? routingApp.getAsJsonObject() : undefined,
  //     full_option: true
  //   };
  //   const dialogRef = this.dialogService.openDialog2(DidConfigWizardModalComponent, { data }, (res: {app?: any, masterDid?: DidEntity}) => {
  //     const app: IRoutingApp = res && res.app;
  //     if (res && res.masterDid) {
  //       // TODO process master-did
  //     } else if (app) {
  //       if (!app.getAppParams()) {
  //         console.error('No application routing params');
  //         return;
  //       }

  //       const routeData = app.getAsJsonObject();
  //       calendar.setRoutingApp(JSON.stringify(routeData));
  //     }
  //   });

  //   return dialogRef.afterClosed().toPromise();
  // }

  // changeCallDestination(outOfOfficeHours: boolean = false): void {
  //   const data = {
  //     call_destination: this.did.action_office_hour,
  //     full_option: true
  //   };
  //   this.dialogService.openDialog2(DidConfigWizardModalComponent, { data }, (res) => {
  //     const app: IRoutingApp = res && res.app;
  //     if (app) {
  //       this.updateCallDestination(app, outOfOfficeHours);
  //       this.cdr.markForCheck();
  //     }
  //   });
  // }

  // updateCallDestination(app: IRoutingApp, outOfOfficeHours?: boolean): void {
  //   if (!app.getAppParams()) {
  //     return;
  //   }

  //   const routeData = app.getAsJsonObject();
  //   this.form.get('sameRouting').setValue(outOfOfficeHours ? 0 : 1);

  //   if (outOfOfficeHours) {
  //     this.form.get('actionNotOfficeHour').markAsDirty();
  //     this.form.get('actionNotOfficeHour').setValue(routeData);
  //     return;
  //   }

  //   this.form.get('actionOfficeHour').markAsDirty();
  //   this.form.get('actionOfficeHour').setValue(routeData);
  // }

  // Validate form
  // validate(): void {
  //   if (!this.MULTI_EDITOR && !this.hasActionOffice) {
  //     throw new Error(_ti('did.validator.destination_not_config'));
  //   }
  // }

  // get actionOfficeInfo(): string {
  //   if (!this.hasActionOffice) {
  //     return '';
  //   }

  //   const did = _.cloneDeep(this.did);
  //   did.action_office_hour = this.form.get('actionOfficeHour').value;

  //   return (did.tooltip[0] || {}).param_value || '';
  // }

  // get actionOfficeAppName(): string {
  //   const application: RoutingAppName = this.form.get('actionOfficeHour').value
  //     ? this.form.get('actionOfficeHour').value.application
  //     : '';

  //   return application ? this.translate.instant(`routing_application.${application}.app_name`) : '';
  // }

  // get actionNotOfficeInfo(): string {
  //   if (!this.hasNotActionOffice) {
  //     return '';
  //   }

  //   const did = _.cloneDeep(this.did);
  //   did.action_office_hour = this.form.get('actionNotOfficeHour').value;

  //   return (did.tooltip[0] || {}).param_value || '';
  // }

  // get actionNotOfficeAppName(): string {
  //   const application = this.form.get('actionNotOfficeHour').value
  //     ? this.form.get('actionNotOfficeHour').value.application
  //     : '';

  //   return application ? this.translate.instant(`routing_application.${application}.app_name`) : '';
  // }

  // get hasActionOffice(): boolean {
  //   return !!this.form.get('actionOfficeHour').value;
  // }

  // get hasNotActionOffice(): boolean {
  //   return !!this.form.get('actionNotOfficeHour').value;
  // }

  // gotoEditPage(): void {
  //   if (!this.hasActionOffice) {
  //     console.warn('No action office');
  //     return;
  //   }
  //   const action = this.form.get('actionOfficeHour').value;
  //   // const action = this.did.action_office_hour;
  //   const application: RoutingAppName = action.application;
  //   const params: any = action.params;

  //   switch (application) {
  //     case RoutingAppName.callqueue:
  //       this.router.navigateByUrl(`queues/${params.queue}`);
  //       // this.router.navigate(['queues'], { queryParams: { id: params.queue } });
  //       break;
  //     case RoutingAppName.conference:
  //       this.router.navigateByUrl(`telephony/conferences/${params.conference}`);
  //       // this.router.navigate(['telephony/conferences'], { queryParams: { id: params.conference } });
  //       break;
  //     case RoutingAppName.call_phone:
  //       this.router.navigateByUrl(`telephony/sip-phones/${params.sipphone_id}`);
  //       // this.router.navigate(['telephony/sip-phones'], { queryParams: { id: params.sipphone_id } });
  //       break;
  //     case RoutingAppName.remote_application:
  //       this.router.navigateByUrl(`developpers/ivr-remote-apps/${params.id}`);
  //       // this.router.navigate(['developpers/ivr-remote-apps'], { queryParams: { id: params.id } });
  //       break;
  //     case RoutingAppName.runvxmlscript:
  //       this.router.navigateByUrl(`developpers/ivr-remote-apps/${params.ivrscript_id}`);
  //       // this.router.navigate(['developpers/ivr-remote-apps'], { queryParams: { id: params.ivrscript_id } });
  //       break;
  //     case RoutingAppName.crm_route:
  //       this.router.navigateByUrl(`crm/crm-routing/${params.id}`);
  //       // this.router.navigate(['crm/crm-routing'], { queryParams: { id: params.id } });
  //       break;
  //     case RoutingAppName.ivr_custom_menu:
  //       this.router.navigateByUrl(`ivr-menus/${params.id}`);
  //       break;
  //     default:
  //       console.warn('Not support application name', application);
  //       break;
  //   }
  // }
}
