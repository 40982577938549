import { Component, OnInit, Self, Optional, ElementRef, Input } from '@angular/core';
import { FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';

@Component({
  selector: 'sipphone-select-form-input',
  templateUrl: './sipphone-select.component.html',
  styleUrls: ['./sipphone-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SipPhoneSelectFormInput }]
})
export class SipPhoneSelectFormInput extends FlexMatInputWrapper
  implements OnInit, MatFormFieldControl<SipPhoneEntity>, ControlValueAccessor {
  static nextId = 0;

  @Input() disabledOptionFunc?: () => boolean;
  @Input() isConfigured: boolean;

  flexSelect: FormControl = new FormControl();
  sipPhoneList: SipPhoneEntity[];
  flexSelectOptions: IFlexSelectOptions;

  private readonly is_hotel: boolean = FlexIvrSettings.getInstance().uiHotel();

  constructor(
    private readonly em: EntityManager,
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: SipPhoneEntity, filterString) => {
        if (!filterString) {
          return true;
        }

        return regexSearch(item.extension, filterString) ||
          item.user && regexSearch(item.extension_with_owner, filterString);
      }

    };

    if (this.disabledOptionFunc) {
      this.flexSelectOptions.disabledOptionFunc = this.disabledOptionFunc;
    }
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    let sipPhoneList = this.em.getRepository<SipPhoneRepository>('SipPhoneRepository').getAllEnabled();

    if (this.isConfigured === true) {
      sipPhoneList = sipPhoneList.filter(s => !!s.user_id || !!s.hotel_room_id);
    }

    this.sipPhoneList = sipPhoneList;

    if (this.is_hotel) {
      this.em.getRepository<HotelRoomRepository>('HotelRoomRepository').findAll();
    }
  }

  get controlType(): string {
    return 'sip-phone-select-form-input';
  }
}
