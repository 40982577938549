import { datafield, Entity } from '@wephone-core/model/model';

export class DidCountryEntity extends Entity {
  public static object_type_id = 'did_country';

  public id: number;
  @datafield
  public code2: string;
  @datafield
  public code3: string;
  @datafield
  public name: string;
  @datafield
  public phone_code: string;
  @datafield
  public has_state: number;
  @datafield
  public has_regulation: number;
  @datafield
  public vat: number;
  @datafield
  public invoice_template: string;
  @datafield
  public default_pack_id: number;
  @datafield
  public states: any[];
}
