import * as _ from 'lodash';
import { ParamRoutableComponent } from './param-routable-component';
import { DynamicFilterSource } from '../flex-table/datasource/dynamic-filter-source';
import { Directive } from "@angular/core";

@Directive()
export class ParamRoutableCRUDComponent extends ParamRoutableComponent {
    dataSource: DynamicFilterSource;
    focusedRow: any;

    // constructor() {
    //     super();
    // }

    protected findItemForParams(params: any): any {
        const id = parseInt(params.id);
        if (id && this.dataSource && this.dataSource.data) {
            for (const x of this.dataSource.data) {
                if (x.id === id) {
                    return x;
                }
            }
        }
    }

    protected onRouteQueryParamChange(params: any): any {
        const selectedItem = this.findItemForParams(params);
        if (selectedItem) {
            this.focusedRow = selectedItem;
        }
    }
}
