<div class="pa-1">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div>
      <mat-form-field class="full-width">
        <input matInput placeholder="{{'ivrremoteapp.content.name'| translate}}" formControlName="name">
        
        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
