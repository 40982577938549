<div fxFlexFill fxLayout="column" class="flex-container">
  <ng-container *ngIf="!isReady(); then loading; else content"></ng-container>

  <ng-template #loading>
    <loading-screen fxFlexFill></loading-screen>
  </ng-template>

  <ng-template #content>
    <div fxLayout="column">
      <h2 class="pa-0 ma-0">{{ 'livecall.page_title'|translate }}</h2>
      
      <div fxLayout="row" fxLayout.lt-md="column"
        fxLayoutAlign="start center" fxLayoutAlign.lt-md="center none"
        fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

        <div fxFlex fxFlex.lt-md="none" fxLayout="row" fxLayoutGap="20px">

          <div fxFlex="50px" *ngIf="hasFeature('SERVICE_GROUP')" fxLayoutAlign="center center">
            <!-- <span class="flex-link" (click)="showGroupFilter()">{{ 'livecall.actions.filter_by_group'|translate
              }}</span>
            <span *ngIf="filters.filter_group_selected_ids.length">&nbsp; : &nbsp;</span>
            <span class="group-filtered-count" (click)="showGroupFilter()"
              *ngIf="filters.filter_group_selected_ids.length">{{ filters.filter_group_selected_ids.length }}</span> -->
            <button [matTooltip]="'livecall.actions.filter_by_group' | translate" (click)="showGroupFilter()" mat-icon-button>
              <mat-icon>groups</mat-icon>
              <span class="flex-notification-label" *ngIf="filters.filter_group_selected_ids && filters.filter_group_selected_ids.length">{{ filters.filter_group_selected_ids.length }}</span>
            </button>
          </div>
  
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-select [(ngModel)]="filters.call_direction" (selectionChange)="changeCallDirection($event.value)"
                placeholder="{{ 'livecall.call_direction.title'|translate }}">
                <mat-option *ngFor="let call_direction of callDirectionList" [value]="call_direction">
                  {{ ('livecall.call_direction.' + call_direction)|translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-select [(ngModel)]="sortCallByDuration"
                (selectionChange)="changeSortByDurationDirection($event.value)"
                placeholder="{{ 'live.call_list.sortby_duration.label'|translate }}">
                <mat-option [value]="sortByCallDirection.recent_call_first">
                  {{ 'live.call_list.sortby_duration.recent_calls_first'|translate }}
                </mat-option>
                <mat-option [value]="sortByCallDirection.recent_call_last">
                  {{ 'live.call_list.sortby_duration.recent_calls_last'|translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxFlex fxFlex.lt-md="none" fxLayout="row">
          <div fxFlex>
            <flex-search-input [formControl]="searchTextControl" [hintText]="'live.search.hint'|translate"></flex-search-input>
          </div>
          <div fxFlex="40px">
            <button data-ci="btn-actions" *ngIf="is_admin" mat-icon-button [matMenuTriggerFor]="actionMenu"
              matTooltip="{{ 'public.action'|translate }}">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <div mat-menu-item [matMenuTriggerFor]="displayModeMenu">
                <mat-icon>view_comfy</mat-icon>
                <span>{{ 'live.filter.display_mode'|translate }}</span>
              </div>
              <div mat-menu-item [matMenuTriggerFor]="sortMenu">
                <mat-icon>sort_by_alpha</mat-icon>
                <span>{{ 'livecall.sort.title'|translate }}</span>
              </div>
              <div data-ci="btn-show-action-logs" mat-menu-item (click)="showActionLogs()">
                <mat-icon>history</mat-icon>
                <span>{{ 'livecall.actions.show_action_logs'|translate }}</span>
              </div>
            </mat-menu>

            <mat-menu #displayModeMenu="matMenu">
              <ng-container *ngFor="let display_type of agent_display_types">
                <div mat-menu-item (click)="agent_display_type = display_type">
                  <mat-icon *ngIf="display_type==='compact'">view_module</mat-icon>
                  <mat-icon *ngIf="display_type==='queue'">queue</mat-icon>
                  <mat-icon *ngIf="display_type==='group'">group</mat-icon>
                  {{ ('live.filter.' + display_type)|translate }}
                </div>
              </ng-container>
            </mat-menu>
            <mat-menu #sortMenu="matMenu">
              <div mat-menu-item (click)="changeSortAgent(agent_sort.sort_column, 1)">
                <mat-icon>
                  <fa-icon [icon]="FAIcons.faSortAmountDown"></fa-icon>
                </mat-icon>
                <span>{{ 'livecall.sort.ascending'|translate }}</span>
              </div>
              <div mat-menu-item (click)="changeSortAgent(agent_sort.sort_column, 0)">
                <mat-icon>
                  <fa-icon [icon]="FAIcons.faSortAmountUp"></fa-icon>
                </mat-icon>
                <span>{{ 'livecall.sort.descending'|translate }}</span>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>

    </div>

    <div class="live-wrapper" fxFlex="grow" fxLayout="row"
      cdkDropListGroup>
      <div fxFlex class="left-panel overview-scroll">
        <active-calls [callDirection]="filters.call_direction" [sortCallByDuration]="sortCallByDuration"
          [filterGroupIds]="filters.filter_group_selected_ids"
          [filterUngroupedAgents]="filters.filter_group_show_not_grouped_agents" [dragDropEnable]="true">
        </active-calls>
      </div>
      <div fxFlex class="right-panel overview-scroll">
        <agent-list [callDirection]="filters.call_direction" [filterGroupIds]="filters.filter_group_selected_ids"
          [filterUngroupedAgents]="filters.filter_group_show_not_grouped_agents" [agentFilters]="agent_filters"
          [agentSort]="agent_sort" [pauseAgentSort]="pause_agent_sort">
        </agent-list>
      </div>
    </div>
  </ng-template>
</div>
