<div fxLayout="column" fxLayoutAlign="start start" class="full-width">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="full-width">
    <div fxFlex="40px">
      <button [hidden]="isPlaying()" mat-icon-button (click)="resume()" [disabled]="!audioTrack">
        <mat-icon>play_circle_filled</mat-icon>
      </button>
      <button [hidden]="!isPlaying()" mat-icon-button (click)="pause()" [disabled]="!audioTrack">
        <mat-icon>pause_circle_filled</mat-icon>
      </button>
    </div>
    <div fxFlex="200px" fxFlex.xs="{{ enableDownload && !hideMute ? 'calc(100%-200px)' : 'calc(100%-150px)' }}">
      <audio-track-progess-bar [audioTrack]="audioTrack"></audio-track-progess-bar>
    </div>
    <div fxFlex="{{ enableDownload && !hideMute ? '130px' : '90px' }}">
      <button mat-icon-button (click)="mute()" [disabled]="!audioTrack" *ngIf="!hideMute">
        <mat-icon *ngIf="!muted; else mutedIcon">volume_up</mat-icon>
        <ng-template #mutedIcon>
          <mat-icon>volume_mute</mat-icon>
        </ng-template>
      </button>
      <button mat-icon-button (click)="download()" *ngIf="enableDownload">
        <mat-icon>file_download_outlined</mat-icon>
      </button>
      <button mat-icon-button (click)="close()" color="warn" [disabled]="!audioTrack">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="audioTrack?.title">
    <em>{{ audioTrack.title }}</em>
  </div>
  <mat-progress-bar *ngIf="!audioTrack?.isLoaded" mode="indeterminate"></mat-progress-bar>
</div>
