import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlexBasePage } from '@wephone-core-ui';
import { DynamicFilterSource, IFlexTableSidePannelOptions, DialogService, regexSearch, FlexCRUDPageComponent, IFlexTableConfig } from '@wephone-utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { _tk, _ti } from '@wephone-translation';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { IvrEvaluationEditComponent } from '@wephone/components/edit-ivr-evaluation/edit-ivr-evaluation.component';
import { CreateIvrEvaluationModalComponent } from '@wephone/modals/create-ivr-evaluation-modal/create-ivr-evaluation-modal.component';
import { Router } from '@angular/router';
import { IvrEvaluationRepository } from '@wephone-core/model/repository/ivr_evaluation';
import { IvrEvaluationService } from '@wephone-core/service/ivr_evaluation.service';
import * as _ from 'lodash';

@Component({
  selector: 'ivr-evaluation',
  templateUrl: './ivr-evaluation.component.html',
  styleUrls: ['./ivr-evaluation.component.scss']
})
export class IvrEvaluationComponent extends FlexBasePage implements OnInit, OnDestroy {
  filterForm: FormGroup;
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: IvrEvaluationEditComponent
  };

  private ivrEvaluationRepo: IvrEvaluationRepository;
  @ViewChild('flexCrud', { static: false }) flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly ivrEvaluationService: IvrEvaluationService,
    readonly em: EntityManager,
  ) {
    super();
    this.ivrEvaluationRepo = em.getRepository<IvrEvaluationRepository>('IvrEvaluationRepository');
    this.ivrEvaluationRepo.findAll();

    const ivrModuleSource = this.ivrEvaluationRepo.dataSource<IvrEvaluationEntity>();
    this.dataSource = new DynamicFilterSource(ivrModuleSource, this.filterFunc);

    this.filterForm = this.fb.group({
      filterValue: undefined
    });

    this.addSubscription(this.filterForm.valueChanges.subscribe(this.onFormValueChange));

    this.tableConfig = {
      enableFilter: false,
      columns: [
        {
          name: 'name',
          label: _tk('ivr_evaluation.content.name'),
          sortable: true,
          // sort: 'asc',
          customSortFn: (ivrEvaluation: IvrEvaluationEntity) => {
            return ivrEvaluation.name.toLowerCase();
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };
  }

  filterFunc = (item: IvrEvaluationEntity): boolean => {
    if (!this.filterValue) {
      return true;
    }

    return regexSearch(item.name, this.filterValue);
  }

  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  createNew(): void {
    this.dialogService.openDialog2(CreateIvrEvaluationModalComponent, {}, (item: IvrEvaluationEntity) => {
      if (item && item.id) {
        this.router.navigateByUrl(`/ivr/ivr-evaluation/${item.id}`);
      }
    });
  }

  async bulkDelete(items: IvrEvaluationEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    const usedQueues = this.ivrEvaluationService.getUsedQueuesOfIvrEvaluations(items.map(i => i.id));

    if (!_.isEmpty(usedQueues)) {

      const msgs: string[] = [];

      for (const item of items) {
        const queueObjects = usedQueues.filter(q => q.eoc_survey_id = item.id);
        if (!_.isEmpty(queueObjects)) {
          msgs.push(_ti('ivr_evaluation.message.confirm_delete_with_dependent_queues', {
            dependent_names: queueObjects.map(x => x.queue_name).join(', '),
            object: item.name,
          }));
        }
      }

      msgs.push(_ti('public.message.cannot_continue_action_with_dependent_items'));

      await this.dialogService.showAlert(_ti('dialogs.warning'), msgs.join('\n'));
      return Promise.reject();
    }

    return this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('user.title.delete'), async () => {
      if (items) {
        try {
          const ret: { items_in_use?: number[] } = await this.ivrEvaluationRepo.bulkDelete(items);
          this.flexCrud.closeSidePanel();

          const inUsseIds: number[] = ret && ret.items_in_use || [];
          if (inUsseIds.length) {
            const inUseNames = this.em.getRepository('IvrEvaluationRepository').getObjectList()
              .filter(i => inUsseIds.includes(i.id))
              .map(i => i.name);
            this.showWarning(_ti('ivr_evaluation.message.already_in_use', { names: inUseNames.join('; ') }));
            return;
          }

          this.successToast(_ti('public.message.delete_success'));
        } catch (error) {
          console.error('Delete ivr-evaluation items error', error);
          this.showError(error.message || _ti('public.message.delete_failure'));
        }
      }
    });
  }
}
