<div fxLayout="column" class="enterprise-billing">
  <div fxFlex="50">
    <h3><strong>{{ 'enterprise_info.billing_plan.current_have' | translate }}</strong></h3>
    <div fxLayout="column" class="your-current" *ngIf="billingPlan">
      <span>{{ 'enterprise_info.billing_plan.phone_numbers' | translate }}<strong> {{ billingPlan.phone_numbers }}</strong></span>
      <span>{{ 'enterprise_info.billing_plan.users' | translate}}<strong> {{ billingPlan.users }}</strong></span>
      <span>{{ 'enterprise_info.billing_plan.license' | translate}}<strong> {{ enterprise.cc_license }}</strong>
        <a class="link" (click)="changeModal()">{{'public.change'
          | translate}}</a></span>
    </div>
  </div>
  <div fxFlex="50" class="current-plan">
    <h3><strong>{{ 'enterprise_info.billing_plan.current_plan' | translate}}</strong></h3>
    <div fxLayout="column" class="your-current" *ngIf="billingPlan">
      <span>{{ 'enterprise_info.billing_plan.per_user' | translate}}<strong> {{ billingPlan.per_user | currency:enterprise.currency ? enterprise.currency : 'EUR' }}</strong></span>
      <span>{{ 'enterprise_info.billing_plan.per_number' | translate}}<strong> {{ billingPlan.per_number | currency:enterprise.currency ? enterprise.currency : 'EUR'}}</strong></span>
      <span>{{ 'enterprise_info.billing_plan.center_license' | translate}}<strong> {{ billingPlan.center_licence | currency:enterprise.currency ? enterprise.currency : 'EUR' }}</strong></span>
    </div>
  </div>
</div>
.
