export interface IAudioTrack {
    title?: string;
    play(): void;
    pause(): void;
    stop(): void;
    mute(muted: boolean): void;
    duration(): number;
    seek(): number;
    setSeek(value: number): void;
    isLoading(): boolean;
    isLoaded(): boolean;
    onend(callback: (soundId: number) => void, id?: number): void;
  }

export enum PlayStatus {
    PLAYING = 'playing',
    PAUSED = 'paused',
    STOPPED = 'stopped'
}

export class SoundPlayEvent {
  constructor(public status: PlayStatus, public soundUri: string, public filePublicEntryId: string, public lang: string) {}
}
