import * as Joi from 'joi-browser';

const joiValidate = (control, schema) => {
  const result = Joi.validate(control.value, schema);
  if (result.error) {
    return result.error.details.reduce((obj, val, key) => {
      // obj[val.type] = val.message;
      obj[val.type] = val.context;
      return obj;
    }, {});
  }
};

export const joiValidator = schema => {
  return control => {
    return joiValidate(control, schema);
  };
};

export function Validator(schema) {
  return function(target: any, name: string) {
    target[name] = control => joiValidate(control, schema);
  };
}
