<ng-template #listFilterTpl>
  <form [formGroup]="invoiceForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
    fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
    <div>
      <flex-search-input formControlName="filterValue" [hintText]="'invoice.search.hint'|translate"
        ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>

<flex-crud-page #flexCrud *ngIf="dataSource" pageTitle="{{ 'menu.manage_invoice'|translate }}"
  [filterTemplate]="listFilterTpl" [tableConfig]="tableConfig" [dataSource]="dataSource"
  [columnTemplates]="{'type_name': typeNameTpl, 'invoice_dt': dateTpl, 'total': totalTpl, 'is_paid': statusTpl}"
  [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">

  <ng-template #typeNameTpl let-item="row">
    <ng-container [ngSwitch]="item.invoice_type">
      <ng-container *ngSwitchCase="0">
        {{ 'invoice.content.monthly_invoice'| translate }} {{ item.start_dt|date: 'MM-yyyy' }}
      </ng-container>
      <ng-container *ngSwitchCase="1">
        {{ 'invoice.content.new_numbers_invoice'| translate }}
      </ng-container>
      <ng-container *ngSwitchCase="2">
        {{ 'invoice.content.manual_invoice'| translate }}
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template #dateTpl let-item="row">
    {{ item.invoice_dt|timeFormatDateShort }}
  </ng-template>
  <ng-template #totalTpl let-item="row">
    <span>{{ item.total | currency:item.currency:'symbol-narrow': '0.2-2' }}</span>
  </ng-template>
  <ng-template #statusTpl let-item="row">
    <mat-icon [title]="'invoice.content.to_be_paid' | translate" *ngIf="!item.is_paid" color="warn">credit_card
    </mat-icon>
    <mat-icon [title]="'invoice.content.paid' | translate" *ngIf="item.is_paid" color="accent">done</mat-icon>
  </ng-template>
</flex-crud-page>
