import { Component, ViewEncapsulation } from '@angular/core';

// create your cellRenderer as a Angular component
@Component({
  selector: 'voicemail-number-renderer',
  template: `<a href (click)="makeOutcall()">{{value}}</a>`
})
export class VoicemailNumberCellRenderer {
  private params: any;
  value;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }

  makeOutcall(): void {
    console.log('makeOutcall', this.value);
  }
}
