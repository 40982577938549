import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'message-renderer',
  template: `<div *ngIf="file_entry_ready">
  <fileentry-player-button [filePublicId]="file_public_id" playerId="main"></fileentry-player-button>
  <i *ngIf="!params.data.is_read">{{'stats.missed_call.message_new' | translate}}</i>
  </div>`
})
export class MessageRenderer implements ICellRendererAngularComp {
  params: any;
  file_entry_ready: any;
  file_public_id: any;

  constructor(
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.file_public_id = params.value;
    this.file_entry_ready = params && params.data ? params.data.file_entry_ready === !!1 : false;
  }

  refresh(): boolean {
    return false;
  }
}
