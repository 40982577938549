import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { OutcallcampaignBaseComponent } from '@wephone/components/outcallcampaign/outcallcampaign-base/outcallcampaign-base.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ToastService, IChangeAwareComponent, NoWhitespaceValidator } from '@wephone-utils';
import { UserService } from '@wephone/services/user.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';

@Component({
  selector: 'app-outcallcampaign-advance',
  templateUrl: './outcallcampaign-advance.component.html',
  styleUrls: ['./outcallcampaign-advance.component.scss']
})
export class OutcallcampaignAdvanceComponent extends OutcallcampaignBaseComponent
  implements OnInit, OnChanges, IChangeAwareComponent {
  @Input() queue: CallQueueEntity;
  @Output() readonly formValueChanges: EventEmitter<boolean>;
  hasInitialized: boolean;

  constructor(
    translate: TranslateService,
    dialogService: DialogService,
    userService: UserService,
    toast: ToastService,
    router: Router,
    protected fb: FormBuilder
  ) {
    super(translate, dialogService, userService, toast, router);
    this.formValueChanges = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized) {
      return;
    }
    console.log('changes queue:', changes);
    this.initFormGroup();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormGroup();
    this.hasInitialized = true;
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  private getFormResetData(): any {
    return {
      alias: this.queue.alias,
      result_notification_url: this.queue.result_notification_url
    };
  }

  getChangeSet(): any {
    return this.formService.getChangeSet(this.form);
  }

  async submitForm() {
  }

  // initialize(): void {
  //   this.initFormGroup();
  // }

  initFormGroup(): void {
    this.form = this.fb.group({
      alias: [this.queue.alias, [NoWhitespaceValidator]],
      result_notification_url: [this.queue.result_notification_url, [UrlValidator.isValidUrl]]
    });

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });
  }

  isValidForm(): boolean {
    return this.form.valid;
  }
}
