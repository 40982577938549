import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FlexBasePage } from '@wephone-core-ui';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { IFlexTableConfig, localNow, parseDateTime, NoWhitespaceValidator, FlexTableComponent, FormService } from '@wephone-utils';
import * as _ from 'lodash';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { _tk, _ti } from '@wephone-translation';
import { DateAdapter } from '@angular/material/core';
import { FlexDatepickerMin, PhoneNumberValidated } from '@wephone/services/form-validator';
import { SmsService } from '../../service';
import { SmsMessage } from '../../sms.types';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';
import { AgentService } from '@wephone/services/agent.service';
import { DidEntity } from '@wephone-core/model/entity/did';
import { ValidatorService } from '@wephone-utils/services/validator.service';

@Component({
  selector: 'app-sms-sent',
  templateUrl: './sms-sent.component.html',
  styleUrls: ['./sms-sent.component.scss'],
  providers: [SmsService]
})
export class SmsSentComponent extends FlexBasePage implements OnInit {
  @ViewChild('flexTable', { static: false }) flexTableSent: FlexTableComponent;
  @ViewChild('flexTable', { static: false }) flexTableReceived: FlexTableComponent;

  dataSentSource: FlexDataSource;
  dataReceivedSource: FlexDataSource;
  filterSmsSentForm: FormGroup;
  updateForm: FormGroup;
  filterSmsReceivedForm: FormGroup;
  canTakeAction: boolean;
  tableConfigSmsSent: IFlexTableConfig;
  tableConfigSmsReceived: IFlexTableConfig;
  myenterprise: EnterpriseEntity;
  activeTab = 'sms_sent';
  limitedMessageLength = 128;

  loadSmsSent = false;
  loadSmsReceived = false;
  canMakeCall: boolean;
  didNames: { [keys: number]: string } = {};
  private enterpriseRepo: EnterpriseRepository;

  constructor(
    cdr: ChangeDetectorRef,
    datasourceService: DataSourceService,
    dateAdapter: DateAdapter<Date>,
    readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly authService: AuthenticationService,
    private readonly configManager: ConfigManager,
    private readonly sms: SmsService,
    private readonly agentService: AgentService,
    private readonly validatorService: ValidatorService,
    private readonly formService: FormService,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;

    this.enterpriseRepo = em.getRepository<EnterpriseRepository>('EnterpriseRepository');
    this.myenterprise = this.enterpriseRepo.getMyEnterprise();

    if (!this.configManager.hasFeature('SMS')) {
      console.error('Not have feature SMS');
    }

    this.filterSmsSentForm = this.fb.group({
      start_dt: localNow().minus({ days: 30 }).toJSDate(),
      end_dt: localNow().plus({ days: 1 }).toJSDate(),
      sender_name: [],
      destination: [],
    }, { validator: FlexDatepickerMin('end_dt', 'start_dt') });

    this.filterSmsReceivedForm = this.fb.group({
      start_dt: [localNow().minus({ days: 30 }).toJSDate(), Validators.required],
      end_dt: [localNow().plus({ days: 1 }).toJSDate(), Validators.required],
      sender_name: [],
      destination: [],
    }, { validator: FlexDatepickerMin('end_dt', 'start_dt') });

    this.dataSentSource = datasourceService.createDataSource('pbx_sms_sent');
    this.dataSentSource.setOrder('creation_time', 'desc');

    this.dataReceivedSource = datasourceService.createDataSource('pbx_sms_received');
    this.dataReceivedSource.setOrder('received_time', 'desc');

    this.canTakeAction = this.authService.isAdmin() || this.authService.isSupervisor();

    this.tableConfigSmsSent = {
      multiSelect: this.canTakeAction,
      enableExportCsv: this.canTakeAction,
      rowHeight: 50,
      focusedRowHeight: 100,
      columns: [
        { name: 'creation_time', label: _tk('sms.send_time') },
        { name: 'sender_name', label: _tk('sms.sender_name') },
        { name: 'destination', label: _tk('sms.destination') },
        { name: 'message', label: _tk('sms.message') },
        { name: 'send_status', label: _tk('sms.send_status') },
        { name: 'outbound_frags', visible: true, exportable: true, label: _tk('sms.outbound_frags') },
        { name: 'action', label: _tk('public.action') },
      ]
    };

    this.canMakeCall = this.authService.isAdmin() || this.authService.isSupervisor() || this.authService.isAgent();

    /******************
    * Cannot bind input [min] because there's a bug with Mat-Datepicker,
    * the Form error won't fire if the binding object's value was changed.
    ******************/
    this.tableConfigSmsReceived = {
      multiSelect: this.canTakeAction,
      enableExportCsv: this.canTakeAction,
      rowHeight: 50,
      focusedRowHeight: 100,
      columns: [
        { name: 'received_time', label: _tk('sms.received_time') },
        { name: 'from', label: _tk('sms.from') },
        { name: 'did_number', label: _tk('sms.did') },
        { name: 'message', label: _tk('sms.message') },
        { name: 'action', label: _tk('public.action') },
      ]
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    for (const did of this.em.getRepository('DidRepository').getObjectList()) {
      this.didNames[did.id] = did.number;
    }

    this.initFormGroup();
    this.reloadSmsSentClick();
    this.reloadSmsReceivedClick();
  }

  isRemotePhoneNumber(item: {
    is_from_phone_number: number,
    from: string
  }): number {
    if (item.is_from_phone_number === undefined) {
      item.is_from_phone_number = this.validatorService.isPhoneNumber(item.from) ? 1 : 0;
    }

    return item.is_from_phone_number;
  }

  initFormGroup(): void {
    this.updateForm = this.fb.group({
      sms_callback_url: [this.myenterprise.sms_callback_url, [Validators.required, UrlValidator.isValidUrl]]
    });
  }

  private getFormResetData(): Object {
    return {
      sms_callback_url: this.myenterprise.sms_callback_url,
    };
  }

  async reloadSmsSentClick(): Promise<void> {
    if (!this.filterSmsSentForm.valid) {
      this.filterSmsSentForm.markAllAsTouched();
      return;
    }

    const filterData = this.formService.normalizeData(this.filterSmsSentForm.value);
    const newFilters = _.cloneDeep(filterData);
    newFilters.start_dt = parseDateTime(newFilters.start_dt).toISODate();
    newFilters.end_dt = parseDateTime(newFilters.end_dt).toISODate();

    try {
      if (this.flexTableSent) {
        this.dataSentSource.setFilter(newFilters);
        await this.flexTableSent.reloadDataSource();
      } else {
        await this.dataSentSource.load(newFilters);
      }

      this.cdr.markForCheck();
      this.loadSmsSent = true;
    } catch (e) {
      console.error('Load sms sent data error', e);
    }
    // this.dataSentSource.load(newFilters).then(() => {
    //   this.cdr.markForCheck();
    //   this.loadSmsSent = true;
    // });
  }

  async reloadSmsReceivedClick(): Promise<void> {
    if (!this.filterSmsReceivedForm.valid) {
      this.filterSmsReceivedForm.markAllAsTouched();
      return;
    }

    const filterData = this.formService.normalizeData(this.filterSmsReceivedForm.value);
    const newFilters = _.cloneDeep(filterData);
    newFilters.start_dt = parseDateTime(newFilters.start_dt).toISODate();
    newFilters.end_dt = parseDateTime(newFilters.end_dt).toISODate();

    try {
      if (this.flexTableReceived) {
        this.dataReceivedSource.setFilter(newFilters);
        await this.flexTableReceived.reloadDataSource();
      } else {
        await this.dataReceivedSource.load(newFilters);
      }

      this.cdr.markForCheck();
      this.loadSmsReceived = true;
    } catch (e) {
      console.error('Load sms received data error', e);
    }
  }

  async makeCall(didId: number, calledNumber: string): Promise<void> {
    const did: DidEntity = this.em.getRepository('DidRepository').getObjectById(didId);
    if (!did) {
      console.error('No did', didId);
      return;
    }

    try {
      await this.agentService.make_outcall(calledNumber, null, did.number);
    } catch (error) {
      this.showError(_ti('public.message.error_occurred'));
    }
  }

  resetForm(): void {
    this.updateForm.reset(this.getFormResetData());
    this.updateForm.markAsPristine();
  }

  async updateSmsCallbackUrl(): Promise<void> {
    if (!this.updateForm.valid) {
      return;
    }

    try {
      await this.enterpriseRepo.updateEnterprise({
        sms_callback_url: this.updateForm.get('sms_callback_url').value
      });
      this.successToast(_ti('public.message.update_success'));

    } catch (e) {
      console.error('Update enterprise failure', e);
      const msg = e && e.message || _ti('public.message.update_failure');
      this.showError(msg);
    }
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilterSmsSent = (d: Date): boolean => {
    const startDate = this.startDateControlSmsSent.value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  get startDateControlSmsSent(): FormControl {
    return this.filterSmsSentForm.get('start_dt') as FormControl;
  }

  get endDateControlSmsSent(): FormControl {
    return this.filterSmsSentForm.get('end_dt') as FormControl;
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilterSmsReceived = (d: Date): boolean => {
    const startDate = this.startDateControlSmsReceived.value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  get startDateControlSmsReceived(): FormControl {
    return this.filterSmsReceivedForm.get('start_dt') as FormControl;
  }

  get endDateControlSmsReceived(): FormControl {
    return this.filterSmsReceivedForm.get('end_dt') as FormControl;
  }

  async showMoreMessage(item: any): Promise<void> {
    if (item.message.length <= this.limitedMessageLength) {
      return;
    }

    item.show_full = 1;
  }
}
