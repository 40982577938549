import { Injectable } from '@angular/core';

import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { IAudioTrack, PlayStatus, SoundPlayEvent } from '../types';
import { FlexAudioTrack } from '../audio-track';

export interface PlayingEntryDataType {
  file_public_id: string;
  lang?: string;
}

@Injectable()
export class SoundService {
  private audioTrack: IAudioTrack;
  private audioSource: string;
  private playStatus: PlayStatus;
  public playEvent$ = new Subject<SoundPlayEvent>();
  private playingEntryData: PlayingEntryDataType;
  private playerVisible = false;
  private topPlayerVisible = false;

  constructor(
    private fileentryService: FileEntryService,
  ) { }

  private notifyPlayEvent(): void {
    const e = new SoundPlayEvent(
      this.playStatus,
      this.audioSource,
      this.playingEntryData && this.playingEntryData.file_public_id,
      this.playingEntryData && this.playingEntryData.lang
    );
    this.playEvent$.next(e);
  }

  private createTrack(source_uri: string): IAudioTrack {
    return new FlexAudioTrack(source_uri);
  }

  setAudioTrack(audioTrack: IAudioTrack): void {
    this.audioTrack = audioTrack;
  }

  getAudioTrack(): IAudioTrack {
    return this.audioTrack;
  }

  getAudioSource(): string {
    return this.audioSource;
  }

  isPlayerVisible(): boolean {
    return this.playerVisible;
  }

  showPlayer(): void {
    this.playerVisible = true;
  }

  hidePlayer(): void {
    this.playerVisible = false;
  }

  isTopPlayerVisible(): boolean {
    return this.isPlayerVisible() && this.topPlayerVisible;
  }

  showTopPlayer(): void {
    this.topPlayerVisible = true;
  }

  hideTopPlayer(): void {
    this.topPlayerVisible = false;
  }

  getPlayStatus(): PlayStatus {
    return this.playStatus;
  }

  isPlaying(): boolean {
    return this.playStatus === PlayStatus.PLAYING;
  }

  isPaused(): boolean {
    return this.playStatus === PlayStatus.PAUSED;
  }

  isStopped(): boolean {
    return this.playStatus === PlayStatus.STOPPED;
  }

  private playSoundSource(sound_uri: string): void {
    this.audioTrack = this.createTrack(sound_uri);
    if (this.audioTrack) {
      this.showPlayer();
      this.audioTrack.play();
      this.playStatus = PlayStatus.PLAYING;
      this.notifyPlayEvent();

      this.audioTrack.onend(() => {
        this.stopPlaying();
      });
    }
  }

  getPlayingFileEntry(): PlayingEntryDataType {
    return this.playingEntryData;
  }

  setPlayingFileEntry(file_public_id: string, lang: string): void {
    this.playingEntryData = { file_public_id, lang };
  }

  playFileEntry(file_public_id: string, lang: string, on_finish_cb?): void {
    const current: any = this.getPlayingFileEntry();
    if (current) {
      this.stopPlayingFileEntry(current.file_public_id, current.lang);
    }
    // set current player
    this.setPlayingFileEntry(file_public_id, lang);
    const source: string = this.fileentryService.getFileUrl(file_public_id, false, lang);
    this.playSoundSource(source);
    this.playEvent$.pipe(
      filter(
        (e) => e.status === PlayStatus.STOPPED
      )
      , first()
    ).subscribe(on_finish_cb);
  }

  stopPlayingFileEntry(file_public_id: string, lang: string): void {
    const current: any = this.getPlayingFileEntry();
    if (current && current.file_public_id === file_public_id && current.lang === lang) {
      this.stopPlaying();
    }
  }

  stopPlaying(): void {
    if (this.audioTrack) {
      this.audioTrack.pause();
      this.audioTrack = undefined;
    }
    this.playingEntryData = undefined;

    this.hidePlayer();
    this.playStatus = PlayStatus.STOPPED;
    this.notifyPlayEvent();
  }

  pause(): void {
    if (this.audioTrack) {
      this.audioTrack.pause();
      this.playStatus = PlayStatus.PAUSED;
      this.notifyPlayEvent();
    }
  }

  resume(): void {
    if (this.audioTrack) {
      this.audioTrack.play();
      this.playStatus = PlayStatus.PLAYING;
      this.notifyPlayEvent();
    }
  }

  pauseFileEntry(file_public_id: string, lang: string): void {
    const current: any = this.getPlayingFileEntry();
    if (current && current.file_public_id === file_public_id && current.lang === lang) {
      this.pause();
    }
  }

  mute(muted: boolean = true): void {
    if (this.audioTrack) {
      this.audioTrack.mute(muted);
    }
  }

  duration(): number {
    return this.audioTrack ? this.audioTrack.duration() : 0;
  }

  seek(): number {
    return this.audioTrack ? this.audioTrack.seek() : 0;
  }

  setSeek(value: number): void {
    this.audioTrack.setSeek(value);
  }

  download(): void {
    if (!this.playingEntryData || !this.playingEntryData.file_public_id) {
      console.warn('Playing data cannot found');
      return;
    }
    // const prefixName = this.downloadNamePrefix || 'file';
    const prefixName = 'file'
    const fileName = `${prefixName}-${this.playingEntryData.file_public_id}.mp3`;
    const download_url = this.fileentryService.getFileUrl(this.playingEntryData.file_public_id, true);
    this.fileentryService.download(download_url, fileName);
  }
}
