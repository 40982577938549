import { Component, Input, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'flex-snack-bar',
  templateUrl: './flex-snack-bar.component.html',
  styleUrls: ['./flex-snack-bar.component.scss'],
})

export class FlexSnackBarComponent {

  constructor(
    public snackBarRef: MatSnackBarRef<FlexSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  get hasAction(): boolean {
    return this.data && !!this.data.action;
  }
}
