'use strict';
import * as Sentry from "@sentry/browser";
import { DateTime } from 'luxon';
import {
  MessageService,
  parseDateTime,
  PhoneNumberService,
} from '@wephone-utils';

export class PhoneCall implements IPhoneCall {

  private readonly d_hangup: Deferred<IPhoneCall>;
  private readonly d_answer: Deferred<IPhoneCall>;
  private _is_answered: boolean;
  private _is_hungup: boolean;
  private _queue: CallQueueEntity;
  private _user: UserEntity;
  private _group: UserGroupEntity;
  private can_answer = false;
  private _sipphone: SipPhoneEntity;
  // private _qualification: QualificationEntity;
  private _callQueueSession: CallQueueSessionEntity;
  private _transferDestination: string;
  private _transferInType: number;
  private _transferInUser: UserEntity;
  private _transferReason: string;
  private _crmContactUrl: any;
  private _execScriptConfig: ConfigExecScript;

  extraInfo: ActiveCallInfo;

  constructor(
    private readonly phoneService: IPhoneService,
    private readonly messageService: MessageService,
    private sipCall?: ISipCall,
    private callData?: MyActiveCall,
  ) {
    this._is_hungup = false;
    this._is_answered = false;
    this.d_hangup = new Deferred<IPhoneCall>();
    this.d_answer = new Deferred<IPhoneCall>();
    this.setSipCall(sipCall);
    this.setCallData(callData);
    this.setSettingsConfig();
  }

  isAnswered(): boolean {
    return this._is_answered;
  }

  isHungup(): boolean {
    return this._is_hungup;
  }

  setSipCall(sip_call: ISipCall): void {
    this.sipCall = sip_call;
    this.can_answer = false;
    if (sip_call) {
      this.can_answer = this.sipCall.isInbound() && (this.sipCall.getState() === CallState.EARLY);

      sip_call.watchHangup(this.onHangup);
      sip_call.watchAnswer(this.onAnswer);
      sip_call.callEvent$.subscribe(
        (ev: CallEvent) => {
          if (ev.type === CallEventType.STATE_CHANGE) {
            if (this.sipCall && this.sipCall.isInbound()) {
              this.can_answer = this.sipCall.getState() === CallState.EARLY;
            }
          }
        }
      );
    }
  }

  onAnswer = () => {
    if (!this._is_answered) {
      this._is_answered = true;
      this.d_answer.resolve(this as any);
    }
  }

  onSipCallHangup = () => {
    if (this.sipCall && this.sipCall.isAnswered()) {
      this.onHangup();
    }
  }

  onHangup = () => {
    if (!this._is_hungup) {
      this._is_hungup = true;
      this.d_hangup.resolve(this as any);
    }
  }

  async setSettingsConfig(): Promise<void> {
    this._execScriptConfig = await this.phoneService.getConfigExecScript();
  }

  setCallData(call_data: MyActiveCall): void {
    this.callData = call_data;
    if (call_data) {
      call_data.watchHangup(this.onHangup);
      call_data.watchAnswer(this.onAnswer);
    }
    this.setQueue();
  }

  watchHangup(callback: (val: IPhoneCall) => any): any {
    return this.d_hangup.promise.then(callback);
  }

  watchAnswer(callback: (val: IPhoneCall) => any): any {
    return this.d_answer.promise.then(callback);
  }

  hasSipCall(): boolean {
    return !!this.sipCall;
  }

  hasCallData(): boolean {
    return !!this.callData;
  }

  get lastStatusCode(): number {
    return this.sipCall && this.sipCall.lastStatusCode;
  }

  get calldata(): any {
    return this.callData;
  }

  get remoteNumber(): string {
    return (this.callData && this.callData.remote_number) ||
      (this.sipCall && this.sipCall.remoteNumber && PhoneNumberService.getInstance().normalizeNumber(this.sipCall.remoteNumber));
  }

  remoteNumberInLocalFormat(): string {
    return PhoneNumberService.getInstance().getDisplayNumber(this.remoteNumber);
  }

  get localNumber(): string {
    return (this.callData && this.callData.local_number) ||
      (this.sipCall && this.sipCall.localNumber && PhoneNumberService.getInstance().normalizeNumber(this.sipCall.localNumber));
  }

  localNumberInLocalFormat(): string {
    return PhoneNumberService.getInstance().getDisplayNumber(this.localNumber);
  }

  localNumberNormalized(): string {
    return PhoneNumberService.getInstance().normalizeNumber(this.localNumber);
  }

  get sipCallId(): string {
    return (this.sipCall && this.sipCall.sipCallId) || (this.callData && this.callData.sip_call_id);
  }

  get canActiveCRM(): boolean {
    return this.callData ? !!this.callData.crm_id : false;
  }

  canAnswerHere(): boolean {
    return this.can_answer;
  }

  canBeAnsweredSomewhere(): boolean {
    return this.isInbound() && !this.isAnswered();
  }

  answer(): void {
    if (this.sipCall && this.sipCall.isInbound() && (this.sipCall.getState() === CallState.EARLY)) {
      try {
        this.sipCall.answer();
      } catch (e) {
        console.warn('Cannot answer call from ', this.sipCall.remoteNumber, e);
        this.sipCall.stopPlaying(); // Stop playing to make sure ring tone won't be played forever
        this.can_answer = false;
        this.onHangup();
      }
    }
  }

  async setExtraInfo(): Promise<void> {
    try {
      console.log('set extra info', this.sipCallId);
      if (!this.sipCallId) {
        console.error('No sip call id to get extra info');
        return;
      }
      this.extraInfo = await this.phoneService.getActiveCallInfoById(this.sipCallId);
    } catch (e) {
      console.error(`Exception while trying to get call's extra info`, e);
    }
  }

  async hangup(): Promise<void> {
    try {
      if (this.sipCall && !this.sipCall.isHungup()) {
        this.sipCall.hangup();
      } else {
        await this.phoneService.hangupCallByWebservice(this);
      }
    } catch (error) {
      console.error('Error on hangup call', error);
      // Manual clear active call because in some cases the call hungup but call data still available
      this.phoneService.terminateActiveCall(this);
    }
  }

  dtmf(dtmf_string: string): void {
    if (this.sipCall && !this.sipCall.isHungup()) {
      this.sipCall.dtmf(dtmf_string);
    }
  }

  setMuted(muted: boolean): void {
    if (this.sipCall) {
      this.sipCall.setMuted(muted);
    }
  }

  toggleMute(): void {
    if (this.sipCall) {
      this.sipCall.setMuted(!this.sipCall.isMuted());
    }
  }

  isMuted(): boolean {
    return this.sipCall && this.sipCall.isMuted();
  }

  isRecording(): boolean {
    return this.callData && (this.callData.recording_state === CallRecordingState.ACTIVE);
  }

  isOnhold(): boolean {
    if (this.callData) {
      return !!this.callData.onhold_since;
    }

    return false;
  }

  canMute(): boolean {
    return !!this.sipCall;
  }

  isInbound(): boolean {
    if (this.callData) {
      return !this.callData.outbound;
    }

    if (this.sipCall) {
      return this.sipCall.isInbound();
    }
  }

  /**
   * Detect if communication with answering machine
   * * Has answered
   * * Is outbound call
   * * Communication time lesser than 90 seconds
   * @returns boolean
   */
  wasContactWithMachine(): boolean {
    if (!this.isAnswered() || this.isInbound() || !this.calldata) { // || !this.calldata.is_answered
      console.log('Not contact with machine',
        'is-answered: ', this.isAnswered(),
        'is-inbound: ', this.isInbound(),
        'call-data: ', this.calldata);
      return false;
    }

    if (!this.calldata.is_bridged) {
      console.log('Call not bridged');
      return false;
    }

    const now = DateTime.local();
    if (!this.answerTime || this.answerTime.plus({seconds: 90}) < now) {
      console.log('Not Answer time Or answer time is in the past more than 90s', this.answerTime.toFormat('HH:mm:ss'), now.toFormat('HH:mm:ss'));
      return false;
    }

    console.log('Was contact with machine, answer time:', this.answerTime.toFormat('HH:mm:ss'));
    return true;
  }

  isCampaign(): boolean {
    return this.callData && !!this.callData.outcall_campaign_id;
  }

  wasAnsweringMachineFromCampaign(): boolean {
    if (!this.isAnswered()) { // || !this.calldata.is_answered
      console.log('No answer');
      return false;
    }

    if (this.isInbound()) {
      console.log('Not outbound call');
    }

    if (!this.calldata) {
      console.log('Not call data');
    }

    const campaignId: number = this.callData && this.callData.outcall_campaign_id || null;
    if (!campaignId) {
      console.log('Call was not outcall campaign');
      return false;
    }

    const campaign: OutcallCampaignEntity = OutcallCampaignRepository.getInstance().getObjectById(campaignId);
    if (!campaign) {
      console.error('No campaign found', campaignId);
      return;
    }

    if (campaign.call_agent_first && !this.calldata.is_bridged) {
      console.log('Call between client-agent not bridged');
      return false;
    }

    if (!campaign.am_check_enabled) {
      console.log('Not enable answering machine detection', campaignId);
      return false;
    }

    const callDuration = campaign.am_check_max_duration || 0; // Seconds

    const now = DateTime.local();
    if (!this.answerTime || this.answerTime.plus({seconds: callDuration}) < now) {
      console.log(`Not Answer time Or answer time is in the past more than ${callDuration}s`,
        this.answerTime && this.answerTime.toFormat('HH:mm:ss'), now.toFormat('HH:mm:ss'));

      return false;
    }

    return true;
  }

  get callPublicId(): string {
    return this.calldata && this.calldata.client_public_call_id || null;
  }

  get startTime(): DateTime {
    if (this.callData && this.callData.start_time) {
      return DateTime.fromSQL(this.callData.start_time);
    }

    if (this.sipCall) {
      return parseDateTime(this.sipCall.getConnectedTime());
    }
  }

  get answerTime(): DateTime {
    if (this.callData && this.callData.answer_time) {
      return DateTime.fromSQL(this.callData.answer_time);
    }

    if (this.sipCall) {
      return parseDateTime(this.sipCall.getConnectedTime());
    }
  }

  get onholdTime(): DateTime {
    return this.callData ? this.callData.onhold_since : undefined;
  }

  isTransfering(): boolean {
    if (this.callData) {
      return this.callData.transfer_state !== 0;
    }

    return false;
  }

  canConfirmTransfer(): boolean {
    return this.callData && this.callData.transfer_state === 2;
  }

  get qualificationId(): number {
    if (!this.queue) {
      return null;
    }

    return this.isInbound() ? this.queue.in_qualification_id : this.queue.out_qualification_id;
  }

  canQualify(): boolean {
    // return (this.qualification && this.queue.has_qualification === 1);
    return (this.qualificationId && this.queue && this.queue.has_qualification === 1);
  }

  get user(): UserEntity {
    if (!this._user && this.callData) {
      this._user = UserRepository.getInstance<UserRepository>().getObjectById(this.calldata.user_id);
    }

    return this._user;
  }

  get queue(): CallQueueEntity {
    if (!this._queue && this.callData) {
      this._queue = CallQueueRepository.getInstance<CallQueueRepository>().getObjectById(this.calldata.call_queue_id);

      if (this._queue
        && this._queue.bo_type === CallQueueEntity.BO_TYPE_URL
        && this._queue.bo_url
      ) {
        this.openBO();
      }
    }

    return this._queue;
  }

  setQueue(): void {
    if (!this._queue && this.callData) {
      this._queue = CallQueueRepository.getInstance<CallQueueRepository>().getObjectById(this.calldata.call_queue_id);

      if (this._queue) {
        this.openBO();
      }
    }
  }

  openBO(): void {
    if (!this._queue) {
      return;
    }

    if (!this.isCampaign() && this._queue.bo_open_derection !== BoOpenDerection.BOTH) {
      if (this.isInbound() && this._queue.bo_open_derection !== BoOpenDerection.INCOMING) {
        return;
      }

      if (!this.isInbound() && this._queue.bo_open_derection !== BoOpenDerection.OUTGOING) {
        return;
      }
    }

    if (this._queue.bo_type === CallQueueEntity.BO_TYPE_URL && this._queue.bo_url && this.calldata.client_public_call_id) {
      const electron = this.phoneService.isElectronApp() ? 1 : 0;
      const boUrl = FlexIvrSettings.getInstance().getAbsoluteUrl
        (`/ws-v2/noauth/queue/open_bo_link?public_call_id=${this.calldata.client_public_call_id}&electron=${electron}`);
      // console.log('boUrl', boUrl);
      this.messageService.broadcast('phone:open_bo_url', boUrl);
    }
  }

  get group(): UserGroupEntity {
    if (!this._group && this.callData) {
      this._group = UserGroupRepository.getInstance().getObjectById(this.calldata.group_id);
    }

    return this._group;
  }

  get sipphone(): SipPhoneEntity {
    if (!this._sipphone && this.callData) {
      const sipPhoneRepo = SipPhoneRepository.getInstance<SipPhoneRepository>();
      this._sipphone = sipPhoneRepo.getObjectByDidNumber(this.calldata.remote_number);
    }

    return this._sipphone;
  }

  getContext(): any {
    if (this.calldata) {
      return this.calldata.getContext();
    }
  }

  getWelcomeMessage(): string {
    if (this.calldata) {
      return this.calldata.getWelcomeMessage();
    }
  }

  get callQueueSession(): CallQueueSessionEntity {
    if (!this._callQueueSession && this.callData && this.callData.call_queue_session_id) {
      this._callQueueSession = CallQueueSessionRepository
        .getInstance<CallQueueSessionRepository>()
        .getObjectById(this.calldata.call_queue_session_id);
    }

    return this._callQueueSession;
  }

  get transferDestination(): string {
    if (!this._transferDestination && this.callQueueSession && this.callQueueSession.transfer_out_data) {
      if (this.callQueueSession.transfer_out_data.dest_type === 'agent') {
        const agent = AgentRepository.getInstance().getObjectById(this.callQueueSession.transfer_out_data.dest) as AgentEntity;
        this._transferDestination = agent ? agent.name : this.callQueueSession.transfer_out_data.dest;
      }
    }

    return this._transferDestination;
  }

  get transferInType(): number {
    if (!this._transferInType && this.callData && this.callData.transfer_in_type >= 0) {
      this._transferInType = this.callData.transfer_in_type;
    }

    return this._transferInType;
  }

  get transferInUser(): UserEntity {
    if (!this._transferInUser && this.callData && this.callData.transfer_in_user_id) {
      this._transferInUser = UserRepository.getInstance().getObjectById(this.callData.transfer_in_user_id) as UserEntity;
    }

    return this._transferInUser;
  }

  get transferReason(): string {
    if (!this._transferReason && this.callData && this.callData.transfer_in_reason) {
      this._transferReason = this.callData.transfer_in_reason;
    }

    return this._transferReason;
  }

  isCrmContact(): boolean {
    if (this.callData) {
      return !!this.callData.crm_contact_id;
    }

    return false;
  }

  async getCrmContactUrl(): Promise<string> {
    if (!this._crmContactUrl) {
      const contactData = await this.phoneService.getCrmContact(this.callData.crm_id, this.callData.crm_contact_id, this.callData.crm_contact_type);
      this._crmContactUrl = contactData.profileUrl;
    }
    return this._crmContactUrl;
  }

  get pathQualIds(): number[] {
    if (this.callData && this.callData.qualification) {
      return this.callData.qualification;
    }

    return [];
  }

  get qualificationComment(): string {
    if (this.callData && this.callData.qualification_comment) {
      return this.callData.qualification_comment;
    }

    return '';
  }

  setActive(active: boolean): void {
    if (this.sipCall) {
      this.sipCall.setActive(active);
    }
  }
}

import { ISipCall, CallEvent, CallEventType, CallState } from '@jsphonelib';
import { MyActiveCall, CallRecordingState } from '@wephone-core/model/entity/my_active_call';
import { Deferred } from 'ts-deferred';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { BoOpenDerection, CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { UserRepository } from '@wephone-core/model/repository/user';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { CallQueueSessionEntity } from '@wephone-core/model/entity/callqueuesession';
import { CallQueueSessionRepository } from '@wephone-core/model/repository/callqueuesession';
import { UserEntity } from '@wephone-core/model/entity/user';
import { AgentRepository } from '@wephone-core/model/repository/agent';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { ActiveCallInfo, IPhoneCall } from '@wephone-app-phone/services/phone/phonecall.i';
import { IPhoneService, ConfigExecScript } from '@wephone-app-phone/services/phone/phone.service.i';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';

