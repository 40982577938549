import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { LiveStatsService, UserStatsDailyLiveOverview } from '@wephone-core/service/live-stats.service';
import { interval } from 'rxjs';
import * as EasyPieChart from 'easy-pie-chart/dist/easypiechart.min.js';
import { Router } from '@angular/router';

interface CallsStatsDaily {
  listened_voicemail: number;
  listened_voicemail_percent: number;
  handled_missedcall: number;
  handled_missedcall_percent: number;
}

@Component({
  selector: 'qos',
  templateUrl: './qos.component.html',
  styleUrls: ['./qos.component.scss']
})
export class QosComponent extends FlexBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filterGroupIds: number[];
  @ViewChild('pieCanvas') pieCanvas: any;
  realtime_pushapi = true;

  highchart_configs: any = {};
  statsCallCounter: CallsStatsDaily = {
    listened_voicemail: 0,
    listened_voicemail_percent: 0,
    handled_missedcall: 0,
    handled_missedcall_percent: 0
  };

  statsDailyLive: UserStatsDailyLiveOverview = {
    inbound_call_count: 0,
    outbound_call_count: 0,
    inbound_call_answered: 0,
    missed_call_count: 0,
    missed_call_unhandled: 0,
    outbound_call_answered: 0,
    inbound_call_effective: 0,
    outbound_call_effective: 0,
    total_voicemail_today: 0,
    unread_voicemail_today: 0,
    active_inbound_call: 0,
    active_outbound_call: 0,
    agent_connected: 0,
    agent_available: 0,
    agent_incall: 0,
    agent_pause: 0,
    qos: 0,
  };
  pieChart: EasyPieChart;

  private readonly todayQosOptions: any;

  constructor(
    private readonly liveStatsService: LiveStatsService,
    private readonly router: Router,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);

    this.todayQosOptions = {
      udupercent: 0,
      lineWidth: 8,
      trackColor: '#e8eff0',
      barColor: '#24a6e7',
      scaleColor: false,
      size: 150,
      rotate: 90,
      lineCap: 'butt'
    };

    this.highchart_configs.today_qos = {
      percent: 0,
      lineWidth: 4,
      trackColor: '#e8eff0',
      barColor: '#24a6e7',
      scaleColor: false,
      size: 150,
      rotate: 90,
      lineCap: 'butt'
    };

    this.addSubscription(
      interval(30000).subscribe(() => {
        this.updateStatsDailyLive();
      })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.updateStatsDailyLive();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterGroupIds) {
      this.updateStatsDailyLive();
    }
  }

  updateStatsDailyLive(): void {
    this.liveStatsService.dashboardGetAgentInfo(this.filterGroupIds).then((res: UserStatsDailyLiveOverview) => {
      this.statsDailyLive = res;
      this._updateCallCounter(this.statsDailyLive);
      this._updateTodayQos(this.statsDailyLive.qos);
    });
  }

  private _updateCallCounter(stats: UserStatsDailyLiveOverview): void {
    const listnedVm: number = stats.total_voicemail_today - stats.unread_voicemail_today;
    const handledMc: number = stats.missed_call_count - stats.missed_call_unhandled;

    this.statsCallCounter = {
      listened_voicemail: listnedVm,
      listened_voicemail_percent: stats.total_voicemail_today > 0 ? Math.round(listnedVm * 100 / stats.total_voicemail_today) : 0,
      handled_missedcall: handledMc,
      handled_missedcall_percent: stats.missed_call_count > 0 ? Math.round(handledMc * 100 / stats.missed_call_count) : 0,
    };
  }

  private _updateTodayQos(qos: number): void {
    if (!this.pieChart && this.pieCanvas && this.pieCanvas.nativeElement) {
      this.pieChart = new EasyPieChart(this.pieCanvas.nativeElement, this.todayQosOptions);
    }
    if (this.pieChart) {
      this.pieChart.update(qos);
    }
  }

  gotoTab(view: string): void {
    this.router.navigate([], { queryParams: { view } });
  }

  todayMissedCalls(): void {
    this.router.navigate([], { queryParams: { view: 'missed-calls' }, state: { period: 'today' } });
  }

  gotoPage(pageAlias: string): void {
    this.router.navigateByUrl(pageAlias);
  }
}
