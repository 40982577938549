<div class="pa-1">
  <form [formGroup]="form" *ngIf="form">
    <div fxFlexLayout="column">
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'ivrremoteapp.content.name' | translate}}</mat-label>
          <input matInput placeholder="{{'ivrremoteapp.content.name' | translate}}" formControlName="name">
          
          <mat-error [flexFormControlError]="form.get('name')"></mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="grow">
          <mat-form-field class=" full-width">
            <mat-label>{{'ivrremoteapp.content.url' | translate}}</mat-label>
            <input matInput placeholder="{{'ivrremoteapp.content.url' | translate}}" formControlName="url">
            
            <mat-error [flexFormControlError]="form.get('url')"></mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="initial">
          <button mat-raised-button color="primary" (click)="verifyUrl()">
            <mat-icon>check</mat-icon>
            {{ 'enterprise_crm.content.test_crm_service'|translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
