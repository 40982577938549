import { datafield } from '@wephone-core/model/model';
import { Entity } from '@wephone-core/model/model';

export class EmailEntity extends Entity {
  public static object_type_id: string = 'email';

  @datafield
  public subject: string;

  @datafield
  public from: string;

  @datafield
  public avatar: string;

  @datafield
  public to: Array<any>;

  @datafield
  public content: string;

  @datafield
  attach: Array<any>;

  @datafield
  public date;

  @datafield
  public label: string;

  @datafield
  public fold: string;
}
