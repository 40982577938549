import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { SkillRequirementEntity } from '@wephone-core/model/entity/skill_requirement';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { IOpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar.i';
import { CallQueueSessionEntity } from '@wephone-core/model/entity/callqueuesession';
import { DateTime } from 'luxon';
import { AgentPhoneMode } from '@wephone-core/system';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { AgentReservedStatus, IAgentEntity } from './agent.i';
import { parseDateTime } from '@wephone-utils';
import { TagEntity } from './tag';
import { AgentStatusType } from '@wephone/components/agents/agent-status-type';

export enum AgentAvailability {
  AvailableWhenConnect = 0,
  AlwaysAvailable = 1,
  OnCalendar = 2
}

export class AgentEntity extends Entity implements IAgentEntity {
  static object_type_id = 'agent';

  id: number;
  @datafield user_id: number;
  @datafield reserving_server_id: number;
  @datafield reserved_dt: any;
  @datafield reserved: AgentReservedStatus;
  @datafield phone_mode: AgentPhoneMode;
  @datafield phone_number: string;
  @datafield is_connected: number;
  @datafield is_connected_calendar: number;
  @datafield unreachable_count: number;
  @datafield last_hangup_dt: any;
  @datafield last_pickup_dt: any;
  @datafield last_unreachable_dt: any;
  @datafield last_release_dt: any;
  @datafield team: string;
  @datafield pause_event_id: number;
  @datafield pause_reason_id: number;
  @datafield connection_event_id: number;
  @datafield reserving_call_id: number;
  @datafield last_session_id: number;
  @datafield last_session_type: string;
  @datafield inout_state: number;
  @datafield phone_connected: number;
  @datafield session_auth_token: string;
  @datafield agent_id: number;
  @datafield available_since: any;
  @datafield pause_since: any;
  @datafield connection_time: any;
  @datafield availability: AgentAvailability;
  @datafield working_calendar_id: number;
  @datafield default_queue_id: number;

  user: IUserEntity;
  skills: SkillRequirementEntity[];
  pause_reason: PauseReasonEntity;

  get always_available(): number {
    return this.availability === AgentAvailability.AlwaysAvailable ? 1 : 0;
  }

  get is_available_cc(): boolean {
    return this.is_online_cc && !this.is_busy;
  }

  get is_busy(): boolean {
    return !!this.reserved || !!this.pause_event_id;
  }

  get enabled(): number {
    return this.user && this.user.enabled ? 1 : 0;
  }

  get sipphone(): SipPhoneEntity {
    return this.user.sipphone;
  }

  /**
   * Phone status: Enabled when
   * phone_mode = SIP_PHONE and phone_connected
   * Or phone_mode = BACKUP_NUMBER and user having phone number
   * Or phone_mode = BOTH and one of above conditions
   */
  get phone_enabled(): boolean {
    return this.phone_mode === AgentPhoneMode.SIP_PHONE && this.phone_connected === 1 ||
      this.phone_mode === AgentPhoneMode.BACKUP_NUMBER && this.user && !!this.user.phone ||
      this.phone_mode === AgentPhoneMode.BOTH && (this.user && !!this.user.phone || this.phone_connected === 1);
  }

  get default_queue(): CallQueueEntity {
    if (!this.default_queue_id) {
      return;
    }

    return EntityManager.getRepository('CallQueueRepository').getObjectById(this.default_queue_id);
  }

  set default_queue(queue) {
    this.default_queue_id = queue ? queue.id : undefined;
  }

  get working_calendar(): IOpeningHourCalendarEntity {
    if (this.working_calendar_id) {
      return EntityManager.getRepository('OpeningHourCalendarRepository').getObjectById(this.working_calendar_id);
    }
  }

  set working_calendar(calendar) {
    this.working_calendar_id = calendar.id;
  }

  get is_online(): boolean {
    if (this.user) {
      return !!this.user.is_online;
    }

    return false;
  }

  get is_online_calendar(): boolean {
    return !!(this.always_available || this.is_connected_calendar);
  }

  get is_online_cc(): boolean {
    if (!this.enabled) {
      return false;
    }
    return !!(this.is_connected || this.is_online_calendar || this.always_available);
  }

  get is_postcall_paused(): boolean {
    return !!(this.is_paused && this.pause_reason && this.pause_reason.after_call);
  }

  get is_paused(): boolean {
    return this.is_online_cc && Boolean(this.pause_event_id);
  }

  get can_use_callcenter(): boolean {
    if (!this.user) {
      console.error('No user associated', this.id);
    }
    return this.user && this.user.can_use_callcenter;
  }

  fetchRelatedData(): void {
    this.skills = EntityManager.getRepository<any>('SkillAgentRequirementRepository').getAgentSkillsByAgentId(this.id);
    this.user = this.user_id ? EntityManager.getRepository('UserRepository').getObjectById(this.user_id) : undefined;

    this.pause_reason =
      this.pause_event_id && this.pause_reason_id
        ? EntityManager.getRepository('PauseReasonRepository').getObjectById(this.pause_reason_id)
        : undefined;
  }

  get inbound_queue_list(): CallQueueEntity[] {
    return EntityManager.getRepository<any>('CallQueueRepository').getInboundQueuesByAgentId(this.id);
  }

  get inbound_queue_names(): string[] {
    const queues = this.inbound_queue_list;
    const queue_names = [];
    if (queues) {
      for (const queue of queues) {
        queue_names.push(queue.queue_name);
      }
    }

    return queue_names;
  }

  get service_group_list(): any[] {
    if (!this.group_ids) {
      return [];
    }
    return EntityManager.getRepository<any>('UserGroupRepository').getObjectListByIds(this.group_ids);
  }

  /**
   * Check agent doesn't belong to any inbound queue or not
   */

  get is_free_from_inbound_queue(): boolean {
    return !this.inbound_queue_list.length;
  }

  /**
   * Agent belong to inbound queue but exclusive applying
   */
  isFreeFromInboundQueue(exclusive = false): boolean {
    // if exclusive: not allow any inbound queues
    if (exclusive) {
      return this.is_free_from_inbound_queue;
    }
    // if not exclusive: allow only not exclusive queue
    if (this.inbound_queue_list.find(x => x.is_exclusive)) {
      return false;
    }

    return true;
  }

  get handled_queue_list(): CallQueueEntity[] {
    if (!this.reserved) {
      return [];
    }
    const queues = [];
    const callqueue_sessions = EntityManager.getRepository('CallQueueSessionRepository').getObjectList();
    for (const session of callqueue_sessions) {
      if (session.operator_id === this.id && session.call_queue_keyid) {
        const queue = EntityManager.getRepository('CallQueueRepository').getObjectById(session.call_queue_keyid);
        queues.push(queue);
      }
    }

    return queues;
  }

  /**
   * Get all uneligible queues (for only inbound queues) of this agent
   * @param comparedQueue: queue contains agent in current context
   */
  getUneligibleQueues(comparedQueue: CallQueueEntity): CallQueueEntity[] {
    if (!comparedQueue) {
      return [];
    }

    const queues: CallQueueEntity[] = [];

    for (const queue of this.inbound_queue_list) {
      if (queue.getId() === comparedQueue.getId()) {
        continue;
      }
      if (comparedQueue.is_exclusive || (!comparedQueue.is_exclusive && queue.is_exclusive)) {
        queues.push(queue);
      }
    }
    return queues;
  }

  hasHandledQueue(queue: CallQueueEntity): boolean {
    if (this.handled_queue_list.indexOf(queue) !== -1) {
      return true;
    }
    return false;
  }

  get name(): string {
    if (this.user) {
      return this.user.name;
    }
  }

  get group_ids(): number[] {
    if (this.user) {
      return this.user.group_ids;
    }
    return [];
  }

  get team_id(): number {
    if (!this.user) {
      return undefined;
    }
    return this.user.team_id;
  }

  get tags(): number[] {
    if (this.user) {
      return this.user.tags;
    }
    return [];
  }

  get tagList(): TagEntity[] {
    if (this.tags) {
      return EntityManager.getRepository<any>('TagRepository').getObjectListByIds(this.tags);
    }

    return [];
  }

  get active_call(): CallQueueSessionEntity {
    if (this.reserved_dt) {
      return EntityManager.getRepository<any>('CallQueueSessionRepository').getActiveObjectByOperatorId(this.id);
    }
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);

    this.reserved_dt = this.reserved_dt ? parseDateTime(this.reserved_dt) : undefined;
    this.last_hangup_dt = this.last_hangup_dt ? parseDateTime(this.last_hangup_dt) : undefined;
    this.last_pickup_dt = this.last_pickup_dt ? parseDateTime(this.last_pickup_dt) : undefined;
    this.last_unreachable_dt = this.last_unreachable_dt ? parseDateTime(this.last_unreachable_dt) : undefined;
    this.last_release_dt = this.last_release_dt ? parseDateTime(this.last_release_dt) : undefined;
    this.pause_since = this.pause_since ? parseDateTime(this.pause_since) : undefined;
    this.available_since = this.available_since ? parseDateTime(this.available_since) : undefined;
  }

  get status_since(): DateTime {
    if (this.reserved) {
      return this.reserved_dt;
    }

    return this.available_since;
  }

  get agent_status_detail(): string {
    // if (this.pause_since) {
    if (this.is_paused) {
      return 'pause';
    }

    // if (!(this.pause_since || this.reserved)) {
    if (!this.is_busy) {
      if (this.inout_state === 0) {
        return 'available';
      }
      if (this.inout_state === 1) {
        return 'available_in';
      }
      if (this.inout_state === 2) {
        return 'available_out';
      }
      if (this.inout_state === 3) {
        return 'available_none';
      }
    }

    if (this.reserved) {
      if (this.reserved === AgentReservedStatus.ExternalReserved) { // && this.active_call
        return this.active_call && this.active_call.is_outgoing ? 'call_out' : 'call_in';
      }

      if (this.reserved === AgentReservedStatus.InternalReserved) {
        return 'internal_call';
      }
    }

    if (!this.is_online_cc) {
      return 'disconnected';
    }

    return;
  }

  get agent_status(): AgentStatusType {
    if (this.is_postcall_paused) {
      // return 'pause-postcall';
      return AgentStatusType.PAUSE_POSTCALL;
    }
    if (this.is_paused) {
      // return 'pause';
      return AgentStatusType.PAUSE;
    }
    // if (!(this.pause_since || this.reserved)) {
    if (!this.is_busy) {
      // return 'available';
      return AgentStatusType.AVAILABLE;
    }
    // if (this.reserved === AgentReservedStatus.ExternalReserved && this.active_call || this.reserved === AgentReservedStatus.InternalReserved) {
    if (this.reserved) {
      // return 'in-call';
      return AgentStatusType.IN_CALL;
    }
  }

  getUser(): IUserEntity {
    return this.user;
  }

  getPauseReasonLabel(): string {
    if (this.pause_reason) {
      return this.pause_reason.label;
    }
    return '';
  }

  /**
   * Last call being shown must be meet the condition: is available but not in call and last hang up date within 24 hours
   */

  get last_call_displayable(): boolean {
    const now = DateTime.utc();

    return (
      this.is_online_cc &&
      !this.reserved &&
      this.last_hangup_dt &&
      now.diff(this.last_hangup_dt).hours <= 24
    );
  }
}
