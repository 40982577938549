import { Component, Input, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { DialogService } from '@wephone-utils';
import { CalendarEditModal } from '@wephone/modals/calendar/calendar-edit/calendar-edit';
import { FlexBaseComponent } from '@wephone-core-ui';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'calendar-small',
  templateUrl: './calendar-small.html',
  styleUrls: ['./calendar-small.scss']
})
export class CalendarSmallComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() embed: boolean;
  @Input() allowEdit = true;
  @Input() showSelector = false;
  @Output() readonly onItemSelected: EventEmitter<any> = new EventEmitter();

  openinghourCalendar: OpeningHourCalendarEntity;
  private hasInitialized: boolean;

  constructor(private dialogService: DialogService, cdr: ChangeDetectorRef, private em: EntityManager) {
    super(cdr);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized) {
      return;
    }
    if (changes.id) {
      this.setCalendar();
    }
  }

  ngOnInit(): void {
    this.setCalendar();
    this.hasInitialized = true;
  }

  private setCalendar(): void {
    this.openinghourCalendar = OpeningHourCalendarRepository.getInstance().getObjectById(this.id);
  }

  editWorkCalendar(calendarId: number): void {
    this.dialogService.openSideDialog(CalendarEditModal, { data: { id: calendarId } }, () => {
      this.setCalendar();
      this.cdr.markForCheck()
      this.cdr.detectChanges();
    });
  }

  onSelectedCalendar(): void {
    if (this.onItemSelected) {
      this.onItemSelected.emit(this.openinghourCalendar);
    }
  }
}
