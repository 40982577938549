import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { SingletonBase } from '@wephone-utils';

@Injectable()
export class TaskService extends SingletonBase {
  static URL_GET_NEXT_TASKS_PREFIX = '/ws-v2/crm/outcall-next-tasks';

  constructor() {
    super();
  }

  outcallNextTasks(): Promise<any> {
    return HttpEngine.getInstance().get(`${TaskService.URL_GET_NEXT_TASKS_PREFIX}`);
  }
}
