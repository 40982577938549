<!-- <app-credit-card [(ngModel)]="credit_card_info"></app-credit-card> -->
<mat-card>
  <mat-card-content [attr.data-ci]="'addCreditCard'">
    <form novalidate (ngSubmit)="onSubmit()" class="checkout" [formGroup]="stripeForm" *ngIf="stripeForm">
      <div class="form-row">
        <mat-form-field class="full-width">
          <mat-label>{{'components.creditcard_input.namecard'| translate}}</mat-label>
          <input matInput type="text" formControlName="name" placeholder="{{ 'components.creditcard_input.namecard'|translate }}">
          <mat-error *ngIf="!stripeForm.controls['name'].valid && stripeForm.controls['name'].touched">{{ 'public.message.data_invalid'|translate }}</mat-error>
        </mat-form-field> 
        
        <div id="stripeCard"></div>
        <div class="mat-text-warn" role="alert" *ngIf="error">{{ error }}</div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
