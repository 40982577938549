import { Component, OnInit, OnDestroy, Self, Optional, ElementRef, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IFlexSelectOptions, FlexMatInputWrapper, regexSearch } from '@wephone-utils';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';

@Component({
  selector: 'teamgroup-select-form-input',
  templateUrl: './teamgroup-select.component.html',
  styleUrls: ['./teamgroup-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TeamGroupSelectFormInput }],
})
export class TeamGroupSelectFormInput extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<TeamGroupEntity> {
  static nextId = 0;
  flexSelect: FormControl = new FormControl();
  list: TeamGroupEntity[];
  flexSelectOptions: IFlexSelectOptions;

  @Input() hasUser: boolean;

  constructor(
    @Optional()
    @Self()
    ngControl: NgControl,
    elRef: ElementRef,
    private readonly em: EntityManager
  ) {
    super(ngControl, elRef);
    this.flexSelectOptions = {
      filterFunc: (item: TeamGroupEntity, filterString) => {
        return regexSearch(item.name, filterString);
      },
      compareWith: (a: TeamGroupEntity, b: TeamGroupEntity) => {
        return !a && !b || a && b && a.getId() === b.getId();
      }
    };
  }

  get wrappedControl(): FormControl {
    return this.flexSelect;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list = this.em.getRepository('TeamGroupRepository').getObjectList();

    if (this.hasUser) {
      this.list = this.list.filter(t => t.hasUser());
    }
  }

  get controlType(): string {
    return 'teamgroup-select-form-input';
  }
}
