import * as _ from 'lodash';

type EnumObject = { [key: string]: number | string };
type EnumObjectEnum<E extends EnumObject> = E extends { [key: string]: infer ET | string } ? ET : never;
export class ArrayHelper {
    static getEnumValues<E extends EnumObject>(enumObject: E): EnumObjectEnum<E>[] {
        return Object.keys(enumObject)
            .filter(key => Number.isNaN(Number(key)))
            .map(key => enumObject[key] as EnumObjectEnum<E>);
    }

    static arrayMove(arr: any[], fromIndex: number, toIndex: number): void {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }
}
