import * as _ from 'lodash';
import { OnDestroy, Input, HostBinding, ElementRef, Directive, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { FlexBaseComponent } from '@wephone-core-ui/flex-base-component';

@Directive()
export abstract class FlexMatInputBase extends FlexBaseComponent implements OnDestroy, ControlValueAccessor {
  static nextId = 0;
  private instanceId: number;
  protected _value;
  protected valueInitialized = true;

  protected _onDestroy = new Subject<boolean>();
  protected _placeholder: string;
  protected _required = false;
  protected _disabled = false;
  protected fm: FocusMonitor;
  protected onControlValueChange: (val) => any;

  focused = false;
  _errorState = false;

  stateChanges = new Subject<void>();
  @HostBinding('attr.aria-describedby') describedBy = '';

  constructor(public ngControl: NgControl, protected elRef?: ElementRef, protected cdr?: ChangeDetectorRef) {
    super();
    this.fm = StaticServiceLocator.injector.get(FocusMonitor);
    if (elRef) {
      this.fm.monitor(this.elRef.nativeElement, true).subscribe(
        origin => {
          this.focused = !!origin;
          this.stateChanges.next();
        }
      );
    }

    if (ngControl) {
      ngControl.valueAccessor = this;
    }
    this.instanceId = FlexMatInputBase.nextId++;
  }

  @HostBinding()
  get id(): string {
    return `${this.controlType}-${this.instanceId}`;
  }

  abstract get controlType(): string;

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  get empty(): boolean {
    return !this._value;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get enabled(): boolean {
    return !this._disabled;
  }
  set enabled(enabled) {
    this.disabled = !enabled;
  }

  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  get value(): any {
    return this._value;
  }
  set value(val) {
    this._value = val;
    if (this.valueInitialized) {
      this.onChange();
    }
    this.valueInitialized = true;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void { }

  registerOnChange(fn): void {
    this.onControlValueChange = fn;
  }

  registerOnTouched(fn): void { }

  writeValue(val): void {
    // This method is called by formControl to set value to the underlying native form control
    // See: https://blog.angularindepth.com/never-again-be-confused-when-implementing-controlvalueaccessor-in-angular-forms-93b9eee9ee83

    this._value = val;

    // We shouldn't call onChange here because the caller of this function already know about the updated value
    // this.onChange();
  }

  onChange(): void {
    if (this.onControlValueChange) {
      this.onControlValueChange(this.value);
      this.stateChanges.next();
    }
  }

  get errorState(): boolean {
    // return this._errorState;
    return  this._errorState || this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  set errorState(val: boolean) {
    this._errorState = val;
  }

  setErrorState(state: boolean): void {
    this.errorState = state;
  }
}
