import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const commonIconList = [
    'robo-head1',
    'robo-head2',
    'graam-head'
] ;

const iconPath = 'assets/images/icons';

@Injectable()
export class CommonCustomIconsService {
    constructor(
        private iconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
    }

    registerCustomIcons(iconList?: Array<string>): void {
        const iList = iconList || commonIconList;

        for (const k of iList) {
            const iconFullPath = this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconPath}/${k}.svg`);

            this.iconRegistry.addSvgIcon(
                k,
                iconFullPath
            );
        }
    }
}
