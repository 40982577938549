import { Component, OnInit, Input, ViewChild, ElementRef, Self, Optional } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexSelectTreeInput } from '../flex-select-tree-input/flex-select-tree-input';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';

import { CustomStatsFilterEntity } from '@wephone-core/model/entity/customstatsfilter';
import { FormControl, NgControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CustomStatsFilterRepository } from '@wephone-core/model/repository/customstatsfilter';

import * as _ from 'lodash';
import { TreeHelper, FlexMatInputWrapper } from '@wephone-utils';

@Component({
  selector: 'flex-select-custom-filter-input',
  templateUrl: './flex-select-custom-filter-input.html',
  styleUrls: ['./flex-select-custom-filter-input.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelectCustomFilterInput }]
})
export class FlexSelectCustomFilterInput extends FlexMatInputWrapper implements OnInit {
  @Input() multiple: boolean;
  @Input() showCheckAll: boolean;
  @ViewChild('flexSelectTreeInput', { static: false }) flexSelectTreeInput: FlexSelectTreeInput;

  // Private member var with default value
  private customFilterRepo: CustomStatsFilterRepository;

  // Private member var
  private _customFilters: CustomStatsFilterEntity[];

  // Public member var with default value

  // Public member var
  myControl = new FormControl();
  customFilterNodes: FlexSelectTreeNode[] = new Array<FlexSelectTreeNode>();

  constructor(
    elRef: ElementRef,
    @Optional() @Self() ngControl: NgControl,
    private em: EntityManager
  ) {
    super(ngControl, elRef);
    this.customFilterRepo = this.em.getRepository('CustomStatsFilterRepository') as CustomStatsFilterRepository;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadCustomFilter();
  }

  protected loadCustomFilter(): void {
    this._customFilters = this.customFilterRepo.getObjectList() as CustomStatsFilterEntity[];

    const nodeList = this._customFilters.map(customFilter => {
      return {
        id: customFilter.id,
        name: customFilter.name,
        parent_id: undefined
      };
    });
    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.customFilterNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }

  get wrappedControl(): FormControl {
    return this.myControl;
  }

  get empty(): boolean {
    if (Array.isArray(this.wrappedControl.value)) {
      return !this.wrappedControl.value.length;
    }

    return !this.wrappedControl.value;
  }

  get controlType(): string {
    return 'flex-select-custom-filter-input';
  }

  async onContainerClick(event: MouseEvent): Promise<any> {
    this.focused = true;
    await this.flexSelectTreeInput.openDialog();
    this.focused = false;
    this.stateChanges.next();
  }

}
