import { GroupEntity } from '@wephone-core/model/entity/group';

export class SkillGroupEntity extends GroupEntity {
  public static object_type_id: string = 'skillgroup';
  public skills: Array<SkillEntity> = [];
  protected _data_loaded: boolean = true;

  constructor() {
    super();
    this.group_type = 'skill';
  }

  public fetchRelatedData() {
    super.fetchRelatedData();
    this.updateList(
      this.skills,
      EntityManager.getInstance()
        .getRepository<any>('SkillRepository')
        .findByGroupId(this.id)
    );
  }
}

import { datafield } from '@wephone-core/model/model';
import { SkillEntity } from '@wephone-core/model/entity/skill';
import { EntityManager } from '@wephone-core/service/entity_manager';
