<div fxFlexFill fxLayout="column">
  <div fxFlex="initial" fxLayout="row" class="pa-1">
    <div fxFlex>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="120px" class="font-weight-bold">{{ 'dialog_missed_call_info.phone_number' | translate }}</div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div fxFlex>
            {{numberNormalize(callNumber)}}
          </div>
          <div fxFlex *ngIf="activeCRM" class="crm-logo" (click)="openContactProfile(callNumber)"
            title="{{activeCRM.name}}">
            <img *ngIf="crmConfig" src="assets/images/{{crmConfig.logo}}" alt="{{activeCRM.type}}">
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="120px" class="font-weight-bold">{{ 'dialog_missed_call_info.start_time' | translate }}</div>
        <div>{{ call.start_time|timeFormatDateTimeShort }}</div>
      </div>

      <div fxLayout="row" *ngIf="callInfo">
        <div fxFlex="120px" class="font-weight-bold">{{ 'dialog_missed_call_info.wait_duration' | translate }}</div>
        <div>{{ callInfo.wait_duration | seconds_as_duration }}</div>
      </div>
    </div>
    <div fxFlex="30" fxLayoutAlign="center center">
      <button class="bt-call" mat-fab (click)="makeCall()">
        <mat-icon class="mat-text-white">call</mat-icon>
      </button>
    </div>
  </div>

  <div fxFlex="grow" fxLayout="column" class="pa-1 overview-scroll">
    <ng-container *ngIf="voicemailMessages && voicemailMessages.length">
      <h2 class="pt-1 tried-calls-title">{{ 'voicemail.title' | translate }}</h2>
      <table class="flex-table-info">
        <tr>
          <th class="text-left">
            {{ 'stats.missed_call.dialog_missed_call_info.content.call_time'|translate }}
          </th>
          <th class="text-left">
            {{ 'call.listen_voicemail'|translate }}
          </th>
        </tr>

        <tr *ngFor="let vm of voicemailMessages">
          <td>{{ vm.receive_dt|timeFormatDateTimeShort }}</td>
          <td>
            <fileentry-player-button [filePublicId]="vm.public_id" playerId="main"></fileentry-player-button>
          </td>
        </tr>
      </table>
    </ng-container>

    <h2 class="pt-1 tried-calls-title">{{ 'dialog_missed_call_info.agent_tried' | translate }}</h2>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 shadow-none" matSort>
      <ng-container matColumnDef="agent_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">
          <mat-icon>height</mat-icon>
          <!-- {{ 'stats.missed_call.dialog_missed_call_info.content.agent_name'|translate }} -->
        </th>
        <td mat-cell *matCellDef="let item">
          <div fxLayout="row">
            <ng-container *ngIf="item.user_direction === 2; then iconCallForwardBlock; else iconCallBackwardBlock">
            </ng-container>

            <ng-template #iconCallForwardBlock>
              <ng-container *ngTemplateOutlet="iconCallForward; context: {callItem: item}"></ng-container>
            </ng-template>
            <ng-template #iconCallBackwardBlock>
              <ng-container *ngTemplateOutlet="iconCallBackward; context: {callItem: item}"></ng-container>
            </ng-template>
            <span class="agent-name">{{ item.agent_name }}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="start_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">{{
          'stats.missed_call.dialog_missed_call_info.content.call_time'|translate }}</th>
        <td mat-cell *matCellDef="let item">{{ item.start_time|timeFormatDateTimeShort }}</td>
      </ng-container>
      <ng-container matColumnDef="called_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">{{
          'stats.missed_call.dialog_missed_call_info.content.phone_number'|translate }}</th>
        <td mat-cell *matCellDef="let item">{{ numberNormalize(item.called_number) }}</td>
      </ng-container>
      <ng-container matColumnDef="voicemail_public_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">{{
          'voicemail.title'|translate }}</th>
        <td mat-cell *matCellDef="let item">
          <fileentry-player-button *ngIf="item.voicemail_public_id" [filePublicId]="item.voicemail_public_id"
            playerId="main"></fileentry-player-button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="tried-call-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <p *ngIf="!agentTriedList || agentTriedList.length == 0">{{
      'stats.missed_call.dialog_missed_call_info.content.no_data'|translate }}
    </p>
    <ng-template #MissedCall let-callItem="callItem">
      <mat-icon *ngIf="callItem.is_voicemail else MissedCallIcon" class="is-missed">voicemail</mat-icon>
      <ng-template #MissedCallIcon>
        <mat-icon class="is-missed">call_missed</mat-icon>
      </ng-template>
      &nbsp;
    </ng-template>

    <ng-template #iconCallBackward let-callItem="callItem">
      <ng-container *ngIf="callItem.is_missed == 1 then missedInbound;else noMissedInbound"></ng-container>

      <ng-template #missedInbound>
        <ng-container *ngTemplateOutlet="MissedCall; context: {callItem: callItem}"></ng-container>
      </ng-template>

      <ng-template #noMissedInbound>
        <mat-icon class="call_in">south</mat-icon>
      </ng-template>
    </ng-template>

    <ng-template #iconCallForward let-callItem="callItem">
      <ng-container *ngIf="callItem.is_missed == 1 then missedOutbound;else noMissedOutbound"></ng-container>

      <ng-template #missedOutbound>
        <ng-container *ngTemplateOutlet="MissedCall; context: {callItem: callItem}"></ng-container>
      </ng-template>

      <ng-template #noMissedOutbound>
        <mat-icon class="mat-text-muted">north</mat-icon>
      </ng-template>
    </ng-template>
  </div>
</div>
