import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { Injectable, ApplicationRef } from '@angular/core';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import { WsRpcService } from '@wephone-core/service/wsrpc_service';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { IEntity, IRepository } from '@wephone-core/model/model.interface';
import { Deferred } from 'ts-deferred';
import { Resolve } from '@angular/router';
import { parseDateTime } from '@wephone-utils/utils/time-util';
import { SingletonBase } from '@wephone-utils/utils/singleton';
import { IEntityManager } from '@wephone-core/service/entity_manager.i';
import { Observable, Subject, interval } from 'rxjs';

@Injectable()
export class EntityManager extends SingletonBase implements Resolve<any>, IEntityManager {

  private watched_repositories = [];
  d_pushed_config_received: Deferred<any>;
  private d_callcenter_live: Deferred<any>;
  private _push_data_received = false;
  private _push_api_enabled = false;
  private _repo_sync_inprogress = false;
  private _hasDataChanges = false;
  private _dataChanged = new Subject();
  private callCenterDataRequestCount = 0;

  protected repo_map = {};
  protected repo_map_by_name = {};

  static getInstance(): EntityManager {
    return super.getInstance();
  }

  static getRepositoryById<T extends IRepository = BaseRepository>(entity_id: string): T {
    return EntityManager.getInstance().getRepositoryById<T>(entity_id);
  }

  static getRepository<T extends IRepository = BaseRepository>(repo_class: string): T {
    return EntityManager.getInstance().getRepository<T>(repo_class);
  }

  constructor(
    private wsrpc: WsRpcService,
    private configManager: ConfigManager
  ) {
    super();

    console.log('EntityManager run constructor!');
    StaticServiceLocator.setServiceByName('EntityManager', this);

    wsrpc.subscribe('APP_CONFIG', undefined, this.on_system_config_data);
    wsrpc.subscribeConnectionEvent(this.on_connection_event);
    this.d_pushed_config_received = new Deferred<any>();

    interval(1000).subscribe(x => {
      if (this._hasDataChanges) {
        this._hasDataChanges = false;
        this._dataChanged.next();
      }
    });

    // for (const r of getRepositoryList()) {
    //     this.addRepository(r);
    // }
    // let repoService = StaticServiceLocator.injector.get('RepositoryService');
  }

  get dataChanged(): Observable<any> {
    return this._dataChanged.asObservable();
  }

  setupRepositories(repo_list): void {
    console.log('setupRepositories: ', repo_list);
    for (const r of repo_list) {
      if (_.isArray(r)) {
        this.addRepository(r[0], r[1]);
      } else {
        this.addRepository(r);
      }
    }
  }

  resolve(): Promise<any> {
    return this.d_pushed_config_received.promise;
  }

  isDataReady(): boolean {
    return this.configManager.isDataReady() && (this._push_data_received || !this.hasPushAPI());
  }

  isPushAPIConnected(): boolean {
    return this.wsrpc.isConnected();
  }

  hasPushAPI(): boolean {
    return this.configManager.hasPushAPI();
  }

  async requestCallCenterLiveData(): Promise<void> {
    this.callCenterDataRequestCount++;
    if (this.callCenterDataRequestCount === 1) {
      this.d_callcenter_live = new Deferred<any>();
      this.wsrpc.subscribe('CALLCENTER_LIVE', undefined, this.on_system_config_data);
      await this.d_callcenter_live.promise;
    }
  }

  releaseCallCenterLiveData(): void {
    if (this.callCenterDataRequestCount <= 0) {
      console.error('releaseCallCenterLiveData got called when there is no active live data request');

      return;
    }
    this.callCenterDataRequestCount--;

    if (this.callCenterDataRequestCount === 0) {
      this.d_callcenter_live = undefined;
      this.wsrpc.unsubscribe('CALLCENTER_LIVE');
    }
  }

  isRepoSyncInprogress(): boolean {
    return this._repo_sync_inprogress;
  }

  private on_connection_event = (event: string, event_param: any) => {
    if (event === 'CONNECTED') {
      this.invalidatePushAPICache();
    } else if (event === 'DISCONNECTED') {
    }
  }

  private on_system_config_data = (remote_data: any) => {
    const repo_data = remote_data.data;
    // update with remote data
    let need_refresh = false;
    this._hasDataChanges = true;
    for (const obj_type of Object.keys(repo_data)) {
      if (repo_data.hasOwnProperty(obj_type)) {
        // Update watched repository list
        if (this.watched_repositories.indexOf(obj_type) < 0) {
          this.watched_repositories.push(obj_type);
        }
        const repo = this.getRepositoryById(obj_type);
        if (repo) {
          if (!repo_data[obj_type]) {
            console.warn('Object type is not supported', obj_type);
            continue;
          }
          const repo_refreshed = repo_data[obj_type]['refresh'];
          repo.updateWithRemoteData(repo_data[obj_type], !repo_refreshed);
          if (repo_refreshed) {
            need_refresh = true;
          }
        } else {
          console.warn('No repository defined for object type ', obj_type);
        }
      }
    }

    if (need_refresh) {
      for (let i = 0; i <= 1; i++) {
        // DO this twice to make sure all data dependencies are satisfied
        // If this is the first time data is received from remote, call onRemtoeDataUpdated for each repository
        for (const obj_type of Object.keys(repo_data)) {
          if (repo_data.hasOwnProperty(obj_type)) {
            const repo = this.getRepositoryById(obj_type);
            if (repo) {
              // Update data of the repo from related repo
              repo.reloadRelatedData();
              // Callback to notify that its data has been updated
              repo.onRemoteDataUpdated();
              // update data of related repository due to repo's update
              repo.updateRelatedRepositories();
            }
          }
        }
      }
    }
    if (!this._push_data_received) {
      this._push_data_received = true;
      this.d_pushed_config_received.resolve();
    }
    if (remote_data.message === 'CALLCENTER_LIVE' && this.d_callcenter_live) {
      this.d_callcenter_live.resolve();
      this.d_callcenter_live = undefined;
    }
  }

  async waitForPushedConfig(): Promise<any> {
    if (this.hasPushAPI()) {
      await this.d_pushed_config_received.promise;
    }
  }

  watchRepository(repo_name: string): void {
    if (this.watched_repositories.indexOf(repo_name) > -1) {
      return;
    }
    this.watched_repositories.push(repo_name);
    this.wsrpc.call_remote('watch_repository', { repository_name: repo_name });
  }

  waitSystemDataVersion(wanted_version: any): Promise<any> {
    return this.wsrpc.call_remote('wait_system_data_version', { data_version: wanted_version });
  }

  invalidatePushAPICache(): void {
    // this.config_manager.invalidateCache();
    for (const key of Object.keys(this.repo_map)) {
      if (this.repo_map[key].usePushAPI()) {
        this.repo_map[key].invalidateCache();
      }
    }
  }

  onWebserviceResponse(updated_objects: any, deleted_objects: any): void {
    const objectTypeList = [];
    if (updated_objects) {
      for (const obj_type of Object.keys(updated_objects)) {
        if (updated_objects.hasOwnProperty(obj_type)) {
          const repo = this.getRepositoryById(obj_type);
          if (repo) {
            repo.mergeUpdatedObjectList(updated_objects[obj_type]);
            objectTypeList.push(obj_type);
            console.log('list-updated', obj_type, repo.getObjectList());
          } else {
            console.error('Cannot Find Repository For Object Type ', obj_type);
          }
        }
      }
    }
    if (deleted_objects) {
      for (const obj_type of Object.keys(deleted_objects)) {
        if (deleted_objects.hasOwnProperty(obj_type)) {
          const repo = this.getRepositoryById(obj_type);
          if (repo) {
            for (const deleted_obj_id of deleted_objects[obj_type]) {
              repo.removeObjectById(deleted_obj_id);
              objectTypeList.push(obj_type);
            }
            console.log('list-deleted', obj_type, repo.getObjectList());
          } else {
            console.error('Cannot find repository for object type ', obj_type);
          }
        }
      }
    }

    if (objectTypeList.length) {
      this.resyncRepositories(objectTypeList);
    }
  }

  addRepository(repo_class: { new(): IRepository }, repo_name?: string): void {
    const new_instance = new repo_class() as BaseRepository;
    const _repo_name = repo_name || repo_class.name;
    if (new_instance.ENTITY_CLASS.object_type_id in this.repo_map) {
      throw new Error('Repository already exists: ' + new_instance.ENTITY_CLASS.object_type_id);
    }
    this.repo_map[new_instance.ENTITY_CLASS.object_type_id] = new_instance;
    this.repo_map_by_name[_repo_name] = new_instance;
  }

  getRepositoryById<T extends IRepository = BaseRepository>(entity_id: string): T {
    return this.repo_map && this.repo_map[entity_id];
    // if (entity_id in this.repo_map) {
    //   return this.repo_map[entity_id];
    // } else {
    //   console.log('this.repo_map', Object.keys(this.repo_map), entity_id);
    // }
  }

  getRepository<T extends IRepository = BaseRepository>(repo_class: string): T {
    if (repo_class in this.repo_map_by_name) {
      return this.repo_map_by_name[repo_class];
    }
  }

  reloadRepositories(repository_list: string[]): Promise<any> {
    //            for (repository_name of repository_list) {
    //                this.invalidateRepositoryCacheFor(repository_name);
    //            }
    const repo_list_config: string[] = [];

    for (const repo_name of repository_list) {
      const repository = this.getRepositoryById(repo_name);
      if (!repository) {
        console.error('Cannot find repository with name ', repo_name);
        return;
      }
      if (!repository.usePushAPI()) {
        repo_list_config.push(repo_name);
      }
    }

    return this.configManager.loadConfig(repo_list_config).then(config_list => {
      this.resyncRepositories(repository_list);

      return config_list;
    });
  }

  resyncRelatedRepositories(): void {
    if (!this.isDataReady()) {
      return;
    }
    const entity_names = [];
    for (const entity_name of Object.keys(this.repo_map)) {
      if (this.repo_map.hasOwnProperty(entity_name)) {
        entity_names.push(entity_name);
      }
    }
    this.resyncRepositories(entity_names);
  }

  resyncRepositories(repositories: string[]): void {
    try {
      this._repo_sync_inprogress = true;
      for (let i = 0; i <= 1; i++) {
        // DO this twice to make sure all data dependencies are satisfied
        for (const entity_name of repositories) {
          if (this.repo_map.hasOwnProperty(entity_name)) {
            this.resyncRepository(entity_name);
          }
        }
      }
    } finally {
      this._repo_sync_inprogress = false;
    }
  }

  resyncRepository(entity_name: string): void {
    const repo = this.repo_map[entity_name];
    if (repo) {
      // Update data of the repo from related repo
      repo.reloadRelatedData();
      // Callback to notify that its data has been updated
      repo.onRemoteDataUpdated();
      // update data of related repository due to repo's update
      repo.updateRelatedRepositories();
    }
  }

  invalidateRepositoryCacheFor(repository_name: string): void {
    this.configManager.invalidateObjectCache(repository_name);
    if (this.repo_map[repository_name]) {
      this.repo_map[repository_name].invalidateCache();
    }
  }

  dumpObjectData(obj: any, depth = 0, check_recursive = true): any {
    if (check_recursive && depth > 3) {
      console.warn('Object data depth is greater than 3');
      return null;
    }
    if (!obj) {
      return obj;
    } else if (typeof obj.dumpObjectData === 'function') {
      return obj.dumpObjectData();
    } else if (Array.isArray(obj)) {
      const ret = [];
      for (const v of obj) {
        ret.push(this.dumpObjectData(v, depth + 1, check_recursive));
      }
      return ret;
    } else if (obj instanceof Date || obj instanceof DateTime) {
      return parseDateTime(obj).toISO();
    } else if (obj instanceof Object) {
      const ret = {};
      for (const k of Object.keys(obj)) {
        ret[k] = this.dumpObjectData(obj[k], depth + 1, check_recursive);
      }
      return ret;
    }
    return obj;
  }
}
// import { RepositoryService } from '../service/reposirory_service';
