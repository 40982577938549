<div fxFill fxLayout="column">
  <h2>{{'enterprise_sip_gateway.content.provision_link'| translate}}</h2>
  <div *ngIf="provisionLink" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <div>
      <h3 class="ma-0" data-ci="provision_link">{{provisionLink}}</h3>
    </div>
    <div>
      <button mat-icon-button [matTooltip]="'clipboard.copy_title'|translate" (click)="copy()">
        <mat-icon color="primary">content_copy</mat-icon>
      </button>
    </div>
  </div>
</div>
