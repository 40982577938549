<form [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="25" class="pr-1">
      <mat-form-field class="full-width">
        <label>{{ 'opening_hour_calendar.content.name'|translate }}</label>
        <input matInput [placeholder]="'opening_hour_calendar.content.name' | translate" formControlName="name">

        <mat-error [flexFormControlError]="form.get('name')"></mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxFlex="50" class="pr-1">
        <mat-form-field class="full-width">
          <label>{{ 'opening_hour_calendar.content.start_date'|translate }}</label>
          <input matInput [matDatepicker]="startpicker" [placeholder]="'opening_hour_calendar.content.start_date' | translate"
            formControlName="startDt">
          <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
          <mat-datepicker #startpicker></mat-datepicker>

          <mat-error [flexFormControlError]="form.get('startDt')"></mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" class="pr-1">
        <mat-form-field class="full-width">
          <label>{{ 'opening_hour_calendar.content.end_date'|translate }}</label>
          <input matInput [min]="form.get('startDt').value" [matDatepicker]="endpicker" [placeholder]="'opening_hour_calendar.content.end_date' | translate"
            formControlName="endDt">
          <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
          <mat-datepicker #endpicker></mat-datepicker>

          <mat-error [flexFormControlError]="form.get('endDt')" [customMessages]="{
            matDatepickerMin: 'filter_form.datepicker.date_value_cannot_lesser_than_min_value'|translate:{min: form.get('endDt').errors?.matDatepickerMin.min |timeFormatDateShort }
          }"></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="25" class="pr-1">
      <mat-form-field class="full-width">
        <label>{{ 'opening_hour_calendar.content.mode'|translate }}</label>
        <mat-select [placeholder]="'opening_hour_calendar.content.mode' | translate" formControlName="openingMode" (selectionChange)="changeOpeningMode()">
          <mat-option *ngFor="let openingMode of openingModes" [value]="openingMode.value">
            {{ openingMode.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  
  <div fxLayout="row" fxLayoutAlign="start center"
    *ngIf="openingModeForm.value === openingModeValues.CloseDuringPeriod || openingModeForm.value === openingModeValues.OpenDuringPeriod">
    <div fxFlex="50"></div>
    <div fxFlex="50">
      <opening-hour-form-input type="hour" formControlName="openingHour"></opening-hour-form-input>
      
      <div class="mat-error relative" *ngIf="form.get('openingHour').invalid">
        <span class="error-message" [flexFormControlError]="form.get('openingHour')" [customMessages]="{
          timeRange: 'opening_hour_calendar.message.exception_time_invalid'|translate
        }"></span>
      </div>
    </div>
  </div>
  
  <div *ngIf="openingModeForm.value === openingModeValues.CloseDuringPeriod || openingModeForm.value === openingModeValues.CloseAllday">
    <sound-upload [attr.data-ci]="'announcement-file'"
      class="wrapper-sound-upload" [fileEntry]="announcement"
      title="{{'opening_hour_calendar.content.announcement_message'|translate}}" (onChange)="updateAnnouncementSoundFile($event)">
    </sound-upload>
  </div>
</form>
