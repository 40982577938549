import { Component, ViewChild, Inject } from '@angular/core';
import { IvrModuleEditComponent } from '@wephone/components/ivr-module-edit/ivr-module-edit.component';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors } from '@wephone-utils';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'ivr-module-create-modal',
  templateUrl: './ivr-module-create-modal.component.html',
  styleUrls: [ './ivr-module-create-modal.component.scss' ]
})
export class IvrModuleCreateModalComponent extends DialogComponentBase {
  @ViewChild('ivrModuleInput') ivrModuleInput: IvrModuleEditComponent;

  dialogTitle = _tk('ivr_module.title.add');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.createIvrModule();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];
  ivrModule: IvrScriptEntity;
  private ivrModuleRepo: IvrScriptRepository;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    dialogRef: MatDialogRef<IvrModuleCreateModalComponent>,
    private em: EntityManager
  ) {
    super(dialogRef);
    this.ivrModuleRepo = em.getRepository<IvrScriptRepository>('IvrScriptRepository');
    this.ivrModule =
      !data || !data.ivrModule ? this.ivrModuleRepo.create() as IvrScriptEntity : (this.ivrModule = data.ivrModule);
  }

  async createIvrModule(): Promise<any> {
    try {
      const retData = await this.ivrModuleInput.saveIvrModule();
      this.em.reloadRepositories([ 'ivrscript' ]);
      this.successToast(_ti('public.message.create_success'));
      this.dismiss(retData && retData.id ? { id: retData.id } : undefined);
    } catch (e) {
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  cancel(result?): void {
    this.dismiss(result);
  }
}
