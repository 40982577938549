import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SingletonBase } from '@wephone-utils';
import { HttpEngine } from './http_engine';
import { _ti } from '@wephone-translation';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ChartPeriod } from '@wephone/pages/dashboard/graph-chart/graph-chart-type';

export interface UserStatsDailyLiveOverview {
  inbound_call_count: number;
  outbound_call_count: number;
  inbound_call_answered: number;
  missed_call_count: number;
  missed_call_unhandled: number;
  outbound_call_answered: number;
  inbound_call_effective: number;
  outbound_call_effective: number;
  total_voicemail_today: number;
  unread_voicemail_today: number;
  active_inbound_call: number;
  active_outbound_call: number;
  agent_connected: number;
  agent_available: number;
  agent_incall: number;
  agent_pause: number;
  qos: number;
}

export interface QosData {
  answered: number;
  total_inbound: number;
  total_outbound: number;
  handled: number;
  total: number;
  qos: number;
  dmc: number;
  acw: number;
  dmt: number;
  dmr: number;
}

export interface CallStatsData {
  history: { [key: number]: number[] };
}

export interface ActiveCall {
  client_call_id: string;
  operator_call_id: string;
  calling_number: string;
  called_number: string;
  is_outgoing: number;
  handling_agent_id: number;
  enter_time: string;
  bridged_time: string;
  session_id: number;
  is_onhold: number;
  last_onhold_time: string;
  queue_id: number;
  group_id: number;
}

export interface LiveQueueData {
  queue_id: number;
  logo_id: number;
  queue_name: string;
  group_id: number;
  agent_list: number[];
  calls: ActiveCall[];
  call_stats: CallStatsData;
  qos_data?: QosData;
  active_call_count?: number;
  waiting_call_count?: number;
}

export interface FilterHour {
  start_hour: number;
  end_hour: number;
  start_min: number;
  end_min: number;
}

export interface IChartFilterTime {
  time_start?: string;
  time_end?: string;
}
export interface IChartFilter extends IChartFilterTime {
  day?: Date;
  queues?: number[];
}

export interface ILiveQueueFilter extends IChartFilterTime {
  period: ChartPeriod;
  period_start_date?: string;
  period_end_date?: string;
  include_outbound: number;
}

export interface LiveAgentData extends AgentEntity {
  _search_text: string;
  is_in_call: boolean;
  agent_status_detail: 'call_out' | 'call_in' | 'avail' | any;
}

@Injectable()
export class LiveStatsService extends SingletonBase {
  // static URL_DASHBOARD_LIVEINFO = 'callcenter/dashboard/agentinfo';
  static URL_DASHBOARD_DAILY_LIVEINFO = 'dashboard/get_daily_call_info';
  static URL_QUEUE_LIVEINFO = 'callcenter/liveinfo/queue';
  static URL_AGENT_LIVEINFO = 'callcenter/liveinfo/agent';

  private agent_live_info: LiveAgentData[] = [];
  private period: ChartPeriod = ChartPeriod.today;
  private include_outbound = false;
  private period_start_date: string;
  private period_end_date: string;

  queue_live_info: LiveQueueData[] = [];
  constructor(private datePipe: DatePipe, private httpEngine: HttpEngine) {
    super();
  }

  getEmptyData(queues: CallQueueEntity[]): LiveQueueData[] {
    const ret: LiveQueueData[] = [];
    const history = [];
    for (const i of [...Array(24).keys()]) {
      history.push([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }

    for (const q of queues) {
      ret.push({
        queue_id: q.id,
        logo_id: q.logo_id,
        queue_name: q.queue_name,
        group_id: q.group_id,
        agent_list: [],
        calls: [],
        call_stats: {
          history
        },
        qos_data: {
          answered: 0,
          total_inbound: 0,
          total_outbound: 0,
          handled: 0,
          total: 0,
          qos: 0,
          dmc: 0,
          acw: 0,
          dmt: 0,
          dmr: 0
        },
        active_call_count: null,
        waiting_call_count: null
      });
    }
    return ret;
  }

  async dashboardGetAgentInfo(groupIds?: number[], period: ChartPeriod = ChartPeriod.today): Promise<UserStatsDailyLiveOverview> {
    const params = {
      period
    };
    if ((groupIds || []).length) {
      params['group_ids[]'] = groupIds;
    }
    const data: {
      dashboard_info: UserStatsDailyLiveOverview;
    } = await this.httpEngine.apiGetV2(LiveStatsService.URL_DASHBOARD_DAILY_LIVEINFO, params);

    if (!data) {
      console.error('dashboardGetAgentInfo: No data responsed');
      return;
    }
    return data.dashboard_info;
  }

  // Never used
  // dashboardGetAgentDailyInfo(user_id: number): Promise<any> {
  //   const params = {
  //     user_id
  //   };
  //   return this.httpEngine.get(LiveStatsService.URL_DASHBOARD_DAILY_LIVEINFO, params);
  // }

  isHourInRange(hourStart: number, filter_hours: FilterHour): boolean {
    const startHour: number = filter_hours.start_hour;
    const endHour: number = filter_hours.end_hour;

    const startMin: number = filter_hours.start_min;
    const endMin: number = filter_hours.end_min;

    // 00:00 - 00:00 => Allday
    if (!startHour && !endHour && !startMin && !endMin) {
      return true;
    }

    if (hourStart < startHour) {
      return false;
    }

    if (hourStart > endHour || hourStart === endHour && endMin === 0) {
      return false;
    }

    return true;
  }

  private _getDayQosData(stats_data: { [key: string]: number[] }, callFilters: IChartFilter = null): QosData {
    const ret: QosData = {
      answered: 0,
      total_inbound: 0,
      total_outbound: 0,
      handled: 0,
      total: 0,
      qos: 0,
      dmc: 0,
      acw: 0,
      dmt: 0,
      dmr: 0
    };
    let process_duration = 0;
    let before_answer_duration = 0;
    let qualify_duration = 0;
    let total_handled_finished = 0;
    // let total_answered = 0;

    // Foreach hour of data
    for (const hourStart of Object.keys(stats_data)) {
      // if (!stats_data.hasOwnProperty(key)) {
      //   continue;
      // }

      if (callFilters) {
        const filterHour: FilterHour = {
          start_hour: +callFilters.time_start.substr(0, 2),
          end_hour: +callFilters.time_end.substr(0, 2),
          start_min: +callFilters.time_start.substr(3, 2),
          end_min: +callFilters.time_end.substr(3, 2)
        };
        if (!this.isHourInRange(+hourStart, filterHour)) {
          continue;
        }
      }

      const data = stats_data[hourStart];

      // ret.total += parseInt(data[0], 10);
      // ret.handled += parseInt(data[1], 10);
      const total_inbound = +data[2];
      const total = +data[7];
      const missed = +data[8];
      const total_outbound = +data[9];
      const handled = total - missed;
      const answered = +data[10];
      // const answered = total_inbound - missed;

      ret.total += total;
      ret.handled += handled; // parseInt(data[8], 10);
      ret.total_inbound += total_inbound;
      ret.answered += answered;
      ret.total_outbound += total_outbound;

      // total_handled_finished += +data[2];
      total_handled_finished += +data[1];
      process_duration += +data[3];
      before_answer_duration += +data[4];
      qualify_duration += +data[5];
    }
    if (ret.total) {
      if (ret.total_inbound) {
        ret.qos = Math.round(ret.answered * 100 / ret.total_inbound);
      }
      if (total_handled_finished) {
        ret.dmr = Math.round(before_answer_duration / total_handled_finished);
        ret.dmc = Math.round(process_duration / total_handled_finished);
        ret.acw = Math.round(qualify_duration / total_handled_finished);
        ret.dmt = ret.dmc + ret.acw;
      }
    }
    return ret;
  }

  private _setQueueLiveInfo(server_data: LiveQueueData[], callFilters: IChartFilter = null): void {
    for (let i = 0; i < server_data.length; i++) {
      const q: LiveQueueData = server_data[i];
      if (q.calls) {
        let active_call_count = 0;
        for (let j = 0; j < q.calls.length; j++) {
          const c = q.calls[j];
          c.queue_id = q.queue_id;
          c.group_id = q.group_id;
          if (c.bridged_time) {
            active_call_count += 1;
          }
        }
        q.active_call_count = active_call_count;
        q.waiting_call_count = q.calls.length - q.active_call_count;
      }
      q.qos_data = this._getDayQosData(q.call_stats.history, callFilters);
      // q.logo = q.logo_id != '0' ? this.userService.getEnterpriseFileEntryUrl(q.logo_id) : '';
    }
    this.queue_live_info = server_data;
  }

  async liveStatsGetQueueInfo(force_reload: boolean = false, callFilters: IChartFilter = null): Promise<LiveQueueData[]> {
    if (!force_reload && this.queue_live_info !== undefined) {
      return this.queue_live_info;
    }

    const params: ILiveQueueFilter = {
      period: this.period,
      include_outbound: this.include_outbound ? 1 : 0
    };
    if (this.period_start_date) {
      params.period_start_date = this.datePipe.transform(this.period_start_date, 'yyyy-MM-dd');
    }
    if (this.period_end_date) {
      params.period_end_date = this.datePipe.transform(this.period_end_date, 'yyyy-MM-dd');
    }

    const ret: LiveQueueData[] = await this.getLiveQueueInfo(params, callFilters);
    return ret;
  }

  async getLiveQueueInfo(params: ILiveQueueFilter, callFilters?: IChartFilter): Promise<LiveQueueData[]> {
    const _params = { ...params };
    if (callFilters) {
      _params.time_start = callFilters.time_start;
      _params.time_end = callFilters.time_end;
    }

    const data: {
      queues: LiveQueueData[]
    } = await this.httpEngine.apiGetV2(LiveStatsService.URL_QUEUE_LIVEINFO, _params);

    const ret = data.queues;
    this._setQueueLiveInfo(ret, callFilters);

    return ret;
  }

  private _updateAgentLiveInfo(): void {
    for (let i = 0; i < this.agent_live_info.length; i++) {
      const agent: LiveAgentData = this.agent_live_info[i];
      agent._search_text = ''; // Search text is used for angular text filter on the agent table
      if (agent.is_in_call) {
        if (agent.active_call.is_outgoing) {
          agent.agent_status_detail = 'call_out';
          agent._search_text += _ti('livecall.call_outgoing');
        } else {
          agent.agent_status_detail = 'call_in';
          agent._search_text += _ti('livecall.call_incoming');
        }
      } else if (agent.pause_since) {
        agent.agent_status_detail = 'pause-' + agent.pause_reason;
        agent._search_text += _ti('agent_status.pause');
      } else {
        agent.agent_status_detail = 'avail';
        agent._search_text += _ti('livecall.agent_list.status_available');
      }

      // Put queue names in agent search text
      const queues = this.liveStatsGetQueuesByAgentId(agent.agent_id);
      if (queues) {
        for (let j = 0; j < queues.length; j++) {
          agent._search_text += queues[j].queue_name + ' ';
        }
      }

      this.agent_live_info[i] = agent;
    }
  }

  private async liveStatsGetAgentInfo(force_reload?: boolean): Promise<LiveAgentData[]> {
    if (!force_reload && this.agent_live_info !== undefined) {
      return this.agent_live_info;
    }

    try {
      const data: {
        agents: LiveAgentData[]
      } = await this.httpEngine.apiGetV2(LiveStatsService.URL_AGENT_LIVEINFO, {});

      if (!data) {
        throw new Error('No data');
      }

      this.agent_live_info = data['agents'];
      this._updateAgentLiveInfo();
      // this.$rootScope.$broadcast(this.notifications.AGENT_LIVEINFO_UPDATE);
      return this.agent_live_info;
    } catch (error) {
      console.error('Invalid agent_get_liveinfo data returned: ', error);
    }
  }

  liveStatsGetQueuesByAgentId(agent_id: number): LiveQueueData[] {
    return this.queue_live_info.filter(x => x.agent_list.indexOf(agent_id) !== -1);
  }

  async reloadData(callFilters: IChartFilter = null): Promise<void> {
    await this.liveStatsGetQueueInfo(true, callFilters);
    await this.liveStatsGetAgentInfo(true);
  }

  liveStatsGetQueueById(queue_id: number): LiveQueueData[] {
    return this.queue_live_info.filter(x => x.queue_id === queue_id);
  }
}
