export class StringUtils {

  /**
   * 
   * @param text : string // 1 00:00:00,000 --> 00:00:03,000 I'm sorry, I can't help you with that. 2 00:00:03,040 --> 00:00:06,040 I'm sorry, I can't help you with that. 3 00:00:06,080 --> 00:00:09,080 I'm sorry, I can't help you with that. 4 00:00:09,120 --> 00:00:12,120 I'm sorry, I can't help you with that. 5 00:00:12,160 --> 00:00:15,160 I'm sorry, I can't help you with that
   * @param separated : string // <br>
   * @returns string // Short line of dialog with separated break
   * 1 00:00:00,000 --> 00:00:03,000 I'm sorry, I can't help you with that. 
   * 2 00:00:03,040 --> 00:00:06,040 I'm sorry, I can't help you with that. 
   * ...
   */
  static alignTranscriptionText(text: string, separated: string): string {
    if (!text) {
      return '';
    }
    // console.log('text', text);
    // const regex = /([0-9])+ ([0-9]*:*,*)+ --> ([0-9]*:*,*)+/gm;
    // const founds: RegExpMatchArray | null = text.match(regex);
    // console.log('founds', founds);
    // if (founds) {
    //   let i = 0;
    //   const lengText = text.length;
    //   const lengFound = founds.length;
    //   // console.log('lengText', lengText);
    //   const ret: string[] = [];
    //   let t = text;
    //   for (const f of founds) {
    //     const startIdx = t.indexOf(f);

    //     let endIdx = lengText;
    //     if (i + 1 < lengFound) {
    //       const nextF = founds[i + 1];
    //       endIdx = t.indexOf(nextF, nextF === f ? 1 : undefined);
    //       // console.log('nextF', nextF);
    //     }

    //     // console.log('endIdx', endIdx);

    //     const str: string = t.substring(startIdx, endIdx);
    //     ret.push(str);
    //     // console.log('str', str);

    //     t = text.substring(endIdx);
    //     i++;
    //   }

    //   return ret.join(separated);
    // }

    // return text;
    const textArr = text.split('\n\n');
    return textArr.join(separated);
  }

}
