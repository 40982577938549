import { Injectable } from '@angular/core';
import { ConversationDetailComponent } from '../component/conversation-detail/conversation-detail.component';
import { DialogService } from '@wephone-utils';
import { SmsDialog } from '@wephone-sms/sms.types';

@Injectable()
export class SmsDialogService {
  private _dialogRefs: any = {};

  constructor(
    private readonly dialogService: DialogService
  ) { }

  detailConversation(item: SmsDialog, callbackFn?: Function): void {
    console.log('Detail sms', item);

    const dialogId = `sms_dialog_${item.id}`;
    if (dialogId in this._dialogRefs && this._dialogRefs[dialogId]) {
      this._dialogRefs[dialogId].close();
    }

    this._dialogRefs[dialogId] = this.dialogService.openDialog2(
      ConversationDetailComponent,
      {
        data: {
          item
        },
        padding: false
      },
      (data: { item: any }) => {
        if (callbackFn) {
          callbackFn(data);
        }
        this._dialogRefs[dialogId] = undefined;
      }
    );
  }
}
