<div class="mat-select-trigger selected-value-text">
  <div class="mat-select-value" [matTooltip]="stripHtml(selectedText || placeholder)"
    [matTooltipDisabled]="!selectedText">
    <span *ngIf="!selectedText" class="mat-select-placeholder">{{ placeholder }}</span>
    <span *ngIf="selectedText" [innerHtml]="selectedText"></span>
  </div>
  <div class="mat-select-arrow-wrapper">
    <div class="mat-select-arrow"></div>
  </div>
</div>
