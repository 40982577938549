import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService, MessageService, ToastService, HttpClient } from '@wephone-utils';
import { TranslatePipe } from '@ngx-translate/core';
import { VoiceMailRepository } from '@wephone-core/model/repository/voicemail';
import { IEntity } from '@wephone-core/model/model.interface';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'action-renderer',
  template: ` <button mat-icon-button (click)="deleteVoicemail()"><mat-icon>delete</mat-icon></button>
  <button mat-icon-button [disabled]="!isFileReady" (click)="downloadVoicemail()"><mat-icon>cloud_download</mat-icon></button>`,
  providers: [TranslatePipe]
})
export class ActionRenderer implements ICellRendererAngularComp {
  private voicemailRepo = VoiceMailRepository.getInstance() as VoiceMailRepository;
  params: any;
  isFileReady;

  constructor(
    private dialogService: DialogService,
    private translatePipe: TranslatePipe,
    public messageService: MessageService,
    public fileEntryService: FileEntryService,
    public toastService: ToastService,
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.isFileReady = params && params.data ? params.data.file_entry_ready == 1 : false;
  }

  refresh(): boolean {
    return false;
  }

  translate(key: string, params: any = {}): any {
    return this.translatePipe.transform(key, params);
  }

  async deleteVoicemail() {
    const entity: any = this.params.data;
    console.log('delete', entity);

    this.dialogService.confirmDialog(
      this.translate('dialogs.confirmation'),
      this.translate('user.title.delete'),
      () => {
        this.bulkDelete([entity]);
      }
    );
  }

  downloadVoicemail() {
    const fileName = 'voicemail-' + this.params.value + '.mp3';
    const entity: any = this.params.data;
    const download_url = this.fileEntryService.getFileUrl(entity.sound_id, true);
    this.fileEntryService.download(download_url, fileName);
  }

  public bulkDelete(entities: Array<any>) {
    const items: Array<IEntity> = [];
    for (const entity of entities) {
      const item = this.voicemailRepo.create();
      item.setObjectData(entity);
      items.push(item);
    }

    this.voicemailRepo.deleteItems(items).then(
      data => {
        this.params.context.componentParent.reloadClick();
        this.toastService.show(_ti('public.message.delete_success'));
      },
      resp => {
        console.error('Delete Voicemail Error: ', resp);
      }
    );
  }
}
