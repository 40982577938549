<div fxLayout="column" fxFill>
  <ng-template #tplListFilter>
    <form [formGroup]="filterForm" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
      fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.xs="0px">
      <div>
        <strong>{{ 'user.content.total_users'|translate }}: {{ userCount }}</strong>
      </div>
      <div *ngIf="!uiSipTrunk">
        <mat-form-field class="full-width">
          <mat-label>{{ 'user_page.filters.phone_status.label' | translate }}</mat-label>
          <mat-select formControlName="phone_state" [placeholder]="'hotelroom.content.select_phone_status'|translate">
            <mat-option *ngFor="let phone_state of phone_state_list" [value]="phone_state.value">
              {{ phone_state.text | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <flex-search-input formControlName="search_text" [hintText]="'user.search.hint'|translate"
          ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>
  <flex-crud-page #flexCrud pageTitle="{{ 'user.title.manage'|translate }}" [dataSource]="dataSource"
    [tableConfig]="tableConfig" [exportable]="true" exportFileName="exported_users"
    [columnTemplates]="{'name': tplName, 'user_type': tplUserType, 'sipphone_is_online': tplPhoneStateCol, 'email': tplEmail}"
    [sidePannelOptions]="sidePannelOptions" [filterTemplate]="tplListFilter" fxLayout="row" fxFlex="grow">
    <ng-template #tplName let-user="row">
      <span [ngClass]="{'mat-text-muted': !user.enabled}"> {{user.name}} </span>
    </ng-template>
    <ng-template #tplEmail let-user="row">
      <span [ngClass]="{'mat-text-muted': !user.enabled}"> {{user.email}} </span>
    </ng-template>
    <ng-template #tplPhoneStateCol let-user="row">
      <span *ngIf="user.sipphone_is_online == -1" [ngClass]="{'mat-text-muted': !user.enabled}">{{
        'hotelroom.content.phone_state_nophone' | translate }}</span>
      <span color="secondary" *ngIf="user.sipphone_is_online > 0" [ngClass]="{'mat-text-muted': !user.enabled}">{{
        'hotelroom.content.phone_state_connected' |
        translate }}</span>
      <span color="danger" *ngIf="user.sipphone_is_online == 0" [ngClass]="{'mat-text-muted': !user.enabled}">{{
        'hotelroom.content.phone_state_disconnected' |
        translate }}</span>
    </ng-template>
    <ng-template #tplUserType let-user="row">
      <span [ngClass]="{'mat-text-muted': !user.enabled}">{{ ('user_type_names.'+ user.user_type) | translate }}</span>
    </ng-template>
  </flex-crud-page>
</div>
