<ng-template #tplListFilter>
  <form [formGroup]="form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="none">
    <div>
      <flex-search-input placeholder="{{  'public.search'|translate }}" formControlName="filterString"
        ngClass.xs="search-full-width"></flex-search-input>
    </div>
  </form>
</ng-template>

<flex-crud-page #flexCrud [dataSource]="dataSource" [tableConfig]="tableConfig" [filterTemplate]="tplListFilter"
  [columnTemplates]="{called_number: tplCalledNumber,'contact_name': tplContactName,  'status': tplStatus, 'scheduled_dt': tplDate, 'action': tplAction}"
  fxLayout="row" fxFlex="grow">
  <ng-template #tplCalledNumber let-item="row">
    {{ item.called_number|displayPhoneNumber }}
  </ng-template>

  <ng-template #tplStatus let-item="row">
    <span *ngIf="item.last_state">{{ ('campaign.item_states.' + item.last_state)|translate }}</span>
  </ng-template>
  <ng-template #tplContactName let-item="row">
    <strong> {{item.first_name}} {{item.last_name}}</strong>
  </ng-template>
  <ng-template #tplDate let-item="row">
    <span>{{ item.scheduled_dt | timeFormatDateShort }}</span>
  </ng-template>
  <ng-template #tplAction let-item="row">
    <div fxLayout="row">
      <button type="button" mat-icon-button (click)="editOutcallCampaignItem(item)" data-ci="button-edit-item">
        <mat-icon>edit</mat-icon>
      </button>
      <button type="button" mat-icon-button (click)="bulkDelete([item])" data-ci="button-delete-item">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </ng-template>

</flex-crud-page>
