export enum DisplayType {
  compact = 'compact',
  queue = 'queue',
  group = 'group',
}

export enum SortCallByDuration {
  RECENT_CALL_FIRST = 1,
  RECENT_CALL_LAST = 2,
}
